import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { Group, ProductionUnit } from '../../../backend_api/models';
import { InputWithHints } from '../../suppliers/components/InputWithHints';
import { GroupsWrapper } from '../../groups/components/GroupsWrapper';

export function NewProductionUnitInputsWithHints(props: {
    groupsEnabled: boolean;
    selectableProductionUnits: ProductionUnit[];
    groupsInput: Group[];
    productionUnitNameInput: string;
    productionUnitNumberInput: string;
    onGroupsChanged(groups: Group[]): void;
    onProductionUnitNameChanged(name: string): void;
    onProductionUnitNumberChanged(number: string): void;
    onExistingProductionUnitIdSelected(productionUnitId: string): void;
}): React.ReactElement {
    return (
        <Form>
            <InputWithHints
                input={props.productionUnitNameInput}
                required={true}
                entities={props.selectableProductionUnits as { id: string; }[]}
                findMatches={findProductionUnitMatchingNameInput as any}
                onEntitySelected={(pu) => props.onExistingProductionUnitIdSelected(pu.id)}
                onInputChanged={props.onProductionUnitNameChanged}
                renderMatchingEntity={renderMatchingProductionUnit}
                renderShowAll={(count) => renderShowAllProductionUnitName(props.productionUnitNameInput, count)}
                renderTitle={() => <label><FormattedMessage id='production_unit.input_label.production_unit_name' /></label>} />

            <InputWithHints
                input={props.productionUnitNumberInput}
                entities={props.selectableProductionUnits as { id: string; }[]}
                findMatches={findProductionUnitMatchingNumberInput as any}
                onEntitySelected={(pu) => props.onExistingProductionUnitIdSelected(pu.id)}
                onInputChanged={props.onProductionUnitNumberChanged}
                renderMatchingEntity={renderMatchingProductionUnit}
                renderShowAll={(count) => renderShowAllProductionUnitNumber(props.productionUnitNumberInput, count)}
                renderTitle={() => <label><FormattedMessage id='production_units.input_label.production_unit_number' /></label>} />

            {props.groupsEnabled
                ? <GroupsWrapper
                    groupsInput={props.groupsInput}
                    onGroupsChanged={props.onGroupsChanged} />
                : null}
        </Form>
    );
}

export function findProductionUnitMatchingNameInput(productionUnits: ProductionUnit[], productionUnitNameInput: string): ProductionUnit[] {
    return productionUnits.filter((productionUnit) => {
        return productionUnitMatchesNameInput(productionUnit, productionUnitNameInput);
    });
}
function productionUnitMatchesNameInput(productionUnit: ProductionUnit, productionUnitNameInput: string) {
    return stringMatchesInput(productionUnit.name, productionUnitNameInput);
}

export function findProductionUnitMatchingNumberInput(productionUnits: ProductionUnit[], productionUnitNumberInput: string): ProductionUnit[] {
    return productionUnits.filter((productionUnit) => {
        return productionUnitMatchesNumberInput(productionUnit, productionUnitNumberInput);
    });
}
function productionUnitMatchesNumberInput(productionUnit: ProductionUnit, productionUnitNumberInput: string) {
    return stringMatchesInput(productionUnit.name, productionUnitNumberInput);
}

function stringMatchesInput(existing: string, input: string, minimumInputLength = 3) {
    const a = (existing || '').toLocaleLowerCase();
    const b = (input || '').toLocaleLowerCase();

    if (b.length < minimumInputLength) {
        return false;
    }
    return a.includes(b);
}

export function renderShowAllProductionUnitName(productionUnitNameInput: string, count: number) {
    return (<FormattedMessage
        // TODO, use this:  id='globals.production_unit_exists.name'
        id='audits.create_supplier.suppliers_exist'
        values={{
            supplierName: <span className='font-semibold'>{productionUnitNameInput}</span>,
            cnt: count
        }} />)
}

export function renderShowAllProductionUnitNumber(productionUnitNumber: string, count: number) {
    return (<FormattedMessage
        // TODO, use this:  id='globals.production_unit_exists.number'
        id='audits.create_supplier.suppliers_number_exist'
        values={{
            supplierNumber: <span className='font-semibold'>{productionUnitNumber}</span>,
            cnt: count
        }} />)
}

export function renderMatchingProductionUnit(productionUnit: ProductionUnit) {
    return <FormattedMessage
        // TODO, use this:  id='globals.production_unit_exists'
        id='audits.create_production_unit.production_unit_exists'
        values={{
            name: <span className='font-semibold'>{productionUnit.name}</span>,
            number: <span className='font-semibold'>{productionUnit.number}</span>
        }} />
}