/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentationTemplate,
    DocumentationTemplateFromJSON,
    DocumentationTemplateFromJSONTyped,
    DocumentationTemplateToJSON
} from './DocumentationTemplate';

import {
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from '../../backend_api/models/TextWithTranslation';

/**
 * pending
 * @export
 * @interface RequirementTemplate
 */
export interface RequirementTemplate {
    /**
     * 
     * @type {Array<DocumentationTemplate>}
     * @memberof RequirementTemplate
     */
    documentation_templates?: Array<DocumentationTemplate>;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof RequirementTemplate
     */
    requirement_template_description?: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof RequirementTemplate
     */
    requirement_template_id: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof RequirementTemplate
     */
    requirement_template_name?: TextWithTranslation;
    /**
     * 
     * @type {number}
     * @memberof RequirementTemplate
     */
    requirement_template_revision?: number;
    /**
     * 
     * @type {Date}
     * @memberof RequirementTemplate
     */
    requirement_template_revision_timestamp?: Date | null;
}

export function RequirementTemplateFromJSON(json: any): RequirementTemplate {
    return RequirementTemplateFromJSONTyped(json, false);
}

export function RequirementTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequirementTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentation_templates': !exists(json, 'documentation_templates') ? undefined : ((json['documentation_templates'] as Array<any>).map(DocumentationTemplateFromJSON)),
        'requirement_template_description': !exists(json, 'requirement_template_description') ? undefined : TextWithTranslationFromJSON(json['requirement_template_description']),
        'requirement_template_id': json['requirement_template_id'],
        'requirement_template_name': !exists(json, 'requirement_template_name') ? undefined : TextWithTranslationFromJSON(json['requirement_template_name']),
        'requirement_template_revision': !exists(json, 'requirement_template_revision') ? undefined : json['requirement_template_revision'],
        'requirement_template_revision_timestamp': !exists(json, 'requirement_template_revision_timestamp') ? undefined : (json['requirement_template_revision_timestamp'] === null ? null : new Date(json['requirement_template_revision_timestamp'])),
    };
}

export function RequirementTemplateToJSON(value?: RequirementTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentation_templates': value.documentation_templates === undefined ? undefined : ((value.documentation_templates as Array<any>).map(DocumentationTemplateToJSON)),
        'requirement_template_description': TextWithTranslationToJSON(value.requirement_template_description),
        'requirement_template_id': value.requirement_template_id,
        'requirement_template_name': TextWithTranslationToJSON(value.requirement_template_name),
        'requirement_template_revision': value.requirement_template_revision,
        'requirement_template_revision_timestamp': value.requirement_template_revision_timestamp === undefined ? undefined : (value.requirement_template_revision_timestamp === null ? null : value.requirement_template_revision_timestamp.toISOString()),
    };
}


