import React, { ReactNode } from 'react';
import { twMerge } from '../../../../../base/utils';

type Props =  {
   className?: string;
   children: ReactNode;
};

const ImagesContainer = (props: Props): React.ReactElement => {
   const { className } = props;
   return <div className={twMerge('gap-4 grid  grid-cols-1 sm:grid-cols-2 pb-4', className)}>{props.children}</div>
}
export default ImagesContainer;
