/* eslint-disable no-undef */
declare global {
    interface Window { T: any, noticeable: any }
}

import '@radix-ui/themes/styles.css';
import * as Sentry from '@sentry/react';
import 'core-js/stable'; // TODO: Dispite useBuiltIns: "usage" in babel.config, core-js needs to be loaded to make ie11 happy
import 'intl'; // TODO: Added intl polyfill to support older Xiaomi browsers
import 'intl/locale-data/jsonp/en';
import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { createRoot } from 'react-dom/client';
import 'whatwg-fetch'; // TODO: Promises for ie11 - not sure why they are not provided by babel/core-js
import '../css/color-variables.scss';
import '../css/index.css'; // Importing stylesheets for entire app
import '../figma/files/figma-color-variables.scss';
import '../less/main.less'; // Importing stylesheets for entire app
import '../semantic/css/semantic.min.css';

import Root from './containers/Root';

const pjson = require('../package.json');
const wl1 = new RegExp('testing\\.aws\\.qarma\\.one/q/app');
const wl2 = new RegExp('app\\.qarmainspect\\.com/q/app/static');

if ((module as any).hot) {
    (module as any).hot.accept();
}


Sentry.init({
    dsn: 'https://1e29d919d28d4791936ab23f997158ce@o70641.ingest.sentry.io/276027',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
    // normalizeDepth: 3, // Or however deep you want your state context to be.
    release: pjson.version,
    allowUrls: [wl1, wl2],
});

// Load updates widget

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);
