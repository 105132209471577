import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CorrectiveActionsState, InlineReportCorrectiveAction } from '../../../../../backend_api/models';
import Button from '../../../../../base/components/basic/Button';
import Link from '../../../../../base/components/basic/Link';
import { TranslateTextWithTranslation, translateTextWithTranslation } from '../../../../../base/components/basic/TextWithTranslation';
import { MicroInteractionOpen } from '../../../../../base/ui/components/MicroInteractions';
import { getPrettyDate, isUndefinedOrNullOrEmptyString, preventDefaultAndStopPropagation, twMerge } from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import FileItem from '../../../../attachments/components/FileItem';
import ApproveCorrectiveActionDialog from '../../../../correctiveActions/components/ApproveCorrectiveActionDialog';
import CorrectiveActionStatus from '../../../../correctiveActions/components/CorrectiveActionStatus';
import { approveCorrectiveActionOnInspection } from '../../../../correctiveActions/correctiveActionsSlice';

type Props = {
    className?: string;
    correctiveAction: InlineReportCorrectiveAction;
    inspectionId: string;
};

const InspectionReportCorrectiveAction = (props: Props): React.ReactElement => {
    const { className, correctiveAction } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    return <div className={twMerge('mb-4', className)}>
        <Link className='flex border rounded-lg text-primary hover:text-primary items-center py-1 px-3' underline={false} to={'/ca/details/' + correctiveAction.id} key={'defect_' + correctiveAction.id}>
            <div className='flex flex-col w-full'>
                <div className='py-2 w-full flex space-x-2 justify-between'>
                    <div className='flex sm:space-x-2 items-start sm:items-center flex-col sm:flex-row'>
                        <CorrectiveActionStatus className='h-6 mb-4 sm:mb-0' state={correctiveAction.state} />
                        <div className='font-bold'><FormattedMessage id='corrective_actions.inspections.corrective_action' /></div>
                        <div className='flex items-center'>
                            <TranslateTextWithTranslation twt={correctiveAction.finding} />
                            <MicroInteractionOpen />
                        </div>
                    </div>
                    {correctiveAction.state === CorrectiveActionsState.Finding && correctiveAction.due_date && <div className='text-sm'><span className='text-secondary mr-2'><FormattedMessage id='corrective_actions.due.text' /></span>{getPrettyDate(correctiveAction.due_date) || '-'}</div>}
                    {correctiveAction.state === CorrectiveActionsState.Review && <div onClick={(e) => preventDefaultAndStopPropagation(e)} className='text-sm'>
                        <ApproveCorrectiveActionDialog id={correctiveAction.id} approveFunction={(data) => {
                            dispatch(approveCorrectiveActionOnInspection({ id: correctiveAction.id, reportId: props.inspectionId, request: data.request }))
                        }} trigger={<Button small primary><FormattedMessage id='corrective_actions.inspections.approve' /></Button>} /></div>}
                </div>
                {(correctiveAction.state === CorrectiveActionsState.Review || correctiveAction.state === CorrectiveActionsState.InProgress || correctiveAction.state === CorrectiveActionsState.Closed) && <div className='flex w-full'>
                    <div className='flex p-1 flex-col sm:flex-row space-y-2 sm:space-y-0 w-full sm:items-center'>
                        {!isUndefinedOrNullOrEmptyString(correctiveAction.description_of_resolution.text) &&
                            <div className='w-full sm:w-1/3'>
                                <div className='text-sm text-secondary'><FormattedMessage id='corrective_actions.resolution' /></div>
                                <div><TranslateTextWithTranslation twt={correctiveAction.description_of_resolution} /></div>
                            </div>}

                        {!isUndefinedOrNullOrEmptyString(correctiveAction.approver_comment && correctiveAction.approver_comment.text) &&
                            <div className='w-full sm:w-1/3'>
                                <div className='text-secondary text-sm'><FormattedMessage id='corrective_actions.inspections.corrective_action.approver_comment' /></div>
                                <TranslateTextWithTranslation twt={correctiveAction.approver_comment} />
                            </div>
                        }
                        {correctiveAction.evidence.length > 0 &&
                            <div className='w-full sm:w-1/3'>
                                <div className='text-secondary text-sm'><FormattedMessage id='corrective_actions.evidence' /></div>
                                <div>{correctiveAction.evidence.map((evidence) => {
                                    return <div key={'ev_' + correctiveAction.id + evidence.id} onClick={(e) => preventDefaultAndStopPropagation(e)} >
                                        <TranslateTextWithTranslation twt={evidence.description} />
                                        {translateTextWithTranslation(evidence.description, intl.locale)}
                                        <FileItem file={evidence} showDelete={false} className='text-sm p-0 m-0 hover:bg-transparent underline text-brand' displayAsFile />
                                    </div>
                                })}</div>
                            </div>}
                    </div>
                </div>}
            </div>
        </Link >
    </div >
}
export default InspectionReportCorrectiveAction;
