import React from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { Score } from '../../../../checklists/types';
import { TextItem } from '../../../../globals/components';

type OwnProps = {
    score: Score;
}
type CheckpointsScoreProps = OwnProps;
const CheckpointsScore = (props: CheckpointsScoreProps): React.ReactElement => {
    const intl = useIntl();
    const { score, max_score } = props.score;
    const label = <span>{Math.round(props.score.percentage)}{'%'}</span>
    return score > 0 || (score === 0 && max_score > 0) ? <div className='score'>
        <Popup trigger={label}>
            <TextItem label={intl.formatMessage({ id: 'report.checkpoint.score' })} column text={label} />
        </Popup></div> : null;
}

export default CheckpointsScore;
