import qs from 'query-string';
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { createAction as createActionTS } from 'typesafe-actions';
import { AdminCtxUser } from '../../../backend_api/models/AdminCtxUser';
import { GetProfileResponse } from '../../../backend_api/models/GetProfileResponse';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import { request, request2, RequestResponse } from '../../../base/api';
import { AppThunk, Locale, RequestError } from '../../../base/types';
import { compareObjects, deepCopy } from '../../../base/utils';
import messages from '../../../modules/messages';
import history from '../../../store/history';
import errorHandling from '../../errorHandling';
import { GroupData } from '../../groups/types';
import { getRolesListSuccess } from '../../roles/actions';
import { Filters, UserPreferences } from '../types';
import * as types from './actionTypes';

const sendErrorMessage = messages.actions.sendErrorMessage;
const sendStatusMessage = messages.actions.sendStatusMessage;
const catchException = errorHandling.handler.catchException;

export const usersRequestSuccessful = createActionTS(types.USERS_REQUEST_SUCCESS, (isFetching: boolean, isOrderSpecific: boolean, data: ListUsersUser[]) => (
    {
        type: types.USERS_REQUEST_SUCCESS,
        payload: {
            data: populateUsersData(data, isOrderSpecific),
            isFetching,
            isOrderSpecific,
        },
    }
));

// Get list of users for use on edit and report pages
export const getOrderUsers = (id: string, isOrder: boolean) => {
    return (dispatch): Promise<void | ListUsersUser[]> => {
        dispatch(isFetching(true));
        const params = isOrder ? '?order_id=' + id : '?inspection_id=' + id;
        return request('users' + params, {})
            .then((response: ListUsersUser[]) => {
                dispatch(usersRequestSuccessful(false, true, response));
                return response;
            }).catch((error: RequestError) => {
                dispatch(usersRequestFailure(false, error));
                catchException('getUsers', {
                    endpoint: 'users',
                    request: 'users',
                    status: error.status,
                }, { error });
            });
    };
};

export const usersRequestFailure = createAction(types.USERS_REQUEST_FAILURE, (isFetching: boolean, error: RequestError) => ({ isFetching, error }));

// reset two factor auth token on user

export const resetTwoFactorToken = (id: string) => {
    return (dispatch): Promise<void> => {
        return request(`admin/org/reset_two_factor/${id}`, { method: 'POST' })
            .then(() => {
                dispatch(sendStatusMessage(['twoFactor.users.resetSuccess'], 3000));
            })
            .catch(() => dispatch(sendErrorMessage(['twoFactor.users.resetFail'], 3000)));
    }
}

export const resendInvitation = (userId: string, message: string): AppThunk => {
    return (dispatch): any => {
        const url = 'admin/org/resend_invitation';
        return request(url, {
            method: 'POST',
            body: JSON.stringify(
                {
                    user_id: userId,
                    message: message
                }
            )
        }).then(() => {
            dispatch(sendStatusMessage(['users.resend_success'], 3000));
        }).catch(() => dispatch(sendErrorMessage(['users.resend_fail'], 3000)));
    }
}

export const getUsers = () => {
    return (dispatch): Promise<void | ListUsersUser[]> => {
        dispatch(isFetching(true));
        return request('users', {})
            .then((response: ListUsersUser[]) => {
                dispatch(usersRequestSuccessful(false, false, response));
                return response;
            }).catch((error: RequestError) => {
                dispatch(usersRequestFailure(false, error));
                dispatch(sendErrorMessage(['error_message.error_loading_users'], 3000));
                catchException('getUsers', {
                    endpoint: 'users',
                    request: 'users',
                    status: error.status,
                }, { error });
            });
    };
};


export const requestUsersWithFilters = createAction(types.GET_USERS_WITH_FILTERS, (filters: Filters) => (
    { filters }
));
export const getUsersWithFilters = (filters: Filters) => {
    return (dispatch: Dispatch<any>): void => {
        dispatch(requestUsersWithFilters(filters || {}));
    }
}

// Get list of users for admin use
export const getAdminUsers = () => {
    return (dispatch): Promise<void> => {
        dispatch(isFetching(true));
        return request('admin/org/users', {})
            .then((response: { users: AdminCtxUser[] }) => {
                dispatch(adminUsersRequestSuccessful(false, response.users));
            }).catch((error: RequestError) => {
                dispatch(adminUsersRequestFailure(false, error));
                dispatch(sendErrorMessage(['error_message.error_loading_users'], 5000));
                catchException('getAdminUsers', {
                    endpoint: 'users',
                    request: 'users',
                    status: error.status,
                }, { error });
            });
    };
};

export const createUser = (user: any) => {
    return (dispatch): Promise<void> => {
        dispatch(isFetching(true));
        return request('admin/org/invitation', {
            method: 'POST',
            body: JSON.stringify(user),
        })
            .then(() => {
                dispatch(showCreateUserDialogAction(false));
                dispatch(adminCreateUsersRequestSuccessful(false));
                dispatch(getAdminUsers());
                dispatch(getUsers());
            }).catch((error: RequestError) => {
                dispatch(adminCreateUsersRequestFailure(false, error));
                catchException('createUser', {
                    endpoint: 'admin/org/invitation',
                    request: 'admin/org/invitation',
                    status: error.status,
                }, { error, method: 'POST', body: JSON.stringify(user) });
            });
    };
};

export const getLocales = () => {
    return (dispatch): Promise<any> => {
        return request('locales', {
            method: 'GET',
        })
            .then((response: { locales: Locale[] }) => {
                dispatch(getLocalesRequest(response.locales));
                return response;
            }).catch((error: RequestError) => {
                dispatch(sendErrorMessage(['error_message.locales_could_not_be_loaded'], 3000));
                catchException('getLocales', {
                    endpoint: 'locales',
                    request: 'locales',
                    status: error.status,
                }, { error });
            });
    };
};

export const getGroups = () => {
    return (dispatch): Promise<any> => {
        return request('groups', {
            method: 'GET',
        })
            .then((response: { groups: GroupData[] }) => {
                dispatch(getGroupsRequests(response.groups));
                return response;
            }).catch((error: RequestError) => {
                dispatch(sendErrorMessage(['error_message.could_not_load_groups'], 3000));
                catchException('getGroups', {
                    endpoint: 'groups',
                    request: 'groups',
                    status: error.status,
                }, { error });
            });
    };
};

export const getRoles = () => {
    return (dispatch): Promise<any> => {
        return request('user_roles', {})
            .then((response: RequestResponse['data']) => {
                dispatch(getRolesListSuccess(response));
                return response;
            }).catch((error) => {
                dispatch(sendErrorMessage(['error_message.could_not_load_user_roles'], 3000));
                catchException('user_roles', {
                    endpoint: 'user_roles',
                    request: 'user_roles',
                    status: error.status,
                }, { error });
            });
    }
}

export const updateUser = (userId: string, payload: Partial<AdminCtxUser>, isAdmin = true, showMessage = true) => {
    return (dispatch): Promise<void | any> => {
        dispatch(isFetching(true));
        const url = isAdmin ? 'admin/org/users/' : 'users/';
        return request(url + userId, {
            body: JSON.stringify(payload),
            method: 'PUT',
        })
            .then((response: any) => {
                isAdmin ? dispatch(adminUserUpdateRequestSuccessful(false, response)) : dispatch(userUpdateRequestSuccessful(false, response));
                if (showMessage) {
                    dispatch(sendStatusMessage(['status_message.the_user_data_was_updated'], 3000));
                }
                dispatch(isFetching(false));
                return response;
            }).catch((error) => {
                dispatch(isFetching(false));
                dispatch(sendErrorMessage(['error_message.could_not_update_user_data'], 3000));
                catchException('updateUser', {
                    endpoint: isAdmin ? 'admin/org/users/' : 'users/',
                    request: url,
                    status: error.status,
                }, { error, method: 'PUT', body: JSON.stringify(payload) });

            });
    };
};

export const updateUserPreferences = (prefFragment: UserPreferences): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('preferences',
            {
                method: 'put',
                body: JSON.stringify(prefFragment),
            })
            .then((preferences: UserPreferences) => {
                dispatch(updateUserPreferencesSuccess(preferences));
            })
            .catch((error: RequestError) => {
                catchException('updateUserPreferences', {
                    endpoint: '/preferences',
                    request: '/preferences',
                    status: error.status,
                }, { error, method: 'PUT', prefFragment });
            });
    };
};

export const getUserPreferences = (): AppThunk => {
    return async (dispatch): Promise<any> => {
        const response: Response = await request2('preferences');
        if (!response.ok) {
            const error = await response.json();
            console.log('error ', error)
            dispatch(sendErrorMessage(['error_message.could_not_load_user_preferences'], 3000));
            catchException('getUserPreferences', {
                endpoint: '/preferences',
                request: '/preferences',
                statusText: response.statusText,
                status: response.status,
            }, { error });
            return response;
        }
        const data = await response.json();
        dispatch(updateUserPreferencesSuccess(data));
        return data;
    };
};

export const populateUsersData = (users: ListUsersUser[], isOrderSpecific: boolean): { persons: ListUsersUser[]; byId: { [userId: string]: ListUsersUser }; byGroupId: { [groupId: string]: ListUsersUser[] }; orderSpecific?: ListUsersUser[]; orderSpecificById?: { [userId: string]: ListUsersUser } } => {
    const byId: { [userId: string]: ListUsersUser } = {};
    const groupsObj: { [groupId: string]: ListUsersUser[] } = {};
    const data = users.filter((d: ListUsersUser) => {
        if (!isOrderSpecific) {
            if (d.order_specific === undefined) {
                return d;
            }
            return d.order_specific === false;
        } else {
            return d;
        }
    });
    data.forEach((item: ListUsersUser) => {
        byId[item.id] = item;
        const groups: GroupData[] = item.groups;
        groups.forEach((group: GroupData) => {
            if (!groupsObj[group.id]) {
                groupsObj[group.id] = [item];
            } else {
                groupsObj[group.id].push(item);
            }
        });
    });
    const persons = data.sort((a: any, b: any) => {
        if (a.firstname === null) {
            return 1;
        }
        return (a.firstname.localeCompare(b.firstname)) < 0 ? -1 : 1;
    });
    if (isOrderSpecific) {
        return {
            persons,
            byId,
            byGroupId: groupsObj,
            orderSpecific: persons,
            orderSpecificById: byId,
        };
    }
    return {
        persons,
        byId,
        byGroupId: groupsObj,
    };
};

export const populateAdminUsersData = (users: AdminCtxUser[]): { persons: AdminCtxUser[] | ListUsersUser[]; byId: { [userId: string]: AdminCtxUser | ListUsersUser }; byGroupId: { [groupId: string]: AdminCtxUser[] | ListUsersUser[] } } => {
    const byId: { [userId: string]: AdminCtxUser } = {};
    const groupsObj: { [groupId: string]: AdminCtxUser[] } = {};
    users.forEach((item: AdminCtxUser) => {
        byId[item.id] = item;
        const groups: GroupData[] = item.groups;
        groups.forEach((group: GroupData) => {
            if (!groupsObj[group.id]) {
                groupsObj[group.id] = [item];
            } else {
                groupsObj[group.id].push(item);
            }
        });
    });
    const persons = users.sort((a: any, b: any) => {
        if (a.firstname === null) {
            return 1;
        }
        return (a.firstname.localeCompare(b.firstname)) < 0 ? -1 : 1;
    });
    return {
        persons,
        byId,
        byGroupId: groupsObj,
    };
}

export const populateUserData = (user: GetProfileResponse): { person: ListUsersUser; byId: { [userId: string]: ListUsersUser }; byGroupId: { [groupId: string]: ListUsersUser[] } } => {
    const byIdObj = {};
    const groupsObj = {};
    const person: ListUsersUser = Object.assign({}, user, { order_specific: false, supplier_user: false, last_activity: null });
    byIdObj[user.id] = person;
    const groups: GroupData[] = person.groups;
    groups.forEach((group: GroupData) => {
        if (!groupsObj[group.id]) {
            groupsObj[group.id] = [person];
        } else {
            groupsObj[group.id].push(person);
        }
    });
    return {
        person,
        byId: byIdObj,
        byGroupId: groupsObj,
    };
};

export const populateAdminUserData = (user: AdminCtxUser): { person: AdminCtxUser; byId: { [userId: string]: AdminCtxUser }; byGroupId: { [groupId: string]: AdminCtxUser[] } } => {
    const byIdObj = {};
    const groupsObj = {};

    byIdObj[user.id] = user;
    const groups: GroupData[] = user.groups;
    groups.forEach((group: GroupData) => {
        if (!groupsObj[group.id]) {
            groupsObj[group.id] = [user];
        } else {
            groupsObj[group.id].push(user);
        }
    });
    return {
        person: user,
        byId: byIdObj,
        byGroupId: groupsObj,
    };
}


export const isFetching = createAction(types.IS_FETCHING, (isFetching: boolean) => ({ isFetching }));

export const adminUsersRequestSuccessful = createAction(types.ADMIN_USERS_REQUEST_SUCCESS, (isFetching: boolean, data: AdminCtxUser[]) => (
    {
        data: populateAdminUsersData(data),
        isFetching,
    }
));
export const adminUsersRequestFailure = createAction(types.ADMIN_USERS_REQUEST_FAILURE, (isFetching: boolean, error: RequestError) => (
    { isFetching, error }
));

export const adminCreateUsersRequestSuccessful = createAction(types.ADMIN_CREATE_USERS_REQUEST_SUCCESS, (isFetching: boolean) => ({ isFetching }));
export const adminCreateUsersRequestFailure = createAction(types.ADMIN_CREATE_USERS_REQUEST_FAILURE, (isFetching: boolean, error: RequestError) => ({ isFetching, error }));

export const getLocalesRequest = createAction(types.GET_LOCALES_REQUEST, (locales: Locale) => ({ locales }));

export const getGroupsRequests = createAction(types.ADMIN_USERS_GET_GROUPS, (groups: GroupData[]) => ({ groups }));

export const userUpdateRequestSuccessful = createActionTS(types.USER_UPDATE_REQUEST_SUCCESS, (isFetching: boolean, user: GetProfileResponse) => (
    {
        type: types.USER_UPDATE_REQUEST_SUCCESS,
        payload: {
            data: populateUserData(user),
            isFetching
        },
    }
));

export const adminUserUpdateRequestSuccessful = createActionTS(types.ADMIN_USER_UPDATE_REQUEST_SUCCESS, (isFetching: boolean, user: AdminCtxUser) => (
    {
        type: types.ADMIN_USER_UPDATE_REQUEST_SUCCESS,
        payload: {
            data: populateAdminUserData(user),
            isFetching
        },
    }
));

export const showCreateUserDialogAction = createAction(types.ADMIN_CREATE_USER_SHOW_DIALOG, (createUserDialogOpen: boolean) => ({ createUserDialogOpen }));

export const showCreateUserDialog = (createUserDialogOpen: boolean) => {

    return (dispatch): void => {
        dispatch(showCreateUserDialogAction(createUserDialogOpen));
    };
};

export const updateUserPreferencesSuccess = createActionTS(types.GET_USER_PREFERENCES_SUCCESS, (preferences: UserPreferences) => {
    return {
        type: types.GET_USER_PREFERENCES_SUCCESS,
        payload: { preferences },
    };
});

export const clearAllUsers = createAction(types.CLEAR_ALL_USERS);

export const setUsersPagination = ({ currentPage, lastPage, itemsPerPage }: { currentPage: number; lastPage: number; itemsPerPage: number }) => {
    return ((dispatch, getState): void => {
        const params = qs.parse(history.location.search);
        currentPage = currentPage ? +currentPage : params.page ? +params.page : 1;
        dispatch({ type: types.SET_USERS_PAGINATION, payload: { currentPage, lastPage, itemsPerPage } });
        const newParams = deepCopy(params);
        if (currentPage > 1) {
            newParams.page = getState().app.users.currentPage.toString();
        } else {
            delete newParams.page
        }
        if (!compareObjects(params, newParams)) {
            history.push('?' + qs.stringify(newParams));
        }

    });
};
