/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Entities to export with audits.
 *
 */
export enum AuditsExportAdditionalObjects {
    CHECKPOINTS = 'checkpoints',
    CORRECTIVE_ACTIONS = 'corrective_actions',
}
