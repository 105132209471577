import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ListableCorrectiveAction, PaginatedCorrectiveActions } from '../../../backend_api/models';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import TabsMenu from '../../../base/components/TabsMenu';
import { Breakpoints, DEFAULT_LIMIT_LIST_SIZE } from '../../../base/config';
import { getLocationEntry, getPrettyDate, twMerge, urlParamsToArray, useMinWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { DateFilter } from '../../filters/components';
import AddFilters from '../../filters/components/AddFilters';
import FiltersListNew from '../../filters/components/FiltersListNew';
import TranslatedLabel from '../../globals/components/TranslatedLabel';
import { InfoIcon } from '../../globals/components/Utils';
import { ListView, ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import { UserInitials } from '../../users/components/Widgets';
import { getCorrectiveActionsFetchingSelector, getCorrectiveActionsListSelector, getCorrectiveActionsMetaDataSelector, listCorrectiveActions, listCorrectiveActionsWaitingForMe, listOverdueCorrectiveActions, setCorrectiveActionListDate } from '../correctiveActionsSlice';
import CorrectiveActionStatus from './CorrectiveActionStatus';
import CreateCorrectiveActionOnSupplier from './CreateCorrectiveActionOnSupplier';


const CorrectiveActionsList = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [total, setTotal] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT_LIST_SIZE);
    const [offset, setOffset] = useState<number>(0);
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    const isMinLG = useMinWidthBreak(Breakpoints.LG);


    let filters = {} as any;
    const preset = getLocationEntry(location, 'preset')[0] || 'all';
    const isFetching = useSelector(getCorrectiveActionsFetchingSelector);
    const history = useHistory();
    const correctiveActionsData: PaginatedCorrectiveActions = useSelector(getCorrectiveActionsListSelector);
    const correctiveActions = correctiveActionsData && correctiveActionsData.corrective_actions || [];
    const hasCorrectiveActions = correctiveActions.length > 0;
    const openDetailedPage = (action: ListableCorrectiveAction) => {
        history.push('/ca/details/' + action.id);
    };
    const getList = (filters: any) => {
        if (preset == 'me') {
            dispatch(listCorrectiveActionsWaitingForMe(filters))
        }
        else if (preset == 'overdue') {
            dispatch(listOverdueCorrectiveActions(filters))
        }
        else {
            dispatch(listCorrectiveActions(filters))
        }
    }

    useEffect(() => {
        if (correctiveActionsData && correctiveActionsData.pagination) {
            setOffset(correctiveActionsData.pagination.offset);
            setLimit(correctiveActionsData.pagination.limit);
            setTotal(correctiveActionsData.pagination.total);
            setCount(parseInt(correctiveActionsData.pagination.limit as unknown as string, 10) + parseInt(correctiveActionsData.pagination.offset as unknown as string, 10));
        }
    }, [correctiveActionsData]);

    useEffect(() => {
        filters = urlParamsToArray(location.search);
        getList(filters);
    }, [preset, location.search])

    return <Fragment>
        
        <div className='flex justify-between'>
            <div className='w-full'>
                <div className='flex flex-col md:flex-row md:space-x-4 md:items-center mb-4'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex items-center space-x-4'>
                            <TabsMenu
                                active={preset}
                                path=''
                                items={[
                                    { labelId: 'corrective_actions.filter_tabs.all', query: { preset: 'all' }, id: 'all' },
                                    { labelId: 'corrective_actions.filter_tabs.overdue', query: { preset: 'overdue' }, id: 'overdue' },
                                ]} />
                            {isMinMD && <DateFilter onChange={setCorrectiveActionListDate} />}
                            <AddFilters />
                        </div>
                        <div className='h-fit-content'>
                            <CreateCorrectiveActionOnSupplier />
                        </div>
                    </div>
                </div>
                <FiltersListNew
                    getFilterMetaData={getCorrectiveActionsMetaDataSelector}
                    context=''
                    noStatusAfterClear={true}
                    dateFilterChange={setCorrectiveActionListDate}
                    filters={[
                        'approver-filter',
                        'supplier-filter',
                        'production-unit-filter',
                        'responsible-filter',
                        'ca-state-filter'
                    ]}
                />
            </div>
        </div>
        {!hasCorrectiveActions && <Loader active={isFetching}><div className={twMerge('justify-center flex p-4')}><InfoIcon compact text={intl.formatMessage({ id: 'corrective_actions.list_no_items' })} /></div></Loader>}
            
        <ListView className='mb-0'>
            {hasCorrectiveActions && <><ListViewHeader
                className={classnames('top-0')}
                items={[
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.finding' }), className: 'w-2/16', key: 'item' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.supplier' }), className: 'w-3/16', key: 'supplier' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.production_unit' }), className: 'w-3/16', key: 'pu' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.assigned' }), className: 'w-2/24', key: 'assigned' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.created_date' }), className: 'w-2/24', key: 'created' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.resolved_date' }), className: 'w-2/24', key: 'resolve' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.due_date' }), className: 'w-2/24', key: 'duedate' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.status' }), className: 'w-2/24', key: 'state' },
                ]} />
                <Loader active={isFetching}>
                    <ListViewLines className='border border-b-0'>
                        {correctiveActions.map((action) => {
                            const approver = action.approver ? <UserInitials user={action.approver} /> : '-';
                            const responsible = action.responsible ? <UserInitials user={action.responsible} /> : '-';
                            return (
                                <ListViewLine onClick={() => openDetailedPage(action)} key={action.id} className='bg-white cursor-pointer border-b sm:border-b-0'>
                                    <ListViewItem className='w-8/12 sm:w-2/16 order-1 pb-4 sm:pb-0'>
                                        <TranslatedLabel text={action.finding} />
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-3/16 order-3'>
                                        {action.supplier_links && action.supplier_links[0] && action.supplier_links[0].name}
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-3/16 order-4'>
                                        {action.production_unit_links && action.production_unit_links[0] && action.production_unit_links[0].name}
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-2/24 order-5  pb-4 sm:pb-0'>
                                        {responsible} <span className='text-xs text-secondary px-1'>/</span>  {approver}
                                    </ListViewItem>
                                    <ListViewItem className='flex w-1/3 sm:w-2/24 order-6'>
                                        {action.created_at && getPrettyDate(action.created_at)}
                                    </ListViewItem>
                                    <ListViewItem className='flex w-1/3 sm:w-2/24  order-7'>
                                        {action.resolve_date && getPrettyDate(action.resolve_date)}
                                    </ListViewItem>
                                    <ListViewItem className='w-1/3 sm:w-2/24  order-8'>
                                        {action.due_date_at && getPrettyDate(action.due_date_at)}
                                    </ListViewItem>
                                    <ListViewItem className='w-4/12 order-2 sm:order-last sm:w-2/24 justify-end'>
                                        <CorrectiveActionStatus state={action.state} />
                                    </ListViewItem>
                                </ListViewLine>)
                        })}
                    </ListViewLines>
                </Loader>
            </>}
        </ListView>
        {total > 0 && <LoadMore
            isLoading={isFetching}
            className='border border-t-0'
            chunk={limit}
            total={total}
            count={count}
            onLoadMore={(limit: number, offset: number) => {
                filters = urlParamsToArray(location.search);
                filters.limit = limit;
                filters.offset = offset;
                getList(filters);
            }} />}
    </Fragment>
}

export default CorrectiveActionsList;