/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Represents a value that has not been set or is being unset.
 *
 */
export type SetCustomFieldNotSet = {
    type: SetCustomFieldNotSet.type;
};

export namespace SetCustomFieldNotSet {

    export enum type {
        NOT_SET = 'not_set',
    }


}

