import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react';
import { arrayOfWeeksInYear } from '../../../../../../base/utils';
import { HasFeature } from '../../../../../profile/containers';

type WeekTogglerProps = {
    currentWeek: number;
    children?: any;
    currentDate: string;
    groupsFilter: any;
    filters: any[];
    changeWeek(isNext: boolean);
    changeWeekByNumber(date: string);
}
export const WeekToggler = (props: WeekTogglerProps): React.ReactElement => {
    const weekNos = arrayOfWeeksInYear(true);
    const intl = useIntl();
    const getSelector = (changeWeekByNumber, weekNumber: number, weekNos: number[], currentDate: string): React.ReactElement => {
        let i = 0;
        const options: DropdownItemProps[] = [];
        while (i < weekNos.length) {
            const m = moment().week(weekNos[i]);
            const start = m.startOf('isoWeek').format('ddd DD MMM YYYY');
            const end = m.endOf('isoWeek').format('ddd DD MMM YYYY');
            const date = moment().week(weekNos[i]).startOf('isoWeek').format('YYYY-MM-DD');
            const weekNoLabel = moment().week(weekNos[i]).format('W, YYYY');
            const weekLabel = intl.formatMessage({ id: 'inspections.week_view.week_label' });
            options.push(
                { value: date, text: weekLabel + ' ' + weekNoLabel, description: start + ' - ' + end },
            );
            i++;
        }
        return (
            <Dropdown
                name='wn'
                options={options}
                onChange={(evt, item): void => {
                    changeWeekByNumber(item.value);
                }}
                selection
                search={(options, query) => {
                    return options.filter((opt: any) => {
                        if (opt.text.search(query) > -1 || opt.description.search(query) > -1 || opt.value.search(query) > -1) {
                            return opt;
                        }
                    });
                }}
                value={currentDate}
                className='w-96'
                selectOnNavigation={false}
            />
        );
    };
    return (
        <div className='flex items-center justify-center p-2'>
            <span className='px-4 py-2 cursor-pointer' onClick={() => props.changeWeek(false)}>
                <Icon name='chevron left' size='small' />
            </span>
            {getSelector(props.changeWeekByNumber, props.currentWeek, weekNos, props.currentDate)}
            <span className='py-2 px-4 cursor-pointer' onClick={() => props.changeWeek(true)}><Icon name='chevron right' size='small' /></span>
            <div className='flex space-x-2'>
                <HasFeature feature='groups'>{props.groupsFilter}</HasFeature>
                {props.filters[0]}
            </div>
            {props.children}
        </div>
    );
};

