import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SimpleProductionUnit } from '../../../backend_api/models';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { addUpstreamProductionUnit, createProductionUnitWithoutSupplier, removeUpstreamProductionUnit } from '../slice/productionUnitsSlice';
import { NewProductionUnitParams } from './NewProductionUnitParams';
import ProductionUnitEditableList from './ProductionUnitEditableList';

type Props = {
    className?: string;
    productionUnitId: string;
    upstreamProductionUnits: SimpleProductionUnit[];
    type: 'upstream' | 'downstream';
    edit: boolean;
    isUserAllowedToCreateProductionUnits: boolean;
};

const DetailedProductionUnitUpstreamDownstream = (props: Props): React.ReactElement => {
    const { className, productionUnitId, upstreamProductionUnits, edit, type } = props;
    const dispatch = useAppDispatch();

    function onUserWantedToCreateAndAddProductionUnit(params: NewProductionUnitParams) {
        dispatch(createProductionUnitWithoutSupplier({
            production_unit_name: params.name,
            production_unit_number: params.number,
            production_unit_address: [],
            production_unit_group_ids: []
        })).then(function (obj) {
            if (obj && obj.payload && obj.payload.id) {
                onUserWantedToAddProductionUnit(obj.payload.id);
            }
        })
    }

    function onUserWantedToRemoveProductionUnitId(productionUnitIdToRemove) {
        dispatch(removeUpstreamProductionUnit({
            productionUnitId,
            upstreamProductionUnitId: productionUnitIdToRemove,
            type
        }));
    }

    function onUserWantedToAddProductionUnit(productionUnitIdToAdd: string) {
        dispatch(addUpstreamProductionUnit({
            productionUnitId,
            upstreamProductionUnitId: productionUnitIdToAdd,
            type
        }));
    }

    return <div className={twMerge('', className)}>
        <h5><FormattedMessage id={type === 'upstream' ? 'production_units.relations.upstream.label' : 'production_units.relations.downstream.label'} /></h5>
        {upstreamProductionUnits.length === 0 && <div><FormattedMessage id='production_units.relations.no_upstream_production__units_added' /></div>}
        <ProductionUnitEditableList
            isUserAllowedToCreateProductionUnits={props.isUserAllowedToCreateProductionUnits}
            productionUnits={upstreamProductionUnits}
            onUserWantedToRemoveProductionUnitId={onUserWantedToRemoveProductionUnitId}
            onUserWantedToAddProductionUnitId={onUserWantedToAddProductionUnit}
            onUserWantedToCreateAndAddProductionUnit={onUserWantedToCreateAndAddProductionUnit}
            edit={edit}
            excludeFromSelectorIds={[productionUnitId]}
        />
    </div>
}
export default DetailedProductionUnitUpstreamDownstream;
