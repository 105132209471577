import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Divider, Dropdown } from 'semantic-ui-react';
import { deepCopy } from '../../../base/utils';
import { getCustomBarcodeTables } from '../../customFields/selectors';
import { CustomTable } from '../../customFields/types';
import { BarcodeSample, BarcodeTableData, CustomString } from '../types';
import Barcode from './Barcode';

export const barcodeTableVersion = 1

type BarcodeDetailsProps = {
    data: BarcodeTableData;
    update(data: BarcodeTableData);
    language: string;
}

const BarcodeDetails = (props: BarcodeDetailsProps): React.ReactElement => {
    const customTables: CustomTable[] = useSelector(getCustomBarcodeTables)

    useEffect(() => {
        if (!props.data) {
            const data = {
                name: { C: '' },
                barcodes: [],
                use_custom_table: false,
                custom_table_definition_id: null,
                version: barcodeTableVersion
            }
            addBarcode(data);
        }
    }, [props.data])

    const addBarcode = (data?: BarcodeTableData): void => {
        const dataCopy: BarcodeTableData = deepCopy(data || props.data);

        const newBarcode: BarcodeSample = {
            name: { C: '' },
            scanned_barcode: null,
            scanned_format: null,
            expected_barcode: makeEmptyCustomString(),
            expected_format: makeEmptyCustomString(),
            added_by_inspector: false
        }

        dataCopy.barcodes.push(newBarcode);
        props.update(dataCopy);
    }

    const removeBarcode = (): void => {
        if (props.data.barcodes.length > 0) {
            const dataCopy: BarcodeTableData = deepCopy(props.data);
            dataCopy.barcodes.pop();
            props.update(dataCopy);
        }
    }

    const updateBarcodeSample = (barcode: BarcodeSample, index: number): void => {
        const dataCopy: BarcodeTableData = deepCopy(props.data);
        dataCopy.barcodes[index] = barcode;
        props.update(dataCopy);
    }

    const updateUseCustomTable = (use: boolean): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.use_custom_table = use
        if (use) {
            dataCopy.custom_table_definition_id = options[0].value;
        }
        else {
            dataCopy.custom_table_definition_id = null;
        }
        props.update(dataCopy)
    }

    const updateCustomTableId = (id: string): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.custom_table_definition_id = id
        props.update(dataCopy)
    }

    const options = [];
    customTables.forEach((table) => {
        options.push({
            text: table.name.C,
            value: table.id
        })
    })

    return (
        <div style={{ marginBottom: '10px' }} data-test-id='barcode-details'>
            {props.data &&
                <div>
                    <div style={{ alignItems: 'center', height: '40px', justifyContent: 'space-between', display: 'flex' }}>
                        {options.length > 0 &&
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                <Checkbox style={{ marginRight: '5px' }} checked={props.data.use_custom_table} onChange={(evt, item): void => updateUseCustomTable(item.checked)} />
                                <FormattedMessage id="checklists.editor.barcode.use_custom_field" />
                            </div>}
                        {props.data.use_custom_table &&
                            <div >
                                <Dropdown selection
                                    closeOnBlur
                                    value={props.data.custom_table_definition_id || options[0].value}
                                    options={options}
                                    onChange={(evt, item): void => updateCustomTableId(item.value as string)}
                                />
                            </div>
                        }
                    </div>
                    {!props.data.use_custom_table &&
                        <Fragment>
                            {props.data && props.data.barcodes.map((barcode: BarcodeSample, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <Divider />
                                        <Barcode
                                            data={barcode}
                                            language={props.language}
                                            update={(barcode): void => { updateBarcodeSample(barcode, index) }}
                                        />
                                    </Fragment>
                                )
                            })}
                            <div style={{ width: '100%', display: 'flow-root', marginBottom: '5px' }}>
                                <div style={{ float: 'right', display: 'flex' }}>
                                    <Button disabled={props.language !== 'C'} className={'with-border'} onClick={(): void => addBarcode()} data-test-id='add-barcode-button'><FormattedMessage id="checklists.editor.barcode.add" /></Button>
                                    <Button disabled={props.language !== 'C'} className={'with-border'} style={{ marginRight: '0px' }} onClick={removeBarcode} data-test-id='remove-barcode-button'><FormattedMessage id="checklists.editor.barcode.remove" /></Button>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
            }
        </div>
    )
}

export function makeEmptyCustomString(): CustomString {
    return {
        value: null,
        use_custom_field: false,
        custom_field_definition_id: null,
    }
}

export default BarcodeDetails;