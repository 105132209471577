import React, { ReactNode, useState } from 'react';
import { twMerge } from '../../../utils';
import Expand from './Expand';

type Props = {
    className?: string;
    onToggle?(isOpen: boolean): void;
    header?: ReactNode;
    children?: ReactNode;
    isExpanded?: boolean;
};

const ListToggle = (props: Props): React.ReactElement => {
    const { className, header } = props;
    const [isExpanded, setIsExpaned] = useState(props.isExpanded || false);
    const toggle = () => {
        setIsExpaned(!isExpanded);
        props.onToggle && props.onToggle(!isExpanded);
    }
    return <div className={twMerge('', !isExpanded ? 'items-center' : 'items-center')}
        onClick={() => {
            toggle();
        }}>
        <div className={twMerge('cursor-pointer flex justify-between px-4 py-3 border bg-white items-center', className)}>
            {header && header}<Expand isExpanded={isExpanded} />
        </div>
        {isExpanded && <div className='w-full'>{props.children}</div>}

    </div>
}
export default ListToggle;
