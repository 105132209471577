/*===========================================
=            Search action types            =
===========================================*/
export const DO_SEARCH = 'DO_SEARCH';
// tslint:disable-next-line:interface-over-type-literal
export type DO_SEARCH = { query: string };

export const SET_QUERY = 'SET_QUERY';
// tslint:disable-next-line:interface-over-type-literal
export type SET_QUERY = { query: string };

export const POPULATE_SEARCH_RESULT = 'POPULATE_SEARCH_RESULT';
// tslint:disable-next-line:interface-over-type-literal
export type POPULATE_SEARCH_RESULT = { result: any; query: string };
