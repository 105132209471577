import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { MicroInteractionPrevious } from '../../../ui/components/MicroInteractions';
import { twMerge } from '../../../utils';

type Props = {
    className?: string;
    disabled?: boolean;
};

const Previous = (props: Props): React.ReactElement => {
    const { className, disabled } = props;
    const location = useLocation();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('', className)}>
        <MicroInteractionPrevious disabled={disabled} />
    </div>
}
export default Previous;
