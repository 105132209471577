import { ListUsersUser } from '../../backend_api/models/ListUsersUser';
import { DropdownValue } from '../../base/types';
import { ApprovalProps } from './components/Approval';
import { ApprovalFlow, ApprovalFlowState } from './types';
export const setupApprovalStep = (step: number): { setupStep: ApprovalFlowState['setupStep']; currentLevel: ApprovalFlowState['currentLevel'] } => {
    return ({ setupStep: true, currentLevel: step });
};

export const hideApprovers = (level: number) => {
    return function update(): { showAddApprovers: ApprovalFlowState['showAddApprovers'] } {
        const showAddApprovers = { ...this.state.showAddApprovers };
        showAddApprovers[level] = false;
        return { showAddApprovers };
    };
};

export const showApprovers = (level: number) => {
    return function update(): { showAddApprovers: ApprovalFlowState['showAddApprovers'] } {
        const showAddApprovers = { ...this.state.showAddApprovers };
        showAddApprovers[level] = true;
        return ({ showAddApprovers });
    };
};
export const addApprover = (step: number, approverIds: DropdownValue) => {
    return function update(): { selectedApprovers: ApprovalFlowState['selectedApprovers'] } {
        const selectedApprovers = [...this.state.selectedApprovers];
        selectedApprovers[step] = approverIds;
        return { selectedApprovers };
    };
};

export const saveApprovalStep = (inspectionId: string, step: number) => {
    return function update(state, props: ApprovalProps): { selectedApprovers: ApprovalFlowState['selectedApprovers']; showAddApprovers: ApprovalFlowState['showAddApprovers']; setupStep: ApprovalFlowState['setupStep'] } {
        const flow = [...this.state.approvalFlow];
        const selectedApprovers = [...this.state.selectedApprovers];
        const approverIds = [...this.state.selectedApprovers[step]];
        const showAddApprovers = { ...this.state.showAddApprovers };
        let setupStep = { ...this.state.setupStep };
        let intended = [];
        let intendedObj = null;
        const level = step;

        if (flow[level]) { intended = flow[level]; }
        approverIds.forEach((approverId) => {
            const user = this.props.usersById[approverId];
            intendedObj = {
                intended: { id: approverId, email: user.email, firstname: user.firstname, lastname: user.lastname, disabled: user.disabled },
            };
            intended.push(intendedObj);
        });
        flow[level] ? flow[level] = intended : flow.push(intended);
        showAddApprovers[level] = false;
        setupStep = false;
        props.handleChange(undefined, { name: 'approval_flow', value: flow }, inspectionId);
        selectedApprovers[step] = [];
        return ({ selectedApprovers, showAddApprovers, setupStep });
    };
};

export const removeApprover = (inspectionId: string, level: number, approverId: string) => {
    return function update(state, props: ApprovalProps): void {
        const flow = [...this.state.approvalFlow];
        const currentApprovers = JSON.parse(JSON.stringify(flow[level]));
        let intendedIndex = -1;
        // If we are about to remove the last approver, we'll  remove the entire approval step instead
        if (currentApprovers.length === 1) {
            if (flow[level]) {
                flow.splice(level, 1);
            }
        } else {
            currentApprovers.forEach((approver, i) => {
                if (approver.intended.id === approverId) {
                    intendedIndex = i;
                }
            });
            currentApprovers.splice(intendedIndex, 1);
            flow[level] = currentApprovers;
        }
        props.handleChange(undefined, { name: 'approval_flow', value: flow }, inspectionId);
    };
};

export const removeApprovalStep = (inspectionId: string, level: number) => {
    return function update(state, props: ApprovalProps): void {
        const flow = Object.assign([], this.state.approvalFlow);
        if (flow[level - 1]) {
            flow.splice(level - 1, 1);
        }
        props.handleChange(undefined, { name: 'approval_flow', value: flow }, inspectionId);
    };
};

export const findWithAttr = (array, attr, value): number => {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export const getUsersThatAreNotApprovers = (currentApprovers: string[], users: ListUsersUser[]): ListUsersUser[] => {
    const usersCopy = (JSON.parse(JSON.stringify(users)));

    if (currentApprovers) {
        currentApprovers.forEach((userId: string) => {
            const index = findWithAttr(usersCopy, 'id', userId);
            if (index != -1) {
                usersCopy.splice(index, 1);
            }
        });
    }
    return Object.values(usersCopy);
};

export const saveFlow = (ids: string[], flow: ApprovalFlow) => {
    return function update(): void {
        ids.forEach((id) => {
            this.props.actions.saveApprovalFlow(id, flow);
        });
    };
};
