import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './actionTypes';
import { VideoState } from './types';

const initialState: VideoState = {
    video: {
        url: '',
        video_resources: []
    },
};
export const reducer = handleActions({
    [types.VIDEO_REQUEST_SUCCESS]: (state = initialState, action: Action<types.VIDEO_REQUEST_SUCCESS>) => {
        return {
            ...state,
            video: action.payload.video,
        };
    },
}, initialState);

export default reducer;