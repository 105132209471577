import React from 'react';
import { useSelector } from 'react-redux';
import { AuditHistoryEvent as AuditHistoryEventType } from '../../../backend_api/models';
import Loader from '../../../base/components/Loader';
import { getAuditHistorySelector } from '../selectors/auditSelectors';
import AuditHistoryEvent from './AuditHistoryEvent';

type AuditHistoryListProps = {
    className?: string;
    events?: AuditHistoryEventType[];
    level?: number;
};

const AuditHistoryList = (props: AuditHistoryListProps): React.ReactElement => {
    const { className, events, level } = props;
    const historyList = events || useSelector(getAuditHistorySelector);
    if (historyList) {
        return <Loader active={false}>
            {historyList.map((he) => {
                return <AuditHistoryEvent level={level} key={'he_' + he.audit_id + he.id} event={he} />
            })}
        </Loader>
    }
    return null;
}
export default AuditHistoryList;
