import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AuthenticationResponse, GenerateSecretRequest, GenerateSecretResponse, TwoFactorRequired, TwoFactorRequiredTwoFactorStateEnum, TwoFactorRequiredTypeEnum } from '../../backend_api/models';
import { AuthenticationAuthorization, AuthenticationAuthorizationTypeEnum } from '../../backend_api/models/AuthenticationAuthorization';
import { TempUserAuthorization } from '../../backend_api_2';
import { request2 } from '../../base/api';
import { applicationNotReady } from '../../base/baseSlice';
import { AppState } from '../../base/types';
import { isUndefinedOrNullOrEmptyString } from '../../base/utils';
import history from '../../store/history';
import { EMAIL_CLOSING_KEY, EMAIL_OPENING_KEY } from '../audit/components/edit-audit/SendEmailPreviewModal';
import { catchException } from '../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../messages/actions';
import { clearAllUsers } from '../users/actions/actions';
import { LoginCreds, Message } from './types';

type LoginResponse = {
    authorizations: (AuthenticationAuthorization | TwoFactorRequired)[];
}

type User = {
    exists: boolean;
    username: string;
}

export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const TWO_FACTOR_ERROR = 'TWO_FACTOR_ERROR';
export type TWO_FACTOR_SETUP_REQUIRED = 'TWO_FACTOR_SETUP_REQUIRED';
export type TWO_FACTOR_RESET_REQUIRED = 'TWO_FACTOR_RESET_REQUIRED';
export type TWO_FACTOR_TOKEN_REQUIRED = 'TWO_FACTOR_TOKEN_REQUIRED';
export type TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED = 'TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED';
export type TWO_FACTOR_NOT_ENABLED = 'TWO_FACTOR_NOT_ENABLED';
export const INVALID_TOKEN = 'Invalid Token';
export const EXPIRED_TOKEN = 'Token Expired';

export const LATEST_LOGGED_IN_USER_ID = "latest_logged_in_user_id";
export const TEMPORARY_USER_MODE = "temporary_user_mode";

export type AuthenticationState = {
    authorizations: (AuthenticationAuthorization | TwoFactorRequired)[];
    type: AuthenticationAuthorizationTypeEnum.Authorization | TwoFactorRequiredTypeEnum.TwoFactorRequired;
    twoFactorSetupRequired?: boolean;
    twoFactorStatus: TWO_FACTOR_SETUP_REQUIRED | TWO_FACTOR_RESET_REQUIRED | TWO_FACTOR_TOKEN_REQUIRED | TWO_FACTOR_NOT_ENABLED | TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED;
    isAuthenticated: boolean;
    isFetching: boolean;
    message: Message;
    user: User;
    email: string;
    password: string;
    emailSent: boolean;
    loginFailed: boolean;
    loginSucceded: boolean;
    twoFactorLoginFailed: boolean;
    firstLogin: boolean;
    redirectURL: string;
    currentOrganizationId: string;
    currentOrganizationName: string;
    selectOrganisation: boolean;
    lastLogin: string;
    secret_qr_code_base_64: string;
    secret_url: string;
    resetToken: string;
    resetStatus: number,
    resetMessage: string,
    showLoggedOutModal: boolean;
    twoFactorResetProcedure: TwoFactorRequiredTwoFactorStateEnum,
}

const initialState: AuthenticationState = {
    authorizations: localStorage.getItem('authentications') ? JSON.parse(localStorage.getItem('authentications')).authorizations : [],
    isAuthenticated: (sessionStorage.getItem('url_token') || sessionStorage.getItem('id_token')) && window.location.href.indexOf('reset_pw?locale_id') === -1 ? true : false,
    lastLogin: '',
    twoFactorSetupRequired: false,
    type: AuthenticationAuthorizationTypeEnum.Authorization,
    twoFactorStatus: 'TWO_FACTOR_NOT_ENABLED',
    isFetching: false,
    message: {
        status: 200,
        message: undefined,
    },
    user: undefined,
    email: '',
    password: '',
    emailSent: false,
    firstLogin: true,
    loginFailed: false,
    loginSucceded: false,
    redirectURL: undefined,
    currentOrganizationId: undefined,
    currentOrganizationName: undefined,
    selectOrganisation: false,
    secret_qr_code_base_64: undefined,
    secret_url: undefined,
    twoFactorLoginFailed: false,
    resetToken: null,
    resetStatus: undefined,
    resetMessage: undefined,
    showLoggedOutModal: false,
    twoFactorResetProcedure: undefined,

};

export const requestNewPassword = createAsyncThunk<void, string>(
    'requestNewPassword',
    async (username: string) => {
        const rq = await request2('reset_token', { method: 'post', body: JSON.stringify({ email: username }) });
        if (!rq.ok) {
            const error = await rq.json();
            catchException('requestNewPassword', {
                endpoint: 'reset_token',
                request: 'reset_token',
                status: rq.status,
            }, { error, rq, body: { email: username }, method: 'POST' });
        }
        return await rq.json();
    });

export const invalidateUserSession = createAsyncThunk<void, string>(
    'invalidateUserSession',
    async (userId: string) => {
        const body = { target_user_id: userId };
        const endpoint = 'invalidate_user_session';
        const rq = await request2(endpoint, { method: 'post', body: JSON.stringify(body) });
        if (!rq.ok) {
            const error = await rq.json();
            catchException('invalidateUserSession', {
                endpoint: endpoint,
                request: endpoint,
                status: rq.status,
            }, { error, rq, body, method: 'POST' });
        }
        return await rq.json();
    });


export type ResetPasswordProps = {
    password: string;
    resetToken: string;
    twoFactorToken?: string;
}

export const resetPassword = createAsyncThunk<AuthenticationResponse, ResetPasswordProps, {
    rejectValue: Response,
}>(
    'resetPassword',
    async (props: ResetPasswordProps, { dispatch, rejectWithValue }) => {
        const isTwoFactor = props.twoFactorToken;
        const endpoint = isTwoFactor ? 'reset_password_with_second_factor' : 'password';
        const body = { password: props.password, reset_token: props.resetToken };
        if (isTwoFactor) {
            body['second_factor_code'] = props.twoFactorToken;
        }
        const rq = await request2(endpoint, { method: 'put', body: JSON.stringify(body) });
        if (!rq.ok) {
            const error = await rq.json();
            const msg: Message = { status: rq.status, message: { reason: error.error && error.error.reason, detail: error.error && error.error.detail || error.errors && error.errors.detail } }
            dispatch(setError(msg));
            catchException('resetPassword', {
                endpoint: 'password',
                request: 'password',
                status: rq.status,
            }, { error, rq, body: { reset_token: props.resetToken }, method: 'PUT' });
            return rejectWithValue(rq as Response);
        }
        const data = await rq.json() as any;
        let token, orgId, accountId;
        if (Array.isArray(data.authorizations)) {
            token = data.authorizations[0].token;
            orgId = data.authorizations[0].organization_id;
            accountId = data.authorizations[0].account_id;
        } else {
            token = data.token;
            orgId = data.organization_id;
            accountId = data.account_id;
        }
        sessionStorage.removeItem('url_token');
        sessionStorage.removeItem('id_token');

        sessionStorage.setItem('id_token', token);
        localStorage.setItem('last_login:' + accountId, orgId);
        return data;
    });

export const resetTwoFactorSecret = createAsyncThunk<any, { userId: string }, {
    rejectValue: Response,
}>(
    'resetTwoFactorSecret',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('reset_two_factor_secret', { method: 'post', body: JSON.stringify({ user_id: params.userId }) });
        if (!rq.ok) {
            const error = await rq.json();
            dispatch(sendErrorMessage(['error_message.2fa.token_was_not_reset'], 3000));
            catchException('resetTwoFactorSecret', {
                endpoint: 'reset_two_factor_secret',
                request: 'reset_two_factor_secret',
                status: rq.status,
            }, { error, rq, body: { user_id: params.userId }, method: 'post' });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.2fa.token_was_succesfully_reset'], 3000))
        const data = await rq.json() as any;
        return data;
    });

export const login = createAsyncThunk<LoginResponse, LoginCreds, {
    rejectValue: Response,
}>(
    'login',
    async (creds: LoginCreds, { getState, dispatch, rejectWithValue }) => {
        const appState: AppState = getState() as AppState;
        const state = appState.app.authentication;
        const bodyData: { email: string; password: string; two_factor_token?: string } = {
            email: creds.email,
            password: creds.password,

        };
        const rq = await request2('login', { method: 'post', body: JSON.stringify(bodyData) });
        if (!rq.ok) {
            const error = await rq.json();
            catchException('login', {
                endpoint: 'login',
                request: 'login',
                status: rq.status,
            }, { error, rq, body: { email: creds.email, password: '****' }, method: 'POST', noAuth: true });
            return rejectWithValue(rq as Response);
        }
        const data = await rq.json() as LoginResponse;

        const authorizations = data.authorizations;
        localStorage.setItem('authentications', JSON.stringify({ authorizations }));
        dispatch(setAuthorizations(authorizations as AuthenticationAuthorization[]));
        if (authorizations.length === 1 || !isUndefinedOrNullOrEmptyString(state.currentOrganizationId)) {
            if (authorizations.length === 1) {
                dispatch(setOrganization({ organization: authorizations[0] }));
            } else {
                authorizations.forEach((authorization) => {
                    if (authorization.organization_id === state.currentOrganizationId) {
                        dispatch(setOrganization({ organization: authorization }));
                    }
                })
            }
        } else {
            dispatch(showSelectOrganisation(true));
        }
    });

export const twoFactorLogin = createAsyncThunk<AuthenticationAuthorization, LoginCreds, {
    rejectValue: Response,
}>(
    'twoFactorLogin',
    async (creds, { getState, dispatch, rejectWithValue }) => {
        const appState: AppState = getState() as AppState;
        const state = appState.app.authentication;
        const bodyData: { email: string; password: string; second_factor_token?: string, organization_id: string } = { 
            email: creds.email,
            password: creds.password,
            organization_id: creds.orgId || state.currentOrganizationId,
            second_factor_token: creds.token,
        };
        const rq = await request2('two_factor_login', { method: 'post', body: JSON.stringify(bodyData) });
        if (!rq.ok) {
            const error = await rq.json();
            catchException('two_factor_login', {
                endpoint: 'two_factor_login',
                request: 'two_factor_login',
                status: rq.status,
            }, { error, rq, body: { email: creds.email, password: '****' }, method: 'POST', noAuth: true });
            const err = await rq.json();
            return rejectWithValue(err as Response);

        }
        const data = await rq.json() as AuthenticationAuthorization;            
        const orgId = data.organization_id;
        let index = -1;
        const authorizations: (AuthenticationAuthorization | TwoFactorRequired)[] = localStorage.getItem('authentications') && [...localStorage.getItem('authentications') && JSON.parse(localStorage.getItem('authentications')).authorizations];
        authorizations && authorizations.map((auth, i) => {
            if (auth.organization_id === orgId) {
                index = i;
            }
        });
        if (index > 0) {
            authorizations[index] = data;
        }
        sessionStorage.removeItem('url_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.setItem('id_token', data.token);
        localStorage.setItem('last_login:' + data.account_id, orgId);
        localStorage.setItem('authentications', JSON.stringify({ authorizations }));
        authorizations && setAuthorizations(authorizations as AuthenticationAuthorization[]);
        dispatch(setLoginSucceded(true));
        dispatch(setFirstLogin(false));

        setTimeout(() => {
            history.push('/')            
            dispatch(setIsAuthenticated(true));

        }, 1000);
        return data;
    });

export const generate2FASecret = createAsyncThunk<GenerateSecretResponse, GenerateSecretRequest>(
    'generate2FASecret',
    async (params, { getState, dispatch, rejectWithValue }) => {
        const rq = await request2('generate_secret', { method: 'post', body: JSON.stringify(params) });
        if (!rq.ok) {
            const error = await rq.json();
            catchException('generate_secret', {
                endpoint: 'password',
                request: 'password',
                status: rq.status,
            }, { error, rq, body: {}, method: 'post' });
            return rejectWithValue(rq as Response);
        }
        const data = await rq.json() as GenerateSecretResponse;
        dispatch(setTwoFactorSecretQR(data.secret_qr_code_base_64));
    })
export const setOrganization = createAsyncThunk<AuthenticationAuthorization | TwoFactorRequired | TempUserAuthorization, { organization: AuthenticationAuthorization | TwoFactorRequired | TempUserAuthorization, creds?: LoginCreds }>(
    'setOrganization',
    async (params, { getState, dispatch, rejectWithValue }) => {
        const t = params.organization.type;
        if (t === 'two_factor_required') {
            dispatch(setAuthType(TwoFactorRequiredTypeEnum.TwoFactorRequired))
            if (params.organization.two_factor_state === 'reset_required') {
                dispatch(setTwoFactorStatus('TWO_FACTOR_RESET_REQUIRED'));
                localStorage.removeItem('authentications');
            } else {
                if (params.organization.two_factor_state === 'setup_required') {
                    const appState: AppState = getState() as AppState;
                    const email = appState.app.authentication.email;
                    const password = appState.app.authentication.password;
                    const rq = await request2('generate_secret', { method: 'post', body: JSON.stringify({ email, password, organization_id: params.organization.organization_id }) });
                    if (!rq.ok) {
                        const error = await rq.json();
                        catchException('generate_secret', {
                            endpoint: 'password',
                            request: 'password',
                            status: rq.status,
                        }, { error, rq, body: {}, method: 'post' });
                        dispatch(setTwoFactorStatus('TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED'));
                        return rejectWithValue(rq as Response);
                    }
                    const data = await rq.json() as GenerateSecretResponse;
                    dispatch(showSelectOrganisation(false));
                    dispatch(setTwoFactorStatus('TWO_FACTOR_SETUP_REQUIRED'));
                    dispatch(setTwoFactorSecretQR(data.secret_qr_code_base_64));
                }
                else {
                    localStorage.setItem('last_login:' + params.organization.account_id, params.organization.organization_id);
                    dispatch(showSelectOrganisation(false));
                    dispatch(setTwoFactorStatus('TWO_FACTOR_TOKEN_REQUIRED'));
                }
            }
        } else {
            sessionStorage.setItem('id_token', params.organization.token);
            
            if(!!(params.organization as TempUserAuthorization).session_id) {
                sessionStorage.setItem('session_id', (params.organization as TempUserAuthorization).session_id);
                sessionStorage.setItem(TEMPORARY_USER_MODE, 'true');
            }
        }
        localStorage.setItem(LATEST_LOGGED_IN_USER_ID, params.organization.user_id);
        dispatch(setOrganizationId(params.organization.organization_id));
        dispatch(setOrganizationName(params.organization.organization_name));
        localStorage.setItem('last_login:' + params.organization['account_id'], params.organization.organization_id);
        return params.organization
    });

export const logout = createAsyncThunk<void, void>(
    'logout',
    async (dummy, { dispatch }) => {
        sessionStorage.removeItem('id_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('authentications')
        localStorage.removeItem(LATEST_LOGGED_IN_USER_ID);
        sessionStorage.removeItem('url_token');
        sessionStorage.removeItem('session_id');
        sessionStorage.removeItem(TEMPORARY_USER_MODE);
        sessionStorage.removeItem(EMAIL_OPENING_KEY);
        sessionStorage.removeItem(EMAIL_CLOSING_KEY);
        dispatch(clearUserExistData());
        dispatch(clearAllUsers());
        dispatch(applicationNotReady());
        history.replace('/login');
        window.location.reload();
    });

export const loginExpired = createAsyncThunk<void, void>(
    'logout',
    async (dummy, { dispatch }) => {

        sessionStorage.removeItem('id_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('authentications');
        localStorage.removeItem(LATEST_LOGGED_IN_USER_ID);
        sessionStorage.removeItem('url_token');
        dispatch(clearUserExistData());
        dispatch(clearAllUsers());
        dispatch(applicationNotReady());
        dispatch(showLoggedOutModal());
    });

export const temporaryUserlogout = createAsyncThunk<void, void>(
    'temporaryUserlogout',
    async (dummy, { dispatch }) => {
        sessionStorage.removeItem('id_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('authentications')
        sessionStorage.removeItem('url_token');
        sessionStorage.removeItem(TEMPORARY_USER_MODE);
        dispatch(clearUserExistData());
        dispatch(clearAllUsers());
        dispatch(applicationNotReady());
    });

export const initAuthorisation = createAsyncThunk<void, void>(
    'initAuthorisation',
    async (dummy, { dispatch }) => {
        // Initializing authentication state
        const authentications: (AuthenticationAuthorization | TwoFactorRequired)[] = localStorage.getItem('authentications') && JSON.parse(localStorage.getItem('authentications')).authorizations;
       
        if (!sessionStorage.getItem('id_token')) {
            // Handle if users are stil logged in the old way
            // This will only be used until the user has logged out
            const oldToken = localStorage.getItem('id_token');
            if (oldToken) {
                sessionStorage.setItem('id_token', oldToken);
                dispatch(setFirstLogin(false));
                dispatch(setIsAuthenticated(true));
            }
            else {
                if (authentications && authentications.length === 1) {
                    dispatch(setOrganizationId(authentications[0].organization_id));
                    dispatch(setOrganization({ organization: authentications[0] }));
                } else if (authentications && authentications.length > 1) {
                    const orgId = localStorage.getItem('last_login:' + authentications[0].account_id);
                    dispatch(setOrganizationId(orgId));
                    const latestLoggedInUserId = localStorage.getItem(LATEST_LOGGED_IN_USER_ID);
                    const auth = authentications.filter(a => {
                        if(latestLoggedInUserId) {
                            return a.user_id === latestLoggedInUserId && a.organization_id == orgId;
                        }
                        return a.organization_id == orgId;
                    })[0];
                    dispatch(setOrganization({ organization: auth }));
                }
                dispatch(setFirstLogin(false));
            }
        }
        else {
            dispatch(setFirstLogin(false));
        }
    });

export const switchOrganisation = createAsyncThunk<void, AuthenticationAuthorization | TwoFactorRequired>(
    'switchOrganisation',
    async (organization: AuthenticationAuthorization | TwoFactorRequired, { dispatch }) => {

        sessionStorage.removeItem('url_token');
        if (organization.type === AuthenticationAuthorizationTypeEnum.Authorization) {
            sessionStorage.setItem('id_token', organization.token);
            localStorage.setItem('last_login:' + organization.account_id, organization.organization_id);
            history.push('/login');
            window.location.reload();
        } else {
            // if (organization.two_factor_state !== 'setup_required') {
            dispatch(setIsAuthenticated(false));
            dispatch(setOrganization({ organization }));
            dispatch(setTwoFactorStatus('TWO_FACTOR_TOKEN_REQUIRED_AND_RELOGIN_REQUIRED'));
        }

    });

export const switchOrganisationSameUrl = createAsyncThunk<void, string>(
    'switchOrganisation',
    async (organizationId: string) => {
        const authentications = JSON.parse(localStorage.getItem('authentications')).authorizations
        const organization = authentications.filter((auth) => auth.organization_id == organizationId)[0]
        sessionStorage.setItem('id_token', organization.token);
        localStorage.setItem('last_login:' + organization.user_id, organization.organization_id);
        window.location.reload();
    });

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        showSelectOrganisation(state, action: { payload: boolean }): void {
            state.selectOrganisation = action.payload;
        },
        setOrganizationId(state, action: { payload: string }): void {
            state.currentOrganizationId = action.payload;
        },
        setOrganizationName(state, action: { payload: string }): void {
            state.currentOrganizationName = action.payload;
        },
        setAuthType(state, action): void {
            state.type = action.payload;
        },
        setTwoFactorStatus(state, action: { payload: AuthenticationState['twoFactorStatus'] }): void {
            state.twoFactorStatus = action.payload;
        },
        setTwoFactorSecretQR(state, action: { payload: string }): void {
            state.secret_qr_code_base_64 = action.payload;
        },
        stopFetching(state): void {
            state.isFetching = false;
        },
        clearUserExistData(state): void {
            state.user = undefined;
            state.message = {
                status: 0,
                message: undefined,
            }
        },
        setRedirectUrl(state, action: { payload: string }): void {
            state.redirectURL = action.payload
        },
        setIsAuthenticated(state, action: { payload: boolean }): void {
            state.isAuthenticated = action.payload;
        },
        setFirstLogin(state, action: { payload: boolean }): void {
            state.firstLogin = action.payload;
        },
        setError(state, action: { payload: Message }): void {
            state.message = action.payload;
        },
        setLoginSucceded(state, action: { payload: boolean }): void {
            state.loginSucceded = action.payload;
            state.loginFailed = !action.payload;
        },
        setAuthorizations(state, action: { payload: AuthenticationAuthorization[] }): void {
            state.authorizations = action.payload
        },
        showLoggedOutModal(state): void {
            state.showLoggedOutModal = true;
        },
        gotoLogin(state): void {
            // 
        }
    },
    extraReducers: builder => {
        builder.addCase(twoFactorLogin.pending, (state) => {
            state.isFetching = true;
            state.twoFactorLoginFailed = false;
        });
        builder.addCase(twoFactorLogin.fulfilled, (state) => {
            state.isFetching = false;
            state.twoFactorLoginFailed = false;
            state.password = undefined;
            state.email = undefined;
            state.twoFactorResetProcedure = TwoFactorRequiredTwoFactorStateEnum.Ready;
        });
        builder.addCase(twoFactorLogin.rejected, (state) => {
            state.isFetching = false;
            state.twoFactorLoginFailed = true;
        });
        builder.addCase(login.pending, (state, action) => {
            state.email = action.meta.arg.email;
            state.password = action.meta.arg.password;
            state.isFetching = true;
        });

        builder.addCase(login.fulfilled, (state) => {
            state.loginFailed = false;
            state.isFetching = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.message.status = action.payload.status;
            state.message.message = { detail: action.payload.statusText, reason: undefined };
            state.isAuthenticated = false;
            state.isFetching = false;
            state.loginFailed = true;
            state.email = undefined;
            state.password = undefined;
        });
        builder.addCase(setOrganization.fulfilled, (state, action) => {
            if (action.payload.type === 'two_factor_required') {
                state.twoFactorSetupRequired = action.payload.two_factor_state === 'setup_required';
            } else {
                state.isAuthenticated = true;
            }
            state.firstLogin = !(action.payload as any).last_login;
        });
        builder.addCase(logout.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.isFetching = false;
            state.authorizations = [];
        });
        builder.addCase(temporaryUserlogout.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.isFetching = false;
            state.authorizations = [];
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.authorizations = [action.payload] as any;
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.isFetching = false;
            state.authorizations = [];
        });
        builder.addCase(requestNewPassword.fulfilled, (state) => {
            state.emailSent = true;
            state.isFetching = false;
        });
    }
});

export const getEmailSentSelector = createSelector(
    [(state: AppState): boolean => state.app.authentication.emailSent],
    (emailSent) => emailSent,
);

export const { stopFetching, clearUserExistData, setRedirectUrl, setIsAuthenticated, setFirstLogin, setAuthType, setTwoFactorStatus, showSelectOrganisation, setTwoFactorSecretQR, setOrganizationId, setOrganizationName, setAuthorizations, setLoginSucceded, setError, showLoggedOutModal } = authenticationSlice.actions;
export default authenticationSlice.reducer;
