import React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import Icon from './Icon';
import Link from './Link';

type Props = {
    className?: string;
    to: string;
};

const BackLink = (props: Props): React.ReactElement => {
    const { className, to } = props;

    return <Link to={to} className={twMerge('flex items-center', className)}>
        <Icon name='chevron_left' className='text-2xl' />
        <FormattedMessage id='globals.back' />
    </Link>
}
export default BackLink;
