import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input, Modal, Radio } from 'semantic-ui-react';
import ControlledProductionUnitSelector from './ControlledProductionUnitSelector';
import { Group, ProductionUnit } from '../../../backend_api/models';
import { NewProductionUnitInputsWithHints } from './NewProductionUnitInputsWithHints';
import { NewProductionUnitParams } from './NewProductionUnitParams';
import { NewProductionUnitInputsWithoutHints } from './NewProductionUnitInputsWithoutHints';
import { useSelectableProductionUnits } from '../slice/productionUnitsSlice';

export function CreateOrSelectProductionUnitModal(props: {
    groupsEnabled: boolean;
    excludeProductionUnitIds?: string[],
    allowExistingProductionUnits: boolean,
    renderOpenButton(): React.ReactElement,
    onProductionUnitIdSelected(productionUnitId: string): void,
    onUserWantedToCreateProductionUnit(params: NewProductionUnitParams): void
    allowCreatingNewProductionUnits: boolean,
}) {
    const [showModal, setShowModal] = React.useState(false)

    function onCancel() {
        setShowModal(false)
    }

    function onOpen() {
        setShowModal(true)
    }

    function onProductionUnitIdSelected(id: string) {
        props.onProductionUnitIdSelected(id);
        setShowModal(false)
    }

    function onUserWantedToCreateProductionUnit(params: NewProductionUnitParams) {
        props.onUserWantedToCreateProductionUnit(params);
        setShowModal(false)
    }

    return (
        <Modal
            open={showModal}
            onOpen={onOpen}
            onClose={onCancel}
            closeIcon={true}
            size='small'
            trigger={props.renderOpenButton()}
            className='create-or-select-production-unit'
        >
            <ModalContentAndActions
                allowCreatingNewProductionUnits={props.allowCreatingNewProductionUnits}
                groupsEnabled={props.groupsEnabled}
                excludedProductionUnitIds={props.excludeProductionUnitIds}
                allowExistingProductionUnits={props.allowExistingProductionUnits}
                onUserWantedToCreateProductionUnit={onUserWantedToCreateProductionUnit}
                onProductionUnitIdSelected={onProductionUnitIdSelected}
                onCancel={onCancel} />
        </Modal>
    )
}

function CreateModalHeader() {
    return (
        <Modal.Header>
            <FormattedMessage id='production_unit.create_production_unit' />
        </Modal.Header>
    );
}

function AddModalHeader() {
    return (
        <Modal.Header>
            <FormattedMessage id='production_unit.add_production_unit' />
        </Modal.Header>
    );
}

function removeExcludedProductionUnits(productionUnits: ProductionUnit[], excludedIds: string[]) {
    const m = {}
    excludedIds.forEach(id => m[id] = true)
    const result = productionUnits.filter(pu => !m[pu.id])
    return result
}

function ModalContentAndActions(props: {
    groupsEnabled: boolean,
    excludedProductionUnitIds?: string[],
    allowExistingProductionUnits: boolean,
    allowCreatingNewProductionUnits: boolean,
    onCancel(): void,
    onProductionUnitIdSelected(productionUnitId: string): void
    onUserWantedToCreateProductionUnit(params: NewProductionUnitParams): void
}) {
    const productionUnits = useSelectableProductionUnits()
    const loading = productionUnits == 'loading';
    const filteredProductionUnits = loading ? [] : removeExcludedProductionUnits(productionUnits, props.excludedProductionUnitIds || [])
    const [useExistingProductionUnit, setUseExistingProductionUnit] = React.useState(true)

    const [selectedProductionUnitId, setSelectedProductionUnitId] = React.useState<string>()
    const [productionUnitNameInput, setProductionUnitNameInput] = React.useState('');
    const [productionUnitNumberInput, setProductionUnitNumberInput] = React.useState('');
    const [groupsInput, setGroupsInput] = React.useState<Group[]>([])

    useEffect(function () {
        if (!props.allowExistingProductionUnits) {
            setUseExistingProductionUnit(false)
            return;
        }
        if (!props.allowCreatingNewProductionUnits) {
            setUseExistingProductionUnit(true)
            return;
        }
    }, [props.allowExistingProductionUnits, props.allowCreatingNewProductionUnits])

    function reset() {
        if (props.allowCreatingNewProductionUnits) {
            setUseExistingProductionUnit(true);
        } else {
            setUseExistingProductionUnit(false);
        }
        setSelectedProductionUnitId(null);
        setProductionUnitNameInput('');
        setProductionUnitNumberInput('');
        setGroupsInput([])
    }

    function onExistingProductionUnitIdSelected(id: string) {
        setUseExistingProductionUnit(true)
        setSelectedProductionUnitId(id)
    }

    function onUserWantsToCreateProductionUnit() {
        props.onUserWantedToCreateProductionUnit({
            name: productionUnitNameInput,
            number: productionUnitNumberInput,
            groups: groupsInput
        });
        reset();
    }

    function onUseSelectedProductionUnit() {
        props.onProductionUnitIdSelected(selectedProductionUnitId);
        reset();
    }

    function renderCancelButton() {
        return (
            <Button onClick={props.onCancel}>
                <FormattedMessage id='globals.cancel' />
            </Button>
        );
    }

    function renderToggle() {
        if (props.allowExistingProductionUnits && props.allowCreatingNewProductionUnits) {
            return <CreateOrSelectSwitch
                setUseExistingProductionUnit={setUseExistingProductionUnit}
                useExistingProductionUnit={useExistingProductionUnit}
            />
        } else {
            return null
        }
    }

    function renderNewProductionUnitInputs() {
        if (props.allowExistingProductionUnits) {
            return (
                <NewProductionUnitInputsWithHints
                    groupsEnabled={props.groupsEnabled}
                    onGroupsChanged={setGroupsInput}
                    onExistingProductionUnitIdSelected={onExistingProductionUnitIdSelected}
                    onProductionUnitNameChanged={setProductionUnitNameInput}
                    onProductionUnitNumberChanged={setProductionUnitNumberInput}
                    groupsInput={groupsInput}
                    productionUnitNameInput={productionUnitNameInput}
                    productionUnitNumberInput={productionUnitNumberInput}
                    // Do not send filteredProductionUnits in here. We want to hint which PUs exist. FilteredPUs also exist!
                    selectableProductionUnits={loading ? [] : productionUnits}
                />
            )
        } else {
            return (
                <NewProductionUnitInputsWithoutHints
                    groupsEnabled={props.groupsEnabled}
                    onGroupsChanged={setGroupsInput}
                    groupsInput={groupsInput}
                    onProductionUnitNameChanged={setProductionUnitNameInput}
                    onProductionUnitNumberChanged={setProductionUnitNumberInput}
                    productionUnitNameInput={productionUnitNameInput}
                    productionUnitNumberInput={productionUnitNumberInput}
                />
            )
        }
    }

    return (
        useExistingProductionUnit ? (
            <>
                <AddModalHeader />
                <Modal.Content>
                    {renderToggle()}
                    <ChooseExistingProductionUnit
                        loading={loading}
                        onProductionUnitIdSelected={onExistingProductionUnitIdSelected}
                        onUserWantsToCreateNewProductionUnit={() => {
                            setUseExistingProductionUnit(false)
                        }}
                        productionUnits={filteredProductionUnits}
                        selectedProductionUnitId={selectedProductionUnitId}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive
                        disabled={!selectedProductionUnitId}
                        onClick={onUseSelectedProductionUnit}>
                        <FormattedMessage id='globals.ok' />
                    </Button>
                    {renderCancelButton()}
                </Modal.Actions>
            </>
        ) : (
            <>
                <CreateModalHeader />
                <Modal.Content>
                    {renderToggle()}
                    {renderNewProductionUnitInputs()}
                </Modal.Content>
                <Modal.Actions>
                    <Button positive
                        disabled={!validNewName(productionUnitNameInput)}
                        onClick={onUserWantsToCreateProductionUnit}>
                        <FormattedMessage id='production_unit.create_production_unit' />
                    </Button>
                    {renderCancelButton()}
                </Modal.Actions>
            </>
        )
    )
}

function validNewName(name: string): boolean {
    return name && name.length > 0;
}

function ChooseExistingProductionUnit(props: {
    loading: boolean,
    productionUnits: ProductionUnit[],
    selectedProductionUnitId: string,
    onUserWantsToCreateNewProductionUnit(): void,
    onProductionUnitIdSelected(productionUnitId: string): void
}) {

    const selectedProductionUnitIdIsInProductionUnits =
        React.useMemo(function calculateIfSelectedProductionIdIsSelectable() {
            return (
                props.selectedProductionUnitId &&
                !props.productionUnits.find((pu) => pu.id === props.selectedProductionUnitId)
            );
        }, [props.selectedProductionUnitId, props.productionUnits]);

    if (selectedProductionUnitIdIsInProductionUnits) {
        return (
            <Form>
                <Form.Field>
                    <span><FormattedMessage id="production_units.selection.already_added" /></span>
                    <br></br>
                    <a className="link"
                        onClick={() => props.onProductionUnitIdSelected(null)} >
                        <FormattedMessage id="production_units.selection.choose_another" />
                    </a>
                </Form.Field>
            </Form>
        )
    }

    return (
        <Form>
            <Form.Field>
                <label><FormattedMessage id='production_units.select_production_unit' /></label>
                <div className={'flex w-full'}>
                    <ControlledProductionUnitSelector
                        loading={props.loading}
                        disableUnassignedOption={true}
                        dataTestId='select-existing-production-unit'
                        productionUnits={props.productionUnits}
                        productionUnitId={props.selectedProductionUnitId}
                        onProductionUnitIdSelected={props.onProductionUnitIdSelected}
                        onUserWantedToCreateNewProductionUnit={props.onUserWantsToCreateNewProductionUnit}
                    />
                </div>
            </Form.Field>
        </Form>
    )
}

export function InputWithoutHints(
    props: {
        input: string,
        required?: boolean,
        onInputChanged(input: string): void,
        renderTitle(): React.ReactElement
    }
) {
    return (
        <Form.Field width={16} inline required={props.required}>
            {props.renderTitle()}
            <Input
                autoFocus={true}
                autoComplete='off'
                type='text'
                value={props.input}
                onChange={(_e, i) => { props.onInputChanged(i.value) }} />
        </Form.Field>
    )
}

function CreateOrSelectSwitch(props: {
    useExistingProductionUnit: boolean,
    setUseExistingProductionUnit(value: boolean): void
}) {
    function toggle() {
        props.setUseExistingProductionUnit(!props.useExistingProductionUnit)
    }

    const intl = useIntl()

    return (
        <Form className='pb-4'>
            <Form.Field>
                <Radio
                    label={intl.formatMessage({ id: 'production_units.use_existing' })}
                    checked={props.useExistingProductionUnit}
                    onChange={toggle}
                />
            </Form.Field>
            <Form.Field>
                <Radio
                    label={intl.formatMessage({ id: 'production_units.create_new' })}
                    checked={!props.useExistingProductionUnit}
                    onChange={toggle}
                />
            </Form.Field>
        </Form>
    )
}