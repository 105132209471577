import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Table } from 'semantic-ui-react';
import { Attachment as AttachmentType } from '../types';
import { calculatePrettySize } from './handlers';

type AttachmentsProps = {
    disabled?: boolean;
    showLink: boolean;
    inline?: boolean;
    showDelete?: boolean;
    attachment: AttachmentType;
    removeAttachment?(url: string);
}
const Attachment = (props: AttachmentsProps): React.ReactElement => {
    const { inline = false, showDelete = true, attachment, showLink = true } = props;
    const name = attachment.name;
    return (<Table.Row className={['attachment', attachment.deleting ? 'deleting' : null].join(' ')}>
        <Table.Cell className='fileName'>
            {showLink && <a title={name} className='link' href={attachment.url}>{name}</a>}
            {!showLink && <span>{name}</span>}
        </Table.Cell>
        <Table.Cell className='fileSize' >
            {calculatePrettySize(attachment.size)}
        </Table.Cell>
        {showDelete && <Table.Cell className='delete flex justify-end'>
            {!props.disabled &&
                <Button size='mini' compact disabled={attachment.deleting}
                    onClick={(): void => props.removeAttachment(attachment.url)}>
                    {!inline && attachment.deleting && <FormattedMessage id='attachments.list.deleting' />}
                    {!inline && !attachment.deleting && <FormattedMessage id='attachments.list.delete' />}
                    {inline && 'x'}</Button>}
        </Table.Cell>}
    </Table.Row>);
};

export default Attachment;
