/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCheckpointMultipleChoiceOption } from './AuditCheckpointMultipleChoiceOption';

/**
 *
 *
 */
export type AuditCheckpointMultipleChoice = {
    choices: AuditCheckpointMultipleChoice.choices;
    options: Array<AuditCheckpointMultipleChoiceOption>;
    version: number;
};

export namespace AuditCheckpointMultipleChoice {

    export enum choices {
        ANY = 'any',
        ONE = 'one',
    }


}

