import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import DetailedProductionUnitComponent from '../components/DetailedProductionUnit';
import { ProductionUnitList } from '../components/ProductionUnitList';

type Props = {
    className?: string;
};

const ProductionUnitsContainer = (props: Props): React.ReactElement => {
    const { className } = props;
    return <div className={twMerge('', className)}>
        <Switch>
            <Route exact path='/production_units/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <DetailedProductionUnitComponent id={props.match.params['id']} />;
            }} />
            <Route path='/production_units' render={(): React.ReactElement => {
                return <ProductionUnitList />;
            }} />
        </Switch >
    </div >
}
export default ProductionUnitsContainer;