import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dimmer, Loader } from 'semantic-ui-react';

type QLoaderProps = {
    isLoading: boolean;
    loadingMessageKey: string;
};

export const QLoader = (props: QLoaderProps): React.ReactElement => {
    return <Dimmer active={props.isLoading} inverted>
        <Loader className='dimLoader'><FormattedMessage id={props.loadingMessageKey} /></Loader>
    </Dimmer>;
};

export default QLoader;
