import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import Icon from '../../../base/components/basic/Icon';
import Segment from '../../../base/components/basic/Segment';
import { twMerge } from '../../../base/utils';
import { getTwoFactorSecretQR } from '../selectors';
import TwoFactorCodeInput from './TwoFactorCodeInput';
import TwoFactorInputLoader from './TwoFactorInputLoader';
import TwoFactorInputMessage from './TwoFactorInputMessage';


type Props = {
    className?: string;
    organisationName: string;
    handleTokenDone(token: string): void;
    loginFailed: boolean;
    loginSucceded: boolean;
    isFetching: boolean;

};

const TwoFactorSetup = (props: Props): React.ReactElement => {
    const { isFetching, loginFailed, loginSucceded, organisationName } = props;
    const qr = useSelector(getTwoFactorSecretQR);
    const qrCode = 'data:image/png;base64, ' + qr;
    useEffect(() => {
        document.location.href = '#top';
    }, []);
    return <div className='flex justify-center mx-0 md:mx-0 md:mb-32' id='top'>
        <Segment className='flex w-full sm:w-136 flex-col space-y-6 md:pb-4 pb-4'
            header={organisationName}
            heading={<FormattedMessage id='twoFactor.setup.headline' />}
            footer={<Link to='/login' onClick={() => document.location.reload()} className='link noUnderline flex'><FormattedMessage id='globals.cancel' /></Link>}
        >

            <div className='flex flex-col sm:flex-row-reverse'>
                <div>
                    <p><FormattedMessage id='twoFactor.setup.intro' /></p>
                    <List ordered>
                        <List.Item><FormattedMessage id='twoFactor.setup.step1' /></List.Item>
                        <List.Item><FormattedMessage id='twoFactor.setup.step2' /></List.Item>
                        <List.Item><FormattedMessage id='twoFactor.setup.step3' /></List.Item>
                    </List>
                </div>
                <div className={twMerge('justify-center flex mr-8 pt-8 sm:pt-0', !qr && 'border rounded w-1/2 items-center justify-center flex-0')}>
                    {qr && <img className='w-1/2 sm:w-full' src={qrCode} />}
                    {!qr && <div className='flex flex-col'>
                        <Icon name='qr_code' className='text-3xl text-brand animate-pulse'/>
                        <div className='text-xs pt-2'>Loading QR code</div>
                    </div>}
                </div>
            </div>
            <div className='flex border-t pt-4 -mx-6 px-6 items-center justify-center flex-col'>
                <h3 className='justify-center flex'><FormattedMessage id='twoFactor.enterCode.headline' /></h3>
                <TwoFactorCodeInput handleTokenDone={props.handleTokenDone} clear={loginFailed} />
                <TwoFactorInputLoader isFetching={isFetching} />
                <TwoFactorInputMessage loginFailed={loginFailed} loginSucceded={loginSucceded} />
                <p className='text-secondary text-sm'><FormattedMessage id='twoFactor.enterCode.text.contact_text' /></p>
            </div>
        </Segment>
    </div >
}
export default TwoFactorSetup;
