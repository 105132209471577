import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { CorrectiveAction } from '../../../backend_api/models';
import TranslatedLabel from '../../globals/components/TranslatedLabel';
import { ListView, ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { CorrectiveActionRelation } from '../types';
import CorrectiveActionStatus from './CorrectiveActionStatus';

type Props = {
    correctiveActions: CorrectiveAction[];
    relation?: CorrectiveActionRelation;
    className?: string;
}

const CorrectiveActionCompactList = (props: Props) => {
    const { relation = CorrectiveActionRelation.Audit, className } = props;
    const history = useHistory();
    const openDetailedPage = (action: CorrectiveAction) => {
        history.push('/ca/details/' + action.id);
    };
    return (
        <ListView >
            {Array.isArray(props.correctiveActions) && props.correctiveActions.length === 0 && <div><FormattedMessage id={relation === 'audit' ? 'corrective_actions.audit_compact_list_no_items' : 'corrective_actions.inspections_compact_list_no_items'} /></div>}
            {Array.isArray(props.correctiveActions) && props.correctiveActions.map((action) => {
                // Please do not remove the caLine class. It is being used by the smoketest
                return (<ListViewLine link={'/ca/details/' + action.id} onClick={() => openDetailedPage(action)} key={action.id} className={twMerge('bg-white cursor-pointer border-b -mx-4 -mt-2 mb-2 caLine',)}> 
                    <ListViewItem className='w-3/4 md:w-2/3 flex-0'>
                        <TranslatedLabel text={action.finding} />
                    </ListViewItem>
                    <ListViewItem className='w-1/4 md:w-1/3 justify-end flex-0'>
                        <CorrectiveActionStatus state={action.state} />
                    </ListViewItem>
                </ListViewLine>)
            })}
        </ListView>
    )
}

export default CorrectiveActionCompactList;