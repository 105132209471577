import { createAction } from 'typesafe-actions';
import { request } from '../../../base/api';
import history from '../../../store/history';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import * as types from '../types';
import { Inspection } from '../types';

const catchException = errorHandling.handler.catchException;
const sendStatusMessage = messages.actions.sendStatusMessage;

export const createCombinedOrderRequest = createAction(types.CREATE_COMBINED_ORDER_SUCCESS, (inspectionIds: [string]) => {
    return {
        type: types.CREATE_COMBINED_ORDER_SUCCESS, payload: {
            inspectionIds,
        },
    };
});

export const createCombinedOrder = (inspectionIds: string[]): any => {
    const body = { inspection_ids: inspectionIds };
    scrollTo(0, 0);
    return ((dispatch) => {
        dispatch(sendStatusMessage(['status_message.the_combined_order_was_created'], 5000));
        return request('inspections/combine', {
            method: 'post',
            body: JSON.stringify(body),
        }).then((data: Inspection) => {
            const orderId = data.sub_inspections[0].master_inspection.order_id;
            history.push('/inspections/edit_inspection/' + orderId);
        }).catch((error) => {
            catchException('createOrderWithInspections', {
                endpoint: 'inspections/combine',
                request: 'inspections/combine',
                status: error.status,
            }, { error, body, inspectionIds, method: 'POST' });
        });
    });
};

export const splitCombinedOrder = (combinedInspectionId: string) => {
    scrollTo(0, 0);
    return ((dispatch) => {
        dispatch(sendStatusMessage(['status_message.the_combined_order_was_split_up'], 5000));
        return request('inspections/' + combinedInspectionId + '/split', {
            method: 'post',
        }).then(() => {
            history.push('/inspections/inspections');
        }).catch((error) => {
            catchException('splitCombinedOrder', {
                endpoint: 'inspections/[combinedInspectionId]/split',
                request: 'inspections/' + combinedInspectionId + '/split',
                status: error.status,
            }, { error, combinedInspectionId, method: 'POST' });
        })
    });
};