import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { AuditsExportAdditionalObjects, AuditStatus } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import { getProfile } from '../../profile/selectors';
import { exportAudits } from '../statisticsSlice';
import DataExportInput from './DataExportInput';

type Props = {
    email?: string
}

const DataExportAudit = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const profile = useSelector(getProfile);

    const getStatusOptions = (): DropdownItemProps[] => {
        const options: DropdownItemProps[] = []
        for (const status in AuditStatus) {
            if (AuditStatus[status] == AuditStatus.Ongoing) {
                options.push({
                    value: AuditStatus[status],
                    key: AuditStatus[status],
                    text: 'In Progress'
                })
            }
            else {
                options.push({
                    value: AuditStatus[status],
                    key: AuditStatus[status],
                    text: status
                })
            }
        }
        return options
    }

    const getExtraOption = (): DropdownItemProps[] => {
        const options = []
        for (const object in AuditsExportAdditionalObjects) {
            options.push({
                value: AuditsExportAdditionalObjects[object],
                key: AuditsExportAdditionalObjects[object],
                text: object
            })
        }
        return options
    }

    const exportStats = (inputParams): void => {
        const params = {
            ...inputParams,
            language: profile.locale.language,
            notify_to_email: getEmail(),
        }
        dispatch(exportAudits(params));
    }

    const getEmail = () => {
        return props.email ? props.email : profile.email
    }

    return <DataExportInput
        statusOptions={getStatusOptions()}
        extraOptions={getExtraOption()}
        exportInProgressText={intl.formatMessage({ id: 'statistics.audits_exporting' }, { email: getEmail() })}
        export={exportStats}
    />;
}
export default DataExportAudit;