import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request2 } from '../../../base/api';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage } from '../../messages/actions';
import { DashboardState } from '../types';

const initialState: DashboardState = {
    defectTypeOverview: undefined,
    inspectionsStatusByType: undefined,
    inspectionsStatusByConclusion: undefined,
    inspectionsStatusBySupplierQC: undefined,
    defectsStatusBySeverity: undefined,
    isLoading: false,
    isFetching: undefined,
};

export const getDashboardDefectImages = createAsyncThunk<any, { defectTypeId: string, filterString?: string }>(
    'getDashboardDefectImages',
    async (params, { dispatch, rejectWithValue }) => {
        let url = 'statistics/defects_by_category/' + params.defectTypeId + '?limit=100';
        if (params.filterString) {
            url += '&' + params.filterString;
        }
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('Getting dashboard defect images did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.dashboard_defect_images_failed_to_load'], 3000));
            catchException('getDashboardDefectImages', {
                endpoint: 'statistics/defects_by_category/:defectTypeId',
                request: 'statistics/defects_by_category/' + params.defectTypeId,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json() as any;
    });

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDashboardDefectImages.fulfilled, (state, action) => {
            state.defectTypeOverview = action.payload;
            state.isLoading = false;
        });
        builder.addCase(getDashboardDefectImages.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardDefectImages.rejected, (state) => {
            state.isLoading = false;
        });
    }
});


const { actions } = dashboardSlice;
export default dashboardSlice.reducer;
