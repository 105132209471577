import { Dispatch } from 'redux';
import { request } from '../../../base/api';
import { AppState, RequestError } from '../../../base/types';
import { getInspectionsMap } from '../../inspections/selectors/selectors';
import { Inspection } from '../../inspections/types';
import { Checklist } from '../types';
import { Action } from './../../../base/genericActions';
import { GET_CHECKLISTS_SELECTOR, GET_CHECKLISTS_SELECTOR_FAILURE, GET_CHECKLISTS_SELECTOR_SUCCESS } from './../actionTypes';

export const getChecklistsSelector = () => {
    return (dispatch: Dispatch): Promise<void | Checklist[]> => {
        dispatch(getChecklistsSelectorRequest());
        return request('checklists', {})
            .then((data: Checklist[]) => {
                dispatch(getChecklistsSelectorRequestSuccess(data));
                return data;
            })
            .catch((error: RequestError) => {
                dispatch(getChecklistsSelectorRequestFailure(error.status, error.errorText || 'statusText: n/a'));
            });
    };
};

export const getInspectionsChecklistsSelector = (inspectionId?: string) => {
    return (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
        const inspection: Inspection = getInspectionsMap(getState())[inspectionId];
        dispatch(getChecklistsSelectorRequest());
        return request('checklists', {})
            .then((data: Checklist[]) => {
                if (inspectionId) {
                    const modifiedObj = { description: inspection.checklist_name, id: '-1' } as any;
                    data.push(modifiedObj);
                }
                dispatch(getChecklistsSelectorRequestSuccess(data, inspectionId));
            })
            .catch((error) => {
                dispatch(getChecklistsSelectorRequestFailure(error.status, error.json.statusText || 'statusText: n/a'));
            });
    };
};

// Initial action when requesting checklists
const getChecklistsSelectorRequest = (): Action<GET_CHECKLISTS_SELECTOR> => {
    return {
        type: GET_CHECKLISTS_SELECTOR,
        payload: {
            isFetching: true,
        },
    };
};

// Action when requesting checklists was a success
export const getChecklistsSelectorRequestSuccess = (data: Checklist[], inspectionId?: string): Action<GET_CHECKLISTS_SELECTOR_SUCCESS> => {
    return {
        type: GET_CHECKLISTS_SELECTOR_SUCCESS,
        payload: {
            isFetching: true,
            data,
            inspectionId,
        },
    };
};

// Action when requesting checklists failed
const getChecklistsSelectorRequestFailure = (status: number, message: string): Action<GET_CHECKLISTS_SELECTOR_FAILURE> => {
    return {
        type: GET_CHECKLISTS_SELECTOR_FAILURE,
        payload: {
            isFetching: true,
            status,
            message,
        },
    };
};
