import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { AppState } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { getSplitShipments, getSplitShipmentsSelector } from '../../slices/splitShipmentsSlice';
import { Inspection } from '../../types';
import DashboardPopup from '../inspectionTypes/DashboardPopup';
import SplitShipmentsMarker from './SplitShipmentsMarker';

type SplitShipmentsInfoProps = {
    inspection: Inspection;
    className?: string;
    selectedInspectionId?: string;
    inline?: boolean;
    extraInfo?: React.ReactElement;
    showLoader?: boolean;
};

const SplitShipmentsInfo = (props: SplitShipmentsInfoProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { inspection, inline = false, extraInfo, showLoader = false } = props;
    const trigger = <div><SplitShipmentsMarker inspection={inspection} inline={false} onlyLabel={true} /></div>;
    const inspections = useSelector((state: AppState) => getSplitShipmentsSelector(state, props.inspection.inspection_id))
    let content;
    useEffect(() => {
        if (props.inspection && !inspections) {
            dispatch(getSplitShipments(props.inspection.inspection_id));
        }
    }, [props.inspection])
    if (inspections) {
        content = inspection._inspection_group_id && <DashboardPopup
            selectedInspectionId={inspection.inspection_id}
            inline={inline}
            trigger={trigger}
            inspections={inspections}
            header={<div>{intl.formatMessage({ id: 'inspections.split_shipment.info.header' })}</div>}
            subHeader={<div className='pb-4'>{extraInfo ? extraInfo : <SplitShipmentsMarker inspection={inspection} inline={true} returnText={true} />}</div>} />
        return <div className='pb-8 w-full'>{content}</div>;
    }
    return inspections ? content : showLoader && <div className='w-96 h-24'>
        <Dimmer active={true} inverted>
            <Loader >{intl.formatMessage({ id: 'globals.loading_data' })}</Loader>
        </Dimmer></div>;
}
export default SplitShipmentsInfo;
