import React from 'react';
import { useIntl } from 'react-intl';
import { OrderListInspectionExtended } from '../../../../slices/inspectionsListSlice';
import { Inspection } from '../../../../types';
import { getOrderDate } from '../../../../Utils';

type OrderDateProps = {
    inspections: (Inspection | OrderListInspectionExtended)[];
    isPlanned: boolean;
};

const OrderDate = (props: OrderDateProps): React.ReactElement => {
    const intl = useIntl();
    return <div>{getOrderDate(props.inspections, props.isPlanned, intl.locale)}</div>;
};

export default OrderDate;
