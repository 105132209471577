import React, { Fragment, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, InputProps } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../store';
import { Attachments } from '../../../attachments/containers';
import { Checklist } from '../../../checklists/types';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { LineTogglerSmall } from '../../../globals/components/Utils';
import { AttachmentResponse } from '../../../Images/actions/actions';
import FileGallery from '../../../Images/components/FileGallery';
import { updateSingleInspection as updateInspection } from '../../actions';
import { handleBlurredInspection, handleChange as handleInspectionChange } from '../../actions/editInspectionActions';
import { Inspection } from '../../types';
import { inspectionHasCustomValues, isInspectionPartOfCombinedInspection } from '../../Utils';
import { CustomFields } from './CustomFields';
import InfoLine from './InfoLine';


type ILProps = {
    inspection: Inspection;
    checklists: Checklist[];
    isSaving?: boolean;
    disabled: boolean;
    hasInspectionTypes: boolean;
    isCombinedInspection: boolean;
    isLastRemaining: boolean;
};

const IL = (props: ILProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleChange = (event: React.SyntheticEvent, item: InputProps, inspectionId?: string): any => {
        dispatch(handleInspectionChange(event, item, inspectionId));
    };
    const updateSingleInspection = (inspectionId: string, data: any): void => {
        dispatch(updateInspection(inspectionId, data));
    };
    const handleBlur = (event: React.SyntheticEvent, item: InputProps, inspectionId: string): any => {
        dispatch(handleBlurredInspection(event, item, inspectionId));
    };

    const getMarkText = (inspection: Inspection): string[] => {
        const inspectionHasCustomValueTxt: string = intl.formatMessage({ id: 'create.the_inspection_has_custom_values_set' });
        const inspectionHasProductPhotoTxt: string = intl.formatMessage({ id: 'create.the_inspection_has_a_product_photo_set' });
        const inspectionHasTxt: string = intl.formatMessage({ id: 'create.the_inspections_has' });
        const firstAttachmentTxt: string = intl.formatMessage({ id: 'create.firstAttachment' });
        const attachmentsTxt: string = intl.formatMessage({ id: 'create.attachments' });
        const text = [];
        if (inspectionHasCustomValues(inspection)) {
            text.push(inspectionHasCustomValueTxt);
        }
        if (inspection.item_image !== null) {
            text.push(inspectionHasProductPhotoTxt);
        }
        if (inspection.attachment_count > 0) { // TODO: Using react-intl's plural functions to format attachment text
            const attText = inspection.attachment_count === 1 ? (firstAttachmentTxt) : (inspection.attachment_count + attachmentsTxt);
            text.push(inspectionHasTxt + attText + '.');
        }
        return text;
    }

    const { inspection, checklists, isSaving = false, disabled = false, hasInspectionTypes = false, isLastRemaining = false, isCombinedInspection = false } = props;
    const [open, setOpen] = useState(false);
    const renderInspection = useMemo(() => {
        const inspectionId = inspection.inspection_id;
        const lineToggler = <LineTogglerSmall
            data={{ open }}
            id={inspectionId}
            toggle={(): void => setOpen(!open)}
            mark={inspectionHasCustomValues(inspection) || inspection.attachment_count > 0 || inspection.item_image !== null}
            markText={getMarkText(inspection)}
        />;
        return (<Fragment key={'inspection_' + inspection.inspection_id}>
            <HasFeatureAccess type='inspection' feature='manage_attachments' showDisabled={true} inspection={inspection} showDialog={false}>
                <Attachments
                    attachToType='inspections'
                    attachToItemId={inspection.inspection_id}
                    visible={open}
                    toggle={(setOpen)}
                    disabled={inspection.locked || inspection.status !== 'planned' || isInspectionPartOfCombinedInspection(inspection)}
                    fileCountChanged={(number): void => {
                        handleChange(undefined, { name: 'attachment_count', value: number }, inspection.inspection_id)
                    }}
                >
                    <InfoLine
                        inspection={inspection}
                        checklists={checklists}
                        isCombinedInspection={isCombinedInspection}
                        hasInspectionTypes={hasInspectionTypes}
                        isSaving={isSaving}
                        isLastRemaining={isLastRemaining}
                        disabled={disabled}
                        intl={intl}
                        lineToggler={lineToggler}
                    />
                </Attachments>
            </HasFeatureAccess>
            <Grid className={open ? 'show' : 'hide'}>
                <Grid.Column width={8} verticalAlign='top'>
                    <HasFeatureAccess type='inspection' showDisabled={true} feature='modify_custom_fields' inspection={inspection}>
                        <CustomFields
                            customFields={inspection.custom_field_values}
                            inspectionId={inspection.inspection_id}
                            handleBlur={handleBlur}
                            visible={open}
                            disabled={inspection.locked || inspection.status !== 'planned' || isInspectionPartOfCombinedInspection(inspection)}
                        />
                    </HasFeatureAccess>
                </Grid.Column>
                <Grid.Column width={5} verticalAlign='top'>
                    <FileGallery
                        images={inspection.item_image ? [inspection.item_image] : []}
                        allowMultiUpload={false}
                        disabled={inspection.locked || inspection.status !== 'planned' || isInspectionPartOfCombinedInspection(inspection)}
                        header={'edit.product_photo'}
                        fileSelectorLableText={intl.formatMessage({ id: 'attachments.upload.upload_photo' })}
                        imagesAdded={(images: AttachmentResponse[]): void => updateSingleInspection(inspection.inspection_id, { item_image: images[0].url })}
                        imageRemoved={(): void => updateSingleInspection(inspection.inspection_id, { item_image: null })}
                    />
                </Grid.Column>
            </Grid>
        </Fragment>);
    }, [inspection.inspection_id, inspection.item_image, inspection.bypass_sampling_protocol, inspection.sample_quantity, inspection.sampling_protocol_id, inspection.item_number, inspection.total_quantity, inspection.calculated_sample_quantity, inspection.locked, open, isLastRemaining, checklists, inspection.assigned_user_id]);
    return renderInspection;
}
export default IL;
