import React from 'react';
import { useFormatMessage } from '../../../base/utils';
import UsersSelector from './UsersSelector';

type InspectorSelectorProps = {
    id: string;
    done?: boolean;
    disabled?: boolean;
    showEmailInSelector?: boolean;
    showUnassigned?: boolean;
    selectedInspector?: string;
    disabledUserLabel?: string;
    handleChange(item: { name: string; value: any }): void;
    handleBlur?(selectedItems: string[]): void;
    fixedWidth?: boolean;
    showExpanded?: boolean;
};
const InspectorSelector = (props: InspectorSelectorProps): React.ReactElement => {
    const { showExpanded = true, showUnassigned = true, disabled = false, disabledUserLabel } = props;
    const selectInspectorLabel = useFormatMessage('inspections.select_inspector');
    return (
        <UsersSelector
            fixedWidth={props.fixedWidth}
            handleChange={(inspectorId): void => {
                props.handleChange({ name: 'assigned_user_id', value: inspectorId.value })
            }}
            onDeactive={props.handleBlur}
            value={props.selectedInspector && [props.selectedInspector] || []}
            allowMultipleSelection={false}
            placeholder={selectInspectorLabel}
            withIcon={true}
            showEmailInSelector={true}
            showExpanded={showExpanded}
            dataTestId='dropdown-select-inspector'
            name='assigned_user_id'
            showUnassigned={showUnassigned}
            disabled={disabled}
            disabledUserLabel={disabledUserLabel}
        />
    );
};

export default React.memo(InspectorSelector);
