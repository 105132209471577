import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { DetailedSupplier, SupplierFeaturesDto } from '../../../backend_api/models';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ListButton from '../../globals/components/ListButton';
import { InfoIcon } from '../../globals/components/Utils';
import { deleteSupplierRequest } from '../actions';
import DeleteSupplier from './DeleteSupplier';
import history from '../../../store/history';
import { CreateSupplierModal } from './CreateSupplierModal';
import { useSuppliers } from '../../productionUnits/hooks/useSuppliers';

type SupplierListProps = {
    deleteSupplierRequest: typeof deleteSupplierRequest;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SupplierList = (props: SupplierListProps): React.ReactElement => {
    const suppliersLoading = useSuppliers()
    const loading = suppliersLoading === 'loading';

    const suppliers = loading ? [] : suppliersLoading;

    const hasSuppliers = true;
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    /* 
        We use display: none, instead of removing the component from the react tree. 
        Otherwise, all useEffect calls inside will retrigger, loading suppliers again
    */
    function renderCreateSupplierButton(visible: boolean) {
        return (
            <div style={visible ? {} : { display: 'none' }}>
                <HasFeatureAccess feature='u_add_suppliers'>
                    <CreateSupplierModal
                        onExistingSupplierIdSelected={(id: string) => history.push(`/suppliers/${id}`)}
                        renderButton={() => <ListButton><FormattedMessage id='suppliers.add_supplier' /></ListButton>} />
                </HasFeatureAccess>
            </div>
        );
    }

    return <span className='suppliers'>
        <div className='listMenu'>
            <Header as='h2'><FormattedMessage id='test.suppliers.suppliers' /></Header>
            {renderCreateSupplierButton(true)}
            {/* {!hasSuppliers && <Segment raised container><InfoIcon text={intl.formatMessage({ id: 'suppliers.no_suppliers_found' })} />
                {createSupplierButton}
            </Segment>} */}
        </div>
        {hasSuppliers && <Fragment><Grid className='listHeader'>
            <Grid.Row columns={16}>
                <Grid.Column width={1} className='header'></Grid.Column>
                <Grid.Column width={4} className='header'><FormattedMessage id='suppliers.supplier_number' /></Grid.Column>
                <Grid.Column width={11} className='header'><FormattedMessage id='suppliers.supplier_name' /></Grid.Column>
            </Grid.Row>
        </Grid>
            <Segment raised container>
                <Grid className='listContent'>
                    {suppliers.map((supplier, i: number) => {
                        return (
                            <Grid.Row key={`supplier_${i}`} className='lineHeader' as={Link} to={`/suppliers/${supplier.id}`}>
                                <Grid.Column width={1} className='header'></Grid.Column>
                                <Grid.Column width={4}>{supplier.number}</Grid.Column>
                                <Grid.Column width={9}>{supplier.name || formatMessage({ id: 'suppliers.list_unknown_name' })}</Grid.Column>
                                <Grid.Column width={2}>
                                    <HasFeatureAccess feature={SupplierFeaturesDto.Delete} type='supplier' supplier={supplier}><DeleteSupplier supplierId={supplier.id} ><span className='link'><FormattedMessage id='suppliers.remove_supplier' /></span></DeleteSupplier></HasFeatureAccess>
                                </Grid.Column>
                            </Grid.Row>);
                    })}
                </Grid>
            </Segment>
            {renderCreateSupplierButton(suppliers.length > 10)}
        </Fragment>}
    </span>;
};

export default SupplierList;
