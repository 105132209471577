import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'semantic-ui-react';
import { AppState, AppThunk } from '../../../base/types';
import { deleteSupplier, deleteSupplierRequest } from '../actions';
import { getConfirmDeleteSupplierSelector } from '../selectors';

type OwnProps = {
    supplierId: string;
    children?: React.ReactNode;
};

type StateProps = {
    confirmDeleteSupplier: { confirm: boolean; supplierId?: string };
};

type DispatchProps = {
    actions: {
        deleteSupplierRequest: typeof deleteSupplierRequest;
        deleteSupplier: typeof deleteSupplier;
    };
};

const initialState = {};

type State = Readonly<typeof initialState>;

type DeleteSupplierProps = OwnProps & StateProps & DispatchProps;

class DeleteSupplier extends Component<DeleteSupplierProps, any> {
    readonly state: State = initialState;
    render(): React.ReactElement {
        return (
            <Modal
                open={this.props.confirmDeleteSupplier.confirm && this.props.confirmDeleteSupplier.supplierId === this.props.supplierId}
                closeIcon={true}
                size='small'
                className='deleteSupplierDialog'
                trigger={this.getTrigger()}
            >
                <Modal.Header><FormattedMessage id='suppliers.remove_supplier_header' /></Modal.Header>
                <Modal.Content>
                    <FormattedMessage id='suppliers.remove_supplier_confirm' />
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={(): AppThunk<void> => this.props.actions.deleteSupplier()}><FormattedMessage id='globals.ok' /></Button>
                    <Button onClick={(): AppThunk<void> => this.props.actions.deleteSupplierRequest(false)}><FormattedMessage id='globals.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }

    getTrigger(): React.ReactElement {
        if (this.props.children) {
            return <span onClick={(evt): void => {
                evt.preventDefault();
                this.props.actions.deleteSupplierRequest(true, this.props.supplierId);
            }}>{this.props.children}</span>;
        }
        return <Button className='deleteSupplier' onClick={(evt): void => {
            evt.preventDefault();
            this.props.actions.deleteSupplierRequest(true, this.props.supplierId);
        }}>
            <FormattedMessage id='suppliers.remove_supplier' />
        </Button>;
    }
}

export const mapStateToProps = (state: AppState): StateProps => {
    return {
        confirmDeleteSupplier: getConfirmDeleteSupplierSelector(state),
    };
};

export const mapDispatchToProps = (dispatch): DispatchProps => {
    const actions = bindActionCreators({
        deleteSupplierRequest,
        deleteSupplier,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(DeleteSupplier);
