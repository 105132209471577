/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 96.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * EntityRemovedFromField history event
 * @export
 * @interface HistoryEventEntityRemovedFromField
 */
export interface HistoryEventEntityRemovedFromField {
    /**
     * 
     * @type {string}
     * @memberof HistoryEventEntityRemovedFromField
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEventEntityRemovedFromField
     */
    entity_name: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEventEntityRemovedFromField
     */
    entity_number: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEventEntityRemovedFromField
     */
    entity_type: HistoryEventEntityRemovedFromFieldEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoryEventEntityRemovedFromField
     */
    field: HistoryEventEntityRemovedFromFieldFieldEnum;
}

/**
* @export
* @enum {string}
*/
export enum HistoryEventEntityRemovedFromFieldEntityTypeEnum {
    Supplier = 'supplier',
    ProductionUnit = 'production_unit'
}/**
* @export
* @enum {string}
*/
export enum HistoryEventEntityRemovedFromFieldFieldEnum {
    RelatedSuppliers = 'related_suppliers',
    ProductionUnits = 'production_units',
    UpstreamProductionUnits = 'upstream_production_units',
    DownstreamProductionUnits = 'downstream_production_units'
}

export function HistoryEventEntityRemovedFromFieldFromJSON(json: any): HistoryEventEntityRemovedFromField {
    return HistoryEventEntityRemovedFromFieldFromJSONTyped(json, false);
}

export function HistoryEventEntityRemovedFromFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEventEntityRemovedFromField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'entity_name': json['entity_name'],
        'entity_number': json['entity_number'],
        'entity_type': json['entity_type'],
        'field': json['field'],
    };
}

export function HistoryEventEntityRemovedFromFieldToJSON(value?: HistoryEventEntityRemovedFromField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_name': value.entity_name,
        'entity_number': value.entity_number,
        'entity_type': value.entity_type,
        'field': value.field,
    };
}


