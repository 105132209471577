import { Action } from '../../base/genericActions';
import { CLEAR_ALL_MESSAGES, CLEAR_ERROR_MESSAGE, CLEAR_GLOBAL_MESSAGE, CLEAR_MESSAGE, CLEAR_STATUS_MESSAGE, SEND_ERROR_MESSAGE, SEND_GLOBAL_MESSAGE, SEND_MESSAGE, SEND_STATUS_MESSAGE } from './actionTypes';

export const sendMessage = (text: any[], hideAfterMs?: number, header?: string, type?: string, showIcon?: boolean): Action<SEND_MESSAGE> => {
    return {
        type: SEND_MESSAGE,
        payload: {
            text,
            hideAfterMs,
            header,
            type,
            showIcon,
        },
    };
};

export type DispatchSendStatusMessageFn = (
    text: any[],
    hideAfterMs?: number,
    showErrorMessages?: boolean,
    header?: string,
    type?: string,
    showIcon?: boolean
) => void

export const sendStatusMessage =
    (text: any[], hideAfterMs?: number, showErrorMessages = false, header?: string, type?: string, showIcon?: boolean): Action<SEND_STATUS_MESSAGE> => {
        return {
            type: SEND_STATUS_MESSAGE,
            payload: {
                text,
                hideAfterMs,
                showErrorMessages,
                header,
                type,
                showIcon,
            },
        };
    };

export const sendErrorMessage =
    (text: [any], hideAfterMs?: number, header?: string, type?: string, showIcon?: boolean): Action<SEND_ERROR_MESSAGE> => {
        return {
            type: SEND_ERROR_MESSAGE,
            payload: {
                text,
                hideAfterMs,
                header,
                type,
                showIcon,
            },
        };
    };

export const sendGlobalMessage =
    (text: any[], hideAfterMs?: number, header?: string, type?: string, showIcon?: boolean): Action<SEND_GLOBAL_MESSAGE> => {
        return {
            type: SEND_GLOBAL_MESSAGE,
            payload: {
                text,
                hideAfterMs,
                header,
                type,
                showIcon,
            },
        };
    };

export const clearMessage = (): Action<CLEAR_MESSAGE> => {
    return {
        type: CLEAR_MESSAGE,
        payload: {},
    };
};
export const clearGlobalMessage = (): Action<CLEAR_GLOBAL_MESSAGE> => {
    return {
        type: CLEAR_GLOBAL_MESSAGE,
        payload: {},
    };
};
export const clearStatusMessage = (): Action<CLEAR_GLOBAL_MESSAGE> => {
    return {
        type: CLEAR_STATUS_MESSAGE,
        payload: {},
    };
};

export const clearErrorMessage = (): Action<CLEAR_ERROR_MESSAGE> => {
    return {
        type: CLEAR_ERROR_MESSAGE,
        payload: {},
    };
};

export const clearAllMessages = (): Action<CLEAR_ALL_MESSAGES> => {
    return {
        type: CLEAR_ALL_MESSAGES,
        payload: {},
    };
};
