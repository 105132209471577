import React from 'react';
import { FormattedMessage } from 'react-intl';
import appScreenShot from '../../../../../images/app-screen-shot.jpg';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import PageContainer from '../../../globals/components/PageContainer';

const AuditorCompletedPage = () => {

    return (
        <PageContainer
            className="flex justify-center items-start"
            headerClassName=""
        >
            <div className='flex flex-row'>
                <div className='flex justify-center items-center rounded-full bg-branding-brand' style={{width: '50px', height: '50px'}}>
                    <Icon className='text-white text-2xl' name='check' />
                </div>
                <div className='flex flex-col justify-between mx-4'>
                    <div className='font-bold text-2xl'><FormattedMessage id='web_based_audit.completed.thank_you'/></div>
                    <div className='text-md'><FormattedMessage id='web_based_audit.completed.message'/></div>
                </div>
            </div>
            <div className='border border-gray-200 bg-white rounded flex flex-wrap justify-center items-center sm:justify-start sm:items-start pt-4 px-4 my-9'>
                <img src={appScreenShot} width={200}/>
                <div className='flex flex-col justify-center items-start sm:mx-8 mx-4'>
                    <div className='font-bold'><FormattedMessage id='web_based_audit.completed.slogan'/></div>
                    <div className='my-4 w-full sm:w-2/3'><FormattedMessage id='web_based_audit.completed.message_2'/></div>
                    <Button className='my-6' primary onClick={() => {
                        window.location.href = "https://www.qarmainspect.com/demo";
                    }}><FormattedMessage id='web_based_audit.completed.get_demo'/></Button>
                </div>
            </div>
        </PageContainer>
    );
}

export default AuditorCompletedPage;