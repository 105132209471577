/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 73.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Authorization information.
 * @export
 * @interface AuthenticationAuthorization
 */
export interface AuthenticationAuthorization {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    account_id: string;
    /**
     * 
     * @type {Date}
     * @memberof AuthenticationAuthorization
     */
    last_login: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    organization_id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    organization_logo_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    organization_name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    type: AuthenticationAuthorizationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationAuthorization
     */
    user_id: string;
}

/**
* @export
* @enum {string}
*/
export enum AuthenticationAuthorizationTypeEnum {
    Authorization = 'authorization'
}

export function AuthenticationAuthorizationFromJSON(json: any): AuthenticationAuthorization {
    return AuthenticationAuthorizationFromJSONTyped(json, false);
}

export function AuthenticationAuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationAuthorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': json['account_id'],
        'last_login': (json['last_login'] === null ? null : new Date(json['last_login'])),
        'organization_id': json['organization_id'],
        'organization_logo_url': json['organization_logo_url'],
        'organization_name': json['organization_name'],
        'token': json['token'],
        'type': json['type'],
        'user_id': json['user_id'],
    };
}

export function AuthenticationAuthorizationToJSON(value?: AuthenticationAuthorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'last_login': (value.last_login === null ? null : value.last_login.toISOString()),
        'organization_id': value.organization_id,
        'organization_logo_url': value.organization_logo_url,
        'organization_name': value.organization_name,
        'token': value.token,
        'type': value.type,
        'user_id': value.user_id,
    };
}


