import React, { ReactElement, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form, Modal, TextArea } from 'semantic-ui-react'
import Button from '../../../base/components/basic/Button'
import { useAppDispatch } from '../../../store'
import { approveCorrectiveAction } from '../correctiveActionsSlice'

type Props = {
    id: string;
    open?: boolean;
    trigger?: ReactElement;
    close?();
    approveFunction?(props: any);
}

const ApproveCorrectiveActionDialog = (props: Props) => {
    const dispatch = useAppDispatch();
    const [comment, setComment] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const resolve = () => {
        const data = {
            id: props.id,
            request: {
                comment: comment
            }
        }
        props.approveFunction ? props.approveFunction(data) : dispatch(approveCorrectiveAction(data));
        close();
    }
    const close = () => {
        setComment('');
        setIsOpen(false);
        props.close && props.close();
    }
    return (
        <Modal
            size='tiny'
            trigger={props.trigger}
            onOpen={() => setIsOpen(true)}
            open={props.open || isOpen}
            closeOnEscape={true}
            closeIcon={true}
            onClose={close}
        >
            <Modal.Header>
                {<FormattedMessage id='corrective_actions.approve_modal.header' />}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        {/* <label><FormattedMessage id='corrective_actions.reject_modal.comment' /></label> */}
                        {/* <Input type='text' value={comment} onChange={(evt, item) => setComment(item.value)} /> */}
                        <TextArea onChange={(evt, item) => setComment(item.value as string)} rows={5} placeholder={'Write a comment'}>{comment}</TextArea>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions className='flex justify-end'>
                <Button onClick={resolve} primary data-test-id="confirm-approve-button"><FormattedMessage id='corrective_actions.approve_modal.approve_button' /></Button>
                <Button onClick={close}><FormattedMessage id="globals.confirmation.cancel" /></Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ApproveCorrectiveActionDialog;