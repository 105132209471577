import classnames from 'classnames';
import React, { ReactText } from 'react';
import { Dropdown, Form, Input, Radio, Select, SemanticWIDTHS, TextArea } from 'semantic-ui-react';
import { DropdownValue } from '../types';


interface InputGroupProps {
    key?: string;
    keyId: string;
    elementType: 'select' | 'textarea' | 'input' | 'dropdown' | 'radio';
    elementConfig: any;
    value: DropdownValue | ReactText;
    invalid: boolean;
    disabled?: boolean;
    touched?: boolean;
    options: Array<{ key: string; value: DropdownValue | ReactText; text: string }>;
    shouldValidate: any;
    width?: SemanticWIDTHS;
    changed(ev, { value }: { value: ReactText }): void;
    onClick?(e);
}

const inputGroup = (props: InputGroupProps): React.ReactElement => {
    let inputElement = null;
    const inputClasses = ['InputElement'];
    let arrVal: string[];
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push('Invalid');
    }
    if(props.elementType === 'select') {
        arrVal = props.value as string[];
    }
    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <Form.Field

                    key={props.keyId}
                    width={props.width}
                    control={Input}
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed} />
            );
            break;
        case ('textarea'):
            inputElement = <Form.Field
                disabled={props.disabled}
                key={props.keyId}
                width={props.width}
                control={TextArea}
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />
            break;
        case ('select'):
            inputElement = (                 
                <Form.Field
                    disabled={props.disabled}
                    key={props.keyId}
                    width={props.width}
                    control={Select}
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    placeholder={(arrVal && arrVal.length === 0) ? props.elementConfig.placeholder : ''}
                    value={props.value}
                    options={props.options}
                    onChange={props.changed}
                    onClick={props.onClick && props.onClick} />
            );
            break;
        case ('dropdown'):
            inputElement = (
                <Form.Field
                    disabled={props.disabled}
                    key={props.keyId}
                    width={props.width}
                    control={Dropdown}
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    value={props.value}
                    options={props.options}
                    onChange={props.changed}
                    onClick={props.onClick && props.onClick} />
            );
            break;
        case ('radio'): {
            const radios = props.options && props.options.map((option) => (
                <Radio
                    disabled={props.disabled}
                    key={option.key}
                    width={props.width}
                    className={classnames(inputClasses, 'mr-2')}
                    {...props.elementConfig}
                    label={option.text}
                    value={option.value as ReactText}
                    name={props.keyId}
                    checked={option.value === props.value}
                    onChange={props.changed} />)
            );

            inputElement = (
                <Form.Field key={props.keyId}>
                    {radios}
                </Form.Field>
            );
            break;
        }
    }

    return (
        inputElement
    );
};

export default inputGroup;