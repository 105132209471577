
export const GET_ROLE_REQUEST_SUCCESS = 'GET_ROLE_REQUEST_SUCCESS';
export type GET_ROLE_REQUEST_SUCCESS = {
    userRole: UserRole;
}
export const CREATE_ROLE = 'CREATE_ROLE';
export type CREATE_ROLE = {
    isCreating: boolean;
}

export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export type GET_ROLES_LIST_SUCCESS = {
    rolesList: any[];
}

export type UserRole = {
    id: string;
    name: TranslatedString;
    access_properties: AccessProperty[];
}

export type AccessProperty = {
    name: TranslatedString;
    id: string;
    scopes: Scope[];
    access: Access[];
}

export type Scope = {
    id: string;
    name: TranslatedString;
    description: TranslatedString;
}

export type Access = {
    id: string;
    name: TranslatedString;
    description: TranslatedString;
    values: AccessValue[];
}

export type TranslatedString = {
    [language: string]: string;
}

export type AccessValue = {
    [scope: string]: boolean;
}