import { createSelector } from '@reduxjs/toolkit';
import { Location } from 'history';
import { AppState, AppState as State } from '../../base/types';
import { UserCreds } from './types';

const fetchingSelector = (state: State) => state.app.authentication.isFetching;
const loginFailedSelector = (state: State) => state.app.authentication.loginFailed;
const messageSelector = (state: State) => state.app.authentication.message;
const authenticatedSelector = (state: State) => state.app.authentication.isAuthenticated;
const firstLoginSelector = (state: State) => state.app.authentication.firstLogin;
const authenticationListSelector = (state: AppState) => state.app.authentication.authorizations;
const routerLocationSelector = (state: State, location: Location) => {
    if (location.search) {
        return location.pathname + location.search;
    }
    return location.pathname;
};
const redirectURLSelector = (state: State): string => state.app.authentication.redirectURL;
const userExistsSelector = (state: State): UserCreds => state.app.authentication.user;

export const getLoginFailed = createSelector(
    [loginFailedSelector],
    (failed) => failed,
)

export const getAuthenticationList = createSelector(
    [authenticationListSelector],
    (list) => list,
)

export const getFetching = createSelector(
    [fetchingSelector],
    (isFetching) => isFetching,
);

export const getMessage = createSelector(
    [messageSelector],
    (message) => message,
);

export const getAuthenticated = createSelector(
    [authenticatedSelector],
    (isAuthenticated) => isAuthenticated,
);

export const showLoggedOutModalSelector = createSelector(
    [(state: State) => state.app.authentication.showLoggedOutModal],
    (show) => show,
);
export const getCurrentOrganizationId = createSelector(
    [(state: State) => state.app.authentication.currentOrganizationId],
    (id) => id,
);
export const getTwoFactorSecretQR = createSelector(
    [(state: State) => state.app.authentication.secret_qr_code_base_64],
    (qr) => qr,
);
export const getIsTwoFactorSetupRequiredSelector = createSelector(
    [(state: State) => state.app.authentication.twoFactorSetupRequired],
    (isSetup) => isSetup,
);
export const getResetPasswordInfoSelector = createSelector(
    [(state: State) => {
        const auth = state.app.authentication;
        return {
            resetToken: auth.resetToken,
            status: auth.message.status,
            message: auth.message,
            emailSent: auth.emailSent,            
        }
    }],
    (info) => info,
);
export const getAuthInfoSelector = createSelector(
    [(state: State) => {
        const auth = state.app.authentication;
        return {
            type: auth.type,
            setupRequired: auth.twoFactorSetupRequired,
            status: auth.twoFactorStatus,
            selectOrganisation: auth.selectOrganisation,
            secretQRCode: auth.secret_qr_code_base_64,
            currentOrg: auth.currentOrganizationId,
            currentOrgName: auth.currentOrganizationName,
            accountId: auth.authorizations && auth.authorizations.map((a) => ({ accounts: { [a.account_id]: a.organization_name + ' ' + a.organization_id } })),
            twoFactorLoginFailed: auth.twoFactorLoginFailed,
            loginFailed: auth.loginFailed,
            loginSucceded: auth.loginSucceded,
            isFetching: auth.isFetching,
            twoFactorResetProcedure: auth.twoFactorResetProcedure,
        }
    }],
    (info) => info,
);

export const isFirstLogin = createSelector(
    [firstLoginSelector],
    (lastLogin) => lastLogin,
);

export const getCurrentLocation = createSelector(
    [routerLocationSelector],
    (currentLocation) => currentLocation,
);

export const getRedirectURL = createSelector(
    [redirectURLSelector],
    (redirectURL) => redirectURL,
);

export const getUserExists = createSelector(
    [userExistsSelector],
    (userExists) => userExists,
);