import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { DashboardPopupInspection } from '../../../../backend_api/models';
import Label from '../../../../base/components/basic/Label';
import { twMerge } from '../../../../base/utils';
import { SupplierBookingInspection } from '../../../suppliers/types';
import { Inspection } from '../../types';

interface CombinedInspectionLabelProps {
    inspection?: (DashboardPopupInspection | Inspection | SupplierBookingInspection) & { master_id?: string; master_inspection_id?: string };
    noData?: boolean;
    className?: string;
    type: 'short' | 'header' | 'dashboard';
    containerClassName?: string;
}

export const CombinedInspectionLabel = (props: CombinedInspectionLabelProps): React.ReactElement => {
    const { inspection, type, className, noData = false, containerClassName } = props;
    if ((inspection && inspection.master_inspection) || (inspection && inspection.master_inspection_id) || (inspection && inspection.master_id) || noData) {
        if (type === 'dashboard') {
            return (<Label className={twMerge('break-normal text-xs border-0 font-normal py-0', containerClassName)}>
                <span><FormattedMessage id='inspections.combined' /></span>
            </Label>);
        }
        if (type === 'short') {
            return <Popup position='top center' trigger={<span>
                <Label className={twMerge('leading-none w-5 py-0 border-0 rounded px-0 rotate-90 transform mx-1', containerClassName)}>
                    <i className={twMerge('bg-default-widget-color border-0 rounded text-2xl md:text-lg break-normal mi combine', className)}>merge_type</i>
                </Label>
            </span>}>
                <FormattedMessage id='inspections.combined_inspections.this_is_a_combined_inspection' />
            </Popup>;
        }
    }
    return null;
};

export const CombineInspectionIcon = (props: { className?: string }): React.ReactElement => {
    const { className } = props;
    return <i className={twMerge('rounded text-xl md:text-xl break-normal rotate-90 mi', className)}>merge_type</i>;
};
