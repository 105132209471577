import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { request2 } from '../../../base/api';
import { AppState } from '../../../base/types';
import { sendStatusMessage } from '../../messages/actions';
import { duplicateCombinedInspection } from '../actions/duplicateActions';
import { orderWithInspectionsRequestSuccess } from '../actions/editInspectionActions';
import { Order } from '../types';

export type EditInspectionsState = {
    order: Order;
    editInspectionFetching: boolean;
}
const initialState: EditInspectionsState = {
    order: undefined,
    editInspectionFetching: false,
};

export const getOrder = createAsyncThunk<Order, { orderId: string; sendMessage: boolean, isFetching?: boolean }>(
    'getOrder',
    async (params, { dispatch, rejectWithValue }) => {
        const { orderId, sendMessage, isFetching } = params;
        const rq = await request2('orders/' + params.orderId);
        if (!rq.ok) {
            console.log('An error occured loading order with orderId: ' + orderId);
            rejectWithValue(rq as Response);
        }
        if (sendMessage) {
            dispatch(sendStatusMessage(['status_message.loading_order_data'], 0, true));
        }
        const order = await rq.json() as Order;
        dispatch(orderWithInspectionsRequestSuccess(order));
        return order;
    });


export const editInspectionsSlice = createSlice({
    name: 'editInspections',
    initialState,
    reducers: {
        updateOrderData: (state, action: { payload: Order }): void => { state.order = action.payload },
    },
    extraReducers: builder => {
        // Reducer responding to createAsyncThunk's:
        builder.addCase(getOrder.fulfilled, (state, action) => {
            state.order = action.payload;
            console.log('current order ', current(state));

        });
        builder.addCase(duplicateCombinedInspection.pending, (state, action) => {
            state.editInspectionFetching = true;
        });
        builder.addCase(duplicateCombinedInspection.fulfilled, (state, action) => {
            state.editInspectionFetching = false;
        });
        builder.addCase(duplicateCombinedInspection.rejected, (state, action) => {
            state.editInspectionFetching = false;
        });
    }
});

export const getOrderSelector = createSelector(
    [(state: AppState) => {
        return state.app.editInspections.order
    }],
    (order) => order,
);
export const getEditInspectionFetchingSelector = createSelector(
    [(state: AppState) => {
        return state.app.editInspections.editInspectionFetching;
    }],
    (f) => f,
);
const { actions, reducer } = editInspectionsSlice
export const { updateOrderData } = actions;
export default reducer;
