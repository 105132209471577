import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Dropdown, Popup } from 'semantic-ui-react';
import { objectHasKeys } from '../../../base/utils';
import { ById, DropdownOptionType, DropdownValue } from '../types';

interface RolesFilterProps {
    roles: Array<{ key: string; value: string; text: string }>;
    selectedRoles: string | string[];
    active: boolean;
    rolesByKey: ById<DropdownOptionType>;
    handleChange(item: { name: string; value: any }, append: boolean, apply: boolean);
    setActive(type: string, active: boolean);
}

const initialState: { roles: string[]; active: boolean; selectedRoles: string | string[] } = { roles: [], active: false, selectedRoles: [] }
type State = Readonly<typeof initialState>;

const intlMessages = defineMessages({
    selectRoles: {
        id: 'users.filters.select_role',
    },
});

class RolesFilter extends Component<RolesFilterProps & WrappedComponentProps, State> {
    private nameInput;
    readonly state: State = initialState;
    constructor(props) {
        super(props);
    }
    public render(): React.ReactElement {
        const selectRolesLabel: string = this.props.intl.formatMessage(intlMessages.selectRoles);

        let selectedRoles = this.props.selectedRoles;
        if (!Array.isArray(selectedRoles)) {
            selectedRoles = [selectedRoles];
        }

        const rolesByKey = this.props.rolesByKey;
        const options = this.props.roles;

        if (objectHasKeys(rolesByKey)) {
            return (
                <div className='filter approver'>
                    <div className='label'><FormattedMessage id='users.roles' /></div>
                    <div className='content'>
                        {!this.state.active && selectedRoles && selectedRoles.length > 1 &&
                            <Popup
                                position='top center'
                                trigger={<Dropdown selection onClick={this.setActive}>{this.getNotActiveLabel(selectedRoles)}</Dropdown>}>
                                <Popup.Header><FormattedMessage id='users.filters.selected_roles' /> ({selectedRoles.length})</Popup.Header>
                                <Popup.Content>
                                    {selectedRoles.map((roleKey, index: number) => {
                                        return (<div key={index}>{rolesByKey[roleKey].text}</div>);
                                    })}
                                </Popup.Content>
                            </Popup>}
                        {!this.state.active && selectedRoles && selectedRoles.length === 1 && <Dropdown selection onClick={this.setActive}>{this.getNotActiveLabel(selectedRoles)}</Dropdown>}
                        {(this.state.active || selectedRoles.length === 0) && <><Dropdown
                            name='groups'
                            options={options}
                            selection
                            multiple
                            search
                            className='mr-1'
                            value={this.state.selectedRoles}
                            placeholder={this.state.selectedRoles.length === 0 ? selectRolesLabel : ''}
                            onChange={(evt, item): void => {
                                this.handleChange(item);
                            }}
                            ref={(input): void => { this.nameInput = input; }}
                        />
                            {this.state.active && <Button size='mini' positive className='apply' onClick={this.save}><FormattedMessage id='users.apply' /></Button>}</>}
                    </div>
                </div>
            );
        }
        return null;
    }

    public componentDidMount(): void {
        if (this.props.selectedRoles) {
            this.setState({ selectedRoles: this.convertToArray(this.props.selectedRoles) });
        }
    }

    public componentDidUpdate(prevProps): void {
        if (this.props.selectedRoles !== prevProps.selectedRoles) {
            this.setState({ selectedRoles: this.convertToArray(this.props.selectedRoles) });
        }
    }

    private convertToArray(value): any[] {
        return Array.isArray(value) ? value : [value];
    }

    private handleChange({ value }: { value?: DropdownValue }): void {
        this.setState({ selectedRoles: this.convertToArray(value) });
        this.setState({ active: true });
    }

    private save = (): void => {
        this.props.handleChange({ name: 'roles', value: this.state.selectedRoles }, false, true);
        this.props.setActive('roles', false);
        this.setState({ active: false });
    }

    private cancel = (): void => {
        const selectedRoles = Object.assign({}, this.state.selectedRoles);
        this.setState({ selectedRoles });
        this.props.setActive('roles', false);
        this.setState({ active: false });
    }

    private setActive = (): void => {
        this.setState({ active: true }, () => {
            this.nameInput.open();
        });
    }

    private getNotActiveLabel(roles: string[]): React.ReactElement {
        const rolesCnt = this.state.selectedRoles.length - 1;
        return <span>{this.props.rolesByKey[roles[0]].text} {rolesCnt > 0 && <span className='plusSome'> + {rolesCnt}</span>}</span>;
    }
}

export default injectIntl(RolesFilter);