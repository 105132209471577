import { sortGroupsSelector } from '../selectors';
import { GroupData, GroupOption } from '../types';

export const handleRemove = (id: string) => {
    return function update(): any {
        const selectedGroups = Object.assign([], this.props.selectedGroups);
        let index = -1;
        selectedGroups.forEach((group: GroupData, i: number) => {
            if (group.id === id) {
                index = i;
            }
        });
        if (index > -1) {
            selectedGroups.splice(index, 1);
        }
        return { selectedGroups };
    };
};

export const handleSave = (concat = true) => {
    return function update(): any {
        let selectedGroups: GroupData[];
        if (concat) {
            selectedGroups = Object.assign([], this.state.selectedGroups.concat(this.props.selectedGroups));
        } else {
            selectedGroups = Object.assign([], this.state.selectedGroups);
        }
        this.props.handleChange(undefined, { name: 'groups', value: selectedGroups });
        return { selectedGroups: [], showAddGroups: false };
    };
};

export const handleChange = (selectedGroupsId: string[]) => {
    return function update(): any {
        const selectedGroups: GroupData[] = [];
        this.props.ownGroups.forEach((group: GroupData) => {
            if (selectedGroupsId.includes(group.id)) {
                selectedGroups.push(group)
            }
        });
        return { selectedGroups };
    };
};

export const handleAddGroup = (): any => {
    return { showAddGroups: true };
};

export const handleCancel = (): any => {
    return { showAddGroups: false };
};

export const getGroupsNotAttached = (selectedGroups: GroupData[], ownGroups: GroupData[]): GroupOption[] => {
    const groups: GroupOption[] = [];
    ownGroups.forEach((group: GroupData) => {
        if (!isSelectedGroup(group, selectedGroups)) {
            const obj: GroupOption = {
                disabled: false,
                key: group.id,
                value: group.id,
                is_supplier_group: group.is_supplier_group,
                text: group.name,
            };
            groups.push(obj);
        }
    });
    return sortGroupsSelector(groups) as GroupOption[];
};

const isSelectedGroup = (group: GroupData, selectedGroups: GroupData[]): boolean => {
    let retval = false;
    selectedGroups.forEach((ownGroup) => {
        if (group.id === ownGroup.id) {
            retval = true;
        }
    });
    return retval;
};

export const isOwnGroup = (group: GroupData, ownGroups: GroupData[]): boolean => {
    let retval = false;
    ownGroups.forEach((ownGroup: GroupData) => {
        if (group.id === ownGroup.id) {
            retval = true;
        }
    });
    return retval;
};

export const isLastOwnGroup = (selectedGroups: GroupData[], ownGroups: GroupData[]): boolean => {
    let cnt = 0;
    selectedGroups.forEach((selectedGroup: GroupData) => {
        if (isOwnGroup(selectedGroup, ownGroups)) {
            cnt++;
        }
    });
    return cnt === 1;
};
