import { DashboardWidget, SisenseContextProvider, ThemeProvider } from "@sisense/sdk-ui";
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';
import { twMerge } from '../../../../base/utils';

type Props = {
    className?: string;
};

export const SisenseUrl = "https://qarma.sisensepoc.com";

const SisenseWidgets = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const dashboardId = qs.parse(location?.search)?.dashboardId as string || '66dfea86cd6a9d00339d2522';
    const widget1Id = '66e02581cd6a9d00339d252a';
    const widget2Id = '66e03487cd6a9d00339d2533';

    return (
        <div className={twMerge('', className)}>
            <div className="p-8">
                <h1>Testing embedded Sisense Dashboard</h1>

                <SisenseContextProvider url={SisenseUrl} ssoEnabled={true} enableSilentPreAuth={true}>
                    <ThemeProvider>
                        <DashboardWidget
                            dashboardOid={dashboardId}
                            widgetOid={widget1Id}
                            styleOptions={{
                                spaceAround: 'Medium',
                                height: 380,
                                border: true,
                            }}
                        />
                        <DashboardWidget
                            dashboardOid={dashboardId}
                            widgetOid={widget2Id}
                            styleOptions={{
                                spaceAround: 'Medium',
                                height: 380,
                                border: true,
                            }}
                        />
                    </ThemeProvider>
                </SisenseContextProvider>
            </div>
        </div>);
}
export default SisenseWidgets;
