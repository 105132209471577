import React from 'react';
import { User } from '../../../../backend_api/models';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import { useAppDispatch } from '../../../../store';
import NewWatchers from '../../../watchers/components/NewWatchers';
import { addAuditWatchers, removeAuditWatchers } from '../../slices/auditSlice';

type Props = {
    auditId: string;
    disabled?: boolean;
    className?: string;
    watchers: User[];
    noContentSection?: boolean;
    isReport?: boolean;
    readonly?: boolean;
};

const NewAuditWatchers = (props: Props): React.ReactElement => {
    const { auditId, watchers, noContentSection = false, isReport, className, disabled = false, readonly = false } = props;
    const dispatch = useAppDispatch();
    return <NewWatchers
        disabled={disabled}
        readonly={readonly}
        watchers={watchers}
        className={className}
        noContentSection={true}
        handleSave={(watchers) => {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            dispatch(addAuditWatchers({ auditId, watchers, isReport }))
        }}
        handleDelete={(watcherId: string) => {
            dispatch(removeAuditWatchers({ auditId, watchers: [watcherId], isReport }))
        }} />
}
export default NewAuditWatchers;
