import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import Input from '../../../base/components/basic/Input';
import FormField from '../../../base/components/basic/form/FormField';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { updateDetailedSupplier } from '../slice/suppliersSlice';

type Props = {
    supplierId: string;
    className?: string;
    disabled?: boolean;
    name: string;
    number: string;
};

const DetailedSupplierInfo = (props: Props): React.ReactElement => {
    const { className, disabled, supplierId } = props;
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState(null);
    const [okToEdit, setOkToEdit] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [name, setName] = useState(props.name);
    const [number, setNumber] = useState(props.number);
    const nameRef = useRef<HTMLInputElement>();
    const numberRef = useRef<HTMLInputElement>();

    const save = () => {
        dispatch(updateDetailedSupplier({ supplierId, obj: { name, number } }))
    }

    useEffect(() => {
        setName(props.name)
    }, [props.name]);

    useEffect(() => {
        setNumber(props.number)
    }, [props.number]);

    useEffect(() => {
        if (okToEdit) {
            edit === 'name' ? nameRef.current.focus() : numberRef.current.focus();
        }
    }, [okToEdit]);

    const showWarningDialog = (trigger: React.ReactElement, type: 'number' | 'name') => {
        return <Popup
            on={'click'}
            open={edit === type && showConfirm}
            closeOnTriggerClick
            closeOnPortalMouseLeave
            closeOnTriggerBlur
            closeOnTriggerMouseLeave
            trigger={<span onClick={() => {
                if (!okToEdit) {
                    setEdit(type);
                    setShowConfirm(true);
                }
            }}>
                {trigger}
            </span>}>
            <Popup.Content>
                <div><FormattedMessage id={'suppliers.edit_basic.info._warning'} />.</div>
                <div className='flex mt-2 py-4 '>
                    <Button small primary onClick={() => {
                        setOkToEdit(true);
                        setShowConfirm(false);
                    }}><FormattedMessage id='suppliers.edit_basic.info._warning.ok' /></Button>
                    <Button
                        onClick={() => {
                            setOkToEdit(false);
                            setShowConfirm(false);
                        }}
                        small
                    ><FormattedMessage id='globals.cancel' /></Button>
                </div>
            </Popup.Content>

        </Popup>

    }

    return <div className={twMerge('', className)}>

        <div className='w-full sm:w-1/2'>
            <FormField className=''>
                <label><FormattedMessage id='suppliers.input_label.supplier_number' /></label>
                {showWarningDialog(<Input inputRef={numberRef} type='text' readOnly={!okToEdit} disabled={disabled} name='number' value={number} onChange={(e) => setNumber(e.currentTarget.value)} onBlur={save} />, 'number')}
            </FormField>
            <FormField>
                <label><FormattedMessage id='suppliers.input_label.supplier_name' /></label>
                {showWarningDialog(<Input inputRef={nameRef} type='text' readOnly={!okToEdit} disabled={disabled} name='name' value={name} onChange={(e) => setName(e.currentTarget.value)} onBlur={save} />, 'name')}
            </FormField>
        </div>
    </div >
}
export default DetailedSupplierInfo;
