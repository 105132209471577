/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCustomFieldNotSet } from './AuditCustomFieldNotSet';
import type { AuditCustomFieldOption } from './AuditCustomFieldOption';
import type { AuditCustomFieldOptionIds } from './AuditCustomFieldOptionIds';
import type { UUID } from './UUID';

/**
 *   Value for a multiple choice based custom field.
 *
 */
export type AuditCustomFieldMultipleChoiceValue = {
    multiple_choices_allowed: boolean;
    new_value: (AuditCustomFieldOptionIds | AuditCustomFieldNotSet);
    options: Array<AuditCustomFieldOption>;
    previous_value: Array<UUID>;
    type: AuditCustomFieldMultipleChoiceValue.type;
};

export namespace AuditCustomFieldMultipleChoiceValue {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

