import React, { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { twMerge } from '../../utils';

// Currently tailwind has problems showing icons with ::before content (using before-content[\\e800] to display the checked icon does not work properly) - as a fix the css for the checkbox is loaded using plain css
import './Checkbox.css';

export type Props = {
    id?: string;
    className?: string;
    label?: string | React.ReactElement;
    labelExplanation?: string | React.ReactElement
    checked?: boolean;
    disabled?: boolean;
    name?: string;
    radio?: boolean;
    tabIndex?: number;
    type?: 'checkbox' | 'radio';
    
    onChange?: React.FormEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    onMouseUp?: React.MouseEventHandler<HTMLInputElement>;
    onMouseDown?: React.MouseEventHandler<HTMLInputElement>;
}

const CheckboxRadio = (props: Props): React.ReactElement => {
    const { className, disabled, tabIndex = 0, type = 'checkbox', name, ...rest } = props;

    const cnBefore = ['before:transform before:scale-0', type === 'checkbox' ? '' : 'before:rounded-3xl '].join(' ');
    const cnBeforeChecked = type === 'checkbox' ?
        'qCheckbox checked:before:transform checked:before:scale-1 checked:before:text-primary checked:before:scale-90' // please leave the .qCheckbox class - it's a fix to show checked checkbox. See above comment also ⬆⬆
        :
        'checked:before:bg-black checked:before:transform checked:before:scale-50';
    const [id, setId] = useState<string>(props.id);

    useEffect(() => {
        if(!id) {
            setId(uuidv1());
        }
    }, []);

    return <div className='flex leading-none items-center gap-2'>
        <div><input
            className={twMerge(
                'appearance-none cursor-pointer border bg-white grid border-default',
                type === 'checkbox' ? 'rounded' : ' rounded-3xl before:w-4 before:h-4 ',
                'focus:outline-1 focus:outline outline-offset-0 active:ring-yellow-300 active:ring-2',
                cnBefore,
                cnBeforeChecked,
                className
            )}
            id={id}
            checked={props.checked}
            name={name}
            type={type}
            disabled={disabled}
            tabIndex={tabIndex || 0}
            {...rest}
        /></div>
        {props.label &&
        (
            props.labelExplanation ? 
            <div className='flex flex-col justify-start items-start cursor-pointer'>
                <label htmlFor={id} className={disabled && 'text-disabled'}>{props.label}</label>
                <div className='text-sm text-secondary'>{props.labelExplanation}</div>
            </div>
            :
            <label htmlFor={id} className={twMerge('cursor-pointer', disabled && 'text-disabled')}>{props.label}</label>
        )
        }
    </div>

}
export const Checkbox = (props: Props) => {
    return <CheckboxRadio type='checkbox' {...props} />
}
export const Radio = (props: Props) => {
    return <CheckboxRadio type='radio' {...props} />
}
export default { Checkbox, Radio };


