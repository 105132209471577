/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentAppliesToProduct
 */
export interface DocumentAppliesToProduct {
    /**
     * 
     * @type {string}
     * @memberof DocumentAppliesToProduct
     */
    product_id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAppliesToProduct
     */
    type: DocumentAppliesToProductTypeEnum;
}


/**
 * @export
 */
export const DocumentAppliesToProductTypeEnum = {
    DocumentAppliesToProduct: 'document_applies_to_product'
} as const;
export type DocumentAppliesToProductTypeEnum = typeof DocumentAppliesToProductTypeEnum[keyof typeof DocumentAppliesToProductTypeEnum];


/**
 * Check if a given object implements the DocumentAppliesToProduct interface.
 */
export function instanceOfDocumentAppliesToProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DocumentAppliesToProductFromJSON(json: any): DocumentAppliesToProduct {
    return DocumentAppliesToProductFromJSONTyped(json, false);
}

export function DocumentAppliesToProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentAppliesToProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product_id': json['product_id'],
        'type': json['type'],
    };
}

export function DocumentAppliesToProductToJSON(value?: DocumentAppliesToProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.product_id,
        'type': value.type,
    };
}

