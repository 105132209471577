import React from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../../base/components/basic/Button';
type LineTogglerButtonProps = {
    itemsCnt: number;
    open: boolean;
    disabled?: boolean;
};

const LineTogglerButton = (props: LineTogglerButtonProps): any => {
    const { itemsCnt, open, disabled } = props;
    const cn = twMerge('rounded-sm w-16 flex justify-end items-center py-2 rounded-md', props.open && 'open bg-branding-brand text-white', itemsCnt > 99 ? 'text-sml pl-2 pr-1' : 'text-base px-2');
    return <div className='lineToggler'>
        <Button className={cn} disabled={disabled}>
            <div className='p-1'>{itemsCnt}</div>
            {!open && <i className='mi text-xl text-secondary bg-q'>expand_more</i>}
            {open && <i className='mi text-xl text-white'>expand_less</i>}
        </Button>
    </div>;
};

export default LineTogglerButton;
