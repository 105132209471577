import { createAction, createAsyncThunk, createSelector, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { request2 } from '../../../base/api';
import { AppState } from '../../../base/types';
import { catchException } from '../../errorHandling/handler';
import { orderWithInspectionsRequestSuccess } from '../../inspections/actions/editInspectionActions';
import { getOrderWithInspectionsSelector } from '../../inspections/editInspectionSelectors';
import { Order } from '../../inspections/types';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';

export type SamplingProtocol = {
    id: string;
    name: string;
    description: string;
};

export type SampleSizeObject = {
    critical_threshold: number;
    major_threshold: number;
    minor_threshold: number;
    sample_size: number | string;
    inspectionId: string;
};

export type SamplingProtocolState = {
    currentProtocol: SamplingProtocol;
    protocols: SamplingProtocol[];
    currentIds: string[];
    currentOrder?: Order;
    ui: {
        showChangeContainer: boolean;
    };
};
export const CALCULATE_SAMPLE_SIZE_SUCCESS = 'CALCULATE_SAMPLE_SIZE_SUCCESS';
export type CALCULATE_SAMPLE_SIZE_SUCCESS = {
    isFetching: boolean;
    totalQuantity: number;
    sample_size: number;
    inspectionId: string;
};

const initialState: SamplingProtocolState = {
    currentProtocol: undefined,
    protocols: [],
    currentIds: [],
    currentOrder: undefined,
    ui: {
        showChangeContainer: false,
    },
};

export const getSamplingProtocols = createAsyncThunk<SamplingProtocol[], void, {
    dispatch: Dispatch;
    rejectWithValue: Response;
}>(
    'getSamplingProtocols',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'sampling_protocols';
        const rq = await request2(url, {});
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.the_sampling_protocols_data_could_not_be_fetched'], 3000));
            catchException('getSamplingProtocols', {
                endpoint: 'sampling_protocols',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const done = await rq.json();
        return done;
    });

export const setSamplingProtocolOnInspections = createAsyncThunk<Order, { orderId: string; inspectionsMap: any }, {
    dispatch: Dispatch;
    rejectWithValue: Response;
}>(
    'setSamplingProtocolOnInspections',
    async (params, { dispatch, rejectWithValue }) => {
        const payload = [];
        Object.values(params.inspectionsMap).map((item) => {
            payload.push(item);
        });
        const order = {
            order_id: params.orderId,
            inspections: payload,
        };
        const url = 'orders/' + params.orderId;
        const rq = await request2(url, { method: 'put', body: JSON.stringify(order) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.the_sampling_protocols_data_could_not_be_stored'], 3000));
            catchException('getSamplingProtocols', {
                endpoint: 'sampling_protocols',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.the_sampling_protocol_was_changed'], 3000));
        const updatedOrder = await rq.json();
        dispatch(orderWithInspectionsRequestSuccess(updatedOrder));
        return updatedOrder;
    });

export const setSamplingProtocolOnOrder = createAsyncThunk<Order, { orderId: string; samplingProtocol: SamplingProtocol }>(
    'setSamplingProtocolOnInspections',
    async (params, { dispatch, rejectWithValue }) => {
        const order = {
            sampling_protocol: params.samplingProtocol,
        };
        const url = 'orders/' + params.orderId;
        const rq = await request2(url, { method: 'put', body: JSON.stringify(order) });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.the_sampling_protocols_data_could_not_be_stored'], 3000));
            catchException('getSamplingProtocols', {
                endpoint: 'sampling_protocols',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.the_sampling_protocol_was_changed_om_order'], 3000));
        const updatedOrder = await rq.json();
        dispatch(orderWithInspectionsRequestSuccess(updatedOrder));
        return updatedOrder;
    });

export const calculateSampleSize = createAsyncThunk<void, { inspectionId: string; protocolId: string; totalQty: number | string }>(
    'calculateSampleSize',
    async (params, { dispatch, rejectWithValue }) => {
        const setSampleSize = createAction<SampleSizeObject>(CALCULATE_SAMPLE_SIZE_SUCCESS);
        const { totalQty, inspectionId, protocolId } = params;
        const url = 'sampling_protocols/' + protocolId + '/calculate?total_quantity=' + totalQty;
        if (totalQty === null || totalQty === undefined || totalQty === '') {
            const obj = {
                critical_threshold: undefined,
                inspectionId,
                major_threshold: undefined,
                minor_threshold: undefined,
                sample_size: '',
            };
            dispatch(setSampleSize(obj));
            return;
        }
        const rq = await request2(url, {});
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.the_sample_size_could_not_be_calculated'], 3000));
            catchException('calculateSampleSize', {
                endpoint: 'sampling_protocols/[samplingId]/calculate?total_quantity=[totalQty]',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, inspectionId, totalQty, protocolId });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        data.inspectionId = inspectionId;
        dispatch(setSampleSize(data));
    });

export const samplingProtocolSlice = createSlice({
    name: 'samplingProtocols',
    initialState,
    reducers: {
        setCurrentSamplingProtocol: (state, action: PayloadAction<SamplingProtocol>): void => {
            state.currentProtocol = action.payload;
        },
        showChangeSamplingProtocol: (state, action: PayloadAction<{ inspectionId: string; show: boolean }>): void => {
            state.ui.showChangeContainer = action.payload.show;
            state.currentIds = [action.payload.inspectionId];
        },
    },
    extraReducers: builder => {
        // Reducer responding to createAsyncThunk's:
        builder.addCase(getSamplingProtocols.pending, () => {
            // todo
        });
        builder.addCase(getSamplingProtocols.fulfilled, (state, action) => {
            state.protocols = action.payload;
        });
    }
});


export const getSamplingProtocolsSelector = createSelector(
    [(state: AppState): SamplingProtocol[] => state.app.samplingProtocols.protocols],
    (samplingProtocols) => samplingProtocols,
);

export const getCurrentSamplingProtocolSelector = createSelector(
    [(state: AppState): SamplingProtocol => state.app.samplingProtocols.currentProtocol],
    (currentProtocol) => currentProtocol,
);
export const showChangeSamplingProtocolSelector = createSelector(
    [(state: AppState): { show: boolean; ids: string[] } => {
        const s = state.app.samplingProtocols;
        return { show: state.app.samplingProtocols.ui.showChangeContainer, ids: s.currentIds }
    }],
    (show) => show,
);
export const doSamplingProtolsDifferSelector = createSelector(
    [(state: AppState): { differ: boolean; cnt: number } => {
        const order = getOrderWithInspectionsSelector(state);
        let differ = false;
        let cnt = 0;
        const cp = state.app.samplingProtocols.currentProtocol;
        if (cp) {
            order.inspections.forEach((inspection) => {
                if (inspection.sampling_protocol && (inspection.sampling_protocol.id !== cp.id)) {
                    differ = true;
                    cnt++;
                }
            });
        }
        return { differ, cnt };
    }],
    (currentProtocol) => currentProtocol,
)
const { actions, reducer } = samplingProtocolSlice;
export const { showChangeSamplingProtocol, setCurrentSamplingProtocol } = actions;
export default reducer;
