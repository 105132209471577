import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Segment } from 'semantic-ui-react';
import { ReportCheckpoint } from '../../../../../backend_api/models';
import { getLocaleLanguage, objectHasKeys } from '../../../../../base/utils';
import { getReportCheckpointsSelector } from '../../../selectors/reportSelectors';
import { Inspection } from '../../../types';
import CheckpointItem from './CheckpointItem';

type CheckpointsProps = {
    inspection: Inspection;
};

const Checkpoints = (props: CheckpointsProps): React.ReactElement => {
    const { inspection } = props;
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const checkpointHeaders = useSelector(getReportCheckpointsSelector);
    return (<Segment.Group raised className='checkpoints'>
        <Segment secondary>
            <h3><FormattedMessage id='inspections.checkpoints' /></h3>
        </Segment>
        <Segment>
            {checkpointHeaders && checkpointHeaders.map((checkpointHeader) => {
                return <Fragment key={'checkpoint_header_' + checkpointHeader.id} >
                    <a id={'checkpoint_header_' + checkpointHeader.id} />
                    {/* if the header title is empty, checkpoints have no headers at all */}
                    {objectHasKeys(checkpointHeader.title) && <Header as='h2'>{checkpointHeader.title[lang] || checkpointHeader.title.C}</Header>}
                    {checkpointHeader.checkpoints.map((checkpoint) => {
                        return <CheckpointItem checkpoint={checkpoint as unknown as ReportCheckpoint} inspection={inspection} key={'checkpointItem_' + checkpoint.id} />
                    })}
                </Fragment>
            })}
        </Segment>
    </Segment.Group>);
};

export default Checkpoints;
