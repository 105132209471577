import * as Sentry from '@sentry/react';
import { createAction } from 'redux-actions';
import { GetProfileResponse } from '../../backend_api/models/GetProfileResponse';
import { request, request2 } from '../../base/api';
import { Action } from '../../base/genericActions';
import { AppThunk } from '../../base/types';
import messages from '../../modules/messages';
import errorHandling from '../errorHandling';
import * as types from './actionTypes';

const sendErrorMessage = messages.actions.sendErrorMessage;
const catchException = errorHandling.handler.catchException;

export const requestProfile = (): Action<types.PROFILE_REQUEST> => {
    return {
        type: types.PROFILE_REQUEST,
        payload: {},
    };
};

const requestProfileSuccess = (profile: GetProfileResponse): Action<types.PROFILE_REQUEST_SUCCESS> => {
    return {
        type: types.PROFILE_REQUEST_SUCCESS,
        payload: {
            profile,
        },
    };
};

const requestProfileFailure = (): Action<types.PROFILE_REQUEST_FAILURE> => {
    return {
        type: types.PROFILE_REQUEST_FAILURE,
        payload: {},
    };
};

const getProfile = (): AppThunk => {
    return async (dispatch): Promise<unknown> => {
        let error: any;
        const response = await request2('profile');
        if (!response.ok) {
            error = response;
            dispatch(requestProfileFailure());
            dispatch(sendErrorMessage(['error_message.could_not_load_user_profile_data'], 3000));
            catchException('getProfile', {
                endpoint: 'profile',
                request: 'profile',
                status: error.status,
                statusText: error.statusMessage,
            }, { error });
            return error;
        }
        const data: GetProfileResponse = await response.json();
        dispatch(requestProfileSuccess(data));

        // Store user email, id, and stuff for Sentry context. Info will be added to each error log entry
        Sentry.setUser({
            email: data.email,
            id: data.id,
            organization: data.organization,
            locale: data.locale && data.locale.language || 'en',
            other_organiztions: data.other_organizations && JSON.stringify(data.other_organizations) || 'none',
            user_role_id: data.user_role_id && data.user_role_id || 'unknown',
            role: data.role && data.role || 'unknown',
        });
    
        // Send user stuff to Hotjar
        const userId = data.email || null;
        (window as any).hj('identify', userId, {
            email: data.email,
            id: data.id,
            organization: data.organization,
        });

        (window as any).chmln.identify(data.id, {
            email: data.email,
            locale: data.locale && data.locale.language || 'en',
            company: {
                name: data.organization,
            },
            features: data.features,

        });
        return data;
    }
};


export { getProfile };


const isUpdatingProfile = createAction(types.IS_UPDATING_PROFILE, (isUpdatingProfile: boolean) => ({ isUpdatingProfile }));

export const updateProfile = (userId: string, payload: unknown): AppThunk => {
    return async (dispatch): Promise<GetProfileResponse | void> => {
        dispatch(isUpdatingProfile(true));
        return request('users/' + userId, {
            body: JSON.stringify(payload),
            method: 'PUT',
        })
            .then((response: GetProfileResponse) => {
                dispatch(requestProfileSuccess(response));
                dispatch(isUpdatingProfile(false));
                return response;
            }).catch(() => {
                dispatch(isUpdatingProfile(false));
                dispatch(sendErrorMessage(['error_message.profile_could_not_be_updated'], 3000));
            });
        // TODO: General errors (network, etc...)
    };
};
