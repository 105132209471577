import qs from 'query-string';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getLocationEntries } from '../../../base/utils';

type OwnProps = {
    to: string;
    children?: React.ReactNode;
    excludeParams?: string[];
    className?: string;
};
type QLinkProps = OwnProps;

const QLink = (props: QLinkProps): React.ReactElement => {
    const location = useLocation();
    const { to, children, excludeParams, className } = props;
    const filters = getLocationEntries(location);
    const tFilters = Object.keys(filters);
    excludeParams && excludeParams.forEach((p: string) => {
        if (tFilters.includes(p)) {
            delete filters[p];
        }
    });
    const q = { ...qs.parseUrl(to) };
    const no = { ...q.query, ...filters };
    q.query = no;
    const url = qs.stringifyUrl(q, { encode: true });
    return <Link className={className} to={url}>{children}</Link>
}

export default QLink;