import React from 'react';
import { Button } from 'semantic-ui-react';

const ListButton = (props): React.ReactElement => {
    return (
        <Button secondary {...props} className='listButton'>
            {props.children}
        </Button>
    )
}

export default ListButton;