import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Checkbox, Loader } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import Input from '../../../base/components/basic/Input';
import Segment from '../../../base/components/basic/Segment';
import Form from '../../../base/components/basic/form/Form';
import FormField from '../../../base/components/basic/form/FormField';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { UserCreds } from '../types';

type Props = {
    className?: string;
    isAuthenticated: boolean;
    isFetching: boolean;
    user: UserCreds;
    email: string;
    password?: string;
    loginFailed: boolean;
    disabled: boolean;
    // goToV1?: boolean;
    handleLoginInput(name: string, value: string): void;
    handleLoginSubmit(e: React.FormEvent<HTMLFormElement>): void;
};

const LoginBox = (props: Props): React.ReactElement => {
    const { className, loginFailed, disabled, isFetching, handleLoginInput } = props;
    const intl = useIntl();
    const [pwType, setPwType] = useState<'text' | 'password'>('password');
    const pwLabel: string = intl.formatMessage({ id: 'login.form.password' });
    const emailPlaceholder: string = intl.formatMessage({ id: 'login.email' });

    const loginDialog =
        <Form className='pt-8' onSubmit={(e) => disabled ? preventDefaultAndStopPropagation(e) : props.handleLoginSubmit(e)}>
            <Fragment>
                <FormField>
                    <label><FormattedMessage id='login.enter_username' /></label>
                    <Input
                        type='text'
                        name='email'
                        autoFocus
                        placeholder={emailPlaceholder}
                        disabled={props.isFetching}
                        onChange={(e) => handleLoginInput('email', e.target.value)}
                        icon='person'
                        iconPosition='left'
                        iconClass='text-brand w-12 justify-center flex'
                    />
                </FormField>
                <FormField className=''>
                    <label><FormattedMessage id='login.enter_password' /></label>
                    <Input
                        type={pwType}
                        name='password'
                        placeholder={pwLabel}
                        disabled={props.isFetching}
                        onChange={(e) => handleLoginInput('password', e.target.value)}
                        icon='lock'
                        iconPosition='left'
                        iconClass='text-brand text-lg w-12 justify-center flex' />
                </FormField>
                <div className='items-center flex text-sm space-x-7 pt-1 mb-4 pb-2 cursor-pointer' onClick={() => setPwType(pwType === 'text' ? 'password' : 'text')} >
                    <Checkbox name='changePw' checked={pwType === 'text'} />
                    <span><FormattedMessage id='login.show_password' /></span>
                </div>

                {loginFailed && <div className='flex items-center justify-center p-2 my-4 text-primary bg-attention rounded w-full'><Icon className='self-start text-xl text-yellow-500 pr-2' name='error' /><div><FormattedMessage id='login.error.password_or_user_incorrect' /></div></div>}
                {/* loginFailed && <Message negative>
                        <FormattedMessage id='login.error.password_or_user_incorrect' />
                    </Message> */}
            </Fragment>
            <Button className='w-full justify-center text-base' disabled={isFetching || disabled}>
                {props.isFetching ? <Loader size='mini' active inline='centered'></Loader> : <FormattedMessage id='login.button.sign_in' />}
            </Button>
            {/* <div className='border-t mt-4 -mx-4 px-4 md:px-6 md:-mx-6 justify-end sm:justify-start flex'>
                <Link to='reset_pw' className='link noUnderline mt-4 flex' onClick={(): void => null}><FormattedMessage id='login.forgot_password' /></Link>
            </div> */}
        </Form>
    return <div className='flex w-full justify-center mx-4 md:mx-0 md:mb-32'>
        <Segment className='flex w-full sm:w-136'
            heading={<FormattedMessage id='login.sign_in' />}
            footer={<Link to='reset_pw' className='link noUnderline' onClick={(): void => null /* props.clearUserData() */}><FormattedMessage id='login.forgot_password' /></Link>}
        >
            {/* {authenticationList.length > 1 ? orgSelector :
                loginDialog
            } */}
            {loginDialog}
        </Segment>
    </div>
}
export default LoginBox;
