import React from 'react';
import { twMerge } from '../../../utils';
import FileActions from './FileActions';

type Props = {
    className?: string;
    fileName: string;
    onZoomIn(): void;
    onZoomOut(): void;
    onDeleteFile(): void;
    onDownloadFile(): void;
    userCanAddAndRemoveFiles: boolean;
};

const FileToolBar = (props: Props): React.ReactElement => {
    const { className, fileName, onZoomIn, onZoomOut, onDeleteFile, userCanAddAndRemoveFiles, onDownloadFile } = props;
    let fn = fileName.split('/');
    let index = fn.length - 1;

    return <div className={twMerge('flex justify-between items-center w-full gap-x-6', className)}>
        <div>
            <div>{fn[index]}</div>
            {/* <MetaItem
                meta={{ metaBelow: { firstItem: 'Søren Mønsted', secondItem: 'Mar 2, 2024 14:21' } }} /> */}
        </div>
        <FileActions
            onZoomIn={onZoomIn}
            onZoomOut={onZoomOut}
            onDeleteFile={onDeleteFile}
            onDownloadFile={onDownloadFile}
            userCanAddAndRemoveFiles={userCanAddAndRemoveFiles}
        />
    </div>

}
export default FileToolBar;
