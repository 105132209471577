import { createAction } from 'redux-actions';
import { createAction as createActionTS } from 'typesafe-actions';
import { request } from '../../../base/api';
import { AppThunk } from '../../../base/types';
import errorHandling from '../../errorHandling';
import { getFirstInspectionNotLockedOrDone } from '../Utils';
import * as types from '../actionTypes/addInspectionActionTypes';
import { Inspection } from '../types';
import { areAllInspectionsEqual } from './editInspectionActions';

const catchException = errorHandling.handler.catchException;

const getInspectionDataRequest = createAction(types.ADD_INSPECTION_GET_DATA_OBJECT_REQUEST);
const getInspectionDataSuccess = createAction(types.ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS);
const getInspectionDataClear = createAction(types.ADD_INSPECTION_GET_DATA_OBJECT_CLEAR);
const getInspectionDataFailure = createAction(types.ADD_INSPECTION_GET_DATA_OBJECT_FAILURE);

// Get an empty inspection object for the add inspection method
// First, though, save the complete order
export const getAddInspectionObject = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        dispatch(getInspectionDataRequest());
        const inspections: (Inspection)[] = getState().app.inspections.order.inspections; // TODO: get inspections from selector
        const bodyData: any = {};
        if (inspections.length > 0) {
            const sourceInspection = getFirstInspectionNotLockedOrDone(inspections) as Inspection;
            if (sourceInspection !== null) {
                bodyData.watchers = sourceInspection.watchers;
                bodyData.approval_flow = sourceInspection.approval_flow;
                bodyData.assigned_user_id = sourceInspection.assigned_user_id;
                bodyData.scheduled_inspection_date = sourceInspection.scheduled_inspection_date;

            }
            const checklists = getState().app.checklists.selectors;
            let chlId: string;
            if (!inspections[inspections.length - 1].checklist_id || inspections.length === 0) {
                chlId = checklists[0] && checklists[0].id || '';
            } else {
                chlId = inspections[inspections.length - 1].checklist_id;
            }
            const supplierEntity = inspections[inspections.length - 1].supplier_entity;
            const linkedProductionUnit = inspections[inspections.length - 1].linked_production_unit;
            const geoLocation = inspections[inspections.length - 1].geographic_location;
            bodyData.checklist_id = chlId;
            bodyData.supplier_entity = supplierEntity;
            bodyData.linked_production_unit = linkedProductionUnit;
            bodyData.geographic_location = geoLocation;
        }
        const url = 'orders/' + inspections[0].order_id + '/inspections';
        return request(url, {
            method: 'POST',
            body: JSON.stringify(bodyData),
        })
            .then((data: any) => {
                dispatch(getInspectionDataSuccess({ data }));
                dispatch(getInspectionDataClear());
                dispatch(areAllInspectionsEqual());
            })
            .catch((error) => {
                dispatch(getInspectionDataFailure());
                catchException('getAddInspectionObject', {
                    endpoint: 'orders/[orderId]',
                    request: url,
                    status: error.status,
                }, { error, orderId: inspections[0].order_id, method: 'PUT' });

            });
    };
};

const indexInspectionsRequest = createActionTS(types.INDEX_ORDER_INSPECTIONS,
    (inspectionsIndex: any) => {
        return {
            type: types.INDEX_ORDER_INSPECTIONS,
            payload: {
                inspectionsIndex,
            },
        };
    });

export const indexInspections = (inspections: Inspection[]): AppThunk => {
    return async (dispatch): Promise<void> => {
        const indexObj = {};
        inspections.forEach((inspection, i: number) => {
            indexObj[inspection.inspection_id] = i;
        });
        dispatch(indexInspectionsRequest(indexObj));
    };
};
