import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../base/components/basic/Button';
import RemoveIcon from '../../../../base/components/basic/RemoveIcon';
import { useAppDispatch } from '../../../../store';
import { InspectorSelector } from '../../../users/components';
import { reassignLockedInspection } from '../../actions/unlockActions';
import { Inspection } from '../../types';

type ReassignInspectorOnInspectionProps = {
    className?: string;
    inspection: Inspection;
    isCombinedInspection?: boolean;
    type?: 'link' | 'button';
};

const ReassignInspectorOnInspection = (props: ReassignInspectorOnInspectionProps): React.ReactElement => {
    const { inspection, type = 'button', isCombinedInspection = false, className } = props;
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const close = (): void => setOpen(false);
    const handleReassign = (item): void => {
        // this.setState({ showReassign: false, show: false });
        if (isCombinedInspection) {
            dispatch(reassignLockedInspection(inspection.master_inspection.id, item.value));
        } else {
            dispatch(reassignLockedInspection(inspection.inspection_id, item.value));
        }
    }


    return <Fragment>
        {!open && type === 'button' ? <Button onClick={() => setOpen(true)}><FormattedMessage id='inspections.reassign' /></Button> : <div className={twMerge(className)} onClick={() => setOpen(true)}><FormattedMessage id='inspections.reassign' /></div>}
        {open && <div className='flex items-center space-x-1 my-1'><InspectorSelector
            selectedInspector={inspection.assigned_user_id}
            id={inspection.inspection_id}
            handleChange={(item): void => {
                handleReassign(item);
            }}
            handleBlur={(): void => null}
            showEmailInSelector={false}
            showExpanded={false}
            showUnassigned={false}
        /><div><Button className='px-3 py-2 flex justify-center' onClick={(): void => close()}><RemoveIcon/></Button></div></div>
        }</Fragment>
}
export default ReassignInspectorOnInspection;
