import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';

type ConfirmationDialogProps = {
    show: boolean;
    headerText: string;
    description?: string;
    close();
    confirm();
}

const ConfirmationDialog = (props: ConfirmationDialogProps): React.ReactElement => {
    return (
        <Modal
            open={props.show}
            closeIcon={true}
            onClose={props.close}
        >
            <Modal.Header>
                {props.headerText}
            </Modal.Header>
            {props.description && <Modal.Content>
                {props.description}
            </Modal.Content>}
            <Modal.Actions>
                <Button onClick={props.confirm} positive><FormattedMessage id='globals.confirmation.ok' /></Button>
                <Button onClick={props.close}><FormattedMessage id="globals.confirmation.cancel" /></Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ConfirmationDialog;