import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { AppState } from '../../../base/types';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getGroupsSelectorSelector } from '../../groups/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type GroupsFilterProps = {
    showLabel?: boolean;
    handleChange?(args): void;
};

const GroupsFilter = (props: GroupsFilterProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { showLabel = true, handleChange } = props;
    const selectedGroups = getLocationEntry(location, 'groups');
    const groups = useSelector((state: AppState) => getGroupsSelectorSelector(state, false));
    return (
        <Filter labelId={showLabel ? 'filters.groups.label_groups' : null}>
            <DropdownFilter
                name='groups'
                options={groups}
                multiple={true}
                placeholder={intl.formatMessage({ id: 'filters.groups.select_groups' })}
                selectedItems={selectedGroups || []}
                handleSave={(items): void => {
                    dispatch(setFilter('groups', items));
                    if (handleChange) {
                        handleChange(items);
                    }
                }}
                inline={false}
            />
        </Filter>);
};

export default GroupsFilter;
