import { InputCustomFieldGroups } from '../../../backend_api_2';
import { CustomField, CustomTable } from '../types';


export const GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS = 'GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS';
export type GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS = {
    data: InputCustomFieldGroups;
};

export const GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS = 'GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS';
export type GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS = {
    data: InputCustomFieldGroups;
};

export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export type GET_CUSTOM_FIELDS_SUCCESS = {
    data: CustomField[];
};

export const GET_CUSTOM_TABLES = 'GET_CUSTOM_TABLES';
export type GET_CUSTOM_TABLES = {
    customTables: CustomTable[];
}