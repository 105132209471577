import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps, Popup, Select } from 'semantic-ui-react';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { OptionsType } from '../../../base/types';
import { ChecklistSummary } from '../../../backend_api_2';

type AuditChecklistsSelectorProps = {
    checklists: ChecklistSummary[];
    checklistId: string;
    onChecklistIdChanged(checklistId: string): void;
    checklistName?: string;
    className?: string;
    isFluid?: boolean;
    disabled?: boolean;
    showUnassigned?: boolean;
    stopPropagation?: boolean;
    dataTestId?: string;
    handleBlur?(event: SyntheticEvent, data: DropdownProps): void;
    didFocus?(event: SyntheticEvent, data: DropdownProps): void;
};

const AuditChecklistsSelector = (props: AuditChecklistsSelectorProps): React.ReactElement => {
    const intl = useIntl();
    const { isFluid = false, showUnassigned = false, stopPropagation = false } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [name, setName] = useState(undefined);

    const value = props.checklistId;
    const cn = props.className || '';
    const disabled = props.disabled;


    useEffect(() => {
        (props.checklists || []).forEach(function (checklist) {
            if (checklist.id == value) {
                setName(checklist.description)
            }
        })
    }, [props.checklists, value])

    useEffect(() => {
        const options: OptionsType[] = (props.checklists || []).map(makeChecklistOption)

        if (showUnassigned) {
            options.push(makeEmptyOption());
        }
        setOptions(options);
    }, [props.checklists]);

    const dd = <Select
        onClick={(e) => stopPropagation && preventDefaultAndStopPropagation(e)}
        selectOnNavigation={false}
        name={props.checklistName || 'checklist_id'}
        className={cn}
        value={value}
        placeholder={intl.formatMessage({ id: 'checklists.select_checklist.label' })}
        search
        fluid={isFluid}
        selection
        disabled={disabled}
        options={options}
        onChange={(evt, data): void => {
            stopPropagation && preventDefaultAndStopPropagation(evt);
            props.onChecklistIdChanged(data.value as string);
        }}
        onBlur={props.handleBlur}
        onFocus={props.didFocus}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        data-test-id={props.dataTestId}
    />;
    return ((name && name.length > 10)) ?
        <Popup
            on='hover'
            position='top center'
            trigger={disabled ? <span className={'overflow-hidden'}>{dd}</span> : dd}>
            <Popup.Content>{name}</Popup.Content>
        </Popup>
        :
        dd;
};

function makeChecklistOption(checklist: ChecklistSummary) {
    return {
        key: checklist.id,
        value: checklist.id,
        text: checklist.description
    }
}

function makeEmptyOption() {
    return {
        key: null,
        id: null,
        value: null,
        text: <span className='emphasizeUnassignedOptionInDropdown'>
            <FormattedMessage id='checklists.selector_set_entry_to_unassiged' />
        </span>
    }
}

export default AuditChecklistsSelector;
