import classnames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Header, Item } from 'semantic-ui-react';
import { BarcodeSample } from '../../../../../backend_api/models';
import { getLocaleLanguageString } from '../../../../../base/utils';

type BarcodesProps = {
    barcodes: BarcodeSample[];
};

const Barcodes = (props: BarcodesProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);

    const getBarcode = (checkpoint: BarcodeSample): React.ReactElement => {
        const scannedBarcode = checkpoint.scanned_barcode || '';
        const expectedBarcode = checkpoint.expected_barcode && checkpoint.expected_barcode.value;
        const scannedOk = scannedBarcode !== null && scannedBarcode !== '';
        const expectedOk = expectedBarcode;
        const barcodesEqual = scannedBarcode === expectedBarcode;

        return (
            <Grid.Row stackable>
                <Grid.Column width='2'>
                    {scannedOk && expectedOk && barcodesEqual && <Button circular basic color='green' icon='check' size='massive' className='barcode' />}
                    {scannedOk && expectedOk && !barcodesEqual && <Button circular basic color='red' icon='delete' size='massive' className='barcode' />}
                    {!scannedOk && expectedOk && !barcodesEqual && <Button circular basic color='blue' icon='question mark' size='massive' className='barcode' />}
                    {!scannedOk && !expectedOk && <Button circular basic color='blue' icon='question mark' size='massive' className='barcode' />}
                    {scannedOk && !expectedOk && <Button circular basic color='blue' icon='question mark' size='massive' className='barcode' />}
                </Grid.Column>
                <Grid.Column width='14'>
                    <Item className='checkpoint-item'>
                        {checkpoint.name &&
                            <Header as='h4'>{checkpoint.name[lang] || checkpoint.name.C}</Header>}
                        <Item.Content className={'sm:w-8/12'}>
                            <Item.Header><FormattedMessage id='inspections.barcode_can_be_scanned' /></Item.Header>
                            <Item.Description>
                                {scannedOk ? 'OK' : ''}
                            </Item.Description>
                        </Item.Content>
                        <Item.Content className={classnames('sm:w-8/12', { error: !barcodesEqual && scannedOk && expectedOk })}>
                            <Item.Header><FormattedMessage id='inspections.scanned_barcode' /></Item.Header>
                            <Item.Description>
                                {scannedBarcode}
                            </Item.Description>
                        </Item.Content>
                        <Item.Content className='sm:w-8/12'>
                            <Item.Header><FormattedMessage id='inspections.barcode_from_order_data' /></Item.Header>
                            <Item.Description>
                                {expectedBarcode}
                            </Item.Description>
                        </Item.Content>
                        {checkpoint.scanned_format && <Item.Content className='sm:w-8/12'>
                            <Item.Header><FormattedMessage id='report.checkpoint.barcode.scanned_format' /></Item.Header>
                            <Item.Description>
                                {checkpoint.scanned_format}
                            </Item.Description>
                        </Item.Content>}
                        {checkpoint.expected_format && checkpoint.expected_format.value && <Item.Content className='sm:w-8/12'>
                            <Item.Header><FormattedMessage id='report.checkpoint.barcode.expected_format' /></Item.Header>
                            <Item.Description>
                                {checkpoint.expected_format.value}
                            </Item.Description>
                        </Item.Content>}
                    </Item>
                </Grid.Column>
            </Grid.Row >
        );

    }
    return <Fragment>{props.barcodes.map((checkpoint, i) => {
        return <Fragment key={'barcode_grid_' + i}>{getBarcode(checkpoint)}</Fragment>;
    })}</Fragment>;

}
export default Barcodes;
