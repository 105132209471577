import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { DetailedSupplier } from '../../backend_api/models/DetailedSupplier';
import { AppState, OptionsType } from '../../base/types';
import { getFirstInspectionNotLockedOrDone } from '../inspections/Utils';
import { Inspection, Order } from '../inspections/types';
import { SuppliersState } from './models';
import { Booking, ProductionUnit, SupplierBooking, SupplierBookingInspection } from './types';

const getSuppliers = (state: AppState): DetailedSupplier[] => state.app.suppliers && state.app.suppliers.data;
const getSupplierData = (state: AppState, supplierId: string): DetailedSupplier => state.app.suppliers && state.app.suppliers[supplierId];
const getProductionUnitById = (state: AppState, supplierId: string, productionUnitId: string): ProductionUnit => {
    if (state.app.suppliers && state.app.suppliers[supplierId] && productionUnitId && state.app.suppliers[supplierId].productionUnitsById && state.app.suppliers[supplierId].productionUnitsById[productionUnitId]) {
        return state.app.suppliers && state.app.suppliers[supplierId]
            && state.app.suppliers[supplierId].productionUnitsById[productionUnitId]
            && state.app.suppliers[supplierId].productionUnitsById[productionUnitId];
    }
};
const isSupplierDataFetching = (state: AppState): boolean => state.app.suppliers && state.app.suppliers.isFetching || false;

const getSuppliersSelectorData = (state: AppState): OptionsType[] => {
    const selector = [];
    selector.push({ key: null, id: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='suppliers.selector_set_entry_to_unassiged' /></span> });
    state.app.suppliers && state.app.suppliers.data && state.app.suppliers.data.forEach((supplier: DetailedSupplier) => {
        selector.push({ key: supplier.id, text: supplier.name, value: supplier.id, id: supplier.id, description: supplier.number });
    });
    return selector;
}

export const getSuppliersSelector = createSelector(
    [getSuppliers],
    (suppliers) => suppliers,
);

export const getSuppliersSelectorSelector = createSelector(
    [getSuppliersSelectorData],
    (suppliersSelector) => suppliersSelector,
);

export const getSupplierDataSelector = createSelector(
    [getSupplierData],
    (supplierData) => supplierData,
);

export const isSupplierDataFetchingSelector = createSelector(
    [isSupplierDataFetching],
    (isFecthing) => isFecthing,
);

// TODO This is a view concern, that should be moved out to the ProductionUnitSelector.
const getProductionUnitsListSelectorData = (state: AppState, supplierId: string, showUnassigOption?: boolean): OptionsType[] => {
    const selector = [];
    const productionUnits = state.app.suppliers && state.app.suppliers.productionUnitsBySupplierId[supplierId];
    if (productionUnits) {
        if (showUnassigOption) {
            selector.push({ key: null, id: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='production_units.selector_set_entry_to_unassiged' /></span> });
        }
        productionUnits.forEach((productionUnit: ProductionUnit) => {
            selector.push({ key: productionUnit.id, text: productionUnit.name || productionUnit.address, value: productionUnit.id, id: productionUnit.id, description: productionUnit.number });
        });
    }
    return selector;
}
const getProductionUnitsSelectorData = (state: AppState, supplierId: string): OptionsType[] => {
    const selector = [];
    const productionUnits = getProductionUnits(state, supplierId);
    if (productionUnits) {
        selector.push({ key: null, id: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='production_units.selector_set_entry_to_unassiged' /></span> });
        productionUnits.forEach((productionUnit: ProductionUnit) => {
            selector.push({ key: productionUnit.id, text: productionUnit.name, value: productionUnit.id, id: productionUnit.id });
        });
    }
    return selector;
}

export const getProductionUnitsListSelectorSelector = createSelector(
    [getProductionUnitsListSelectorData],
    (puSelector) => puSelector,
);

export const getProductionUnitsSelectorSelector = createSelector(
    [getProductionUnitsSelectorData],
    (puSelector) => puSelector,
);

const getProductionUnits = (state: AppState, supplierId: string): ProductionUnit[] => {
    return state.app.suppliers && state.app.suppliers.productionUnitsBySupplierId[supplierId];
}
export const getProductionUnitsSelector = createSelector(
    [getProductionUnits],
    (supplierId) => supplierId,
);

export const getProductionUnitByIdSelector = createSelector(
    [getProductionUnitById],
    (productionUnit) => productionUnit,
);

const getNewSupplier = (state: AppState): DetailedSupplier => {
    return state.app.suppliers.newSupplier;
}
export const getNewSupplierSelector = createSelector(
    [getNewSupplier],
    (supplier) => supplier,
)

const getCurrentInspectionSupplierId = (state: AppState): string => state.app.suppliers && state.app.suppliers.inspectionSupplierId || '';

export const getCurrentInspectionSupplierIdSelector = createSelector(
    getCurrentInspectionSupplierId,
    (currentSupplierId) => currentSupplierId,
);

const getCurrentOrder = (state: AppState): Order => {
    return state.app.inspections.order;
}

export const getCurrentSupplierSelector = createSelector(
    [getCurrentOrder],
    (currentOrder) => {
        if (currentOrder) {
            const inspections: Inspection[] = currentOrder.inspections;
            let inspection: Inspection = getFirstInspectionNotLockedOrDone(inspections);
            let supplier: DetailedSupplier;
            if (inspection === null) {
                inspection = inspections[0];
            }
            if (inspection) {
                if (inspection.supplier !== null) {
                    supplier = inspection.supplier
                }
            }
            return supplier;
        }
    }
);

const getInspectionProductionUnits = (state: AppState, supplierId: string): ProductionUnit[] => {
    if (supplierId) {
        return state.app.suppliers && state.app.suppliers[supplierId] && state.app.suppliers[supplierId].productionUnits;
    }
    return [];
};

export const getInspectionProductionUnitsSelector = createSelector(
    [getInspectionProductionUnits],
    (productionUnits) => {
        const selector: OptionsType[] = [];
        selector.push({ key: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='suppliers.selector_set_entry_to_unassiged' /></span> });
        if (productionUnits) {
            productionUnits.forEach((productionUnit: ProductionUnit) => {
                let name = '-';
                name = productionUnit.name ? productionUnit.name : productionUnit.address[0];
                selector.push({ key: productionUnit.id, value: productionUnit.id, text: name });
            });
        }
        return selector;
    },
);
export const getInspectionProductionUnitsExpandedSelector = createSelector(
    [getInspectionProductionUnits],
    (productionUnits) => {
        const selector = [];
        selector.push({ key: null, id: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='suppliers.selector_set_entry_to_unassiged' /></span> });
        if (productionUnits) {
            productionUnits.forEach((productionUnit: ProductionUnit) => {
                let name = '-';
                name = productionUnit.name ? productionUnit.name : productionUnit.address[0];
                selector.push({ key: productionUnit.id, text: name, value: productionUnit });
            });
        }
        return selector;
    },
);

const getInspectionProductionUnitToBeOverwritten = (state: AppState): boolean => state.app.suppliers && state.app.suppliers.inspectionProductionUnitToBeOverwritten;

export const getInspectionProductionUnitToBeOverwrittenSelector = createSelector(
    getInspectionProductionUnitToBeOverwritten,
    (overwrite) => overwrite,
);

const getConfirmDeleteSupplier = (state: AppState): SuppliersState['confirmDeleteSupplier'] => state.app.suppliers && state.app.suppliers.confirmDeleteSupplier;

export const getConfirmDeleteSupplierSelector = createSelector(
    getConfirmDeleteSupplier,
    (confirm) => confirm,
);

const getConfirmDeleteProductionUnit = (state: AppState): SuppliersState['confirmDeleteProductionUnit'] => state.app.suppliers && state.app.suppliers.confirmDeleteProductionUnit;

export const getConfirmDeleteProductionUnitSelector = createSelector(
    getConfirmDeleteProductionUnit,
    (confirm) => confirm,
);
const getBookingInspectionDataById = (state: AppState, orderId: string, inspectionId): SupplierBookingInspection => {
    const inspections = state.app.suppliers && state.app.suppliers.bookingData[orderId].inspections;
    return inspections.filter((insp) => insp.id === inspectionId)[0]
};
export const getBookingInspectionDataByIdSelector = createSelector(
    getBookingInspectionDataById,
    (insp) => insp,
);
const getBookingData = (state: AppState, orderId: string): SupplierBooking => {
    return state.app.suppliers && state.app.suppliers.bookingData[orderId];
};

const getBookingDataFetching = (state: AppState): boolean => {
    return state.app.suppliers && state.app.suppliers.isFetching;
};

export const getBookingDataSelector = createSelector(
    getBookingData,
    (bookingData) => bookingData,
);

export const getBookingDataFetchingSelector = createSelector(
    getBookingDataFetching,
    (isFetching) => isFetching,
);

const getBookingsData = (state: AppState): Booking => state.app.suppliers.bookings;
export const getBookingsSelector = createSelector(
    getBookingsData,
    (data) => data,
);

const isBookingDataFetching = (state: AppState): boolean => state.app.suppliers && state.app.suppliers.isFetching || false;
export const isBookingDataFetchingSelector: any = createSelector(
    [isBookingDataFetching],
    (isFecthing) => isFecthing,
);

export const getBookingDefaultDate = (state: AppState): string => state.app.suppliers.bookingDefaultDate;
export const getBookingDefaultDateSelector = createSelector(
    [getBookingDefaultDate],
    (defaultDate) => defaultDate,
);
export const getBookingId = (state: AppState): string => {
    const bd = state.app.suppliers.bookingData;
    return bd && (bd[Object.keys(bd)[0]].order.order_id);
}
export const getBookingIdSelector = createSelector(
    [getBookingId],
    (id) => id,
);

export const getSupplierAndProductionUnitRelationsSelector = (state: AppState): [string, string][] | 'loading' => {
    return (state.app.suppliers && state.app.suppliers.supplierAndProductionUnitRelations) || 'loading';
}
