import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit, AuditStatus as AuditStatusEnum } from '../../../backend_api/models';

type Props = {
    audit: Audit;
    className?: string;
};

const AuditStatus = (props: Props): React.ReactElement => {

    const { audit, className } = props;
    const finalConclusion = audit.final_conclusion;
    const auditorConclusion = audit.auditor_conclusion;
    const status = getStatusByAudit(audit);
    const hasAuditorConclusion = audit.auditor_conclusion;
    const isReport = audit.status === 'report';
    const statusClass = audit.approval_flow_status && isReport && (audit.approval_flow_status === 'disagree' || audit.approval_flow_status === 'not_set') ? 'text-white bg-status-pending' : 'text-white bg-status-' + status;
    const style = isReport ? { backgroundColor: finalConclusion && finalConclusion.background_color, color: finalConclusion && finalConclusion.text_color } : {};
    const statusElement = <div className={twMerge('flex space-x-1 w-40', className)}>
        {hasAuditorConclusion && <div className='w-3 rounded-l' style={{ backgroundColor: audit.auditor_conclusion.background_color }}></div>}
        <div className={twMerge('flex justify-center items-center py-3 text-xs', statusClass, className, hasAuditorConclusion ? 'rounded-r w-full' : 'w-full rounded')} style={style}>
            <span className='uppercase whitespace-pre-wrap flex leading-tight text-center'>{finalConclusion === null ? 
            (audit.approval_flow_status && isReport && (audit.approval_flow_status === 'disagree' || audit.approval_flow_status === 'not_set') ? <FormattedMessage id={'audit.approval_status.' + audit.approval_flow_status} />: <FormattedMessage id={'status.' + status} />)
             : 
             (isReport ? finalConclusion && finalConclusion.name.text : <FormattedMessage id={'status.' + status} />)}</span>
        </div>
    </div>;
    return (finalConclusion || auditorConclusion) && status !== 'planned' ? <Popup trigger={statusElement} className='w-72'>
        <div className='flex flex-col space-y-4'>
            {finalConclusion && isReport && <div className='space-y-2'>
                <div className='font-medium'><FormattedMessage id='audits.conclusion.final_conclusion' /></div>
                <div className='rounded px-2 w-2/3 justify-center text-smx flex' style={{ backgroundColor: finalConclusion.background_color, color: finalConclusion.text_color }}>
                    {finalConclusion.name.text}
                    </div>
                <div className='text-secondary'>{finalConclusion.description.text}</div>
            </div>}
            {auditorConclusion && <div className='space-y-2'>
                <div className='font-medium'><FormattedMessage id='audits.conclusion.auditors_conclusion' /></div>
                <div className='rounded px-2 w-2/3 justify-center flex text-smx' style={{ backgroundColor: auditorConclusion.background_color, color: auditorConclusion.text_color }}>
                    {auditorConclusion.name.text}</div>
                <div className='text-secondary'>{auditorConclusion.description.text}</div>
            </div>}
        </div></Popup> : statusElement;
};

export const getStatusByAudit = (audit: Audit): AuditStatusEnum => {
    if (audit.status === AuditStatusEnum.Planned && audit.locked) {
        return AuditStatusEnum.Downloaded;
    }
    return audit.status;
};

export default AuditStatus;
