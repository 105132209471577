import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { DropdownItemProps } from 'semantic-ui-react';
import { AppState, byId, OptionsType } from '../../../base/types';
import { Supplier } from '../../suppliers/types';
import { OrderListOrderExtended } from '../slices/inspectionsListSlice';
import { Inspection, InspectionType, Order } from '../types';
import { isInspectionNotLockedAndPlanned } from '../Utils';

const inspectionsMapSelector = (state: AppState): byId<Inspection> => state.app.inspections.inspectionsMap;
const orderWithInspectionsSelector = (state: AppState): Order => state.app.inspections.order;
const inspectionAddedSelector = (state: AppState): boolean => state.app.inspections.inspectionAdded;
const fetchingSelector = (state: AppState): boolean => state.app.inspections.isFetching;
const savingSelector = (state: AppState): boolean => state.app.inspections.isSaving || false;

const lastViewedInspectionPageSelector = (state: AppState): string => state.app.inspections.lastViewedInspectionPage;
const inspectionsBaseURLSelector = (state: AppState): string => state.app.inspections.inspectionsBaseURL;

const suppliersSelectorSelector = (state: AppState): DropdownItemProps[] => {
    const options: DropdownItemProps[] = [];
    if (state.app.inspections.suppliers) {
        state.app.inspections.suppliers.data.forEach((supplier: Supplier) => {
            options.push({
                key: supplier.id,
                value: supplier.id,
                text: supplier.name,
                description: supplier.number,
            });
        });
    }
    return options;
};
const suppliersSelector = (state: AppState): unknown => state.app.inspections.suppliers || [];

const suppliersSelectorById = (state: AppState): byId<Supplier> => {
    const byId = {};
    if (state.app.inspections.suppliers.loaded) {
        state.app.inspections.suppliers.data.forEach((supplier: Supplier) => {
            byId[supplier.id] = supplier;
        });
    }
    return byId;
};

const inspectionFiltersHiddenSelector = (state: AppState): string[] => state.app.inspections.activeHiddenFilters;

const weekViewSelector = (state: AppState): unknown => state.app.inspections.weekView;

const listViewScrollPosition = (state: AppState): number => state.app.inspections.listView.scrollPosition;
const listViewData = (state: AppState): unknown => state.app.inspections.listView.elements;
const listViewParams = (state: AppState): unknown => state.app.inspections.listView.params;

const weekViewFavoriteInspectorsSelector = (state: AppState): string[] => {
    return state.app.inspections.weekView.favoriteInspectors && state.app.inspections.weekView.favoriteInspectors.split(',');
};

const weekViewFavoriteInspectorsManullySelector = (state: AppState): boolean => {
    return state.app.inspections.weekView.setInspectorsManually;
};

const getWeekViewGroups = (state: AppState): { name: string; value: any }[] => {
    const groups = [];
    const filters = state.app.inspections.weekView.filters || [];
    if (filters && filters.length > 0) {
        filters.forEach((filter) => {
            if (filter.name === 'groups') {
                groups.push(filter.value);
            }
        });
    }
    return groups;
};

const currentAQLProtocolSelector = (state: AppState): string => state.app.inspections.order && state.app.inspections.order.sampling_protocol && state.app.inspections.order.sampling_protocol.id;

const inspectionsTypesSelectorSelector = (state: AppState, labelType: 'name' | 'tag' = 'name'): InspectionType[] => {
    const inspectionTypes = state.app.inspections.inspectionTypes;
    const options = [];
    inspectionTypes.forEach((type: InspectionType) => {
        const obj = {
            text: type[labelType] || type.name,
            key: type.tag || type.name,
            value: type,
        };
        options.push(obj);
    });
    options.push({ text: <FormattedMessage id='inspections.inspection_types.none' />, value: 'none', key: <FormattedMessage id='inspections.inspection_types.none' /> });
    return options;
};

const inspectionsTypesFilterSelectorSelector = (state: AppState): DropdownItemProps[] => {
    const inspectionTypes = state.app.inspections.inspectionTypes;
    const options: DropdownItemProps[] = [];
    inspectionTypes.forEach((type: InspectionType) => {
        const obj = {
            text: type.name,
            key: type.tag,
            value: type.id,
        };
        options.push(obj);
    });
    return options;
};
const inspectionsTypesOnOrderSelector = (state: AppState, order: (Order | OrderListOrderExtended)): DropdownItemProps| {value: {id: string}}[] => {
    const inspectionTypes: (DropdownItemProps | {value: {id: string}}) [] = [];
    const included: string[] = [];
    order.inspections.forEach((inspection: Inspection) => {
        if (inspection.inspection_type && !included.includes(inspection.inspection_type.id) && isInspectionNotLockedAndPlanned(inspection, true)) {
            included.push(inspection.inspection_type.id);
            inspectionTypes.push({
                text: inspection.inspection_type.name,
                value: inspection.inspection_type as any,
                id: inspection.inspection_type.id,
            });
        }
        if (!inspection.inspection_type && !included.includes('none') && isInspectionNotLockedAndPlanned(inspection, true)) {
            included.push('none');
            inspectionTypes.push({ text: <FormattedMessage id='inspections.inspection_types.none' />, value: { id: 'none' }, id: 'none' }) as unknown as DropdownItemProps;
        }
    });
    if (inspectionTypes.length > 1) {
        inspectionTypes.push({ text: <FormattedMessage id='inspections.inspection_types.all' />, value: { id: 'all' }, id: 'all' });
    }
    return inspectionTypes;
};

const inspectionsTypesSelector = (state: AppState): InspectionType[] => state.app.inspections.inspectionTypes;

const inspectionsTypesByIdSelector = (state: AppState): byId<InspectionType> => state.app.inspections.inspectionTypesById;
const hasInspectionsTypesSelector = (state: AppState): boolean => {
    if (state.app.inspections.inspectionTypes) {
        return state.app.inspections.inspectionTypes.length > 0;
    }
    return false;
};

const focussedInspectionIdSelector = (state: AppState): string => state.app.inspections.focussedInspectionId;
const isSameForAllInspectionsSelector = (state: AppState): boolean => state.app.inspections.isSameForAllInspections;
const isSameForAllInspectionsPermissionsSelector = (state: AppState): { hasPermissions: boolean; permissions: any } => state.app.inspections.isSameForAllInspectionsPermissions || { hasPermissions: false, permissions: {} };
const areAllInspectionsEqualSelector = (state: AppState): boolean => state.app.inspections.areAllInspectionsEqual;

const getCombinedInspectionMasterInspectionSelector = (state: AppState): Inspection => {
    return state.app.inspections.order.inspections[0];
};

export const getInspectionsMap = createSelector(
    [inspectionsMapSelector],
    (inspectionsMap) => inspectionsMap,
);

export const getOrderWithInspections = createSelector(
    [orderWithInspectionsSelector],
    (order) => order,
);

export const getInspectionAdded = createSelector(
    [inspectionAddedSelector],
    (added) => added,
);

export const getFetching = createSelector(
    [fetchingSelector],
    (isFetching) => isFetching,
);

export const getSaving = createSelector(
    [savingSelector],
    (isSaving) => isSaving,
);

export const getLastViewedInspectionPage = createSelector(
    [lastViewedInspectionPageSelector],
    (page) => page,
);

export const getLoadedSupplisers: any = createSelector(
    [suppliersSelector],
    (suppliers) => suppliers,
);
export const getLoadedSuppliersSelector: any = createSelector(
    [suppliersSelectorSelector],
    (suppliers) => suppliers,
);
export const getLoadedSuppliersById: any = createSelector(
    [suppliersSelectorById],
    (suppliersById) => suppliersById,
);

export const getInspectionHiddenFilters: any = createSelector(
    [inspectionFiltersHiddenSelector],
    (activeHiddenFilters) => activeHiddenFilters,
);

export const getCurrentAQLProtocolSelector = createSelector(
    [currentAQLProtocolSelector],
    (currentProtocolSelector) => currentProtocolSelector,
);

export const getWeekViewSelector: any = createSelector(
    [weekViewSelector],
    (weekView) => weekView,
);

export const getWeekViewFavoriteInspectors: any = createSelector(
    [weekViewFavoriteInspectorsSelector],
    (favoriteInspectors) => favoriteInspectors,
);

export const getWeekViewFavoriteInspectorsSetManually: any = createSelector(
    [weekViewFavoriteInspectorsManullySelector],
    (manually) => manually,
);

export const getWeekViewGroupsSelector: any = createSelector(
    [getWeekViewGroups],
    (weekViewGroups) => weekViewGroups,
);

export const getListViewScrollPosition: any = createSelector(
    [listViewScrollPosition],
    (position) => position,
);

export const getListViewDataSelector = createSelector(
    [listViewData],
    (data) => data,
);

export const getListViewParamsSelector: any = createSelector(
    [listViewParams],
    (params) => params,
);

export const getInspectionsBaseURL: any = createSelector(
    [inspectionsBaseURLSelector],
    (baseurl) => baseurl,
);

export const getInspectionTypes = createSelector(
    [inspectionsTypesSelector],
    (types) => types,
);
export const getInspectionTypesOnOrderSelector = createSelector(
    [inspectionsTypesOnOrderSelector],
    (types) => types,
);
export const getInspectionTypesSelector = createSelector(
    [inspectionsTypesSelectorSelector],
    (types) => types,
);

export const getInspectionTypesFilterSelector: any = createSelector(
    [inspectionsTypesFilterSelectorSelector],
    (types) => types,
);

export const getInspectionTypesById = createSelector(
    [inspectionsTypesByIdSelector],
    (types) => types,
);

export const hasInspectionTypes = createSelector(
    [hasInspectionsTypesSelector],
    (has) => has,
);

export const getFocussedInspectionId: any = createSelector(
    [focussedInspectionIdSelector],
    (focussedId) => focussedId,
);

export const getIsSameForAllInspections = createSelector(
    [isSameForAllInspectionsSelector],
    (isSame) => isSame,
);

export const getIsSameForAllInspectionsPermissionsSelector = createSelector(
    [isSameForAllInspectionsPermissionsSelector],
    (permissions) => permissions,
);

export const getAreAllInspectionsEqual: any = createSelector(
    [areAllInspectionsEqualSelector],
    (areEqual) => areEqual,
);

export const getCombinedInspectionMasterInspection: any = createSelector(
    [getCombinedInspectionMasterInspectionSelector],
    (master) => master,
);

export const getInspectionTypesOptionsByType = (inspectionTypes: InspectionType[], labelType: 'name' | 'tag' = 'name'): OptionsType[] => {
    const options = [];
    inspectionTypes.forEach((type: InspectionType) => {
        const obj = {
            text: type[labelType] || type.name,
            key: type.tag || type.name,
            value: type,
        };
        options.push(obj);
    });
    options.push({ text: <FormattedMessage id='inspections.inspection_types.none' />, value: 'none', key: <FormattedMessage id='inspections.inspection_types.none' /> });
    return options;
}
const getInspectionById = (state: AppState, id: string) => {
    const map = getInspectionsMap(state);
    if (map[id]) {
        return map[id];
    }
    const order = getOrderWithInspections(state);
    if (order.inspections[0].inspection_id === id) {
        return order.inspections[0];
    }
}
export const getInspectionByIdSelector = createSelector(
    [getInspectionById],
    (inspection) => inspection,
);

const getCurrentOrderId = (state: AppState) => state.app.inspections.order.order_id;
export const getCurrentOrderIdSelector = createSelector(
    [getCurrentOrderId],
    (id) => id,
);
