import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, Icon, Modal } from 'semantic-ui-react';
import { MAX_UPLOAD_FILE_SIZE, WARN_UPLOAD_FILE_SIZE } from '../../../base/config';
import { calculatePrettySize } from './handlers';

interface WarnBoxProps {
    showWarnBox: boolean; // Show the warning box or not
    actionHandler(proceed: boolean); // Function to close and proceed
    okFiles?: File[]; // Those files that are ok to upload as is
    tooBigFiles?: File[]; // Those files that are above upload max size limit
    warnFiles?: File[]; // Those files that are above warn-of-size limit
    canRemoveWarnFile: boolean; // Show remove warn file button
    removeWarnFileHandler?(file: File); // Function to remove a warn file from upload
}

const WarnBox = (props: WarnBoxProps): React.ReactElement => {
    const tooBigFiles: any = props.tooBigFiles || [];
    const okFiles: any = props.okFiles || [];
    const warnFiles: any = props.warnFiles || [];
    const warnFilesCnt = warnFiles.length
    const errorFilesCnt = tooBigFiles.length;
    const okFilesCnt = okFiles.length;
    const showWaring = warnFilesCnt > 0;
    const showError = errorFilesCnt > 0;
    const showOk = okFilesCnt > 0;
    const noFiles = !showWaring && !showError && !showOk;
    const onlyOkFiles = !showWaring && !showError && showOk;
    const onlyErrorFiles = !showWaring && !showOk && showError;
    const canRemoveWarnFile = props.canRemoveWarnFile;

    const warnBox = <Modal
        open={props.showWarnBox}
        className='warnBox'>
        <Modal.Header>
            {onlyOkFiles && <span><FormattedMessage id={'attachments.warnbox.header.ok_to_upload'} /></span>}
            {noFiles && <span><FormattedMessage id={'attachments.warnbox.header.no_files_to_upload'} /></span>}
            {!noFiles && <span>
                <FormattedMessage id={'attachments.warnbox.header.warning_prefix'} />
                {showError && <span>{errorFilesCnt} {errorFilesCnt === 1 ? <FormattedMessage id={'attachments.warnbox.header.error.single'} /> : <FormattedMessage id={'attachments.warnbox.header.error.multiple'} />}</span>}
                {showWaring && <span>{warnFilesCnt} {warnFilesCnt === 1 ? <FormattedMessage id={'attachments.warnbox.header.warning.single'} /> : <FormattedMessage id={'attachments.warnbox.header.warning.multiple'} />}</span>}
            </span>}
        </Modal.Header>
        <Modal.Content>
            {noFiles && <span><FormattedMessage id={'attachments.warnbox.content.no_more_files'} /><span className='link' onClick={(): void => {
                props.actionHandler(false);
            }}><FormattedMessage id={'attachments.warnbox.content.close_window'} /></span></span>}
            {showError &&
                <div className='error'>
                    <p>{errorFilesCnt} {errorFilesCnt === 1 ? <FormattedMessage id={'attachments.warnbox.content.error.single'} /> : <FormattedMessage id={'attachments.warnbox.content.error.multiple'} />} {calculatePrettySize(MAX_UPLOAD_FILE_SIZE)}:</p>
                    <ul className='files'>
                        {tooBigFiles.map((file: File, index) => {
                            return (<li key={index + '-warnbox'}><span className='fileName'>{file.name}</span> <span className='fileSize'>{calculatePrettySize(file.size)}</span></li>);
                        })}
                    </ul>
                    <p><Icon name='warning sign' />{errorFilesCnt === 1 ? <FormattedMessage id={'attachments.warnbox.content.error.include.single'} /> : <FormattedMessage id={'attachments.warnbox.content.error.include.multiple'} />}</p>
                    <Divider hidden />
                </div>}
            {showWaring && <div className='warning'>
                <p>{warnFilesCnt} {warnFilesCnt === 1 ? <FormattedMessage id={'attachments.warnbox.content.warning.single'} /> : <FormattedMessage id={'attachments.warnbox.content.warning.multiple'} />}{calculatePrettySize(WARN_UPLOAD_FILE_SIZE)}<FormattedMessage id={'attachments.warnbox.content.warning.postfix'} /></p>
                <ul className='files'>
                    {warnFiles.map((file, index) => {
                        return (<li key={index + 'files'}><span className='fileName'>{file.name}</span> <span className='fileSize'>{calculatePrettySize(file.size)}</span>. {canRemoveWarnFile && <Button onClick={(): void => props.removeWarnFileHandler(file)} floated='right' size='mini'> <FormattedMessage id={'attachments.warnbox.content.warning.exclude'} /></Button>}</li>);
                    })}
                </ul>
            </div>}
            {showOk && <div className='ok'>
                <p>{okFilesCnt} {okFilesCnt === 1 ? 'file is' : 'files are'} <FormattedMessage id={'attachments.warnbox.content.ok'} /></p>
                <ul className='files'>
                    {okFiles.map((file, index) => {
                        return (<li key={index + 'ok'}><span className='fileName'>{file.name}</span> <span className='fileSize'>{calculatePrettySize(file.size)}</span>.</li>);
                    })}
                </ul>
            </div>}
            {onlyErrorFiles && <p><FormattedMessage id={'attachments.warnbox.content.no_files'} />{calculatePrettySize(MAX_UPLOAD_FILE_SIZE)}.</p>}
        </Modal.Content>
        <Modal.Actions>
            {!noFiles && !onlyErrorFiles && <Fragment>
                <Button positive onClick={(): void => {
                    props.actionHandler(true);
                }}><FormattedMessage id={'attachments.warnbox.action.ok'} /></Button>
                <Button onClick={(): void => {
                    props.actionHandler(false);
                }}><FormattedMessage id={'attachments.warnbox.action.cancel'} /></Button>
            </Fragment>
            }
            {noFiles && <Button onClick={(): void => {
                props.actionHandler(false);
            }}><FormattedMessage id={'attachments.warnbox.action.close'} /></Button>}
            {onlyErrorFiles && <Button onClick={(): void => {
                props.actionHandler(false);
            }}><FormattedMessage id={'attachments.warnbox.action.close'} /></Button>}
        </Modal.Actions>
    </Modal>
    return warnBox;
}

export default WarnBox;