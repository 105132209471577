import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';

type Props = {
    isFetching: boolean;
};

const TwoFactorInputLoader = (props: Props): React.ReactElement => {
    const { isFetching } = props;
    return <Fragment>
        {isFetching ? <Loader size='small' active inline='centered'><span className='text-secondary'><FormattedMessage id='twoFactor.verifying' /></span></Loader> : null}
    </Fragment>
}
export default TwoFactorInputLoader;
