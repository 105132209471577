
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { Input } from 'semantic-ui-react';
import { AppState } from '../../../base/types';
import { validateEmail } from '../../../base/utils';
import { getSaving } from '../../inspections/selectors/selectors';
import { clearUserExistData, getEmailSentSelector, login, logout, requestNewPassword, resetPassword } from '../authenticationSlice';
import LoginBox from '../components/LoginBox';
import Logout from '../components/Logout';
import { getAuthenticated, getFetching, getMessage, getUserExists } from '../selectors';

type OwnProps = {
    type?: 'login' | 'reset';
};

type StateProps = {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: {
        exists: boolean;
        username: string;
    };
    isSaving: boolean;
    emailSent: boolean;
    message: any; // TODO: define type for message
};


type DispatchProps = {
    actions: {
        clearUserExistData: typeof clearUserExistData;
        login: typeof login;
        logout: typeof logout;
        resetPassword: typeof resetPassword;
        requestNewPassword: typeof requestNewPassword;
    };
};

type LoginProps = OwnProps & StateProps & DispatchProps & RouteComponentProps<unknown>;

type LoginState = {
    email: string;
    password: string;
    confirm_password: string;
    okToReset: boolean;
    isFetching: boolean;
    resetToken: string | string[];
    justReset: boolean;
    status: number;
    goToV1: boolean;
    referrer: string;
    token: string;
    emailValid: boolean;
    user: {
        exists: boolean;
        username: string;
    };
}
const initialState: LoginState = { email: '', password: '', token: '', justReset: false, status: 0, referrer: undefined, confirm_password: '', okToReset: false, isFetching: false, resetToken: '', goToV1: false, emailValid: false, user: { exists: false, username: undefined } };
class LoginInner extends Component<LoginProps, LoginState> {

    private inputElement: Input = null;
    private type: string;
    private resetToken: string | string[] = null;
    readonly state: LoginState = initialState;
    constructor(props) {
        super(props);
        this.changeAccount = this.changeAccount.bind(this);
        this.clearUserData = this.clearUserData.bind(this);
        this.handleForm = this.handleForm.bind(this);

    }

    renderLogin = (): React.ReactElement => {
        const { isAuthenticated } = this.props;

        if (!isAuthenticated) {
            return (
                <LoginBox
                    changeAccount={this.changeAccount}
                    handleChange={this.handleChange}
                    handleForm={this.handleForm}
                    isAuthenticated={this.props.isAuthenticated}
                    isFetching={this.props.isFetching}
                    user={this.props.user}
                    email={this.state.email}
                    usernameInput={(el: Input): Input => this.inputElement = el}
                    clearUserData={this.clearUserData}
                />
            )
        }
        return (<Logout isSaving={this.props.isSaving} />)
    }

    render(): React.ReactElement {
        const type = this.props.type || 'login';
        return (
            <span>
                {type === 'reset' && <div>
                   {/*  <ResetBox
                        resetToken={this.state.resetToken}
                        okToReset={this.state.okToReset}
                        requestNewPassword={this.props.actions.requestNewPassword}
                        handleChange={this.handleChange}
                        handleForm={this.handleForm}
                        isAuthenticated={this.props.isAuthenticated}
                        isFetching={this.props.isFetching}
                        user={this.props.user}
                        message={this.props.message}
                        email={this.state.email}
                        usernameInput={(el: Input): Input => this.inputElement = el}
                        status={this.state.status}
                        clearUserExistData={clearUserExistData}
                        justReset={this.state.justReset}
                        clearUserData={this.clearUserData}
                        token={this.state.token}
                        emailValid={this.state.emailValid}
                        emailSent={this.props.emailSent}
                    />
 */}                </div>}
                {type === 'login' && this.renderLogin()}
            </span>
        );
    }

    public UNSAFE_componentWillMount(): void {
        const qs = queryString.parse(this.props.history.location.search);
        if (typeof (qs.reset_token) !== 'undefined') {
            this.resetToken = qs.reset_token;
            this.setState({ resetToken: this.resetToken });
            // TODO: find safer way to extract resetToken from url / props
        } else {
            this.setState({ resetToken: null });
        }
    }
    public componentDidMount(): void {
        let referrer = 'no_ref';
        if (this.props.location.search.includes('referrer')) {
            referrer = this.props.location.search.split('?referrer=')[1];
        }

        this.setState({ referrer });
        this.type = this.props.type;
        if (this.inputElement !== null) {
            this.inputElement.focus();
        }
    }

    public componentDidUpdate(): void {
        this.type = this.props.type;
        if (this.inputElement !== null) {
            this.inputElement.focus();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: LoginProps): void {
        this.setState({ user: nextProps.user });
        if (nextProps.type === 'reset') {
            this.setState({ status: nextProps.message.status })
        }
        const qs = queryString.parse(this.props.history.location.search);
        if (typeof (qs.reset_token) !== 'undefined') {
            this.resetToken = qs.reset_token;
            this.setState({ resetToken: this.resetToken })
            // TODO: find safer way to extract resetToken from url / props
        } else {
            this.setState({ resetToken: null });
        }
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>, { name, value }: { name: 'email' | 'password' | 'token'; value: string }): void => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }), () => {
            if (this.type === 'reset') {
                this.setState({ emailValid: validateEmail(this.state.email) });
                this.setState({ okToReset: (this.state.password === this.state.confirm_password) && this.state.password.length > 0 });
            }
        });
    }

    private handleForm = (e: React.FormEvent<HTMLFormElement>): void => {
        this.setState({ isFetching: true });
        e.preventDefault();
        if (this.type === 'login') {
            this.props.actions.login({ email: this.state.email, password: this.state.password, token: this.state.token });
        }
        if (this.type === 'reset') {
            if (this.state.resetToken !== null) {
                setTimeout(() => {
                    this.props.actions.resetPassword({ password: this.state.password, resetToken: this.state.resetToken });
                    this.setState({ justReset: true });
                }, 1000)
            } else {
                this.props.actions.requestNewPassword(this.state.email);
            }
        }
    }

    private changeAccount(): void {
        this.inputElement.focus();
        this.setState({ user: undefined });
        this.props.actions.clearUserExistData();
    }

    private clearUserData(): void {
        this.setState({ justReset: false, user: undefined, resetToken: undefined, email: '', password: '', confirm_password: '', goToV1: false });
        this.props.actions.clearUserExistData();
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        actions: bindActionCreators(
            {
                clearUserExistData,
                login,
                logout,
                resetPassword,
                requestNewPassword,
            }, dispatch),
    };
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: getAuthenticated(state),
        isFetching: getFetching(state),
        message: getMessage(state),
        user: getUserExists(state),
        isSaving: getSaving(state),
        emailSent: getEmailSentSelector(state),
    };
};


const Login = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(LoginInner))

export default Login;
