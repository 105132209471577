import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from '../../../base/components/basic/Icon';
import Segment from '../../../base/components/basic/Segment';

type Props = {
    organisationName: string;
    resetLogin();
};

const TwoFactorResetRequired = (props: Props): React.ReactElement => {
    return <div className='flex justify-center mx-4 md:mx-0 md:mb-32 md:pb-2 pb-2'>

        <Segment
            className='flex w-full sm:w-136 flex-col'
            header={<div className=''>{props.organisationName}</div>
            }
            heading={<FormattedMessage id='twoFactor.code_needs_reset.headline' />}
            footer={<Link to='/login' onClick={() => props.resetLogin()} className='link noUnderline flex'><FormattedMessage id='globals.close' /></Link>}            
            >
            <div className='text-lg pb-4 flex'><Icon className='text-xl text-alert pr-1' name='error_outline' /><FormattedMessage id='twoFactor.code_needs_reset' /></div>
            <div><FormattedMessage id='twoFactor.code_needs_reset.contact_administrator'/></div>
        </Segment>
    </div>
}
export default TwoFactorResetRequired;
