import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { getShowModalSelector, showModal } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { resetInspection } from '../../actions';
import { Inspection } from '../../types';

type ResetProps = {
    className?: string;
    showTrigger?: boolean;
    inspection: Inspection;
    isCombinedInspection?: boolean;
};

const Reset = (props: ResetProps): React.ReactElement => {
    const { inspection, showTrigger = true, isCombinedInspection = false, className } = props;
    const dispatch = useAppDispatch();
    const [keepAssignedUser, setKeepAssignedUser] = useState(true);
    const [keepScheduledInspectionDate, setKeepScheduledInspectionDate] = useState(true);
    const [ready, setReady] = useState(false);
    const modalOpen = useSelector(getShowModalSelector);
    const intl = useIntl();
    const modalId = 'resetInspectorModal_' + inspection.inspection_id;
    const close = () => {
        dispatch(showModal({ id: modalId, show: false }));
        setKeepAssignedUser(true);
        setKeepScheduledInspectionDate(true);
    };
    useEffect(() => {
        setReady(true);
    }, [keepAssignedUser]);
    useEffect(() => {
        return () => close();
    }, []);
    if (ready) {
        return <Modal
            open={modalOpen[modalId] && modalOpen[modalId].show}
            onClose={(): void => close()}
            trigger={showTrigger ? <div className={twMerge(className)} onClick={() => dispatch(showModal({ show: true, id: modalId }))}><FormattedMessage id='inspection.reset'/></div> : null}
            closeIcon
            closeOnEscape
        >
            <ModalHeader>
                <FormattedMessage id='globals.confirmation' />
            </ModalHeader>
            <ModalContent>
                <div className='flex flex-col'>
                    <FormattedMessage id='inspection.reset_inspection.confirmation_text' />
                    <Checkbox
                        className='pb-1'
                        label={intl.formatMessage({ id: 'inspections.reset.keep_assigned_user' })}
                        checked={keepAssignedUser}
                        onClick={(evt, item): void => setKeepAssignedUser(item.checked)}
                    />
                    <Checkbox
                        label={intl.formatMessage({ id: 'inspections.reset.keep_scheduled_inspection_date' })}
                        checked={keepScheduledInspectionDate}
                        onClick={(evt, item): void => setKeepScheduledInspectionDate(item.checked)}
                    />
                </div>
            </ModalContent>
            <ModalActions>
                <Button
                    primary
                    onClick={
                        (): void => {
                            dispatch(resetInspection(isCombinedInspection ? inspection.master_inspection.id : inspection.inspection_id, keepAssignedUser, keepScheduledInspectionDate));
                            close();
                        }
                    }
                >
                    <FormattedMessage id='globals.confirmation.ok' />
                </Button>
                <Button onClick={(): void => close()}><FormattedMessage id='globals.confirmation.cancel' /></Button>
            </ModalActions>
        </Modal>
    }
    return null;
}
export default Reset;
