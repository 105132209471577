import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from '../../../base/utils';
import { TEMPORARY_USER_MODE } from '../../authentication/authenticationSlice';
import { getSaving } from '../../inspections/selectors/selectors';
import Logo from './Logo';
import Menu from './Menu';

type HeaderProps = {
    isAuthenticated: boolean;
}

const Header = (props: HeaderProps): React.ReactElement => {
    const [dir, setDir] = useState(undefined);
    const isTemporaryUserMode = !!sessionStorage.getItem(TEMPORARY_USER_MODE);
    useScrollPosition(({ prevPos, currPos }) => {
        const dirTmp = (prevPos.y < currPos.y ? 'UP' : 'DOWN');
        if (dirTmp !== dir/*  && (Math.abs(prevPos.y - currPos.y) > 20 */) {
            setDir(dirTmp);
        }
    });
    const isSaving = useSelector(getSaving)
    if (props.isAuthenticated || isTemporaryUserMode) {
        return (
            <div className={twMerge('top-header hideOnPrint bg-branding-brand', dir === 'UP' && 'sticky top-0 md:relative')}>
                <div className='inner'>
                    <Logo
                        isSaving={isSaving}
                        isAuthenticated={props.isAuthenticated} />
                    <Menu
                        isAuthenticated={props.isAuthenticated}
                        isSaving={isSaving}
                    />
                </div>
            </div>);
    }
    return (<div className='bg-branding-brand p-4 mb-12 justify-center flex'>
        <Logo
            isSaving={isSaving}
            isAuthenticated={props.isAuthenticated} />
    </div>);
};

export default Header;
