import classnames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type InspectorIconProps = {
    className?: string;
    onClick?(e?: React.MouseEvent);
};

const InspectorIcon = (props: InspectorIconProps): React.ReactElement => {
    const { className, onClick } = props;
    return <div className={twMerge('flex items-center', className)} onClick={onClick ? (e): void => onClick(e) : null}>
        <i className={classnames('mi')}>person_outline</i>

    </div>
}
export default InspectorIcon;
