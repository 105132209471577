import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, DropdownProps, Icon } from 'semantic-ui-react';
import { AppState } from '../../../base/types';
import { useAppDispatch } from '../../../store';
import { getProductionUnits } from '../actions';
import { getProductionUnitsListSelectorSelector, isSupplierDataFetchingSelector } from '../selectors';
import { loadingProductionUnitMessage as loadingProductionUnitMessageFragment, noProductionUnitsInfoIcon } from '../../productionUnits/components/productionUnitSelector/shared';

type ProductionUnitsSelectorProps = {
    supplierId: string;
    showLabel?: boolean;
    disabled?: boolean;
    name?: string;
    value?: string;
    open?: boolean;
    showUnassigned?: boolean;
    dataTestId?: string;
    handleChange(event: React.SyntheticEvent, item: DropdownProps): void;
    handleBlur?(event: React.SyntheticEvent, item: DropdownProps): void;
};

/**  Look at ControlledProductionUnitSelector as an alternative to this one. */

const ProductionUnitsSelector = (props: ProductionUnitsSelectorProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { supplierId, value, handleChange, handleBlur, disabled, showUnassigned = false, name } = props;
    const none = showUnassigned ? 1 : 0;
    const productionUnits = useSelector((state: AppState) => getProductionUnitsListSelectorSelector(state, supplierId, showUnassigned));
    const isFetching = useSelector(isSupplierDataFetchingSelector);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching]);
    useEffect(() => {
        if (supplierId) {
            dispatch(getProductionUnits(supplierId, false));
        }
    }, [supplierId]);

    if (!isLoading && productionUnits.length === none) {
        return noProductionUnitsInfoIcon(intl);
    }

    if (productionUnits.length > none) {
        return <Dropdown
            value={value}
            fluid
            selection
            placeholder={intl.formatMessage({ id: 'audits.select_production_unit' })}
            search
            options={productionUnits as DropdownItemProps[]}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name || 'target_production_unit.id'}
            disabled={disabled}
            data-test-id={props.dataTestId}
        />
    }
    return isLoading && loadingProductionUnitMessageFragment();
}
export default ProductionUnitsSelector;


