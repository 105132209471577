import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, List, Modal } from 'semantic-ui-react';
import { getInspectionTypes } from '../../../inspections/selectors/selectors';
import { InspectionType } from '../../../inspections/types';

export type InspectionTypeSelectionModalProps = {
    open: boolean;
    cancel();
    select(type: InspectionType);
}

const InspectionTypeSelectionModal = (props: InspectionTypeSelectionModalProps): React.ReactElement => {
    const inspectionTypes = useSelector(getInspectionTypes);

    return <Modal
        open={inspectionTypes.length > 0 && props.open}
        size='mini'
        dimmer='inverted'
        className='CISelectInspectionType'
    >
        <Modal.Header><FormattedMessage id='inspections.combined_inspection.choose_inspection_type' /></Modal.Header>
        <Modal.Content>
            <div className='mb-2'><FormattedMessage id='inspections.inspection_types_selection_modal.choose_inspection_type' /></div>
            <List>
                {inspectionTypes.map((inspectionType: InspectionType) => {
                    return <List.Item className='inspectionType' key={inspectionType.id}>
                        <Button onClick={(): void => props.select(inspectionType)} size='mini' positive>{inspectionType.name}</Button>
                    </List.Item>;
                })}
                <List.Item className='inspectionType'><Button onClick={(): void => props.select(null)} size='mini' positive><FormattedMessage id='inspections.inspection_types.fake_none' /></Button></List.Item>
            </List>
        </Modal.Content>
        <Modal.Actions><Button onClick={props.cancel}><FormattedMessage id='globals.cancel' /></Button></Modal.Actions>
    </Modal>
}

export default InspectionTypeSelectionModal;