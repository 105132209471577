import { Checklist } from './types';

// Create new checklist
export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export type CREATE_CHECKLIST = {
    isFetching: boolean;
};

export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS';
export type CREATE_CHECKLIST_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const CREATE_CHECKLIST_FAILURE = 'CREATE_CHECKLIST_FAILURE';
export type CREATE_CHECKLIST_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Delete checklist
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export type DELETE_CHECKLIST = {
    isFetching: boolean;
};

export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS';
export type DELETE_CHECKLIST_SUCCESS = {
    isFetching: boolean;
};

export const DELETE_CHECKLIST_FAILURE = 'DELETE_CHECKLIST_FAILURE';
export type DELETE_CHECKLIST_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Copy checklist
export const COPY_CHECKLIST = 'COPY_CHECKLIST';
export type COPY_CHECKLIST = {
    isFetching: boolean;
};

export const COPY_CHECKLIST_SUCCESS = 'COPY_CHECKLIST_SUCCESS';
export type COPY_CHECKLIST_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const COPY_CHECKLIST_FAILURE = 'COPY_CHECKLIST_FAILURE';
export type COPY_CHECKLIST_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Create checklist checkpoint
export const ADD_CHECKLIST_CHECKPOINT = 'ADD_CHECKLIST_CHECKPOINT';
export type ADD_CHECKLIST_CHECKPOINT = {
    isFetching: boolean;
};

export const ADD_CHECKLIST_CHECKPOINT_SUCCESS = 'ADD_CHECKLIST_CHECKPOINT_SUCCESS';
export type ADD_CHECKLIST_CHECKPOINT_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const ADD_CHECKLIST_CHECKPOINT_FAILURE = 'ADD_CHECKLIST_CHECKPOINT_FAILURE';
export type ADD_CHECKLIST_CHECKPOINT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Delete checklist checkpoint
export const DELETE_CHECKLIST_CHECKPOINT = 'DELETE_CHECKLIST_CHECKPOINT';
export type DELETE_CHECKLIST_CHECKPOINT = {
    isFetching: boolean;
};


// checkpoint delete request failed
export const DELETE_CHECKLIST_CHECKPOINT_FAILED = 'DELETE_CHECKLIST_CHECKPOINT_FAILED';
export type DELETE_CHECKLIST_CHECKPOINT_FAILED = {
    failed: boolean;
}

export const DELETE_CHECKLIST_CHECKPOINT_SUCCESS = 'DELETE_CHECKLIST_CHECKPOINT_SUCCESS';
export type DELETE_CHECKLIST_CHECKPOINT_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const DELETE_CHECKLIST_CHECKPOINT_SUCCESS_FORCE_STATE = 'DELETE_CHECKLIST_CHECKPOINT_SUCCESS_FORCE_STATE';
export type DELETE_CHECKLIST_CHECKPOINT_SUCCESS_FORCE_STATE = {
    success: boolean;
}

export const DELETE_CHECKLIST_CHECKPOINT_FAILURE = 'DELETE_CHECKLIST_CHECKPOINT_FAILURE';
export type DELETE_CHECKLIST_CHECKPOINT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Update checklist content
export const UPDATE_CHECKLIST_CONTENT = 'UPDATE_CHECKLIST_CONTENT';
export type UPDATE_CHECKLIST_CONTENT = {
    isFetching: boolean;
};

export const UPDATE_CHECKLIST_CONTENT_SUCCESS = 'GET_CHECKLIST_CONTENT_SUCCESS';
export type UPDATE_CHECKLIST_CONTENT_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const UPDATE_CHECKLIST_CONTENT_FAILURE = 'UPDATE_CHECKLIST_CONTENT_FAILURE';
export type UPDATE_CHECKLIST_CONTENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Get data for a given checklist component
export const GET_CHECKLIST_CONTENT = 'GET_CHECKLIST_CONTENT';
export type GET_CHECKLIST_CONTENT = {
    isFetching: boolean;
};

export const GET_CHECKLIST_CONTENT_SUCCESS = 'GET_CHECKLIST_CONTENT_SUCCESS';
export type GET_CHECKLIST_CONTENT_SUCCESS = {
    isFetching: boolean;
    data: Checklist;
};

export const GET_CHECKLIST_CONTENT_FAILURE = 'GET_CHECKLIST_CONTENT_FAILURE';
export type GET_CHECKLIST_CONTENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Data for checklists component
export const GET_CHECKLISTS = 'GET_CHECKLISTS';
export type GET_CHECKLISTS = {
    isFetching: boolean;
};

export const GET_CHECKLISTS_SUCCESS = 'GET_CHECKLISTS_SUCCESS';
export type GET_CHECKLISTS_SUCCESS = {
    isFetching: boolean;
    data: Checklist[];
};

export const GET_AUDIT_CHECKLISTS_SUCCESS = 'GET_AUDIT_CHECKLISTS_SUCCESS';
export type GET_AUDIT_CHECKLISTS_SUCCESS = {
    isFetching: boolean;
    data: Checklist[];
}

export const GET_INSPECTION_CHECKLISTS_SUCCESS = 'GET_INSPECTION_CHECKLISTS_SUCCESS';
export type GET_INSPECTION_CHECKLISTS_SUCCESS = {
    isFetching: boolean;
    data: Checklist[];
}

export const GET_CHECKLISTS_FAILURE = 'GET_CHECKLISTS_FAILURE';
export type GET_CHECKLISTS_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// A selector for checklists - e.g. used in inspections module
export const GET_CHECKLISTS_SELECTOR = 'GET_CHECKLISTS_SELECTOR';
export type GET_CHECKLISTS_SELECTOR = {
    isFetching: boolean;
};

export const GET_CHECKLISTS_SELECTOR_SUCCESS = 'GET_CHECKLISTS_SELECTOR_SUCCESS';
export type GET_CHECKLISTS_SELECTOR_SUCCESS = {
    isFetching: boolean;
    data: Checklist[];
    inspectionId: string;
};

export const GET_CHECKLISTS_SELECTOR_FAILURE = 'GET_CHECKLISTS_SELECTOR_FAILURE';
export type GET_CHECKLISTS_SELECTOR_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Attach image to checkpoint
export const ATTACH_IMAGE_TO_CHECKPOINT = 'ATTACH_IMAGE_TO_CHECKPOINT';
export type ATTACH_IMAGE_TO_CHECKPOINT = {
    isFetching: boolean;
};

export const ATTACH_IMAGE_TO_CHECKPOINT_SUCCESS = 'ATTACH_IMAGE_TO_CHECKPOINT_SUCCESS';
export type ATTACH_IMAGE_TO_CHECKPOINT_SUCCESS = {
    isFetching: boolean;
    data: Checklist; // TODO: Check type
};

export const ATTACH_IMAGE_TO_CHECKPOINT_FAILURE = 'ATTACH_IMAGE_TO_CHECKPOINT_FAILURE';
export type ATTACH_IMAGE_TO_CHECKPOINT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};
