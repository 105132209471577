import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReportDefect } from '../../../../../backend_api/models';
import { AppState } from '../../../../../base/types';
import { getStringsSeparatedBy, isUndefinedOrNullOrEmptyString, objectIsEmpty } from '../../../../../base/utils';
import Comments from '../../../../comments/components/Comments';
import WriteComment from '../../../../comments/components/WriteComment';
import { getCommentsByTypeSelector, showTranslatedCommentsSelector } from '../../../../comments/selectors';
import getCommentsByDefect from '../../../../comments/util';
import { CorrectiveActionsFromDefectOrCheckpointsType } from '../../../../correctiveActions/components/CorrectiveActionsFromCheckpointsWizard';
import { TranslateText } from '../../../../globals/components';
import { SeverityToggler } from '../../../../globals/components/Severity';
import ContentSection from '../../../../pageLayouts/components/ContentSection';
import { setDefectSeverity } from '../../../slices/inspectionReportSlice';
import { DefectType, Inspection } from '../../../types';
import ItemsAffected from '../ItemsAffected';
import { getEvidenceImages } from '../checkpoints/CheckpointItem';
import CheckpointImage from '../report2/CheckpointImage';
import ImagesContainer from '../report2/ImagesContainer';
import DefectsAndCheckpointsContextMenu from './DefectsAndCheckpointsContextMenu';
import InspectionReportCorrectiveAction from './InspectionReportCorrectiveAction';

type Props = {
    className?: string;
    defect: ReportDefect;
    inspection: Inspection;
    inspectionId: string;
};

const InspectionReportDefect = (props: Props): React.ReactElement => {
    const { className, defect, inspectionId, inspection } = props;
    const severity = defect.severity;
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    const canCreateComments = inspection.features.includes('create_comments')
    const canCreateCorrectiveActionFeature = inspection.features.includes('corrective_action_for_quality.create');
    const commentRef = useRef(null)
    let evidenceImages = [];
    defect.corrective_actions.length > 0 && defect.corrective_actions.map((ca) => {
        const f = ca.evidence.filter((ev) => ev.type === 'image').map((img) => ({ url: img.preview_url, ca_status: ca.state }));
        evidenceImages = [...evidenceImages].concat(f);
    })
    const comments = useSelector((state: AppState) => getCommentsByTypeSelector(state, 'inspection', 'defect', null));
    const dComments = getCommentsByDefect.getCommentsByDefect(comments, defect.id)
    const hasComments = !objectIsEmpty(dComments);
    const canCreateCorrectiveAction = inspection.status === 'report' && canCreateCorrectiveActionFeature;

    const contextMenu = <div className='flex w-full flex-0 sm:justify-end justify-start h-fit-content space-x-1 pb-4 sm:pb-0'>
        <DefectsAndCheckpointsContextMenu className='py-0 bg-transparent border-none'
            inspectionId={inspectionId}
            itemId={defect.id}
            createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Inspection}
            itemType={'defect'}
            commentRef={commentRef}
            comments={dComments}
            type='defect'
            canCreateCorrectiveAction={canCreateCorrectiveAction}
        />
    </div>

    const severityToggler =
        <SeverityToggler
            currentSeverity={severity}
            setDefectSeverity={setDefectSeverity}
            defect={defect}
            inspectionId={inspectionId}
            scrollToTop={false}
        />;

    const content = <div>
        <a id={'defect_' + defect.id} />
        <div className='flex flex-col sm:flex-row space-x-1 sm:pb-4'>
            <div className='mb-2 sm:mb-0'>{severityToggler}</div>
            <div className='flex flex-col'>
                <div className='flex space-y-1 flex-col w-full'>
                    <div className='text-xl font-bold'  >{getDefectType(defect.type)}</div>
                    <div className='text-xl'>{getDefectDescription(defect, true, showTranslatedComments)}</div>
                </div>
                <div className='pb-2 sm:pb-0'><ItemsAffected pcs={defect.quantity_affected} all={defect.whole_lot} className='text-sm text-secondary' /></div>
            </div>
            {contextMenu}
        </div>
        <div className='flex flex-col w-full my-2'>
            {defect.corrective_actions.map((ca) => {
                return <InspectionReportCorrectiveAction inspectionId={inspectionId} key={'irca_' + ca.id + defect.id} correctiveAction={ca} />
            })}
        </div>
        <ImagesContainer>
            {defect.photos.map((imageURL, i) => {
                return <>

                    <CheckpointImage imageURL={imageURL} inspection={inspection} typeId={defect.id} type='defect_image' key={'defectImage_' + imageURL} />
                    {/* <ImageContainer imageURL={imageURL} /> */}
                </>
            })}
        </ImagesContainer>
        <ImagesContainer>
            {getEvidenceImages(evidenceImages, inspection, defect.id, 'defect_image')}
        </ImagesContainer>
        {hasComments && <div ref={commentRef}>
            <Comments type='defect' typeId={defect.id} showCollapsed={false} hideZero={true} inspection={inspection} />
            {canCreateComments && <WriteComment showModal={false}
                id={defect.id}
                type={'defect'}
            />}
        </div>}
    </div >

    return <ContentSection content={content} className='border-none mb-0 pb-0 pl-0 pr-0' contentClassName='border-b pb-8' />
}
export default InspectionReportDefect;

export const getDefectType = (type: DefectType): React.ReactElement => {
    return type && <span>{getStringsSeparatedBy([type.code, type.name], '.')}</span>
};

export const getDefectDescription = (defect: ReportDefect, showToggleActions = true, showTranslatedComments = false): React.ReactElement => {
    return !isUndefinedOrNullOrEmptyString(defect.description) &&
        <TranslateText
            showTranslatedComments={showTranslatedComments}
            translateObj={defect.translated_description}
            originalLanguage={defect.language}
            originalText={defect.description}
            showToggleActions={showToggleActions}
        />;
};

