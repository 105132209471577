import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { twMerge } from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import { Inspection } from '../../../types';
import InspectionReportDefect from './InspectionReportDefect';

type Props = {
    className?: string;
    inspection: Inspection;
};

const InspectionReportDefects = (props: Props): React.ReactElement => {
    const { className, inspection } = props;
    const defects = props.inspection.defects;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('defectsFull', className)}>
        {defects.critical.map((defect) => {
            return <InspectionReportDefect key={'ird_' + defect.id} defect={defect} inspectionId={inspection.inspection_id} inspection={inspection} />
        })}
        {defects.major.map((defect) => {
            return <InspectionReportDefect key={'ird_' + defect.id} defect={defect} inspectionId={inspection.inspection_id} inspection={inspection} />
        })}
        {defects.minor.map((defect) => {
            return <InspectionReportDefect key={'ird_' + defect.id} defect={defect} inspectionId={inspection.inspection_id} inspection={inspection} />
        })}
    </div>
}
export default InspectionReportDefects;
