import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { DisplaySupplier } from '../../../backend_api/models';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { twMerge } from '../../../base/utils';

type Props = {
    className?: string;
    relatedSuppliers: DisplaySupplier[];
    onUserWantedToRemoveSupplier(supplierId: string): void;
    edit: boolean;
};

const DetailedProductionUnitRelatedSuppliers = (props: Props): React.ReactElement => {
    const { className, relatedSuppliers, edit } = props;
    const intl = useIntl();
    return <div className={twMerge('w-1/2 mt-4', className)}>
        <h5><FormattedMessage id='production_units.relations.related_suppliers' /></h5>
        {relatedSuppliers.length === 0 && <div className='pb-4'><FormattedMessage id='production_units.relations.no_related_suppliers' /></div>}
        {relatedSuppliers.map((rs: DisplaySupplier) => {
            return (
                <div key={'rs_' + rs.id} className='flex justify-between items-center mb-4'>
                    <Link className='link' to={'/suppliers/' + rs.id}>{rs.name}{rs.number && '. ' + rs.number}</Link>
                    {edit &&
                        <div className=''>
                            <DeleteConfirm
                                deleteText={intl.formatMessage({ id: 'globals.confirmation.remove' })}
                                trigger={<div className='bg-surface-interactive rounded text-smx px-1 cursor-pointer'><FormattedMessage id='globals.confirmation.remove' /></div>}
                                deleteFunction={() => props.onUserWantedToRemoveSupplier(rs.id)} />
                        </div>}
                </div>
            )
        })}
    </div>
}
export default DetailedProductionUnitRelatedSuppliers;
