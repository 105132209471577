import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Inspection } from '../../../inspections/types';

type BookingStatusProps = {
    inspection: Inspection;
};

const BookingStatus = (props: BookingStatusProps): React.ReactElement => {
    const inspection = props.inspection;
    let content: string | React.ReactElement = '-';
    if (inspection.overdue) {
        content = <div className='overdue'><FormattedMessage id='supplier_booking.bookings.marker.overdue' /></div>;
    }
    return <div className='bookingStatus'>{content}</div>;
};
export default BookingStatus;
