import React, { Component, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Header } from 'semantic-ui-react';
import { AppState } from '../../../base/types';
import DropZone from '../../globals/components/DropZone';
import { addAttachment, clearUploads, getAttachments, removeAttachment as removeAttachmentAction } from '../actions/actions';
import AttachmentsList from '../components/AttachmentsList';
import { FileSelector } from '../components/FileSelector';
import { handleFileDrop, handleWarning, isUploadComplete, removeAttachment, removeWarnFile, uploadFiles } from '../components/handlers';
import Uploading from '../components/Uploading';
import WarnBox from '../components/WarnBox';
import { attachmentsInitialState } from '../constants';
import { getAttachments as getAttachmentsSelector, getUploading as getUploadingSelector } from '../selectors';
import { Attachment, AttachmentsLocalState, AttachToType, UploadingFile } from '../types';


interface OwnProps {
    attachToType: AttachToType;
    attachToItemId: string; // Id of the item that the attachment is to be attached to
    disabled?: boolean;
    visible: boolean;
    toggle(visible: boolean, id: string);
    children?: ReactNode;
    fileCountChanged?(count: number);
}

interface StateProps {
    attachmentProgress: number;
    attachments: Attachment[];
    uploading: UploadingFile[];
}

interface DispatchProps {
    actions: {
        addAttachment(id: string, type: string, file: File);
        getAttachments(id: string, type: string);
        removeAttachmentAction(id: string, type: string, attachmentURL: string);
        clearUploads(id: string, type: string);
    };
}

type AttachmentsProps = OwnProps & StateProps & DispatchProps;

class Attachments extends Component<AttachmentsProps, AttachmentsLocalState> {
    readonly state: AttachmentsLocalState = attachmentsInitialState;
    constructor(props: AttachmentsProps) {
        super(props);
        this.updateUploadState = this.updateUploadState.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.removeAttachmentHandler = this.removeAttachmentHandler.bind(this);
        this.isUploadComplete = this.isUploadComplete.bind(this);
        this.clearUploadsHandler = this.clearUploadsHandler.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
        this.removeWarnFile = this.removeWarnFile.bind(this);
        this.handleFileDrop = this.handleFileDrop.bind(this);
    }

    public render(): React.ReactElement {
        const elements = <Fragment>{this.props.children}
            <span className={['pt-8 inner ', this.props.visible ? 'show' : 'hide'].join(' ')}>
                <Header as='h4'><FormattedMessage id='attachments.attachments' /></Header>
                <FileSelector
                    uploadFiles={this.uploadFiles}
                    updateStateHandler={this.updateUploadState}
                    hasDrop={!this.props.disabled}
                    isComplete={this.state.upload.isComplete}
                    disabled={this.props.disabled}
                    multiple={true}
                    isUploading={this.state.uploading.length > 0}
                />
                <Uploading
                    files={this.state.uploading}
                    isComplete={this.state.upload.isComplete}
                    autoClear={true}
                    clearUploadsHandler={this.clearUploadsHandler}
                    showCompleteMessage={false}
                    reUpload={this.handleReUpload}
                />
                <AttachmentsList
                    attachments={this.state.attachments}
                    removeAttachment={this.removeAttachmentHandler}
                    disabled={this.props.disabled}
                    loading={!this.state.loaded}
                    showEmpty={true}
                />
                <WarnBox
                    tooBigFiles={this.state.upload.tooBigFiles}
                    warnFiles={this.state.upload.warnFiles}
                    okFiles={this.state.upload.okFiles}
                    actionHandler={this.handleWarning}
                    removeWarnFileHandler={this.removeWarnFile}
                    showWarnBox={this.state.upload.showWarn}
                    canRemoveWarnFile={true}
                /></span></Fragment>;
        return (
            <div className='attachments'>
                {this.props.disabled ? elements : <DropZone onDrop={this.handleFileDrop} >
                    {elements}
                </DropZone>}
            </div>
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: AttachmentsProps): void {
        if (nextProps.visible && !this.state.loaded) {
            this.setState({ loaded: true });
            this.props.actions.getAttachments(this.props.attachToItemId, this.props.attachToType);
        }
        if (nextProps.attachments) {
            if (this.props.fileCountChanged && this.props.attachments && this.props.attachments.length !== nextProps.attachments.length) {
                this.props.fileCountChanged(nextProps.attachments.length);
            }
            this.setState({ attachments: nextProps.attachments });
        }
        if (this.state.uploading) {
            this.setState({ uploading: nextProps.uploading }, () => {
                this.isUploadComplete();
            });
        }
    }

    private cancel(): void {
        this.updateUploadState('droppedFiles', []);
    }

    private handleWarning(proceed: boolean): void {
        this.setState(handleWarning(proceed, (file: File) => this.props.actions.addAttachment(this.props.attachToItemId, this.props.attachToType, file)));
    }

    private isUploadComplete(): void {
        if (!(this.state.waitingForUploadedAttachments > this.props.attachments.length)) {
            this.setState(isUploadComplete());
        }
    }

    private handleReUpload = (files: File[]): void => {
        this.setState({ upload: { droppedFiles: files } }, () => this.uploadFiles());
    }

    private handleFileDrop = (files: FileList): void => {
        this.props.toggle(true, this.props.attachToItemId);
        this.setState(handleFileDrop(files), () => this.uploadFiles());
    }

    private uploadFiles(): void {
        this.setState(uploadFiles((file: File) => this.props.actions.addAttachment(this.props.attachToItemId, this.props.attachToType, file)), () => {
            let waitingForUploadedAttachments = this.state.waitingForUploadedAttachments;
            if (this.props.attachments.length > waitingForUploadedAttachments) {
                waitingForUploadedAttachments = this.props.attachments.length + 1;
            }
            else {
                waitingForUploadedAttachments = waitingForUploadedAttachments + 1;
            }
            this.setState({ waitingForUploadedAttachments })
        });
    }

    private updateUploadState(item: string, data: boolean | number | string | File[]): void {
        const upload = Object.assign({}, this.state.upload);
        upload[item] = data;
        this.setState({ upload });
    }

    private removeAttachmentHandler(attachmentURL: string): void {
        this.setState(removeAttachment(attachmentURL));
    }

    private removeWarnFile(file: File): void {
        this.setState(removeWarnFile(file));
    }
    private clearUploadsHandler(): void {
        this.props.actions.clearUploads(this.props.attachToItemId, this.props.attachToType);
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        attachmentProgress: 0,
        attachments: getAttachmentsSelector(state, ownProps.attachToItemId, ownProps.attachToType),
        uploading: getUploadingSelector(state, ownProps.attachToItemId, ownProps.attachToType),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        addAttachment,
        clearUploads,
        getAttachments,
        removeAttachmentAction,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Attachments);
