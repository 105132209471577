import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useSelector } from 'react-redux';
import { setLayoutAttribute } from '../../../base/baseSlice';
import { getLayoutSelector } from '../../../base/selectors';
import { useAppDispatch } from '../../../store';
import MobileProfile from './MobileProfile';

type MobileMenuProps = {
    children: React.ReactElement;
}
const MobileMenu = (props: MobileMenuProps): React.ReactElement => {
    const { children } = props;
    const dispatch = useAppDispatch();
    const isOpen = useSelector(getLayoutSelector).mobileMenuOpen;
    return <div className='mobileMenu'>
        <Menu isOpen={isOpen} onStateChange={(state): void => {
            dispatch(setLayoutAttribute('mobileMenuOpen', state.isOpen));
            dispatch(setLayoutAttribute('bodyFixed', state.isOpen));
        }} right>
            <MobileProfile />
            {children}
        </Menu>
    </div>
};

export default MobileMenu;

