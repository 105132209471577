import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthenticationAuthorization, TwoFactorRequired } from '../../../backend_api/models';
import { TempUserAuthorization } from '../../../backend_api_2';
import Label from '../../../base/components/basic/Label';
import Segment from '../../../base/components/basic/Segment';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setOrganization } from '../authenticationSlice';

type Props = {
    className?: string;
    authenticationList: (AuthenticationAuthorization | TwoFactorRequired | TempUserAuthorization)[];
};

const SelectOrganisation = (props: Props): React.ReactElement => {
    const { className, authenticationList } = props;
    const dispatch = useAppDispatch();
    const normalOrganizations = authenticationList.filter(a => !a['context_reference']);
    const tempUserAuthorizations = authenticationList.filter(a => !!a['context_reference']);

    return <div className='flex w-full justify-center mx-4 md:mx-0 md:mb-32'>
        <Segment className='flex sm:w-136 w-full flex-col'
            footer={<Link to='/login' onClick={() => window.location.reload()} className='link noUnderline'><FormattedMessage id='globals.cancel' /></Link>}
            heading={<h2 className='justify-center flex'><FormattedMessage id='login.select_organisation' /></h2>}
        >
            <div className='flex flex-col items-center'>                
                <p><FormattedMessage id='login.select_organisation_to_sign_into' /></p>
                <div className='py-4 space-y-2 justify-center flex flex-col items-center'>
                    {normalOrganizations.map((auth, index) => {
                        const resetRequired = (auth as TwoFactorRequired).two_factor_state === 'reset_required';
                        return <div key={auth.organization_id} className='cursor-pointer'>
                            <div className='flex flex-col justify-center'>
                                <div className='justify-center flex'>
                                    <Label disabled={resetRequired} className={twMerge('py-2 px-4 bg-branding-brand', resetRequired && 'bg-disabled cursor-default')} onClick={resetRequired ? null : (): any => dispatch(setOrganization({ organization: normalOrganizations[index] }))} key={auth.organization_id}>{auth.organization_name}</Label>
                                </div>
                                {resetRequired && <span className='text-center w-72 text-sm text-secondary'><FormattedMessage id='twoFactor.org_switcher.requires_reset.contact_adm'/></span>}
                            </div>
                        </div>
                    })}
                    {
                        tempUserAuthorizations.length > 0 &&
                        <div className='my-2'>
                            {/* <div className='font-bold'>Audits from outside your organization</div> */}
                            <div className='flex flex-col justify-center items-center my-2'>
                            {tempUserAuthorizations.map((auth, index) => {
                                const resetRequired = (auth as TwoFactorRequired).two_factor_state === 'reset_required';
                                return <div key={auth.organization_id} className='cursor-pointer'>
                                    <div className='flex flex-col justify-center'>
                                        <div className='justify-center flex'>
                                            <Label disabled={resetRequired} className={twMerge('py-2 px-4 bg-gray-500 flex flex-col items-center', resetRequired && 'bg-disabled cursor-default')} onClick={resetRequired ? null : (): any => dispatch(setOrganization({ organization: tempUserAuthorizations[index] }))} key={auth.organization_id}>
                                                <div>
                                                    {(auth as TempUserAuthorization).context_reference.reference_name || "Audit"} requested by
                                                </div>
                                                <div>
                                                    {auth.organization_name}
                                                </div>
                                            </Label>
                                        </div>
                                        {resetRequired && <span className='text-center w-72 text-sm text-secondary'><FormattedMessage id='twoFactor.org_switcher.requires_reset.contact_adm'/></span>}
                                    </div>
                                </div>
                            })}
                            </div>
                        </div>
                    }
                    
                </div>
            </div>

        </Segment>
    </div>
}
export default SelectOrganisation;
