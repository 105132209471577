import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Audit, AuditCheckpoint } from '../../../../backend_api_2';
import Icon from '../../../../base/components/basic/Icon';
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { doesCheckpointRequireMandatoryInput } from './mandatory';
import { AuditCheckpointTitleAndActionMenu } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';

export function DefaultCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const requiresInput = doesCheckpointRequireMandatoryInput(checkpoint);

    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            {
                triedToSubmit && requiresInput &&
                <div className='text-status-rejected text-sm mb-2 flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.provide_inputs'/>
                </div>
            }
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
        </div>
    )
}