import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    positive: boolean;
    labelText: string;
}

const ApproveRejectResolveStatus = (props: Props) => {
    const getColor = () => {
        return props.positive ? 'bg-status-approved' : 'bg-status-rejected';
    }
    return <div className='flex justify-start space-x-1'>
        <span className=''><FormattedMessage id='corrective_actions.history.status_set_to'/></span><span className={classnames('text-white px-1 whitespace-nowrap rounded text-xs flex items-center', getColor())}>{props.labelText}</span>
    </div>
}

export default ApproveRejectResolveStatus;