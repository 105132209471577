/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 94.0.4 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AuditLink,
    BasicUser,
    BasicUserOptional,
    CheckpointLink,
    CorrectiveActionsCorrectiveActionDomain,
    CorrectiveActionsCorrectiveActionPrimaryLinkType,
    CorrectiveActionsState,
    DefectLink,
    FileResource,
    Group,
    InspectionLink,
    MasterInspectionLink,
    ProductionUnitLink,
    ReportLocation,
    SupplierLink,
    TextWithTranslation,
} from './';

/**
 * Basic Corrective Action.
 * A task that somebody has to do and provide evidence that its done.
 * @export
 * @interface CorrectiveAction
 */
export interface CorrectiveAction {
    /**
     * 
     * @type {Array<string>}
     * @memberof CorrectiveAction
     */
    accessible_features?: Array<CorrectiveActionAccessibleFeaturesEnum>;
    /**
     * 
     * @type {BasicUserOptional}
     * @memberof CorrectiveAction
     */
    approver: BasicUserOptional | null;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof CorrectiveAction
     */
    approver_comment: TextWithTranslation;
    /**
     * 
     * @type {Array<AuditLink>}
     * @memberof CorrectiveAction
     */
    audit_links: Array<AuditLink>;
    /**
     * 
     * @type {boolean}
     * @memberof CorrectiveAction
     */
    can_be_resolved: boolean;
    /**
     * 
     * @type {Array<CheckpointLink>}
     * @memberof CorrectiveAction
     */
    checkpoint_links?: Array<CheckpointLink>;
    /**
     * 
     * @type {CorrectiveActionsCorrectiveActionDomain}
     * @memberof CorrectiveAction
     */
    corrective_action_domain: CorrectiveActionsCorrectiveActionDomain;
    /**
     * 
     * @type {Date}
     * @memberof CorrectiveAction
     */
    created_at: Date | null;
    /**
     * 
     * @type {BasicUser}
     * @memberof CorrectiveAction
     */
    creator: BasicUser;
    /**
     * 
     * @type {Array<DefectLink>}
     * @memberof CorrectiveAction
     */
    defect_links?: Array<DefectLink>;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof CorrectiveAction
     */
    description: TextWithTranslation;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof CorrectiveAction
     */
    description_of_resolution: TextWithTranslation;
    /**
     * 
     * @type {Date}
     * @memberof CorrectiveAction
     */
    due_date_at: Date | null;
    /**
     * 
     * @type {Array<FileResource>}
     * @memberof CorrectiveAction
     */
    evidence?: Array<FileResource>;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof CorrectiveAction
     */
    finding: TextWithTranslation;
    /**
     * 
     * @type {Array<Group>}
     * @memberof CorrectiveAction
     */
    groups?: Array<Group>;
    /**
     * 
     * @type {string}
     * @memberof CorrectiveAction
     */
    id: string | null;
    /**
     * 
     * @type {Array<InspectionLink | MasterInspectionLink>}
     * @memberof CorrectiveAction
     */
    inspection_links: Array<InspectionLink | MasterInspectionLink>;
    /**
     * 
     * @type {ReportLocation}
     * @memberof CorrectiveAction
     */
    location: ReportLocation | null;
    /**
     * 
     * @type {string}
     * @memberof CorrectiveAction
     */
    location_country?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CorrectiveAction
     */
    overdue: boolean | null;
    /**
     * 
     * @type {CorrectiveActionsCorrectiveActionPrimaryLinkType}
     * @memberof CorrectiveAction
     */
    primary_link_type: CorrectiveActionsCorrectiveActionPrimaryLinkType;
    /**
     * 
     * @type {Array<ProductionUnitLink>}
     * @memberof CorrectiveAction
     */
    production_unit_links?: Array<ProductionUnitLink>;
    /**
     * The responsible should use the app to document the action
     * @type {boolean}
     * @memberof CorrectiveAction
     */
    requires_evidence_from_app?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CorrectiveAction
     */
    resolve_date?: Date | null;
    /**
     * 
     * @type {BasicUserOptional}
     * @memberof CorrectiveAction
     */
    responsible: BasicUserOptional | null;
    /**
     * 
     * @type {CorrectiveActionsState}
     * @memberof CorrectiveAction
     */
    state: CorrectiveActionsState;
    /**
     * 
     * @type {Array<SupplierLink>}
     * @memberof CorrectiveAction
     */
    supplier_links?: Array<SupplierLink>;
}

/**
* @export
* @enum {string}
*/
export enum CorrectiveActionAccessibleFeaturesEnum {
    Create = 'create',
    DeleteAndRestore = 'delete_and_restore',
    EditDetails = 'edit_details',
    Show = 'show',
    UploadEvidence = 'upload_evidence',
    Resolve = 'resolve',
    Approve = 'approve'
}

