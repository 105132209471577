import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { request } from '../../../base/api';
import { RequestError } from '../../../base/types';
import messages from '../../../modules/messages';
import errorHandling from '../../errorHandling';
import { GroupData } from '../types';
import * as types from './actionTypes';

const sendErrorMessage = messages.actions.sendErrorMessage;
const catchException = errorHandling.handler.catchException;

const isFetching = createAction(types.IS_FETCHING, (isFetching: boolean) => ({ isFetching }));
const groupsRequestSuccessful = createAction(types.GROUPS_REQUEST_SUCCESS, (isFetching: boolean, groups: GroupData[]) => (
    {
        groups,
        isFetching,
    }
));
const groupsRequestFailure = createAction(types.GROUPS_REQUEST_FAILURE, (isFetching: boolean, error: RequestError) => (
    { isFetching, error }
));

export const getGroups = () => {
    return (dispatch: Dispatch): Promise<void> => {
        dispatch(isFetching(true));
        return request('groups', {
            method: 'GET',
        })
            .then((response: { groups: GroupData[] }) => {
                dispatch(groupsRequestSuccessful(false, response.groups));
            }).catch((error: RequestError) => {
                dispatch(groupsRequestFailure(false, error));
                dispatch(sendErrorMessage(['error_message.could_not_load_groups'], 3000));
                catchException('getGroups', {
                    endpoint: 'groups',
                    request: 'groups',
                    status: error.status,
                }, { error });
            });
    };
}

const creatGroupFailure = createAction(types.GROUPS_CREATE_FAILURE, (isFetching: boolean, error: RequestError) => (
    { isFetching, error }
));

export const createGroup = (name: string) => {
    return (dispatch: Dispatch<any>): Promise<void> => {
        dispatch(isFetching(true));
        return request('groups', {
            method: 'POST',
            body: JSON.stringify({ name }),
        })
            .then(() => {
                dispatch(getGroups());
            }).catch((error: RequestError) => {
                dispatch(creatGroupFailure(false, error));
                catchException('createGroup', {
                    endpoint: 'groups',
                    request: 'groups',
                    status: error.status,
                }, { error, method: 'POST', body: { name } });
            });
    };
}
