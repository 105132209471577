import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DetailedSupplier } from '../../../backend_api/models';
import { LIMITED_SELECTOR_ITEMS_COUNT } from '../../../base/config';
import { OptionsType } from '../../../base/types';
import LimitedDropdown from '../../filters/components/LimitedDropdown';
import { InfoIcon } from '../../globals/components/Utils';
import { searchOptionTextAndDescription } from '../../inspections/Utils';
import { loadingSupplierMessage } from '../../productionUnits/components/productionUnitSelector/shared';

type SupplierSelectorProps = {
    suppliers: DetailedSupplier[];
    loading: boolean;
    // these suppliers do not match a selected production unit.
    nonMatchingSuppliers?: DetailedSupplier[];
    supplierId: string | null;
    className?: string;
    disabled?: boolean;
    dataTestId?: string;
    // If this function is not passed in, we do not show the "new supplier" option.
    onUserWantsToCreateNewSupplier?: () => void;
    // second argument is only passed on, if supplierId is not null. 
    onSupplierIdSelected(supplierId: string | null, matchingSupplier?: boolean): void;
};

const NEW_SUPPLIER = 'NEW_SUPPLIER_VALUE';

function ControlledSupplierSelector(props: SupplierSelectorProps) {
    const {
        suppliers,
        supplierId,
        disabled = false,
        onSupplierIdSelected,
        // onNonMatchingSupplierIdSelected,
        className,
        onUserWantsToCreateNewSupplier: onUserWantsToCreateNewSupplier
    } = props;

    const intl = useIntl();

    const newSupplierOptionEnabled = !!onUserWantsToCreateNewSupplier;
    const options = useMemoizedSupplierOptions(suppliers, props.nonMatchingSuppliers || [], newSupplierOptionEnabled)

    function doesIdBelongToMatchingSupplier(selectedSupplierId: string): boolean {
        return selectedSupplierId && suppliers.some(supplier => supplier.id === selectedSupplierId)
    }

    if (props.loading) {
        return loadingSupplierMessage();
    }

    return <LimitedDropdown
        limitItemsCount={LIMITED_SELECTOR_ITEMS_COUNT}
        options={options}
        selection
        search={(_, query) => {
            return makeSupplierOptions(
                suppliers,
                props.nonMatchingSuppliers || [],
                newSupplierOptionEnabled,
                query
            )
        }}
        fluid
        placeholder={intl.formatMessage({ id: 'audits.select_supplier' })}
        value={supplierId}
        disabled={disabled}
        data-test-id={props.dataTestId}
        id={props.dataTestId}
        onChange={(_evt, item): void => {
            if (item.value && item.value == DIVIDER_OPTION_KEY) {
                return;
            }

            if (item.value === null || item.value === undefined) {
                onSupplierIdSelected(null);
                return;
            }

            if (item.value && item.value == NEW_SUPPLIER && onUserWantsToCreateNewSupplier) {
                onUserWantsToCreateNewSupplier();
                return;
            }

            const selectedSupplierId = item.value as string;
            onSupplierIdSelected(selectedSupplierId, doesIdBelongToMatchingSupplier(selectedSupplierId));
        }}
        name='target_supplier.id'
        selectOnBlur={false}
        selectOnNavigation={false}
    />
    // </ListViewItem>
}

function convertDetailedSuppliersToQueriedOptions(suppliers: DetailedSupplier[] = [], query: string = undefined) {
    const nonMatchingSuppliersWithId = keepSuppliersWithId(suppliers || []);
    const nonMatchingSupplierOptions = convertToSupplierOptions(nonMatchingSuppliersWithId);
    return searchOptionTextAndDescription(nonMatchingSupplierOptions, query);
}

function useMemoizedSupplierOptions(
    suppliers: DetailedSupplier[],
    nonMatchingSuppliers: DetailedSupplier[],
    newSupplierOptionEnabled: boolean
): OptionsType[] {
    // only recalculate the list, when suppliers changed
    return useMemo<OptionsType[]>(() => {
        return makeSupplierOptions(suppliers, nonMatchingSuppliers, newSupplierOptionEnabled);
    }, [suppliers, nonMatchingSuppliers, newSupplierOptionEnabled])
}

function makeSupplierOptions(
    suppliers: DetailedSupplier[],
    nonMatchingSuppliers: DetailedSupplier[],
    newSupplierOptionEnabled = true,
    query: string = undefined
) {
    const matchingSuppliersOptions = convertDetailedSuppliersToQueriedOptions(suppliers, query);
    const nonMatchingSupplierOptions = convertDetailedSuppliersToQueriedOptions(nonMatchingSuppliers, query);

    const unassignedOptions = query ? [] : [makeUnassignOption()];
    const newOption = newSupplierOptionEnabled ? [makeNewSupplierOption()] : [];
    const dividerOption = makeDividerOption();

    if (nonMatchingSupplierOptions.length == 0) {
        return []
            .concat(
                unassignedOptions,
                matchingSuppliersOptions,
                newOption
            );
    } else {
        return []
            .concat(
                unassignedOptions,
                matchingSuppliersOptions,
                [dividerOption],
                nonMatchingSupplierOptions,
                newOption
            );
    }

}

function keepSuppliersWithId(suppliers: DetailedSupplier[]) {
    return suppliers.filter((supplier) => supplier.id);
}

function convertToSupplierOptions(suppliers: DetailedSupplier[]) {
    return suppliers.map(convertToSupplierOption);
}

function convertToSupplierOption(supplier: DetailedSupplier): OptionsType {
    return {
        key: supplier.id,
        text: supplier.name,
        value: supplier.id,
        description: supplier.number
    };
}

function makeUnassignOption(): OptionsType {
    return {
        key: null,
        value: null,
        text: (
            <span className='emphasizeUnassignedOptionInDropdown'>
                <FormattedMessage id='suppliers.selector_set_entry_to_unassiged' />
            </span>
        )
    }
}

function makeNewSupplierOption(): OptionsType {
    return {
        key: 'newItem',
        value: NEW_SUPPLIER,
        text: 'new',
        content:
            (
                <div className='w-full flex space-x-2 text-sm py-2'>
                    <div>
                        <FormattedMessage id='audits.create_supplier.cannot_find' />
                    </div>
                    <div className='link'>
                        <FormattedMessage id='audits.create_supplier.cannot_find_create_new' />
                    </div>
                </div>
            )
    }

}

const DIVIDER_OPTION_KEY = 'dividerOption'

function makeDividerOption(): OptionsType {
    return {
        key: DIVIDER_OPTION_KEY,
        value: DIVIDER_OPTION_KEY,
        text: '-',
        content: (
            <div className='text-secondary -my-1 flex items-center'>
                <InfoIcon compact noMargins />
                <div>
                    <FormattedMessage id='audits.select_supplier.following_suppliers_do_not_match_production_unit' />
                </div>
            </div>
        )
    }

}

export default ControlledSupplierSelector;
