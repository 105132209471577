import React from 'react';
import { Input, InputProps, Ref } from 'semantic-ui-react';

type OwnProps = {
    focus?: boolean;
}

type QarmaInputProps = OwnProps & InputProps;

const QarmaInput = (props: QarmaInputProps): React.ReactElement => {

    const focusInput = (node: HTMLElement): void => {
        if (node && props.focus) {
            (node.firstElementChild as HTMLElement).focus();
        }
    }

    return (
        <Ref innerRef={focusInput}>
            <Input
                {...props}
                value={(props.value === null || props.value === undefined) ? '' : props.value}
            />
        </Ref>
    )
}

export default QarmaInput;