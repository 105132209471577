import React from 'react';
import { defineMessages, FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';

interface ImageCommentsMetaProps {
    imageComment: React.ReactElement | string;
    commentsCnt: number;
    intl: any;
}

class ImageCommentsMeta extends React.Component<ImageCommentsMetaProps, any> {
    public render(): React.ReactElement | string {
        defineMessages({
            imageComment: {
                id: 'report.comments_on_this_image_click_to_read_and_reply',
            },
            comment: { id: 'report.comment' },
            comments: { id: 'report.comments' },
        });
        const imageComment = this.props.imageComment || '';
        const commentsCnt = this.props.commentsCnt || 0;
        const formatMessage = this.props.intl.formatMessage;
        let cnt = this.props.commentsCnt;

        if (imageComment) {
            cnt++;
        }
        if (imageComment !== '' && commentsCnt < 2) {
            return imageComment;
        }

        return <FormattedMessage
            id='report.comments_on_this_image_click_to_read_and_reply'
            values={{
                ...
                { commentscnt: cnt },
                comments:
                    (
                        <FormattedPlural
                            value={cnt}
                            one={formatMessage({ id: 'report.comment' })}
                            other={formatMessage({ id: 'report.comments' })}
                        />
                    ),
            }}
        />;
    }
}

export default injectIntl(ImageCommentsMeta);
