import * as components from './components';
import * as constants from './constants';
import * as actions from './dashboardActions';
import reducers from './reducers';
import * as selectors from './selectors';

// export { FetchingData, Locale, LocaleObj } from './model';
// Models need to be exported explicitly to be accessible as interfaces

export default { actions, components, constants, reducers, selectors };
