/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.7 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditReportCustomFieldsNumber
 */
export interface AuditReportCustomFieldsNumber {
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsNumber
     */
    number: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsNumber
     */
    previous_number: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsNumber
     */
    type: AuditReportCustomFieldsNumberTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsNumberTypeEnum {
    Number = 'Number'
}

export function AuditReportCustomFieldsNumberFromJSON(json: any): AuditReportCustomFieldsNumber {
    return AuditReportCustomFieldsNumberFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'previous_number': json['previous_number'],
        'type': json['type'],
    };
}

export function AuditReportCustomFieldsNumberToJSON(value?: AuditReportCustomFieldsNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'previous_number': value.previous_number,
        'type': value.type,
    };
}


