import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { getProfile } from '../../profile/selectors';
import { exportSuppliers } from '../statisticsSlice';
import DataExportInput from './DataExportInput';

type Props = {
    email?: string
}

const DataExportSuppliers = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const profile = useSelector(getProfile);


    const exportStats = (inputParams): void => {
        const params = {
            ...inputParams,
            language: profile.locale.language,
            notify_to_email: getEmail(),
        }
        dispatch(exportSuppliers(params))
    }

    const getEmail = () => {
        return props.email ? props.email : profile.email
    }

    return <DataExportInput
        hasDates={false}
        exportObjects={['suppliers', 'production_units']}
        description={intl.formatMessage({ id: 'statistics.export_suppliers_to_excel.description' })}
        exportInProgressText={intl.formatMessage({ id: 'statistics.suppliers_exporting' }, { email: getEmail() })}
        export={exportStats}
    />;
}
export default DataExportSuppliers;