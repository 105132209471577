import * as Sentry from '@sentry/react';
import { ENVIRONMENT, RELEASE } from '../../base/config';

export const catchException = (
    description: string,
    tags: {
        endpoint?: string;
        request?: string;
        status?: number;
        statusText?: string;
    }, extra: any): void => {
    const tagsExtended = {
        endpoint: tags.endpoint || 'N/A',
        request: tags.request || 'N/A',
        status: tags.status && tags.status.toString() || 'N/A',
        statusText: tags.statusText || 'N/A',
        environment: ENVIRONMENT,
        release: RELEASE,
        errorDetail: extra && JSON.stringify(extra.error),
        error: extra && JSON.stringify(extra.error),
    };
    Sentry.configureScope(scope => scope.setTransactionName(description));
    if (extra.error && extra.error.json) {
        // if extra contains an error object, we need to resolve it
        const error = extra.error;
        error.json().then((d: any) => {
            extra.error.detail = d;
            Sentry.captureException(new Error(description), {
                tags: tagsExtended,
            });

        });
    } else {
        Sentry.captureException(new Error(description), {
            tags: tagsExtended,
        });
    }
};
export default { catchException };
