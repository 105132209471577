import React from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { ListedManagedSupplier } from '../../../backend_api/models';
import { ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import ProductionUnitCorrectiveActionStatusPie from './ProductionUnitCorrectiveActionStatusPie';
import SupplierListAuditType from './SupplierListAuditType';

type Props = {
    className?: string;
    supplier: ListedManagedSupplier;
};

const SupplierListProductionUnit = (props: Props): React.ReactElement => {
    const productionUnits = props.supplier.production_units
    const intl = useIntl();
    const noTypeId = 'no_type'
    const auditTypeIDs = [... new Set(props.supplier.audits.map((audit) => {
        if (audit.audit_type) {
            return audit.audit_type.id
        }
        else {
            return noTypeId
        }
    }))]
    const columnWidths = ['w-3/16', 'w-3/16', 'w-9/16', 'w-1/16']
    const auditTagWidth = 'w-48'
    if (productionUnits.length > 0) {
        const auditsLabel = <div className='w-full flex justify-end'>
            {auditTypeIDs.map((dummy, index) => {
                if (index == 0) {
                    return <div key={index} className={auditTagWidth + ' pl-2'}>{intl.formatMessage({ id: 'suppliers.pu.list.header.audits' })}</div>
                }
                else {
                    return <div key={index} className={auditTagWidth} />
                }
            })}
        </div>
        return <ListViewLines className='w-full'>
            <ListViewHeader
                className={twMerge('top-0 md:py-2 bg-highlight-light-green border-b')}
                items={[
                    { label: intl.formatMessage({ id: 'suppliers.pu.list.header.number' }), className: columnWidths[0], key: 'pu_number' },
                    { label: intl.formatMessage({ id: 'suppliers.pu.list.header.name' }), className: columnWidths[1], key: 'pu_name' },
                    { label: auditsLabel, className: columnWidths[2], key: 'pu_ac_and_bar' },
                    { label: undefined, className: columnWidths[3], key: 'spacer.1' },
                ]} />

            {productionUnits.map((pu) => {
                return <ListViewLine className='w-full flex-row items-center py-0 sm:py-1 md:py-1 hover:bg-highlight-green' key={'PU_' + pu.id} link={'/production_units/' + pu.id}>
                    <ListViewItem className={columnWidths[0]} key={'pu_number_' + pu.id}>{pu.number || '-'}</ListViewItem>
                    <ListViewItem className={columnWidths[1]} key={'pu_name_' + pu.id}>{pu.name}</ListViewItem>
                    <ListViewItem className={columnWidths[2] + ' flex justify-end'} key={'pu_audits_' + pu.id}>
                        <div className='flex flex-wrap'>
                            {auditTypeIDs.map((typeId, index) => {
                                const audit = pu.audits.find((audit) => {
                                    if (typeId == noTypeId) {
                                        return audit.audit_type == null
                                    }
                                    else {
                                        return audit.audit_type && audit.audit_type.id == typeId
                                    }
                                })
                                if (audit) {
                                    return <div key={audit.id} className={auditTagWidth + ' p-2'}><SupplierListAuditType auditTypeTag={audit} /></div>
                                }
                                else {
                                    return <div key={index} className={auditTagWidth}></div>
                                }
                            })}
                        </div>
                    </ListViewItem>
                    <ListViewItem className={columnWidths[3] + ' justify-end'} key={'pu_ca_status' + pu.id} >
                        <ProductionUnitCorrectiveActionStatusPie correctiveActionStatus={pu.corrective_action_status} supplierId={props.supplier.id} productionUnitId={pu.id} />
                    </ListViewItem>
                </ListViewLine>
            })
            }
        </ListViewLines >
    }
    return <div></div>;
}

export default SupplierListProductionUnit;