import { createAction } from 'redux-actions';
import { request } from '../../../base/api';
import { setLayoutAttribute } from '../../../base/baseSlice';
import errorHandling from '../../errorHandling';
import * as types from '../actionTypes/watcherActionTypes';
import { updateAndReloadReportData } from '../slices/inspectionReportSlice';
import { ReportState } from '../types';
import { getCheckpointsObject } from './reportActions';

const catchException = errorHandling.handler.catchException;

const addWatcherToInspectionRequest = createAction(types.ADD_WATCHER_TO_INSPECTION, (isFetching) => ({ isFetching }));
const addWatcherToInspectionRequestFailure = createAction(types.ADD_WATCHER_TO_INSPECTION_FAILURE, (error) => ({ error }));

const removeWatcherFromInspectionRequest = createAction(types.REMOVE_WATCHER_FROM_INSPECTION, (isFetching) => ({ isFetching }));

export const addWatcherToInspection = (inspectionId: string, userId: string) => {
    return (dispatch): Promise<void> => {
        dispatch(addWatcherToInspectionRequest(true));
        return request('inspections/' + inspectionId + '/watchers/' + userId, {
            method: 'post',
        })
            .then((data: ReportState) => {
                data = getCheckpointsObject(data);
                dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                dispatch(updateAndReloadReportData(data));
            })
            .catch((error) => {
                dispatch(addWatcherToInspectionRequestFailure(error));
                catchException('addWatcherToInspection', {
                    endpoint: 'inspections[inspectionId]/watchers/[userId]',
                    request: 'inspections/' + inspectionId + '/watchers/' + userId,
                    status: error.status,
                }, { error, inspectionId, userId, method: 'POST' });
            });
    };
}

export const removeWatcherFromInspection = (inspectionId: string, userId: string) => {
    return (dispatch): Promise<void> => {
        dispatch(removeWatcherFromInspectionRequest(true));
        return request('inspections/' + inspectionId + '/watchers/' + userId, {
            method: 'delete',
        })
            .then((data: ReportState) => {
                data = getCheckpointsObject(data);
                dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                dispatch(updateAndReloadReportData(data));
            })
            .catch((error) => {
                catchException('removeWatcherFromInspection', {
                    endpoint: 'inspections[inspectionId]/watchers/[userId]',
                    request: 'inspections/' + inspectionId + '/watchers/' + userId,
                    status: error.status,
                }, { error, inspectionId, userId, method: 'DELETE' });
            });
    };
}

