import React, { Component, Fragment, MouseEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { getLocaleLanguageString } from '../../../base/utils';

type OwnProps = {
    showTranslatedComments: boolean;
    translateObj: any;
    originalLanguage: string;
    originalText?: string;
    showToggleActions?: boolean;
    showTranslation?(): void;
}
const initialState = { translationVisible: false };
type State = typeof initialState;
type TranslateProps = OwnProps & WrappedComponentProps;

class TranslateText extends Component<TranslateProps, State> {

    readonly state = initialState;
    render(): React.ReactElement {
        const { originalText = '', translateObj, showToggleActions } = this.props;
        const currentLang = getLocaleLanguageString(this.props.intl.locale);
        const originalLanguage = getLocaleLanguageString(this.props.originalLanguage || this.props.intl.locale);
        const langDiff = originalLanguage !== currentLang;
        const langLabel = this.props.intl.formatMessage({ id: 'globals.locale.' + currentLang });
        const langLabelFrom = this.props.intl.messages['globals.locale.own.' + originalLanguage] ?
            <FormattedMessage id='comments.translated_from_lang' values={{ lang: this.props.intl.formatMessage({ id: 'globals.locale.own.' + originalLanguage }) }} /> : <FormattedMessage id='comments.translations_translated_from_unknow_language' />;
        const triggerLabel = !this.state.translationVisible ? <span className='link textLabel' onClick={(e: MouseEvent): void => {
            this.showTranslation(e);
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }}><FormattedMessage id='comments.translate_to_lang' values={{ lang: langLabel }} /></span>
            :
            <span className='textLabel'>
                {langLabelFrom}{' '}|{' '}<span className='link' onClick={(e: MouseEvent): void => {
                    this.hideTranslation(e);
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}><FormattedMessage id='comments.translate_show_original' /></span>
            </span>;
        return (
            <span className='translateText break-word whitespace-pre-wrap'>
                {this.state.translationVisible && langDiff && translateObj && translateObj[currentLang]}
                {(!this.state.translationVisible || !langDiff) && <Fragment>{originalText || ''}</Fragment>}
                {showToggleActions && currentLang !== originalLanguage && <div>{triggerLabel}</div>}
            </span>
        );
    }
    componentDidMount(): void {
        this.setState({ translationVisible: this.props.showTranslatedComments });
    }

    componentDidUpdate(prevProps: TranslateProps): void {
        if (this.props.showTranslatedComments !== prevProps.showTranslatedComments) {
            this.setState({ translationVisible: this.props.showTranslatedComments });
        }
    }

    showTranslation(e: MouseEvent): void {
        e.stopPropagation();
        if (this.props.showTranslation) {
            this.props.showTranslation();
        }
        this.setState({ translationVisible: true });
    }

    hideTranslation(e: MouseEvent): void {
        e.stopPropagation();
        this.setState({ translationVisible: false });
    }
}

export default injectIntl(TranslateText);