import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon } from 'semantic-ui-react';
import { AddCommentBodyEntityTypeEnum, DetailedProductionUnit, DisplaySupplier } from '../../../backend_api/models';
import { GPSLocation } from '../../../backend_api_2';
import Loader from '../../../base/components/Loader';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import EditLink from '../../../base/components/basic/EditLink';
import { getFeaturesSelector, getLayoutSelector } from '../../../base/selectors';
import { deepCopy, isUndefinedOrNull, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import LocationMap from '../../inspections/components/LocationMap';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { linkProductionUnitAndSupplier, unlinkProductionUnitAndSupplier } from '../../supplierAndPuRelations/slice/relationSlice';
import { CreateOrSelectSupplierModal } from '../../suppliers/components/CreateOrSelectSupplierModal';
import { createLegacySupplier } from '../../suppliers/slice/suppliersSlice';
import { addGroupToProductionUnit, deleteProductionUnit, getDetailedProductionUnit, getDetailedProductionUnitListSelector, getDetailedProductionUnitLoadingSelector, getProductionUnitHistorySelector, getProductionUnitHistory, productionUnitsSliceActions, removeGroupFromProductionUnit, updateProductionUnitAddressContact, updateProductionUnitInfo, addCommentToProductionUnit, getProductionUnitHistoryLoadingSelector } from '../slice/productionUnitsSlice';
import DetailedProductionUnitAddressAndContactPerson from './DetailedProductionUnitAddressAndContactPerson';
import DetailedProductionUnitAudits from './DetailedProductionUnitAudits';
import DetailedProductionUnitCustomFields from './DetailedProductionUnitCustomFields';
import DetailedProductionUnitGroups from './DetailedProductionUnitGroups';
import DetailedProductionUnitInfo from './DetailedProductionUnitInfo';
import DetailedProductionUnitRelatedSuppliers from './DetailedProductionUnitRelatedSuppliers';
import DetailedProductionUnitUpstreamDownstream from './DetailedProductionUnitUpstreamDownstream';
import ProductionUnitStatus from './ProductionUnitStatus';
import { ProductionUnitOrSupplierHistoryEntry } from './HistorySection';
import CommentInput from '../../../base/components/comments/CommentInput';

const EDIT_PU_STATUS = 'edit_status';
const CREATE_PU = 'create';
const DELETE_PU = 'delete';
/* Name, Number, Gps location */
const EDIT_IDENTIFICATION_DATA = 'edit_identification_data';
/* address and contact person OR custom fields */
const EDIT_ADDITIONAL_DATA = 'edit_additional_data';
const EDIT_GROUPS = 'edit_groups';
const LINK_PRODUCTION_UNITS = 'link_production_unit';

type Props = {
    className?: string;
    id: string;
};

function userIsAllowedTo(productionUnit: DetailedProductionUnit, action: string): boolean {
    if (!productionUnit) {
        return false;
    }

    return productionUnit.features.includes(action);
}

const DetailedProductionUnitComponent = (props: Props): React.ReactElement => {
    const { className, id } = props;
    const scrollToTop = !useSelector(getLayoutSelector).dontScrollToTopAfterAction;
    const detailedData = useSelector(getDetailedProductionUnitListSelector);
    const features = useSelector(getFeaturesSelector);
    const loading = useSelector(getDetailedProductionUnitLoadingSelector);
    const fetchingHistory = useSelector(getProductionUnitHistoryLoadingSelector);

    const hasIndependentPU = features.includes('org_independent_production_units');
    const hasCustomFields = features.includes('org_production_unit_custom_fields');
    const canShowStatus = features.includes('org_production_unit_status');

    const userIsAllowedToChangeStatus = userIsAllowedTo(detailedData, EDIT_PU_STATUS);
    const userIsAllowedToCreateProductionUnit = userIsAllowedTo(detailedData, CREATE_PU);
    const userIsAllowedToDelete = userIsAllowedTo(detailedData, DELETE_PU);
    const userIsAllowedToEditAdditionalData = userIsAllowedTo(detailedData, EDIT_ADDITIONAL_DATA)
    const userIsAllowedToEditIdentificationData = userIsAllowedTo(detailedData, EDIT_IDENTIFICATION_DATA)
    const userIsAllowedToEditGroups = userIsAllowedTo(detailedData, EDIT_GROUPS)
    const userIsAllowedToLinkProductionUnits = userIsAllowedTo(detailedData, LINK_PRODUCTION_UNITS)

    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [editProductionUnitLinks, setEditProductionUnitLinks] = useState(false);
    const [data, setData] = useState(detailedData);
    const history = useHistory();
    const productionUnitHistory = useSelector(getProductionUnitHistorySelector);

    function onUserWantedToRemoveSupplier(supplierId: string) {
        dispatch(unlinkProductionUnitAndSupplier({
            production_unit_id: props.id,
            supplier_id: supplierId
        }))
    }

    function addComment(text: string) {
        dispatch(addCommentToProductionUnit({
            entity_id: props.id,
            entity_type: AddCommentBodyEntityTypeEnum.ProductionUnit,
            text
        }))
    }

    useEffect(() => {
        const getPUPromise = dispatch(getDetailedProductionUnit({ id }));
        const getPUHistoryPromise = dispatch(getProductionUnitHistory({ id }));
        scrollToTop && window.scrollTo(0, 0);

        return () => {
            dispatch(productionUnitsSliceActions.resetDetailedProductionUnit());
            getPUPromise.abort?.();
            getPUHistoryPromise.abort?.();
        }
    }, [id]);

    useEffect(() => {
        if (!isUndefinedOrNull(detailedData)) {
            setData(detailedData);
        }
    }, [detailedData]);

    function onGroupAdded(id: string) {
        dispatch(productionUnitsSliceActions.addGroupToDetailedProductionUnit({
            groupId: id,
            productionUnitId: data.id
        }))
        dispatch(addGroupToProductionUnit({ productionUnitId: data.id, groupId: id }))

    }

    function onGroupRemoved(id: string) {
        dispatch(productionUnitsSliceActions.removeGroupFromDetailedProductionUnit({
            groupId: id,
            productionUnitId: data.id
        }))
        dispatch(removeGroupFromProductionUnit({ productionUnitId: data.id, groupId: id }))
    }

    function onUserWantsToDeleteProductionUnit() {
        dispatch(deleteProductionUnit({ production_unit_id: data.id })).then(() => {
            history.push('/production_units')
        })
    }

    const historySection = <ContentSection 
        headerText={intl.formatMessage({ id: 'production_units.detailed_page.history_and_comments' })}
        content={
            <>
                <CommentInput handleNewComment={addComment} showUpload={false} />
                <div className={fetchingHistory && (!productionUnitHistory || productionUnitHistory?.length == 0) ? "-m-4" : ""}>
                    <Loader active={fetchingHistory} spinner={<Icon name='circle notch' loading color='green' size='large' />}>
                    {
                    productionUnitHistory?.map(entry => <ProductionUnitOrSupplierHistoryEntry entry={entry} />)
                }
                    </Loader>
                </div>
            </>
        }
    />

    function onHandleChange(name: string, value: string | GPSLocation, index?: number) {
        let sd;
        if (index !== undefined) {
            sd = deepCopy(data)
            sd[name][index] = value;
            setData(sd);
        } else {
            sd = {
                ...data,
                [name]: value,
            }
            setData(sd);
        }

        /* TODO Lukas: Remove all this spaghetti */
        if (name === 'name' || name == 'number' || name == 'location') {
            dispatch(updateProductionUnitInfo({ productionUnitId: id, info: sd }));
        }

        if (name === 'contact_person' || name === 'address') {
            dispatch(updateProductionUnitAddressContact({ productionUnitId: id, info: sd }));
        }

    }

    function onAddAddressLine() {
        const a = data.address.concat('')

        const newData = {
            ...data,
            address: a
        }

        // a.push('');
        setData(newData);
    }

    function onRemoveAddressLine(index) {
        const a = [...data.address];
        a.splice(index, 1);
        setData({
            ...data,
            address: a,
        });
    }


    const info = <>
        {detailedData && <DetailedProductionUnitInfo
            allowedToEdit={userIsAllowedToEditIdentificationData}
            name={detailedData.name}
            number={detailedData.number}
            location={detailedData.location}
            handleChange={onHandleChange}
            locationCountry={detailedData.location_country}
        />}

        {detailedData && detailedData.location && <LocationMap location={detailedData.location} locationCountry={detailedData.location_country} />}
    </>;

    const groups = <DetailedProductionUnitGroups
        allowedToEdit={userIsAllowedToEditGroups}
        onGroupAdded={onGroupAdded}
        onGroupRemoved={onGroupRemoved}
        groupIds={detailedData && detailedData.group_ids}
        productionUnitId={props.id} />;


    const cfs = detailedData && hasCustomFields && (
        <DetailedProductionUnitCustomFields
            allowedToEdit={userIsAllowedToEditAdditionalData}
            type='productionUnit'
            productionUnitId={detailedData.id}
            customFieldGroups={detailedData.custom_field_groups} />
    )

    const addressAndContactPerson = data && !hasCustomFields && (
        <DetailedProductionUnitAddressAndContactPerson
            allowedToEdit={userIsAllowedToEditAdditionalData}
            onAddAddressLine={onAddAddressLine}
            onRemoveAddressLine={onRemoveAddressLine}
            address={data.address}
            contactPerson={data.contact_person}
            handleChange={onHandleChange}
        />
    )

    const audits = (
        <ContentSection
            className=''
            headerText={intl.formatMessage({ id: 'audits.list.header' })}
            content={<div className={twMerge('', className)}>
                {data && <DetailedProductionUnitAudits audits={data.audits} onAuditsUpdated={() => dispatch(getDetailedProductionUnit({ id }))} productionUnitId={id} />}
            </div>}
        />
    )

    const deleteAction = userIsAllowedToDelete && (
        <DeleteConfirm
            type='alert'
            alertText={intl.formatMessage({ id: 'production_units.delete_description' })}
            alertHeader={intl.formatMessage({ id: 'production_units.header.delete' }, { name: detailedData.name && detailedData.name })}
            deleteFunction={onUserWantsToDeleteProductionUnit}
            trigger={< Button alert >
                <FormattedMessage id='production_units.delete' />
            </Button >} />
    )

    const relations = hasIndependentPU ? <ContentSection
        headerText={
            <div className='flex w-full justify-between items-center'>{intl.formatMessage({ id: 'production_units.relations.heading' })}
                {userIsAllowedToLinkProductionUnits && <EditLink edit={editProductionUnitLinks} className='flex w-full justify-end' onEdit={setEditProductionUnitLinks} />}
            </div>
        }
        content={<div className=' pb-4'><div className='flex space-x-8'>
            <DetailedProductionUnitUpstreamDownstream
                isUserAllowedToCreateProductionUnits={userIsAllowedToCreateProductionUnit}
                className='w-1/2'
                productionUnitId={id}
                upstreamProductionUnits={detailedData && detailedData.upstream_production_units}
                edit={editProductionUnitLinks}
                type='upstream'
            />
            <DetailedProductionUnitUpstreamDownstream
                isUserAllowedToCreateProductionUnits={userIsAllowedToCreateProductionUnit}
                className='w-1/2'
                productionUnitId={id}
                upstreamProductionUnits={detailedData && detailedData.downstream_production_units}
                type='downstream'
                edit={editProductionUnitLinks}
            />

        </div>
            <div className='pt-4'>
                <DetailedProductionUnitRelatedSuppliers
                    onUserWantedToRemoveSupplier={onUserWantedToRemoveSupplier}
                    relatedSuppliers={detailedData && detailedData.related_suppliers}
                    edit={editProductionUnitLinks}
                />
                {
                    editProductionUnitLinks
                        ? <CreateOrSelectSupplierModal
                            onSupplierIdSelected={(supplier_id) =>

                                dispatch(linkProductionUnitAndSupplier({
                                    production_unit_id: props.id,
                                    supplier_id: supplier_id
                                })).finally(() => {
                                    return dispatch(getDetailedProductionUnit({ id }))
                                })

                            }
                            onUserWantedToCreateSupplier={(params) =>
                                dispatch(createLegacySupplier({
                                    name: params.name,
                                    number: params.number,
                                    groups: params.groups,
                                    deleted: false,
                                    features: undefined,
                                    id: undefined,
                                    primary_group: undefined,
                                    users: undefined
                                }))
                                    .then((response) => {
                                        const supplierId = response.payload.id

                                        return dispatch(linkProductionUnitAndSupplier({
                                            production_unit_id: props.id,
                                            supplier_id: supplierId
                                        }))
                                    })
                                    .finally(() => {
                                        return dispatch(getDetailedProductionUnit({ id }))
                                    })
                            }
                        />
                        : null
                }

            </div>
        </div>} /> : null;

    return <Loader active={loading}>
        <PageContainer
            header={<span className='flex'>{detailedData && detailedData.name}</span>}>
            {detailedData && <div className='flex flex-col'>
                <DocumentTitle title={intl.formatMessage({ id: 'page_title.product_unit' }, { puNo: detailedData.name })} />

                {!hasIndependentPU && renderBreadcrumbs(getSingleRelatedSupplier(data))}

                <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                    <div className='w-full md:w-8/12 order-last md:order-first'>
                        {info}
                        {addressAndContactPerson}
                        {cfs}
                        {relations}
                        {audits}
                        {groups}
                        {deleteAction}
                    </div>
                    <div className='w-full md:w-4/12 order-first md:order-last pt-0'>
                        { canShowStatus && 
                            <ProductionUnitStatus
                                status={detailedData.status}
                                productionUnitId={id}
                                allowedToEdit={userIsAllowedToChangeStatus}
                            />
                        }
                        {userIsAllowedToChangeStatus &&
                            <ContentSection
                                content={(
                                    <div className='flex justify-end py-2'>
                                        <ProductionUnitStatus
                                            status={detailedData.status}
                                            productionUnitId={id}
                                            onlyButton
                                            allowedToEdit={userIsAllowedToChangeStatus}
                                        />
                                    </div>
                                )} />
                        }
                        {historySection}
                    </div>
                </div>
            </div>}
        </PageContainer>
    </Loader>

}

/* 
        This function gives you a single related supplier, if and only if there is one related supplier available.
        If org_independent_production_units is not set, this is the supplier that controls the production unit.
    */
function getSingleRelatedSupplier(productionUnit: DetailedProductionUnit): DisplaySupplier {
    if (!productionUnit) {
        return undefined;
    }

    if (productionUnit.related_suppliers.length === 1) {
        return productionUnit.related_suppliers[0];
    }

    return undefined;
}

function renderBreadcrumbs(displaySupplier: DisplaySupplier | undefined) {
    if (!displaySupplier) {
        return null;
    }

    return (
        <div className='pb-2 pl-2'>
            <Breadcrumb>
                <Breadcrumb.Section>
                    <Link className='link noUnderline' to='/suppliers'><FormattedMessage id='suppliers.suppliers' /></Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section>
                    <Link
                        className='link noUnderline'
                        to={`/suppliers/${displaySupplier.id}`}>
                        {`${displaySupplier.number} - ${displaySupplier.name}`}
                    </Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section><FormattedMessage id='production_unit.breadcrumb.edit_production_unit' /></Breadcrumb.Section>
            </Breadcrumb>
        </div>
    )

}

export default DetailedProductionUnitComponent;