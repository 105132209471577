import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FileResource } from '../../../backend_api/models';
import CommentInput from '../../../base/components/comments/CommentInput';
import { useAppDispatch } from '../../../store';
import { StandAloneMessage } from '../../messages/components/Messages';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getAuditCommentsLoadingSelector } from '../selectors/auditSelectors';
import { addAuditComment, getAuditComments } from '../slices/auditSlice';
import AuditHistoryList from './AuditHistoryList';

type AuditsCommentsAndHistoryProps = {
    className?: string;
    auditId: string;
    loadComments?: boolean;
};

const AuditsCommentsAndHistory = (props: AuditsCommentsAndHistoryProps): React.ReactElement => {
    const { className, auditId, loadComments = true } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const loading = useSelector(getAuditCommentsLoadingSelector);
    const [isFirst, setIsFirst] = useState(undefined);

    const addNewComment = (comment: string, uploads?: FileResource[]) => {
        const attachments = [];
        uploads.forEach((upload) => {
            attachments.push({ [upload.type]: upload[upload.type], type: upload.type, name: upload.name });
        });
        dispatch(addAuditComment({ auditId, comment, attachments }))
    }

    useEffect(() => {
        loadComments && dispatch(getAuditComments({ auditId }));
    }, [])
    useEffect(() => {
        if (isFirst && !loading) {
            setIsFirst(false);
        }
        if (isFirst === undefined && loading) {
            setIsFirst(true);
        }
    }, [loading])
    const content = <ContentSection content={<div className={classnames('', className)}>
        <CommentInput handleNewComment={addNewComment} isReply={false} />
        {isFirst && loading && <StandAloneMessage message={intl.formatMessage({ id: 'comments.loading_comments' })} type='info' />}
        <AuditHistoryList level={0} />
    </div>} headerText={intl.formatMessage({ id: 'audits.comments_and_history.label' })} />
    return <Fragment>{content}</Fragment>;
}
export default AuditsCommentsAndHistory;
