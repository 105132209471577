import classnames from 'classnames';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { prettyClipText, preventDefaultAndStopPropagation } from '../../utils';

type ClipTextProps = {
    text: string;
    clipAt: number;
    className?: string;
};

const ClipText = (props: ClipTextProps): React.ReactElement => {
    const { className, clipAt, text } = props;
    const shouldClip = text && text.length >= clipAt;
    const [showAll, setShowAll] = useState(false);
    if (text) {
        return <div className={classnames('', className)}>
            {!shouldClip && <Fragment>
                {text}
            </Fragment>}
            {shouldClip && <Fragment>
                {!showAll && <div>{prettyClipText(text, clipAt)}<div className='text-xs' onClick={() => setShowAll(true)}><span className='link'><FormattedMessage id='cliptext.show_all' /></span> ({text.length})</div></div>}
                {(showAll) && <Fragment>{text}<div className='link text-xs' onClick={(e) => {
                    setShowAll(false);
                    preventDefaultAndStopPropagation(e);
                }}><FormattedMessage id='cliptext.hide' /></div>
                </Fragment>}
            </Fragment>}
        </div>
    }

    return null;
}
export default ClipText;
