import { createSelector } from 'reselect';
import { AppState } from '../../base/types';
import { Attachment, UploadingFile } from './types';

const attachmentProgressSelector = (state: AppState, id: string, type: string): number => {
    if (state.app.attachments[type].byId[id].uploading) {
        return state.app.attachments[type].byId[id].uploading;
    }
    return 0;
};

const attachmentsSelector = (state: AppState, id: string, type: string): Attachment[] => {
    if (state.app.attachments[type].byId[id]) {
        const atts = state.app.attachments[type].byId[id].items || [];
        if (atts.length === 0) {
            return [];
        }
        return atts;
    }
    return [];
};

const uploadingSelector = (state: AppState, id: string, type: string): UploadingFile[] => {
    if (state.app.attachments[type] && state.app.attachments[type].byId[id]) {
        return state.app.attachments[type].byId[id].uploading;
    }
    return [];
};

export const getAttachmentProgress = createSelector(
    [attachmentProgressSelector],
    (progress) => progress,
);

export const getAttachments = createSelector(
    [attachmentsSelector],
    (attachments) => attachments,
);

export const getUploading = createSelector(
    [uploadingSelector],
    (uploading) => uploading,
);
