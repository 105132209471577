/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldMultipleChoiceValue = {
    option_ids: Array<string>;
    type: InputCustomFieldMultipleChoiceValue.type;
};

export namespace InputCustomFieldMultipleChoiceValue {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

