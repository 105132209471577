import React from 'react';
import { FileResource, FileResourceTypeEnum } from '../../../backend_api/models';
import { twMerge } from '../../../base/utils';
import FileItem from './FileItem';

type Props = {
    attachments: FileResource[];
    className?: string;
    disabled?: boolean;
    deleteFunction?(any);
}

const AttachmentItemList = (props: Props): React.ReactElement => {
    const { attachments, disabled, deleteFunction, className } = props;
    const galleryItems = attachments.filter((att) => att.type === FileResourceTypeEnum.Image);
    const galleryItemsVideos = attachments.filter((att) => att.type === FileResourceTypeEnum.Video);
    const fileItems = attachments.filter((att) => att.type === FileResourceTypeEnum.File);
    return <div className={twMerge('flex flex-col mt-2', className)}>
        {galleryItems.length > 0 && <div className='flex flex-wrap w-full'>{galleryItems.map((attachment, index) =>
            <FileItem className={''} key={'images_' + index} disabled={disabled} file={attachment} deleteFunction={deleteFunction}/ >
        )}</div>}
        {galleryItemsVideos.length > 0 && <div className={twMerge('flex flex-wrap w-full pb-2')}>{galleryItemsVideos.map((attachment, index) =>
            <FileItem className={''} key={'videos_' + index} disabled={disabled} file={attachment} deleteFunction={deleteFunction}/>
        )}</div>}
        {fileItems.length > 0 && <div className={twMerge('flex w-full flex-col')}>{fileItems.map((attachment, index) =>
            <FileItem className={''} key={'files_' + index} disabled={disabled} file={attachment} deleteFunction={deleteFunction} showOddEven={false}/>
        )}</div>}
    </div>
}

export default AttachmentItemList;