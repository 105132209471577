import React, { useEffect, useState } from 'react';
import type { ListBoxItemProps } from 'react-aria-components';
import { Button, Checkbox, ComboBox, GridList, GridListItem, Input, ListBox, ListBoxItem, Popover, Text, useDragAndDrop } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';




type Props = {
    className?: string;
};

const FETest = (props: Props): React.ReactElement => {
    const { className } = props;
    const [id, setId] = useState('1');
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    const options = [
        { id: '11-100-2', value: 'Aardvark' },
        { id: '2222-tt', value: 'Cat' },
        { id: '3333-55-xx', value: 'Dog' },
        { id: '400-1', value: 'Kangaroo' },
        { id: '5000000000', value: 'Koala' },
        { id: 'x6-x7-x8', value: 'Penguin' },
        { id: '7-000-1aa', value: 'Snake' },
        { id: '800', value: 'Turtle' },
        { id: '99999999', value: 'Wombat' }
    ];
    useEffect(() => {
        //
    }, []);
    const item = <ListBoxItem textValue="Admin">
        <Text slot="label">Admin</Text>
        <Text slot="description">Full access</Text>
    </ListBoxItem>;

    function SelectExample() {
        return (


            <ComboBox className="sm:w-[200px] w-full py-2 rounded-md bg-white border border-gray-300"
                selectedKey={id}
                onSelectionChange={(id) => setId(id.toString())}
                menuTrigger='focus'
            >
                <div className='flex'>
                    <Input className={'w-full pl-4'} onChange={(e) => console.log('input ', e.currentTarget.value)} />
                    <Button className={'px-2'}>▾</Button>
                </div>

                <Popover
                    className="sm:w-[200px] w-full max-h-60 overflow-auto rounded-md bg-white text-base entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out"
                    containerPadding={0}>
                    <ListBox className="rounded bg-white border-grey-300 border outline-none" items={options}>
                        {(option) => <ListBoxItem id={option.id} textValue={option.value} key={'k_' + option.id} className={({ isSelected, isFocused, isFocusVisible }) => twMerge('relative flex flex-col px-4 py-2 hover:bg-gray-200', isFocusVisible && 'bg-pink-400', isSelected && 'bg-gray-300 font-bold')}>
                            <Text className='text-secondary' slot="description">{option.id}</Text>
                            <Text slot="label">{option.value}</Text>
                        </ListBoxItem>}
                    </ListBox>
                </Popover>
            </ComboBox>

        );
    }

    function StatusItem(props: ListBoxItemProps & { children: React.ReactNode }) {
        return (
            <ListBoxItem
                {...props}
                className="group flex items-center gap-2 cursor-default select-none py-2 px-4 outline-none rounded text-gray-900 focus:bg-rose-600 focus:text-white"
            >
                {({ isSelected }) => (
                    <>
                        <span className="flex-1 flex items-center gap-2 truncate font-normal group-selected:font-medium">
                            {props.children}
                        </span>
                        <span className="w-5 flex items-center text-rose-600 group-focus:text-white">
                            {isSelected && 'S'}
                        </span>
                    </>
                )}
            </ListBoxItem>
        );
    }

    function Status({ className }: { className: string }) {
        return (
            <span
                className={`w-3 h-3 rounded-full border border-solid border-white ${className}`}
            />
        );
    }
    const { dragAndDropHooks } = useDragAndDrop({
        //
      });
    return <div className={twMerge('py-64 sm:px-16', className)}>
        <GridList aria-label="Favorite pokemon" selectionMode="multiple" dragAndDropHooks={dragAndDropHooks} >
            <GridListItem textValue="Charizard">
                <Checkbox slot="selection" />
                Charizard
                <Button aria-label="Info">ⓘ</Button>
            </GridListItem>
            <GridListItem textValue="Blastoise">
                <Checkbox slot="selection" />
                Blastoise
                <Button aria-label="Info">ⓘ</Button>
            </GridListItem>
            <GridListItem textValue="Venusaur">
                <Checkbox slot="selection" />
                Venusaur
                <Button aria-label="Info">ⓘ</Button>
            </GridListItem>
            <GridListItem textValue="Pikachu">
                <Checkbox slot="selection" />
                Pikachu
                <Button aria-label="Info">ⓘ</Button>
            </GridListItem>
        </GridList>
        {SelectExample()}
        {/* <ComboBox>
            <Label>Favorite Animal</Label>
            <div>
                <Input />
                <Button>▼</Button>
            </div>
            <Popover>
                <ListBox>
                    <ListBoxItem>Aardvark</ListBoxItem>
                    <ListBoxItem>Cat</ListBoxItem>
                    <ListBoxItem>Dog</ListBoxItem>
                    <ListBoxItem>Kangaroo</ListBoxItem>
                    <ListBoxItem>Panda</ListBoxItem>
                    <ListBoxItem>Snake</ListBoxItem>
                    <ListBoxItem>Aardvark</ListBoxItem>
                    <ListBoxItem>Cat</ListBoxItem>
                    <ListBoxItem>Dog</ListBoxItem>
                    <ListBoxItem>Kangaroo</ListBoxItem>
                    <ListBoxItem>Panda</ListBoxItem>
                    <ListBoxItem>Snake</ListBoxItem>
                </ListBox>
            </Popover>
        </ComboBox> */}
        {/* <Dropdown options={[{ key: 1, id: 1, text: 'Aardvark', description: '100-100' }, { key: 2, id: 2, text: 'Cat', description: 'CA-T-12200' }]} selection />
        <ComboBox
            className={''}
            // menuTrigger="focus"
            selectedKey={id}
            onSelectionChange={(id) => setId(id.toString())}
        >
            <div className=''>
                <Input className={'w-full'} />
                <Button className={'px-2'}>▾</Button>
            </div>
            <Popover className={'sm:w-[200px] w-full block'} containerPadding={0}>
                <ListBox className='rounded bg-white border-grey-300 border' items={options} >
                    {(option) => <ListBoxItem id={option.id} textValue={option.value} key={'k_' + option.id} className={({ isSelected, isFocused }) => twMerge('relative flex flex-col px-4 py-2 border hover:bg-gray-200', isFocused && 'bg-pink-400', isSelected && 'bg-gray-300 font-bold')}>
                        <Text className='text-secondary' slot="description">{option.id}</Text>
                        <Text slot="label">{option.value}</Text>
                    </ListBoxItem>}
                </ListBox>

            </Popover>
        </ComboBox> */}
    </div>
}
export default FETest;
