import React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { duplicateCombinedInspection, duplicateInspection } from '../../actions/duplicateActions';

type DuplicateInspectionProps = {
    className?: string;
    inspectionId: string;
    orderId: string;
    itemName: string;
    isCombinedInspection?: boolean;
    fromCombinedInspection?: boolean;
    type?: 'button' | 'link';
    context?: 'combined' | 'not-combined';
};

const DuplicateInspection = (props: DuplicateInspectionProps): React.ReactElement => {
    const { className, type = 'button', isCombinedInspection = false, context = 'not-combined' } = props;
    const dispatch = useAppDispatch();
    const { inspectionId, orderId, itemName } = props;
    const duplicate = () => {
        dispatch(isCombinedInspection ? duplicateCombinedInspection({ combinedInspectionId: inspectionId, orderId, inspectionName: itemName, reloadData: context === 'not-combined' }) : duplicateInspection(inspectionId, orderId, itemName));
    }
    if (type === 'button') {
        return <Button noBorder onClick={(): void => duplicate()}>
            <FormattedMessage id='inspections.duplicate_short' />
        </Button>
    }
    return <div onClick={(): void => duplicate()} className={twMerge(className, 'cursor-pointer')}>
        <FormattedMessage id={isCombinedInspection ? 'inspections.duplicate_combined_inspection' : 'inspections.duplicate_short'} /></div>
}

export default DuplicateInspection;
