import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import QIcon from '../../../base/components/basic/Icon';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { UploadingFile } from '../types';
import UploadingItem from './UploadingItem2';

interface UploadingProps {
    autoClear?: boolean;
    showCompleteMessage?: boolean;
    files: UploadingFile[];
    isComplete: boolean;
    inline?: boolean;
    clearTimeout?: number;
    compact?: boolean;
    clearUploadsHandler();
    reUpload(files: File[]);


}
const Uploading = (props: UploadingProps): React.ReactElement => {
    const intl = useIntl();
    const fileCnt: number = Array.from(props.files).length || 0;
    const clearTimeout: number = props.clearTimeout || 0;
    const { inline = false, autoClear = false, showCompleteMessage = true, isComplete, compact = false } = props;
    const clear = (): void => {
        setTimeout(() => props.clearUploadsHandler(), clearTimeout)
    }
    const completeMessage = <div className={twMerge('items-center space-x-2 flex py-3 text-base border-t -mx-4 px-4 mt-2 pb-0', !inline && '-mb-4')}>
        <div className='flex space-x-1'><QIcon name='check_circle' className='text-brand'/><div><FormattedMessage id='file_upload.upload_is_complete' />. <FormattedMessage id='file_upload.files_uploaded' values={{cnt: props.files.length}}/></div>
        </div>
        <Button small className='text-xs py-1 px-1' onClick={props.clearUploadsHandler}><FormattedMessage id='globals.close' /></Button>
    </div>;
    if (fileCnt > 0) {
        let hasError = false;
        props.files.forEach((file) => {
            if (file.status === 'error') {
                hasError = true;
            }
        })
        const content = <>
            {(!isComplete) && <div className='pb-2 pt-4 space-x-1 items-center  flex' >
                <div><FormattedMessage id={fileCnt === 1 ? 'file_upload.uploading_file' : 'file_upload.uploading_files'} values={{ fileCnt }} /></div>
                <div><Icon loading name='circle notch'/></div>
            </div>}
            <div className='flex flex-col space-y-2 py-2'>
                {props.files.map((file: UploadingFile, index) => {
                    return <UploadingItem compact={compact} reUploadFile={(file) => props.reUpload([file])} inline={inline} key={index + ' uploading'} file={file} />;
                })}
            </div>
            {isComplete && (showCompleteMessage || hasError) && completeMessage}
            {isComplete && autoClear && !hasError && clear()}
        </>;
        return (
            inline ? <div style={{}}>{content}</div> : <ContentSection className='w-full border-0 p-0 pb-0 px-0' headerText={intl.formatMessage({ id: 'file_upload.uploading_files_header_cnt' }, { cnt: fileCnt })} content={content} />
        );
    }
    return (null);
};

export default Uploading;
