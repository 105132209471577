import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Group, SupplierUser } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { Checkbox } from '../../../base/components/basic/CheckboxRadio';
import { OptionsType, Role } from '../../../base/types';
import { addArrayItemOrRemoveIfExists, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { ListView } from '../../globals/components';
import { ListViewHeader, ListViewHeaderItem, ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { getLocales } from '../../globals/selectors';
import { sendStatusMessage } from '../../messages/actions';
import { UserNameText } from '../../users/components/Widgets';
import CreateUserModal from '../../users/containers/CreateUserModal';
import DeactivateUser from '../../users/containers/DeactivateUser';
import { getRolesByIdSelector, getRolesSelector } from '../../users/selectors';
import { setSupplierUserAsContactPerson } from '../slice/suppliersSlice';

type Props = {
    supplierId: string;
    users: SupplierUser[];
    className?: string;
    disabled?: boolean;
    groups?: any;
    supplierGroup?: Group;
    onUserUpdated();
};

const DetailedSupplierUsers = (props: Props): React.ReactElement => {
    const { className, disabled, groups, supplierGroup, supplierId } = props;
    const rolesById = useSelector(getRolesByIdSelector);
    const roles = useSelector(getRolesSelector);
    const locales = useSelector(getLocales);
    const [showAddUser, setShowAddUser] = useState(false);
    const [users, setUsers] = useState((props.users || []).filter((user) => !user.disabled));
    const [contacts, setContacts] = useState((props.users && props.users.map(user => user.contact && user.id)) || []);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const formattedRoles: OptionsType[] = [];
    roles.forEach((role: Role) => {
        formattedRoles.push({ key: role.id, value: role.id, text: role.name.C });
    });

    const updateContact = (id: string) => {
        const c = addArrayItemOrRemoveIfExists([...contacts], id);
        setContacts(c);
    }
    useEffect(() => {
        if (props.users) {
            setUsers(props.users);
            setContacts((props.users && props.users.map(user => user.contact && user.id)) || []);
        }
    }, [props.users]);
    const headerItems: ListViewHeaderItem[] = [
        { label: formatMessage({ id: 'supplier.user_list.user_name.label' }), className: 'w-4/16', key: 'item' },
        { label: formatMessage({ id: 'supplier.user_list.user_email.label' }), className: 'w-5/16', key: 'order_no' },
        { label: formatMessage({ id: 'supplier.user_list.user_role.label' }), className: 'w-4/16', key: 'order_no' },
        { label: formatMessage({ id: 'supplier.user_list.user_is_contact.label' }), className: 'w-1/16', key: 'order_no' },
        { label: undefined, className: 'w-2/16', key: 'spacer.2' },
    ];
    return <div className={twMerge('', className)}>
        {showAddUser &&
            <CreateUserModal
                showCreateUser={showAddUser}
                cancel={(): void => setShowAddUser(false)}
                done={(): void => {
                    setShowAddUser(false);
                    dispatch(sendStatusMessage(['status_message.user_was_created_succesfully'], 3000)); // TODO move to slice at some later point
                    setTimeout(() => props.onUserUpdated(), 1000); // TODO this timeout should be removed when feature is part of new supplier service
                }}
                roles={formattedRoles}
                groups={[supplierGroup]}
                hideGroups={true}
                locales={locales}
            />}
        <ListView>
            {users && users.length === 0 && <div><FormattedMessage id='suppliers.no_users' /></div>}
            {users && users.length > 0 && <ListViewHeader items={headerItems} />}
            {users && users.map((user, i) => {
                return <ListViewLine className='border-t sm:border-t-0 justify-start pb-6 pt-6' key={'production_units_' + user.id + '_' + i}>
                    <ListViewItem className='w-4/16'><UserNameText user={user} /></ListViewItem>
                    <ListViewItem className='w-5/16'>{user.email}</ListViewItem>
                    <ListViewItem className='w-4/16'>{rolesById && (rolesById[user.user_role_id].name.C)}</ListViewItem>
                    <ListViewItem className='w-1/16'>
                        <Checkbox onClick={(e) => {
                            updateContact(user.id)
                            dispatch(setSupplierUserAsContactPerson({ supplierId, userId: user.id, isContact: e.currentTarget.checked }))
                        }} checked={contacts.includes(user.id)} /></ListViewItem>
                    <ListViewItem className='w-2/16 link justify-end'>
                        <DeactivateUser
                            userId={user.id}
                            done={() => setTimeout(() => props.onUserUpdated(), 1000)}  // TODO this timeout should be removed when feature is part of new supplier service
                        ><span className='link'><FormattedMessage id='suppliers.deactivate_user' /></span></DeactivateUser>
                    </ListViewItem>
                </ListViewLine>
            })}</ListView>
        <Button primary onClick={() => setShowAddUser(true)}><FormattedMessage id='suppliers.add_user' /></Button>
    </div >
}
export default DetailedSupplierUsers;
