/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InputCustomFieldDefinitionMultipleChoiceOption } from './InputCustomFieldDefinitionMultipleChoiceOption';

export type InputCustomFieldDefinitionMultipleChoice = {
    multiple_selection_allowed: boolean;
    options: Array<InputCustomFieldDefinitionMultipleChoiceOption>;
    type: InputCustomFieldDefinitionMultipleChoice.type;
};

export namespace InputCustomFieldDefinitionMultipleChoice {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

