import { ResponsivePie as NPie } from '@nivo/pie';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Header, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { twMerge } from 'tailwind-merge';
import { DashboardPopupInspection } from '../../../../backend_api/models';
import { APPROVED_COLOR, DOWNLOADING_COLOR, IN_PROGRESS_COLOR, PENDING_COLOR, PLANNED_COLOR, REJECTED_COLOR } from '../../../../base/config';
import { convertConclusionCategoy, getLocaleSpecificString, prettyClipText, preventDefaultAndStopPropagation, useIsSmallMobileDevice } from '../../../../base/utils';
import { TextItem } from '../../../globals/components';
import { isInspectionPartOfCombinedInspection } from '../../Utils';
import { OrderListInspectionExtended, OrderListOrderExtended } from '../../slices/inspectionsListSlice';
import { Inspection, Order } from '../../types';
import { InspectionsWeekviewInspectionExtended } from '../dashboard/views/weekView/WeekView2';

interface InspectionStatusProps {
    inspection: Inspection | InspectionsWeekviewInspectionExtended;
    skipLink?: boolean;
    isTag?: boolean;
    isFluid?: boolean;
    emptyText?: boolean;
    content?: any;
    classNames?: string;
    mobile?: boolean;
    showInfo?: boolean;
    hover?(hover: boolean);
}
export const ReportStatus = ({ inspection, mobile }: InspectionStatusProps): React.ReactElement => {
    const icons = { approved: 'icon check', rejected: 'icon remove', pending: 'icon refresh', not_set: 'icon refresh' };
    const text = <Fragment><Icon name={icons[inspection.conclusion.conclusion_category] as SemanticICONS} />{getStatusText(inspection)}</Fragment>;
    if (mobile) {
        return text;
    }
    const textColor = 'text-status-' + getStatus(inspection);
    return (
        <div className={twMerge('w-full p-2 rounded-md bg-white text-sm', textColor)}>
            {text}
        </div>
    );
};

const getStatusText = (inspection): React.ReactElement | string => {
    if (inspection.status == 'report') {
        return inspection.conclusion ? getLocaleSpecificString(inspection.conclusion.short_name).toUpperCase() : ''
    }
    else {
        return <FormattedMessage id={getStatusKey(inspection)} />;
    }
}

export const ReportStatusCompact = (props: { inspection: Inspection }): React.ReactElement => {
    const inspection = props.inspection;
    const statusKey = getStatusKey(inspection, true);
    const hasInspectorConclusion = inspection.inspector_conclusion.conclusion_category !== 'not_set' && inspection.has_approval_flow;
    const inspectorConclusion = hasInspectorConclusion ? inspection.inspector_conclusion.conclusion_category : 'not_set'
    const emptyInspectorComment = !inspection.inspector_comment || inspection.inspector_comment.comment === ' '; // TODO: FIXME: By mistake, empty inspector comments have been saved as ' ' (one space) for a very long time and needs this check.

    return <div className='statusContainer'>
        <div className='inspector'>
            <div className='label'><FormattedMessage id='inspections.inspector' /></div>
            <div className='inspectorName'>{inspection.assigned_user ? <span>{inspection.assigned_user.firstname} {inspection.assigned_user.lastname}</span> : '-'}</div>
        </div>
        <div className='status'>
            <div className='label'><FormattedMessage id='inspections.status' /></div>
            <div className='container'>
                {hasInspectorConclusion && <div className={classnames('inspectorConclusion', inspectorConclusion)}></div>}
                <div className={classnames('approval', statusKey, { hasInspector: hasInspectorConclusion })}>{getStatusText(inspection)}</div>
            </div>
        </div>
        {!emptyInspectorComment && <div className={'inspectorComment '}>{prettyClipText(inspection.inspector_comment.comment, 100)}</div>}
    </div>;
}


export const InspectionStatus = ({ inspection, skipLink }: InspectionStatusProps): React.ReactElement => {
    const done = inspection.status === 'report';
    const combined = isInspectionPartOfCombinedInspection(inspection);
    const ongoing = inspection.status === 'ongoing';
    const locked = inspection.locked;
    const hasLink = done || ongoing || combined;
    const shouldSkipLink = !hasLink && !locked || skipLink;
    const linkLink = shouldSkipLink ? '' : 'haslink';
    const statusKey = getStatusKey(inspection);
    return (
        <div className={['', linkLink, (done || ongoing || locked) ? 'disabled' : null].join(' ')}>
            {getFormattedStatus(inspection, statusKey)}
        </div>
    );
};

type TypeStatusProps = {
    inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection;
}

type WithDashboardPopupInspection = Omit<InspectionStatusProps, 'inspection'> & TypeStatusProps

export const InspectionTypeStatus = ({ inspection, isTag, isFluid, emptyText, showInfo = false }: WithDashboardPopupInspection): React.ReactElement => {
    const intl = useIntl();
    const done = inspection.status === 'report';
    const ongoing = inspection.status === 'ongoing';
    const locked = inspection.locked;
    const cn = 'bg-status-' + getColor(inspection);
    let cnType = isTag ? 'inspectionTypeTag' : 'inspectionType';
    cnType += isFluid ? ' fluid' : '';
    let text;
    if (isTag) {
        text = inspection.inspection_type && inspection.inspection_type.tag || (emptyText ? <div className='emptyText'></div> : '-');
    }
    else {
        if (inReportState(inspection)) {
            text = getLocaleSpecificString(inspection.conclusion.short_name).toUpperCase();
        }
        else {
            text = <FormattedMessage id={getStatusKey(inspection)} />
        }
    }
    let content;
    const inspector_status_classname = inspection.inspector_conclusion && inspection.inspector_conclusion.conclusion_category !== 'not_set' ? 'status_' + inspection.inspector_conclusion.conclusion_category : cn;
    if (inReportState(inspection) && inspection.has_approval_flow && cn !== inspector_status_classname && !isTag) {
        content = <div className='dual_status'>
            <div className={[inspector_status_classname, cnType, 'inspector_conclusion'].join(' ')} />
            <div className={[cn, cnType].join(' ')}>
                {text}
            </div>
        </div>
    }
    else {
        content = <div className={[cn, cnType].join(' ')}>{text}</div>;
    }

    return (
        <div className={['status', cnType, (done || ongoing || locked) ? 'disabled' : null].join(' ')}>
            {showInfo ? <Popup trigger={content}>
                <TextItem label={intl.formatMessage({ id: 'inspection_types.type_label' })} text={inspection.inspection_type && inspection.inspection_type.name || intl.formatMessage({ id: 'inspections.inspection_types.type_none' })} column />
            </Popup> : content}
        </div>
    );
};

export const DotStatus = (props: InspectionStatusProps): React.ReactElement => {
    const cn = getColor(props.inspection);
    return (
        <Label className='status dot' color={`status_${cn}` as unknown as SemanticCOLORS} empty key={cn} />
    );
};

export const PlainStatus = ({ inspection, classNames }: WithDashboardPopupInspection): React.ReactElement => {
    const cn = 'rounded bg-status-' + getColor(inspection);
    const inspector_status_classname = inspection.inspector_conclusion && 'bg-status-' + inspection.inspector_conclusion.conclusion_category;
    const hasDual = (inspection.status === 'report' && inspection.has_approval_flow) || (inspection.status === 'ongoing' && (inspection.inspector_conclusion && inspection.inspector_conclusion.conclusion_category && inspection.inspector_conclusion.conclusion_category !== 'not_set'));
    let status
    const inspectionStatus = <div className={twMerge(cn, hasDual ? 'rounded-l-none' : 'rounded', 'flex px-2 w-full h-full justify-center items-center text-center leading-normal text-xs text-white')}>{getStatusText(inspection)}</div>
    if (hasDual) {
        status = <div className='flex w-full h-full justify-center items-center bg-white '>
            <div className={classnames(inspector_status_classname, 'w-3 mr-1 h-full rounded-l')}></div>
            {inspectionStatus}
        </div>
    }
    else {
        status = inspectionStatus
    }
    return <div className={classnames(classNames, cn)} key={cn}>{status}</div>;
};

export const NakedStatus = ({ inspection, content, classNames }: InspectionStatusProps): React.ReactElement => {
    const cn = getColor(inspection);
    return (
        <div className={twMerge('flex items-center justify-center text-white p-1 rounded text-xs', classNames, 'bg-status-' + cn)}>{content}</div>
        // <Label className={twMerge(classNames, 'bg-status-' + cn)} color={'bg-status-' + cn as unknown as SemanticCOLORS} key={cn}><div className='text-primary'>x: {cn}</div>{content}</Label>
    );
};

export const NakedStatusWithType = ({ inspection, className }: { inspection: Inspection | InspectionsWeekviewInspectionExtended, className?: string }): React.ReactElement => {
    const cn = getColor(inspection);
    return <div className={twMerge('text-white bg-status-' + cn, className)}>{inspection.inspection_type && inspection.inspection_type.tag || '-'} </div>
}

export const OrderStatus = (props: { order: (Order | OrderListOrderExtended); inspections: (OrderListInspectionExtended | Inspection)[] }): React.ReactElement => {
    const intl = useIntl();
    const isMobile = useIsSmallMobileDevice();
    const inspections = props.inspections;
    const inspObj = { planned: 0, ongoing: 0, report: 0, downloaded: 0 };
    const reportObj = { approved: 0, rejected: 0, ongoing: 0, pending: 0 };
    const total = inspections.length;
    const statusArr = [];

    inspections.forEach((inspection: Inspection) => {
        if (inspection) {
            if (inspection.status === 'report') {
                const status = inspection.conclusion ? convertConclusionCategoy(inspection.conclusion.conclusion_category) : 'not_set';
                reportObj[status]++;
                statusArr.push({ status: status, type: 'report' });
            }
            if (inspection.locked && inspection.status === 'planned') {
                statusArr.push({ status: 'downloaded', type: 'report' });
                inspObj.downloaded++;
            }
            if (!inspection.locked && inspection.status === 'planned') {
                statusArr.push({ status: inspection.status, type: 'report' });
                inspObj[inspection.status]++;
            }
            if (inspection.status === 'ongoing') {
                statusArr.push({ status: inspection.status, type: 'report' });
                inspObj[inspection.status]++;
            }
        }
    });
    const planned = inspObj.planned;
    const inProgress = inspObj.ongoing;
    const downloaded = inspObj.downloaded;
    const approved = reportObj.approved;
    const rejected = reportObj.rejected;
    const pending = reportObj.pending;
    const reportInProgress = reportObj.ongoing;
    const plannedPercent = parseInt(((planned / total) * 100).toFixed(2));
    const inProgressPercent = parseInt(((inProgress / total) * 100).toFixed(2));
    const downloadedPercent = parseInt(((downloaded / total) * 100).toFixed(2));
    const approvedPercent = parseInt(((reportObj.approved / total) * 100).toFixed(2));
    const rejectedPercent = parseInt(((reportObj.rejected / total) * 100).toFixed(2));
    const reportPendingPercent = parseInt(((reportObj.pending / total) * 100).toFixed(2));
    const reportInProgressPercent = parseInt(((reportObj.ongoing / total) * 100).toFixed(2));
    const statusItemsCnt = statusArr.length;
    const data = [
        { label: intl.formatMessage({ id: 'inspections.planned' }), value: plannedPercent, color: PLANNED_COLOR, id: 'Planned' },
        { label: intl.formatMessage({ id: 'inspections.downloaded' }), value: downloadedPercent, color: DOWNLOADING_COLOR, id: 'Downloaded' },
        { label: intl.formatMessage({ id: 'inspections.in_progress' }), value: inProgressPercent, color: IN_PROGRESS_COLOR, id: 'InProgress' },
        { label: intl.formatMessage({ id: 'inspections.approved' }), value: approvedPercent, color: APPROVED_COLOR, id: 'Approved' },
        { label: intl.formatMessage({ id: 'inspections.rejected' }), value: rejectedPercent, color: REJECTED_COLOR, id: 'Rejected' },
        { label: intl.formatMessage({ id: 'inspections.pending' }), value: reportPendingPercent, color: PENDING_COLOR, id: 'ReportPending' },
        { label: intl.formatMessage({ id: 'inspections.report_in_progress' }), value: reportInProgressPercent, color: IN_PROGRESS_COLOR, id: 'ReportProgress' },
    ];
    const content = <div className='preview'>
        <Header as='h3'>{props.order.order_number}</Header>
        {plannedPercent > 0 && <div className='item'><div className='bg-status-planned w-10 h-2 labelFoo'></div><FormattedMessage id='inspections.planned' /><FormattedMessage id='inspections.colon_sign' />{planned} / {total}  </div>}
        {downloadedPercent > 0 && <div className='item'><div className='status_downloaded labelFoo'></div><FormattedMessage id='inspections.downloaded' /><FormattedMessage id='inspections.colon_sign' />{downloaded} / {total}</div>}
        {inProgressPercent > 0 && <div className='item'><div className='bg-status-in-progress labelFoo'></div><FormattedMessage id='inspections.in_progress' /><FormattedMessage id='inspections.colon_sign' />{inProgress} / {total}</div>}
        {approvedPercent > 0 && <div className='item'><div className='bg-status-approved labelFoo'></div><FormattedMessage id='inspections.approved' /><FormattedMessage id='inspections.colon_sign' />{approved} / {total}</div>}
        {rejectedPercent > 0 && <div className='item'><div className='status_rejected labelFoo'></div><FormattedMessage id='inspections.rejected' /><FormattedMessage id='inspections.colon_sign' />{rejected} / {total}</div>}
        {reportPendingPercent > 0 && <div className='item'><div className='status_pending labelFoo'></div><FormattedMessage id='inspections.pending' /><FormattedMessage id='inspections.colon_sign' />{pending} / {total}</div>}
        {reportInProgressPercent > 0 && <div className='item'><div className='status_pending labelFoo'></div><FormattedMessage id='inspections.report_in_progress' /><FormattedMessage id='inspections.colon_sign' />{reportInProgress} / {total}</div>}
        <div className='chart'><NPie
            data={data}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={{ datum: 'data.color' }}
            padAngle={0}
            borderWidth={2}
            borderColor={'#fff'}
            tooltip={({ datum: { value, color, label } }): React.ReactElement => {
                return <div className='px-2 py-1 bg-white shadow-xs'><span style={{ backgroundColor: color }} className='label'></span><span className='value'>{label}: {value}%</span></div>;
            }}
        />
        </div>
    </div>;
    const trigger = <div className='flex w-full' onClick={(e): void => (preventDefaultAndStopPropagation(e))}>
        {statusArr.map((obj, index: number) => {
            const s = 'bg-status-' + obj.status;
            return <span key={index} className={classnames(obj.type, s, 'h-full', {
                'rounded-l-sm': index === 0,
                'rounded-r-sm': index === statusItemsCnt - 1,
            })}></span>;
        })}
    </div>;
    if (isMobile) {
        return <Modal
            trigger={trigger}
            closeIcon={true}
        >
            <Modal.Content>{content}</Modal.Content>
        </Modal>
    }
    return <Popup
        wide={true}
        position='left center'
        hoverable={true}
        verticalOffset={0}
        trigger={trigger}>
        <Popup.Content>
            {content}
        </Popup.Content>
    </Popup>;
};

const getFormattedStatus = (inspection: Inspection | InspectionsWeekviewInspectionExtended, key): React.ReactElement => {
    const intl = useIntl();
    const cn = 'status_' + getColor(inspection);
    const inspector_status_classname = inspection.inspector_conclusion.conclusion_category !== 'not_set' ? 'status_' + inspection.inspector_conclusion.conclusion_category : cn;
    let status;
    if (inReportState(inspection)) {
        if (inspection.has_approval_flow) {
            status = <div className='dual_status'>
                <Label className={'inspector_status ' + inspector_status_classname} size='small' circular />
                <Label className={cn} size='small' circular>{getLocaleSpecificString(inspection.conclusion.short_name).toUpperCase()}</Label>
            </div>
        }
        else {
            status = <Label className={cn} size='small' circular>{getLocaleSpecificString(inspection.conclusion.short_name).toUpperCase()}</Label>
        }
    }
    else {
        status = <Label className={cn} size='small' circular><FormattedMessage id={key} /></Label>
    }
    return (
        <Popup
            disabled={getStatus(inspection) === 'planned' || getStatus(inspection) === 'downloaded'}
            trigger={status}
            className='inspectionTypes dashboardPopup'
            hoverable
            flowing
        >
            <Popup.Content>
                {intl.formatMessage({ id: 'inspections.hover.inspector.conclusion' }) + ': ' + getLocaleSpecificString(inspection.inspector_conclusion.full_name)}<br />
                {intl.formatMessage({ id: 'inspections.hover.inspection.status' }) + ': ' + getLocaleSpecificString(inspection.conclusion.full_name)}<br />
            </Popup.Content>
        </Popup>);
};

const getStatusKey = (inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection, noPrefix?: boolean): string => {
    const status = getStatus(inspection);
    if (status === 'planned' && inspection.locked) {
        return noPrefix ? 'planned_and_locked' : 'status.planned_and_locked';
    }
    return noPrefix ? status : 'status.' + status;
};

export const getStatus = (inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection): string => {
    if (inspection.status) {
        if (inspection.status.toLowerCase() === 'report') {
            return inspection.conclusion ? convertConclusionCategoy(inspection.conclusion.conclusion_category) : '';
        }
        if (inspection.locked && inspection.status.toLowerCase() === 'planned') {
            return 'downloaded';
        }
        return inspection.status.toLowerCase();
    }
    return undefined;
};

export const getColor = (inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection): string => {
    let color;
    switch (getStatus(inspection)) {
        case 'pending':
        case 'deprecated':
            color = 'pending';
            break;
        case 'ongoing':
            color = 'in-progress';
            break;
        case 'approved':
        case 'done':
            color = 'approved';
            break;
        case 'rejected':
            color = 'rejected';
            break;
        case 'downloaded':
            color = 'downloaded';
            break;
        case 'planned':
            color = 'planned';
            break;
        default:
            color = 'default';
            break;
    }
    return color;
};

const inReportState = (inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection): boolean => {
    return inspection.status.toLowerCase() === 'report';
}
