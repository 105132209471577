import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GetProfileResponse } from '../../../backend_api/models/GetProfileResponse';
import BaseComponent from '../../../base/components/BaseComponent';
import { AppState } from '../../../base/types';
import { objectHasKeys } from '../../../base/utils';
import { updateUserPreferences } from '../../users/actions/actions';
import { getUserPreferencesSelector } from '../../users/selectors';
import { UserPreferences } from '../../users/types';
import { getProfile, updateProfile } from '../actions';
import Profile from '../components/Profile';
import { getIsUpdating, getProfile as getProfileSelector } from '../selectors';

type DispatchProps = {
    actions: {
        getProfile: typeof getProfile;
        updateProfile: typeof updateProfile;
        updateUserPreferences: typeof updateUserPreferences;
    };
};
const initialState = { profile: undefined, userPreferences: {} };

type State = Readonly<typeof initialState>;

type StateProps = {
    isUpdatingProfile: boolean;
    profile: GetProfileResponse;
    userPreferences: UserPreferences;
}
type ProfileContainerProps = StateProps & DispatchProps;

class ProfileContainer extends BaseComponent<ProfileContainerProps, State> {
    constructor(props: Readonly<ProfileContainerProps>) {
        super(props);
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleUserPreferenceChange = this.handleUserPreferenceChange.bind(this);
    }

    public doRender(): React.ReactElement {
        return (
            <Profile
                handleChange={this.handleChange}
                handleUserPreferenceChange={this.handleUserPreferenceChange}
                handleSave={this.saveProfile}
                isUpdatingProfile={this.props.isUpdatingProfile}
                profile={this.state.profile}
                userPreferences={this.state.userPreferences} />
        );
    }

    public handleChange(event): void {
        const name = event.target.name;
        const value = event.target.value;
        const oldProfile = Object.assign({}, this.state.profile);
        const newProfile = { ...oldProfile, [name]: value };
        this.setState({ profile: newProfile });
    }

    public handleUserPreferenceChange(evt, item): void {
        const prefs = Object.assign({}, this.state.userPreferences);
        let value;
        if (item.type === 'checkbox') {
            value = item.checked;
        } else {
            value = item.value;
        }
        const newPrefs = { ...prefs, [item.name]: value };
        this.setState({ userPreferences: newPrefs });
    }

    public saveProfile = (): void => {
        this.props.actions.updateProfile(this.state.profile.id, this.state.profile);
        this.props.actions.updateUserPreferences(this.state.userPreferences);
    };

    public componentDidMount(): void {
        // Get profile data from backend on component mount
        this.props.actions.getProfile();
    }

    public componentWillReceiveProps(nextProps): void {
        this.setState({ profile: nextProps.profile }, () => {
            if (!objectHasKeys(this.state.userPreferences)) {
                this.setState({ userPreferences: this.props.userPreferences });
            }
        });
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        profile: getProfileSelector(state),
        isUpdatingProfile: getIsUpdating(state),
        userPreferences: getUserPreferencesSelector(state),
    };
};

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            getProfile,
            updateProfile,
            updateUserPreferences,
        }, dispatch),
    };
};

export default connect<StateProps, DispatchProps, null>(mapStateToProps, mapDispatchToProps)(ProfileContainer);
