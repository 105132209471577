import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Progress } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Icon from '../../../base/components/basic/Icon';
import Label from '../../../base/components/basic/Label';
import { UploadingFile } from '../types';
import { calculatePrettySize } from './handlers';

type UploadingItemProps = {
    inline: boolean;
    file: UploadingFile;
    compact?: boolean;
    reUploadFile(file: File);
}

const UploadingItem = (props: UploadingItemProps): React.ReactElement => {
    const { file, inline = false, compact = false } = props;
    const [show, setShow] = useState<boolean>(true);

    const reUpload = () => {
        props.reUploadFile(file.file);
    }

    if (show) {
        if (inline) {
            return (
                <div className=''>
                    {(file.status === 'ok' && file.progress < 101 && file.progress > 0) && <div className='text-secondary truncate pb-1' title={file.file.name}>
                        {file.file.name}
                    </div>}
                    <div className=''>
                        {(file.status === 'ok' && file.progress === 0) &&
                            <Progress active size='small' color='green' percent={100} />
                        }
                        {(file.status === 'ok' && file.progress < 100 && file.progress > 0) &&
                            <Progress indicating size='small' percent={file.progress} color='green' />
                        }
                        {(file.status === 'ok' && file.progress === 100) && <div className='flex truncate text-sm rounded text-white px-1 border-0 bg-green-500 py-0 w-full space-x-1'><Icon name='check_circle' /><span><FormattedMessage id='file_upload.statusbar.done' /></span></div>}
                    </div>
                </div>
            );
        }
        return (<div className='flex items-center space-x-1 text-sm whitespace-normal'>
            <div className={twMerge('font-bold truncate', compact ? 'w-6/12' : 'w-5/12')}>
                {file.file.name}
            </div>
            <div className={twMerge('text-secondary', compact ? 'w-3/12' : 'w-2/12')} >
                {calculatePrettySize(file.file.size)}
            </div>
            <div className={twMerge('items-center h-4', compact ? 'w-3/12' : 'w-5/12', file.progress === 100 && 'flex')}>
                {(file.status === 'ok' && file.progress === 0) &&
                    <Progress active size='medium' color='green' percent={100} className='' />
                }
                {(file.status === 'ok' && file.progress < 100 && file.progress > 0) &&
                    <Progress percent={file.progress} size='small' progress color='green' className='pl-2' />
                }
                {(file.status === 'ok' && file.progress === 100) && <div className='flex truncate text-sm rounded text-white px-1 border-0 bg-green600 py-0 w-full'><Icon name='check' /><FormattedMessage id='file_upload.statusbar.done' /></div>}
                {file.status === 'error' && <div className="flex justify-between">
                    <div className='w-full flex pr-2'><Label className='w-full'><Icon name='remove' /><FormattedMessage id='attachments.statusbar.error' /></Label></div>
                    <Button onClick={reUpload}><FormattedMessage id='attachments.reupload.button' /></Button>
                </div>}
            </div>
        </div>);
    }
    else {
        return null;
    }
};

export default UploadingItem;
