import React from 'react';
import { FormattedMessage } from 'react-intl';

export function HideableHints<T extends { id: string }>(props: {
    entities: T[],
    onEntitySelected(entity: T): void,
    renderShowAllText(): React.ReactElement,
    renderMatchingEntityText(entity: T): React.ReactElement
}): React.ReactElement {
    const minimumMatchesForButtons = 3;
    const [showAllHints, setShowAll] = React.useState(false)

    if (props.entities.length < minimumMatchesForButtons) {
        // Always show hints, if there are only a few.
        return (
            <div className='flex flex-col'>
                <GenericHints
                    entities={props.entities}
                    renderMatchingEntityText={props.renderMatchingEntityText}
                    onEntitySelected={props.onEntitySelected}
                />
            </div>
        );
    } else if (showAllHints) {
        // Give the option to hide the hints.
        return (
            <div className='flex flex-col'>
                <GenericHints
                    entities={props.entities}
                    renderMatchingEntityText={props.renderMatchingEntityText}
                    onEntitySelected={props.onEntitySelected}
                />
                <GenericHideButton onClick={() => setShowAll(false)} />
            </div>
        )
    } else {
        // Give the option to show the hints again.
        return (
            <div className='flex flex-col'>
                <GenericShowAllButton
                    onClick={() => setShowAll(true)}
                    message={props.renderShowAllText} />
            </div>
        )
    }
}

function GenericHints<T extends { id: string }>(props: {
    entities: T[],
    onEntitySelected(entity: T): void,
    renderMatchingEntityText(entity: T): React.ReactElement
}): React.ReactElement {
    return (<>{
        props.entities.map((entity: T) =>
            <GenericHint
                key={entity.id}
                entity={entity}
                matchingEntityMessage={props.renderMatchingEntityText}
                onClick={() => props.onEntitySelected(entity)}
            />
        )
    }</>)
}

function GenericHint<T extends { id: string }>(props: {
    entity: T;
    onClick: () => void;
    matchingEntityMessage(entity: T): React.ReactElement;
}): React.ReactElement {
    if (!props.entity || !props.matchingEntityMessage) {
        return null;
    }

    return (
        <div
            className='flex w-full py-1 pr-2 pl-1 text-sm space-x-1 cursor-pointer hover:bg-gray-100'
            key={props.entity.id}
            onClick={props.onClick}>
            <div>
                {props.matchingEntityMessage(props.entity)}
            </div>
            <div className='link noUnderline'>
                <FormattedMessage id='globals.use_this_instead' />
            </div>
        </div>
    )
}

function GenericHideButton(props: { onClick(): void }): React.ReactElement {
    return (
        <span
            className='justify- link noUnderline text-sm'
            onClick={props.onClick}>
            <FormattedMessage id='globals.hide' />
        </span>
    )
}

function GenericShowAllButton(props: {
    onClick: () => void,
    message(): React.ReactElement
}): React.ReactElement {
    return (
        <div className='text-sm py-2'>
            {props.message()}{'. '}
            <span className='link noUnderline' onClick={props.onClick}>
                <FormattedMessage id='globals.see_all' />
            </span>
        </div>
    )
}