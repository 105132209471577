import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getOrganisationNameSelector } from '../../../users/selectors';
export type BookingInspectionLineHeaderProps = {
    isCombinedInspection: boolean;
    hasInspectionTypes: boolean;
};

export const BookingInspectionLineHeader = (props: BookingInspectionLineHeaderProps): React.ReactElement => {
    const isCI: boolean = props.isCombinedInspection;
    const qtyId: string = isCI ? 'inspections.total_quantity' : 'inspections.total_qty';
    const hasInspectionTypes: boolean = props.hasInspectionTypes;
    const organisationName: string = useSelector(getOrganisationNameSelector);

    return (
        <div className='infoLineHeader flex md:space-x-1 lg:space-x-2 lg:mr-8 items-center mb-3 pl-1 w-full'>
            {hasInspectionTypes && <div className={classnames('w-12')}>
                <FormattedMessage id='supplier_booking.info_line.item_type' /></div>}
            <div className={classnames('w-5/24')}><FormattedMessage id='supplier_booking.info_line.item_number' /></div>
            <div className={classnames('w-6/24')}><FormattedMessage id='supplier_booking.info_line.item_name' /></div>
            <div className={classnames('w-2/24')}><FormattedMessage id={qtyId} /></div>
            <div className={classnames('w-4/24')}><FormattedMessage id='supplier_booking.info_line.ready_for_inspection' /></div>
            <div className={classnames('w-4/24')}><FormattedMessage id='supplier_booking.info_line.planned_date' values={{ organisationName }} /></div>
            <div className={classnames('w-2/24')}><FormattedMessage id='supplier_booking.info_line.status'/></div>
        </div >);
};
