/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditApprovalFlow } from './AuditApprovalFlow';
import type { AuditCustomConclusion } from './AuditCustomConclusion';
import type { AuditingBody } from './AuditingBody';
import type { AuditStatus } from './AuditStatus';
import type { AuditType } from './AuditType';
import type { BasicUserOptional } from './BasicUserOptional';
import type { Comment } from './Comment';
import type { CorrectiveActionsStatuses } from './CorrectiveActionsStatuses';
import type { FileResource } from './FileResource';
import type { ModelDate } from './ModelDate';
import type { ProductionUnit } from './ProductionUnit';
import type { ReportLocation } from './ReportLocation';
import type { Supplier } from './Supplier';
import type { Timestamp } from './Timestamp';
import type { TypedUUID } from './TypedUUID';
import type { User } from './User';
import type { UUID } from './UUID';

/**
 * Supplier Audit.
 * `id`, `status`, `start_time`, and `end_time` fields are read-only at all times. They are maintained by the system.
 * If the audit is in any other state than `planned`, all fields are read-only.
 *
 */
export type Audit = {
    approval_flow?: AuditApprovalFlow | null;
    approval_flow_status?: string | null;
    assigned_user?: BasicUserOptional;
    attachments?: Array<FileResource>;
    audit_type?: AuditType;
    auditing_body?: AuditingBody;
    auditor_comment?: Comment;
    auditor_conclusion?: AuditCustomConclusion;
    checklist_id?: TypedUUID;
    checklist_name?: string | null;
    checklist_revision?: number | null;
    checklist_revision_timestamp?: Timestamp;
    corrective_actions_status?: CorrectiveActionsStatuses;
    end_time?: Timestamp;
    features?: Array<'edit' | 'delete' | 'read' | 'perform' | 'set_final_conclusion' | 'edit_approval_flow' | 'behalf_of' | 'assign_audit_via_link'>;
    final_conclusion?: AuditCustomConclusion;
    id?: UUID;
    last_tracked_location?: ReportLocation;
    location_country?: string | null;
    locked?: boolean;
    planned_date?: ModelDate;
    source_type?: Audit.source_type | null;
    start_time?: Timestamp;
    status?: AuditStatus;
    target_production_unit?: ProductionUnit;
    target_supplier: Supplier;
    watchers?: Array<User>;
};

export namespace Audit {

    export enum source_type {
        USER = 'user',
        IMPORTER = 'importer',
        PDF = 'PDF',
    }


}

