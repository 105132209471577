import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { moveArrayItem } from '../../../base/utils';
import ListItemWrapper from './ListItemWrapper';

export interface DnDListProps {
    items: any[];
    itemMoved(fromIndex: number, toIndex: number);
    enableDrage: boolean;
}

interface ItemContainer {
    id: number;
    item: any;
}

const DnDList = (props: DnDListProps): React.ReactElement => {
    const [items, setItems] = useState<ItemContainer[]>([])
    useEffect(() => {
        if (props.items) {
            const items: ItemContainer[] = []
            props.items.map((item, id) => items.push({ id: id, item: item }));
            setItems(items);
        }
    }, [props.items])

    const preMoveItem = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            setItems(moveArrayItem(items, dragIndex, hoverIndex))
        }, [items],
    )

    const drop = (itemId: number): void => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == itemId && i !== itemId) {
                props.itemMoved(itemId, i);
                i = items.length;
            }
        }
    }

    const renderItem = (item: ItemContainer, index: number): React.ReactElement => {
        return (
            <ListItemWrapper key={item.id} index={index} listItem={item.item} moveItem={preMoveItem} drop={drop} enabledDrag={props.enableDrage} />
        )
    }
    return (
        <DndProvider backend={HTML5Backend} key='sortableListDndProvider'>
            <div>
                {items.map((item, index) => renderItem(item, index))}
            </div>
        </DndProvider>
    )
}

export default DnDList;