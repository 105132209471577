import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import { Popup } from 'semantic-ui-react';
import ClearIcon from './ClearIcon';

type ColorPickerProps = {
    color: string;
    handlePick(color: string);
}

const ColorPicker = (props: ColorPickerProps): React.ReactElement => {
    const [color, setColor] = useState<string>(props.color);

    const pickColor = (): void => {
        if (color !== props.color) {
            props.handlePick(color);
        }
    }
    const clear = (): void => {
        setColor(undefined);
        props.handlePick(undefined);
    }

    return (
        <div style={{ minWidth: '51px' }}>
            <Popup
                on='click'
                onClose={pickColor}
                trigger={<button className="ui button" style={{ background: color, paddingLeft: '12px', paddingRight: '12px', paddingTop: '15px', paddingBottom: '7.4px', minHeight: '0px', borderRadius: '8px 0px 0px 8px', marginRight: '0px' }} />}>
                <Popup.Content>
                    <TwitterPicker
                        triangle="hide"
                        color={color}
                        colors={['#444D55', '#d84444', '#fdc35a', '#529e64']} // Important! These colors have to match the mobile-apps colors for Checked-OK, Checked-NotOK and Pending. Otherwise the progress bar in the app will look disjointed.
                        onChange={(color): void => setColor(color.hex)}
                    />
                </Popup.Content>
            </Popup>
            <button className="ui button"
                style={{ borderRadius: '0px 8px 8px 0px', margin: '0px', minHeight: '0px', paddingLeft: '6px', paddingRight: '6px', paddingTop: '1px', paddingBottom: '3px' }}
                onClick={clear}
            >
                <ClearIcon />
            </button>
        </div>
    )
}

export default ColorPicker;