import { RequestError } from '../../../base/types';
import { GroupData } from '../types';

export const IS_FETCHING = 'IS_FETCHING';
export type IS_FETCHING = {
    isFetching: boolean;
};

export const GROUPS_REQUEST_SUCCESS = 'GROUPS_REQUEST_SUCCESS';
export type GROUPS_REQUEST_SUCCESS = {
    isFetching: boolean;
    groups: GroupData[];
};

export const GROUPS_REQUEST_FAILURE = 'GROUPS_REQUEST_FAILURE';
export type GROUPS_REQUEST_FAILURE = {
    isFetching: boolean;
    error: RequestError;
};

export const GROUPS_CREATE_FAILURE = 'GROUPS_CREATE_FAILURE';
export type GROUPS_CREATE_FAILURE = {
    isFetching: boolean;
    error: RequestError;
};
