import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { objectHasKeys } from '../../../base/utils';
import { GroupData } from '../../groups/types';
import { ById, DropdownValue } from '../types';

interface GroupFilterProps {
    groups: GroupData[];
    groupsById: ById<GroupData>;
    selectedGroups: string[] | string;
    active: boolean;
    handleChange(item: { name: string; value: any }, append: boolean, apply: boolean);
    setActive(type: string, active: boolean);
}

const initialState = { groups: [], active: false, selectedGroups: [] }
type State = Readonly<typeof initialState>;

const intlMessages = defineMessages({
    selectGroups: {
        id: 'users.filters.select_group',
    },
});

class GroupFilter extends Component<GroupFilterProps & WrappedComponentProps, State> {
    private nameInput;
    readonly state: State = initialState;
    constructor(props) {
        super(props);
    }

    public render(): React.ReactElement {
        const selectGroupsLabel: string = this.props.intl.formatMessage(intlMessages.selectGroups);

        let selectedGroups = this.props.selectedGroups;
        if (!Array.isArray(selectedGroups)) {
            selectedGroups = [selectedGroups];
        }

        const groupsById = this.props.groupsById;
        const options = [];
        Object.keys(groupsById).map((groupId: string) => {
            const group = groupsById[groupId];
            const content = <div className='flex justify-between'><span>{group.name}</span>{group.is_supplier_group && <Popup position={'top right'} trigger={<Icon className='text-default-widget-color' name='factory' />}>
                <FormattedMessage id='users.user_is_supplier_group' />
            </Popup>}</div>;

            options.push({
                value: group.id,
                key: group.id,
                text: group.name,
                content,
            });
        });
        if (objectHasKeys(groupsById)) {
            return (
                <div className='filter approver'>
                    <div className='label'><FormattedMessage id='users.groups' /></div>
                    <div className='content'>
                        {!this.state.active && selectedGroups && selectedGroups.length > 1 &&
                            <Popup
                                position='top center'
                                trigger={<Dropdown selection onClick={this.setActive}>{this.getNotActiveLabel(selectedGroups)}</Dropdown>}>
                                <Popup.Header><FormattedMessage id='users.filters.selected_groups' /> ({selectedGroups.length})</Popup.Header>
                                <Popup.Content>
                                    {selectedGroups.map((groupId) => {
                                        return (<div key={groupId}>{groupsById[groupId].name}</div>);
                                    })}
                                </Popup.Content>
                            </Popup>}
                        {!this.state.active && selectedGroups && selectedGroups.length === 1 && <Dropdown selection onClick={this.setActive}>{this.getNotActiveLabel(selectedGroups)}</Dropdown>}
                        {(this.state.active || selectedGroups.length === 0) && <><Dropdown
                            name='groups'
                            options={options}
                            selection
                            multiple
                            search
                            className='mr-1'
                            value={this.state.selectedGroups}
                            placeholder={this.state.selectedGroups.length === 0 ? selectGroupsLabel : ''}
                            onChange={(evt, item): void => {
                                this.handleChange(item);
                            }}
                            ref={(input): void => { this.nameInput = input; }}
                        />
                            {this.state.active && <Button size='mini' positive className='apply' onClick={this.save}><FormattedMessage id='users.apply' /></Button>}</>}
                    </div>
                </div>
            );
        }
        return null;
    }

    public componentDidMount(): void {
        if (this.props.selectedGroups) {
            this.setState({ selectedGroups: this.convertToArray(this.props.selectedGroups) });
        }
    }

    public componentDidUpdate(prevProps): void {
        if (this.props.selectedGroups !== prevProps.selectedGroups) {
            this.setState({ selectedGroups: this.convertToArray(this.props.selectedGroups) });
        }
    }

    private convertToArray(value): string[] {
        return Array.isArray(value) ? value : [value];
    }

    private handleChange({ value }: { value?: DropdownValue }): void {
        this.setState({ selectedGroups: this.convertToArray(value) });
        this.setState({ active: true });
    }

    private save = (): void => {
        this.props.handleChange({ name: 'groups', value: this.state.selectedGroups }, false, true);
        this.props.setActive('groups', false);
        this.setState({ active: false });
    }

    private setActive = (): void => {
        this.setState({ active: true }, () => {
            this.nameInput.open();
        });
    }

    private getNotActiveLabel(groups: string[]): React.ReactElement {
        const groupsById = this.props.groupsById;
        const groupCnt = this.state.selectedGroups ? this.state.selectedGroups.length - 1 : 0;
        return groups[0] ? <span>{groupsById[groups[0]].name} {groupCnt > 0 && <span className='plusSome'> + {groupCnt}</span>}</span> : null;
    }
}

export default injectIntl(GroupFilter);