import { ConclusionConclusionCategoryEnum } from '../../backend_api/models';
import { CompleteApprover } from '../../backend_api/models/CompleteApprover';
import { MultiLanguageText } from '../globals/types';

export type CustomConclusion = {
    approval_conclusion: boolean;
    // conclusion_category: ConclusionTypes;
    conclusion_category: ConclusionConclusionCategoryEnum;
    deprecated: boolean;
    full_name: MultiLanguageText;
    id: string;
    inspector_conclusion: boolean;
    short_name: MultiLanguageText;
}

export type ApprovalFlow = CompleteApprover[][];

export type ApprovalFlowState = {
    conclusions: CustomConclusion[];
    approvalFlow: ApprovalFlow;
    currentLevel: number;
    createStep: boolean;
    steps: any;
    selectedApprovers: string[];
    showAddApprovers: { [level: number]: boolean };
    setupStep: boolean;
};

export const InitialState: ApprovalFlowState = {
    conclusions: [],
    approvalFlow: [],
    currentLevel: 0,
    createStep: false,
    steps: {},
    selectedApprovers: [],
    showAddApprovers: {
        [0]: false
    },
    setupStep: false,
};

export type ApprovalFlowReportState = {
    approval: CompleteApprover;
    showReinspectModal: boolean;
    showModal: boolean;
    showReinspect: boolean;
    notDoneFlowsCnt: number;
    [key: string]: any;
};

export const InitialStateReport: ApprovalFlowReportState = {
    approval: undefined,
    showReinspectModal: false,
    showModal: false,
    type: undefined,
    showReinspect: false,
    notDoneFlowsCnt: 0,
};
