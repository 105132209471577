import { byId } from '../../base/types';
import { Comment, CommentTarget } from './types';

const getCommentsByType = (comments: byId<Comment[]>, type: CommentTarget, id?: string, imageURL?: string): byId<Comment[]> => {
    let ret;
    switch (type) {
        case 'inspection':
            ret = getInspectionComments(comments);
            break;
        case 'defect':
            ret = getCommentsByDefect(comments, id);
            break;
        case 'checkpoint':
            ret = getCommentsByCheckpoint(comments, id);
            break;
        case 'follow_up_task':
            ret = getCommentsByFollowUpTask(comments, id);
            break;
        case 'defect_image':
            ret = getCommentsByDefectImage(comments, id, imageURL);
            break;
        case 'checkpoint_image':
            ret = getCommentsByCheckpointImage(comments, id, imageURL);
            break;
    }
    return ret;
};

// Returns all comments that are not attached to a follow-up task
const getInspectionComments = (comments: byId<Comment[]>): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                if (!ret[key]) {
                    ret[key] = [];
                }
                ret[key].push(comment);
            });
        });
    }
    return ret;
};

const getCommentsByDefect = (comments: byId<Comment[]>, defectId: string): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                // If the comment has an image url, it's a comment on an image and shouldn't be included
                if (comment.defect_id !== null && comment.defect_id === defectId && comment.image_url === null) {
                    if (!ret[key]) {
                        ret[key] = [];
                    }
                    ret[key].push(comment);
                }
            });
        });
    }
    return ret;
};

const getCommentsByCheckpoint = (comments: byId<Comment[]>, checkpointId: string): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                // If the comment has an image url, it's a comment on an image and shouldn't be included
                if (comment.checkpoint_id !== null && comment.checkpoint_id === checkpointId && comment.image_url === null) {
                    if (!ret[key]) {
                        ret[key] = [];
                    }
                    ret[key].push(comment);
                }
            });
        });
    }
    return ret;
};

const getCommentsByFollowUpTask = (comments: byId<Comment[]>, followUpTaskId?: string): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                if (comment.follow_up_task_id !== null && comment.follow_up_task_id === followUpTaskId) {
                    if (!ret[key]) {
                        ret[key] = [];
                    }
                    ret[key].push(comment);
                }
            });
        });
    }
    return ret;
};

const getCommentsByDefectImage = (comments: byId<Comment[]>, defectId: string, imageURL: string): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                if (comment.defect_id !== null && comment.defect_id === defectId && comment.image_url !== null && comment.image_url === imageURL) {
                    if (!ret[key]) {
                        ret[key] = [];
                    }
                    ret[key].push(comment);
                }
            });
        });
    }
    return ret;
};

const getCommentsByCheckpointImage = (comments: byId<Comment[]>, checkpointId: string, imageURL: string): byId<Comment[]> => {
    const ret = {};
    if (comments) {
        Object.keys(comments).map((key: string) => {
            comments[key].map((comment: Comment) => {
                if (comment.checkpoint_id !== null && comment.checkpoint_id === checkpointId && comment.image_url !== null && comment.image_url === imageURL) {
                    if (!ret[key]) {
                        ret[key] = [];
                        // console.log('found ', comment.checkpoint_id, comment, key)
                    }
                    // console.log('chp image ret ', ret);

                    ret[key].push(comment);
                    // console.log('chp image ret 2 ', ret);

                    if (comment.checkpoint.observed_photos) {
                        comment.checkpoint.observed_photos.map((photo: any) => {
                            if (photo.comment && photo.type ==='image') {
                                ret[key].push(photo);
                                // ret[key].push(photo.comment);
                            }
                        });
                    }
                }
            });
        });
        return ret;
    }
    return ret;
};

const getCommentsCnt = (commentsObj): number => {
    const obj = commentsObj;
    let cnt = 0;
    Object.keys(obj).forEach((arr) => {
        cnt += (obj[arr].length);
    });
    return cnt;
};

export default { getCommentsByDefect, getCommentsByCheckpoint, getCommentsByDefectImage, getCommentsByCheckpointImage, getCommentsByType, getCommentsCnt };
