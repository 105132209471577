/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TextWithTranslation,
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from '../../backend_api/models';

/**
 * pending
 * @export
 * @interface DocumentationTemplate
 */
export interface DocumentationTemplate {
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof DocumentationTemplate
     */
    documentation_template_description: TextWithTranslation;
    /**
     * 
     * @type {string}
     * @memberof DocumentationTemplate
     */
    documentation_template_id: string;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof DocumentationTemplate
     */
    documentation_template_name: TextWithTranslation;
    /**
     * 
     * @type {number}
     * @memberof DocumentationTemplate
     */
    documentation_template_revision: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentationTemplate
     */
    documentation_template_timestamp: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentationTemplate
     */
    expires_after_months: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentationTemplate
     */
    requirement_template_id: string;
}

export function DocumentationTemplateFromJSON(json: any): DocumentationTemplate {
    return DocumentationTemplateFromJSONTyped(json, false);
}

export function DocumentationTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentationTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentation_template_description': TextWithTranslationFromJSON(json['documentation_template_description']),
        'documentation_template_id': json['documentation_template_id'],
        'documentation_template_name': TextWithTranslationFromJSON(json['documentation_template_name']),
        'documentation_template_revision': json['documentation_template_revision'],
        'documentation_template_timestamp': (json['documentation_template_timestamp'] === null ? null : new Date(json['documentation_template_timestamp'])),
        'expires_after_months': json['expires_after_months'],
        'requirement_template_id': json['requirement_template_id'],
    };
}

export function DocumentationTemplateToJSON(value?: DocumentationTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentation_template_description': TextWithTranslationToJSON(value.documentation_template_description),
        'documentation_template_id': value.documentation_template_id,
        'documentation_template_name': TextWithTranslationToJSON(value.documentation_template_name),
        'documentation_template_revision': value.documentation_template_revision,
        'documentation_template_timestamp': (value.documentation_template_timestamp === null ? null : value.documentation_template_timestamp.toISOString()),
        'expires_after_months': value.expires_after_months,
        'requirement_template_id': value.requirement_template_id,
    };
}


