import React from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { FileResource, TextWithTranslation, User } from '../../../backend_api/models';
import AttachmentItemList from '../../../modules/attachments/components/AttachmentItemList';
import { UserNameText } from '../../../modules/users/components/Widgets';
import { getPrettyDatetime } from '../../utils';
import TranslateComment from './TranslateComment';

type CommentProps = {
    className?: string;
    comment: TextWithTranslation;
    author: User;
    timestamp: string;
    level?: number;
    attachments?: FileResource[];
    commentInput?: React.ReactElement;
    handleNewComment(comment: string, uploads?: FileResource[], isReply?: boolean);
};

const Comment = (props: CommentProps): React.ReactElement => {
    const { comment, author, timestamp, attachments = [], commentInput } = props;
    const intl = useIntl();
    return <div className={twMerge('pb-4')}>
        <div className='flex flex-row justify-between mb-2'>
            <div className='text-secondary'><UserNameText user={author} /></div>
            <div className='text-sm text-gray-500'>
                <div>{getPrettyDatetime(timestamp, intl.locale || null)}</div>
            </div>
        </div>
        <div className='' >
            <TranslateComment comment={comment} />
            <div>
                <AttachmentItemList disabled attachments={attachments} className='m-0' />
            </div>
        </div> <div className='my-1'>
        </div>
        {commentInput}
    </div>
}
export default Comment;
