import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, Header, Message, Segment } from 'semantic-ui-react';
import Confirm from './Confirm';

interface DeleteCombinedInspectionProps {
    onlyHasPlanned: boolean;
    showConfirm: boolean;
    handleConfirm();
    deleteOrder();
    handleClose();
}

const DeleteCombinedInspection = (props: DeleteCombinedInspectionProps): React.ReactElement => {
    return (
        <Segment basic>
            <Header as='h3'><FormattedMessage id='inspections.split_the_combined_order' /></Header>
            <FormattedMessage id='inspections.this_will_release_the_inspections_from_the_combined_order_the_combined_order_is_then_deleted' />
            {!props.onlyHasPlanned && <Message negative><FormattedMessage id='inspections.the_order_cannot_be_split_all_inspections_need_to_have_status_planned' /></Message>}
            <Divider />
            <Button negative disabled={!props.onlyHasPlanned} onClick={(): void => props.handleConfirm()}><FormattedMessage id='inspections.split_the_combined_order' /></Button>
            <Button onClick={(): void => props.handleClose()}><FormattedMessage id='inspections.cancel' /></Button>
            <Confirm
                show={props.showConfirm}
                handleCancel={props.handleClose}
                confirmAction={props.deleteOrder}>
                <FormattedMessage id='inspections.are_you_sure_this_action_cannot_be_undone' /></Confirm>
        </Segment>
    );
};

export default DeleteCombinedInspection;
