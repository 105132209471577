import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Audit } from '../../../backend_api/models';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import CreateAuditModal from '../../audit/components/CreateAuditModal';
import MinimalAuditList from '../../audit/components/MinimalAuditList';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';

type Props = {
    audits: Audit[];
    productionUnitId?: string;
    onAuditsUpdated?();
    className?: string;
    disabled?: boolean;

};

const DetailedProductionUnitAudits = (props: Props): React.ReactElement => {
    const { className, disabled, audits, onAuditsUpdated, productionUnitId } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();

    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('', className)}>
        <HasFeatureAccess feature='u_audits'>
            <MinimalAuditList audits={audits} />
            {/* <div className='pt-2 pb-6'><Link className='link' to={'/audits?production_unit_id=' + productionUnitId}>
                <FormattedMessage id='production_units.expanded_list'/></Link>
            </div> */}
            <CreateAuditModal initialProductionUnitId={props.productionUnitId} />
        </HasFeatureAccess>
    </div >
}
export default DetailedProductionUnitAudits;
