import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EditLink from '../../../base/components/basic/EditLink';
import Group from '../../groups/components/Group';
import { ControlledDelayedGroupsSelector, ControlledImmediateGroupsSelector } from '../../groups/components/GroupsSelector';
import { useGroups } from '../../groups/hooks';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getGroupsById } from '../../users/selectors';
import { Group as GroupModel } from '../../../backend_api/models';

/* Note that I have to use an alias here: Group -> GroupModel*/

type Props = {
    className?: string;
    groups: GroupModel[];
    productionUnitId: string;
    onGroupsUpdated(groups: GroupModel[]): void
    allowedToEdit: boolean

};

const DetailedSupplierGroups = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(false);
    const groups = props.groups;
    const selectableGroups = useGroups().filter(g => !g.is_supplier_group);

    return <ContentSection
        headerText={<div className='flex justify-between items-center w-full'>{intl.formatMessage({ id: 'production_units.detailed_page.groups.heading' })}
            {props.allowedToEdit && <EditLink onEdit={setEdit} />}
        </div>}
        content={<div>
            {!edit && groups.length === 0 && <div><FormattedMessage id='production_units.detailed_page.no_groups_added' /></div>}
            {edit && <p><FormattedMessage id='production_units.detailed_page.groups.add_or_remove' /></p>}

            {/* There are some issues with the immedate groups select. 
            How to consolidate state changes from UI and backend? 
            We want to keep some UI state, so the groups do not flicker around. 
            But also not accidentally remove or add groups, 
            while we are receiving the supplier from the backend */}
            {/* {edit && <ControlledImmediateGroupsSelector
                onGroupAdded={onUserWantedToAddGroup}
                onGroupRemoved={onUserWantedToRemoveGroup}
                className=''
                selectableGroups={selectableGroups}
                selectedGroupIds={groups.map(g => g.id)}
            />} */}

            {edit && <ControlledDelayedGroupsSelector
                selectableGroups={selectableGroups}
                onSelectingGroupsDone={(groupIds) => {
                    const groups = selectableGroups.filter((g) => groupIds.includes(g.id))
                    props.onGroupsUpdated(groups.map(g => {
                        const group: GroupModel = { id: g.id, is_supplier_group: g.is_supplier_group, name: g.name }
                        return group;
                    }));
                }}
                selectedGroupIds={groups.map(g => g.id)}
                className=''
                onSelectingGroupsCanceled={() => undefined}
            />}

            {!edit && <div className='pb-4'>
                {
                    groups
                        .map((group: GroupModel) => {
                            if (group) {
                                return <Group key={'group_' + group.id} group={group} />
                            } else {
                                return null;
                            }
                        })
                        .filter(g => g != null)}
            </div>}
        </div>}
    />;
}
export default DetailedSupplierGroups;
