import React from 'react';
import { FormattedMessage } from 'react-intl';

type ItemsAffectedProps = {
    pcs: number;
    all?: boolean;
    doNotShowAll?: boolean;
    className?: string;
};
const ItemsAffected = (props: ItemsAffectedProps): React.ReactElement => {
    const { pcs, all = false, doNotShowAll = false, className } = props;
    return (
        <div className={className}>{all ? !doNotShowAll && <FormattedMessage id='inspections.items_affected_all' /> : <FormattedMessage id='inspections.items_affected_pcs' values={{ pcs }} />}</div>
    );
};

export default ItemsAffected;
