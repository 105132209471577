import React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { UploadingFile } from '../types';
import UploadingItem from './UploadingItem';
interface UploadingProps {
    autoClear?: boolean;
    showCompleteMessage?: boolean;
    files: UploadingFile[];
    isComplete: boolean;
    inline?: boolean;
    clearTimeout?: number;
    clearUploadsHandler();
    reUpload?(files: File[]);
}
const Uploading = (props: UploadingProps): React.ReactElement => {
    const fileCnt: number = Array.from(props.files).length || 0;
    const clearTimeout: number = props.clearTimeout || 0;
    const { inline = false, autoClear = false, showCompleteMessage = true } = props;
    const filesLabel: string = fileCnt === 1 ? 'file' : 'files';
    const clear = (): void => {
        setTimeout(() => props.clearUploadsHandler(), clearTimeout)
    }
    if (fileCnt > 0) {
        let hasError = false;
        props.files.forEach((file) => {
            if (file.status === 'error') {
                hasError = true;
            }
        })
        return (
            <Segment basic className={['filesContainer', inline ? ('inline') : (null)].join(' ')}>
                <>
                    {(!props.isComplete && inline) && <div className='uploading' >Uploading {fileCnt} {filesLabel}</div>}
                    <div className='files'>
                        {props.files.map((file: UploadingFile, index) => {
                            return <UploadingItem reUploadFile={(file) => props.reUpload([file])} inline={inline} key={index + ' uploading'} file={file} />;
                        })}
                    </div>
                    {props.isComplete && (showCompleteMessage || hasError) && <div className='isComplete' >Upload is complete <Button size='mini' onClick={props.clearUploadsHandler}>Ok</Button></div>}
                    {props.isComplete && autoClear && !hasError && fileCnt && clear()}
                </>
            </Segment>
        );
    }
    return (null);
};

export default Uploading;
