import classnames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { FileResource, FileResourceTypeEnum } from '../../../compliance_api/models';
import ComplianceFileItem from './ComplianceFileItem';

type Props = {
    attachments: FileResource[];
    className?: string;
    disabled?: boolean;
    deleteItem: (file: FileResource) => void
}

const ComplianceAttachmentItemList = (props: Props): React.ReactElement => {
    const { attachments, disabled } = props;
    const galleryItems = attachments.filter((att) => att.type === FileResourceTypeEnum.Image);
    const fileItems = attachments.filter((att) => att.type === FileResourceTypeEnum.File);
    return <div className={classnames('flex flex-col mt-2')}>
        {galleryItems.length > 0 && <div className='flex flex-wrap w-full py-4'>{galleryItems.map((attachment, index) =>
            <ComplianceFileItem className={''} key={'images_' + index} disabled={disabled} file={attachment} deleteItem={props.deleteItem} />
        )}</div>}
        {fileItems.length > 0 && <div className={twMerge('flex w-full flex-col')}>{fileItems.map((attachment, index) =>
            <ComplianceFileItem className={''} key={'files_' + index} disabled={disabled} file={attachment} deleteItem={props.deleteItem} />
        )}</div>}
    </div>
}

export default ComplianceAttachmentItemList;