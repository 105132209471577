import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import qs from 'query-string';
import { InspectionDetails, InspectionsWeekviewResponse, ListUsersUser } from '../../../backend_api/models';
import { request2 } from '../../../base/api';
import { AppState, byId } from '../../../base/types';
import { deepCopy, getLocationEntries, getWeekDay, getWeekNumberByDate } from '../../../base/utils';
import history from '../../../store/history';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';
import { getFavoriteInspectors, getStartAndEndDateByDate } from '../actions/dashboard/weekViewActions';
import { InspectionsWeekviewInspectionExtended, WeekViewDataItem, WeekViewItemType, WeekViewType } from '../components/dashboard/views/weekView/WeekView2';
import { Inspection } from '../types';

export type WeekViewState = {
    //data: WeekViewData;
    data: InspectionsWeekviewResponse;
    // data: InspectionsWeekviewResponse & InspectionDetails & {inspection_type: string};
    details: byId<InspectionDetails>;
    dates: WeekViewDates;
    favoriteInspectors: string;
    setInspectorsManually: boolean;
    isLoading: boolean;
}
export type WeekViewOrder = {
    combined: boolean;
    feature_set_id: string;
    id: string;
    readonly: string[];
    inspections: Inspection[];
    order_number: string;
    features: string[];
}
/* type WeekViewData = {
    conclusions: byId<Conclusion>;
    inspection_types: byId<InspectionType>;
    days: string[];
    inspectors: {
        days: { orders: WeekViewOrder[], supplier: Supplier }[][];
        type: 'booked' | 'planned' | 'supplier_qc' | 'user';
        user: ListUsersUser;
    }[];
    feature_sets: byId<string[]>;
} */
type WeekViewDates = {
    startDate: string;
    endDate: string;
    weekNumber: number;
}
export type WeekViewStoreObject = {
    inspection_ids: string[];
    assigned_user_id?: string;
    scheduled_inspection_date?: Date;
    booking_date?: Date;
    supplier_qc: boolean;
};
const initialState: WeekViewState = {
    data: undefined,
    dates: { startDate: undefined, endDate: undefined, weekNumber: undefined },
    details: {
        ['']: {
            custom_field_values: [],
            reinspection_based_on: undefined,
            reinspected_by: [],
        }
    },
    favoriteInspectors: '',
    setInspectorsManually: false,
    isLoading: false,
};
export const getWeekViewDataFiltered = createAsyncThunk<InspectionsWeekviewResponse, { date: string }, {
    dispatch;
    state: AppState;
    rejectWithValue: Response;
}>(
    'getWeekViewDataFiltered',
    async (params, { dispatch, getState, rejectWithValue }) => {
        dispatch(getFavoriteInspectors());
        const filters = getLocationEntries(history.location);
        const inspection_type_ids = filters.inspection_type_ids;
        filters.d = [params.date];
        const newFilters = qs.stringify(filters);
        delete filters.inspection_type_ids
        const filtersParam: string = qs.stringify(filters, { arrayFormat: 'bracket' });
        const weekView = getState().app.weekView;
        const favInsp = weekView.favoriteInspectors && weekView.favoriteInspectors !== '' ? weekView.favoriteInspectors.split(',') : [];
        const setInspectorsManually = weekView.setInspectorsManually;
        history.push('?' + newFilters);
        let url = 'planning/weekview?start_date=' + params.date + '&no_of_days=7&' + filtersParam;
        // dispatch(getWeekViewDataRequest(isLoading));
        if (setInspectorsManually) {
            url += '&only_specified_inspectors=true';
        }
        const body = JSON.stringify({ assigned_user_ids: favInsp, inspection_type_ids });
        const rq = await request2(url, {
            method: 'PUT', body,
        });
        if (!rq.ok) {
            console.log('There was an error gettting week view data...');
            dispatch(sendErrorMessage(['error_message.week_view_data_could_not_be_loaded'], 3000));
            catchException('getWeekViewData', {
                endpoint: 'planning/weekview?start_date=[startDate]&no_of_days=[no_of_days]',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq });
            rejectWithValue(rq as Response);
        }
        const data = await rq.json();
        return data;
    });
export const getWeekViewInspectionDetails = createAsyncThunk<InspectionDetails, string>(
    'getWeekViewInspectionDetails',
    async (inspectionId: string, { dispatch, rejectWithValue }) => {
        const rq = await request2('planning/weekview/' + inspectionId + '/details', {});
        if (!rq.ok) {
            console.log('There was an error gettting week view inspection details...');
            dispatch(sendErrorMessage(['error_message.week_view_inspection_details_could_not_be_loaded'], 3000));
            catchException('getWeekViewInspectionDetails', {
                endpoint: 'planning/weekview/[inspectionId]/details',
                request: 'planning/weekview/' + inspectionId + '/details',
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq, inspectionId });
            rejectWithValue(rq as Response);
            return;
        }
        const data = await rq.json() as InspectionDetails;
        return data;
    });

export const storeWeekViewData = createAsyncThunk<null, any>(
    'storeWeekViewData',
    async (storeObj: WeekViewStoreObject, { dispatch, rejectWithValue }) => {
        // toBooking ? rescheduleObj['booking_date'] = inspection.booking_date : rescheduleObj['scheduled_inspection_date'] = inspection.scheduled_inspection_date;
        // rescheduleObj['supplier_qc'] = toSupplierQC;
        const rq = await request2('inspections/reschedule', {
            method: 'put',
            body: JSON.stringify(storeObj),
        });
        if (!rq.ok) {
            console.log('There was an error gettting week view inspection details...');
            dispatch(sendErrorMessage(['error_message.week_view_inspection_details_could_not_be_loaded'], 3000));
            rejectWithValue(rq as Response);
        } else {
            dispatch(sendStatusMessage(['status_message.the_inspection_was_update'], 3000));
        }
        return await rq.json() as any;
    });

export const weekViewSlice = createSlice({
    name: 'weekView',
    initialState,
    reducers: {
        // Plain reducer here:         
        updateWeekViewData: (state, action: { payload: any }): void => { state.data = action.payload },
        setFavoriteInspectors: (state, action) => {
            state.favoriteInspectors = action.payload;
        },
        setFavoriteInspectorsManually: (state, action) => {
            const manually = action.payload;
            state.setInspectorsManually = manually;
        }

    },
    extraReducers: builder => {
        builder.addCase(getWeekViewInspectionDetails.pending, () => {
            // state.loading = true;
        });
        builder.addCase(getWeekViewInspectionDetails.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getWeekViewDataFiltered.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getWeekViewInspectionDetails.fulfilled, (state, action) => {
            const inspectionId = action.meta.arg;
            state.details[inspectionId] = action.payload;
        });
        builder.addCase(getWeekViewDataFiltered.fulfilled, (state, action) => {
            state.isLoading = false;
            const date = action.meta.arg.date;
            const dates = getStartAndEndDateByDate(date);
            const weekNumber = getWeekNumberByDate(date);
            const data = { ...action.payload };
            data.rows.forEach((row) => {
                row.days.forEach((day) => {
                    day.forEach((dayObj) => {
                        const supplier = dayObj.supplier;
                        dayObj.orders.forEach((order: any) => {
                            order.features = data.feature_sets[order.feature_set_id];
                            order.inspections.forEach((inspection: InspectionsWeekviewInspectionExtended) => {
                                if (inspection.combined) {
                                    inspection.sub_inspections.forEach((subInsp: any) => {
                                        subInsp.inspector_conclusion = data.conclusions[subInsp.inspector_conclusion_id];
                                        subInsp.conclusion = data.conclusions[subInsp.conclusion_id];
                                        subInsp.supplier_entity = supplier;
                                        subInsp.inspection_type = data.inspection_types[subInsp.inspection_type_id];
                                        subInsp.features = data.feature_sets[subInsp.feature_set_id];
                                    })
                                }
                                inspection.conclusion = data.conclusions[inspection.conclusion_id];
                                inspection.inspector_conclusion = data.conclusions[inspection.inspector_conclusion_id];
                                inspection.inspection_type = data.inspection_types[inspection.inspection_type_id];
                                inspection.features = data.feature_sets[inspection.feature_set_id];
                                inspection.supplier_entity = supplier;
                            })
                        })
                    })
                })
            });
            let pArray = [];
            if (localStorage.getItem('parking')) {
                pArray = JSON.parse(localStorage.getItem('parking'));
            }
            // data.inspectors.unshift({ days: [pArray, [], [], [], [], [], []], type: 'parking', user: null });

            state.dates = { startDate: dates.startDate, endDate: dates.endDate, weekNumber };
            state.data = data;
        });
    }
});

export const moveWeekViewItem = (item: { from: any, to: any }) => {
    return (dispatch, getState) => {
        const weekViewData: any = JSON.parse(JSON.stringify(getWeekViewDataSelector(getState())));
        const getDay = (user: ListUsersUser, date: string, itemType: WeekViewItemType, from: boolean): any => {
            let selectedItem: WeekViewDataItem;
            const weekDay = getWeekDay(date);
            let inspectorIndex = -1;
            if (user) {
                selectedItem = weekViewData.data.rows.find((inspector, i) => {
                    if (inspector.user && (inspector.user.id === user.id)) {
                        inspectorIndex = i;
                        return inspector;
                    }
                });
            } else {
                selectedItem = weekViewData.data.rows.find((inspector, i) => {
                    if (inspector.type === itemType) {
                        inspectorIndex = i;
                        return inspector;
                    }
                });
            }
            const ret = selectedItem ? selectedItem.days[weekDay - 1] : [];
            return deepCopy({ data: ret, inspectorIndex, weekDay });
        }
        let toDay = [];
        const add = (item, type: WeekViewType, ids: { supplierId: string, orderId?: string, inspectionId?: string }) => {
            const addOrder = (_dayItem, isInspection: boolean) => {
                let oIndex = -1;
                let iIndex = -1;
                _dayItem.orders.forEach((order, i) => {
                    if (order.id === ids.orderId) {
                        oIndex = i;
                        // fOrder = order;
                        if (isInspection) {
                            order.inspections.forEach((inspection: Inspection, j) => {
                                // console.log('-inspection ', inspection)
                                if (inspection.inspection_id === ids.inspectionId) {
                                    iIndex = j;
                                    // console.log('iIndex ', iIndex)
                                }
                            })
                        }
                    }
                })
                if (!isInspection) {
                    if (oIndex > -1) {
                        // The order already exists - merge using oIndex
                        const orderToAdd = { ...item.orders[oIndex] };
                        // _dayItem.orders = _dayItem.orders.concat(item.orders[oIndex]);
                        _dayItem.orders.forEach((order) => {
                            if (order.order_id === orderToAdd.order_id) {
                                order.inspections = order.inspections.concat(orderToAdd.inspections);
                            }
                        })
                    } else {
                        // The order is not on the day - find the order from the data item and add it
                        item.orders.forEach((order) => {
                            if (order.id === ids.orderId) {
                                _dayItem.orders = _dayItem.orders.concat(order);

                            }
                        })
                    }
                } else {
                    if (iIndex === -1 && oIndex === -1) {
                        let index = -1;
                        _dayItem.orders.forEach((order, i) => {
                            if (order.id === item.orders[0].id) {
                                index = i;
                            }
                        });
                        if (index > -1) {
                            // _dayItem.orders[index] = _dayItem.orders[index].concat(item.orders[0])
                        } else {
                            _dayItem.orders.forEach((dayTimeOrder, i) => {
                                item.orders.forEach((order) => {
                                    if (dayTimeOrder.id === ids.orderId) {
                                        _dayItem.orders[i].push(order)
                                    }
                                });
                            });
                            item.orders.forEach((order) => {
                                if (order.id === ids.orderId) {
                                    _dayItem.orders = _dayItem.orders.concat([order])
                                }
                            });
                        }
                    } else {
                        if (iIndex > -1) {
                            _dayItem.orders[oIndex].inspections = _dayItem.orders[oIndex].inspections.concat(item.inspections[iIndex]);
                        } else {
                            item.inspections.forEach((inspection) => {
                                if (inspection.inspection_id === ids.inspectionId) {
                                    if (_dayItem.orders[oIndex]) {
                                        _dayItem.orders[oIndex].inspections = _dayItem.orders[oIndex].inspections.concat(inspection);
                                    } else {
                                        if (_dayItem.orders[0].inspections) {
                                            _dayItem.orders[0].inspections = _dayItem.orders[0].inspections.concat(inspection)
                                        } else {
                                            _dayItem.orders[0].inspections = inspection;
                                        }
                                    }

                                }
                            });
                        }
                    }

                }
                return _dayItem;
            }
            const addSupplier = () => {
                if (toDay.length === 0) {
                    toDay.push(item);
                } else {

                    let sIndex = -1;
                    toDay.forEach((dayItem, i) => {
                        if (dayItem.supplier && dayItem.supplier.id === ids.supplierId) {
                            sIndex = i;
                        }
                    })
                    if (sIndex > -1) {
                        let orderExists = false;
                        const ordersToAdd = [...item.orders];
                        toDay[sIndex].orders.forEach((order) => {
                            ordersToAdd.forEach((orderToAdd) => {
                                if (order.id === orderToAdd.order_id) {
                                    order.inspections = order.inspections.concat(orderToAdd.inspections);
                                    orderExists = true;
                                }
                            });
                        });
                        if (!orderExists) {
                            toDay[sIndex].orders = toDay[sIndex].orders.concat(item.orders)
                        }
                    } else {
                        toDay.push(item);
                    }
                }
            }
            const addOrderFoo = () => {
                if (toDay.length === 0) {
                    // Day is empty - push entire data item
                    item.orders.forEach((order) => {
                        if (order.id === ids.orderId) {
                            toDay.push({ supplier: item.supplier, orders: [order] })
                        }
                    })
                } else {
                    let supplierExists = false;
                    toDay.forEach((dayItem, i) => {
                        if (dayItem.supplier && dayItem.supplier.id === ids.supplierId) {
                            addOrder(dayItem, false)
                            supplierExists = true;
                        }
                    });
                    if (!supplierExists) {
                        item.orders.forEach((order) => {
                            if (order.id === ids.orderId) {
                                toDay.push({ supplier: item.supplier, orders: [order] })

                            }
                        })
                    }
                }
            }
            if (type === 'inspection') {
                if (toDay.length === 0) {
                    // Day is empty - push entire data item
                    item.orders.forEach((order) => {
                        if (order.id === ids.orderId) {
                            order.inspections.forEach((inspection) => {
                                if (inspection.inspection_id === ids.inspectionId) {
                                    const { inspections, ...orderObj } = order;
                                    orderObj.inspections = [inspection];
                                    toDay.push({ supplier: item.supplier, orders: [orderObj] })
                                }
                            })
                        }
                    })
                } else {
                    let supplierExists = false;
                    toDay.forEach((dayItem, i) => {
                        if (dayItem.supplier && dayItem.supplier.id === ids.supplierId) {
                            addOrder(dayItem, true)
                            supplierExists = true;
                        }
                    });
                    if (!supplierExists) {
                        item.orders.forEach((order) => {
                            if (order.id === ids.orderId) {
                                order.inspections.forEach((inspection) => {
                                    if (inspection.inspection_id === ids.inspectionId) {
                                        const { inspections, ...orderObj } = order;
                                        orderObj.inspections = [inspection];
                                        toDay.push({ supplier: item.supplier, orders: [orderObj] })
                                    }
                                })

                            }
                        })
                    }
                }
            }
            if (type === 'order') {
                addOrderFoo();
            }
            if (type === 'supplier') {
                addSupplier();
            }

        }
        const remove = () => {

            // const fromDay = [];
            let index = -1;
            let oIndex = -1;
            let iIndex = -1;
            fromDay.forEach((dayItem, i) => {
                if (item.from.type === 'inspection') {
                    dayItem.orders.forEach((order, j) => {
                        order.inspections.forEach((inspection, k) => {
                            if (inspection.inspection_id === item.from.id) {
                                oIndex = j;
                                iIndex = k;
                                index = i;

                            }
                        });

                    });

                }

                if (item.from.type === 'order') {
                    dayItem.orders.forEach((order, j) => {
                        if (order.id === item.from.id) {
                            oIndex = j;
                            index = i;
                        }
                    })
                }
                if (item.from.type === 'supplier') {
                    if (dayItem.supplier && dayItem.supplier.id === item.from.id) {
                        index = i;
                    }
                }
            })
            if (item.from.type === 'inspection') {
                if (iIndex > -1) {
                    fromDay[index].orders[oIndex].inspections.splice(iIndex, 1);
                }
                if (fromDay[index] && fromDay[index].orders[oIndex].inspections.length === 0) {
                    fromDay[index].orders.splice(oIndex, 1);
                    if (fromDay[index].orders.length === 0) {
                        fromDay.splice(index, 1);
                    }
                }
            }
            if (item.from.type === 'order') {
                if (oIndex > -1) {
                    fromDay[index].orders.splice(oIndex, 1);
                }
                if (fromDay[index] && fromDay[index].orders.length === 0) {
                    fromDay.splice(index, 1)
                }
            }
            if (item.from.type === 'supplier') {
                fromDay.splice(index, 1)
            }
        }
        const addFoo = () => {
            const storeObj = {
                inspection_ids: [],
                assigned_user_id: '',
                supplier_qc: item.to.itemType === 'supplier_qc',
            };
            item.to.itemType === 'booked' ? storeObj['booking_date'] = item.to.date : storeObj['scheduled_inspection_date'] = item.to.date;
            // storeObj['supplier_qc'] = item.to.itemType === 'supplier_qc';

            // console.log('item from ', item.from)

            if (item.from.type === 'inspection') {
                add(item.from.data, 'inspection', { supplierId: item.from.data.supplier && item.from.data.supplier.id || 'none', orderId: item.from.order.id, inspectionId: item.from.id });
                storeObj.inspection_ids.push(item.from.id);
                storeObj.assigned_user_id = item.to.user && item.to.user.id || null;

            }
            if (item.from.type === 'order') {
                add(item.from.data, 'order', { supplierId: item.from.data.supplier && item.from.data.supplier.id || 'none', orderId: item.from.id });
                item.from.order.inspections.forEach((inspection) => {
                    storeObj.inspection_ids.push(inspection.inspection_id);
                    storeObj.assigned_user_id = item.to.user && item.to.user.id || null;
                })
            }
            if (item.from.type === 'supplier') {
                add(item.from.data, 'supplier', { supplierId: item.from.id });
                item.from.data.orders.forEach((order) => {
                    order.inspections.forEach((inspection) => {
                        storeObj.inspection_ids.push(inspection.inspection_id);
                        storeObj.assigned_user_id = item.to.user && item.to.user.id || null;
                    })
                })
            }

            // remove();
            // if (item.to.itemType !== 'parking') {
            dispatch(storeWeekViewData(storeObj));
            // }
        }
        const toData = getDay(item.to.user, item.to.date, item.to.itemType, false);
        toDay = toData.data;

        const fromData = getDay(item.from.user, item.from.date, item.from.itemType, true);
        const fromDay = fromData.data;


        remove();
        addFoo();
        weekViewData.data.rows[fromData.inspectorIndex].days[fromData.weekDay - 1] = fromDay;
        weekViewData.data.rows[toData.inspectorIndex].days[toData.weekDay - 1] = toDay;
        if (item.to.itemType === 'parking') {
            localStorage.setItem('parking', JSON.stringify(toDay));
        }
        if (item.from.itemType === 'parking') {
            localStorage.setItem('parking', JSON.stringify(fromDay));
        }
        dispatch(updateWeekViewData((weekViewData.data)));
    }

}

export const getWeekViewInspectionDetailsSelector = createSelector(
    [(state: AppState, inspectionId: string): InspectionDetails => state.app.weekView.details && state.app.weekView.details[inspectionId]],
    (details) => details,
);
export const getWeekViewDataSelector = createSelector(
    [(state: AppState): WeekViewState => state.app.weekView && state.app.weekView],
    (data) => data,
);
export const getWeekViewFavoriteInspectors = createSelector(
    [(state: AppState): string => state.app.weekView && state.app.weekView.favoriteInspectors],
    (fav) => fav,
);
export const getWeekViewFavoriteInspectorsSetManually = createSelector(
    [(state: AppState): boolean => state.app.weekView && state.app.weekView.setInspectorsManually],
    (setManually) => setManually,
);
const { actions, reducer } = weekViewSlice;
export const { updateWeekViewData, setFavoriteInspectors, setFavoriteInspectorsManually } = actions;
export default reducer;
