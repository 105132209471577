import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ListableProductionUnit } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { getFeaturesSelector } from '../../../base/selectors';
import { isUndefinedOrNull, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { ListView, ListViewHeader, ListViewHeaderItem, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import ListProductionUnitStatus from '../ListProductionUnitStatus';
import { getListableProductionUnitsSelector } from '../slice/productionUnitsSlice';

type Props = {
    productionUnits?: ListableProductionUnit[];
    containerClassName?: string;
    listClassName?: string;
    confirmDeleteTextId: string;
    removeProductionUnit?(productionUnitId: string): void
}
export const BasicProductionUnitList = (props: Props): React.ReactElement => {
    const { containerClassName, listClassName } = props;
    const intl = useIntl();
    const features = useSelector(getFeaturesSelector);
    const canShowStatus = features.includes('org_production_unit_status');
    const productionUnits = !isUndefinedOrNull(props.productionUnits) ? props.productionUnits : useSelector(getListableProductionUnitsSelector);
    const hasProductionUnits = productionUnits.length > 0;
    const formatMessage = intl.formatMessage;

    const headerItems: ListViewHeaderItem[] = [
        { label: formatMessage({ id: 'production_units.input_label.unit_number' }), className: props.removeProductionUnit ? 'w-6/16' : 'w-7/16', key: 'item_number' },
        { label: formatMessage({ id: 'production_units.input_label.unit_name' }), className: props.removeProductionUnit ? 'w-4/16 sm:w-5/16 md:w-8/16' : 'w-6/16 sm:w-7/16 md:w-8/16', key: 'item_name' },
        { label: undefined, className: props.removeProductionUnit ? 'w-2/16 sm:w-2/16 md:w-1/16' : 'w-3/16 sm:w-2/16 md:w-1/16', key: 'spacer.2' },
    ];
    if (props.removeProductionUnit) {
        headerItems.push({ label: undefined, className: 'w-4/16 sm:w-3/16 md:w-2/16 justify-end', key: 'spacer.delete' });
    }
    const content = <>
        {/* !hasProductionUnits && <div className='justify-center w-full flex'><InfoIcon text={intl.formatMessage({ id: 'production_units.no_production_units' })} /></div> */}

        <ListView className={twMerge('mt-0', containerClassName)}>
            {hasProductionUnits && <ListViewHeader items={headerItems} />}
            {hasProductionUnits && <ListViewLines className={listClassName}>
                {productionUnits.map((productionUnit, i) => {
                    return <ListViewLine link={'/production_units/' + productionUnit.id} className={twMerge('border-t sm:border-t-0 justify-start pb-6 pt-6 hover:bg-highlight-green')} key={'production_units_' + productionUnit.id + '_' + i}>

                        {
                            props.removeProductionUnit
                                ? (
                                    <>
                                        <ListViewItem className='w-6/16'>{productionUnit.number}</ListViewItem>
                                        <ListViewItem className='w-4/16 sm:w-5/16 md:w-8/16'>{productionUnit.name}</ListViewItem>
                                        <ListViewItem className='w-2/16 sm:w-2/16 md:w-1/16'>{canShowStatus && <ListProductionUnitStatus status={productionUnit.status} />}</ListViewItem>
                                        {
                                            <ListViewItem className='w-4/16 sm:w-3/16 md:w-2/16 justify-end'>
                                                <div onClick={(event) => preventDefaultAndStopPropagation(event)}>
                                                    <DeleteConfirm
                                                        deleteText={intl.formatMessage({ id: props.confirmDeleteTextId })}
                                                        deleteFunction={() => props.removeProductionUnit(productionUnit.id)}
                                                        trigger={<Button small className='text-smx'>
                                                            <FormattedMessage id={props.confirmDeleteTextId} />
                                                        </Button>} />
                                                </div>
                                            </ListViewItem>
                                        }
                                    </>
                                )
                                : (
                                    <>
                                        <ListViewItem className='w-7/16'>{productionUnit.number}</ListViewItem>
                                        <ListViewItem className='w-6/16 sm:w-7/16 md:w-8/16'>{productionUnit.name}</ListViewItem>
                                        <ListViewItem className='w-3/16 sm:w-2/16 md:w-1/16'>{canShowStatus && <ListProductionUnitStatus status={productionUnit.status} />}</ListViewItem>
                                    </>
                                )
                        }
                    </ListViewLine>
                })}
            </ListViewLines>}
        </ListView>
    </>
    return content
}