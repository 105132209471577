import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form, Modal, ModalHeader, Radio, TextArea } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';
import { Audit, AuditCustomConclusion } from '../../../backend_api/models';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import { AppState } from '../../../base/types';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditCustomConclusionsSelector } from '../selectors/auditSelectors';
import { updateAuditConclusion } from '../slices/auditSlice';


type Props = {
    className?: string;
    trigger: React.ReactElement | string;
    audit: Audit;
    commentMandatory?: boolean;
    handleUpdate?(conclusionId: string, comment?: string): void;
    disabled?: boolean;
};

const AuditChangeConclusionDialog = (props: Props): React.ReactElement => {
    const { trigger, audit, handleUpdate, commentMandatory, disabled = false } = props;
    const modalOpen = useSelector(getShowModalSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const auditId = audit && audit.id;
    const [conclusion, setConclusion] = useState<AuditCustomConclusion>(props.audit.final_conclusion);
    const [comment, setComment] = useState<string>(undefined);
    const [modalId] = useState<string>('acd_' + uuidv1());
    const [commentSet, setCommentSet] = useState(false);
    const conclusions = useSelector((state: AppState) => getAuditCustomConclusionsSelector(state, audit.audit_type && audit.audit_type.id));
    const hasConclusion = props.audit.final_conclusion;

    const close = (): void => {
        setConclusion(props.audit.final_conclusion);
        setComment('');
        setCommentSet(false);
        dispatch(showModal({ show: false, id: modalId }));
    };
    const save = () => {
        handleUpdate ? handleUpdate(conclusion.id, comment) : dispatch(updateAuditConclusion({ auditId, conclusionId: conclusion.id, comment }));
        close();
    };
    const isDisabled = () => {
        if (commentMandatory && !commentSet) {
            return true;
        }
        if (conclusion && !audit.final_conclusion) {
            return false;
        }
        if (conclusion && audit.final_conclusion) {
            return conclusion.id === audit.final_conclusion.id;
        }
        return true;
    };

    const updateComment = (comment: string) => {
        setComment(comment);
        setCommentSet(!isUndefinedOrNullOrEmptyString(comment));
    }
    useEffect(() => {
        setConclusion(props.audit.final_conclusion);
    }, [props.audit.final_conclusion]);

    useEffect(() => {
        isDisabled();
    }, [conclusion]);

    return <Modal
        trigger={trigger}
        onOpen={() => dispatch(showModal({ show: true, id: modalId }))}
        onClose={() => close()}
        closeIcon={true}
        open={!disabled && modalOpen[modalId] && modalOpen[modalId].show}
    >
        <ModalHeader>{hasConclusion ? <FormattedMessage id='audits.change_conclusion.header' /> : <FormattedMessage id='audits.set_conclusion.header' />}</ModalHeader>
        <Modal.Content>
            <div className='flex-col flex  space-y-8'>
                <div className='flex-col w-full'>
                    <h4><FormattedMessage id='audits.change_conclusion.label.conclusion' /></h4>
                    {conclusions.map((_conclusion) => {
                        return <div key={'auditConclusionDialog_' + _conclusion.id} className='flex items-center space-x-2 my-3 cursor-pointer' onClick={() => setConclusion(_conclusion)}>
                            <Radio className='self-start pt-2' checked={conclusion && conclusion.id === _conclusion.id} />
                            <div className='w-2/12 p-2 rounded text-sm justify-center self-start items-baseline flex' style={{ backgroundColor: _conclusion.background_color, color: _conclusion.text_color }}>{_conclusion.name.text}</div>
                            <div className='w-10/12 px-2 text-base overflow-auto'>{_conclusion.description.text}</div>
                        </div>
                    })}
                </div>
                <div className='w-2/3'>
                    <h4 className='space-x-1'><span><FormattedMessage id='audits.change_conclusion.label.reason' /></span>{commentMandatory && <span className='text-alert'>*</span>}</h4>
                    <Form>
                        <TextArea rows={5} name='reason' value={comment} onChange={(evt, item): void => updateComment(item.value as string)} placeholder={intl.formatMessage({ id: 'audits.change_conclusion.label.enter_text' })} ></TextArea>
                    </Form>
                </div>
            </div>
        </Modal.Content>
        <Modal.Actions className='flex justify-end'>
            <Button onClick={() => save()} disabled={isDisabled()} primary><FormattedMessage id='globals.save' /></Button>
            <Button onClick={() => close()}><FormattedMessage id='globals.cancel' /></Button>
        </Modal.Actions>
    </Modal >
}
export default AuditChangeConclusionDialog;
