import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../base/components/basic/Icon';
import Upload from './Upload';

interface FileSelectorProps {
    isComplete: boolean;
    disabled?: boolean;
    multiple?: boolean;
    disableWhileUploading?: boolean;
    isUploading?: boolean;
    autoClear?: boolean;
    label?: string;
    hasIcon?: boolean;
    hasDrop?: boolean;
    inline?: boolean;
    uploadFiles?();
    updateStateHandler(name: string, data: File[]);
}
export const FileSelector = (props: FileSelectorProps): React.ReactElement => {
    const { disabled = false, hasIcon = true, inline = false } = props;

    return (
        <div className={['fileSelector', inline ? 'inline' : null].join(' ')}>
            {disabled && props.isComplete && <span><Icon name='cloud_upload'  />{(props.label ? (<span className='disabled'>{props.label}</span>) : (<span className='disabled'><FormattedMessage id='attachments.select_files' /></span>))}</span>}
            {!disabled && <Upload
                uploadFiles={props.uploadFiles}
                updateStateHandler={props.updateStateHandler}
                label={props.label}
                hasIcon={hasIcon}
                disableWhileUploading={props.disableWhileUploading}
                multiple={props.multiple}
                inline={inline}
                isUploading={props.isUploading}
                autoClear={props.autoClear}
            />}
            {!disabled && props.hasDrop && <FormattedMessage id='attachments.drop_files_here' />}
        </div>
    );

};

export default FileSelector;