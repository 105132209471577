import classnames from 'classnames';
import React, { CSSProperties, SyntheticEvent, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { InputProps, TextArea } from 'semantic-ui-react';
import Input from '../../../../../base/components/basic/Input';
import { twMerge } from '../../../../../base/utils';

type SharedProps = {
    value: string | number;
    name: string;
    id?: string;
    disabled?: boolean;
    readOnly?: boolean;
    placeholder?: string;
    className?: string;
    style?: CSSProperties;
    label?: string | React.ReactElement;
    labelPosition?: '' | 'left' | 'right';
    ref?: React.MutableRefObject<typeof Input>;
    key?: string;
    autoFocus?: boolean;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    handleBlur?(evt: SyntheticEvent, item: InputProps): void;
    handleFocus?(evt: SyntheticEvent, item: InputProps): void;
    setRef?(ref: typeof Input): void;
    autoHeight?: boolean;
    format?: string;
    onKeyUp?(e?): any;
    onKeyDown?(e?): any;
    onKeyPress?(e?): any;
    onPaste?(e?): any;

}
type NumericProps = SharedProps & {
    type: 'number';
    max?: number;
    min?: number;
    step?: number;
    abs?: boolean;
    precision?: number;
    id?: string;

}
type TextProps = SharedProps & {
    type: 'text';
}

type TextAreaProps = SharedProps & {
    type: 'textarea';
}
type QInputProps = NumericProps | TextProps | TextAreaProps;

const  QInput = (props: QInputProps): React.ReactElement => {
    const [inputValue, setInputValue] = useState(props.value);
    const cn = classnames({
        [props.className]: props.className,
        readonly: props.disabled,
    });
    const handleChange = (item): void => {
        setInputValue(item.value);
    };
    const handleTextAreaChange = (evt, item): void => {
        setInputValue(item.value);
    };
    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const inputProps = {
        placeholder: props.placeholder,
        value: inputValue,
        name: props.name,
        readOnly: props.readOnly || false,
        className: cn,
        disabled: props.disabled,
        autoFocus: props.autoFocus || false,
        key: props.key,
        label: props.label,
        format: props.format,
        id: props.id,
        onKeyUp: props.onKeyUp,
        onKeyDown: props.onKeyDown,
        onKeyPress: props.onKeyPress,
        onPaste: props.onPaste,
    };
    if (props.type === 'text') {
        return <Input
            {...inputProps}
            onBlur={(e): void => props.handleBlur && props.handleBlur(e, { name: props.name, value: inputValue })}
            onChange={(e) => {
                handleChange && handleChange(e);
                setInputValue(e.currentTarget.value);
            }}
            type='text'
            labelPosition={props.labelPosition && props.labelPosition}
            ref={(refObj): void => props.setRef && props.setRef(refObj)}
        />;
    }
    if (props.type === 'textarea') {
        let numberOfLines = inputValue && (inputValue as string).split('\n').length;
        if(numberOfLines === 1) {
            numberOfLines = Math.ceil(inputValue.toString().length / 45);
        }
        const rows = numberOfLines > 0 && props.autoHeight ? numberOfLines : 1;
        return <TextArea
            {...inputProps}
            rows={rows}
            onBlur={(e): void => props.handleBlur && props.handleBlur(e, { name: props.name, value: inputValue })}
            onFocus={props.handleFocus && props.handleFocus}
            fluid
            onChange={handleTextAreaChange}
            type='text'
            labellposition={props.labelPosition && props.labelPosition}
        />;
    }
    if (props.type === 'number') {
        return <NumberFormat
            autoComplete='off'       
            style={props.style}                 
            {...inputProps}
            className={twMerge('rounded w-full border-input border py-3 px-3 outline-none', inputProps.className)}
            decimalScale={props.precision || 0}
            onValueChange={(value): void => {
                setInputValue(value.floatValue);
            }}
            isAllowed={(values): boolean => values.floatValue >= 0 || values.floatValue === undefined}
            onBlur={(evt): void => {
                props.handleBlur && props.handleBlur(evt, { name: props.name, value: inputValue });
            }}
            getInputRef={props.inputRef}
        />
    }
};
export default QInput;



