import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { WeekviewUser } from '../../../../../../backend_api/models';
import Image from '../../../../../../base/components/Image';
import ClipText from '../../../../../../base/components/basic/ClipText';
import { AppState } from '../../../../../../base/types';
import { getLocaleSpecificString, getPrettyDate, getPrettyDatetime } from '../../../../../../base/utils';
import { useAppDispatch } from '../../../../../../store';
import { isInspectionLockedOrDone } from '../../../../Utils';
import { WeekViewOrder, getWeekViewInspectionDetails, getWeekViewInspectionDetailsSelector } from '../../../../slices/weekViewSlice';
import WeekViewReassignOrder from '../../../WeekViewReassignOrder';
import { NakedStatusWithType, PlainStatus } from '../../../edit/Status';
import OriginalQtyInfo from '../../../report/OriginalQtyInfo';
import SplitShipmentsMarker from '../../../shared/SplitShipmentsMarker';
import { DragType, InspectionsWeekviewInspectionExtended, WeekViewItemType } from './WeekView2';

type InspectionItemProps = {
    inspection: InspectionsWeekviewInspectionExtended;
    order: WeekViewOrder;
    date: Date;
    itemType: WeekViewItemType;
    user: WeekviewUser;
    combinedInspectionId?: string;
    className?: string;
    data: any;
};

const InspectionItem = (props: InspectionItemProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { inspection, className, combinedInspectionId, order, date, itemType, data, user } = props;
    const [collectedDragProps, drag] = useDrag({
        type: DragType.WEEKVIEW_INSPECTION_ITEM, item: { type: DragType.WEEKVIEW_INSPECTION_ITEM, order, inspection, date, itemType, data, id: inspection.inspection_id, user },
    });
    const [isOpen, setOpen] = useState(false);
    const itemNumberName = (inspection.item_number || '-') + ': ' + (inspection.item_name || '-');
    const productionUnit = inspection.production_unit;
    const isPlanned = inspection.status.toLowerCase() === 'planned';
    const isBooking = itemType === 'booked';
    const isCombined = combinedInspectionId !== undefined;
    const canAssign = (!isCombined ? inspection.features.includes('change_assignment_data') : false);
    const canAssignBooking = !isCombined;
    const canDrag = isBooking ? (canAssignBooking && !isInspectionLockedOrDone(inspection)) : (canAssign && !isInspectionLockedOrDone(inspection));
    const hasInspectionTypes = true;
    const totalQty = inspection.total_quantity || '-'
    const details: any = useSelector((state: AppState) => getWeekViewInspectionDetailsSelector(state, inspection.inspection_id));
    const originalTotalQty = details && details.original_total_quantity || '-';
    
    const cfs = details && details.custom_field_values.filter((cfv) => cfv.value !== null).length > 0;
    const itemImage = details && details.item_image;
    const etd = details && details.etd;
    const bookingDate = details && details.booking_date;
    const bookingNote = details && details.booking_note;
    const packedQty = details && details.packed_quantity || '-';
    const sampleQty = details && details.sample_quantity || '-';
    const textItem = 'flex';
    const content = 'w-8/12';
    const description = 'w-4/12 font-medium';
    const confirmed = isCombined ? order.inspections[0].confirmed : inspection.confirmed;
    const lastConfirmedAt = isCombined ? order.inspections[0].last_confirmed_at : inspection.last_confirmed_at;
    const confirmedTrigger = confirmed ? <Popup
        trigger={<Icon name='check circle' color='green' />}>
        <b><FormattedMessage id='inspections.week_view.booking_confirmed' /></b>
        <div>{getPrettyDatetime(lastConfirmedAt)}</div>
        <div>{productionUnit && productionUnit.contact_person || ''}</div>
    </Popup> : null;

    const getEditLink = (): string => {
        const linkPath = '/inspections/edit_inspection/';
        return [linkPath, order.inspections[0].order_id].join('');
    }
    const getLink = (): string => {
        const linkPath = isPlanned ? '/inspections/edit_inspection/' : '/inspections/reports/';
        const reportLink = isCombined ? combinedInspectionId : inspection.inspection_id;
        return [linkPath, isPlanned ? order.id : reportLink].join('');
    };
    useEffect(() => {
        if (isOpen) {
            dispatch(getWeekViewInspectionDetails(inspection.inspection_id))
        }
    }, [isOpen]);
    const trigger = <div ref={canDrag ? drag : null} className={classnames('bg-white border-b p-1', className)}>
        <div className='flex justify-between'>
            <div className='text-sm truncate p-1'>{confirmedTrigger}{itemNumberName}</div>
            <div className='bg-gray-200 text-sm p-1'>{inspection.total_quantity || '-'}</div>
        </div>
        <div className='flex justify-between items-center'>
            <div className='truncate text-sm p-1'>{inspection.supplier_entity && inspection.supplier_entity.name || '-'}</div>
            <span className=''>
                <Link to={getLink()}>
                    <NakedStatusWithType inspection={inspection} className='p-1 rounded text-xs leading-4' />
                </Link>
            </span>
        </div>
    </div>;

    const popup = <Popup
        hideOnScroll={false}
        closeOnDocumentClick={true}
        on='click'
        hoverable={false}
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        basic
        flowing
        popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
        offset={[0, document.body.clientHeight < 750 ? -250 : 0]}
        trigger={trigger}>
        <Popup.Content className='w-136 pt-4 pb-1 px-3' >
            <div className='flex space-x-1 justify-between mb-6 pb-4 border-b -mx-8 px-8'>
                <div className={classnames('flex space-x-3')}>
                    {itemImage && <div className='w-3/12'><Image className='border rounded' url={itemImage} /></div>}
                    <div className={classnames('flex flex-row space-x-2 items-base', itemImage ? 'w-8/12' : '')}>
                        <h4 className='flex space-x-2'>{details && <SplitShipmentsMarker className='self-start mt-1' inspection={Object.assign({}, inspection, { sequence_identifier: details.sequence_identifier })} />}
                        <div className=''>{inspection.item_name}-{inspection.item_number}</div></h4>
                    </div>
                </div>
                <div className={classnames('status w-3/12')}>
                    <Popup
                        trigger={<span className='flex flew-grow w-full'><PlainStatus classNames='h-10 w-32' inspection={inspection} /></span>}
                        className='inspectionTypes dashboardPopup'
                        hoverable
                        flowing
                    >
                        <Popup.Content>
                            {inspection.inspector_conclusion && intl.formatMessage({ id: 'inspections.hover.inspector.conclusion' }) + ': ' + getLocaleSpecificString(inspection.inspector_conclusion.full_name)}<br />
                            {inspection.inspector_conclusion && intl.formatMessage({ id: 'inspections.hover.inspection.status' }) + ': ' + getLocaleSpecificString(inspection.conclusion.full_name)}
                        </Popup.Content>
                    </Popup>
                </div>
            </div>
            <div className='space-y-2'>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.order_number' /></div>
                    <div className={content}>{order.order_number}</div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.item_number' /></div>
                    <div className={content}>{inspection.item_number}</div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.item_name' /></div>
                    <div className={content}> {inspection.item_name}</div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.supplier' /></div>
                    <div className={content}> {inspection.supplier_entity && inspection.supplier_entity.name || '-'}</div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.production_unit' /></div>
                    <div className={content}>
                        {productionUnit && productionUnit.name && <div>{productionUnit.name || '-'}</div>}
                        <div>{productionUnit && productionUnit.address || '-'}</div>
                    </div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.qty_packed' /></div>
                    <div className={content}>{originalTotalQty} / {totalQty}</div>
                </div>
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.sample_qty' /></div>
                    <div className={content}>{sampleQty || '-'} <OriginalQtyInfo inspection={inspection} type='report' />                    
                    </div>
                </div>
                {
                    hasInspectionTypes && <div className={textItem}>
                        <div className={description}><FormattedMessage id='inspections.week_view.type' /></div>
                        <div className={content}>{inspection.inspection_type && inspection.inspection_type.name}</div>
                    </div>
                }
                <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.etd' /></div>
                    <div className={content}>{etd ? getPrettyDate(etd) : '-'}</div>
                </div>
                {bookingDate && <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.booking_date' /></div>
                    <div className={content}>{getPrettyDate(bookingDate) || '-'}</div>
                </div>}
                {bookingNote && <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.booking_note' /></div>
                    <div className='content bg-attention'>{bookingNote || '-'}</div>
                </div>}
                {confirmed && <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.booking_last_confirmed_at' /></div>
                    <div className={content}>{getPrettyDate(lastConfirmedAt)} <Icon name='check circle' color='green' /></div>
                </div>}
                {productionUnit && <div className={textItem}>
                    <div className={description}><FormattedMessage id='inspections.week_view.booking_contact_person' /></div>
                    <div className={content}>{productionUnit.contact_person || '-'}</div>
                </div>}
                {cfs && details.custom_field_values.map((cf) => {
                    return <div className={textItem} key={cf.name}>
                        <div className={description}>{cf.name}</div>
                        <div className={content} >
                            <ClipText text={cf.value} clipAt={200} />
                        </div>
                    </div>
                })}

                <div className='flex border-t mt-2 justify-between -mx-8 px-8'>
                    <div className={'pt-4 font-medium'}>{!isPlanned &&
                        <Link className='link noUnderline' as={Button} to={getLink()}><FormattedMessage id='inspections.week_view.view_report' /></Link>}
                    </div>
                    {<div className={'pt-4 font-medium flex space-x-4'}>
                        <WeekViewReassignOrder
                            orderId={order.inspections[0].order_id + '_' + date}
                            date={date}
                            supplierObj={data}
                            assignedUserId={user && user.id}
                            itemType={itemType}
                            trigger={<div className='flex space-x-4'><div onClick={() => {
                                setOpen(false);
                            }} className='cursor-pointer link noUnderline'><FormattedMessage id='inspections.week_view.reassign_order.trigger' /></div><div>|</div>
                            </div>} />
                        <Link className='link noUnderline' as={Button} to={getEditLink()}><FormattedMessage id='inspections.week_view.edit' /></Link>
                    </div>}
                </div>
            </div>

        </Popup.Content >
    </Popup>
    return popup;
}
export default InspectionItem;
