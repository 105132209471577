import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { ProductionUnitLink } from '../../../backend_api/models';

type Props = {
    productionUnitLinks: ProductionUnitLink[];
}

const   ProductionUnitLinkList = (props: Props) => {
    const history = useHistory();
    const redirect = (link: ProductionUnitLink) => {
        history.push('/production_units/' + link.production_unit_id)
    }
    return <Fragment>
        {props.productionUnitLinks.map((link) => {
            return <div className='cursor-pointer' onClick={() => redirect(link)} key={link.production_unit_id}>
                <span className='link'>{link.name || link.number ? <Fragment>{link.name} {link.number && ' : ' + link.number}</Fragment> : '-'}</span>
            </div>
        })}
    </Fragment>
}

export default ProductionUnitLinkList;