import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { AuditApprovalFlow, AuditApprovalLevel } from '../../../backend_api/models';
import { ListUsersUser } from '../../../backend_api_2';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import Form from '../../../base/components/basic/form/Form';
import { addArrayItemOrRemoveIfExists, deepCopy, preventDefaultAndStopPropagation, removeArrayItem, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { ApproverSelector } from '../../approval/components';
import AuditApprovalItem from '../../approval/components/AuditApprovalItem';
import { getUsersThatAreNotApprovers } from '../../approval/handlers';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getAllUsers, getUsersById } from '../../users/selectors';
import { approveAuditApprovalStep, editAuditApprovalStep, editAuditReportApprovalStep } from '../slices/auditSlice';

type Props = {
    className?: string;
    auditId: string;
    approvalFlow?: AuditApprovalFlow;
    type?: 'planned' | 'report';
    noContentSection?: boolean
    canEditApprovalFlow?: boolean
};

const AuditEditApprovalFlow = (props: Props): React.ReactElement => {
    const { className, type = 'planned', auditId, noContentSection, canEditApprovalFlow = false } = props;
    const isPlanned = type === 'planned';
    const [flow, updateFlow] = useState<AuditApprovalFlow>(props.approvalFlow);
    const [selectedIds, updateSelectedIds] = useState<{ [id: string]: string[] }>(null);
    const [showApprovers, setShowApprovers] = useState<string[]>([]);
    const [showReportAddApprovalLevel, setShowReportAddApprovalLevel] = useState<string[]>([]);
    const [showReportAddApprover, setShowReportAddApprover] = useState<string[]>([]);
    const [edit, setEdit] = useState<boolean>(isPlanned);
    const dispatch = useAppDispatch();
    const usersById = useSelector(getUsersById);
    const users = useSelector(getAllUsers);
    const currentLevel = flow && flow.levels && flow.levels.length || 0;
    const hasApprovalFlow = flow && flow.levels.length > 0;

    const initLevel = (level: number) => {
        return {
            level_index: level,
            steps: [],
            editable: false,
            deletable: false,
        }
    };

    const approve = (conclusionId: string, comment: string, approvalStepId: string, approvalLevelIndex: number) => {
        dispatch(approveAuditApprovalStep({ audit_id: auditId, approval_step_id: approvalStepId, conclusion_id: conclusionId, comment, approval_level_index: approvalLevelIndex }))
    };

    const addLevel = (levelIndex?: number) => {
        const s = [...showApprovers];
        if ((flow && flow.levels.length === 0)) {
            updateFlow({ ...flow, levels: [initLevel(0)] });
        } else {
            const f = deepCopy(flow);
            f.levels.push(initLevel(levelIndex))
            updateFlow(f);
        }
        setShowApprovers(addArrayItemOrRemoveIfExists(s, 'level_' + (levelIndex)));
    };

    const addReportLevel = (levelIndex: number) => {
        const showLevel = [...showReportAddApprovalLevel];
        setShowReportAddApprovalLevel(addArrayItemOrRemoveIfExists(showLevel, 'level_' + (levelIndex)));
    };

    const updateApprover = (approverId: string[], _level: number, step: number) => {
        const ids = { ...selectedIds };
        if (!ids[_level.toString()]) {
            ids[_level.toString()] = [approverId[0]];
        } else {
            ids[_level.toString()][step] = approverId[0];
        }
        updateSelectedIds(ids);
    };
    const addApprover = (approverId: string[], _level: number) => {
        const ids = { ...selectedIds };
        if (!ids[_level.toString()]) {
            ids[_level.toString()] = [approverId[0]];
        } else {
            ids[_level.toString()] = approverId;
        }
        updateSelectedIds(ids);
    };

    const addReportLevelApprovers = (approverIds: string[], _level: number) => {
        const ids = { ...selectedIds };
        if (!ids[_level.toString()]) {
            ids[_level.toString()] = approverIds
        } else {
            ids[_level.toString()] = approverIds;
        }
        updateSelectedIds(ids);
    };

    const saveApprover = (_level: number) => {
        const levels = deepCopy(flow.levels);
        const ids = { ...selectedIds };
        selectedIds[_level.toString()].forEach((approverId) => {
            const approver = usersById[approverId];
            if (levels.length === 0) {
                levels.push(initLevel(0))
            }
            levels[_level].steps.push({ approver, approver_id: approverId, step_id: null, conclusion_id: null });
            updateFlow({ ...flow, levels, })
        })
        dispatch(isPlanned ? editAuditApprovalStep({ ...flow, levels, }) :
            editAuditReportApprovalStep({ ...flow, levels, }));
        hideShowLevel(_level);
        delete ids[_level];
        updateSelectedIds(ids);
    };

    const saveAddApprovelLevel = (levelIndex: number) => {
        let f;
        if ((flow && flow.levels.length === 0)) {
            f = {
                ...flow,
                levels: [initLevel(0)],
            }
        } else {
            f = deepCopy(flow);
            f.levels.push(initLevel(levelIndex));
        }
        selectedIds[levelIndex.toString()].forEach((approverId, i) => {
            const approver = usersById[approverId];
            if (f.levels[levelIndex]) {
                f.levels[levelIndex].steps[i] = ({ approver, approver_id: approverId, step_id: null, conclusion_id: null });
            } else {
                f.levels[levelIndex] = { level_index: 0, steps: [{ approver, approver_id: approverId, step_id: null, conclusion_id: null }], editable: false, deletable: false };
            }
        });
        hideReportLevel(levelIndex)
        updateFlow(f);
        dispatch(editAuditReportApprovalStep(f));
    };

    const saveUpdateApprover = (_level: number, step: number, type: 'add' | 'update') => {
        const levels = deepCopy(flow.levels);
        const ids = { ...selectedIds };
        selectedIds[_level.toString()].forEach((approverId, i) => {
            const approver = usersById[approverId];
            if (levels.length === 0) {
                levels.push(initLevel(0))
            }
            if (type === 'update') {
                if (i === step) {
                    levels[_level].steps[step] = ({ approver, approver_id: approverId, step_id: null, conclusion_id: null, editable: true });
                }
            } else {
                levels[_level].steps[step + i] = ({ approver, approver_id: approverId, step_id: null, conclusion_id: null, editable: true });
                hideShowLevel(_level)
            }
        });
        const f = { ...flow, levels, }
        updateFlow(f)
        updateSelectedIds(ids);
        dispatch(isPlanned ? editAuditApprovalStep(f) : editAuditReportApprovalStep(f));
    };

    const removeLevel = (level: number) => {
        const f = deepCopy(flow);
        f.levels.splice(level, 1);
        f.levels.forEach((l, i) => {
            l.level_index = i;
        });
        updateFlow(f);
        dispatch(isPlanned ? editAuditApprovalStep(f) : editAuditReportApprovalStep(f));
    };

    const removeApprover = (approverId: string, level: number) => {
        const _f = deepCopy(flow);
        const sIds = { ...selectedIds };
        const l1 = _f.levels[level].steps.filter((s) => s.approver_id !== approverId);
        _f.levels[level].steps = l1;
        if (l1.length === 0) {
            _f.levels.splice(level, 1);
        }
        const l = addArrayItemOrRemoveIfExists(sIds[level], approverId);
        if (l.length === 0) {
            delete sIds[level];
        } else {
            sIds[level] = l;
        }
        updateSelectedIds(sIds)
        dispatch(isPlanned ? editAuditApprovalStep(_f) : editAuditReportApprovalStep(_f))
        updateFlow(_f)
    };

    const hideShowLevel = (level: number) => {
        const selected = [...showApprovers];
        setShowApprovers(addArrayItemOrRemoveIfExists(selected, 'level_' + (level)));
    };

    const hideReportLevel = (level: number) => {
        showReportApprovalLevel(level);
    };

    const hidePlannedLevel = (level: number) => {
        hideShowLevel(level);
        const sIds = { ...selectedIds };
        delete sIds[level];
        updateSelectedIds(sIds);
    };

    const showReportAddApproverLevel = (level: number) => {
        const selected = [...showReportAddApprover]
        setShowReportAddApprover(addArrayItemOrRemoveIfExists(selected, 'level_' + (level)));
        showReportApprovalLevel(level);
        hideShowLevel(level);
        if (flow.levels.length === 0) {
            const f = { ...flow, levels: [initLevel(0)], }
            updateFlow(f);
        }
    };

    const showReportApprovalLevel = (levelIndex: number) => {
        let selected = [...showReportAddApprovalLevel];
        if (selected.includes('level_' + (levelIndex).toString())) {
            selected = removeArrayItem(selected, 'level_' + levelIndex.toString());
        }
        setShowReportAddApprovalLevel(selected);
    };

    const setupApprovalStep = (level: number) => {
        hideShowLevel(level);
    };

    const getUsersAtLevel = (level: AuditApprovalLevel) => {
        const levelUsers = level && level.steps && level.steps.map((step) => step.approver_id);
        return levelUsers ? getUsersThatAreNotApprovers(levelUsers, users) : [];
    };

    const getSelector = (levelIndex: number, step: number, hasSelection: boolean, users: ListUsersUser[], showSelectedUsers: boolean, type: 'add' | 'update', disabled?: boolean) => {
        return <>
            <ApproverSelector
                users={users}
                handleChange={(data): void => {
                    let ret = data.value;
                    if (data && typeof data.value === 'string') {
                        ret = [data.value];
                    }
                    if (isPlanned) {
                        addApprover(ret as any, levelIndex)
                    } else {
                        if (type === 'update') {
                            updateApprover(ret as any, levelIndex, step);
                            saveUpdateApprover(levelIndex, step, type);
                        } else {
                            addReportLevelApprovers(ret as any, levelIndex + 1);
                        }
                    }
                }}
                handleCancel={(): void => isPlanned ? hidePlannedLevel(levelIndex) : hideReportLevel(levelIndex + 1)}
                handleSave={(): void => isPlanned ? saveApprover(levelIndex) : saveAddApprovelLevel(levelIndex + 1)}
                showSelector={true}
                showEmailInSelector={true}
                hasSelection={isPlanned ? hasSelection : (type === 'add' && selectedIds[levelIndex + 1] && selectedIds[levelIndex + 1][step] && selectedIds[levelIndex + 1][step].length > 0)}
                allowMultipleSelection={isPlanned ? true : type === 'add'}
                hasCancel={isPlanned ? true : type === 'add'}
                value={showSelectedUsers && selectedIds && selectedIds[levelIndex] && selectedIds[levelIndex][step] && [selectedIds[levelIndex][step]]}
                disabled={disabled}
                className={twMerge('flex w-1/2 pb-2', !isPlanned && 'p-1')}
            /></>
    };

    const getRemoveLevelButton = (levelIndex: number, basic?: boolean) => {
        if (basic) {
            return <div className='text-right text-sm link' onClick={() => removeLevel(levelIndex)}>Remove</div>
        }
        return <Button className={twMerge(isPlanned ? 'items-center py-1 px-2' : 'py-0 px-2 text-sm')} onClick={() => removeLevel(levelIndex)} >
            <RemoveIcon className='text-alert text-2xl mr-1' />
            <FormattedMessage id='audits.approval_flow.remove_approval_step' /></Button>
    };

    const getReportApprover = (levelIndex, stepsLength, hasSelection, users, type, hideDone?: boolean) => {
        return (
            <div className='flex items-center'>{getSelector(levelIndex, stepsLength, hasSelection, users, false, type)}
                {!hideDone && <div className=''><Button className='text-sm py-1 px-2' onClick={() => showReportAddApproverLevel(levelIndex)}><FormattedMessage id='globals.done' /></Button></div>}
            </div>
        )
    };

    const content = <div className={twMerge('', className, (edit && !isPlanned) && 'py-2')}>

        {isPlanned && <h5 className='text-secondary text-sm'><FormattedMessage id='audits.approval_flow.edit_approval_flow.heading' /></h5>}
        <Form className='' onSubmit={(e) => { preventDefaultAndStopPropagation(e) }}>
            {flow && flow.levels && flow.levels.map((level, j) => {
                const levelUsers = getUsersAtLevel(level);
                const showPlanned = isPlanned && showApprovers.includes('level_' + (j).toString());
                const showReportApprover = showReportAddApprover.includes('level_' + (j).toString());
                const showReportApproverLevel = showReportAddApprovalLevel.includes('level_' + (j + 1).toString());
                const hasUsers = levelUsers.length > 0;
                const hasSelection = selectedIds && selectedIds[j] && selectedIds[j].length > 0;
                const isLast = j === (flow.levels.length - 1);
                const levelUsersAndSelf = (selfId: string) => {
                    return levelUsers.concat([usersById[selfId]]);
                }

                return <div key={j} className={twMerge('pb-2', isPlanned && 'py-2', !isLast && 'border-b mb-3')}>
                    {isPlanned && <h5><FormattedMessage id='audits.approval_flow.approver' /> {j + 1}</h5>}
                    <div className={twMerge('flex', !isPlanned ? 'flex-col  mb-2' : level.steps.length > 0 && 'py-2 flex-wrap')}>
                        {level.steps.map((step, i) => {
                            const levelsUsersSelf = levelUsersAndSelf(step.approver_id);
                            return <>
                                <AuditApprovalItem
                                    type={type}
                                    disabled={!step.editable}
                                    edit={edit}
                                    showHeading={i === 0}
                                    handleRemoveApprover={() => removeApprover(step.approver_id, j)}
                                    handleChangeConclusion={!isPlanned ? (conclusionId, comment) => approve(conclusionId, comment, step.step_id, j) : null}
                                    approvalStep={step}
                                    key={'s_' + step.step_id}
                                    editUserSelector={edit && getSelector(j, i, hasSelection, levelsUsersSelf, true, 'update', !step.editable)}
                                />
                                {edit && hasUsers && showReportApprover && i === level.steps.length - 1 && <div className='p-1 py-3'>
                                    <h5><FormattedMessage id='audits.approval_flow.add_approver' /></h5>
                                    {getReportApprover(j, level.steps.length, hasSelection, levelUsers, 'update')}
                                </div>}

                            </>

                        })}
                    </div>
                    <div className=''>
                        {edit && showReportApproverLevel &&
                            <div>
                                <h5><FormattedMessage id='audits.approval_flow.add_approval_step' /></h5>
                                {getSelector(j, 0, hasSelection, users, false, 'add')}</div>}{edit && (isPlanned || showReportApproverLevel) && level.steps.length === 0 && <div><FormattedMessage id='audits.approval_flow.select_an_approver_text' /></div>}
                        {edit && levelUsers.length === 0 && <div className='py-2'><FormattedMessage id='audits.approval_flow.no_more_users_to_add' /></div>}
                        {level.editable && edit && hasUsers && !showPlanned && !isPlanned && !showReportApprover && <div className={twMerge(isPlanned ? 'py-2' : 'justify-end flex')}>
                            <Button disabled={!level.editable} className={twMerge(isPlanned ? 'py-1 px-2 text-base' : 'py-1 px-1 text-sm')} onClick={() => showReportAddApproverLevel(j)}><FormattedMessage id='audits.approval_flow.add_approver' /></Button>
                        </div>}
                        {edit && hasUsers && !showPlanned && isPlanned && <div className={twMerge(isPlanned ? 'py-2' : 'justify-end flex')}>
                            <Button className={twMerge(isPlanned ? 'py-1 px-2 text-base' : 'py-1 px-1 text-sm')} onClick={() => setupApprovalStep(j)}><FormattedMessage id='audits.approval_flow.add_approver' /></Button>
                        </div>}
                        {edit && hasUsers && showPlanned && <div className='pt-3'>{getSelector(j, j, hasSelection, levelUsers, false, 'add')}</div>}
                        {isPlanned && getRemoveLevelButton(j)}

                        {(edit && !showPlanned && !showReportApproverLevel) && <div className={twMerge('flex space-x-1 py-2', !isPlanned && 'justify-end')}>
                            {<>
                                {isPlanned && isLast && <Button className={twMerge(isPlanned ? 'items-center py-1 px-2' : 'py-0 px-2 text-sm')} onClick={() => addLevel(currentLevel)}>
                                    <Icon name='add' className='text-brand text-2xl' />
                                    <FormattedMessage id='audits.approval_flow.add_approval_step' />
                                </Button>}
                                {!isPlanned && isLast && <Button className={twMerge(isPlanned ? 'items-center py-1 px-2' : 'py-0 px-2 text-sm')} onClick={() => addReportLevel(j + 1)}>{/* {j + 1} */}
                                    <Icon name='add' className='text-brand text-2xl' />
                                    <FormattedMessage id='audits.approval_flow.add_approval_step' />
                                </Button>}
                                {/* j > 0 &&  */level.deletable && edit && !isPlanned && <div className='flex '>{getRemoveLevelButton(j, false)}</div>}
                            </>}
                        </div>}
                    </div>
                </div>
            })}
            {!hasApprovalFlow && !edit && <><FormattedMessage id='audits.approval_flow.no_approvers' /></>}
            {!hasApprovalFlow && isPlanned && <>
                <Button className={twMerge(isPlanned ? 'py-1 px-2 text-base' : 'py-1 px-1 text-sm')} onClick={() => setupApprovalStep(0)}><FormattedMessage id='audits.approval_flow.add_approver' /></Button>
            </>}
            {edit && !isPlanned && !hasApprovalFlow && <div>
                <h5><FormattedMessage id='audits.approval_flow.add_approver' /></h5>
                {getReportApprover(0, 0, false, users, 'add', true)}</div>}
            {flow && flow.levels.length === 0 && <>
                {edit && <div className='py-4'><FormattedMessage id='audits.approval_flow.select_an_approver_text' /></div>}
                {edit && hasApprovalFlow && !showApprovers.includes('level_' + (0).toString()) && getUsersAtLevel(flow.levels[0]).length === 0 && <Button className='py-1 px-2 text-base' onClick={() => isPlanned ? setupApprovalStep(0) : showReportAddApproverLevel(0)}><FormattedMessage id='audits.approval_flow.add_approver' /></Button>}
                {edit && isPlanned && showApprovers.includes('level_0') && getSelector(0, 0, selectedIds && selectedIds[0] && selectedIds[0].length > 0, users, false, 'add')}
            </>}
        </Form>
        {!isPlanned && !edit && canEditApprovalFlow && <Popup position='bottom right' trigger={<div className='text-sm text-brand link text-right' onClick={() => setEdit(!edit)}><FormattedMessage id={hasApprovalFlow ? 'audits.approval_flow.edit' : 'audits.approval_flow.add'} /></div>}><FormattedMessage id={hasApprovalFlow ? 'audits.approval_flow.click_to_edit_approval_flow' : 'audits.approval_flow.click_to_add_approval_flow'} /></Popup>}
        {!isPlanned && edit && <div className='text-sm text-brand link text-right' onClick={() => setEdit(!edit)}><FormattedMessage id='globals.close' /></div>}
    </div>;

    useEffect(() => {
        const s = {};
        flow && flow.levels.forEach((l, i) => {
            const sl = [];
            l.steps.forEach((st) => {
                sl.push(st.approver_id)
            })
            s[i] = sl;
        });
        updateSelectedIds(s);
    }, []);

    useEffect(() => {
        const s = {};
        props.approvalFlow && props.approvalFlow.levels.forEach((l, i) => {
            const sl = [];
            l.steps.forEach((st) => {
                sl.push(st.approver_id)
            })
            s[i] = sl;
        });
        updateSelectedIds(s);
        updateFlow(props.approvalFlow)
    }, [props.approvalFlow]);

    return noContentSection ? content : <ContentSection content={content} className={twMerge('border-0 pt-0 sm:-mb-2', isPlanned && 'px-0')} />
}
export default AuditEditApprovalFlow;

