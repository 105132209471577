import React from 'react';
import { Audit, CorrectiveActionsState } from '../../../backend_api/models';
import StatusPie from '../../../base/ui/components/status/StatusPie';

type Props = {
    correctiveActionStatus: Audit['corrective_actions_status'];
    showEmptyCorrectiveAction?: boolean;
    w?: number;
    h?: number;
};

const CorrectiveActionStatusPie = (props: Props) => {
    const { correctiveActionStatus, w, h, showEmptyCorrectiveAction = true, } = props;
    const total = correctiveActionStatus.closed + correctiveActionStatus.finding + correctiveActionStatus.in_progress + correctiveActionStatus.review;
    const data = total > 0 ? [
        // TODO: get corrective action status hex color values from config file
        { id: CorrectiveActionsState.Closed, value: correctiveActionStatus.closed, color: '#529e64' },
        { id: CorrectiveActionsState.Finding, value: correctiveActionStatus.finding, color: '#455f94' },
        { id: CorrectiveActionsState.Review, value: correctiveActionStatus.review, color: '#FDC35A' },
        { id: CorrectiveActionsState.InProgress, value: correctiveActionStatus.in_progress, color: '#BAC3D6' },
    ] : showEmptyCorrectiveAction ? [{ id: 'none', value: 2, color: '#eee' }] : [{}];
    return <StatusPie
        data={data}
        h={h}
        w={w}
    />
}

export default CorrectiveActionStatusPie