import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, Icon, Modal } from 'semantic-ui-react';
import { clearError } from '../../../../../base/baseSlice';
import Button from '../../../../../base/components/basic/Button';
import { errorSelector } from '../../../../../base/selectors';
import { AppState } from '../../../../../base/types';
import { useAppDispatch } from '../../../../../store';
import { InfoIcon } from '../../../../globals/components/Utils';
import { requestPDF } from '../../../../pdf/actions/actions';
import { getPdfReportURLSelector } from '../../../../pdf/selectors';
import { updateUserPreferences } from '../../../../users/actions/actions';
import { getUserPreferencesSelector } from '../../../../users/selectors';
import { Inspection, Order } from '../../../types';

enum FileType { DOCX = 'docx', PDF = 'pdf', ODT = 'odt' }

type DownloadReportProps = {
    inspectionId: string;
    order?: Order;
    isGroupedInspection?: boolean;
    showLabel?: boolean;
    context?: 'inspection' | 'audit';
};

const DownloadReport = (props: DownloadReportProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const texts = {
        initial: formatMessage({ id: 'report.download.in_progress' }),
        inProgress: formatMessage({ id: 'report.download.in_progress_2' }),
        inProgressTooLong: formatMessage({ id: 'report.download.in_progress_3' }),
        error: formatMessage({ id: 'report.download.an_error_occured' }),
    };
    const typeKeys = [];
    const typeOptions = []
    const dispatch = useAppDispatch();
    const { isGroupedInspection = false, inspectionId, order, showLabel = true, context = 'inspection' } = props;
    let error = useSelector((state: any) => {
        return errorSelector(state, 'requestPdf');
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDownloading, downloadBegin] = useState(false);
    const [currentFileType, setCurrentFileType] = useState(FileType.PDF);
    const [text, setText] = useState(texts.initial);
    const [pdfId, setPdfId] = useState(undefined);
    const [done, setDone] = useState(false);
    const [includeComments, setIncludeComments] = useState(true);
    const [includeCheckpoints, setIncludeCheckpoints] = useState(true);
    const [correctiveActions, setCorrectiveActions] = useState(true);
    const [correctiveActionsTable, setCorrectiveActionsTable] = useState(true);
    const [selectedId, setSelectedId] = useState(null);
    const pdfURL = useSelector((state: AppState) => {
        return getPdfReportURLSelector(state, pdfId);
    });
    const userPreferences = useSelector((state) => getUserPreferencesSelector(state));

    const reset = (): void => {
        dispatch(clearError('requestPdf'));
        setText(texts.initial);
        setDone(false);
        downloadBegin(false);
        setModalIsOpen(false);
    };
    const options = [];
    if (isGroupedInspection) {
        order.inspections.forEach((inspection: Inspection) => {
            if (inspection.inspection_type) {
                if (!typeKeys.includes(inspection.inspection_type.id) && (inspection.status === 'report' || inspection.status === 'ongoing') && inspection.inspection_type) {
                    typeKeys.push(inspection.inspection_type.id);
                    typeOptions.push({
                        key: inspection.inspection_type.id,
                        text: inspection.inspection_type.name,
                        value: inspection.inspection_id,
                    })
                }
            } else {
                if (!typeKeys.includes('no_type') && (inspection.status === 'report' || inspection.status === 'ongoing')) {
                    typeKeys.push('no_type');
                    typeOptions.push({
                        key: 'no_type',
                        text: 'no_type',
                        value: inspection.inspection_id,
                    })
                }
            }
        });
    }
    options.push({ key: 'pdf', value: { type: FileType.PDF }, text: formatMessage({ id: 'report.download.download_pdf_format' }), icon: 'file pdf outline' });
    options.push({ key: 'word', value: { type: FileType.DOCX }, text: formatMessage({ id: 'report.download.download_word_format' }), icon: 'file word outline' });
    const trigger = (
        <div className='flex items-center text-base font-normal text-secondary space-x-2'>
            {showLabel && <span><FormattedMessage id='report.download_report' /></span>}
            <Button data-test-id='download-pdf-button' className='py-3 px-3 md:p-2 border-0 bg-gray-300 ' onClick={(): void => setModalIsOpen(true)}>
                <i className='mi'>vertical_align_bottom</i>
            </Button>
        </div>

    );
    const doDownload = (fileType: FileType): void => {
        let id = '';
        if (selectedId != null) {
            id = selectedId;
        }
        setPdfId(id);
        setCurrentFileType(fileType);
        downloadBegin(true);
        error = null;
        dispatch(requestPDF(id, context, fileType, includeComments, includeCheckpoints, correctiveActions, correctiveActionsTable, isGroupedInspection));
        setCurrentFileType(fileType);
        setText(texts.initial);
    };

    const updateIncludeComments = (include: boolean): void => {
        setIncludeComments(include);
        userPreferences['downloadWithComments'] = include;
        dispatch(updateUserPreferences(userPreferences))
    }

    const updateIncludeCheckpoints = (include: boolean): void => {
        setIncludeCheckpoints(include);
        userPreferences['downloadWithCheckpoints'] = include;
        dispatch(updateUserPreferences(userPreferences))
    }

    const updateCorrectiveActions = (include: boolean): void => {
        setCorrectiveActions(include);
        userPreferences['downloadWithCorrectiveActions'] = include;
        dispatch(updateUserPreferences(userPreferences))
    }

    const updateCorrectiveActionsTable = (include: boolean): void => {
        setCorrectiveActionsTable(include);
        userPreferences['downloadWithCorrectiveActionsTable'] = include;
        dispatch(updateUserPreferences(userPreferences))
    }

    useEffect(() => {
        if ('downloadWithComments' in userPreferences) {
            setIncludeComments(userPreferences['downloadWithComments'])
        }

        if ('downloadWithCheckpoints' in userPreferences) {
            setIncludeCheckpoints(userPreferences['downloadWithCheckpoints'])
        }

        if ('downloadWithCorrectiveActions' in userPreferences) {
            setCorrectiveActions(userPreferences['downloadWithCorrectiveActions'])
        }

        if ('downloadWithCorrectiveActionsTable' in userPreferences) {
            setCorrectiveActionsTable(userPreferences['downloadWithCorrectiveActionsTable'])
        }
    }, [userPreferences])
    useEffect(() => {
        downloadBegin(false);
        setText(texts.initial);
        setSelectedId(inspectionId);
    }, [inspectionId]);
    useEffect(() => {
        if (pdfURL !== undefined) {
            setDone(pdfURL.url !== 'unknown' || !!error);
        }
    }, [pdfURL]);
    const elements = <div className=''>
        {isDownloading && !done && !error && <div className='py-3'><Icon className='text-brand' name='circle notch' loading /> {text}</div>}
        {isDownloading && error && <div className='py-3'>
            <span className='flex space-x-2'>
                <InfoIcon warn={true} compact noMargins />
                {texts.error}
                <span className='link noUnderline' onClick={(): void => doDownload(currentFileType)}>
                    <FormattedMessage id='report.download.please_try_again' />
                </span>
            </span>
        </div>}
        {isDownloading && done && !error && <div className='py-3 downloadDone'>
            <Icon name='check circle' className='text-brand' />
            <FormattedMessage id='report.download.done' />. <a rel='noreferrer' target='_blank' onClick={(): void => reset()} className='link noUnderline' href={pdfURL && pdfURL.url}>
                <FormattedMessage id='report.download.open_report' />
            </a>
        </div>}
    </div>
    if (selectedId == null) {
        if (typeOptions.length > 0) {
            setSelectedId(typeOptions[0].value)
        }
        else if (inspectionId != null) {
            setSelectedId(inspectionId)
        }
    }
    return <Modal className='' size='small' trigger={trigger} open={modalIsOpen}>
        <Modal.Header><FormattedMessage id='report.download.download_report' /></Modal.Header>
        <Modal.Content>
            <div className='flex space-y-8 flex-col'>
                <div className='flex-col flex md:flex-row space-y-2 md:space-y-0 md:items-center'>
                    {elements}
                    <div className='flex mr-2'>
                        {!isDownloading && options.map((option) => {
                            return <Button primary data-test-id={'download_' + option.value.type} key={'download_' + option.value.type} onClick={(): void => doDownload(option.value.type)}>{option.text}</Button>
                        })}
                    </div>
                    {!isDownloading && context === 'inspection' &&
                        <div className='flex flex-col space-y-1'>
                            <Checkbox checked={includeComments} onChange={(evt, item): void => updateIncludeComments(item.checked)} label={intl.formatMessage({ id: 'report.download.include_comments' })} />
                            <Checkbox checked={correctiveActions} onChange={(evt, item): void => updateCorrectiveActions(item.checked)} label={intl.formatMessage({ id: 'report.download.use_corrective_actions' })} />
                        </div>
                    }
                    {!isDownloading && context === 'audit' &&
                        <div className='flex flex-col'>
                            <Checkbox className='space-y-1' checked={includeCheckpoints} onChange={(evt, item): void => updateIncludeCheckpoints(item.checked)} label={intl.formatMessage({ id: 'report.download.include_checkpoints' })} />
                            <Checkbox checked={correctiveActionsTable} onChange={(evt, item): void => updateCorrectiveActionsTable(item.checked)} label={intl.formatMessage({ id: 'report.download.use_corrective_actions_table' })} />
                        </div>
                    }
                </div>
                {!isDownloading && typeKeys.length > 0 && selectedId &&
                    <div className='flex flex-col md:w-96'>
                        <div className='mb-2'><FormattedMessage id='report.download.inspection_type' /></div>
                        <Dropdown selection
                            options={typeOptions}
                            value={selectedId}
                            onChange={(evt, item): void => setSelectedId(item.value)}
                        />
                    </div>
                }
            </div>

        </Modal.Content>
        <Modal.Actions className='flex justify-end'>
            <Button data-test-id='close-dialog' onClick={reset}>{done ? intl.formatMessage({ id: 'report.download.close' }) : intl.formatMessage({ id: 'report.download.cancel' })}</Button>
        </Modal.Actions>
    </Modal>;
};

export default DownloadReport;
