import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { SimpleProductionUnit } from '../../../backend_api/models';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { CreateOrSelectProductionUnitModal } from './CreateOrSelectProductionUnitModal';
import { NewProductionUnitParams } from './NewProductionUnitParams';

type Props = {
    className?: string;
    productionUnits: SimpleProductionUnit[];
    edit: boolean;
    onUserWantedToRemoveProductionUnitId(id: string): void;
    onUserWantedToAddProductionUnitId(id: string): void;
    onUserWantedToCreateAndAddProductionUnit(params: NewProductionUnitParams): void;
    excludeFromSelectorIds?: string[];
    isUserAllowedToCreateProductionUnits: boolean;
};

const ProductionUnitEditableList = (props: Props): React.ReactElement => {
    const { className, productionUnits, edit } = props;
    const dispatch = useAppDispatch();

    const idsToExclude = React.useMemo(function calculateExcludedIds() {
        return props.excludeFromSelectorIds.concat(props.productionUnits.map(pu => pu.id));
    }, [props.excludeFromSelectorIds, props.productionUnits]);

    return <div className={twMerge('', className)}>
        <div className='mb-4'>
            {productionUnits.map((upu) => {
                return <div key={'upu_' + upu.id} className='flex justify-between py-1'>
                    <Link className='link' to={'/production_units/' + upu.id}>{upu.number && <span>{upu.number}.</span>} {upu.name}</Link>
                    {edit && <div className=''>
                        <DeleteConfirm
                            trigger={<div className='bg-surface-interactive text-smx px-1 cursor-pointer'><FormattedMessage id='globals.confirmation.remove' /></div>}
                            deleteFunction={() => props.onUserWantedToRemoveProductionUnitId(upu.id)} /></div>}
                </div>
            })}
        </div>
        {
            edit ?
                <CreateOrSelectProductionUnitModal
                    allowCreatingNewProductionUnits={props.isUserAllowedToCreateProductionUnits}
                    groupsEnabled={true}
                    excludeProductionUnitIds={idsToExclude}
                    allowExistingProductionUnits={true}
                    onProductionUnitIdSelected={(productionUnitId: string) => {
                        if (productionUnitId) {
                            props.onUserWantedToAddProductionUnitId(productionUnitId)
                        }
                    }}
                    onUserWantedToCreateProductionUnit={(params: NewProductionUnitParams) => {
                        if (params) {
                            props.onUserWantedToCreateAndAddProductionUnit(params)
                        }
                    }}
                    renderOpenButton={() =>
                        <Button primary size='tiny'>
                            <FormattedMessage id='production_units.selector.add_production_unit' />
                        </Button>
                    }
                />
                : null
        }
    </div>
}
export default ProductionUnitEditableList;
