import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Dropdown, Grid, Input, Modal, Table } from 'semantic-ui-react';
import { MultiLingualText, UserRole } from '../../../backend_api/models';
import { OptionsType } from '../../../base/types';
import { deepCopy } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { Locale } from '../../globals';
import { getCurrentLocale, getLocales } from '../../globals/selectors';
import { getRole, saveRole } from '../actions';
import { RoleState } from '../reducers';
import { getRoleSelector } from '../selectors';
import DetailedRoleView from './DetailedRoleView';

type RoleEditorProps = {
    roleId: string;
}

const RoleEditor = (props: RoleEditorProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const role: UserRole = useSelector((state: { app: { roles: RoleState } }) => getRoleSelector(state, props.roleId))
    const [editedRole, setEditedRole] = useState<UserRole>();
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [accessLevel, setAccessLevel] = useState<number>();
    const [isTranslating, setIsTranslating] = useState<boolean>(false);
    const locales: Locale[] = useSelector(getLocales);
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const [translatingLanguage, setTranslatingLanguage] = useState<Locale>();
    const [translationName, setTranslationName] = useState<string>();
    const [translationDescription, setTranslationDescription] = useState<string>();

    const setTranslationValues = (): void => {
        if (role && translatingLanguage) {
            setTranslationName(role.name[translatingLanguage.language] || '')
            setTranslationDescription(role.description[translatingLanguage.language] || '')
        }
    }

    const setValues = (): void => {
        if (role) {
            setName(getLanguageText(role.name, currentLocale))
            setDescription(getLanguageText(role.description, currentLocale))
        }
    }

    useEffect(() => {
        dispatch(getRole(props.roleId));
    }, []);

    useEffect(() => {
        setTranslationValues()
    }, [translatingLanguage]);

    useEffect(() => {
        setTranslatingLanguage(currentLocale)
        setValues()
    }, [currentLocale]);

    useEffect(() => {
        if (role) {
            setEditedRole(role)
            setValues()
            setAccessLevel(role.access_level)
        }
    }, [role]);

    const toggleAccessSelect = (propertieId: string, accessId: string, scopeId: string): void => {
        const editedRoleCopy = deepCopy(editedRole);
        editedRoleCopy.properties.forEach((property) => {
            if (property.id === propertieId) {
                property.permissions.forEach((permission) => {
                    if (permission.id === accessId) {
                        permission.values[scopeId] = !permission.values[scopeId];
                    }
                });
            }
        });
        save(editedRoleCopy, false);
    }

    const languageOptions: OptionsType[] = [];
    locales.forEach((locale) => languageOptions.push({
        key: locale.id,
        value: locale.language,
        text: locale.name
    }));

    const save = (editedRoleOld: UserRole, updateRoleList): void => {
        const editedRoleCopy: UserRole = deepCopy(editedRoleOld);
        editedRoleCopy.name.C = name;
        editedRoleCopy.description.C = description;
        editedRoleCopy.access_level = accessLevel;
        setEditedRole(editedRoleCopy);
        dispatch(saveRole(props.roleId, editedRoleCopy, updateRoleList));
    }

    const saveTranslation = (): void => {
        const editedRoleCopy: UserRole = deepCopy(editedRole);
        editedRoleCopy.name[translatingLanguage.language] = translationName;
        editedRoleCopy.description[translatingLanguage.language] = translationDescription;
        dispatch(saveRole(props.roleId, editedRoleCopy, false));
        setIsTranslating(false)
    }

    const startTranslating = (): void => {
        setTranslationValues()
        setIsTranslating(true)
    }

    const cancelTranslation = (): void => {
        setIsTranslating(false)
        setTranslatingLanguage(currentLocale)
    }

    const settingTranslationLanguage = (language: string): void => {
        const locale = locales.find((locale) => locale.language === language)
        setTranslatingLanguage(locale)
    }

    const accessLevelOptions = []
    for (let i = 1; i < 11; i++) {
        accessLevelOptions.push({
            value: i,
            text: i
        })
    }

    const inputStyle = { float: 'right', width: '15em' }

    return <div className='pageContainer'>
        {role && <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb>
                    <Breadcrumb.Section>
                        <Link className='link noUnderline' to='/roles'><FormattedMessage id='roles.bread_crumb' /></Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section>
                        <FormattedMessage id='roles.bread_crumb.edit_role' />
                    </Breadcrumb.Section>
                </Breadcrumb>
                <Button style={{ float: 'right', marginBottom: '10px' }} secondary onClick={startTranslating}><FormattedMessage id="roles.translate" /></Button>
            </div>
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            {intl.formatMessage({ id: 'roles.role_name' })}
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                style={inputStyle}
                                type="text"
                                value={name}
                                onChange={(evt, item): void => setName(item.value)}
                                onBlur={(): void => save(editedRole, true)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            {intl.formatMessage({ id: 'roles.role_description' })}
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                style={inputStyle}
                                type="text"
                                value={description}
                                onChange={(evt, item): void => setDescription(item.value)}
                                onBlur={(): void => save(editedRole, false)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            {intl.formatMessage({ id: 'roles.role_access_level' })}
                        </Table.Cell>
                        <Table.Cell>
                            <Dropdown selection
                                style={inputStyle}
                                options={accessLevelOptions}
                                value={accessLevel}
                                onChange={(evt, item): void => setAccessLevel(item.value as number)}
                                onBlur={(): void => save(editedRole, false)}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <DetailedRoleView isEditable={true} role={editedRole} toggleValue={toggleAccessSelect} />
            <Breadcrumb>
                <Breadcrumb.Divider icon='left angle' />
                <Breadcrumb.Section>
                    <Link className='link noUnderline' to='/roles'><FormattedMessage id='roles.back_to_roles' /></Link>
                </Breadcrumb.Section>
            </Breadcrumb>
            {translatingLanguage && <Modal
                open={isTranslating}
                onClose={cancelTranslation}
            >
                <Modal.Header>
                    <FormattedMessage id="roles.translate" />
                </Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width='3' className='description'>
                                {intl.formatMessage({ id: 'roles.language' })}
                            </Grid.Column>
                            <Grid.Column width='3' className='description'>
                                <Dropdown
                                    className="selection"
                                    value={translatingLanguage.language}
                                    options={languageOptions}
                                    onChange={(evt, item): void => settingTranslationLanguage(item.value as string)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width='3' className='description'>
                                {intl.formatMessage({ id: 'roles.role_name' })}
                            </Grid.Column>
                            <Grid.Column width='3' className='description'>
                                <Input
                                    type="text"
                                    value={translationName}
                                    onChange={(evt, item): void => setTranslationName(item.value)}
                                    placeholder={role.name.C || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width='3' className='description'>
                                {intl.formatMessage({ id: 'roles.role_description' })}
                            </Grid.Column>
                            <Grid.Column width='3' className='description'>
                                <Input
                                    type="text"
                                    value={translationDescription}
                                    onChange={(evt, item): void => setTranslationDescription(item.value)}
                                    placeholer={role.description.C || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={saveTranslation} positive><FormattedMessage id='globals.confirmation.ok' /></Button>
                    <Button onClick={cancelTranslation}><FormattedMessage id="globals.confirmation.cancel" /></Button>
                </Modal.Actions>
            </Modal>}
        </Fragment>}
    </div>;
}

function getLanguageText(text: MultiLingualText, locale: Locale): string {
    return text[locale.language] || text.C
}

export default RoleEditor;
