import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Checkbox, Dropdown, Grid, Input } from 'semantic-ui-react'
import { deepCopy } from '../../../base/utils'
import { getCustomFields } from '../../customFields/selectors'
import { CustomField } from '../../customFields/types'
import MultiLangualText from '../../globals/components/MultiLangualText'
import { MultiLanguageText } from '../../globals/types'
import { BarcodeFormat, BarcodeSample } from '../types'

const formatDefault = 'Qarma-format-default';

type BarcodeProps = {
    data: BarcodeSample;
    update(data: BarcodeSample);
    language: string;
}

const Barcode = (props: BarcodeProps): React.ReactElement => {
    const [expectedValue, setExpectedValue] = useState<string>()
    const customFields: CustomField[] = useSelector(getCustomFields)
    const [focus] = useState<boolean>(false);

    useEffect(() => {
        if (props.data) {
            setExpectedValue(props.data.expected_barcode.value || '')
        }
    }, [props.data])

    const save = (): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.expected_barcode.value = expectedValue
        props.update(dataCopy)
    }

    const saveName = (name: MultiLanguageText): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.name = name;
        props.update(dataCopy);
    }

    const saveUseCustomField = (use: boolean): void => {
        const dataCopy = deepCopy(props.data);
        dataCopy.expected_barcode.use_custom_field = use;
        if (use) {
            dataCopy.expected_barcode.custom_field_definition_id = customFieldsOptions[0].value;
        }
        else {
            dataCopy.expected_barcode.custom_field_definition_id = null;
        }
        props.update(dataCopy)
    }

    const saveCustomFieldId = (id: string): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.expected_barcode.custom_field_definition_id = id
        props.update(dataCopy)
    }

    const saveExpectedFormat = (format: string): void => {
        const dataCopy = deepCopy(props.data)
        dataCopy.expected_format.value = format
        props.update(dataCopy)
    }

    const customFieldsOptions = [];
    customFields.forEach((field) => {
        const obj = {
            text: field.name,
            value: field.id,
        };
        customFieldsOptions.push(obj)
    })

    const formatOptions = [];
    for (const type in BarcodeFormat) {
        const obj = {
            text: BarcodeFormat[type],
            key: type,
            value: BarcodeFormat[type],
        };
        formatOptions.push(obj);
    }
    formatOptions.push({
        text: 'No Format',
        value: formatDefault,
    })

    const descriptionStyle = { width: '40%', alignItems: 'center', display: 'flex' }
    const inputBoxStyle = { width: '60%', alignItems: 'center', display: 'flex' }
    const checkboxStyle = { justifyContent: 'center', display: 'flex' }
    const inputStyle = { width: '100%' }

    return (
        <Grid style={{ margin: 'unset' }} data-test-id='barcode'>
            <Grid.Row>
                <div style={descriptionStyle}>
                    <FormattedMessage id={'checklists.editor.barcode.name'} />
                </div>
                <div style={inputBoxStyle}>
                    <MultiLangualText
                        presentation='input'
                        style={inputStyle}
                        originalText={props.data.name}
                        language={props.language}
                        save={saveName}
                        focus={focus}
                    />
                </div>
            </Grid.Row>
            <Grid.Row>
                <div style={descriptionStyle}>
                    <FormattedMessage id={'checklists.editor.barcode.expected_value'} />
                    {customFieldsOptions.length > 0 &&
                        <Checkbox checked={props.data.expected_barcode.use_custom_field} onChange={(evt, item): void => saveUseCustomField(item.checked)} style={checkboxStyle} />}
                </div>
                <div style={inputBoxStyle}>
                    {props.data.expected_barcode.use_custom_field ?
                        <Dropdown selection
                            style={inputStyle}
                            closeOnBlur
                            value={props.data.expected_barcode.custom_field_definition_id || customFieldsOptions[0].value}
                            options={customFieldsOptions}
                            onChange={(evt, item): void => saveCustomFieldId(item.value as string)}
                        />
                        :
                        <Input style={inputStyle} type="text" value={expectedValue} onBlur={save} onChange={(evt, item): void => { setExpectedValue(item.value) }} />
                    }
                </div>
            </Grid.Row>
            <Grid.Row >
                <div style={descriptionStyle}>
                    <FormattedMessage id={'checklists.editor.barcode.expected_format'} />
                </div>
                <div style={inputBoxStyle}>
                    <Dropdown selection
                        style={inputStyle}
                        closeOnBlur
                        value={props.data.expected_format.value || formatDefault}
                        options={formatOptions}
                        onChange={(evt, item): void => { saveExpectedFormat(item.value as string) }}
                    />
                </div>
            </Grid.Row>
        </Grid>
    )
}

export default Barcode