import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Header, Icon, List, Modal } from 'semantic-ui-react';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { LineTogglerSmall } from '../../globals/components/Utils';
import { MultiLanguageText } from '../../globals/types';
import TranslationText from './TranslationText';

type CheckpointHeaderProps = {
    name: any;
    collapsed: boolean;
    index: number;
    language: string;
    nameChanged(name: MultiLanguageText);
    collapse();
    containsCheckpoints: boolean;
    delete();
    duplicate();
}

const CheckpointHeader = (props: CheckpointHeaderProps): React.ReactElement => {
    const [edit, setEdit] = useState<boolean>(false);
    const [translating, setTranslating] = useState<boolean>(props.language !== 'C');
    const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

    useEffect(() => {
        setTranslating(props.language !== 'C')
    }, [props])

    const collapse = (): void => {
        if (!edit) {
            props.collapse()
        }
    }

    return (
        <List.Item key={'ckeckpointheader' + props.index}>
            <Form.Group className={'checkpoint'}>
                <Form.Field className='write u-flex-align-center' style={{ cursor: 'grab' }}>
                    <Icon name="ellipsis vertical" style={{ height: 'unset', color: '#888' }} />
                </Form.Field>
                <Form.Field width={14} style={{ display: 'flex', alignItems: 'center' }} onClick={(): void => {
                    if (!translating) {
                        collapse();
                    }
                }}>
                    {(edit) &&
                        <Fragment>
                            <MultiLangualText
                                originalText={props.name}
                                presentation={'area'}
                                language={props.language}
                                save={props.nameChanged}
                                focus={true && !translating}
                                style={{ marginRight: '5px' }}
                            />
                            <Button onClick={props.duplicate} style={{ float: 'right' }}><FormattedMessage id='checklists.duplicate' /></Button>
                            <Button onClick={(): void => setConfirmingDelete(true)} style={{ float: 'right' }}><FormattedMessage id='checklists.delete' /></Button>
                        </Fragment>
                    }
                    {!edit && !translating && <Header >
                        {props.name.C}
                    </Header>}
                    {!edit && translating && <TranslationText
                        text={props.name}
                        language={props.language}
                        isHeader={true}
                    />}
                </Form.Field>
                <Form.Field className='write u-flex-align-center' onClick={(): void => setEdit(!edit)} style={{ cursor: 'pointer' }}><Icon name="pencil" /></Form.Field>
                <Form.Field className='write u-flex-align-center' onClick={collapse} width={1}>
                    <LineTogglerSmall
                        data={{ open: !props.collapsed }}
                        mark={props.containsCheckpoints}
                    />
                </Form.Field>
            </Form.Group>
            <Modal
                open={confirmingDelete}
                closeIcon={true}
                onClose={(): void => setConfirmingDelete(false)}
                size='tiny'
            >
                <Modal.Header>
                    <FormattedMessage id='checklists.header.confirm_delete.header' />
                </Modal.Header>
                <Modal.Content>
                    <FormattedMessage id='checklists.header.confirm_delete.description' />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={(): void => { setConfirmingDelete(false); setEdit(false); props.delete() }}><FormattedMessage id='inspections.okay' /></Button>
                    <Button onClick={(): void => setConfirmingDelete(false)}><FormattedMessage id='inspections.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        </List.Item>
    );
}

export default CheckpointHeader;