import React, { ChangeEvent, Component, ReactNode } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Input, Loader, Message, Modal, Segment, Table } from 'semantic-ui-react';
import { AdminCtxUser } from '../../../backend_api/models/AdminCtxUser';
import { AppState, OptionsType } from '../../../base/types';
import ListButton from '../../globals/components/ListButton';
import { getAdminUsers } from '../../users/actions/actions';
import { createGroup, getGroups } from '../actions/actions';
import GroupItem from '../components/GroupItem';
import { getGroupsSelector, getIsFetchingSelector, getUsersAdminSelector } from '../selectors';
import { GroupData } from '../types';

type DispatchProps = {
    actions: {
        getGroups(): void;
        createGroup(name: string): void;
        getAdminUsers(): void;
    };
}
type StateProps = {
    groups: GroupData[];
    users: AdminCtxUser[];
    isFetching: boolean;
}

type GroupsProps = DispatchProps & StateProps & WrappedComponentProps;

const initialState = {
    newGroupName: '',
    showCreateGroup: false,
    newGroupValid: false,
    validationError: '',
};
type State = Readonly<typeof initialState>;

class GroupsContainer extends Component<GroupsProps, State> {

    readonly state: State = initialState;

    public componentDidMount(): void {
        this.props.actions.getGroups();
        this.props.actions.getAdminUsers();
    }

    public showCreateGroupHandler = (): void => {
        this.setState({ showCreateGroup: true });
    }

    public hideCreateGroupHandler = (): void => {
        this.setState({ showCreateGroup: false });
    }
    public createGroupSubmitHandler = (): void => {
        this.setState({ showCreateGroup: false, newGroupValid: false, validationError: '', newGroupName: '' });
        this.props.actions.createGroup(this.state.newGroupName);
    }
    public groupNameChangedHandler = (event: ChangeEvent, { value }: { value: string }): void => {
        let validationError = '';
        let isValid = true;
        const newName: string = value;
        isValid = newName !== '' && isValid;
        if (isValid) {
            if (this.props.groups.findIndex((group: any) => {
                if (group.name) {
                    return group.name.toLowerCase() === newName.toLowerCase();
                }
            }) !== -1) {
                isValid = false;
                validationError = 'Group name is already in use';
            }
        }
        this.setState({ newGroupName: newName, validationError, newGroupValid: isValid });
    }

    public render(): React.ReactElement {
        const formatMessage = this.props.intl.formatMessage;
        const getGroupUsers = (group: GroupData): OptionsType[] => {
            const myUsers: OptionsType[] = this.props.users.filter((user: AdminCtxUser) => {
                const index = user.groups.findIndex((userGroup: GroupData) => {
                    return userGroup.id === group.id && user.active;
                });
                return index !== -1;
            }).map((user: AdminCtxUser) => {
                const name = user.firstname + ' ' + user.lastname
                return { key: user.id, value: user.id, text: name };
            });
            return myUsers;
        }
        const groupsContent = this.props.groups.map((group: any) => {

            return (<GroupItem key={group.id} group={group} users={getGroupUsers(group)} />);
        });

        let validationElement: ReactNode = null;
        if (this.state.validationError !== '') {
            validationElement = (
                <Segment>
                    <Message negative>
                        <p>{this.state.validationError}</p>
                    </Message>
                </Segment>
            );
        }
        return (
            <Grid container className='groups-menu gridcontainer'>
                <Dimmer active={this.props.isFetching}>
                    <Loader />
                </Dimmer>
                <div className='groupsContainer'>
                    <div className='listMenu'>
                        <DocumentTitle title={this.props.intl.formatMessage({ id: 'page_title.groups' })} />
                        <Header as='h2'><FormattedMessage id='groups.groups' /></Header>
                        <ListButton onClick={this.showCreateGroupHandler} size='small' floated='right' primary><FormattedMessage id='groups.add_group' /></ListButton>
                    </div>
                    <Segment.Group raised>
                        <Segment>
                            <Table celled striped >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell><FormattedMessage id='groups.name' /></Table.HeaderCell>
                                        <Table.HeaderCell><FormattedMessage id='groups.assigned_users' />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {groupsContent}
                                </Table.Body>
                            </Table>
                            <Modal open={this.state.showCreateGroup}
                                onClose={this.hideCreateGroupHandler}
                                closeIcon={true}
                                size='small'>
                                <Header content={formatMessage({ id: 'groups.create_groups' })} />
                                <Modal.Content>
                                    {validationElement}
                                    <Form.Field>
                                        <Input
                                            fluid
                                            autoFocus
                                            value={this.state.newGroupName}
                                            placeholder={formatMessage({ id: 'groups.group_name' })}
                                            width={16}
                                            onChange={this.groupNameChangedHandler} />
                                    </Form.Field>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button primary disabled={!this.state.newGroupValid} onClick={this.createGroupSubmitHandler}><FormattedMessage id='groups.add_group' /></Button>
                                    <Button onClick={this.hideCreateGroupHandler}><FormattedMessage id='groups.cancel' /></Button>
                                </Modal.Actions>
                            </Modal>
                        </Segment>
                    </Segment.Group>

                </div>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            getGroups,
            createGroup,
            getAdminUsers,
        }, dispatch),
    };
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        groups: getGroupsSelector(state, true),
        isFetching: getIsFetchingSelector(state),
        users: getUsersAdminSelector(state),
    };
};

export default injectIntl(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(GroupsContainer));
