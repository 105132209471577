import qs from 'query-string';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';

interface NoMatchProps {
    location: {
        location: {
            search: string;
        };
    };
}

type MatchType = 'order_not_found' | 'report_not_found' | 'page_not_found'

const NoMatch = (props: NoMatchProps): React.ReactElement => {
    const q = qs.parse(props.location.location.search);
    const type: MatchType = q.type as MatchType || 'page_not_found';
    const ulClass = 'pl-6 pt-2';
    const ulStyle = { listStyle: 'disc' }
    const text = {
        report_not_found: {
            header: 'Report not found',
            mailHeader: 'Qarma - report not found',
            mailBody: 'An inspection report was not found. It has the following id: ' + q.id + '.',
            bodyText: <span>
                <h3><FormattedMessage id='base.the_report_you_were_looking_for_could_not_be_found' /></h3>
                <p><FormattedMessage id='base.something_went_wrong' /></p>
                <ul className={ulClass} style={{ listStyle: 'disc' }}>
                    <li><FormattedMessage id='base.please_check_that_the_link_to_the_report_is_correct' /></li>
                    <li><FormattedMessage id='base.please_also_check_that_you_do_have_the_proper_credentials_to_access_the_report' /></li>
                </ul>
            </span>,
        },
        order_not_found: {
            header: 'Order not found',
            mailHeader: 'Qarma - order not found',
            mailBody: 'An order was not found. It has the following id: ' + q.id + '.',
            bodyText: < span >
                <h3><FormattedMessage id='base.the_order_you_were_looking_for_could_not_be_found' /></h3>
                <p><FormattedMessage id='base.something_went_wrong' /></p>
                <ul className={ulClass} style={ulStyle}>
                    <li><FormattedMessage id='base.please_check_that_the_link_to_the_order_is_correct' /></li>
                    <li><FormattedMessage id='base.please_also_check_that_you_do_have_the_proper_credentials_to_access_the_order' /></li>
                </ul>
            </span >,
        },
        page_not_found: {
            header: 'Page not found',
            mailHeader: 'Qarma - webpage not found',
            mailBody: 'A page was not found on the Qarma website. It has this address: ' + location.href + '.',
            bodyText: <span>
                <h3><FormattedMessage id='base.we_are_sorry_but_the_page_you_are_looking_for_does_not_exist' /></h3>
                <FormattedMessage id='base.please' />
                <ul className={ulClass} style={{ listStyle: 'disc' }}>
                    <li><FormattedMessage id='base.check_that_the_link_to_page_is_correct' /></li>
                    <li><FormattedMessage id='base.make_sure_that_you_did_not_misspell_something' /></li>
                </ul>
            </span>,
        },
    };
    let mailBody = text[type].mailBody + '%0D%0A%0D%0A';
    if (type !== 'page_not_found') {
        mailBody += 'Details about the error:%0D%0A' + JSON.stringify(q);
    }
    const mailTo = 'mailto:support@qarmainspect.com?subject=' + text[type].mailHeader + '&body=' + mailBody;

    return (
        <Grid container className='inspections-menu'>
            <span className='pageContainer'>
                <Header as='h2'><Icon name='exclamation circle' />{text[type].header}</Header>
                <Segment.Group raised>
                    <Segment padded>
                        <DocumentTitle title={`${text[type].header} - Qarma`} />
                        <p>{text[type].bodyText}</p>
                        <p><FormattedMessage id='base.if_the_problem_continues_please_contact' /><a href={mailTo}><FormattedMessage id='base.qarma_support' /></a>.</p>
                        {type !== 'page_not_found' && <details>
                            <div><FormattedMessage id='base.type' />{type}</div>
                            <div><FormattedMessage id='base.id' />{q.id}</div>
                            <div><FormattedMessage id='base.error' />{q.error}</div>
                        </details>}
                    </Segment>
                </Segment.Group>
            </span>
        </Grid>
    );
};

export default NoMatch;
