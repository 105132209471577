/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UUID } from './UUID';

/**
 *
 *
 */
export type SetStatusText = {
    audit_id: UUID;
    checkpoint_id: UUID;
    status_text: SetStatusText.status_text;
};

export namespace SetStatusText {

    export enum status_text {
        EMPTY = 'empty',
        CHECKED_OK = 'checked_ok',
        CHECKED_NOT_OK = 'checked_not_ok',
        NOT_APPLICABLE = 'not_applicable',
        PENDING = 'pending',
    }


}

