import React, { Fragment } from 'react';
import { ChildrenOrStringType, ComponentOrStringType } from '../../types';
import { twMerge } from '../../utils';

type SegmentProps = {
    className?: string;
    heading?: ComponentOrStringType;
    footer?: ComponentOrStringType;
    header?: ComponentOrStringType;
    children?: ChildrenOrStringType;
    border?: boolean;
};

const Segment = (props: SegmentProps): React.ReactElement => {
    const { className, children, heading, footer, header, border = true } = props;
    const segCn = twMerge('md:mt-4 md:mb-6 rounded bg-white w-full flex flex-col',
        border && 'border border-color',
        className);
    let headerItem, footerItem, headingItem;
    if (header) {
        headerItem = <div className='border-b -mx-6 px-6 items-center pb-3 mb-4'>
            {header}
        </div>
    }
    if (footer) {
        footerItem = <div className='border-t -mx-4 md:-mx-6 px-4 md:px-6 pt-3 md:pb-4 mt-4'>
            {footer}
        </div>
    }
    if (heading) {
        headingItem = <h2 className='pb-2'>{heading}</h2>;
    }
    const body = <div className={twMerge(segCn, heading ? 'p-4 md:px-6 md:py-4' : 'p-4 md:px-6 md:py-4', footer && 'md:pb-0')}>
        {headerItem}
        {headingItem}
        {children}
        {footerItem}
    </div>;


    return <Fragment>{body}</Fragment>
}
export default Segment;
