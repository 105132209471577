import { configureStore as cs } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers, Middleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import thunkMiddleware from 'redux-thunk';
import { updateInspectionLogic, updateOrderLogic } from '../modules/inspections/logic/editInspectionLogic';
import { inspectionsListLogic } from '../modules/inspections/logic/inspectionsListLogic';
import { messageLogic } from '../modules/messages/logic';
import app from './rootReducer';


let store;
export const configureStore = () => {
    const logicMiddleware = createLogicMiddleware([updateInspectionLogic, updateOrderLogic, messageLogic, inspectionsListLogic]);

    const middlewares: Middleware[] = [
        thunkMiddleware,
        logicMiddleware,
    ];

    const rootReducer = combineReducers({
        app,
    });
    store = cs({
        reducer: rootReducer,
        middleware: middlewares,
    })
    return store;
}

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;

/*



export const configureStore = (initialState?: any): Store => {
  const rootReducer = combineReducers({
    app,
  });
  const store = createStore(rootReducer, initialState, composeWithDevTools(
    applyMiddleware(...middlewares),
    // sentryReduxEnhancer,
  ));
  return store;
};*/

