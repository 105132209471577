/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * pending
 * @export
 * @interface UpdateDocumentationTemplateAction
 */
export interface UpdateDocumentationTemplateAction {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentationTemplateAction
     */
    documentation_template_description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentationTemplateAction
     */
    documentation_template_name?: string;
}

export function UpdateDocumentationTemplateActionFromJSON(json: any): UpdateDocumentationTemplateAction {
    return UpdateDocumentationTemplateActionFromJSONTyped(json, false);
}

export function UpdateDocumentationTemplateActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDocumentationTemplateAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentation_template_description': !exists(json, 'documentation_template_description') ? undefined : json['documentation_template_description'],
        'documentation_template_name': !exists(json, 'documentation_template_name') ? undefined : json['documentation_template_name'],
    };
}

export function UpdateDocumentationTemplateActionToJSON(value?: UpdateDocumentationTemplateAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentation_template_description': value.documentation_template_description,
        'documentation_template_name': value.documentation_template_name,
    };
}


