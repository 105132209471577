import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Divider, Dropdown } from 'semantic-ui-react';
import { DropdownFilter } from '.';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditAllCustomConclusionsSelector } from '../../audit/selectors/auditSelectors';
import { DropdownOptionType } from '../../users/types';
import { getFilterConclusions, setFilter } from '../filtersActions';
import Filter from './Filter';

type ConclusionFilterProps = {
    disabled?: boolean;
    search?: boolean;
};
const AuditConclusionFilter = (props: ConclusionFilterProps): React.ReactElement => {
    const { disabled = false, search = true } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const intl = useIntl();
    const filterKey = 'final_conclusion_ids';
    const conclusion = getLocationEntry(location, filterKey);
    const conclusions = useSelector(getAuditAllCustomConclusionsSelector);    
    const [conclusionOptions, setConclusionOptions] = useState([])
    useEffect(() => {
        dispatch(getFilterConclusions());
    }, [])
    useEffect(() => {
        if (conclusions) {
            const options: DropdownOptionType[] = [];
            let deprecatedSection = false;
            conclusions.map((conclusion) => {
                // const name = getLocaleSpecificString(conclusion.full_name);
                const name = conclusion.name.text;
                if (conclusion.deprecated && !deprecatedSection) {
                    deprecatedSection = true;
                    options.push(
                        {
                            key: 'devider',
                            text: '',
                            value: '',
                            content: (<Divider />),
                            disabled: true
                        }
                    );
                    options.push(
                        {
                            key: 'filters.deprecated-conclusions',
                            text: '',
                            value: '',
                            content: <Dropdown.Header content={intl.formatMessage({ id: 'filters.deprecated-conclusions' })} />,
                            disabled: true
                        }
                    );
                }
                options.push({ key: conclusion.id, value: conclusion.id, text: name })
            })
            setConclusionOptions(options);
        }
    }, [conclusions])
    return <Filter labelId='filters.audit_conclusions.label_conclusion'>
        <DropdownFilter
            name='auditConclusions'
            options={conclusionOptions}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.audit_conclusions.select_conclusion' })}
            selectedItems={conclusion}
            handleSave={(items): void => {
                dispatch(setFilter(filterKey, items));
            }}
            inline={false}
            disabled={disabled}
            search={search}

        />
    </Filter>;
};

export default AuditConclusionFilter;
