import classnames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Popup } from 'semantic-ui-react';
import Image from '../../../../base/components/Image';
import { containsLongAndUnbrokenString, getLocaleSpecificString, preventDefaultAndStopPropagation, twMerge } from '../../../../base/utils';
import { TextItem } from '../../../globals/components';
import { ListViewItem, ListViewLine } from '../../../globals/components/views/list/ListView';
import { UserInitials } from '../../../users/components/Widgets';
import { getInspectionDate } from '../../Utils';
import { OrderListInspectionExtended } from '../../slices/inspectionsListSlice';
import { Inspection } from '../../types';
import { DefectsLine } from '../InspectionsItems';
import { CombineInspectionIcon, CombinedInspectionLabel } from '../combine/CombinedInspections';
import SelectCombineInspection from '../combine/SelectCombineInspection';
import { PlainStatus, getStatus } from '../edit/Status';
import IsEditPopup from '../report/IsEditPopup';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import { config } from './inspectionItemConfig';

export type InspectionItemProps = {
    orderId: string;
    inspection: Inspection | OrderListInspectionExtended;
    typeGroupInspections?: Inspection[] | OrderListInspectionExtended[];
    showCombine: boolean;
    showDefects?: boolean;
    showInspector?: boolean;
    hasInspectionTypes?: boolean;
    inspectionTypesStatus: React.ReactElement;
    className?: string;
    selectable?: boolean;
    isSelected?: boolean;
    askBeforeGoingToPlanned?: boolean;
    toggleSelect?(inspection: Inspection): void;
}

export const InspectionItem = (props: InspectionItemProps): React.ReactElement => {
    const intl = useIntl();
    const { inspection, hasInspectionTypes = false, inspectionTypesStatus, askBeforeGoingToPlanned = false, className, selectable = false, isSelected, showCombine, typeGroupInspections, showDefects = false, showInspector = false } = props;
    const plannedUrl = '/inspections/edit_inspection/' + props.orderId;
    const isPlanned = inspection.status === 'planned';
    const isCombinedInspection = inspection.master_inspection !== null;
    const clickFunction = (): void => {
        if (selectable) {
            props.toggleSelect(inspection);
        }
    };

    let to = '/inspections/';
    if (inspection.status === 'planned' && !isCombinedInspection) {
        to += 'edit_inspection/' + props.orderId;
    }
    if (inspection.status === 'planned' && isCombinedInspection) {
        to += 'edit_inspection/' + inspection.master_inspection.order_id;
    }
    if (inspection.status === 'report' || inspection.status === 'ongoing') {
        if (isCombinedInspection) {
            to += 'reports/' + inspection.master_inspection.id;

        } else {
            to += 'reports/' + inspection.inspection_id;
        }
    }
    let url = '';
    if (inspection.item_image) {
        url = inspection.item_image + '?w=150';
    }
    const widths = config.widths;
    const itemNumberContent = <div className='space-x-1 break-all flex items-center'><span>{inspection.item_number || ''}</span><CombinedInspectionLabel type='short' inspection={inspection} />{/* <ReinspectLabel type='dashboard' inspection={inspection} /> */}<SplitShipmentsMarker inspection={inspection} /></div>;
    const item = <ListViewLine
        onClick={clickFunction}
        link={selectable ? null : to}
        className={classnames('inspection text-lg md:text-base md:flex md:flex-row border-b border-default md:hover:bg-highlight-green md:hover:cursor-pointer pb-4', { 'md:font-bold': !inspection.seen_by_user }, className)}>
        <div className={classnames('md:hidden grid grid-cols-12 w-full gap-0 space-y-2')} >
            <div className='flex col-span-12 space-x-2'>
                {inspection.item_name}
            </div>
            <div className='col-span-10 text-secondary space-x-2 flex'>
                <span>{inspection.item_number}</span>
                {isCombinedInspection && <CombineInspectionIcon className={'bg-default-widget-color'} />}
                <SplitShipmentsMarker inspection={inspection} />
            </div>
            <div className='col-span-2 row-span-2 flex justify-end'>
                {url !== '' && <Image squared className='md:h-24 h-16' url={url} />}
                {/* url == '' && <div className='w-12 h-12 bg-gray-200 rounded'></div> */}
            </div>
            <div className='col-span-10	text-secondary'>{getInspectionDate([inspection], intl.locale)} | {inspection.total_quantity}</div>
            <div className='col-span-8' onClick={(e): void => preventDefaultAndStopPropagation(e)}>{inspectionTypesStatus}</div>
            <div className='col-span-4'><PlainStatus classNames='h-6 w-full' inspection={inspection} /></div >
        </div >
        <ListViewItem className={classnames('items-center', { [widths.image]: !showCombine, 'md:w-2/24': showCombine })}>
            {!showCombine && url !== '' && <Image squared className='hidden md:flex' url={url} />}
            {showCombine && <SelectCombineInspection inspection={inspection} hasInspectionTypes={hasInspectionTypes} inspectionsTypeGroup={typeGroupInspections} />}</ListViewItem>
        <ListViewItem className={classnames('hidden w-20/24', widths.itemName)}>{inspection.item_name}</ListViewItem>
        <ListViewItem className={classnames('hidden md:flex break-all md:flex-wrap itemItemNo', widths.itemNo)}>{itemNumberContent}</ListViewItem>
        <ListViewItem className={twMerge('hidden md:flex pr-4 md:flex-wrap', widths.itemName, (inspection.item_name && containsLongAndUnbrokenString(inspection.item_name, 35)) && 'break-all')}>{inspection.item_name}</ListViewItem>
        <ListViewItem className={classnames('hidden md:flex w-2/24', widths.qty)}>{inspection.total_quantity}</ListViewItem>
        <ListViewItem className={classnames('hidden md:flex md:flex-wrap break-words w-full', widths.types)}>{inspectionTypesStatus}</ListViewItem>
        {showDefects && <ListViewItem className={classnames('hidden md:flex md:flex-wrap break-words w-full', widths.defects)}>{showDefects && <DefectsLine defects={inspection.defect_counts} />}</ListViewItem>}
        <ListViewItem className={classnames('hidden md:flex break-words w-2/24', widths.date)}>{getInspectionDate([inspection], intl.locale)}</ListViewItem>
        {showInspector && <ListViewItem className={classnames('hidden md:flex break-words w-2/24', widths.inspector)}><UserInitials user={inspection.assigned_user} /></ListViewItem>}
        <ListViewItem className={classnames('hidden md:flex w-3/24 md:font-normal', widths.status)}>
            <Popup
                disabled={getStatus(inspection) === 'planned' || getStatus(inspection) === 'downloaded'}
                trigger={<span className='flex flew-grow w-full'><PlainStatus classNames='h-10 w-full' inspection={inspection} /></span>}
                className=''
                hoverable
                flowing
                style={{ width: '200px' }}
            >
                <Popup.Content className='space-y-4'>
                    {inspection.inspector_conclusion !== null && <TextItem column label={intl.formatMessage({ id: 'inspections.hover.inspector.conclusion' })} text={getLocaleSpecificString(inspection.inspector_conclusion.full_name)} />}
                    {inspection.conclusion !== null && <TextItem column label={intl.formatMessage({ id: 'inspections.hover.inspection.status' })} text={getLocaleSpecificString(inspection.conclusion.full_name)} />}
                </Popup.Content>
            </Popup></ListViewItem>
        {selectable && <ListViewItem className='select'><Checkbox disabled={inspection.status !== 'report'} checked={isSelected} className='pl-2' /></ListViewItem>}
    </ListViewLine>;
    if (askBeforeGoingToPlanned && isPlanned && !selectable) {
        return <IsEditPopup
            plannedUrl={plannedUrl}
            trigger={item}
            inspection={inspection}
        />;
    }
    return item;
};

export default InspectionItem;