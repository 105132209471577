import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { AdminCtxUser } from '../../../backend_api/models';
import { getProfile } from '../../profile/selectors';

type Props = {
    user: AdminCtxUser,
    canManage: boolean,
    disableUser(user: AdminCtxUser);
}

const DisableUser = (props: Props) => {
    const profile = useSelector(getProfile);
    const disableUser = (): void => props.canManage ? props.disableUser(props.user) : null;
    const iconName = props.user.active ? 'trash' : 'undo'
    return <Fragment>
        {profile.id !== props.user.id ? <i data-test-id='users-disable' onClick={disableUser} className={`u-cursor-hover icon ${iconName} u-table-cell-center ${props.canManage ? '' : 'disabled noCursor'}`} ></i> : null}
    </Fragment>
}

export default DisableUser;