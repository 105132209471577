import qs from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'semantic-ui-react';
import { useSelection } from '../../../../../base/hooks';
import {
    addArrayItemOrRemoveIfExists,
    compareObjects,
    preventDefaultAndStopPropagation
} from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import CompactDateList from '../../../../globals/components/CompactDateList';
import PageContainer from '../../../../globals/components/PageContainer';
import { InfoIcon } from '../../../../globals/components/Utils';
import QLoader from '../../../../globals/components/loaders/Loader';
import LineTogglerButton from '../../../../globals/components/views/list/LineTogglerButton';
import { ListView, ListViewHeader, ListViewItem, ListViewLine } from '../../../../globals/components/views/list/ListView';
import ListViewShowMore from '../../../../globals/components/views/list/ListViewShowMore';
import { getInspectionETD } from '../../../../inspections/Utils';
import OrderItemTitle from '../../../../inspections/components/dashboard/views/listView/OrderItemTitle';
import SupplierInfo from '../../../../inspections/components/dashboard/views/listView/SupplierInfo';
import { Inspection, InspectionType } from '../../../../inspections/types';
import { getOrganisationNameSelector } from '../../../../users/selectors';
import { getBookings, updateBookingDateBulk } from '../../../actions';
import { getBookingsSelector, isBookingDataFetchingSelector } from '../../../selectors';
import { Booking, SupplierBooking } from '../../../types';
import BookingETD from '../BookingETD';
import BookingStatusOrder from '../BookingStatusOrder';
import CollectBooking from '../CollectBookings';
import InspectionTypeSelectionModal from '../InspectionTypeSelectionModal';
import BookingLine from './BookingLine';
import BookingMenu from './BookingMenu';

const Bookings = (): React.ReactElement => {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    let content: React.ReactElement;
    const PER_PAGE = 20;
    const formatMessage = intl.formatMessage;
    const dispatch = useAppDispatch();
    const isLoading: boolean = useSelector(isBookingDataFetchingSelector);
    const organisationName: string = useSelector(getOrganisationNameSelector);
    const [openSelectionModal, setOpenSelectionModal] = useState<boolean>(false);

    const {
        selection: selectedInspections,
        selectElement: selectInspection,
        deselectElement: deselectInspection,
        resetSelection: resetSelectedInspections,
        isElementSelected: isInspectionSelected,
    } = useSelection<Inspection>(function (inspection: Inspection) { return inspection.inspection_id });

    const [selecting, setSelecting] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<InspectionType>(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [params, setParams] = useState(undefined);
    const [openItems, setOpen] = useState(() => {
        const q = qs.parse(location.search);
        if (q.id) {
            return Array.isArray(q.id) ? q.id : [q.id];
        }
        return [];
    });
    const bookingItems: Booking = useSelector(getBookingsSelector);
    const loadMore = (): void => {
        setIsLoadingMore(true);
        dispatch(getBookings(bookingItems.start + PER_PAGE, PER_PAGE));
        const url = qs.parse(location.search);
        url['id'] = openItems;
        history.push('?' + qs.stringify({ ...url }, { skipNull: true }));
    };
    const isOpen = (id: string): boolean => {
        return openItems.includes(id);
    };
    const toggleOpen = (id: string): void => {
        const oi = Object.assign([], openItems);
        const arr = addArrayItemOrRemoveIfExists(oi, id);
        setOpen(arr);
    };
    const paramsChanged = (): boolean => {
        const pn = qs.parse(location.search);
        const pc = { ...params };
        if (pc.id) {
            delete pc.id;
        }
        if (pn.id) {
            delete pn.id;
        }
        return !compareObjects(pc, pn);
    };

    useEffect(() => {
        if (paramsChanged()) {
            dispatch(getBookings(0, PER_PAGE));
            setParams(qs.parse(location.search));
        }
    }, [location.search]);

    useEffect(() => {
        const url = qs.parse(location.search);
        url['id'] = openItems;
        history.push('?' + qs.stringify({ ...url }, { skipNull: true }));
    }, [openItems]);

    useEffect(() => {
        if (!isLoading) {
            setIsLoadingMore(false);
        }
    }, [isLoading]);

    function userWantsToSelectInspection(inspection: Inspection): void {
        selectInspection(inspection);
    }

    function userWantsToDeselectInspection(inspection: Inspection): void {
        deselectInspection(inspection)
    }

    function userWantsToCancelSelection(): void {
        resetSelectedInspections();
        setSelecting(false);
    }

    function save(date: moment.Moment): void {
        const ids = selectedInspections.map((inspection) => {
            if (inspection.master_inspection) {
                return inspection.master_inspection.id;
            }
            else {
                return inspection.inspection_id;
            }
        });
        dispatch(updateBookingDateBulk(date, ids, PER_PAGE));
        userWantsToCancelSelection();
    }

    function selectAll(item: SupplierBooking): void {
        if (areAllSelectableInspectionsSelected(item)) {
            deselectAllSelectableInspections(item);
        } else {
            selectAllSelectableInspections(item)
        }
    }

    function selectableInspections(item: SupplierBooking): Inspection[] {
        return item.inspections.filter((inspection: Inspection) => isSelectable(inspection, selectedType));
    }

    function selectAllSelectableInspections(item: SupplierBooking): void {
        selectableInspections(item).forEach((inspection: Inspection) => {
            selectInspection(inspection)
        });
    }

    function deselectAllSelectableInspections(item: SupplierBooking): void {
        selectableInspections(item).forEach((inspection: Inspection) => {
            deselectInspection(inspection);
        });
    }

    function isAnyInspectionSelectable(item: SupplierBooking): boolean {
        return selectableInspections(item).length > 0;
    }

    function areAllSelectableInspectionsSelected(item: SupplierBooking): boolean {
        return selectableInspections(item).every((inspection) => {
            return isInspectionSelected(inspection)
        });
    }

    if (bookingItems && bookingItems.elements && bookingItems.elements.length === 0) {
        content = <ListView className='bookingOverview'><InfoIcon text={formatMessage({ id: 'supplier_booking.bookings.no_bookings' })} /></ListView>;
    }
    if (bookingItems && bookingItems.elements && bookingItems.elements.length > 0) {
        content =
            <Fragment>
                <DocumentTitle title={intl.formatMessage({ id: 'page_title.bookings' })} />
                {selecting && <CollectBooking
                    inspections={selectedInspections}
                    cancel={userWantsToCancelSelection}
                    removeInspection={userWantsToDeselectInspection}
                    save={save}
                    inspectionType={selectedType}
                />}
                <ListViewHeader items={[
                    { label: undefined, className: selecting ? 'w-2/16' : 'w-0/16', key: 'spacer.1' },
                    { label: formatMessage({ id: 'inspections.order_no' }), className: 'sm:w-3/16', key: 'order_number' },
                    { label: formatMessage({ id: 'inspections.item' }), className: selecting ? 'sm:w-4/16' : 'sm:w-5/16', key: 'order_name' },
                    { label: formatMessage({ id: 'supplier_booking.bookings.order_line.booking_dates' }), className: selecting ? 'sm:w-2/16' : 'sm:w-3/16', key: 'order_name' },
                    { label: formatMessage({ id: 'supplier_booking.bookings.order_line.etd' }), className: 'sm:w-2/16', key: 'etd' },
                    { label: formatMessage({ id: 'inspections.supplier' }), className: selecting ? 'sm:w-3/16' : 'sm:w-4/16', key: 'supplier' },
                    { label: undefined, className: 'sm:w-2/16', key: 'spacer.2' },
                ]} />

                <ListView className='border'>
                    {bookingItems.elements.map((item: SupplierBooking, i) => {
                        const open = isOpen(item.order_id);
                        let bookingDates = item.inspections ? item.inspections.map((inspection) => {
                            return inspection.booking_date
                        }) : []
                        bookingDates = bookingDates.filter((value, index, self) => {
                            return value && self.indexOf(value) === index;
                        })
                        return <Fragment key={'booking_' + i}>
                            <ListViewLine className='cursor-pointer' onClick={(): void => toggleOpen(item.order_id)}>                            
                                <ListViewItem className={selecting ? 'sm:w-2/16' : 'sm:w-0/16'}>
                                    {selecting && isAnyInspectionSelectable(item) &&
                                        <Button primary onClick={(e): void => {
                                            preventDefaultAndStopPropagation(e);
                                            selectAll(item)
                                        }}>
                                            {areAllSelectableInspectionsSelected(item) ? <FormattedMessage id='supplier_booking.bookings.remove_all' /> : <FormattedMessage id='supplier_booking.bookings.select_all' />}
                                        </Button>}
                                </ListViewItem>
                                <ListViewItem className='sm:w-3/16'>{item.order_number}</ListViewItem>
                                <ListViewItem className={selecting ? 'sm:w-4/16' : 'sm:w-5/16'}><OrderItemTitle inspections={item.inspections} ordersCnt={item.inspections.length} /></ListViewItem>
                                <ListViewItem className={selecting ? 'sm:w-2/16' : 'sm:w-3/16'}><CompactDateList dates={bookingDates.sort()} /></ListViewItem>
                                <ListViewItem className='sm:w-2/16'>
                                    <BookingStatusOrder order={item}><BookingETD date={getInspectionETD(item.inspections)} isOverdue={false} /></BookingStatusOrder>
                                </ListViewItem>
                                <ListViewItem className={selecting ? 'sm:w-3/16' : 'sm:w-4/16'}><SupplierInfo inspections={item.inspections} /></ListViewItem>
                                <ListViewItem className='sm:w-2/16 justify-end'><LineTogglerButton open={open} itemsCnt={item.inspections.length} /></ListViewItem>
                            </ListViewLine>
                            <ListViewLine visible={open} className='border-l-3 border-l-brand sub flex-col md:pt-0 md:px-0 w-full'>
                                {/* STICKY ! */}
                                <ListViewHeader className='mt-0 bg-highlight-green md:py-1 border-b' items={[
                                    { label: undefined, className: selecting ? 'w-2/16' : 'w-0/16', key: 'spacer.1' },
                                    { label: undefined, className: 'w-1/16', key: 'spacer.2' },
                                    { label: formatMessage({ id: 'supplier_booking.bookings.item_line.item_number' }), className: 'w-3/16', key: 'item_number' },
                                    { label: formatMessage({ id: 'supplier_booking.bookings.item_line.item_name' }), className: 'w-3/16', key: 'item_name' },
                                    { label: formatMessage({ id: 'supplier_booking.bookings.item_line.qty' }), className: 'w-1/16', key: 'qty' },
                                    { label: formatMessage({ id: 'supplier_booking.bookings.item_line.booking_date' }), className: 'w-2/16', key: 'booking_date' },
                                    selecting
                                        ? { label: undefined, className: 'w-0/16', key: 'spacer.3' }
                                        : { label: formatMessage({ id: 'supplier_booking.bookings.item_line.confirmation_date' }), className: 'w-2/16', key: 'confirmation_date' },
                                    { label: formatMessage({ id: 'supplier_booking.bookings.item_line.planned_date' }, { organisationName }), className: 'w-2/16', key: 'planned_date' },
                                ]} />
                                {item.inspections.map((inspection: Inspection, index) => {
                                    return <BookingLine key={i + 'booking_line_' + index}
                                        inspection={inspection}
                                        className=''
                                        isSelectable={isSelectable(inspection, selectedType)}
                                        selecting={selecting}
                                        select={(remove): void => {
                                            if (remove) {
                                                userWantsToDeselectInspection(inspection)
                                            } else {
                                                userWantsToSelectInspection(inspection)
                                            }
                                        }}
                                        isSelected={isInspectionSelected(inspection)}
                                    />
                                })}
                            </ListViewLine>
                        </Fragment>;
                    })}
                    <ListViewShowMore
                        isLoading={isLoading}
                        totalCnt={bookingItems.total}
                        start={bookingItems.start}
                        perPage={PER_PAGE}
                        loadMore={loadMore}
                    />
                </ListView>
                <InspectionTypeSelectionModal
                    open={openSelectionModal}
                    cancel={(): void => {
                        setOpenSelectionModal(false)
                    }}
                    select={(type: InspectionType): void => {
                        setOpenSelectionModal(false)
                        setSelectedType(type)
                        setSelecting(true)
                    }}
                />
            </Fragment>;
    }
    return <PageContainer header={<FormattedMessage id='bookings.title' />}>
        <BookingMenu
            multiSelectingEnabled={selecting}
            enableMultiselect={(): void => setOpenSelectionModal(true)}
        />
        <QLoader isLoading={isLoading && !isLoadingMore} loadingMessageKey='supplier_booking.bookings.loading_data_please_wait' />
        {content}
    </PageContainer>;
};

function isSelectable(inspection: Inspection, type: InspectionType): boolean {
    let correctType = false;
    if (type == null && inspection.inspection_type == null) {
        correctType = true;
    }
    else if (inspection.inspection_type && type && inspection.inspection_type.id == type.id) {
        correctType = true;
    }
    return inspection.status == 'planned' && inspection.locked == false && correctType;
}

export default Bookings;
