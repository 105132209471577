import { createSelector } from '@reduxjs/toolkit';
import { GetProfileResponse } from '../../backend_api/models/GetProfileResponse';
import { AppState } from '../../base/types';
import { GroupData } from '../groups/types';

const profileSelector = (state: AppState): GetProfileResponse => state.app.profile.profile;
const ownGroupsSelector = (state: AppState, excludeSuppliers?: boolean): GroupData[] => {
    if (excludeSuppliers) {
        return state.app.groups.groups.filter((group: GroupData) => !group.is_supplier_group);
    }
    return state.app.groups.groups;
};

const isUpdatingSelector = (state: AppState): boolean => state.app.profile.isUpdatingProfile;
const customerLogoPathSelector = (state: AppState): string => {
    return state.app.profile.profile.organization && state.app.profile.profile.organization.logo || '';
};
export const getIsUpdating = createSelector(
    [isUpdatingSelector],
    (isUpdatingProfile) => isUpdatingProfile,
);

export const getProfile = createSelector(
    [profileSelector],
    (profile) => profile,
);

export const getOwnGroups = createSelector(
    [ownGroupsSelector],
    (groups) => groups,
);

export const getCustomerLogoPath = createSelector(
    [customerLogoPathSelector],
    (path) => path,
);
