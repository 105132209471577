import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Divider, Header, List, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Link from '../../../../base/components/basic/Link';
import { AppState, byId } from '../../../../base/types';
import { compareObjects, containsLongAndUnbrokenString } from '../../../../base/utils';
import globals from '../../../../modules/globals';
import { getComments as getCommentsSelector, showTranslatedCommentsSelector } from '../../../comments/selectors';
import { Comment } from '../../../comments/types';
import CorrectiveActionsFromCheckpointsWizard, { CorrectiveActionsFromDefectOrCheckpointsType } from '../../../correctiveActions/components/CorrectiveActionsFromCheckpointsWizard';
import { Defect, Inspection } from '../../types';
import DefectsSummary from './defects/DefectsSummary';

const TranslateText = globals.components.TranslateText;

interface OwnProps {
    inspection: Inspection;
    images?: any;
    titles?: any;
    items?: any;
    type?: string;
}
interface StateProps {
    comments: byId<Comment[]>;
    showTranslatedComments: boolean;
}
type CheckpointsProps = OwnProps & StateProps & WrappedComponentProps;

class Defects extends Component<CheckpointsProps, any> {
    constructor(props) {
        super(props);
        this.state = { labels: { major: '', minor: '', critical: '' }, showComments: false, commentsData: [], currentImage: null, settingDefectSeverity: false };
        this.toggleComments = this.toggleComments.bind(this);
        this.setCurrentImage = this.setCurrentImage.bind(this);
    }

    public render(): React.ReactElement {
        const inspection = this.props.inspection;
        const type = this.props.type;
        if (type === 'summary') {
            return (this.getSummary(inspection));
        }
        // return (this.getFullList(inspection));
    }
    public componentDidMount(): void {
        if (this.props.comments) {
            this.setState({ showComments: true, commentsData: this.props.comments, commentsLoading: false });
        } else {
            this.setState({ commentsLoading: true });
        }
    }

    public UNSAFFE_componentWillReceiveProps(nextProps): void {
        if (!compareObjects(this.props.inspection.defect_counts, nextProps.inspection.defect_counts)) {
            this.setState({ settingDefectSeverity: false });
        }
        if (nextProps.comments) {
            this.setState({ showComments: true, commentsData: nextProps.comments, commentsLoading: false });
        }
    }

    private getLabel(level: string): string {
        return this.props.intl.formatMessage({ id: 'defects.' + level });
    }

    /* private getFullList(inspection): React.ReactElement {
        const hasNoDefects = inspection.defects.critical.length === 0 &&
            inspection.defects.major.length === 0 &&
            inspection.defects.minor.length === 0;
        return (<div className='defectsFull'>

            <List className='defects full'>
                {hasNoDefects
                    && <List.Item key='noDefects'>
                        <List.Content>
                            <FormattedMessage id='inspections.no_defects' />
                        </List.Content>
                    </List.Item>
                }
                {
                    [
                        this.getItem(inspection, 'critical'),
                        this.getItem(inspection, 'major'),
                        this.getItem(inspection, 'minor'),
                    ]
                }
            </List>
        </div>);
    } */

    private getThresholdValue(threshold: number): number {
        if (threshold) {
            return threshold > 0 ? threshold - 1 : 0
        }
        else {
            return null
        }
    }

    private getSummary(inspection: Inspection): React.ReactElement {
        let criticalDefects = [];
        let majorDefects = [];
        let minorDefects = [];
        const bypassSamplingProtocol = inspection.bypass_sampling_protocol;
        const canCreateCorrectiveAction = inspection.status === 'report';
        const criticalCount = inspection.defect_counts.critical;
        const majorCount = inspection.defect_counts.major;
        const minorCount = inspection.defect_counts.minor;
        if (inspection.defect_counts.critical > 0) {
            criticalDefects = inspection.defects.critical;
        }
        if (inspection.defect_counts.major > 0) {
            majorDefects = inspection.defects.major;
        }
        if (inspection.defect_counts.minor > 0) {
            minorDefects = inspection.defects.minor;
        }
        const hasCritical = criticalDefects.length > 0;
        const hasMajor = majorDefects.length > 0;
        const hasMinor = minorDefects.length > 0;
        const hasCriticalGeneral = inspection.defects.critical.filter((d) => d.whole_lot).length > 0;
        const hasMajorGeneral = inspection.defects.major.filter((d) => d.whole_lot).length > 0;
        const hasMinorGeneral = inspection.defects.minor.filter((d) => d.whole_lot).length > 0;
        const hasDefects = hasCritical || hasMajor || hasMinor || hasCriticalGeneral || hasMajorGeneral || hasMinorGeneral;
        const minorThreshold = this.getThresholdValue(inspection.minor_defects_threshold)
        const majorThreshold = this.getThresholdValue(inspection.major_defects_threshold)
        const criticalThreshold = this.getThresholdValue(inspection.critical_defects_threshold)
        const defectCategories = [
            {
                name: 'critical',
                caption: this.getLabel('critical'),
                count: criticalCount,
                defects: criticalDefects,
                hasRows: hasCritical,
                max: criticalThreshold,
                totalLineClass: (criticalCount > criticalThreshold) ? ' above_max' : '',
            },
            {
                name: 'major',
                caption: this.getLabel('major'),
                count: majorCount,
                defects: majorDefects,
                hasRows: hasMajor,
                max: majorThreshold,
                totalLineClass: (majorCount > majorThreshold) ? ' above_max' : '',
            },
            {
                name: 'minor',
                caption: this.getLabel('minor'),
                count: minorCount,
                defects: minorDefects,
                hasRows: hasMinor,
                max: minorThreshold,
                totalLineClass: (minorCount > minorThreshold) ? ' above_max' : '',
            },
        ];
        return (
            <div className='pb-8'>
                <Header as='h3'><FormattedMessage id='defects.summary.counts_heading' /></Header>
                <List divided relaxed className='defects-list-vertical'>
                    {defectCategories.map((defectCategory, index: number) => {
                        return (
                            <List.Item key={'defectsSummaryItem_' + index}>
                                <List.Content>
                                    <List.Header><span className={'marker ' + defectCategory.name}></span>{defectCategory.caption}</List.Header>
                                    {defectCategory.hasRows && defectCategory.defects.map((defectFoo: Defect, i) => {
                                        const defect: Defect = Object.assign({}, defectFoo);
                                        const link = '#defect_' + defect.id;
                                        const hasType = defect.type;
                                        const trigger = hasType ? <div key={'defectLabel_' + i} className={twMerge('defectLabel', (defect.type.name && containsLongAndUnbrokenString(defect.type.name, 50)) && 'break-all')}>{defect.type.code}. {defect.type.name}</div> : null;
                                        if (!defectFoo.whole_lot) {
                                            return (
                                                <div className='defects-list-item'>
                                                    <div className='text' key={'defectLabel2_' + i}>
                                                        <Link className='link noUnderline noColor' to={link}>
                                                            {hasType && <span>{this.getDefectPoup(defect, trigger)}</span>}
                                                            {!hasType && <span>{this.getDescription(defect)}</span>}
                                                        </Link>
                                                    </div>
                                                    <div className='affected'><span className='times'>x</span><span className='cnt'>{defect.quantity_affected}</span></div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}

                                    {defectCategory.hasRows && (
                                        <div>
                                            <Divider />
                                            <div className={'defects-list-item total_line' + defectCategory.totalLineClass}>
                                                <div className='text'>{!bypassSamplingProtocol && defectCategory.max != null && <span><FormattedMessage id='report.aql_max' />{defectCategory.max}</span>}</div>
                                                <div className='total'><span className='label'><FormattedMessage id='report.total' /></span><span className='cnt'>{defectCategory.count}</span></div>
                                            </div>
                                        </div>)}
                                    {!defectCategory.hasRows && <span><FormattedMessage id='inspections.no_severity_defects' values={{ severity: <FormattedMessage id={`inspections.${defectCategory.name}`} /> }} /></span>}
                                </List.Content>
                            </List.Item>
                        );
                    })}
                </List>
                <div className='pt-8'><DefectsSummary /></div>
                {hasDefects && canCreateCorrectiveAction && <div className='pt-0'><CorrectiveActionsFromCheckpointsWizard id={inspection.inspection_id} createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Inspection} itemType='defect' /></div>}
            </div>
        );
    }

    /* private getItem(inspection, level): React.ReactElement {
        if (inspection.defects[level]) {
            const defects = inspection.defects[level];
            return (defects.map((defect: ReportDefect, index: number) => {
                return <div key={'defectsItem_' + index}><DefectsItem
                    defect={defect}
                    inspection={inspection}
                    showTranslatedComments={this.props.showTranslatedComments} />
                </div>;
            }));
        }
        return (null);
    } */

    private getDescription(defect: Defect): React.ReactElement {
        return <TranslateText
            showTranslatedComments={this.props.showTranslatedComments}
            translateObj={defect.translated_description}
            originalLanguage={defect.language}
            originalText={defect.description}
            showToggleActions={true}
        />
    }
    private toggleComments(): void {
        const showComments = !this.state.showComments;
        this.setState({ showComments });
    }

    private setCurrentImage(imageUrl: string): void {
        this.setState({ currentImage: imageUrl });
    }

    private getDefectPoup(defect: Defect, trigger: any): React.ReactElement {
        return (
            <Popup
                className='defectTypePopup'
                trigger={<span>{trigger}</span>}>
                <Popup.Content>
                    <Header as='h5'><FormattedMessage id='inspections.defect_type' /></Header>
                    <div>{defect.type.name}</div>
                    <div>{defect.type.code}</div>
                    <div>{defect.type.description}</div>
                </Popup.Content>
            </Popup>
        );
    }

    gotoLink(link): void {
        window.location.href = link;
    }
}


const mapStateToProps = (state: AppState): StateProps => {
    return {
        comments: getCommentsSelector(state),
        showTranslatedComments: showTranslatedCommentsSelector(state),
    };
};

export default connect<StateProps, null, OwnProps>(mapStateToProps)(injectIntl(Defects));
