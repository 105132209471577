/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Attachment
 *
 */
export type Attachment = {
    mimetype?: string;
    name?: string;
    size?: number;
    type?: Attachment.type;
    url?: string;
};

export namespace Attachment {

    export enum type {
        FILE = 'file',
        IMAGE = 'image',
    }


}

