import React from 'react';
import { useIntl } from 'react-intl';
import { TextInput as TextInputType } from '../../../../../backend_api/models/TextInput';
import { TextInputs } from '../../../../../backend_api/models/TextInputs';
import { getLocaleLanguage } from '../../../../../base/utils';
import TextItem from '../../../../globals/components/TextItem';

type TextInputProps = {
    textInputs: TextInputs;
};

const TextInput = (props: TextInputProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const { textInputs } = props;
    return (<div className='pb-4'>
        {textInputs.inputs.map((textInput: TextInputType, i) => {
            return <TextItem labelFixed key={'ti_' + i} label={textInput.name[lang] || textInput.name.C} text={textInput.value || '-'} />
        })}
    </div>);
};

export default TextInput;
