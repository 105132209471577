import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Label, Modal, ModalHeader, Popup, Radio } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { AuditChecklistWithContent } from '../../../../backend_api_2';
import Button from '../../../../base/components/basic/Button';
import { AppState } from '../../../../base/types';
import { TextArea, TextAreaHeight } from '../../../../base/ui/components/TextArea';
import { ListItem, ListItems } from '../../../../base/ui/components/listitems/ListItem';
import { useAppDispatch } from '../../../../store';
import { UserInitialsText } from '../../../users/components/Widgets';
import Watcher from '../../../watchers/components/Watcher';
import { getAuditContentByIdSelector, getAuditCustomConclusionsSelector } from '../../selectors/auditSelectors';
import { submitAudit, updateAuditorComment, updateAuditorConclusion } from '../../slices/auditSlice';
import { isAuditImmutable } from './PerformAuditPage';
import { doesAuditHaveAnyMandatoryCheckpoints } from './mandatory';

export function SummaryAndSubmit(props: {
    checklist: AuditChecklistWithContent,
    notify: () => void
}) {
    const {checklist, notify} = props;
    const intl = useIntl();

    const dispatch = useAppDispatch();
    const auditWithContent = useSelector(getAuditContentByIdSelector);
    const audit = auditWithContent?.audit;
    const isTemporaryUser = !!sessionStorage.getItem('temporary_user_mode');


    const [auditComment, setAuditComment] = React.useState(audit.auditor_comment?.comment);
    const [isEditing, setIsEditing] = React.useState(false);

    const hasApprovers = audit.approval_flow?.levels?.some(l => l.steps.length > 0);
    const conclusions = useSelector((state: AppState) => getAuditCustomConclusionsSelector(state, audit.audit_type?.id));
    const [showConclusionModal, setShowConclusionModal] = React.useState(false);
    const [auditConclusionId, setAuditConclusionId] = React.useState(audit.auditor_conclusion?.id);
    const hasConclusion = !!audit.auditor_conclusion;

    const chosenConclusion = conclusions?.filter(c => c.id == auditConclusionId)[0];

    React.useEffect(() => {
        if(auditWithContent?.audit && !isEditing) {
            setAuditComment(auditWithContent?.audit?.auditor_comment?.comment);
        }
    }, [auditWithContent]);

    function doSubmit() {
        if(!isAuditImmutable(audit)) {
            notify();
            if(!doesAuditHaveAnyMandatoryCheckpoints(checklist)) {
                dispatch(submitAudit({
                    audit_id: audit.id
                }))
            }
        }
    }

    function doUpdateConclusion() {
        if(!isAuditImmutable(audit)) {
            setShowConclusionModal(false);
            dispatch(updateAuditorConclusion({
                audit_id: audit.id,
                conclusion_id: auditConclusionId
            }))
        }
    }

    function handleOnChange(text: string) {
        setAuditComment(text);
        setIsEditing(true);
    }

    function handleOnBlur(text: string) {
        dispatch(updateAuditorComment({
            audit_id: audit.id,
            comment: text
        }))
        setIsEditing(false);
    }

    return (
        <div>
            <div className='flex flex-wrap-reverse sm:flex-row border bg-white py-4 rounded'>
                <div className='flex flex-col w-full sm:w-3/5 sm:py-2 border-r px-4'>
                    {/* <div className='flex flex-row justify-between'>
                        <div className='text-lg font-bold'>Total audit score</div>
                        <div className='flex border py-2 font-bold my-2 rounded w-48 justify-center items-center cursor-pointer'>78%</div>
                    </div> */}
                    {
                        conclusions.length > 0 && !isTemporaryUser &&
                        <div className='flex flex-row justify-between'>
                            <div className='text-lg font-bold'><FormattedMessage id='audits.conclusion.label.conclusion'/></div>
                            <Modal
                                trigger={
                                    chosenConclusion ? 
                                    <div className='flex border py-2 my-2 bg-gray-700 text-white rounded w-48 justify-center items-center cursor-pointer' style={{ backgroundColor: chosenConclusion.background_color, color: chosenConclusion.text_color }}>{chosenConclusion.name.text}</div>
                                    :
                                    <div className='flex border py-2 my-2 bg-gray-700 text-white rounded w-48 justify-center items-center cursor-pointer'><FormattedMessage id='audits.conclusion.set_conclusion'/></div> 
                                }
                                onOpen={() => setShowConclusionModal(true)}
                                onClose={() => setShowConclusionModal(false)}
                                closeIcon={true}
                                open={showConclusionModal}
                            >
                                <ModalHeader>{hasConclusion ? <FormattedMessage id='audits.change_conclusion.header' /> : <FormattedMessage id='audits.set_conclusion.header' />}</ModalHeader>
                                <Modal.Content>
                                    <div className='flex-col flex  space-y-8'>
                                        <div className='flex-col w-full'>
                                            <h4><FormattedMessage id='audits.change_conclusion.label.conclusion' /></h4>
                                            {conclusions.map((conclusion) => {
                                                return <div key={'auditConclusionDialog_' + conclusion.id} className='flex items-center space-x-2 my-3 cursor-pointer' onClick={() => setAuditConclusionId(conclusion.id)}>
                                                    <Radio className='self-start pt-2' checked={auditConclusionId === conclusion.id} />
                                                    <div className='w-2/12 p-2 rounded text-sm justify-center self-start items-baseline flex' style={{ backgroundColor: conclusion.background_color, color: conclusion.text_color }}>{conclusion.name.text}</div>
                                                    <div className='w-10/12 px-2 text-base overflow-auto'>{conclusion.description.text}</div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </Modal.Content>
                                <Modal.Actions className='flex justify-end'>
                                    <Button onClick={() => doUpdateConclusion()} primary><FormattedMessage id='globals.save' /></Button>
                                    <Button onClick={() => setShowConclusionModal(false)}><FormattedMessage id='globals.cancel' /></Button>
                                </Modal.Actions>
                            </Modal >
                        </div>
                    }
                    
                    <div className=''>
                        <div className='flex flex-row justify-between py-1 w-full'>
                            <label className='text-sm text-secondary mb-1' htmlFor="auditor-comment"><FormattedMessage id="web_based_audit.summarizing_comment"/></label>
                        </div>
                        <TextArea value={auditComment} onChange={handleOnChange} onBlur={handleOnBlur} height={TextAreaHeight.DEFAULT_AUTO_EXPAND} immutable={isAuditImmutable(audit)} />
                    </div>
                </div>
                <div className='flex flex-col w-full sm:w-2/5 px-4'>
                    <ListItems className={'border rounded border-b-0'}>                
                        <ListItem
                            icon={'user_watcher'}
                            meta={
                                { metaAbove: { firstItem: intl.formatMessage({id: "inspections.watchers"}) }}
                            }
                        >
                            { audit?.watchers?.length > 0 ?  
                                <div className='flex flex-wrap'>
                                    {audit.watchers.map((watcher) => {
                                        return <Watcher
                                            user={watcher}
                                            disabled={true}
                                            handleRemoveWatcher={() => {}}
                                            key={'watcher_' + watcher.id} />;
                                    })}
                                </div>
                                : 
                                <FormattedMessage id='web_based_audit.no_watchers' /> 
                            }
                        </ListItem>
                        <ListItem
                            icon={'user_user'}
                            meta={
                                { metaAbove: { firstItem: intl.formatMessage({id: "approval.approvers"}) }}
                            }
                        >
                            { hasApprovers ? 
                                <div className='flex flex-wrap'>
                                    {
                                        audit.approval_flow.levels.map(level => {
                                            return <div className='flex flex-wrap'>
                                                {
                                                    level.steps.map(step => {
                                                        const user = step.approver;
                                                        return <Popup
                                                        position='top center'
                                                        trigger={<span className='mr-1'><Label disabled={user.disabled} className='flex items-center ml-0'>
                                                            <span className='flex items-center space-x-2'>
                                                                <span className={twMerge(user.disabled && 'line-through')}>{user.firstname} {user.lastname}</span>
                                                            </span>
                                                        </Label></span>}>
                                                        {user.disabled && <div className='font-bold'><FormattedMessage id='watchers.user_is_disabled' /></div>}
                                                        <div>{<UserInitialsText user={user} />}</div><div>{user.email}</div></Popup>
                                                    })
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                <FormattedMessage id='audits.approval_flow.no_approvers' /> 
                            }
                        </ListItem>
                    </ListItems>
                    <div className='text-gray-500 py-1 text-sm'><FormattedMessage id='web_based_audit.notifications_explanation'/></div>
                </div>
            </div>
            <div className='flex flex-row py-4 w-full justify-between sm:justify-start'>
                <Button className="justify-center text-white" primary large onClick={doSubmit}><FormattedMessage id='web_based_audit.submit_and_end_audit'/></Button>
                {/* <Button className="justify-center bg-white mx-2" large onClick={() => {
                    const token = sessionStorage.getItem('id_token');
                    const reportUrl = document.location.origin.concat(`/q/app?token=${token}&redir=/q/app/audits/report/${audit.id}&output=embed`);
                    window.open(reportUrl, '_blank').focus();
                }}>Preview report</Button> */}
            </div>
        </div>
        
    )
}