import { BasicUser, CompleteApprover, DetailedSupplier, OrderAccessData, ReportCheckpoint } from '../../backend_api/models';
import { ReportCheckpointHeader } from '../../backend_api/models/ReportCheckpointHeader';
import { ReportDefect } from '../../backend_api/models/ReportDefect';
import { ReportLocation as Location } from '../../backend_api/models/ReportLocation';
import { Severity } from '../../backend_api/models/Severity';
import { byId } from '../../base/types';
import { ApprovalFlow, CustomConclusion } from '../approval/types';
import { Checkpoint, CheckpointRemark, CheckpointRemarks, CheckpointWithRemark } from '../checklists/types';
import { Comment } from '../comments/types';
import { CorrectiveActionState } from '../correctiveActions/types';
import { CustomFieldValue, Filter } from '../filters/types';
import { ProductionUnit } from '../suppliers/types';
import { OrderListOrderExtended } from './slices/inspectionsListSlice';



export const EDIT_INSPECTION_DATA_READY = '@@inspections/EDIT_INSPECTION_DATA_READY';
export type EDIT_INSPECTION_DATA_READY = any;
export const EDIT_INSPECTION_CREATED_NEW = '@@inspections/EDIT_INSPECTION_CREATED_NEW';
export type EDIT_INSPECTION_CREATED_NEW = any;

export const EDIT_INSPECTION_CREATED_NEW_CANCEL = '@@inspections/EDIT_INSPECTION_CREATED_NEW_CANCEL';
export type EDIT_INSPECTION_CREATED_NEW_CANCEL = any;
export const EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS = '@@inspections/EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS';
export type EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS = { changedInspections };

export const EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL = 'EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL';
export type EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL = { areEqual };

export const EDIT_INSPECTION_SET_FOCUSSED_INSPECTION = 'EDIT_INSPECTION_SET_FOCUSSED_INSPECTION';
export type EDIT_INSPECTION_SET_FOCUSSED_INSPECTION = { inspectionId };

export const UPDATE_INSPECTION_REQUEST = '@@inspections/UPDATE_INSPECTION_REQUEST';
export const UPDATE_INSPECTION_SUCCESS = '@@inspections/UPDATE_INSPECTION_SUCCESS';
export const UPDATE_INSPECTION_FAILURE = '@@inspections/UPDATE_INSPECTION_FAILURE';

export const UPDATE_SINGLE_INSPECTION_REQUEST = 'UPDATE_SINGLE_INSPECTION_REQUEST';
export type UPDATE_SINGLE_INSPECTION_REQUEST = {
    isSaving: boolean;
    inspectionId: string;
    data: Inspection;
    inspectionData: any;
};

export const UPDATE_SINGLE_INSPECTION_SUCCESS = 'UPDATE_SINGLE_INSPECTION_SUCCESS';
export type UPDATE_SINGLE_INSPECTION_SUCCESS = {
    isSaving: boolean;
    inspectionId: string;
    data: Inspection;
};

export const UPDATE_SINGLE_INSPECTION_FAILURE = 'UPDATE_SINGLE_INSPECTION_FAILURE';

export const REMOVE_PRODUCT_PHOTO_REQUEST = '@@inspections/REMOVE_PRODUCT_PHOTO_REQUEST';

export const DELETE_INSPECTION_REQUEST = 'DELETE_INSPECTION_REQUEST';
export type DELETE_INSPECTION_REQUEST = {
    isSaving;
    orderId;
    inspectionId;
};
export const DELETE_INSPECTION_SUCCESS = 'DELETE_INSPECTION_SUCCESS';
export type DELETE_INSPECTION_SUCCESS = {
    isSaving: boolean;
    orderId: string;
    inspectionId: string;
    data: {
        inspections: Inspection[];
        inspectionsMap: any;
    };
};
export const DELETE_INSPECTION_FAILURE = 'DELETE_INSPECTION_FAILURE';
export type DELETE_INSPECTION_FAILURE = {
    isSaving;
    orderId;
    inspectionId;
    status;
    message;
};

export const DELETE_ORDER_REQUEST = '@@inspections/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = '@@inspections/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = '@@inspections/DELETE_ORDER_FAILURE';

export const UNLOCK_INSPECTION_REQUEST = '@@inspections/UNLOCK_INSPECTION_REQUEST';
export const UNLOCK_INSPECTION_SUCCESS = '@@inspections/UNLOCK_INSPECTION_SUCCESS';
export const UNLOCK_INSPECTION_FAILURE = '@@inspections/UNLOCK_INSPECTION_FAILURE';

export const REASSIGN_LOCKED_INSPECTION_REQUEST = '@@inspections/REASSIGN_LOCKED_INSPECTION_REQUEST';
export const REASSIGN_LOCKED_INSPECTION_SUCCESS = '@@inspections/REASSIGN_LOCKED_INSPECTION_SUCCESS';
export const REASSIGN_LOCKED_INSPECTION_FAILURE = '@@inspections/REASSIGN_LOCKED_INSPECTION_FAILURE';

export const DUPLICATE_INSPECTION_REQUEST = '@@inspections/DUPLICATE_INSPECTION_REQUEST';
export const DUPLICATE_INSPECTION_SUCCESS = '@@inspections/DUPLICATE_INSPECTION_SUCCESS';
export const DUPLICATE_INSPECTION_FAILURE = '@@inspections/DUPLICATE_INSPECTION_FAILURE';

export const CREATE_FOLLOW_UP_TASK_REQUEST = '@@inspections/CREATE_FOLLOW_UP_TASK_REQUEST';
export const CREATE_FOLLOW_UP_TASK_SUCCESS = '@@inspections/CREATE_FOLLOW_UP_TASK_SUCCESS';
export const CREATE_FOLLOW_UP_TASK_FAILURE = '@@inspections/CREATE_FOLLOW_UP_TASK_FAILURE';

export const RESOLVE_FOLLOW_UP_TASK_REQUEST = '@@inspections/RESOLVE_FOLLOW_UP_TASK_REQUEST';
export const RESOLVE_FOLLOW_UP_TASK_SUCCESS = '@@inspections/RESOLVE_FOLLOW_UP_TASK_SUCCESS';
export const RESOLVE_FOLLOW_UP_TASK_FAILURE = '@@inspections/RESOLVE_FOLLOW_UP_TASK_FAILURE';

export const REOPEN_FOLLOW_UP_TASK_REQUEST = '@@inspections/REOPEN_FOLLOW_UP_TASK_REQUEST';
export const REOPEN_FOLLOW_UP_TASK_SUCCESS = '@@inspections/REOPEN_FOLLOW_UP_TASK_SUCCESS';
export const REOPEN_FOLLOW_UP_TASK_FAILURE = '@@inspections/REOPEN_FOLLOW_UP_TASK_FAILURE';

export const REASSIGN_FOLLOW_UP_TASK_USER_REQUEST = '@@inspections/REASSIGN_FOLLOW_UP_TASK_USER_REQUEST';
export const REASSIGN_FOLLOW_UP_TASK_USER_SUCCESS = '@@inspections/REASSIGN_FOLLOW_UP_TASK_USER_SUCCESS';
export const REASSIGN_FOLLOW_UP_TASK_USER_FAILURE = '@@inspections/REASSIGN_FOLLOW_UP_TASK_USER_FAILURE';

export const PLANNING_DATA_REQUEST = '@@inspections/PLANNING_DATA_REQUEST';
export const PLANNING_DATA_SUCCESS = '@@inspections/PLANNING_DATA_SUCCESS';
export const PLANNING_DATA_FAILURE = '@@inspections/PLANNING_DATA_FAILURE';

export const GET_WEEK_VIEW_DATA_REQUEST = '@@inspections/GET_WEEK_VIEW_DATA_REQUEST';
export const GET_WEEK_VIEW_DATA_SUCCESS = '@@inspections/GET_WEEK_VIEW_DATA_SUCCESS';
export const GET_WEEK_VIEW_DATA_FAILURE = '@@inspections/GET_WEEK_VIEW_DATA_FAILURE';
export const SET_WEEK_VIEW_TYPE = '@@inspections/SET_WEEK_VIEW_TYPE';

export const SET_WEEK_VIEW_FILTERS = '@@inspections/SET_WEEK_VIEW_FILTERS';
export type SET_WEEK_VIEW_FILTERS = {
    filters: [];
};

export const SET_INSPECTION_FILTER = '@@inspections/SET_INSPECTION_FILTER';
export const SET_INSPECTION_FILTERS = '@@inspections/SET_INSPECTION_FILTERS';
export const SET_INSPECTION_FILTERS_VISIBILITY = '@@inspections/SET_INSPECTION_FILTERS_VISIBILITY';
export const SET_INSPECTION_HIDDEN_FILTERS = '@@inspections/SET_INSPECTION_HIDDEN_FILTERS';
export const INSPECTION_FILTERS_CLEAR_ALL = '@@inspections/INSPECTION_FILTERS_CLEAR_ALL';

export const CREATE_COMBINED_ORDER_REQUEST = '@@inspections/CREATE_COMBINED_ORDER_REQUEST';
export const CREATE_COMBINED_ORDER_SUCCESS = '@@inspections/CREATE_COMBINED_ORDER_SUCCESS';
export const CREATE_COMBINED_ORDER_FAILURE = '@@inspections/CREATE_COMBINED_ORDER_FAILURE';

export const SUPPLIERS_IS_FETCHING_REQUEST = '@@inspections/SUPPLIERS_IS_FETCHING_REQUEST';
export const SUPPLIERS_SUCCESS = '@@inspections/SUPPLIERS_SUCCESS';
export const SET_FAVORITE_INSPECTORS = '@@inspections/SET_FAVORITE_INSPECTORS';

export const SET_FAVORITE_INSPECTORS_MANUALLY = '@@inspections/SET_FAVORITE_INSPECTORS_MANUALLY';

export const STORE_LIST_VIEW_SCROLL_POSITION = '@@inspections/STORE_LIST_VIEW_SCROLL_POSITION';

export const SET_INSPECTIONS_BASE_URL = '@@inspections/SET_INSPECTIONS_BASE_URL';

export const ORDER_AND_INSPECTIONS_REQUEST = '@@inspections/ORDER_AND_INSPECTIONS_REQUEST';
export type ORDER_AND_INSPECTIONS_REQUEST = {
    isFetching: boolean;
};

export const ORDER_AND_INSPECTIONS_REQUEST_SUCCESS = '@@inspections/ORDER_AND_INSPECTIONS_REQUEST_SUCCESS';
export type ORDER_AND_INSPECTIONS_REQUEST_SUCCESS = {
    isFetching: boolean;
    order: Order;
};

export const ORDER_AND_INSPECTIONS_REQUEST_FAILURE = '@@inspections/ORDER_AND_INSPECTIONS_REQUEST_FAILURE';
export type ORDER_AND_INSPECTIONS_REQUEST_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

export const UPDATE_ORDER_AND_INSPECTIONS_REQUEST = 'UPDATE_ORDER_AND_INSPECTIONS_REQUEST';
export type UPDATE_ORDER_AND_INSPECTIONS_REQUEST = {
    isSaving: boolean;
};

export const UPDATE_ORDER_AND_INSPECTIONS_SUCCESS = 'UPDATE_ORDER_AND_INSPECTIONS_SUCCESS';
export type UPDATE_ORDER_AND_INSPECTIONS_SUCCESS = {
    isSaving: boolean;
    data: any;
};

export const UPDATE_ORDER_AND_INSPECTIONS_FAILURE = 'UPDATE_ORDER_AND_INSPECTIONS_FAILURE';
export type UPDATE_ORDER_AND_INSPECTIONS_FAILURE = {
    isSaving: boolean;
    message: string;
    status: number;
};

export const CLEAR_ORDER_AND_INSPECTIONS_DATA = '@@inspections/CLEAR_ORDER_AND_INSPECTIONS_DATA';
export type CLEAR_ORDER_AND_INSPECTIONS_DATA = any;

export const GET_INSPECTIONS_TYPES = 'GET_INSPECTIONS_TYPES';

export const SET_SAME_ON_ALL_INSPECTIONS = '@@editInspection/SET_SAME_ON_ALL_INSPECTIONS';
export type SET_SAME_ON_ALL_INSPECTIONS = {
    isSame: boolean;
    permissions?: any;
    order?: Order;
    inspectionsMap?: any;
};

export const CLEAR_REPORT_IMAGES = 'CLEAR_REPORT_IMAGES';
export type CLEAR_REPORT_IMAGES = any;

export type InspectionStatusType = 'planned' | 'ongoing' | 'report';

export type Inspection = {
    involved_in_split_shipment: boolean;
    has_approval_flow: boolean;
    order_id?: string;
    order_line_id?: string;
    attachment_count?: number;
    sample_quantity?: number;
    calculated_sample_quantity?: number;
    conclusion?: CustomConclusion;
    conclusion_id?: string;
    geographic_location?: string;
    item_name?: string;
    inspector_conclusion?: CustomConclusion;
    item_number?: string;
    critical_defects_threshold?: number;
    inspection_end?: string;
    approval_flow?: ApprovalFlow;
    checklist_name?: string;
    scheduled_inspection_date?: Date;
    order_number?: string;
    assigned_user: BasicUser | null;
    total_quantity?: number;
    original_total_quantity?: number;
    status?: InspectionStatusType;
    supplier_entity?: DetailedSupplier;
    linked_production_unit?: ProductionUnit;
    production_unit?: ProductionUnit;
    etd?: Date;
    custom_field_values?: CustomFieldValue[];
    inspection_id?: string;
    checklist_id?: string;
    defect_counts?: DefectCounts;
    defects?: {
        minor: ReportDefect[];
        major: ReportDefect[];
        critical: ReportDefect[];
    };
    checkpoints?: Checkpoint[];
    checkpoint_headers?: ReportCheckpointHeader[];
    major_defects_threshold?: number;
    minor_defects_threshold?: number;
    inspected_quantity?: number;
    packed_quantity?: number;
    locked?: boolean;
    assigned_user_id?: string;
    original_sampling_protocol_id?: string;
    original_sampling_protocol?: {
        name?: string;
        id?: string;
        description?: string;
    };
    sampling_protocol_id?: string;
    sampling_protocol?: {
        name?: string;
        id?: string;
        description?: string;
    };
    inspection_start?: string;
    bypass_sampling_protocol?: boolean;
    checklist?: {
        updated_by: {
            lastname: string;
            id: string;
            firstname: string;
            email: string;
        };
        updated_at: string;
        id: string;
        description: string;
    };
    checklist_revision?: number;
    checklist_revision_timestamp?: string;
    watchers?: BasicUser[];
    last_tracked_location?: Location;
    item_image?: string;
    inspector_comment?: Comment;
    reinspection_based_on?: {
        inspection_id: string;
        count: number;
        status: string;
        order_id: string;
    };
    reinspected_by?: Array<{
        inspection_id: string;
        count: number;
        status: string;
        order_id: string;
    }>;
    seen?: boolean;
    seen_by_user?: boolean;
    master_inspection?: {
        order_id?: string;
        id?: string;
        checklist_id?: string;
    };
    is_master?: boolean;
    sub_inspections?: Inspection[
    ];
    inspection_type?: InspectionType;
    last_confirmed_at?: string;
    booking_date?: string;
    booking_note?: string;
    confirmed?: boolean;
    overdue?: boolean;
    must_confirm?: boolean;
    supplier_qc?: boolean;
    destination_split_shipments?: [
        {
            user_id: string;
            source_inspection_id: string;
            original_total_quantity: number;
            id: string;
            destination_inspection_id: string;
            destination_inspection_status: string;
            source_inspection_status: string;
        },
    ];
    source_split_shipments?: [
        {
            user_id: string;
            source_inspection_id: string;
            original_total_quantity: number;
            id: string;
            destination_inspection_id: string;
            destination_inspection_status: string;
            source_inspection_status: string;
        },
    ];
    readonly?: InspectionReadOnlyTypes[];
    features?: InspectionFeatureTypes[];
    follow_up_tasks: FollowUpTask[];
    supplier?: DetailedSupplier;
    combined?: boolean; // Found on week view inspection data
    tag?: any;
    location_country: string;
    sequence_identifier: string[];
    id; // For booking inspection;
    split_shipment_allowed: boolean;
    _inspection_group_id?: string;
};

export type DefectCounts = {
    minor?: number;
    major?: number;
    critical?: number;
};

export type Dispatch = {
    action: string;
};

export type Order = {
    sampling_protocol?: {
        name: string;
        id: string;
        description: string;
    };
    order_number?: string;
    order_id?: string;
    inspectionsMap?: byId<Inspection>;
    inspections?: Inspection[];
    inspectionsGrouped?: any;
    inspectionsByTypeId?: { [typeId: string]: Inspection[] };
    inspectionsNoTypes?: Inspection[];
    statusInspections?: Inspection[];
    groups?: Group[];
    all_approval_flows_equal?: boolean;
    seen?: boolean;
    features?: OrderFeatureTypes[];
    readonly?: OrderReadOnlyTypes[];
    cnt?: number;
};

export type FollowUpTask = {
    id?: string;
    title?: string;
    description?: string;
    status?: string;
    assigned_user?: BasicUser;
};

export type WeekViewType = {
    type: 'normal' | 'one-order' | 'minimal';
};

export type InspectionType = {
    tag?: string;
    sequence?: number;
    name?: string;
    id?: string;
};

export type Defect = {
    type?: DefectType;
    severity: DefectSeverities;
    quantity_affected: number;
    whole_lot: boolean;
    photos: string[];
    id: string;
    description: string;
    language: string;
    translated_description?: { [lang: string]: string };
};

export type DefectType = {
    name?: string;
    code?: string;
    id?: string;
    description?: string;
};

export type DefectSeverity = {
    minor?: number;
    major?: number;
    critical?: number;
};

export type Group = {
    name?: string;
    id?: string;
    is_supplier_group?: boolean;
};

export type DefectSeverities = 'minor' | 'major' | 'critical';

export type ConclusionTypes = 'approved' | 'rejected' | 'pending' | 'not_set';

export type InspectionTypeViews = 'switcher' | 'dashboard' | 'report' | 'weekView' | 'search' | 'editInspection' | 'compactWeekView';

export type Report = {
    order?: Order;
    inspection?: Inspection;
    checkpoints: ReportCheckpoint[];
    defectsSummary: any;
    checkpointsRemarks: CheckpointRemark[];
};

export type ReportMatrix = {
    inspections: Inspection[];
    inspection_type: { InspectionType };
    inspectionsMap: { ['inspectionId']: Inspection };
    checkpoints: Checkpoint[];
    matrices: any; // TODOO: add proper Matrice type
}[];

export type ReportState = {
    order: Order;
    inspection: Inspection;
    checkpoints: ReportCheckpoint[];
    defectsSummary: any;
    orderMatrix: any;
    checkpointsRemarks: CheckpointRemarks;
    checkpointWithRemarksIds: CheckpointWithRemark[];

};

export type ReportDataState = {
    report: {
        data: ReportState;
        allInspectionsCount: number;
        imagesItems: ImageItems;
        isFetching: boolean;
        inspectionId: string;
        restrictToTypes: string[];
        dontScrollToTop: boolean;
    };
    settingDefectSeverity: boolean;
    changingBulkStatus: boolean;
    orderMatrix: ReportMatrix;
};

export type ImageItems = {
    items: ImageItem[];
    images: string[];
};

export const reportState: ReportDataState = {
    report: {
        data: {
            order: undefined,
            inspection: undefined,
            checkpoints: [],
            defectsSummary: undefined,
            checkpointsRemarks: undefined,
            checkpointWithRemarksIds: [],
            orderMatrix: undefined,
        },
        allInspectionsCount: 0,
        imagesItems: {
            items: [],
            images: [],
        },
        isFetching: false,
        inspectionId: undefined,
        restrictToTypes: [],
        dontScrollToTop: false,
    },
    settingDefectSeverity: false,
    changingBulkStatus: false,
    orderMatrix: [{
        inspections: [],
        checkpoints: [],
        inspectionsMap: undefined,
        inspection_type: undefined,
        matrices: undefined,
    }],

};


export type OrderFeatureTypes =
    'combine_split' |
    'order_change_sampling_protocol' |
    'manage_attachments' |
    'manage_groups' |
    'order_manage_supplier' |
    'order_change_assignment_data' |
    'order_change_booking_data' |
    'delete';

export type InspectionFeatureTypes =
    'delete' |
    'list' |
    'search' |
    'perform_inspection' |
    'read_report_data' |
    'change_master_data' |
    'change_booking_data' |
    'change_assignment_data' |
    'change_checklist_data' |
    'change_production_unit_data' |
    'change_sampling_protocol' |
    'create' |
    'modify_custom_fields' |
    'create_comments' |
    'list_comments' |
    'change_conclusion' |
    'manage_watchers' |
    'setup_approval_flow' |
    'manage_attachments' |
    'handle_follow_up_tasks' |
    'delegated_approval_step' |
    'reset_inspection' |
    'unlock' |
    'split_planned_shipment'
    |
    'corrective_action_for_quality.create';

export type OrderReadOnlyTypes =
    'order_number' |
    'order_change_assignment_data' |
    'etd' |
    'groups';

export type InspectionReadOnlyTypes =
    'booking_date' |
    'booking_update_at' |
    'booking_note' |
    'item_name' |
    'item_number' |
    'item_image' |
    'inspection_type' |
    'inspection_type_id' |
    'custom_field_values' |
    'supplier_id' |
    'supplier_entity' |
    'approval_steps' |
    'checklist' |
    'checklist_id' |
    'checklist_name' |
    'assigned_user_id' |
    'scheduled_inspection_date' |
    'inspector_conclusion' |
    'total_quantity' |
    'sample_quantity' |
    'packed_quantity' |
    'conclusion' |
    'sampling_protocol' |
    'sampling_protocol_id' |
    'bypass_sampling_protocol' |
    'production_unit' |
    'production_unit_id' |
    'linked_production_unit' |
    'supplier_qc' |
    'change_production_unit_data';

export type ImageItem = {
    type: string;
    description: string;
    severity?: Severity;
    imageUrl?: string;
    imageComment?: any;
    quantity_affected?: number;
    id?: string;
    whole_lot?: any;
    translated_comment2?: any;
    defectType?: DefectType;
    defect?: ReportDefect;
    isEvidence?: boolean;
    evidenceImage?: {url: string, ca_status: CorrectiveActionState}
};

export type BulkApprovalFlow = {
    merged_approval_flow: BulkApprovalStep[][];
};

export interface BulkApprovalStep extends CompleteApprover {
    ids?: string[];
}

export type ApproveBulkStepsRequest = {
    approval_step_ids: string[];
    conclusion: CustomConclusion;
    comment: string;
};

export type ApproveBulkInspectionRequest = {
    inspection_ids: string[];
    conclusion: CustomConclusion;
    comment: string;
};

export type InspectionsListMeta = {
    per_page: number;
    start: number;
    total: number;
    isFetching: boolean;
};

export type InspectionsList = {
    per_page: number;
    returned_for_page: number;
    start: number;
    total: number;
    elements: (OrderListOrderExtended | Order)[];
    error: boolean;
};

// inspectionsMap: { ['inspectionId']: Inspection };
export type InspectionsListState = {
    [id: string]: InspectionsList &
    
    {
        accessData: byId<OrderAccessData>;
        status: InspectionStatusType | string;
        filters: Filter;
        requestId: string;
        isFetching: boolean;
        showCombineCollector: boolean;
        defaultDate: string;
        scrollPosition: number;
        inspectionTypeSelectedForCombine: InspectionType;
        setInspectionTypeForCombine(type: string): void;
        addInspectionForCombine(inspection: Inspection): void;
        setShowCombineCollector(inspection: Inspection): void;
        removeInspectionForCombine(inspection: Inspection): void;
        setListViewDefaultDate(inspection: Inspection): void;
        inspectionsForCombine: Inspection[];

    }
};