import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { ProductionUnit } from '../../../backend_api_2';
import { LIMITED_SELECTOR_ITEMS_COUNT } from '../../../base/config';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { convertProductionUnitsToOptions } from '../../productionUnits/components/ControlledProductionUnitSelector';
import { getListableProductionUnits, getListableProductionUnitsSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type ProductionUnitFilterProps = {
    selectedProductionUnitIds?: string[];
    filterName?: string;
    disabled?: boolean;
    search?: boolean;
};

export default function ProductionUnitFilter(props: ProductionUnitFilterProps): React.ReactElement {
    const { search = true, disabled = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const filterName = props.filterName || 'production_unit_id';
    const selectedSuppliers = getLocationEntry(location, filterName);

    useEffect(() => {
        dispatch(getListableProductionUnits({}));
    }, [])

    const productionUnits: ProductionUnit[] = useSelector(getListableProductionUnitsSelector);

    const options = React.useMemo(() => {
        return productionUnits && convertProductionUnitsToOptions(productionUnits);
    }, [productionUnits])

    return <Filter labelId='filters.production_units.label_production_units' >
        <DropdownFilter
            limitItemsCount={LIMITED_SELECTOR_ITEMS_COUNT}
            name='production_units'
            options={options}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.production_units.select_production_unit' })}
            selectedItems={selectedSuppliers || []}
            handleSave={(items): void => {
                dispatch(setFilter(filterName, items));
            }}
            inline={false}
            showLoader={true}
            disabled={disabled}
            search={search}

        /></Filter >;
}