/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileFileResource } from './FileFileResource';
import type { ImageFileResource } from './ImageFileResource';
import type { TextWithTranslation } from './TextWithTranslation';
import type { UUID } from './UUID';
import type { VideoFileResource } from './VideoFileResource';

/**
 * PEDING
 */
export type FileResource = {
    description?: TextWithTranslation | null;
    file?: FileFileResource | null;
    id?: UUID;
    image?: ImageFileResource | null;
    name?: string;
    preview_url?: string | null;
    type: FileResource.type;
    video?: VideoFileResource | null;
};

export namespace FileResource {

    export enum type {
        FILE = 'file',
        IMAGE = 'image',
        VIDEO = 'video',
    }


}

