import { handleActions } from 'redux-actions';
import { ADMIN_USERS_GET_GROUPS } from '../users/actions/actionTypes';
import { Action } from './../../base/genericActions';
import * as types from './actions/actionTypes';
import { GroupsState } from './types';

const initialState: GroupsState = {
    isFetching: false,
    error: '',
    groups: [],
};

export const reducer = handleActions({
    [types.IS_FETCHING]: (state: GroupsState = initialState, action: Action<types.IS_FETCHING>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: '',
        });
    },
    [ADMIN_USERS_GET_GROUPS]: (state: GroupsState = initialState, action: Action<ADMIN_USERS_GET_GROUPS>) => {
        return Object.assign({}, state, {
            groups: action.payload.groups,
        });
    },
    [types.GROUPS_REQUEST_SUCCESS]: (state: GroupsState = initialState, action: Action<types.GROUPS_REQUEST_SUCCESS>) => {
        return Object.assign({}, state, {
            groups: action.payload.groups,
            isFetching: action.payload.isFetching,
            error: '',
        });
    },
    [types.GROUPS_REQUEST_FAILURE]: (state: GroupsState = initialState, action: Action<types.GROUPS_REQUEST_FAILURE>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: action.payload.error.errorText,
        });
    },
    [types.GROUPS_CREATE_FAILURE]: (state: GroupsState = initialState, action: Action<types.GROUPS_CREATE_FAILURE>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: action.payload.error.errorText,
        });
    },
}, initialState);

export default reducer;
