import classnames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import { LineTogglerSmall } from '../../../../globals/components/Utils';
import { Order } from '../../../types';
import OrderInspectionsList from '../../dashboard/views/inspectionsList/OrderInspectionsList';

interface ReportTogglerProps {
    order: Order;
    show: boolean;
    toggle(show: boolean): void;
    selectedInspectionId?: string;
}

const ReportToggler = (props: ReportTogglerProps): React.ReactElement => {
    const { order, show, selectedInspectionId, toggle } = props;
    const cn = classnames('reportToggler', {
        open: show,
    });
    const cnt = order.inspections.length;
    const label = order.order_number;
    const toggler = <div className={cn}>
        <div className='inner'>
            <LineTogglerSmall
                data={{ open: show }}
                toggle={toggle}
                id={'report_toogler'}
                label={<span><span className='cnt'>{cnt} </span>{label}</span>}
            />
        </div>
    </div>
    if (show) {
        return <Fragment><Segment.Group raised className='reportSwitcher'>
            <Segment secondary>
                <Header as='h3'><FormattedMessage id='report.order_list.header' /></Header>
            </Segment>
            <Segment>
                <OrderInspectionsList
                    isGrouped={false}
                    order={order}
                    showDefects={false}
                    showInspectionTypes={true}
                    selectedInspectionId={selectedInspectionId} />
            </Segment>
        </Segment.Group>
            {toggler}
        </Fragment>
    }
    return cnt > 0 ? toggler : null;
}

export default ReportToggler;
