import React, { Fragment, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Dropdown, DropdownProps, Icon, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { AppState } from '../../../base/types';
import { arrayDiffLarge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getGroups } from '../../users/actions/actions';
import { getGroupsSelectorSelector } from '../selectors';
import { GroupData, GroupOption } from '../types';

interface GroupsSelectorProps {
    groups?: GroupOption[];
    hasSelection: boolean;
    hasCancel?: boolean;
    showSelector: boolean;
    selectedIds?: string[];
    className?: string;
    disabled?: boolean;
    handleCancel?(): void;
    handleChange?(evt: SyntheticEvent, { value }: { value: string[] }): void;
    handleSave(concat: boolean, groupIds?: string[]): void;
}

/** Consider using one of the other two selectors:
 *  - ControlledDelayedGroupsSelector
 *  - ControlledImmediateGroupsSelector
 * 
 * This selector can have its groups controlled from the outside, or not be controlled and get its data from
 * a selector.
 * 
 * Immediate and delayed behaviour are mixed
 */
export function GroupsSelector(props: GroupsSelectorProps) {
    const { hasSelection, disabled = false, hasCancel = true, className } = props;
    const [selectedGroups, setSelectedGroups] = useState(props.selectedIds || []);
    const dispatch = useAppDispatch();
    const groups =
        props.groups
            ? props.groups
            : useSelector((state: AppState) => getGroupsSelectorSelector(state, false));
    const intl = useIntl();

    function onChange(e, item: DropdownProps) {
        if (props.handleChange) {
            props.handleChange(e, { value: item.value as string[] })
        }
        setSelectedGroups(item.value as string[]);
    }

    function onCancel() {
        setSelectedGroups([]);
    }

    function onSave() {
        props.handleSave(true, selectedGroups);
        onCancel();
    }

    useEffect(() => {
        if (!props.groups) {
            dispatch(getGroups());
        }
    }, []);

    if (!props.showSelector) {
        return <Fragment />;
    }

    return (
        <Fragment>
            <div className={twMerge('groupsSelector', className)}>
                <Dropdown
                    value={selectedGroups}
                    name='groups'
                    closeOnBlur
                    className='groupSelector'
                    placeholder={
                        ((hasSelection === false && (selectedGroups.length === 0)))
                            ? intl.formatMessage({ id: 'groups.select_groups' })
                            : null
                    }
                    multiple
                    disabled={disabled}
                    search
                    selection
                    fluid
                    options={groups}
                    onChange={onChange}
                    data-test-id={'dropdown-select-group'}
                />
                {hasCancel && renderCancelButton(() => {
                    onCancel();
                    if (props.handleCancel) {
                        props.handleCancel()
                    }
                })}
                {hasSelection && renderDoneButton(disabled, onSave)}
            </div>
        </Fragment>
    );
}

/** 
 * This Groups Selector is controlled and delayed
 * Controlled: 
 *  The selected groups are controlled by the calling component
 *  The available groups are controlled by the calling component
 * 
 * Delayed:
 *  The parent component will only receive a onSelectingGroupsDone callback, which 
 *  includes all the group ids that were set by the user. Deselected groups will
 *  simply not be present in the list of group ids.
 */
export function ControlledDelayedGroupsSelector(props: {
    disabled?: boolean;
    selectableGroups: GroupData[];
    selectedGroupIds: string[];
    className?: string;
    onSelectingGroupsDone(groupIds: string[]): void;
    onSelectingGroupsCanceled?(): void;
}) {
    const intl = useIntl()

    const [localSelection, setLocalSelection] = useState<string[]>(props.selectedGroupIds || []);

    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        setLocalSelection(props.selectedGroupIds);
    }, [props.selectedGroupIds])

    const options =
        useMemo(function () {
            return groupsToOption(props.selectableGroups)
        }, [props.selectableGroups]);

    function onChange(_e: React.SyntheticEvent, item: DropdownProps) {
        setShowButtons(true);
        setLocalSelection(item.value as string[]);
    }

    function onCancel() {
        setShowButtons(false)
        setLocalSelection(props.selectedGroupIds);
        props.onSelectingGroupsCanceled && props.onSelectingGroupsCanceled()
    }

    function onSave() {
        setShowButtons(false)
        props.onSelectingGroupsDone(localSelection);
    }

    return (
        <Fragment>
            <div className={twMerge('groupsSelector', props.className)}>
                <Dropdown
                    value={localSelection}
                    onFocus={() => setShowButtons(true)}
                    name='groups'
                    closeOnBlur
                    className='groupSelector'
                    placeholder={renderPlaceholder(localSelection, intl)}
                    multiple
                    disabled={props.disabled}
                    search
                    selection
                    options={options}
                    onChange={onChange}
                    data-test-id={'dropdown-select-group'}
                />
                {showButtons && renderCancelButton(onCancel)}
                {showButtons && renderDoneButton(props.disabled, onSave)}
            </div>
        </Fragment>
    );
}

/**
 * This Groups selector is controlled and immediate:
 * Controlled: 
 *  The selected groups are controlled by the calling component
 *  The available groups are controlled by the calling component
 * 
 * Immediate:
 *  Whenever the user adds or removes a group, the callbacks are called immediately.
 * 
 */
export function ControlledImmediateGroupsSelector(props: {
    selectableGroups: GroupData[];
    selectedGroupIds?: string[];
    className?: string;
    // disabled?: boolean;
    onGroupAdded(groupId: string): void,
    onGroupRemoved(groupId: string): void
}) {
    const intl = useIntl();

    const options =
        useMemo(function () {
            return groupsToOption(props.selectableGroups)
        }, [props.selectableGroups]);

    function onChange(e, item: DropdownProps) {
        const diff = arrayDiffLarge(props.selectedGroupIds, item.value as string[]);
        diff.addedKeys.forEach((k) => props.onGroupAdded(k));
        diff.removedKeys.forEach((k) => props.onGroupRemoved(k));
    }

    return (
        <Fragment>
            <div className={twMerge('groupsSelector', props.className)}>
                <Dropdown
                    value={props.selectedGroupIds}
                    name='groups'
                    closeOnBlur
                    className='groupSelector'
                    placeholder={renderPlaceholder(props.selectedGroupIds, intl)}
                    multiple
                    // disabled={props.disabled}
                    search
                    selection
                    options={options}
                    onChange={onChange}
                    data-test-id={'dropdown-select-group'}
                />
            </div>
        </Fragment>
    );
}


export function groupsToOption(groups: GroupData[]): GroupOption[] {
    return groups.map((group: GroupData) => {

        const content = (
            <div className='flex justify-between'>
                <span>{group.name}</span>
                {groupSupplierIndicator(group)}
            </div>
        )

        const option = {
            text: group.name,
            key: group.id,
            value: group.id,
            is_supplier_group: group.is_supplier_group,
            content: content
        }

        return option;
    })

}

function groupSupplierIndicator(group: GroupData) {
    if (!group.is_supplier_group) {
        return null;
    }

    const trigger = <Icon className='text-default-widget-color' name='factory' />

    return (
        <Popup position={'top right'} trigger={trigger}>
            <FormattedMessage id='users.user_is_supplier_group' />
        </Popup>
    )
}

function renderCancelButton(onCancel: () => void) {
    return (
        <Button
            size='mini'
            className='cancel'
            onClick={onCancel}>
            <FormattedMessage id='groups.cancel' />
        </Button>
    )
}

function renderDoneButton(disabled: boolean, onSave: () => void) {
    return (
        <Button size='mini'
            positive
            className='done'
            data-test-id='btn-groups-done'
            onClick={disabled ? undefined : onSave}>
            <FormattedMessage id='groups.done' />
        </Button>
    )
}

function renderPlaceholder(selectedGroupIds: string[], intl) {
    if (selectedGroupIds.length > 0) {
        return undefined
    }
    return intl.formatMessage({ id: 'groups.select_groups' })
}