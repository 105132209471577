export const ADD_WATCHER_TO_INSPECTION = 'ADD_WATCHER_TO_INSPECTION';
export type ADD_WATCHER_TO_INSPECTION = {
    isFetching: boolean;
};

export const ADD_WATCHER_TO_INSPECTION_SUCCESS = 'ADD_WATCHER_TO_INSPECTION_SUCCESS';
export type ADD_WATCHER_TO_INSPECTION_SUCCESS = {
    isFetching: boolean;
    data: any;
};

export const ADD_WATCHER_TO_INSPECTION_FAILURE = 'ADD_WATCHER_TO_INSPECTION_FAILURE';
export type ADD_WATCHER_TO_INSPECTION_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};
export const REMOVE_WATCHER_FROM_INSPECTION = 'REMOVE_WATCHER_FROM_INSPECTION';
export type REMOVE_WATCHER_FROM_INSPECTION = {
    isFetching: boolean;
};

export const REMOVE_WATCHER_FROM_INSPECTION_SUCCESS = 'REMOVE_WATCHER_FROM_INSPECTION_SUCCESS';
export type REMOVE_WATCHER_FROM_INSPECTION_SUCCESS = {
    isFetching: boolean;
    data: any;
};

export const REMOVE_WATCHER_FROM_INSPECTION_FAILURE = 'REMOVE_WATCHER_FROM_INSPECTION_FAILURE';
export type REMOVE_WATCHER_FROM_INSPECTION_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};
