import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Grid, Modal } from 'semantic-ui-react';
import BaseComponent from '../../../../../base/components/BaseComponent';
import { splitCombinedOrder } from '../../../actions/combineActions';
import { deleteOrder } from '../../../actions/deleteInspection';
import { Inspection, Order } from '../../../types';
import { getCombinedInspectionsInspectionId, orderOnlyHasPlannedInspections } from '../../../Utils';
import DeleteCombinedInspection from './DeleteCombinedInspection';
import DeleteOrder from './DeleteOrder';

interface OwnProps {
    isCombinedInspection: boolean;
    order: Order;
    inspection?: Inspection;
    disabled?: boolean;
}

interface DispatchProps {
    actions: {
        splitCombinedOrder(combinedInspectionId: string): void;
        deleteOrder(orderId: string): void;
    };
}
const initialState = {
    showModal: false,
    conclusion: null,
    reason: '',
    showConfirm: false,
};
type State = Readonly<typeof initialState>;
type DeleteProps = OwnProps & DispatchProps;
class Delete extends BaseComponent<DeleteProps, State> {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleOpen = this.handleOpen.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
    }

    public doRender(): React.ReactElement {
        const isCombined = this.props.isCombinedInspection;
        let onlyHasPlanned;
        if (this.props.order.inspections.length > 0) {
            if (isCombined) {
                onlyHasPlanned = orderOnlyHasPlannedInspections(this.props.order.inspections[0].sub_inspections, true);
            } else {
                onlyHasPlanned = orderOnlyHasPlannedInspections(this.props.order.inspections, true);
            }
        }
        const buttonTextId = isCombined ? 'inspections.split_inspection' : 'inspections.delete_order';
        return (
            <span>
                <Button disabled={this.props.disabled} className="inverted-color" onClick={this.handleOpen}><FormattedMessage id={buttonTextId} /></Button>
                <Modal
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    closeIcon={true}
                    size='small'
                >
                    <Modal.Content>
                        <Grid columns={1}>
                            {isCombined && <Grid.Column>

                                <DeleteCombinedInspection
                                    onlyHasPlanned={onlyHasPlanned}
                                    showConfirm={this.state.showConfirm}
                                    handleConfirm={this.handleConfirm}
                                    deleteOrder={this.deleteOrder}
                                    handleClose={this.handleClose}
                                />

                            </Grid.Column>}
                            {!isCombined && <Grid.Column>

                                <DeleteOrder
                                    onlyHasPlanned={true}
                                    showConfirm={this.state.showConfirm}
                                    handleConfirm={this.handleConfirm}
                                    deleteOrder={this.deleteOrder}
                                    handleClose={this.handleClose}
                                />

                            </Grid.Column>}
                        </Grid>
                        {this.state.showConfirm && <Modal>
                            <Modal.Content>
                                <FormattedMessage id='inspections.ok_to_delete' />
                            </Modal.Content>
                        </Modal>}
                    </Modal.Content>
                </Modal>
            </span >
        );

    }

    private handleOpen(): void {
        this.setState({ showModal: true });
    }

    private handleConfirm(): void {
        this.setState({ showConfirm: true });
    }

    private handleCancel(): void {
        this.setState({ showModal: false, reason: null });
    }

    private handleClose(): void {
        this.setState({ showConfirm: false, showModal: false, reason: null });
    }

    private deleteOrder(): void {
        if (this.props.isCombinedInspection) {
            const CIInspectionId = getCombinedInspectionsInspectionId(this.props.order);
            this.props.actions.splitCombinedOrder(CIInspectionId);
        } else {
            this.props.actions.deleteOrder(this.props.order.order_id);
        }
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            splitCombinedOrder,
            deleteOrder,
        }, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(Delete);
