import React from 'react';
import { useIntl } from 'react-intl';
import { CorrectiveActionEvent } from '../../../backend_api/models/CorrectiveActionEvent';
import ApproveRejectResolveStatus from './ApproveRejectResolveStatus';
import NotifiedEvent from './NotifiedEvent';

type Props = {
    event: CorrectiveActionEvent;
}
const ResolveEventDetails = (props: Props) => {
    const intl = useIntl()
    return <div className='flex flex-col space-y-2'>
        <div className='flex'><ApproveRejectResolveStatus positive={true} labelText={intl.formatMessage({ id: 'corrective_actions.events.status.resolve' })} /></div>
        <NotifiedEvent event={props.event} />
    </div>
}

export default ResolveEventDetails;