import { InspectionDetails } from '../../../backend_api/models/InspectionDetails';
import { Supplier } from '../../../backend_api/models/Supplier';
import { Action } from '../../../base/genericActions';
import { byId } from '../../../base/types';
import { GET_ATTACHMENTS_SUCCESS } from '../../attachments/constants';
import { GET_CHECKLIST_CONTENT, GET_CHECKLIST_CONTENT_SUCCESS } from '../../checklists/actionTypes';
import { Filter } from '../../filters/types';
import {
    CREATE_ORDER_AND_INSPECTIONS_SUCCESS,
    INSPECTIONS_ADD_ATTACHMENT_PROGRESS,
    INSPECTIONS_GET_ATTACHMENTS_SUCCES,
    ORDER_ADD_ATTACHMENT_PROGRESS,
    ORDER_GET_ATTACHMENTS_SUCCES, SET_LAST_VIEWED_INSPECTION_PAGE,
    UPDATE_ORDER_FROM_RESPONSE
} from '../actionTypes';
import { INDEX_ORDER_INSPECTIONS, UPDATE_INSPECTION_REQUEST, UPDATE_INSPECTION_SUCCESS } from '../actionTypes/addInspectionActionTypes';
import * as types from '../types';
import { InspectionType } from '../types';

export type InspectionsState = {
    isFetching?: boolean;
    isSaving?: boolean;
    attachments?: any;
    inspectionAdded?: boolean;
    lastViewedInspectionPage?: string;
    filters?: Filter[];
    suppliers: { loading: boolean; data: Supplier[]; loaded: boolean };
    filtersVisible?: boolean;
    hiddenFilters?: string[];
    activeHiddenFilters?: string[];
    orderInspectionsIndex?: any;
    inspectionTypes?: InspectionType[];
    inspectionTypesById?: byId<InspectionType>;
    inspectionTypesSelector?; any;
    weekView?: {
        favoriteInspectors?: string;
        setInspectorsManually?: boolean;
        filters?: { name: string; value: any }[];
        currentWeek: number;
        data: { inspectors: [], days: []}
        datesRange: { startDate: string, endDate: string }
        isLoading: boolean;
        details: byId<InspectionDetails>;
    }
    inspectionsBaseURL?: string;
    inspectionsMap?: byId<types.Inspection>;
    inspections?: types.Inspection[];
    editInspectionDataReady?: boolean;
    changedInspections?: byId<{ [name: string]: any }>;
    isAddingInspection?: boolean;
    isCreatingOrder?: boolean;
    focussedInspectionId?: string;
    isSameForAllInspections?: boolean;
    areAllInspectionsEqual?: boolean;
    listView?: {
        elements: [];
        isLoading: boolean;
        scrollPosition: number;
        params: any;
        defaultDate: string;
    },
    isSameForAllInspectionsPermissions?: {
        hasPermissions: boolean; permissions: any
    };
    order: types.Order;
};

const initialState = {
    isFetching: false,
    isSaving: false,
    listView: {
        elements: [],
    },
    attachments: {
        byId: {
            data: [{}],
            progress: 0,
        },
    },
    order: {
        all_approval_flows_equal: true,
        groups: [],
        inspections: [],
        order_id: '',
        order_number: '',
        sampling_protocol: {},
        seen: true,
    },
    inspectionAdded: false,
    lastViewedInspectionPage: '/inspections',
    filters: {},
    suppliers: { loading: false, data: [], loaded: false },
    filtersVisible: false,
    hiddenFilters: ['date', 'assigned_user', 'conclusion', 'approver', 'supplier_id', 'inspection_type'],
    activeHiddenFilters: [],
    orderInspectionsIndex: {},
    inspectionTypes: [],
    inspectionTypesById: {},
    inspectionTypesSelector: {},
};

const map = {
    [CREATE_ORDER_AND_INSPECTIONS_SUCCESS]:
        (state = initialState, action: Action<CREATE_ORDER_AND_INSPECTIONS_SUCCESS>) => {
            return Object.assign({}, state, {
                isFetching: action.payload.isFetching,
                orderId: action.payload.orderId,
                order: action.payload.data,
                isNew: action.payload.isNew,
                inspectionAdded: false,
            });
        },
    [INSPECTIONS_ADD_ATTACHMENT_PROGRESS]:
        (state = initialState, action: Action<INSPECTIONS_ADD_ATTACHMENT_PROGRESS>) => {
            const id = action.payload.inspectionId;
            const byId = state.attachments.byId;
            if (byId[id]) {
                const data = byId[id].data;
                byId[id] = {
                    data,
                    progress: {
                        cnt: action.payload.progress,
                    },
                };
                return Object.assign({}, state, {
                    attachments: {
                        byId,
                    },
                });
            }
            return state;
        },
    [ORDER_ADD_ATTACHMENT_PROGRESS]:
        (state = initialState, action: Action<ORDER_ADD_ATTACHMENT_PROGRESS>) => {
            const id = action.payload.orderId;
            const byId = state.attachments.byId || {};
            const data = byId[id].data;
            byId[id] = {
                data,
                progress: {
                    cnt: action.payload.progress,
                },
            };
            return Object.assign({}, state, {
                attachments: {
                    byId,
                },
            });
        },
    [ORDER_GET_ATTACHMENTS_SUCCES]:
        (state = initialState, action: Action<ORDER_GET_ATTACHMENTS_SUCCES>) => {
            const id = action.payload.orderId;
            const byId = state.attachments.byId || {};
            byId[id] = {
                data: action.payload.attachments,
                progress: state.attachments.byId.progress,
            };
            return Object.assign({}, state, {
                attachments: {
                    byId,
                },
            });
        },
    [INSPECTIONS_GET_ATTACHMENTS_SUCCES]:
        (state = initialState, action: Action<INSPECTIONS_GET_ATTACHMENTS_SUCCES>) => {
            const id = action.payload.inspectionId;
            const byId = state.attachments.byId || {};
            byId[id] = {
                data: action.payload.attachments,
                progress: state.attachments.byId.progress,
            };
            return Object.assign({}, state, {
                attachments: {
                    byId,
                },
            });
        },
    [UPDATE_INSPECTION_SUCCESS]:
        (state = initialState) => {
            return Object.assign({}, state, {
                saveOk: true,
            });
        },
    [UPDATE_INSPECTION_REQUEST]:
        (state = initialState) => {
            return Object.assign({}, state, {
                isSaving: true,
            });
        },
    [types.UNLOCK_INSPECTION_REQUEST]: (state = initialState, action) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isSaving,
        });
    },
    [types.UNLOCK_INSPECTION_SUCCESS]: (state = initialState, action) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isSaving,
        });
    },
    [SET_LAST_VIEWED_INSPECTION_PAGE]: (state = initialState, action) => {
        return Object.assign({}, state, {
            lastViewedInspectionPage: action.payload.url,
        });
    },
    [GET_ATTACHMENTS_SUCCESS]: (state = initialState, action) => {
        const inspections = Object.assign([], state.order.inspections);
        inspections.forEach((inspection: types.Inspection) => {
            if (action.payload.attachToItemId === inspection.inspection_id) {
                inspection.attachment_count = action.payload.attachments.length;
            }
        });
        return Object.assign({}, state, {
            inspections,
        });
    },
    [UPDATE_ORDER_FROM_RESPONSE]: (state = initialState, action) => {
        const newInspectionData = action.payload.data;
        const inspectionId = newInspectionData.order_id;
        const index = state.listView.elements.findIndex((inspection) => {
            return inspection.order_id === inspectionId;
        });
        const newElements = [...state.listView.elements];
        newElements[index] = newInspectionData;
        return {
            ...state,
            data: {
                ...state.listView,
                elements: newElements,
            },
        };

    },
    [types.PLANNING_DATA_SUCCESS]: (state = initialState, action) => {
        return {
            ...state,
            planningData: action.payload.data,
        };

    },
    [types.SET_INSPECTION_FILTER]: (state = initialState, action) => {
        const filters = Object.assign({}, state.filters);
        filters[action.payload.name] = action.payload.value;
        return Object.assign({}, state, {
            filters,
        });
    },
    [types.SET_INSPECTION_FILTERS]: (state = initialState, action) => {
        return Object.assign({}, state, {
            filters: action.payload.filters,
        });
    },
    [types.SET_INSPECTION_FILTERS_VISIBILITY]: (state = initialState, action) => {
        return Object.assign({}, state, {
            filtersVisible: action.payload.visible,
        });
    },
    [types.SET_INSPECTION_HIDDEN_FILTERS]: (state = initialState, action) => {
        return Object.assign({}, state, {
            activeHiddenFilters: action.payload.filters,
        });
    },
    [GET_CHECKLIST_CONTENT_SUCCESS]:
        (state = initialState, action: Action<GET_CHECKLIST_CONTENT_SUCCESS>) => {
            return Object.assign({}, state, {
                isSaving: action.payload.isFetching,
                inspectionAdded: false,
            });
        },
    [GET_CHECKLIST_CONTENT]: (state = initialState, action: Action<GET_CHECKLIST_CONTENT>) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isFetching,
            inspectionAdded: false,
        });
    },
    [types.SUPPLIERS_IS_FETCHING_REQUEST]: (state = initialState, action) => {
        const suppliers = { ...state.suppliers };
        suppliers.loading = action.payload.isFetching;
        return Object.assign({}, state, {
            suppliers,
        });
    },
    [types.SUPPLIERS_SUCCESS]: (state = initialState, action) => {
        const suppliers = { ...state.suppliers };
        suppliers.loading = false;
        suppliers.loaded = true;
        suppliers.data = action.payload.suppliers;

        return Object.assign({}, state, {
            suppliers,
        });
    },
    [INDEX_ORDER_INSPECTIONS]: (state = initialState, action) => {
        return Object.assign({}, state, {
            orderInspectionsIndex: action.payload.inspectionsIndex,
            inspectionAdded: false,
        });
    },
    [types.GET_INSPECTIONS_TYPES]: (state = initialState, action) => {
        const inspectionTypes = action.payload.inspectionTypes;
        const inspectionTypesById = action.payload.inspectionTypesById;
        return Object.assign([], state, {
            inspectionTypes,
            inspectionTypesById,
        });
    },
};

export default { map, initialState };
