import { handleActions } from 'redux-actions';
import { Action } from './../../base/genericActions';
import * as types from './types';

const initialState = {
    comments: undefined,
    hasTranslatedComments: false,
    showTranslatedComments: false,
    showTranslatedCommentsAsked: false,
    isFetching: false,
    isSaving: false,
};

export const reducer = handleActions({
    [types.GET_COMMENTS_REQUEST]: (state = initialState, action: Action<types.GET_COMMENTS_REQUEST>) => {
        return {
            ...state,
            isFetching: action.payload.isFetching,
        };
    },
    [types.GET_COMMENTS_SUCCESS]: (state = initialState, action: Action<types.GET_COMMENTS_SUCCESS>) => {
        const comments = action.payload.comments;
        if (comments.meta) {
            delete comments.meta;
        }
        return {
            ...state,
            comments,
            isFetching: false,
        };
    },
    [types.ADD_COMMENT_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isSaving: true,
        };
    },
    [types.ADD_COMMENT_FAILURE]: (state = initialState) => {
        return {
            ...state,
            isSaving: false,
        };
    },
    [types.ADD_COMMENT_SUCCESS]: (state = initialState, action: Action<types.ADD_COMMENT_SUCCESS>) => {
        const comments = action.payload.comments;
        if (comments.meta) {
            delete comments.meta;
        }
        return {
            ...state,
            comments: action.payload.comments,
            isSaving: false,
        };
    },
    [types.ADD_DEFECT_COMMENT_SUCCESS]: (state = initialState, action: Action<types.ADD_DEFECT_COMMENT_SUCCESS>) => {
        const comments = action.payload.comments;
        if (comments.meta) {
            delete comments.meta;
        }
        return {
            ...state,
            comments: action.payload.comments,
            isSaving: false,
        };
    },
    [types.CLEAR_COMMENTS]: (state = initialState) => {
        return {
            ...state,
            comments: {},
        };
    },
    [types.HAS_TRANSLATED_COMMENTS]: (state = initialState, action: Action<types.HAS_TRANSLATED_COMMENTS>) => {
        return {
            ...state,
            hasTranslatedComments: action.payload.hasTranslatedComments,
        };
    },
    [types.SHOW_TRANSLATED_COMMENTS]: (state = initialState, action: Action<types.SHOW_TRANSLATED_COMMENTS>) => {
        return {
            ...state,
            showTranslatedComments: action.payload.showTranslatedComments,
        };
    },
    [types.SHOW_TRANSLATED_COMMENTS_ASKED]: (state = initialState, action: Action<types.SHOW_TRANSLATED_COMMENTS_ASKED>) => {
        return {
            ...state,
            showTranslatedCommentsAsked: action.payload.asked,
        };
    },
}, initialState);

export default reducer;
