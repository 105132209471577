import React from 'react';
import { Audit } from '../../../backend_api/models';
import { twMerge } from '../../../base/utils';
import BasicAuditList from '../../audit/components/BasicAuditList';
import CreateAuditModal from '../../audit/components/CreateAuditModal';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';

type Props = {
    audits: Audit[];
    productionUnitId?: string;
    supplierId?: string;
    onAuditsUpdated?();
    className?: string;
    disabled?: boolean;

};

const DetailedSupplierAudits = (props: Props): React.ReactElement => {
    const { className, audits, onAuditsUpdated } = props;

    return <div className={twMerge('', className)}>
        <HasFeatureAccess feature='u_audits'>
            <BasicAuditList audits={audits} onUpdate={onAuditsUpdated} />
            <CreateAuditModal initialSupplierId={props.supplierId} />
        </HasFeatureAccess>
    </div >
}
export default DetailedSupplierAudits;
