import React, { } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useStore } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { Group } from '../../../backend_api/models';
import { createSupplier } from '../../suppliers/actions';
import { AppState } from '../../../base/types';
import { useSuppliers } from '../../productionUnits/hooks/useSuppliers';
import { NewSupplierInputsWithHints } from './NewSupplierInputs';

export type CreateSupplierParams = {
    name: string,
    number: string,
    groups: Group[]
}

export function CreateSupplierModal(props: {
    renderButton(): React.ReactNode,
    renderNote?(): React.ReactNode,
    open?: boolean,
    onModalClosed?: () => void,
    onExistingSupplierIdSelected(supplierId: string): void,
    onUserWantedToCreateSupplier?(params: CreateSupplierParams)
}) {

    const store = useStore<AppState>()
    const dispatch = useDispatch()

    const [modalVisible, setModalVisible] = React.useState(false)

    const visiblityOverride = props.open === true || props.open === false ? props.open : modalVisible

    const [groupsInput, setGroupsInput] = React.useState<Group[]>([]);
    const [supplierNameInput, setSupplierNameInput] = React.useState<string>('');
    const [supplierNumberInput, setSupplierNumberInput] = React.useState<string>('');

    const selectableSuppliersLoading = useSuppliers();

    const loading = selectableSuppliersLoading === 'loading';
    const selectableSuppliers = loading ? [] : selectableSuppliersLoading;

    function onClosed() {
        setModalVisible(false);
        if (props.onModalClosed) {
            props.onModalClosed()
        }
    }

    function onOpened() {
        setModalVisible(true);
    }

    /* This needs to be a callback, otherwise EditAudit and EditInspection will have some problems. */
    function onUserWantedToCreateSupplier() {
        if (props.onUserWantedToCreateSupplier) {
            props.onUserWantedToCreateSupplier({
                name: supplierNameInput,
                number: supplierNumberInput,
                groups: groupsInput,
            })
        } else {
            const fun = createSupplier({
                name: supplierNameInput,
                number: supplierNumberInput,
                groups: groupsInput,
                deleted: false,
                users: undefined,
                features: undefined,
                id: undefined,
                primary_group: undefined
            }, true)
            // TODO. hacks! how to fix?
            /* TODO: Replace with props.onUserWantedToCreateSupplier */
            fun(dispatch, () => store.getState(), undefined)
            onClosed()
        }
    }

    return (
        <Modal
            closeOnEscape
            closeIcon
            onClose={onClosed}
            onOpen={onOpened}
            open={visiblityOverride}
            trigger={props.renderButton()}
        >
            <Modal.Header>
                <FormattedMessage id='suppliers.add_supplier' />
            </Modal.Header>
            <Modal.Content className='space-y-8'>
                <NewSupplierInputsWithHints
                    selectableSuppliers={selectableSuppliers}
                    onExistingSupplierIdSelected={props.onExistingSupplierIdSelected}
                    groupsInput={groupsInput}
                    onGroupsChanged={setGroupsInput}
                    supplierNameInput={supplierNameInput}
                    onSupplierNameChanged={setSupplierNameInput}
                    supplierNumberInput={supplierNumberInput}
                    onSupplierNumberChanged={setSupplierNumberInput}
                />
                {/* {props.renderNote && props.renderNote()} */}
            </Modal.Content>
            <Modal.Actions>
                <Button data-test-id='button-create-audit-save'
                    primary
                    disabled={!isValidSupplierName(supplierNameInput)}
                    onClick={onUserWantedToCreateSupplier}>
                    <FormattedMessage id='globals.ok' />
                </Button>
                <Button onClick={onClosed}>
                    <FormattedMessage id='globals.cancel' />
                </Button>
            </Modal.Actions>

        </Modal>
    )

}

function isValidSupplierName(newSupplierName: string) {
    return (typeof newSupplierName === 'string') && (newSupplierName.length > 0);
}