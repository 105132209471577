import React from 'react';
import { NavLink } from "react-router-dom";
import { twMerge } from '../../../../base/utils';

type Props = {
    className?: string;
};

const SisenseRoot = (props: Props): React.ReactElement => {
    const { className } = props;

    return (
        <div className={twMerge('', className)}>
            <div className="p-8">
                <h1>Testing Sisense Opts</h1>

                <ul className="w-full">
                    <li className="flex h-full items-center justify-center md:justify-left p-3 md:p-6 truncate text-on-surface-light hover:text-on-surface-light bg-surface-light">
                        <NavLink to="/dashboard/sis-dashboard">Sisense Embedded Dashboard</NavLink>
                    </li>
                    <li className="flex h-full items-center justify-center md:justify-left p-3 md:p-6 truncate text-on-surface-light hover:text-on-surface-light bg-surface-light">
                        <NavLink to="/dashboard/sis-widgets">Sisense Embedded Widgets</NavLink>
                    </li>
                    <li className="flex h-full items-center justify-center md:justify-left p-3 md:p-6 truncate text-on-surface-light hover:text-on-surface-light bg-surface-light">
                        <NavLink to="/dashboard/sis-iframe">Sisense IFrame Dashboard</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SisenseRoot;