import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { DetailedProductionUnitStatus } from '../../../backend_api/models';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getProductionUnitStatusList, getProductionUnitStatusListSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type ProductionUnitFilterProps = {
    selectedProductionUnitIds?: string[];
    filterName?: string;
    disabled?: boolean;
    search?: boolean;
};

export default function ProductionUnitStatusFilter(props: ProductionUnitFilterProps): React.ReactElement {
    const { search = true, disabled = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const filterName = props.filterName || 'production_unit_status';
    const selectedSuppliers = getLocationEntry(location, filterName);

    useEffect(() => {
        dispatch(getProductionUnitStatusList());
    }, [])

    const productionUnitsStatus: DetailedProductionUnitStatus[] = useSelector(getProductionUnitStatusListSelector);
    const options = productionUnitsStatus.map((pus) => ({ id: pus.id, text: pus.name.text, value: pus.id }));

    return <Filter labelId='filters.production_units_status.label_production_units_status' >
        <DropdownFilter
            name='production_units_status'
            options={options}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.production_units.select_production_units_status' })}
            selectedItems={selectedSuppliers || []}
            handleSave={(items): void => {
                dispatch(setFilter(filterName, items));
            }}
            inline={false}
            showLoader={true}
            disabled={disabled}
            search={search}
        /></Filter >;
}