import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { GPSLocation } from '../../../backend_api/models';
import Input from '../../../base/components/basic/Input';
import { isUndefinedOrNull } from '../../../base/utils';
import ProductionUnitMap from './ProductionUnitMap';

type Props = {
    className?: string;
    location: GPSLocation;
    handleChange(name: string, value: string | GPSLocation, index?: number): void;
    locationCountry: string;
    edit: boolean;
};

const DetailedProductionUnitInfoLocation = (props: Props): React.ReactElement => {
    const { className, handleChange, edit, locationCountry } = props;
    type Location = { location: GPSLocation; isValid: boolean };
    const LONGITUDE_MAX = 180;
    const LATITUDE_MAX = 90;

    const [isValid, setIsValid] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);
    const [loc, setLoc] = useState<Location>({ location: props.location || { latitude: null, longitude: null }, isValid: false });

    const updateLatitude = (lat: number, loc: Location): Location => {
        let isValid = false;
        const obj = { location: undefined, isValid };
        if (lat === null && loc.location.longitude === null) {
            obj.location = null;
        } else {
            obj.location = { longitude: loc.location.longitude, latitude: lat };
        }
        isValid = ((isUndefinedOrNull(lat) && !isUndefinedOrNull(loc.location.longitude)) || (!isUndefinedOrNull(lat) && isUndefinedOrNull(loc.location.longitude))) ? false : true;
        setIsValid(isValid);
        const o = saveLocation(obj.location, isValid);
        return o;
    };

    const updateLongitude = (longitude: number, loc: Location): Location => {
        let isValid = false;
        const obj = { location: undefined, isValid };
        if (longitude === null && loc.location.longitude === null) {
            obj.location = null;
        } else {
            obj.location = { latitude: loc.location.latitude, longitude };
        }
        isValid = ((isUndefinedOrNull(longitude) && !isUndefinedOrNull(loc.location.latitude)) || (!isUndefinedOrNull(longitude) && isUndefinedOrNull(loc.location.latitude))) ? false : true;
        setIsValid(isValid);
        const o = saveLocation(obj.location, isValid);
        return o;

    };

    const saveLocation = (obj: GPSLocation, isValid: boolean): Location => {
        if (obj === null || (obj.latitude === null && obj.longitude === null) || (obj.latitude === undefined || obj.longitude === undefined)) {
            setLoc({ location: { latitude: null, longitude: null }, isValid });
            setIsRemoved(isUndefinedOrNull(obj.latitude) && isUndefinedOrNull(obj.longitude));
            return { location: null, isValid: true };
        }
        setLoc({ location: obj, isValid });
        return { location: obj, isValid };
    };

    return edit ? <><div className='flex pb-4'>
        <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.location.lat' /></div>
        <div className='w-2/4'>
            <NumberFormat
                value={loc && loc.location && loc.location.latitude}
                className=''
                decimalScale={5}
                customInput={Input}
                fixedDecimalScale={true}
                onValueChange={(value): void => {
                    updateLatitude(value.floatValue, loc);
                }}

                isAllowed={(values): boolean => values.floatValue ? (values.floatValue <= LATITUDE_MAX) && (values.floatValue >= -LATITUDE_MAX) : true}
                onBlur={() => isValid && handleChange('location', isRemoved ? null : loc.location)}
            />
        </div>
    </div>
        <div className='flex pb-4'>
            <div className='w-1/4'><FormattedMessage id='production_units.detailed_page.info.location.long' /></div>
            <div className='w-2/4'>
                <NumberFormat
                    value={loc && loc.location && loc.location.longitude}
                    className=''
                    decimalScale={5}
                    customInput={Input}
                    fixedDecimalScale={true}
                    onValueChange={(value): void => {
                        updateLongitude(value.floatValue, loc);
                    }}
                    isAllowed={(values): boolean => values.floatValue ? (values.floatValue <= LONGITUDE_MAX) && (values.floatValue >= -LONGITUDE_MAX) : true}
                    onBlur={() => isValid && handleChange('location', isRemoved ? null : loc.location)}
                />
            </div>
        </div>

        <div className='flex'>
            <div className='w-full pt-4'>
                <ProductionUnitMap
                    locationCountry={locationCountry}
                    location={loc.location}
                    setMapLocation={(location) => {
                        setLoc({ location: { latitude: location.latitude, longitude: location.longitude }, isValid: true });
                        handleChange('location', { latitude: location.latitude, longitude: location.longitude });
                    }}
                />
            </div>
        </div></> : null;

}
export default DetailedProductionUnitInfoLocation;
