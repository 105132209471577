import classnames from 'classnames';
import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Icon, Image, List, Popup } from 'semantic-ui-react';
import { ReportCheckpoint } from '../../../../../backend_api/models';
import { addArrayItemOrRemoveIfExists, deepCopy, getLocaleLanguage, preventDefaultAndStopPropagation, stopPropagation } from '../../../../../base/utils';
import { getAuditReportCheckpointsRemarksSelector } from '../../../../audit/selectors/auditSelectors';
import { Checkpoint, CheckpointHeaderData, CheckpointRemark, CheckpointStatusEnum as STATUS, Score } from '../../../../checklists/types';
import { showTranslatedCommentsSelector } from '../../../../comments/selectors';
import CorrectiveActionsFromCheckpointsWizard, { CorrectiveActionsFromDefectOrCheckpointsType } from '../../../../correctiveActions/components/CorrectiveActionsFromCheckpointsWizard';
import { TranslateText } from '../../../../globals/components';
import { reportCheckpointsWithRemarksSelector } from '../../../selectors/reportSelectors';
import CheckpointStatus from '../report2/CheckpointStatus';
import CheckpointsScore from './CheckpointsScore';
import CheckpointsStatusIndicator from './CheckpointsStatusIndicator';

type CheckpointsSummaryProps = {
    isAudit?: boolean;
    id: string;
    summaryScore?: Score;
};

const CheckpointsSummary = (props: CheckpointsSummaryProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const { isAudit = false, id } = props;
    const [onlyShowRemarks, setShowRemarks] = useState(true);
    const remarks = isAudit ? useSelector(getAuditReportCheckpointsRemarksSelector) : useSelector(reportCheckpointsWithRemarksSelector);
    const hasRemarks = Object.keys(remarks).length > 0;
    const [remarksCnt, setRemarksCnt] = useState(0);
    const [openItems, setOpen] = useState([]);
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    useEffect(() => {
        let remarkCnt = 0;
        const ids = Object.keys(remarks).filter((key: string) => {
            const items = getSummaryItems(remarks[key].remarks, true);
            remarkCnt += items.length;
            return items.length > 0;
        });
        setRemarksCnt(remarkCnt);
        setOpen(ids);
    }, [remarks])
    const getHeader = (checkpointHeader: CheckpointHeaderData, checkpoints: Checkpoint[], lang: string, isOpen: boolean): React.ReactElement => {
        return <div className='cpHeader'><div>{!isOpen ? <Icon name='caret right' /> : <Icon name='caret down' />}{checkpointHeader.title[lang] || checkpointHeader.title.C || intl.formatMessage({ id: 'checklists.checkpoints_no_header' })}</div><div className='left'>
            <CheckpointsScore score={checkpointHeader.score} />
            <div className='cpStatusAggr'>
                <CheckpointsStatusIndicator onlyStatusEnabled={false} checkpoints={checkpoints} />
            </div></div></div>;
    };


    const toggleOpen = (id: string): void => {
        const oi = Object.assign([], openItems);
        const arr = addArrayItemOrRemoveIfExists(oi, id);
        setOpen(arr);
    };
    const getItems = (remarkObj, hasHeaders): React.ReactElement => {
        const items = getSummaryItems(remarkObj.remarks, onlyShowRemarks);
        if (items.length === 0) {
            return (openItems.includes(remarkObj.checkpointHeader.id) || !hasHeaders) && <List.Item className={hasHeaders ? 'h' : null}><FormattedMessage id='checklist.summary.header.no_checkpoints' /></List.Item>;
        }
        const list = (remark: CheckpointRemark): React.ReactElement => {
            const d = remark.description;
            // If its a sub-checkpoint, the description array has two elements with the first being the master checkpoint text
            const desc = d.length === 1 ? (d[0][lang] || d[0].C) : [(d[0][lang] || d[0].C), <Icon key='cp_icon' name='triangle right' />, (d[1][lang] || d[1].C)];
            const cn = classnames({
                link: true,
                noUnderline: true,
                noColor: true,
                cpStatus: true,
                h: hasHeaders,
                spacer: remark.has_spacer,
            });
            return <List.Item>
                <List.Content onClick={(e) => preventDefaultAndStopPropagation(e)}>
                    <Link to={{ hash: '#checkpoint_' + remark.id }}
                        className={cn}
                        key={'cpStatus_1'}
                        onClick={() => {
                            // ⬇️ This is a hack to cheat the browser to use the hash part of the url for navigation ⬇️ 
                            document.location.hash = '';
                            document.location.hash = '#checkpoint_' + remark.id;
                        }}
                    ><div className='break-all'>{desc}</div>
                        <CheckpointStatus showInfo={true} checkpoint={remark as unknown as ReportCheckpoint} />
                    </Link>
                    <List.Content className={hasHeaders ? 'h' : null}>{showRemark(remark, onlyShowRemarks, showTranslatedComments)}</List.Content>
                </List.Content>
            </List.Item>;
        }
        if (hasHeaders) {
            return <Fragment>{items.length > 0 && openItems.includes(remarkObj.checkpointHeader.id) && items.map((remark) => {
                const l = list(remark);
                return (onlyShowRemarks ? remark.status !== STATUS.CHECKED_OK && l : l);
            })}</Fragment>
        }
        return <Fragment>{items.length > 0 && items.map((remark) => {
            return list(remark);
        })}</Fragment>;
    };
    return <div className='checkpoints-summary'>
        <div className='flex justify-between'>
            <h3><FormattedMessage id='inspections.checkpoints_remarks' />{remarksCnt > 0 && <Fragment> ({remarksCnt})</Fragment>}</h3>
            {hasRemarks && isAudit && <CorrectiveActionsFromCheckpointsWizard createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Audit} id={id} itemType='checkpoint' />}
        </div>
        {remarks.length === 0 && <p>
            <Divider hidden />
            <FormattedMessage id='checklist.summary.no_checkpoints' />
        </p>}
        <List divided relaxed className='remark-list'>
            {Object.keys(remarks).map((remarkId: string) => {
                const remarkObj: { checkpointHeader: CheckpointHeaderData; remarks: CheckpointRemark[]; hasHeaders: boolean } = deepCopy(remarks[remarkId]);
                const hasHeaders = remarkObj.hasHeaders;
                let isOpen = false;
                if (hasHeaders) {
                    if (!onlyShowRemarks) {
                        isOpen = true;
                    }
                    if (openItems.includes(remarkObj.checkpointHeader.id)) {
                        isOpen = true;
                    } else {
                        isOpen = false;
                    }
                }
                if (!hasHeaders) {
                    isOpen = true;
                }
                const ret = [];
                const cn = classnames({
                    link: true,
                    noUnderline: true,
                    secondary: true,
                    showAll: true,
                    h: hasHeaders,
                });
                hasHeaders ? ret.push(<div className='' onClick={(): void => toggleOpen(remarkObj.checkpointHeader.id)}>
                    {getHeader(remarkObj.checkpointHeader, remarkObj.checkpointHeader.checkpoints as unknown as Checkpoint[], lang, isOpen)}
                    {getItems(remarkObj, hasHeaders)}</div>)
                    : ret.push(getItems(remarkObj, hasHeaders));
                isOpen && onlyShowRemarks && ret.push(<div className='jumpLinks'>
                    <List.Item className={cn} onClick={(): void => setShowRemarks(false)}>
                        <FormattedMessage id='checklist.see_all_checkpoints' />
                    </List.Item>
                </div>);
                isOpen && !onlyShowRemarks && ret.push(<div className='jumpLinks'>
                    <List.Item className={cn} onClick={(): void => setShowRemarks(true)}>
                        <FormattedMessage id='checklist.see_only_remarks' />
                    </List.Item>
                </div>);
                return ret;
            })}
            {isAudit &&
                <div className='cpHeader'><div>{<FormattedMessage id='checklist.summary_score' />}</div><div className='left'>
                    <CheckpointsScore score={props.summaryScore} />
                </div></div>
            }
        </List>
        {hasRemarks && isAudit && <div className='flex justify-end'><CorrectiveActionsFromCheckpointsWizard id={id} createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Audit} itemType='checkpoint' /></div>}
    </div >;
}


const getSummaryItems = (items: CheckpointRemark[], onlyWithRemarks: boolean): CheckpointRemark[] => {
    const ret = [];
    items.forEach((remark: CheckpointRemark) => {
        if (remark.multiple_choice && remark.multiple_choice.options.filter((mp) => mp.chosen && mp.failed).length > 0 || remark.barcode !== null || (!onlyWithRemarks && remark.status === STATUS.NOT_APPLICABLE) || (!onlyWithRemarks && remark.status === STATUS.NOT_SET) || remark.status === STATUS.PENDING || remark.status === STATUS.CHECKED_NOT_OK /* || remark.defects.length > 0 */) {
            ret.push(remark);
        }
    });
    return onlyWithRemarks ? ret : items;
}
const showRemark = (remark: CheckpointRemark, onlyRemarks: boolean, showTranslatedComments: boolean): React.ReactElement[] => {
    const retval = [];
    const link = '#checkpoint_' + remark.id;
    const cn = 'link noColor noUnderline py-2';
    if (remark.hasRemark) {
        remark.imageComments.forEach((imageComment) => {
            if (imageComment.comment && imageComment.comment !== '') {
                const imageLink = '?imageURL=' + imageComment.url;
                const ictt = <TranslateText
                    showTranslatedComments={showTranslatedComments}
                    translateObj={imageComment.translated_comment}
                    originalLanguage={imageComment.language}
                    originalText={imageComment.comment}
                    showToggleActions={true}
                />;
                retval.push(<Link onClick={(e) => stopPropagation(e)} className={'link noColor noUnderline'} to={imageLink}><Popup position='top center' hoverable={true} trigger={<div className='imageComment'><Icon name='image' /> {ictt}</div>}>
                    <Popup.Content>
                        <Image floated='left' src={imageComment.url} size='tiny' />
                        <FormattedMessage id='inspections.this_is_a_remark_on_an_image_click_to_open_the_image' /></Popup.Content></Popup></Link>);
            }
        });
    }
    if (remark.multiple_choice) {
        remark.hasRemark = true;
    }
    if (remark.barcode) {
        remark.hasRemark = true;
        retval.push(
            <div className={cn} onClick={(e): void => gotoLink(e, link)}>
                <span><i className={'mi error'}>error</i><FormattedMessage id='inspections.barcode_mismatch' /></span>
            </div>);
    }
    if (remark.commentObj.comment !== null) {
        remark.hasRemark = true;
        retval.push(
            <div className={cn} onClick={(e): void => gotoLink(e, link)}>{/* <Icon name='comment' /> */}
                {remark.commentObj.comment2 && <i className={'mi comment'}>comment</i>}
                <TranslateText
                    showTranslatedComments={showTranslatedComments}
                    translateObj={remark.commentObj.translated_comment2}
                    originalLanguage={remark.commentObj.comment_language}
                    originalText={remark.commentObj.comment2}
                    showToggleActions={true}
                />
            </div>
        );
    }
    return retval;
}

const gotoLink = (event: SyntheticEvent, link: string): void => {
    event.stopPropagation();
    window.location.href = link;
};


export default CheckpointsSummary;