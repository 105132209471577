import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionEvent } from '../../../backend_api/models/CorrectiveActionEvent';
import NotifiedEvent from './NotifiedEvent';

type Props = {
    event: CorrectiveActionEvent;
}

const CreatedEventDetails = (props: Props) => {
    return <Fragment>
        <FormattedMessage id={'corrective_actions.events.created'} />
        <NotifiedEvent event={props.event} />
    </Fragment>
}

export default CreatedEventDetails;