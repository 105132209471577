import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { widthsFoo } from './InfoLine';

type InfolineHeaderProps = {
    isCombinedInspection: boolean;
    hasInspectionTypes: boolean;
}

export const InfolineHeader = (props: InfolineHeaderProps): React.ReactElement => {
    const isCI = props.isCombinedInspection;
    const qtyId = isCI ? 'inspections.total_quantity' : 'inspections.total_and_sample_quantity';
    const hasInspectionTypes = props.hasInspectionTypes;
    const labelClass = 'text-secondary font-bold text-sm';
    const widths = widthsFoo(hasInspectionTypes);

    return (

        <div className='md:flex md:flex-row lg:space-x-1 mb-4 pl-1 hidden'>
            {hasInspectionTypes && <div className={classnames(widths.types[isCI ? 'c' : 't'], labelClass)}>
                <FormattedMessage id='inspections.item_type' /></div>}
            {isCI && <div className={classnames(widths.orderNumber.c, labelClass)}><FormattedMessage id='inspections.order_number' /></div>}
            <div className={classnames(widths.itemNumber[isCI ? 'c' : 't'], labelClass)}><FormattedMessage id='inspections.item_number' /></div>
            <div className={classnames(widths.itemName[isCI ? 'c' : 't'], labelClass)}><FormattedMessage id='inspections.item_name' /></div>
            <div className={classnames(isCI ? widths.totalQty.c : 'w-4/24 truncate pr-3', labelClass)}><FormattedMessage id={qtyId} /></div>
            {!isCI && <div className={classnames(widths.checklist['t'], labelClass)}><FormattedMessage id='inspections.checklist' /></div>}
            <div className={classnames(widths.status[isCI ? 'c' : 't'], labelClass)}><FormattedMessage id='inspections.status' /></div>
        </div >);
};
