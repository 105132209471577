import { createSelector } from '@reduxjs/toolkit';
import { getProfile } from '../modules/profile/selectors';
import { LayoutAttributes } from './baseSlice';
import { AppState } from './types';

const applicationReadySelector = (state: AppState): boolean => state.app.BASE.applicationReady;

export const getApplicationReady: any = createSelector(
    [applicationReadySelector],
    (ready) => ready,
);

const getActionFlagSelector = (state): string => state.app.BASE.actionFlag;

export const getActionFlag: any = createSelector(
    [getActionFlagSelector],
    (flag) => flag,
);

export const errorSelector = (state, type: string): string => {
    if (state.app.BASE.errors && state.app.BASE.errors[type]) {
        return state.app.BASE.errors[type];
    }
    return null;
};

export const getError: any = createSelector(
    [errorSelector],
    (error) => error,
);

export const getFeatures = (state: AppState): string[] => {
    const profile = getProfile(state);
    return (profile.features && profile.features || []);
};

export const getFeaturesSelector = createSelector(
    [getFeatures],
    (features) => features,
);
export const hasFeature = (state: AppState, feature: string): boolean => {
    const profile = getProfile(state);
    return profile.features && profile.features.includes(feature);
};

export const hasFeatureSelector = createSelector(
    [hasFeature],
    (feature) => feature,
);

export const getLayout = (state: AppState): LayoutAttributes => {
    return state.app.BASE.layout;
};

export const getLayoutSelector = createSelector(
    [getLayout],
    (layout) => layout,
);
