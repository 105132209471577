import { Location } from 'history';
import queryString from 'query-string';
import { getAuditConclusions, getAuditTypes, getAuditTypesAndConclusionsRelations, setAuditListDate } from '../modules/audit/slices/auditSlice';
import { setCorrectiveActionListDate } from '../modules/correctiveActions/correctiveActionsSlice';
import { getCustomFields } from '../modules/filters/filtersActions';
import { setLocaleById, setLocaleDefault } from '../modules/globals/actions';
import { getInspectionTypes } from '../modules/inspections/actions';
import { setListViewDefaultDate } from '../modules/inspections/slices/inspectionsListSlice';
import { getProfile } from '../modules/profile/actions';
import { setBookingDefaultDate } from '../modules/suppliers/actions';
import { getGroups, getLocales, getRoles, getUserPreferences, getUsers } from '../modules/users/actions/actions';
import { applicationReady } from './baseSlice';
import { BOOKINGS_DEFAULT_DATE, LIST_VIEW_DEFAULT_DATE } from './config';
import * as types from './types';


export const initializeAppData = (location: Location, isAuthenticated: boolean, isTemporaryUserMode: boolean): types.AppThunk => {
    const search = queryString.parse(location.search);
    return async (dispatch): Promise<void> => {
        const defaultFilterDate = search.date as string || LIST_VIEW_DEFAULT_DATE;
        dispatch(setListViewDefaultDate(defaultFilterDate));
        dispatch(setAuditListDate(defaultFilterDate));
        dispatch(setCorrectiveActionListDate(defaultFilterDate));
        dispatch(setBookingDefaultDate(BOOKINGS_DEFAULT_DATE));
        if(isTemporaryUserMode && isAuthenticated) {
            dispatch(applicationReady(false));
            return waitFor(1000)
            .then(() => {
                return Promise.all([
                    dispatch(getProfile()),
                    dispatch(getAuditTypesAndConclusionsRelations()),
                    dispatch(getAuditTypes()),
                    dispatch(getAuditConclusions()),
                    dispatch(getLocales()),
                ])
            })
            .then(() => {
                dispatch(setLocaleDefault());
                if (search.locale_id) {
                    dispatch(setLocaleById(search.locale_id as string));
                }
                dispatch(applicationReady(true));
            });
        } else if (isAuthenticated) {
            Promise.all([
                dispatch(getProfile()),
                dispatch(getCustomFields()),
            ]).then(([profileData]) => {
                const endpointsToCall = [
                    dispatch(getUsers()),
                    dispatch(getLocales()),
                    dispatch(getInspectionTypes()),
                    dispatch(getUserPreferences()),
                    dispatch(getRoles()),
                    dispatch(getAuditTypesAndConclusionsRelations()),
                    dispatch(getAuditTypes()),
                    dispatch(getAuditConclusions()),
                ];
                if (profileData && profileData.role !== 'supplier') {
                    endpointsToCall.push(dispatch(getGroups()));
                }
                
                // Here we call all the data loading actions that need to be in place before the app is ready
                Promise.all(endpointsToCall).then(() => {
                    dispatch(setLocaleDefault());
                    if (search.locale_id) {
                        dispatch(setLocaleById(search.locale_id as string));
                    }
                    dispatch(applicationReady(true));
                });
            });
            dispatch(applicationReady(false));

        } else {
            // When not logged in we only load the list of locales
            Promise.all([
                dispatch(getLocales()),
            ])
                .then(() => {
                    if (search.locale_id) {
                        dispatch(setLocaleById(search.locale_id as string));
                    }
                });
        }
    };
};

function waitFor(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}