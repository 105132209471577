import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Button, Form, Input, Modal } from 'semantic-ui-react/';
import { useAppDispatch } from '../../../../store';
import { cancelCreateOrder, createOrderWithInspections } from '../../actions/editInspectionActions';


const CreateOrder = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [orderNumber, setOrderNumber] = useState({ order_number: '' });

    const createOrder = (): void => {
        setSaving(true);
        dispatch(createOrderWithInspections(orderNumber));
    }

    const handleChange = (evt, data): void => {
        setOrderNumber({ order_number: data.value });
    }

    const handleCancel = (): void => {
        dispatch(cancelCreateOrder());
        history.goBack();
    }
    return (<Modal
        open={true}
        size='small'
        onClose={handleCancel}
        closeIcon={true}
    >
        <Modal.Header><FormattedMessage id='inspections.new_inspection' /></Modal.Header>
        <Modal.Content>
            <Form onSubmit={createOrder}>
                <Form.Field width={8}>
                    <label><FormattedMessage id='inspections.enter_order_number' /></label>
                    <Input
                        type='text'
                        name='order_number'
                        data-test-id='create_inspection_order_number'
                        onChange={handleChange}
                        autoFocus={true}
                    />
                </Form.Field>
                {saving && <span className='infoColor'><FormattedMessage id='inspections.creating_inspection' /></span>}
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button disabled={saving || orderNumber.order_number.length === 0} onClick={createOrder} positive><FormattedMessage id='inspections.ok' /></Button>
            <Button onClick={handleCancel}><FormattedMessage id='inspections.cancel' /></Button>
        </Modal.Actions>
    </Modal>);
}

export default (CreateOrder);
