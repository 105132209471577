import React from 'react'
import { useIntl } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import { getPrettyDate } from '../../../base/utils'

type Props = {
    dates: string[]
}

const CompactDateList = (props: Props) => {
    const intl = useIntl()
    if (props.dates.length > 1) {
        return <Popup hoverable basic trigger={<div>{getPrettyDate(props.dates[0]) + ' + ' + (props.dates.length - 1) + ' ' + intl.formatMessage({ id: 'inspections.more' })}</div>}>
            {props.dates.map((date) => {
                return <div key={date}>{getPrettyDate(date)}</div>
            })}
        </Popup>
    }
    else if (props.dates.length == 1) {
        return <div>{getPrettyDate(props.dates[0])}</div>
    }
    else {
        return null
    }
}

export default CompactDateList