import React from 'react';
import { isStringAnUrl, twMerge } from '../../../base/utils';

type OwnProps = {
    text: string | string[] | number | React.ReactElement;
    textClass?: string;
    label?: string | string[] | number | React.ReactElement;
    labelClass?: string;
    labelFixed?: boolean;
    column?: boolean;
    width?: number;
    align?: 'left' | 'right';
    dataTestId?: string;
    showAsUrl?: boolean;
    className?: string;
};

type TextItemProps = OwnProps;

const TextItem = (props: TextItemProps): React.ReactElement => {
    const { text, label, labelFixed = false, column = false, align = 'left', dataTestId, showAsUrl = false, className, labelClass, textClass } = props;
    const cn = twMerge(
        'w-full',
        'textItem',
        labelFixed && 'labelFixed',
        column && 'column',
        className,
    );
    const cnText = twMerge(
        'text break-words',
        align === 'right' && ' justify-end flex w-full',
        textClass,
    );
    let content;
    if (showAsUrl && typeof text === 'string') {
        const wordList = convertTextToWordList(text)
        content = wordList.map(word => covertUrlToLink(word))
    }
    else {
        content = text;
    }
    return <div className={cn} style={{ width: props.width + 'px' }}>
        {label && <div className={twMerge('label break-words font-bold w-1/2 mr-2', labelClass)}>{label}</div>}
        {dataTestId && <div data-test-id={dataTestId} className={cnText}>{content}</div>}
        {!dataTestId && <div className={cnText}>{content}</div>}
    </div>;
};

const convertTextToWordList = (text): string[] => {
    return text.split(' ')
}

const covertUrlToLink = (word: string) => {
    if (isStringAnUrl(word)) {
        return <a href={word}>{word} </a>
    }
    else {
        return word + ' ';
    }
}

export default TextItem;
