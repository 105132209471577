import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Statistic } from 'semantic-ui-react';

type StatsProps = {
    summary: any;
};
const Stats = (props: StatsProps): React.ReactElement => {
    const summary = props.summary;
    if (summary) {
        return (
            <Statistic.Group horizontal><Statistic>
                <Statistic.Value>{summary.number_of_reports}</Statistic.Value>
                <Statistic.Label><FormattedMessage id='dashboard.statistics.reports' /></Statistic.Label>
            </Statistic><Statistic>
                    <Statistic.Value><FormattedNumber value={summary.total_sample_quantity} /></Statistic.Value>
                    <Statistic.Label><FormattedMessage id='dashboard.statistics.total_sample_qty' /></Statistic.Label>
                </Statistic><Statistic>
                    <Statistic.Value><FormattedNumber value={summary.number_of_orders} /></Statistic.Value>
                    <Statistic.Label><FormattedMessage id='dashboard.statistics.orders' /></Statistic.Label>
                </Statistic>
            </Statistic.Group>);
    }
    return null;
};

export default Stats;
