import { format } from 'date-fns';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, FormField } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';
import { Audit, AuditCheckpoint, AuditCustomFieldDate, AuditCustomFieldDateValue, AuditCustomFieldMultipleChoiceValue, AuditCustomFieldNotSet, AuditCustomFieldNumber, AuditCustomFieldNumberValue, AuditCustomFieldOptionIds, AuditCustomFieldText, AuditCustomFieldTextValue, AuditCustomFieldValue, SetCustomFieldDate, SetCustomFieldDateValue, SetCustomFieldNumber, SetCustomFieldNumberValue, SetCustomFieldOptionIds, SetCustomFieldText, SetCustomFieldTextValue, SetFieldMultipleChoiceValue, TextWithTranslation } from '../../../../backend_api_2';
import DatePicker3 from '../../../../base/components/basic/DatePicker3';
import Icon from '../../../../base/components/basic/Icon';
import Input from '../../../../base/components/basic/Input';
import { getLocaleLanguageString, isUndefinedOrNull, isUndefinedOrNullOrEmptyString } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { searchOptionTextAndDescription } from '../../../inspections/Utils';
import { updateProductionUnitCustomFieldCheckpoint, updateSupplierCustomFieldCheckpoint } from '../../slices/auditSlice';
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { AuditCheckpointTitleAndActionMenu, isAuditImmutable } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';
import { doesCheckpointRequireMandatoryInput } from './mandatory';

export function ProductionUnitCustomFieldCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const customFields = checkpoint?.production_unit_custom_fields;
    const requiresInput = doesCheckpointRequireMandatoryInput(checkpoint);

    const dispatch = useAppDispatch();

    function updateCustomField(customField: AuditCustomFieldValue, newValue: (SetCustomFieldTextValue | SetCustomFieldDateValue | SetCustomFieldNumberValue | SetFieldMultipleChoiceValue)) {
        dispatch(updateProductionUnitCustomFieldCheckpoint({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            custom_field_id: customField.id,
            new_value: newValue
        }))
    }

    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
            <div>
                {
                    customFields?.map(customField => {
                        switch(customField.value.type) {
                            case AuditCustomFieldTextValue.type.TEXT:
                                return <TextCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldNumberValue.type.NUMBER:
                                return <NumberCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldDateValue.type.DATE:
                                return <DateCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldMultipleChoiceValue.type.MULTIPLE_CHOICE:
                                return <MultipleChoiceCustomField audit={audit} customField={customField} doUpdate={updateCustomField} requiresInput={triedToSubmit && requiresInput}/>
                        }
                    })
                }
            </div>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
        </div>
    )
}

function TextCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldTextValue) => void,
    requiresInput: boolean
}) {
    const {audit, customField, doUpdate, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);

    const value = customField.value as AuditCustomFieldTextValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldText).text;
    const [textValue, setTextValue] = React.useState(hasNewValue ? newValue : previousValue);

    function updateCustomField(e) {
        const value = e.currentTarget.value;
        const shouldUpdateValue = !isUndefinedOrNull(value);
        if(shouldUpdateValue) {
            doUpdate(customField, {
                type: SetCustomFieldTextValue.type.TEXT,
                new_value: {
                    type: SetCustomFieldText.type.TEXT,
                    text: e.currentTarget.value
                }
            })
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldTextValue.type.TEXT,
            new_value: {
                type: SetCustomFieldText.type.TEXT,
                text: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-col w-full sm:w-2/3'>
                <div className='flex flex-row justify-between items-center'>
                    <label className='text-sm text-secondary mb-1' htmlFor={customField.id}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                    <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput}/>
                </div>
                {
                    requiresInput && !previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field'/>
                    </div>
                }

                {
                    requiresInput && !!previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center'>
                            <Icon name='error' className='text-lg mr-1' />
                            <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm'/>
                        </div>
                        <ConfirmButton onClick={confirmCustomField} />
                    </div>
                }
            </div>
            
            <Input
                id={customField.id}
                inputClass='w-full sm:w-2/3'
                type="text"
                name="street"
                onBlur={updateCustomField}
                iconPosition="left"
                iconClass="text-brand w-12 justify-center flex"
                value={textValue}
                onChange={(e) => setTextValue(e.currentTarget.value)}
                disabled={isAuditImmutable(audit)}
            />
        </FormField>
    )
}

function NumberCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldNumberValue) => void,
    requiresInput: boolean
}) {
    const {audit, customField, doUpdate, requiresInput} = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);
    const value = customField.value as AuditCustomFieldNumberValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldNumber).number;

    const [numberValue, setNumberValue] = React.useState(hasNewValue ? newValue : previousValue);
    const invalidInput = (previousValue || hasNewValue) && (isUndefinedOrNullOrEmptyString(numberValue) || isNaN(Number(numberValue)));

    const elementId = uuidv1();
    
    function updateCustomField(e) {
        const value = e.currentTarget.value;
        const isValidNumber = !isUndefinedOrNullOrEmptyString(value);
        if(isValidNumber) {
            doUpdate(customField, {
                type: SetCustomFieldNumberValue.type.NUMBER,
                new_value: {
                    type: SetCustomFieldNumber.type.NUMBER,
                    number: value
                }
            })
        } else {
            setNumberValue(hasNewValue ? newValue : previousValue);
        }
    }

    function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key;
        if(key == 'e' || key == 'E' || key == '+' || key == ',') {
            return e.preventDefault();
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldNumberValue.type.NUMBER,
            new_value: {
                type: SetCustomFieldNumber.type.NUMBER,
                number: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3'>
                <label className='text-sm text-secondary mb-1' htmlFor={elementId}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput}/>
            </div>
            {
                requiresInput && !previousValue && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field'/>
                </div>
            }

            {
                requiresInput && !!previousValue && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row justify-between items-center w-full sm:w-2/3'>
                    <div className='flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm'/>
                    </div>
                    <ConfirmButton onClick={confirmCustomField} />
                </div>
            }

            {
                invalidInput &&
                <div className='flex flex-row items-center text-status-rejected text-sm w-full sm:w-2/3'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.invalid_number'/>
                </div>
            }
            
            <Input
                id={elementId}
                inputClass='w-full sm:w-2/3'
                type="number"
                onChange={(e) => setNumberValue(e.target.value)}
                onKeyDown={handleOnKeyDown}
                value={numberValue}
                onBlur={updateCustomField}
                disabled={isAuditImmutable(audit)}
            />
        </FormField>
    )
}

function DateCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetCustomFieldDateValue) => void,
    requiresInput: boolean
}) {
    const { audit, customField, doUpdate, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);

    const value = customField.value as AuditCustomFieldDateValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldDate)?.date;
    const elementId = uuidv1();

    function updateCustomField(date: Date) {
        if(date) {
            const newDate = format(date, 'yyyy-MM-dd');
            doUpdate(customField, {
                type: SetCustomFieldDateValue.type.DATE,
                new_value: {
                    type: SetCustomFieldDate.type.DATE,
                    date: newDate
                }
            })
        }
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetCustomFieldDateValue.type.DATE,
            new_value: {
                type: SetCustomFieldDate.type.DATE,
                date: previousValue
            }
        })
    }

    return (
        <FormField>
            <div className='flex flex-col w-full sm:w-2/3 pb-4'>
                <div className='flex flex-row justify-between items-center'>
                    <label className='text-sm text-secondary mb-1' htmlFor={elementId}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                    <CFConfirmButton hasPreviousValue={!!previousValue} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput}/>
                </div>
                {
                    requiresInput && !previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field'/>
                    </div>
                }

                {
                    requiresInput && !!previousValue && !hasNewValue &&
                    <div className='text-status-rejected text-sm flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center'>
                            <Icon name='error' className='text-lg mr-1' />
                            <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm'/>
                        </div>
                        <ConfirmButton onClick={confirmCustomField} />
                    </div>
                }

                <DatePicker3
                    id={elementId}
                    pastDates={true}
                    date={hasNewValue ? newValue : previousValue}                            
                    handleChange={updateCustomField}
                    disabled={isAuditImmutable(audit)}
                    name="pu-cf-date"
                />
            </div>
        </FormField>
    )
}

function MultipleChoiceCustomField(props: {
    audit: Audit,
    customField: AuditCustomFieldValue,
    doUpdate: (customField: AuditCustomFieldValue, newValue: SetFieldMultipleChoiceValue) => void,
    requiresInput: boolean
}) {
    const {audit, doUpdate, customField, requiresInput } = props;
    const intl = useIntl();
    const currentLocale = getLocaleLanguageString(intl.locale);
    const value = customField.value as AuditCustomFieldMultipleChoiceValue;
    const previousValue = value.previous_value;
    const hasNewValue = value.new_value.type != AuditCustomFieldNotSet.type.NOT_SET;
    const newValue = (value.new_value as AuditCustomFieldOptionIds).option_ids;

    const needsConfirm = requiresInput && previousValue?.length > 0 && !hasNewValue;

    const selectedOptionIds = hasNewValue ? newValue : previousValue;
    const multipleSelectionAllowed = value.multiple_choices_allowed;
    const options = value.options.map(o => {
        return {
            key: o.option_id,
            value: o.option_id,
            text: o.name.text,
            id: o.option_id
        }
    })

    function updateCustomField(options: string[]) {
        doUpdate(customField, {
            type: SetFieldMultipleChoiceValue.type.MULTIPLE_CHOICE,
            new_value: {
                type: SetCustomFieldOptionIds.type.OPTION_IDS,
                option_ids: options
            }
        })
    }

    function confirmCustomField() {
        doUpdate(customField, {
            type: SetFieldMultipleChoiceValue.type.MULTIPLE_CHOICE,
            new_value: {
                type: SetCustomFieldOptionIds.type.OPTION_IDS,
                option_ids: previousValue
            }
        })
    }

    return (
        <FormField className='pb-4'>
            <div className='flex flex-row justify-between items-center w-full sm:w-2/3'>
                <label className='text-sm text-secondary mb-1' htmlFor={customField.id}>{getTextWithTranslation(customField.custom_field_name, currentLocale)}</label>
                <CFConfirmButton hasPreviousValue={previousValue?.length > 0} hasNewValue={hasNewValue} onClick={confirmCustomField} showingAlert={requiresInput}/>
            </div>
            {
                requiresInput && previousValue?.length == 0 && !hasNewValue &&
                <div className='text-status-rejected text-sm flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.custom_field'/>
                </div>
            }

            {
                needsConfirm &&
                <div className='text-status-rejected text-sm flex flex-row justify-between items-center w-full sm:w-2/3'>
                    <div className='flex flex-row items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <FormattedMessage id='web_based_audit.mandatory_warning.custom_field.confirm'/>
                    </div>
                    <ConfirmButton onClick={confirmCustomField} />
                </div>
            }
            <Dropdown
                id={customField.id}
                className='flex flex-row w-full sm:w-2/3'
                selection
                disabled={isAuditImmutable(audit)}
                options={options}
                value={multipleSelectionAllowed ? selectedOptionIds : selectedOptionIds[0]}
                multiple={multipleSelectionAllowed}
                search={searchOptionTextAndDescription}
                placeholder={selectedOptionIds.length === 0 && intl.formatMessage({ id: 'globals.select' })}
                renderLabel={(item) => {
                    return ({
                        style: { color: 'white' },
                        className: 'selectLabel justify-between flex-0',
                        content: <div className='flex text-white'>{item.text}</div>,
                    });
                }}
                onChange={(e, option) => {
                    let value;
                    if (option.value !== null) {
                        value = multipleSelectionAllowed ? option.value : [option.value];
                    } else {
                        value = [];
                    }
                    updateCustomField(value)
                }}
            />
        </FormField>
    )
}

export function SupplierCustomFieldCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const customFields = checkpoint?.supplier_custom_fields;
    const dispatch = useAppDispatch();
    const requiresInput = doesCheckpointRequireMandatoryInput(checkpoint);

    function updateCustomField(customField: AuditCustomFieldValue, newValue: (SetCustomFieldTextValue | SetCustomFieldDateValue | SetCustomFieldNumberValue | SetFieldMultipleChoiceValue)) {
        dispatch(updateSupplierCustomFieldCheckpoint({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            custom_field_id: customField.id,
            new_value: newValue
        }))
    }
    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            <div>
                {
                    customFields?.map(customField => {
                        switch(customField.value.type) {
                            case AuditCustomFieldTextValue.type.TEXT:
                                return <TextCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldNumberValue.type.NUMBER:
                                return <NumberCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldDateValue.type.DATE:
                                return <DateCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput}/>
                            case AuditCustomFieldMultipleChoiceValue.type.MULTIPLE_CHOICE:
                                return <MultipleChoiceCustomField audit={audit} doUpdate={updateCustomField} customField={customField} requiresInput={triedToSubmit && requiresInput}/>
                        }
                    })
                }
            </div>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
        </div>
    )
}

function CFConfirmButton(props: {
    hasPreviousValue: boolean,
    hasNewValue: boolean,
    onClick: () => void,
    showingAlert: boolean
}) {
    const {hasPreviousValue, hasNewValue, onClick, showingAlert} = props;

    if(!hasPreviousValue) {
        return null;
    }

    if(hasNewValue) {
        return <Icon name='check_circle' className='text-brand text-xl'/>
    }

    if(showingAlert) {
        return null;
    }

    return <ConfirmButton onClick={onClick}/>
}

function ConfirmButton(props: {
    onClick: () => void
}) {
    const {onClick} = props;
    return <div className='flex flex-row text-link-color cursor-pointer rounded' onClick={onClick}>
        <FormattedMessage id='web_based_audit.buttons.confirm' />
    </div>
}

function getTextWithTranslation(text: TextWithTranslation, currentLocale: string): string {
    if(text.language == currentLocale) {
        return text.text;
    }

    return text.translations[currentLocale] || text.text;
}