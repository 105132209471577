import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import SuppliersListNew from './SuppliersListNew';


type Props = {
    className?: string;
};

const SuppliersContainer2 = (props: Props): React.ReactElement => {
    const { className } = props;
    return <div className={twMerge('', className)}>

        <Switch>
            <Route path='/suppliers' render={(): React.ReactElement => {
                return <SuppliersListNew />;
            }} />
        </Switch >
    </div >
}
export default SuppliersContainer2;
