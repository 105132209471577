import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Header, Popup } from 'semantic-ui-react';
import Icon from '../../../../base/components/basic/Icon';
import { isStringAnUrl } from '../../../../base/utils';
import { CustomFieldValue } from '../../../filters/types';
import QInput from './formelements/QInput';

type CustomFieldsProps = {
    customFields: CustomFieldValue[];
    inspectionId: string;
    visible: boolean;
    disabled?: boolean;
    handleBlur(event: React.SyntheticEvent, item: { name: string; value: any }, inspectionId: string): void;
};

export const CustomFields = (props: CustomFieldsProps): React.ReactElement => {
    const customFields = props.customFields;
    const inspectionId = props.inspectionId;
    const disabled = props.disabled || false;

    if (customFields && customFields.length > 0) {
        return (
            <div className={['editCustomFields ', props.visible ? 'show' : 'hide'].join(' ')}>
                <Header as='h4'><FormattedMessage id='edit.additional_info' /></Header>
                <Form>
                    {customFields.map((field) => {
                        return (
                            <span key={'CustomField' + field.id} className='flex items-top'>
                                <Form.Group inline >
                                    <Form.Field width={16}>
                                        <label className='label'>{field.name}</label>
                                        <QInput
                                            autoHeight={true}
                                            type='textarea'
                                            placeholder=''
                                            name={field.name}
                                            id={field.id}
                                            value={field.value}
                                            handleBlur={(evt): void => {
                                                let hasChanged = false;
                                                const item = evt.currentTarget as any;
                                                const id = field.id;
                                                customFields.forEach((cf) => {
                                                    if (cf.id === id && field.value !== item.value) {
                                                        cf.value = item.value;
                                                        hasChanged = true;
                                                    }
                                                });
                                                if (hasChanged) {
                                                    props.handleBlur(undefined, { name: 'custom_field_values', value: customFields }, inspectionId);
                                                }
                                            }}
                                            readOnly={disabled}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                {isStringAnUrl(field.value) && <Popup trigger={<a href={field.value}><Icon className='-mx-4 border-l-8 border-transparent pt-2 text-gray-400 hover:text-secondary text-2xl' name={'open_in_new'} /></a>}>
                                    <FormattedMessage id='edit_inspection.custom_fields.click_to_open_link' />
                                </Popup>}

                            </span>);
                    })}
                </Form>
            </div>
        );
    }
    return (null);
};
