/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventBooleanChange,
    EventBooleanChangeFromJSON,
    EventBooleanChangeFromJSONTyped,
    EventBooleanChangeToJSON,
    EventDateChange,
    EventDateChangeFromJSON,
    EventDateChangeFromJSONTyped,
    EventDateChangeToJSON,
    EventStringChange,
    EventStringChangeFromJSON,
    EventStringChangeFromJSONTyped,
    EventStringChangeToJSON,
    EventStringListChange,
    EventStringListChangeFromJSON,
    EventStringListChangeFromJSONTyped,
    EventStringListChangeToJSON,
    EventUserChange,
    EventUserChangeFromJSON,
    EventUserChangeFromJSONTyped,
    EventUserChangeToJSON,
} from './';

/**
 * Describes the editing of a CA.
 * @export
 * @interface CorrectiveActionEditedEvent
 */
export interface CorrectiveActionEditedEvent {
    /**
     * 
     * @type {EventUserChange}
     * @memberof CorrectiveActionEditedEvent
     */
    approver_change?: EventUserChange;
    /**
     * 
     * @type {EventStringChange}
     * @memberof CorrectiveActionEditedEvent
     */
    description_change?: EventStringChange;
    /**
     * 
     * @type {EventDateChange}
     * @memberof CorrectiveActionEditedEvent
     */
    due_date_change?: EventDateChange;
    /**
     * 
     * @type {EventStringChange}
     * @memberof CorrectiveActionEditedEvent
     */
    finding_change?: EventStringChange;
    /**
     * 
     * @type {EventStringListChange}
     * @memberof CorrectiveActionEditedEvent
     */
    groups_change?: EventStringListChange;
    /**
     * 
     * @type {EventBooleanChange}
     * @memberof CorrectiveActionEditedEvent
     */
    requires_evidence_from_app_change?: EventBooleanChange;
    /**
     * 
     * @type {EventUserChange}
     * @memberof CorrectiveActionEditedEvent
     */
    responsible_change?: EventUserChange;
}

export function CorrectiveActionEditedEventFromJSON(json: any): CorrectiveActionEditedEvent {
    return CorrectiveActionEditedEventFromJSONTyped(json, false);
}

export function CorrectiveActionEditedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionEditedEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver_change': !exists(json, 'approver_change') ? undefined : EventUserChangeFromJSON(json['approver_change']),
        'description_change': !exists(json, 'description_change') ? undefined : EventStringChangeFromJSON(json['description_change']),
        'due_date_change': !exists(json, 'due_date_change') ? undefined : EventDateChangeFromJSON(json['due_date_change']),
        'finding_change': !exists(json, 'finding_change') ? undefined : EventStringChangeFromJSON(json['finding_change']),
        'groups_change': !exists(json, 'groups_change') ? undefined : EventStringListChangeFromJSON(json['groups_change']),
        'requires_evidence_from_app_change': !exists(json, 'requires_evidence_from_app_change') ? undefined : EventBooleanChangeFromJSON(json['requires_evidence_from_app_change']),
        'responsible_change': !exists(json, 'responsible_change') ? undefined : EventUserChangeFromJSON(json['responsible_change']),
    };
}

export function CorrectiveActionEditedEventToJSON(value?: CorrectiveActionEditedEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver_change': EventUserChangeToJSON(value.approver_change),
        'description_change': EventStringChangeToJSON(value.description_change),
        'due_date_change': EventDateChangeToJSON(value.due_date_change),
        'finding_change': EventStringChangeToJSON(value.finding_change),
        'groups_change': EventStringListChangeToJSON(value.groups_change),
        'requires_evidence_from_app_change': EventBooleanChangeToJSON(value.requires_evidence_from_app_change),
        'responsible_change': EventUserChangeToJSON(value.responsible_change),
    };
}


