import React from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { TextWithTranslation } from '../../../backend_api/models';
import { getCurrentLanguage } from '../../../base/utils';
import { showTranslatedCommentsSelector } from '../../comments/selectors';

type Props = {
    text: TextWithTranslation
    className?: string
    placeholder?: string
}

const TranslatedLabel = (props: Props) => {
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    let isTranslated = false
    let text = ''
    let className = 'flex flex-row'
    let chameleonId = ''

    if (props.text && props.text.text) {
        const currentLanguage = getCurrentLanguage()
        const canTranslate = currentLanguage != props.text.language && !!props.text.language && !!props.text.translations[currentLanguage]
        if (showTranslatedComments) {
            isTranslated = canTranslate
            text = props.text.translations[currentLanguage] || props.text.text
        }
        else {
            if (canTranslate) {
                chameleonId = 'translation'
            }
            text = props.text.text
        }
    }
    else if (props.placeholder) {
        text = props.placeholder
        className = className + ' italic text-gray-500'
    }
    if (isTranslated) {
        className = className + ' border-dashed border-b'
    }
    const textElement = <div chameleon-id={chameleonId} className={className}>{text}</div>
    return <Popup
        trigger={textElement}
        disabled={!isTranslated}
    >
        <Popup.Content>{props.text && props.text.text}</Popup.Content>
    </Popup>
}

export default TranslatedLabel