/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * History entry import job reference
 *
 */
export type HistoryEntryImportJobReference = {
    import_job_id: string;
    source_reference_type: HistoryEntryImportJobReference.source_reference_type;
};

export namespace HistoryEntryImportJobReference {

    export enum source_reference_type {
        AUDIT = 'audit',
        USER = 'user',
        IMPORT_JOB = 'import_job',
    }


}

