/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a string that represents a number
 *
 */
export type SetCustomFieldNumber = {
    number: string;
    type: SetCustomFieldNumber.type;
};

export namespace SetCustomFieldNumber {

    export enum type {
        NUMBER = 'number',
    }


}

