import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import { Inspection } from '../../types';

type SupplierQCSelectorProps = {
    inspection: Inspection;
    label?: string;
    disabled?: boolean;
    handleChange(e, item, inspectionId: string);
};

const SupplierQCSelector = (props: SupplierQCSelectorProps): React.ReactElement => {
    const { inspection, disabled } = props;
    const label = props.label ? props.label : '';

    return <HasReadonlyAccess type='inspection' actionId='scheduled_inspection_date' inspection={inspection}>
        <Checkbox disabled={disabled || inspection.assigned_user_id !== null} name='supplier_qc' checked={inspection.supplier_qc} label={label} onChange={
            (): void => props.handleChange(null, { name: 'supplier_qc', value: !props.inspection.supplier_qc }, inspection.inspection_id)
        } /></HasReadonlyAccess>;
};

export default SupplierQCSelector;
