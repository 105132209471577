import { createSelector } from 'reselect';
import { AppState } from '../../base/types';
import { Result } from './model';

const resultSelector = (state): Result => state.app.search.result;
const querySelector = (state: AppState): string => {
    if (state.app.filters.active.search) {
        return state.app.filters.active.search[0];
    }
    return '';
};

export const getResult: any = createSelector(
    [resultSelector],
    (result): Result => result,
);

export const getQuery = createSelector(
    [querySelector],
    (query): string => query,
);
