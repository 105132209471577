import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownFilter } from '.';
import { AuditType } from '../../../backend_api/models';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditTypesSelector } from '../../audit/selectors/auditSelectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type Props = {
    showLabel?: boolean;
    handleChange?(args): void;
};

const AuditTypesFilter = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [options, setOptions] = useState([]);

    const { showLabel = true, handleChange } = props;
    const selectedTypes = getLocationEntry(location, 'audit_type');
    const auditTypes = useSelector(getAuditTypesSelector);
    const getOptions = () => {
        const options = [];
        auditTypes.forEach((auditType) => {
            options.push({ value: auditType.id, text: auditType.name, id: auditType.id });
        });
        return options;
    }
    useEffect(() => {
        setOptions(getOptions());
    }, [auditTypes])
    return (
        <Filter labelId={showLabel ? 'Audit types' : null}>
            <DropdownFilter
                name='audit_type'
                options={options}
                multiple={true}
                placeholder={intl.formatMessage({ id: 'audits.select_audit_type' })}
                selectedItems={selectedTypes || []}
                handleSave={(type: AuditType[]): void => {
                    dispatch(setFilter('audit_type', type.map((t) => t)));
                    if (handleChange) {
                        handleChange(type);
                    }
                }}
                inline={false}
            />
        </Filter>);
};

export default AuditTypesFilter;
