import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Divider, Form, Grid, Header, Icon, Modal, TextArea } from 'semantic-ui-react';
import { getLocaleSpecificString } from '../../../base/utils';
import { getApprovalConclusions } from '../../approval/selectors';
import { CustomConclusion } from '../../approval/types';

type props = {
    showModal: boolean;
    notDoneFlowsCnt: number;
    isMobile: boolean;
    conclusion: CustomConclusion;
    handleClose();
    handleCancel();
    handleSave(conclusion: CustomConclusion, comment: string);
}

const ChangeConclusionDialog = (props: props): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const [reason, setReason] = useState<string>();
    const conclusions = useSelector(getApprovalConclusions)
    const [conclusion, setConclusion] = useState<CustomConclusion>(props.conclusion);

    useEffect(() => {
        setConclusion(props.conclusion)
    }, [props.conclusion])

    const setConclusionFromId = (id: string): void => {
        conclusions.forEach((conclusion) => {
            if (conclusion.id === id) {
                setConclusion(conclusion)
            }
        })
    }

    const formData = <Fragment><Header as='h4'><FormattedMessage id='inspections.status' /></Header>
        <Form horizontal>
            {conclusions && conclusions.map((theConclusion) => {
                return (
                    <Form.Radio
                        key={theConclusion.id}
                        name='conclusion'
                        checked={theConclusion.id === conclusion.id}
                        onChange={(evt, item): void => {
                            setConclusionFromId(item.value as string)
                        }}
                        value={theConclusion.id}
                        label={getLocaleSpecificString(theConclusion.full_name)}
                    />
                );
            })}
        </Form></Fragment>

    const reasonData = <Fragment>
        <Header as='h4'><FormattedMessage id='inspections.reason' /></Header>
        <Form>
            <TextArea autoHeight={true} name='reason' value={reason} onChange={(evt, item): void => setReason(item.value as string)} rows={5} placeholder={formatMessage({ id: 'approval.enter_comment' })} />
        </Form>
    </Fragment>
    return <Modal
        open={props.showModal}
        onClose={props.handleClose}
        closeIcon={true}
    >
        <Modal.Header>
            {props.notDoneFlowsCnt === 0 ? formatMessage({ id: 'approval.change_or_confirm_status' }) : formatMessage({ id: 'approval.status_cannot_be_changed' })}
        </Modal.Header>
        <Modal.Content>
            {props.notDoneFlowsCnt > 0 &&
                <div><Icon name='info circle' color='blue' />
                    <FormattedMessage id='inspections.unresolved_approval_steps' values={{ cnt: props.notDoneFlowsCnt, steps: props.notDoneFlowsCnt === 1 ? formatMessage({ id: 'inspections.step' }) : formatMessage({ id: 'inspections.steps' }) }} />
                    <FormattedMessage id='inspections.resolved_the_status_cannot_be_altered' values={{ theyAre: props.notDoneFlowsCnt === 1 ? formatMessage({ id: 'inspections.itIs' }) : formatMessage({ id: 'inspections.theyAre' }) }} />
                </div>
            }
            {props.notDoneFlowsCnt === 0 && (!props.isMobile ? (<Grid columns={2}>
                <Grid.Column width={4}>
                    {formData}
                </Grid.Column>
                <Grid.Column width={9}>
                    {reasonData}
                </Grid.Column>
            </Grid>) : (<Grid>
                <Grid.Column>
                    <Grid.Row>
                        {formData}
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row>
                        {reasonData}
                    </Grid.Row>
                </Grid.Column>
            </Grid>))}
        </Modal.Content>
        <Modal.Actions>
            {props.notDoneFlowsCnt === 0 && <Fragment><Button default onClick={(): void => props.handleSave(conclusion, reason)} positive data-test-id={'btn-modal-save'}><FormattedMessage id='globals.save' /></Button>
                <Button onClick={props.handleCancel}><FormattedMessage id='globals.cancel' /></Button></Fragment>}
            {props.notDoneFlowsCnt > 0 && <Button onClick={props.handleCancel}><FormattedMessage id='inspections.close' /></Button>}
        </Modal.Actions>
    </Modal>
}

export default ChangeConclusionDialog;