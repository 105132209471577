/*===========================================
=            Util action types            =
===========================================*/
import { Locale } from '../globals';

export const SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES';
// tslint:disable-next-line:interface-over-type-literal
export type SET_DEFAULT_VALUES = {
    locale: { default: string; locales: [string, string] }; project: { user: { type: string; foo: string } };
};
export const SET_LOCALE = 'SET_LOCALE';
// tslint:disable-next-line:interface-over-type-literal
export type SET_LOCALE = { locale: Locale };
export const FETCHING_DATA = 'FETCHING_DATA';
// tslint:disable-next-line:interface-over-type-literal
export type FETCHING_DATA = { isFetching: boolean };
export const NAVIGATION_DATA_LOAD = 'NAVIGATION_DATA_LOAD';

export const SAVING_DATA = 'SAVING_DATA';
// tslint:disable-next-line:interface-over-type-literal
export type SAVING_DATA = { isSaving: boolean };

export const SAVING_DATA_DONE = 'SAVING_DATA_DONE';
// tslint:disable-next-line:interface-over-type-literal
export type SAVING_DATA_DONE = { isSaving: boolean };

