import React, { ChangeEvent, KeyboardEventHandler, LegacyRef } from 'react';
import { twMerge } from '../../utils';


type InputProps = {
    name?: string;
    id?: string;
    className?: string;
    disabled?: boolean;
    icon?: string;
    iconClass?: string;
    inputClass?: string;
    iconPosition?: 'left' | 'right';
    loading?: boolean;
    label?: any;
    labelPosition?: '' | 'left' | 'right';
    readOnly?: boolean;
    autoFocus?: boolean;
    type: 'text' | 'number' | 'password' | 'email';
    value?: string | number;
    tabIndex?: number;
    placeholder?: string;
    placeholderIcon?: string;
    ref?: LegacyRef<any>; // TODO: fix LegacyRef<any>
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onKeyDown?: (KeyboardEventHandler<HTMLInputElement>);
    onKeyUp?: (KeyboardEventHandler<HTMLInputElement>);
    onIconClick?: React.MouseEventHandler<HTMLInputElement>;
};

const Input = (props: InputProps): React.ReactElement => {
    const { id, className, inputClass, icon, label, iconPosition = 'left', iconClass, labelPosition, placeholderIcon, ...p } = props;
    const cn = 'bg-white rounded w-full border-input border py-3 px-3 outline-none text-primary';
    if (icon && label) {
        return <div className={twMerge('flex rounded-md border border-input bg-white items-stretch', labelPosition === 'right' && 'flex-row-reverse', iconPosition === 'right' && 'flex-row-reverse', className)}>
            <div className={twMerge('text-secondary flex rounded items-center  pr-2 pl-3 text-sm m-0 py-0 whitespace-nowrap', labelPosition === 'right' ? 'rounded-l-none border-l' : 'rounded-r-none border-r', label.props && label.props.className)}>{label}</div>
            {
                placeholderIcon ? <i className={twMerge('mi text-2xl px-2 py-1 text-gray-500')}>{placeholderIcon}</i> : null
            }
            <input ref={props.inputRef} className={twMerge('bg-white px-2 py-3 border-0 w-full outline-none mx-1', cn, inputClass)} {...p} autoComplete='off' />
            <i className={twMerge('mi text-2xl px-2 py-1 text-gray-500 border-input', iconPosition === 'right' ? 'border-l' : 'border-r', iconClass)}>{icon}</i>
        </div>;
    }
    if (icon) {
        return <div className={twMerge('flex rounded border border-input bg-white w-full', iconPosition === 'right' && 'flex-row-reverse', className)}>
            <i onClick={props.onIconClick && props.onIconClick} className={twMerge('mi text-2xl px-2 py-1 items-center justify-center text-gray-500 border-input', iconPosition === 'right' ? 'border-l' : 'border-r', iconClass)}>{icon}</i>
            <input ref={props.inputRef} className={twMerge('px-2 py-3 outline-none border-none', cn,  inputClass)} {...p} autoComplete='off' style={{ border: 0, marginLeft: '5px', width: '100' }} />
            {
                placeholderIcon ? <i className={twMerge('mi text-2xl px-2 py-1 text-gray-500')}>{placeholderIcon}</i> : null
            }
        </div>;
    }
    if (label) {
        return <div className={twMerge('flex rounded-md border border-input bg-white items-stretch', labelPosition === 'right' && 'flex-row-reverse', className)}>
            <div className={twMerge('text-secondary flex rounded items-center  pr-2 pl-3 text-sm m-0 py-0 whitespace-nowrap', labelPosition === 'right' ? 'rounded-l-none border-l' : 'rounded-r-none border-r', label.props && label.props.className)}>{label}</div>
            <input id={id} ref={props.inputRef} className={twMerge('bg-white px-2 py-3 border-0 w-full outline-none', labelPosition === 'right' ? 'ml-1 ' : 'mr-1', inputClass)} {...p} autoComplete='off' />
        </div>;
    }
    return <input id={id} ref={props.inputRef} autoComplete='off' className={twMerge(cn, inputClass)} {...p} />;

}
export default Input;
