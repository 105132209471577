/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 99.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditApprovalFlow,
    AuditApprovalFlowFromJSON,
    AuditApprovalFlowFromJSONTyped,
    AuditApprovalFlowToJSON,
    AuditCustomConclusion,
    AuditCustomConclusionFromJSON,
    AuditCustomConclusionFromJSONTyped,
    AuditCustomConclusionToJSON,
    AuditStatus,
    AuditStatusFromJSON,
    AuditStatusFromJSONTyped,
    AuditStatusToJSON,
    AuditType,
    AuditTypeFromJSON,
    AuditTypeFromJSONTyped,
    AuditTypeToJSON,
    AuditingBody,
    AuditingBodyFromJSON,
    AuditingBodyFromJSONTyped,
    AuditingBodyToJSON,
    BasicUserOptional,
    BasicUserOptionalFromJSON,
    BasicUserOptionalFromJSONTyped,
    BasicUserOptionalToJSON,
    Comment,
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
    CorrectiveActionsStatuses,
    CorrectiveActionsStatusesFromJSON,
    CorrectiveActionsStatusesFromJSONTyped,
    CorrectiveActionsStatusesToJSON,
    FileResource,
    FileResourceFromJSON,
    FileResourceFromJSONTyped,
    FileResourceToJSON,
    ProductionUnit,
    ProductionUnitFromJSON,
    ProductionUnitFromJSONTyped,
    ProductionUnitToJSON,
    ReportLocation,
    ReportLocationFromJSON,
    ReportLocationFromJSONTyped,
    ReportLocationToJSON,
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * Supplier Audit.
 * `id`, `status`, `start_time`, and `end_time` fields are read-only at all times. They are maintained by the system.
 * If the audit is in any other state than `planned`, all fields are read-only.
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * 
     * @type {AuditApprovalFlow}
     * @memberof Audit
     */
    approval_flow?: AuditApprovalFlow | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    approval_flow_status?: string | null;
    /**
     * 
     * @type {BasicUserOptional}
     * @memberof Audit
     */
    assigned_user?: BasicUserOptional | null;
    /**
     * 
     * @type {Array<FileResource>}
     * @memberof Audit
     */
    attachments?: Array<FileResource>;
    /**
     * 
     * @type {AuditType}
     * @memberof Audit
     */
    audit_type?: AuditType | null;
    /**
     * 
     * @type {AuditingBody}
     * @memberof Audit
     */
    auditing_body?: AuditingBody | null;
    /**
     * 
     * @type {Comment}
     * @memberof Audit
     */
    auditor_comment?: Comment | null;
    /**
     * 
     * @type {AuditCustomConclusion}
     * @memberof Audit
     */
    auditor_conclusion?: AuditCustomConclusion | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    checklist_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    checklist_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Audit
     */
    checklist_revision?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Audit
     */
    checklist_revision_timestamp?: Date | null;
    /**
     * 
     * @type {CorrectiveActionsStatuses}
     * @memberof Audit
     */
    corrective_actions_status?: CorrectiveActionsStatuses;
    /**
     * 
     * @type {Date}
     * @memberof Audit
     */
    end_time?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Audit
     */
    features?: Array<AuditFeaturesEnum>;
    /**
     * 
     * @type {AuditCustomConclusion}
     * @memberof Audit
     */
    final_conclusion?: AuditCustomConclusion | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    id?: string | null;
    /**
     * 
     * @type {ReportLocation}
     * @memberof Audit
     */
    last_tracked_location?: ReportLocation | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    location_country?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Audit
     */
    locked?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Audit
     */
    planned_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    source_type?: AuditSourceTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Audit
     */
    start_time?: Date | null;
    /**
     * 
     * @type {AuditStatus}
     * @memberof Audit
     */
    status?: AuditStatus;
    /**
     * 
     * @type {ProductionUnit}
     * @memberof Audit
     */
    target_production_unit?: ProductionUnit | null;
    /**
     * 
     * @type {Supplier}
     * @memberof Audit
     */
    target_supplier: Supplier | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Audit
     */
    watchers?: Array<User>;
}

/**
* @export
* @enum {string}
*/
export enum AuditFeaturesEnum {
    Edit = 'edit',
    Delete = 'delete',
    Read = 'read',
    Perform = 'perform',
    SetFinalConclusion = 'set_final_conclusion',
    EditApprovalFlow = 'edit_approval_flow',
    BehalfOf = 'behalf_of',
    AssignAuditViaLink = 'assign_audit_via_link'
}/**
* @export
* @enum {string}
*/
export enum AuditSourceTypeEnum {
    User = 'user',
    Importer = 'importer',
    Pdf = 'PDF'
}

export function AuditFromJSON(json: any): Audit {
    return AuditFromJSONTyped(json, false);
}

export function AuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Audit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approval_flow': !exists(json, 'approval_flow') ? undefined : AuditApprovalFlowFromJSON(json['approval_flow']),
        'approval_flow_status': !exists(json, 'approval_flow_status') ? undefined : json['approval_flow_status'],
        'assigned_user': !exists(json, 'assigned_user') ? undefined : BasicUserOptionalFromJSON(json['assigned_user']),
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(FileResourceFromJSON)),
        'audit_type': !exists(json, 'audit_type') ? undefined : AuditTypeFromJSON(json['audit_type']),
        'auditing_body': !exists(json, 'auditing_body') ? undefined : AuditingBodyFromJSON(json['auditing_body']),
        'auditor_comment': !exists(json, 'auditor_comment') ? undefined : CommentFromJSON(json['auditor_comment']),
        'auditor_conclusion': !exists(json, 'auditor_conclusion') ? undefined : AuditCustomConclusionFromJSON(json['auditor_conclusion']),
        'checklist_id': !exists(json, 'checklist_id') ? undefined : json['checklist_id'],
        'checklist_name': !exists(json, 'checklist_name') ? undefined : json['checklist_name'],
        'checklist_revision': !exists(json, 'checklist_revision') ? undefined : json['checklist_revision'],
        'checklist_revision_timestamp': !exists(json, 'checklist_revision_timestamp') ? undefined : (json['checklist_revision_timestamp'] === null ? null : new Date(json['checklist_revision_timestamp'])),
        'corrective_actions_status': !exists(json, 'corrective_actions_status') ? undefined : CorrectiveActionsStatusesFromJSON(json['corrective_actions_status']),
        'end_time': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'features': !exists(json, 'features') ? undefined : json['features'],
        'final_conclusion': !exists(json, 'final_conclusion') ? undefined : AuditCustomConclusionFromJSON(json['final_conclusion']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'last_tracked_location': !exists(json, 'last_tracked_location') ? undefined : ReportLocationFromJSON(json['last_tracked_location']),
        'location_country': !exists(json, 'location_country') ? undefined : json['location_country'],
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
        'planned_date': !exists(json, 'planned_date') ? undefined : (json['planned_date'] === null ? null : new Date(json['planned_date'])),
        'source_type': !exists(json, 'source_type') ? undefined : json['source_type'],
        'start_time': !exists(json, 'start_time') ? undefined : (json['start_time'] === null ? null : new Date(json['start_time'])),
        'status': !exists(json, 'status') ? undefined : AuditStatusFromJSON(json['status']),
        'target_production_unit': !exists(json, 'target_production_unit') ? undefined : ProductionUnitFromJSON(json['target_production_unit']),
        'target_supplier': SupplierFromJSON(json['target_supplier']),
        'watchers': !exists(json, 'watchers') ? undefined : ((json['watchers'] as Array<any>).map(UserFromJSON)),
    };
}

export function AuditToJSON(value?: Audit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approval_flow': AuditApprovalFlowToJSON(value.approval_flow),
        'approval_flow_status': value.approval_flow_status,
        'assigned_user': BasicUserOptionalToJSON(value.assigned_user),
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(FileResourceToJSON)),
        'audit_type': AuditTypeToJSON(value.audit_type),
        'auditing_body': AuditingBodyToJSON(value.auditing_body),
        'auditor_comment': CommentToJSON(value.auditor_comment),
        'auditor_conclusion': AuditCustomConclusionToJSON(value.auditor_conclusion),
        'checklist_id': value.checklist_id,
        'checklist_name': value.checklist_name,
        'checklist_revision': value.checklist_revision,
        'checklist_revision_timestamp': value.checklist_revision_timestamp === undefined ? undefined : (value.checklist_revision_timestamp === null ? null : value.checklist_revision_timestamp.toISOString()),
        'corrective_actions_status': CorrectiveActionsStatusesToJSON(value.corrective_actions_status),
        'end_time': value.end_time === undefined ? undefined : (value.end_time === null ? null : value.end_time.toISOString()),
        'features': value.features,
        'final_conclusion': AuditCustomConclusionToJSON(value.final_conclusion),
        'id': value.id,
        'last_tracked_location': ReportLocationToJSON(value.last_tracked_location),
        'location_country': value.location_country,
        'locked': value.locked,
        'planned_date': value.planned_date === undefined ? undefined : (value.planned_date === null ? null : value.planned_date.toISOString().substr(0,10)),
        'source_type': value.source_type,
        'start_time': value.start_time === undefined ? undefined : (value.start_time === null ? null : value.start_time.toISOString()),
        'status': AuditStatusToJSON(value.status),
        'target_production_unit': ProductionUnitToJSON(value.target_production_unit),
        'target_supplier': SupplierToJSON(value.target_supplier),
        'watchers': value.watchers === undefined ? undefined : ((value.watchers as Array<any>).map(UserToJSON)),
    };
}


