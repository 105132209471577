import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { showLightBox } from '../../../../../base/baseSlice';
import Link from '../../../../../base/components/basic/Link';
import { AppState, DataType } from '../../../../../base/types';
import { useAppDispatch } from '../../../../../store';
import { getCommentsByTypeSelector } from '../../../../comments/selectors';
import { CommentTypes } from '../../../../comments/types';
import util from '../../../../comments/util';
import { Inspection } from '../../../types';
import ImageCommentsMeta from '../ImageCommentsMeta';
import PhotoOverlay from './PhotoOverlay';
import { useUrlQueForDownload } from './UrlQueHooks';

const IMAGE_WIDTH = 375;

type OwnProps = {
    imageURL: string;
    className?: string;
    imageClassName?: string;
    type: CommentTypes;
    typeId: string;
    inspection?: Inspection;
    imageComment?: React.ReactElement | string;
    dataType?: DataType;
    overlay?: { content?: ReactElement, className?: string };
}
type CheckpointImageProps = OwnProps;
const CheckpointImage = (props: CheckpointImageProps): React.ReactElement => {
    const { imageURL, inspection, type, typeId, imageComment, dataType = 'inspection', className, imageClassName, overlay } = props;
    const dispatch = useAppDispatch();
    const croppedURL = props.imageURL + '?fit=crop&w=' + IMAGE_WIDTH;
    const [url, urlDone] = useUrlQueForDownload(croppedURL);
    const anchorId = 'image_' + imageURL;
    const commentsOnImage = useSelector((state: AppState) => getCommentsByTypeSelector(state, type, typeId, imageURL));
    const imageCnt = util.getCommentsCnt(commentsOnImage);
    return (<div key={'checkpointImageItem_' + imageURL} className={twMerge('checkpointOrDefectPhoto box-border', className)}
        onClick={() => {
            dispatch(showLightBox({ show: true, url: imageURL}))
        }}>
        <PhotoOverlay overlayContent={overlay && overlay.content} overlayCss={overlay && overlay.className || undefined} />
        <a id={anchorId}></a>
        {/* NOTE: please do not remove the checkpointOrDefectPhoto or photoOverlay classes. They are used by the smoke test */}
        <Link className='box-border' to={'?imageURL=' + imageURL}>

            <img
                key={'image_' + imageURL}
                // rounded
                src={url}
                // size='large'
                width={'100%'}
                className={twMerge('h-auto rounded', imageClassName)}
                onLoad={urlDone}
            />
            {(imageCnt > 0 || (imageComment && imageComment !== '')) && <PhotoOverlay className='' bottom overlayContent={
                <ImageCommentsMeta
                    imageComment={imageComment}
                    commentsCnt={imageCnt}
                />} />}
        </Link>

        {/* </Lightbox> */}
    </div>);
}
export default CheckpointImage;
