/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * EntityAddedToField history event
 *
 */
export type HistoryEventEntityAddedToField = {
    entity_id: string;
    entity_name: string;
    entity_number: string;
    entity_type: HistoryEventEntityAddedToField.entity_type;
    field: HistoryEventEntityAddedToField.field;
};

export namespace HistoryEventEntityAddedToField {

    export enum entity_type {
        SUPPLIER = 'supplier',
        PRODUCTION_UNIT = 'production_unit',
    }

    export enum field {
        RELATED_SUPPLIERS = 'related_suppliers',
        PRODUCTION_UNITS = 'production_units',
        UPSTREAM_PRODUCTION_UNITS = 'upstream_production_units',
        DOWNSTREAM_PRODUCTION_UNITS = 'downstream_production_units',
    }


}

