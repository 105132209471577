import React, { Fragment } from 'react';
import { UserRole } from '../../../backend_api/models/UserRole';
import { UserRoleProperty } from '../../../backend_api/models/UserRoleProperty';
import AccessPropertyView from './AccessProperty';

export type DetailedRoleViewProps = {
    role: UserRole;
    toggleValue?(propertieId: string, accessId: string, scopeId: string): void;
    isEditable: boolean;
}

const DetailedRoleView = (props: DetailedRoleViewProps): React.ReactElement => {

    return <Fragment>
        {props.role && props.role.properties.map((property: UserRoleProperty, index: number) => {
            return <AccessPropertyView key={index} isEditable={props.isEditable} property={property} toggleValue={props.toggleValue} />
        })}
    </Fragment>;
}

export default DetailedRoleView;