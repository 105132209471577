import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useIsMobileDevice } from '../../../base/utils';

type PageContainerProps = {
    header?: string | (React.ReactElement | React.ReactElement[]);
    children?: React.ReactElement | React.ReactElement[];
    className?: string;
    headerClassName?: string;
}
const PageContainer = (props: PageContainerProps): React.ReactElement => {
    const cn = 'px-2 lg:px-2 md:mb-4 pt-2 mb-4 sm:pt-4';
    const isMobile = useIsMobileDevice();
    return <div className={twMerge('flex flex-col py-1 sm:py-4 sm:m-auto  w-full lg:max-w-page', props.className)}>
        {props.header && isMobile ? <h3 className={twMerge(cn, props.headerClassName)}>{props.header}</h3> : <h2 className={twMerge(cn, props.headerClassName)}>{props.header}</h2>}
        {props.children}
    </div>
}

export default PageContainer;
