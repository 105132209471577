import { handleActions } from 'redux-actions';
import { byId } from '../../base/types';
import { sortGroupsSelector } from '../groups/selectors';
import { GroupData } from '../groups/types';
import { Action } from './../../base/genericActions';
import * as types from './actions/actionTypes';
import { UsersState } from './models';


const initialState: UsersState = {
    isFetching: false,
    persons: [],
    createUserDialogOpen: false,
    byId: {},
    byGroupId: {},
    admin: {
        persons: [],
        byId: {},
    },
    error: undefined,
    groups: [],
    groupsById: {},
    locales: [],
    preferences: {},
    roles: [],
    orderSpecific: [],
    orderSpecificById: {},
    filters: {},
    filtersVisible: false,
    hiddenFilters: ['groups', 'roles', 'search'],
    activeHiddenFilters: [],
    currentPage: 1,
    lastPage: 1,
    itemsPerPage: 25,
    status: 'active'
};

export const reducer = handleActions({
    [types.IS_FETCHING]: (state = initialState, action: Action<types.IS_FETCHING>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
        });
    },
    [types.USERS_REQUEST]: (state = initialState, action: Action<types.USERS_REQUEST>) => {
        return {
            ...state,
            isFetching: action.payload.isFetching,
        };
    },
    [types.USERS_REQUEST_SUCCESS]: (state = initialState, action: Action<types.USERS_REQUEST_SUCCESS>) => {
        const isOrderSpecific = action.payload.isOrderSpecific;
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            persons: action.payload.data.persons,
            byId: action.payload.data.byId,
            byGroupId: action.payload.data.byGroupId,
            orderSpecific: isOrderSpecific ? action.payload.data.orderSpecific : state.orderSpecific,
            orderSpecificById: isOrderSpecific ? action.payload.data.orderSpecificById : state.orderSpecificById,
        });
    },
    [types.USER_UPDATE_REQUEST_SUCCESS]: (state = initialState, action: Action<types.USER_UPDATE_REQUEST_SUCCESS>) => {
        const { person } = action.payload.data;
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            persons: state.persons.map(p => {
                return p.id === person['id'] ? person : p
            }),
            byId: Object.assign({}, state.byId, action.payload.data.byId),
            byGroupId: Object.assign({}, state.byGroupId, action.payload.data.byGroupId)
        });
    },
    [types.ADMIN_USER_UPDATE_REQUEST_SUCCESS]: (state = initialState, action: Action<types.USER_UPDATE_REQUEST_SUCCESS>) => {
        const { person } = action.payload.data;
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            admin: Object.assign({},
                state.admin,
                {
                    persons: state.admin.persons.map(p => {
                        return p.id === person['id'] ? person : p
                    })
                },
                { byId: Object.assign({}, state.admin.byId, action.payload.data.byId) }
            ),
            byGroupId: Object.assign({}, state.byGroupId, action.payload.data.byGroupId)
        });
    },
    [types.ADMIN_USERS_REQUEST]: (state = initialState, action: Action<types.ADMIN_USERS_REQUEST>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
        });
    },
    [types.ADMIN_USERS_REQUEST_SUCCESS]: (state = initialState, action: Action<types.ADMIN_USERS_REQUEST_SUCCESS>) => {
        return Object.assign({}, state, {
            admin: {
                ...state.admin,
                persons: action.payload.data.persons,
                byId: action.payload.data.byId,
            },
            isFetching: action.payload.isFetching,
        });
    },
    [types.ADMIN_USERS_REQUEST_FAILURE]: (state = initialState, action: Action<types.ADMIN_USERS_REQUEST_FAILURE>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: action.payload.error,
        });
    },
    [types.ADMIN_CREATE_USERS_REQUEST]: (state = initialState, action: Action<types.ADMIN_CREATE_USERS_REQUEST>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: {},
        });
    },
    [types.ADMIN_CREATE_USERS_REQUEST_SUCCESS]: (state = initialState, action: Action<types.ADMIN_CREATE_USERS_REQUEST_SUCCESS>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            createUserDialogOpen: false,
        });
    },
    [types.ADMIN_CREATE_USERS_REQUEST_FAILURE]: (state = initialState, action: Action<types.ADMIN_CREATE_USERS_REQUEST_FAILURE>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
            error: action.payload.error,
        });
    },
    [types.ADMIN_USERS_GET_GROUPS]: (state = initialState, action: Action<types.ADMIN_USERS_GET_GROUPS>) => {
        const groups = sortGroupsSelector(action.payload.groups);
        const byIdObj: byId<GroupData> = {};
        groups.forEach((group) => {
            byIdObj[group.id] = group
        })
        return Object.assign({}, state, {
            groups: action.payload.groups,
            groupsById: byIdObj,
        });
    },

    [types.ADMIN_CREATE_USER_SHOW_DIALOG]: (state = initialState, action: Action<types.ADMIN_CREATE_USER_SHOW_DIALOG>) => {
        return Object.assign({}, state, {
            createUserDialogOpen: action.payload.createUserDialogOpen,
            error: {},
        });
    },
    [types.CLEAR_ALL_USERS]: (state = initialState) => {
        return Object.assign({}, state, {
            persons: [],
            byId: [],
        });
    },
    [types.GET_USER_PREFERENCES_SUCCESS]: (state = initialState, action: Action<types.GET_USER_PREFERENCES_SUCCESS>) => {
        return {
            ...state,
            preferences: action.payload.preferences,
        };
    },
    [types.GET_ROLES]: (state = initialState, action: Action<types.GET_ROLES>) => {
        const roles = action.payload.roles;
        return {
            ...state,
            roles,
        };
    },
    [types.SET_USERS_FILTER]: (state = initialState, action: Action<types.SET_USERS_FILTER>) => {
        const filters = Object.assign({}, state.filters);
        filters[action.payload.name] = action.payload.value;
        return Object.assign({}, state, {
            filters,
        });
    },
    [types.SET_USERS_FILTERS]: (state = initialState, action: Action<types.SET_USERS_FILTERS>) => {
        return Object.assign({}, state, {
            filters: action.payload.filters,
        });
    },
    [types.SET_USERS_FILTERS_VISIBILITY]: (state = initialState, action: Action<types.SET_USERS_FILTERS_VISIBILITY>) => {
        return Object.assign({}, state, {
            filtersVisible: action.payload.visibility,
        });
    },
    [types.SET_USERS_HIDDEN_FILTERS]: (state = initialState, action: Action<types.SET_USERS_HIDDEN_FILTERS>) => {
        return Object.assign({}, state, {
            activeHiddenFilters: action.payload.filters,
        });
    },
    [types.SET_USERS_PAGINATION]: (state = initialState, action: Action<types.SET_USERS_PAGINATION>) => {
        const { currentPage, lastPage, itemsPerPage } = action.payload;
        return Object.assign({}, state, {
            currentPage: currentPage ? currentPage : state.currentPage,
            lastPage: lastPage ? lastPage : state.lastPage,
            itemsPerPage: itemsPerPage ? itemsPerPage : state.itemsPerPage,
        });
    },
    [types.SET_USERS_SHOW_STATUS]: (state = initialState, action: Action<types.SET_USERS_SHOW_STATUS>) => {
        return Object.assign({}, state, {
            status: action.payload.status
        });
    },
}, initialState);

export default reducer;
