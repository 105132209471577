import React, { Fragment, ReactText, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Label, Modal, Popup, Table } from 'semantic-ui-react';
import { AdminCtxUser, AdminCtxUserFeatures } from '../../../backend_api/models';
import { UserRoleBrief } from '../../../backend_api/models/UserRoleBrief';
import { getPrettyDate } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { GroupData } from '../../groups/types';
import { ProfileData } from '../../profile/types';
import InputGroup from '../UI/InputGroup';
import { resendInvitation } from '../actions/actions';
import DisableUser from '../containers/DisableUser';
import { DropdownOptionType, DropdownValue } from '../types';
import { UserLine } from './Widgets';
interface UserProps {
    user: AdminCtxUser;
    roleOptions: Array<DropdownOptionType>;
    roles: UserRoleBrief[];
    changerole(userId: string, value: DropdownValue);
    changegroups(userId: string, value: DropdownValue | GroupData[]);
    disableUser(user: AdminCtxUser);
    disabled: boolean;
    groups: GroupData[];
    showGroups: boolean;
    openGroup: boolean;
    profile: ProfileData;
    resetTwoFactor(userId: string);
    invalidateUserSession(userId: string);
    twoFactorModalClose();
    twoFactorModalVisibility: string;
    twoFactorModalOpen(userId: string);
    invalidateSessionModalVisibility: string;
    toggleinvalidateSessionModal(show: boolean, userId?: string);
    setOpenGroup(id: string);
    language: string;
}

const User = (props: UserProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const defaultMessage = intl.formatMessage({ id: 'users.invitation_email_message' }, { sender: props.profile.profile.firstname, reciver: props.user.firstname, companyname: props.profile.profile.organization.name, senderCompanyName: props.profile.profile.organization.name });
    const [open, setOpen] = useState<boolean>(false);
    const canMange = props.user.features.includes(AdminCtxUserFeatures.Manage);

    const resend = (): void => {
        setOpen(false)
        dispatch(resendInvitation(props.user.id, defaultMessage))
    }

    const getGroups = (): { key: string; value: DropdownValue | ReactText; text: string }[] => {
        const groups = !props.openGroup ? props.user.groups : props.groups;
        if (!groups) {
            return [];
        }
        if (!groups.length) {
            return [];
        }
        return groups.map((element) => {
            const content = <div className='flex justify-between'><span>{element.name}</span>{element.is_supplier_group && <Popup position={'top right'} trigger={<Icon className='text-default-widget-color' name='factory' />}>
                <FormattedMessage id='users.user_is_supplier_group' />
            </Popup>}</div>;
            return { key: element.id, value: element.id, text: element.name, content };
        });
    }
    const getUserGroups = (): string[] => {
        return props.user.groups.map((group) => {
            return group.id;
        });
    }

    let rolesElement = null;
    const roleId = props.user.user_role_id;
    let roleOnlist = false;
    props.roleOptions.forEach((role) => {
        if (role.key === roleId) {
            roleOnlist = true;
        }
    })
    let roleOptions;
    if (roleOnlist) {
        roleOptions = props.roleOptions;
    }
    else {
        roleOptions = [];
        props.roles.forEach((role) => {
            if (role.id === roleId) {
                roleOptions.push({ key: role.id, value: role.id, text: role.name[props.language] || role.name.C })
            }
        })
    }
    const changerole = (event, { value }: { value?: DropdownValue }): React.ReactElement => props.changerole(props.user.id, value)
    if (props.roleOptions.length > 0) {
        if (props.profile.id !== props.user.id) {
            rolesElement = (<Table.Cell className='w-2/16'>
                <HasFeatureAccess type='user' feature={AdminCtxUserFeatures.Manage} user={props.user} showDisabled={true}>
                    <InputGroup
                        data-test-id='users-roles'
                        key={props.user.id}
                        keyId={props.user.id + 'sub'}
                        elementType='select'
                        elementConfig={{}}
                        value={props.user.user_role_id}
                        invalid={false}
                        options={roleOptions}
                        shouldValidate={{}}
                        disabled={!roleOnlist}
                        changed={changerole} />
                </HasFeatureAccess>
            </Table.Cell>);
        }
        else {
            rolesElement = <Table.Cell />;
        }
    }

    const changegroups = (event, { value }: { value?: DropdownValue }): React.ReactElement => { event.preventDefault(); return props.changegroups(props.user.id, value) };
    let groupsElement = null;
    if (props.showGroups) {
        const currentGroups = getUserGroups();
        groupsElement = (<Table.Cell className='w-4/16'>
            <HasFeatureAccess type='user' feature={AdminCtxUserFeatures.Manage} user={props.user} showDisabled={true}>
                <InputGroup
                    onClick={() => {
                        !props.openGroup ? props.setOpenGroup(props.user.id) : null;
                    }}
                    key={props.user.groups + 'id'}
                    keyId={props.user.id + 'idsub'}
                    data-test-id='users-groups'
                    elementType='select'
                    elementConfig={{ multiple: true, search: true }}
                    value={currentGroups}
                    invalid={false}
                    options={getGroups()}
                    shouldValidate={{}}
                    changed={changegroups} />
            </HasFeatureAccess>
        </Table.Cell>);
    }

    const { profile } = props;
    const resetTwoFactorAuth = (): void => props.resetTwoFactor(props.user.id);
    const twoFactorModalOpen = (): void => props.twoFactorModalOpen(props.user.id);
    const resetTwoFactor = (): React.ReactElement => {
        return (
            <Modal
                trigger={<Icon onClick={twoFactorModalOpen} className="u-cursor-hover u-table-cell-center" name='refresh'></Icon>}
                open={props.twoFactorModalVisibility === props.user.id}
                onClose={props.twoFactorModalClose}>
                <Modal.Header><FormattedMessage id="twoFactor.modal.headline" values={{user: <UserLine user={props.user}/>}}></FormattedMessage></Modal.Header>
                <Modal.Content><FormattedMessage id="twoFactor.modal.content" values={{user: <strong><UserLine user={props.user}/></strong>}}></FormattedMessage></Modal.Content>
                <Modal.Actions>
                    <Button basic color="green" onClick={props.twoFactorModalClose}><FormattedMessage id="twoFactor.modal.button.cancel"></FormattedMessage></Button>
                    <Button primary onClick={resetTwoFactorAuth}><FormattedMessage id="twoFactor.modal.button.reset"></FormattedMessage></Button>
                </Modal.Actions>
            </Modal>
        )
    }
    const invalidateUserSessionModal = (): React.ReactElement => {
        return (
            <Modal
                trigger={<Icon onClick={() => props.toggleinvalidateSessionModal(true, props.user.id)} className="u-cursor-hover u-table-cell-center" name='remove'></Icon>}
                open={props.invalidateSessionModalVisibility === props.user.id}
                onClose={() => props.toggleinvalidateSessionModal(false)}>
                <Modal.Header><FormattedMessage id='users.invalidate_user_session'/></Modal.Header>
                <Modal.Content><FormattedMessage id='users.invalidate_user_session.text'/></Modal.Content>
                <Modal.Actions>
                    <Button basic color="green" onClick={() => props.toggleinvalidateSessionModal(false)}><FormattedMessage id="globals.cancel"></FormattedMessage></Button>
                    <Button primary onClick={() => {
                        props.invalidateUserSession(props.user.id);
                        props.toggleinvalidateSessionModal(false);                        
                    }}><FormattedMessage id='users.invalidate_user_session.button'/></Button>
                </Modal.Actions>
            </Modal>
        )
    }
    const userEmail = props.user.did_login_once ? props.user.email : <div className='¨space-x-1 flex flex-col'>
        <Popup
            on='hover'
            trigger={<Label className='cursor-pointer w-fit-content' size='mini' onClick={(): void => setOpen(true)}><FormattedMessage id='users.invitation_pending' /></Label>}
        >
            <Popup.Content>
                <FormattedMessage id='users.resend_invitation.popup' />
            </Popup.Content>
        </Popup>
        <div data-test-id='users-email'>
            {props.user.email}
        </div>

    </div>
    const fUser = useMemo(() => {
        return <Fragment>
            <Modal
                open={open}
            >
                <Modal.Header>
                    <FormattedMessage id='users.resend_invitation.modal.header' />
                </Modal.Header>
                <Modal.Content>
                    <FormattedMessage id='users.resend_invitation.modal.body' values={{ firstname: props.user.firstname, lastname: props.user.lastname }} />
                </Modal.Content>
                <Modal.Actions>
                    <Fragment>
                        <Button positive onClick={resend}><FormattedMessage id={'globals.confirmation.ok'} /></Button>
                        <Button onClick={(): void => setOpen(false)}><FormattedMessage id={'globals.confirmation.cancel'} /></Button>
                    </Fragment>
                </Modal.Actions>
            </Modal>
            <Table.Row negative={props.disabled} data-test-id='users-row'>
                <Table.Cell className='w-5/16' data-test-id='users-name'>{props.user.firstname} {props.user.lastname}</Table.Cell>
                <Table.Cell className='w-5/16'>{userEmail}</Table.Cell>
                {rolesElement}
                {groupsElement}
                {canMange && profile.profile.features.includes('two_factor_authentication') && <Table.Cell>{resetTwoFactor()}</Table.Cell>}
                {canMange && <Table.Cell>{invalidateUserSessionModal()}</Table.Cell>}
                <Table.Cell>{props.user.last_activity && getPrettyDate(props.user.last_activity, intl.locale)}</Table.Cell>
                <Table.Cell>
                    <DisableUser
                        disableUser={props.disableUser}
                        user={props.user}
                        canManage={canMange}
                    /></Table.Cell>
            </Table.Row>
        </Fragment>
    }, [props.groups, props.openGroup, open, props.roles, props.roleOptions]);
    return fUser;
}

export default User;
