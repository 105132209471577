import React from 'react';

type Props = {
    labelValue?: string;
    placeholder?: string;
    inputField: React.ReactElement;
    isEditable: boolean;
    className?: string;
}

const ConditionalInputField = (props: Props) => {
    let className = ''
    if (!props.labelValue) {
        className = ' italic'
    }
    if (props.isEditable) {
        return props.inputField
    }
    else {
        const value = props.labelValue ? props.labelValue : props.placeholder
        return <div className={props.className + className}>{value}</div>
    }
}

export default ConditionalInputField