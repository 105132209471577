import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Segment } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { storeStuff } from '../actions';
import { getStuffSelector } from '../selectors';

const Template = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const stuff = useSelector(getStuffSelector);
    const setStuff = (evt: React.SyntheticEvent): void => {
        dispatch(storeStuff(evt.currentTarget.baseURI));
    }
    return <div className='pageContainer'>
        <Segment>Stuff: {stuff}</Segment>
        <Segment><Input placeholder='Enter stuff' name='stuff' onBlur={setStuff} /></Segment>
    </div>;
}

export default Template;

