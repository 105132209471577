import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import Icon from '../../../base/components/basic/Icon';
import { useIsMobileDevice } from '../../../base/utils';
import { showLoggedOutModalSelector } from '../selectors';

type Props = {
    className?: string;
};

const SignedOutModal = (props: Props): React.ReactElement => {

    const signedOut = useSelector(showLoggedOutModalSelector);
    const isMobile = useIsMobileDevice();
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (signedOut !== show) {
            setShow(signedOut);
        }
    }, [signedOut]);
    return <Modal
        style={{ width: isMobile ? '100%' : '600px' }}
        open={show}>
        <Modal.Header><div className='flex items-center space-x-2'><Icon className='text-3xl text-alert pr-1' name='error_outline' /><div><FormattedMessage id='login.signed_out.your_sign_in_has_expired' /></div></div></Modal.Header>
        <Modal.Content className='flex space-y-4'>
            <div className='flex items-center text-lg'><FormattedMessage id='login.signed_out.your_sign_in_has_expired.text_1' /></div>
            <div className='text-secondary'><div><FormattedMessage id='login.signed_out.your_sign_in_has_expired.text_2' /></div>
            </div>
        </Modal.Content>
        <Modal.Actions>
            <Button primary onClick={() => window.location.reload()}><FormattedMessage id='globals.ok' /></Button>
        </Modal.Actions>
    </Modal>
}
export default SignedOutModal;
