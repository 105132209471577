import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { Sizes } from '../../../components/types';
import Button, { ButtonTypes } from '../buttons/Button';

export type ActionProps = {
    className?: string;
    label: string;
    destructable?: boolean;
};

const Action = (props: ActionProps): React.ReactElement => {
    const { className, label, destructable = false } = props;
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className='mb-1'>
        <Button 
            className={twMerge('px-1 py-0', destructable && 'text-text-text-destructable')} buttonType={ButtonTypes.Ghost} size={Sizes.Small}>{label}</Button>
    </div>
}
export default Action;
