import React from 'react';
import logoImg from '../../../../images/logo_only.png';

type Props = {
    className?: string;
};

const SimpleLogo = (props: Props): React.ReactElement => {
    const { className } = props;
    return <img className={className} src={logoImg} />
}
export default SimpleLogo;
