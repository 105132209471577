import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import UsersSelector from '../../users/components/UsersSelector';
import { getUsersById } from '../../users/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type ApproverFilterProps = {
    selectedItems?: string[];
    disabled?: boolean;
    search?: boolean;
    showExpanded?: boolean;
};
const ApproverFilter = (props: ApproverFilterProps): React.ReactElement => {
    const { disabled = false, search = true, showExpanded = true } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const approver = getLocationEntry(location, 'approver');
    const users = useSelector(getUsersById);
    const [isActive, setIsActive] = useState(false);
    const finish = (approver): void => {
        setIsActive(false);
        dispatch(setFilter('approver', approver));
    };
    return (
        <Filter labelId='filters.approvers.label_approver'>
            <UsersSelector
                name='approver'
                users={users}
                placeholder={intl.formatMessage({ id: 'filters.approvers.select_approver' })}
                allowMultipleSelection={true}
                value={approver || []}
                showExpanded={showExpanded}
                onDeactive={(approvers): void => finish(approvers)}
                clear={(): void => finish([])}
                onActive={(): void => setIsActive(true)}
                showEmailInSelector
                withIcon
                disabled={disabled}
                search={search}
            />
            {/*This button is on visual, if it is clicked then onBlur will be called on the UserSelector and this will promt the appropate action*/}
            {isActive && <Button className='apply' size='mini' positive ><FormattedMessage id='filters.dropdown.label.ok' /></Button>}
        </Filter>
    );
};

export default ApproverFilter;
