import { request } from '../../base/api';
import { clearAllFilters, setFilter } from '../filters/filtersActions';
import { getListViewDefaultDateSelector } from '../inspections/slices/inspectionsListSlice';
import * as types from './actionTypes';

/**
 * Search action creator
 * @param query
 */

export function search(query: string): any {
    return request('/search-services/search?query=' + query);
}

/**
 * Doing the search
 * @param query
 */

export const doSearch = (query: string) => {
    return (dispatch) => {
        dispatch(setFilter('search', [query], false));
        dispatch(setFilter('date', [null], false));
    };
};

export const setQuery = (query: string) => {
    console.log('setQuery ', query)
    return (dispatch) => {
        if (query === '') {
            dispatch(clearSearch());
        }
        dispatch({
            type: types.SET_QUERY,
            payload: {
                query,
            },
        });
    };
};

export const clearSearch = () => {
    return (dispatch, getState) => {
        const dd = getListViewDefaultDateSelector(getState())
        if (dd !== null) {
            dispatch(clearAllFilters());

        } else {
            dispatch(clearAllFilters(true));
        }
        dispatch({
            type: 'CLEAR_SEARCH_QUERY',
        });
    };
};

