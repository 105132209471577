import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { sendErrorMessage } from '../../messages/actions'
import { request2 } from '../../../base/api'
import { catchException } from '../../errorHandling/handler'
import { AppState } from '../../../base/types'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store'
import React from 'react'
import { RemoveProductionUnitFromSupplierBody, SimpleSupplier } from '../../../backend_api/models'
import { createProductionUnitWithSupplier } from '../../productionUnits/slice/productionUnitsSlice'

export type SupplierId = string
export type ProductionUnitId = string
export type SupplierPuLink = [SupplierId, ProductionUnitId]

export type SupplierAndProductionUnitRelationsState = {
    /** 
     * How many calls are trying to fetch data right now. 
     * extraReducers are called before passing the params and state to async thunks.
     * This means the first invocation will see fetchingCounter as 1. 
     * If a thunk sees a number above 1, it should not run.
     * 
    */
    fetchingCounter: number;
    error: boolean,
    /** relations fetched from the backend. */
    relations: SupplierPuLink[]
}

function initialSupplierAndProductionUnitRelationsState(): SupplierAndProductionUnitRelationsState {
    return {
        fetchingCounter: 0,
        error: false,
        relations: []
    }
}

function appStateToSupplierAndPURelationsState(appState: AppState): SupplierAndProductionUnitRelationsState {
    return appState.app.supplierAndProductionUnitRelations;
}

export const getSupplierAndProductionUnitRelationsSelector =
    createSelector(
        [appStateToSupplierAndPURelationsState],
        (data) => data
    )

export type LinkProductionUnitAndSupplierParams = {
    supplier_id: SupplierId,
    production_unit_id: ProductionUnitId
}

export type LinkProductionUnitAndSupplierResult = {
    supplierId: SupplierId,
    productionUnitId: ProductionUnitId
}

export type UnlinkProductionUnitAndSupplier = {
    supplier_id: SupplierId,
    production_unit_id: ProductionUnitId
}

export type UnlinkProductionUnitAndSupplierResult = {
    supplierId: SupplierId,
    productionUnitId: ProductionUnitId
}



export const unlinkProductionUnitAndSupplier = createAsyncThunk<
    UnlinkProductionUnitAndSupplierResult,
    UnlinkProductionUnitAndSupplier>(
        'unlinkProductionUnitAndSupplier',
        async (params: UnlinkProductionUnitAndSupplier, { dispatch, rejectWithValue }) => {
            const url = 'suppliers/' + params.supplier_id + '/remove_production_unit_from_supplier';
            const options = { method: 'post', body: JSON.stringify(params as RemoveProductionUnitFromSupplierBody) };
            const rq = await request2(url, options);
            if (!rq.ok) {
                console.log('Error when unlinking production unit and supplier', rq.statusText)
                dispatch(sendErrorMessage(['error_message.supplier.remove_production_unit'], 3000))
                catchException('unlinkProductionUnitAndSupplier', {
                    endpoint: 'suppliers/[id]/remove_production_unit_to_supplier',
                    request: url,
                    status: rq.status
                }, {
                    rq,
                    params
                });

                return rejectWithValue(rq);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response: SimpleSupplier = await rq.json();
            /* TODO. This could trigger an update of the supplier state that has a now stale version of the supplier*/
            return {
                supplierId: params.supplier_id,
                productionUnitId: params.production_unit_id
            }

        }
    )

export const linkProductionUnitAndSupplier = createAsyncThunk<
    LinkProductionUnitAndSupplierResult,
    LinkProductionUnitAndSupplierParams
>(
    'linkProductionUnitAndSupplier',
    async (params: LinkProductionUnitAndSupplierParams, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/' + params.supplier_id + '/add_production_unit_to_supplier'
        const rq = await request2(url, { method: 'post', body: JSON.stringify(params) })
        if (!rq.ok) {
            console.log('Error when linking production unit and supplier', rq.statusText)
            dispatch(sendErrorMessage(['error_message.supplier.add_production_unit'], 3000))
            catchException('linkProductionUnitAndSupplier', {
                endpoint: 'suppliers/[id]/add_production_unit_to_supplier',
                request: url,
                status: rq.status
            }, {
                rq,
                params
            });

            return rejectWithValue(rq);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: SimpleSupplier = await rq.json();
        /* TODO. This could trigger an update of the supplier state that has a now stale version of the supplier*/
        return {
            supplierId: params.supplier_id,
            productionUnitId: params.production_unit_id
        }
    }
)

export const fetchSupplierAndPuRelations = createAsyncThunk<SupplierPuLink[]>(
    'getSupplierAndProductionUnitRelations',
    async (params, { dispatch, rejectWithValue, getState }) => {

        const currentState = getState() as AppState;
        const fetchCounter = currentState.app.supplierAndProductionUnitRelations.fetchingCounter

        if (fetchCounter > 1) {
            return undefined;
        }

        const url = 'suppliers2/links'
        const rq = await request2(url, { method: 'GET' });
        if (!rq.ok) {
            console.log('getting suplier and pu relations failed', rq.statusText);
            dispatch(sendErrorMessage(['error_message.fetch_supplier_pu_relations_failed'], 3000));
            catchException('getSupplierAndPuRelations', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq })
            return rejectWithValue(rq);
        }
        const relations: SupplierPuLink[] = await rq.json();
        return relations;
    }
)

const supplierAndProductionUnitRelationsSlice = createSlice({
    name: 'supplierAndProductionUnitRelations',
    initialState: initialSupplierAndProductionUnitRelationsState(),
    reducers: {},
    extraReducers: (builder) => {

        /* !  listens to productionUnitSlice  ! */
        builder.addCase(createProductionUnitWithSupplier.fulfilled, (state, action) => {
            const supplierId = action.meta.arg.supplier_id;
            const productionUnitId = action.payload.id;
            state.relations.push([supplierId, productionUnitId]);
        })

        /* Fetch */
        builder.addCase(fetchSupplierAndPuRelations.pending, (state) => {
            state.fetchingCounter++;
        })
        builder.addCase(fetchSupplierAndPuRelations.rejected, (state) => {
            state.error = true
            state.fetchingCounter--;
        })
        builder.addCase(fetchSupplierAndPuRelations.fulfilled, (state, action) => {

            /* If payload is undefined, then the thunk was cancelled to not call the endpoint multiple times at once. */

            if (action.payload !== undefined) {
                state.error = false;
                state.relations = action.payload
            }

            state.fetchingCounter--;
        })

        /* Link supplier and production unit */
        builder.addCase(linkProductionUnitAndSupplier.pending, () => undefined)
        builder.addCase(linkProductionUnitAndSupplier.rejected, () => undefined)
        builder.addCase(linkProductionUnitAndSupplier.fulfilled, (state, action) => {
            state.relations.push([action.payload.supplierId, action.payload.productionUnitId]);
        })

        /* Unlink supplier and production unit */
        builder.addCase(unlinkProductionUnitAndSupplier.pending, () => undefined)
        builder.addCase(unlinkProductionUnitAndSupplier.rejected, () => undefined)
        builder.addCase(unlinkProductionUnitAndSupplier.fulfilled, (state, action) => {
            state.relations = state.relations.filter(r => {
                if (r[0] === action.payload.supplierId && r[1] === action.payload.productionUnitId) {
                    return false;
                }
                return true;
            });
        })
    }
})

export function useSupplierAndProductionUnitRelations(): SupplierPuLink[] | 'loading' {
    /* only returns loading, to keep the same interface as the hook I am replacing */

    /* This dispatch is not very nice, especially when there could be several uses of this hook */
    /* It would be nice to have some central place, or at least a throttled version */
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(fetchSupplierAndPuRelations());
    }, [])

    const state = useSelector(getSupplierAndProductionUnitRelationsSelector)
    if (!state) {
        return 'loading';
    }

    if (state.fetchingCounter > 0) {
        return 'loading'
    }

    return state.relations;
}

export default supplierAndProductionUnitRelationsSlice.reducer