import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Sizes } from '../../../components/types';
import { twMerge } from '../../../utils';
import Icon, { IconSizeMappings } from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

type Props = {
    className?: string;
    status: Status;
    large?: boolean;
    inverted?: boolean;
    border?: boolean;
    showInfo?: boolean;
    size?: Sizes;
};

type Status = 'approved' | 'rejected' | 'skipped' | 'pending' | 'empty' | 'not-submitted-yet';
type StatusIconColors = { background: string, color: string, invertedColor: string, invertedTextColor?: string, borderColor?: string };
type StatusMap = { [s in Status]: { iconName: Icons, color: StatusIconColors } };

const StatusMappings: StatusMap = {
    approved: { iconName: 'status_checked-ok', color: { background: 'status-approved', color: 'on-status', invertedColor: 'default', invertedTextColor: 'brand', borderColor: 'status-approved' } },
    rejected: { iconName: 'status_rejected', color: { background: 'status-rejected', color: 'on-status', invertedColor: 'default', invertedTextColor: 'status-rejected', borderColor: 'status-rejected' } },
    skipped: { iconName: 'status_skipped', color: { background: 'status-ignored', color: 'on-status', invertedTextColor: 'status-ignored', invertedColor: null, borderColor: 'status-ignored' } },
    pending: { iconName: 'operation_review', color: { background: 'status-pending', color: 'on-status', invertedColor: 'default', invertedTextColor: 'status-pending', borderColor: 'status-pending' } },
    empty: { iconName: 'operation_add', color: { background: 'status-none-planned', color: 'secondary', invertedTextColor: 'secondary', invertedColor: null, borderColor: 'status-none-planned' } },
    "not-submitted-yet": { iconName: 'object_file', color: { background: 'status-none-planned', color: 'secondary', invertedTextColor: 'secondary', invertedColor: null, borderColor: 'secondary' } },
}

const StatusIcon = (props: Props): React.ReactElement => {
    const { className, status, inverted = false, large = false, showInfo = true, size = Sizes.XSmall, border } = props;
    const sizeValue = IconSizeMappings[size];
    const item = StatusMappings[status];
    const s = <div className={twMerge('leading-none rounded-full inline-block p-1 h-min', border && ['border rounded-full', 'border-' + item.color.borderColor], showInfo && 'cursor-default', inverted ? ['bg-' + item.color.invertedColor, 'text-' + item.color.invertedTextColor] : ['bg-' + item.color.background, 'text-' + item.color.color], className)}>
        <Icon name={item.iconName} className={twMerge(large ? 'text-xl' : sizeValue, inverted ? ['bg-' + item.color.invertedColor, 'text-' + item.color.invertedTextColor] : ['bg-' + item.color.background, 'text-' + item.color.color], 'leading-rem-none')} />
    </div>
    return showInfo ? <Popup trigger={s}>{status}</Popup> : s;
}
export default StatusIcon;
