import * as types from '../types';

const initialState = {
    inspectionsBaseURL: {
        url: '/inspections/list',
    },

};
const map = {

    [types.SET_INSPECTIONS_BASE_URL]: (state = initialState, action): any => {
        return {
            ...state,
            inspectionsBaseURL: action.payload.baseurl,
        };
    },
};

export default { map, initialState };
