import { createLogic } from 'redux-logic';
import { AppState } from '../../base/types';
import { SEND_STATUS_MESSAGE } from './actionTypes';

export const messageLogic = createLogic({
    type: SEND_STATUS_MESSAGE,
    validate({ getState, action }, allow, reject) {
        if (!(getState() as AppState).app.inspections.isSaving) {
            allow(action);
        } else {
            reject(action);
        }
    },
});
