/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldDate } from './SetCustomFieldDate';
import type { SetCustomFieldNotSet } from './SetCustomFieldNotSet';
import type { SetCustomFieldNull } from './SetCustomFieldNull';

/**
 *   Value for a date based custom field
 *
 */
export type SetCustomFieldDateValue = {
    new_value: (SetCustomFieldNotSet | SetCustomFieldNull | SetCustomFieldDate);
    type: SetCustomFieldDateValue.type;
};

export namespace SetCustomFieldDateValue {

    export enum type {
        DATE = 'Date',
    }


}

