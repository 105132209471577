/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 43.7.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Basic Information about a User.
 * 
 * This might be null or undefined.
 * @export
 * @interface BasicUserOptional
 */
export interface BasicUserOptional {
    /**
     * 
     * @type {boolean}
     * @memberof BasicUserOptional
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicUserOptional
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserOptional
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserOptional
     */
    id: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicUserOptional
     */
    lastname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasicUserOptional
     */
    supplier_user?: boolean;
}

export function BasicUserOptionalFromJSON(json: any): BasicUserOptional {
    return BasicUserOptionalFromJSONTyped(json, false);
}

export function BasicUserOptionalFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicUserOptional {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'id': json['id'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'supplier_user': !exists(json, 'supplier_user') ? undefined : json['supplier_user'],
    };
}

export function BasicUserOptionalToJSON(value?: BasicUserOptional | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'email': value.email,
        'firstname': value.firstname,
        'id': value.id,
        'lastname': value.lastname,
        'supplier_user': value.supplier_user,
    };
}


