import { createAction } from 'typesafe-actions';
import { request } from '../../base/api';
import { AppThunk, RequestError } from '../../base/types';
import errorHandling from '../errorHandling';
import { BulkApprovalFlow } from '../inspections/types';
import * as types from './actionTypes';
import { ApprovalFlow, CustomConclusion } from './types';

const catchException = errorHandling.handler.catchException;

export const getFlowRequest = createAction(types.APPROVAL_GET_FLOW_REQUEST, (inspectionId: string, isFetching: boolean) => ({ type: types.APPROVAL_GET_FLOW_REQUEST, payload: { inspectionId, isFetching } }));
export const getFlowSuccess = createAction(types.APPROVAL_GET_FLOW_SUCCESS, (inspectionId: string, data: ApprovalFlow, isFetching: boolean) => ({ type: types.APPROVAL_GET_FLOW_SUCCESS, payload: { inspectionId, data, isFetching } }));
export const getFlowFailure = createAction(types.APPROVAL_GET_FLOW_FAILURE, (inspectionId: string, message: string, status: number, isFetching: boolean) => ({ type: types.APPROVAL_GET_FLOW_FAILURE, payload: { inspectionId, message, status, isFetching } }));

export const getApprovalConclusionsSucess = createAction(types.APPROVAL_GET_CONCLUSIONS_SUCCESS, (conclusions: CustomConclusion[]) => ({ type: types.APPROVAL_GET_CONCLUSIONS_SUCCESS, payload: { conclusions } }))

export const getBulkFlowRequest = createAction(types.BULK_APPROVAL_GET_FLOW_REQUEST, (isFetching: boolean, inspectionIds: string[]) => ({ type: types.BULK_APPROVAL_GET_FLOW_REQUEST, payload: { inspectionIds, isFetching } }));
export const getBulkFlowSuccess = createAction(types.BULK_APPROVAL_GET_FLOW_SUCCESS, (isFetching: boolean, inspectionIds: string[], data: BulkApprovalFlow) => ({ type: types.BULK_APPROVAL_GET_FLOW_SUCCESS, payload: { inspectionIds, isFetching, data } }));
export const getBulkFlowFailure = createAction(types.BULK_APPROVAL_GET_FLOW_FAILURE, (isFetching: boolean, inspectionIds: string[], error: RequestError) => ({ type: types.BULK_APPROVAL_GET_FLOW_FAILURE, payload: { inspectionIds, isFetching, error } }));

export const getApprovalFlow = (inspectionId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/approvals';
        dispatch(getFlowRequest(inspectionId, true));
        return request(url, {})
            .then((data: ApprovalFlow) => {
                dispatch(getFlowSuccess(inspectionId, data, false));
            })
            .catch((error: RequestError) => {
                catchException('getApprovalFlow', {
                    endpoint: 'inspections/[inspectionId]/approvals',
                    request: url,
                    status: error.status,
                }, { error, inspectionId });
                dispatch(getFlowFailure(inspectionId, error.errorText, error.status, false));
            });
    };
};

export const getApprovalConclusion = (): AppThunk => {
    return async(dispatch): Promise<void> => {
        const url = 'active_approval_conclusions';
        return request(url, {})
            .then((data: CustomConclusion[]) => {
                dispatch(getApprovalConclusionsSucess(data))
            })
            .catch((error: RequestError) => {
                catchException('getApprovalConclusion', {
                    endpoint: 'active_approval_conclusions',
                    request: url,
                    status: error.status,
                }, { error });
            });
    };
}



export const saveApprovalFlow = (inspectionId: string, approvalFlow: ApprovalFlow): AppThunk => {
    return async(dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/approvals';
        return request(url, {
            body: JSON.stringify(approvalFlow),
            method: 'post',
        })
            .then((data: ApprovalFlow) => {
                dispatch(getFlowSuccess(inspectionId, data, false));
            })
            .catch((error: RequestError) => {
                catchException('saveApprovalFlow', {
                    endpoint: 'inspections/[inspectionId]/approvals',
                    request: url,
                    status: error.status,
                }, { error, body: approvalFlow, method: 'POST', inspectionId });
            });
    };
};

export const setSameFlowOnAll = (sourceInspectionId: string, inspectionIds: string[]): AppThunk => {
    return async(dispatch): Promise<void> => {
        return request('inspections/' + sourceInspectionId + '/approvals', {})
            .then((data: ApprovalFlow) => {
                inspectionIds.forEach((id) => {
                    dispatch(saveApprovalFlow(id, data));
                });
            });
    };
};

export const getBulkApprovalFlow = (ids: string[]): AppThunk => {
    return async(dispatch): Promise<void> => {
        const url = 'inspections/approvals/get_bulk_approval';
        dispatch(getBulkFlowRequest(true, ids));
        return request(url, {
            body: JSON.stringify({ inspection_ids: ids }),
            method: 'post',
        })
            .then((data: BulkApprovalFlow) => {
                dispatch(getBulkFlowSuccess(false, ids, data));
            })
            .catch((error) => {
                catchException('getBulkApprovalFlow', {
                    endpoint: url,
                    request: url,
                    status: error.status,
                }, { error, ids });
                dispatch(getBulkFlowFailure(false, ids, error));
            });
    };
};
