import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Icon,
    Message as UIMessage
} from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';

const types = {
    error: 'red',
    info: 'yellow',
};

const icons = {
    error: 'warning sign',
    info: 'info',
};
interface MessageProps {
    messages: [{
        type?: string;
        showIcon?: boolean;
        text?: string[];
        header?: string;
    }];
}
const Message = (props: MessageProps): React.ReactElement => {
    if (props.messages !== undefined) {
        const messages = props.messages;
        const paragraphs = [];
        const message = messages[0];
        const showIcon: boolean = message.showIcon;
        message.text.forEach((element, i) => {
            paragraphs.push(<p key={i}>{element}</p>);
        });
        const type = types[message.type] || 'yellow';
        const icon = icons[message.type] || 'info circle';
        if (message.text.length > 0) {
            if (showIcon) {
                return (
                    <div className='messageContainer'>
                        <UIMessage icon color={type}>
                            <Icon className='' name={icon} />
                            <UIMessage.Content>
                                {message.header && <UIMessage.Header>{message.header}</UIMessage.Header>}
                                {paragraphs}
                            </UIMessage.Content>
                        </UIMessage>
                    </div>
                );
            }
            return (
                <div className='messageContainer'>
                    <UIMessage color={type}>
                        <UIMessage.Content>
                            {message.header && <UIMessage.Header><FormattedMessage id='messages.header' /></UIMessage.Header>}
                            {paragraphs}
                        </UIMessage.Content>
                    </UIMessage>
                </div>
            );
        }
        return (null);
    }
    return (<div className='messageContainer hidden'>...</div>);
};

type StatusMessageProps = {
    messages: [{ text: [any] }];
};
const StatusMessage = (props: StatusMessageProps) => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    if (props.messages !== undefined) {
        const messages = props.messages;
        const paragraphs = [];
        const message = messages[0];
        message.text.forEach((element, i) => {
            if (typeof (element) !== 'string') { // Is a message object
                const translatedText = formatMessage({ id: element.text }, element.values);
                paragraphs.push(<p key={i}>{translatedText}</p>);
            } else {
                const translatedText = formatMessage({ id: element });
                paragraphs.push(<p key={i}>{translatedText}</p>);
            }
        });
        return (<span className='statusMessage'>{paragraphs}</span>);
    }
    return (null);
};


type ErrorMessageProps = {
    messages: [{ text: [any] }];
};
export const ErrorMessage = (props: ErrorMessageProps) => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    if (props.messages !== undefined) {
        const messages = props.messages;
        const paragraphs = [];
        const message = messages[0];
        message.text.forEach((element, i) => {
            if (typeof (element) !== 'string') { // Is a message object
                const translatedText = formatMessage({ id: element.text }, element.values);
                paragraphs.push(<p key={i}>{translatedText}</p>);
            } else {
                const translatedText = formatMessage({ id: element });
                paragraphs.push(<p key={i}>{translatedText}</p>);
            }
        });
        return (<span className='errorMessage self-center bg-surface-danger'>{paragraphs}</span>);
    }
    return (null);
};

export const StandAloneMessage = (props: { message: string, type?: 'message' | 'info' | 'error', removeIcon?: boolean, onRemove?(), className?: string, }) => {
    const { type = 'message', message, className, removeIcon = false, onRemove } = props;
    return <div className={twMerge('flex items-center justify-between pl-2 pr-1 rounded text-sm bg-surface-secondary text-white', type==='info' && 'bg-attention text-primary', type === 'error' && 'bg-surface-danger text-white', className)}>
        {message}
        {removeIcon && <RemoveIcon className='cursor-pointer' onClick={() => onRemove ? onRemove() : null} />}
    </div>;
}

type GlobalMessageProps = {
    messages: [{ text: [any] }];
}
export const GlobalMessage = (props: GlobalMessageProps) => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    if (props.messages !== undefined) {
        const messages = props.messages;
        const paragraphs = [];
        const message = messages[0];
        message.text.forEach((element, i) => {
            if (typeof (element) !== 'string') { // Is a message object
                const translatedText = formatMessage({ id: element.text }, element.values);
                paragraphs.push(<p key={i}>{translatedText}</p>);
            } else {
                const translatedText = formatMessage({ id: element });
                paragraphs.push(<p key={i}>{translatedText}</p>);
            }
        });
        return (<span className='globalMessage'>{paragraphs}</span>);
    }
    return (null);
};

export default { ErrorMessage, GlobalMessage, Message, StatusMessage };
