import { Inspection } from '../types';
export const ADD_INSPECTION_GET_DATA_OBJECT_REQUEST = 'ADD_INSPECTION_GET_DATA_OBJECT_REQUEST';
export type ADD_INSPECTION_GET_DATA_OBJECT_REQUEST = {
    isFetching: boolean;
};

export const ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS = 'ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS';
export type ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS = {
    isFetching: boolean;
    data: Inspection;
};

export const ADD_INSPECTION_GET_DATA_OBJECT_CLEAR = 'ADD_INSPECTION_GET_DATA_OBJECT_CLEAR';
export type ADD_INSPECTION_GET_DATA_OBJECT_CLEAR = {
    isFetching: boolean;
};

export const ADD_INSPECTION_GET_DATA_OBJECT_FAILURE = 'ADD_INSPECTION_GET_DATA_OBJECT_FAILURE';
export type ADD_INSPECTION_GET_DATA_OBJECT_FAILURE = {
    isFetching: boolean;
    status: number;
    message: string;
};

export const UPDATE_INSPECTION_REQUEST = 'UPDATE_INSPECTION_REQUEST';
export type UPDATE_INSPECTION_REQUEST = {
    isFetching: boolean;
    isSaving: boolean;
};

export const UPDATE_INSPECTION_SUCCESS = 'UPDATE_INSPECTION_SUCCESS';
export type UPDATE_INSPECTION_SUCCESS = {
    isFetching: boolean;
    data: string;
};

export const UPDATE_INSPECTION_FAILURE = 'UPDATE_INSPECTION_FAILURE';
export type UPDATE_INSPECTION_FAILURE = {
    isFetching: boolean;
    status: number;
    message: string;
};

export const INDEX_ORDER_INSPECTIONS = '@@inspections/INDEX_ORDER_INSPECTIONS';
