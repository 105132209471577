/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Represents the `null` or `nil` value
 *
 */
export type AuditCustomFieldNull = {
    type: AuditCustomFieldNull.type;
};

export namespace AuditCustomFieldNull {

    export enum type {
        NULL = 'null',
    }


}

