import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import ContentSection from '../../../../modules/pageLayouts/components/ContentSection';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';

export enum EmptyStateType {
    Standalone = 'standalone',
    Inline = 'inline',
}
type Props = {
    className?: string;
    type: EmptyStateType;
    title: ComponentOrStringType;
    guidance?: ComponentOrStringType;
    headerClassName?: string;
    guidanceClassName?: string;
};

const EmptyState = (props: Props): React.ReactElement => {
    const { className, guidance, title, type = EmptyStateType.Standalone, guidanceClassName, headerClassName} = props;
    const location = useLocation();
    const intl = useIntl();
    const [state, setState] = useState();
    const headerStyle = twMerge('flex justify-center text-secondary italic', type === EmptyStateType.Inline ? 'text-base' : 'text-3xl pb-6', headerClassName);
    const guidanceStyle = twMerge('flex pt-6 border-t justify-center', guidanceClassName);

    const getTitle = (title: ComponentOrStringType) => {
        return <div className={headerStyle}> {title}</div >

    }
    const getGuidance = (guidance: ComponentOrStringType) => {
        return <div className={guidanceStyle}>
            <span className='inline-block text-center max-w-[500px]'>{guidance}</span>
        </div>
    }
    const t = getTitle(title);
    useEffect(() => {
        //
    }, []);
    return type === EmptyStateType.Inline ? t : <ContentSection className='w-full p-6'
        content={<div>
            {t}
            {getGuidance(guidance)}
        </div>}
    />
}
export default EmptyState;
