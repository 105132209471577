import { UploadField } from '@navjobs/upload';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';

interface UploaderProps {
    multiple: boolean;
    label?: string;
    hasIcon: boolean;
    isUploading?: boolean;
    disableWhileUploading?: boolean;
    inline?: boolean;
    autoClear?: boolean;
    uploadFiles?();
    updateStateHandler(name: string, data: File[]);
}
const Upload = (props: UploaderProps): React.ReactElement => {
    const multiple = props.multiple;
    const { isUploading = false, autoClear = false, disableWhileUploading = false } = props;
    return (<UploadField
        onFiles={(files: File[]): void => {
            props.updateStateHandler('droppedFiles', files);
            // Needed to ensure that droppedFiles are in place in state before uploading
            // FIXME: remove setTimeout and use setState callback
            if (!props.inline) {
                setTimeout(() => {
                    props.uploadFiles && props.uploadFiles();
                }, 150);
            }
        }}
        containerProps={{
            className: 'browseFiles',
        }}
        uploadProps={{
            // accept: '.jpg, .png., .pdf,.doc,.docx,.txt,.rtf, .dmg', // TODO: get accepted upload file types from config
            multiple,
            onClick: (e): void => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();

                e.target.value = '';
            },
        }}
    >
        {disableWhileUploading && autoClear && !isUploading && <span className=''>{props.hasIcon && <Icon name='cloud upload' />}{(props.label ? (<span className='link'>{props.label}</span>) : (<span className='link'><FormattedMessage id='attachments.select_files' /></span>))}</span>}
        {disableWhileUploading && !autoClear && <span className=''>{props.hasIcon && <Icon name='cloud upload'/>}{(props.label ? (<span className='link'>{props.label}</span>) : (<span className='link'><FormattedMessage id='attachments.select_files' /></span>))}</span>}
        {!disableWhileUploading && <span className=''>{props.hasIcon && <Icon name='cloud upload'/>}{(props.label ? (<span className='link'>{props.label}</span>) : (<span className='link'><FormattedMessage id='attachments.select_files' /></span>))}</span>}
    </UploadField>);
};

export default Upload;
