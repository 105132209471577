import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps } from 'semantic-ui-react';
import { ProductionUnit } from '../../../backend_api/models';
import { LIMITED_SELECTOR_ITEMS_COUNT } from '../../../base/config';
import { OptionsType } from '../../../base/types';
import LimitedDropdown from '../../filters/components/LimitedDropdown';
import { InfoIcon } from '../../globals/components/Utils';
import { searchOptionTextAndDescription } from '../../inspections/Utils';
import { loadingProductionUnitMessage } from './productionUnitSelector/shared';

const NEW_PRODUCTION_UNIT = 'NEW_PRODUCTION_UNIT';

type ControlledProductionUnitsSelectorProps = {
    productionUnits: ProductionUnit[];
    loading: boolean;
    disableUnassignedOption?: boolean;

    // production units that do not match a currently selected supplier
    nonMatchingProductionUnits?: ProductionUnit[];
    showLabel?: boolean;
    disabled?: boolean;
    name?: string;
    productionUnitId: string | null;
    open?: boolean;
    dataTestId?: string;
    onProductionUnitIdSelected(value: string | null, doesIdBelongToMatchingProductionUnit?: boolean): void;
    onUserWantedToCreateNewProductionUnit?(): void,
    // handleChange(event: React.SyntheticEvent, item: DropdownProps): void;
    handleBlur?(event: React.SyntheticEvent, item: DropdownProps): void;
};

const ControlledProductionUnitSelector = (props: ControlledProductionUnitsSelectorProps): React.ReactElement => {
    const intl = useIntl();
    const { onProductionUnitIdSelected: onProductionUnitIdSelected, handleBlur, disabled, name } = props;
    const isLoading = props.loading
    const productionUnits = props.productionUnits
    const nonMatchingProductionUnits = props.nonMatchingProductionUnits || []

    const productionUnitOptions = useMemoizedProductionUnitOptions(
        productionUnits,
        nonMatchingProductionUnits,
        props.disableUnassignedOption,
        !props.onUserWantedToCreateNewProductionUnit
    );

    if (isLoading) {
        return loadingProductionUnitMessage();
    }

    function doesIdBelongToMatchingProductionUnit(productionUnitId: string): boolean {
        return productionUnitId && productionUnits.some(pu => pu.id === productionUnitId);
    }

    return <LimitedDropdown
        limitItemsCount={LIMITED_SELECTOR_ITEMS_COUNT}
        value={props.productionUnitId}
        fluid
        selection
        placeholder={intl.formatMessage({ id: 'audits.select_production_unit' })}
        search={(_, query) => {
            return makeProductionUnitOptions(productionUnits, nonMatchingProductionUnits, query, props.disableUnassignedOption, !props.onUserWantedToCreateNewProductionUnit)
        }}
        options={productionUnitOptions}
        onChange={(a, item) => {

            if (item.value === NEW_PRODUCTION_UNIT && props.onUserWantedToCreateNewProductionUnit) {
                props.onUserWantedToCreateNewProductionUnit()
                return;
            }

            if (item.value && item.value === DIVIDER_OPTION_KEY) {
                return;
            }

            if (!item.value) {
                onProductionUnitIdSelected(null);
                return;
            }

            if (item.value) {
                const selectedId = item.value as string;
                onProductionUnitIdSelected(selectedId, doesIdBelongToMatchingProductionUnit(selectedId))
            }
        }}
        onBlur={handleBlur}
        name={name || 'target_production_unit.id'}
        disabled={disabled}
        data-test-id={props.dataTestId}
    />
}

function useMemoizedProductionUnitOptions(
    productionUnits: ProductionUnit[],
    nonMatchingProductionUnits: ProductionUnit[],
    disableUnassignedOption: boolean,
    disableCreateNewOption: boolean
) {
    return useMemo<OptionsType[]>(() => {
        return makeProductionUnitOptions(productionUnits, nonMatchingProductionUnits, undefined, disableUnassignedOption, disableCreateNewOption)
    }, [productionUnits, nonMatchingProductionUnits])
}

function makeProductionUnitOptions(
    productionUnits: ProductionUnit[],
    nonMatchingProductionUnits: ProductionUnit[],
    query: string = undefined,
    unassignedOptionDisabled = false,
    disableCreateNewOption = false) {

    const unassignedOptions = query || unassignedOptionDisabled ? [] : [makeNoProductionUnitOption()]
    const matchingPUOptions = convertProductionUnitsToOptions(productionUnits, query);
    const nonMatchingPUOptions = convertProductionUnitsToOptions(nonMatchingProductionUnits, query);
    const newOptions = disableCreateNewOption ? [] : [makeNewProductionUnitOption()]

    if (matchingPUOptions.length == 0 && nonMatchingPUOptions.length == 0) {
        return [
            makeEmptyProductionUnitOption(),
        ].concat(newOptions)
    } else if (nonMatchingPUOptions.length === 0) {
        return [].concat(
            unassignedOptions,
            matchingPUOptions,
            newOptions
        );
    } else {
        return [].concat(
            unassignedOptions,
            matchingPUOptions,
            [makeDividerOption()],
            nonMatchingPUOptions,
            newOptions
        )
    }
}

export function convertProductionUnitsToOptions(productionUnits: ProductionUnit[] = [], query: string = undefined) {
    const withId = keepProductionUnitsWithId(productionUnits);
    const asOptions = convertToProductionUnitOptions(withId)
    return searchOptionTextAndDescription(asOptions, query);
}

function keepProductionUnitsWithId(productionUnits: ProductionUnit[]): ProductionUnit[] {
    return productionUnits.filter((pu) => pu.id)
}

function convertToProductionUnitOptions(productionUnits: ProductionUnit[]): OptionsType[] {
    return productionUnits.map(convertToProductionUnitOption);
}

function convertToProductionUnitOption(productionUnit: ProductionUnit): OptionsType {
    return {
        key: productionUnit.id,
        text: productionUnit.name,
        value: productionUnit.id,
        description: productionUnit.number
    }
}

function makeNewProductionUnitOption(): OptionsType {
    return {
        key: 'newItem',
        value: NEW_PRODUCTION_UNIT,
        text: 'new',
        content:
            (
                <div className='w-full flex space-x-2 text-sm py-2'>
                    <div>
                        <FormattedMessage id='production_units.selection.cannot_find_match' />
                    </div>
                    <div className='link'>
                        <FormattedMessage id='production_units.selection.cannot_find_match_create_new' />
                    </div>
                </div>
            )
    }

}

function makeNoProductionUnitOption(): OptionsType {
    return {
        key: null,
        value: null,
        text: (
            <span className='emphasizeUnassignedOptionInDropdown'>
                <FormattedMessage id='production_units.selector_set_entry_to_unassiged' />
            </span>
        )
    }
}

function makeEmptyProductionUnitOption(): OptionsType {
    return {
        key: null,
        value: null,
        text: (<span><FormattedMessage id='production_units.relations.no_more_production_units_to_add' /></span>)
    }
}

const DIVIDER_OPTION_KEY = 'dividerOption'

function makeDividerOption(): OptionsType {
    return {
        key: DIVIDER_OPTION_KEY,
        value: DIVIDER_OPTION_KEY,
        text: '-',
        content: (
            <div className='text-secondary -my-1 flex items-center'>
                <InfoIcon compact noMargins />
                <div>
                    <FormattedMessage id='audits.select_production_unit.following_production_units_do_not_match_supplier' />
                </div>
            </div>
        )
    }

}

export default ControlledProductionUnitSelector;
