import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import UsersSelector from '../../users/components/UsersSelector';
import { getUsersById } from '../../users/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type Props = {
    selectedItems?: string[];
    disabled?: boolean;
    search?: boolean;
    showExpanded?: boolean;
};
const ResponsibleFilter = (props: Props): React.ReactElement => {
    const { disabled = false, search = true, showExpanded = true } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const responsibles = getLocationEntry(location, 'responsible_ids');
    const users = useSelector(getUsersById);
    const [isActive, setIsActive] = useState(false);
    const finish = (responsibles): void => {
        setIsActive(false);
        dispatch(setFilter('responsible_ids', responsibles));
    };
    return (
        <Filter labelId='filters.responsibles.label'>
            <UsersSelector
                name='approver'
                users={users}
                placeholder={intl.formatMessage({ id: 'filters.responsibles.placeholder' })}
                allowMultipleSelection={true}
                value={responsibles || []}
                showExpanded={showExpanded}
                onDeactive={(responsibles): void => finish(responsibles)}
                clear={(): void => finish([])}
                onActive={(): void => setIsActive(true)}
                showEmailInSelector
                withIcon
                disabled={disabled}
                search={search}
            />
            {/*This button is on visual, if it is clicked then onBlur will be called on the UserSelector and this will promt the appropate action*/}
            {isActive && <Button className='apply' size='mini' positive ><FormattedMessage id='filters.dropdown.label.ok' /></Button>}
        </Filter>
    );
};

export default ResponsibleFilter;