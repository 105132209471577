import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import { translateTextWithTranslation, useLocaleLanguageString } from '../../../base/components/basic/TextWithTranslation';
import { useAppDispatch } from '../../../store';
import { getProductionUnitStatusList, setProductionUnitStatus } from '../slice/productionUnitsSlice';
import ProductionUnitChangeStatusDialog from './ProductionUnitChangeStatusDialog';
import { DetailedProductionUnitStatus } from '../../../backend_api/models';

type Props = {
    allowedToEdit: boolean;
    className?: string;
    status: DetailedProductionUnitStatus;
    productionUnitId: string;
    onlyButton?: boolean;
};

const ProductionUnitStatus = (props: Props): React.ReactElement => {
    const { className, productionUnitId, status, onlyButton } = props;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getProductionUnitStatusList());
    }, []);

    function renderStatusSection() {
        return (
            <>
                <span className='font-bold text-lg'>
                    <FormattedMessage id='audits.conclusion.status.label' />
                </span>
                <div className='p-2 rounded' style={{ backgroundColor: status.text_color, color: status.background_color }}>
                    {translateTextWithTranslation(status.name, useLocaleLanguageString())}
                </div>
            </>
        )
    }

    if (!props.allowedToEdit && props.onlyButton) {
        return undefined;
    }

    if (!props.allowedToEdit && !props.onlyButton) {
        return (
            <div
                className={twMerge('flex flex-row justify-between items-center', 'p-segment')}
                style={{ backgroundColor: status.background_color, color: status.text_color }}>
                {renderStatusSection()}
            </div>
        )
    }

    const trigger = <div className={twMerge('space-y-2  sm:space-y-0 flex sm:flex-row w-full items-center justify-between', className)}>
        {
            onlyButton
                ? (<Button><FormattedMessage id='production_units.change_status.button_label' /></Button>)
                : renderStatusSection()
        }
    </div>;
    const content =
        <div
            className={twMerge('flex flex-row justify-between items-center cursor-pointer', !onlyButton && 'p-segment')}
            style={!onlyButton ? { backgroundColor: status.background_color, color: status.text_color } : null}>
            <ProductionUnitChangeStatusDialog
                trigger={trigger}
                status={status}
                handleUpdate={(statusId, comment) => {
                    dispatch(setProductionUnitStatus({ productionUnitId, status: { comment, production_unit_status_id: statusId } }));
                }} />
        </div>
    return content;
}
export default ProductionUnitStatus;
