import React from 'react';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

type Props = {
    className?: string;
    keyItem: KeyType;
    value: Value;
};

type KeyType = {
    name: string;
    icon?: Icons;
}
type Value = {
    value: ComponentOrStringType;
}
const KeyValuePair = (props: Props): React.ReactElement => {
    const { className, keyItem, value } = props;
    
    const getKey = () => {
        return <div className='text-secondary gap-1 flex text-sm items-center'>{keyItem.icon && <Icon className='text-secondary' size={Sizes.Small} name={keyItem.icon} />}{keyItem.name}</div>
    };
    
    const getValue = () => {
        return <div>{value.value}</div>
    };
    
    return <div className={twMerge('flex flex-col gap-1', className)}>
        {getKey()}
        {getValue()}
    </div>
}
export default KeyValuePair;
