/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Option } from './Option';
import type { UUID } from './UUID';

/**
 *
 *
 */
export type SetMultipleChoice = {
    audit_id: UUID;
    checkpoint_id: UUID;
    choices: SetMultipleChoice.choices;
    options: Array<Option>;
};

export namespace SetMultipleChoice {

    export enum choices {
        ANY = 'any',
        ONE = 'one',
    }


}

