import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Form, Input } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { ListType, getFilteredListNew as getFilteredList } from '../../inspections/slices/inspectionsListSlice';
import { getQuery } from '../selectors';

type SearchProps = {
    push?: boolean;
    type?: ListType;
    handleQuery?(q: string);
}
const Search = (props: SearchProps): React.ReactElement => {
    const { push = true, type, handleQuery } = props;
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const q = useSelector(getQuery);
    const [query, setQuery] = useState(q);

    const doSearch = (): void => {
        handleQuery && handleQuery(query);
        push ? history.push('/inspections?search=' + encodeURIComponent(query)) : dispatch(getFilteredList({ start: 0, prPage: undefined, filters: { 'search': [query] }, listType: type }));

    }
    const placeholderLabel = intl.formatMessage({ id: 'search.search_inspections' });
    useEffect(() => {
        if (history.action === 'POP') {
            setQuery(q);
        }
    }, [q])
    return (
        <Form onSubmit={doSearch} className='searchForm'>
            <Input
                type='text'
                autoFocus={true}
                placeholder={placeholderLabel}
                data-test-id='input-search'
                onChange={(e, item): void => setQuery(item.value)}
                value={query}
                action={<Button
                    tabIndex='-1'
                    icon='search'
                    className='doSearch'
                />}
            />
        </Form>

    );
}
export default Search;
