import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { FileResource } from '../../../backend_api/models';
import { AttachmentResponse } from '../../../modules/Images/actions/actions';
import { addingAuditCommentSelector } from '../../../modules/audit/selectors/auditSelectors';
import FileUpload from '../../../modules/files/components/FileUpload';
import { StandAloneMessage } from '../../../modules/messages/components/Messages';
import { TextArea, TextAreaHeight } from '../../ui/components/TextArea';
import { preventDefaultAndStopPropagation, useIsMobileDevice } from '../../utils';
import Button from '../basic/Button';
import Icon from '../basic/Icon';
import CommentAttachments from './CommentAttachments';

type Props = {
    showUpload?: boolean;
    uploadEndpoint?: string;
    disabled?: boolean;
    isReply?: boolean;
    handleNewComment(comment: string, uploads?: FileResource[], isReply?: boolean): void;
}
const CommentInput = (props: Props): React.ReactElement => {
    const { showUpload = true, uploadEndpoint, disabled = false, handleNewComment, isReply = false } = props;
    const intl = useIntl();
    const [newComment, setNewComment] = useState<string>('');
    const [uploads, setUpload] = useState<FileResource[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [showReply, setShowReply] = useState<boolean>(props.isReply || false);
    const [saving, setIsSaving] = useState<boolean>(false);
    const loading = useSelector(addingAuditCommentSelector);
    const isMobile = useIsMobileDevice();

    const remove = (index: number) => {
        const att = [...uploads];
        att.splice(index, 1);
        setUpload(att);
    }
    useEffect(() => {
        if (saving && !loading) {
            setIsSaving(false);
        }
    }, [loading])
    return <>
        {isReply && <div className='link noUnderline text-sm'
            data-test-id='btn-comment-reply'
            onClick={(e): void => {
                preventDefaultAndStopPropagation(e);
                setShowReply(!showReply);
            }}>
            <FormattedMessage id='comments.reply' />
        </div>}
        {!showReply && <Form className='writeComment'>
            <Form.Field>
                <TextArea 
                    value={newComment}
                    onChange={(value) => setNewComment(value)}
                    placeholder={intl.formatMessage({ id: 'comments.write_a_comment' })}
                    height={isReply ? TextAreaHeight.SINGLE_LINE_AUTO_EXPAND : TextAreaHeight.DEFAULT_AUTO_EXPAND}
                />
                {showUpload && <FileUpload
                    inline={true}
                    autoClearAfterUpload={true}
                    showCompleteMessage={false}
                    allowMultiUpload
                    fileSelectorShowIcon={false}
                    fileSelectorLabelClass='link text-sml font-normal pt-1'
                    fileSelectorLabelText={intl.formatMessage({ id: uploads.length > 0 ? 'audits.comments.comment.attach_more_files.label' : 'audits.comments.comment.attach_files.label' })}
                    disabled={disabled || false}
                    endpoint={uploadEndpoint}
                    onUploadComplete={(file: AttachmentResponse) => {
                        if (file.error) {
                            setError(true);
                        } else {
                            error && setError(false);
                            setUpload([file as FileResource, ...uploads]);
                        }
                    }}
                ></FileUpload>}
                <CommentAttachments attachments={uploads} remove={remove} />
                {error && <StandAloneMessage removeIcon message={intl.formatMessage({ id: 'upload.an_error_occured.file_could_not_be_uploaded' })} type='error' onRemove={() => setError(false)} />}
            </Form.Field>
            <Form.Field>
                <div className='pb-3'>
                    {saving && loading && <StandAloneMessage message={intl.formatMessage({id: 'comments.saving_comment'})} type='info' className='flex -mt-4 mb-2'/>}
                    <Button
                        className='py-2 px-4 text-sm text-white'
                        data-test-id='btn-send-comment'
                        primary
                        // loading={isLoading}
                        disabled={newComment.length === 0}
                        onClick={() => {
                            setIsSaving(true);
                            handleNewComment(newComment, uploads, true);
                            setTimeout(() => {
                                setNewComment('');
                                setUpload([]);
                                isReply && setShowReply(true);
                            }, 500);
                        }}
                    >
                        {intl.formatMessage({ id: uploads.length > 0 ? 'comments.attach_and_send' : 'comments.send' })}
                        <Icon name='chevron_right' className=' text-xl' />
                    </Button>
                </div>
            </Form.Field>
        </Form>}
    </>
}

export default CommentInput;