import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Grid } from 'semantic-ui-react';
import { TextTable } from '../types';
import TextTableDialog from './TextTableDialog';

type TextTableDetailsProps = {
    data: TextTable;
    language: string;
    update(data: TextTable);
}

const TextTableDetails = (props: TextTableDetailsProps): React.ReactElement => {
    const intl = useIntl();
    const [showDialog, setShowDialog] = useState<boolean>();

    const update = (data: TextTable): void => {
        props.update(data);
        setShowDialog(false);
    }

    return (
        <Grid style={{ margin: 'unset' }}>
            <Grid.Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button positive onClick={(): void => setShowDialog(true)} content={intl.formatMessage({ id: 'checklists.editor.text_table.button' })} data-test-id='open-text-table-dialog-button' />
            </Grid.Row>
            <TextTableDialog
                show={showDialog}
                data={props.data}
                language={props.language}
                update={update}
                cancel={(): void => setShowDialog(false)}
            />
        </Grid>
    )
}

export default TextTableDetails;