import React from 'react';
import { useSelector } from 'react-redux';
import { Audit } from '../../../backend_api/models';
import { AppState } from '../../../base/types';
import { useAppDispatch } from '../../../store';
import CorrectiveActionStatusPie from '../../correctiveActions/components/CorrectiveActionStatusPie';
import CorrectiveActionStatusPopup from '../../correctiveActions/components/CorrectiveActionStatusPopup';
import { getCorrectivationsForAudit } from '../../correctiveActions/correctiveActionsSlice';
import { auditActionsSelector } from '../../correctiveActions/selectors/correctiveActionSelectors';

type Props = {
    correctiveActionStatus: Audit['corrective_actions_status'];
    auditId?: string;
    className?: string;
    showEmptyCorrectiveAction?: boolean;
    w?: number;
    h?: number;
};

const AuditCorrectiveActionStatusPie = (props: Props): React.ReactElement => {
    const { correctiveActionStatus, showEmptyCorrectiveAction } = props;
    const correctiveActions = useSelector((state: AppState) => auditActionsSelector(state, props.auditId));
    const dispatch = useAppDispatch();

    const loadCorrectiveActions = () => {
        dispatch(getCorrectivationsForAudit(props.auditId))
    }

    const trigger = <span><CorrectiveActionStatusPie showEmptyCorrectiveAction={showEmptyCorrectiveAction} correctiveActionStatus={correctiveActionStatus} h={props.h} w={props.w} /></span>
    return <CorrectiveActionStatusPopup correctiveActions={correctiveActions} loadCorrectiveActions={loadCorrectiveActions} context={'audit'} correctiveActionStatus={correctiveActionStatus} trigger={trigger} />;
}
export default AuditCorrectiveActionStatusPie;
