/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 63.0.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Supplier2,
    Supplier2FromJSON,
    Supplier2FromJSONTyped,
    Supplier2ToJSON,
} from './';

/**
 * List of supplier, which might be incomplete
 * @export
 * @interface SupplierList
 */
export interface SupplierList {
    /**
     * 
     * @type {string}
     * @memberof SupplierList
     */
    status: SupplierListStatusEnum;
    /**
     * 
     * @type {Array<Supplier2>}
     * @memberof SupplierList
     */
    suppliers: Array<Supplier2>;
}

/**
* @export
* @enum {string}
*/
export enum SupplierListStatusEnum {
    Complete = 'complete',
    Incomplete = 'incomplete',
    Fetching = 'fetching'
}

export function SupplierListFromJSON(json: any): SupplierList {
    return SupplierListFromJSONTyped(json, false);
}

export function SupplierListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'suppliers': ((json['suppliers'] as Array<any>).map(Supplier2FromJSON)),
    };
}

export function SupplierListToJSON(value?: SupplierList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'suppliers': ((value.suppliers as Array<any>).map(Supplier2ToJSON)),
    };
}


