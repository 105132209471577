/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileResourceDescription } from './FileResourceDescription';
import {
    FileResourceDescriptionFromJSON,
    FileResourceDescriptionFromJSONTyped,
    FileResourceDescriptionToJSON,
} from './FileResourceDescription';
import type { FileResourceFile } from './FileResourceFile';
import {
    FileResourceFileFromJSON,
    FileResourceFileFromJSONTyped,
    FileResourceFileToJSON,
} from './FileResourceFile';
import type { FileResourceImage } from './FileResourceImage';
import {
    FileResourceImageFromJSON,
    FileResourceImageFromJSONTyped,
    FileResourceImageToJSON,
} from './FileResourceImage';

/**
 * File Resource: Image or File. Can include a description
 * @export
 * @interface FileResource
 */
export interface FileResource {
    /**
     * 
     * @type {FileResourceDescription}
     * @memberof FileResource
     */
    description: FileResourceDescription | null;
    /**
     * 
     * @type {FileResourceFile}
     * @memberof FileResource
     */
    file: FileResourceFile | null;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    id: string;
    /**
     * 
     * @type {FileResourceImage}
     * @memberof FileResource
     */
    image: FileResourceImage | null;
    /**
     * 
     * @type {string}
     * @memberof FileResource
     */
    type: FileResourceTypeEnum;
}


/**
 * @export
 */
export const FileResourceTypeEnum = {
    File: 'file',
    Image: 'image',
    Video: 'video'
} as const;
export type FileResourceTypeEnum = typeof FileResourceTypeEnum[keyof typeof FileResourceTypeEnum];


/**
 * Check if a given object implements the FileResource interface.
 */
export function instanceOfFileResource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function FileResourceFromJSON(json: any): FileResource {
    return FileResourceFromJSONTyped(json, false);
}

export function FileResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': FileResourceDescriptionFromJSON(json['description']),
        'file': FileResourceFileFromJSON(json['file']),
        'id': json['id'],
        'image': FileResourceImageFromJSON(json['image']),
        'type': json['type'],
    };
}

export function FileResourceToJSON(value?: FileResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': FileResourceDescriptionToJSON(value.description),
        'file': FileResourceFileToJSON(value.file),
        'id': value.id,
        'image': FileResourceImageToJSON(value.image),
        'type': value.type,
    };
}

