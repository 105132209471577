import React, { } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { DetailedSupplier, Group } from '../../../backend_api/models';
import { InputWithHints } from './InputWithHints';
import { GroupsWrapper } from '../../groups/components/GroupsWrapper';


export function NewSupplierInputsWithHints(props: {
    selectableSuppliers: DetailedSupplier[],
    groupsInput: Group[],
    supplierNameInput: string,
    supplierNumberInput: string,
    onGroupsChanged(groups: Group[]): void,
    onSupplierNameChanged(supplierName: string): void,
    onSupplierNumberChanged(supplierNumber: string): void,
    onExistingSupplierIdSelected(supplierId: string): void
}): React.ReactElement {
    return (
        <Form>
            <SupplierNameInputWithHints
                onExistingSupplierIdSelected={props.onExistingSupplierIdSelected}
                onInputNameChanged={props.onSupplierNameChanged}
                selectableSuppliers={props.selectableSuppliers}
                supplierName={props.supplierNameInput}
            />
            <SupplierNumberInputWithHints
                onExistingSupplierIdSelected={props.onExistingSupplierIdSelected}
                onInputNumberChanged={props.onSupplierNumberChanged}
                selectableSuppliers={props.selectableSuppliers}
                supplierNumber={props.supplierNumberInput}
            />
            <GroupsWrapper groupsInput={props.groupsInput} onGroupsChanged={props.onGroupsChanged} />
        </Form>
    )
}

function SupplierNameInputWithHints(
    props: {
        supplierName: string,
        selectableSuppliers: DetailedSupplier[],
        onExistingSupplierIdSelected(supplierId: string): void,
        onInputNameChanged(supplierName: string): void,
    }
) {
    return (
        <InputWithHints
            input={props.supplierName}
            entities={props.selectableSuppliers}
            onInputChanged={props.onInputNameChanged}
            findMatches={findSupplierMatchingNameInput}
            renderShowAll={(count: number) => renderShowAllSupplierName(props.supplierName, count)}
            renderTitle={() => <label><FormattedMessage id='audits.input_label.supplier_name' /></label>}
            onEntitySelected={(t) => props.onExistingSupplierIdSelected(t.id)}
            renderMatchingEntity={renderMatchingSupplier}
        />
    )
}

function SupplierNumberInputWithHints(
    props: {
        supplierNumber: string,
        selectableSuppliers: DetailedSupplier[],
        onExistingSupplierIdSelected(supplierId: string): void,
        onInputNumberChanged(supplierNumber: string): void,
    }
) {
    return (
        <InputWithHints
            input={props.supplierNumber}
            entities={props.selectableSuppliers}
            onInputChanged={props.onInputNumberChanged}
            findMatches={findSupplierMatchingNumberInput}
            renderShowAll={(count: number) => renderShowAllSupplierNumber(props.supplierNumber, count)}
            renderTitle={() => <label><FormattedMessage id='audits.input_label.supplier_number' /></label>}
            onEntitySelected={(t) => props.onExistingSupplierIdSelected(t.id)}
            renderMatchingEntity={renderMatchingSupplier}
        />
    )
}

function renderMatchingSupplier(supplier: DetailedSupplier) {
    return <FormattedMessage
        id='audits.create_supplier.supplier_exists'
        values={{
            supplierName: <span className='font-semibold'>{supplier.name}</span>,
            supplierNumber: <span className='font-semibold'>{supplier.number}</span>
        }} />
}

function renderShowAllSupplierName(supplierName: string, count: number) {
    return (<FormattedMessage
        id='audits.create_supplier.suppliers_exist'
        values={{
            supplierName: <span className='font-semibold'>{supplierName}</span>,
            cnt: count
        }} />)
}

function renderShowAllSupplierNumber(supplierNumber: string, count: number) {
    return (<FormattedMessage
        id='audits.create_supplier.suppliers_number_exist'
        values={{
            supplierNumber: <span className='font-semibold'>{supplierNumber}</span>,
            cnt: count
        }} />)
}

function findSupplierMatchingNameInput(suppliers: DetailedSupplier[], supplierNameInput: string): DetailedSupplier[] {
    return suppliers.filter((supplier) => {
        return supplierMatchesNameInput(supplier, supplierNameInput);
    });
}

function findSupplierMatchingNumberInput(suppliers: DetailedSupplier[], supplierNumberInput: string) {
    return suppliers.filter((supplier) => {
        return supplierMatchesNumberInput(supplier, supplierNumberInput);
    });
}

function supplierMatchesNameInput(supplier: DetailedSupplier, supplierNameInput: string) {
    return stringMatchesInput(supplier.name, supplierNameInput);
}

function supplierMatchesNumberInput(supplier: DetailedSupplier, supplierNumberInput: string) {
    return stringMatchesInput(supplier.number, supplierNumberInput);
}

function stringMatchesInput(existing: string, input: string, minimumInputLength = 3) {
    const a = (existing || '').toLocaleLowerCase();
    const b = (input || '').toLocaleLowerCase();

    if (b.length < minimumInputLength) {
        return false;
    }
    return a.includes(b);
}