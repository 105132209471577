import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getShowFiltersSelector, showFilters } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { hasActiveFiltersSelector } from '../selectors';

type Props = {
    className?: string;
};

const AddFilters = (props: Props): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const filtersVisible = useSelector(getShowFiltersSelector);
    const hasActiveFilters = useSelector(hasActiveFiltersSelector);

    const toggleFilters = () => {
        dispatch(showFilters(!filtersVisible));
    }
    const getLabel = () => {
        let key = 'filters.selector.';
        if (filtersVisible) {
            key += 'hide_filters';
        } else {
            key += hasActiveFilters ? 'edit_filters' : 'add_filters';
        }
        return intl.formatMessage({ id: key });
    }
    return <div className={twMerge('', className)}>
        <Button onClick={toggleFilters} className='whitespace-nowrap'>{getLabel()}</Button>
    </div>
}
export default AddFilters;
