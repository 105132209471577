import { Pie } from '@nivo/pie';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import { capitaliseWord } from '../../../../base/utils';
import config from '../../config';

type SupplierQCPiesProps = {
    data: any;
};
const SupplierQCPies = (props: SupplierQCPiesProps): React.ReactElement => {
    const intl = useIntl();
    const getColors = (item): string => {
        return config.supplierQCColors[item.data.colorId];
    }
    return (<Segment.Group raised container>
        <Segment secondary>
            <Header as='h3'><FormattedMessage id='dashboard.pie_header.inspections_by_supplier_qc' /></Header>
        </Segment>
        <Segment>
            <div className='pies'>
                {props.data && props.data.map((pieData: any, index: number) => {
                    const legendHeight = 60;
                    let cnt = 0;
                    pieData.values.forEach((val) => {
                        cnt += val.value;
                    });
                    return <div key={index} className='pie flex-col mb-24'>
                        <div className='flex justify-center'>
                            <div className='typeLabel'>{pieData.label === '' ? intl.formatMessage({ id: 'dashboard.inspection_type.no_type' }) : pieData.label} ({cnt})</div>
                        </div>
                        <div className='flex justify-center'>
                            <Pie
                                width={config.pie.width}
                                height={config.pie.width + legendHeight}
                                colors={getColors}
                                data={pieData.values}
                                arcLabelsSkipAngle={20}
                                enableArcLinkLabels={false}
                                borderWidth={1}
                                borderColor={config.pie.textColor}
                                margin={{ top: 0, right: 0, bottom: legendHeight, left: 0 }}
                                arcLabel={(d): string => {
                                    let total = 0;
                                    pieData.values.forEach((item) => {
                                        total += item.value;
                                    });
                                    let ret = capitaliseWord(d.label as string) + '. ';
                                    ret += total > 0 ? (d.value / total * 100).toFixed(2) + '%' : d.value;
                                    return ret;
                                }}
                                tooltip={({ datum: { id, value, color } }) => {
                                    let total = 0;
                                    pieData.values.forEach((item) => {
                                        total += item.value;
                                    });
                                    return <div className='px-2 py-1 bg-white shadow-xs flex space-x-2 items-center'>
                                        <div className='w-4 h-4' style={{ background: color }}></div>
                                        <div className='flex space-x-1'><span>{capitaliseWord(id as string)}:</span><span className='font-semibold'>{value}</span><span>({(value / total * 100).toFixed(2)}%)</span></div>
                                    </div>
                                }}

                                arcLabelsTextColor={config.pie.textColor}
                                legends={[
                                    {
                                        anchor: 'bottom-left',
                                        direction: 'row',
                                        itemsSpacing: 0,
                                        itemWidth: 150,
                                        itemHeight: 18,
                                        symbolSize: 15,
                                        translateY: 55,
                                        translateX: 10,
                                    },
                                ]}
                            /></div>
                    </div>;
                })}
            </div>
        </Segment>
    </Segment.Group>);
};

export default SupplierQCPies;
