/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * To log in to this organization, the user needs to supply his second factor token.
 *
 */
export type TwoFactorRequired = {
    account_id: string;
    last_login: string | null;
    organization_id: string;
    organization_logo_url: string | null;
    organization_name: string;
    two_factor_state: TwoFactorRequired.two_factor_state;
    type: TwoFactorRequired.type;
    user_id: string;
};

export namespace TwoFactorRequired {

    export enum two_factor_state {
        SETUP_REQUIRED = 'setup_required',
        RESET_REQUIRED = 'reset_required',
        READY = 'ready',
    }

    export enum type {
        TWO_FACTOR_REQUIRED = 'two_factor_required',
    }


}

