import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import { ERROR } from '../../base/types';
import * as types from './types';

const initialState: types.DashboardState = {
    defectTypeOverview: undefined,
    inspectionsStatusByType: undefined,
    inspectionsStatusByConclusion: undefined,
    inspectionsStatusBySupplierQC: undefined,
    defectsStatusBySeverity: undefined,
    isLoading: false,
    isFetching: {
        inspectionBars: false,
        inspectionPies: false,
        inspectionQCPies: false,        
    }
};

const errorTypes = ['getInspectionsStatusByConclusion', 'getInspectionsStatusByType', 'getDefectsStatusBySeverity'];

export const reducer = handleActions({
    [types.GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isLoading: true,
            isFetching: {
                inspectionBars: true,
            }
        };
    },
    [types.GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS]: (state = initialState, action: Action<types.GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS>) => {
        const inspectionsStatusByType = action.payload.data;
        return {
            ...state,
            inspectionsStatusByType,
            isLoading: false,
            isFetching: {
                inspectionBars: false,
                inspectionPies: state.isFetching.inspectionPies,
                inspectionQCPies: state.isFetching.inspectionQCPies,
            }
        };
    },
    [types.GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS]: (state = initialState, action: Action<types.GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS>) => {
        const inspectionsStatusBySupplierQC = action.payload.data.result;
        return {
            ...state,
            inspectionsStatusBySupplierQC,
            isLoading: false,
            isFetching: {
                inspectionBars: state.isFetching.inspectionBars,
                inspectionPies: state.isFetching.inspectionPies,
                inspectionQCPies: false,
            }
        };
    },
    [types.GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isLoading: true,
            isFetching: {
                inspectionQCPies: true,
                inspectionBars: state.isFetching.inspectionBars,
                inspectionPies: state.isFetching.inspectionPies,
            }
        };
    },
    [types.GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isLoading: true,
            isFetching: {
                inspectionPies: true,
                inspectionBars: state.isFetching.inspectionBars,
                inspectionQCPies: state.isFetching.inspectionQCPies,
            }
        };
    },
    [types.GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS]: (state = initialState, action: Action<types.GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS>) => {
        const inspectionsStatusByConclusion = action.payload.data.result;
        return {
            ...state,
            inspectionsStatusByConclusion,
            isLoading: false,
            isFetching: {
                inspectionPies: false,
            }
        };
    },
    [types.GET_DEFECTS_STATUS_BY_SEVERITY_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isLoading: true,
        };
    },
    [types.GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS]: (state = initialState, action: Action<types.GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS>) => {
        const defectsStatusBySeverity = action.payload.data;
        return {
            ...state,
            defectsStatusBySeverity,
            isLoading: false,
        };
    },
    [types.GET_LATEST_INSPECTIONS_SUCCESS]: (state = initialState, action: Action<types.GET_LATEST_INSPECTIONS_SUCCESS>) => {
        const latestInspections = action.payload.data;
        return {
            ...state,
            latestInspections,
        };
    },
    [ERROR]: (state = initialState, action: Action<ERROR>) => {
        const type = action.payload.type;
        if (errorTypes.includes(type)) {
            return {
                ...state,
                isLoading: false,
            };
        }
        return { ...state };

    },
}, initialState);

export default reducer;
