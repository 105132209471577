import React, { ChangeEvent, Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Checkbox, Input, Loader } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import Segment from '../../../base/components/basic/Segment';
import Form from '../../../base/components/basic/form/Form';
import FormField from '../../../base/components/basic/form/FormField';
import { DEFAULT_PASSWORD_LENGTH } from '../../../base/config';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { Space } from '../../globals/components/Utils';
import TwoFactorCodeInput from './TwoFactorCodeInput';

type OwnProps = {
    resetToken: string | string[];
    okToReset: boolean;
    isAuthenticated: boolean;
    isFetching: boolean;
    message: any; // TODO: Create proper type for message
    email: string;
    statusOk: boolean;
    justReset: boolean;
    emailSent: boolean;
    emailValid?: boolean;
    twofactorEnabled?: boolean;
    organizationName?: string;
    errors?: any;
    handleChange(e: ChangeEvent, { name, value }): void;
    handleResetPasswordInput(email: string, isConfirm: boolean): void;
    handleResetEmail(email: string): void;
    // handleForm(e: React.FormEvent<HTMLFormElement>): void;
    handleSumbitResetPasswords()
    handleSendResetEmail();
    resetOk();
    clearResetState();
    clearUserData(): void;
    handleTokenChanged(code: string): void;
};

type ResetBoxProps = OwnProps;

const ResetBox = (props: ResetBoxProps): React.ReactElement => {
    const { emailSent, statusOk, justReset, twofactorEnabled, organizationName } = props;
    const intl = useIntl();
    const noResetToken = props.resetToken === null;
    const [pwType, setPwType] = useState<'text' | 'password'>('password');
    const emailPlaceholder: string = intl.formatMessage({ id: 'login.email' });
    const pwPlaceholder: string = intl.formatMessage({ id: 'login.form.password' });
    const confirmPwPlaceholder: string = intl.formatMessage({ id: 'login.confirm_password' });
    const close = <Link onClick={props.clearResetState} to='/login' className='link noUnderline flex'><FormattedMessage id='globals.close' /></Link>;
    const pwDoNotMatch = props.errors.pw.ok && props.errors.pwConfirm.ok && !props.okToReset;
    const heading = () => {
        if (!justReset) {
            return noResetToken ? <FormattedMessage id='login.forgot_password' /> : <FormattedMessage id='login.set_your_password' />;
        } else {
            return statusOk ? <FormattedMessage id='login.your_password_was_reset' /> : <FormattedMessage id='login.error_your_password_was_not_reset' />
        }
    }
    const footer = () => {
        if (statusOk) {
            if (!justReset) {
                if (emailSent || !noResetToken) {
                    return close;
                }
                if (noResetToken && !emailSent) {
                    return <Link to='/login' className='link noUnderline'><FormattedMessage id='login.go_to_login' /></Link>;
                }
            } else {
                return <div><Space /></div>
            }
        }
        if (!statusOk) {
            return close;
        }
    }
    const getChangeButtonText = () => {
        if (props.okToReset) {
            return 'login.ok_set_the_new_password';
        }
        if (pwDoNotMatch) {
            return props.errors.dirty ? 'login.pw_do_not_match' : 'login.enter_new_pws';
        }
        return 'login.cannot_set_new_pw_yet';
    }
    return (
        <div className='flex justify-center mx-4 md:mx-0 md:mb-32 mb-0'>
            <Loader active={props.isFetching} inline='centered' />
            {!props.isFetching &&
                <Segment className='w-136 md:pb-4'
                    header={organizationName ? organizationName : null}
                    heading={heading()}
                    footer={footer()}
                >
                    {justReset && statusOk &&
                        <Fragment>
                            <p className='text-lg pb-2 flex space-x-2'><Icon name='check_circle' className='text-brand' /><div><FormattedMessage id='login.your_password_was_succesfully_reset' /></div>   </p>
                        </Fragment>}
                    {justReset && statusOk && <>

                        <div className='pt-4 justify-center flex'><Button onClick={() => props.resetOk()}><FormattedMessage id='globals.confirmation.continue' /></Button></div>
                    </>}
                    <div className='flex space-y-4 flex-col' >
                        {!statusOk && <div className='text-base'>
                            <div className='mb-4'>
                                <p className='flex space-x-2 text-lg leading-normal'><Icon name='error_outline' className='text-alert text-2xl' /><div><FormattedMessage id='login.sorry_something_went_wrong_and_we_could_not_reset_your_password_please_try_again' /></div></p>
                                <p><FormattedMessage id='login.click_this_to_reset_your_password_and enter_your_email_again' /></p></div>
                            <Button className='w-full justify-center' onClick={(): void => props.clearUserData()} behaveAsLink link='/reset_pw'><FormattedMessage id='login.reset_password_again' /></Button>

                        </div>}

                        {!justReset && statusOk && <>
                            {!justReset && !noResetToken &&
                                <Form className='pt-8' onSubmit={(e) => {
                                    (props.isFetching || !props.okToReset) ? null : props.handleSumbitResetPasswords();
                                    preventDefaultAndStopPropagation(e);
                                }}>
                                    <div className='text-base pb-4'>
                                        <FormattedMessage id='login.enter_the_new_password_twice' />
                                        <div className='text-secondary pt-2'>
                                            <FormattedMessage id='login.enter_new_password.requirements' />
                                            <ul className='list-disc'>
                                                <li className='ml-6 my-3'><FormattedMessage id='login.enter_new_password.requirements.req_len' values={{ minLen: DEFAULT_PASSWORD_LENGTH }} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <FormField>
                                        <label><FormattedMessage id='login.form.password' /></label>
                                        <Input
                                            autoFocus
                                            type={pwType}
                                            name='password'
                                            placeholder={pwPlaceholder}
                                            disabled={props.isFetching}
                                            autoComplete='off'
                                            onChange={(e, item) => props.handleResetPasswordInput(item.value, false)}
                                            onBlur={props.handleChange}
                                        />
                                        {!props.errors.pw.ok && <span className='text-alert flex pt-2 text-sml'>{props.errors.pw.message}</span>}
                                    </FormField>
                                    <FormField>
                                        <label><FormattedMessage id='login.confirm_password' /></label>
                                        <Input
                                            type={pwType}
                                            name='confirmPassword'
                                            placeholder={confirmPwPlaceholder}
                                            disabled={props.isFetching}
                                            autoComplete='off'
                                            onChange={(e, item) => props.handleResetPasswordInput(item.value, true)}
                                            onBlur={props.handleChange}
                                        />
                                        {!props.errors.pwConfirm.ok && <span className='text-alert flex pt-2 text-sml'>{props.errors.pwConfirm.message}</span>}
                                    </FormField>
                                    {twofactorEnabled && <div>
                                        <label><FormattedMessage id='login.enter_two_factor_code' /></label>
                                        <TwoFactorCodeInput
                                            handleTokenChanged={(code) => {
                                                props.handleTokenChanged(code);
                                            }}
                                            autoFocus={false}
                                            handleTokenDone={(code) => props.handleTokenChanged(code)}
                                        />
                                    </div>}
                                    <div className='items-center flex text-sm space-x-2 pb-2 cursor-pointer pt-4' onClick={() => setPwType(pwType === 'text' ? 'password' : 'text')}>
                                        <Checkbox name='changePw' checked={pwType === 'text'} />
                                        <span><FormattedMessage id='login.show_passwords' /></span>
                                    </div>
                                    <Button primary={props.okToReset} className={twMerge('space-x-1 w-full justify-center')} disabled={props.isFetching || !props.okToReset}>
                                        <FormattedMessage id='login.set_password' />
                                    </Button>
                                    {!props.okToReset && props.errors.dirty && <div className='flex space-x-1 py-2 justify-center'>
                                        <Icon name={props.okToReset ? 'check_circle' : 'remove_circle'} className={twMerge((!props.okToReset) && 'text-alert')} />
                                        <div className='text-alert'>
                                            <FormattedMessage id={getChangeButtonText()} />
                                        </div>
                                    </div>}

                                </Form>}
                        </>}
                        {!justReset && statusOk && <>
                            {noResetToken && !emailSent &&
                                <Form className='pt-8' onSubmit={(e) => {
                                    (props.isFetching || !props.emailValid) ? null : props.handleSendResetEmail();
                                    preventDefaultAndStopPropagation(e);
                                }}>
                                    <div className='text-base pb-8'><FormattedMessage id='login.enter_your_email_address_and_we_will_send_you_an_email_with_instructions_to_reset_your_password' /></div>
                                    <FormField>
                                        <label><FormattedMessage id='login.enter_email' /></label>
                                        <Input
                                            control={Input}
                                            name='email'
                                            placeholder={emailPlaceholder}
                                            disabled={props.isFetching}
                                            autoComplete='off'
                                            onChange={(e, item) => props.handleResetEmail(item.value)}
                                            icon='user'
                                            iconPosition='left'
                                        // ref={props.usernameInput}
                                        />
                                    </FormField>
                                    <Button className='w-full justify-center' disabled={props.isFetching || !props.emailValid}><FormattedMessage id='login.reset_password' /></Button>
                                </Form>}
                            {!justReset && statusOk && emailSent &&
                                <div className='text-base pb-2'>
                                    <p className='flex text-lg space-x-2'><Icon className='text-brand' name='check_circle' /><div><FormattedMessage id='login.instruction_email_sent' /></div></p>
                                    <p><FormattedMessage id='login.we_have_sent_an_email_to_you_with_instructions_on_how_to_reset_your_password' /></p>
                                    <p><FormattedMessage id='login.please_check_your_inbox' /></p>
                                    {/* {close} */}
                                </div>
                            }
                        </>}
                    </div>
                </Segment>
            }
        </div>
    );
};

export default ResetBox;
