import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import { DO_SEARCH, POPULATE_SEARCH_RESULT, SET_QUERY } from './actionTypes';
import { SearchState } from './state';

const initialState: SearchState = {
    result: [{}],
    query: '',
};
export const reducer = handleActions({
    ['CLEAR_SEARCH_QUERY']: (state = initialState) => {
        return Object.assign({}, state,
            {
                query: '',
            },
        );
    },
    [DO_SEARCH]: (state = initialState, action: Action<DO_SEARCH>) => {
        return Object.assign({}, state,
            {
                query: action.payload.query,
            },
        );
    },
    [SET_QUERY]: (state = initialState, action: Action<DO_SEARCH>) => {
        return Object.assign({}, state,
            {
                query: action.payload.query,
            },
        );
    },
    [POPULATE_SEARCH_RESULT]: (state = initialState, action: Action<POPULATE_SEARCH_RESULT>) => {
        return Object.assign({}, state,
            {
                result: action.payload.result,
                query: action.payload.query,
            },
        );
    },
}, initialState);

export default reducer;
