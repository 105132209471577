import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CreateCorrectiveActionRequest } from '../../../backend_api/models';
import ContextMenu from '../../../base/components/basic/ContextMenu';
import { useAppDispatch } from '../../../store';
import { createCorrectiveActionOnAudit } from '../../correctiveActions/correctiveActionsSlice';

type AuditContextMenuProps = {
    ca: CreateCorrectiveActionRequest;
};

const AuditContextMenu = (props: AuditContextMenuProps): React.ReactElement => {
    const { ca } = props;
    const dispatch = useAppDispatch();
    const createCa = () => {
        dispatch(createCorrectiveActionOnAudit({ 'list': [ca] }));
    }
    const getItem = (item: React.ReactElement, disabled = false) => {
        return <div className={classnames(disabled ? 'hover:bg-white cursor-default' : 'hover:bg-surface-plain', 'text-sm hover:text-primary px-4 -mx-4  link noUnderline')}>{item}</div>;
    }
    return <ContextMenu
        open={false}
        close={() => null}
        triggerIconClass='text-xl w-4 justify-center flex'
    >
        <div className='text-sm pb-2'>
            <h5><FormattedMessage id='audits.context_menu.audit_report' /></h5>
            {getItem(<div style={{ textIndent: '1rem' }} className='-mx-4 py-2 pr-2' onClick={() => createCa()}><FormattedMessage id='audits.context_menu.create_corrective_action' /></div>)}
        </div>
    </ContextMenu>
}
export default AuditContextMenu;
