import React, { Component, Fragment, SyntheticEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Form, Grid, Header, Modal, Popup, Segment } from 'semantic-ui-react';
import { DetailedSupplier } from '../../../backend_api/models';
import { AppState, OptionsType } from '../../../base/types';
import { InfoIcon } from '../../globals/components/Utils';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { searchOptionTextAndDescription } from '../../inspections/Utils';
import { Inspection, Order } from '../../inspections/types';
import CreateProductionUnit from '../../productionUnits/components/CreateProductionUnit';
import { getProductionUnits, getSupplierData, getSuppliers, setInspectionProductionUnitWillBeOverwritten, updateInspectionProductionUnit, updateInspectionSupplier } from '../actions';
import CreateSupplier from '../containers/CreateSupplier';
import { getCurrentInspectionSupplierIdSelector, getCurrentSupplierSelector, getInspectionProductionUnitToBeOverwrittenSelector, getInspectionProductionUnitsSelector, getSuppliersSelector, getSuppliersSelectorSelector, isSupplierDataFetchingSelector } from '../selectors';
import { ProductionUnit as ProductionUnitType } from '../types';
import { getSupplierNameAndNumber } from '../util';
import ProductionUnitReadOnly from './ProductionUnitReadOnly';
import ProductionUnitsSelector from './ProductionUnitsSelector';

type OwnProps = {
    order: Order;
    supplierId: string;
    supplierEntity: DetailedSupplier;
    linkedProductionUnitId: string;
    linkedProductionUnit: ProductionUnitType;
    disabled: boolean;
}

type StateProps = {
    currentSupplierId: string;
    suppliers: DetailedSupplier[];
    suppliersSelector: OptionsType[];
    currentSupplier: DetailedSupplier;
    productionUnits: OptionsType[];
    productionUnitOverwrite: boolean;
    isFetching: boolean;
}

type DispatchProps = {
    actions: {
        getSuppliers: typeof getSuppliers;
        updateInspectionSupplier: typeof updateInspectionSupplier;
        getProductionUnits: typeof getProductionUnits;
        getSupplierData: typeof getSupplierData;
        updateInspectionProductionUnit: typeof updateInspectionProductionUnit;
        setInspectionProductionUnitWillBeOverwritten: typeof setInspectionProductionUnitWillBeOverwritten;
    };
}
type State = {
    productionUnitData: ProductionUnitType;
    selectedItem: { name: string; value: string };
    dirty: boolean;
    show: boolean;
    valid: boolean;
    supplierOpen: boolean;
};

const initialState = {
    productionUnitData: {
        name: '',
        contact_person: '',
        address: [],
    },
    selectedItem: undefined,
    dirty: false,
    show: false,
    valid: false,
    supplierOpen: false,
};


type EditInspectionSupplierProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class EditInspectionSupplier extends Component<EditInspectionSupplierProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.closeOverwriteDialog = this.closeOverwriteDialog.bind(this);
    }

    render(): React.ReactElement {
        const formatMessage = this.props.intl.formatMessage;
        const orderId = this.props.order.order_id;
        const suppliers = this.getSupplierList(this.props.order.inspections);
        const canEditPU = this.props.order.inspections[0].features.includes('change_production_unit_data');
        const canEditSupplier = this.props.order.features.includes('order_manage_supplier');
        const multiSupplierWarning = <Popup
            trigger={<span><InfoIcon inline={false} compact={true} text={formatMessage({ id: 'supplier.multiple_suppliers' })} /></span>}
        >
            <Popup.Header>
                <FormattedMessage id="supplier.multiple_suppliers.popup.header" />
            </Popup.Header>
            <Popup.Content>
                {suppliers.map((supplier) => supplier.name)}
            </Popup.Content>
        </Popup>
        if (this.props.suppliers) {
            const overWriteMessage = <Modal
                open={this.props.productionUnitOverwrite}
                onClose={(): void => {
                    this.closeOverwriteDialog();
                }}
                closeIcon={true}
                size='small'
            >
                <Modal.Header><FormattedMessage id='production_unit.remove_production_unit' /></Modal.Header>
                <Modal.Content>
                    <InfoIcon compact text={formatMessage({ id: 'supplier.current_production_unit_will_be_replaced.proceed' })} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={(): void => {
                        this.props.actions.updateInspectionSupplier(orderId, this.state.selectedItem.value, false, true);
                    }}><FormattedMessage id='globals.ok' /></Button>
                    <Button onClick={(): void => this.closeOverwriteDialog()}><FormattedMessage id='globals.cancel' /></Button>
                </Modal.Actions>
            </Modal>;
            return (
                <Segment.Group raised className='editInspectionSupplier'>
                    {this.props.productionUnitOverwrite && overWriteMessage}
                    <Segment>
                        <Header as='h3'>
                            <FormattedMessage id='supplier.supplier_and_production_units' />
                        </Header>
                    </Segment>
                    <Segment padded>
                        <Form>
                            <Grid columns={16}>
                                <Grid.Row>
                                    <Grid.Column width={8}>{this.props.suppliersSelector && <Fragment><Form.Field inline width={16}>
                                        <label>{formatMessage({ id: 'suppliers.supplier_name' })}</label>
                                        <Dropdown
                                            fluid
                                            text={getSupplierNameAndNumber(this.props.supplierEntity && this.props.supplierEntity.name, this.props.supplierEntity && this.props.supplierEntity.number)}
                                            disabled={this.props.disabled || !canEditSupplier}
                                            value={this.props.supplierId || ''}
                                            selection
                                            placeholder={formatMessage({ id: 'suppliers.select_supplier' })}
                                            search={searchOptionTextAndDescription}
                                            options={this.props.suppliersSelector}
                                            onChange={this.handleChange}
                                            onFocus={(): void => this.loadSuppliers()}
                                            onOpen={(): void => this.setState({ supplierOpen: true })}
                                            name='supplier'
                                            loading={this.props.suppliersSelector.length === 1 && this.state.supplierOpen}
                                        />
                                        {suppliers.length > 1 && <label>{multiSupplierWarning}</label>}
                                        {canEditSupplier && <CreateSupplier><span className='link indent'><FormattedMessage id='suppliers.create_supplier' /></span></CreateSupplier>}
                                    </Form.Field></Fragment>}
                                        {this.props.supplierId && <Form.Field width={16}>
                                            <label><FormattedMessage id='production_units.unit_name' /></label>
                                            <HasFeatureAccess feature='change_production_unit_data' type='inspection' inspection={this.props.order.inspections[0]} showDisabled>
                                                <ProductionUnitsSelector
                                                    supplierId={this.props.supplierId || ''}
                                                    disabled={this.props.disabled}
                                                    handleChange={this.handleChange}
                                                    value={this.props.linkedProductionUnitId || ''}
                                                    showUnassigned
                                                    name='productionUnit'
                                                />
                                            </HasFeatureAccess>
                                            {canEditPU && <CreateProductionUnit supplierId={this.props.supplierId} isStandalone={true}><span className='link indent'><FormattedMessage id='production_unit.create_production_unit' /></span></CreateProductionUnit>}
                                        </Form.Field>}
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <ProductionUnitReadOnly
                                            productionUnit={this.props.linkedProductionUnit}
                                            supplierId={this.props.supplierId}
                                            canEdit={canEditPU}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Segment>
                </Segment.Group>);
        }
        return null;
    }

    loadSuppliers(): void {
        if (this.props.suppliersSelector.length < 2) {
            this.props.actions.getSuppliers();
        }
    }

    getSupplierList(inspections: Inspection[]): DetailedSupplier[] {
        const suppliers = inspections.map((ins) => ins.supplier_entity);
        const uniqSuppliersIds = {}
        return suppliers.filter((supplier) => {
            if (supplier && uniqSuppliersIds[supplier.id] == null) {
                uniqSuppliersIds[supplier.id] = supplier.id;
                return true
            }
            else {
                return false
            }
        });
    }

    handleChange(evt: SyntheticEvent, { name, value }: { name: string; value: string }): void {
        if (name === 'productionUnit') {
            this.props.actions.updateInspectionProductionUnit(this.props.order.order_id, value);
        }
        if (name === 'supplier') {
            this.setState({ selectedItem: { name, value } });
            this.props.actions.updateInspectionSupplier(this.props.order.order_id, value, false);
            if (value) {
                this.props.actions.getSupplierData(value);
            }
        }
    }

    closeOverwriteDialog(): void {
        this.props.actions.setInspectionProductionUnitWillBeOverwritten(false);
    }
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        currentSupplierId: getCurrentInspectionSupplierIdSelector(state),
        suppliers: getSuppliersSelector(state),
        suppliersSelector: getSuppliersSelectorSelector(state),
        productionUnits: getInspectionProductionUnitsSelector(state, ownProps.supplierId),
        currentSupplier: getCurrentSupplierSelector(state),
        productionUnitOverwrite: getInspectionProductionUnitToBeOverwrittenSelector(state),
        isFetching: isSupplierDataFetchingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch): DispatchProps => {
    const actions = bindActionCreators({
        getSuppliers,
        getSupplierData,
        getProductionUnits,
        updateInspectionSupplier,
        updateInspectionProductionUnit,
        setInspectionProductionUnitWillBeOverwritten,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(EditInspectionSupplier));
