import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Header, Icon, Label, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { WeekviewUser } from '../../../../backend_api/models';
import Tag from '../../../../base/components/basic/Tag';
import { getArrayInChunks } from '../../../../base/utils';
import users from '../../../users';
import { isInspectionPlanned } from '../../Utils';
import { Inspection, InspectionType, InspectionTypeViews, Order } from '../../types';
import { InspectionTypeStatus, NakedStatus } from '../edit/Status';
import { DashboardPopup } from './DashboardPopup';

export type StatusDashboardProps = {
    inspections: Inspection[];
    type?: InspectionTypeViews;
    showInfo?: boolean;
    className?: string;
};

export const InspectionTypeMarker = (props: StatusDashboardProps): React.ReactElement => {
    if (!props.type) {
        return (<span>{props.inspections.map((inspection: Inspection) => {
                if (inspection.inspection_type) {
                    return getLabel(inspection.inspection_type, inspection);
                }
                return null;
            })}</span>)
    }
    if (props.type === 'dashboard') {
        return (
            <div className='flex flex-wrap inspectionTypes dashboard'>
                {props.inspections.map((inspection: Inspection) => {
                    if (inspection.inspection_type) {
                        const trigger = getLabel(inspection.inspection_type, inspection);
                        return <span><DashboardPopup trigger={trigger} inspections={props.inspections} /></span>;
                    }
                    return null;
                })}
            </div>
        );
    }
    if (props.type === 'switcher') {
        if (props.inspections[0].inspection_type) {
            return <div className='inspectionTypes switcher'>{getTag(props.inspections[0])}</div>;
        }
        return null;
    }
    if (props.type === 'editInspection') {
        if (props.inspections[0].inspection_type) {
            return <div className={'inspectionTypes editInspection flex items-center'}><NakedStatus content={props.inspections[0].inspection_type && props.inspections[0].inspection_type.tag || '-'} inspection={props.inspections[0]} /></div>;
        }
        return null;
    }
    if (props.type === 'report') {
        if (props.inspections[0].inspection_type) {
            const getStatusClass = (): string => {
                const inspection = props.inspections[0];
                let status = inspection.conclusion.conclusion_category.toString();
                if (inspection.status === 'ongoing') {
                    status = 'inProgress';
                }
                return 'status_' + status;
            }
            return <Tag className={twMerge(getStatusClass(), props.className)}>{props.inspections[0].inspection_type.tag}</Tag>;
        }
        return null;
    }
    if (props.type === 'weekView') {
        return <InspectionTypeStatus showInfo={true} inspection={props.inspections[0]} isTag={true} isFluid={true} />;
    }
    if (props.type === 'compactWeekView') {
        const inspections = props.inspections;
        const inspObj = { planned: 0, ongoing: 0, report: 0, downloaded: 0 };
        const reportObj = { approved: 0, rejected: 0, ongoing: 0, pending: 0, not_set: 0 };
        const total = inspections.length;
        const statusArr = [];

        inspections.forEach((inspection: Inspection) => {
            if (inspection) {
                if (inspection.status === 'report') {
                    reportObj[inspection.conclusion.conclusion_category]++;
                    statusArr.push({ status: inspection.conclusion.conclusion_category, type: 'report' });
                }
                if (inspection.locked && inspection.status === 'planned') {
                    statusArr.push({ status: 'downloaded', type: 'report' });
                    inspObj.downloaded++;
                }
                if (!inspection.locked && inspection.status === 'planned') {
                    statusArr.push({ status: inspection.status, type: 'report' });
                    inspObj[inspection.status]++;
                }
                if (inspection.status === 'ongoing') {
                    statusArr.push({ status: inspection.status, type: 'report' });
                    inspObj[inspection.status]++;
                }
            }
        });
        const planned = inspObj.planned;
        const inProgress = inspObj.ongoing;
        const downloaded = inspObj.downloaded;
        const plannedPercent = (planned / total) * 100;
        const inProgressPercent = (inProgress / total) * 100;
        const downloadedPercent = (downloaded / total) * 100;
        const approvedPercent = (reportObj.approved / total) * 100;
        const rejectedPercent = (reportObj.rejected / total) * 100;
        const reportPendingPercent = (reportObj.pending / total) * 100;
        const reportInProgressPercent = (reportObj.ongoing / total) * 100;
        const reportNoSetPercent = (reportObj.not_set / total) * 100;
        const status = <div className='status'>
            {plannedPercent > 0 && <div className='statusItem rounded-sm bg-status-planned' style={{ width: `${plannedPercent}%` }}></div>}
            {downloadedPercent > 0 && <div className='statusItem rounded-sm bg-status-downloaded' style={{ width: `${downloadedPercent}%` }}></div>}
            {rejectedPercent > 0 && <div className='statusItem rounded-sm bg-status-rejected' style={{ width: `${rejectedPercent}%` }}></div>}
            {approvedPercent > 0 && <div className='statusItem rounded-sm bg-status-approved' style={{ width: `${approvedPercent}%` }}></div>}
            {inProgressPercent > 0 && <div className='statusItem rounded-sm bg-status-in-progress' style={{ width: `${inProgressPercent}%` }}></div>}
            {reportPendingPercent > 0 && <div className='statusItem rounded-sm bg-status-pending' style={{ width: `${reportPendingPercent}%` }}></div>}
            {reportInProgressPercent > 0 && <div className='statusItem rounded-sm bg-status-ongoing' style={{ width: `${reportInProgressPercent}%` }}></div>}
            {reportNoSetPercent > 0 && <div className='statusItem rounded-sm bg-status-pending' style={{ width: `${reportNoSetPercent}%` }}></div>}
        </div>;
        return status;
    }
};

type CompactWeekviewPopupProps = {
    trigger: React.ReactElement<any>;
    supplierObj: any;
    name: string | React.ReactElement;
    orderCnt: number;
    user: WeekviewUser;
    hasInspectionTypes: boolean;
};

export const CompactWeekviewPopup = (props: CompactWeekviewPopupProps): React.ReactElement => {
    const UserInitialsText = users.components.Widgets.UserLine;
    const intl = useIntl();
    const { trigger, name, orderCnt, user, supplierObj, hasInspectionTypes } = props;
    let inspections = [];
    let inspectionsCnt = 0;
    Object.entries(supplierObj).forEach((obj) => {
        const order: Order = obj[1];
        if (order.inspections) {
            if (order.inspections[0] && order.inspections[0].combined) {
                inspections = inspections.concat(order.inspections[0].sub_inspections);
                inspectionsCnt += order.inspections[0].sub_inspections.length;
            } else {
                inspections = inspections.concat(order.inspections);
                inspectionsCnt += order.inspections.length;
            }
        }
    });
    const inspectionsLabel = inspectionsCnt === 1 ? intl.formatMessage({ id: 'inspections.week_view.compact_inspection_label' }) : intl.formatMessage({ id: 'inspections.week_view.compact_inspections_label' });
    const ordersLabel = orderCnt === 1 ? intl.formatMessage({ id: 'inspections.week_view.compact_order_label' }) : intl.formatMessage({ id: 'inspections.week_view.compact_orders_label' });
    const items = [];
    Object.entries(supplierObj).forEach((obj) => {
        const order: Order = obj[1];
        const orderNumber = order.order_number || '-';
        if (order.inspections) {
            if (order.inspections[0] && !order.inspections[0].combined) {
                order.inspections.forEach((inspection: Inspection) => {
                    const isPlanned = isInspectionPlanned(inspection);
                    const linkPath = isPlanned ? '/inspections/edit_inspection/' : '/inspections/reports/';
                    const link = isPlanned ? linkPath + inspection.order_id : linkPath + inspection.inspection_id;
                    items.push(
                        <Link className='link noUnderline noColor' to={link}>
                            <div className='compact popupItem items-center'>
                                <div className='w-10 mr-2 py-1 h-fit items-center justify-center'>
                                    {!inspection.inspection_type && <NakedStatus content={hasInspectionTypes ? intl.formatMessage({ id: 'inspections.inspection_types.none' }) : ''} inspection={inspection} classNames='compact' />}
                                    {inspection.inspection_type && <NakedStatus content={inspection.inspection_type.tag} inspection={inspection} classNames='compact' />}
                                </div>
                                <div className='item name'>{orderNumber}. {`${inspection.item_number ? inspection.item_number + '.' : '-'}`} {inspection.item_name}</div>
                            </div>
                        </Link>);
                });
            } else {
                order.inspections[0] && order.inspections[0].sub_inspections.forEach((inspection: Inspection) => {
                    const isPlanned = isInspectionPlanned(inspection);
                    const linkPath = isPlanned ? '/inspections/edit_inspection/' : '/inspections/reports/';
                    const link = isPlanned ? linkPath + inspection.order_id : linkPath + order.inspections[0].inspection_id;
                    items.push(
                        <Link className='link noUnderline noColor' to={link}>
                            <div className='compact popupItem items-center'>
                                <div className='w-10 mr-2 py-1  items-center justify-center'>
                                    {!inspection.inspection_type && <NakedStatus content={hasInspectionTypes ? intl.formatMessage({ id: 'inspections.inspection_types.none' }) : ''} inspection={inspection} classNames='compact' />}
                                    {inspection.inspection_type && <NakedStatus content={inspection.inspection_type.tag} inspection={inspection} classNames='compact' />}
                                </div>
                                <div className='item name ci'>{orderNumber}. {`${inspection.item_number ? inspection.item_number + '.' : '-'}`} {inspection.item_name}</div>
                                <div className='item ci'><Label size='tiny'><FormattedMessage id='inspections.week_view.combined' /></Label></div>
                            </div>
                        </Link>);
                });
            }
        }
    });
    const chunks = getArrayInChunks(items, 12);
    return (
        <Popup
            trigger={trigger}
            className={((): string => {
                const cn = 'inspectionTypes compactWeekViewPopup';
                if (chunks.length === 2) {
                    return cn + ' twoCol';
                }
                if (chunks.length > 2) {
                    return cn + ' wide';
                }
                return cn;
            })()}
            hoverable
            // open={true}
            basic
            mouseEnterDelay={800}
        >
            <Popup.Content>
                <Header as='h4'>{name}</Header>
                <p><Icon name='user' color='green' />{user && <UserInitialsText user={user} /> || 'Not assigned'}</p>
                <p><FormattedMessage id='inspections.week_view.compact_popup.order_and_inspections_cnt' values={{ ordersLabel, inspectionsLabel, orderCnt, inspectionsCnt }} /></p>
                {chunks.length === 1 && <div className='col'>{chunks[0]}</div>}
                {chunks.length > 1 && <div className='cols'>
                    {chunks.map((chunk, index: number) => {
                        return <div key={index} className='col'>{chunk}</div>;
                    })}
                </div>}

            </Popup.Content>
        </Popup >
    );
};


export const getInspectionLink = (isReport: boolean, reportLink, orderId, inspection, linkPath): string => {
    if (isReport) {
        return [linkPath, reportLink].join('');
    }
    if (inspection.master_inspection) {
        return [linkPath, inspection.master_inspection.order_id].join('');
    }
    return [linkPath, orderId].join('');
};

const getLabel = (type: InspectionType, inspection: Inspection): React.ReactElement => {
    return <span><InspectionTypeStatus inspection={inspection} isTag={true} /></span>;
};

const getTag = (inspection: Inspection): React.ReactElement => {
    return (<Popup className='inspectionTypes switcher' trigger={<span><InspectionTypeStatus inspection={inspection} isTag={false} /></span>}>
        <Popup.Content>
            <div>Inspection type</div><span className='typeName'>{inspection.inspection_type.name}</span>
        </Popup.Content></Popup>);
};
