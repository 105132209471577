import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';

interface DeleteRequirementProps {
    showDeleteModal: boolean;
    handleDelete();
}

const ComplianceDeleteRequirement = (props: DeleteRequirementProps): React.ReactElement => {
    const [visible, setVisible] = useState(false);
    const handleDelete = () => {
        setVisible(false);
        props.handleDelete();
    }
    return (
        <Modal
            open={visible}
            trigger={<Button negative onClick={(): void => setVisible(true)} size='tiny'><FormattedMessage id="compliance.requirement.delete" /></Button>}
        >
            <Modal.Header><FormattedMessage id="compliance.requirement.delete.title" /></Modal.Header>
            <Modal.Content>
                <Modal.Description><FormattedMessage id="compliance.requirement.delete.do_you_want_do_delete_this_requirement" /></Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={(): void => handleDelete()}><FormattedMessage id="inspections.yes_delete_it" /></Button>
                <Button onClick={(): void => setVisible(false)}><FormattedMessage id="globals.cancel" /></Button>
            </Modal.Actions>

        </Modal>
    );
};

export default ComplianceDeleteRequirement;
