import React, { Fragment } from 'react';
import { showLightBox } from '../../../../../base/baseSlice';
import Link from '../../../../../base/components/basic/Link';
import { useAppDispatch } from '../../../../../store';
import { CheckpointPhoto } from '../../../../checklists/types';
import Video from '../../../../video/components/Video';


type Props = {
    image: CheckpointPhoto;
    imageComment: React.ReactElement;
    videoURL?: string;
}

const CheckpointVideo = (props: Props): React.ReactElement => {
    const className = props.imageComment ? 'withComment' : '';
    const dispatch = useAppDispatch();
    return (
        <Fragment>

            <div className='item' onClick={() => {
                dispatch(showLightBox({ show: true, url: props.image.video_resources[0].url }))
            }}>
                <Link className='box-border' to={'?imageURL=' + props.videoURL}>
                    <div className={className}>
                        <div className='image-container'>
                            <Video
                                thumbnail={props.image.url}
                                videoResources={props.image.video_resources}
                                usePlayIcon={true}
                                preload={true}
                                className='h-auto'
                            />
                            {props.imageComment && <div className='videoComment'>
                                {props.imageComment}
                            </div>}
                        </div>
                    </div>
                </Link>
            </div>
        </Fragment>
    )
}

export default CheckpointVideo;