/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a string that represents a date.
 *
 */
export type AuditCustomFieldDate = {
    date: string;
    type: AuditCustomFieldDate.type;
};

export namespace AuditCustomFieldDate {

    export enum type {
        DATE = 'date',
    }


}

