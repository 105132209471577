import React, { MutableRefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import Icon from '../../../../../base/components/basic/Icon';
import { byId } from '../../../../../base/types';
import { objectIsEmpty, twMerge } from '../../../../../base/utils';
import WriteComment from '../../../../comments/components/WriteComment';
import { Comment, CommentTypes } from '../../../../comments/types';
import CorrectiveActionsFromCheckpointsWizard, { CorrectiveActionsFromDefectOrCheckpointsType } from '../../../../correctiveActions/components/CorrectiveActionsFromCheckpointsWizard';
import { HasFeature } from '../../../../profile/containers';


type Props = {
    className?: string;
    inspectionId: string;
    itemId: string; // Defect or checkpoint id
    createOnType: CorrectiveActionsFromDefectOrCheckpointsType;
    itemType: 'defect' | 'checkpoint';
    commentRef: MutableRefObject<any>;
    comments: byId<Comment[]>;
    type: CommentTypes;
    canCreateCorrectiveAction: boolean;
};

const DefectsAndCheckpointsContextMenu = (props: Props): React.ReactElement => {
    const { className, inspectionId, itemId, itemType, commentRef, comments, type, canCreateCorrectiveAction = true } = props;
    const hasComments = !objectIsEmpty(comments);
    const commentsCnt = Object.values(comments).flat().length;
    const commentCl = 'flex p-1 text-base bg-surface-interactive space-x-1 cursor-pointer rounded-l'
    return (
        <div className='flex space-x-1 items-center rounded-sm text-sm'><div>
            {hasComments && <Popup trigger={<div className={commentCl} onClick={() => {
                hasComments && commentRef && commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }}><div>{commentsCnt}</div><Icon name='chat_bubble_outline' /></div>}><FormattedMessage id='comments.comments_count' values={{ cnt: commentsCnt }} /></Popup>}
            {!hasComments && <Popup trigger={<span><WriteComment
                showModal={true}
                id={itemId}
                type={type}
                trigger={<Icon name='chat_bubble_outline' className={commentCl} />}
            /></span>}><FormattedMessage id='comments.no_comments.click_to_write_one' />
            </Popup>}
        </div>
            {canCreateCorrectiveAction && <HasFeature feature='u_corrective_actions_inspection' >
                <div className='bg-surface-interactive p-1 rounded-r'>
                    <CorrectiveActionsFromCheckpointsWizard className={twMerge('text-base', className)} id={inspectionId} selectedIds={[itemId]} createOnType={CorrectiveActionsFromDefectOrCheckpointsType.Inspection} itemType={itemType} />
                </div>
            </HasFeature>}
        </div>
    )
}
export default DefectsAndCheckpointsContextMenu;
