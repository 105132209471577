import { createSelector } from 'reselect';
import { AppState } from '../../base/types';
import { DashboardState } from './types';

type State = { app: { dashboard: DashboardState } };

const inspectionsStatusByTypeData = (state: State): boolean => state.app.dashboard.inspectionsStatusByType;
export const getInspectionsStatusByTypeSelector: any = createSelector(
    [inspectionsStatusByTypeData],
    (data) => data,
);
const inspectionsStatusByConclusionData = (state: State): boolean => state.app.dashboard.inspectionsStatusByConclusion;
export const inspectionsStatusByConclusionSelector: any = createSelector(
    [inspectionsStatusByConclusionData],
    (data) => data,
);
const inspectionsStatusBySuplierQCData = (state: State): boolean => state.app.dashboard.inspectionsStatusBySupplierQC;
export const inspectionsStatusBySupplierQCSelector: any = createSelector(
    [inspectionsStatusBySuplierQCData],
    (data) => data,
);

const defectsStatusBySeverityData = (state: State): boolean => state.app.dashboard.defectsStatusBySeverity;
export const getDefectsStatusBySeveritySelector: any = createSelector(
    [defectsStatusBySeverityData],
    (data) => data,
);

const inspectionStatusPieData = (state): any => {
    return state.app.dashboard.inspectionStatus || [];
};

export const getInspectionStatusPieSelector: any = createSelector(
    [inspectionStatusPieData],
    (data) => data,
);

const latestInspectionsData = (state): boolean => state.app.dashboard && state.app.dashboard.latestInspections || [];
export const getLatestInspectionsSelector: any = createSelector(
    [latestInspectionsData],
    (data) => data,
);

const isLoading = (state): boolean => state.app.dashboard && state.app.dashboard.isLoading || state.app.dashboard && state.app.dashboard2.isLoading || false;
export const isDashboardLoadingSelector = createSelector(
    [isLoading],
    (loading) => loading,
);
const isFetching = (state, type: string): boolean => state.app.dashboard && state.app.dashboard.isFetching[type] || false;
export const isDashboardFetchingSelector = createSelector(
    [isFetching],
    (fetching) => fetching,
);

export const dashboardDefectsImagesSelector = createSelector(
    [(state: AppState) => state.app.dashboard2.defectTypeOverview],
    (overview) => overview,
);
