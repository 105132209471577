import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { Sizes } from '../../../base/components/types';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { AppState } from '../../../base/types';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Icon from '../../../base/ui/components/icons/Icon';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';
import Label from '../../../base/ui/components/labels/Label';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNull } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import ControlledSupplierSelectorSimple from '../../suppliers/components/ControlledSupplierSelectorSimple';
import { complianceAddDueDate, complianceRemoveDueDate, complianceSetProductState, complianceUpdateDueDate } from '../complianceSlice';
import { getComplianceDeadlineByIdSelector } from '../selectors/ComplianceSelectors';

export type DueDateType = 'create' | 'edit' | 'noOrdersYet';
export enum DueDateTypeEnum {
    Create = 'create',
    Edit = 'edit',
    NoOrdersYet = 'noOrdersYet',
}
type Props = {
    className?: string;
    type?: DueDateTypeEnum.Edit
    deadlineId?: string;
    productId?: string;
};

const ComplianceProductEditDueDate = (props: Props): React.ReactElement => {
    const { className, type = DueDateTypeEnum.Create, productId } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const modalData = useSelector(getShowModalSelector)?.['editDueDateModal'];
    const _deadline = useSelector((state: AppState) => getComplianceDeadlineByIdSelector(state, props.deadlineId))
    const [deadline, setDeadline] = useState(_deadline);
    const [errorMessage, setErrorMessage] = useState({ date: null, supplier: null });
    const [date, setDate] = useState(deadline?.date);
    const [supplierId, setSupplierId] = useState(deadline?.supplier.supplier_id);
    const [dueDateName, setDueDateName] = useState(deadline?.name);
    const [showDelete, setShowDelete] = useState(false);
    const [view, setView] = useState<DueDateType>(type || DueDateTypeEnum.Edit);
    const [open, setIsOpen] = useState(modalData?.show || false);

    useEffect(() => {
        if (type) {
            setView(type);
        }
    }, [type])

    const save = () => {
        if (view === DueDateTypeEnum.Edit) {
            if (checkValidation()) {
                dispatch(complianceUpdateDueDate({ productId, date, name: dueDateName, supplier_id: supplierId, supplier_type: 'supplier', deadline_id: deadline.id }))
                clearData();
                hide();
            }
        }
        if (view === DueDateTypeEnum.Create) {
            if (checkValidation()) {
                dispatch(complianceAddDueDate({ date, name: intl.formatMessage({ id: 'compliance.product.due_date.initial_due_date' }), supplier_id: supplierId, supplier_type: 'supplier', product_id: productId }));
                dispatch(complianceSetProductState({ product_id: productId, state: 'active' }))
                clearData();
                hide();
            }
        }
        if (view === DueDateTypeEnum.NoOrdersYet) {
            if (checkValidation()) {
                dispatch(complianceAddDueDate({ date, name: intl.formatMessage({ id: 'compliance.product.due_date.initial_due_date' }), supplier_id: supplierId, supplier_type: 'supplier', product_id: productId }));
                dispatch(complianceSetProductState({ product_id: productId, state: 'active' }))
                clearData();
                hide();
            }
        }
    };

    const dontAdd = () => {
        dispatch(complianceSetProductState({ product_id: productId, state: 'active' }))
        clearData();
        hide();
    }
    const hide = () => {
        setIsOpen(false);
        dispatch(showModal({ id: 'editDueDateModal', show: false }));
    };
    const clearData = () => {
        setDate(null);
        setSupplierId(null);
        setErrorMessage({ date: null, supplier: null })
    };
    const cancel = () => {
        hide();
    }
    const deleteAction = () => {
        setShowDelete(true);
    }
    const headerMap = {
        noOrdersYet: intl.formatMessage({ id: 'compliance.product.activate.header.no_orders_yet' }),
        create: intl.formatMessage({ id: 'compliance.product.activate.header.due_date_for_documents' }),
        edit: intl.formatMessage({ id: 'compliance.product.activate.header.due_date_for_documents' }),
    }
    const actionsMap = {
        create: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.add_a_due_date' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        },
        edit: {
            ok: intl.formatMessage({ id: 'globals.done' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        },
        noOrdersYet: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.add_a_due_date' }),
            cancel: intl.formatMessage({ id: 'globals.cancel' }),
        }
    }
    const getContent = (type) => {
        return <div className='flex flex-col gap-6'>
            {(type === DueDateTypeEnum.NoOrdersYet) && <div>
                <FormattedMessage id='compliance.product.activate.no_orders_on_product_yet' />
            </div>}
            <div>
                <Label text={intl.formatMessage({ id: 'compliance.product.activate.due_date_label' })} />
                <DatePicker3
                    date={date}
                    handleChange={(d) => {
                        setDate(isUndefinedOrNull(d) ? null : format(d, DEFAULT_DATE_FORMAT2));
                    }} />
                <InvalidFieldMessage className='pt-2' message={errorMessage.date} />
            </div>
            <div className=''>
                <Label text={intl.formatMessage({ id: 'compliance.product.activate.supplier_label' })} />
                <ControlledSupplierSelectorSimple
                    supplierId={supplierId}
                    onSupplierSelected={(v) => setSupplierId(v.value)} placeholder={intl.formatMessage({ id: 'compliance.product.activate.select_supplier' })} />
                <InvalidFieldMessage className='pt-2' message={errorMessage.supplier} />
            </div>
            {type === DueDateTypeEnum.Edit && <div className=''>
                <Field
                    label={{ text: intl.formatMessage({ id: 'compliance.product.activate.due_date_name_label' }) }}
                    type='text' defaultValue={dueDateName} onChange={setDueDateName} />
                <InvalidFieldMessage className='pt-2' message={errorMessage.supplier} />
            </div>}
            {showDelete && <DeleteConfirm type='alert'
                alertHeader={intl.formatMessage({ id: 'compliance.product.due_date.delete_header_text' })}
                alertText={intl.formatMessage({ id: 'compliance.product.due_date.delete_text' }, { name: <span className='font-bold'>{deadline.name}</span> })}
                alertButtonText={intl.formatMessage({ id: 'compliance.product.due_date.delete_button_text' })}
                show={showDelete}
                onClose={() => setShowDelete(false)}
                deleteFunction={() => {
                    dispatch(complianceRemoveDueDate({ deadline_id: deadline.id, product_id: productId }));
                    setShowDelete(false);
                    hide();
                }} />}
        </div>
    }
    const contentMap = {
        create: getContent('create'),
        edit: getContent('edit'),
        noOrdersYet: getContent('noOrdersYet'),
    }
    const checkValidation = (type?: 'date' | 'supplier') => {
        const t = !type ? 'all' : type;
        const dateValid = !isUndefinedOrNull(date);
        const supplierValid = !isUndefinedOrNull(supplierId);
        if (t === 'all') {
            setErrorMessage({
                ...errorMessage,
                date: dateValid ? null : intl.formatMessage({ id: 'compliance.product.activate.error.please_set_date' }),
                supplier: supplierValid ? null : intl.formatMessage({ id: 'compliance.product.activate.error.please_set_supplier' }),
            })
        }
        if (t === 'date') {
            setErrorMessage({
                ...errorMessage,
                date: dateValid ? null : intl.formatMessage({ id: 'compliance.product.activate.error.please_set_date' }),
            })
        }
        if (t === 'supplier') {
            setErrorMessage({
                ...errorMessage,
                supplier: supplierValid ? null : intl.formatMessage({ id: 'compliance.product.activate.error.please_set_supplier' }),
            })
        }
        return supplierValid && dateValid;
    }

    useEffect(() => {
        if (modalData?.data) {
            setView(modalData?.data?.view);
        }
    }, [modalData]);
    useEffect(() => {
        if (modalData?.show !== open) {
            setIsOpen(modalData?.show);
        }
    }, [modalData?.show]);

    useEffect(() => {
        !isUndefinedOrNull(date) && checkValidation('date');
    }, [date]);
    useEffect(() => {
        if (_deadline) {
            // setDeadline(_deadline);
            setDate(_deadline?.date);
            setSupplierId(_deadline?.supplier.supplier_id);
            setDueDateName(_deadline?.name);
        }
    }, [_deadline]);
    useEffect(() => {
        !isUndefinedOrNull(supplierId) && checkValidation('supplier');
    }, [supplierId]);

    const createModal = <Modal
        trigger={view === DueDateTypeEnum.Edit && <div className='items-center text-sm link noUnderline flex gap-x-1'><Icon className='text-brand' size={Sizes.Small} name='operation_edit' /><FormattedMessage id='compliance.product.due_date.label.edit_due_date' /></div>}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: actionsMap[view]?.ok, event: save, stayOpen: true },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: actionsMap[view]?.cancel, event: cancel },
            view === DueDateTypeEnum.Edit && { type: ActionTypes.AltAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'compliance.product.due_date.label.delete_due_date' }), event: deleteAction, icon: 'operation_delete', stayOpen: true },
            view === DueDateTypeEnum.NoOrdersYet && { type: ActionTypes.AltAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'compliance.product.activate.dont_add_a_due_date' }), event: dontAdd, icon: 'operation_delete', stayOpen: true },
        ]}
        onOpenChange={(open) => setIsOpen(open)}
        isOpen={open}
        width={500}
        header={headerMap[view]}
        content={contentMap[view]}
    >
    </Modal >

    return createModal;
}
export default ComplianceProductEditDueDate;
