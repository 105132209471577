import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Form, Icon, Label, List } from 'semantic-ui-react';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { CheckpointData } from '../types';
import TranslationText from './TranslationText';

export type CheckpointProps = {
    editing: boolean;
    checkpoint: CheckpointData;
    headerCnt: number;
    language: string;
    handleBlur(value: MultiLanguageText);
    handleFocus(isFocused: boolean);
    hendleCheck(isChecked: boolean);
    dragable(enableDrag: boolean);
    isChecked: boolean;
    reff;
}

const Checkpoint = (props: CheckpointProps): React.ReactElement => {
    const intl = useIntl()
    const formatMessage = intl.formatMessage;
    const [edit, setEdit] = useState<boolean>(props.editing);
    const [displaynameType, setDisplaynameType] = useState<string>('')

    const handelEdit = (): void => {
        props.handleFocus(true);
        props.dragable(false);
        setEdit(true);
    }

    useEffect(() => {
        setDisplaynameType(formatMessage({ id: 'checkpoint.type.' + props.checkpoint.checkpoint_type }));
    }, [props.checkpoint.checkpoint_type])

    useEffect(() => {
        setEdit(props.editing)
    }, [props.editing])

    const spacer_style = props.checkpoint.has_spacer ? { margin: '0 0 2em 0' } : { margin: '0px' };
    return (
        <div ref={props.reff}>
            <List.Item key={props.checkpoint.id} >
                <Form.Group className={'checkpoint'} style={{ ...spacer_style, minHeight: '54px' }}>
                    <Form.Field className='delete u-flex-align-center' width={1} />
                    <Form.Field className='write u-flex-align-center' style={{ cursor: 'grab' }}>
                        <Icon name="ellipsis vertical" style={{ height: 'unset', color: '#888' }} />
                    </Form.Field>
                    <Form.Field className='delete u-flex-align-center' >
                        <Checkbox
                            checked={props.isChecked || false}
                            onChange={(evt, data): void => props.hendleCheck(data.checked)}
                        />
                    </Form.Field>
                    {edit &&
                        <Form.Field width={16}>
                            <MultiLangualText
                                onMouseEnter={(): void => props.dragable(false)}
                                onMouseLeave={(): void => props.dragable(true)}
                                originalText={props.checkpoint.description}
                                save={props.handleBlur}
                                language={props.language}
                                presentation={'area'}
                            />
                        </Form.Field>
                    }
                    {!edit &&
                        <Fragment >
                            <Form.Field width={9} style={{ display: 'flex', alignItems: 'center' }} onClick={handelEdit}>
                                <TranslationText
                                    text={props.checkpoint.description}
                                    language={props.language}
                                />
                            </Form.Field>
                            <Form.Field className='u-flex-align-center' width={5} onClick={handelEdit}>
                                <Label style={{ width: '125px', textAlign: 'center' }}>{displaynameType}</Label>
                            </Form.Field>
                            <Form.Field className='u-flex-align-center' width={1} onClick={handelEdit} style={{ cursor: 'pointer' }}>
                                <Icon name='pencil' />
                            </Form.Field>
                            <Form.Field className='delete u-flex-align-center' width={1} onClick={handelEdit} />
                        </Fragment>
                    }
                </Form.Group>
            </List.Item>
        </div>
    );
}

export default Checkpoint;