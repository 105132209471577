/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 58.0.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes who has been notified on corrective action being overdue.
 * @export
 * @interface NotifiedOnOverdueEvent
 */
export interface NotifiedOnOverdueEvent {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotifiedOnOverdueEvent
     */
    notified_emails: Array<string>;
}

export function NotifiedOnOverdueEventFromJSON(json: any): NotifiedOnOverdueEvent {
    return NotifiedOnOverdueEventFromJSONTyped(json, false);
}

export function NotifiedOnOverdueEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifiedOnOverdueEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notified_emails': json['notified_emails'],
    };
}

export function NotifiedOnOverdueEventToJSON(value?: NotifiedOnOverdueEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notified_emails': value.notified_emails,
    };
}


