import React, { useState } from 'react';
import { useHover, usePress } from 'react-aria';
import { Popup as SUIPopup } from 'semantic-ui-react';
import { ComponentOrStringType } from '../../../types';

type Props = {
    trigger?: ComponentOrStringType;
    on?: 'click' | 'hover';
    children?: React.ReactNode;
}
const Popup = (props: Props) => {
    const { trigger, on = 'click', children, ...rest } = props;
    const [isOpen, setIsOpen] = useState(false);

    let { pressProps, isPressed } = usePress({
        onPress: (e) => {
            on === 'click' && setIsOpen(true);
            // e.continuePropagation();
            // props.onPress && props.onPress(e);
        }
    });
    let { hoverProps } = useHover({
        onHoverChange(isHovering) {
            console.log('isHovering ', isHovering)
            if (isOpen !== isHovering) {
                setIsOpen(isHovering);
                console.log('set is open ', isHovering)
            }
        },

    });
    const p = <SUIPopup
        trigger={<span>{trigger}</span>}
        on={on}
    >
        {children}
    </SUIPopup>
    return p;
}

export default Popup;
