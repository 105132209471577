import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import UsersSelector from '../../users/components/UsersSelector';
import { getUsersById } from '../../users/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type InspectorFilterProps = {
    filterName?: string;
    disabled?: boolean;
    search?: boolean;
    showExpanded?: boolean;
};
const InspectorFilter = (props: InspectorFilterProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { disabled = false, search = true, showExpanded = true } = props;
    const location = useLocation();
    const filterName = props.filterName || 'assigned_user';
    const selectedInspectors = getLocationEntry(location, filterName);
    const users = useSelector(getUsersById);
    const [isActive, setIsActive] = useState(false);
    const finish = (inspector): void => {
        setIsActive(false);
        dispatch(setFilter(filterName, inspector));
    };
    return (
        <Filter labelId='filters.inspectors.label_inspector'>
            <UsersSelector
                users={users}
                placeholder={intl.formatMessage({ id: 'filters.inspectors.select_inspector' })}
                allowMultipleSelection={true}
                value={selectedInspectors || []}
                showExpanded={showExpanded}
                onDeactive={(selectedInspectors): void => finish(selectedInspectors)}
                clear={(): void => finish([])}
                onActive={(): void => setIsActive(true)}
                name='inspector'
                withIcon
                showEmailInSelector
                disabled={disabled}
                search={search}
            />
            {/*This button is on visual, if it is clicked then onBlur will be called on the UserSelector and this will promt the appropate action*/}
            {isActive && <Button className='apply' size='mini' positive ><FormattedMessage id='filters.dropdown.label.ok' /></Button>}
        </Filter>
    );
};

export default InspectorFilter;
