import qs from 'query-string';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { getContextDataSelector, getCurrentContextSelector, getShowFiltersSelector, showFilters } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import SpinnerIcon from '../../../base/components/basic/SpinnerIcon';
import { translateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { Breakpoints } from '../../../base/config';
import { Context } from '../../../base/types';
import { getLocationEntries, getStringsSeparatedBy, objectIsEmpty, preventDefaultAndStopPropagation, useMaxWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getProductionUnitCustomFieldsListGroupEntriesByIdSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { getSupplierCustomFieldsListGroupEntriesByIdSelector } from '../../suppliers/slice/suppliersSlice';
import { clearAllFilters } from '../filtersActions';


const FILTERS_MINUS_DATES: string[] = 'scf_ids,cff_ids,production_unit_status,production_unit_id,compliance_req_cat,final_conclusion_ids,audit_type,ca_states,responsible_ids,groups,assigned_user,conclusion,custom_conclusion_ids,approver,supplier_id,supplier_ids,inspection_type,custom_field_values'.split(',') || [];

type SelectedFiltersProps = {
    showCompact?: boolean;
    hideDate?: boolean;
    context?: Context;
    isFetching?: boolean;
};

const SelectedFilters = (props: SelectedFiltersProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const isMobile = useMaxWidthBreak(Breakpoints.SM);
    const PRODUCTION_UNIT_CUSTOM_FIELD_FILTER = 'scf_ids';
    const SUPPLIER_CUSTOM_FIELD_FILTER = 'scf_ids';
    const locationParsed = qs.parse(location.search);
    const query = locationParsed.query || locationParsed.search;
    const currentContext = useSelector(getCurrentContextSelector);
    const contextData = useSelector(getContextDataSelector);
    const contextType = currentContext.context;
    const currentFilters = getLocationEntries(location);
    const filtersVisible = useSelector(getShowFiltersSelector);
    const filterTypes = Object.keys(currentFilters).filter((key) => {
        return FILTERS_MINUS_DATES.includes(key);
    });
    const hasFilters = filterTypes.length > 0;
    const hasContext = contextData[contextType];
    const found = (contextData && contextData[contextType] && contextData[contextType].metaData && contextData[contextType].metaData.list.total) || 0;
    const isFetching = props.isFetching;
    const [hitBottom, setHitBottom] = useState(false);
    const parentHeight = document.getElementsByClassName('filtersList').length > 0 && document.getElementsByClassName('filtersList')[0].clientHeight;

    const CONTEXT_TYPE = hasContext && intl.formatMessage({ id: found === 1 ? 'filters.context.' + contextType + '.found.one' : 'filters.context.' + contextType + '.found.many' })
    // TODO (Michael should fix): ⬇️ this is only relevant for PU or suppliers custom field filters and should probably be refactored or moved
    const customFieldsListEntriesById = useSelector(contextType === Context.Suppliers ? getSupplierCustomFieldsListGroupEntriesByIdSelector : getProductionUnitCustomFieldsListGroupEntriesByIdSelector);

    const hideFilters = (): void => {
        dispatch(showFilters(false));
    };
    const toggleFilters = () => {
        dispatch(showFilters(!filtersVisible));
    }
    const clear = (e) => {
        preventDefaultAndStopPropagation(e);
        dispatch(clearAllFilters(true, contextType === Context.Inspections && { status: 'planned' }))
        history.push('/' + contextType);
    }
    const FILTERS_TO_SHOW = isMobile ? 1 : 3;
    const getFilterTypeStrings = () => {
        const ret = [];
        filterTypes.map((filter) => {
            if (filter === PRODUCTION_UNIT_CUSTOM_FIELD_FILTER || filter === SUPPLIER_CUSTOM_FIELD_FILTER) {
                if (!objectIsEmpty(customFieldsListEntriesById)) {
                    const items = JSON.parse(currentFilters[filter] as unknown as string);
                    Object.keys(items).map((itemId) => {
                        ret.push(translateTextWithTranslation(customFieldsListEntriesById[itemId].name, intl.locale));
                    })
                }
            } else {
                ret.push(intl.formatMessage({ id: 'filters.selected_filter.' + filter }));
            }
        });
        return ret;
    }

    const getLabel = () => {
        if (filterTypes.length > 0) {
            const filterTypeStrings = getFilterTypeStrings();
            if (filterTypeStrings.length <= FILTERS_TO_SHOW) {
                const filterStr = getStringsSeparatedBy(filterTypeStrings, ',', ' and');
                return intl.formatMessage({ id: filterTypes.length === 1 ? 'custom_fields.filters.filter_set' : 'custom_fields.filters.filters_set' }, { filterStr });
            } else {
                const remaingFilters = filterTypeStrings.length - FILTERS_TO_SHOW;
                const strings = filterTypeStrings.slice(0, FILTERS_TO_SHOW);
                const ret = <span>{getStringsSeparatedBy(strings, ',') + ' ' + (remaingFilters === 1 ? intl.formatMessage({ id: 'custom_fields.filters.selected_labels.and_one_other' }) : intl.formatMessage({ id: 'custom_fields.filters.selected_labels.and_n_others' }, { n: remaingFilters }))}</span>;
                const popupText = filterTypeStrings.map((fts, i) => {
                    return <div className='py-1' key={'fts_' + i}>{fts}</div>
                })
                return remaingFilters === 1 ? ret : <Popup position='top center' trigger={ret}><Popup.Header><FormattedMessage id='filters.selected_filters.selected' /> ({filterTypeStrings.length})</Popup.Header>
                    <Popup.Content>{popupText}</Popup.Content></Popup>
            }
        }
    }

    const hide = filtersVisible && <div onClick={() => hideFilters()} className={twMerge('text-sm justify-self-end', isMobile ? '' : 'link')}>{isMobile ? <Button small><FormattedMessage id={found > 0 ? 'filters.show_results' : 'filters.no_results_hide'} /></Button> : <FormattedMessage id={'filters.selected_filters.hide'} />}</div>;
    let content = null;
    const labelId = 'filters.label.found_n_of_context_type';
    content = (hasFilters || query) ? (<span className='flex items-center space-x-1'>
        {isFetching && <div className='flex items-center space-x-2'><SpinnerIcon className='text-brand' /><div><FormattedMessage id='filters.selected_filters.loading_please_wait' /></div></div>}
        {!isFetching && <>{found === 0 ? hasContext && <span className='font-bold'><FormattedMessage id='filters.label.found_none_of_context_type' values={{ context_type: CONTEXT_TYPE }} /></span>
            :
            <span className={twMerge('font-bold', (hitBottom && !isMobile) && 'link')} onClick={() => {
                if (isMobile) {
                    toggleFilters();
                }
                hitBottom ? scrollTo({ top: parentHeight, left: 0, behavior: 'smooth' }) : null;
            }}><FormattedMessage id={labelId} values={{ n: found, context_type: CONTEXT_TYPE }} /></span>}
            {query && <span className='ml-1'><FormattedMessage id='filters.label.by_search' values={{ query: '"' + query + '"' }} />.</span>} <span className='hidden sm:flex'>{getLabel()}</span>
            {(filterTypes.length > 0 || query) && <div className={twMerge(!isMobile ? 'link ' : 'underline', 'text-sm')} onClick={(e) => clear(e)}><FormattedMessage id='filters.selected_filters.clear' /></div>}
        </>}
    </span>) : <div className='space-x-1'>{hasContext && <span className='font-bold'><FormattedMessage id={found === 0 ? 'filters.label.none_of_context_type' : 'filters.label.n_of_context_type_in_total'} values={{ n: found, context_type: CONTEXT_TYPE }} />.</span>} <FormattedMessage id='filters.label.no_filers_set' /></div>
    return <Sticky
        positionRecheckInterval={5}
        mode='bottom'
        stickStyle={'absolute bottom-0'}
        onFixedToggle={(hitBottom) => setHitBottom(hitBottom)}>
        <div
            onClick={() => !filtersVisible && toggleFilters()}
            className={twMerge(' bg-white items-center flex space-x-2 w-full p-3 md:p-3 text-base md:text-base self-end md:self-center rounded-bl md:rounded-none',
                filtersVisible ? 'border-t' : 'border',
                !filtersVisible && !isMobile && 'cursor-pointer',
                isMobile ? 'justify-between' : 'justify-between', (isMobile && filtersVisible) && ' bg-branding-brand')}>
            <div>{content}</div>
            <div className=''>{hide}</div>
        </div>
    </Sticky>;
};

export default SelectedFilters;
