import * as HoverCardRadix from '@radix-ui/react-hover-card';
import React from 'react';

import { ChildrenOrStringType } from '../../types';
import { twMerge } from '../../utils';

type Props = {
    className?: string;
    trigger: string | React.ReactElement;
    delay?: number;
    showArrow?: boolean;
    children?: ChildrenOrStringType;
};

const HoverCard = (props: Props): React.ReactElement => {
    const { className, trigger, delay, showArrow = true, children } = props;

    return <div className={twMerge('', className)}>

        <HoverCardRadix.Root openDelay={delay}>
            <HoverCardRadix.Trigger>{trigger}</HoverCardRadix.Trigger>
            <HoverCardRadix.Portal>
                <HoverCardRadix.Content className='bg-white p-d2 rounded shadow-2xl border'>
                    {children}
                    {showArrow && <HoverCardRadix.Arrow className='fill-white' />}
                </HoverCardRadix.Content>
            </HoverCardRadix.Portal>
        </HoverCardRadix.Root>
    </div>
}
export default HoverCard;
