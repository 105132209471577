import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

const initialState = { foo: 1 };
type State = Readonly<typeof initialState>;
type SupplierProps = WrappedComponentProps & RouteComponentProps<any>;

class Supplier extends Component<SupplierProps, State> {

    readonly state: State = initialState;
    render(): React.ReactElement {
        return <div className=''>
            SUPPLIER LIST
        </div>
    }
}

export default injectIntl(withRouter(Supplier));
