import React from 'react';

type Props = {
    firstColumnContent: React.ReactElement;
    secondColumnContent: React.ReactElement;
}

const TwoColPageLayout = (props: Props) => {
    return <div>
        <div className='flex space-between'> {/*colsMain*/}
            <div className='mr-4 w-col1-width min-w-col1-width'> {/*col1*/}
                {props.firstColumnContent}
            </div>
            <div className='w-col2-width min-w-col2-width'>{/*col2*/}
                {props.secondColumnContent}
            </div>
        </div>
    </div>
}

export default TwoColPageLayout;