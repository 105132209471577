import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { FileResource } from '../../../backend_api/models/FileResource';
import { calculatePrettySize } from '../../../modules/attachments/components/handlers';
import Icon from '../basic/Icon';

type Props = {
    attachments: FileResource[];
    remove(index: number): void;
    className?: string;
};

const CommentAttachments = (props: Props): React.ReactElement => {
    const { className, remove } = props;
    const [attachments, setAttachments] = useState(props.attachments);
    useEffect(() => {
        setAttachments(props.attachments);
    }, [props.attachments]);
    return <div className={twMerge('', className)}>
        {attachments.length > 0 && <div className='text-sm text-secondary mt-2 items-center flex space-x-1'><Icon name='check_circle' className='text-lg justify-center flex'/><div><FormattedMessage id='comments.files_ready_to_attach' values={{ cnt: attachments.length }} /></div></div>}
        {attachments.length > 0 && <div className='space-y-0 border rounded mt-2'>{attachments.map((upload, i) => {
            const type = upload.type;
            const size = type === 'video' ? upload.video.video_resources[0].size : upload[type].size;
            return <div key={'upload_' + upload.id} className={twMerge('pl-1 text-sm flex items-center', i < attachments.length - 1 && 'border-b')}>
                <div title={upload.name} className='truncate w-8/12 p-1'>{upload.name}</div>
                <div className='w-3/12 flex justify-end text-secondary '>{calculatePrettySize(size)}</div>
                <div className='w-1/12 justify-center flex' onClick={() => remove(i)}>x</div>
            </div>
        })}</div>}

    </div>
}
export default CommentAttachments;
