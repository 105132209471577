/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditReportCustomFieldsMultipleChoiceOption } from './AuditReportCustomFieldsMultipleChoiceOption';

/**
 *
 *
 */
export type AuditReportCustomFieldsMultipleChoice = {
    options: Array<AuditReportCustomFieldsMultipleChoiceOption>;
    type: AuditReportCustomFieldsMultipleChoice.type;
};

export namespace AuditReportCustomFieldsMultipleChoice {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

