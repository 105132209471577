/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCheckpointBarcodeTable } from './AuditCheckpointBarcodeTable';
import type { AuditCheckpointMeasurementTables } from './AuditCheckpointMeasurementTables';
import type { AuditCheckpointMultipleChoice } from './AuditCheckpointMultipleChoice';
import type { AuditCheckpointTextInputs } from './AuditCheckpointTextInputs';
import type { AuditCheckpointTextTable } from './AuditCheckpointTextTable';
import type { AuditCustomFieldValue } from './AuditCustomFieldValue';
import type { FileResource } from './FileResource';
import type { MultiLingualText } from './MultiLingualText';
import type { UUID } from './UUID';

export type AuditCheckpoint = {
    barcode_table: AuditCheckpointBarcodeTable;
    checkpoint_status: AuditCheckpoint.checkpoint_status;
    checkpoint_type: AuditCheckpoint.checkpoint_type;
    comment: string;
    description: MultiLingualText;
    has_spacer: boolean;
    id: UUID;
    instruction_details: MultiLingualText;
    instruction_file_resources: Array<FileResource>;
    is_picture_mandatory: boolean;
    mandatory: boolean;
    maximum_score: number;
    measurement_tables: AuditCheckpointMeasurementTables;
    multiple_choice: AuditCheckpointMultipleChoice;
    observed_file_resources: Array<FileResource>;
    production_unit_custom_fields: Array<AuditCustomFieldValue>;
    spacer_text: string;
    status_enabled: boolean;
    status_scores: Record<string, number>;
    supplier_custom_fields: Array<AuditCustomFieldValue>;
    text_inputs: AuditCheckpointTextInputs;
    text_table: AuditCheckpointTextTable;
};

export namespace AuditCheckpoint {

    export enum checkpoint_status {
        EMPTY = 'empty',
        CHECKED_OK = 'checked_ok',
        CHECKED_NOT_OK = 'checked_not_ok',
        NOT_APPLICABLE = 'not_applicable',
        PENDING = 'pending',
    }

    export enum checkpoint_type {
        DEFAULT = 'default',
        MEASUREMENT_TABLES = 'measurement_tables',
        BARCODE_TABLE = 'barcode_table',
        MULTIPLE_CHOICE = 'multiple_choice',
        TEXT_INPUTS = 'text_inputs',
        TEXT_TABLE = 'text_table',
        PRODUCTION_UNIT_CUSTOM_FIELDS = 'production_unit_custom_fields',
        SUPPLIER_CUSTOM_FIELDS = 'supplier_custom_fields',
    }


}

