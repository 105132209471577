import React, { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { DataType } from '../../../base/types';
import { getLocationEntry, useIsMobileDevice, useIsTabletDevice } from '../../../base/utils';
import QLightbox from '../../../lib/ril';
import { getAuditReportImagesSelector } from '../../audit/selectors/auditSelectors';
import { ImageComment } from '../../inspections/components/report/ImageComment';
import { getReportImagesSelector } from '../../inspections/selectors/reportSelectors';
import { ImageItems, Inspection } from '../../inspections/types';


type LightBoxProps = {
    photoURL: string;
    inspection?: Inspection;
    children: ReactNode;
    infoItem?: React.ReactElement;
    type?: DataType | 'other';
    images?: any[];
    className?: string;
};

const LightBox = (props: LightBoxProps): React.ReactElement => {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const isMobile = useIsMobileDevice();
    const isTablet = useIsTabletDevice();
    const { photoURL, type = 'inspection', infoItem } = props;
    const [isOpen, toggleOpen] = useState<boolean>(() => {
        let imageURL: string;
        if (location.search.includes('imageURL')) {
            imageURL = getLocationEntry(location, 'imageURL')[0];
        }
        return photoURL === imageURL;
    });

    /* const imagesItems = type === 'inspection' ? useSelector(getReportImagesSelector) : useSelector(getAuditReportImagesSelector)
    const images: string[] = props.images ? props.images : imagesItems.images; */
    let imagesItems: ImageItems;
    let images;
    switch (type) {
        case 'inspection':
            imagesItems = useSelector(getReportImagesSelector);
            images = imagesItems.images;
            break;

        case 'audit':
            imagesItems = useSelector(getAuditReportImagesSelector);
            images = imagesItems.images;
            break;
        default:
            images = props.images;
            break;
    }
    const [photoIndex, setPhotoIndex] = useState<number>(() => images.indexOf(photoURL) || 0);
    const imagesCnt: number = images.length;
    const imageTitleLabel: string = isMobile ? photoIndex + 1 + '/' + imagesCnt : intl.formatMessage({ id: 'lightbox.images_label' }, { photoIndex: photoIndex + 1, imagesCnt });
    let imageInfo;
    if (props.inspection) {
        const item = imagesItems.items[photoIndex];
        imageInfo =
            <ImageComment
                type={item && item.type || 'defect'}
                description={item && item.description || ''}
                severity={item && item.severity}
                imageUrl={item && item.imageUrl || ''}
                imageComment={item && item.imageComment}
                quantity_affected={item && item.quantity_affected}
                whole_lot={item && item.whole_lot}
                id={item && item.id}
                defectType={item && item.defectType}
                inspection={props.inspection}
                defect={item && item.defect}
                isEvidence={item && item.isEvidence}
                evidenceImage={item && item.evidenceImage}

            />;
    }
    if (infoItem) {
        imageInfo = infoItem;
    }
    useEffect(
        () => {
            let imageURL: string;
            if (location.search.includes('imageURL')) {
                imageURL = getLocationEntry(location, 'imageURL')[0];
            }
            const open = photoURL === imageURL;
            if (isOpen !== open) {
                toggleOpen(open);
            }
        }, [location.search]);
    return isOpen ? <QLightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        clickOutsideToClose={false}
        onMoveNextRequest={(): void => setPhotoIndex((photoIndex + 1) % images.length)}
        onMovePrevRequest={(): void => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        imageComment={imageInfo}
        imageInfo={imageInfo}
        draggable={!(isMobile || isTablet)}
        onCloseRequest={(): void => {
            const index = images.indexOf(photoURL);
            setPhotoIndex(index);
            if (type === 'audit' || type === 'inspection') {
                history.replace(location.pathname);
            }
            setTimeout(() => {
                // Allow location object to pickup change before closing LB - otherwise it will reopen
                toggleOpen(false);
            }, 150);

        }}
        imageTitle={imageTitleLabel}
    />
        :
        <div className={props.className}
            
            onClick={(): void => toggleOpen(true)}>
            {props.children}
        </div>;
};

export default LightBox;
