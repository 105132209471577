import { createSelector } from '@reduxjs/toolkit';
import { PaginatedAudits } from '../../../backend_api/models';
import { Audit } from '../../../backend_api/models/Audit';
import { AuditCustomConclusion } from '../../../backend_api_2';
import { AuditWithContent } from '../../../backend_api_2/models';
import { AppState, byId } from '../../../base/types';
import { groupArrayByObjectKey } from '../../../base/utils';
import { CheckpointRemarks } from '../../checklists/types';
import { ImageItems } from '../../inspections/types';
import { AuditReportState, AuditState } from '../types';

const audits = (state: AppState): AuditState => state.app.audits;

const getAuditList = (state: AppState): Audit[] => {
    return state.app.audits && state.app.audits.audits && state.app.audits.audits.audits;
}
export const getAuditListSelector = createSelector(
    [getAuditList],
    (list) => list,
);
const getPaginatedAuditList = (state: AppState): PaginatedAudits => {
    return state.app.audits && state.app.audits.audits;
}
export const getPaginatedAuditListSelector = createSelector(
    [getPaginatedAuditList],
    (list) => list,
);

const getAuditById = (state: AppState): Audit => audits(state).auditById;
export const getAuditByIdSelector = createSelector(
    [getAuditById],
    (audit) => audit,
);

const getAuditContentById = (state: AppState): AuditWithContent => audits(state).auditWithContent;
export const getAuditContentByIdSelector = createSelector(
    [getAuditContentById],
    (auditWithContent) => auditWithContent,
);

const getPreviousAuditContentById = (state: AppState): AuditWithContent => audits(state).previousAudit;
export const getPreviousAuditContentByIdSelector = createSelector(
    [getPreviousAuditContentById],
    (previous) => previous,
);

const getAuditsLoading = (state: AppState): boolean => audits(state).loading;
export const getAuditsLoadingSelector = createSelector(
    [getAuditsLoading],
    (loading) => loading,
);

const getAuditsFetchingFailed = (state: AppState): number => audits(state).errorCodeFetchingAudit;
export const getFetchingAuditErrorCodeFailedSelector = createSelector(
    [getAuditsFetchingFailed],
    (errorCodeFetchingAudit) => errorCodeFetchingAudit,
);

const getAuditCommentsLoading = (state: AppState): boolean => audits(state).loadingComments;
export const getAuditCommentsLoadingSelector = createSelector(
    [getAuditCommentsLoading],
    (loading) => loading,
);
const addingAuditComment = (state: AppState): boolean => audits(state).addingComment;
export const addingAuditCommentSelector = createSelector(
    [addingAuditComment],
    (adding) => adding,
);

const getAuditReport = (state: AppState): AuditReportState => audits(state).auditReport;
export const getAuditReportSelector = createSelector(
    [getAuditReport],
    (report) => report,
);
const isAuditListLoading = (state: AppState): boolean => audits(state).loading;
export const isAuditListLoadingSelector = createSelector(
    [isAuditListLoading],
    (loading) => loading,
);

const getAuditReportImages = (state: AppState): ImageItems => state.app.audits.auditReport && state.app.audits.auditReport.imageItems;
export const getAuditReportImagesSelector = createSelector(
    [getAuditReportImages],
    (images) => images,
);

const getAuditRportCheckpointsRemarks = (state: AppState): CheckpointRemarks => state.app.audits.auditReport.remarks || {};
export const getAuditReportCheckpointsRemarksSelector = createSelector(
    [getAuditRportCheckpointsRemarks],
    (remarks) => remarks,
);
const getAuditReportCheckpointWithRemarksIds = (state: AppState) => (state.app.audits.auditReport && state.app.audits.auditReport.checkpointWithRemarksIds) || [];
export const getReportCheckpointWithRemarksIdsSelector = createSelector(
    [getAuditReportCheckpointWithRemarksIds],
    (ids) => ids,
);
const auditCheckpoints = (state: AppState) => state.app.audits.auditReport && state.app.audits.auditReport.checkpoint_headers || [];
export const getAuditCheckpointsSelector = createSelector(
    [auditCheckpoints],
    (checkpoints) => checkpoints,
);
const getAuditTypes = (state: AppState) => state.app.audits.auditTypes;
export const getAuditTypesSelector = createSelector(
    [getAuditTypes],
    (types) => types,
);
const hasAuditTypes = (state: AppState) => state.app.audits.auditTypes.length > 0;
export const hasAuditTypesSelector = createSelector(
    [hasAuditTypes],
    (types) => types,
);

export const getAuditAllCustomConclusionsSelector = createSelector(
    [(state) => state.app.audits.auditCustomConclusions],
    (conclusions) => conclusions,
);

const getAuditCustomConclusionById = (state: AppState, conclusionId: string) => {
    const c = state.app.audits.auditCustomConclusions;
    if (c[conclusionId]) {
        return c[conclusionId];
    }
    return null;
};

export const getAuditCustomConclusionsById = (state: AppState) => {
    return groupArrayByObjectKey(state.app.audits.auditCustomConclusions, 'id') as unknown as byId<AuditCustomConclusion[]>;
}
export const getAuditCustomConclusionsByIdSelector = createSelector(
    [getAuditCustomConclusionsById],
    (byId) => byId
)
export const getAuditCustomConclusionByIdSelector = createSelector(
    [getAuditCustomConclusionById],
    (conclusion) => conclusion,
)
const getAuditCustomConclusions = (state: AppState, auditTypeId: string) => {
    const relationsMap = state.app.audits.auditTypesAndConclusionsRelations;
    if (relationsMap && auditTypeId) {
        return relationsMap[auditTypeId];
    }
    return [];
};

export const getAuditCustomConclusionsSelector = createSelector(
    [getAuditCustomConclusions],
    (conclusions) => conclusions,
);
const getAuditHistory = (state: AppState) => state.app.audits.history;
export const getAuditHistorySelector = createSelector(
    [getAuditHistory],
    (history) => history,
);
const getAuditHistoryLength = (state: AppState) => state.app.audits.history.length;
export const getAuditHistoryLengthSelector = createSelector(
    [getAuditHistoryLength],
    (l) => l,
);

export const getAuditAttachmentsSelector = createSelector(
    [() => 1],
    (attachments) => attachments,
);

const getTemporaryUser = (state: AppState) => state.app.audits.temporaryUser;
export const getTemporaryUserSelector = createSelector(
    [getTemporaryUser],
    (user) => user,
);