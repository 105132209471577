import React, { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react'
import { CreateCorrectiveActionOnSupplierRequest } from '../../../backend_api/models'
import { useAppDispatch } from '../../../store'
import HasFeature from '../../profile/containers/HasFeature'
import { createCorrectiveActionOnSupplier } from '../correctiveActionsSlice'
import { areSupplierAndProductionUnitLinked, useSupplierAndProductionUnitSelection } from '../../productionUnits/hooks/useSupplierAndProductionUnitSelection'
import ControlledSupplierSelector from '../../suppliers/components/ControlledSupplierSelector'
import ControlledProductionUnitSelector from '../../productionUnits/components/ControlledProductionUnitSelector'
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits'
import { useSupplierAndProductionUnitRelations } from '../../supplierAndPuRelations/slice/relationSlice'

const CreateCorrectiveActionOnSupplier = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [finding, setFinding] = useState<string>('');
    const independentProductionUnits = useHasIndependentProductionUnits();

    const {
        otherProductionUnits,
        otherSuppliers,
        productionUnits,
        suppliers,
        selectedProductionUnitId,
        selectedSupplierId,
        setSelectedProductionUnitId,
        setSelectedSupplierId,
        linked,
        loading: useSupplierLoading
    } = useSupplierAndProductionUnitSelection(independentProductionUnits)

    const relations = useSupplierAndProductionUnitRelations()

    const loading = relations === 'loading' || useSupplierLoading

    function onProductionUnitIdSelectedByUser(productionUnitId: string) {
        /* Should not be possible to call these callback, while loading. Selectors are disabled */
        if (loading) {
            return;
        }

        setSelectedProductionUnitId(productionUnitId)

        if (productionUnitId && selectedSupplierId) {
            if (!areSupplierAndProductionUnitLinked(selectedSupplierId, productionUnitId, relations)) {
                setSelectedSupplierId(null);
            }
        }
    }
    function onSupplierIdSelectedByUser(supplierId: string) {
        /* Should not be possible to call these callback, while loading. Selectors are disabled */
        if (loading) {
            return;
        }

        setSelectedSupplierId(supplierId)

        if (supplierId && selectedProductionUnitId) {
            if (!areSupplierAndProductionUnitLinked(supplierId, selectedProductionUnitId, relations)) {
                setSelectedProductionUnitId(null);
            }
        }
    }

    const dispatchCreateCorrectiveActionOnSupplier = () => {
        const supplier_links = selectedSupplierId ? [selectedSupplierId] : []
        const production_unit_links = selectedProductionUnitId ? [selectedProductionUnitId] : []
        const request: CreateCorrectiveActionOnSupplierRequest = {
            finding: finding,
            supplier_links: supplier_links,
            production_unit_links: production_unit_links
        }
        dispatch(createCorrectiveActionOnSupplier(request))
        close()
    }

    const close = () => {
        setDialogOpen(false);
        setFinding('');
    }

    const updateFinding = (evt, input) => {
        setFinding(input.value)
    }

    // If independendProductionUnits is true, it's enough to choose one of either PU or Supplier
    const supplierRequired = independentProductionUnits ? !selectedProductionUnitId : true
    const productionUnitRequired = independentProductionUnits ? !selectedSupplierId : false

    function canBeSaved() {
        if (!finding || finding === '') {
            return false
        }

        if (selectedProductionUnitId && selectedSupplierId && !linked) {
            return false;
        }

        return (independentProductionUnits ? selectedProductionUnitId || selectedSupplierId : true)
    }


    const trigger = <HasFeature feature='u_create_corrective_action'>
        <Button onClick={() => setDialogOpen(true)} secondary>
            <Fragment>
                <Icon name='plus' size='small' />
                <FormattedMessage id='corrective_actions.list.new' />
            </Fragment>
        </Button>
    </HasFeature>

    return <Modal
        open={dialogOpen}
        closeOnEscape
        closeIcon
        onClose={close}
        trigger={trigger}
    >
        <Modal.Header><FormattedMessage id='corrective_actions.create_modal.header' /></Modal.Header>
        <Modal.Content>
            <Form >
                <Form.Field required>
                    <label><FormattedMessage id='corrective_actions.create_modal.finding' /></label>
                    <Input
                        value={finding}
                        onChange={updateFinding}
                        placeholder={intl.formatMessage({ id: 'corrective_actions.create_modal.finding.placeholder' })}
                    />
                </Form.Field>
                <Form.Field required={supplierRequired}>
                    <label><FormattedMessage id='corrective_actions.create_modal.supplier' /></label>
                    <div className={'flex w-full'}>
                        <ControlledSupplierSelector
                            onSupplierIdSelected={onSupplierIdSelectedByUser}
                            supplierId={selectedSupplierId}
                            suppliers={suppliers}
                            nonMatchingSuppliers={otherSuppliers}
                            className='w-full'
                            loading={loading}
                        />
                    </div>
                </Form.Field>
                <Form.Field required={productionUnitRequired}>
                    <label><FormattedMessage id='corrective_actions.create_modal.production_unit' /></label>
                    <div className={'flex w-full'}>
                        <ControlledProductionUnitSelector
                            loading={loading}
                            onProductionUnitIdSelected={onProductionUnitIdSelectedByUser}
                            productionUnitId={selectedProductionUnitId}
                            productionUnits={productionUnits}
                            nonMatchingProductionUnits={otherProductionUnits}
                        />
                    </div>
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button
                primary
                disabled={!canBeSaved()}
                onClick={dispatchCreateCorrectiveActionOnSupplier}>
                <FormattedMessage id='globals.ok' />
            </Button>
            <Button onClick={close}>
                <FormattedMessage id='globals.cancel' />
            </Button>
        </Modal.Actions>
    </Modal>
}

export default CreateCorrectiveActionOnSupplier