import classnames from 'classnames';
import React from 'react';
import { Inspection } from '../../types';
import SplitShipmentsInfo from './SplitShipmentsInfo';

type SplitShipmentsMarkerOrderProps = {
    className?: string;
    inspection: Inspection;
};

const SplitShipmentsMarkerOrder = (props: SplitShipmentsMarkerOrderProps): React.ReactElement => {
    const { className, inspection } = props;
    return <div className={classnames('', className)}>
        <SplitShipmentsInfo inspection={inspection} />
    </div>
}
export default SplitShipmentsMarkerOrder;
