import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { AdminCtxUser } from '../../backend_api/models/AdminCtxUser';
import { AppState } from '../../base/types';
import { sortArrayByKey } from '../../base/utils';
import { GroupData, GroupOption } from './types';

const usersAdminSelector = (state: AppState): AdminCtxUser[] => state.app.users.admin.persons || [];

const groupsSelector = (state: AppState, excludeSuppliers: boolean): GroupData[] => {
    if (state.app.groups.groups) {

        return excludeSuppliers ? state.app.groups.groups.filter((group: GroupData) => !group.is_supplier_group) : state.app.groups.groups;
    }
    return [];
};

const groupsSelectorSelector = (state: AppState, excludeSuppliers: boolean): GroupOption[] => {
    const groups: GroupData[] = groupsSelector(state, excludeSuppliers);
    const selector: GroupOption[] = [];
    groups.forEach((group: GroupData) => {
        const content = <div className='flex justify-between'><span>{group.name}</span>{group.is_supplier_group && <Popup position={'top right'} trigger={<Icon className='text-default-widget-color' name='factory' />}>
            <FormattedMessage id='users.user_is_supplier_group' />
        </Popup>}</div>;
        const obj = { text: group.name, key: group.id, value: group.id, is_supplier_group: group.is_supplier_group, content };

        selector.push(obj);
        // selector.push({ text: group.name, key: group.id, value: group.id, is_supplier_group: group.is_supplier_group });
    });
    return selector;
};

const groupsWithSupplierSelector = (state: AppState): GroupData[] => state.app.groups.groups || [];

const isFetchingSelector = (state: AppState): boolean => state.app.groups.isFetching;

const sortGroups = (groups: GroupOption[] | GroupData[]): GroupOption[] | GroupData[] => {
    return sortArrayByKey(groups, 'is_supplier_group');
};

export const getUsersAdminSelector = createSelector(
    [usersAdminSelector],
    (users) => users,
);

export const getGroupsSelector = createSelector([groupsSelector],
    (groups) => groups,
);
export const getGroupsSelectorSelector = createSelector([groupsSelectorSelector],
    (groups) => groups,
);
export const getGroupsWithSuppliersSelector = createSelector([groupsWithSupplierSelector],
    (groups) => groups,
);

export const getIsFetchingSelector = createSelector([isFetchingSelector], (isFetching) => isFetching);

export const sortGroupsSelector = createSelector(
    [sortGroups],
    (sortedGroups) => sortedGroups,
);
