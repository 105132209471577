import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Form, Input, List, Popup, Table } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';
import { deepCopy } from '../../../base/utils';
import ColorPicker from '../../globals/components/ColorPicker';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { CheckpointTypeVersionNumber, MultipleChoiceData, MultipleChoiceOption } from '../types';

export const multipleChoiceVersion = 1

type MultipleChoiceDetailsProps = {
    data: MultipleChoiceData;
    update(data: MultipleChoiceData);
    language: string;
}

type UniqueData = {
    options: UniqueOption[];
    choices: 'any' | 'one';
    version: CheckpointTypeVersionNumber;
}

type UniqueOption = MultipleChoiceOption & {
    id: string;
}

const MultipleChoiceDetails = (props: MultipleChoiceDetailsProps): React.ReactElement => {
    const intl = useIntl()
    const [data, setData] = useState<UniqueData>(makeEmptyUniqueMultipleChoiceData());

    /**
     * We have to put some id's on the rows, otherwise rendering will fail, when deleting rows.
     * The problem with this is, that we lose focus, everytime we save, because we generate new id's,
     * and React will generate new DOM nodes, thus the element that had focus is removed and replaced with something else.
     *
     * For now, I added to the backend schema, that a "editor_reference_id" is allowed, which means that the editor kan
     * generate ids to use for the UI. Now we only generate an id, if no `editor_reference_id` is present, otherwise we use
     * that as the id.
     * */
    useEffect(() => {
        if (props.data) {
            const uniqueData = convertMultipleChoiceDataToUniqueMultipleChoiceData(props.data);
            setData(uniqueData);
        }
        else {
            const uniqueData = makeEmptyUniqueMultipleChoiceData();
            setData(uniqueData);
        }
    }, [props.data]);

    function callUpdateCallback(updatedData: UniqueData): void {
        props.update(convertUniqueDataToMultipleChoiceData(updatedData))
    }

    function setScoreAndSave(score: number, index: number): void {
        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.options[index].score = score;
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function setColorAndUpdate(color: string, index: number): void {
        if (color == undefined) {
            color = null;
        }

        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.options[index].color = color;
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function setFailedAndUpdate(failed: boolean, index: number): void {
        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.options[index].failed = failed;
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function addNewRowAndUpdate(): void {
        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.options.push(createNewRow());
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function deleteRowAndUpdate(index: number): void {
        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.options.splice(index, 1);

        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function setNameAndUpdate(name: MultiLanguageText, index: number): void {
        const dataCopy = deepCopy(data);
        dataCopy.options[index].name = name;
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    function setDescriptionAndUpdate(description: MultiLanguageText, index: number): void {
        const dataCopy = deepCopy(data);
        dataCopy.options[index].description = description;
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }

    const setMultiSelectionAndUpdate = (multi: boolean): void => {
        const dataCopy: UniqueData = deepCopy(data);
        dataCopy.choices = multi ? 'any' : 'one';
        setData(dataCopy);
        callUpdateCallback(dataCopy);
    }
    return (
        <Fragment>
            <List.Item>
                <Popup
                    on="hover"
                    trigger={<Checkbox
                        label={intl.formatMessage({ id: 'checklists.editor.radio.multipick' })}
                        checked={data.choices === 'any'}
                        onChange={(evt, item): void => setMultiSelectionAndUpdate(item.checked)}
                    />}
                >
                    <Popup.Content><FormattedMessage id="checklists.editor.radio.multipick.pupop" /></Popup.Content>
                </Popup>
            </List.Item>
            <Table className="multiple-choice">
                <Table.Header>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.description'} />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.detailed_description'} />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.score'} />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.color'} />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.failed'} />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id={'checklists.editor.radio.delete'} />
                    </Table.HeaderCell>
                </Table.Header>
                {data.options.map((row, index) => {
                    return (
                        <Table.Row key={row.id} data-test-id='multiple-choice-option'>
                            <Table.Cell>
                                <Form>
                                    <MultiLangualText
                                        presentation='area'
                                        language={props.language}
                                        originalText={row.name}
                                        onKeyDown={(e): void => {
                                            if (e.keyCode === 13) {
                                                e.preventDefault();
                                                addNewRowAndUpdate();
                                            }
                                        }}
                                        save={(name): void => {
                                            setNameAndUpdate(name, index);
                                        }}
                                    />
                                </Form>
                            </Table.Cell>
                            <Table.Cell>
                                <Form>
                                    <MultiLangualText
                                        presentation='area'
                                        language={props.language}
                                        originalText={row.description}
                                        save={(description): void => {
                                            setDescriptionAndUpdate(description, index);
                                        }}
                                    />
                                </Form>
                            </Table.Cell>
                            <Table.Cell>
                                <ScoreInput
                                    score={row.score}
                                    setScore={(score): void => setScoreAndSave(score, index)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <ColorPicker
                                    color={row.color || undefined}
                                    handlePick={(color): void => setColorAndUpdate(color, index)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <div className='u-flex-align-center'>
                                    <Checkbox
                                        checked={row.failed}
                                        onChange={(evt, item): void => setFailedAndUpdate(item.checked, index)}
                                    />
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <Button disabled={props.language !== 'C'} onClick={(): void => deleteRowAndUpdate(index)} data-test-id='remove-choice-option-button'>-</Button>
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
                <Table.Row>
                    <Table.Cell>
                        <Button disabled={props.language !== 'C'} onClick={addNewRowAndUpdate} data-test-id='add-choice-option-button'>+</Button>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                </Table.Row>
            </Table>
        </Fragment>
    );
}

function createNewRow(): UniqueOption {
    return {
        name: { C: '' },
        description: { C: '' },
        score: null,
        color: null,
        failed: false,
        chosen: false,
        id: uuidv1()
    }
}

function makeEmptyUniqueMultipleChoiceData(): UniqueData {
    return { options: [], choices: 'any', version: multipleChoiceVersion };
}

function convertMultipleChoiceDataToUniqueMultipleChoiceData(data: MultipleChoiceData): UniqueData {
    const uniqueOptions = data.options.map((option) => {
        return {
            name: option.name,
            description: option.description,
            score: option.score,
            failed: option.failed,
            color: option.color,
            chosen: option.chosen,
            id: option.editor_reference_id || uuidv1()
        };
    })
    return {
        options: uniqueOptions,
        choices: data.choices,
        version: data.version
    };
}

function convertUniqueDataToMultipleChoiceData(cdata: UniqueData): MultipleChoiceData {
    const convertedOptions = cdata.options.map((option) => {
        return {
            description: option.description,
            name: option.name,
            score: option.score,
            failed: option.failed,
            color: option.color,
            chosen: option.chosen,
            editor_reference_id: option.id,
        };
    })
    return {
        options: convertedOptions,
        choices: cdata.choices,
        version: cdata.version
    }
}

function ScoreInput(props: { score: number; setScore: (score: number) => void }): React.ReactElement {

    const [localScore, setLocalScore] = React.useState(props.score || null);

    useEffect(() => {
        setLocalScore(props.score);
    }, [props.score])

    function callCallback(): void {
        props.setScore && props.setScore(localScore)
    }

    return (
        <Input
            style={{ width: '50px' }}
            className='cell'
            type='number'
            value={localScore}
            onChange={(ev): void => setLocalScore(parseInt(ev.target.value))}
            onBlur={callCallback}
            placeholder={'/'}
        />
    )
}

export default MultipleChoiceDetails;