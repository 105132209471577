import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuditCheckpointMultipleChoiceOption } from '../../../../backend_api/models';
import { Audit, AuditCheckpoint, AuditCheckpointMultipleChoice } from '../../../../backend_api_2';
import Icon from '../../../../base/components/basic/Icon';
import { ListItem, ListItems, SelectableType } from '../../../../base/ui/components/listitems/ListItem';
import { getLocaleLanguageString } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { updateCheckpointMultipleChoice } from '../../slices/auditSlice';
import { AuditCheckpointMedia } from './AuditCheckpointMedia';
import { doesMultipleChoiceCheckpointRequireInput } from './mandatory';
import { AuditCheckpointTitleAndActionMenu, getTextWithTranslation } from './PerformAuditPage';
import { WebAuditCheckpointStatus } from './WebAuditCheckpointStatus';

export function MultipleChoiceCheckpoint(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const { audit, checkpoint, triedToSubmit } = props;
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const [options, setOptions] = React.useState(checkpoint?.multiple_choice?.options);

    const dispatch = useAppDispatch();
    const singleChoice = checkpoint.multiple_choice?.choices == AuditCheckpointMultipleChoice.choices.ONE;
    const requiresInput = doesMultipleChoiceCheckpointRequireInput(checkpoint);

    function doUpdateMultpleChoice(option: AuditCheckpointMultipleChoiceOption, index: number) {
        let optionsToUpdate = JSON.parse(JSON.stringify(checkpoint.multiple_choice.options)) as AuditCheckpointMultipleChoiceOption[];
        if (singleChoice) {
            optionsToUpdate.forEach((o, i) => {
                if (i == index) {
                    optionsToUpdate[i].chosen = true;
                } else {
                    optionsToUpdate[i].chosen = false;
                }
            })
        } else {
            optionsToUpdate[index].chosen = !optionsToUpdate[index].chosen;
        }
        setOptions(optionsToUpdate);
        dispatch(updateCheckpointMultipleChoice({
            audit_id: audit.id,
            checkpoint_id: checkpoint.id,
            options: optionsToUpdate,
            choices: checkpoint.multiple_choice.choices
        }))
    }
    return (
        <div>
            <AuditCheckpointTitleAndActionMenu audit={audit} checkpoint={checkpoint} />
            <WebAuditCheckpointStatus audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
            {
                triedToSubmit && requiresInput &&
                <div className='text-status-rejected text-sm mb-2 flex flex-row items-center'>
                    <Icon name='error' className='text-lg mr-1' />
                    <FormattedMessage id='web_based_audit.mandatory_warning.provide_inputs' />
                </div>
            }
            <fieldset className='p-0'>
                <legend className='visually-hidden'>{getTextWithTranslation(checkpoint.description, lang)}</legend>
                <ListItems className={'border rounded border-b-0 w-full sm:w-2/3 mb-4'}>
                    {
                        options?.map((option, index) =>
                            <ListItem
                                selectable={{
                                    onChange: () => doUpdateMultpleChoice(option, index),
                                    type: singleChoice ? SelectableType.Radio : SelectableType.Checkbox,
                                    id: checkpoint.id + "_" + index,
                                    selectedColor: option.color,
                                    selected: option.chosen
                                }}
                                meta={
                                    { metaBelow: { firstItem: getTextWithTranslation(option.description, lang) } }
                                }
                            >
                                {getTextWithTranslation(option.name, lang)}
                            </ListItem>
                        )
                    }
                </ListItems>
            </fieldset>
            <AuditCheckpointMedia audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit} />
        </div>
    )
}