import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { ApproverFilter, ConclusionFilter, CustomFieldFilter, DateFilter, InspectionTypesFilter, InspectorFilter, SupplierFilter } from '.';
import { setLayoutAttribute } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { Breakpoints } from '../../../base/config';
import { useIsMobileDevice, useMaxWidthBreak, useMinWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getInspectionsListMetaDataSelector, setListViewDefaultDate } from '../../inspections/slices/inspectionsListSlice';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';
import { clearAllFilters, loadFilters, setFiltersVisible } from '../filtersActions';
import { getFiltersVisibleSelector, hasActiveFiltersSelector } from '../selectors';
import AuditConclusionFilter from './AuditConclusionFilter';
import AuditTypesFilter from './AuditTypeFilter';
import ComplianceRequirementCategoryFilter from './ComplianceRequirementCategoryFilter';
import CorrectiveActionStateFilter from './CorrectiveActionStateFilter';
import ProductionUnitFilter from './ProductionUnitFilter';
import ResponsibleFilter from './ResponsibleFilter';

type FiltersListProps = {
    className?: string;
    filters: ('date-filter' | 'inspector-filter' | 'audit-conclusion-filter' | 'conclusion-filter' | 'approver-filter' | 'inspection-types-filter' | 'custom-field-filter' | 'supplier-filter' | 'production-unit-filter' | 'responsible-filter' | 'ca-state-filter' | 'audit-type-filter' | 'compliance-requirements-category-filter')[];
    dateFilterChange?();
    getFilterMetaData?(any);
    noStatusAfterClear?: boolean;
    context?: string;
};

const FiltersList = (props: FiltersListProps): React.ReactElement => {
    const { filters, className, dateFilterChange, getFilterMetaData, noStatusAfterClear = false } = props;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isMobile = useIsMobileDevice();
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);
    const isMinLG = useMinWidthBreak(Breakpoints.LG);
    const showAll = useSelector(getFiltersVisibleSelector);
    const [showLocal, setShowLocal] = useState(showAll);
    const hasActiveFilters = useSelector(hasActiveFiltersSelector);
    const meta: any = useSelector(getFilterMetaData ? getFilterMetaData : getInspectionsListMetaDataSelector);
    const setShowAll = (show: boolean): void => {
        dispatch(setFiltersVisible(show));
        dispatch(setLayoutAttribute('bodyFixed', show));
        setShowLocal(show);
    };
    const clearAll = (statusAfter: boolean): void => {
        (statusAfter && noStatusAfterClear === false) ? dispatch(clearAllFilters(true, { status: 'planned' })) : dispatch(clearAllFilters(true));
    };
    useEffect(() => {
        if (history.action === 'POP') {
            dispatch(loadFilters());
        }
    }, [history.location.search]);
    useEffect(() => {
        setShowLocal(showAll)
        if (showAll && isMobile) {
            dispatch(setLayoutAttribute('bodyFixed', true));
        }
    }, [showAll]);

    const independentProductionUnits = useHasIndependentProductionUnits();

    const filterClass = 'md:w-80 border-b md:border-0 p-4 pt-0 md:p-0 text-md';
    const clearAction = <div className='text-sm link' onClick={(): void => clearAll(true)}><FormattedMessage id='filters.link.label.clear_all' /></div>;
    const includeSearch = true;
    const showExpanded = isMinLG;
    if (showAll) {
        const filterItems = showLocal ? <>
            {filters.includes('date-filter') && <div className={filterClass}><DateFilter disabled={meta.isFetching} onChange={dateFilterChange ? dateFilterChange : setListViewDefaultDate} showFuture={true} showPast={true} showLabels={true} /></div>}
            {filters.includes('inspector-filter') && <div className={filterClass}><InspectorFilter search={includeSearch} showExpanded={showExpanded} disabled={meta.isFetching} /></div>}
            {filters.includes('conclusion-filter') && <div className={filterClass}><ConclusionFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('audit-conclusion-filter') && <div className={filterClass}><AuditConclusionFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('approver-filter') && <div className={filterClass}><ApproverFilter search={includeSearch} showExpanded={showExpanded} disabled={meta.isFetching} /></div>}
            {filters.includes('inspection-types-filter') && <div className={filterClass}><InspectionTypesFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('custom-field-filter') && <div className={filterClass}><CustomFieldFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('supplier-filter') && <div className={filterClass}><SupplierFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('production-unit-filter') && independentProductionUnits && <div className={filterClass}><ProductionUnitFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('responsible-filter') && <div className={filterClass}><ResponsibleFilter search={includeSearch} disabled={meta.isFetching} /></div>}
            {filters.includes('ca-state-filter') && <div className={filterClass}><CorrectiveActionStateFilter context={props.context} /></div>}
            {filters.includes('audit-type-filter') && <div className={filterClass}><AuditTypesFilter /></div>}
            {filters.includes('compliance-requirements-category-filter') && <div className={filterClass}><ComplianceRequirementCategoryFilter /></div>}
        </> : <div className='pl-4 md:pl-2'><FormattedMessage id='filters.loading_filters.please_wait' /></div>;
        const content = <>
            {isMaxMD && 
            <div className={classnames('md:hidden flex flex-col h-screen', className)}>
                <div className='flex py-3 border-b-border-dark border-b-2 -mx-4 px-8 items-center justify-between text-lg'>
                    <div className='flex space-x-2 items-center'><RemoveIcon className='mr-2 pr-2 border-r-2' onClick={(): void => setShowAll(false)} /><div><FormattedMessage id='filters.label' /></div></div>
                    {clearAction}
                </div>
                <div className='flex-1 overflow-y-auto py-8 pb-48 space-y-4'>
                    {filterItems}
                </div>

            </div>}
            {isMaxMD && <div className='flex md:hidden sticky bottom-0 bg-branding-brand items-center pt-4 pb-4 text-xl justify-center'>
                {meta && meta.isFetching && <div><Icon name='circle notch' loading /><FormattedMessage id='filters.updating_metadata_loader' /></div>}
                {meta && !meta.isFetching && <Fragment>
                    <div className='space-x-3 flex items-center'>
                        <div><FormattedMessage id={meta.total === 1 ? 'filters.order_found' : 'filters.orders_found'} values={{ ordersCnt: meta && meta.total }} /></div>
                        <Button onClick={(): void => {
                            setShowAll(false);
                            scrollTo(0, 0);
                        }}
                            className='py-1 px-2 '><FormattedMessage id='globals.ok' /></Button>
                    </div>
                </Fragment>}
            </div>}
            <div className={classnames('hidden md:flex bg-white md:px-6 md:pt-2 md:pb-4 flex-col w-full', props.className)}>
                <div className='flex flex-col md:flex-row md:flex-wrap space-y-4 space-x-4 mb-4 -ml-4'>
                    <div></div>
                    {filterItems}
                </div>
                <div className='flex flex-row pt-2 pl-6  mt-2 -mx-6 space-x-2 border-t'>
                    <div className='text-sm link' onClick={(): void => setShowAll(!showAll)}><FormattedMessage id='filters.link.label.hide' /></div>
                    {hasActiveFilters && <div className='text-sm link' onClick={(): void => clearAll(true)}><FormattedMessage id='filters.link.label.clear_all' /></div>}
                </div>
            </div>
        </>

        return <div className={classnames('bg-white fixed top-0 w-full md:flex md:relative md:mb-4 border', { 'z-20': isMobile })}>
            {content}
        </div>;
    }
    return null;
};

export default FiltersList;
