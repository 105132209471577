import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { DropdownFilter } from '.';
import { LIMITED_SELECTOR_ITEMS_COUNT } from '../../../base/config';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getSuppliers } from '../../inspections/actions/filterActions';
import { getLoadedSuppliersSelector } from '../../inspections/selectors/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type SupplierFilterProps = {
    selectedItems?: string[];
    filterName?: string;
    disabled?: boolean;
    search?: boolean;
};
const SupplierFilter = (props: SupplierFilterProps): React.ReactElement => {
    const { search = true, disabled = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const date = getLocationEntry(location, 'date')[0];
    const [d, setD] = useState(undefined);
    const filterName = props.filterName || 'supplier_id';
    const selectedSuppliers = getLocationEntry(location, filterName);
    const suppliers: DropdownItemProps[] = useSelector(getLoadedSuppliersSelector);
    useEffect(() => {
        if (date !== d || d === undefined) {
            dispatch(getSuppliers());
            setD(date);
        }
    }, [date]);
    return <Filter labelId='filters.supplier.label_supplier' >
        <DropdownFilter
            limitItemsCount={LIMITED_SELECTOR_ITEMS_COUNT}
            name='supplier'
            options={suppliers}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.supplier.select_supplier' })}
            selectedItems={selectedSuppliers || []}
            handleSave={(items): void => {
                dispatch(setFilter(filterName, items));
            }}
            inline={false}
            showLoader={true}
            disabled={disabled}
            search={search}
        /></Filter >;
};

export default SupplierFilter;
