/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 */
export type AuditReportCustomFieldsText = {
    previous_text: string | null;
    text: string | null;
    type: AuditReportCustomFieldsText.type;
};

export namespace AuditReportCustomFieldsText {

    export enum type {
        TEXT = 'Text',
    }


}

