import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'semantic-ui-react';
import { updateUser } from '../actions/actions';

type OwnProps = {
    userId: string;
    done?();
    children?: React.ReactNode;
};

type StateProps = any;

type DispatchProps = {
    actions: {
        updateUser: typeof updateUser;
    };
};

const initialState = {
    dirty: false, show: false, valid: false,
};

type State = Readonly<typeof initialState>;

type DeactivateUserProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class DeactivateUser extends Component<DeactivateUserProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
    }
    render(): React.ReactElement {
        return (
            <Modal
                open={this.state.show}
                onOpen={(): void => this.setState({ show: true })}
                onClose={this.cancel}
                closeIcon={true}
                size='small'
                trigger={this.props.children}
                className='deactivateUser'
            >
                <Modal.Header><FormattedMessage id='users.deactivate_user' /></Modal.Header>
                <Modal.Content>
                    <FormattedMessage id='users.deactivate_user_confirm' />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={(): void => this.deactivateUser()}><FormattedMessage id='globals.ok' /></Button>
                    <Button onClick={this.cancel}><FormattedMessage id='globals.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }
    deactivateUser(): void {
        Promise.all([
            this.props.actions.updateUser(this.props.userId, { active: false })]).
            then(() => {
                if (this.props.done) {
                    this.props.done();
                }
            });
        this.setState(initialState);
    }

    cancel(): void {
        this.setState(initialState);
    }
}

export const mapStateToProps = (): StateProps => {
    return {
    };
};

export const mapDispatchToProps = (dispatch): DispatchProps => {
    const actions = bindActionCreators({
        updateUser,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(DeactivateUser));
