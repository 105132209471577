import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { Group } from '../../../backend_api/models';
import { InputWithoutHints } from '../../productionUnits/components/CreateOrSelectProductionUnitModal';
import { GroupsWrapper } from '../../groups/components/GroupsWrapper';

export function NewProductionUnitInputsWithoutHints(props: {
    groupsEnabled: boolean;
    groupsInput: Group[];
    onGroupsChanged(groups: Group[]): void;
    productionUnitNameInput: string;
    productionUnitNumberInput: string;
    onProductionUnitNameChanged(name: string): void;
    onProductionUnitNumberChanged(number: string): void;
}): React.ReactElement {
    return (
        <Form>
            <InputWithoutHints
                required={true}
                input={props.productionUnitNameInput}
                onInputChanged={props.onProductionUnitNameChanged}
                renderTitle={() => <label><FormattedMessage id='production_unit.input_label.production_unit_name' /></label>} />
            <InputWithoutHints
                input={props.productionUnitNumberInput}
                onInputChanged={props.onProductionUnitNumberChanged}
                renderTitle={() => <label><FormattedMessage id='production_units.input_label.production_unit_number' /></label>} />
            {props.groupsEnabled
                ? <GroupsWrapper
                    groupsInput={props.groupsInput}
                    onGroupsChanged={props.onGroupsChanged} />
                : null}
        </Form>
    );
}
