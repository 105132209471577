import { Action } from '../../../base/genericActions';
import { byId } from '../../../base/types';
import { CALCULATE_SAMPLE_SIZE_SUCCESS } from '../../samplingProtocols/slices/samplingProtocolsSlice';
import { ADD_INSPECTION_GET_DATA_OBJECT_CLEAR, ADD_INSPECTION_GET_DATA_OBJECT_REQUEST, ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS } from '../actionTypes/addInspectionActionTypes';
import * as types from '../types';
import {
    DELETE_INSPECTION_SUCCESS, EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL, EDIT_INSPECTION_CREATED_NEW,
    EDIT_INSPECTION_CREATED_NEW_CANCEL, EDIT_INSPECTION_SET_FOCUSSED_INSPECTION, EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS, Inspection,
    Order, SET_SAME_ON_ALL_INSPECTIONS
} from '../types';
import { getCombinedOrderSubInspections, isInspectionMasterInspection, isInspectionPartOfCombinedInspection, isOrderCombinedInspection } from '../Utils';

const initialState: EditInspectionState = {
    order: undefined,
    inspectionsMap: {},
    editInspectionDataReady: false,
    changedInspections: {},
    isFetching: false,
    isSaving: false,
    inspectionAdded: false,
    isAddingInspection: false,
    isCreatingOrder: false,
    focussedInspectionId: '',
    isSameForAllInspections: false,
    isSameForAllInspectionsPermissions: undefined,
    areAllInspectionsEqual: false,
};

export type EditInspectionState = {
    order: Order;
    inspectionsMap: byId<Inspection>;
    changedInspections?: byId<{ [name: string]: any }>;
    focussedInspectionId?: string;
    editInspectionDataReady?: boolean;
    editInspectionFetching?: boolean;
    isFetching?: boolean;
    isSaving?: boolean;
    inspectionAdded?: boolean;
    isAddingInspection?: boolean;
    error?: {
        message: string;
        status: number;
        error: boolean;
    };
    isCreatingOrder?: boolean;
    areAllInspectionsEqual?: boolean;
    isSameForAllInspections?: boolean;
    isSameForAllInspectionsPermissions?: any;
    isNew?: boolean;
    newData?: { inspection: Inspection };
}

const map = {
    [types.ORDER_AND_INSPECTIONS_REQUEST]:
        (state = initialState, action): EditInspectionState => {
            return {
                ...state,
                isFetching: action.payload.isFetching,
            };
        },
    [types.ORDER_AND_INSPECTIONS_REQUEST_FAILURE]:
        (state = initialState, action: Action<types.ORDER_AND_INSPECTIONS_REQUEST_FAILURE>): EditInspectionState => {
            return {
                ...state,
                isFetching: action.payload.isFetching,
                error: {
                    message: action.payload.message,
                    status: action.payload.status,
                    error: true,
                },
            };
        },
    [types.ORDER_AND_INSPECTIONS_REQUEST_SUCCESS]:
        (state = initialState, action: Action<types.ORDER_AND_INSPECTIONS_REQUEST_SUCCESS>): EditInspectionState => {
            // Called when edit order component loads data
            const inspectionsMap: byId<Inspection> = {};
            const order = Object.assign({}, action.payload.order);
            const isCombined = isOrderCombinedInspection(order);
            const inspections = Object.assign([], isCombined ? getCombinedOrderSubInspections(order) : action.payload.order.inspections);
            inspections.forEach((inspection: Inspection) => {
                inspectionsMap[inspection.inspection_id] = inspection;
            });
            order.inspectionsMap = inspectionsMap;
            return {
                ...state,
                isFetching: action.payload.isFetching,
                order,
                inspectionsMap,
            };
        },
    [types.UPDATE_SINGLE_INSPECTION_REQUEST]:
        (state = initialState, action: Action<types.UPDATE_SINGLE_INSPECTION_REQUEST>): EditInspectionState => {
            const findSubinspectionOnOrder = (inspection: Inspection, order: Order) => {
                let subInsp: Inspection;
                order.inspections.forEach((insp) => {
                    insp.sub_inspections.forEach((sub) => {
                        if (sub.inspection_id === inspection.inspection_id) {
                            subInsp = sub;
                        }
                    });
                })
                return subInsp;
            }
            // Updating state when a single inspection's data is updated
            let index = -1;
            const inspectionId = action.payload.inspectionId;
            const inspectionsMap = Object.assign({}, state.inspectionsMap);
            const order = Object.assign({}, state.order);
            order.inspections.forEach((inspection: Inspection, i: number) => {
                if (inspection.inspection_id === inspectionId) {
                    index = i;
                }
            });
            if (index > -1) {
                order.inspections[index] = action.payload.data;
            }
            if ((inspectionsMap[inspectionId]) && !isInspectionPartOfCombinedInspection(inspectionsMap[inspectionId])) {
                inspectionsMap[inspectionId] = action.payload.data;
            } else {
                const inspection = inspectionsMap[inspectionId];
                if (inspection) {
                    const data = action.payload.inspectionData;
                    const key = Object.keys(data)[0];
                    const sub = findSubinspectionOnOrder(inspection, state.order);
                    if (sub) {
                        sub[key] = data[key];
                    }
                }
            }
            return {
                ...state,
                inspectionsMap,
                order,
            };
        },
    [types.UPDATE_SINGLE_INSPECTION_SUCCESS]:
        (state = initialState, action: Action<types.UPDATE_SINGLE_INSPECTION_SUCCESS>): EditInspectionState => {
            // Updating state when a single inspection's data is updated
            let index = -1;
            const inspectionId = action.payload.inspectionId;
            const inspectionsMap = Object.assign({}, state.inspectionsMap);
            const order = Object.assign({}, state.order);
            order.inspections.forEach((inspection: Inspection, i: number) => {
                if (inspection.inspection_id === inspectionId) {
                    index = i;
                }
            });

            if (index > -1) {
                order.inspections[index] = action.payload.data;

            }

            if ((inspectionsMap[inspectionId]) && !isInspectionMasterInspection(inspectionsMap[inspectionId])) {
                inspectionsMap[inspectionId] = action.payload.data;
            }
            return {
                ...state,
                inspectionsMap,
                order,
            };
        },
    [types.EDIT_INSPECTION_DATA_READY]: (state = initialState, action): EditInspectionState => {
        return {
            ...state,
            editInspectionDataReady: action.payload.ready,
            isCreatingOrder: false,
        };
    },
    [EDIT_INSPECTION_CREATED_NEW]: (state = initialState): EditInspectionState => {
        return {
            ...state,
            editInspectionDataReady: false,
            isCreatingOrder: true,
        };
    },
    [EDIT_INSPECTION_CREATED_NEW_CANCEL]: (state = initialState): EditInspectionState => {
        return {
            ...state,
            // editInspectionDataReady: false,
            isCreatingOrder: false,
        };
    },
    [EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL]: (state = initialState, action: Action<EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL>): EditInspectionState => {
        return {
            ...state,
            areAllInspectionsEqual: action.payload.areEqual,
        };
    },
    [SET_SAME_ON_ALL_INSPECTIONS]: (state = initialState, action: Action<SET_SAME_ON_ALL_INSPECTIONS>): EditInspectionState => {
        const isSame = action.payload.isSame;
        if (isSame) {
            return {
                ...state,
                isSameForAllInspections: true,
                isSameForAllInspectionsPermissions: action.payload.permissions,
                order: action.payload.order || state.order,
                inspectionsMap: action.payload.inspectionsMap || state.inspectionsMap,
            };
        }
        return {
            ...state,
            isSameForAllInspections: false,
            isSameForAllInspectionsPermissions: action.payload.permissions,
        };
    },
    [ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS]:
        (state = initialState, action: Action<ADD_INSPECTION_GET_DATA_OBJECT_SUCCESS>): EditInspectionState => {
            const order = Object.assign({}, state.order);
            const inspectionsMap = Object.assign({}, state.inspectionsMap);
            const changedInspections = Object.assign({}, state.changedInspections);
            const newInspectionId = action.payload.data.inspection_id;
            inspectionsMap[newInspectionId] = action.payload.data;
            order.inspections.push(action.payload.data);
            return {
                ...state,
                isFetching: false,
                order,
                isNew: false,
                newData: {
                    inspection: action.payload.data,
                },
                inspectionAdded: true,
                isAddingInspection: false,
                inspectionsMap,
                changedInspections,
            };
        },
    [ADD_INSPECTION_GET_DATA_OBJECT_CLEAR]:
        (state = initialState): EditInspectionState => {
            return {
                ...state,
                isFetching: false,
                isNew: false,
                inspectionAdded: false,
                isAddingInspection: false,
            };
        },
    [ADD_INSPECTION_GET_DATA_OBJECT_REQUEST]:
        (state = initialState): EditInspectionState => {
            return {
                ...state,
                isFetching: false,
                inspectionAdded: false,
                isAddingInspection: true,
            };
        },

    [EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS]:
        (state = initialState, action: Action<EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS>): EditInspectionState => {
            const changedInspections = action.payload.changedInspections;
            return {
                ...state,
                changedInspections,
            };
        },
    [types.DELETE_INSPECTION_SUCCESS]:
        (state = initialState, action: Action<DELETE_INSPECTION_SUCCESS>): EditInspectionState => {
            const order = { ...state.order };
            order.inspections = action.payload.data.inspections;
            return {
                ...state,
                order,
                inspectionsMap: action.payload.data.inspectionsMap,
                isSaving: action.payload.isSaving,
            };
        },

    [types.DELETE_INSPECTION_REQUEST]: (state = initialState, action: Action<types.DELETE_INSPECTION_REQUEST>): EditInspectionState => {
        return {
            ...state,
            isSaving: action.payload.isSaving,
        };
    },
    [types.DELETE_INSPECTION_FAILURE]: (state = initialState, action: Action<types.DELETE_INSPECTION_FAILURE>): EditInspectionState => {
        return {
            ...state,
            isSaving: action.payload.isSaving,
        };
    },
    [CALCULATE_SAMPLE_SIZE_SUCCESS]:
        (state = initialState, action: Action<CALCULATE_SAMPLE_SIZE_SUCCESS>): EditInspectionState => {
            const order = Object.assign({}, state.order);
            const isCombined = isOrderCombinedInspection(order);
            const inspectionId = action.payload.inspectionId;
            if (isCombined) {
                order.inspections[0].sample_quantity = action.payload.sample_size;
                return {
                    ...state,
                    order,
                };
            } else {
                const inspections = order.inspections;
                const inspectionsMap = Object.assign({}, state.inspectionsMap);
                inspectionsMap[inspectionId].sample_quantity = action.payload.sample_size;
                inspections.forEach((inspection: types.Inspection) => {
                    if (inspection.inspection_id === inspectionId) {
                        inspection.sample_quantity = action.payload.sample_size;
                    }
                });
                return {
                    ...state,
                    order,
                    inspectionsMap,
                };
            }

        },



    [types.CLEAR_ORDER_AND_INSPECTIONS_DATA]: (state = initialState): EditInspectionState => {
        return {
            ...state,
            order: undefined,
            inspectionsMap: {},
        };
    },
    [EDIT_INSPECTION_SET_FOCUSSED_INSPECTION]: (state = initialState, action: Action<EDIT_INSPECTION_SET_FOCUSSED_INSPECTION>): EditInspectionState => {
        return {
            ...state,
            focussedInspectionId: action.payload.inspectionId,
        };
    },
};

export default { map, initialState };
