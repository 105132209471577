import React, { Fragment, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { TextItem } from '../../../../../globals/components';
import { Inspection } from '../../../../types';

type SupplierInfoProps = {
    inspections: Inspection[];
};

const SupplierInfo = (props: SupplierInfoProps): ReactElement<any> => {
    const intl = useIntl();
    const { inspections } = props;
    const getPuInfo = () => {
        let pu = { name: undefined, address: undefined };
        if (inspections[0].linked_production_unit) {
            pu = { name: inspections[0].linked_production_unit.name || '-', address: inspections[0].linked_production_unit.address || '-' };
        }
        if (inspections[0].production_unit) {
            pu = { name: inspections[0].production_unit.name || '-', address: inspections[0].production_unit.address || '-' };
        }
        return pu;
    }
    if (inspections[0] && inspections[0].supplier_entity) {
        const pu = getPuInfo();
        if (inspections[0].linked_production_unit || inspections[0].production_unit) {
            return <Popup wide trigger={<span>{inspections[0].supplier_entity.name}</span>}>
                <Popup.Header><FormattedMessage id='inspections.production_unit_info.header' /></Popup.Header>
                <Popup.Content>
                    <TextItem labelFixed={true} label={intl.formatMessage({ id: 'inspections.production_unit_info.name' })} text={pu.name} />
                    <TextItem labelFixed={true} label={intl.formatMessage({ id: 'inspections.production_unit_info.address' })} text={pu.address} />
                </Popup.Content>
            </Popup>;
        }
        return <Fragment>{inspections[0].supplier_entity.name}</Fragment>;

    }
    return <Fragment>-</Fragment>;
};

export default SupplierInfo;
