/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 45.2.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicUserOptional,
    BasicUserOptionalFromJSON,
    BasicUserOptionalFromJSONTyped,
    BasicUserOptionalToJSON,
} from './';

/**
 * Describes the changing of a User.
 * @export
 * @interface EventUserChange
 */
export interface EventUserChange {
    /**
     * 
     * @type {BasicUserOptional}
     * @memberof EventUserChange
     */
    next_value: BasicUserOptional | null;
    /**
     * 
     * @type {BasicUserOptional}
     * @memberof EventUserChange
     */
    previous_value: BasicUserOptional | null;
}

export function EventUserChangeFromJSON(json: any): EventUserChange {
    return EventUserChangeFromJSONTyped(json, false);
}

export function EventUserChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventUserChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_value': BasicUserOptionalFromJSON(json['next_value']),
        'previous_value': BasicUserOptionalFromJSON(json['previous_value']),
    };
}

export function EventUserChangeToJSON(value?: EventUserChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_value': BasicUserOptionalToJSON(value.next_value),
        'previous_value': BasicUserOptionalToJSON(value.previous_value),
    };
}


