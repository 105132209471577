import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuditLink } from '../../../backend_api/models';
import { twMerge } from '../../../base/utils';

type Props = {
    className?: string;
    event?: Array<AuditLink>;
};

const AuditsAddedEvent = (props: Props): React.ReactElement => {
    const { className, event } = props;

    return <div className={twMerge('', className)}>
        <FormattedMessage id={'corrective_actions.events.audits_added'} />
        {event.map((link, i) => {
            const supplierName = link.supplier_name ? link.supplier_name : '-';
            const supplierNumber = link.supplier_number ? ' : ' + link.supplier_number : '';
            const supplier = link.supplier_name || link.supplier_number ? supplierName + supplierNumber : '-'
            const productionName = link.production_unit_name ? link.production_unit_name : '-';
            const productionNumber = link.production_unit_number ? ' : ' + link.production_unit_number : '';
            const productionUnit = link.production_unit_name || link.production_unit_number ? productionName + productionNumber : '-';
            return <div key={'audit_link_event_' + link.audit_id + i}>
                <Link className='link noUnderline space-x-1' to={'/audits/report/' + link.audit_id}>
                    {<span className='noUnderline text-secondary'>{supplier}</span>}{<span className='text-secondary noUnderline'>{productionUnit}</span>}
                </Link>
            </div>
        })}
    </div>
}
export default AuditsAddedEvent;
