import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../base/components/basic/Icon';
import { twMerge } from '../../../base/utils';

type Props = {
    className?: string;
    showclassName?: string;
    hideclassName?: string;
    label?: string;
    cnt?: number;
    isExpanded?: boolean;
    children?: ReactNode;
};

const ExpandItem = (props: Props): React.ReactElement => {
    const { className, cnt, isExpanded = false, children, showclassName, hideclassName } = props;
    const [expand, setExpand] = useState(isExpanded);

    return <div className={twMerge('flex w-full flex-col mt-8', className)}>
        {!expand && <div className={twMerge('flex  w-full items-center  justify-center space-x-2 link noUnderline', showclassName)} onClick={(): void => setExpand(!expand)}><div>
            {props.label ? props.label : <FormattedMessage id='component.expand.show_more' />}</div>{cnt !== null && <span className=''>({cnt})</span>}<Icon className='text-xl' name='expand_more' />
        </div>}
        {expand && <div className='flex w-full'>{children}</div>}
        {expand && <div className={twMerge('flex justify-center space-x-2 items-center link noUnderline', hideclassName)} onClick={(): void => setExpand(!expand)}>
            <div><FormattedMessage id='component.expand.hide' /></div><Icon  className='text-xl' name='expand_less' />
        </div>}
    </div>
}
export default ExpandItem;
