import React, { Fragment, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Modal } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { Search } from '../../search/components';
import { duplicateInspection } from '../actions/duplicateActions';
import DuplicateInspectionItem from '../components/dashboard/DuplicateInspectionItem';
import InspectionsList from '../components/dashboard/views/InspectionsList';
import { ListType } from '../slices/inspectionsListSlice';
import { Inspection } from '../types';

const intlMessages = defineMessages({
    addItemToCombinedInspection: {
        id: 'containers.add_item_to_combined_inspection',
    },
    addRepeatItem: {
        id: 'containers.add_repeat_item',
    },
    copyBasicInformation: {
        id: 'containers.copy_basic_information_from_previous_inspections_to_this_order_this_is_useful_for_adding_information_from_a_previous_inspection_of_the_same_item',
    },
    addExistingItem: {
        id: 'containers.add_an_existing_item_to_the_combined_inspection',
    },
    addItem: {
        id: 'containers.add_item',
    },
});
type DuplicateInspectionsProps = {
    orderId: string;
    disabled?: boolean;
}
type DuplicateInspectionItemComponentProps = {
    inspection: Inspection;
}
const DuplicateInspections = (props: DuplicateInspectionsProps): React.ReactElement => {
    const { disabled, orderId } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [showDuplicate, setShowDuplicate] = useState(false);
    const [showDuplicateItem, setShowDuplicateItem] = useState({});
    const [query, setQuery] = useState(undefined);
    const [addCombinedQuantity, setAddCombinedQuantity] = useState(false);
    const addRepeatItemTxt: string = intl.formatMessage(intlMessages.addRepeatItem);
    const copyBasicInformationTxt: string = intl.formatMessage(intlMessages.copyBasicInformation);
    const header = addRepeatItemTxt;
    const text = copyBasicInformationTxt;
    const buttonLabel = addRepeatItemTxt;

    const handleOpen = (): void => setShowDuplicate(true);
    const handleClose = (): void => setShowDuplicate(false);
    const changeAddCombinedQuantity = (): void => {
        setAddCombinedQuantity(!addCombinedQuantity);
    };
    const handleItem = (show: boolean, inspection: Inspection): void => {
        setShowDuplicateItem({ [inspection.inspection_id]: show });
    };
    const handleDuplicateItem = (inspection: Inspection, inspectionName: string): void => {
        setShowDuplicate(false);
        dispatch(duplicateInspection(inspection.inspection_id, orderId, inspectionName));
    };

    const DuplicateInspectionItemComponent = (props: DuplicateInspectionItemComponentProps): React.ReactElement => {
        return (
            <DuplicateInspectionItem
                inspection={props.inspection}
                handleItem={handleItem}
                handleDuplicateItem={handleDuplicateItem}
                showDuplicateItem={showDuplicateItem[props.inspection.inspection_id]}
                isCombinedInspection={false}
                changeAddCombinedQuantity={changeAddCombinedQuantity}
                addCombinedQuantity={addCombinedQuantity}
            />
        );
    };
    return (
        <Fragment>
            <Modal
                open={showDuplicate}
                mountNode={document.body.getElementsByClassName('editOrder')[0]}
                size='large'
                closeIcon={true}
                closeOnEscape={true}
                onClose={handleClose}
            >
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content className='flex space-y-8'>
                    <Search
                        type={ListType.DuplicateItems}
                        push={false}
                        handleQuery={(q): void => setQuery(q)} />
                    <Form>
                        <Form.Field width='8'>
                            {text}
                        </Form.Field>
                    </Form>
                    <InspectionsList
                        inspectionsComponent={DuplicateInspectionItemComponent}
                        listType={ListType.DuplicateItems}
                        pushWhenToggle={false}
                        showCombine={false}
                        params={{ search: query }}
                        loadContent={false}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}><FormattedMessage id='containers.close' /></Button>
                </Modal.Actions>
            </Modal>
            <Button primary disabled={disabled} onClick={handleOpen}>{buttonLabel}</Button>
        </Fragment>
    );
};

export default DuplicateInspections;
