/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentTemplateScopeProduct
 */
export interface DocumentTemplateScopeProduct {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateScopeProduct
     */
    type: DocumentTemplateScopeProductTypeEnum;
}


/**
 * @export
 */
export const DocumentTemplateScopeProductTypeEnum = {
    DocumentTemplateProductScope: 'document_template_product_scope'
} as const;
export type DocumentTemplateScopeProductTypeEnum = typeof DocumentTemplateScopeProductTypeEnum[keyof typeof DocumentTemplateScopeProductTypeEnum];


/**
 * Check if a given object implements the DocumentTemplateScopeProduct interface.
 */
export function instanceOfDocumentTemplateScopeProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DocumentTemplateScopeProductFromJSON(json: any): DocumentTemplateScopeProduct {
    return DocumentTemplateScopeProductFromJSONTyped(json, false);
}

export function DocumentTemplateScopeProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTemplateScopeProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

export function DocumentTemplateScopeProductToJSON(value?: DocumentTemplateScopeProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}

