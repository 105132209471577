import { handleActions } from 'redux-actions';
import { UserRoleBrief } from '../../backend_api/models/UserRoleBrief';
import { Action } from '../../base/genericActions';
import { CREATE_ROLE, GET_ROLES_LIST_SUCCESS, GET_ROLE_REQUEST_SUCCESS, UserRole } from './types';

export type RoleState = {
    userRoles: { [id: string]: UserRole };
    isCreatingRole: boolean;
    rolesList: UserRoleBrief[];
}

const initialState: RoleState = {
    userRoles: undefined,
    isCreatingRole: false,
    rolesList: [],
};

export const reducer = handleActions({
    [GET_ROLE_REQUEST_SUCCESS]: (state = initialState, action: Action<GET_ROLE_REQUEST_SUCCESS>) => {
        return {
            ...state,
            userRoles: {
                ...state.userRoles,
                [action.payload.userRole.id]: action.payload.userRole
            }
        };
    },
    [CREATE_ROLE]: (state = initialState, action: Action<CREATE_ROLE>) => {
        return {
            ...state,
            isCreatingRole: action.payload.isCreating
        };
    },
    [GET_ROLES_LIST_SUCCESS]: (state = initialState, action: Action<GET_ROLES_LIST_SUCCESS>) => {
        return {
            ...state,
            rolesList: action.payload
        };
    },
}, initialState);


export default reducer;
