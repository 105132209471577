import { Audit } from '../../backend_api/models';

export const getAuditDate = (audit: Audit): string => {
    let date;
    if (audit.status === 'report' && audit.end_time) {
        date = audit.end_time;
    }
    if (audit.status === 'ongoing' && audit.start_time) {
        date = audit.start_time;
    }
    if (audit.status === 'planned' && audit.planned_date) {
        date = audit.planned_date;
    }
    return date;
}