import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Divider, Icon } from 'semantic-ui-react';
import { ListUsersUser } from '../../../../../../backend_api/models';
import { BasicUser } from '../../../../../../backend_api/models/BasicUser';
import Input from '../../../../../../base/components/basic/Input';
import RemoveIcon from '../../../../../../base/components/basic/RemoveIcon';
import { useAppDispatch } from '../../../../../../store';
import { getFavoriteInspectors, setFavoriteInspector } from '../../../../actions/dashboard/weekViewActions';
import { getWeekViewFavoriteInspectors } from '../../../../slices/weekViewSlice';

type InspectorListProps = {
    inspectors: ListUsersUser[];
    toggleManually: boolean;
    setToggleManually(): void;
    close(): void;
    children?: React.ReactElement;
}

const InspectorList = (props: InspectorListProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl()
    const favoriteInspectors: string = useSelector(getWeekViewFavoriteInspectors)
    const [inspectors, updateInspectors] = useState(props.inspectors);
    useEffect(() => {
        dispatch(getFavoriteInspectors());
    }, [favoriteInspectors]);
    const filter = (e) => {
        const q: string = e.currentTarget.value.toLowerCase();
        const insp = props.inspectors.filter((insp) => insp.email.toLowerCase().includes(q) || insp.firstname.toLowerCase().includes(q) || insp.lastname.toLowerCase().includes(q));
        updateInspectors(insp);
    }
    return (
        <div className='relative pl-4 pt-4 border-r'>
            <div className='flex justify-between items-start mb-4'>
                <div><h3><FormattedMessage id='inspections.week_view.inspectors' /></h3></div>
                <RemoveIcon className='pr-2 text-secondary hover:text-primary cursor-pointer' onClick={() => props.close()}/>
               
            </div>
            <div className='border-b pb-4'>
                <FormattedMessage id='inspections.week_view.select_inspectors' />
                <Input type='text' className='w-64 p-2 mt-2' onChange={(e) => filter(e)} />
            </div>

            <div className=' w-96 py-2 bg-white flex-grow overflow-y-scroll overflow-x-hidden' style={{ height: window.screen.availHeight - 460 }}>
                {inspectors && inspectors.map((inspector: BasicUser) => {

                    return <div key={inspector.id} className='p-2 cursor-pointer hover:bg-gray-200' onClick={(): any => dispatch(setFavoriteInspector(inspector))} >
                        <Icon color={favoriteInspectors && favoriteInspectors.includes(inspector.id) ? 'yellow' : 'grey'} name='star' />
                        {inspector.firstname} {inspector.lastname}
                    </div>;
                })}
                <Divider hidden />
            </div>
            <div className='py-4 px-2 space-x-2 flex flex-col space-y-2'>
                <Checkbox onChange={props.setToggleManually} label={intl.formatMessage({ id: 'inspections.week_view.only_show_selected_inspectors' })} checked={props.toggleManually} />
                <div className='border-t p-2'>
                    {props.children}
                </div>

            </div>

        </div>
    );
}
export default InspectorList;