import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import { Button } from 'semantic-ui-react';
import { isMobileDevice } from '../../../base/utils';
import PageContainer from '../../globals/components/PageContainer';
const appleLogo = require('../../appInstallPage/assets/apple-logo.png');
const appleLogo2x = require('../../appInstallPage/assets/apple-logo@2x.png');
const appleLogo3x = require('../../appInstallPage/assets/apple-logo@3x.png');
const appleAppStore = require('../../appInstallPage/assets/apple-app-store.png');
const appleAppStore2x = require('../../appInstallPage/assets/apple-app-store@2x.png');
const appleAppStore3x = require('../../appInstallPage/assets/apple-app-store@3x.png');
const androidLogo = require('../../appInstallPage/assets/android-logo.png');
const androidLogo2x = require('../../appInstallPage/assets/android-logo@2x.png');
const androidLogo3x = require('../../appInstallPage/assets/android-logo@3x.png');
const googlePlay = require('../../appInstallPage/assets/google-play.png');
const googlePlay2x = require('../../appInstallPage/assets/google-play@2x.png');
const googlePlay3x = require('../../appInstallPage/assets/google-play@3x.png');

const huawei = require('../../appInstallPage/assets/huawei.png');
const huawei2x = require('../../appInstallPage/assets/huawei@2x.png');
const huawei3x = require('../../appInstallPage/assets/huawei@3x.png');
const xiaomi = require('../../appInstallPage/assets/xiaomi.png');
const xiaomi2x = require('../../appInstallPage/assets/xiaomi@2x.png');
const xiaomi3x = require('../../appInstallPage/assets/xiaomi@3x.png');
const oppo = require('../../appInstallPage/assets/oppo.png');
const oppo2x = require('../../appInstallPage/assets/oppo@2x.png');
const oppo3x = require('../../appInstallPage/assets/oppo@3x.png');
const vivo = require('../../appInstallPage/assets/vivo.png');
const vivo2x = require('../../appInstallPage/assets/vivo@2x.png');
const vivo3x = require('../../appInstallPage/assets/vivo@3x.png');
const baidu = require('../../appInstallPage/assets/baidu.png');
const baidu2x = require('../../appInstallPage/assets/baidu@2x.png');
const baidu3x = require('../../appInstallPage/assets/baidu@3x.png');


const AppInstallPage = () => {
    const intl = useIntl();
    return (
        <PageContainer headerClassName='' header={<div className='flex space-x-2 justify-center'>
                    <DocumentTitle title={intl.formatMessage({ id: 'globals.get_qarma_app' })} />
                    <div className='px-4 sm:px-0 text-3xl font-bold'><FormattedMessage id='globals.get_qarma_app'/></div>
                </div>}>
                <div className='flex flex-wrap justify-center items-stretch gap-8 my-8' >
                    <div className='flex flex-col bg-white rounded-md p-4 self-auto border gap-3' style={{width: '375px'}}>
                        <div className='flex flex-row mb-5 justify-between gap-3'>
                            <div className='flex flex-col'>
                                <div className='text-xl font-bold'><FormattedMessage id='app_install.iphone_and_ipad'/></div>
                                <div><FormattedMessage id='app_install.scan_to_install'/></div>
                            </div>
                            <img src={appleLogo} srcSet={`${appleLogo2x} 2x, ${appleLogo3x} 3x`}/>
                        </div>
                        <div className='flex flex-row justify-center items-center'>
                            {isMobileDevice() ?
                                <Button primary className='w-full mb-3' onClick={() => {
                                    window.location.href = "https://apps.apple.com/in/app/qarma-inspect/id1176875564"
                                }}><FormattedMessage id='app_install.install_now'/></Button>
                                :
                                <QRCode value={"https://apps.apple.com/in/app/qarma-inspect/id1176875564"} size={160}/>
                            }
                            
                        </div>
                        <div className='flex flex-row my-3 justify-center items-center'>
                            <img src={appleAppStore} srcSet={`${appleAppStore2x} 2x, ${appleAppStore3x} 3x`}/>
                            <span className='ml-2'><FormattedMessage id='app_install.available_on_app_store'/></span>
                        </div>
                    </div>
                    <div className='flex flex-col items-center bg-white rounded-md p-4 self-auto border gap-4' style={{width: '375px'}}>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-row mb-5 justify-between gap-3'>
                                <div className='flex flex-col'>
                                    <div className='text-xl font-bold'><FormattedMessage id='app_install.android_phone_and_tablets'/></div>
                                    <div><FormattedMessage id='app_install.scan_to_install'/></div>
                                </div>
                                <img className='self-start' src={androidLogo} srcSet={`${androidLogo2x} 2x, ${androidLogo3x} 3x`}/>
                            </div>
                            <div className='flex flex-row justify-center items-center'>
                                {isMobileDevice() ?
                                    <Button primary className='w-full mb-3' onClick={() => {
                                        window.location.href = document.location.origin + "/q/app/get_android_app"
                                    }}><FormattedMessage id='app_install.install_now'/></Button>
                                    :
                                    <QRCode value={document.location.origin + "/q/app/get_android_app"} size={160}/>
                                }
                            </div>
                            <div className='flex flex-row my-3 justify-center items-center hover:cursor-pointer'>
                                <img src={googlePlay} srcSet={`${googlePlay2x} 2x, ${googlePlay3x} 3x`}/>
                                <span className='ml-2'><FormattedMessage id='app_install.available_on_google_play'/></span>
                            </div>
                        </div>
                        <div className='border rounded-md p-4'>
                            <div className='mb-2'><FormattedMessage id='app_install.find_app_in_china'/></div>
                            <div className='flex flex-wrap gap-6 gap-y-3 items-stretch mb-3'>
                                <div className='flex flex-row justify-start items-center hover:cursor-pointer' style={{width: '80px'}}>
                                    <img src={huawei} srcSet={`${huawei2x} 2x, ${huawei3x} 3x`}/>
                                    <span className='p-1 text-secondary'>Huawei</span>
                                </div>
                                <div className='flex flex-row justify-start items-center hover:cursor-pointer' style={{width: '80px'}}>
                                    <img src={xiaomi} srcSet={`${xiaomi2x} 2x, ${xiaomi3x} 3x`}/>
                                    <span className='p-1 text-secondary'>Xiaomi</span>
                                </div>
                                <div className='flex flex-row justify-start items-center hover:cursor-pointer' style={{width: '80px'}}>
                                    <img src={oppo} srcSet={`${oppo2x} 2x, ${oppo3x} 3x`}/>
                                    <span className='p-1 text-secondary'>OPPO</span>
                                </div>
                                <div className='flex flex-row justify-start items-center hover:cursor-pointer' style={{width: '80px'}}>
                                    <img src={vivo} srcSet={`${vivo2x} 2x, ${vivo3x} 3x`}/>
                                    <span className='p-1 text-secondary'>vivo</span>
                                </div>
                                <div className='flex flex-row justify-start items-center hover:cursor-pointer' style={{width: '80px'}}>
                                    <img src={baidu} srcSet={`${baidu2x} 2x, ${baidu3x} 3x`}/>
                                    <span className='p-1 text-secondary'>Baidu</span>
                                </div>
                            </div>
                            <a href='https://app.qarmainspect.com/q/objects/files/109ffd7c-c7e6-41ab-8354-9b964f7a719f/602a7e79-1d44-4c2b-953e-190ce5104d68/Qarma-sideload-release.apk' className='text-brand hover:text-brand noUnderline'>
                                <FormattedMessage id='app_install.alternative_app_install'/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                    <a href=' https://support.qarmainspect.com/en/support/solutions/folders/43000550466' className='text-brand hover:text-brand noUnderline'>
                        <FormattedMessage id='app_install.guide'/>
                    </a>
                </div>
            </PageContainer>
    )
}

export default AppInstallPage;