import qs from 'query-string';
import { request } from '../../base/api';
import { FILTERS } from '../../base/config';
import { AppThunk, RequestError } from '../../base/types';
import { getLocationEntries } from '../../base/utils';
import history from '../../store/history';
import { CustomConclusion } from '../approval/types';
import errorHandling from '../errorHandling';
import { getCustomFieldFilterDataRequest, getCustomFieldFilterDataSuccess, getCustomFieldsRequest, getCustomFieldsSuccess, getFilterConclusionsSuccess, SET_FILTERS, SET_FILTERS_VISIBLE } from './types';
import { replaceFilterStringWithArray } from './util';

const catchException = errorHandling.handler.catchException;

export const setFilter = (name: string, value: any[], changeUrl = true, replace = false) => {
    return ((): void => {
        if (changeUrl) {
            const currentParams = qs.parse(history.location.search);
            if (value === null) {
                delete currentParams[name];
            } else {
                currentParams[name] = value;
            }
            const params = qs.stringify(currentParams);
            replace ? history.replace('?' + params) : history.push('?' + params);
        }
    });
}

export const loadFilters = () => {
    return ((dispatch): any => {
        const filters = replaceFilterStringWithArray(qs.parse(history.location.search));
        dispatch({ type: SET_FILTERS, payload: { filters } });
        return filters;
    });
};

export const setFiltersVisible = (visible: boolean) => {
    return async (dispath): Promise<void> => {
        dispath({ type: SET_FILTERS_VISIBLE, payload: { visible } });
    };
};

export const getFilterConclusions = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const url = 'filter_conclusions';
        return request(url, {})
            .then((data: CustomConclusion[]) => {
                dispatch(getFilterConclusionsSuccess(data))
            })
            .catch((error: RequestError) => {
                catchException('getFileterConclusions', {
                    endpoint: 'filter_conclusions',
                    request: url,
                    status: error.status,
                }, { error });
            });
    };
}

export const clearAllFilters = (changeUrl = true, paramsAfterClear?: any) => {
    return ((dispatch): void => {
        const allowedFilters = FILTERS;
        const filters = getLocationEntries(history.location);
        const params = qs.parse(history.location.search, { arrayFormat: 'bracket' });
        Object.keys(filters).forEach((filterName) => {
            if (allowedFilters.includes(filterName)) {
                delete filters[filterName];
                delete params[filterName];
            }
        });
        if (paramsAfterClear) {
            Object.keys(paramsAfterClear).forEach((key: string) => {
                params[key] = paramsAfterClear[key];
            });
        }
        dispatch({ type: SET_FILTERS, payload: { filters } });
        if (changeUrl) {
            history.push('?' + qs.stringify(params));
        }
    });
};

export const getCustomFieldsData = (ids: string[]): AppThunk => {
    return (async (dispatch): Promise<void> => {
        dispatch(getCustomFieldFilterDataRequest());
        const date = getLocationEntries(history.location).date;
        let url = 'fetch_custom_field_values';
        if (date) {
            url += '?date=' + date;
        }
        return request(url,
            {
                method: 'post',
                body: JSON.stringify({ custom_field_definition_ids: ids }),
            })
            .then((data) => {
                dispatch(getCustomFieldFilterDataSuccess(ids, data));
            }).catch((error) => {
                catchException('getCustomFieldsData', {
                    endpoint: 'cfvs',
                    request: 'cfvs',
                    status: error.status || '',
                }, { error });
            });
    });
}
export const getCustomFields = (): AppThunk => {
    return async (dispatch): Promise<void | any> => {
        dispatch(getCustomFieldsRequest());
        return request('custom_fields',
            {})
            .then((data) => {
                dispatch(getCustomFieldsSuccess(data));
                return data;
            }).catch((error) => {
                catchException('getCustomFields', {
                    endpoint: '/custom_fields',
                    request: '/custom_fields',
                    status: error.status || '',
                }, { error });
            });
    };
}
