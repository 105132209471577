import React, { ChangeEvent, Component, SyntheticEvent } from 'react';
import DocumentTitle from 'react-document-title';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { DetailedSupplier } from '../../../backend_api/models/DetailedSupplier';
import { SupplierUser } from '../../../backend_api/models/SupplierUser';
import { UserRoleBrief } from '../../../backend_api/models/UserRoleBrief';
import { AppState } from '../../../base/types';
import { Locale } from '../../globals';
import { getLocales } from '../../globals/selectors';
import { getRolesSelector } from '../../users/selectors';
import { addProductionUnitToSupplier, deleteSupplier, deleteSupplierRequest, getProductionUnits, getSupplierData, goToSuppliersList, updateProductionUnit, updateSupplierData } from '../actions';
import { SupplierList } from '../components';
import { getConfirmDeleteSupplierSelector, getProductionUnitByIdSelector, getProductionUnitsSelector, getSupplierDataSelector, isSupplierDataFetchingSelector } from '../selectors';
import { ProductionUnit as ProductionUnitType } from '../types';
import CreateSupplier from './CreateSupplier';

type OwnProps = {
    supplierId?: string;
    productionUnitId?: string;
    action: 'edit' | 'list' | 'productionUnit' | 'create';
    auditId?: string;
};

type StateProps = {
    supplierData: DetailedSupplier;
    productionUnits: ProductionUnitType[];
    productionUnit: ProductionUnitType;
    roles: UserRoleBrief[];
    locales: Locale[];
    isFetching: boolean;
    confirmDeleteSupplier: { confirm: boolean; supplierId?: string };
};

type DispatchProps = {
    actions: {
        getSupplierData: typeof getSupplierData;
        updateSupplierData: typeof updateSupplierData;
        getProductionUnits: typeof getProductionUnits;
        updateProductionUnit: typeof updateProductionUnit;
        deleteSupplier: typeof deleteSupplier;
        deleteSupplierRequest: typeof deleteSupplierRequest;
        goToSuppliersList: typeof goToSuppliersList;
        addProductionUnitToSupplier: typeof addProductionUnitToSupplier;
    };
};

type State = {
    supplierData: DetailedSupplier;
    productionUnit: ProductionUnitType;
    dirty: boolean;
    showAddSupplier: boolean;
};

const initialState: State = {
    supplierData: undefined,
    productionUnit: undefined,
    dirty: false,
    showAddSupplier: false
};
type SupplierProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps & RouteComponentProps<any>;

class SuppliersContainer extends Component<SupplierProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleEditUpdate = this.handleEditUpdate.bind(this);
        this.reloadSupplierData = this.reloadSupplierData.bind(this);
    }

    render(): React.ReactElement {
        if (this.props.action === 'create') {
            return <CreateSupplier show={true} />;
        } else {
            return <div className='pageContainer'>
                <DocumentTitle title={this.props.intl.formatMessage({ id: 'page_title.suppliers' })} />
                <Switch>
                    {/* <Route path='/audit/edit_pu/:supplierId/:productionUnitId/:auditId' render={(): React.ReactElement => {
                        return <ProductionUnit
                            productionUnit={this.props.productionUnit}
                            handleChange={this.handleEditChange}
                            handleEditUpdate={this.handleEditUpdate}
                            supplier={this.props.supplierData}
                            isDirty={this.state.dirty}
                            auditId={this.props.auditId}
                        />;
                    }} /> */}
                    {/* <Route path='/suppliers/:supplierId/:productionUnitId' render={(): React.ReactElement => {
                        return <ProductionUnit
                            productionUnit={this.props.productionUnit}
                            handleChange={this.handleEditChange}
                            handleEditUpdate={this.handleEditUpdate}
                            supplier={this.props.supplierData}
                            isDirty={this.state.dirty}
                        />;
                    }} /> */}
                    {/* <Route path='/suppliers/:supplierId' render={(): React.ReactElement => {
                        const editSupplier =
                            <EditSupplier
                                onProductionUnitIdAdded={(id) => this.onProductionUnitIdAddedToSupplier(id)}
                                supplierData={this.state.supplierData}
                                handleChange={this.handleEditChange}
                                handleEditUpdate={this.handleEditUpdate}
                                reloadSupplierData={this.reloadSupplierData}
                                isDirty={this.state.dirty}
                                roles={this.props.roles}
                                locales={this.props.locales}
                                isFetching={this.props.isFetching}
                                productionUnits={this.props.productionUnits}
                            />;
                        return editSupplier;
                    }} /> */}
                    <Route path='/suppliers' render={(): React.ReactElement => {
                        const suppliersList = <SupplierList
                            deleteSupplierRequest={this.props.actions.deleteSupplierRequest}
                        />;
                        return suppliersList;
                    }} />
                </Switch>
            </div>;
        }
    }

    componentDidMount(): void {
        this.getAction();
    }

    componentDidUpdate(prevProps: SupplierProps): void {
        if (this.props.action !== prevProps.action) {
            this.getAction();
        }
        if (this.props.action === 'edit' && (!prevProps.supplierData && this.props.supplierData) || (prevProps.supplierData && this.props.supplierData && this.state.supplierData === undefined)) {
            this.setState({ supplierData: this.props.supplierData });
        }
        if (this.props.action === 'edit' && (prevProps.supplierData && this.props.supplierData && prevProps.supplierData.users.length !== this.props.supplierData.users.length)) {
            this.setState({ supplierData: this.props.supplierData });
        }
        if (this.props.action === 'productionUnit' && !prevProps.productionUnit && this.props.productionUnit) {
            this.setState({ productionUnit: this.props.productionUnit });
        }
    }

    onProductionUnitIdAddedToSupplier(productionUnitId: string) {
        this.props.actions.addProductionUnitToSupplier(this.props.supplierId, productionUnitId)
        this.reloadSupplierData()
    }

    getAction(): void {
        if (this.props.action === 'list') {
            if (this.props.history.action === 'POP') {
                this.setState({ supplierData: undefined });
            }
        }
        if (this.props.action === 'edit') {
            this.setState({ productionUnit: undefined }, () => {
                this.props.actions.getSupplierData(this.props.supplierId);
            });
        }
        if (this.props.action === 'productionUnit') {
            this.props.actions.getSupplierData(this.props.supplierId);
        }
    }

    handleEditChange(evt: ChangeEvent | SyntheticEvent, item: { name?: string; value: string; checked?: boolean }, callback?): void {
        if (this.props.action === 'edit') {
            const supplierData: DetailedSupplier = Object.assign({}, this.state.supplierData);
            if (item.name === 'user') {
                supplierData.users.forEach((user: SupplierUser) => {
                    if (item.value === user.email) {
                        user.contact = item.checked;
                    }
                });
            }
            supplierData[item.name] = item.value;
            this.setState({ supplierData, dirty: true }, () => {
                if (callback) {
                    callback.apply();
                }
            });
        }
        if (this.props.action === 'productionUnit') {
            const productionUnit = Object.assign({}, this.state.productionUnit);
            productionUnit[item.name] = item.value;
            this.setState({ productionUnit, dirty: true }, () => {
                if (callback) {
                    callback.apply();
                }
            });
        }
    }

    handleEditUpdate(): void {
        if (this.props.action === 'edit') {
            this.props.actions.updateSupplierData(this.props.supplierId, this.state.supplierData);
        }
        if (this.props.action === 'productionUnit') {
            this.props.actions.updateProductionUnit(this.props.supplierId, this.props.productionUnitId, this.state.productionUnit);
        }
        this.setState({ dirty: false });
    }
    reloadSupplierData(): void {
        this.props.actions.getSupplierData(this.props.supplierId);
    }
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        supplierData: getSupplierDataSelector(state, ownProps.supplierId),
        roles: getRolesSelector(state),
        locales: getLocales(state),
        isFetching: isSupplierDataFetchingSelector(state),
        productionUnits: getProductionUnitsSelector(state, ownProps.supplierId),
        productionUnit: getProductionUnitByIdSelector(state, ownProps.supplierId, ownProps.productionUnitId),
        confirmDeleteSupplier: getConfirmDeleteSupplierSelector(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        getSupplierData,
        updateSupplierData,
        getProductionUnits,
        updateProductionUnit,
        deleteSupplierRequest,
        deleteSupplier,
        goToSuppliersList,
        addProductionUnitToSupplier,
    }, dispatch);
    return { actions };
};

export default injectIntl(withRouter(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(SuppliersContainer)));
