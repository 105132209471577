import React, { useEffect, useState } from 'react';
// import { DisplayCustomField, DisplayCustomFieldDateValue, DisplayCustomFieldGroup, DisplayCustomFieldMultipleChoiceValue, DisplayCustomFieldNumberValue, DisplayCustomFieldTextValue, InputCustomFieldDateValueTypeEnum, InputCustomFieldMultipleChoiceValueTypeEnum, InputCustomFieldNumberValueTypeEnum, InputCustomFieldTextValueTypeEnum, InputCustomFieldValue, TextWithTranslation } from '../../../backend_api/models';
import { DisplayCustomFieldGroup, InputCustomFieldValue } from '../../../backend_api/models';
import { objectHasKeys, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setSupplierCustomFieldValue } from '../../suppliers/slice/suppliersSlice';
import { setProductionUnitCustomFieldValue } from '../slice/productionUnitsSlice';
import DetailedProductionUnitCustomFieldGroup from './DetailedProductionUnitCustomFieldGroup';

type Props = {
    className?: string;
    productionUnitId: string;
    type: 'productionUnit' | 'supplier';
    customFieldGroups: DisplayCustomFieldGroup[];
    allowedToEdit: boolean;
};
const DetailedProductionUnitCustomFields = (props: Props): React.ReactElement => {
    const { className, productionUnitId, type } = props;
    const dispatch = useAppDispatch();
    const [customFieldGroups, setCustomFieldGroups] = useState<DisplayCustomFieldGroup[]>(props.customFieldGroups);
    const [okToSaveId, setOkToSaveId] = useState('');
    const handleSave = (customFieldDefinitionId, value: InputCustomFieldValue, byPass?: boolean) => {
        if (okToSaveId === customFieldDefinitionId || byPass) {
            dispatch(
                type === 'productionUnit'
                    ? setProductionUnitCustomFieldValue({ productionUnitId, customFieldDefinitionId, cf: value })
                    : setSupplierCustomFieldValue({ supplierId: productionUnitId, customFieldDefinitionId, cf: value })
            );
            setOkToSaveId('');
        }
    }
    useEffect(() => {
        setCustomFieldGroups(props.customFieldGroups);
    }, [props.customFieldGroups])

    return <div className={twMerge('', className)}>
        {(objectHasKeys(customFieldGroups)) && customFieldGroups.map((group) => {
            return <DetailedProductionUnitCustomFieldGroup
                allowedToEdit={props.allowedToEdit}
                handleSave={handleSave}
                setOkToSaveId={setOkToSaveId}
                customFieldGroup={group}
                key={'cf_' + group.id} />
        })}
    </div>
}
export default DetailedProductionUnitCustomFields;
