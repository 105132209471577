import classnames from 'classnames';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { setContext } from '../../../base/baseSlice';
import TabsMenu from '../../../base/components/TabsMenu';
import { Breakpoints } from '../../../base/config';
import { getLayoutSelector } from '../../../base/selectors';
import { Context } from '../../../base/types';
import { getLocationEntry, useMaxWidthBreak, useMinWidthBreak } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { DateFilter } from '../../filters/components';
import AddFilters from '../../filters/components/AddFilters';
import FiltersListNew from '../../filters/components/FiltersListNew';
import PageContainer from '../../globals/components/PageContainer';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { getAuditsListMetaDataSelector, setAuditListDate } from '../slices/auditSlice';
import AuditList from './AuditList';
import CreateAuditModal from './CreateAuditModal';

const Audits = (): React.ReactElement => {
    const location = useLocation();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const status = getLocationEntry(location, 'status')[0] || 'planned';
    const formatMessage = intl.formatMessage;
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    const isMaxLG = useMaxWidthBreak(Breakpoints.LG);
    const isMobileMenuOpen = useSelector(getLayoutSelector).mobileMenuOpen;
    const label = <div><Icon name='plus' size='small' />{isMinMD && formatMessage({ id: 'audits.globals.create_new' })}</div>;
    const filters = [
        'assigned-user-filter',
        'supplier-filter',
        'production-unit-filter',
        'audit-type-filter',
        'audit-conclusion-filter',
        'ca-state-filter',
    ];
    if (isMaxLG) {
        filters.push('date-filter');
    }
    const createAudit = <HasFeatureAccess feature='u_create_audits'>
        <CreateAuditModal label={label} buttonClass='flex items-center cursor-pointer p-3 border-l border-default sm:border-0 rounded-none sm:py-3 sm:px-6 text-white hover:text-white sm:rounded-lg bg-surface-secondary' />
    </HasFeatureAccess>;
    useEffect(() => {
        dispatch(setContext({ context: Context.Audits }))
    }, []);
    return <PageContainer header={<FormattedMessage id='audits.list.header' />}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.audits' }, { status: intl.formatMessage({ id: 'page_title.list_view_' + status }) })} />
        <div className={classnames('flex flex-col md:items-center md:flex-row md:space-x-4 md:justify-between md:mb-8 top-0 md:relative', { 'sticky': !isMobileMenuOpen })}>
            <div className='flex flex-col md:flex-row md:space-x-4 md:items-center '>
                <div className='flex'>
                    <TabsMenu
                        active={status}
                        path='audits'
                        items={[
                            { labelId: 'tabs.item.planned', query: { status: ['planned', 'downloaded'] }, id: 'planned' },
                            { labelId: 'tabs.item.ongoing', query: { status: ['ongoing'] }, id: 'ongoing' },
                            { labelId: 'tabs.item.reports', query: { status: 'report' }, id: 'report' },
                        ]} />
                    {(isMaxMD) && <div>{createAudit}</div>}
                </div>
                {isMinMD && <DateFilter onChange={setAuditListDate} />}
                {/* <SelectedFilters hideDate={isMinLG} showCompact={true} hideEmpty={false} /> */}
                {!isMaxMD && <AddFilters />}
            </div>
            {isMaxMD && <div className='p-2 justify-center w-full flex'><AddFilters /></div>}
            {(isMinMD) && createAudit}
        </div>
        {/* <SelectedFiltersNew context={Context.Audits}/> */}
        <FiltersListNew
            dateFilterChange={setAuditListDate}
            getFilterMetaData={getAuditsListMetaDataSelector}
            filters={filters as any}
            context='audit_list'
        />
        <AuditList />
    </PageContainer>
}
export default Audits;
