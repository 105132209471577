import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import { GET_LOCALES_REQUEST } from '../users/actions/actionTypes';
import { FETCHING_DATA, SAVING_DATA, SAVING_DATA_DONE, SET_LOCALE } from './actionTypes';
import { FetchingData } from './model';

const defaultLocale = {
    name: 'English',
    language: 'en',
    id: '',
    country: 'US',
};

const initialStateLocale = {
    locale: {
        locales: [],
        selected: defaultLocale,
        default: defaultLocale,
    },
};
const initialStateFetching: FetchingData = {
    isFetching: false,
};
const initialStateAttachments = {
    attachments: {
        byId: {},
    },
};

/**
 * Global reducer
 */
export const reducer = handleActions({
    [SET_LOCALE]: (state: typeof initialStateLocale = initialStateLocale, action: Action<SET_LOCALE>) => {
        const loc = state.locale;
        return Object.assign({}, state, {
            locale: {
                locales: loc.locales,
                default: loc.default,
                selected: action.payload.locale,
            },
        });
    },
    [GET_LOCALES_REQUEST]: (state: typeof initialStateLocale = initialStateLocale, action: Action<GET_LOCALES_REQUEST>) => {
        return Object.assign({}, state, {
            locale: {
                locales: action.payload.locales,
                selected: state.locale.selected,
                default: state.locale.default,
            },
        });
    },

    [FETCHING_DATA]: (state: FetchingData = initialStateFetching, action: Action<FETCHING_DATA>) => {
        return Object.assign({}, state, {
            isFetching: action.payload.isFetching,
        });
    },
    [SAVING_DATA]: (state: { savingDone?: boolean } = {}) => {
        return Object.assign({}, state, {
            savingDone: false,
        });
    },
    [SAVING_DATA_DONE]: (state: { savingDone?: boolean } = {}) => {
        return Object.assign({}, state, {
            savingDone: true,
        });
    },
}, Object.assign(initialStateLocale, initialStateFetching, initialStateAttachments));

export default reducer;
