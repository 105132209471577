/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Add a comment to an entity.
 *
 */
export type AddCommentBody = {
    entity_id: string;
    entity_type: AddCommentBody.entity_type;
    text: string;
};

export namespace AddCommentBody {

    export enum entity_type {
        SUPPLIER = 'supplier',
        PRODUCTION_UNIT = 'production_unit',
    }


}

