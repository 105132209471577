import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { InspectionsExportAdditionalObjects, InspectionStatus } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import { getProfile } from '../../profile/selectors';
import { exportInspections } from '../statisticsSlice';
import DataExportInput from './DataExportInput';

type Props = {
    email?: string
}

const DataExportInspection = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const profile = useSelector(getProfile);

    const getStatusOptions = (): DropdownItemProps[] => {
        const options: DropdownItemProps[] = []
        for (const status in InspectionStatus) {
            if (InspectionStatus[status] != InspectionStatus.Deprecated) {
                if (InspectionStatus[status] == InspectionStatus.Ongoing) {
                    options.push({
                        value: InspectionStatus[status],
                        key: InspectionStatus[status],
                        text: 'In Progress'
                    })
                }
                else {
                    options.push({
                        value: InspectionStatus[status],
                        key: InspectionStatus[status],
                        text: status
                    })
                }
            }
        }
        return options
    }

    const getExtraOption = (): DropdownItemProps[] => {
        const options = []
        for (const object in InspectionsExportAdditionalObjects) {
            options.push({
                value: InspectionsExportAdditionalObjects[object],
                key: InspectionsExportAdditionalObjects[object],
                text: object
            })
        }
        return options
    }

    const exportStats = (inputParams): void => {
        const params = {
            ...inputParams,
            language: profile.locale.language,
            notify_to_email: getEmail(),
        }
        dispatch(exportInspections(params))
    }

    const getEmail = () => {
        return props.email ? props.email : profile.email
    }

    return <DataExportInput
        statusOptions={getStatusOptions()}
        extraOptions={getExtraOption()}
        exportInProgressText={intl.formatMessage({ id: 'statistics.inspections_exporting' }, { email: getEmail() })}
        export={exportStats}
    />;
}
export default DataExportInspection;