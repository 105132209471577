import { createAction } from 'typesafe-actions';
import { DefectTypeOverview } from '../../backend_api/models';

// Get inspection status by type

export const GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST = 'GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST';
export type GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST = {
    fromDate: string;
    toDate: string;
};
export const GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS = 'GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS';
export type GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS = {
    fromDate: string;
    toDate: string;
    data: any;
};

export const getInspectionsStatusByTypeRequest = createAction(GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST, (() => {
    return { type: GET_INSPECTIONS_BY_TYPE_STATUS_REQUEST };
}));

export const getInspectionsStatusByTypeSuccess = createAction(GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS, ((data) => {
    return { type: GET_INSPECTIONS_BY_TYPE_STATUS_SUCCESS, payload: { data } };
}));

// Get inspection status by conclusion

export const GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST = 'GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST';
export type GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST = '';

export const GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS = 'GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS';
export type GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS = {
    data: any;
};
export const GET_INSPECTIONS_BY_CONCLUSION_STATUS_FAILURE = 'GET_INSPECTIONS_BY_CONCLUSION_STATUS_FAILURE';
export type GET_INSPECTIONS_BY_CONCLUSION_STATUS_FAILURE = {
    error: any;
};

export const getInspectionsStatusByConclusionRequest = createAction(GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST, (() => {
    return { type: GET_INSPECTIONS_BY_CONCLUSION_STATUS_REQUEST, payload: {} };
}));
export const getInspectionsStatusByConclusionSuccess = createAction(GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS, ((data) => {
    return { type: GET_INSPECTIONS_BY_CONCLUSION_STATUS_SUCCESS, payload: { data } };
}));
export const getInspectionsStatusByConclusionFailure = createAction(GET_INSPECTIONS_BY_CONCLUSION_STATUS_FAILURE, ((error) => {
    return { type: GET_INSPECTIONS_BY_CONCLUSION_STATUS_FAILURE, payload: { error } };
}));

export const GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST = 'GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST';
export type GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST = '';

export const GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS = 'GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS';
export type GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS = {
    data: any;
};
export const GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_FAILURE = 'GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_FAILURE';
export type GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_FAILURE = {
    error: any;
};

export const getInspectionsStatusBySupplierQCRequest = createAction(GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST, (() => {
    return { type: GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_REQUEST, payload: {} };
}));
export const getInspectionsStatusBySupplierQCSuccess = createAction(GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS, ((data) => {
    return { type: GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_SUCCESS, payload: { data } };
}));
export const getInspectionsStatusBySupplierQCFailure = createAction(GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_FAILURE, ((error) => {
    return { type: GET_INSPECTIONS_BY_SUPPLIER_QC_STATUS_FAILURE, payload: { error } };
}));

// Get defect status by severity

export const GET_DEFECTS_STATUS_BY_SEVERITY_REQUEST = 'GET_DEFECTS_STATUS_BY_SEVERITY_REQUEST';

export const GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS = 'GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS';
export type GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS = {
    data: any;
};

export const GET_DEFECTS_STATUS_BY_SEVERITY_FAILURE = 'GET_DEFECTS_STATUS_BY_SEVERITY_FAILURE';
export type GET_DEFECTS_STATUS_BY_SEVERITY_FAILURE = {
    error: any;
};

export const getDefectsStatusBySeverityRequest = createAction(GET_DEFECTS_STATUS_BY_SEVERITY_REQUEST, (() => {
    return { type: GET_DEFECTS_STATUS_BY_SEVERITY_REQUEST, payload: {} };
}));
export const getDefectsStatusBySeveritySuccess = createAction(GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS, ((data) => {
    return { type: GET_DEFECTS_STATUS_BY_SEVERITY_SUCCESS, payload: { data } };
}));
export const getDefectsStatusBySeverityFailure = createAction(GET_DEFECTS_STATUS_BY_SEVERITY_FAILURE, ((error) => {
    return { type: GET_DEFECTS_STATUS_BY_SEVERITY_FAILURE, payload: { error } };
}));

export const GET_LATEST_INSPECTIONS_REQUEST = 'GET_LATEST_INSPECTIONS_REQUEST';
export type GET_LATEST_INSPECTIONS_REQUEST = {
    data: any;
};

export const GET_LATEST_INSPECTIONS_SUCCESS = 'GET_LATEST_INSPECTIONS_SUCCESS';
export type GET_LATEST_INSPECTIONS_SUCCESS = {
    data: any;
};


export const LatestInspections = 'LatestInspections';
export type LatestInspections = {
    inspections: any[];
};

export type DashboardState = {
    inspectionsStatusByType: undefined;
    inspectionsStatusByConclusion: undefined;
    inspectionsStatusBySupplierQC: undefined;
    defectsStatusBySeverity: undefined;
    defectTypeOverview: DefectTypeOverview;
    isLoading: boolean;
    isFetching: {
        inspectionBars: boolean;
        inspectionPies: boolean;
        inspectionQCPies: boolean;
    };
};

export type InspectionsStatusByTypeBackend = {
    result: any;
    summary: any;
};
