/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetailedProductRequiredDocumentRequiredOfSupplier
 */
export interface DetailedProductRequiredDocumentRequiredOfSupplier {
    /**
     * 
     * @type {string}
     * @memberof DetailedProductRequiredDocumentRequiredOfSupplier
     */
    supplier_id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedProductRequiredDocumentRequiredOfSupplier
     */
    supplier_type: DetailedProductRequiredDocumentRequiredOfSupplierSupplierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedProductRequiredDocumentRequiredOfSupplier
     */
    type: DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum;
}


/**
 * @export
 */
export const DetailedProductRequiredDocumentRequiredOfSupplierSupplierTypeEnum = {
    Supplier: 'supplier',
    ProductionUnit: 'production_unit'
} as const;
export type DetailedProductRequiredDocumentRequiredOfSupplierSupplierTypeEnum = typeof DetailedProductRequiredDocumentRequiredOfSupplierSupplierTypeEnum[keyof typeof DetailedProductRequiredDocumentRequiredOfSupplierSupplierTypeEnum];

/**
 * @export
 */
export const DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum = {
    RequiredOfSupplier: 'required_of_supplier'
} as const;
export type DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum = typeof DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum[keyof typeof DetailedProductRequiredDocumentRequiredOfSupplierTypeEnum];


/**
 * Check if a given object implements the DetailedProductRequiredDocumentRequiredOfSupplier interface.
 */
export function instanceOfDetailedProductRequiredDocumentRequiredOfSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supplier_id" in value;
    isInstance = isInstance && "supplier_type" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DetailedProductRequiredDocumentRequiredOfSupplierFromJSON(json: any): DetailedProductRequiredDocumentRequiredOfSupplier {
    return DetailedProductRequiredDocumentRequiredOfSupplierFromJSONTyped(json, false);
}

export function DetailedProductRequiredDocumentRequiredOfSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedProductRequiredDocumentRequiredOfSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supplier_id': json['supplier_id'],
        'supplier_type': json['supplier_type'],
        'type': json['type'],
    };
}

export function DetailedProductRequiredDocumentRequiredOfSupplierToJSON(value?: DetailedProductRequiredDocumentRequiredOfSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplier_id': value.supplier_id,
        'supplier_type': value.supplier_type,
        'type': value.type,
    };
}

