import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { AuditApprovalStep, AuditFeaturesEnum } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Label from '../../../base/components/basic/Label';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import TranslateComment from '../../../base/components/comments/TranslateComment';
import { isUndefinedOrNull, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import AuditChangeConclusionDialog from '../../audit/components/AuditChangeConclusionDialog';
import AuditConclusion from '../../audit/components/AuditConclusion';
import { getAuditByIdSelector, getAuditCustomConclusionsByIdSelector, getAuditReportSelector } from '../../audit/selectors/auditSelectors';
import { UserInitialsText } from '../../users/components/Widgets';
import ReportApprovalItemShared from './ReportApprovalItemShared';

type Props = {
    className?: string;
    disabled?: boolean;
    showHeading?: boolean;
    edit?: boolean;
    editUserSelector?: ReactNode;
    handleRemoveApprover(): void;
    approvalStep: AuditApprovalStep;
    type?: 'planned' | 'report';
    handleChangeConclusion?(conclusionId: string, comment: string);

};

const AuditApprovalItem = (props: Props): React.ReactElement => {
    const { handleRemoveApprover, approvalStep, disabled, type = 'planned', handleChangeConclusion, edit } = props;
    const isPlanned = type === 'planned';
    const user = approvalStep.approver;
    const conclusionsById = useSelector(getAuditCustomConclusionsByIdSelector);
    const audit = isPlanned ? useSelector(getAuditByIdSelector) : (useSelector(getAuditReportSelector).audit);
    const hasConclusions = true;
    const c = conclusionsById && conclusionsById[approvalStep.conclusion_id] && conclusionsById[approvalStep.conclusion_id][0];
    const canRemove = disabled ? false : edit;
    const canChangeConclusion = audit.features.includes(AuditFeaturesEnum.SetFinalConclusion) && (audit.status === 'report' || audit.status === 'ongoing');

    const handleUpdate = (conclusionId: string, comment: string) => {
        handleChangeConclusion(conclusionId, comment);
    }

    const remove = (e) => {
        preventDefaultAndStopPropagation(e);
        props.handleRemoveApprover();
    }
    return (<div className='flex items-center'>
        {!isPlanned && <>
            <ReportApprovalItemShared
                heading={props.showHeading ? <FormattedMessage id='audits.approval_flow.approver' /> : null}
                user={approvalStep.approver}
                editUserSelector={props.editUserSelector}
                status={edit ? <DeleteConfirm disabled={!canRemove} deleteFunction={remove} trigger={
                    <div><Button disabled={!canRemove} className={twMerge('text-xs px-2 py-1')} ><FormattedMessage id='audits.approval_flow.delete' /></Button></div>} /> : <AuditChangeConclusionDialog
                    commentMandatory
                    disabled={!hasConclusions || !canChangeConclusion}
                    handleUpdate={handleUpdate} audit={audit}
                    trigger={<span>
                        <AuditConclusion
                            notDone={approvalStep.conclusion_id === 'not_set' || !c}
                            approvalConclusion={c}
                            type='final'
                            audit={audit}
                            className='px-3 py-2 text-sm'
                            handleRemove={remove}
                            canRemove={canRemove}
                            disabled={!canChangeConclusion}
                        />
                    </span>} />}
                comment={!edit && <TranslateComment
                    comment={!isUndefinedOrNull(approvalStep.comment) && approvalStep.comment}
                />}
                showCommentIcon={!edit}
            />
        </>}
        {isPlanned && <Popup
            position='top center'
            trigger={<span><Label disabled={user.disabled} className='flex items-center ml-0'>
                <span className='flex items-center space-x-2'>
                    <span className={twMerge(user.disabled && 'line-through')}>{user.firstname} {user.lastname}</span>
                    <RemoveIcon className={twMerge(canRemove ? 'cursor-pointer' : 'text-disabled')} onClick={(): void => canRemove ? handleRemoveApprover() : null} />
                </span>
            </Label></span>}>
            {user.disabled && <div className='font-bold'><FormattedMessage id='watchers.user_is_disabled' /></div>}
            <div>{<UserInitialsText user={user} />}</div><div>{user.email}</div></Popup>}
    </div>
    );
}
export default AuditApprovalItem;
