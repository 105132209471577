import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit } from '../../../backend_api/models';
import { AuditCustomConclusion } from '../../../backend_api_2';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { getApprovalColor, getColor } from './AuditDisplayState';

type Props = {
    className?: string;
    audit: Audit;
    approvalConclusion?: AuditCustomConclusion;
    type?: 'final' | 'auditor';
    inverted?: boolean;
    notDone?: boolean;
    canRemove?: boolean;
    disabled?: boolean;
    handleRemove?(e, conclusionId: string): void;
};

const AuditConclusion = (props: Props): React.ReactElement => {
    const { className, inverted = false, type = 'final', audit, notDone = false, approvalConclusion, handleRemove, canRemove = false, disabled } = props;
    const conclusion = type === 'final' ? audit.final_conclusion : audit.auditor_conclusion;
    const getConclusionStyle = (): Record<string, unknown> => {
        if (audit.status === 'report' || (audit.status === 'ongoing' && type === 'auditor')) {
            return !inverted ? { backgroundColor: conclusion.background_color, color: conclusion.text_color } : { backgroundColor: conclusion.text_color, color: conclusion.background_color };
        }
    }
    const getConclusionText = (): ReactNode => {
        if (audit.status === 'report') {
            return conclusion.name?.text;
        } else {
            if (type === 'final') {
                return <FormattedMessage id={'status.' + audit.status} />;
            }
            return conclusion.name?.text;
        }
    }
    const getConclusionDescription = (): ReactNode => {
        if (audit.status === 'report') {
            return conclusion.description?.text;
        } else {
            if (type === 'final') {
                return <FormattedMessage id={'status.' + audit.status} />;
            }
            return conclusion.description?.text;
        }
    }
    const cn = 'flex rounded p-1 uppercase items-center justify-center truncate sm:w-40 w-32 whitespace-pre-line text-sm';
    if (notDone) {
        return <div className={twMerge(cn, 'bg-status-pending text-white text-xs py-2 px-2', !disabled && 'cursor-pointer')}>
            <div className='justify-between flex w-full items-center '>
                <div className='w-full flex justify-center'>
                    <FormattedMessage id={'audits.auditor_conclusion.status.not_set'} /></div>
                {canRemove && <DeleteConfirm
                    deleteFunction={(e) => handleRemove(e, 'not_set')}
                    trigger={<RemoveIcon iconClassName='leading-none cursor-pointer' onClick={(e) => preventDefaultAndStopPropagation(e)} />} />
                }
            </div>
        </div>
    }
    if ((audit.status === 'report' || audit.status === 'ongoing') && approvalConclusion && approvalConclusion !== null) {
        const style = !inverted ? { backgroundColor: approvalConclusion.background_color, color: approvalConclusion.text_color } : { backgroundColor: approvalConclusion.text_color, color: approvalConclusion.background_color };
        return <Popup position='top center' trigger={<div className={classnames(cn, className)} style={style}>
            <div className='justify-between flex w-full items-center cursor-pointer'>
                <div className='w-full flex justify-center'>{approvalConclusion.name && approvalConclusion.name.text}</div>
                {canRemove && <DeleteConfirm
                    deleteFunction={(e) => handleRemove(e, approvalConclusion.id)}
                    trigger={<RemoveIcon iconClassName='leading-none cursor-pointer' onClick={(e) => preventDefaultAndStopPropagation(e)} />} />
                }
            </div>
        </div>}>{approvalConclusion.description.text}</Popup>
    }
    if (conclusion !== null) {
        const style = getConclusionStyle();
        return <Popup position='top center' 
        trigger={<div className={classnames(cn, className, audit.status !== 'report' && ['bg-white text-' + getColor(audit.status)])} style={style}>
            {getConclusionText()}
        </div>}
        >{getConclusionDescription()}
        </Popup>
    }
    if (audit.approval_flow_status && audit.status === 'report' && (audit.approval_flow_status === 'disagree' || audit.approval_flow_status === 'not_set')) {
        return <div className={twMerge(cn, className, 'bg-white text-' + getApprovalColor(audit.approval_flow_status))}><FormattedMessage id={'audit.approval_status.' + audit.approval_flow_status} /></div>
    }
    return <div className={twMerge(cn, className, inverted ? 'bg-white text-' + getColor(audit.status) : 'bg-status-report')}><FormattedMessage id={'audits.conclusion.status.' + audit.status} /></div>
}
export default AuditConclusion;
