import React, { ChangeEvent, Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { createProductionUnit } from '../../suppliers/actions';
import { ProductionUnit } from '../../suppliers/types';

type OwnProps = {
    supplierId: string;
    isStandalone?: boolean;
    auditId?: string;
    children?: React.ReactNode;
}

type StateProps = {
}
type DispatchProps = {
    actions: {
        createProductionUnit: typeof createProductionUnit;
    };
}

type CreateProductionUnitProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

type State = {
    productionUnitData: ProductionUnit;
    dirty: boolean;
    show: boolean;
    valid: boolean;
};

const initialState: State = {
    productionUnitData: {
        name: '',
        contact_person: '',
        address: [],
    }, dirty: false, show: false, valid: false,
};

class CreateProductionUnit extends Component<CreateProductionUnitProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.createProductionUnit = this.createProductionUnit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    render(): React.ReactElement {
        const productionUnitData = this.state.productionUnitData;
        return (
            <Modal
                open={this.state.show}
                onOpen={(): void => this.setState({ show: true })}
                onClose={this.cancel}
                closeIcon={true}
                size='small'
                trigger={this.props.children}
                className='createProductionUnit'
            >
                <Modal.Header><FormattedMessage id='production_unit.add_production_unit' /></Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field width={16} inline required>
                            <label><FormattedMessage id='production_unit.input_label.production_unit_name' /></label>
                            <Input autoFocus={true} type='text' name='name' value={productionUnitData.name} onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field width={16} inline>
                            <label><FormattedMessage id='production_unit.input_label.production_unit_contact' /></label>
                            <Input type='text' name='contact_person' value={productionUnitData.contact_person} onChange={this.handleChange} />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive disabled={!this.state.valid} onClick={this.createProductionUnit}><FormattedMessage id='production_unit.create_production_unit' /></Button>
                    <Button onClick={this.cancel}><FormattedMessage id='suppliers.button_cancel' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }

    handleChange(evt: ChangeEvent, { name, value }: { name: string; value: string }): void {
        const productionUnitData = Object.assign({}, this.state.productionUnitData);
        productionUnitData[name] = value;
        this.setState({ productionUnitData }, () => {
            this.setState({ valid: this.isValid() });
        })
    }

    createProductionUnit(): void {
        this.props.actions.createProductionUnit(this.props.supplierId, this.state.productionUnitData, this.props.isStandalone, this.props.auditId);
        this.setState(initialState);
    }

    cancel(): void {
        this.setState(initialState);
    }

    isValid(): boolean {
        return this.state.productionUnitData.name !== '' && !!this.props.supplierId;
    }

}

export const mapStateToProps = (): StateProps => {
    return {
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        createProductionUnit,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(CreateProductionUnit));
