import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { HideableHints } from '../../inputHints/HideableHints';


export function InputWithHints<T extends { id: string; }>(
    props: {
        required?: boolean;
        input: string;
        entities: T[];
        onEntitySelected(entity: T): void;
        onInputChanged(input: string): void;
        renderTitle(): React.ReactElement;
        renderShowAll(count: number): React.ReactElement;
        renderMatchingEntity(entity: T): React.ReactElement;
        findMatches(entities: T[], input: string): T[];
    }
) {
    const matchingEntities = React.useMemo(
        function () { return props.findMatches(props.entities, props.input); },
        [props.entities, props.input]
    );
    return (
        <Form.Field width={16} inline required={props.required}>
            {props.renderTitle()}
            <Input
                autoFocus={true}
                autoComplete='off'
                type='text'
                value={props.input}
                onChange={(_e, i) => { props.onInputChanged(i.value); }} />

            <HideableHints
                entities={matchingEntities}
                onEntitySelected={(entity: T) => props.onEntitySelected(entity)}
                renderMatchingEntityText={props.renderMatchingEntity}
                renderShowAllText={() => props.renderShowAll(matchingEntities.length)} />
        </Form.Field>
    );
}
