import React from 'react';
import { ErrorMessages, GlobalMessages, StatusMessages } from '.';
import BaseComponent from '../../../base/components/BaseComponent';

class Messages extends BaseComponent<any, any> {
    public doRender(): React.ReactElement {
        return (
            <div className='messagesContainer'>
                <GlobalMessages />
                <StatusMessages />
                <ErrorMessages />
            </div>
        );
    }
}

export default Messages;
