/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The document applies from start date, until the end of time
 * @export
 * @interface DetailedDocumentAppliesNoExpiration
 */
export interface DetailedDocumentAppliesNoExpiration {
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentAppliesNoExpiration
     */
    type: DetailedDocumentAppliesNoExpirationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentAppliesNoExpiration
     */
    valid_after: string;
}


/**
 * @export
 */
export const DetailedDocumentAppliesNoExpirationTypeEnum = {
    NoExpiration: 'no_expiration'
} as const;
export type DetailedDocumentAppliesNoExpirationTypeEnum = typeof DetailedDocumentAppliesNoExpirationTypeEnum[keyof typeof DetailedDocumentAppliesNoExpirationTypeEnum];


/**
 * Check if a given object implements the DetailedDocumentAppliesNoExpiration interface.
 */
export function instanceOfDetailedDocumentAppliesNoExpiration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "valid_after" in value;

    return isInstance;
}

export function DetailedDocumentAppliesNoExpirationFromJSON(json: any): DetailedDocumentAppliesNoExpiration {
    return DetailedDocumentAppliesNoExpirationFromJSONTyped(json, false);
}

export function DetailedDocumentAppliesNoExpirationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedDocumentAppliesNoExpiration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'valid_after': json['valid_after'],
    };
}

export function DetailedDocumentAppliesNoExpirationToJSON(value?: DetailedDocumentAppliesNoExpiration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'valid_after': value.valid_after,
    };
}

