import ISO6391 from 'iso-639-1';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, Modal, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import { useAppDispatch } from '../../../store';
import { downloadChecklistAsExcel } from '../actions/actions';


type DownloadChecklistProps = {
    className?: string;
    checklistId: string;
    checklistDescription: string;
    checklistLanguages: string[];
    skipTranslation?: boolean;
};

const DownloadChecklist = (props: DownloadChecklistProps): React.ReactElement => {
    const { skipTranslation = false, checklistLanguages } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [selectedLanguage, setSelectedLanguage] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [languages, setLanguages] = useState<DropdownItemProps[]>(undefined);
    const hasTranslations = languages && languages.length > 1;

    const downloadChecklist = (): void => {
        skipTranslation ? doDownloadChecklist() : setModalOpen(true);
    }
    const doDownloadChecklist = (): void => {
        dispatch(downloadChecklistAsExcel({ checklistId: props.checklistId, fileName: props.checklistDescription, selectedLanguage }));
        setModalOpen(false);
    }
    const languageSelector = <Dropdown fluid selection placeholder={intl.formatMessage({ id: 'checklists.download.select_language_placeholder' })} defaultValue={'C'} options={languages} onChange={(i, v) => setSelectedLanguage(v.value)} />;
    const trigger = <div className='flex items-center'>
        <Button className={twMerge('border-0 text-base leading-3', props.className)} onClick={() => downloadChecklist()} ><FormattedMessage id='checklists.download.button' /></Button>
    </div>
    const cancel = () => {
        setModalOpen(false);
    }
    useEffect(() => {
        if (checklistLanguages) {
            const langs = checklistLanguages.filter((lang) => lang !== 'C').map((lang) => ({ value: lang, text: ISO6391.getNativeName(lang), id: lang }));
            langs.push({ value: 'C', text: intl.formatMessage({ id: 'checklists.download.default_language' }), id: 'C' });
            setLanguages(langs);
        }
    }, [checklistLanguages]);
    return <>
        <Modal
            open={modalOpen}
            closeOnDimmerClick
            closeOnEscape
            size='tiny'
            onClose={() => setModalOpen(false)}>
            <Modal.Header><FormattedMessage id='checklists.download.download_checklist_as_excel_file' values={{ name: props.checklistDescription }} /></Modal.Header>
            <Modal.Content>
                {hasTranslations ?
                    <><div className='pb-2'><FormattedMessage id='checklists.download.select_download_language' /></div>
                        {languageSelector}
                    </>
                    :
                    <div><FormattedMessage id='checklists.download.checklist_no_translated_yet' /></div>
                }
            </Modal.Content>
            <Modal.Actions className='flex'>
                <Button primary onClick={() => doDownloadChecklist()}><FormattedMessage id='checklists.download.download_checklist' /></Button>
                <Button onClick={cancel}><FormattedMessage id='globals.cancel' /></Button>
            </Modal.Actions>
        </Modal>
        <Popup trigger={trigger}>
            <FormattedMessage id='checklists.download.label' />
        </Popup>
    </>
}
export default DownloadChecklist;
