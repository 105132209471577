import React from 'react';
import { useSelector } from 'react-redux';
import { getProfileSelector } from '../../users/selectors';

const MobileProfile = (): React.ReactElement => {
    const profile = useSelector(getProfileSelector).profile;
    return <div className='profile'>
        <div className='name item'>
            <div>{profile.firstname || '-'}{' '}</div>
            <div>{profile.lastname || '-'}</div>
        </div>
        <div className='item'>{profile.email}</div>
        <div className='item'>{profile.organization.name}</div>
    </div>
}

export default MobileProfile;