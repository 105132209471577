/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldTextValue = {
    text: string | null;
    type: InputCustomFieldTextValue.type;
};

export namespace InputCustomFieldTextValue {

    export enum type {
        TEXT = 'Text',
    }


}

