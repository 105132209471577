/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldNumberValue = {
    number: string | null;
    type: InputCustomFieldNumberValue.type;
};

export namespace InputCustomFieldNumberValue {

    export enum type {
        NUMBER = 'Number',
    }


}

