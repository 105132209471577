import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { AuditReportCustomFields } from '../../../../../backend_api/models';
import { useLocaleLanguageString } from '../../../../../base/components/basic/TextWithTranslation';
import { renderCustomFieldValueRow } from './custom_fields/shared';

type ProductionUnitCustomFieldsProps = {
    customFields: AuditReportCustomFields;
};

export function ProductionUnitCustomFields(props: ProductionUnitCustomFieldsProps): React.ReactElement {
    const locale = useLocaleLanguageString();

    const hierarchy = props.customFields && props.customFields.custom_fields.map(function (group) {
        return renderCustomFieldValueRow(group, locale);
    });

    if (!hierarchy) {
        return null;
    }

    return (
        <div className='audit-report-production-unit-custom-field-table mb-8'>
            <Table unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <FormattedMessage id='report.checkpoint.production_unit_custom_field_name_header' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage id='report.checkpoint.production_unit_custom_field_value_header' />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {hierarchy}
            </Table>
        </div>
    )
}
