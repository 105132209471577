import React, { useState } from 'react';
import { LineTogglerSimple } from '../../globals/components/Utils';

type Props = {
    expandedAsDefault?: boolean;
    content: React.ReactElement;
    headerText?: string | React.ReactElement;
}

const ExpandableArea = (props: Props) => {
    const [expanded, setExpanded] = useState<boolean>(props.expandedAsDefault)

    const toggle = (show) => {
        setExpanded(show)
    }

    return <div>
        <div className=''>
            <LineTogglerSimple
                open={expanded}
                toggle={toggle}
            >{props.headerText}
            </LineTogglerSimple>
        </div>
        {expanded && props.content}
    </div>
}

export default ExpandableArea;
