/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 49.0.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes the changing of a boolean.
 * @export
 * @interface EventBooleanChange
 */
export interface EventBooleanChange {
    /**
     * 
     * @type {boolean}
     * @memberof EventBooleanChange
     */
    next_value: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventBooleanChange
     */
    previous_value: boolean | null;
}

export function EventBooleanChangeFromJSON(json: any): EventBooleanChange {
    return EventBooleanChangeFromJSONTyped(json, false);
}

export function EventBooleanChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventBooleanChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_value': json['next_value'],
        'previous_value': json['previous_value'],
    };
}

export function EventBooleanChangeToJSON(value?: EventBooleanChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_value': value.next_value,
        'previous_value': value.previous_value,
    };
}


