import React from 'react';
import { ReportCheckpoint } from '../../../../backend_api/models';
import { twMerge } from '../../../../base/utils';
import { TranslateText } from '../../../globals/components';
import CheckpointStatus from './report2/CheckpointStatus';

type CheckpointCommentProps = {
    checkpoint: ReportCheckpoint;
    showTranslatedComments?: boolean;
    showToggleActions?: boolean;
    showStatus?: boolean;
    showIcon?: boolean;
    className?: string;
};

const CheckpointComment = (props: CheckpointCommentProps): React.ReactElement => {
    const { checkpoint, showTranslatedComments = false, showToggleActions, showStatus = false, showIcon = false, className } = props;
    const tt = [];
    const cl = twMerge('flex mb-5 checkpointComment', className)
    if (showIcon && (checkpoint.comment2)) {
        tt.push(<i className={'mi comment ml-2'}>comment</i>);
    }
    tt.push(<TranslateText
        showTranslatedComments={showTranslatedComments}
        translateObj={checkpoint.translated_comment2}
        originalLanguage={checkpoint.comment_language}
        originalText={checkpoint.comment2 || checkpoint.comment}
        showToggleActions={showToggleActions}
    />);
    if (checkpoint.comment2) {
        return <span className={(cl)}>{showStatus && <CheckpointStatus checkpoint={checkpoint} onlyStatusEnabled showLabel showInfo />}{' '}{tt}</span>;
    } else {
        if (checkpoint.status as string === '4') {
            return <span className={(cl)}>{showStatus && <CheckpointStatus checkpoint={checkpoint} onlyStatusEnabled showLabel showInfo />}</span>;
        }
    }
    return <span className={(cl)}>{showStatus && <CheckpointStatus checkpoint={checkpoint} onlyStatusEnabled showInfo showLabel />}</span>;
};

export default CheckpointComment;
