import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Button, Divider, Grid, Icon, Label, List, Popup, Segment } from 'semantic-ui-react';
import { CompleteApprover } from '../../../../backend_api/models/CompleteApprover';
import { ListUsersUser } from '../../../../backend_api/models/ListUsersUser';
import BaseComponent from '../../../../base/components/BaseComponent';
import { AppState } from '../../../../base/types';
import { ApprovalFlow, ApprovalFlowReportState, CustomConclusion, InitialStateReport } from '../../../approval/types';
import { getComments } from '../../../comments/actions';
import { showTranslatedCommentsSelector } from '../../../comments/selectors';
import globals from '../../../globals';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { getProfileSelector } from '../../../users/selectors';
import { Inspection } from '../../types';
import ChangeConclusionDialog from '../ChangeConclusionDialog';
import { ReportStatus } from '../edit/Status';
import { Reinspect } from '../Reinspect';
import ApprovalSteps from './ApprovalSteps';


const TranslateText = globals.components.TranslateText;

type OwnProps = {
    inspection: Inspection;
    users: ListUsersUser[];
    isBulk: boolean;
    approvalFlow: ApprovalFlow;
    changeInspectionConclusion(inspectionId: string, conclusion: any);
    changeApprovalStep(inspectionId: string, approvalStepId: string, conclusion: any);
    scheduleReinspection?(inspectionId: string);
    isMobile?: boolean;
};

type DispatchProps = {
    actions: {
        getComments: typeof getComments;
    };
};

type StateProps = {
    showTranslatedComments: boolean;
    loggedInUserId: string;
};

const initialState: ApprovalFlowReportState = InitialStateReport;
type State = Readonly<typeof initialState>;

type ApprovalProps = RouteComponentProps<any> & OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class ApprovalInner extends BaseComponent<ApprovalProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            approval: undefined,
            showReinspectModal: false,
            showModal: false,
            type: undefined,
            showReinspect: false,
            notDoneFlowsCnt: undefined,
            conclustion: props.inspection.conclusion,
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.changeApprovalStep = this.changeApprovalStep.bind(this);
        this.handleScheduleReinspection = this.handleScheduleReinspection.bind(this);
        this.handleShowReinspection = this.handleShowReinspection.bind(this);
        this.goToReInspection = this.goToReInspection.bind(this);
    }

    public doRender(): React.ReactElement {
        const { isMobile = false } = this.props;
        const inspection: Inspection = this.props.inspection;
        const approvalFlow = this.props.approvalFlow;
        const inspectorsConclusion = inspection.inspector_conclusion.conclusion_category;
        const canChangeConclusion = inspection.features.includes('change_conclusion');
        const disabled = inspection.status === 'ongoing' || !canChangeConclusion;
        const reinspectDisabled = inspection.status === 'ongoing';
        const icons = { approved: 'icon check', rejected: 'icon remove', pending: 'icon refresh', not_set: 'icon refresh' };
        const getStatusClass = (): string => {
            let status = inspection.conclusion.conclusion_category.toString();
            if (inspection.status === 'ongoing') {
                status = 'inProgress';
            }
            return 'text-white bg-status-report-' + status;
        }
        const statusClass = getStatusClass();
        const statusButton =
            <HasFeatureAccess feature='change_conclusion' type='inspection' inspection={inspection}>
                <Button onClick={(): void => this.handleOpen('conclusion', null)} size='small' disabled={disabled} className=''>
                    {approvalFlow.length === 0 && <span className='' data-test-id='btn-set-status'><FormattedMessage id='inspections.set_status' /></span>}
                    {approvalFlow.length > 0 && <span className='' data-test-id='btn-set-status'><FormattedMessage id='inspections.change_status' /></span>}
                </Button>
                <Divider />
            </HasFeatureAccess>;
        const inProgressPopupContent = <Fragment><Popup.Header>
            <Icon name='info circle' color='blue' />
            <FormattedMessage id='inspections.report_in_progress' /></Popup.Header>
            <Popup.Content><FormattedMessage id='inspections.report_in_progress_popup_content' /></Popup.Content></Fragment>;
        const approvalData = <Segment.Group raised>
            <Segment>
                <Grid verticalAlign='top' className={statusClass}>
                    <Grid.Column width={10} className='flex items-center'>
                        <h4><FormattedMessage id='inspections.status' /></h4>
                    </Grid.Column>
                    <Grid.Column width={6} className='top' textAlign='center'>
                        <ReportStatus inspection={inspection} />
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment className='conclusion'>
                <List verticalAlign='top'>
                    {/* No inspector conclusion set */}
                    {(approvalFlow.length === 0 && inspection.inspector_conclusion.conclusion_category === 'not_set' || inspection.inspector_conclusion.conclusion_category === 'not_set') &&
                        [<List.Item className='inspector' key='approvalItem_inspectors_conclusion'>
                            <List.Content className='header'>
                                <FormattedMessage id='inspections.inspectors_conclusion' />
                            </List.Content>
                        </List.Item>,
                        <List.Item className='approver' key='approvalItem_inspectors_conclusion-2'>
                            <List.Content>
                                <Grid>
                                    <Grid.Row verticalAlign='middle' className='approval-item-header'>
                                        <Grid.Column width={8}>
                                            <div className='name'>{inspection.assigned_user ? <span>{inspection.assigned_user.firstname} {inspection.assigned_user.lastname}</span> : '-'}</div>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='right'>
                                            <Label circular size='small' className={['noaction', 'status_' + inspectorsConclusion].join(' ')}>
                                                <Icon className={icons[inspectorsConclusion]} />
                                                <span><FormattedMessage id='inspections.not_set' /></span>
                                            </Label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* But we may have an inspector comment */}
                                    {inspection.inspector_comment && <Grid.Row className='approval-item-content'>
                                        <Grid.Column width={16}>
                                            {inspection.inspector_comment && <div className='comment approver conclusion_text'>
                                                <TranslateText
                                                    showTranslatedComments={this.props.showTranslatedComments}
                                                    translateObj={inspection.inspector_comment.translated_comment}
                                                    originalText={inspection.inspector_comment.comment}
                                                    originalLanguage={inspection.inspector_comment.comment_language}
                                                    showToggleActions={true}
                                                /></div>}
                                        </Grid.Column>
                                    </Grid.Row>}
                                </Grid>
                            </List.Content>
                        </List.Item>,
                        ]}
                    {/* We have an inspector conclusion */}
                    {inspection && inspection.inspector_conclusion.conclusion_category !== 'not_set' &&
                        [<List.Item className='inspector' key='approvalItem_inspectors_conclusion-3'>
                            <List.Content className='header'>
                                <FormattedMessage id='inspections.inspectors_conclusion' />
                            </List.Content>
                        </List.Item>,
                        <List.Item className='approver' key='approvalItem_inspectors_conclusion-4'>
                            <List.Content>
                                <Grid>
                                    <Grid.Row verticalAlign='middle' className='approval-item-header'>
                                        <Grid.Column width={8}>
                                            {inspection.assigned_user && <div className='name'>{inspection.assigned_user.firstname || '-'} {inspection.assigned_user.lastname || '-'}</div>}
                                            {!inspection.assigned_user && <div className='name'>-</div>}
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='right'>
                                            <Label circular size='small' className={['noaction', 'status_' + inspectorsConclusion].join(' ')}>
                                                <Icon className={icons[inspectorsConclusion]} />
                                                <span><FormattedMessage id={`conclusion.${inspectorsConclusion}`} /></span>
                                            </Label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* ... and maybe also an inspector comment */}
                                    {inspection.inspector_comment && <Grid.Row className='approval-item-content'>
                                        <Grid.Column width={16}>
                                            {inspection.inspector_comment && <div className='comment approver conclusion_text'>
                                                <TranslateText
                                                    showTranslatedComments={this.props.showTranslatedComments}
                                                    translateObj={inspection.inspector_comment.translated_comment}
                                                    originalText={inspection.inspector_comment.comment}
                                                    originalLanguage={inspection.inspector_comment.comment_language}
                                                    showToggleActions={true}
                                                />
                                            </div>}
                                        </Grid.Column>
                                    </Grid.Row>}
                                </Grid>
                            </List.Content>
                        </List.Item>
                        ]}

                    <Divider />
                    {/* Show the approval flow */}
                    {approvalFlow && approvalFlow.map((approvalFlowItems: CompleteApprover[], i: number) => {
                        return <ApprovalSteps
                            key={'approvalFlowItem' + i}
                            approvalSteps={approvalFlowItems}
                            index={i}
                            disabled={disabled}
                            handleOpen={this.handleOpen}
                            element={inspection}
                        />
                    })}
                    <List.Item>
                        <List.Content>
                            {disabled && canChangeConclusion && <Popup
                                trigger={<span>{statusButton}</span>}>
                                {inProgressPopupContent}
                            </Popup>}
                            {disabled && !canChangeConclusion && statusButton}
                            {!disabled && statusButton}
                            {!this.props.isBulk &&
                                <Fragment>
                                    <Reinspect
                                        inspection={inspection}
                                        disabled={reinspectDisabled}
                                    />
                                </Fragment>}
                        </List.Content>
                    </List.Item>
                </List>
            </Segment>
        </Segment.Group>;
        return (
            <span className='reportApproval'>
                {approvalData}
                {isMobile && <div>Close</div>}
                {<ChangeConclusionDialog
                    handleCancel={this.handleCancel}
                    handleClose={this.handleClose}
                    handleSave={this.handleSave}
                    conclusion={this.state.conclusion || inspection.conclusion}
                    isMobile={isMobile}
                    notDoneFlowsCnt={this.state.notDoneFlowsCnt}
                    showModal={this.state.showModal}
                />}
            </span>
        );
    }


    private handleOpen(type: string, approval: CompleteApprover): void {
        let notDoneFlowsCnt = 0;
        if (type === 'conclusion') {
            notDoneFlowsCnt = this.getNotDoneFlowsCnt();
        }
        this.setState({ showModal: true, type, notDoneFlowsCnt, approval: approval });
    }

    private handleCancel(): void {
        this.setState({ showModal: false, reason: null, type: undefined, approval: undefined });
    }

    private handleClose(): void {
        this.setState({ showModal: false, reason: undefined, type: undefined, approval: undefined });
    }

    private handleSave(conclusion: CustomConclusion, comment: string): void {
        if (this.state.type === 'conclusion') {
            this.changeConclusion(conclusion, comment);
        }
        if (this.state.type === 'approvalStep') {
            this.changeApprovalStep(conclusion, comment);
        }
    }

    private changeConclusion(conclusion: CustomConclusion, comment: string): void {
        this.props.changeInspectionConclusion(this.props.inspection.inspection_id, { conclusion: conclusion, comment: comment });
        this.props.actions.getComments(this.props.inspection.inspection_id);
        this.handleClose();
    }

    private changeApprovalStep(conclusion: CustomConclusion, comment: string): void {
        this.props.changeApprovalStep(this.props.inspection.inspection_id, this.state.approval.id, { conclusion: conclusion, comment: comment });
        this.props.actions.getComments(this.props.inspection.inspection_id);
        this.handleClose();
    }

    private handleShowReinspection(show: boolean): void {
        this.setState({ showReinspect: show });
    }

    private handleScheduleReinspection(reinspect: boolean): void {
        if (reinspect) {
            this.props.scheduleReinspection(this.props.inspection.inspection_id);
        }
        this.setState({ showReinspect: false });
    }

    private goToReInspection(basedOn: boolean): void {
        let reportUrl;
        const inspection: Inspection = this.props.inspection;
        const history = this.props.history;
        if (basedOn) {
            const status = inspection.reinspection_based_on.status;
            if (status === 'Report') {
                reportUrl = inspection.reinspection_based_on.inspection_id;
                history.push('/inspections/reports/' + reportUrl);
            } else {
                history.push('/inspections/edit_inspection/' + inspection.reinspection_based_on.order_id);
            }
        } else {
            const status = inspection.reinspected_by[0].status;
            if (status === 'Report') {
                reportUrl = inspection.reinspected_by[0].inspection_id;
                history.push('/inspections/reports/' + reportUrl);
            } else {
                history.push('/inspections/edit_inspection/' + inspection.reinspected_by[0].order_id);

            }
        }
    }

    private getNotDoneFlowsCnt(): number {
        const flow = this.props.approvalFlow;
        let ret = [];
        flow.forEach((outerItem) => {
            ret = outerItem.filter((item) => {
                return item.approved_status.conclusion.conclusion_category === 'not_set';
            });
        });
        return ret.length;
    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            getComments,
        }, dispatch),
    };
};
const mapStateToProps = (state: AppState): StateProps => {
    return {
        showTranslatedComments: showTranslatedCommentsSelector(state),
        loggedInUserId: getProfileSelector(state).profile.id,
    };
};

const Approval = injectIntl(withRouter(connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(ApprovalInner)))

export default Approval;
