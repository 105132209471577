import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import { UserRoleBrief } from '../../../backend_api/models/UserRoleBrief';
import { useAppDispatch } from '../../../store';
import ConfirmationDialog from '../../globals/components/ConfirmationDialog';
import { deleteRole, getRoleList } from '../actions';
import { getRolesList } from '../selectors';
import NewRoleDialog from './NewRoleDialog';

const RoleList = (): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const roles = useSelector(getRolesList);
    const [showNewRoleDialog, setShowNewRoleDialog] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [roleToDelete, setRoleToDelete] = useState<UserRoleBrief>();

    useEffect(() => {
        dispatch(getRoleList());
    }, []);

    const promtDelete = (role: UserRoleBrief): void => {
        setRoleToDelete(role);
        setShowDeleteConfirmation(true)
    }

    const dispatchDeleteRole = (): void => {
        setShowDeleteConfirmation(false)
        dispatch(deleteRole(roleToDelete.id))
    }

    return <div className='pageContainer'>
        <span className='suppliers'>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.roles' })} />
            <Header as='h2'><FormattedMessage id='roles.list.caption' />
                <Button className='addSupplier' size='tiny' positive onClick={(): void => setShowNewRoleDialog(true)}><FormattedMessage id='roles.new_roles_button' /></Button>
            </Header>
            <Fragment><Grid className='listHeader'>
                <Grid.Row columns={16}>
                    <Grid.Column width={1} className='header'></Grid.Column>
                    <Grid.Column width={4} className='header'><FormattedMessage id='roles.list.name_column' /></Grid.Column>
                    <Grid.Column width={11} className='header'><FormattedMessage id='roles.list.description_column' /></Grid.Column>
                </Grid.Row>
            </Grid>
                <Segment raised container>
                    <Grid className='listContent'>
                        {roles.map((role: UserRoleBrief, i: number) => {
                            return (
                                <Grid.Row key={`supplier_${i}`} className='lineHeader'>
                                    <Grid.Column as={Link} to={`/role/edit/${role.id}`} width={1} className='header'></Grid.Column>
                                    <Grid.Column as={Link} to={`/role/edit/${role.id}`} width={4} className='link noUnderline'>{role.name.C}</Grid.Column>
                                    <Grid.Column as={Link} to={`/role/edit/${role.id}`} width={9} className='link noUnderline'>{role.description.C}</Grid.Column>
                                    <Grid.Column width={2}>
                                        <span className='link noUnderline' onClick={(): void => promtDelete(role)}><FormattedMessage id='roles.delete' /></span>
                                    </Grid.Column>
                                </Grid.Row>);
                        })}
                    </Grid>
                </Segment>
            </Fragment>
            <NewRoleDialog show={showNewRoleDialog} onClose={(): void => setShowNewRoleDialog(false)} />
            <ConfirmationDialog
                show={showDeleteConfirmation}
                headerText={intl.formatMessage({ id: 'roles.delete.modal.header' })}
                description={intl.formatMessage({ id: 'roles.delete.modal.content' }, { rolename: roleToDelete && roleToDelete.name.C })}
                close={(): void => setShowDeleteConfirmation(false)}
                confirm={dispatchDeleteRole}
            />
        </span>
    </div>;
}

export default RoleList;