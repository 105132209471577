import { Pie } from '@nivo/pie';
import React from 'react';

type Props = {
    className?: string;
    data: { id: string, value: number, color: string }[] | {}[];
    showEmptyStatus?: boolean;
    allApproved?: boolean;
    w?: number;
    h?: number;
};
const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (<svg className='fill-branding-brand' width={'30px'} height={'30px'} viewBox="20 20 200 1380">
        <path
            d="m0 844.3623 176 176 272-336-48-48-224 288-128-128z" />
    </svg>)
}
const StatusPie = (props: Props): React.ReactElement => {

    const { className, data, w, h, showEmptyStatus, allApproved } = props;
    return <Pie
        borderWidth={0}
        data={data}
        tooltip={() => null}
        isInteractive={false}
        height={h ? h : 35} width={w ? w : 35} innerRadius={.5} enableArcLabels={false} enableArcLinkLabels={false} layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', allApproved && CenteredMetric]}
        colors={{ datum: 'data.color' }}
    />;
}
export default StatusPie;
