import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../store';
import { showFilters } from '../baseSlice';
import { Search } from '../ui/components/fields/Field';
import { twMerge } from '../utils';
import Button from './basic/Button';


type Props = {
    className?: string;
    path?: string;
    q?: string;
    isSearching?: boolean;
    placeholderText?: string;
    buttonText?: string;
    onSearch(query: string): void;
    onClear?(): void;
};

const SearchBox = (props: Props): React.ReactElement => {
    const { className, isSearching, placeholderText, buttonText, q, path } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [query, setQuery] = useState(undefined);

    useEffect(() => {
        setQuery(q);
    }, [q]);

    const search = () => {
        dispatch(showFilters(false)); // Collapse filters box when searching
        history.push('/' + path + '?query=' + query);
    };

    return <div className={twMerge('w-full flex space-x-1 items-center', className)}>
        <Search
            onChange={(value) => setQuery(value)}
            value={query}
            type='search'
            onSubmit={search}
            className={'w-full'}
        />
        <Button primary disabled={isSearching || query && query.length < 1 || !query} onClick={search}>{buttonText ? buttonText : <FormattedMessage id='search_box.button_label' />}</Button>
    </div>
}
export default SearchBox;
