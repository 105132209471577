import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { getSamplingProtocolsSelector, SamplingProtocol } from '../../../samplingProtocols/slices/samplingProtocolsSlice';

type SamplingProtocolSelector3Props = {
    value?: string;
    disabled?: boolean;
    handleChange(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps);
};

const SamplingProtocolSelectorDropdown = (props: SamplingProtocolSelector3Props): React.ReactElement => {
    const { handleChange, value, disabled = false } = props;
    const intl = useIntl();
    const samplingProtocols = useSelector(getSamplingProtocolsSelector);
    const options = [];
    samplingProtocols.forEach((sp: SamplingProtocol) => {
        options.push({ value: sp.id, id: sp.id, key: sp.id, text: sp.name });
    });
    options.push({ value: 'bypass', id: 'bypass', key: 'bypass', text: intl.formatMessage({ id: 'inspections.sampling_protocol.do_not_use_sampling_protocol' }) });

    return <Dropdown
        options={options}
        value={value}
        selection
        onChange={handleChange}
        disabled={disabled} />;
}
export default SamplingProtocolSelectorDropdown;
