import { AdminCtxUser, ListUsersUser } from '../../../backend_api/models';
import { Locale, RequestError, Role } from '../../../base/types';
import { GroupData } from '../../groups/types';
import { ById, Filters, ShowStatus, UserPreferences } from '../types';

export const USERS_REQUEST = 'USERS_REQUEST';
export type USERS_REQUEST = {
    isFetching: boolean;
};

export const USERS_REQUEST_SUCCESS = 'USERS_REQUEST_SUCCESS';
export type USERS_REQUEST_SUCCESS = {
    isFetching: boolean;
    isOrderSpecific: boolean;
    data: { persons: ListUsersUser[]; byId: ById<ListUsersUser>; byGroupId: ById<ListUsersUser>; orderSpecific: ListUsersUser[]; orderSpecificById: ById<ListUsersUser> };
};

export const USERS_REQUEST_FAILURE = 'USERS_REQUEST_FAILURE';
export type USERS_REQUEST_FAILURE = {
    isFetching: boolean;
};

export const ADMIN_USERS_REQUEST = 'ADMIN_USERS_REQUEST';
export type ADMIN_USERS_REQUEST = {
    isFetching: boolean;
};

export const ADMIN_USERS_REQUEST_SUCCESS = 'ADMIN_USERS_REQUEST_SUCCESS';
export type ADMIN_USERS_REQUEST_SUCCESS = {
    isFetching: boolean;
    data: { persons: AdminCtxUser[]; byId: ById<AdminCtxUser> };
};

export const ADMIN_USERS_REQUEST_FAILURE = 'ADMIN_USERS_REQUEST_FAILURE';
export type ADMIN_USERS_REQUEST_FAILURE = {
    isFetching: boolean;
    error: RequestError;
};

export const ADMIN_CREATE_USERS_REQUEST = 'ADMIN_CREATE_USERS_REQUEST';
export type ADMIN_CREATE_USERS_REQUEST = {
    isFetching: boolean;
};

export const ADMIN_CREATE_USERS_REQUEST_SUCCESS = 'ADMIN_CREATE_USERS_REQUEST_SUCCESS';
export type ADMIN_CREATE_USERS_REQUEST_SUCCESS = {
    isFetching: boolean;
};

export const ADMIN_CREATE_USERS_REQUEST_FAILURE = 'ADMIN_CREATE_USERS_REQUEST_FAILURE';
export type ADMIN_CREATE_USERS_REQUEST_FAILURE = {
    isFetching: boolean;
    error: RequestError;
};

export const GET_LOCALES_REQUEST = 'GET_LOCALES_REQUEST';
export type GET_LOCALES_REQUEST = {
    locales: Locale[];
};

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export type UPDATE_USER_ROLE = {
    userId: string;
    locale: Locale;
};

export const IS_FETCHING = 'IS_FETCHING';
export type IS_FETCHING = {
    isFetching: boolean;
};

export const ADMIN_CREATE_USER_SHOW_DIALOG = 'ADMIN_CREATE_USER_SHOW_DIALOG';
export type ADMIN_CREATE_USER_SHOW_DIALOG = {
    createUserDialogOpen: boolean;
};
export const ADMIN_USERS_GET_GROUPS = 'ADMIN_USERS_GET_GROUPS';
export type ADMIN_USERS_GET_GROUPS = {
    groups: GroupData[];
};

export const GET_ROLES = 'GET_ROLES';
export type GET_ROLES = {
    roles: Role[];
};

export const GET_USER_PREFERENCES_SUCCESS = 'GET_USER_PREFERENCES_SUCCESS';
export type GET_USER_PREFERENCES_SUCCESS = {
    preferences: UserPreferences;
};

export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export type CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export type USER_UPDATE_REQUEST = {
    isFetching: boolean;
};

export const USER_UPDATE_REQUEST_SUCCESS = 'USER_UPDATE_REQUEST_SUCCESS';
export type USER_UPDATE_REQUEST_SUCCESS = {
    isFetching: boolean;
    data: { person: ListUsersUser; byId: ById<ListUsersUser>; byGroupId: ById<ListUsersUser> };
};

export const ADMIN_USER_UPDATE_REQUEST_SUCCESS = 'ADMIN_USER_UPDATE_REQUEST_SUCCESS';
export type ADMIN_USER_UPDATE_REQUEST_SUCCESS = {
    isFetching: boolean;
    data: { person: AdminCtxUser; byId: ById<AdminCtxUser>; byGroupId: ById<AdminCtxUser> };
};

export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export type SET_USERS_FILTER = {
    name: string;
    value: any;
};

export const SET_USERS_FILTERS = 'SET_USERS_FILTERS';
export type SET_USERS_FILTERS = {
    filters: Filters;
};

export const SET_USERS_FILTERS_VISIBILITY = 'SET_USERS_FILTERS_VISIBILITY';
export type SET_USERS_FILTERS_VISIBILITY = {
    visibility: boolean;
};

export const SET_USERS_HIDDEN_FILTERS = 'SET_USERS_HIDDEN_FILTERS';
export type SET_USERS_HIDDEN_FILTERS = {
    filters: string[];
};
export const GET_USERS_WITH_FILTERS = 'GET_USERS_WITH_FILTERS';

export const SET_USERS_PAGINATION = 'SET_USERS_PAGINATION';
export type SET_USERS_PAGINATION = {
    currentPage: number;
    lastPage: number;
    itemsPerPage: number;
};
export const SET_USERS_SHOW_STATUS = 'SET_USERS_SHOW_STATUS';
export type SET_USERS_SHOW_STATUS = {
    status: ShowStatus;
};