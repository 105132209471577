import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { NotifiedResponsibleEvent } from '../../../backend_api/models/NotifiedResponsibleEvent';

type Props = {
    event: NotifiedResponsibleEvent
}

const ResponsibleNotifiedEvent = (props: Props) => {
    return <Fragment>
        <label className='mb-1'><FormattedMessage id={'corrective_actions.events.responsible_notified'} /></label>
        <div className='flex items-center text-secondary'>
            {props.event.responsible_email}
        </div>
    </Fragment>
}

export default ResponsibleNotifiedEvent;