import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Icon } from 'semantic-ui-react';
import BaseComponent from '../../../base/components/BaseComponent';
import messages from '../../../modules/messages';
import { clearGlobalMessage } from '../actions';
import { GlobalMessage } from '../components/Messages';

interface StateProps {
    globalMessages: any;
}

interface DispatchProps {
    actions: {
        clearGlobalMessage: typeof clearGlobalMessage;
    };
}

type GlobalMessagesProps = StateProps & DispatchProps;

class GlobalMessages extends BaseComponent<GlobalMessagesProps & WrappedComponentProps, any> {
    public doRender(): React.ReactElement {
        if (this.props.globalMessages.length > 0) {
            const isInfo = this.props.globalMessages[0].type === 'info';
            return (
                <div className={`globalMessageContainer ${isInfo} ? 'info' : ''`} onClick={this.props.actions.clearGlobalMessage} >
                    <Grid>
                        <Grid.Column width={1}>
                            {!isInfo && <Icon name='check circle' fitted />}
                            {isInfo && <Icon name='info circle' fitted />}
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <GlobalMessage messages={this.props.globalMessages} />
                        </Grid.Column>
                        <Grid.Column width={1}>
                            {isInfo && <span className='hideLabel'><FormattedMessage id='global_message.hide_message' /></span>}
                        </Grid.Column>
                    </Grid>
                </div>
            );
        }
        return (null);
    }

    public componentWillReceiveProps(nextProps): void {
        this.setMessageTimeout(nextProps);
    }

    private setMessageTimeout(props): void {
        if (props.globalMessages.length > 0) {
            const hideAfterMs = props.globalMessages[0].hideAfterMs;
            if (hideAfterMs && hideAfterMs > 0) {
                const timer = setTimeout(() => {
                    this.props.actions.clearGlobalMessage();
                    clearTimeout(timer);
                }, hideAfterMs);
            }
        }
    }
}

export const mapStateToProps = (state): StateProps => {
    return {
        globalMessages: messages.selectors.getGlobalMessages(state),
    };
};

export const mapDispatchToProps = (dispatch): DispatchProps => {
    const clearGlobalMessage = messages.actions.clearGlobalMessage;
    return {
        actions: bindActionCreators({
            clearGlobalMessage,
        }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GlobalMessages));
