import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { getShowModalSelector, showModal } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';

type Props = {
    className?: string;
};

const DuplicateCombinedInspectionInfo = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const modalOpen = useSelector(getShowModalSelector);
    const closeCIInfoModal = () => dispatch(showModal({ id: 'new_combined_inspection_created', show: false }));

    return <Modal
        size='tiny'
        onClose={() => closeCIInfoModal()}
        closeIcon
        closeOnDimmerClick
        closeOnDocumentClick
        open={modalOpen['new_combined_inspection_created'] && modalOpen['new_combined_inspection_created'].show}>
        <Modal.Header><FormattedMessage id='inspections.duplicate_combined_inspection.header' /></Modal.Header>
        <Modal.Content>
            <div className='flex space-y-2 flex-col'>
                <div className='items-center space-x-1 flex'>
                    <div><FormattedMessage id='inspections.duplicate_combined_inspection_was_succesfull' /></div>
                </div>
                <Link onClick={() => closeCIInfoModal()} className='link' to={'/inspections/edit_inspection/' + (modalOpen['new_combined_inspection_created'] && modalOpen['new_combined_inspection_created'].data)}>
                    <FormattedMessage id='inspections.open_the_duplicate_combined_inspection'/>
                    </Link>
            </div>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => closeCIInfoModal()}>Close</Button>
        </Modal.Actions>
    </Modal>
}
export default DuplicateCombinedInspectionInfo;
