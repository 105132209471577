import { createAction } from 'typesafe-actions';
import { request } from '../../../base/api';
import { AppThunk, RequestError } from '../../../base/types';
import errorHandling from '../../errorHandling';
import { CustomField, CustomTable } from '../types';
import * as types from './actionTypes';
import { InputCustomFieldGroups } from '../../../backend_api_2';

const catchException = errorHandling.handler.catchException;

const getSupplierCustomFieldsSuccess =
    createAction(
        types.GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS,
        (data: InputCustomFieldGroups) => ({
            type: types.GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS, payload: { data }
        })
    );
const getProductionUnitCustomFieldsSuccess =
    createAction(
        types.GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS,
        (data: InputCustomFieldGroups) => ({
            type: types.GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS, payload: { data }
        })
    );
const getCustomFieldsSuccess = createAction(types.GET_CUSTOM_FIELDS_SUCCESS, (data: CustomField[]) => ({ type: types.GET_CUSTOM_FIELDS_SUCCESS, payload: { data } }));
const getCustomTablesSuccess = createAction(types.GET_CUSTOM_TABLES, (data: CustomTable[]) => ({ type: types.GET_CUSTOM_TABLES, payload: { customTables: data } }));

export const getSupplierCustomFields = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('supplier_custom_fields', {})
            .then((data: InputCustomFieldGroups) => {
                dispatch(getSupplierCustomFieldsSuccess(data));
            })
            .catch((error: RequestError) => {
                catchException('getSupplierCustomFields', {
                    endpoint: 'supplier_custom_fields',
                    request: 'supplier_custom_fields',
                    status: error.status,
                }, { error });
            });
    }
}

export const getProductionUnitCustomFields = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('production_unit_custom_fields', {})
            .then((data: InputCustomFieldGroups) => {
                dispatch(getProductionUnitCustomFieldsSuccess(data));
            })
            .catch((error: RequestError) => {
                catchException('getProductionUnitsCustomField', {
                    endpoint: 'production_unit_custom_fields',
                    request: 'production_unit_custom_fields',
                    status: error.status,
                }, { error });
            });
    }
}

export const getCustomFields = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('custom_fields', {})
            .then((data: CustomField[]) => {
                dispatch(getCustomFieldsSuccess(data));
            })
            .catch((error: RequestError) => {
                catchException('getCustomFields', {
                    endpoint: 'custom_fields',
                    request: 'custom_fields',
                    status: error.status,
                }, { error });
            });
    };
};

export const getCustomTables = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('custom_tables', {})
            .then((data: CustomTable[]) => {
                dispatch(getCustomTablesSuccess(data));
            })
            .catch((error: RequestError) => {
                catchException('getCustomTables', {
                    endpoint: 'custom_tables',
                    request: 'custom_tables',
                    status: error.status,
                }, { error });
            });
    };
}
