import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Header } from 'semantic-ui-react';
import { AppState } from '../../../base/types';
import { addAttachment, clearUploads, getAttachments, removeAttachment as removeAttachmentAction } from '../actions/actions';
import AttachmentsList from '../components/AttachmentsList';
import { handleWarning, isUploadComplete, removeAttachment, removeWarnFile, uploadFiles } from '../components/handlers';
import { attachmentsInitialState } from '../constants';
import { getAttachments as getAttachmentsSelector, getUploading as getUploadingSelector } from '../selectors';
import { Attachment, AttachmentsLocalState as ReportAttachmentsState, AttachToType, UploadingFile } from '../types';


interface OwnProps {
    attachToType: AttachToType;
    attachToItemId: string; // Id of the item that the attachment is to be attached to
    disabled?: boolean;
    children?: React.ReactNode;

}

type StateProps = {
    attachmentProgress: number;
    attachments?: Attachment[];
    uploading?: UploadingFile[];
}

type DispatchProps = {
    actions: {
        addAttachment: typeof addAttachment;
        getAttachments: typeof getAttachments;
        removeAttachmentAction: typeof removeAttachmentAction;
        clearUploads: typeof clearUploads;
    };
}

type ReportAttachmentsProps = DispatchProps & StateProps & OwnProps;

class ReportAttachments extends Component<ReportAttachmentsProps, ReportAttachmentsState> {
    readonly state: ReportAttachmentsState = attachmentsInitialState;
    constructor(props: ReportAttachmentsProps) {
        super(props);
        this.updateUploadState = this.updateUploadState.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.removeAttachmentHandler = this.removeAttachmentHandler.bind(this);
        this.isUploadComplete = this.isUploadComplete.bind(this);
        this.clearUploadsHandler = this.clearUploadsHandler.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
        this.removeWarnFile = this.removeWarnFile.bind(this);
    }

    public render(): React.ReactElement {
        const elements = <Fragment>{this.props.children}
            <span className='attachments inner'>
                <Header as='h4'><FormattedMessage id='attachments.attachments' /></Header>
                <AttachmentsList
                    attachments={this.state.attachments}
                    removeAttachment={this.removeAttachmentHandler}
                    disabled={this.props.disabled}
                    showDelete={false}
                    showEmpty={true}
                    loading={!this.state.loaded}
                />
            </span></Fragment>;
        return (
            <div className='attachments'>
                {elements}
            </div>
        );
    }

    public componentDidMount(): void {
        this.props.actions.getAttachments(this.props.attachToItemId, this.props.attachToType, true);
    }
    public UNSAFE_componentWillReceiveProps(nextProps): void {
        if (!this.state.loaded) {
            this.setState({ loaded: true });
        }
        if (nextProps.attachments && nextProps.attachments.length > 0) {
            this.setState({ attachments: nextProps.attachments });
        }
    }

    private cancel(): void {
        this.updateUploadState('droppedFiles', []);
        setTimeout(() => { // Needed to ensure that droppedFiles are in place in state
            this.uploadFiles();
        }, 200);
    }

    private handleWarning(proceed: boolean): void {
        this.setState(handleWarning(proceed, (file: File) => this.props.actions.addAttachment(this.props.attachToItemId, this.props.attachToType, file)));
    }

    private isUploadComplete(): void {
        this.setState(isUploadComplete());
    }

    private uploadFiles(): void {
        this.setState(uploadFiles((file: File) => this.props.actions.addAttachment(this.props.attachToItemId, this.props.attachToType, file)));
    }

    private updateUploadState(item: string, data: boolean | number | string | File[]): void {
        const upload = Object.assign({}, this.state.upload);
        upload[item] = data;
        this.setState({ upload });
    }

    private removeAttachmentHandler(attachmentURL: string): void {
        this.setState(removeAttachment(attachmentURL));
    }

    private removeWarnFile(file): void {
        this.setState(removeWarnFile(file));
    }
    private clearUploadsHandler(): void {
        this.props.actions.clearUploads(this.props.attachToItemId, this.props.attachToType);
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        attachmentProgress: 0,
        attachments: getAttachmentsSelector(state, ownProps.attachToItemId, ownProps.attachToType),
        uploading: getUploadingSelector(state, ownProps.attachToItemId, ownProps.attachToType),
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        addAttachment,
        clearUploads,
        getAttachments,
        removeAttachmentAction,
    }, dispatch);
    return { actions };
};

export default (connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(ReportAttachments));
