import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { CorrectiveActionsState } from '../../../backend_api/models/CorrectiveActionsState';
import Icon from '../../../base/components/basic/Icon';
import { twMerge } from '../../../base/utils';
import { getColor, getDisplayState, getFullStateName } from '../CorrectiveActionDisplayState';

type Props = {
    state: CorrectiveActionsState;
    inverted?: boolean;
    className?: string;
}

const CorrectiveActionStatus = (props: Props) => {
    const {inverted} = props;
    const cn = 'h-8 rounded w-32 flex items-center'
    const statusColor = getColor(props.state);
    const backgroundColor = inverted ? 'bg-white' : 'bg-' + statusColor;
    const textColor = inverted ? 'text-' + statusColor : 'text-white'
    return <Popup
        trigger={
            <div className={twMerge(backgroundColor, cn, 'pl-2', props.className)}>
                <Icon className={inverted ? 'bg-white text-' + statusColor : 'text-white bg-' + statusColor} name='assignment_turned_in'/>
                <div className={'whitespace-nowrap text-sm px-3 py-1 w-full ' + textColor}>
                    {getDisplayState(props.state)}
                </div>
            </div>
        }
    >
        <Popup.Content>
            <Button></Button>
            {getFullStateName(props.state)}
        </Popup.Content>
    </Popup>
}

export default CorrectiveActionStatus;