import React, { Fragment } from 'react';
import { Grid, Icon, Image } from 'semantic-ui-react';
import { AttachmentResponse } from '../actions/actions';

type FileListViewProps = {
    files: AttachmentResponse[];
    delete(file: AttachmentResponse);
}

const FileListView = (props: FileListViewProps): React.ReactElement => {
    const textDivStyle = { alignItems: 'center', display: 'flex', marginLeft: '5px' }
    return (
        <Grid>
            {props.files && props.files.map((file, index) =>
                <Fragment key={index}>
                    <Grid.Row style={{ padding: '5px 0px' }}>
                        {file.type === 'image' ?
                            <Fragment>
                                <Image src={file.url + '?w=40&h=40'} />
                                <div style={textDivStyle}>
                                    <a className='link' href={file.url}>{file.name}</a>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <div style={{ display: 'flex', alignItems: 'center', width: '40px', height: '40px' }}>
                                    <Icon style={{ margin: '0px', width: '100%' }} name="file alternate outline" size="big" />
                                </div>
                                <div style={textDivStyle}>
                                    <a className='link' href={file.url}>{file.name}</a>
                                </div>
                            </Fragment>
                        }
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon name="delete" onClick={(): void => props.delete(file)} style={{ height: 'unset' }} />
                        </div>
                    </Grid.Row>
                </Fragment>
            )}
        </Grid>
    )
}

export default FileListView