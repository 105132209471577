/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 43.7.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoResource
 */
export interface VideoResource {
    /**
     * 
     * @type {number}
     * @memberof VideoResource
     */
    audio_bitrate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VideoResource
     */
    audio_codec?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VideoResource
     */
    duration?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VideoResource
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoResource
     */
    mime_type?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoResource
     */
    size?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VideoResource
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoResource
     */
    video_bitrate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VideoResource
     */
    video_codec?: string | null;
}

export function VideoResourceFromJSON(json: any): VideoResource {
    return VideoResourceFromJSONTyped(json, false);
}

export function VideoResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audio_bitrate': !exists(json, 'audio_bitrate') ? undefined : json['audio_bitrate'],
        'audio_codec': !exists(json, 'audio_codec') ? undefined : json['audio_codec'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mime_type': !exists(json, 'mime_type') ? undefined : json['mime_type'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'video_bitrate': !exists(json, 'video_bitrate') ? undefined : json['video_bitrate'],
        'video_codec': !exists(json, 'video_codec') ? undefined : json['video_codec'],
    };
}

export function VideoResourceToJSON(value?: VideoResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audio_bitrate': value.audio_bitrate,
        'audio_codec': value.audio_codec,
        'duration': value.duration,
        'id': value.id,
        'mime_type': value.mime_type,
        'size': value.size,
        'url': value.url,
        'video_bitrate': value.video_bitrate,
        'video_codec': value.video_codec,
    };
}


