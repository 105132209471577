import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    className?: string;
    heading: string | React.ReactElement;
    advice?: string | React.ReactElement;
};

const NoHits = (props: Props): React.ReactElement => {
    const { className } = props;
    return <div className={twMerge('flex flex-col w-full items-center h-44 mt-18', className)}>
        <div className='text-2xl font-normal py-3'>{props.heading}</div>
        {props.advice && <div className='text-secondary'>{props.advice}</div>}
    </div>
}
export default NoHits;
