import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "semantic-ui-react";
import Button from "../../../base/components/basic/Button";
import Icon from "../../../base/components/basic/Icon";
import history from "../../../store/history";
import { TrainingVideoData } from "../types";
import TrainingVideo from "./TrainingVideo";
const multiLogo = require("../../appInstallPage/assets/multi-logo.png");
const multiLogo2x = require("../../appInstallPage/assets/multi-logo@2x.png");
const multiLogo3x = require("../../appInstallPage/assets/multi-logo@3x.png");

interface TrainingVideoSectionProps {
    videos: TrainingVideoData[];
    header: string;
    description: string;
    columns: number;
    mobile: boolean;
}

const TrainingVideoSection = (
    props: TrainingVideoSectionProps
): React.ReactElement => {
    const rows = [];
    const cellWidthClassName = "w-1/" + props.columns;
    for (
        let i = 0;
        i < (props.videos && props.videos.length);
        i = i + props.columns
    ) {
        const cells = [];
        for (let l = 0; l < props.columns; l++) {
            const index = i + l;
            if (index < (props.videos && props.videos.length)) {
                cells.push(
                    <Table.Cell className={cellWidthClassName}>
                        <TrainingVideo
                            trainingVideo={props.videos[index]}
                            index={index + 1}
                        />
                    </Table.Cell>
                );
            } else {
                cells.push(<Table.Cell className={cellWidthClassName} />);
            }
        }
        rows.push(
            <Table.Row>
                {cells.map((cell) => {
                    return cell;
                })}
            </Table.Row>
        );
    }
    return (
        <div className="training-video-section">
            <div className="flex flex-row justify-between my-6 p-3 pr-2">
                <div className="training-video-section-header">
                    {props.header}
                </div>
                {props.mobile && (
                    <Button
                        secondary
                        className="flex flex-row justify-between app-download"
                        onClick={() => history.push("/get_app")}
                    >
                        <div className="flex flex-row items-center">
                            <img src={multiLogo} srcSet={`${multiLogo2x} 2x ${multiLogo3x} 3x`} />
                            <span className="mx-2">
                                <FormattedMessage id="globals.get_qarma_app" />
                            </span>
                        </div>
                        <Icon
                            className=" text-white pl-2 text-2xl"
                            name="navigate_next"
                        />
                    </Button>
                )}
            </div>
            <div className="flex flex-row justify-start p-3 pr-2 training-video-section-description">
                {props.description}
            </div>

            <div>
                <Table className="training-video-table">
                    {rows.map((row) => {
                        return row;
                    })}
                </Table>
            </div>
        </div>
    );
};

export default TrainingVideoSection;
