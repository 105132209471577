import React from 'react';
import { useIntl } from 'react-intl';
import { Inspection } from '../../../../types';

type OrderItemTitleProps = {
    inspections: Inspection[];
    ordersCnt: number;
};

const OrderItemTitle = (props: OrderItemTitleProps): React.ReactElement => {
    const { inspections, ordersCnt } = props;
    const intl = useIntl();
    let title = inspections[0] && inspections[0].item_name;
    if (title === null) {
        title = '';
    }
    if (ordersCnt > 1) {
        title += ' + ' + (ordersCnt - 1) + ' ' + intl.formatMessage({ id: 'inspections.more' });
    }
    return <p>{title}</p>;
};

export default OrderItemTitle;
