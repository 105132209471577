import React, { LabelHTMLAttributes } from 'react';
import { twMerge } from '../../utils';

export interface LabelProps extends  LabelHTMLAttributes<HTMLInputElement> {
    padded?: boolean;
    disabled?: boolean;
    small?: boolean;
    large?: boolean;
}

const Label = (props: LabelProps): React.ReactElement => {
    const { className, children, padded = false, disabled = false, small, large, ...rest } = props;
    let textSize = 'text-smx';
    let padding = 'px-3 py-1';
    if (small) {
        textSize = 'text-xsl';
        padding = 'px-2 py-0'
    }
    if (large) {
        textSize = 'text-base';
        padding = 'px-6 py-2'
    }

    const cn = twMerge(
        'inline-block rounded-md border border-default leading-none text-white my-1 bg-surface-chip',
        padded ? 'px-3 py-3' : 'px-2 py-1',
        textSize,
        padding,
        className,

    );
    return <div onClick={props.onClick} className={cn} {...rest}>{children}</div>
}
export default Label;
