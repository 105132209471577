import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dimmer, FormField, Loader, Modal } from 'semantic-ui-react';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import Input from '../../../../base/components/basic/Input';
import {
    isAndroidOS,
    preventDefaultAndStopPropagation,
    validateEmail,
} from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { setIsAuthenticated } from '../../../authentication/authenticationSlice';
import PageContainer from '../../../globals/components/PageContainer';
import { getLocale } from '../../../globals/selectors';
import { getAuditsLoadingSelector, getTemporaryUserSelector } from '../../selectors/auditSelectors';
import { temporaryUserLogin, temporaryUserUpdate } from '../../slices/auditSlice';
import { getAppLink } from './PerformAuditPage';

type AuditorWelcomePageProps = {
    sessionId: string;
};

const AuditorWelcomePage = (props: AuditorWelcomePageProps) => {
    const dispatch = useAppDispatch();
    const loading = useSelector(getAuditsLoadingSelector);
    const temporaryUser = useSelector(getTemporaryUserSelector);
    
    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');

    const [showModal, setShowModal] = React.useState(false);
    const [showEmailError, setShowEmailError] = React.useState(false);
    const isValidEmail = email && validateEmail(email);
    const locale = useSelector(getLocale);
    const defaultLocaleId =  locale?.selected?.id || "";

    React.useEffect(() => {
        if(temporaryUser?.user?.email) {
            setEmail(temporaryUser.user.email);
        }
    }, [temporaryUser])
   
    const handleSubmit = function (e) {
        preventDefaultAndStopPropagation(e);
        dispatch(temporaryUserUpdate({
            email, firstname: firstName, lastname: lastName, locale_id: defaultLocaleId
        }))
    };

    const onWhatIsThisClick = (e) => {
        preventDefaultAndStopPropagation(e);
        setShowModal(true);
    };

    const onModalCancel = () => {
        setShowModal(false);
    };

    useEffect(() => {
        dispatch(setIsAuthenticated(false));
        dispatch(temporaryUserLogin(props.sessionId));
    }, [props.sessionId]);

    useEffect(() => {
        const shouldOpenApp = temporaryUser?.user_details_updated && isAndroidOS();
        if(shouldOpenApp) {
            window.location.href = getAppLink({auditId: temporaryUser.reference.reference_id});
        }
    }, [temporaryUser])

    if(loading) {
        return <Dimmer active={true} inverted>
            <Loader className='dimLoader'><FormattedMessage id='globals.initialising_app.please_wait' /></Loader>
        </Dimmer>
    }

    return (
        <PageContainer
            className="flex justify-center items-center"
            headerClassName=""
        >
            <div className="w-full flex-col mx-4 justify-center sm:w-136 px-8">
                <h2>
                    <FormattedMessage id="onboarding.form.welcome_heading" />
                </h2>
                <h6>
                    <FormattedMessage id="onboarding.form.welcome_message" values={{ auditType: temporaryUser?.reference?.reference_name }}/>
                </h6>
                <div className="flex flex-row justify-between items-center my-4">
                    <FormField className="mr-4">
                        <div className='mb-1'>
                            <label className='text-sm text-secondary'><FormattedMessage id='profile.first_name'/></label>
                        </div>
                        <Input
                            type="text"
                            name="firstname"
                            onChange={(e) =>
                                setFirstName(e.target.value)
                            }
                            value={firstName}
                            iconPosition="left"
                            iconClass="text-brand text-lg w-12 justify-center flex"
                        />
                    </FormField>
                    <FormField className="">
                        <div className='mb-1'>
                            <label className='text-sm text-secondary'><FormattedMessage id='profile.last_name'/></label>
                        </div>
                        <Input
                            type="text"
                            name="lastname"
                            onChange={(e) =>
                                setLastName(e.target.value)
                            }
                            value={lastName}
                            iconPosition="left"
                            iconClass="text-brand text-lg w-12 justify-center flex"
                        />
                    </FormField>
                </div>

                <FormField>
                    <div className='mb-1'>
                        <label className='text-sm text-secondary'><FormattedMessage id='profile.email'/></label>
                    </div>
                    {
                        showEmailError && !isValidEmail &&
                        <div className='flex flex-row inline text-sm text-alert mb-1'>
                            <Icon name='error'/>
                            <span className='pl-1'><FormattedMessage id='audits.edit.warning.invalid_email'/></span>
                        </div>   
                    }
                    <Input
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                            setShowEmailError(!validateEmail(e.target.value));
                        }} 
                        value={email}
                        iconPosition="left"
                        iconClass="text-brand w-12 justify-center flex"
                    />
                </FormField>
                <div className="flex justify-between items-center my-7">
                    <Button className="justify-between text-base" primary onClick={handleSubmit}>
                        <FormattedMessage id="onboarding.form.button.next" />
                        <Icon
                            className=" text-white pl-2"
                            name="navigate_next"
                        />
                    </Button>
                </div>
            </div>

            <Modal
                size="small"
                closeOnEscape
                closeIcon
                onClose={onModalCancel}
                open={showModal}
            >
                <Modal.Header>
                    <FormattedMessage id="onboarding.modal.welcome_to_qarma" />
                </Modal.Header>
                <Modal.Content>
                    <h6>
                        <FormattedMessage id="onboarding.modal.welcome_message" values={{ auditType: temporaryUser?.reference?.reference_name }}/>
                    </h6>
                </Modal.Content>
                <Modal.Actions className="flex justify-end">
                    <Button primary onClick={onModalCancel}>
                        <FormattedMessage id="onboarding.modal.confirm" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </PageContainer>
    );
}

export default AuditorWelcomePage;