import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '../../../../store';
import { deleteInspection } from '../../actions/deleteInspection';

type DeleteInspectionProps = {
    inspectionId: string;
    orderId: string;
    isSaving: boolean;
    disabled: boolean;
    type?: 'link' | 'button';
    className?: string;
};

const DeleteInspection = (props: DeleteInspectionProps): React.ReactElement => {
    const { disabled, isSaving, orderId, inspectionId, type = 'button', className } = props;
    const dispatch = useAppDispatch();
    const [canDelete, setCanDelete] = useState(false);
    const doDelete = (): void => {
        dispatch(deleteInspection(orderId, inspectionId));
    }
    const trigger = type === 'button' ? <Button circular icon='delete'></Button> : <div className={twMerge(className)}><FormattedMessage id='inspections.delete' /></div>
    return (
        <Fragment>
            {!disabled ? (<Popup
                on='click'
                hideOnScroll={true}
                trigger={trigger}
                basic={type === 'link'}
                position='top right'
                onOpen={(): void => setCanDelete(true)}
                onClose={(): void => setCanDelete(false)}
                open={canDelete}
            >
                <Popup.Content>
                    {!isSaving &&
                        <Button onClick={(): void => {
                            doDelete();
                            setCanDelete(false);
                        }}
                            size='mini' negative ><FormattedMessage id='inspections.delete' /></Button>}
                    {isSaving &&
                        <Popup
                            on='hover'
                            hideOnScroll
                            trigger={<span><Button disabled={true} negative size='tiny'><Icon name='warning circle' /><FormattedMessage id='inspections.delete' /></Button></span>}
                        >
                            <h4><Icon name='warning circle' color='red' /><FormattedMessage id='inspections.cannot_delete_inspection' /></h4>
                            <p><FormattedMessage id='inspections.the_order_data_is_being_saved_right_now_so_the_inspection_cannot_be_deleted_please_try_again_in_a_moment' /></p>
                        </Popup>}
                    <Button onClick={(): void => setCanDelete(false)}
                        size='mini'><FormattedMessage id='inspections.cancel' /></Button>
                </Popup.Content>
            </Popup>) : (type === 'button' ? <Button disabled circular icon='delete'></Button> : <div className={twMerge('text-disabled cursor-default', className)}><FormattedMessage id='inspections.delete' /></div>)}
        </Fragment>
    );
}
export default DeleteInspection;
