import { createAction } from 'redux-actions';
import { request } from '../../../base/api';
import { clearError, sendError } from '../../../base/baseSlice';
import { AppThunk } from '../../../base/types';
import * as types from './actionTypes';

const pdfSetExternalId = createAction(types.PDF_SET_EXTERNAL_ID, (id: string, externalId: string) => (
    { id, externalId }
));

const pdfClearExternalId = createAction(types.PDF_CLEAR_EXTERNAL_ID, (inspectionId: string) => (
    { inspectionId }
));

export const requestPDF = (id: string, context: 'inspection' | 'audit', fileType: 'pdf' | 'docx' | 'odt', includeComments: boolean, includeCheckpoints: boolean, correctiveActions: boolean, correctiveActionsTable: boolean, isGrouped?: boolean): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(clearError('requestPdf'));
        dispatch(pdfClearExternalId(id));
        let url;
        if (context === 'audit') {
            url = 'audits/' + id + '/pdf';
            url += '?filetype=' + fileType;
            url += '&checkpoints=' + includeCheckpoints;
            url += '&cap_table=' + correctiveActionsTable;
        }
        if (context === 'inspection') {
            url = 'inspections/' + id + '/';
            url += isGrouped ? 'grouped_pdf' : 'pdf';
            url += '?filetype=' + fileType;
            url += '&comments=' + includeComments;
            url += '&corrective_actions=' + correctiveActions;
        }
        return request(url, {})
            .then((response: any) => {
                dispatch(pdfSetExternalId(id, response.url));
            })
            .catch((error: any) => {
                dispatch(sendError('requestPdf', error, error.status));
            });
    };
};

