import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { InfoIcon } from '../../globals/components/Utils';
import { handleChange } from '../../inspections/actions/editInspectionActions';
import { Inspection } from '../../inspections/types';
import { getUsersById } from '../../users/selectors';
import { getUsersThatAreNotWatchers } from '../Handlers';
import Watcher from './Watcher';
import WatcherSelector from './WatcherSelector';

type WatchersProps = {
    inspection: Inspection;
    disabled?: boolean;
    done?: boolean;
    locked?: boolean;
    handleChange: typeof handleChange;
};

const Watchers = (props: WatchersProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { inspection, done, locked } = props;
    const currentWatchers = inspection.watchers;
    const [selectedWatchers, setSelectedWatchers] = useState([]);
    const [showAddWatchers, setShowAddWatchers] = useState(false);
    const [watcherIds, setWatcherIds] = useState(() => {
        const ret: any[] = [];
        currentWatchers && currentWatchers.forEach((w) => {
            ret.push(w.id)
        })
        return ret;
    });

    const usersById = useSelector(getUsersById);
    const users = getUsersThatAreNotWatchers(currentWatchers as any, usersById);
    const handleAddWatcher = (): void => {
        setShowAddWatchers(true);
    };
    const handleChange = (item: any): void => {
        setSelectedWatchers(item.value);
    };

    const handleSave = (): void => {
        const watcherObj = [];
        const ids = Object.assign([], watcherIds.concat(selectedWatchers));
        ids.forEach((watcherId) => {
            if (!usersById[watcherId]) {
                // If watcher is not in user list that the logged-in user is allowed to see, use watcher from inspection
                currentWatchers.forEach((watcher) => {
                    if (watcher.id === watcherId) {
                        watcherObj.push(watcher)
                    }
                })
            } else {
                watcherObj.push(usersById[watcherId]);
            }
        });
        updateWatchers(watcherObj, true);
        setWatcherIds(ids);

    };
    const handleCancel = (): void => {
        setShowAddWatchers(false);
        setSelectedWatchers([]);
    };
    const updateWatchers = (watcherObj: string[], clear: boolean): void => {
        dispatch(props.handleChange(undefined, { name: 'watchers', value: watcherObj }, inspection.inspection_id));
        if (clear) {
            setSelectedWatchers([]);
            setShowAddWatchers(false);
        }
    };
    const handleRemoveWatcher = (watcherId: string): void => {
        const watcherObj = [];
        const ids = [...watcherIds];
        const index = ids.indexOf(watcherId);
        ids.splice(index, 1);
        ids.forEach((watcherId) => {
            if (!usersById[watcherId]) {
                // If watcher is not in user list that the logged-in user is allowed to see, use watcher from inspection
                currentWatchers.forEach((watcher) => {
                    if (watcher.id === watcherId) {
                        watcherObj.push(watcher)
                    }
                })
            } else {
                watcherObj.push(usersById[watcherId]);
            }
        });
        setWatcherIds(ids);
        updateWatchers(watcherObj, false);
    };

    return <div className='flex flex-wrap space-y-4'>
        <div className='flex flex-wrap w-full'>
            {currentWatchers.length === 0 &&
                <InfoIcon compact text={intl.formatMessage({ id: 'watchers.no_watchers_added' })} />
            }
            {currentWatchers && currentWatchers.map((watcher) => {
                const name = watcher.firstname + ' ' + watcher.lastname;
                return (
                    <Watcher
                        key={'watcher' + watcher.id}
                        user={watcher}
                        name={name}
                        handleRemoveWatcher={handleRemoveWatcher}
                        disabled={done || locked}
                    />
                );
            })}
        </div>

        {(!done && !locked) && <div className='w-full'>
            <Button className='add' disabled={showAddWatchers} onClick={handleAddWatcher} size='small' data-test-id={'add-watcher-button'}>
                <Icon className='icon icon-add' /><FormattedMessage id='watchers.add_watcher' />
            </Button>
        </div>}
        <div className='w-full'>
            <WatcherSelector
                usersById={users}
                handleChange={handleChange}
                handleCancel={handleCancel}
                handleSave={handleSave}
                showSelector={showAddWatchers}
                showEmailInSelector={true}
                hasSelection={selectedWatchers.length > 0}
            />
        </div>
    </div>
}
export default Watchers;
