import React, { Component, Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Popup } from 'semantic-ui-react';
import { AppState } from '../../../base/types';
import { GroupData } from '../../groups/types';
import { clearUsersFilters, setActiveHiddenFilters, setCurrentUsersShowStatus, setUsersFilter, setUsersFilters, setUsersFiltersVisibilty, setUsersShowStatus } from '../actions/filterActions';
import GroupFilter from '../components/groupFilter';
import RolesFilter from '../components/rolesFilter';
import SearchFilter from '../components/searchFilter';
import SearchHits from '../components/searchHits';
import StatusFilter from '../components/statusFilter';
import { getGroups, getGroupsById, getUsersFilters, getUsersFiltersVisibility, getUsersShowStatus } from '../selectors';
import { ById, DropdownOptionType, Filters, ShowStatus } from '../types';

type OwnProps = {
    roles: DropdownOptionType[];
    filtersActiveHidden: string[];
};

type StateProps = {
    groups: GroupData[];
    groupsById: ById<GroupData>;
    filters: Filters;
    status: ShowStatus;
    filtersVisible: boolean;
};

type DispatchProps = {
    actions: {
        clearUsersFilters: typeof clearUsersFilters;
        setUsersFilter: typeof setUsersFilter;
        setUsersFilters: typeof setUsersFilters;
        setUsersFiltersVisibilty: typeof setUsersFiltersVisibilty;
        setActiveHiddenFilters: typeof setActiveHiddenFilters;
        setUsersShowStatus: typeof setUsersShowStatus;
        setCurrentUsersShowStatus: typeof setCurrentUsersShowStatus;
    };
};

const initialState = { active: { groups: false, roles: false, search: false }, groups: {}, roles: {}, query: '', filters: {} };
type State = Readonly<typeof initialState>;
type FiltersContainerProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps & RouteComponentProps<any>;

class FiltersContainer extends Component<FiltersContainerProps, State> {
    readonly state: State = initialState;
    public constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.setActive = this.setActive.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.getActiveHiddenFilters = this.getActiveHiddenFilters.bind(this);
        this.hasActiveFilters = this.hasActiveFilters.bind(this);
    }

    public render(): React.ReactElement {
        const rolesByKey = this.roleByKey();
        return (
            <Fragment>
                <div className={['flex space-x-4 items-center mb-6', this.props.filtersVisible ? 'block' : 'hidden'].join(' ')}>
                    <SearchFilter
                        query={this.props.filters.search || []}
                        doSearch={this.handleChange}
                    />
                    <GroupFilter
                        groups={this.state.groups}
                        groupsById={this.props.groupsById}
                        handleChange={this.handleChange}
                        selectedGroups={this.props.filters.groups || []}
                        setActive={this.setActive}
                        active={this.state.active.groups}
                    />
                    <RolesFilter
                        roles={this.props.roles}
                        rolesByKey={rolesByKey}
                        handleChange={this.handleChange}
                        selectedRoles={this.props.filters.roles || []}
                        setActive={this.setActive}
                        active={this.state.active.roles}
                    />
                    <StatusFilter
                        selectedStatus={this.props.status}
                        handleChange={this.handleStatusChange} />
                    {this.hasActiveFilters() && <div className='filter'>
                        <div className='label'>&nbsp;</div>
                        <div className='content'>
                            <Popup trigger={<span onClick={this.clearAll} className='link clearall'><FormattedMessage id='users.clear_all' /></span>}>
                                <Popup.Content><FormattedMessage id='users.clear_search_and_all_filters' /></Popup.Content>
                            </Popup>
                        </div>
                    </div>}
                    {this.props.filtersVisible && <div className='filter'>
                        <div className='label'>&nbsp;</div>
                        <div className='content'>
                            <span className='link hideFilters' onClick={(): void => this.props.actions.setUsersFiltersVisibilty(false)}><FormattedMessage id='users.hide' /></span>
                        </div>
                    </div>}
                </div>
                {this.props.filters.search &&
                    <div className={['moreFilters', this.props.filtersVisible ? 'show' : 'hide'].join(' ')}>
                        <SearchHits
                            clear={this.clearAll}
                            query={this.props.filters.search} />
                    </div>}
            </Fragment>
        );
    }

    public componentDidMount(): void {
        this.props.actions.setCurrentUsersShowStatus()
        this.props.actions.setUsersFilters();
        this.props.actions.setActiveHiddenFilters();
    }

    public componentDidUpdate(prevProps): void {
        const currentStatus = this.props.history.location.pathname.slice(7);
        const status = currentStatus ? currentStatus : 'active';
        if (Object.keys(this.state.groups).length === 0 && this.props.groups.length > 0) {
            this.setState({ groups: this.props.groups });
        }
        if (prevProps.status !== status) {
            this.props.actions.setCurrentUsersShowStatus();
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.props.actions.setUsersFilters();
            this.props.actions.setActiveHiddenFilters();
        }
    }

    public setActive(filterName: string, isActive: boolean): void {
        const active = Object.assign({}, this.state.active);
        active[filterName] = isActive;
        this.setState({ active });
    }

    public handleChange({ name, value }: { name: string; value: any }): void {

        this.props.actions.setUsersFilter(name, value);
        this.props.actions.setActiveHiddenFilters();
    }

    public handleStatusChange = (item: string): void => {
        this.props.actions.setUsersShowStatus(item);
    }

    private clearAll(): void {
        this.props.actions.clearUsersFilters();
    }

    public clearSearch = (): void => {
        this.props.actions.setUsersFilter('search', []);
        this.props.actions.setActiveHiddenFilters();
    }

    public roleByKey = (): any => {
        const rolesByKey = {};
        this.props.roles.forEach((role) => {
            rolesByKey[role.key] = role
        });
        return rolesByKey;
    }

    private hasActiveFilters(): boolean {
        return this.getActiveHiddenFilters() > 0;
    }

    private getActiveHiddenFilters(): number {
        let cnt = 0;
        this.props.filtersActiveHidden.forEach((filter) => {
            if (this.props.filters[filter]) {
                cnt++;
            }
        });
        return cnt;
    }
}
const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        actions: bindActionCreators({
            clearUsersFilters,
            setUsersFilter,
            setUsersFilters,
            setUsersFiltersVisibilty,
            setActiveHiddenFilters,
            setUsersShowStatus,
            setCurrentUsersShowStatus
        }, dispatch),
    };
};

const mapStateToProps = (state: AppState): StateProps => {
    return {
        groups: getGroups(state),
        groupsById: getGroupsById(state),
        filters: getUsersFilters(state),
        status: getUsersShowStatus(state),
        filtersVisible: getUsersFiltersVisibility(state),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FiltersContainer) as React.ComponentType<any>);