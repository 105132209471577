import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Loader } from 'semantic-ui-react';
import { BasicUser, ListUsersUser } from '../../../backend_api/models';
import BaseComponent from '../../../base/components/BaseComponent';
import Button from '../../../base/components/basic/Button';
import { byId } from '../../../base/types';
import { addWatcherToInspection, removeWatcherFromInspection } from '../../inspections/actions/watcherActions';
import { getUsersThatAreNotWatchers, handleAddWatcher, handleCancel, handleChange, handleRemoveWatcherReport, handleSaveReport } from '../Handlers';
import Watcher from './Watcher';
import WatcherSelector from './WatcherSelector';

type OwnProps = {
    usersById?: byId<ListUsersUser>;
    currentWatchers?: BasicUser[];
    inspectionId: string;
    canManageWatchers?: boolean;
};

type DispatchProps = {
    actions: {
        addWatcherToInspection: typeof addWatcherToInspection;
        removeWatcherFromInspection: typeof removeWatcherFromInspection;
    };
};

type WatcherState = {
    watchers: string[];
    selectedWatchers: string[];
    showAddWatchers: boolean;
    isSaving: boolean;
}

type WatchersProps = OwnProps & DispatchProps;
class Watchers extends BaseComponent<WatchersProps, WatcherState> {
    constructor(props) {
        super(props);
        this.state = { watchers: [], selectedWatchers: [], showAddWatchers: false, isSaving: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleAddWatcher = this.handleAddWatcher.bind(this);
        this.handleRemoveWatcher = this.handleRemoveWatcher.bind(this);
    }
    public doRender(): React.ReactElement {
        const { canManageWatchers = false } = this.props;
        const usersById = getUsersThatAreNotWatchers(this.props.currentWatchers, this.props.usersById);
        return (
            <div>
                <div className='flex flex-wrap mb-4 text-sm'>
                    {this.props.currentWatchers && this.props.currentWatchers.map((watcher) => {
                        const name = watcher.firstname + ' ' + watcher.lastname;
                        return (
                            <Watcher
                                key={'watcher' + watcher.id}
                                user={watcher}
                                name={name}
                                handleRemoveWatcher={this.handleRemoveWatcher}
                                disabled={!canManageWatchers || false}
                            />
                        );
                    })}
                </div>
                {this.props.currentWatchers.length === 0 && <span className='flex pb-4'><FormattedMessage id='watchers.no_watchers_added' /></span>}
                {canManageWatchers && <Fragment>
                    {<div className='mb-4'>
                        {this.state.isSaving && <Button disabled={true} data-test-id='btn-add-watcher'>
                            <Icon name='spinner' loading color='green' /><FormattedMessage id='watchers.adding_watcher_please_wait' />
                            <Loader />
                        </Button>}
                        {!this.state.isSaving && <Button className='add' onClick={this.handleAddWatcher} data-test-id='btn-add-watcher'>
                            <Icon className='icon icon-add text-brand' /><FormattedMessage id='watchers.add_watcher' />
                        </Button>}
                    </div>}
                    <WatcherSelector
                        usersById={usersById}
                        handleChange={this.handleChange}
                        handleCancel={this.handleCancel}
                        handleSave={this.handleSave}
                        showSelector={this.state.showAddWatchers}
                        showEmailInSelector={true}
                        hasSelection={this.state.selectedWatchers.length > 0}
                    />
                </Fragment>}
            </div>
        );
    }

    public componentDidUpdate(prevProps): void {
        if (this.props.currentWatchers.length !== prevProps.currentWatchers.length) {
            this.setState({ isSaving: false });
        }
    }

    private handleChange(item): void {
        this.setState(handleChange(item));
    }

    private handleCancel(): void {
        this.setState(handleCancel);
    }

    private handleSave(): void {
        this.setState(handleSaveReport());
    }

    private handleRemoveWatcher(watcherId: string): void {
        this.setState(handleRemoveWatcherReport(watcherId));
    }

    private handleAddWatcher(): void {
        this.setState(handleAddWatcher);
    }
}
function mapDispatchToProps(dispatch): DispatchProps {

    return {
        actions: bindActionCreators({
            addWatcherToInspection,
            removeWatcherFromInspection,
        }, dispatch),
    };
}
export default connect(null, mapDispatchToProps)(Watchers);
