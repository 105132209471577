import React from 'react';
import { useSelector } from 'react-redux';
import { BasicUserOptional, ListUsersUser } from '../../../backend_api/models';
import ConditionalInputField from '../../globals/components/ConditionalInputField';
import { getUsersById } from '../selectors';
import { ById } from '../types';
import UsersSelector, { UsersSelectorProps } from './UsersSelector';

type OwnProps = {
    isEditable: boolean;
    conditionalPlaceholder?: string;
    className?: string;
    fallbackUser?: BasicUserOptional
}

type Props = OwnProps & UsersSelectorProps

const ConditionalUsersSelector = (props: Props) => {
    let users = props.users;
    if (!users) {
        users = useSelector(getUsersById);
    }
    let labelValue = null
    if (props.value && props.value.length > 0) {
        labelValue = props.value.map((userId) => {
            const user = users[userId]
            return user && user.firstname + ' ' + user.lastname
        }).reduce((previousValue, user) => {
            return previousValue == null ? user : previousValue + ', ' + user
        })
    }

    /* Add the fallback user, if we can not find the selected id */
    function addFallbackToUsers(innerUsers: ById<ListUsersUser>): ById<ListUsersUser> {
        if (!props.fallbackUser) {
            return innerUsers
        }

        if (!innerUsers) {
            return innerUsers
        }

        if (!innerUsers[props.fallbackUser.id]) {
            /* Do we really need all these fields ? */
            const listableUser: ListUsersUser = {
                groups: [],
                order_specific: false,
                last_activity: undefined,
                disabled: !!props.fallbackUser.disabled,
                email: props.fallbackUser.email,
                firstname: props.fallbackUser.firstname,
                lastname: props.fallbackUser.lastname,
                id: props.fallbackUser.id,
                supplier_user: !!props.fallbackUser.supplier_user
            }

            return {
                ...innerUsers,
                [props.fallbackUser.id]: listableUser
            }
        }

        return innerUsers;
    }

    const usersWithFallback = addFallbackToUsers(users)

    return <ConditionalInputField
        isEditable={props.isEditable}
        labelValue={labelValue}
        placeholder={props.conditionalPlaceholder}
        className={props.className}
        inputField={
            <UsersSelector
                {...props}
                users={usersWithFallback}
                showUnassigned
            />
        }
    />
}
export default ConditionalUsersSelector