import classnames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from '../../../../../base/utils';

type ListViewItem = {
    label: string;
    className?: string;
}

type ListViewHeaderItem = {
    label: string | JSX.Element;
    key: string;
    className?: string;
}

type ListViewProps = {
    items?: ListViewItem[];
    compact?: boolean;
    children?: ReactNode;
    className?: string;
};

const ListView = (props: ListViewProps): React.ReactElement => {
    const { className } = props;
    const lvClass = twMerge('flex flex-col w-full mb-4', className);
    return <div className={lvClass}>
        {props.children}
    </div>;
};

type ListViewHeaderItemProps = {
    label: string | JSX.Element;
    className?: string;
};

const ListViewHeaderItem = (props: ListViewHeaderItemProps): React.ReactElement => {
    const { label, className } = props;
    const lvhiClass = classnames('flex items-center', {
        [className]: className,
    });
    return <div className={lvhiClass}>{label}</div>;
};

type ListViewHeaderProps = {
    items: ListViewHeaderItem[];
    compact?: boolean;
    className?: string;
};

const ListViewHeader = (props: ListViewHeaderProps): React.ReactElement => {
    const { items, className } = props;
    return <div
        className={
            twMerge(
                'hidden md:flex text-secondary text-sm font-medium px-4 py-4 justify-between space-y-5 md:space-y-0 flex-wrap md:flex-nowrap', className)}
    >{items.map((item: ListViewHeaderItem) => {
        return <ListViewHeaderItem key={item.key} className={item.className} label={item.label} />;
    })}</div>;
};

type ListViewLineProps = {
    className?: string;
    children?: ReactNode;
    link?: string;
    visible?: boolean;
    compact?: boolean;
    onClick?(e: MouseEvent);
};

const ListViewLine = (props: ListViewLineProps): React.ReactElement => {
    const { link = null, visible = true, children, className, onClick } = props;
    const lvlClass = twMerge('text-primary hover:text-primary bg-white py-2 sm:pt-4 sm:pb-4 px-4 md:px-4 justify-between flex md:border-b flex-wrap md:flex-nowrap', className);
    if (visible) {
        return link ? <Link
            to={link}
            className={lvlClass}
            onClick={(e: MouseEvent): void => onClick ? onClick(e) : null}
        >{children}</Link> : <div data-test-id="list-view-line"
            className={lvlClass}
            onClick={(e: MouseEvent): void => onClick ? onClick(e) : null}
        >{children}</div>
    }
    return null;
};

type ListViewItemProps = {
    className?: string;
    children?: ReactNode;
    onClick?(e: MouseEvent);
};

const ListViewItem = (props: ListViewItemProps): React.ReactElement => {
    const { className, onClick } = props;
    const lviClass = twMerge('flex items-center', className);
    return <div className={lviClass} onClick={(e: MouseEvent): void => onClick ? onClick(e) : null}>
        {props.children}
    </div>;
};
type ListViewLinesProps = {
    className?: string;
    children?: ReactNode;
}
const ListViewLines = (props: ListViewLinesProps): React.ReactElement => {
    const { className } = props;
    const lvlClass = twMerge('linesContainer', className);
    return <div className={lvlClass}>
        {props.children}
    </div>;
}

export { ListView, ListViewHeader, ListViewHeaderItem, ListViewItem, ListViewLine, ListViewLines };

