/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldDefinitionNumber = {
    type: InputCustomFieldDefinitionNumber.type;
};

export namespace InputCustomFieldDefinitionNumber {

    export enum type {
        NUMBER = 'Number',
    }


}

