/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes the changing of a list of strings.
 * @export
 * @interface EventStringListChange
 */
export interface EventStringListChange {
    /**
     * 
     * @type {Array<string>}
     * @memberof EventStringListChange
     */
    next_value: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventStringListChange
     */
    previous_value: Array<string>;
}

export function EventStringListChangeFromJSON(json: any): EventStringListChange {
    return EventStringListChangeFromJSONTyped(json, false);
}

export function EventStringListChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStringListChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_value': json['next_value'],
        'previous_value': json['previous_value'],
    };
}

export function EventStringListChangeToJSON(value?: EventStringListChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_value': value.next_value,
        'previous_value': value.previous_value,
    };
}


