/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HistoryEntryAuditReference } from './HistoryEntryAuditReference';
import type { HistoryEntryImportJobReference } from './HistoryEntryImportJobReference';
import type { HistoryEntryUserReference } from './HistoryEntryUserReference';
import type { HistoryEventCommentAdded } from './HistoryEventCommentAdded';
import type { HistoryEventEntityAddedToField } from './HistoryEventEntityAddedToField';
import type { HistoryEventEntityRemovedFromField } from './HistoryEventEntityRemovedFromField';
import type { HistoryEventFieldChanged } from './HistoryEventFieldChanged';
import type { HistoryEventProductionUnitCreated } from './HistoryEventProductionUnitCreated';
import type { HistoryEventStatusSet } from './HistoryEventStatusSet';
import type { HistoryEventSupplierCreated } from './HistoryEventSupplierCreated';
import type { UUIDRequired } from './UUIDRequired';

/**
 *   A history entry for either a production unit or supplier
 *
 */
export type SupplierServiceHistoryEntry = {
    created_at: string;
    history_entry_id: UUIDRequired;
    history_event: (Array<HistoryEventFieldChanged> | HistoryEventSupplierCreated | HistoryEventProductionUnitCreated | HistoryEventStatusSet | HistoryEventEntityAddedToField | HistoryEventEntityRemovedFromField | HistoryEventCommentAdded);
    history_event_type: SupplierServiceHistoryEntry.history_event_type;
    source_reference: (HistoryEntryUserReference | HistoryEntryAuditReference | HistoryEntryImportJobReference);
};

export namespace SupplierServiceHistoryEntry {

    export enum history_event_type {
        COMMENT_ADDED = 'comment_added',
        ENTITY_ADDED_TO_FIELD = 'entity_added_to_field',
        ENTITY_REMOVED_FROM_FIELD = 'entity_removed_from_field',
        FIELDS_CHANGED = 'fields_changed',
        PRODUCTION_UNIT_CREATED = 'production_unit_created',
        STATUS_SET = 'status_set',
        SUPPLIER_CREATED = 'supplier_created',
    }


}

