import { handleActions } from 'redux-actions';
import { ProductionUnit } from '../../backend_api/models/ProductionUnit';
import { byId } from '../../base/types';
import { Action } from './../../base/genericActions';
import * as types from './actionTypes';
import { SuppliersState } from './models';

const initialState: SuppliersState = {
    data: [],
    newSupplier: undefined,
    isFetching: false,
    productionUnits: [],
    productionUnitsBySupplierId: { '': [] },
    inspectionSupplierId: '',
    inspectionProductionUnitToBeOverwritten: false,
    confirmDeleteSupplier: {
        confirm: false,
        supplierId: undefined,
    },
    confirmDeleteProductionUnit: {
        confirm: false,
        productionUnitId: undefined,
        supplierId: undefined,
    },
    bookingData:
        {},
    bookingDefaultDate: '',
    supplierAndProductionUnitRelations: 'loading'
};

export const reducer = handleActions({
    [types.GET_SUPPLIERS]: (state: SuppliersState = initialState, action: Action<types.GET_SUPPLIERS>) => {
        const suppliers = action.payload.suppliers;
        return {
            ...state,
            data: suppliers,
            isFetching: false,
        };
    },
    [types.GET_SUPPLIER_DATA]: (state: SuppliersState = initialState, action: Action<types.GET_SUPPLIER_DATA>) => {
        const supplierId = action.payload.supplierId;
        const supplierData = action.payload.supplierData;
        return {
            ...state,
            [supplierId]: supplierData,
            isFetching: false,
        };
    },
    [types.GET_SUPPLIER_DATA_REQUEST]: (state: SuppliersState = initialState) => {
        return {
            ...state,
            isFetching: true,
        };
    },
    [types.CREATE_SUPPLIER]: (state: SuppliersState = initialState, action: Action<types.CREATE_SUPPLIER>) => {
        return {
            ...state,
            data: action.payload.suppliers,
            newSupplier: action.payload.supplierData,
            isFetching: false,
        };
    },
    [types.CREATE_PRODUCTION_UNIT]: (state: SuppliersState = initialState) => {
        return {
            ...state,
            isFetching: false,
        };
    },
    [types.GET_PRODUCTION_UNITS_SUCCESS]: (state: SuppliersState = initialState, action: Action<types.GET_PRODUCTION_UNITS_SUCCESS>) => {
        const productionUnitsBySupplierId = { ...state.productionUnitsBySupplierId };
        productionUnitsBySupplierId[action.payload.supplierId] = (action.payload.productionUnits);
        const supplierData = state[action.payload.supplierId];
        const productionUnits = Object.assign([], action.payload.productionUnits);
        const puObj: byId<ProductionUnit> = {};
        productionUnits.forEach((productionUnit: ProductionUnit) => {
            puObj[productionUnit.id] = productionUnit;
        });
        if (supplierData) {
            supplierData.productionUnits = productionUnits;
            supplierData.productionUnitsById = puObj;
        }

        return {
            ...state,
            supplierData,
            productionUnitsBySupplierId,
            isFetching: false,
        };

    },
    [types.SET_INSPECTION_SUPPLIER_ID]: (state: SuppliersState = initialState, action: Action<types.SET_INSPECTION_SUPPLIER_ID>) => {
        return {
            ...state,
            inspectionSupplierId: action.payload.supplierId,
        };
    },
    [types.SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN]: (state: SuppliersState = initialState, action: Action<types.SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN>) => {
        return {
            ...state,
            inspectionProductionUnitToBeOverwritten: action.payload.overwrite,
        };
    },
    [types.SET_CONFIRM_SUPPLIER_DELETE]: (state: SuppliersState = initialState, action: Action<types.SET_CONFIRM_SUPPLIER_DELETE>) => {
        return {
            ...state,
            confirmDeleteSupplier: { confirm: action.payload.confirm, supplierId: action.payload.supplierId },
        };
    },
    [types.SET_CONFIRM_PRODUCTION_UNIT_DELETE]: (state: SuppliersState = initialState, action: Action<types.SET_CONFIRM_PRODUCTION_UNIT_DELETE>) => {
        return {
            ...state,
            confirmDeleteProductionUnit: {
                confirm: action.payload.confirm,
                productionUnitId: action.payload.productionUnitId,
                supplierId: action.payload.supplierId,
            },
        };
    },
    [types.SET_BOOKING_DEFAULT_DATE]: (state: SuppliersState = initialState, action: Action<types.SET_BOOKING_DEFAULT_DATE>) => {
        return {
            ...state,
            bookingDefaultDate: action.payload.date,
        };
    },
    [types.GET_SUPPLIER_BOOKING_REQUEST]: (state: SuppliersState = initialState, action: Action<types.GET_SUPPLIER_BOOKING_REQUEST>) => {
        return {
            ...state,
            isFetching: action.payload.isFetching,
        };
    },

    [types.GET_SUPPLIER_BOOKING_FAILURE]: (state: SuppliersState = initialState, action: Action<types.GET_SUPPLIER_BOOKING_FAILURE>) => {
        return {
            ...state,
            isFetching: action.payload.isFetching,
            error: action.payload.error,
        };
    },
    [types.GET_SUPPLIER_BOOKING_SUCCESS]: (state: SuppliersState = initialState, action: Action<types.GET_SUPPLIER_BOOKING_SUCCESS>) => {
        const data = action.payload.bookingData;
        const orderId = action.payload.orderId;
        const bookingData = Object.assign({});
        bookingData[orderId] = data;
        return {
            ...state,
            isFetching: false,
            bookingData,
        };
    },
    [types.GET_BOOKINGS_REQUEST]: (state: SuppliersState = initialState) => {
        return {
            ...state,
            isFetching: true,
        };
    },
    [types.GET_BOOKINGS_SUCCESS]: (state: SuppliersState = initialState, action: Action<types.GET_BOOKINGS_SUCCESS>) => {
        const isFirst = action.payload.isFirst;
        const data = action.payload.data;
        if (!isFirst) {
            const newData = state.bookings.elements.concat(data.elements);
            data.elements = newData;
        }
        return {
            ...state,
            isFetching: false,
            bookings: data,
        };
    },
    [types.GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS]: (
        state: SuppliersState = initialState,
        action: Action<types.GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS>) => {
        const relations = action.payload.relations
        return {
            ...state,
            supplierAndProductionUnitRelations: relations
        }
    }
}, initialState);

export default reducer;
