import React from 'react';
import { Label, Table } from 'semantic-ui-react';
import { OptionsType } from '../../../base/types';
import { GroupData } from '../types';

interface GroupProps {
    group: GroupData;
    users: OptionsType[];
}

const Group = (props: GroupProps): React.ReactElement => {
    const users = props.users.map((user: OptionsType) => {
        return <Label key={user.key}>{user.text}</Label>;
    });
    return (
        <Table.Row>
            <Table.Cell>{props.group.name}</Table.Cell>
            <Table.Cell><Label.Group size='small'>{users}</Label.Group></Table.Cell>
        </Table.Row>
    );
};

export default Group;
