import qs from 'query-string';
import { createAction } from 'typesafe-actions';
import { request } from '../../base/api';
import { getFeaturesSelector } from '../../base/selectors';
import { AppThunk, ERROR } from '../../base/types';
import { getLocationEntry, getPrettyCustomFormat, urlParamsToArray } from '../../base/utils';
import history from '../../store/history';
import errorHandling from '../errorHandling';
import messages from '../messages';
import config from './config';
import { getDefectsStatusBySeveritySelector } from './selectors';
import * as types from './types';
import { getInspectionsStatusByTypeSuccess, InspectionsStatusByTypeBackend } from './types';


const catchException = errorHandling.handler.catchException;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;

export const getInspectionsStatus = () => {
    return (dispatch) => {
        dispatch(getInspectionsStatusByType());        
    }
};
export const getInspectionsStatusByType = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        let params = '&results[]=month&results[]=inspection_type';
        const filterStr: string = qs.stringify(urlParamsToArray(history.location.search), { arrayFormat: 'bracket' });
        if (filterStr) {
            params += '&' + filterStr;
        }
        dispatch(clearAllMessages());
        dispatch(types.getInspectionsStatusByTypeRequest());
        return request('statistics?status[]=report' + params, {})
            .then((data: InspectionsStatusByTypeBackend) => {
                const ret = [];
                const typesArr = [];
                data.result.forEach((item: any) => {
                    const obj: any = {};
                    item.values.forEach((valueItem: any) => {
                        obj[valueItem.label] = valueItem.value;
                        const type = item.type;
                        let label = item.label;
                        label = getPrettyCustomFormat(item.label, 'MMM YYYY');
                        obj[type] = label;
                        if (!typesArr.includes(valueItem.label)) {
                            typesArr.push(valueItem.label);
                        }
                    });
                    ret.push(obj);
                });
                dispatch(getInspectionsStatusByTypeSuccess({ data: ret, types: typesArr, summary: data.summary }));
                const hasSupplierQc = getFeaturesSelector(getState()).includes('supplier_qc');
                dispatch(getInspectionsStatusByConclusion());
                if (hasSupplierQc) {
                    dispatch(getInspectionsStatusBySupplierQC());
                }
            })
            .catch((error) => {
                dispatch({ type: ERROR, payload: { type: 'getInspectionsStatusByType', error, status: error.status } });
                dispatch(sendErrorMessage(['error_message.dashboard.could_not_load_inspections_type_status_data']));
                catchException('getInspectionsStatusByType', {
                    endpoint: 'statistics?status=report&results=' + params,
                    request: 'statistics?status=report&results=' + params,
                }, { error });
            });
    };
};

export const getInspectionsStatusByConclusion = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const filterStr = qs.stringify(urlParamsToArray(history.location.search), { arrayFormat: 'bracket' });
        let params = '&results[]=inspection_type&results[]=conclusion';
        if (filterStr) {
            params += '&' + filterStr;
        }
        dispatch(clearAllMessages());
        dispatch(types.getInspectionsStatusByConclusionRequest());
        return request('statistics?status[]=report' + params, {})
            .then((data: any) => {
                const ret = [];
                const typesArr = [];
                data.result.forEach((item: any) => {
                    const obj: any = {};
                    item.values.forEach((valueItem: any) => {
                        valueItem.id = valueItem.label;
                        obj[valueItem.label] = valueItem.value;
                        const type = item.type;
                        let label = item.label;
                        label = getPrettyCustomFormat(item.label, 'MMM YYYY');
                        obj[type] = label;
                        if (!typesArr.includes(valueItem.label)) {
                            typesArr.push(valueItem.label);
                        }
                    });
                    ret.push(obj);
                });
                dispatch(types.getInspectionsStatusByConclusionSuccess(data));
            })
            .catch((error) => {
                dispatch(types.getInspectionsStatusByConclusionFailure(error));
                dispatch({ type: ERROR, payload: { type: 'getInspectionsStatusByConclusion', error, status: error.status } });
                dispatch(sendErrorMessage(['error_message.dashboard.could_not_load_inspections_conclusion_status_data']));
                catchException('getInspectionsStatusByConclusion', {
                    endpoint: 'statistics?status=report&results=' + params,
                    request: 'statistics?status=report&results=' + params,
                }, { error });
            });
    };
};
export const getInspectionsStatusBySupplierQC = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(clearAllMessages());
        dispatch(types.getInspectionsStatusBySupplierQCRequest());
        const filterStr = qs.stringify(urlParamsToArray(history.location.search), { arrayFormat: 'bracket' });
        let params = '&results[]=inspection_type&results[]=supplier_qc';
        if (filterStr) {
            params += '&' + filterStr;
        }
        return request('statistics?status[]=report' + params, {})
            .then((data: any) => {
                const ret = [];
                const typesArr = [];
                data.result.forEach((item: any) => {
                    const obj: any = {};
                    item.values.forEach((valueItem: any) => {
                        valueItem.colorId = valueItem.label === 'true' ? 'supplier_qc' : 'non_supplier_qc';
                        valueItem.label = valueItem.label === 'true' ? 'Supplier QC' : 'Non supplier QC';
                        valueItem.id = valueItem.label;
                        obj[valueItem.label] = valueItem.value;
                        const type = item.type;
                        let label = item.label;
                        label = getPrettyCustomFormat(item.label, 'MMM YYYY');
                        obj[type] = label;
                        if (!typesArr.includes(valueItem.label)) {
                            typesArr.push(valueItem.label);
                        }
                    });
                    ret.push(obj);
                });
                dispatch(types.getInspectionsStatusBySupplierQCSuccess(data));
            })
            .catch((error) => {
                dispatch(types.getInspectionsStatusByConclusionFailure(error));
                dispatch({ type: ERROR, payload: { type: 'getInspectionsStatusByConclusion', error, status: error.status } });
                dispatch(sendErrorMessage(['error_message.dashboard.could_not_load_inspections_conclusion_status_data____']));
                catchException('getInspectionsStatusByConclusion', {
                    endpoint: 'statistics?status=report&results=' + params,
                    request: 'statistics?status=report&results=' + params,
                }, { error });
            });
    };
};

export const getDefectsStatusBySeverity = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const _p = urlParamsToArray(history.location.search);
        if (_p.defectTypeId) {
            delete _p.defectTypeId;
        }
        const filterStr = qs.stringify(_p, { arrayFormat: 'bracket' });
        let params = 'results[]=month&results[]=defect_severity';
        const catId = getLocationEntry(history.location, 'catid')[0] || '';
        dispatch(clearAllMessages());
        dispatch(types.getDefectsStatusBySeverityRequest());
        if (filterStr) {
            params += '&' + filterStr;
        }
        return request('statistics?' + 'status[]=report&' + params, {})
            .then((data: any) => {
                const ret = [];
                const typesArr = ['minor', 'major', 'critical'];
                data.result.forEach((item: any) => {
                    const obj: any = {};
                    if (item.values.length > 0) {
                        item.values.forEach((valueItem: any) => {
                            obj[valueItem.label] = valueItem.value;
                            const type = item.type;
                            let label = item.label;
                            label = getPrettyCustomFormat(item.label, 'MMM YYYY');
                            obj[type] = label;
                        });
                    } else {
                        const type = item.type;
                        let label = item.label;
                        label = getPrettyCustomFormat(item.label, 'MMM YYYY');
                        obj[type] = label;
                    }
                    ret.push(obj);
                });
                const tableData: any = getDefectsTableDataByCategory(catId, data.defect_type_tree);
                const pieData: [] = getDefectsPieDataByCategory(catId, data.defect_type_tree.children);
                const retObj = {
                    originalData: data,
                    data: ret, types: typesArr, summary: data.summary,
                    defectTypesPie: pieData,
                    defectTypesTable: tableData,
                    colors: getDefectsByCategoryColors(catId, data.defect_type_tree.children),
                };
                dispatch(types.getDefectsStatusBySeveritySuccess(retObj));
            })
            .catch((error) => {
                dispatch({ type: ERROR, payload: { type: 'getDefectsStatusBySeverity', error, status: error.status } });
                dispatch(sendErrorMessage(['error_message.dashboard.could_not_load_defect_severity_status_data']));
                catchException('getDefecsStatusBySeverity', {
                    endpoint: 'statistics?status=report&results=' + params,
                    request: 'statistics?status=report&results=' + params,
                }, { error });
            });
    };
};

export const getDefectsByCategoryColors = (categoryId: string, data: any): Record<string, unknown> => {
    let ret;
    const colors = config.colors;
    const populateData = (d) => {
        const _ret = {};
        d.forEach((element, i: number) => {
            const id = element.defect_type.name;
            const color = colors[i % colors.length];
            _ret[id] = color;
        });
        return _ret;
    };
    if (categoryId === '') {
        return populateData(data);
    } else {
        data.forEach((subCat) => {
            if (subCat.defect_type.id === categoryId) {
                ret = populateData(subCat.children);
            }
        });
    }
    return ret;
}
export const getDefectsPieDataByCategory = (categoryId: string, data: any): any => {
    let ret;
    const populateData = (d) => {
        const _ret = [];
        d.forEach((element) => {
            const obj: any = {};
            obj.id = element.defect_type.name;
            obj.label = element.defect_type.name;
            obj.value = element.total + element.whole_lot;
            obj.description = element.defect_type.description;
            obj.code = element.defect_type.code;
            obj.major = element.major;
            obj.minor = element.minor;
            obj.critical = element.critical;
            obj.total = element.total + element.whole_lot; // element.total does not include whole_lot in endpoint data
            obj.defectId = element.defect_type.id;
            _ret.push(obj);
        });
        return _ret;
    };
    if (categoryId === '') {
        return populateData(data);
    } else {
        data.forEach((subCat) => {
            if (subCat.defect_type.id === categoryId) {
                ret = populateData(subCat.children);
            }
        });
    }
    return ret;
};

export const getDefectsTableDataByCategory = (categoryId: string, data: any) => {
    let ret;
    if (categoryId === '') {
        return data;
    } else {
        data.children.forEach((subCat) => {
            if (subCat.defect_type.id === categoryId) {
                ret = subCat;
            }
        });
    }
    return ret;

};

export const updateDefectsCategoryTableData = (categoryId: string) => {
    return (dispatch, getState) => {
        const dataAll = getDefectsStatusBySeveritySelector(getState());
        const table = categoryId === '' ? dataAll.originalData.defect_type_tree : dataAll.defectTypesTable;
        const defectTypesTable = getDefectsTableDataByCategory(categoryId, table);
        const pieData = getDefectsPieDataByCategory(categoryId, table.children);
        const colors = getDefectsByCategoryColors(categoryId, table.children);
        dispatch(types.getDefectsStatusBySeveritySuccess({
            originalData: dataAll.originalData,
            data: dataAll.data, types: dataAll.types, summary: dataAll.summary,
            defectTypesPie: pieData,
            defectTypesTable,
            colors,
        }));
    };
}

export const getLastestInspectionLocationsRequest = () => createAction(types.GET_LATEST_INSPECTIONS_REQUEST);
export const getLastestInspectionLocationsSuccess = createAction(types.GET_LATEST_INSPECTIONS_SUCCESS, ((data) => {
    return { type: types.GET_LATEST_INSPECTIONS_SUCCESS, payload: { data } };
}));

export const getLastestInspectionLocations = (): AppThunk => {
    return async (dispatch) => {
        return request('get_latest_locations', {
            method: 'post',
        })
            .then((data: { response: types.LatestInspections }) => {
                dispatch(getLastestInspectionLocationsSuccess(data.response));
            })
            .catch((error) => {
                catchException('getLastestInspectionLocations', {
                    endpoint: 'get_latest_locations',
                    request: 'get_latest_locations',
                }, { error });
            });
    };
};