import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { Grid, Header, Segment } from 'semantic-ui-react';
import Icon from '../../../../base/components/basic/Icon';

const NoAuditAccessPage = (): React.ReactElement => {
    let mailBody = "Cannot access web-based audit with location address " + location.href + '.%0D%0A%0D%0A';
    const mailTo = 'mailto:support@qarmainspect.com?subject=' + "Qarma - Invalid audit access" + '&body=' + mailBody;

    return (
        <Grid container className='inspections-menu'>
            <span className='pageContainer'>
                <Header as='h2' className='flex flex-row text-lg font-bold'><Icon name='error' className='text-danger mr-1'/>Unable to open page</Header>
                <Segment.Group raised>
                    <Segment padded>
                        <DocumentTitle title={`Invalid access - Qarma`} />
                        <p><FormattedMessage id='web_based_audit.cant_open_page' /></p>
                        <p><FormattedMessage id='web_based_audit.try_again_later_or_contact_support' /> <a href={mailTo}><FormattedMessage id='base.qarma_support' /></a>.</p>
                    </Segment>
                </Segment.Group>
            </span>
        </Grid>
    );
};

export default NoAuditAccessPage;
