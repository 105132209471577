/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 */
export type AuditReportCustomFieldsDate = {
    date: string | null;
    previous_date: string | null;
    type: AuditReportCustomFieldsDate.type;
};

export namespace AuditReportCustomFieldsDate {

    export enum type {
        DATE = 'Date',
    }


}

