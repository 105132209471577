import React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { byId } from '../../../base/types';
import { twMerge, useFormatMessage } from '../../../base/utils';
import UsersSelector from '../../users/components/UsersSelector';

interface ApproverSelectorProps {
    users?: ListUsersUser[];
    showSelector: boolean;
    showEmailInSelector: boolean;
    allowMultipleSelection?: boolean;
    showUnassigned?: boolean;
    hasSelection: boolean;
    hasCancel?: boolean;
    value: string[] | undefined;
    className?: string;
    disabled?: boolean;
    handleChange(data: DropdownProps): void;
    handleCancel(): void;
    handleSave(): void;
}
const ApproverSelector = (props: ApproverSelectorProps): React.ReactElement => {
    const { hasCancel = true, disabled } = props;
    return (<span className={twMerge('w-full')}>
        {props.showSelector && <div className='w-full flex'>
            <UsersSelector
                name='approverSelector'
                fixedWidth={false}
                users={convertToById(props.users)}
                placeholder={useFormatMessage('approval.select_approver')}
                handleChange={props.handleChange}
                showExpanded={true}
                showEmailInSelector={props.showEmailInSelector}
                withIcon={true}
                value={props.value}
                allowMultipleSelection={props.allowMultipleSelection}
                showUnassigned={props.showUnassigned}
                disabled={disabled}
                fluid={true}
                className={props.className}
            />
            <div className='flex h-10 ml-2'>
                {props.hasSelection && <Button primary className='py-1 px-2 text-sm' onClick={props.handleSave}><Icon name='check' /></Button>}
                {hasCancel && <Button className='py-1 px-2 text-sm' onClick={props.handleCancel}><RemoveIcon className='' iconClassName='p-0 text-sml' /></Button>}
            </div>

        </div>}
    </span>
    );
};

function convertToById(users: ListUsersUser[]): byId<ListUsersUser> {
    const usersById: byId<ListUsersUser> = {};
    users.forEach((user) => {
        usersById[user.id] = user;
    });
    return usersById;
}

export default ApproverSelector;
