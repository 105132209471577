import { MAX_UPLOAD_FILE_SIZE, WARN_UPLOAD_FILE_SIZE } from '../../../base/config';
import { UploadingFile } from '../types';

export const uploadFiles = (action: (file: File) => unknown) => {
    return function update() {
        const warnFiles = [];
        const tooBigFiles = [];
        const okFiles = [];
        const upload = this.state.upload;
        const filesObj = this.state.upload.droppedFiles;
        const tmpFiles = Array.from(filesObj);
        const files = Array.from(filesObj);
        tmpFiles.forEach((file: File, i: number) => {
            if (file.size >= MAX_UPLOAD_FILE_SIZE) {
                tooBigFiles.push(file);
                files.splice(i, 1);
            } else {
                if (file.size >= WARN_UPLOAD_FILE_SIZE) {
                    warnFiles.push(file);
                } else {
                    okFiles.push(file);
                }
            }
        });
        upload.warnFiles = warnFiles;
        upload.tooBigFiles = tooBigFiles;
        upload.okFiles = okFiles;
        upload.filesToUpload = files;
        if (warnFiles.length > 0 || tooBigFiles.length > 0) {
            upload.showWarn = true;
        } else {
            files.forEach((file: File) => {
                action(file);
            });
        }
        return { upload, uploadBegin: false, waitingForUploadedAttachments: 0 };
    };
};
export const handleFileDrop = (files) => {
    return function update(): any {
        return { upload: { droppedFiles: files } };
    };
};
export const handleFileDrop2 = (files: FileList) => {
    return function update(): any {
        const droppedFiles = [];
        for (let i = 0; i < files.length; i++) {
            droppedFiles.push(files.item(i))
        }
        return { upload: { droppedFiles } };
    };
};

export const calculatePrettySize = (size: number): string => {
    // TODO: localise calculatePrettySize results
    if (size) {
        if (size > 1000000) {
            return (size / 1000000).toFixed(2) + ' MB';
        }
        return (size / 1000).toFixed(2) + ' KB';
    }
    return '';
};

export const removeAttachment = (attachmentURL: string) => {
    return function update(): void {
        this.props.actions.removeAttachmentAction(this.props.attachToItemId, this.props.attachToType, attachmentURL);
    };
};

export const isUploadComplete = () => {
    return function update(): any {
        const upload = Object.assign({}, this.state.upload);
        let isComplete = false;
        const hasFiles = this.state.uploading.length;
        if (hasFiles) {
            isComplete = true;
            this.state.uploading.forEach((upload: UploadingFile) => {
                if (upload.progress < 100) {
                    isComplete = false;
                }
            });
        }
        upload.isComplete = isComplete;
        return { upload };
    }
}

export const handleWarning = (proceed: boolean, action: (file: File) => unknown) => {
    return function update(): any {
        const upload = Object.assign({}, this.state.upload);
        if (proceed) {
            const files = this.state.upload.filesToUpload;
            files.forEach((file: File) => {
                action(file);
            });
        } else {
            upload.droppedFiles = [];
        }
        upload.showWarn = false;
        return { upload };
    }
}

export const removeWarnFile = (file: File) => {
    return function update(): any {
        const files = Object.assign([], this.state.upload.filesToUpload);
        const warnFiles = Object.assign([], this.state.upload.warnFiles);
        const upload = this.state.upload;
        const index = files.indexOf(file);
        if (index > -1) {
            files.splice(index, 1);
            warnFiles.splice(index, 1);
        }
        upload.filesToUpload = files;
        upload.warnFiles = warnFiles;
        upload.warnFiles = warnFiles;
        return ({ upload });
    }
}