import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { createRole } from '../actions';

type NewRoleDialogProps = {
    show: boolean;
    onClose();
}

const NewRoleDialog = (props: NewRoleDialogProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const [roleName, setRoleName] = useState<string>();
    const handleChange = (evt, item): void => {
        setRoleName(item.value as string);
    }
    const crateRole = (): void => {
        dispatch(createRole(roleName))
        props.onClose();
    }

    return <Modal
        open={props.show}
        closeIcon={true}
        onClose={props.onClose}
    >
        <Modal.Header><FormattedMessage id='roles.new_role.dialog_header' /></Modal.Header>
        <Modal.Content>
            <Form onSubmit={crateRole}>
                <Form.Field width={8}>
                    <label><FormattedMessage id='roles.new_role.dialog_input' /></label>
                    <Input
                        type='text'
                        name='role_name'
                        onChange={handleChange}
                        autoFocus={true}
                    />
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button disabled={!(roleName && roleName.length > 0)} onClick={crateRole} positive><FormattedMessage id='roles.ok' /></Button>
            <Button onClick={props.onClose}><FormattedMessage id='roles.cancel' /></Button>
        </Modal.Actions>
    </Modal>
}

export default NewRoleDialog;