import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Modal } from 'semantic-ui-react';
import { DatePicker } from '../../../globals/components';
import InspectionCollection from '../../../inspections/components/InspectionCollection';
import { Inspection, InspectionType } from '../../../inspections/types';

type CollectBookingProps = {
    inspections: Inspection[];
    removeInspection(inspection: Inspection);
    cancel();
    save(date: moment.Moment);
    inspectionType: InspectionType;
}

const CollectBooking = (props: CollectBookingProps): React.ReactElement => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newDate, setNewDate] = useState(null);

    const save = (): void => {
        props.save(newDate);
        setOpenModal(false);
        setNewDate(null);
    }

    const header = <Fragment><FormattedMessage id='inspections.inspections_to_book' />{props.inspectionType ? ' - ' + props.inspectionType.name : ''}</Fragment>
    const action = <Button primary disabled={props.inspections.length === 0} onClick={(): void => setOpenModal(true)}><FormattedMessage id='supplier_booking.bookings.collection.setdate' /></Button>
    return <Fragment>
        <InspectionCollection
            collectedInspections={props.inspections}
            enable={true}
            cancel={props.cancel}
            removeInspection={props.removeInspection}
            header={header}
            action={action}
        />
        <Modal
            size='tiny'
            open={openModal}
        >
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <FormattedMessage id="globals.select_date" />
                    </Form.Field>
                    <Form.Field>
                        <DatePicker
                            date={newDate}
                            handleChange={(date): void => setNewDate(date)}
                            pastDates={false}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary disabled={newDate == null} onClick={save}>
                    <FormattedMessage id='globals.confirmation.save' />
                </Button>
                <Button onClick={(): void => { setOpenModal(false) }}>
                    <FormattedMessage id='globals.confirmation.cancel' />
                </Button>
            </Modal.Actions>
        </Modal>
    </Fragment>
}

export default CollectBooking;