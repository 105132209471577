import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionEvent, EventType } from '../../../backend_api/models';

type Props = {
    event: CorrectiveActionEvent
}

const NotifiedEvent = (props: Props) => {
    const notifiedEvent = props.event.events.find((event) => event.event_type == EventType.NotifiedEvent)

    return <Fragment>
        {notifiedEvent &&
            <div className='pt-4'>
                <label className='mb-1'><FormattedMessage id={'corrective_actions.events.people_notified'} /></label>
                {notifiedEvent.notified_event.notified_emails.map((email) => {
                    return <div key={email} className='flex items-center text-secondary'>
                        {email}
                    </div>
                })}
            </div>
        }
    </Fragment>
}
export default NotifiedEvent;