import React from 'react';
import { ReportCheckpoint } from '../../../../../backend_api/models';
import { Checkpoint } from '../../../../checklists/types';
import CheckpointStatus from '../report2/CheckpointStatus';

type CheckpointsStatusIndicatorProps = {
    checkpoints: Checkpoint[];
    onlyStatusEnabled?: boolean;
};
const CheckpointsStatusIndicator = (props: CheckpointsStatusIndicatorProps): React.ReactElement => {
    const { onlyStatusEnabled = false } = props;
    let checkpoints = props.checkpoints;
    if (onlyStatusEnabled) {
        checkpoints = props.checkpoints.filter((cp) => (!cp.status_enabled && cp.multiple_choice && cp.multiple_choice.options.filter((mp) => mp.chosen).length > 0) || cp.status_enabled);
    }
    const ret = [];
    const w = 100 / checkpoints.length;
    checkpoints.forEach((checkpoint) => {
        ret.push(<CheckpointStatus width={w} checkpoint={checkpoint as unknown as ReportCheckpoint} onlyStatusEnabled={onlyStatusEnabled} />)
    })
    return <div className='csIndicator'>{ret}</div>;
}

export default CheckpointsStatusIndicator;
