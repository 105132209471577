/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCustomFieldNotSet } from './AuditCustomFieldNotSet';
import type { AuditCustomFieldNull } from './AuditCustomFieldNull';
import type { AuditCustomFieldText } from './AuditCustomFieldText';

/**
 *   Value for a text based custom field
 *
 */
export type AuditCustomFieldTextValue = {
    new_value: (AuditCustomFieldNotSet | AuditCustomFieldNull | AuditCustomFieldText);
    previous_value: string | null;
    type: AuditCustomFieldTextValue.type;
};

export namespace AuditCustomFieldTextValue {

    export enum type {
        TEXT = 'Text',
    }


}

