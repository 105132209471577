/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a string that represents a number
 *
 */
export type AuditCustomFieldNumber = {
    number: string;
    type: AuditCustomFieldNumber.type;
};

export namespace AuditCustomFieldNumber {

    export enum type {
        NUMBER = 'number',
    }


}

