/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldDefinitionText = {
    type: InputCustomFieldDefinitionText.type;
};

export namespace InputCustomFieldDefinitionText {

    export enum type {
        TEXT = 'Text',
    }


}

