import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { DefectLink } from '../../../backend_api/models';
import Link from '../../../base/components/basic/Link';
import { getStringsSeparatedBy } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import FileItem from '../../attachments/components/FileItem';
import { SeverityLabel } from '../../globals/components/Severity';

type Props = {
    className?: string;
    links: DefectLink[];
};

const DefectListLinks = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <Fragment>
        {props.links.map((link) => {
            /* const label = <Popup trigger={<span>{getInspectionLabel(link.order_number, link.item_name, link.item_number)}</span>}>
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.order_number' })} text={link.order_number} />
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.item_name' })} text={link.item_name} />
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.item_number' })} text={link.item_number} />
            </Popup>; */
            return (
                <div className='flex' key={link.id}>
                    <div key={'insp_link' + link.id} className=''>
                        <div className='flex'>
                            <Link underline={false} to={'/inspections/reports/' + link.inspection_id + '#defect_' + link.id} className='flex items-center'><SeverityLabel className='py-1 px-4 sm:w-20' severity={link.severity} /></Link>
                            <Link to={'/inspections/reports/' + link.inspection_id + '#defect_' + link.id} className='flex items-center'><div>{link.defect_type && getStringsSeparatedBy([link.defect_type.code, link.defect_type.name, link.defect_type.description], '.')}</div></Link>
                        </div>
                        <div className='flex flex-wrap'>
                            {link.file_resources.map((file) => {
                                return <div key={'defect_image_' + file.id} className=' pr-4'>
                                    <FileItem border={false} showFilename={false} file={file} className='sm:w-112 sm:mx-0' showDelete={false} imageClassName='sm:w-112' />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            )
        })}
    </Fragment >
}
export default DefectListLinks;
