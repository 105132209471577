import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { Inspection } from '../../../inspections/types';
import { SupplierBooking } from '../../types';

type BookingStatusOrderProps = {
    order: SupplierBooking;
    children: ReactElement;
};

const BookingStatusOrder = (props: BookingStatusOrderProps): React.ReactElement => {
    const overdues = props.order.inspections.filter((insp: Inspection) => {
        return insp.overdue;
    });
    const overdueCnt = overdues.length;
    return overdues.length > 0 ? <Popup trigger={<div className='border-b border-danger'>{props.children}</div>}>
        <FormattedMessage id='supplier_booking.bookings.no_of_overdue_items' values={{ overdueCnt }} />
    </Popup> : props.children;
};
export default BookingStatusOrder;
