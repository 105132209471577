import { createAction } from 'redux-actions';
import { createAction as createActionTS } from 'typesafe-actions';
import { request } from '../../base/api';
import { AppThunk, RequestError } from '../../base/types';
import history from '../../store/history';
import { catchException } from '../errorHandling/handler';
import { clearAllMessages, clearErrorMessage, clearStatusMessage, sendErrorMessage, sendGlobalMessage, sendStatusMessage } from '../messages/actions';
import { orderWithInspectionsRequestSuccess } from './actions/editInspectionActions';
import {
    INSPECTIONS_GET_ATTACHMENTS_FAILURE,
    INSPECTIONS_GET_ATTACHMENTS_REQUEST,
    INSPECTIONS_GET_ATTACHMENTS_SUCCES, INSPECTION_REMOVE_ATTACHMENT_FAILURE,
    INSPECTION_REMOVE_ATTACHMENT_REQUEST,
    INSPECTION_REMOVE_ATTACHMENT_SUCCES, ORDER_GET_ATTACHMENTS_FAILURE,
    ORDER_GET_ATTACHMENTS_REQUEST,
    ORDER_GET_ATTACHMENTS_SUCCES,
    ORDER_REMOVE_ATTACHMENT_FAILURE,
    ORDER_REMOVE_ATTACHMENT_REQUEST,
    ORDER_REMOVE_ATTACHMENT_SUCCES,
    SET_LAST_VIEWED_INSPECTION_PAGE,
    UPDATE_ORDER_FROM_RESPONSE
} from './actionTypes';
import { getInspectionsMap, getOrderWithInspections as orderWithInspectionsSelector } from './selectors/selectors';
import * as types from './types';
import { isOrderCombinedInspection, updateInspectionWithFragment } from './Utils';

const MESSAGE_HIDE_AFTER = 5000;

export const resetInspection = (inspectionId: string, keepAssignedUser: boolean, keepScheduledInspectionDate: boolean): AppThunk => {
    return async (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/reset';
        dispatch(clearErrorMessage());
        dispatch(sendStatusMessage(['status_message.resetting_inspection'], 0, true));
        return request(url, {
            method: 'POST',
            body: JSON.stringify({
                'keep_assigned_user': keepAssignedUser,
                'keep_scheduled_inspection_date': keepScheduledInspectionDate
            })
        }).then((data) => {
            dispatch(orderWithInspectionsRequestSuccess(data));
            dispatch(sendStatusMessage(['status_message.the_inspection_was_succesfully_reset'], 5000));
        }).catch((error) => {
            console.log('Error', error)
            catchException('searchInspections', {
                endpoint: 'inspections/:inspection_id/reset',
                request: url,
                status: error.status,
            }, { error, inspectionId });
        });
    };
}

// Update order with inspection lines
const updateOrderFromResponse = (data: any): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch({
            type: UPDATE_ORDER_FROM_RESPONSE,
            payload: {
                data,
            },
        });
    };
};

export const updateOrderWithInspectionsRequest = createAction(types.UPDATE_ORDER_AND_INSPECTIONS_REQUEST, () => ({ isSaving: true }));
export const updateOrderWithInspectionsSuccess = createAction(types.UPDATE_ORDER_AND_INSPECTIONS_SUCCESS, () => ({ isSaving: false }));
export const updateOrderWithInspectionsFailure = createAction(types.UPDATE_ORDER_AND_INSPECTIONS_FAILURE, (message, status) => ({ message, status, isSaving: false }));
export const updateOrderWithInspections = (
    orderId: string,
    order: any,
    isAutosave: boolean,
    reload: boolean,
    send: { send: boolean; type?: string },
    notifyInspectors?: boolean,
    redirectToUrl?: string,
    updateWithResponse?: boolean): AppThunk => {

    return async (dispatch): Promise<void> => {
        let url = 'orders/' + orderId;
        if (notifyInspectors) {
            url += '?notify=true';
        }
        dispatch(clearAllMessages());
        dispatch(sendStatusMessage(['status_message.saving'], 0, true));
        dispatch(updateOrderWithInspectionsRequest());
        return request(url, {
            method: 'PUT',
            body: JSON.stringify(order),
        })
            .then((data) => {
                if (send.send) {
                    if (send.type === 'global') {
                        notifyInspectors ? dispatch(sendGlobalMessage(['status_message.order_was_saved_and_inspector_notified'], MESSAGE_HIDE_AFTER))
                            :
                            dispatch(sendGlobalMessage(['status_message.order_was_saved'], MESSAGE_HIDE_AFTER));
                    } else {
                        notifyInspectors ? dispatch(sendStatusMessage(['status_message.order_was_saved_and_inspector_notified'], MESSAGE_HIDE_AFTER))
                            :
                            dispatch(sendStatusMessage(['status_message.order_was_saved'], MESSAGE_HIDE_AFTER));
                    }
                }
                if (reload) {
                    dispatch(orderWithInspectionsRequestSuccess(data));
                }
                if (updateWithResponse) {
                    dispatch(updateOrderFromResponse(data));
                }
                if (redirectToUrl) {
                    history.push(redirectToUrl);
                }
                dispatch(updateOrderWithInspectionsSuccess());
            })
            .catch((error) => {
                catchException('updateOrderWithInspections', {
                    endpoint: 'orders/[orderId]',
                    request: url,
                    status: error.status,
                }, { error, body: order, method: 'PUT', orderId, isAutosave, reload });

                dispatch(clearStatusMessage());
                dispatch(sendErrorMessage(['error_message.order_could_not_be_saved']));
                dispatch(updateOrderWithInspectionsFailure(null, error, false));
            });
    };
};

export const updateInspection = (inspectionId: string, inspectionData: any) => {
    return async (dispatch): Promise<void> => {
        dispatch(updateInspectionRequest(true));
        return request('inspections/' + inspectionId, {
            method: 'put',
            body: JSON.stringify(inspectionData),
        })
            .then((data: any) => {
                dispatch(updateInspectionSuccess(false, data));
                return data;
            })
    };
};

export const updateInspectionRequest = createAction(types.UPDATE_INSPECTION_REQUEST, () => ({ isSaving: true }));
export const updateInspectionSuccess = createAction(types.UPDATE_INSPECTION_SUCCESS, () => ({ isSaving: false }));
export const updateInspectionFailure = createAction(types.UPDATE_INSPECTION_FAILURE, (message, status) => ({ message, status, isSaving: false }));

export const updateSingleInspectionSuccess = createAction(types.UPDATE_SINGLE_INSPECTION_SUCCESS, (isSaving, inspectionId, data) => ({ isSaving, inspectionId, data }));
export const updateSingleInspectionRequest = createActionTS(types.UPDATE_SINGLE_INSPECTION_REQUEST, (isSaving: boolean, inspectionId: string, data: any, inspectionData: any) => {
    return {
        type: types.UPDATE_SINGLE_INSPECTION_REQUEST,
        payload: { isSaving, inspectionId, data, inspectionData },
    };
});

export const updateSingleInspectionFailure = createAction(types.UPDATE_SINGLE_INSPECTION_FAILURE, (message, status) => ({ message, status, isSaving: false }));


export const updateSingleInspection = (inspectionId: string, inspectionData: any): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const order = orderWithInspectionsSelector(getState());
        const isCombinedInspection = isOrderCombinedInspection(order);
        if (!isCombinedInspection) {
            let item = getInspectionsMap(getState())[inspectionId];
            item = updateInspectionWithFragment(item, inspectionData);
            dispatch(updateSingleInspectionRequest(true, inspectionId, item, inspectionData));
            // storing the inspection is done in the updateInspectionLogic method
        } else {
            let item = order.inspections[0];
            item = updateInspectionWithFragment(item, inspectionData);
            dispatch(updateSingleInspectionRequest(true, inspectionId, item, inspectionData));
        }
    };
};

export const updateOrder = (orderId: string, order: any, updateData = true, sendMessage = true, notifyInspectors = false): AppThunk => {
    return async (dispatch): Promise<unknown> => {
        let url = 'orders/' + orderId;
        if (notifyInspectors) {
            url += '?notify=true';
        }
        return request(url, {
            method: 'PUT',
            body: JSON.stringify(order),
        })
            .then((data) => {
                if (sendMessage) {
                    dispatch(sendStatusMessage(['status_message.order_was_saved'], MESSAGE_HIDE_AFTER));
                }
                if (updateData) {
                    dispatch(orderWithInspectionsRequestSuccess(data));
                }
                return data;
            })
            .catch((error: RequestError) => {
                dispatch(sendErrorMessage(['error_message.data_could_not_be_saved'], MESSAGE_HIDE_AFTER));
                catchException('updateOrder', {
                    endpoint: 'inspections/[orderId]',
                    request: 'inspections/' + orderId,
                    status: error.status,
                }, { error, orderId, order, notifyInspectors, updateData });
                return { error: true, status: error.status };
            });
    };
};

export const storeSingleInspection = (inspectionId: string, inspectionData: any, sendMessage = true): AppThunk => {
    return async (dispatch): Promise<void> => {
        request('inspections/' + inspectionId, {
            method: 'put',
            body: JSON.stringify(inspectionData),
        })
            .then(() => {
                if (sendMessage) {
                    dispatch(sendStatusMessage(['status_message.order_was_saved'], MESSAGE_HIDE_AFTER));
                }
            }).catch((error) => {
                dispatch(sendErrorMessage(['error_message.data_could_not_be_saved'], MESSAGE_HIDE_AFTER));
                catchException('storeSingleInspection', {
                    endpoint: 'inspections/[inspectionId]',
                    request: 'inspections/' + inspectionId,
                    status: error.status,
                }, { error, inspectionId, inspectionData });
            });
    };
};

// Get a list of an inspection's attachments
export const getInspectionAttachments = (inspectionId: string) => {
    return (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/attachments';
        dispatch({
            type: INSPECTIONS_GET_ATTACHMENTS_REQUEST,
            payload: {
                isFetching: false,
                inspectionId,
            },
        });
        return request(url, {})
            .then((data) => {
                dispatch({
                    type: INSPECTIONS_GET_ATTACHMENTS_SUCCES,
                    payload: {
                        isFetching: false,
                        inspectionId,
                        attachments: data,
                    },
                });
            })
            .catch((error) => {
                catchException('getInspectionAttachments', {
                    endpoint: 'inspections/[inspectionId]/attachments',
                    request: url,
                    status: error.status,
                }, { error, inspectionId });

                dispatch({
                    type: INSPECTIONS_GET_ATTACHMENTS_FAILURE,
                    payload: {
                        isFetching: false,
                        inspectionId,
                        message: error.json.statusText || 'statusText: n/a',
                        status: error.status,
                    },
                });
            });
    };
};

// Get a list of an order's attachments
export const getOrderAttachments = (orderId: string) => {
    return (dispatch): Promise<void> => {
        const url = 'orders/' + orderId + '/attachments';
        dispatch({
            type: ORDER_GET_ATTACHMENTS_REQUEST,
            payload: {
                isFetching: false,
                orderId,
            },
        }, dispatch);
        return request(url, {})
            .then((data: any) => {
                dispatch({
                    type: ORDER_GET_ATTACHMENTS_SUCCES,
                    payload: {
                        isFetching: false,
                        orderId,
                        attachments: data,
                    },
                });
            })
            .catch((error) => {
                catchException('getOrderAttachments', {
                    endpoint: 'orders/[orderId]/attachments',
                    request: url,
                    status: error.status,
                }, { error, orderId });

                dispatch({
                    type: ORDER_GET_ATTACHMENTS_FAILURE,
                    payload: {
                        isFetching: false,
                        orderId,
                        message: error.json.statusText || 'statusText: n/a',
                        status: error.status,
                    },
                });
            });
    };
};

// Remove an order's attachments
export const removeOrderAttachment = (orderId: string, attachment: string) => {
    return (dispatch): Promise<void> => {
        const url = 'orders/' + orderId + '/attachments?url=' + attachment;
        dispatch({
            type: ORDER_REMOVE_ATTACHMENT_REQUEST,
            payload: {
                isFetching: true,
                orderId,
                attachment,
            },
        }, dispatch);
        return request(url, {
            method: 'DELETE',
            body: {
                url: JSON.stringify(attachment),
            },
        })
            .then(() => {
                dispatch({
                    type: ORDER_REMOVE_ATTACHMENT_SUCCES,
                    payload: {
                        isFetching: true,
                    },
                });
                dispatch(getOrderAttachments(orderId));
            })
            .catch((error) => {
                catchException('removeOrderAttachment', {
                    endpoint: 'orders/[orderId]/attachments?url=[attachment]',
                    request: url,
                    status: error.status,
                }, {
                    error, orderId, body: {
                        url: JSON.stringify(attachment),
                    }, method: 'DELETE',
                });

                dispatch({
                    type: ORDER_REMOVE_ATTACHMENT_FAILURE,
                    payload: {
                        isFetching: true,
                        message: error.json.statusText || 'statusText: n/a',
                        status: error.status,
                    },
                });
            });
    };
};

export const removeInspectionAttachment = (inspectionId: string, attachment: string) => {
    return (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/attachments?url=' + attachment;
        dispatch({
            type: INSPECTION_REMOVE_ATTACHMENT_REQUEST,
            payload: {
                isFetching: true,
                inspectionId,
                attachment,
            },
        }, dispatch);
        return request(url, {
            method: 'DELETE',
            body: {
                url: JSON.stringify(attachment),
            },
        })
            .then(() => {
                dispatch({
                    type: INSPECTION_REMOVE_ATTACHMENT_SUCCES,
                    payload: {
                        isFetching: true,
                    },
                });
                dispatch(getInspectionAttachments(inspectionId));
            }, dispatch)
            .catch((error) => {
                catchException('removeInspectionAttachment', {
                    endpoint: 'inspections/[inspectionId]/attachments?url=[attachment]',
                    request: url,
                    status: error.status,
                }, {
                    error, inspectionId, body: {
                        url: JSON.stringify(attachment),
                    }, method: 'DELETE',
                });
                dispatch({
                    type: INSPECTION_REMOVE_ATTACHMENT_FAILURE,
                    payload: {
                        isFetching: true,
                        message: error.json.statusText || 'statusText: n/a',
                        status: error.status,
                    },
                });
            });
    };
};

export const setLastViewedInspectionPage = (url: string) => {
    return (dispatch): void => {
        dispatch({
            type: SET_LAST_VIEWED_INSPECTION_PAGE,
            payload: {
                url,
            },
        });
    };
};

export const setInspectionsBaseURL = (baseurl) => {
    return (dispatch): void => {
        dispatch({
            type: types.SET_INSPECTIONS_BASE_URL,
            payload: {
                baseurl,
            },
        });
    };
};

export const getInspectionTypes = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('inspection_types', {})
            .then((data: any) => {
                const inspectionTypes = data.inspection_types;
                const inspectionTypesById = {};
                const inspectionTypesSelector = [];
                inspectionTypes.forEach((inspectionType: types.InspectionType) => {
                    inspectionTypesById[inspectionType.id] = inspectionType;
                    const obj = {
                        text: inspectionType.name,
                        key: inspectionType.tag,
                        value: inspectionType,
                    };
                    inspectionTypesSelector.push(obj);
                });
                dispatch({
                    type: types.GET_INSPECTIONS_TYPES,
                    payload: {
                        isFetching: true,
                        inspectionTypes: data.inspection_types,
                        inspectionTypesById,
                    },
                });
            })
            .catch((error) => {
                catchException('getInspectionTypes', {
                    endpoint: 'inspection_types',
                    request: 'inspection_types',
                    status: error.status,
                }, { error });
            });
    };
};
