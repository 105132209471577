import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Table } from 'semantic-ui-react';
import { deepCopy } from '../../../base/utils';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { TextData } from '../types';

type TextDetailsProps = {
    data: TextData;
    language: string;
    update(data: TextData);
}

const TextDetails = (props: TextDetailsProps): React.ReactElement => {
    const intl = useIntl();
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        if (!props.data) {
            props.update({ inputs: [], version: 1, use_custom_table: false, custom_table_definition_id: null });
        }
    }, [props.data])

    const saveInput = (name: MultiLanguageText, index: number): void => {
        const dataCopy = deepCopy(props.data);
        dataCopy.inputs[index].name = name;
        props.update(dataCopy);
    }

    const deleteInput = (index: number): void => {
        const dataCopy = deepCopy(props.data);
        dataCopy.inputs.splice(index, 1);
        props.update(dataCopy);
    }

    const addInput = (): void => {
        const dataCopy = deepCopy(props.data);
        dataCopy.inputs.push({ name: { C: '' }, value: '' })
        setIsEditing(true);
        props.update(dataCopy);
    }

    return (<Fragment>
        <Table className="multiple-choice">
            <Table.Header>
                <Table.HeaderCell>
                    <FormattedMessage id={'checklists.editor.radio.description'} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage id={'checklists.editor.radio.delete'} />
                </Table.HeaderCell>
            </Table.Header>
            {props.data && props.data.inputs.map((row, index) => {
                return (
                    <Table.Row key={index + row.name.C} data-test-id='text-input-row'>
                        <Table.Cell>
                            <MultiLangualText
                                originalText={row.name}
                                language={props.language}
                                save={(name): void => saveInput(name, index)}
                                presentation='area'
                                placeholder={intl.formatMessage({ id: 'checklists.editor.text.placeholder' })}
                                onKeyDown={(e): void => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        addInput();
                                    }
                                }}
                                focus={isEditing && index === props.data.inputs.length - 1}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Button disabled={props.language !== 'C'} onClick={(): void => deleteInput(index)} data-text-id='remove-text-input-button'>-</Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })}
            <Table.Row>
                <Table.Cell>
                    <Button disabled={props.language !== 'C'} onClick={addInput} data-text-id='add-text-input-button'>+</Button>
                </Table.Cell>
                <Table.Cell />
            </Table.Row>
        </Table>
    </Fragment>)
}

export default TextDetails;