import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Divider, Form, Header, Input, Label, Loader, Message, Segment } from 'semantic-ui-react';
import FormField from '../../../base/components/basic/form/FormField';
import { useAppDispatch } from '../../../store';
import { getAuthenticationList, getLoginFailed } from '../selectors';
import { UserCreds } from '../types';

type OwnProps = {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: UserCreds;
    email: string;
    password?: string;
    goToV1?: boolean;
    usernameInput(element: Input): void;
    clearUserData(): void;
    changeAccount(): void;
    handleChange(e: ChangeEvent, { name, value }): void;
    handleForm(e: React.FormEvent<HTMLFormElement>): void;
};

type LoginBoxProps = OwnProps;
const LoginBox = (props: LoginBoxProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const formatMessage = intl.formatMessage;
    const pwLabel: string = formatMessage({ id: 'login.form.password' });
    const emailPlaceholder: string = formatMessage({ id: 'login.email' });
    const authenticationList = useSelector(getAuthenticationList);
    const loginFailed = useSelector(getLoginFailed);
    const [pwType, setPwType] = useState<'text' | 'password'>('password');

    useEffect(() => {
        if (authenticationList.length == 1) {
            // dispatch(setOrganization(authenticationList[0]))
        }
        else if (authenticationList.length > 1) {
            const orgId = localStorage.getItem('last_login:' + authenticationList[0].account_id);
            authenticationList.forEach((auth) => {
                if (auth.organization_id == orgId) {
                    // dispatch(setOrganization(auth))
                }
            });
        }
    }, [authenticationList])


    const loginDialog =
        <Fragment>
            <Header as='h1'><FormattedMessage id='login.sing_in' /></Header>
            <Divider hidden />
            <Form size='large' onSubmit={props.handleForm}>
                {props.user === undefined &&
                    <Fragment>
                        <FormField>
                            <label><FormattedMessage id='login.enter_username' /></label>
                            <Input
                                type='email'
                                name='email'
                                autoFocus
                                placeholder={emailPlaceholder}
                                disabled={props.isFetching}
                                autoComplete='off'
                                onChange={props.handleChange}
                                icon='user'
                                iconPosition='left'
                            />
                        </FormField>
                        <FormField className=''>
                            <label><FormattedMessage id='login.enter_password' /></label>
                            <Input
                                type={pwType}
                                name='password'
                                placeholder={pwLabel}
                                disabled={props.isFetching}
                                autoComplete='off'
                                onChange={props.handleChange}
                                icon='lock'
                                iconPosition='left' />
                        </FormField>
                        <div className='items-center flex text-sm space-x-5 pt-1 pb-2 mb-2 cursor-pointer' onClick={() => setPwType(pwType === 'text' ? 'password' : 'text')} >
                            <Checkbox name='changePw' checked={pwType === 'text'} />
                            <span><FormattedMessage id='login.show_password' /></span>
                        </div>
                        {loginFailed && <Message negative>
                            <FormattedMessage id='login.error.password_or_user_incorrect' />
                        </Message>}
                    </Fragment>
                }
                <Form.Field>
                    <Button fluid disabled={props.isFetching || props.email === '' || props.password === ''}>
                        {
                            props.isFetching ? <Loader size='mini' active inline='centered'></Loader> : <FormattedMessage id='login.next' />
                        }
                    </Button>
                    <Link to='reset_pw' className='link noUnderline text-base mt-4 flex' onClick={(): void => props.clearUserData()}><FormattedMessage id='login.forgot_password' /></Link>
                </Form.Field>
            </Form>
        </Fragment>

    const orgSelector = <div className='flex flex-col items-center'>
        <FormattedMessage id='login.select_organisation' />
        <Divider />
        {authenticationList.map((auth, index) => {
            return <div key={auth.organization_id} className='justify-items-center cursor-pointer'><Label className='w-fit-content' onClick={(): any => null /* dispatch(setOrganization(authenticationList[index])) */} key={auth.organization_id}>{auth.organization_name}</Label><Divider /></div>
        })}
    </div>

    return (
        <div className='flex justify-center mx-4 md:mx-0 md:mb-32'>
            <Segment padded className='flex w-136 flex-col'>
                {authenticationList.length > 1 ? orgSelector :
                    loginDialog
                }
            </Segment>
        </div>
    );
};

export default LoginBox;
