import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { DashboardPopupInspection } from '../../../../backend_api/models';
import { InfoIcon, Space, Spacer } from '../../../globals/components/Utils';
import { Inspection } from '../../types';
import { InspectionsWeekviewInspectionExtended } from '../dashboard/views/weekView/WeekView2';

type OriginalQTYInfoProps = {
    inspection: Inspection | InspectionsWeekviewInspectionExtended | DashboardPopupInspection;
    type?: 'report' | 'editOrder' | 'dashboard';
    showPopup?: boolean;
};

const OriginalQTYInfo = (props: OriginalQTYInfoProps): React.ReactElement => {
    const { inspection, type = false, showPopup = true } = props;
    const isPartOfSplit = inspection.involved_in_split_shipment;
    if (isPartOfSplit) {
        const isSource = inspection.destination_split_shipments.length > 0;
        const messageId = isSource ? 'report.info.split_shipment.inspection_is_source' : 'report.info.split_shipment.inspection_is_destination';
        const linkMessageId = isSource ? 'report.info.split_shipment.inspection_is_source.destion_link' : 'report.info.split_shipment.inspection_is_destination.source_link';
        let link;
        if (!isSource) {
            link = '/inspections/reports/' + inspection.source_split_shipments[0].source_inspection_id;
        } else {
            link = inspection.destination_split_shipments[0].destination_inspection_status === 'report' ?
                '/inspections/reports/' + inspection.destination_split_shipments[0].destination_inspection_id
                :
                '/inspections/edit_inspection/' + inspection.order_id;
        }
        const pcs = inspection.original_total_quantity;
        let trigger;
        switch (type) {
            case 'report':
                trigger = <span><Spacer space='left'>(</Spacer><FormattedMessage id='report.info.original_total_qty' values={{ pcs }} />)</span>;
                break;
            case 'editOrder':
                trigger = <span><InfoIcon noMargins={true} color='grey' compact={true} small={true} /></span>;
                break;
            case 'dashboard':
                trigger = <div className='qty'>{inspection.total_quantity}<Space />/<Space />{pcs}</div>;
                break;
        }
        return showPopup ? <Fragment><Popup
            hoverable={true}
            hideOnScroll={false}
            trigger={trigger}>
            <Popup.Content>
                <p><FormattedMessage id={messageId} values={{ pcs }} /></p>
                {type === 'report' && <Link className='link noUnderline' as={Button} to={link}><FormattedMessage id={linkMessageId} /></Link>}
            </Popup.Content>
        </Popup></Fragment> : trigger;
    } else {
        if (inspection.original_total_quantity && inspection.total_quantity !== inspection.original_total_quantity) {
            const pcs = inspection.original_total_quantity;
            let ret;
            switch (type) {
                case 'report':
                    ret = <span><Spacer space='left'>(</Spacer><FormattedMessage id='report.info.original_total_qty' values={{ pcs }} />)</span>;
                    break;

                case 'dashboard':
                    ret = <div className='qty'>{inspection.total_quantity}<Space />/<Space />{pcs}</div>;
                    break;
            }
            return ret;
        }
        if (type === 'dashboard') {
            return <div>{inspection.original_total_quantity}</div>;
        }
    }
    return null;
};

export default OriginalQTYInfo;
