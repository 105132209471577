import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import filters from '../../filters';

const NoData = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    return (<div>
        <Header as='h2'><FormattedMessage id='dashboard.no_data' /></Header>
        <p><FormattedMessage id='dashboard.no_data.try_removing_filter_or_expand_date' /></p>
        <p><div className='link' onClick={() => dispatch(filters.actions.clearAllFilters())}><FormattedMessage id='dashboard.no_data.clear_all_filters' /></div></p>
    </div>);
};

export default NoData;
