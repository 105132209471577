import React, { useEffect, useState } from 'react';

import { Label as AriaLabel } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

export type LabelProps = {
    className?: string;
    text: string;
    icon?: Icons;
};

const Label = (props: LabelProps): React.ReactElement => {
    const { className, text, icon } = props;
    const baseStyle = 'text-sm pb-1 flex text-input-label gap-1 items-center';
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div>
        <AriaLabel className={twMerge(className, baseStyle, '')}>
            {icon && <Icon name={icon}/>}
            {text}</AriaLabel>
    </div>
}
export default Label;
