import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'semantic-ui-react';
import BaseComponent from '../../../base/components/BaseComponent';
import customFields from '../../customFields';
import { getChecklist, updateChecklist, updateInspectionChecklist } from '../actions/actions';
import EditChecklist, { EditChecklistProps } from '../components/EditChecklist';
import { getChecklistSelector, getFetching } from '../selectors';

type ownProps = {
    inspectionId: string;
    checklistId: string;
    actions: any;
}

type EditChecklistContainerProps = ownProps & EditChecklistProps;

class EditChecklistContainerInner extends BaseComponent<EditChecklistContainerProps, any> {

    public state = { isSaving: false };

    constructor(props) {
        super(props);
        this.doSave = this.doSave.bind(this);
    }

    public doRender(): React.ReactElement {
        return (
            <Grid container className='inspections-menu'>
                <EditChecklist
                    {...this.props}
                    doSave={this.doSave}
                    isStandalone={this.props.isStandalone || false}
                    itemNumber={this.props.itemNumber}
                    itemName={this.props.itemName}
                    isFetching={this.props.isFetching}
                    isSaving={this.state.isSaving}
                />
            </Grid>
        );
    }

    public componentDidMount(): void {
        if (!this.props.isStandalone) {
            window.scrollTo(0, 0);
            this.props.actions.getChecklist(this.props.checklistId);
        }
        this.props.actions.getCustomFields();
    }

    public componentWillReceiveProps(nextProps): void {
        if (nextProps.isFetching === false && this.state.isSaving) {
            this.setState({ isSaving: false });
        }
    }


    private doSave(checklistData: any, returnToChecklists: boolean, updateChecklist: boolean): void {
        if (this.props.isStandalone) {
            this.props.actions.updateInspectionChecklist(this.props.inspectionId, checklistData, true);
        } else {
            this.props.actions.updateChecklist(this.props.checklistId, checklistData, returnToChecklists, updateChecklist);
        }
        this.setState({ isSaving: true });
    }
}

const mapDispatchToProps = (dispatch): any => {
    const getCustomFields = customFields.actions.getCustomFields;
    return {
        actions: bindActionCreators({
            updateChecklist,
            updateInspectionChecklist,
            getCustomFields,
            getChecklist,
        }, dispatch),
    };
};

const mapStateToProps = (state, ownProps?): any => {
    const customFieldsSelector = customFields.selectors.getCustomFields;
    const inspectionId = (ownProps.match && ownProps.match.params.inspectionId) || '';
    const { app } = state;

    return {
        checklistData: getChecklistSelector(state),
        isFetching: getFetching(state),
        inspectionId,
        customFieldsData: customFieldsSelector(state),
        deleteCheckpointFailed: app.checklists.content.deleteCheckpointFailed,
        deleteCheckpointSuccess: app.checklists.content.deleteCheckpointSuccess
    };
};
const EditChecklistContainer = connect(mapStateToProps, mapDispatchToProps)(EditChecklistContainerInner as any);

export default EditChecklistContainer;
