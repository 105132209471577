import React, { useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { FormattedMessage } from 'react-intl';
import { Label } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { InspectionsWeekviewOrder, WeekviewUser } from '../../../../../../backend_api/models';
import { orderOnlyHasPlannedInspections } from '../../../../Utils';
import { DragType, InspectionsWeekviewInspectionExtended, InspectionsWeekviewOrderExtended, WeekViewItemType } from './WeekView2';
import WeekViewInspectionItem from './WeekViewInspectionItem';

type OrderItemProps = {
    order: InspectionsWeekviewOrderExtended;
    date: Date;
    itemType: WeekViewItemType;
    className?: string;
    user: WeekviewUser;
    cnt: number;
    data: any;
};
const COLLAPS_AT = 3;
const WeekViewOrderItem = (props: OrderItemProps): React.ReactElement => {
    const { className, order, date, itemType, data, cnt, user } = props;
    const [collectedDragProps, drag] = useDrag({
        type: DragType.WEEKVIEW_ORDER_ITEM, item: { type: DragType.WEEKVIEW_ORDER_ITEM, order, date, itemType, id: order.id, data, user },
    });
    const [isOpen, setIsOpen] = useState(false);
    const getHeaderItem = (order: InspectionsWeekviewOrder, isCombined: boolean): React.ReactElement => {
        return <div className={twMerge('flex justify-between bg-week-view-order text-primary text-xs w-full p-1', cnt === 0 ? '' : 'rounded-sm-t')}><div className='text'>{order.order_number || '-'}</div>{isCombined && <div className=''>
            <Label size='mini'><FormattedMessage id='inspections.week_view.combined' /></Label>


        </div>}
            {/* <WeekViewReassignOrder
                orderId={order.inspections[0].order_id + '_' + date}
                date={date}
                supplierObj={data}
                assignedUserId={user && user.id}
                trigger={<div className='cursor-pointer link'><FormattedMessage id='inspections.week_view.reassign_order.trigger'/></div>} /> */}</div>;
    }
    const getInspectionItem = (inspection: InspectionsWeekviewInspectionExtended, order, i: number, isCombined: boolean, combinedInspectionId?: string): React.ReactElement => {
        const _data = { ...data, inspections: order.inspections };

        return <WeekViewInspectionItem
            inspection={inspection}
            order={order}
            combinedInspectionId={combinedInspectionId}
            date={date}
            itemType={itemType}
            data={_data}
            user={user} />
    }
    const collapse = () => {
        setIsOpen(!isOpen);
    }

    const item = useMemo(() => {
        const isCombined = order.combined;
        const inspCnt = isCombined ? order.inspections[0].sub_inspections.length || 0 : order.inspections.length || 0;
        const isBooking = itemType === 'booked';
        const canAssign = order.features.includes('order_change_assignment_data') && orderOnlyHasPlannedInspections(order.inspections as InspectionsWeekviewInspectionExtended[]);
        const canAssignBooking = isBooking && orderOnlyHasPlannedInspections(order.inspections as InspectionsWeekviewInspectionExtended[]);
        const canDrag = (isBooking ? canAssignBooking : canAssign);

        const orderItem = <div ref={canDrag ? drag : null}>{!order.combined &&
            <div className={twMerge('flex flex-col bg-white rounded-lg border mb-1 shadow-sm')}>
                {getHeaderItem(order, false)}
                {!order.combined && !isOpen && order.inspections.slice(0, COLLAPS_AT).map((inspection: InspectionsWeekviewInspectionExtended, i: number) => {
                    return getInspectionItem(inspection, order, i, false);
                })}
                {!order.combined && isOpen && order.inspections.map((inspection: InspectionsWeekviewInspectionExtended, i: number) => {
                    return getInspectionItem(inspection, order, i, false);
                })}
                {!isOpen && inspCnt > COLLAPS_AT && <div onClick={(): void => collapse()} className='pl-2  pt-1 toggle link text-smx py-2 px-1'><FormattedMessage id='inspections.week_view.show_more' values={{ cnt: (inspCnt - COLLAPS_AT) }} /></div>}
                {isOpen && <div onClick={(): void => collapse()} className='pl-2 pt-1 text-smx toggle link'><FormattedMessage id='inspections.week_view.hide' /></div>}
            </div>
        }
            {
                order.combined && <div className={twMerge('flex flex-col bg-white rounded-lg border mb-1 shadow-sm')}>
                    {getHeaderItem(order, true)}
                    {order.combined && !isOpen && order.inspections[0].sub_inspections.slice(0, COLLAPS_AT).map((inspection: InspectionsWeekviewInspectionExtended, i: number) => {
                        return getInspectionItem(inspection, order, i, true, order.inspections[0].id);
                    })}
                    {order.combined && isOpen && order.inspections[0].sub_inspections.map((inspection: InspectionsWeekviewInspectionExtended, i: number) => {
                        return getInspectionItem(inspection, order, i, true, order.inspections[0].id);
                    })}
                    {!isOpen && inspCnt > COLLAPS_AT && <div onClick={(e): void => collapse()} className='pl-2  pt-1 toggle link text-smx'><FormattedMessage id='inspections.week_view.show_more' values={{ cnt: (inspCnt - COLLAPS_AT) }} /></div>}
                    {isOpen && <div onClick={(e): void => collapse()} className='pl-2  pt-1 text-smx toggle link'><FormattedMessage id='inspections.week_view.hide' /></div>}
                </div>
            }</div>;
        return orderItem;
    }, [isOpen, order]);
    return item;
}
export default WeekViewOrderItem;
