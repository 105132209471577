import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { getVideo } from '../actions';
import Video from '../components/Video';
import { getVideoResource } from '../selectors';

type VideoContainerProps = {
    videoId: string;
}

const VideoContainer = (props: VideoContainerProps): React.ReactElement => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getVideo(props.videoId))
    }, [props.videoId])

    const videoResource = useSelector(getVideoResource)

    return <div className='pageContainer'>
        <div className="flex justify-center">
            <div className="w-96 bg-red-700">
                <Video
                    usePlayIcon={true}
                    preload={true}
                    videoResources={videoResource.video_resources}
                    thumbnail={videoResource.url}
                />
            </div>
        </div>
    </div>
}

export default VideoContainer;