import classnames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from '../utils';
import SpinnerIcon from './basic/SpinnerIcon';

type LoaderProps = {
    active: boolean;
    small?: boolean;
    label?: string;
    basic?: boolean;
    hideSpinner?: boolean;
    hideLabel?: boolean;
    children?: React.ReactElement | React.ReactElement[];
    spinner?: React.ReactElement;
    spinnerClassName?: string;
    className?: string;
    containerClassName?: string;
};

const Loader = (props: LoaderProps): React.ReactElement => {
    const { children, active, small = false, hideLabel = false, hideSpinner = false, spinner, className, basic = false, label, containerClassName, spinnerClassName } = props;

    return active ? <div className='relative z-0'>
        <div style={{ backgroundColor: 'rgba(255,255,255,0.7)' }} className={twMerge('dimmer absolute w-full h-full top-0 left-0 flex z-50 bg-white', containerClassName)}>
            <div className={twMerge('mx-auto  mt-4 text-sm flex items-start', className)}>
                {spinner}
                {spinner !== undefined || (!hideSpinner && !hideLabel) && <div className={classnames('bg-white opacity-100 p-2 flex flex-col', { 'shadow-xs border': !small })}>
                    {!hideSpinner &&
                        <SpinnerIcon className={twMerge('self-center flex text-brand w-8 h-8 justify-center mb-2', spinnerClassName)} />}
                    {!hideLabel && <div>{label ? label : <FormattedMessage id='loader.text.loading' />}</div>}
                </div>}
            </div>
        </div>
        {children}
    </div> : <Fragment>{children}</Fragment>
}
export default Loader;
