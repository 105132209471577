import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PageContainer from '../../globals/components/PageContainer';
import DocumentTitle from 'react-document-title';
import { isWeChat } from '../../../base/utils';
import { Modal } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
const googlePlay = require('../../appInstallPage/assets/large-google-play.png');
const googlePlay2x = require('../../appInstallPage/assets/large-google-play@2x.png');
const googlePlay3x = require('../../appInstallPage/assets/large-google-play@3x.png');
const huawei = require('../../appInstallPage/assets/large-huawei.png');
const huawei2x = require('../../appInstallPage/assets/large-huawei@2x.png');
const huawei3x = require('../../appInstallPage/assets/large-huawei@3x.png');
const xiaomi = require('../../appInstallPage/assets/large-xiaomi.png');
const xiaomi2x = require('../../appInstallPage/assets/large-xiaomi@2x.png');
const xiaomi3x = require('../../appInstallPage/assets/large-xiaomi@3x.png');
const oppo = require('../../appInstallPage/assets/large-oppo.png');
const oppo2x = require('../../appInstallPage/assets/large-oppo@2x.png');
const oppo3x = require('../../appInstallPage/assets/large-oppo@3x.png');
const vivo = require('../../appInstallPage/assets/large-vivo.png');
const vivo2x = require('../../appInstallPage/assets/large-vivo@2x.png');
const vivo3x = require('../../appInstallPage/assets/large-vivo@3x.png');
const baidu = require('../../appInstallPage/assets/large-baidu.png');
const baidu2x = require('../../appInstallPage/assets/large-baidu@2x.png');
const baidu3x = require('../../appInstallPage/assets/large-baidu@3x.png');

const wechatInstructionArrow = require('../../appInstallPage/assets/wechat-intructions-arrow.png');
const wechatInstructionArrow2x = require('../../appInstallPage/assets/wechat-intructions-arrow@2x.png');
const wechatInstructionArrow3x = require('../../appInstallPage/assets/wechat-intructions-arrow@3x.png');

const wechatInstructionContextMenu = require('../../appInstallPage/assets/wechat-instructions-context-menu.png');
const wechatInstructionContextMenu2x = require('../../appInstallPage/assets/wechat-instructions-context-menu@2x.png');
const wechatInstructionContextMenu3x = require('../../appInstallPage/assets/wechat-instructions-context-menu@3x.png');

const wechatInstructionBrowser = require('../../appInstallPage/assets/wechat-instructions-browser.png');
const wechatInstructionBrowser2x = require('../../appInstallPage/assets/wechat-instructions-browser@2x.png');
const wechatInstructionBrowser3x = require('../../appInstallPage/assets/wechat-instructions-browser@3x.png');

const GetAndroidAppPage = () => {
    const intl = useIntl();

    const isInWeChat = isWeChat();
    const [showOppoModal, setShowOppoModal] = useState(false);

    useEffect(() => {
        window.location.href = "market://details?id=com.qarma.app";
    }, []);

    const openAppStore = (url: string) => {
        window.open(url, "_blank");
    }
    
    return (
        <PageContainer headerClassName='' header={<div className='flex space-x-2 justify-center'>
                    <DocumentTitle title={intl.formatMessage({ id: 'globals.get_qarma_app' })} />
                    <div className='px-4 sm:px-0 text-3xl font-bold'><FormattedMessage id='android_app_install.choose_preferred_app_store'/></div>
                </div>}>
                
                <div className='flex flex-wrap justify-center items-stretch gap-8 my-8'>
                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => openAppStore("https://play.google.com/store/apps/details?id=com.qarma.app")}>
                        <img src={googlePlay} srcSet={`${googlePlay2x} 2x, ${googlePlay3x} 3x`}/>
                        <div className='mt-2'>Google Play</div>
                    </div>
                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => openAppStore("https://appgallery.huawei.com/app/C108421561")}>
                        <img src={huawei} srcSet={`${huawei2x} 2x, ${huawei3x} 3x`}/>
                        <div className='mt-2'>Huawei</div>
                    </div>
                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => openAppStore("http://app.xiaomi.com/detail/1547322")}>
                        <img src={xiaomi} srcSet={`${xiaomi2x} 2x, ${xiaomi3x} 3x`}/>
                        <div className='mt-2'>Xiaomi</div>
                    </div>

                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => setShowOppoModal(true)}>
                        <img src={oppo} srcSet={`${oppo2x} 2x, ${oppo3x} 3x`}/>
                        <div className='mt-2'>OPPO</div>
                    </div>
                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => openAppStore("https://h5.appstore.vivo.com.cn/#/details?search_word=qarma&search_action=4&app_id=2943620&app_pos=1&source=5&appId=2943620&frompage=searchResultApp&listpos=1")}>
                        <img src={vivo} srcSet={`${vivo2x} 2x, ${vivo3x} 3x`}/>
                        <div className='mt-2'>vivo</div>
                    </div>
                    <div className='bg-white rounded-md p-4 justify-center items-center flex flex-col' style={{minWidth: '120px'}}
                    onClick={() => openAppStore("https://mobile.baidu.com/item?docid=5008174649")}>
                        <img src={baidu} srcSet={`${baidu2x} 2x, ${baidu3x} 3x`}/>
                        <div className='mt-2'>Baidu</div>
                    </div>
                </div>
                { isInWeChat && <WeChatGuide />}
                { showOppoModal && <OppoModal show={showOppoModal} close={() => setShowOppoModal(false)} />}
            </PageContainer>
    )
}

function OppoModal(props: {
    show: boolean,
    close: () => void
}) {
    const {show, close} = props;
    return <Modal
        size="small"
        closeOnEscape
        closeIcon
        onClose={close}
        open={show}
    >
        <Modal.Header>
            <div className='flex flex-row justify-start font-bold'><FormattedMessage id='android_app_install.using_oppo'/></div>
        </Modal.Header>
        <Modal.Content>
            <div className='flex flex-row justify-start'><FormattedMessage id='android_app_install.using_oppo_guide'/></div>
        </Modal.Content>
        <Modal.Actions className="flex justify-end py-4 mb-4">
            <Button primary large onClick={close}><FormattedMessage id='onboarding.modal.confirm'/></Button>
        </Modal.Actions>
    </Modal>
}

function WeChatGuide() {
    return <div className='fixed w-full h-full opacity-100 text-white top-0 right-0 left-0 z-50' style={{backgroundColor: 'rgba(0,0,0,.85)'}}>
        <div className='flex flex-row justify-end items-center mt-5 mr-5'>
            <img src={wechatInstructionArrow} srcSet={`${wechatInstructionArrow2x} 2x, ${wechatInstructionArrow3x} 3x`} style={{right: '25px'}}/>
        </div>
        <div className='flex flex-col justify-center items-center'>
            <div className='py-4 px-8 text-center not-italic' style={{fontSize: '21px', fontWeight: '700', lineHeight: '28px'}}>The Qarma app can't be installed through WeChat</div>
            <div className='flex flex-row py-4 px-8 justify-center items-center text-center gap-8'>
                <div className='flex flex-col justify-center items-center' style={{width: '125px'}}>
                    <img src={wechatInstructionContextMenu} srcSet={`${wechatInstructionContextMenu2x} 2x, ${wechatInstructionContextMenu3x} 3x`}/>
                    <div><FormattedMessage id='android_app_install.we_chat_guide_1'/></div>
                </div>
                <div className='flex flex-col justify-center items-center' style={{width: '125px'}}>
                    <img src={wechatInstructionBrowser} srcSet={`${wechatInstructionBrowser2x} 2x, ${wechatInstructionBrowser3x} 3x`}/>
                    <div><FormattedMessage id='android_app_install.we_chat_guide_2'/></div>
                </div>
            </div>
        </div>
        <div className='absolute bottom-0'>
            <div className='flex justify-center items-center px-8 pb-8'>
                <FormattedMessage id='android_app_install.we_chat_guide_3'/>
            </div>
        </div>
    </div>
}

export default GetAndroidAppPage;