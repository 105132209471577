import React, { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';

type Props =  {
   className?: string;
   children?: ComponentOrStringType;
   onSubmit?(event: FormEvent<HTMLFormElement>);
};

const Form = (props: Props): React.ReactElement => {
   const { className, children, ...rest } = props;
   const location = useLocation();
   const  dispatch = useDispatch();
   const  intl = useIntl();
   const  s = useState();
   useEffect(() => {
    //
}, []);
   return <form {...rest} className={twMerge('', className)}>{children}</form>
}
export default Form;
