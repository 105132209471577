/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   Describes the current progress of a Corrective Action.
 *   - "finding": Corrective action can stil be edited and resolved.
 *   - "in_progress": The Corrective action has had evedence uploaded.
 *   - "review": Corrective action can no longer be edited and is waiting to be approved.
 *   - "closed": The Corrective action is finished.
 * @export
 * @enum {string}
 */
export enum CorrectiveActionsState {
    Finding = 'finding',
    Review = 'review',
    Closed = 'closed',
    InProgress = 'in_progress'
}

export function CorrectiveActionsStateFromJSON(json: any): CorrectiveActionsState {
    return CorrectiveActionsStateFromJSONTyped(json, false);
}

export function CorrectiveActionsStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionsState {
    return json as CorrectiveActionsState;
}

export function CorrectiveActionsStateToJSON(value?: CorrectiveActionsState | null): any {
    return value as any;
}

