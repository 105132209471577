import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListedManagedSupplier } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import LineTogglerButton from '../../globals/components/views/list/LineTogglerButton';
import { ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import SupplierAuditsStatusBar from './SupplierAuditsStatusBar';
import SupplierCorrectiveActionStatusPie from './SupplierCorrectiveActionStatusPie';
import SupplierListProductionUnit from './SupplierListProductionUnit';

type Props = {
    className?: string;
    supplier: ListedManagedSupplier;
    cnt?: number;
};

const SupplierListItem = (props: Props): React.ReactElement => {
    const { supplier } = props;
    const hasProductionUnits = supplier.production_units.length > 0;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <><ListViewLine onClick={() => {
        setIsOpen(!isOpen)
    }} className='bg-white border-b cursor-pointer items-center hover:bg-highlight-green sm:py-3'>
        <ListViewItem className='w-1/2 pb-4 sm:pb-0 md:w-3/16 font-bold md:font-normal'>{supplier.number}</ListViewItem>
        <ListViewItem className='w-1/2 pb-4 sm:pb-0 md:w-8/16 font-bold md:font-normal'>{supplier.name}</ListViewItem>
        <ListViewItem className='w-full pb-2 sm:pb-0 md:w-4/16 font-bold md:font-normal flex justify-between'>
            <SupplierCorrectiveActionStatusPie correctiveActionStatus={supplier.corrective_action_status} supplierId={supplier.id} />
            <SupplierAuditsStatusBar className='mr-0 sm:mr-4' items={{ latestAudits: supplier.latest_audits, audits: supplier.audits }} supplierId={supplier.id} /></ListViewItem>
        <ListViewItem className='w-full md:w-1/16 font-bold md:font-normal justify-end'><LineTogglerButton disabled={!hasProductionUnits} itemsCnt={supplier.production_units.length} open={isOpen} /></ListViewItem>
    </ListViewLine>
        {isOpen && <ListViewLine className='w-full flex-col md:bg-highlight-light-green md:pt-0 md:pb-0 md:px-0 border-l-4 border-l-brand border-b-brand sm:border-b-4 md:border-b-4 lg:border-b-4'>
            <SupplierListProductionUnit supplier={supplier} />
            <ListViewItem className='justify-center items-center pt-2 pb-3'><Button link={'suppliers/' + supplier.id}><FormattedMessage id='suppliers.list.item.edit_button' /></Button></ListViewItem>
        </ListViewLine>}
    </>
}
export default SupplierListItem;
