import React, { Fragment, useState } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../../store';
import { getInProgressAndDoneInspectionsCount, getNotLockedAndDoneInspections, getPlannedAndLockedInspectionsCount } from '../../../Utils';
import { setSameForAllInspections } from '../../../actions/editInspectionActions';
import { getIsSameForAllInspections, getIsSameForAllInspectionsPermissionsSelector, hasInspectionTypes as hasInspectionTypesSelector } from '../../../selectors/selectors';
import { OrderListInspectionExtended } from '../../../slices/inspectionsListSlice';
import { Inspection } from '../../../types';
import InspectorLine from '../InspectorLine2';

type AssignProps = {
    inspections: Inspection[];
    isCombinedInspection?: boolean;
    intl: IntlShape;
};

const Assign = (props: AssignProps): React.ReactElement => {

    const dispatch = useAppDispatch();
    const { isCombinedInspection = false, intl } = props;
    const [showSameForAllDialog, setShowSameForAllDialog] = useState(false);

    const toggleSameForAll = (): void => {
        if (!isSameForAllInspections) {
            setShowSameForAllDialog(true);
        } else {
            dispatch(setSameForAllInspections(false));
        }
    };
    const handleToggleSameForAll = (toggle: boolean): void => {
        setShowSameForAllDialog(false)
        dispatch(setSameForAllInspections(toggle));
    }
    const getSameForAllLabel = (ask: boolean): string => {
        let str = '';
        const strs = [];
        const permissions = isSameForAllPermissions.permissions;
        const andLabel = intl.formatMessage({ id: 'inspections.set_same_data_and' });
        Object.keys(permissions).forEach((k: string) => {
            if (permissions[k]) {
                strs.push(intl.formatMessage({ id: 'inspections.set_same_data.' + k }));
            }
        });
        const cnt = strs.length;
        str = strs[0];
        if (cnt > 1) { // This could probably be done much nicer, but we don't have much time...
            str = '';
            strs.forEach((s, i) => {
                if ((i + 1) < cnt) {
                    str += s + ', ';
                }
                if ((i + 1) === cnt) {
                    str = str.substr(0, str.length - 2);
                    str += ' ' + andLabel + ' ' + s;
                }
            })
        }
        return ask ? intl.formatMessage({ id: 'inspections.do_you_want_to_set_same_data' }, { data: str }) : intl.formatMessage({ id: 'inspections.set_same_data' }, { data: str });
    };
    const isSameForAllPermissions = useSelector(getIsSameForAllInspectionsPermissionsSelector);
    const isSameForAllInspections = useSelector(getIsSameForAllInspections) && isSameForAllPermissions.hasPermissions;
    const hasInspectionTypes = useSelector(hasInspectionTypesSelector);
    const isSameForAll = isCombinedInspection ? false : isSameForAllInspections;
    const notLockedInspections: (OrderListInspectionExtended | Inspection)[] = isCombinedInspection ? [props.inspections[0]] : getNotLockedAndDoneInspections(props.inspections);
    const inspections = isSameForAllInspections ? notLockedInspections.slice(0, 1) : notLockedInspections;
    const hasEditableInspections = inspections.length > 0;

    const getNotEditedInfo = (showIcon: boolean): React.ReactElement => {
        const plannedButLockedCnt = getPlannedAndLockedInspectionsCount(props.inspections, true);
        const inProgressAndDoneCnt = getInProgressAndDoneInspectionsCount(props.inspections, true);
        let ret = null;
        if (plannedButLockedCnt + inProgressAndDoneCnt > 0) {
            ret = <div className='flex space-x-1 mb-4 items-baseline'>
                {showIcon && <Icon name='info circle' color='blue' />}
                {plannedButLockedCnt === 1 && <span><FormattedMessage id='inspections.first_planned_inspection_is_locked_and_not_edited' /></span>}
                {plannedButLockedCnt > 1 && <span>{plannedButLockedCnt} <FormattedMessage id='inspections.inspections_are_locked_and_not_edited' /></span>}
                {inProgressAndDoneCnt === 1 && <span><FormattedMessage id='inspections.one_inspection_is_in_progress_or_done_and_not_edited' /></span>}
                {inProgressAndDoneCnt > 1 && <span>{inProgressAndDoneCnt} <FormattedMessage id='inspections.inspections_are_in_progress_or_done_and_not_edited' /></span>}
            </div>
        }
        return ret;
    }
    return <Fragment>
        <Segment.Group raised>
            <Segment secondary>
                <Header as='h3'>
                    <Grid columns='equal' verticalAlign='middle' className='inspection-same-for-all'>
                        <Grid.Column width={6}>
                            <FormattedMessage id='edit_inspection.inspector_date_approver_header' />
                        </Grid.Column>
                        <Grid.Column width={10} textAlign='right'>
                            <div className='header-info sameForAll'>
                                {!isSameForAllInspections && notLockedInspections.length > 1 && isSameForAllPermissions.hasPermissions && <span className='link' onClick={toggleSameForAll}>
                                    {getSameForAllLabel(false)}
                                </span>}
                                {isSameForAllInspections && notLockedInspections.length > 1 && isSameForAllPermissions.hasPermissions && <span>
                                    {notLockedInspections.length} <FormattedMessage id='inspections.inspections_with_same_date_inspector_watchers_and_approval_flow' />
                                    <span className='link' onClick={toggleSameForAll}><FormattedMessage id='inspections.let_me_edit_separately' /></span>
                                </span>
                                }
                            </div>
                        </Grid.Column>
                    </Grid>
                </Header>
            </Segment>
            <Segment>
                <Form name='approval' className='space-y-2'>
                    {hasEditableInspections && getNotEditedInfo(true)}
                    {!hasEditableInspections && <Fragment><div><Icon name='info circle' color='blue' /> <FormattedMessage id='inspections.no_editable_inspections' />.</div>{getNotEditedInfo(false)}</Fragment>}
                    {inspections.map((inspectionFromMap: Inspection, i: number) => {
                        const inspection = inspectionFromMap;
                        return <InspectorLine
                            key={'inspectorline__' + i}
                            inspection={inspection}
                            sameForAll={isSameForAll}
                            isOnlyOne={inspections.length === 1}
                            hasInspectionTypes={hasInspectionTypes}
                            hideHeading={isSameForAll && notLockedInspections.length > 1}
                        />;
                    })}</Form>
            </Segment>
        </Segment.Group>
        <Modal open={showSameForAllDialog} onClose={(): void => null} closeIcon={true}>
            <Modal.Header>{getSameForAllLabel(false)}</Modal.Header>
            <Modal.Content>
                <p>{getSameForAllLabel(true)}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={(): void => handleToggleSameForAll(true)}><FormattedMessage id='inspections.ok' /></Button>
                <Button onClick={(): void => handleToggleSameForAll(false)}><FormattedMessage id='inspections.cancel' /></Button>
            </Modal.Actions>
        </Modal></Fragment >

}
export default Assign;
