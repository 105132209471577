import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Image, Modal } from 'semantic-ui-react';
import { TextItem } from '../../../globals/components';
import { ListViewItem, ListViewLine } from '../../../globals/components/views/list/ListView';
import { getInspectionDate, isInspectionPartOfCombinedInspection } from '../../Utils';
import { Inspection } from '../../types';
import { CombinedInspectionLabel } from '../combine/CombinedInspections';
import { PlainStatus } from '../edit/Status';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import { config } from './inspectionItemConfig';


interface DuplicateInspectionItemProps {
    inspection: Inspection;
    showDuplicateItem: boolean;
    isCombinedInspection: boolean;
    addCombinedQuantity: boolean;
    handleItem(show: boolean, inspection: Inspection): void;
    handleDuplicateItem(inspection: Inspection, inspectionName: string): void;
    changeAddCombinedQuantity(): void;
}

const DuplicateInspectionItem = (props: DuplicateInspectionItemProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const inspection = props.inspection;
    const isCombinedInspection = props.isCombinedInspection;
    const showSelectIfCombinedInspection = inspection.status === 'planned' && !isInspectionPartOfCombinedInspection(inspection);
    const inspectionName = inspection.item_name;
    const header = isCombinedInspection ? formatMessage({ id: 'duplicate_inspections.add' }) : formatMessage({ id: 'duplicate_inspections.copy' });
    const label = isCombinedInspection ? formatMessage({ id: 'duplicate_inspections.add_to_combined_inspection' }) : formatMessage({ id: 'duplicate_inspections.make_a_copy_and_add_to_order' });
    const itemNumberContent = <div className='space-x-1'><CombinedInspectionLabel type='dashboard' inspection={inspection} />{/* <ReinspectLabel type='dashboard' inspection={inspection} /> */}<SplitShipmentsMarker inspection={inspection}/><span>{inspection.item_number}</span></div>;
    const widths = config.widths;
    const trigger = <ListViewLine className={classnames('inspection text-lg sm:text-base sm:flex sm:flex-row border-b border-default sm:hover:bg-highlight-green sm:hover:cursor-pointer', { 'sm:font-bold': !inspection.seen })}>
        <ListViewItem className={classnames('hidden sm:flex break-words sm:flex-wrap', widths.image)}>
            {isCombinedInspection ?
                (showSelectIfCombinedInspection && <Button size='mini' onClick={(): void => props.handleItem(true, inspection)}><FormattedMessage id='inspections.select' /></Button>)
                :
                (<Button size='mini' onClick={(): void => props.handleItem(true, inspection)}><FormattedMessage id='inspections.select' /></Button>)
            }
        </ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex break-words sm:flex-wrap', widths.itemNo)}>{itemNumberContent}</ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex break-words sm:flex-wrap', widths.itemName)}>{inspection.item_name}</ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex w-2/24', widths.qty)}>{inspection.total_quantity}</ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex sm:flex-wrap break-words w-full', widths.types)}><InspectionTypeMarker inspections={[inspection]} type='dashboard' /></ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex break-words w-2/24', widths.date)}>{getInspectionDate([inspection], intl.locale)}</ListViewItem>
        <ListViewItem className={classnames('hidden sm:flex break-words w-2/24', widths.status)}><PlainStatus classNames='h-10 w-full cursor-default' inspection={inspection} /></ListViewItem>
    </ListViewLine>;

    return (
        <Modal
            closeOnEscape={true}
            open={props.showDuplicateItem}
            dimmer={true}
            className='duplicateItem'
            mountNode={document.body.getElementsByClassName('editOrder')[0]}
            trigger={trigger}>
            <Modal.Header>{header} {inspection.item_name}</Modal.Header>
            <Modal.Content>
                <p>{label}</p>
                <div className='w-full flex'>
                    {inspection.item_image && <div className='mr-4'><Image size='small' src={inspection.item_image} /></div>}
                    <div>
                        <TextItem label={intl.formatMessage({ id: 'inspections.item_name' })} text={inspection.item_name} />
                        <TextItem label={intl.formatMessage({ id: 'inspections.item_number' })} text={inspection.item_number} />
                        <TextItem label={intl.formatMessage({ id: 'inspections.total_quantity' })} text={inspection.total_quantity} />
                        <TextItem label={intl.formatMessage({ id: 'inspections.status' })} text={inspection.status} />
                    </div>
                    {isCombinedInspection && <Checkbox onChange={(): void => props.changeAddCombinedQuantity()} label="Add this item's quantity to the total quantity of the combined inspection" checked={props.addCombinedQuantity} defaultChecked={true} />}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={(): void => {
                    props.handleItem(false, inspection);
                    props.handleDuplicateItem(inspection, inspectionName);
                }}><FormattedMessage id='inspections.ok_add_it' /></Button>
                <Button onClick={(): void => props.handleItem(false, inspection)}><FormattedMessage id='inspections.cancel' /></Button>
            </Modal.Actions>
        </Modal >
    );
};
export default DuplicateInspectionItem;
