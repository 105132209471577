import { request } from '../../../base/api';
import { RequestError } from '../../../base/types';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import { updateAndReloadReportData } from '../slices/inspectionReportSlice';
import { ReportState } from '../types';
import { getCheckpointsObject } from './reportActions';

const catchException = errorHandling.handler.catchException;
const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;

export const scheduleReinspection = (inspectionId: string) => {
    return (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/reinspect';
        return request(url, {
            method: 'post',
        })
            .then((data: ReportState) => {
                data = getCheckpointsObject(data);
                dispatch(sendStatusMessage(['status_message.the_reinspection_was_succesfully_scheduled'], 5000));
                dispatch(updateAndReloadReportData(data));
            }, dispatch)
            .catch((error: RequestError) => {
                dispatch(clearAllMessages());
                dispatch(sendErrorMessage(['error_message.the_reinspection_was_not_scheduled'], 0));
                catchException('scheduleReinspection', {
                    endpoint: 'inspections/[inspectionId]/reinspect',
                    request: url,
                    status: error.status,
                }, { error, inspectionId });
            });
    };
};
