/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 45.2.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes the changing of a date.
 * @export
 * @interface EventDateChange
 */
export interface EventDateChange {
    /**
     * 
     * @type {boolean}
     * @memberof EventDateChange
     */
    next_value: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventDateChange
     */
    previous_value: boolean | null;
}

export function EventDateChangeFromJSON(json: any): EventDateChange {
    return EventDateChangeFromJSONTyped(json, false);
}

export function EventDateChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDateChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'next_value': json['next_value'],
        'previous_value': json['previous_value'],
    };
}

export function EventDateChangeToJSON(value?: EventDateChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_value': value.next_value,
        'previous_value': value.previous_value,
    };
}


