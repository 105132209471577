import React, { Component, SyntheticEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Header, Icon } from 'semantic-ui-react';
import { hasFeatureSelector } from '../../../base/selectors';
import { AppState } from '../../../base/types';
import { InfoIcon } from '../../globals/components/Utils';
import profile from '../../profile';
import { GroupOption, GroupData as GroupType } from '../types';
import Group from './Group';
import { GroupsSelector } from './GroupsSelector';
import { getGroupsNotAttached, handleAddGroup, handleCancel, handleChange, handleRemove, handleSave, isLastOwnGroup, isOwnGroup } from './Handlers';

type OwnProps = {
    className?: string
    selectedGroups: GroupType[];
    hideHeader?: boolean;
    excludeSuppliers?: boolean;
    disabled?: boolean;
    handleChange(evt, item: any); // TODO: fix handleChange signature
}
type StateProps = {
    ownGroups: GroupType[];
    isAdmin: boolean;
}

type GroupsProps = OwnProps & StateProps & WrappedComponentProps;
type State = {
    selectedGroups: GroupType[];
    showAddGroups: boolean;
};

const initialState: State = {
    selectedGroups: [], showAddGroups: false
};

class GroupsInner extends Component<GroupsProps, State> {
    readonly state: State = initialState;
    public constructor(props: GroupsProps) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleAddGroup = this.handleAddGroup.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    public render(): React.ReactElement {
        const { disabled = false, isAdmin } = this.props;
        const formatMessage = this.props.intl.formatMessage;
        const noGroupsLabel = formatMessage({ id: 'groups.no_groups_selected' });
        if (this.props.selectedGroups && this.props.ownGroups) {
            const groupsNotAttached: GroupOption[] = getGroupsNotAttached(this.props.selectedGroups, this.props.ownGroups);
            return (
                <div className={this.props.className || 'groups'}>
                    {!this.props.hideHeader && <Header as='h4'><FormattedMessage id='groups.groups_header' /></Header>}
                    {this.props.selectedGroups.length === 0 && <div className='pb-2'><InfoIcon compact text={noGroupsLabel} /></div>}
                    {this.props.selectedGroups.map((group, i) => {
                        const isOwn = isOwnGroup(group, this.props.ownGroups);
                        const isLastOwn = isLastOwnGroup(this.props.selectedGroups, this.props.ownGroups);
                        let toDelete = true;
                        let message: string;
                        if (!isAdmin && isLastOwn) {
                            toDelete = false;
                            message = formatMessage({ id: 'groups.cannot_remove_last_of_own_groups' });
                        }
                        if (!isAdmin && !isOwn) {
                            toDelete = false;
                            message = formatMessage({ id: 'groups.cannot_remove_group_not_in_own_groups' });
                        }
                        if (disabled) {
                            toDelete = false
                            message = ''
                        }
                        return <Group key={'group_' + i} toDelete={toDelete} group={group} message={message} handleRemove={this.handleRemove} />;
                    })}
                    {groupsNotAttached.length > 0 && !disabled && <Button className='add' disabled={this.state.showAddGroups} onClick={disabled ? null : this.handleAddGroup} size='small'>
                        <Icon className='icon icon-add' /><FormattedMessage id='groups.add_group' />
                    </Button>}
                    {groupsNotAttached.length === 0 && <span><Icon name='info circle' color='blue' /><FormattedMessage id='groups.no_more_groups_to_add' /></span>}
                    <GroupsSelector
                        groups={groupsNotAttached}
                        handleCancel={this.handleCancel}
                        handleChange={this.handleChange}
                        handleSave={this.handleSave}
                        hasSelection={this.state.selectedGroups.length > 0}
                        showSelector={this.state.showAddGroups}
                        disabled={disabled}
                    />
                </div>
            );
        }
        return (null);
    }

    private handleSave(concat?: boolean): void {
        this.setState(handleSave(concat));
    }

    private handleChange(evt: SyntheticEvent, { value }: { value: string[] }): void {
        this.setState(handleChange(value));
    }

    private handleRemove(id: string): void {
        this.setState(handleRemove(id), () => {
            this.handleSave(false);
        });
    }

    private handleAddGroup(): void {
        this.setState(handleAddGroup());
    }

    private handleCancel(): void {
        this.setState({ selectedGroups: [] })
        this.setState(handleCancel());
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        ownGroups: profile.selectors.getOwnGroups(state, ownProps.excludeSuppliers),
        isAdmin: hasFeatureSelector(state, 'u_groups_admin'),
    };
};

const Groups = connect<StateProps, any, OwnProps>(mapStateToProps)(injectIntl(GroupsInner))




export default Groups;
