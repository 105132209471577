import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { InputCustomFieldGroups } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import { translateTextWithTranslation, useLocaleLanguageString } from '../../../base/components/basic/TextWithTranslation';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { getProductionUnitCustomFieldGroups } from '../../customFields/selectors';
import DnDList from '../../sortableList/components/DnDList';
import { ProductionUnitCustomFields } from '../types';
import { choicesForDropdown, twt } from '../customFieldsShared/dropdownShared';

type ProductionUnitCustomFieldDetailProps = {
    data: ProductionUnitCustomFields;
    language: string;
    update(data: ProductionUnitCustomFields);
}

const ProductionUnitCustomFieldDetails = (props: ProductionUnitCustomFieldDetailProps): React.ReactElement => {
    const locale = useLocaleLanguageString();
    const intl = useIntl();

    const puCustomFieldGroups: InputCustomFieldGroups = useSelector(getProductionUnitCustomFieldGroups)
    const customFieldGroups = puCustomFieldGroups.groups;

    const [selectedValue, setSelectedValue] = React.useState<string | undefined>()

    // do not modify original list directly.
    const shallowCopy: ProductionUnitCustomFields = { custom_fields: props.data.custom_fields.map(x => x) }

    function buildChoicesForDropdown(query?: string) {
        return choicesForDropdown(customFieldGroups, intl, locale, query)
    }

    const choices = buildChoicesForDropdown()

    function isGroupId(id: string) {
        return customFieldGroups.some((group) => group.id === id);
    }

    function findGroup(id: string) {
        return customFieldGroups.find(group => group.id == id);
    }

    function onChoiceChanged(evt, item) {
        setSelectedValue(item.value)
    }

    function renderName(id) {
        let result = 'Placeholder'
        customFieldGroups.forEach((group) =>
            group.custom_field_definitions.forEach((definition) => {
                if (definition.id == id) {
                    result = translateTextWithTranslation(definition.name, locale)
                }
            })
        )
        return result;
    }

    function removeCustomField(indexToRemove) {
        shallowCopy.custom_fields = shallowCopy.custom_fields.filter((_, index) => index !== indexToRemove)
        notifyFieldsChanged(shallowCopy);
    }

    function addCustomField() {
        if (isGroupId(selectedValue)) {
            const group = findGroup(selectedValue);
            group.custom_field_definitions.forEach(definition => {
                /* Make sure not to add hidden definitions. */
                if (definition.hidden) {
                    return;
                }

                shallowCopy.custom_fields.push({
                    name: definition.name,
                    custom_field_definition_id: definition.id
                })
            })
        } else {
            shallowCopy.custom_fields.push({
                name: twt(),
                custom_field_definition_id: selectedValue
            })
        }
        notifyFieldsChanged(shallowCopy)
    }

    function notifyFieldsChanged(copy) {
        props.update(copy)
    }

    const dndItems = <DnDList items={shallowCopy.custom_fields.map((cf, index) => {
        return <div className=' pl-2 hover:bg-gray-200 border-gray-100 border items-center cursor-move py-2 w-full flex justify-between' key={cf.name}>
            {renderName(cf.custom_field_definition_id)}<Button className='bg-white text-xs' small onClick={() => removeCustomField(index)}>
                <FormattedMessage id='checklists.editor.custom_fields.remove' />
            </Button></div>
    })}
        itemMoved={(from, to) => {
            const temp = shallowCopy.custom_fields[from];
            shallowCopy.custom_fields[from] = shallowCopy.custom_fields[to];
            shallowCopy.custom_fields[to] = temp;
            notifyFieldsChanged(shallowCopy)
        }}
        enableDrage={true} />
    return (
        <div className='pb-4'>
            {dndItems}
            <div className='flex space-x-1  pt-2'>
                <Dropdown selection
                    placeholder={intl.formatMessage({ id: 'checklists.editor.custom_fields.selector.select_custom_field' })}
                    fluid
                    closeOnBlur
                    search={function (choices, query: string) {
                        return buildChoicesForDropdown(query);
                    }}
                    value={selectedValue}
                    options={choices}
                    onChange={onChoiceChanged}
                    data-test-id='checkpoint-type-dropdown'
                />
                <Button primary small disabled={isUndefinedOrNullOrEmptyString(selectedValue)} className='leading-normal whitespace-nowrap ' onClick={addCustomField}>

                    {intl.formatMessage({ id: customFieldGroups.map((gr) => gr.id).includes(selectedValue) ? 'checklists.editor.custom_fields.add_group' : 'checklists.editor.custom_fields.add' })}
                </Button>
            </div>
        </div>
    )
}

export default ProductionUnitCustomFieldDetails;