import React, { useState } from 'react';
import { ReactBingmaps } from 'react-bingmaps';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import markerIcon from '../../../../images/marker.png';
import { ReportLocation as Location } from '../../../backend_api/models/ReportLocation';
import SpinnerIcon from '../../../base/components/basic/SpinnerIcon';

type LocationMapProps = {
    location: Location;
    locationCountry?: string;
    label?: string;
}
let T;
const LocationMap = (props: LocationMapProps): React.ReactElement => {
    const getWorldmap = (long: number, lat: number) => {
        setTimeout(() => {
            const zoom = 16;
            const map = new T.Map('mapDiv');
            const marker = new T.Marker(new T.LngLat(long, lat));
            const z = new T.Control.Zoom();
            map.centerAndZoom(new T.LngLat(long, lat), zoom);
            map.addControl(z);
            map.addOverLay(marker);
            document.getElementById('mapLoader').innerHTML = '';
        }, 200)
    }
    const { location, locationCountry } = props;
    const [showMap, setShowMap] = useState(false);
    const close = (): void => setShowMap(false);
    const label = location.formatted_string || props.label;
    const trigger = <div className='link noUnderline hover' onClick={(): void => setShowMap(true)}>{label}</div>;
    const centerLocation = [location && location.latitude || 0, location && location.longitude || 0];
    const getMap = (country: string) => {
        if (country !== 'CN') {
            return <ReactBingmaps
                bingmapKey='AuNjAlN_Sq3pRGRH5yygB1rlSxgcZPsgvmHPCM2REtJADzPVJpEALzAXhJXS7xMW'
                center={centerLocation}
                pushPins={[{ location: centerLocation, option: { color: 'red', icon: markerIcon } }]}
            />
        }
    }
    const getTiandituScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=1662e191edb65576a0f967ed66955ca3';
        script.addEventListener('load', () => {
            T = window.T;
            getWorldmap(centerLocation[1], centerLocation[0]);
        })
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    };
    const mapProvider = locationCountry === 'CN' ? 'tianditu' : 'bing';
    return (
        <div>
            <Modal
                open={showMap}
                onClose={close}
                closeIcon={true}
                size='fullscreen'
                trigger={trigger}
                onOpen={() => {
                    if (locationCountry === 'CN') {
                        getTiandituScript();
                    }
                }}
            >
                <Modal.Header>{label}</Modal.Header>
                <Modal.Content>
                    <div id='mapDiv' style={{ height: '70vh' }} className={'w-full'}>
                        {locationCountry === 'CN' && <div className='p-4 items-center justify-center w-full flex space-x-2' id='mapLoader'><SpinnerIcon className='text-brand'/><span><FormattedMessage id='report.please_wait_map_is_loading' /></span></div>}
                        {getMap(locationCountry)}
                    </div>
                    <div className='text-sm text-secondary py-1 flex space-x-1'><span><FormattedMessage id={'report.map_provided_by'} /></span><span className='font-medium'><FormattedMessage id={'report.map_provider_' + mapProvider} /></span></div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={(): void => close()}><FormattedMessage id='inspections.close' /></Button>
                </Modal.Actions>
            </Modal >
        </div>
    );
}

export default LocationMap;
