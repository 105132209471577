import React from 'react';
import { User } from '../../../backend_api/models';
import { setLayoutAttribute } from '../../../base/baseSlice';
import { useAppDispatch } from '../../../store';
import Watchers from '../../watchers/components/Watchers';
import { addAuditWatchers, removeAuditWatchers } from '../slices/auditSlice';

type Props = {
    auditId: string;
    disabled?: boolean;
    className?: string;
    watchers: User[];
    noContentSection?: boolean;
    isReport?: boolean;
    readonly?: boolean;
};

const AuditWatchers = (props: Props): React.ReactElement => {
    const { auditId, watchers, noContentSection = false, isReport, className, disabled = false, readonly = false } = props;
    const dispatch = useAppDispatch();
    return <Watchers
        disabled={disabled}
        readonly={readonly}
        watchers={watchers}
        className={className}
        noContentSection={noContentSection}
        handleSave={(watchers) => {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            dispatch(addAuditWatchers({ auditId, watchers, isReport }))
        }}
        handleDelete={(watcherId: string) => {
            dispatch(removeAuditWatchers({ auditId, watchers: [watcherId], isReport }))
        }} />
}
export default AuditWatchers;
