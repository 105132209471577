import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Dropdown, Form, Grid, Input, Modal, Table } from 'semantic-ui-react';
import { v1 as uuidv1 } from 'uuid';

import { ChecklistType } from '../../../backend_api/models';
import { deepCopy } from '../../../base/utils';
import { getCustomFields } from '../../customFields/selectors';
import { CustomField } from '../../customFields/types';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { CheckpointTypeVersionNumber, DataPoint, MeasurementSpecification, MeasurementTable, MeasurementTablesData, Sample } from '../types';

type MeasurementTableDetailsProps = {
    data: MeasurementTablesData;
    language: string;
    show: boolean;
    checklistType: ChecklistType;
    saveAndUpdate(data: MeasurementTablesData);
    cancel();
}

type MeasurementTablesDataWithId = {
    tables: MeasurementTableWithId[];
    custom_table_definition_id: string;
    use_custom_table: boolean;
    version: CheckpointTypeVersionNumber;
}

type MeasurementTableWithId = {
    id: string;
    specifications: MeasurementSpecificationWithId[];
    name: MultiLanguageText;
    samples: Sample[];
}

type MeasurementSpecificationWithId = MeasurementSpecification & {
    id: string;
}

const MeasurementTableDialog = (props: MeasurementTableDetailsProps): React.ReactElement => {
    const intl = useIntl();
    const customFields: CustomField[] = useSelector(getCustomFields)
    const formatMessage = intl.formatMessage;
    const [data, setData] = useState<MeasurementTablesDataWithId>();
    const [sampleCount, setSampleCount] = useState<number>(data && data.tables[0].samples.length);
    const [useCustomFields, setUseCustomFields] = useState<boolean>()

    const isAllowedToUseCustomFields = props.checklistType !== 'audit';

    useEffect(() => {
        if (data) {
            const dataCopy = deepCopy(data);
            updateSamples(dataCopy);
            setData(dataCopy);
        }
    }, [sampleCount])

    useEffect(() => {
        setDataFromProps()
    }, [props.data])

    const saveAndReturn = () => {
        const dataCopy = deepCopy(data);
        //dataCopy.tables[tableIndex].specifications[specIndex].target.value = value;
        dataCopy.tables.forEach(
            (table) => table.specifications.forEach(convertTargetValueToString)
        )
        props.saveAndUpdate(dataCopy)
    }

    const convertTargetValueToString = (spec) => {
        if (spec.target.value) {

            spec.target.value = spec.target.value.toString()
        }
    }

    const customFieldsOptions = []
    customFields.forEach((field) => {
        const obj = {
            text: field.name,
            value: field.id,
        };
        customFieldsOptions.push(obj)
    })

    const settingUseCustomFields = (use: boolean): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table) => {
            table.specifications.forEach((spec) => {
                spec.target.use_custom_field = use;
                spec.target.custom_field_definition_id = customFieldsOptions[0].value;
            })
        })
        setUseCustomFields(use);
        setData(dataCopy);
    }

    const setDataFromProps = (): void => {
        const dataWithId = addIdsToData(props.data)
        setData(dataWithId);
        setSampleCount(props.data.tables[0].samples.length);
        setUseCustomFields(props.data.tables[0].specifications[0].target.use_custom_field);
    }

    const addIdsToData = (data: MeasurementTablesData): MeasurementTablesDataWithId => {
        const dataCopy = deepCopy(data);
        const tablesWithId: MeasurementTableWithId[] = dataCopy.tables.map((table) => {
            const specificationsWithIds: MeasurementSpecificationWithId[] = table.specifications.map((spec) => {
                return { ...spec, id: uuidv1() }
            })
            return { ...table, specifications: specificationsWithIds, id: uuidv1() }
        })
        return { ...dataCopy, tables: tablesWithId }
    }

    const addMeasurement = (): void => {
        const dataCopy = deepCopy(data);
        const newMeasurement: MeasurementSpecificationWithId = {
            id: uuidv1(),
            name: { C: '' },
            unit: data.tables[0].specifications[0].unit,
            target: {
                value: null,
                custom_field_definition_id: useCustomFields ? customFieldsOptions[0].value : null,
                use_custom_field: useCustomFields
            },
            min: null,
            max: null,
            deviation: null,
        }
        dataCopy.tables.forEach((table: MeasurementTable) => {
            table.specifications.push(deepCopy(newMeasurement));
        });
        updateSamples(dataCopy);
        setData(dataCopy);
    }

    const addProductVariation = (): void => {
        const dataCopy = deepCopy(data);
        const specifications = deepCopy(data.tables[0].specifications);
        specifications.forEach((spec) => {
            if (spec.target !== null) {
                spec.target = {
                    value: null,
                    custom_field_definition_id: useCustomFields ? customFieldsOptions[0].value : null,
                    use_custom_field: useCustomFields
                }
            }
        });
        const productVariation = {
            name: { C: '' },
            samples: [[{
                value: null
            }]],
            specifications: specifications,
            id: uuidv1()
        }
        dataCopy.tables.push(productVariation);
        updateSamples(dataCopy);
        setData(dataCopy);
    }

    const setSamples = (dataCopy: MeasurementTablesData, count: number): void => {
        const dataPoint: DataPoint = { value: null };
        const sample: DataPoint[] = [];
        const samples: Sample[] = [];
        dataCopy.tables[0].specifications.forEach(() => {
            sample.push(dataPoint);
        })
        for (let i = 0; i < count; i++) {
            samples.push(sample);
        }
        dataCopy.tables.forEach((table) => {
            table.samples = samples;
        });
    }

    const updateSamples = (dataCopy: MeasurementTablesData): void => {
        if (sampleCount > 0) {
            setSamples(dataCopy, sampleCount);
        }
        else {
            setSamples(dataCopy, 1);
        }
    }

    const changeProductVariationName = (newValue: MultiLanguageText, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables[index].name = newValue;
        setData(dataCopy);
        save();
    }

    const changeMeasurementName = (newValue: MultiLanguageText, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.map((table) => {
            table.specifications[index].name = newValue;
        })
        setData(dataCopy);
        save();
    }

    const setUnit = (unit: string): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table) => {
            table.specifications.forEach((spec) => {
                spec.unit = unit;
            })
        })
        setData(dataCopy);
    }

    const removeProductVariation = (index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.splice(index, 1);
        setData(dataCopy);
    }

    const removeMeasurement = (index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table: MeasurementTable) => {
            table.specifications.splice(index, 1);
        })
        setData(dataCopy);
    }

    const changeAbsDeviationMax = (value: number, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table) => {
            table.specifications[index].max = value;
            table.specifications[index].deviation = null;
        });
        setData(dataCopy);
    }

    const changeAbsDeviationMin = (value: number, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table) => {
            table.specifications[index].min = value;
            table.specifications[index].deviation = null;
        });
        setData(dataCopy);
    }

    const changeRelDeviation = (value: number, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables.forEach((table) => {
            table.specifications[index].deviation = value;
            table.specifications[index].max = null;
            table.specifications[index].min = null;
        });
        setData(dataCopy);
    }

    const changeTarget = (value: any, tableIndex: number, specIndex: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.tables[tableIndex].specifications[specIndex].target.value = value as string; //This is a hack, we store the value as a number but then converts is to a string on save. QARMA-7820
        setData(dataCopy);
    }

    const save = (): void => {
        const dataCopy = deepCopy(data);
        updateSamples(dataCopy)
    }

    const cancel = (): void => {
        props.cancel()
        setDataFromProps()
    }

    const setTargetAsCustomField = (id: string, specIndex: number, tableIndex: number): void => {
        const dataCopy = deepCopy(data);

        if (id === '' || id === undefined) {
            id = null;
        }

        dataCopy.tables[tableIndex].specifications[specIndex].target.custom_field_definition_id = id;
        setData(dataCopy);
    }

    const unit = data && data.tables[0].specifications[0].unit;
    const verticalCenter = { justifyContent: 'center', alignItems: 'center', display: 'flex' };
    const padding = { paddingRight: '10px' };
    const targetCellMinWidth = { minWidth: '120px' }
    const widths = ['750px', '900px', '1050px'];
    const customFieldsAvailable = isAllowedToUseCustomFields && customFields.length > 0;

    const content = <Grid textAlign='left' style={{ margin: '0px', width: widths[(data && data.tables.length - 1)] }}>
        <Grid.Row>
            <div style={verticalCenter}>
                <div style={padding}>
                    <FormattedMessage id="checklists.editor.measurementtable.samplecount" />
                </div>
                <div style={padding}>
                    <Input
                        className="cell"
                        type='number'
                        autoHeight
                        onChange={(evt, item): void => {
                            setSampleCount(parseInt(item.value));
                        }}
                        value={sampleCount}
                    />
                </div>
            </div>
            <div style={verticalCenter}>
                <div style={padding}>
                    <FormattedMessage id="checklists.editor.measurementtable.unit" />
                </div>
                <div style={padding}>
                    <Input
                        className="cell"
                        type='text'
                        autoHeight
                        placeholder={formatMessage({ id: 'checklists.editor.measurementtable.unit.placeholder' })}
                        onChange={(evt, item): void => { setUnit(item.value) }}
                        onBlur={save}
                        value={unit}
                    />
                </div>
            </div>
            {customFieldsAvailable && <div style={verticalCenter}>
                <div style={padding}>
                    <FormattedMessage id="checklists.editor.barcode.use_custom_field" />
                </div>
                <Checkbox checked={useCustomFields} onChange={(evt, item): void => settingUseCustomFields(item.checked)} />
            </div>}
        </Grid.Row>
        <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ overflowY: 'auto' }}>
                <Table definition>
                    <Table.Header>
                        <Table.HeaderCell />
                        <Table.HeaderCell>
                            <FormattedMessage id='checklists.editor.measurementtable.deviation.abs' values={{ unit: unit }} />
                        </Table.HeaderCell>
                        <Table.HeaderCell className='truncate'>
                            <FormattedMessage id='checklists.editor.measurementtable.deviation.rel' />
                        </Table.HeaderCell>
                        {data && data.tables.map((table, index) => {
                            return (
                                <Table.HeaderCell key={table.id} data-test-id='product-variation-column'>
                                    <div style={{ ...targetCellMinWidth, display: 'flex' }}>

                                        <MultiLangualText
                                            className="cell"
                                            presentation='input'
                                            originalText={table.name}
                                            language={props.language}
                                            placeholder={formatMessage({ id: 'checklists.editor.measurementtable.product_variation.placeholder' })}
                                            save={(name): void => changeProductVariationName(name, index)}
                                        />
                                        {data.tables.length > 1 &&
                                            <Button
                                                className="inverted-color"
                                                onClick={(): void => removeProductVariation(index)}
                                                disabled={data.tables.length < 2 || props.language !== 'C'}
                                                data-test-id='remove-variation-button'
                                            >
                                                -
                                            </Button>}
                                    </div>
                                </Table.HeaderCell>
                            )
                        })}
                        <Table.HeaderCell>
                            <Button
                                disabled={props.language !== 'C'}
                                className="inverted-color"
                                onClick={addProductVariation}
                                data-test-id='add-variation-button'
                            >
                                +
                            </Button>
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {data && data.tables[0].specifications.map((spec, index) => {
                            return (<Table.Row key={spec.id} data-test-id='measurement-row'>
                                <Table.Cell >
                                    <Form style={targetCellMinWidth}>
                                        <div style={{ display: 'flex' }}>
                                            <Button className="inverted-color"
                                                onClick={(): void => removeMeasurement(index)}
                                                disabled={data.tables[0].specifications.length < 2 || props.language !== 'C'}
                                                data-test-id='remove-measurement-button'
                                            >
                                                -
                                            </Button>
                                            <MultiLangualText
                                                presentation='area'
                                                style={{ minWidth: '140px' }}
                                                className="specname"
                                                originalText={spec.name}
                                                placeholder={formatMessage({ id: 'checklists.editor.measurementtable.product_specification.placeholder' })}
                                                language={props.language}
                                                save={(name): void => changeMeasurementName(name, index)}
                                            />
                                        </div>
                                    </Form>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className='flex'>
                                        <Input
                                            className="pr-1 w-24"
                                            type="number"
                                            onChange={(evt, item): void => { changeAbsDeviationMax(parseFloat(item.value), index) }}
                                            value={undefinedOrNull(spec.max) ? '' : spec.max}
                                            
                                        />
                                        <Input
                                            className="w-24"
                                            type="number"
                                            onChange={(evt, item): void => { changeAbsDeviationMin(parseFloat(item.value), index) }}
                                            value={undefinedOrNull(spec.min) ? '' : spec.min}
                                            
                                        />
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        className="cell w-24"
                                        type="number"
                                        onChange={(evt, item): void => changeRelDeviation(parseFloat(item.value), index)}
                                        value={undefinedOrNull(spec.deviation) ? '' : spec.deviation}
                                        
                                    />
                                </Table.Cell>
                                {data.tables.map((table, tableIndex) => {
                                    return (
                                        <Table.Cell key={tableIndex} style={targetCellMinWidth}>
                                            {useCustomFields && customFieldsAvailable ?
                                                <Dropdown selection
                                                    style={targetCellMinWidth}
                                                    closeOnBlur
                                                    value={table.specifications[index].target.custom_field_definition_id}
                                                    options={customFieldsOptions}
                                                    onChange={(evt, item): void => { setTargetAsCustomField(item.value as string, index, tableIndex) }}
                                                />
                                                :
                                                <Input
                                                    className="cell"
                                                    type="number"
                                                    onChange={(evt, item): void => changeTarget(parseFloat(item.value), tableIndex, index)}
                                                    value={undefinedOrNull(table.specifications[index].target.value) ? '' : table.specifications[index].target.value}
                                                    placeholder={formatMessage({ id: 'checklists.editor.measurementtable.notavailable' })}
                                                />
                                            }
                                        </Table.Cell>
                                    )
                                })}
                            </Table.Row>)
                        })}
                        <Table.Row>
                            <Table.Cell>
                                <Button
                                    disabled={props.language !== 'C'}
                                    onClick={addMeasurement}
                                    data-test-id='add-measurement-button'
                                >
                                    +
                                </Button>
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                            {data && data.tables.map((table, index: number) => {
                                return (
                                    <Table.Cell key={index} />
                                )
                            })}
                            <Table.Cell />
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </Grid.Row>
    </Grid>

    return (<Modal
        style={{ maxWidth: '1200px', width: 'unset' }}
        open={props.show}
        closeIcon={true}
        onClose={cancel}
    >
        <Modal.Header>
            <FormattedMessage id='checklist.measurement_table.dialog.header' />
        </Modal.Header>
        <Modal.Content >
            {content}
        </Modal.Content>
        <Modal.Actions>
            <Button positive onClick={saveAndReturn}><FormattedMessage id="globals.confirmation.save" /></Button>
            <Button onClick={cancel}><FormattedMessage id="globals.confirmation.cancel" /></Button>
        </Modal.Actions>
    </Modal>)
}

function undefinedOrNull(value): boolean {
    return value === undefined || value === null;
}

export default MeasurementTableDialog;