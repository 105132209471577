import { handleActions } from 'redux-actions';
import { Action } from './../../base/genericActions';
import * as types from './actions/actionTypes';
import { CustomFieldsState } from './types';

const initialState: CustomFieldsState = {
    customTables: [],
    data: undefined,
    productionUnitCustomFields: {groups: []},
    supplierCustomFields: {groups: []}
};

const reducer = handleActions({
    [types.GET_SUPPLIER_CUSTOM_FIELDS_SUCCESS]: (state = initialState, action: Action<types.GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS>) => {
        return Object.assign({}, state, {
            supplierCustomFields: action.payload.data,
        });
    },
    [types.GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS]: (state = initialState, action: Action<types.GET_PRODUCTION_UNIT_CUSTOM_FIELDS_SUCCESS>) => {
        return Object.assign({}, state, {
            productionUnitCustomFields: action.payload.data,
        });
    },
    [types.GET_CUSTOM_FIELDS_SUCCESS]: (state = initialState, action: Action<types.GET_CUSTOM_FIELDS_SUCCESS>) => {
        const cf =
            Object.assign({}, state, {
                data: action.payload.data,
            });
        return cf;
    },
    [types.GET_CUSTOM_TABLES]: (state = initialState, action: Action<types.GET_CUSTOM_TABLES>) => {
        const cf =
            Object.assign({}, state, {
                customTables: action.payload.customTables,
            });
        return cf;
    },
}, initialState);

export default reducer;
