import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps, Form, Grid, Input, TextArea } from 'semantic-ui-react';
import { removeArrayItem } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { InfoIcon } from '../../../globals/components/Utils';
import { getSupplierData } from '../../actions';
import { ProductionUnit, SupplierBooking } from '../../types';
import CreateProductionUnit from '../../../productionUnits/components/CreateProductionUnit';
import ProductionUnitsSelector from '../ProductionUnitsSelector';

type BookingInfoProps = {
    bookingData: SupplierBooking;
    isFetching: boolean;
    disabled?: boolean;
    canChangeProductionUnitData: boolean;
    canChangeBookingData: boolean;
    handleChange(evt: ChangeEvent | FormEvent, item: DropdownProps | unknown, save?: boolean): void;
    updateChanges(): void;
};

const BookingInfo = (props: BookingInfoProps): React.ReactElement => {
    const { bookingData, disabled = false, canChangeProductionUnitData, canChangeBookingData } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [supplierId, setSupplierId] = useState(undefined);
    const [changed, setChanged] = useState([]);
    const clearChange = (name: string): void => {
        setChanged(removeArrayItem(changed, name));
    }
    const handleChange = (item): void => {
        if (!changed.includes(item.name)) {
            setChanged([...changed, item.name]);
        }
    }
    const hasChanged = (name: string): void => {
        if (changed.includes(name)) {
            props.updateChanges();
            clearChange(name);
        }
    }
    useEffect(() => {
        if (bookingData.booking_info.supplier) {
            if (bookingData.booking_info.supplier.id !== supplierId) {
                setSupplierId(bookingData.booking_info.supplier.id);
                dispatch(getSupplierData(bookingData.booking_info.supplier.id));
            }
        }
    }, [bookingData]);
    const currentProductionUnit: ProductionUnit = bookingData.booking_info.production_unit;
    return (
        <Grid>
            <Grid.Row stretched className=''>
                <Grid.Column width={8} verticalAlign='top'>
                    <Form className={props.isFetching && 'disabled'}>
                        {!currentProductionUnit && <InfoIcon compact text={intl.formatMessage({ id: 'supplier_booking.booking_info.production_unit.not_set' })} />}
                        <Form.Field>
                            <label><FormattedMessage id='supplier_booking.booking_info.production_unit.name' /></label>
                            <ProductionUnitsSelector
                                supplierId={bookingData.booking_info.supplier && bookingData.booking_info.supplier.id}
                                handleChange={(evt, item: { name: 'target_production_unit.id'; value: any }): void => {
                                    props.handleChange(evt, { name: 'productionUnit', value: { value: item.value, name: item.value, address: [] } }, true);
                                }}
                                value={currentProductionUnit && currentProductionUnit.id || ''}
                                showUnassigned={true}
                                disabled={disabled || !canChangeProductionUnitData}
                            />
                            {supplierId && canChangeProductionUnitData && <CreateProductionUnit supplierId={supplierId} isStandalone={true}>
                                <span className='link padding indent'><FormattedMessage id='production_unit.create_production_unit' /></span></CreateProductionUnit>}
                        </Form.Field>
                        <Form.Field>
                            <label><FormattedMessage id='supplier_booking.booking_info.production_unit.address' /></label>
                            <TextArea
                                name='address'
                                value={bookingData.booking_info.production_unit && bookingData.booking_info.production_unit.address.join(', ') || ''}
                                readOnly={true}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={8} verticalAlign='top'>
                    {bookingData.booking_info && <Form className={props.isFetching && 'disabled'}>
                        <Form.Field>
                            <label><FormattedMessage id='supplier_booking.booking_info.contact_info' /></label>
                            <Input
                                disabled={props.disabled || bookingData.booking_info.production_unit === null || !canChangeBookingData}
                                name='contact_person'
                                value={bookingData.booking_info.production_unit && bookingData.booking_info.production_unit.contact_person || ''}
                                onChange={(evt: ChangeEvent, item: { name: string; value: string }): void => { handleChange(item); props.handleChange(evt, item); }}
                                onBlur={(): void => hasChanged('contact_person')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label><FormattedMessage id='supplier_booking.booking_info.note' /></label>
                            <TextArea
                                disabled={props.disabled || !canChangeBookingData}
                                name='note'
                                value={bookingData.booking_info && bookingData.booking_info.note || ''}
                                onChange={(evt: FormEvent, item: { name: string; value: string }): void => {
                                    handleChange(item);
                                    props.handleChange(evt, item);
                                }}
                                onBlur={(): void => hasChanged('note')}
                            ></TextArea>
                        </Form.Field>
                    </Form>}
                </Grid.Column>
            </Grid.Row>
        </Grid>

    );
};

export default BookingInfo;
