import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { DropdownFilter } from '.';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getInspectionTypesFilterSelector } from '../../inspections/selectors/selectors';
import { setFilter } from '../filtersActions';
import Filter from './Filter';

type InspectionTypesFilterProps = {
    selectedItems?: string[];
    disabled?: boolean;
    search?: boolean;
    showLabel?: boolean;
    filterId?: string;
    handleChange?(args): void;
};
const InspectionTypesFilter = (props: InspectionTypesFilterProps): React.ReactElement => {
    const { disabled = false, search = true, showLabel = true, handleChange, filterId } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const types: DropdownItemProps[] = useSelector(getInspectionTypesFilterSelector);
    const selectedTypes = getLocationEntry(location, filterId || 'inspection_type');
    return <Filter labelId={showLabel ? 'filters.inspection_type.label_inspection_type' : null}>
        <DropdownFilter
            name='inspection_type'
            options={types}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.inspection_type.select_inspection_type' })}
            selectedItems={selectedTypes || []}
            handleSave={(items): void => {
                dispatch(setFilter(filterId || 'inspection_type', items));
                if (handleChange) {
                    handleChange(items);
                }
            }}
            inline={false}
            search={search}
            disabled={disabled}
        />
    </Filter>;
};

export default InspectionTypesFilter;
