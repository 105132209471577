import { InputCustomFieldDefinition, InputCustomFieldGroup } from '../../../backend_api/models';
import { translateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';

export function twt(name = 'Frontend twt Placeholder') {
    return {
        text: name,
        language: 'C',
        translations: {},
        translation_method: 'manual'
    }
}

export function groupToOption(group: InputCustomFieldGroup, intl, locale) {
    return {
        description: intl.formatMessage({ id: 'checklists.editor.custom_fields.selector.add_entire_group' }),
        text: translateTextWithTranslation(group.name, locale),
        value: group.id,
        className: 'custom-field-group'
    };
}

export function definitionToOption(definition: InputCustomFieldDefinition, locale) {
    return {
        text: '  ' + translateTextWithTranslation(definition.name, locale),
        value: definition.id,
        className: 'custom-field-definition'
    }
}

export function doesDefinitionMatchQuery(definition: InputCustomFieldDefinition, locale, query: string | undefined) {
    if (!query || query === '') {
        return true;
    }

    const localeName = translateTextWithTranslation(definition.name, locale) || '';
    return localeName.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

export function doesGroupMatchQuery(group: InputCustomFieldGroup, locale, query: string | undefined) {
    if (!query || query === '') {
        return true;
    }

    const localeName = translateTextWithTranslation(group.name, locale) || '';
    return localeName.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

/* 
    - Never output .hidden definitions.
    - If there is a query, only output matching custom field definitions
    - If the query matches a group, output the whole group.
*/
export function choicesForDropdown(customFieldGroups: InputCustomFieldGroup[], intl, locale: string, query: string | undefined) {
    const choices = []

    customFieldGroups.forEach(function keepGroup(group: InputCustomFieldGroup,) {
        if (doesGroupMatchQuery(group, locale, query)) {

            /* The group matches, then add all non-hidden options from the group */
            choices.push(groupToOption(group, intl, locale));

            group.custom_field_definitions
                .filter(definition => !definition.hidden)
                .forEach(definition => {
                    choices.push(definitionToOption(definition, locale))
                })
        } else {
            const matchingAndVisibleDefinitions =
                group.custom_field_definitions
                    .filter(definition => !definition.hidden)
                    .filter(definition => doesDefinitionMatchQuery(definition, locale, query))

            if (matchingAndVisibleDefinitions.length > 0) {
                choices.push(groupToOption(group, intl, locale));
                matchingAndVisibleDefinitions.forEach(definition => {
                    choices.push(definitionToOption(definition, locale))
                })
            }
        }
    })

    return choices;
}