import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { MultipleChoiceOption, ReportCheckpoint } from '../../../../../backend_api/models';
import { isUndefinedOrNull, objectHasKeys } from '../../../../../base/utils';
import { TextItem } from '../../../../globals/components';

type CheckpointStatusProps = {
    checkpoint: ReportCheckpoint;
    width?: number;
    showInfo?: boolean;
    showLabel?: boolean;
    onlyStatusEnabled?: boolean;
};

const CheckpointStatus = (props: CheckpointStatusProps): React.ReactElement => {
    const intl = useIntl();
    const { checkpoint, width, showInfo, showLabel = false, onlyStatusEnabled = false } = props;
    const isInspection = !isUndefinedOrNull(checkpoint.inspection_id);
    let status: number | string = '-1';
    const [notEnabled, setNotEnabled] = useState<MultipleChoiceOption>(undefined);
    const ret: any = [];
    useEffect(() => {
        if (checkpoint.multiple_choice) {
            setNotEnabled(checkpoint.multiple_choice.options.filter((mp) => mp.chosen)[0]);
        }
    }, [props.checkpoint]);
    const getTrigger = (): React.ReactElement => {
        let colorStyle: string;
        const cn = classnames('status', `_${status}`, {
            notEnabled: !checkpoint.status_enabled,
        });
        if (notEnabled) {
            colorStyle = notEnabled.color;
        }
        return <div style={{ width: `${width}%`, backgroundColor: `${colorStyle}` }} className={cn}></div>;
    };
    const getText = (onlyStatus: boolean): any[] => {
        const text: any[] = [];
        if (checkpoint.status_enabled) {
            text.push(intl.formatMessage({ id: 'checkpoint.status.' + status }));
        }

        if (!checkpoint.status_enabled && isInspection) {
            text.push(intl.formatMessage({ id: 'checkpoint.status.not_selected' }));
        }
        if (notEnabled && objectHasKeys(checkpoint.multiple_choice) && !onlyStatus) {
            const t = [<div key='name' className='text'><FormattedMessage id='checklist.summary.multiple_choice_selected' />: {notEnabled.name && notEnabled.name.C} {notEnabled.description && <div>{notEnabled.description.C}</div>}</div>];
            text.push(t);
        }
        return text;
    }

    if (checkpoint.status.toString() === '-1') {
        if (checkpoint.comment && checkpoint.comment2 === null) {
            if (checkpoint.comment === 'Checked - OK') {
                status = '1';
            }
            if (checkpoint.comment === 'Checked - Not OK') {
                status = '4';
            }
        }
    } else {
        status = checkpoint.status;
    }
    const text = getText(false);
        
    if (text.length > 0 && showInfo) {
        ret.push(<Popup trigger={getTrigger()}>
            <TextItem column label={intl.formatMessage({ id: 'checkpoint.status.label_status' })} text={text} />
        </Popup>);
    } else {
        ret.push(getTrigger());
    }
    if (showLabel) {
        ret.push(<div className='text'>{getText(true)}</div>);
    }
    if (onlyStatusEnabled) {
        return checkpoint.status_enabled ? <div className='flex'>{ret}</div> : null;
    }

    return ret;
}

export default CheckpointStatus;
