import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Popup } from 'semantic-ui-react';
import Icon from '../../../base/components/basic/Icon';
import { twMerge } from '../../../base/utils';

type Props = {
    headerText?: string | React.ReactElement;
    subHeaderText?: string | React.ReactElement;
    headerTextExpanded?: string | React.ReactElement;
    headerActions?: React.ReactElement;
    content: React.ReactElement;
    expandedContent?: React.ReactElement;
    showExpanded?: boolean;
    className?: string;
    contentClassName?: string;
}

const ContentSection = (props: Props) => {
    const { showExpanded = false, content, expandedContent, headerText, headerActions, headerTextExpanded, subHeaderText, className, contentClassName } = props;
    const [expanded, setExpanded] = useState(false);
    const close = () => setExpanded(false);
    const header = <h3 className={classnames('w-full flex m-0 p-0 font-medium text-large', { 'justify-between cursor-pointer': showExpanded })} onClick={() => showExpanded ? setExpanded(!expanded) : null}>
        {headerText}
        {showExpanded && <Popup
            position='top center'
            trigger={<div><Icon className='text-secondary' name='open_in_full' /></div>}
        ><FormattedMessage id='corrective_actions.popup_more_info' /></Popup>}</h3>;
    return <div className={twMerge('px-4 pt-4 pb-6 bg-white border mb-4', className)}>
        {headerText && <div className='border-b -mx-4 px-4 pb-4'>
            <div className='flex justify-between w-full'>
                <div className='flex flex-row w-full items-center'>
                    {header}
                </div>
                <div className='flex whitespace-nowrap'>
                    {headerActions}
                </div>
            </div>
        </div>}
        <div className={twMerge('justify-center flex flex-col', headerText && 'pt-4', contentClassName)}>
            {subHeaderText && <div className='font-bold text-large pb-2'>{subHeaderText}</div>}
            {content}
        </div>
        {showExpanded &&
            <Modal
                onClose={() => setExpanded(false)}
                open={expanded}
                className='w-full flex'
                style={{ width: '90%', maxWidth: '1200px' }}
            >
                <Modal.Content>
                    <h3 className='pl-4'>{headerTextExpanded || headerText}</h3>
                    <div className='border-b -mx-6' />
                    {expandedContent}

                </Modal.Content>
                <Modal.Actions><Button onClick={() => close()}><FormattedMessage id='corrective_actions.content_section.close' /></Button></Modal.Actions>

            </Modal>
        }
    </div>
}

export default ContentSection;