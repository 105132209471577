import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { AuditCustomConclusion, AuditEventType, AuditHistoryEvent as AuditHistoryEventType, FileResource, TextWithTranslation } from '../../../backend_api/models';
import Image from '../../../base/components/Image';
import Comment from '../../../base/components/comments/Comment';
import CommentInput from '../../../base/components/comments/CommentInput';
import TranslateComment from '../../../base/components/comments/TranslateComment';
import { getPrettyDatetime } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { UserNameText } from '../../users/components/Widgets';
import { addAuditComment } from '../slices/auditSlice';
import AuditHistoryList from './AuditHistoryList';

type Props = {
    className?: string;
    event: AuditHistoryEventType;
    level?: number;
};

const AuditHistoryEvent = (props: Props): React.ReactElement => {
    const { className, event, level } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const hasChildEvents = event.events.length > 0;
    const commentEvents = hasChildEvents ? event.events.filter((e) => e.event_type === AuditEventType.CommentEvent) : [];
    const attachmentEvents = event.attachment_event && event.attachment_event.attachments;
    const indent = level > 0 ? ((level < 4) ? 15 : 10) : 0;

    const addNewComment = (comment: string, uploads?: FileResource[], isReply?: boolean) => {
        const attachments = [];
        uploads.forEach((upload) => {
            attachments.push({ [upload.type]: upload[upload.type], type: upload.type, name: upload.name });
        });
        dispatch(addAuditComment({ auditId: event.audit_id, comment, attachments, eventId: isReply && event.id }))
    }
    const getContainer = (children: ReactNode): ReactNode => {
        return <div className='flex flex-col pb-4 space-y-2'>
            {children}
        </div>
    }
    const getComment = (comment: TextWithTranslation): ReactNode => {
        return <div className=''><TranslateComment comment={comment} /></div>;
    }
    const getUserAndTime = (event: AuditHistoryEventType): ReactNode => {
        return <>
            <div className='flex justify-between mb-2 w-full'>
                <div className='text-secondary font-medium w-2/3'><UserNameText user={event.author} /></div>
                <div className='text-sm text-gray-500 w-1/3 text-right'>
                    <div>{getPrettyDatetime(event.timestamp, intl.locale || null)}</div>
                </div>
            </div>
        </>
    }
    const getAuditConclusionItem = (approvalConclusion: AuditCustomConclusion) => {
        const style = { backgroundColor: approvalConclusion.background_color, color: approvalConclusion.text_color }
        return <Popup position='top center' trigger={
            <span>
                {event.approval_step_updated.behalf_of &&
                    <div className='justify-start flex space-x-1 text-secondary text-sm pt-2 pb-1'><FormattedMessage id='audits.history.approved_on_behalf_of' values={{ name: <UserNameText className='ml-1' user={event.approval_step_updated.behalf_of} /> }} /></div>}

                <span className='flex w-full justify-start space-x-2 items-center'>
                    <span className='space-x-1'><FormattedMessage id='audits.history.conclusion_set_to' /></span><span className={twMerge('place-self-end text-sm p-1 rounded')} style={style}>
                        {approvalConclusion.name && approvalConclusion.name.text}
                    </span></span></span>}
        >{approvalConclusion.description.text}</Popup>;
    }
    return <div className={twMerge('pt-3', level > 0 && 'border-l', level === 0 && 'border-b', className)} style={{ paddingLeft: indent + 10 + 'px' }}>
        {event && event.event_type === AuditEventType.CommentEvent &&
            <Comment
                attachments={event.comment_event.attachments}
                level={level}
                handleNewComment={addNewComment}
                author={event.author}
                comment={event.comment_event.comment}
                timestamp={event.timestamp}
                commentInput={<CommentInput handleNewComment={addNewComment} isReply={true} />}
            />}
        {event && event.event_type === AuditEventType.ApprovalFlowUpdated && 
        getContainer(<div className='text-secondary'>
            {getUserAndTime(event)}
            <div className='text-primary'><FormattedMessage id='audits.history.entry.approval_flow_was_updated' /></div>
        </div>)}

        {event && event.event_type === AuditEventType.ApprovalStepUpdated &&
            getContainer(<>{getUserAndTime(event)}
                {getComment(event.approval_step_updated.comment)}
                {getAuditConclusionItem(event.approval_step_updated.conclusion)}
                <CommentInput handleNewComment={addNewComment} isReply={true} />
            </>)
        }
        {event && event.event_type === AuditEventType.AttachmentEvent &&
            getContainer(<>{getUserAndTime(event)}
                <div className='mb-2'>{attachmentEvents.length > 1 ? <FormattedMessage id='audits.history.attachments_were_added' values={{ cnt: attachmentEvents.length }} /> : <FormattedMessage id='audits.history.an_attachment_was_added' />}</div>
                <div className='text-secondary text-sml'>
                    {event.attachment_event && event.attachment_event.attachments.map((event) => {
                        return <div className='w-full mb-2' key={'att_' + event.id} >
                            {event.image &&
                                <a href={event.image.image_url} className='text-secondary text-sml link'>
                                    <Image squared url={event.image && event.image.image_url} />
                                </a>}
                            <a href={event.file && event.file.download_url || event.image && event.image.image_url || event.video && event.video.video_resources[0].url} className='text-secondary text-sml link'>{event.name}</a></div>
                    })}</div></>)

        }

        {event && event.event_type === AuditEventType.FinalConclusionEvent &&
            getContainer(<>{getUserAndTime(event)}
                <div className='flex flex-row justify-between mb-2'>
                    <div className='flex flex-col space-y-2'>
                        <div className='flex flex-row space-x-1'>
                            <div><FormattedMessage id='audits.conclusion.conclusion_set_to' /></div>
                            <div className='text-sm px-1 py-0 rounded' style={{ color: event.final_conclusion_event.conclusion.text_color, backgroundColor: event.final_conclusion_event.conclusion.background_color }}>{event.final_conclusion_event.conclusion.name.text}</div>
                        </div>
                        <div className='text-secondary'><TranslateComment comment={event.final_conclusion_event.comment} /></div>
                    </div>
                </div>
                <CommentInput handleNewComment={addNewComment} isReply={false} />
            </>)

        }
        {hasChildEvents && <AuditHistoryList level={level + 1} events={commentEvents} />}
    </div>
}
export default AuditHistoryEvent;
