import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownProps, Popup, Select } from 'semantic-ui-react';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { Checklist } from '../types';

type ChecklistsSelectorProps = {
    checklists: Checklist[];
    value: string;
    text?: string;
    checklistName?: string;
    className?: string;
    isFluid?: boolean;
    disabled?: boolean;
    showUnassigned?: boolean;
    stopPropagation?: boolean;
    dataTestId?: string;
    handleChange(event: SyntheticEvent, data: DropdownProps): void;
    handleBlur?(event: SyntheticEvent, data: DropdownProps): void;
    didFocus?(event: SyntheticEvent, data: DropdownProps): void;
};

const ChecklistsSelector = (props: ChecklistsSelectorProps): React.ReactElement => {
    const intl = useIntl();
    const { isFluid = false, showUnassigned = false, text, stopPropagation = false } = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [name, setName] = useState(undefined);
    const [value, setValue] = useState(props.value);
    const cn = props.className || '';
    const disabled = props.disabled;

    useEffect(() => {
        const items = [];
        Object.keys(props.checklists).forEach((key) => {
            const checklist = props.checklists[key];
            const id = checklist.id;
            const chlText = checklist.text;
            if (props.value === id) {
                setName(chlText);
            }
            items.push({ key: id, value: id, id, text: chlText });
        });
        if (showUnassigned) {
            items.push({ key: null, id: null, value: null, text: <span className='emphasizeUnassignedOptionInDropdown'><FormattedMessage id='checklists.selector_set_entry_to_unassiged' /></span> });
        }
        setOptions(items);
    }, [props.checklists]);
    useEffect(() => {
        let index = -1;
        const items = props.checklists;
        const isModified = items.filter((cd) => cd.id === '-1').length > 0;
        Object.keys(props.checklists).forEach((key, i) => {
            const checklist = props.checklists[key];
            const id = checklist.id;
            const chlText = checklist.text;
            if (value === id) {
                setName(chlText);
            }
            if (id === '-1') {
                index = i;
            }
            delete checklist.description;
        });
        if (value === '-1' && !isModified) {
            items.push({ id: '-1', value: '-1', features: [] });
            setName(props.text);
        }
        if (value !== '-1' && isModified) {
            items.splice(index, 1);
        }
        setOptions(items);
    }, [value]);
    const dd = <Select
        onClick={(e) => stopPropagation && preventDefaultAndStopPropagation(e)}
        selectOnNavigation={false}
        name={props.checklistName || 'checklist_id'}
        className={cn}
        value={value}
        text={open ? null : (name || text)}
        placeholder={intl.formatMessage({ id: 'checklists.select_checklist.label' })}
        search
        fluid={isFluid}
        selection
        disabled={disabled}
        options={open ? options : []}
        onChange={(evt, data): void => {
            stopPropagation && preventDefaultAndStopPropagation(evt);
            props.handleChange(evt, data);
            setValue(data.value as string);
        }}
        onBlur={props.handleBlur}
        onFocus={props.didFocus}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        data-test-id={props.dataTestId}
    />;
    return ((name && name.length > 10)) ?
        <Popup
            on='hover'
            position='top center'
            trigger={disabled ? <span className={'overflow-hidden'}>{dd}</span> : dd}>
            <Popup.Content>{name}</Popup.Content>
        </Popup>
        :
        dd;
};

export default ChecklistsSelector;
