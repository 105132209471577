/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 96.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *   Add a comment to an entity.
 * @export
 * @interface AddCommentBody
 */
export interface AddCommentBody {
    /**
     * 
     * @type {string}
     * @memberof AddCommentBody
     */
    entity_id: string;
    /**
     * 
     * @type {string}
     * @memberof AddCommentBody
     */
    entity_type: AddCommentBodyEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AddCommentBody
     */
    text: string;
}

/**
* @export
* @enum {string}
*/
export enum AddCommentBodyEntityTypeEnum {
    Supplier = 'supplier',
    ProductionUnit = 'production_unit'
}

export function AddCommentBodyFromJSON(json: any): AddCommentBody {
    return AddCommentBodyFromJSONTyped(json, false);
}

export function AddCommentBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCommentBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity_id': json['entity_id'],
        'entity_type': json['entity_type'],
        'text': json['text'],
    };
}

export function AddCommentBodyToJSON(value?: AddCommentBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity_id': value.entity_id,
        'entity_type': value.entity_type,
        'text': value.text,
    };
}


