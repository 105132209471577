import classnames from 'classnames';
import React from 'react';
import { DefectSeverities, DefectSeverity } from '../../inspections/types';

interface DefectsShortProps {
    defects: {
        minor: number;
        major: number;
        critical: number;
    };
}
const DefectsShort = (props: DefectsShortProps): React.ReactElement => {
    const hasMinor = props.defects.minor > 0;
    const hasMajor = props.defects.major > 0;
    const hasCritical = props.defects.critical > 0;
    return (
        <div className='defectsShort'>
            <div className={`item ${hasMinor && 'minor'}`}>
                {props.defects.minor}
            </div>
            <div className={`item ${hasMajor && 'major'}`}>
                {props.defects.major}
            </div>
            <div className={`item ${hasCritical && 'critical'}`}>
                {props.defects.critical}
            </div>
        </div>
    );
};

type DefectCountProps = {
    type: DefectSeverities;
    cnt: number;
};

const DefectCount = (props: DefectCountProps): React.ReactElement => {
    return <div className='flex'><div className={classnames('defectItem text-sm px-1 justify-center min-w-8 rounded-sm flex', props.type)}>{props.cnt}</div></div>;
};

type DefectsLineProps = DefectSeverity;

const DefectsLine = (props: DefectsLineProps): React.ReactElement => {
    const defects = props;
    let criticalClass = 'critical';
    let majorClass = 'major';
    let minorClass = 'minor';

    if (defects.critical > 0) {
        criticalClass = 'defect critical attention';
    }
    if (defects.major > 0) {
        majorClass = 'defect major attention';
    }
    if (defects.minor > 0) {
        minorClass = 'defect minor attention';
    }
    return (
        // TODO: switch to custom semantic-ui label
        <span className='defectsLine'>
            {defects.critical > 0 && <span className={criticalClass}>{defects.critical}</span>}
            {defects.major > 0 && <span className={majorClass}>{defects.major}</span>}
            {defects.minor > 0 && <span className={minorClass}>{defects.minor}</span>}
        </span>
    );
};
export { DefectsLine, DefectsShort, DefectCount };
