import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { unlockInspection } from '../../actions/unlockActions';

type UnlockInspectionProps = {
    className?: string;
    inspectionId: string;
    type?: 'button' | 'link';
};

const UnlockInspection = (props: UnlockInspectionProps): React.ReactElement => {
    const { className, type = 'button' } = props;
    const dispatch = useAppDispatch();
    const { inspectionId } = props;
    const unlock = () => {
        dispatch(unlockInspection(inspectionId));
    }
    if (type === 'button') {
        return <div className='p-2'><Button noBorder small onClick={(): void => unlock()}>
            <FormattedMessage id='inspections.unlock' />
        </Button></div>
    }
    return <div onClick={(): void => unlock()} className={classnames(className, 'cursor-pointer py-2')}><FormattedMessage id='inspections.unlock' /></div>
}

export default UnlockInspection;
