import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Input, Modal, Popup, Ref, TextArea } from 'semantic-ui-react';
import { deepCopy } from '../../../base/utils';
import { MultiLanguageText } from '../types';

type MultiLangualTextProps = {
    presentation: 'area' | 'input';
    originalText: MultiLanguageText;
    language: string;
    save(text: MultiLanguageText);
    focus?: boolean;
    style?: any;
    onMouseEnter?();
    onMouseLeave?();
    placeholder?: string;
    onKeyDown?(e: any);
    className?: string;
}

const MultiLangualText = (props: MultiLangualTextProps): React.ReactElement => {
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [value, setValue] = useState<string>(props.originalText[props.language]);
    const [ref, setRef] = useState(null)

    const save = (): void => {
        if (props.language === 'C' && Object.keys(props.originalText).length > 1 && props.originalText.C !== value) {
            setShowWarning(true);
        }
        else if ((props.originalText[props.language] != null || value != '') && props.originalText[props.language] !== value) {
            keep();
        }
    }

    // This is done because clicking on a semantic ui checkbox does not take focus and it is therefore not enough to trigger onBlur.
    useEffect(() => {
        const listener = (): void => {
            if (props.originalText[props.language] !== value) {
                save();
            }
        }
        if (ref) {
            document.addEventListener('click', listener, { capture: true })
        }
        return (): void => {
            document.removeEventListener('click', listener, { capture: true })
        }
    })

    useEffect(() => {
        setValue(props.originalText[props.language] || '');
    }, [props.language, props.originalText])

    const reset = (): void => {
        setShowWarning(false);
        setValue(props.originalText[props.language])
    }

    const focus = (node): void => {
        setRef(node);
        if (node && props.focus) {
            if (props.presentation === 'area') {
                node.firstElementChild.focus();
            }
            if (props.presentation === 'input') {
                node.firstElementChild.firstElementChild.focus();
            }
        }
    }

    const keep = (): void => {
        const originalTextCopy = deepCopy(props.originalText);
        originalTextCopy[props.language] = value;
        props.save(originalTextCopy);
        setShowWarning(false);
    }

    const clear = (): void => {
        props.save({ 'C': value });
        setShowWarning(false);
    }

    return <Fragment>
        <Ref innerRef={focus}>
            <Popup
                on='hover'
                disabled={props.language === 'C'}
                trigger={
                    <Form style={props.style}>
                        {props.presentation === 'area' ?
                            <TextArea
                                rows={1}
                                value={value}
                                onChange={(evt, item): void => setValue(item.value as string)}
                                placeholder={props.language === 'C' ? props.placeholder : props.originalText.C}
                                onMouseEnter={props.onMouseEnter}
                                onMouseLeave={props.onMouseLeave}
                                onKeyDown={props.onKeyDown}
                                onBlur={save}
                            />
                            :
                            <Input
                                className={props.className}
                                type='text'
                                value={value}
                                onChange={(evt, item): void => setValue(item.value as string)}
                                placeholder={props.language === 'C' ? props.placeholder : props.originalText.C}
                                onMouseEnter={props.onMouseEnter}
                                onMouseLeave={props.onMouseLeave}
                                onKeyDown={props.onKeyDown}
                                onBlur={save}
                            />}
                    </Form>
                }
            >
                <Popup.Content>{props.originalText.C}</Popup.Content>
            </Popup>
        </Ref>
        <Modal open={showWarning}>
            <Modal.Header>
                <FormattedMessage id='globals.translationtext.warning_modal.header' />
            </Modal.Header>
            <Modal.Content>
                <FormattedMessage id='globals.translationtext.warning_modal.text' />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={keep}><FormattedMessage id='globals.translationtext.warning_modal.keep' /></Button>
                <Button onClick={clear}><FormattedMessage id='globals.translationtext.warning_modal.clear' /></Button>
                <Button onClick={reset}><FormattedMessage id='inspections.cancel' /></Button>
            </Modal.Actions>
        </Modal>
    </Fragment >
}

export default MultiLangualText;