import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import Image from '../../../base/components/Image';
import { ACCEPTED_UPLOAD_IMAGE_FILE_TYPES, SERVICES_PREFIX_COMPLIANCE } from '../../../base/config';
import { useAppDispatch } from '../../../store';
import FileUpload from '../../files/components/FileUpload';

type Props = {
    className?: string;
    edit: boolean;
    image: string;
    updateImage(imageId: string): void;
};

const ComplianceDetailedProductImage = (props: Props): React.ReactElement => {
    const { className, edit, image, updateImage } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);

    return <div className={twMerge('flex flex-1 w-full items-start justify-center', edit && 'border-input border rounded-md ')}>

        {!edit && image && <Image url={image + '?w=250'} />}

        {edit && <div className='flex items-center justify-center flex-col p-3 '>
            {image && <Image className='pb-2' url={image + '?w=250'} />}
            <FileUpload
                asFileResource={true}
                acceptedFileTypes={ACCEPTED_UPLOAD_IMAGE_FILE_TYPES}
                allowMultiUpload={false}
                showButton={true}
                fileSelectorCustomIcon='operation_take-photo'
                fileSelectorCustomIconClass='text-on-surface-secondary'
                fileSelectorLabelText={intl.formatMessage({ id: image ? 'compliance.product_detail.product_image.replace' : 'compliance.product_detail.product_image.add' })}
                fileSelectorLabelClass={'bg-surface-secondary text-sm cursor-pointer py-1 px-3 rounded text-on-surface-secondary '}
                endpoint={SERVICES_PREFIX_COMPLIANCE + '/upload_media/'}                
                onUploadComplete={(file) => {
                    updateImage(file as any);
                }}
                autoClearAfterUpload={true}
            />
        </div>
        }
    </div>
}

export default ComplianceDetailedProductImage;
