import { DocRenderer } from '@cyntler/react-doc-viewer';
import React, { useState } from 'react';
import { Page, Document as PdfDocument, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

export const PdfRenderer: DocRenderer = ({
    mainState: { currentDocument, rendererRect },
}) => {


    const [numPages, setNumPages] = useState<number>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [dimensions, setDimensions] = useState(null);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <PdfDocument className={''} file={currentDocument.uri} onLoadSuccess={onDocumentLoadSuccess} >
            {[...Array(numPages).keys()].map(i => {
                return <><Page pageNumber={i + 1} className={''} width={rendererRect.width} /></>
            })}
        </PdfDocument>
    )
}
PdfRenderer.fileTypes = ['application/pdf'];
PdfRenderer.weight = 0

