import qs from 'query-string';
import { createAction } from 'typesafe-actions';
import history from '../../../store/history';
import { clearSearch } from '../../search/actions';
import * as types from './actionTypes';

export const setUsersFilterRequest = createAction(types.SET_USERS_FILTER, (name: string, value: string | string[]) => ({
    type: types.SET_USERS_FILTER, payload: { name, value },
}));

export const setUsersFilter = (name: string, value: string | string[]) => {
    return ((dispatch, getState): void => {
        dispatch({ type: types.SET_USERS_FILTER, payload: { name, value } });
        const filters = getState().app.users.filters;
        if (filters.page) { delete filters.page; }
        const params = name === 'search' ? { search: filters.search } : filters
        history.push('?' + qs.stringify(params));
    });
};

export const setUsersFilters = (): any => {
    const filters = qs.parse(history.location.search);
    return ((dispatch): void => {
        dispatch({ type: types.SET_USERS_FILTERS, payload: { filters } });
    });
};

export const setCurrentUsersShowStatus = (): any => {
    const status = history.location.pathname.slice(7);
    return ((dispatch): void => {
        dispatch({ type: types.SET_USERS_SHOW_STATUS, payload: { status: status ? status : 'active' } });
    });
};

export const setUsersShowStatus = (status: string) => {
    return ((dispatch, getState): void => {
        const currentStatus = Object.assign({}, getState().app.users.status);
        dispatch({ type: types.SET_USERS_SHOW_STATUS, payload: { status } });
        if (status !== currentStatus) {
            let pathname = '/users';
            switch (status) {
                case 'all':
                    pathname = '/users/all';
                    break;
                case 'active':
                    pathname = '/users';
                    break;
                case 'disabled':
                    pathname = '/users/disabled';
                    break;
            }
            const params = qs.parse(history.location.search);
            if (params.page) { delete params.page }
            history.push({
                pathname,
                search: qs.stringify(params)
            });
        }
    });
};

export const setUsersFiltersVisibiltyRequest = createAction(types.SET_USERS_FILTERS_VISIBILITY, (visibility: boolean) => ({
    type: types.SET_USERS_FILTERS_VISIBILITY, payload: { visibility },
}));

export const setUsersFiltersVisibilty = (visibility: boolean) => {
    return ((dispatch): void => {
        dispatch({ type: types.SET_USERS_FILTERS_VISIBILITY, payload: { visibility } });
    });
};

export const clearUsersFilters = () => {
    return ((dispatch, getState): void => {
        const filters = Object.assign({}, getState().app.users.filters);
        const params = qs.parse(history.location.search);
        Object.keys(filters).forEach((filterName) => {
            delete filters[filterName];
            delete params[filterName];

        });
        history.push('?' + qs.stringify(params));
        dispatch(clearSearch());
    });
};

export const setActiveHiddenFiltersRequest = createAction(types.SET_USERS_HIDDEN_FILTERS, (filters: string[]) => ({
    type: types.SET_USERS_HIDDEN_FILTERS, payload: { filters },
}));

export const setActiveHiddenFilters = () => {
    return ((dispatch, getState): void => {
        const filters: any = [];
        getState().app.users.hiddenFilters.forEach((filter) => {
            if (getState().app.users.filters[filter]) {
                filters.push(filter);
            }
        });
        dispatch(setActiveHiddenFiltersRequest(filters));
    });
};