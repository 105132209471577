import { createAction } from 'typesafe-actions';
import * as types from './types';

export const storeStuff = createAction(types.STORE_STUFF, (stuff: string) => ({
    type: types.STORE_STUFF,
    payload: {
        stuff,
    }
}));

export const getStuff = (stuffString: string) => {
    return (dispatch): void => {
        dispatch(storeStuff(stuffString));
    }
};
