export const SEND_MESSAGE = 'SEND_MESSAGE';
export type SEND_MESSAGE = {
    text: any[];
    hideAfterMs?: number;
    type?: string;
    header?: string;
    showIcon?: boolean;
};

export const SEND_STATUS_MESSAGE = 'SEND_STATUS_MESSAGE';
export type SEND_STATUS_MESSAGE = {
    text: any[];
    hideAfterMs?: number;
    showErrorMessages?: boolean;
    type?: string;
    header?: string;
    showIcon?: boolean;
};

export const SEND_ERROR_MESSAGE = 'SEND_ERROR_MESSAGE';
export type SEND_ERROR_MESSAGE = {
    text: any[];
    hideAfterMs?: number;
    showErrorMessages?: boolean;
    type?: string;
    header?: string;
    showIcon?: boolean;
};

export const SEND_GLOBAL_MESSAGE = 'SEND_GLOBAL_MESSAGE';
export type SEND_GLOBAL_MESSAGE = {
    text: any[];
    hideAfterMs?: number;
    showErrorMessages?: boolean;
    type?: string;
    header?: string;
    showIcon?: boolean;
};

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export type CLEAR_MESSAGE = any;

export const CLEAR_GLOBAL_MESSAGE = 'CLEAR_GLOBAL_MESSAGE';
export type CLEAR_GLOBAL_MESSAGE = any;

export const CLEAR_STATUS_MESSAGE = 'CLEAR_STATUS_MESSAGE';
export type CLEAR_STATUS_MESSAGE = any;

export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export type CLEAR_ERROR_MESSAGE = any;

export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES';
export type CLEAR_ALL_MESSAGES = any;
