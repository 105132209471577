import { ReactText } from 'react';

export interface ById<TValue> {
    [id: string]: TValue;
}

export type Filters = {
    search?: string;
    roles?: string[];
    groups?: string[];
}
export type ShowStatus = 'active' | 'all' | 'disabled';
export enum UserFilters { SEARCH = 'search', ROLES = 'roles', GROUPS = 'groups' }
export type DropdownValue = string | number | boolean | (string | number | boolean)[];
export type DropdownOptionType = {
    key: string;
    value: ReactText;
    text: string;
    content?: React.ReactElement;
    disabled?: boolean;
};

// TODO: Check User Preference Type
export type UserPreferences = {
    commentsShowAllTranslations?: boolean;
}

export type UserFeatureTypes =
    'access' |
    'list' |
    'access_org_data' |
    'edit' |
    'manage';
