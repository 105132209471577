/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisplayCustomFieldMultipleChoiceOption } from './DisplayCustomFieldMultipleChoiceOption';

export type DisplayCustomFieldMultipleChoiceValue = {
    multiple_selection_allowed: boolean;
    options: Array<DisplayCustomFieldMultipleChoiceOption>;
    type: DisplayCustomFieldMultipleChoiceValue.type;
};

export namespace DisplayCustomFieldMultipleChoiceValue {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

