import React from 'react';
import { useIntl } from 'react-intl';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import StatusIcon from '../../../base/ui/components/status/StatusIcon';
import { twMerge } from '../../../base/utils';
import { OrganizedProductRequiredDocumentStateEnum } from '../../../compliance_api/models';

type Props = {
    className?: string;
    state: OrganizedProductRequiredDocumentStateEnum;
    hasFiles: boolean;
    isIgnored?: boolean;
};
const ComplianceProductRequirementDocumentState = (props: Props): React.ReactElement => {
    const { className, state, isIgnored } = props;
    const intl = useIntl();

    const getState = () => {
        let s;
        let icon: Icons;
        let iconBase: string = 'rounded-full text-base p-2';
        let iconClass: string;
        if (isIgnored) {
            return <StatusIcon status='skipped' />
        }
        switch (state) {
            case OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval:
                icon = 'operation_add';
                iconClass = 'bg-[#eeeeff] text-lg text-secondary' // TODO: Figma get color var from Figma: text-status-bg-none-planned
                s = <StatusIcon status='empty' showInfo={false} />
                break;
            case OrganizedProductRequiredDocumentStateEnum.ReadyForApproval:
                icon = 'operation_review'
                iconClass = 'text-white text-lg bg-status-bg-pending';
                s = <StatusIcon status='pending' showInfo={false} />
                break;
            case OrganizedProductRequiredDocumentStateEnum.Approved:
                icon = 'status_approved'
                iconClass = 'text-white text-lg bg-status-bg-approved';
                s = <StatusIcon status='approved' showInfo={false} />
                break;
            case OrganizedProductRequiredDocumentStateEnum.Rejected:
                icon = 'operation_review'
                iconClass = 'text-white bg-qarma-green';
                s = <StatusIcon status='rejected' showInfo={false} />

                break;

            default:
                icon = 'operation_add'
                iconClass = 'text-lg bg-[#eeeeff] text-secondary' // TODO: Figma get color var from Figma: text-status-bg-none-planned
                break;
        }
        if (props.hasFiles && state === OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval) {
            s = <StatusIcon status='not-submitted-yet' />
        }
        return s;
    }
    return <div className={twMerge('', className)}>
        {getState()}
    </div>
}
export default ComplianceProductRequirementDocumentState;
