import React, { useEffect, useRef, useState } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';

type Props = {
    className?: string;
    clear?: boolean;
    autoFocus?: boolean;
    handleTokenDone?(code: string): void;
    handleTokenChanged?(token: string): void;
};

const TwoFactorCodeInput = (props: Props): React.ReactElement => {
    const { handleTokenChanged } = props;
    const AuthInputRef = useRef<AuthCodeRef>(null);
    const [token, updateToken] = useState(null);
    const CODE_LEN = 6;

    useEffect(() => {
        if (props.clear) {
            updateToken(['', '', '', '', '', '']);
            AuthInputRef.current?.clear()
        }
    }, [props.clear]);

    return <AuthCode
        autoFocus={true}
        ref={AuthInputRef}
        allowedCharacters='numeric'
        inputClassName='border rounded w-12 h-12 text-xl text-center focus:border-brand focus:border-2'
        containerClassName='self-center flex flex-row space-x-4 pt-2 pb-8 text-xl justify-center'
        onChange={
            (token) => {
                const tokenDone = token.length === CODE_LEN;
                updateToken(token);
                handleTokenChanged && handleTokenChanged(tokenDone ? token : null);
                if (tokenDone) {
                    props.handleTokenDone(token);
                }
            }} />

}
export default TwoFactorCodeInput;
