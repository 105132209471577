import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { getPrettyDate } from '../../../../../base/utils';
import { ListViewItem, ListViewLine } from '../../../../globals/components/views/list/ListView';
import { isInspectionLockedOrDone } from '../../../../inspections/Utils';
import { CombinedInspectionLabel } from '../../../../inspections/components/combine/CombinedInspections';
import { InspectionTypeMarker } from '../../../../inspections/components/inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../../../../inspections/components/shared/SplitShipmentsMarker';
import { Inspection } from '../../../../inspections/types';
import BookingStatus from '../BookingStatus';

type BookingLineProps = {
    inspection: Inspection;
    className?: string;
    selecting?: boolean;
    isSelected?: boolean;
    isSelectable: boolean;
    select(remove: boolean);
};

const BookingLine = (props: BookingLineProps): React.ReactElement => {
    const { inspection, className } = props;
    const isDone = isInspectionLockedOrDone(inspection);
    const bookingUrl = props.selecting ? null : '/booking/' + inspection.order_id;
    const locale = useIntl().locale;
    const isSelectable = props.selecting && props.isSelectable;
    const bookingDate = (): React.ReactElement | string => {
        if (inspection.booking_date) {
            if (props.inspection.overdue) {
                return <div className='border-b border-danger'>{getPrettyDate(inspection.booking_date, locale)}</div>;
            }
            else {
                return getPrettyDate(inspection.booking_date, locale);
            }
        }
        else {
            return <BookingStatus inspection={inspection} />;
        }
    }
    return (<ListViewLine link={bookingUrl} className={classnames(['bookingLine', isDone && 'text-secondary', className])}>
        <ListViewItem className={props.selecting ? 'w-2/16' : 'w-0/16'}>{isSelectable && <Button primary onClick={(): void => props.select(props.isSelected)} >{props.isSelected ? <FormattedMessage id="inspections.remove" /> : <FormattedMessage id="inspections.select" />}</Button>}</ListViewItem>
        <ListViewItem className='w-1/16'><InspectionTypeMarker type='dashboard' inspections={[inspection]} /></ListViewItem>
        <ListViewItem className='w-3/16 space-x-1 flex'>
            <div className='mr-1'>{inspection.item_number}</div><SplitShipmentsMarker inspection={inspection} />
            <CombinedInspectionLabel className='' inspection={inspection} type='short'/>            
            </ListViewItem>
        <ListViewItem className='w-3/16'>{inspection.item_name}</ListViewItem>
        <ListViewItem className='w-1/16'>{inspection.total_quantity}</ListViewItem>
        <ListViewItem className='w-2/16'>{bookingDate()}</ListViewItem>
        <ListViewItem className={props.selecting ? 'w-0/16' : 'w-2/16'}>{inspection.last_confirmed_at ? getPrettyDate(inspection.last_confirmed_at, locale) : '-'}</ListViewItem>
        <ListViewItem className='w-2/16'>{inspection.scheduled_inspection_date ? getPrettyDate(inspection.scheduled_inspection_date, locale) : '-'}</ListViewItem>
    </ListViewLine>
    );
};

export default BookingLine;
