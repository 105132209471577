import React from 'react';
import { PressEvent, usePress } from 'react-aria';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { MicroInteractionClose } from '../../ui/components/MicroInteractions';


type RemoveIconProps = {
    className?: string;
    dataTestId?: string;
    iconClassName?: string;
    popupText?: string | React.ReactNode;
    onClick?(e: React.MouseEvent): void;
    onPress?(e: PressEvent): void;
};

const RemoveIcon = (props: RemoveIconProps): React.ReactElement => {

    const { className, iconClassName, onClick, popupText, dataTestId } = props;

    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            e.continuePropagation();
            props.onPress && props.onPress(e);
        }
    });

    const ret = <div
        {...pressProps}
        onClick={(e) => onClick ? onClick(e) : null}
        data-test-id={dataTestId}
        className={twMerge('items-center flex', className)}
    >
        <MicroInteractionClose className={twMerge('cursor-pointer', iconClassName)} {...pressProps} />

    </div>
    if (popupText) {
        return <Popup trigger={ret}>{props.popupText}</Popup>
    }
    return ret;
}
export default RemoveIcon;
