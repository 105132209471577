import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { User } from '../../../backend_api/models';
import { getLoadingByIdSelector, setLoadingById } from '../../../base/baseSlice';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import { AppState } from '../../../base/types';
import { useAppDispatch } from '../../../store';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getUsersById } from '../../users/selectors';
import { getUsersThatAreNotWatchers } from '../Handlers';
import Watcher from './Watcher';
import WatcherSelector from './WatcherSelector';

type Props = {
    className?: string;
    watchers: User[];
    noContentSection?: boolean;
    handleSave(watchers: string[]): void;
    handleDelete(watcherId: string): void;
    disabled?: boolean;
    readonly?: boolean;
};

const Watchers = (props: Props): React.ReactElement => {
    const { className, watchers, handleSave, handleDelete, noContentSection = false, disabled = false, readonly = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [currentWatchers, setCurrentWatchers] = useState<User[]>(watchers);
    const [selectedWatchers, setSelectedWatchers] = useState<string[]>([]);
    const usersById = useSelector(getUsersById);
    const [usersNoDupes, setUsersNoDupes] = useState(getUsersThatAreNotWatchers(watchers, usersById));
    const [addMode, setAddMode] = useState(false);
    const hasWatchers = watchers.length > 0;
    const addingWatcher = useSelector((state: AppState) => getLoadingByIdSelector(state, 'addingWatcher'));

    useEffect(() => {
        const _usersNoDupes = getUsersThatAreNotWatchers(watchers, usersById);
        setUsersNoDupes(_usersNoDupes);
        dispatch(setLoadingById({ id: 'addingWatcher', loading: false }));
    }, [watchers]);

    useEffect(() => {
        updateCW();
    }, [selectedWatchers]);
    const updateCW = () => {
        const cw = [...currentWatchers];
        selectedWatchers.forEach((id) => {
            const user = usersById[id];
            cw.push(user);
        });
        setCurrentWatchers(cw);
    }
    const content = <div className={twMerge('flex flex-col py-1 space-y-4', className)}>
        {!hasWatchers && <div className='w-full'><FormattedMessage id='watchers.no_watchers' /></div>}
        <div className='flex flex-wrap'>
            {watchers.map((watcher) => {
                return <Watcher
                    user={watcher}
                    disabled={disabled}
                    handleRemoveWatcher={(watcherId: string) => {
                        setAddMode(false);
                        handleDelete(watcherId);
                    }}
                    key={'watcher_' + watcher.id} />;
            })}
        </div>
        {
            !readonly && 
            <div>
                <Button disabled={disabled || addingWatcher} onClick={() => setAddMode(true)} className='text-sml px-3 py-2'>
                    <Icon name='add' className={twMerge('text-brand font-bold text-2xl', addingWatcher && 'animate-spin')} />{addingWatcher ? intl.formatMessage({ id: 'watchers.adding_watchers.please_wait' }) : <FormattedMessage id='watchers.add_watcher' />}
                </Button>
            </div>
        }
       
        {!readonly && addMode && <WatcherSelector
            usersById={usersNoDupes}
            showSelector
            showEmailInSelector
            hasSelection={selectedWatchers.length > 0}
            handleChange={(d) => {
                setSelectedWatchers(d.value as string[]);
            }}
            handleSave={() => {
                handleSave(selectedWatchers);
                setSelectedWatchers([]);
                setAddMode(false);
                dispatch(setLoadingById({ id: 'addingWatcher', loading: true }));
            }}
            handleCancel={() => {
                setSelectedWatchers([]);
                setAddMode(false);
            }}
        />}

    </div>
    return <div className={twMerge('', className)}>
        {noContentSection ? content : <ContentSection headerText={intl.formatMessage({ id: 'watchers.watchers' })} content={content} />}
    </div>
}
export default Watchers;
