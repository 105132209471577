/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    HistoryEntryAuditReference,
    HistoryEntryImportJobReference,
    HistoryEntryUserReference,
    HistoryEventCommentAdded,
    HistoryEventEntityAddedToField,
    HistoryEventEntityRemovedFromField,
    HistoryEventFieldChanged,
    HistoryEventProductionUnitCreated,
    HistoryEventStatusSet,
    HistoryEventSupplierCreated,
} from './';

/**
 *   A history entry for either a production unit or supplier
 * @export
 * @interface SupplierServiceHistoryEntry
 */
export interface SupplierServiceHistoryEntry {
    /**
     * 
     * @type {Date}
     * @memberof SupplierServiceHistoryEntry
     */
    created_at: Date;
    /**
     * 
     * @type {string}
     * @memberof SupplierServiceHistoryEntry
     */
    history_entry_id: string;
    /**
     * 
     * @type {Array<HistoryEventFieldChanged> | HistoryEventSupplierCreated | HistoryEventProductionUnitCreated | HistoryEventStatusSet | HistoryEventEntityAddedToField | HistoryEventEntityRemovedFromField | HistoryEventCommentAdded}
     * @memberof SupplierServiceHistoryEntry
     */
    history_event: Array<HistoryEventFieldChanged> | HistoryEventSupplierCreated | HistoryEventProductionUnitCreated | HistoryEventStatusSet | HistoryEventEntityAddedToField | HistoryEventEntityRemovedFromField | HistoryEventCommentAdded;
    /**
     * 
     * @type {string}
     * @memberof SupplierServiceHistoryEntry
     */
    history_event_type: SupplierServiceHistoryEntryHistoryEventTypeEnum;
    /**
     * 
     * @type {HistoryEntryUserReference | HistoryEntryAuditReference | HistoryEntryImportJobReference}
     * @memberof SupplierServiceHistoryEntry
     */
    source_reference: HistoryEntryUserReference | HistoryEntryAuditReference | HistoryEntryImportJobReference;
}

/**
* @export
* @enum {string}
*/
export enum SupplierServiceHistoryEntryHistoryEventTypeEnum {
    CommentAdded = 'comment_added',
    EntityAddedToField = 'entity_added_to_field',
    EntityRemovedFromField = 'entity_removed_from_field',
    FieldsChanged = 'fields_changed',
    ProductionUnitCreated = 'production_unit_created',
    StatusSet = 'status_set',
    SupplierCreated = 'supplier_created'
}

