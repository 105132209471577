import React from 'react';
import { twMerge } from '../../../utils';

type Props = {
    className?: string;
    items: { w: number, text: string, className?: string }[];
};

const ListSubHeading = (props: Props): React.ReactElement => {
    const { className, items } = props;

return <div className={twMerge('mr-[30px] flex w-full text-sm text-secondary', className)}>
        {items.map((item) => {
            const cl = 'w-' + item.w + '/12';
            return <div className={twMerge(cl, item.className)}>{item.text}</div>
        })}
    </div>
}
export default ListSubHeading;
