import { combineReducers } from 'redux';
import baseReducer from '../base/baseSlice';
import uiReducer from '../base/ui/uiSlice';
import approvalFlow from '../modules/approval';
import attachments from '../modules/attachments';
import auditReducer from '../modules/audit/slices/auditSlice';
import authenticationReducer from '../modules/authentication/authenticationSlice';
import checklists from '../modules/checklists';
import comments from '../modules/comments';
import complianceReducer from '../modules/compliance/complianceSlice';
import correctiveActionReducer from '../modules/correctiveActions/correctiveActionsSlice';
import customFields from '../modules/customFields';
import dashboard from '../modules/dashboard';
import dashboardReducer from '../modules/dashboard/slice/dashboardSlice';
import filters from '../modules/filters';
import globals from '../modules/globals';
import groups from '../modules/groups';
import inspections from '../modules/inspections';
import { inspectionReportApi } from '../modules/inspections/apis/inspectionReportApi';
import editInspectionsReducer from '../modules/inspections/slices/editInspectionsSlice';
import inspectionsReportSlice from '../modules/inspections/slices/inspectionReportSlice';
import inspectionsListReducer from '../modules/inspections/slices/inspectionsListSlice';
import splitShipmentsReducer from '../modules/inspections/slices/splitShipmentsSlice';
import weekViewReducer from '../modules/inspections/slices/weekViewSlice';
import messages from '../modules/messages';
import pdf from '../modules/pdf';
import productionUnitsSlice from '../modules/productionUnits/slice/productionUnitsSlice';
import profile from '../modules/profile';
import roles from '../modules/roles';
import samplingProtocolsReducer from '../modules/samplingProtocols/slices/samplingProtocolsSlice';
import search from '../modules/search';
import statistics from '../modules/statistics';
import supplierAndProductionUnitRelationsSlice from '../modules/supplierAndPuRelations/slice/relationSlice';
import suppliers from '../modules/suppliers';
import suppliers2Reducer from '../modules/suppliers/slice/suppliersSlice';
import template from '../modules/template';
import users from '../modules/users';
import video from '../modules/video';

const reducer = combineReducers({
    BASE: baseReducer,
    ui: uiReducer,
    correctiveActions: correctiveActionReducer,
    weekView: weekViewReducer,
    audits: auditReducer,
    inspectionsList: inspectionsListReducer,
    authentication: authenticationReducer,
    samplingProtocols: samplingProtocolsReducer,
    editInspections: editInspectionsReducer,
    suppliers2: suppliers2Reducer,
    productionUnits: productionUnitsSlice,
    compliance: complianceReducer,
    dashboard2: dashboardReducer,
    splitShipments: splitShipmentsReducer,
    supplierAndProductionUnitRelations: supplierAndProductionUnitRelationsSlice,
    [video.constants.NAME]: video.reducer,
    [search.constants.NAME]: search.reducer,
    [checklists.constants.NAME]: checklists.reducers,
    [globals.constants.NAME]: globals.reducer,
    [profile.constants.NAME]: profile.reducers,
    [inspections.constants.NAME]: inspections.reducers,
    [messages.constants.NAME]: messages.reducers,
    [users.constants.NAME]: users.reducers,
    [groups.constants.NAME]: groups.reducers,
    [comments.constants.NAME]: comments.reducers,
    [customFields.constants.NAME]: customFields.reducers,
    [approvalFlow.constants.NAME]: approvalFlow.reducers,
    [attachments.constants.NAME]: attachments.reducers,
    [pdf.constants.NAME]: pdf.reducers,
    [statistics.constants.NAME]: statistics.reducers,
    [suppliers.constants.NAME]: suppliers.reducers,
    [dashboard.constants.NAME]: dashboard.reducers,
    [filters.constants.NAME]: filters.reducers,
    [template.constants.NAME]: template.reducers,
    [roles.constants.NAME]: roles.reducers,
    [inspectionReportApi.reducerPath]: inspectionReportApi.reducer,
    'inspectionReport': inspectionsReportSlice
});

export default reducer;
