/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.6.3 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCorrectiveActionOnSupplierRequest
 */
export interface CreateCorrectiveActionOnSupplierRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    approver_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    due_date_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    finding: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    production_unit_links?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    requires_evidence_from_app?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    responsible_id?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCorrectiveActionOnSupplierRequest
     */
    supplier_links: Array<string>;
}

export function CreateCorrectiveActionOnSupplierRequestFromJSON(json: any): CreateCorrectiveActionOnSupplierRequest {
    return CreateCorrectiveActionOnSupplierRequestFromJSONTyped(json, false);
}

export function CreateCorrectiveActionOnSupplierRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCorrectiveActionOnSupplierRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver_id': !exists(json, 'approver_id') ? undefined : json['approver_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'due_date_at': !exists(json, 'due_date_at') ? undefined : json['due_date_at'],
        'finding': json['finding'],
        'production_unit_links': !exists(json, 'production_unit_links') ? undefined : json['production_unit_links'],
        'requires_evidence_from_app': !exists(json, 'requires_evidence_from_app') ? undefined : json['requires_evidence_from_app'],
        'responsible_id': !exists(json, 'responsible_id') ? undefined : json['responsible_id'],
        'supplier_links': json['supplier_links'],
    };
}

export function CreateCorrectiveActionOnSupplierRequestToJSON(value?: CreateCorrectiveActionOnSupplierRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver_id': value.approver_id,
        'description': value.description,
        'due_date_at': value.due_date_at,
        'finding': value.finding,
        'production_unit_links': value.production_unit_links,
        'requires_evidence_from_app': value.requires_evidence_from_app,
        'responsible_id': value.responsible_id,
        'supplier_links': value.supplier_links,
    };
}


