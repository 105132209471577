import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../../globals/components/PageContainer';
import CorrectiveActionsList from './CorrectiveActionsList';

const CorrectiveActionOverview = () => {
    return <PageContainer header={<FormattedMessage id='corrective_actions.list.audits.headline' />}>
        <CorrectiveActionsList />
    </PageContainer>
}

export default CorrectiveActionOverview;