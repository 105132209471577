import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Icon from '../../../base/ui/components/icons/Icon';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { useAppDispatch } from '../../../store';
import { complianceSetProductState } from '../complianceSlice';

type Props = {
    className?: string;
    productId: string;
    handleProductUpdate(string, any): void;
    cancelCreate(): void;
};

export type ActivateProductViewType = 'noRequirementsYet';
export enum ActivateProductViewTypeEnum {
    NoRequirementsYet = 'noRequirementsYet',
}
const ComplianceActivateProductWizard = (props: Props): React.ReactElement => {
    const { className, productId } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const modalData = useSelector(getShowModalSelector)['dueDateWizard'];
    const [view, setView] = useState<ActivateProductViewType>(modalData?.data?.view as ActivateProductViewTypeEnum);

    const save = () => {
        if (view === ActivateProductViewTypeEnum.NoRequirementsYet) {
            dispatch(complianceSetProductState({ product_id: productId, state: 'active' }));
            hide();
        }
    }
    const hide = () => {
        dispatch(showModal({ id: 'dueDateWizard', show: false }));
    }
    const dontSet = () => {
        hide();

    }
    const headerMap = {
        noRequirementsYet: intl.formatMessage({ id: 'compliance.product.activate.header.no_requirements_yet' }),
    }
    const actionsMap = {
        noRequirementsYet: {
            ok: intl.formatMessage({ id: 'compliance.product.activate.activate_now' }),
            dont: intl.formatMessage({ id: 'globals.cancel' }),
        },
    }
    const contentMap = {
        noRequirementsYet: <div className='gap-y-6 flex flex-col'>
            <div>
                <FormattedMessage id='compliance.product.activate.no_requirements_yet.text' />
                <div className='font-bold'><FormattedMessage id='compliance.product.activate.no_requirements_yet.text_2' /></div>
            </div>
            <div className='border rounded-md p-3 text-sm'>
                <div className='flex items-center text-secondary font-bold uppercase gap-x-1'><Icon name='callout_info' className='text-secondary'/><FormattedMessage id='compliance.product.activate.no_requirements_yet.text_note.note' /></div>
                <FormattedMessage id='compliance.product.activate.no_requirements_yet.text_note' /></div>
        </div>,
    }

    useEffect(() => {
        if (modalData) {
            setView(modalData?.data?.view);
        }
    }, [modalData]);

    return <Modal
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: actionsMap[view]?.ok, event: save, stayOpen: true },
            { type: ActionTypes.Action, buttonType: ButtonTypes.Plain, text: actionsMap[view]?.dont, event: dontSet },
        ]}
        isOpen={modalData?.show}
        width={500}
        header={headerMap[view]}
        content={contentMap[view]}
        onClose={hide}

    />;
}
export default ComplianceActivateProductWizard;
