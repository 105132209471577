import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from "semantic-ui-react"
import { twMerge } from 'tailwind-merge';
import { AuditCheckpointMultipleChoiceOption } from '../../../../backend_api_2';
import { AuditChecklistHeader, AuditCheckpoint } from '../../../../backend_api_2';
import { getLocaleLanguageString, isHexadecimalColorCode } from '../../../../base/utils';
import { doesCheckpointRequireMandatoryInput } from './mandatory';

export function CheckpointHeaderProgressPopup(props: {
    header: AuditChecklistHeader
}) {
    const {header} = props;
    const total = header.checkpoints.length;
    const progresses = calculateCheckpointProgresses(header);
    const multipleChoiceCategories = Object.keys(progresses.multipleChoice);
    const checkedOkCheckpoints = progresses.status[AuditCheckpoint.checkpoint_status.CHECKED_OK].length;
    const checkedNotOkCheckpoints = progresses.status[AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK].length;
    const pendingCheckpoints = progresses.status[AuditCheckpoint.checkpoint_status.PENDING].length;
    const notApplicableCheckpoints = progresses.status[AuditCheckpoint.checkpoint_status.NOT_APPLICABLE].length;
    const noStatusCheckpoints = progresses.status[AuditCheckpoint.checkpoint_status.EMPTY].length;

    return (
        <div>
            <Popup
                hoverable
                trigger={
                    <div className={'bg-checkpoint-not-checked sm:w-1/4 w-1/2 flex flex-row justify-start items-center rounded-md overflow-hidden'} style={{width: '100px', height: '10px'}} >
                        {
                            checkedOkCheckpoints > 0 &&
                            <ColorProgressElement percentage={checkedOkCheckpoints * 100 / total} color="bg-checkpoint-checked-ok"/>
                        }
                        {
                            checkedNotOkCheckpoints > 0 &&
                            <ColorProgressElement percentage={checkedNotOkCheckpoints * 100/ total} color="bg-checkpoint-checked-not-ok"/>
                        }
                        {
                            pendingCheckpoints > 0 &&
                            <ColorProgressElement percentage={pendingCheckpoints * 100 / total} color="bg-checkpoint-checked-pending"/>
                        }
                        {
                            notApplicableCheckpoints > 0 &&
                            <ColorProgressElement percentage={notApplicableCheckpoints * 100 / total} color="bg-checkpoint-checked-not-applicable"/>
                        }

                        {
                            Object.keys(progresses.multipleChoice)?.map(key => {
                                const checkpoints: AuditCheckpoint[] = progresses.multipleChoice[key];
                                return checkpoints?.map(checkpoint => <ColorProgressElement percentage={checkpoints?.length * 100 / total} color={getMultipleChoiceColor(checkpoint)}/>)
                            })
                        }
                    </div>
                }>
                <h4><FormattedMessage id='web_based_audit.checkpoint_header_progress.popup.header' values={{ numberOfCheckpoints: header.checkpoints.length }}/></h4>
                <Popup.Content>
                    <CategoryItem 
                        className='_1'
                        title="checkpoint.status.1"
                        progress={checkedOkCheckpoints} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                    <CategoryItem 
                        className='_2'
                        title="checkpoint.status.2"
                        progress={checkedNotOkCheckpoints} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                    <CategoryItem 
                        className='_4'
                        title="checkpoint.status.4"
                        progress={pendingCheckpoints} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                    <CategoryItem 
                        className='_3'
                        title="checkpoint.status.3"
                        progress={notApplicableCheckpoints} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                    <CategoryItem 
                        className='_-1'
                        title="web_based_audit.checkpoint_header_progress.missing_status"
                        progress={noStatusCheckpoints} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                    {
                        multipleChoiceCategories?.filter(c => progresses.multipleChoice[c]?.length > 0).map(category => 
                            <CategoryItem 
                                title={category}
                                progress={progresses.multipleChoice[category]?.length}
                                total={header.checkpoints.length}
                                backgroundColor={getColorByCategoryName(category, progresses.multipleChoice[category][0]?.multiple_choice?.options)}
                                shouldTranslate={false}
                            />
                        )
                    }
                    <CategoryItem 
                        className='_-1'
                        title='web_based_audit.checkpoint_header_progress.missing_inputs'
                        progress={progresses.other.missingData?.length} 
                        total={header.checkpoints.length}
                        shouldTranslate
                    />
                </Popup.Content>
            </Popup>
        </div>
        
    )
}

function CategoryItem(props: {
    className?: string
    title: string,
    progress: number,
    total: number
    backgroundColor?: string,
    shouldTranslate: boolean
}) {
    
    const {title, className, progress, total, backgroundColor, shouldTranslate} = props;
    if(progress == 0) {
        return null;
    }
    return (
        <div className='flex flex-row items-center'>
            <span className={className} style={{height: '10px', width: '10px', backgroundColor: backgroundColor}}></span>
            <span className='mx-1'>{shouldTranslate ? <FormattedMessage id={title}/> : title}</span>
            <span>: {progress} / {total}</span>
        </div>
    )
}

function getColorByCategoryName(name: string, options: AuditCheckpointMultipleChoiceOption[]): string {
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const chosenOption = options?.filter(option => {
        const optionName = option.name[lang] || option.name.C;
        return name == optionName;
    })[0];
    return chosenOption?.color;
}

function calculateCheckpointProgresses(header: AuditChecklistHeader) {
    const map = {
        status: {
            [AuditCheckpoint.checkpoint_status.CHECKED_OK]: header.checkpoints.filter(c => c.checkpoint_status == AuditCheckpoint.checkpoint_status.CHECKED_OK),
            [AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK]: header.checkpoints.filter(c => c.checkpoint_status == AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK),
            [AuditCheckpoint.checkpoint_status.PENDING]: header.checkpoints.filter(c => c.checkpoint_status == AuditCheckpoint.checkpoint_status.PENDING),
            [AuditCheckpoint.checkpoint_status.NOT_APPLICABLE]: header.checkpoints.filter(c => c.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE),
            [AuditCheckpoint.checkpoint_status.EMPTY]: header.checkpoints.filter(c => c.status_enabled && c.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY && c.checkpoint_type != AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE),
        },
        multipleChoice: {},
        other: {
            missingData: header.checkpoints.filter(c => doesCheckpointRequireMandatoryInput(c)),
            hasInput: header.checkpoints.filter(c => !c.status_enabled && !doesCheckpointRequireMandatoryInput(c))
        }
    }
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    header.checkpoints
    .filter(c => c.checkpoint_type == AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE && !c.status_enabled && c.multiple_choice.options.some(o => o.chosen))
    .forEach(c => {
        c.multiple_choice?.options.filter(o => o.chosen).forEach(option => {
            const optionName = option.name[lang] || option.name.C;
            if(map.multipleChoice[optionName]) {
                map.multipleChoice[optionName]?.push(c)
            } else {
                map.multipleChoice[optionName] = [c];
            }
        })
    })
    return map;
}

function ColorProgressElement(props: {className?: string, percentage: number; color: string}) {
    const {className, color, percentage} = props;
    const isHexaColorCode = isHexadecimalColorCode(color);
    return (
        <div
            className={twMerge(className, !isHexaColorCode && color)}
            style={{
                backgroundColor: isHexaColorCode && color,
                width: `${percentage}%`,
                height: '10px'
            }}
        />
    );
}

function getMultipleChoiceColor(checkpoint: AuditCheckpoint): string {
    if (checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE
        && checkpoint.multiple_choice
        && checkpoint.multiple_choice.options) {

        const mc = checkpoint.multiple_choice;
        const chosenOptions = mc.options.filter(o => o.chosen)

        if (chosenOptions.length > 1) {
            const isFailed = chosenOptions.some(o => o.chosen && o.failed);
            if(isFailed) {
                return "bg-checkpoint-checked-not-ok";
            } else {
                return "bg-checkpoint-checked-ok";
            }
        } else if (chosenOptions.length == 1) {
            return chosenOptions[0].color;
        } else {
            return "bg-checkpoint-not-checked";
        }
    }
}