import { format } from 'date-fns';
import qs from 'query-string';
import React, { ChangeEvent, Component, FormEvent } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Button, Dimmer, Dropdown, DropdownItemProps, Form, Header, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import DatePicker from '../../../base/components/basic/DatePicker3';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { AppState } from '../../../base/types';
import { getPrettyDate } from '../../../base/utils';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { DatePickerProps } from '../../globals/types';
import { hasInspectionTypes } from '../../inspections/selectors/selectors';
import { Inspection } from '../../inspections/types';
import { getSupplierBooking, updateSupplierBooking } from '../actions';
import BookingInfo from '../components/booking/BookingInfo';
import BookingInspectionLine from '../components/booking/BookingInspectionLine';
import { BookingInspectionLineHeader } from '../components/booking/BookingInspectionLineHeader';
import { getBookingDataFetchingSelector, getBookingDataSelector } from '../selectors';
import { ProductionUnit, SupplierBookingInspection, SupplierBooking as SupplierBookingType } from '../types';

type OwnProps = {
    orderId: string;
    token?: string;
};

type StateProps = {
    bookingData: SupplierBookingType;
    hasInspectionTypes: boolean;
    isFetching: boolean;
};

type DispatchProps = {
    actions: {
        getSupplierBooking: typeof getSupplierBooking;
        updateSupplierBooking: typeof updateSupplierBooking;
    };
};

const initialState = { unConfirmedCnt: 0, dataUpdated: false, openModal: false, bookingDate: null, inspectionType: 'all' };

type State = {
    unConfirmedCnt: number;
    dataUpdated?: boolean;
    booking_info?: {
        production_unit?: ProductionUnit;
        note?: string;
    };
    openModal: boolean;
    bookingDate: Date;
    inspectionType: string;
}

export type InspectionsItemType = DatePickerProps | { name: string; value: string };

type SupplierBookingProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class SupplierBooking extends Component<SupplierBookingProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleInspectionChange = this.handleInspectionChange.bind(this);
        this.updateChanges = this.updateChanges.bind(this);
    }

    render(): React.ReactElement {
        const bookingData: SupplierBookingType = this.props.bookingData;
        const type = (qs.parse(location.search).type as 'view' | 'edit');

        if (this.props.bookingData) {
            const cnt: number = bookingData.inspections.length;
            return <div className='pageContainer bookingInfo'>
                <DocumentTitle title={this.props.intl.formatMessage({ id: 'page_title.booking' }, { orderNo: bookingData.order.order_number })} />
                <Header as='h1'><FormattedMessage id='supplier_booking.header_booking_prefix' />: {bookingData.order.order_number}</Header>
                <Segment.Group raised>
                    <Dimmer active={this.props.isFetching} inverted>
                        <Loader className='dimLoader'><FormattedMessage id='globals.loading_data' /></Loader>
                    </Dimmer>
                    <Segment secondary>
                        <div className='flex justify-between'>
                            <div>
                                <span className='etd'>ETD</span> {bookingData.order.etd && getPrettyDate(bookingData.order.etd) || '-'}
                            </div>
                            <HasFeatureAccess feature='change_booking_data' type='inspection' inspection={bookingData.inspectionsMap[0]} showDisabled={true}>
                                <Button onClick={(): void => this.openModal()}><FormattedMessage id='supplier_booking.actions.set_booking_date' /></Button>
                            </HasFeatureAccess>
                        </div>
                    </Segment>
                    <Segment>
                        <BookingInspectionLineHeader isCombinedInspection={false} hasInspectionTypes={this.props.hasInspectionTypes} />
                        {bookingData.inspectionsMap && bookingData.inspectionsMap.map((inspection, i: number) => {
                            return <BookingInspectionLine
                                key={i}
                                inspection={inspection}
                                bookingId={bookingData.order.order_id}
                                cnt={i}
                                hasInspectionTypes={this.props.hasInspectionTypes}
                                type={type}
                                handleInspectionChange={this.handleInspectionChange}
                                isFetching={this.props.isFetching} />;
                        })}
                    </Segment>
                    <Segment className='actions'>
                        <HasFeatureAccess feature='change_booking_data' type='inspection' inspection={bookingData.inspectionsMap[0]} showDisabled={true}>
                            <Button disabled={this.props.isFetching} onClick={(): void => {
                                this.confirmAll();
                            }} positive>{cnt === 0 ? <FormattedMessage id='supplier_booking.actions.confirm_booking' />
                                :
                                <FormattedMessage id='supplier_booking.actions.confirm_bookings' />}</Button>
                        </HasFeatureAccess>
                    </Segment>
                </Segment.Group>
                <Segment.Group raised>
                    <Dimmer active={this.props.isFetching} inverted />
                    <Segment secondary>
                        <Header as='h3'>
                            <FormattedMessage id='supplier_booking.production_unit' />
                        </Header>
                    </Segment>
                    <Segment>
                        <BookingInfo
                            bookingData={bookingData}
                            updateChanges={this.updateChanges}
                            handleChange={this.handleChange}
                            isFetching={this.props.isFetching}
                            canChangeProductionUnitData={bookingData.inspectionsMap[0].features.includes('change_production_unit_data')}
                            canChangeBookingData={bookingData.inspectionsMap[0].features.includes('change_booking_data')}
                        />
                    </Segment>
                </Segment.Group>
                <Modal
                    open={this.state.openModal}
                    closeOnEscape={true}
                    onClose={(): void => this.closeModal()}
                >
                    <Modal.Header><FormattedMessage id='supplier_booking.actions.modal.header' /></Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <FormattedMessage id='inspections.filters.select_inspection_type' />
                                <Dropdown selection
                                    options={this.getInspectionTypeOptions()}
                                    value={this.state.inspectionType}
                                    onChange={(evt, item) => { this.setState({ inspectionType: item.value as string }) }}
                                >
                                </Dropdown>
                            </Form.Field>
                            {this.numberOfInspectionsToSetBookingDate(bookingData.inspections) == cnt && <Form.Field>
                                <Icon name='info circle' color='blue' /><FormattedMessage id='supplier_booking.actions.modal.content_all' />
                            </Form.Field>}

                            {this.numberOfInspectionsToSetBookingDate(bookingData.inspections) != cnt && <Form.Field>
                                <Icon name='info circle' color='blue' /><FormattedMessage id='supplier_booking.actions.modal.content_subset' values={{ count: this.numberOfInspectionsToSetBookingDate(bookingData.inspections), total: cnt }} />
                            </Form.Field>}
                            <Form.Field>
                                <DatePicker
                                    name='scheduled_inspection_date'
                                    date={this.state.bookingDate && this.state.bookingDate.toString()}
                                    handleChange={(dp): void => {
                                        this.setState({ bookingDate: dp })
                                    }}
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={(): void => this.updateBookingDates()} disabled={!this.state.bookingDate || this.numberOfInspectionsToSetBookingDate(bookingData.inspections) == 0}><FormattedMessage id='globals.confirmation.ok' /></Button>
                        <Button onClick={(): void => this.closeModal()}><FormattedMessage id='globals.confirmation.cancel' /></Button>
                    </Modal.Actions>
                </Modal>
            </div>;
        }
        return !this.props.isFetching && <div className='pageContainer bookingInfo'><Header as='h1'><FormattedMessage id='supplier_booking.error.could_not_load_form' /></Header>
            <Segment.Group raised>
                <Segment secondary>
                    <Icon name='exclamation circle' color='red' /><FormattedMessage id='supplier_booking.error.could_not_load_data_for_form' />
                </Segment>
            </Segment.Group></div>;
    }

    noPlanndedDateSet(inspection: Inspection): boolean {
        return !(inspection.scheduled_inspection_date !== null && inspection.scheduled_inspection_date !== undefined);
    }

    isInspectionTypeIncluded(inspection: Inspection): boolean {
        return this.state.inspectionType == 'all' || (this.state.inspectionType == 'none' && inspection.inspection_type == null) || (inspection.inspection_type && inspection.inspection_type.id == this.state.inspectionType)
    }

    numberOfInspectionsToSetBookingDate(inspections: Inspection[]) {
        let count = 0;
        inspections.forEach(ins => {
            if (this.noPlanndedDateSet(ins) && this.isInspectionTypeIncluded(ins)) {
                count = count + 1;
            }
        })
        return count
    }

    getInspectionTypeOptions(): DropdownItemProps[] {
        const typeOptionMap = {
            'All': {
                key: 'all',
                text: this.props.intl.formatMessage({ id: 'inspections.type.all' }),
                value: 'all'
            }
        }
        this.props.bookingData.inspections.forEach(ins => {
            if (ins.inspection_type == null && typeOptionMap['none'] == null) {
                typeOptionMap['none'] = {
                    key: 'none',
                    text: this.props.intl.formatMessage({ id: 'inspections.type.none' }),
                    value: 'none'
                }
            }
            else if (ins.inspection_type && typeOptionMap[ins.inspection_type.id] == null) {
                typeOptionMap[ins.inspection_type.id] = {
                    key: ins.inspection_type,
                    text: ins.inspection_type.name,
                    value: ins.inspection_type.id
                }
            }
        });
        return Object.keys(typeOptionMap).map((key) => typeOptionMap[key]);
    }

    updateBookingDates(): void {
        const bookingData: SupplierBookingType = Object.assign({}, this.props.bookingData);
        bookingData.inspections.forEach((ins) => {
            if (this.isInspectionTypeIncluded(ins)) {
                if (this.noPlanndedDateSet(ins)) {
                    ins.booking_date = format(this.state.bookingDate, DEFAULT_DATE_FORMAT2)
                }
            }
        });
        this.props.actions.updateSupplierBooking(this.props.orderId, bookingData);
        this.closeModal();
    }

    closeModal(): void {
        this.setState({ openModal: false, bookingDate: null })
    }

    componentDidMount(): void {
        this.props.actions.getSupplierBooking(this.props.orderId, this.props.token);
    }

    componentDidUpdate(): void {
        this.hasUnconfirmedBookings();
    }

    updateChanges(): void {
        const bookingData: SupplierBookingType = Object.assign({}, this.props.bookingData);
        bookingData.booking_info = this.state.booking_info;
        this.props.actions.updateSupplierBooking(this.props.orderId, bookingData);
    }

    openModal(): void {
        this.setState({ openModal: true });
    }

    handleChange(evt: ChangeEvent | FormEvent, item: { name: string; value: any }, save?: boolean): void {
        const bookingInfo = this.props.bookingData.booking_info;
        if (item.name === 'productionUnit') {
            bookingInfo.production_unit = { id: item.value.value, name: item.value.name, address: item.value.address };
        } else {
            if (item.name === 'note') {
                bookingInfo[item.name] = item.value;
            } else {
                bookingInfo.production_unit[item.name] = item.value;
            }
        }
        this.setState({ booking_info: bookingInfo }, () => {
            if (save) {
                this.updateChanges();
            }
        });
    }

    handleInspectionChange(d: Date, item: InspectionsItemType, id?: string): void {
        const bookingData = Object.assign({}, this.props.bookingData);
        let value;
        if (item.name === 'booking_date' || item.name === 'scheduled_inspection_date') {
            value = d && format(d, DEFAULT_DATE_FORMAT2);
        } else {
            value = item && item['value'];
        }
        bookingData.inspections.forEach((inspection: SupplierBookingInspection) => {
            if (inspection.id === id) {
                inspection[item.name] = value;
            }
        });
        this.setState({ dataUpdated: true });
        this.props.actions.updateSupplierBooking(this.props.orderId, bookingData);
    }

    confirmAll(): void {
        const bookingData = this.props.bookingData;
        bookingData.inspections.forEach((inspection: SupplierBookingInspection) => {
            inspection.do_confirm = true;
        });
        this.props.actions.updateSupplierBooking(this.props.orderId, bookingData);
    }

    hasUnconfirmedBookings(): void {
        if (this.props.bookingData) {
            const inspections = this.props.bookingData.inspections;
            const unConfirmed = [];
            inspections.forEach((inspection: SupplierBookingInspection) => {
                if (!inspection.confirmed && inspection.must_confirm) {
                    unConfirmed.push(inspection);
                }
            });
            if (unConfirmed.length !== this.state.unConfirmedCnt) {
                this.setState({ unConfirmedCnt: unConfirmed.length });
            }
        }
    }
}

export const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
    return {
        isFetching: getBookingDataFetchingSelector(state),
        bookingData: getBookingDataSelector(state, ownProps.orderId),
        hasInspectionTypes: hasInspectionTypes(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        getSupplierBooking,
        updateSupplierBooking,
    }, dispatch);
    return { actions };
};


export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(SupplierBooking))