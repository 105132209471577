import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Grid } from 'semantic-ui-react';
import { AppThunk } from '../../../../base/types';
import checklists from '../../../checklists';
import { getInspectionChecklistsSelector } from '../../../checklists/selectors';
import { Checklist } from '../../../checklists/types';
import { DatePicker } from '../../../globals/components';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import profile from '../../../profile';
import { datePickerChange, handleAction, handleBlurredInspection, handleFocussedInspection } from '../../actions/editInspectionActions';
import { Group, Inspection, Order } from '../../types';
import { getNotLockedAndDoneInspections } from '../../Utils';
import EditChecklistAction from './EditChecklistAction';
import QInput from './formelements/QInput';
import Quantity from './Quantity';
import SamplingProtocolSelector from './SamplingProtocolSelector';

const ChecklistsSelector = checklists.components.ChecklistsSelector;

type OwnProps = {
    defaultDate: Date;
    disabled: boolean;
    isCombinedInspection: boolean;
    order: Order;
    isNew?: boolean;
    lineToggler: React.ReactElement;
    handleBlur: typeof handleBlurredInspection;
    handleFocus: typeof handleFocussedInspection;
    handleAction: typeof handleAction;
    handleDatePickerChange: typeof datePickerChange;
}

type StateProps = {
    ownGroups?: Group[];
    checklists: Checklist[];
};

type DispatchProps = {
    actions: any;
};

type OrderInfoProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

const initialState = {
    order: {
        inspections: [],
    },
};
type State = Readonly<typeof initialState>;

class OrderInfoInner extends Component<OrderInfoProps, State> {
    readonly state: State = initialState;
    public constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    public render(): React.ReactElement {
        if (this.props.isCombinedInspection) {
            if (this.props.order.inspections.length > 0) {        
                const inspection: Inspection = this.props.order.inspections[0];
                const inspectionId = inspection.inspection_id;
                const disabled = this.props.disabled;
                const checklistId = inspection.checklist_id;
                const checklistsData = this.props.checklists;
                const checklistValue = inspection.checklist_id !== null ? checklistId : '-1';
                const checklistText = inspection.checklist_name !== null ? inspection.checklist_name : '';
                const label = 'w-full text-secondary font-bold text-sm mb-4';
                const combinedQty = (inspection.sub_inspections.reduce((a, b) => a + b['total_quantity'], 0));
                return (<Grid>
                    <Grid.Column width={15}>
                        <div className='pl-1'>
                            <div className='flex space-x-2'>
                                <div className='flex flex-col'>
                                    <div className={label}>
                                        <FormattedMessage id='inspections.total_and_sample_quantity' />
                                    </div>
                                    <div className='flex space-x-1'>
                                        <Quantity
                                            sampleQtyClassName='flex w-32'
                                            sampleQtyDisabled={false}
                                            totalQtyClassName='flex w-32'
                                            inspection={inspection}
                                            disabled={true}
                                            handleBlur={this.props.handleBlur}
                                            handleFocus={(): void => null}
                                            handleAction={this.props.handleAction}
                                            showSampleQty={true}
                                            hasInspectionTypes={false}
                                            isCombined={this.props.isCombinedInspection}
                                            value={combinedQty}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className={label}><FormattedMessage id='inspections.checklist' /></div>
                                    <div className='flex w-96'>
                                        <HasReadonlyAccess type='inspection' actionId='checklist_id' inspection={inspection}>
                                            <ChecklistsSelector
                                                checklists={checklistsData}
                                                disabled={this.props.disabled}
                                                isFluid={true}
                                                didFocus={(event): AppThunk => this.props.handleFocus(event, inspectionId)}
                                                handleChange={(evt, item): void => {
                                                    this.props.handleAction({ name: 'checklist_id', value: item.value }, inspectionId);
                                                }}
                                                checklistName='checklist_id'
                                                value={checklistValue}
                                                text={checklistText}
                                                handleBlur={(): void => null}
                                                className='orderInfoChecklist'
                                            />
                                        </HasReadonlyAccess>
                                        <HasReadonlyAccess type='inspection' actionId='checklist_id' inspection={inspection}>
                                            <EditChecklistAction
                                                inspection={inspection}
                                                disabled={disabled}
                                                isSaving={false}
                                            />
                                        </HasReadonlyAccess>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>);
            }
            return (null);
        } else {
            return (
                <Grid>
                    <Grid.Column width={16}>
                        <Form>
                            <Form.Group className='orderLine'>
                                <Form.Field width={4} >
                                    <label><FormattedMessage id='inspections.order_number' /></label>
                                    <HasReadonlyAccess type='order' actionId='order_number' order={this.props.order}>
                                        <QInput
                                            type='text'
                                            placeholder='Order number'
                                            name='order_number'
                                            value={this.props.order.order_number}
                                            handleBlur={(item): AppThunk<void> => this.props.disabled ? null : this.props.handleAction(item.currentTarget as unknown as { name: string, value: string }, '')}
                                            handleFocus={(evt, item): void => { this.props.disabled ? null : this.props.handleFocus(evt, item as unknown as string) }}
                                            readOnly={this.props.disabled}
                                        />
                                    </HasReadonlyAccess>
                                </Form.Field>
                                <Form.Field inline width={4}>
                                    <label><FormattedMessage id='inspections.etd' /></label>
                                    <HasReadonlyAccess type='order' actionId='etd' order={this.props.order}>
                                        <DatePicker
                                            handleChange={(dp): AppThunk<void> => this.props.handleDatePickerChange(dp, 'etd')}
                                            name='etd'
                                            date={this.props.defaultDate}                                            
                                            disabled={getNotLockedAndDoneInspections(this.props.order.inspections).length === 0}
                                        />
                                    </HasReadonlyAccess>
                                </Form.Field>
                                <Form.Field inline width={3}>
                                    <div>
                                        <label><FormattedMessage id='edit.sampling_method' /></label>
                                    </div>
                                    <HasFeatureAccess type='order' feature='order_change_sampling_protocol' order={this.props.order} showDisabled={true} showDialog={true}>
                                        <SamplingProtocolSelector
                                            disabled={this.props.disabled}
                                        />
                                    </HasFeatureAccess>
                                </Form.Field>
                                <Form.Field inline className='edit-inspection-last-col'>
                                    <div>
                                        <label>&nbsp;</label>
                                    </div>
                                    {!this.props.isNew && <span>{this.props.lineToggler}</span>}
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column></Grid>
            );
        }
    }
    public handleChange(item): void {
        const order = Object.assign({}, this.state.order);
        order.inspections[0][item.name] = item.value;
        this.setState({ order });

    }
}

const mapDispatchToProps = (dispatch): DispatchProps => {
    const actions = bindActionCreators({}, dispatch);
    return { actions };
};

const mapStateToProps = (state): StateProps => {
    return {
        ownGroups: profile.selectors.getOwnGroups(state, false),
        checklists: getInspectionChecklistsSelector(state),
    };
};

const OrderInfo = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(OrderInfoInner))

export default OrderInfo;
