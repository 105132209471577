import React from 'react';
import { createIntl, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from '../../../../base/utils';
import { Inspection } from '../../types';
import { InspectionsWeekviewInspectionExtended } from '../dashboard/views/weekView/WeekView2';

type SplitShipmentsMarkerInfo = {
    sequence_identifier: string[];
}

type SplitShipmentsMarkerProps = {
    className?: string;
    inspection: Inspection | InspectionsWeekviewInspectionExtended | SplitShipmentsMarkerInfo;
    extraInfo?: React.ReactElement;
    inline?: boolean;
    onlyLabel?: boolean;
    returnText?: boolean;
};

const SplitShipmentsMarker = (props: SplitShipmentsMarkerProps): React.ReactElement => {
    const { className, inspection, inline = true, extraInfo, returnText = false, onlyLabel = false } = props;
    const intlLocal = createIntl({
        locale: 'en-US',
        messages: {},
    });
    const ordinalsLabels = {
        'en-US': { one: 'st', two: 'nd', few: 'rd', other: 'th' },
    };

    const intl = useIntl();
    const locale = intlLocal.locale;
    const getSequenceLabelType = (sequence: string[]): { type: 'S' | 'R' | 'SR' | 'RS', cnt?: number, splitCnt?: number } => {
        const reg = /^[a-z]+$/i;
        const reg_reinsp = /\d/;
        const splits = sequence.filter((a) => reg.test(a));
        const reinsps = sequence.filter((n) => reg_reinsp.test(n));
        const splitPos = sequence.indexOf(splits[0]);
        const respPos = sequence.indexOf(reinsps[0]);
        const splitChar = sequence[splitPos];
        const splitCnt = splitChar && splitChar.toLowerCase().charCodeAt(0) - 97 + 1 || 0;
        if (splitPos === -1) {
            return { type: 'R', cnt: parseInt(sequence[0], 10) };
        }
        if (splitPos > 0) {
            return { type: 'RS', cnt: parseInt(sequence[respPos], 10), splitCnt };
        }
        if (splitPos === 0) {
            if (respPos > 0) {
                return { type: 'SR', cnt: parseInt(sequence[respPos], 10), splitCnt };
            }
            return { type: 'S', splitCnt };
        }
    };
    const getSeqContent = (): { ret: React.ReactElement, label: string }[] => {
        const m = [];
        inspection.sequence_identifier.map((si) => {
            const sqType = getSequenceLabelType(inspection.sequence_identifier);
            let l = si;
            if (!isNaN(parseInt(si))) {
                l = parseInt(si) + ordinalsLabels[locale][intlLocal.formatPlural(parseInt(si), { type: 'ordinal' })];
            }
            const ret = <span>{l}</span>;
            const nOrdinal = sqType.cnt;
            const label = intl.formatMessage({ id: 'split_shipments.marker.' + sqType.type }, { n: nOrdinal, splitCnt: sqType.splitCnt });
            m.push({ ret, label });
        })
        return m;
    }
    const getRet = (ret: { ret: React.ReactElement }[]) => {
        return ret.map((r, i) => {
            return <span key={i}>{r.ret}</span>
        });
    }
    if (inspection.sequence_identifier && inspection.sequence_identifier.length > 0) {
        const c = getSeqContent();
        if (returnText) {
            return <div>{c[0].label}</div>;
        }
        const trigger = <div className={twMerge('flex-row space-x-1 bg-default-widget-color px-1 items-center justify-center text-xss rounded text-white leading-5 self-center', inline ? 'inline' : 'flex', className)}>
            {getRet(c)}</div>;
        return onlyLabel ? trigger : <Popup
            hoverable
            trigger={trigger}
            position='top center'
        >{c[0].label}
            {extraInfo && <div className='pt-4'>{extraInfo}</div>}
        </Popup>;
    }
    return null;
}
export default SplitShipmentsMarker;
