import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit } from '../../../backend_api/models';
import { AuditTag } from '../../../backend_api/models/AuditTag';
import { getArrayInChunks, getPrettyDate } from '../../../base/utils';
import { getColor } from '../../audit/components/AuditDisplayState';
import AuditStatus from '../../audit/components/AuditStatus';
import AuditTypeTag from '../../audit/components/AuditTypeTag';
import { getAuditDate } from '../../audit/util';
import { UserInitialsText } from '../../users/components/Widgets';

type Props = {
    className?: string;
    items: { latestAudits: AuditTag[], audits: Audit[] };
    supplierId: string;
};

const SupplierAuditsStatusBar = (props: Props): React.ReactElement => {
    const { className, items } = props;
    const audits = items.audits;
    const cnt = items.latestAudits.length;
    const barWidth = 250;
    const w = 1 / cnt * barWidth;
    const chunks = getArrayInChunks(audits, 25) as Array<Audit[]>;
    const latestAuditsIds = items.latestAudits.map((la) => la.id);
    const summaryAudits = items.audits.filter((audit) => latestAuditsIds.includes(audit.id));
    const trigger = <div className={twMerge('flex mr-4 w-64', className)}>{items.latestAudits.map((item, i) => {
        return <div style={{ width: w, backgroundColor: item.conclusion && item.conclusion.background_color && item.conclusion.background_color }} className={'flex h-3 bg-' + getColor(item.status)} key={i}></div>
    })}</div>
    return <Popup
        hoverable
        basic
        className=''
        trigger={trigger}>
        <div style={{ width: '600px' }}>
            <h3 className='pt-2'><FormattedMessage id='suppliers.audit_bar.audits_on_supplier' values={{ supplierName: (items.audits[0] && items.audits[0].target_supplier && items.audits[0].target_supplier.name) || '-' }} /></h3>
            <h5><FormattedMessage id='suppliers.audit_bar.latest_audits' /></h5>
            <div className='pb-4 -mx-4 px-4'>{summaryAudits.map((audit, i) => {
                const maxNumbersOfAudits = 10
                if (i < maxNumbersOfAudits) {
                    const date = getAuditDate(audit);
                    const link = audit.status === 'planned' ? 'audits/edit_audit/' + audit.id : 'audits/report/' + audit.id
                    return <Link key={'audit_ssb_' + i} to={link}>
                        <div key={i} className='hover:bg-gray-200 text-sm text-primary noUnderline flex w-full items-center space-x-2 p-1' style={chunks.length > 1 ? { width: '600px' } : {}}>
                            <div className='w-2/24'><AuditTypeTag className='flex w-8 text-xs' auditStatus={audit.status} auditType={audit.audit_type}  auditConclusion={audit.final_conclusion} /></div>
                            <div className='w-4/24'>{audit.target_production_unit && audit.target_production_unit.number || '-'}</div>
                            <div className='w-8/24 truncate'>{audit.target_production_unit && audit.target_production_unit.name || '-'}</div>
                            <div className='w-2/24'>{audit.assigned_user && <UserInitialsText user={audit.assigned_user} />}</div>
                            <div className='w-4/24 flex justify-end'>{getPrettyDate(date) || '-'}</div>
                            <div className='w-4/24 flex justify-end'><AuditStatus className='h-6 w-40' audit={audit} /></div>
                        </div>
                    </Link>
                }
                else if (i == maxNumbersOfAudits) {
                    return <Link key={'audit_ssb_' + i} to={'/suppliers/' + props.supplierId} ><FormattedMessage id='suppliers.audit_bar.audits_on_supplier.more' values={{ count: summaryAudits.length - maxNumbersOfAudits }} /></Link>
                }
            })}</div>
        </div>
    </Popup>
}
export default SupplierAuditsStatusBar;
