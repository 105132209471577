import React from 'react';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';

type ImageProps = {
    url: string;
    squared?: boolean;
    className?: string;
    w?: number | string;
    h?: number | string;
};

const Image = (props: ImageProps): React.ReactElement => {
    const { squared = false, url, className, w, h } = props;
    /*width: 42px;
    height: 42px;
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    display: inline-block;*/
    return squared ?
        <Popup hoverable basic trigger={<div className={twMerge('rounded bg-cover bg-center w-12 h-12', h && 'h-' + h, w && 'w-' + w, className)} style={{ backgroundImage: 'url("' + url + '")' }} />}>
            <img src={url} />
        </Popup>
        :
        <img src={url} className={twMerge('rounded', className)} />
}
export default Image;
