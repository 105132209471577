import { createAction } from 'typesafe-actions';
import { CustomConclusion } from '../approval/types';

export const SET_FILTER = 'SET_FILTER';
export type SET_FILTER = {
    name: string;
    value: any;
};
export const SET_FILTERS = 'SET_FILTERS';
export type SET_FILTERS = {
    filters: [any];
};

export const SET_FILTERS_VISIBLE = 'SET_FILTERS_VISIBLE';
export type SET_FILTERS_VISIBLE = {
    visible: boolean;
};

export const GET_CUSTOM_FIELDS_REQUEST = 'GET_CUSTOM_FIELDS_REQUEST';

export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export type GET_CUSTOM_FIELDS_SUCCESS = {
    data: any;
};

export const GET_CUSTOM_FIELDS_FAILURE = 'GET_CUSTOM_FIELDS_FAILURE';

export const getCustomFieldsRequest = createAction(GET_CUSTOM_FIELDS_REQUEST);
export const getCustomFieldsSuccess = createAction(GET_CUSTOM_FIELDS_SUCCESS, (data: any) => ({
    type: GET_CUSTOM_FIELDS_SUCCESS, payload: { data },
}));
export const getCustomFieldsFailure = createAction(GET_CUSTOM_FIELDS_FAILURE, (error: any) => ({
    type: GET_CUSTOM_FIELDS_FAILURE, payload: { error },
}));
export const GET_CUSTOM_FIELD_FILTER_DATA_REQUEST = 'GET_CUSTOM_FIELD_FILTER_DATA_REQUEST';
export type GET_CUSTOM_FIELD_FILTER_DATA_REQUEST = {
    customFieldDefinitionId: string[];
};
export const GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS = 'GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS';
export type GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS = {
    customFieldDefinitionId: string[];
    data: any;
};

export const GET_FILTER_CONCLUSION_SUCCESS = 'GET_FILTER_CONCLUSION_SUCCESS';
export type GET_FILTER_CONCLUSION_SUCCESS = {
    conclusions: CustomConclusion[];
}

export const getFilterConclusionsSuccess = createAction(GET_FILTER_CONCLUSION_SUCCESS, (conclusions: CustomConclusion[]) => ({
    type: GET_FILTER_CONCLUSION_SUCCESS, payload: { conclusions },
}));

export const getCustomFieldFilterDataRequest = createAction(GET_CUSTOM_FIELD_FILTER_DATA_REQUEST);
export const getCustomFieldFilterDataSuccess = createAction(GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS, (customFieldDefinitionId: string[], data: any) => ({
    type: GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS, payload: { customFieldDefinitionId, data },
}));

export type Filters = {
    Filter;
};
export type Filter = {
    [id: string]: string[];
};
export type FiltersState = {
    active: { [type: string]: string[] };
    data: { [type: string]: unknown };
    filtersVisible: boolean;
    isFetching: boolean;
    conclusions: CustomConclusion[];
};
export type CustomFieldDataType = {
    id: string;
    is_filter: boolean;
    name: string;
};

export type CustomFieldValue = {
    value?: string;
    name?: string;
    id?: string;
    definition_id?: string;
};