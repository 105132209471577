/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.4 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditReportCustomFieldsMultipleChoiceOption,
    AuditReportCustomFieldsMultipleChoiceOptionFromJSON,
    AuditReportCustomFieldsMultipleChoiceOptionFromJSONTyped,
    AuditReportCustomFieldsMultipleChoiceOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuditReportCustomFieldsMultipleChoice
 */
export interface AuditReportCustomFieldsMultipleChoice {
    /**
     * 
     * @type {Array<AuditReportCustomFieldsMultipleChoiceOption>}
     * @memberof AuditReportCustomFieldsMultipleChoice
     */
    options: Array<AuditReportCustomFieldsMultipleChoiceOption>;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsMultipleChoice
     */
    type: AuditReportCustomFieldsMultipleChoiceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsMultipleChoiceTypeEnum {
    MultipleChoice = 'MultipleChoice'
}

export function AuditReportCustomFieldsMultipleChoiceFromJSON(json: any): AuditReportCustomFieldsMultipleChoice {
    return AuditReportCustomFieldsMultipleChoiceFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsMultipleChoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsMultipleChoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': ((json['options'] as Array<any>).map(AuditReportCustomFieldsMultipleChoiceOptionFromJSON)),
        'type': json['type'],
    };
}

export function AuditReportCustomFieldsMultipleChoiceToJSON(value?: AuditReportCustomFieldsMultipleChoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': ((value.options as Array<any>).map(AuditReportCustomFieldsMultipleChoiceOptionToJSON)),
        'type': value.type,
    };
}


