import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from '../../utils';

type Props = {
    className?: string;
    edit?: boolean;
    onEdit(isEditable: boolean): void;
};

const EditLink = (props: Props): React.ReactElement => {
    const { className, onEdit } = props;
    const intl = useIntl();
    const [edit, setEdit] = useState<boolean>(props.edit || false);

    const editLink = <span className={twMerge('text-sm font-normal text-secondary', !edit && 'link noUnderline', className)} onClick={() => { onEdit(!edit); setEdit(!edit); }}>
        {intl.formatMessage({ id: edit ? 'globals.editing' : 'globals.edit' })}
        {edit && <>.<span className='ml-1 link noUnderline'>
            <FormattedMessage id='globals.editing_done' />
        </span></>}
    </span>;

    return editLink
}
export default EditLink;
