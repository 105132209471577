import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown, Popup } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../store';
import TextItem from '../../../globals/components/TextItem';
import { handleChange, handleFocussedInspection } from '../../actions/editInspectionActions';
import { getInspectionTypes, getInspectionTypesOptionsByType } from '../../selectors/selectors';
import { Inspection, InspectionType } from '../../types';

type InspectionTypeSelectorProps = {
    inspection: Inspection;
    disabled?: boolean;
    type?: 'tag' | 'name';
};

const InspectionTypeSelector = (props: InspectionTypeSelectorProps): React.ReactElement => {
    const { inspection, disabled, type = 'name' } = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isTag = type === 'tag';
    const showInfoPopup = isTag;
    const [options, setOptions] = useState([]);
    const inspectionTypes = useSelector(getInspectionTypes);
    const [typeItem, setTypeItem] = useState(inspection.inspection_type);
    const [isOpen, setIsOpen] = useState(false);
    const [isDOpen, setIsDOpen] = useState(false);
    const inspectionId = inspection.inspection_id;
    useEffect(() => {
        setOptions(getInspectionTypesOptionsByType(inspectionTypes, type));
    }, [inspectionTypes]);

    const getPopup = (typeItem: InspectionType): React.ReactElement => {
        return <Popup
            trigger={dd}
            hoverable={true}
            onClose={(): void => setIsOpen(false)}
            onOpen={(): void => setIsOpen(true)}
            open={!isDOpen && isOpen}
        >
            <TextItem label={intl.formatMessage({ id: 'inspection_types.selector.type_label' })} column={true} text={typeItem && typeItem.name || intl.formatMessage({ id: 'inspections.inspection_types.type_none' })} />
        </Popup>
    }
    const getText = (): string => {
        const none = intl.formatMessage({ id: 'inspection_types.selector.type_label.none' });
        return isTag ? (typeItem && typeItem.tag) || (typeItem && typeItem.name || none) : typeItem && typeItem.name || none;
    }
    const dd = <Dropdown
        value={inspection.inspection_type as string}
        text={getText()}
        disabled={disabled}
        selectOnBlur={false}
        onChange={(evt, item): void => {
            setTypeItem(item.value as InspectionType);
            if (item.value === 'none') {
                item.value = null;
                if (inspection.inspection_type !== null) {
                    dispatch(handleChange(evt, item, inspectionId));
                }
            } else {
                if (inspection.inspection_type === null || (inspection.inspection_type && inspection.inspection_type.id !== (item.value as InspectionType).id)) {
                    dispatch(handleChange(evt, item, inspectionId));
                }
            }
        }}
        name='inspection_type'
        selection
        fluid
        placeholder={'selectType'}
        onFocus={(evt): void => {
            handleFocussedInspection(evt, inspectionId);
        }}
        options={options}
        onOpen={(): void => {
            setIsOpen(false);
            setIsDOpen(true);
        }}
        onClose={(): void => {
            setIsOpen(false);
            setIsDOpen(false);
        }}
        open={isDOpen}

    />;
    return showInfoPopup ? getPopup(typeItem) : dd;
};

export default InspectionTypeSelector;
