import React from 'react';
import { useSelector } from 'react-redux';
import { GetProfileResponse } from '../../../backend_api/models/GetProfileResponse';
import { getTemporaryUserSelector } from '../../audit/selectors/auditSelectors';
type ProfileNameProps = {
    profile: GetProfileResponse;
}
const ProfileName = (props: ProfileNameProps): React.ReactElement => {
    const temporaryUser = useSelector(getTemporaryUserSelector);

    if(temporaryUser?.user) {
        const name = temporaryUser?.user?.firstname + ' ' + temporaryUser?.user?.lastname;
        return (
            <span>{temporaryUser?.user?.firstname && temporaryUser?.user?.lastname ? name : temporaryUser?.user?.email}</span>
        );
    }

    if(temporaryUser?.user?.email) {
        const name = props.profile.firstname + ' ' + props.profile.lastname;
        return (
                <span>{name}</span>
        );
    }

    if (props.profile && props.profile.firstname) {
        const name = props.profile.firstname + ' ' + props.profile.lastname;
        return (
            <div>
                <span>{name}</span>
            </div>
        );
    }

    if(props.profile?.email) {
        return (
            <div>
                <span>{props.profile.email}</span>
            </div>
        );
    }

    return (
        <div>...</div>
    );
};
export default ProfileName;
