import { DashboardPopupInspection, InspectionsWeekviewInspection } from '../../backend_api/models';
import { Audit } from '../../backend_api/models/Audit';
import { BasicUser } from '../../backend_api/models/BasicUser';
import { INSPECTIONS_GROUP_KEY } from '../../base/config';
import { OptionsType, byId } from '../../base/types';
import { getMoment, getPrettyDate, groupArrayByObjectKey, sortArrayByKeys } from '../../base/utils';
import { SupplierBooking } from '../suppliers/types';
import { InspectionsWeekviewInspectionExtended, InspectionsWeekviewOrderExtended } from './components/dashboard/views/weekView/WeekView2';
import { OrderListInspectionExtended } from './slices/inspectionsListSlice';
import { Inspection, Order } from './types';

export const hasPlannedInspections = (_inspections: Inspection[] | InspectionsWeekviewInspectionExtended[]) => {
    const inspections: any = _inspections; // TODO: FIXME: setting inspections to any to avoid type error in filter below (orderOnlyHasPlannedInspections)
    const retval = inspections.filter((inspection: Inspection) => {
        if (inspection.status === 'planned') {
            return inspection;
        }
    });
    return retval.length > 0;
};

export const orderOnlyHasPlannedInspections = (_inspections: Inspection[] | InspectionsWeekviewInspectionExtended[], includeCombinedInspections?: boolean): boolean => {
    const inspections: any = _inspections; // TODO: FIXME: setting inspections to any to avoid type error in filter below (orderOnlyHasPlannedInspections)
    let retval = [];
    if (includeCombinedInspections) {
        retval = inspections.filter((inspection) => {
            if (inspection.status !== 'planned' || inspection.locked) {
                return inspection;
            }
        });
    } else {
        retval = inspections.filter((inspection: Inspection) => {
            if (inspection.status !== 'planned' || inspection.locked || isInspectionPartOfCombinedInspection(inspection)) {
                return inspection;
            }
        });
    }
    return retval.length === 0;
};

export const searchOptionTextAndDescription = (options: OptionsType[], query: string): OptionsType[] => {
    if(!query || query === '') {
        return options;
    }

    const isMatch = (opt: OptionsType): boolean => {
        const q = query.toLowerCase();
        if (opt.description) {
            if (typeof (opt.description) === 'string' && typeof (opt.text) === 'string') {
                return (opt.description).toLowerCase().includes(q) || (opt.text).toLowerCase().includes(q);
            }
        }
        if (typeof (opt.searchtext) === 'string') {
            return (opt.searchtext).toLowerCase().includes(q);
        }
        if (typeof (opt.text) === 'string') {
            return (opt.text).toLowerCase().includes(q);
        }
        if (typeof (opt.content) === 'string') {
            return (opt.content).toLowerCase().includes(q);
        }
    };
    return options.filter((opt) => isMatch(opt));
};

export const isOrderMovable = (order: Order, isBooking?: boolean) => {
    if (isBooking) {
        return (order.features.includes('order_change_assignment_data') || order.features.includes('order_change_booking_data')) && orderOnlyHasPlannedInspections(order.inspections);
    }
    return order.features.includes('order_change_assignment_data') && orderOnlyHasPlannedInspections(order.inspections);
};

export const isInspectionInprogressOrDone = (inspection: Inspection): boolean => {
    return inspection.status.toLocaleLowerCase() === 'ongoing' || inspection.status.toLocaleLowerCase() === 'report';
};

export const isInspectionLockedOrDone = (inspection: Inspection | InspectionsWeekviewInspection | SupplierBooking): boolean => {
    if (inspection.locked || inspection.status === 'ongoing' || inspection.status === 'report') {
        return true;
    }
    return false;
};

export const getLockedAndDoneInspections = (inspections: (Inspection | OrderListInspectionExtended)[]): (Inspection | OrderListInspectionExtended)[] => {
    return inspections.filter((inspection) => {
        if (inspection.locked || inspection.status === 'ongoing' || inspection.status === 'report') {
            return inspection;
        }
    });
};

export const getDoneInspections = (inspections: Inspection[]): Inspection[] => {
    return inspections.filter((inspection: Inspection) => {
        if (inspection.status === 'report') {
            return inspection;
        }
    });
};

export const getDoneOrOngoingInspections = (inspections: (OrderListInspectionExtended | Inspection)[], includeCombinedInspections?: boolean): (OrderListInspectionExtended | Inspection)[] => {
    if (includeCombinedInspections) {
        return inspections.filter((inspection: Inspection) => {
            if (inspection.status === 'report' || inspection.status === 'ongoing') {
                return inspection;
            }
        });
    } else {
        return inspections.filter((inspection: Inspection) => {
            if ((inspection.status === 'report' || inspection.status === 'ongoing') && !isInspectionPartOfCombinedInspection(inspection)) {
                return inspection;
            }
        });
    }
};

export const getNotLockedAndDoneInspections = (inspections: (Inspection | OrderListInspectionExtended)[], includeCombinedInspections?: boolean): (Inspection | OrderListInspectionExtended)[] => {
    let arr = < (Inspection | OrderListInspectionExtended)[] > [];
    if (includeCombinedInspections) {
        arr = inspections.filter((inspection: Inspection) => {
            if (!inspection.locked && (inspection.status === 'planned')) {
                return inspection;
            }
        });
    } else {
        arr = inspections.filter((inspection: Inspection) => {
            if (!inspection.locked && !isInspectionPartOfCombinedInspection(inspection) && (inspection.status === 'planned')) {
                return inspection;
            }
        });
    }
    return arr;
};


export const getInspections = (inspections: Inspection[], includeCombinedInspections?: boolean): Inspection[] => {
    if (!includeCombinedInspections) {
        let arr = [];
        arr = inspections.filter((inspection: Inspection) => {
            if (!isInspectionPartOfCombinedInspection(inspection)) {
                return inspection;
            }
        });

        return arr;
    }
    return inspections;
};

export const getNotLockedAndDoneInspectionIds = (inspections: [Inspection]) => {
    return inspections.map((inspection: Inspection) => {
        if (!inspection.locked && !isInspectionPartOfCombinedInspection(inspection) && (inspection.status === 'planned')) {
            return inspection.inspection_id;
        }
    });
};

export const getPlannedAndLockedInspectionsCount = (inspections: Inspection[], includeCombinedInspections?: boolean): number => {
    let ret: Inspection[];
    if (includeCombinedInspections) {
        ret = inspections.filter((inspection: Inspection) => {
            if ((inspection.locked || isInspectionPartOfCombinedInspection(inspection)) && (inspection.status === 'planned')) {
                return inspection;
            }
        });
    } else {
        ret = inspections.filter((inspection: Inspection) => {
            if (inspection.locked && !isInspectionPartOfCombinedInspection(inspection) && (inspection.status === 'planned')) {
                return inspection;
            }
        });
    }
    return ret.length || 0;
};

export const getLockedInProgressAndDoneInspectionsCount = (inspections: Inspection[], includeCombinedInspections?: boolean): number => {
    let ret: Inspection[];
    if (includeCombinedInspections) {
        ret = inspections.filter((inspection: Inspection) => {
            if (inspection.status !== 'planned' || inspection.locked) {
                return inspection;
            }
        });
    } else {
        ret = inspections.filter((inspection: Inspection) => {
            if ((inspection.status !== 'planned' || inspection.locked) && !isInspectionPartOfCombinedInspection(inspection)) {
                return inspection;
            }
        });
    }
    return ret.length || 0;
};
export const getInProgressAndDoneInspectionsCount = (inspections: Inspection[], includeCombinedInspections?: boolean): number => {
    let ret: Inspection[];
    if (includeCombinedInspections) {
        ret = inspections.filter((inspection: Inspection) => {
            if (inspection.status !== 'planned') {
                return inspection;
            }
        });
    } else {
        ret = inspections.filter((inspection: Inspection) => {
            if ((inspection.status !== 'planned') && !isInspectionPartOfCombinedInspection(inspection)) {
                return inspection;
            }
        });
    }
    return ret.length || 0;
};

// Get the first inspection that is not locked or done
export const getFirstInspectionNotLockedOrDone = (inspections: (OrderListInspectionExtended | Inspection)[], includeCombinedInspections?: boolean): Inspection | OrderListInspectionExtended => {
    let done = false;
    let index = -1;
    if (includeCombinedInspections) {
        inspections.forEach((inspection: Inspection, i: number) => {
            if (!done) {
                if (inspection.status === 'planned' && !inspection.locked) {
                    done = true;
                    index = i;
                }
            }
        });
    } else {
        inspections.forEach((inspection: Inspection, i: number) => {
            if (!done) {
                if (inspection.status === 'planned' && !isInspectionPartOfCombinedInspection(inspection) && !inspection.locked) {
                    done = true;
                    index = i;
                }
            }
        });
    }
    if (index === -1) {
        return null;
    }
    return inspections[index];
};

export const getFirstNotLockedInspection = (inspections: Inspection[]) => {
    return inspections.filter((inspection: any) => {
        return (inspection.locked === false && !isInspectionPartOfCombinedInspection(inspection) && inspection.status === 'planned');
    })[0];
};
export const getFirstOngoingOrDoneInspection = (inspections: Inspection[]): Inspection => {
    return inspections.filter((inspection: any) => {
        return ((inspection.status === 'report' || inspection.status === 'ongoing') && !isInspectionPartOfCombinedInspection(inspection));
    })[0];
};
export const isInspectionPlanned = (inspection: Inspection, includeCombined?: boolean): boolean => {
    if (includeCombined) {
        return (inspection.status === 'planned');
    }
    return (!isInspectionPartOfCombinedInspection(inspection) && inspection.status === 'planned');
}

export const isInspectionNotLockedAndPlanned = (inspection: Inspection, includeCombined?: boolean): boolean => {
    if (includeCombined) {
        return (inspection.locked === false && inspection.status === 'planned');
    }
    return (inspection.locked === false && !isInspectionPartOfCombinedInspection(inspection) && inspection.status === 'planned');
};

export const isInspectionUnlockable = (inspection: Inspection) => {
    return (inspection.locked && inspection.status === 'planned' && (inspection.features.includes('unlock')));
};
export const isAuditUnlockable = (audit: Audit): boolean => {
    return audit.locked && audit.status === 'planned';
};

export const isInspectionUnlockableOrReassignable = (inspection: Inspection) => {
    return (inspection.locked && inspection.status === 'planned') || inspection.status === 'ongoing';
};

export const isInspectionUnlockableOrReassignableOrDone = (inspection: Inspection) => {
    return (inspection.locked && inspection.status === 'planned') || inspection.status === 'ongoing' || inspection.status === 'report';
};

export const isInspectionUnlockableOrReassignableOrDoneOrInCombined = (inspection: Inspection) => {
    return (inspection.locked && inspection.status === 'planned') || inspection.status === 'ongoing' || inspection.status === 'report' || isInspectionPartOfCombinedInspection(inspection);
};
export const isInspection = (item) => item.inspection_id;

export const getCombinedInspectionMasterInspection = (order: Order): Inspection => {
    let retval: Inspection;
    order.inspections.forEach((inspection: Inspection) => {
        if (inspection.is_master === true) {
            retval = inspection;
        }
    });
    return retval;
};

export const isOrderCombinedInspection = (order: Order | InspectionsWeekviewOrderExtended) => {
    if (order.inspections && order.inspections.length > 0) {
        return order.inspections[0].is_master;
    }
    return false;
};

export const isInspectionMasterInspection = (inspection: Inspection) => {
    return inspection.is_master;
};

export const isInspectionPartOfCombinedInspection = (inspection: (Inspection | InspectionsWeekviewInspectionExtended | OrderListInspectionExtended | DashboardPopupInspection)) => {
    if ((inspection.master_inspection && inspection.master_inspection !== null)) {
        return true;
    }
    return false;
};

export const getCombinedInspectionsOrderId = (order: Order) => {
    return order.inspections[0].sub_inspections[0].master_inspection.order_id;
};
export const getCombinedInspectionOrderId = (inspection: Inspection) => {
    return (inspection.master_inspection && inspection.master_inspection.order_id) || '';
};

export const getCombinedOrderSubInspections = (order: Order): Inspection[] => {
    return order.inspections[0].sub_inspections || [];
};

export const getCombinedInspectionsInspectionId = (order: Order) => {
    return order.inspections[0].sub_inspections[0].master_inspection.id;
};

export const isInspectionReinspection = (inspection: Inspection) => {
    return inspection.reinspected_by.length > 0 || inspection.reinspection_based_on !== null;
};

export const getInspectionReportLink = (inspection: Inspection | DashboardPopupInspection | OrderListInspectionExtended) => {
    const isCombined = isInspectionPartOfCombinedInspection(inspection);
    return isCombined ? inspection.master_inspection && inspection.master_inspection.id : inspection.inspection_id;
}

export const inspectionHasCustomValues = (inspection: Inspection) => {
    if (inspection.custom_field_values) {
        const retval = inspection.custom_field_values.filter((cs) => {
            if (cs.value !== '') {
                return cs;
            }
        });
        return retval.length > 0;
    }
    return false;
};

export const getInspectionsMap = (inspections: [Inspection]) => {
    const map = new Map();
    inspections.forEach((inspection: Inspection) => {
        map.set(inspection.inspection_id, inspection);
    });
    return map;
};

export const inspectionContainsItem = (inspection: Inspection, item: any) => {
    if (item) {
        let contains = true;
        Object.keys(item).forEach((key) => {
            if (inspection[key] !== item[key]) {
                contains = false;
            }
        });
        // console.log('contains ', contains);
        return contains;
    }
    return false;
};

export const updateInspectionWithFragment = (inspection: Inspection, item: any): Inspection => {
    if (item) {
        Object.keys(item).forEach((key) => {
            inspection[key] = item[key];
        });
    }
    return inspection;
};

export const inspectionPropertyChanged = (currentInspection: Inspection, changedAttribute: { name: string; value: any }): boolean => {
    let changed = false;
    if (Object.prototype.hasOwnProperty.call(currentInspection, changedAttribute.name)) {
        if (currentInspection[changedAttribute.name] === null) {
            return changedAttribute !== null;
        }
        changed = currentInspection[changedAttribute.name].toString() !== changedAttribute.value;
    }
    return changed;
};

export const inspectionsHaveElement = (inspections: (OrderListInspectionExtended | Inspection | DashboardPopupInspection)[], element: 'scheduled_inspection_date' | 'etd' | 'inspection_end' | 'inspection_start'): boolean => {
    const retval = inspections.filter((inspection: Inspection) => {
        return inspection[element];
    });
    return retval.length > 0;
};

export const getInspectionETD = (inspections: Inspection[] | DashboardPopupInspection[]) => {
    if (inspectionsHaveElement(inspections, 'etd')) {
        return getDateByType(inspections, 'etd');
    }
    return '-';
};

export const getOrderDate = (inspections: (OrderListInspectionExtended | Inspection)[], isPlanned: boolean, locale: string): string => {
    if (isPlanned) {
        inspections = getNotLockedAndDoneInspections(inspections, true);
        if (inspectionsHaveElement(inspections, 'scheduled_inspection_date')) {
            return getPrettyDate(getDateByType(inspections, 'scheduled_inspection_date', 'earliest'), locale);
        }
        if (inspectionsHaveElement(inspections, 'etd')) {
            return getPrettyDate(getDateByType(inspections, 'etd', 'latest'), locale) + '*';
        }
    }
    if (inspectionsHaveElement(inspections, 'inspection_end')) {
        return getPrettyDate(getDateByType(inspections, 'inspection_end', 'latest'), locale);
    }
    if (inspectionsHaveElement(inspections, 'inspection_start')) {
        return getPrettyDate(getDateByType(inspections, 'inspection_start', 'latest'), locale);
    }
    if (inspectionsHaveElement(inspections, 'scheduled_inspection_date')) {
        return getPrettyDate(getDateByType(inspections, 'scheduled_inspection_date', 'earliest'), locale);
    }
    if (inspectionsHaveElement(inspections, 'etd')) {
        return getPrettyDate(getDateByType(inspections, 'etd', 'latest'), locale) + '*';
    }
};

export const getInspectionDate = (inspections: (Inspection | OrderListInspectionExtended | DashboardPopupInspection)[], locale: string, includeETD = true): string => {
    if (inspectionsHaveElement(inspections, 'inspection_end')) {
        return getPrettyDate(getDateByType(inspections, 'inspection_end'), locale);
    }
    if (inspectionsHaveElement(inspections, 'inspection_start')) {
        return getPrettyDate(getDateByType(inspections, 'inspection_start'), locale);
    }
    if (inspectionsHaveElement(inspections, 'scheduled_inspection_date')) {
        return getPrettyDate(getDateByType(inspections, 'scheduled_inspection_date'), locale);
    }
    if (includeETD && inspectionsHaveElement(inspections, 'etd')) {
        return getPrettyDate(getDateByType(inspections, 'etd'), locale) + '*';
    }
    return '-';
};

export const getInspectionDateTimestamp = (inspections) => {
    if (inspectionsHaveElement(inspections, 'inspection_start')) {
        return getDateByType(inspections, 'inspection_start');
    }
    if (inspectionsHaveElement(inspections, 'scheduled_inspection_date')) {
        return getDateByType(inspections, 'scheduled_inspection_date');
    }
    return null;
};

export const getDateByType = (inspections: (OrderListInspectionExtended | Inspection | DashboardPopupInspection)[], type: string, sortBy?: 'earliest' | 'latest'): string => {
    let date;
    let diff;
    inspections.some((inspection: OrderListInspectionExtended | Inspection | DashboardPopupInspection) => {
        const inspectionType = inspection[type];
        if (inspectionType) {
            if (!date) {
                date = inspectionType;
                return diff = getMoment(date).diff(inspectionType);
            }
            if (sortBy) {
                if (sortBy === 'earliest') {
                    if (getMoment(inspectionType) < getMoment(date)) {
                        date = inspectionType;
                    }
                } else {
                    if (getMoment(inspectionType) > getMoment(date)) {
                        date = inspectionType;
                    }
                }
            } else {
                if (getMoment().diff(inspectionType) < diff) {
                    date = inspectionType;
                    diff = (getMoment(date).diff(inspectionType));
                }
            }
        }
    });
    return date;
};

export const getInspectionByTypeSequence = (inspections: (OrderListInspectionExtended | Inspection)[]): Inspection => {
    const inspectionsSorted = sortArrayByKeys([...inspections]);
    return getLatestInInspectionTypeSequence(inspectionsSorted);
};

export const getLatestInInspectionTypeSequence = (inspections: Inspection[]): Inspection => {
    // Returns the inspection with the highest type sequence number - i.e. the inspection that is "most final"
    let latestSequence = 0;
    let latestReinspectionCount = 0;
    let latestPlanned: Inspection;
    let ret: Inspection; // TODO: If inspections types are all planned, which type should be returned?
    const inspectionsSorted: Inspection[] = sortArrayByKeys(inspections);
    inspectionsSorted.forEach((inspection: Inspection) => {
        if (inspection.inspection_type) {
            const inspectionType = inspection.inspection_type;
            const reinspectionBasedOn = inspection.reinspection_based_on;
            if (inspection.status !== 'planned') {
                ret = inspection;
            } else {
                latestPlanned = inspection;
            }
            if (inspectionType.sequence > latestSequence && inspection.status !== 'planned') {
                latestSequence = inspectionType.sequence;
            }
            if (reinspectionBasedOn) {
                latestReinspectionCount = reinspectionBasedOn.count || 0;
                if (reinspectionBasedOn.count > latestReinspectionCount) {
                    ret = inspection;
                }
            }

        }
    });
    if (!ret) {
        return latestPlanned;
    }
    return ret;
};

export const getInspectionsByInspectionSequence = (inspections: Inspection[] | DashboardPopupInspection[]) => {
    const ret = {};
    inspections.forEach((inspection: Inspection | DashboardPopupInspection) => {
        let seq = 0;
        if (inspection.inspection_type) {
            seq = inspection.inspection_type.sequence;
        }
        ret[seq] ? ret[seq].push(inspection) : ret[seq] = [inspection];
    });
    /* if (Object.keys(ret).length < types.length) {
        types.forEach((type: InspectionType, i: number) => {
            if (!Object.keys(ret).includes(type.sequence.toString())) {
                ret[i + 1] = type;
            }
        });
    } */
    return ret;
};

export const getGroupedInspectionsLatestInSequence = (inspections: Inspection[]): Inspection[] => {
    const ret = [];
    const inspectionsGrouped = groupArrayByObjectKey(inspections, INSPECTIONS_GROUP_KEY);
    Object.values(inspectionsGrouped).map((inspectionsByGroup: OrderListInspectionExtended[]) => {
        ret.push(getInspectionByTypeSequence(inspectionsByGroup));
    });
    return ret;
};

export const splitInspectionsByTypeOrNone = (inspections: (OrderListInspectionExtended | Inspection)[]): { withType: (OrderListInspectionExtended | Inspection)[]; withOutType: (OrderListInspectionExtended | Inspection)[] } => {
    const withType = [];
    const withOutType = [];
    inspections.forEach((inspection: OrderListInspectionExtended) => {
        if (inspection.inspection_type) {
            withType.push(inspection);
        } else {
            withOutType.push(inspection);
        }
    });
    return { withType, withOutType };
};

export const getInspectionsGroupedAndNoTypes = (inspections: (Inspection | OrderListInspectionExtended)[]): { grouped: byId<(OrderListInspectionExtended | Inspection)[]>; noTypes: (OrderListInspectionExtended | Inspection)[]; withTypes: (OrderListInspectionExtended | Inspection)[] } => {
    const inspectionsBase: (Inspection | OrderListInspectionExtended)[] = inspections[0].is_master !== undefined && inspections[0].is_master ? inspections[0].sub_inspections : inspections;
    const inspectionsWithTypeOrNone = splitInspectionsByTypeOrNone(inspectionsBase);
    return {
        grouped: groupArrayByObjectKey(inspectionsBase, INSPECTIONS_GROUP_KEY),
        noTypes: inspectionsWithTypeOrNone.withOutType,
        withTypes: inspectionsWithTypeOrNone.withType,
    };
};

export const getNotLockedAndDoneInspectionsByTypeId = (inspections: Inspection[], typeId: string, includeCombinedInspections?: boolean): Inspection[] => {
    let arr = [];
    if (includeCombinedInspections) {
        arr = inspections.filter((inspection: Inspection) => {
            if (!inspection.locked && inspection.status === 'planned' && ((inspection.inspection_type && inspection.inspection_type.id === typeId) || (typeId === 'none' || typeId === 'all'))) {
                return inspection;
            }
        });
    } else {
        arr = inspections.filter((inspection: Inspection) => {
            if (!inspection.locked && !isInspectionPartOfCombinedInspection(inspection) && ((inspection.inspection_type && inspection.inspection_type.id === typeId) || (typeId === 'none' || typeId === 'all'))) {
                return inspection;
            }
        });
    }
    return arr;
};



export const inspectionIsReport = (inspection: Inspection | DashboardPopupInspection) => {
    return inspection.status !== 'planned';
};

export const inspectionIsReportOrPending = (inspection: Inspection) => {
    return inspection.status === 'report' || inspection.status === 'ongoing';
};

export const allInspectionsHaveInspectionTypes = (inspections: Inspection[]) => {
    return (inspections.filter((inspection: Inspection) => !inspection.inspection_type)).length === 0;
};

export const inspectionHasTypes = (inspection: Inspection) => {
    return inspection.inspection_type !== null;
};

export const inspectionHasType = (inspection: Inspection, typeId: string) => {
    return inspection.inspection_type && inspection.inspection_type.id === typeId;
};

export const inspectionsHaveType = (inspections: Inspection[], typeId: string) => {
    return inspections.filter((inspection: Inspection) => inspectionHasType(inspection, typeId));
};

export const selectTypedFromInspections = (inspections: Inspection[], typeId: string): Inspection[] => {
    const ret = [];
    inspections.forEach((inspection: Inspection) => {
        if (inspection && inspection.inspection_type && inspection.inspection_type.id === typeId) {
            ret.push(inspection);
        }
    });
    return ret;
};
export const selectPlannedFromGroupedInspections = (inspections: Inspection[]) => {
    const ret = [];
    if (inspections) {
        inspections.forEach((inspection: Inspection) => {
            if (inspection.status === 'planned') {
                ret.push(inspection);
            }
        });
    }
    return ret;
};

export const getIsPropertyInSameForAllInspections = (propertyName: string) => {
    const propsInSameForAll = ['approval_flow', 'watchers', 'assigned_user_id', 'scheduled_inspection_date', 'supplier_qc'];
    return propsInSameForAll.includes(propertyName);
};

export const getChecklistDefaultValue = (checklistId: string, checklistsData: any[]) => {
    const checklistDefaultValue = checklistId || checklistsData[0] && checklistsData[0].id;
    return checklistDefaultValue;
};

export const getOrderDefaultDate = (order: Order): Date => {
    if (order) {
        const inspection = getFirstInspectionNotLockedOrDone(order.inspections);
        if (inspection) {
            return inspection.etd;
        }
        return order.inspections.map((insp: Inspection) => {
            if (insp.etd) {
                return insp.etd;
            }
            return null;
        })[0];
    }
    return null;
};

export const getItemClassName = (itemName: string, hasInspectionTypes: boolean, isCombined: boolean): string => {
    return `item ${itemName} ${hasInspectionTypes ? 'inspectionType' : ''} ${isCombined ? 'combinedInspection' : ''}`;
};

export const getBookingItemClassName = (itemName: string, hasInspectionTypes: boolean, isCombined: boolean): string => {
    return `lvItem ${itemName} ${hasInspectionTypes ? 'inspectionType' : ''} ${isCombined ? 'combinedInspection' : ''}`;
};

export const getFirstAssignedUser = (inspections: (Inspection | OrderListInspectionExtended)[], includeDone: boolean): BasicUser => {
    let user;
    const plannedInspections = getNotLockedAndDoneInspections(inspections, true);
    let wasFound = false;
    plannedInspections.forEach((inspection: Inspection) => {
        if (inspection.assigned_user_id !== null && !wasFound) {
            user = inspection.assigned_user;
            wasFound = true;
        }
    });
    if (!wasFound && includeDone) {
        const doneInspections = getLockedAndDoneInspections(inspections);
        doneInspections.forEach((inspection: Inspection) => {
            if (inspection.assigned_user_id !== null && !wasFound) {
                user = inspection.assigned_user;
                wasFound = true;
            }
        });
    }
    return wasFound ? user : null;
};