import React, { ReactNode, useEffect, useState } from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';

type ContextMenuProps = {
    children: ReactNode;
    trigger?: ReactNode;
    triggerIconClass?: string;
    open: boolean;
    pointer?: boolean;
    position?: PopupProps['position'];
    close(): void;
}

const ContextMenu = (props: ContextMenuProps): React.ReactElement => {
    const [isOpen, setIsOpen] = useState(props.open || false);
    useEffect(() => {
        setIsOpen(props.open)
    }, [props.open])
    useEffect(() => {
        setIsOpen(false)
    }, [props.close])
    return <Popup
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setTimeout(() => setIsOpen(false), 100)}
        closeOnDocumentClick={true}
        on={'click'}
        position={props.position || 'bottom center' || 'top center'}
        basic={!props.pointer}
        trigger={props.trigger || <div className='inline-flex justify-items-center items-center bg-gray-100 rounded hover:bg-branding-brand'><i className={twMerge('mi cursor-pointer text-2xl text-on-surface-plain hover:text-white', props.triggerIconClass)}>more_vert</i></div>}>
        <Popup.Content className='-mb-3'>
            {props.children}
        </Popup.Content>
    </Popup>
}

export default ContextMenu;

