/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldNotSet } from './SetCustomFieldNotSet';
import type { SetCustomFieldOptionIds } from './SetCustomFieldOptionIds';

/**
 *   Value for a multiple choice based custom field.
 *
 */
export type SetFieldMultipleChoiceValue = {
    new_value: (SetCustomFieldNotSet | SetCustomFieldOptionIds);
    type: SetFieldMultipleChoiceValue.type;
};

export namespace SetFieldMultipleChoiceValue {

    export enum type {
        MULTIPLE_CHOICE = 'MultipleChoice',
    }


}

