import { createLogic } from 'redux-logic';
import { getFilteredListNew as getFilteredList } from '../slices/inspectionsListSlice';

export const inspectionsListLogic = createLogic({
    type: getFilteredList.fulfilled,
    latest: true,
    transform({ action }, next) {
        next(action);
    },
});
