import { createLogic } from 'redux-logic';
import { AppState } from '../../../base/types';
import { storeSingleInspection } from '../actions';
import { areAllInspectionsEqual } from '../actions/editInspectionActions';
import { getInspectionsMap } from '../selectors/selectors';
import { ORDER_AND_INSPECTIONS_REQUEST_SUCCESS, UPDATE_SINGLE_INSPECTION_REQUEST } from '../types';
import { isInspectionNotLockedAndPlanned } from '../Utils';

const updateInspectionLogic = createLogic<AppState, {inspectionId: string, inspectionData: any}>(
    {
        type: UPDATE_SINGLE_INSPECTION_REQUEST,
        latest: true,
        validate({ getState, action }, allow, reject) {
            let allowAction = true;
            const map = getInspectionsMap(getState());
            const inspectionId = action.payload.inspectionId;
            if (map[inspectionId]) {
                const inspection = getInspectionsMap(getState())[action.payload.inspectionId];
                if (!isInspectionNotLockedAndPlanned(inspection, true)) {
                    allowAction = false;
                }
            }
            allowAction ? allow(action) : reject(action);
        },
        process({ action }, dispatch, done) {
            dispatch(storeSingleInspection(action.payload.inspectionId, action.payload.inspectionData) as any);
            done(); // done dispatching
        },
    });
const updateOrderLogic = createLogic({
    type: ORDER_AND_INSPECTIONS_REQUEST_SUCCESS,
    latest: true,
    process(dummy, dispatch, done) {
        dispatch(areAllInspectionsEqual() as any);
        done(); // done dispatching
    },
});
export { updateInspectionLogic, updateOrderLogic };

