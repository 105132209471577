import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { configureStore } from '../store';
import history from '../store/history';
import App from './App';
import ConnectedIntlProvider from './ConnectedIntlProvider';
export const store = configureStore();

const Root = (): React.ReactElement => {
    return (
                    
        <Provider store={store}>
            <ConnectedIntlProvider>
                <div>
                        <Router history={history}>
                            <App />
                        </Router>
                </div>
            </ConnectedIntlProvider>
        </Provider>
    );
};

export const globalStore = store;
export default Root;