export const PDF_SET_EXTERNAL_ID = 'PDF_SET_EXTERNAL_ID';
export type PDF_SET_EXTERNAL_ID = {
    id: string;
    externalId: string;
};

export const PDF_CLEAR_EXTERNAL_ID = 'PDF_CLEAR_EXTERNAL_ID';
export type PDF_CLEAR_EXTERNAL_ID = {
    inspectionId: string;
};
