/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * History entry audit reference
 * @export
 * @interface HistoryEntryAuditReference
 */
export interface HistoryEntryAuditReference {
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    audit_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof HistoryEntryAuditReference
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryAuditReference
     */
    source_reference_type: HistoryEntryAuditReferenceSourceReferenceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum HistoryEntryAuditReferenceSourceReferenceTypeEnum {
    Audit = 'audit',
    User = 'user',
    ImportJob = 'import_job'
}

export function HistoryEntryAuditReferenceFromJSON(json: any): HistoryEntryAuditReference {
    return HistoryEntryAuditReferenceFromJSONTyped(json, false);
}

export function HistoryEntryAuditReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntryAuditReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audit_id': json['audit_id'],
        'disabled': json['disabled'],
        'email': json['email'],
        'firstname': json['firstname'],
        'id': json['id'],
        'lastname': json['lastname'],
        'source_reference_type': json['source_reference_type'],
    };
}

export function HistoryEntryAuditReferenceToJSON(value?: HistoryEntryAuditReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audit_id': value.audit_id,
        'disabled': value.disabled,
        'email': value.email,
        'firstname': value.firstname,
        'id': value.id,
        'lastname': value.lastname,
        'source_reference_type': value.source_reference_type,
    };
}


