import React from 'react';
import { twMerge } from '../../utils';
import Icon from './icons/Icon';

type Props = {
    className?: string;
};

enum Size { small = 'text-md', medium = 'text-2xl', large = 'text-4xl' }
const iconBg = 'text-icon-default';
const disabledColor = 'text-gray-300';  // TODO: #figma fix colors: should have disabled color from Figma?

const MicroInteractions = (props: Props): React.ReactElement => {
    const { className } = props;

    return <div className={twMerge('', className)}></div>
}

export const MicroInteractionNext = (props: { className?: string, size?: Size, disabled?: boolean }) => {
    const { size = Size.medium, className, disabled } = props;
    return <div><Icon name='microinteraction_navigate-next' className={twMerge(!disabled && 'hover:text-gray-300', iconBg, disabled && disabledColor, size, className)} /></div>
}
export const MicroInteractionPrevious = (props: { className?: string, size?: Size, disabled?: boolean }) => {
    const { size = Size.medium, disabled, className } = props;
    return <div><Icon name='microinteraction_navigate-previous' className={twMerge(!disabled && 'hover:text-gray-300', iconBg, disabled && disabledColor, size, className)} /></div>
}
export const MicroInteractionOpen = (props: { className?: string, size?: Size }) => {
    const { size = Size.medium, className } = props;
    return <div><Icon name='microinteraction_navigate-next' className={twMerge('hover:text-gray-300', iconBg, size, className)} /></div>
}
export const MicroInteractionExpand = (props: { className?: string, size?: Size }) => {
    const { size = Size.medium, className } = props;
    return <div><Icon name='microinteraction_expand' className={twMerge('hover:text-gray-300', iconBg, size, className)} /></div>
}
export const MicroInteractionCollapse = (props: { className?: string, size?: Size }) => {
    const { size = Size.medium, className } = props;
    return <div><Icon name='microinteraction_collapse' className={twMerge('hover:text-gray-300', iconBg, size, className)} /></div>
}
export const MicroInteractionClose = (props: { className?: string, size?: Size }) => {
    const { size = Size.small, className } = props;
    return <div><Icon name='microinteraction_close' className={twMerge('hover:text-gray-300', iconBg, size, className)} /></div>
}
export const MicroInteractionContextMenu = (props: { className?: string, size?: Size, onClick?(e): void }) => {
    const { size = Size.small, className, onClick } = props;
    return <Icon name='microinteraction_context-menu' className={twMerge('', iconBg, size, className)} />
}
export default MicroInteractions;
