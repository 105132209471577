
import { Pie } from '@nivo/pie';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../store';
import { updateDefectsCategoryTableData } from '../../dashboardActions';
type DefectsPieProps = {
    data: any;
    colors: any;
    isTopLevel: boolean;
};
const DefectsPie = (props: DefectsPieProps): React.ReactElement => {
    const { data, colors } = props;
    const dispatch = useAppDispatch();
    return (<div className='pie'>
        <Segment.Group raised container>
            <Segment secondary>
                <Header as='h3'><FormattedMessage id='dashboard.pie_header.defects_distribution' /></Header>
            </Segment>
            <Segment>
                <Pie
                    width={360}
                    height={360}
                    innerRadius={0.4}
                    colors={(d): string => colors[d.id]}
                    data={data || []}
                    enableArcLinkLabels={false}
                    enableArcLabels={false}
                    margin={{ top: 30, right: 0, bottom: 20, left: 0 }}
                    arcLinkLabelsSkipAngle={20}
                    onClick={(d: any): void => {
                        if (props.isTopLevel) {
                            dispatch(updateDefectsCategoryTableData(d.data.defectId as string));
                        }
                    }}
                />
            </Segment>
        </Segment.Group>
    </div>
    );
};

export default DefectsPie;
