import React from 'react';
import { Image } from 'semantic-ui-react';
interface CustomerLogoProps {
    path: string;
}
const CustomerLogo = (props: CustomerLogoProps): React.ReactElement => {
    const src = props.path;
    if (src === null || src === '') {
        return null;
    }
    return (
        <div className='customerLogo'><Image className='image' src={src} alt='Customer logo' /></div>
    );
};
export default CustomerLogo;
