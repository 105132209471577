import classnames from 'classnames';
import React from 'react';
import { useDrag } from 'react-dnd';
import { WeekviewUser } from '../../../../../../backend_api/models';
import { orderOnlyHasPlannedInspections } from '../../../../Utils';
import { DragType } from './WeekView2';

type SupplierItemProps = {
    id: string;
    date: Date;
    data: any;
    itemType: string;
    user: WeekviewUser;
    className?: string;
    children: React.ReactNode;
};

const SupplierItem = (props: SupplierItemProps): React.ReactElement => {
    const { id, className, itemType, date, children, data, user } = props;
    const [collectedDragProps, drag] = useDrag({
        type: DragType.WEEKVIEW_SUPPLIER_ITEM, item: { type: DragType.WEEKVIEW_SUPPLIER_ITEM, id, itemType, date, data, user },
    });
    const canDrag = () => {
        if (props.itemType === 'booked') {
            return data.orders.filter((order) => order.features.includes('order_change_assignment_data') && orderOnlyHasPlannedInspections(order.inspections)).length > 0;
        }
        return data.orders.filter((order) => order.features.includes('order_change_assignment_data') && orderOnlyHasPlannedInspections(order.inspections)).length > 0;
    }
    return <div ref={canDrag() ? drag : null} className={classnames('', className)}>
        {children}
    </div>
}
export default SupplierItem;
