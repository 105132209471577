import classnames from 'classnames';
import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';
import { InputProps } from 'semantic-ui-react';
import { AppThunk } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { ChecklistsSelector } from '../../../checklists/components';
import { Checklist } from '../../../checklists/types';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import { isInspectionPartOfCombinedInspection, isInspectionReinspection } from '../../Utils';
import { handleBlurredInspection, handleFocussedInspection, handleAction as handleInspectionAction } from '../../actions/editInspectionActions';
import { Inspection } from '../../types';
import { CombinedInspectionLabel } from '../combine/CombinedInspections';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import SplitShipments from '../shared/SplitShipments';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import EditChecklistAction from './EditChecklistAction';
import EditInspectionsContextMenu from './EditInspectionsContextMenu';
import InspectionTypeSelector from './InspectionTypeSelector';
import Quantity from './Quantity';
import { PlainStatus } from './Status';
import QInput from './formelements/QInput';

type OwnProps = {
    checklists: Checklist[];
    disabled: boolean;
    isSaving: boolean;
    isCombinedInspection: boolean;
    hasInspectionTypes: boolean;
    isLastRemaining: boolean;
    inspection: Inspection;
    intl: IntlShape;
    lineToggler: React.ReactElement;
};

export const areEqual = (prevProps, nextProps): boolean => {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    if (nextProps.intl.locale !== prevProps.intl.locale) {
        return false;
    }
    if (nextProps.inspection.inspection_id !== prevProps.inspection.inspection_id) {
        return false;
    }
    if (nextProps.checklists.length !== prevProps.checklists.length) {
        return false;
    }
    if (nextProps.inspection.assigned_user_id !== prevProps.inspection.assigned_user_id) {
        return false;
    }
    if (nextProps.inspection.sample_quantity !== prevProps.inspection.sample_quantity) {
        return false;
    }
    if (nextProps.inspection.total_quantity !== prevProps.inspection.total_quantity) {
        return false;
    }
    if (nextProps.inspection.checklist_id !== prevProps.inspection.checklist_id) {
        return false;
    }
    if (prevProps.inspection.inspection_type && !nextProps.inspection.inspection_type) {
        return false;
    }
    if (!prevProps.inspection.inspection_type && nextProps.inspection.inspection_type) {
        return false;
    }
    if (nextProps.isCombinedInspection !== prevProps.isCombinedInspection) {
        return false;
    }
    if (nextProps.isSaving !== prevProps.isSaving) {
        return false;
    }
    if (nextProps.inspection.bypass_sampling_protocol !== prevProps.inspection.bypass_sampling_protocol) {
        return false;
    }
    if (nextProps.inspection.sampling_protocol_id !== prevProps.inspection.sampling_protocol_id) {
        return false;
    }

    if (nextProps.lineToggler.props.data.open !== prevProps.lineToggler.props.data.open) {
        return false;
    }

    if (nextProps.lineToggler.props.markText.length !== prevProps.lineToggler.props.markText.length) {
        return false;
    }
    if (nextProps.isLastRemaining !== prevProps.isLastRemaining) {
        return false;
    }
    if (nextProps.disabled !== prevProps.disabled) {
        return false;
    }
    // Please leave this line at the end
    if (prevProps.inspection.inspection_type && nextProps.inspection.inspection_type) {
        return prevProps.inspection.inspection_type.id === nextProps.inspection.inspection_type.id;
    }
    return true;
};
type InfoLineProps = OwnProps;

export const widthsFoo = (hasInspectionTypes: boolean): any => {
    const f =
    {
        types: {
            t: 'md:w-3/24 lg:w-2/24 w-full',
            c: 'md:w-1/24 w-full',
        },
        itemNumber: {
            t: 'md:w-4/24 w-full',
            c: 'md:w-5/24 w-full',
        },
        orderNumber: {
            t: 'md:w-5/24 w-full',
            c: 'md:w-5/24 w-full',
        },
        itemName: {
            t: 'md:w-4/24 sm:w-4/24 w-full lg:w-5/24',
            c: 'md:w-7/24 w-full',
        },
        totalQty: {
            t: 'md:w-2/24 lg:w-2/24 w-full',
            c: 'md:w-2/24 w-full',
        },
        sampleQty: {
            t: 'md:w-2/24 lg:w-2/24 w-full flex h-fit-content',
            c: '',
        },
        checklist: {
            t: hasInspectionTypes ? 'md:w-4/24 w-full flex h-fit-content' : 'md:w-6/24 w-full flex h-fit-content',
            c: '',
        },
        status: {
            t: 'md:w-3/24 lg:w-3/24 w-full',
            c: 'md:w-2/24 lg:w-2/24 w-full',
        },
        delete: {
            t: 'md:w-2/24 lg:w-1/24 w-full flex',
            c: 'md:w-2/24 w-full flex',
        },
    };
    return f;
};

const InfoLine = (props: InfoLineProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = props.intl;
    const formatMessage = intl.formatMessage;
    const { inspection, isSaving = false, isLastRemaining = false, isCombinedInspection, hasInspectionTypes } = props;
    const locked = inspection.locked;
    const done = inspection.status === 'report';
    const ongoing = inspection.status === 'ongoing';
    const inspectionId = inspection.inspection_id;
    const isPartOfCombinedInspection = isInspectionPartOfCombinedInspection(inspection);
    const checklistId = inspection.checklist_id;
    const disabled = props.disabled || isPartOfCombinedInspection || isCombinedInspection || locked || done || ongoing;
    const disabledNotCombined = props.disabled || !isCombinedInspection || locked || done || ongoing;
    const checklistsData = props.checklists;
    const [checklistValue, setChecklistValue] = useState(inspection.checklist_id !== null ? checklistId : '-1');
    const [checklistText, setChecklistText] = useState(inspection.checklist_name !== null ? inspection.checklist_name : '');
    const isReinspection = isInspectionReinspection(inspection);
    const deleteDisabled = locked || done || ongoing || isLastRemaining || isCombinedInspection || isPartOfCombinedInspection;
    const widths = widthsFoo(hasInspectionTypes);
    const handleBlur = (evt: React.SyntheticEvent<Element, Event>, item: InputProps): void => {
        const name = item.name;
        const value = item.value;
        if (value !== inspection[name]) {
            dispatch(handleBlurredInspection(evt, item, inspectionId));
        }

    }
    const handleFocus = (evt: SyntheticEvent<Element, Event>): AppThunk => dispatch(handleFocussedInspection(evt, inspectionId));

    const handleAction = (item: { name: string; value: any }): AppThunk => dispatch(handleInspectionAction(item, inspectionId));

    useEffect(() => {
        if (inspection.checklist_id !== null) {
            setChecklistValue(inspection.checklist_id);
        }
        else if (props.inspection.master_inspection !== null) {
            setChecklistValue(props.inspection.master_inspection.checklist_id);
        }
    }, [inspection.checklist_id]);
    return (
        <Fragment key={inspection.inspection_id}>
            <div className='flex flex-col md:flex-row md:space-x-1 lg:space-x-2 lg:mr-8 items-center mb-3 pl-1'>
                {hasInspectionTypes && <div className={classnames(widths.types[isCombinedInspection ? 'c' : 't'], 'flex justify-center')}>
                    {isCombinedInspection &&
                        <InspectionTypeMarker type='editInspection' inspections={[inspection]} />}
                    {!isCombinedInspection &&
                        <HasReadonlyAccess type='inspection' actionId='inspection_type' inspection={inspection}>
                            <InspectionTypeSelector
                                inspection={inspection}
                                type='tag'
                                disabled={disabled}
                            />
                        </HasReadonlyAccess>}
                </div>}
                {isCombinedInspection &&
                    <div className={classnames(widths.orderNumber.c, {})}>
                        <HasReadonlyAccess type='inspection' actionId='item_number' inspection={inspection}>
                            <QInput
                                type='text'
                                name='order_number'
                                value={inspection.order_number}
                                placeholder={formatMessage({ id: 'inspections.item_number' })}
                                readOnly={true}
                                className={'readonly'}
                                key={'item_number_' + inspectionId}
                                label={(isReinspection || isPartOfCombinedInspection) && <EditInspectionsContextMenu
                                    inspection={inspection}
                                    isCombinedInspection={isCombinedInspection}
                                    deleteDisabled={deleteDisabled}
                                    isSaving={isSaving}
                                    pointer>
                                    <div><CombinedInspectionLabel inspection={inspection} type='short' /></div>
                                </EditInspectionsContextMenu>
                                }
                            />
                        </HasReadonlyAccess>
                    </div>
                }
                <div className={classnames(widths.itemNumber[isCombinedInspection ? 'c' : 't'], {})}>
                    <HasReadonlyAccess type='inspection' actionId='item_number' inspection={inspection}>
                        <EditInspectionsContextMenu inspection={inspection} isCombinedInspection={isCombinedInspection} unlockOnly pointer deleteDisabled={deleteDisabled}>
                            <span>
                                <QInput
                                    type='text'
                                    name='item_number'
                                    value={inspection.item_number}
                                    placeholder={formatMessage({ id: 'inspections.item_number' })}
                                    handleBlur={disabled ? null : handleBlur}
                                    handleFocus={disabled ? null : handleFocus}
                                    readOnly={disabled}
                                    className={disabled ? 'readonly' : null}
                                    key={'item_number_' + inspectionId}
                                    label={(inspection.involved_in_split_shipment || (isReinspection && !isCombinedInspection) || (isPartOfCombinedInspection && !isCombinedInspection)) ? <Fragment>
                                        <EditInspectionsContextMenu inspection={inspection} isCombinedInspection={isCombinedInspection} pointer deleteDisabled={deleteDisabled}>
                                            <div className='flex space-x-1 items-baseline'>
                                                <CombinedInspectionLabel inspection={inspection} type='short' />
                                                <SplitShipmentsMarker inspection={inspection} className='cursor-pointer' />

                                            </div>
                                        </EditInspectionsContextMenu>

                                    </Fragment> : null}

                                />
                            </span>
                        </EditInspectionsContextMenu>
                    </HasReadonlyAccess>
                </div>
                <div className={classnames(widths.itemName[isCombinedInspection ? 'c' : 't'], {})}>
                    <HasReadonlyAccess type='inspection' actionId='item_name' inspection={inspection}>
                        <EditInspectionsContextMenu inspection={inspection} isCombinedInspection={isCombinedInspection} unlockOnly pointer deleteDisabled={deleteDisabled}>
                            <span>
                                <QInput
                                    name='item_name'
                                    value={inspection.item_name}
                                    type='text'
                                    handleBlur={disabled ? null : handleBlur}
                                    handleFocus={disabled ? null : handleFocus}
                                    readOnly={disabled}
                                    className={disabled ? 'readonly' : null}
                                    placeholder={formatMessage({ id: 'inspections.item_name' })}
                                    key={'item_name_' + inspectionId}
                                />
                            </span>
                        </EditInspectionsContextMenu>
                    </HasReadonlyAccess>
                    {/* </UnlockInspection> */}
                </div>
                <Quantity
                    inspection={inspection}
                    disabled={isCombinedInspection ? disabledNotCombined : disabled}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    handleAction={handleAction}
                    showSampleQty={!isCombinedInspection}
                    hasInspectionTypes={hasInspectionTypes}
                    isCombined={isCombinedInspection}
                />
                <Fragment>{!isCombinedInspection &&
                    <div className={classnames(widths.checklist.t, {})}>
                        <HasReadonlyAccess type='inspection' actionId='checklist_id' inspection={inspection} showDialog={false}>
                            <ChecklistsSelector
                                className='editInspectionChecklist'
                                checklists={checklistsData}
                                disabled={disabled}
                                isFluid={true}
                                handleChange={(evt, item): void => {
                                    if (checklistValue !== item.value) {
                                        handleBlur(null, { name: item.name, value: item.value });
                                        setChecklistValue(item.value as string);
                                    }
                                }}
                                text={checklistText}
                                checklistName='checklist_id'
                                value={checklistValue}
                            />
                        </HasReadonlyAccess>

                        <HasReadonlyAccess type='inspection' actionId='checklist_id' inspection={inspection}>
                            <EditChecklistAction
                                inspection={inspection}
                                disabled={disabled}
                                isSaving={isSaving}
                            />
                        </HasReadonlyAccess>
                    </div>}</Fragment>
                <div className={classnames(widths.status[isCombinedInspection ? 'c' : 't'], {})}>
                    <SplitShipments context='inspection' isCombinedInspection={isCombinedInspection} inspection={inspection} useTrigger={true} />
                    <EditInspectionsContextMenu inspection={inspection} isCombinedInspection={isCombinedInspection} pointer deleteDisabled={deleteDisabled}>
                        <span><PlainStatus classNames='h-10 cursor-pointer' inspection={inspection} /></span>
                    </EditInspectionsContextMenu>
                </div>

                <div className={classnames(widths.delete.t, {})}>
                    {props.lineToggler}
                    <EditInspectionsContextMenu
                        inspection={inspection}
                        isCombinedInspection={isCombinedInspection}
                        deleteDisabled={deleteDisabled}
                        isSaving={isSaving} />
                </div>
            </div>
        </Fragment>
    );
};

export const getItemClassName = (itemName: string, hasInspectionTypes: boolean, isCombined: boolean): string => {
    return `item ${itemName} ${hasInspectionTypes ? 'inspectionType' : ''} ${isCombined ? 'combinedInspection' : ''}`;
};

export default React.memo(InfoLine, areEqual);
