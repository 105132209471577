import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './actionTypes';
import { InitialState } from './types';

const initialState = InitialState;

const reducer = handleActions({
    [types.APPROVAL_GET_CONCLUSIONS_SUCCESS]: (state = initialState, action: Action<types.APPROVAL_GET_CONCLUSIONS_SUCCESS>) => {
        return Object.assign({}, state, {
            conclusions: action.payload.conclusions
        });
    },
    [types.APPROVAL_GET_FLOW_REQUEST]: (state = initialState, action: Action<types.APPROVAL_GET_FLOW_REQUEST>) => {
        return Object.assign({}, state, {
            [action.payload.inspectionId]: {
                isFetching: action.payload.isFetching,
            },
        });
    },
    [types.APPROVAL_GET_FLOW_SUCCESS]: (state = initialState, action: Action<types.APPROVAL_GET_FLOW_SUCCESS>) => {
        return Object.assign({}, state, {
            [action.payload.inspectionId]: {
                isFetching: action.payload.isFetching,
                flow: action.payload.data,
            },
        });
    },
    [types.BULK_APPROVAL_GET_FLOW_REQUEST]: (state = initialState, action: Action<types.BULK_APPROVAL_GET_FLOW_REQUEST>) => {
        return Object.assign({}, state, {
            bulkApprovalFlow: {
                isFetching: action.payload.isFetching,
            },
        });
    },
    [types.BULK_APPROVAL_GET_FLOW_SUCCESS]: (state = initialState, action: Action<types.BULK_APPROVAL_GET_FLOW_SUCCESS>) => {
        return Object.assign({}, state, {
            bulkApprovalFlow: {
                isFetching: action.payload.isFetching,
                flow: action.payload.data,
            },
        });
    },
}, initialState);

export default reducer;
