import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { Inspection } from '../../types';

type IsEditPopupProps = {
    plannedUrl: string;
    inspection: Inspection;
    trigger: ReactElement<any>;
};

const IsEditPopup = (props: IsEditPopupProps): React.ReactElement => {
    const [show, setVisible] = useState(false);
    return (
        <Popup
            popperModifiers={[{ name: 'preventOverflow', options: { boundariesElement: 'window' } }]}
            trigger={props.trigger}
            open={show}
            onOpen={(): void => setVisible(true)}
            onClose={(): void => setVisible(false)}
            position='right center'
            on='click'
            basic
            size='large'
            hideOnScroll={true}
        >
            <p>{props.inspection.item_name}</p>
            <p><FormattedMessage id='inspections.this_inspection_is_still_planned_and_no_report_is_generated' /></p>
            <Button size='mini' as={Link} to={props.plannedUrl}><FormattedMessage id='inspections.edit_the_inspection' /></Button>
            <Button size='mini' onClick={(): void => setVisible(false)}><FormattedMessage id='globals.cancel' /></Button>
        </Popup>
    );
};

export default IsEditPopup;
