import React from 'react';
import { getPrettyDate } from '../../../base/utils';
import ConditionalInputField from './ConditionalInputField';
import DatePicker, { DatePickerProps } from './DatePicker';

type OwnProps = {
    isEditable: boolean;
    conditionalPlaceholder?: string;
    className?: string;
}

type Props = OwnProps & DatePickerProps;

const ConditionalDatePicker = (props: Props) => {
    const labelValue = props.date ? getPrettyDate(props.date) : null;
    return <ConditionalInputField
        className={props.className}
        labelValue={labelValue}
        isEditable={props.isEditable}
        placeholder={props.conditionalPlaceholder}
        inputField={<DatePicker
            {...props}
        />}
    />
}

export default ConditionalDatePicker