import { createSelector } from 'reselect';
import { FILTERS, FILTERS_MINUS_DATES } from '../../base/config';
import { AppState } from '../../base/types';
import { getLocationEntries } from '../../base/utils';
import history from '../../store/history';
import { CustomConclusion } from '../approval/types';



const getFilter = (state: AppState, filterName: string): any => {
    return state.app.filters && state.app.filters.active[filterName];
};
export const getFilterSelector: any = createSelector(
    [getFilter],
    (filter) => filter,
);

const getFilters = (state: AppState): any => {
    const filters = state.app.filters && state.app.filters.active;
    return filters;
};
export const getFiltersSelector = createSelector(
    [getFilters],
    (filters) => filters,
);

const getFilterData = (state: AppState, type: string): any => {
    return state.app.filters.data[type];
};
export const getFilterDataSelector = createSelector(
    [getFilterData],
    (data) => data,
);

const getFilterDataFetching = (state: AppState): boolean => {
    return state.app.filters.isFetching;
}

export const getFilterDataIsFetchingSelector = createSelector(
    [getFilterDataFetching],
    (isFetching) => isFetching,
);

const getFilterConclusions = (state: AppState): CustomConclusion[] => {
    return state.app.filters.conclusions;
}
export const getFilterConclusionsSelector = createSelector(
    [getFilterConclusions],
    (conclustions) => conclustions,
);

const getHasActiveFilters = (): boolean => {
    const currentFilters = getLocationEntries(history.location);
    let filterTypes = [];
    if (currentFilters) {
        filterTypes = Object.keys(currentFilters).filter((key) => {
            return FILTERS_MINUS_DATES.includes(key);
        });
    }
    return filterTypes.length > 0;
};
const getHasActiveFiltersIncludingDate = (): boolean => {
    const currentFilters = getLocationEntries(history.location);
    let filterTypes = [];
    if (currentFilters) {
        filterTypes = Object.keys(currentFilters).filter((key) => {
            return FILTERS.includes(key);
        });
    }
    return filterTypes.length > 0;
};
export const hasActiveFiltersSelector: any = createSelector(
    [getHasActiveFilters],
    (active) => active,
);
export const hasActiveFiltersSelectorIncludingDate: any = createSelector(
    [getHasActiveFiltersIncludingDate],
    (active) => active,
);

const getVisibleFilters = (state: AppState): boolean => {
    return state.app.filters.filtersVisible;
};

export const getFiltersVisibleSelector = createSelector(
    [getVisibleFilters],
    (visible) => visible,
);
