import React from 'react';
import { useIntl } from 'react-intl';
import { TextWithTranslation } from '../../../backend_api/models';
import { getLocaleLanguageString } from '../../utils';

export function useLocaleLanguageString() {
    const intl = useIntl();
    return getLocaleLanguageString(intl.locale);
}

export function TranslateTextWithTranslation(props: { twt: TextWithTranslation }) {
    const locale = useLocaleLanguageString()
    return <div className='flex items-center space-x-1 '>
        {/* TODO: colors: icon color should be based on Figma variable */}
        {/* <Icon name='translate' className='border rounded border-secondary text-secondary ' /> */}
        <div>{translateTextWithTranslation(props.twt, locale)}</div></div>;
}

export function translateTextWithTranslation(twt: TextWithTranslation, locale: string | null): string | null {
    if (!twt) {
        return null
    }
    if (!locale) {
        return twt.text
    }
    const fromTranslations = twt.translations[locale]
    if (fromTranslations) {
        return fromTranslations
    }
    return twt.text
}

