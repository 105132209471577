import { createSelector } from 'reselect';
import { AppState } from '../../base/types';
import { Checklist, ChecklistData } from './types';
import { ChecklistSummary } from '../../backend_api_2';

// Get checklist data for a given checklist
const checklistSelector = (state: AppState): ChecklistData => state.app.checklists.content;
export const getChecklistSelector = createSelector(
    [checklistSelector],
    (checklist) => checklist,
);


// Get (global) checklists
const fetchingSelector = (state: AppState): boolean => state.app.checklists.isFetching;
export const getFetching = createSelector(
    [fetchingSelector],
    (isFetching) => isFetching,
);

const checklistsSelector = (state: AppState): Checklist[] => state.app.checklists.checklists;
export const getChecklistsSelector = createSelector(
    [checklistsSelector],
    (checklists) => checklists,
);


// Get global checklists, which are only for inspections.
function inspectionChecklistsFetchingSelector(state: AppState): boolean {
    return state.app.checklists.isFetchingInspectionChecklists
}

export const getInspectionChecklistsFetchingSelector = createSelector(
    [inspectionChecklistsFetchingSelector],
    (isFetching) => isFetching,
);

function inspectionChecklistsSelector(state: AppState): Checklist[] {
    if (state.app.checklists) {
        return state.app.checklists.inspectionChecklists || []
    }
    return [];
}

export const getInspectionChecklistsSelector = createSelector(
    [inspectionChecklistsSelector],
    (checklists) => checklists,
);


// get global checklists, which are only for audits.
function auditChecklistsFetchingSelector(state: AppState): boolean {
    return state.app.checklists.isFetchingAuditChecklists;
}

export const getAuditChecklistsFetchingSelector = createSelector(
    [auditChecklistsFetchingSelector],
    (isFetching) => isFetching,
);

function auditChecklistsSelector(state: AppState): ChecklistSummary[] {
    if (state.app.checklists) {
        return state.app.checklists.auditChecklists || []
    }
    return [];
}

export const getAuditChecklistsSelector = createSelector(
    [auditChecklistsSelector],
    (checklists) => checklists,
);


// A checklist selector - e.g. used in inspections module
const checklistsSelectorSelector = (state: AppState): Checklist[] => state.app.checklists.selectors;
export const getChecklistsSelectorSelector = createSelector(
    [checklistsSelectorSelector],
    (checklists) => checklists,
);

const checklistsSelectorByIdSelector = (state: AppState, inspectionId: string): Checklist[] => {
    if (state.app.checklists.selectorsById[inspectionId]) {
        return state.app.checklists.selectorsById[inspectionId];
    }
    return state.app.checklists.selectors;
};

export const getChecklistsSelectorById = createSelector(
    [checklistsSelectorByIdSelector],
    (checklists) => checklists,
);
