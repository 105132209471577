import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Segment } from 'semantic-ui-react';

interface SearchHitsProps {
    query: string;
    clear();
}
class SearchHits extends Component<SearchHitsProps & WrappedComponentProps, any> {
    public render(): React.ReactElement {
        return (
            <Segment className='hits' secondary={true}>
                <div className='query'>
                    <div>
                        <FormattedMessage id='search.search_for'
                            values={{
                                query: (
                                    <b>{this.props.query}</b>
                                ),
                            }} />
                    </div>
                </div>
                <div className='clear'>
                    <span className='link' onClick={(): void => this.props.clear()}><FormattedMessage id='search.clear' /></span>
                </div>
            </Segment>
        );
    }
}
export default injectIntl(SearchHits);