import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Popup, Segment } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs';


const LineToggler = (props: {
    data: { open: boolean; text?: string };
}): React.ReactElement => {
    return (<>
        {props.data.text}
        <>{
            props.data.open ?
                (<Icon name='chevron up' />)
                :
                (<Icon name='chevron down' />)
        }
        </>
    </>);
};

export const LineTogglerSmall = (props: {
    label?: string | React.ReactElement;
    data: { open: boolean };
    id?: string;
    toggle?(show: boolean, id: string);
    mark?: boolean;
    markText?: string[];
}): React.ReactElement => {
    const text2 = [];
    if (props.markText) {
        props.markText.forEach((text, i) => {
            const p = <p key={'p_' + i}>{text}</p>;
            text2.push(p);
        });
    }
    const el = (<span className='linetogglersmall'
        onClick={(): void => toggle()}
    >{props.label && <span>{props.label}</span>}{props.data.open ?
        (<Button circular icon='chevron up'></Button>)
        :
        (props.mark ? (<Popup
            trigger={<Button circular inverted color='green' icon='chevron down'></Button>}
        >{text2}</Popup>) : (<Button circular icon='chevron down'></Button>))
        }
    </span>);
    const toggle = (): void => {
        props.toggle && props.toggle(!props.data.open, props.id);
    };
    return el;
};

export const LineTogglerSimple = ((props: {
    label?: string;
    toggle?(show: boolean, id: string);
    id?: string;
    open: boolean;
    children?: any;
}): React.ReactElement => {
    const el = (<div className='flex w-full justify-between items-center cursor-pointer'
        onClick={(): void => toggle()}
    >
                {props.children && <span className=''>{props.children}</span>}

        {props.label && <span>{props.label}</span>}{props.open ?
            (<Icon name='chevron up' className='u-flex-align-center' />)
            :
            (<Icon name='chevron down' className='u-flex-align-center' />)
        }
    </div>);
    const toggle = (): void => {
        props.toggle && props.toggle(!props.open, props.id);
    };
    return el;
});

export const IsSavingInfo = (props: {
    trigger: any;
    open?: boolean;
    on?: any;
    position?: any;
}): React.ReactElement => {
    return (
        <Popup
            on={props.on}
            open={props.open}
            trigger={props.trigger}
            position={props.position}
        >
            <h4><Icon name='warning circle' color='red' /><FormattedMessage id='globals.please_wait' /></h4>
            <p><FormattedMessage id='globals.order_data_is_being_saved_right_now_please_wait_a_moment_before_leaving_this_page' /></p>
        </Popup>
    );
};

export const InfoIcon = (props:
    {
        text?: string;
        name?: string;
        hoverText?: string | React.ReactElement;
        compact?: boolean;
        basic?: boolean;
        inline?: boolean;
        color?: SemanticCOLORS;
        warn?: boolean;
        small?: boolean;
        noMargins?: boolean;
        iconOnly?: boolean;
    },
): React.ReactElement => {
    const iconName = props.warn ? 'warning sign' : 'info circle';
    let color = props.color || 'blue';
    const cn = classnames({
        infoIcon: true,
        compact: props.compact,
        basic: props.basic,
        inline: props.inline,
        small: props.small,
        noMargins: props.noMargins,
    });
    if (props.warn) {
        color = 'red';
    }
    if (props.iconOnly) {
        return <Icon name={iconName} color={color} />;
    }
    let ret;
    if (props.basic) {
        ret = <div className={cn}><Icon name={iconName} color={color} />{props.text !== null && <span>{props.text}</span>}</div>;
    } else {
        ret = <Segment basic compact className={cn}><Icon name={iconName} color={color} />{props.text !== null && <span>{props.text}</span>}</Segment>;
    }
    if (props.hoverText) {
        return <Popup
            trigger={ret}>
            <Popup.Content>{props.hoverText}</Popup.Content>
        </Popup>;
    }
    return ret;
};

type SpacerProps = {
    children?: ReactNode;
    space?: 'left' | 'right' | 'top' | 'bottom';
};
export const Spacer = (props: SpacerProps): React.ReactElement => {
    const cn = classnames({
        spacer: true,
        [props.space]: props.space,
    });
    return <span className={cn}>{props.children}</span>;
};
export const Space = (): React.ReactElement => {
    return <span className='space'>{''}</span>;
};

type HideProps = {
    text?: string;
    onClick(...args): void;
};
export const Hide = (props: HideProps): React.ReactElement => {
    const { onClick, text = 'x' } = props;
    return <span className='hideElement' onClick={onClick}>{text}</span>;
}

export default { IsSavingInfo, LineToggler, LineTogglerSmall, LineTogglerSimple, InfoIcon, Spacer, Space, Hide };
