
import { PublishedComponent } from '@figma/rest-api-spec';
import React, { useEffect, useState } from 'react';
import { useDragAndDrop } from 'react-aria-components';
import { Route, Switch } from 'react-router';
import { Modal } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { IconMappings } from '../../../../figma/figma-icon-mappings';
import figmaComponents from '../../../../figma/figma_components.json';
import figmaBgColors from '../../../../figma/files/figma-bg-colors.json';
import figmaBorderColors from '../../../../figma/files/figma-border-colors.json';
import figmaTextColors from '../../../../figma/files/figma-text-colors.json';
import { BasicUser } from '../../../backend_api/models';
import Image from '../../../base/components/Image';
import Loader from '../../../base/components/Loader';
import TabsMenu from '../../../base/components/TabsMenu';
import Button from '../../../base/components/basic/Button';
import DatePicker3 from '../../../base/components/basic/DatePicker3';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Input from '../../../base/components/basic/Input';
import Label from '../../../base/components/basic/Label';
import SpinnerIcon from '../../../base/components/basic/SpinnerIcon';
import Form from '../../../base/components/basic/form/Form';
import FormField from '../../../base/components/basic/form/FormField';
import { Sizes } from '../../../base/components/types';
import { FieldActionSize, FieldActionType, TextArea, TextAreaHeight } from '../../../base/ui/components/TextArea';
import QButton, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import Chip from '../../../base/ui/components/chips/Chip';
import ContextMenu from '../../../base/ui/components/contextMenu/ContextMenu';
import { Field } from '../../../base/ui/components/fields/Field';
import Header from '../../../base/ui/components/headers/Header';
import Icon from '../../../base/ui/components/icons/Icon';
import { Icons } from '../../../base/ui/components/icons/iconTypes';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import { ArrowTypeEnum, ListItem, ListItems, MainActionEnum, SecondaryActionEnum, SelectableType } from '../../../base/ui/components/listitems/ListItem';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import StatusIcon from '../../../base/ui/components/status/StatusIcon';
import { showSnackbar } from '../../../base/ui/uiSlice';
import { useAppDispatch } from '../../../store';
import ComplianceDocumentStatusPie from '../../compliance/components/ComplianceDocumentStatusPie';
import { ListView, TextItem } from '../../globals/components';
import PageContainer from '../../globals/components/PageContainer';
import { ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import QInput from '../../inspections/components/edit/formelements/QInput';
import ContentSection from '../../pageLayouts/components/ContentSection';
import FETest from './FETest';
import './test.css';

/* console.log('figmaBorderColors ', figmaBorderColors)
console.log('figmaBorderColors 2', require('../../../../figma/figma-border-colors.json')) */

const FrontendElements = (): React.ReactElement => {
    const [showModal, setShowModal] = useState(false);
    const [figmaColors, setFigmaColors] = useState([]);
    const dispatch = useAppDispatch();
    const user: BasicUser = { firstname: 'Joe', lastname: 'Montana', email: 'goat@jets.com', disabled: false, last_activity: null, supplier_user: false, id: 'joeId' };
    /* const setupFigmaColors = () => {
        const arr = [];
        Object.entries(figmaTokens.colors).map((ft, i) => {
            const base = ft[0];
            Object.entries(ft[1]).map((ftV) => {
                const s = base + '-' + ftV[0];
                const cl = 'bg-' + s.trim();
                arr.push(cl)
            })
        })
        setFigmaColors(arr);
    } */

    const [textAreaValue1, setTextAreaValue1] = React.useState('Some random text');
    const [textAreaValue2, setTextAreaValue2] = React.useState('');
    const [textAreaValue3, setTextAreaValue3] = React.useState('Some random text');
    const [textAreaValue4, setTextAreaValue4] = React.useState('Some random text');

    const getComponents = () => {
        return <div>{figmaComponents.meta.component_sets.map((fc: PublishedComponent) => {
            return <div>
                <div>{fc.name}</div>
                <div><Image url={fc.thumbnail_url} /></div>
            </div>
        })}</div>;
    }
    const { dragAndDropHooks } = useDragAndDrop({
        //
    });
    const figmaTest = <ContentSection
        headerText={'Figma test'}
        content={<div className='w-1/2 p-24 flex flex-col'>
            <div className='bg-base-test w-48 h-48 items-center p-8 justify-center flex text-lg text-white rounded shadow-xl'>Hello</div>
        </div>} />

    const fields = <ContentSection
        headerText={'New design elements'}
        content={<div className='w-1/2 gap-y-8 flex flex-col'>

            <Chip label='Chip' removable={true} />
            <Chip label='Chip' removable={true} />
            <Chip label='Chip' removable={true} />

            <Field
                placeholder='First name'
            />
            <Field
                placeholder='Last name'
            />
            <div className='flex gap-2'>
                <Field
                    labelLeft='From'
                />
                <Field
                    labelLeft='To'
                />
            </div>
            <div className='flex gap-2 items-end'>

                <Field
                    label={{ text: 'Some icons' }}
                    iconLeft='callout_assistant'
                    placeholder='Enter AI'
                />
                <Field
                    iconRight='object_product'
                />
            </div>
        </div>} />

    const newDesignElements = <ContentSection
        headerText={'New design elements'}
        content={<div className='w-full gap-y-8 flex flex-col'>
            {/* getComponents() */}
            <div>
                <div className='flex items-center space-x-2'>
                    <div>Context menu</div>
                    <ContextMenu
                        actions={[
                            { icon: 'callout_assistant', label: 'foo' },
                            { label: 'Label right', labelRight: true, icon: 'object_number' },
                        ]}
                        overflowItems={[{ label: 'Item' }]} />
                    {/* <ContextMenu close={() => null} open={true} >
                <div className='pb-4'>
                    <h5>Context menu</h5>

                    <div>Option 1</div>
                    <div>Option 3</div>
                    <div>Option 5</div>
                </div>
            </ContextMenu> */}
                </div>

                <Header
                    pageTitle='Organic Running Jacket, Orange '
                    reference='9093781'
                    entity={'Product'}
                    contextAction={<QButton size={Sizes.Small}>Stuff</QButton>}
                    secondaryAction={<QButton size={Sizes.Small}>Stuff</QButton>} />

                <h5>List item</h5>
                <div className='gap-y-2 flex flex-col w-1/2'>
                    <ListItems className={'border'}>
                        <ListItem >
                            Plain list item
                        </ListItem>
                        <ListItem mainAction={{ type: MainActionEnum.Add, onMainActionClick: () => alert('ma') }}>
                            List item with main action Add
                        </ListItem>
                        <ListItem secondaryAction={{ type: SecondaryActionEnum.Edit }}>
                            List item with secondary action Edit
                        </ListItem>
                        <ListItem secondaryAction={{ type: SecondaryActionEnum.Delete }}>
                            List item with secondary action Delete
                        </ListItem>
                        <ListItem inlineButton={<QButton size={Sizes.Small}>IB</QButton>}>
                            List item with inline button
                        </ListItem>
                        <ListItem arrow={{ type: ArrowTypeEnum.External }}>
                            List item with arrow type external
                        </ListItem>
                        <ListItem arrow={{ type: ArrowTypeEnum.Open }}>
                            List item with arrow type open
                        </ListItem>
                        <ListItem info={{ text: 'Info text' }}>
                            List item with info text
                        </ListItem>
                    </ListItems>
                    <ListItems className={'border'}>
                        <ListItem icon={'callout_info'}>
                            List item with icon
                        </ListItem>
                        <ListItem icon={'cloud_synced'}>
                            List item with icon
                        </ListItem>
                        <ListItem thumbnail='https://dev.dev.amz.qarma.one/q/objects/images/9c6aac36-5c6d-4243-af49-fb1c40e60b98/Wood%20Chair.jpg'>
                            List item with icon
                        </ListItem>
                        <ListItem thumbnail='https://app.qarmainspect.com/q/objects/images/504266c0-b0fd-179a-8d6c-2589a47ffff4/diane%20stool.png'>
                            List item with icon
                        </ListItem>
                        <ListItem icon={'object_corrective-action'} meta={{ metaAbove: { firstItem: 'Meta above', } }}>
                            List item
                        </ListItem>
                        <ListItem icon={'callout_tap-here'} meta={{ metaBelow: { firstItem: 'Meta below', } }}>
                            List item
                        </ListItem>
                        <ListItem icon={'callout_tap-here'} meta={{ metaAbove: { firstItem: 'Meta above' }, metaBelow: { firstItem: 'Meta below', } }}>
                            List item
                        </ListItem>
                        <ListItem
                            meta={{ metaAbove: { firstItem: 'Chemical Testing' } }}
                            icon='object_file' value='Organic Running Jacket'
                            arrow={{ type: ArrowTypeEnum.Open }}
                        >
                            BSCI Report
                        </ListItem>
                    </ListItems>
                    <ListItems className={'border'}>
                        <ListItem slim>
                            Plain list item (slim)
                        </ListItem>
                    </ListItems>
                    <ListItems className={'border'}>
                        <ListItem icon='cloud_download' meta={{ metaBelow: { firstItem: '2nd April 2024' } }}>
                            1st Quarter earnings report
                        </ListItem>
                        <ListItem
                            meta={{ metaAbove: { firstItem: 'Chemical Testing' } }}
                            // icon='object_file' value='Organic Running Jacket' 
                            selectable={{ type: SelectableType.Checkbox, id: '' }}
                        >
                            Selectable
                        </ListItem>
                        <ListItem icon={'user_group'}
                            arrow={{ type: ArrowTypeEnum.Open }}
                            meta={{ metaBelow: { firstItem: 'Senior analyst', secondItem: 'pbha@ibm.com' } }}
                        >
                            Peter B. Hansen-Andersen
                        </ListItem>
                        <ListItem
                            meta={{ metaAbove: { firstItem: 'Chemical Testing' } }}
                            icon='object_file' value='Organic Running Jacket'
                            arrow={{ type: ArrowTypeEnum.Open }}
                        >
                            BSCI Report
                        </ListItem>
                    </ListItems>
                    <ListItems className={'border'}>
                        <ListItem slim>
                            Plain list item (slim)
                        </ListItem>
                        <ListItem slim>
                            Plain list item (slim)
                        </ListItem>
                        <ListItem slim icon='cloud_download' meta={{ metaBelow: { firstItem: '2nd April 2024' } }}>
                            1st Quarter earnings report
                        </ListItem>
                        <ListItem slim
                            meta={{ metaAbove: { firstItem: 'Chemical Testing' } }}
                            selectable={{ type: SelectableType.Checkbox, id: '', selectedColorClass: 'bg-red-500' }}
                        >
                            Selectable
                        </ListItem>
                        <ListItem slim
                            selectable={{ type: SelectableType.Checkbox, id: '', selectedColorClass: 'bg-yellow-500' }}
                        >
                            Selectable
                        </ListItem>
                        <ListItem slim
                            icon={'user_group'}
                            arrow={{ type: ArrowTypeEnum.Open }}
                            meta={{ metaBelow: { firstItem: 'Senior analyst', secondItem: 'pbha@ibm.com' } }}
                        >
                            Peter B. Hansen-Andersen
                        </ListItem>
                        <ListItem
                            slim
                            meta={{ metaAbove: { firstItem: 'Chemical Testing' } }}
                            icon='object_file' value='Organic Running Jacket'
                            arrow={{ type: ArrowTypeEnum.Open }}
                        >
                            BSCI Report
                        </ListItem>
                    </ListItems>
                    
                </div>
            </div>
            <div className='gap-y-2 flex flex-col'>
                <h5>Button</h5>
                <div className='flex gap-2 items-center'>
                    <QButton primary icon='cloud_synced' >Button icon</QButton>
                    <QButton primary icon='cloud_synced' size={Sizes.Small}>Button</QButton>
                    <QButton primary icon='cloud_synced' size={Sizes.XSmall}>Button</QButton>
                </div>
                <div className='flex gap-2 items-center border'>
                    <QButton icon='cloud_download'>Button</QButton>
                    <QButton size={Sizes.Small}>Button</QButton>
                    <QButton icon='callout_info' size={Sizes.XSmall}>Button</QButton>

                </div>
                <div className='flex gap-2 items-center border'>
                    <QButton buttonType={ButtonTypes.Ghost}>Ghost me</QButton>
                    <QButton icon='callout_tap-here' buttonType={ButtonTypes.Ghost}>Ghost me</QButton>
                </div>

            </div>
            <div className='gap-y-2 flex flex-col'>
                <h5>Chip</h5>
                <div className='flex gap-2 items-center'>
                    <Chip label='Chip' />
                    <Chip size={Sizes.Small} label='Chip' />
                    <Chip size={Sizes.XSmall} label='Chip' />
                </div>


            </div>
            <div className=''>
                <h5>Modal</h5>
                <QModal
                    width={500}
                    header='Reject document'
                    content={<>
                        <div className='pb-1 text-sm text-input-text-label'>* Reason for rejection</div>
                        {/* <TextArea>

                        </TextArea>  */}
                    </>
                    }
                    actionItems={[
                        { type: ActionTypes.Action, text: 'Reject document', buttonType: ButtonTypes.Danger, event: null },
                        { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => null },
                    ]}
                    trigger={<QButton size={Sizes.Small} danger>Reject</QButton>}>

                </QModal>
            </div>
            <div className=''>
                <h5>Key value pair</h5>
                <KeyValuePair keyItem={{ name: 'Approvers', icon: 'user_approver' }} value={{ value: 'Esben Olsen, Pilar Ozoon and Peter Kpial' }} />
            </div>
            <div className=''>
                <h5>Snackbar</h5>
                <QButton onPress={() => dispatch(showSnackbar({ content: 'The audit was updated' }))}>Show snackbar</QButton>
            </div>
            <div className=''>
                <h5>Status</h5>
                <div className='flex flex-col gap-8'>
                    <div className='flex gap-2'>
                        <StatusIcon status={'approved'} />
                        <StatusIcon status={'rejected'} />
                        <StatusIcon status={'pending'} />
                        <StatusIcon status={'empty'} />
                    </div>
                    <div>
                        <ComplianceDocumentStatusPie productName={'Title'} statistics={{ not_ready_for_approval: 0, approved: 2, ignored: 0, ready_for_approval: 1, rejected: 2 }} />
                    </div>
                </div>
            </div>
        </div>}
    />

    const contentSection1 = <ContentSection
        headerText={'Status icons'}
        content={<div className='flex gap-16'>
            <div className='flex gap-y-4 flex-col'>
                <div className='gap-2 flex'>
                    <StatusIcon status='approved' />
                    <StatusIcon status='approved' inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='rejected' />
                    <StatusIcon status='rejected' inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='pending' />
                    <StatusIcon status='pending' inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='empty' />
                    <StatusIcon status='empty' inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='not-submitted-yet' />
                    <StatusIcon status='not-submitted-yet' inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='skipped' />
                    <StatusIcon status='skipped' inverted />
                </div>
            </div>
            <div className='flex gap-y-4 flex-col'>
                <div className='gap-2 flex'>
                    <StatusIcon status='approved' large />
                    <StatusIcon status='approved' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='rejected' large />
                    <StatusIcon status='rejected' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='pending' large />
                    <StatusIcon status='pending' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='empty' large />
                    <StatusIcon status='empty' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='not-submitted-yet' large />
                    <StatusIcon status='not-submitted-yet' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='skipped' />
                    <StatusIcon status='skipped' large inverted />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='approved' large />
                    <StatusIcon status='approved' large inverted />
                </div>
            </div>

            <div className='flex gap-y-4 flex-col'>
                <div className='gap-2 flex'>
                    <StatusIcon status='approved' large />
                    <StatusIcon status='approved' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='rejected' large />
                    <StatusIcon status='rejected' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='pending' large />
                    <StatusIcon status='pending' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='empty' large />
                    <StatusIcon status='empty' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='not-submitted-yet' large />
                    <StatusIcon status='not-submitted-yet' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='skipped' />
                    <StatusIcon status='skipped' large inverted border />
                </div>
                <div className='gap-2 flex'>
                    <StatusIcon status='approved' large />
                    <StatusIcon status='approved' large inverted border />
                </div>
            </div>

        </div>}
    />
    const contentSection2 = <ContentSection
        className=''
        headerText={'Frontend components '}
        content={<div>
            <div className='sm:w-1/3 pb-8'>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
            </div>
        </div>}
    />

    const contentSection3 = <ContentSection
        headerText={'List item'}
        content={<div><div className='py-8 space-y-8'>
            <ListItems className={'border rounded border-b-0'}>

                <ListItem
                    icon={'user_watcher'}
                    selectable={{ type: SelectableType.Checkbox, id: 's_1', onChange: () => null, selectedColor: '#ff9933', selected: true }}
                    meta={
                        { metaAbove: { firstItem: 'Meta above', }, metaBelow: { firstItem: 'Meta below' } }
                    }
                    mainAction={{ type: MainActionEnum.Add, onMainActionClick: () => null }}
                    secondaryAction={{ type: SecondaryActionEnum.Edit, onSecondaryActionClick: () => null }}
                    arrow={{ type: ArrowTypeEnum.External, onArrowClick: () => null }}
                    info={{ text: 'Info text' }}
                >
                    No watchers
                </ListItem>
            </ListItems>
            <ListItems className={'border rounded border-b-0'}>
                <ListItem
                    icon={'user_watcher'}
                    meta={{ metaAbove: { firstItem: 'Watchers' } }}
                >
                    No watchers
                </ListItem>
                <ListItem
                    icon={'user_user'}
                    meta={{ metaAbove: { firstItem: 'Approvers' } }}
                >
                    No approvers
                </ListItem>
                <ListItem
                    icon={'user_production-unit'}
                    meta={{ metaAbove: { firstItem: 'Production unit' } }}
                >
                    Dongguang Textiles ltd.
                </ListItem>
            </ListItems>
            <ListItems className={'border rounded border-b-0'}>
                <ListItem
                    selectable={{ type: SelectableType.Checkbox, id: 'radio_id', selectedColor: 'green' }}
                >
                    Yes
                </ListItem>
                <ListItem
                    icon={'user_user'}
                    meta={{ metaAbove: { firstItem: 'Approvers' } }}
                >
                    No approvers
                </ListItem>
                <ListItem
                    selectable={{ type: SelectableType.Checkbox, id: 'radio_id', }}
                >
                    No
                </ListItem>
                <ListItem
                    info={{ text: 'Show me' }}
                    arrow={{ type: ArrowTypeEnum.External }}
                    selectable={{ type: SelectableType.Radio, id: 'radio_id', }}
                >
                    Radio?
                </ListItem>
            </ListItems>
            <ListItems className={'border rounded border-b-0'}>
                <ListItem
                    mainAction={{ type: MainActionEnum.Remove, onMainActionClick: () => alert('main action') }}
                    icon={'user_watcher'}
                    meta={{ metaBelow: { firstItem: 'Chief buyer', icon: 'user_user', secondItem: '004533456712' }, metaAbove: { firstItem: 'Watchers' } }}
                >
                    Hannah Johnson, Peter Smith,
                    Ola Johnson, Mads Smith,
                    Fred Johnson, Peter Petersen,
                    John Johnson, Peter Smithson,
                    Hannah Johnson, Peter Smith,
                    Ola Johnson, Mads Smith,
                    FredHannah Johnson, Peter Petersen,
                    John Johnson, Peter Smithson
                </ListItem>
                <ListItem
                    // selectable={{ type: SelectableType.Radio, onChange: () => null, id: 'foo' }}
                    mainAction={{ type: MainActionEnum.Remove, onMainActionClick: () => alert('main action') }}
                    secondaryAction={{ type: SecondaryActionEnum.Edit, onSecondaryActionClick: () => alert('click'), className: 'text-green-500' }}
                    icon={'user_approver'}
                    meta={{ metaAbove: { firstItem: 'Approvers' } }}
                >
                    Ole Olsen</ListItem>
                <ListItem
                    secondaryAction={{ type: SecondaryActionEnum.Edit, onSecondaryActionClick: () => alert('click'), className: 'text-green-500' }}
                    icon={'user_approver'}
                    meta={{ metaAbove: { firstItem: 'Approvers' } }}
                >
                    Smilla Snow
                </ListItem>
                <ListItem
                    // secondaryAction={{ type: SecondaryActionEnum.Edit, onSecondaryActionClick: () => alert('click'), className: 'text-green-500' }}
                    icon={'object_product'}
                    meta={{ metaBelow: { firstItem: 'Chief buyer', icon: 'user_user', secondItem: '004533456712' } }}
                >
                    Dexter Dolittle
                </ListItem>

            </ListItems>
            <ListItems className={'border rounded border-b-0'}>
                <ListItem>Hannah Smith</ListItem>
                <ListItem>Peter Smith</ListItem>
                <ListItem icon={'callout_assistant'}>Ola Smith</ListItem>
            </ListItems>
            <ListItems className={'border rounded border-b-0'}>
                <ListItem slim>Hannah Smith</ListItem>
                <ListItem slim>Peter Smith</ListItem>
                <ListItem slim icon={'callout_assistant'}>Ola Smith</ListItem>
            </ListItems>
        </div>

        </div >}
    />

    const tabs = <ContentSection className='' content={<div className='bg-surface-plain sm:p-8 py-8'>
        <div className='sm:w-1/3 pb-4'>
            <TextItem label='Text item 1' text={'Text item text'} />
            <TextItem label='Text item 2' text={'Text item text 2'} />
            <TextItem column label='Text item 3' text={'Text item text 3'} className='pt-4' />
        </div>
        <div className='sm:w-1/3 pb-8 space-y-4' >
            <h3>Input</h3>
            <Form>
                <FormField>
                    <label>Text input</label>
                    <Input type='text' value={'Some text'} />
                </FormField>
                <FormField>
                    <label>Number input</label>
                    <QInput type='number' name='foo' value={10} />
                </FormField>
                <FormField>
                    <label>Input with icon</label>
                    <Input type='text' name='foo' value={''} icon='favorite' iconClass='text-red-500' placeholder='Favourite' />
                </FormField>
                <FormField>
                    <label>Input with icon</label>
                    <Input type='text' name='foo' value={''} icon='accessible_forward' iconPosition='right' />
                </FormField>
                <FormField>
                    <label>Input with label</label>
                    <Input type='text' name='foo' value={''} label={'Capacity'} />
                </FormField>
                <FormField>
                    <label>Input with label</label>
                    <Input type='text' name='foo' value={''} labelPosition='right' label={'After long text'} />
                </FormField>
            </Form>
            <DatePicker3 handleChange={() => null} />

        </div>

        <div className='sm:w-1/3 pb-8 space-y-4' >
            <h3>Text Area</h3>
            <Form>
                <FormField>
                    <TextArea
                        value={textAreaValue1}
                        height={TextAreaHeight.DEFAULT_FIXED}
                        label="Basic with fixed height"
                        onChange={setTextAreaValue1}
                    />
                </FormField>
                <FormField>
                    <TextArea
                        value={textAreaValue2}
                        height={TextAreaHeight.SINGLE_LINE_AUTO_EXPAND}
                        label="Single line with auto expand height"
                        onChange={setTextAreaValue2}
                    />
                </FormField>
                <FormField>
                    <TextArea
                        value={textAreaValue3}
                        height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                        label="Default with auto expand height"
                        onChange={setTextAreaValue3}

                    />
                </FormField>
                <FormField>
                    <TextArea
                        value={textAreaValue4}
                        height={TextAreaHeight.DEFAULT_AUTO_EXPAND}
                        label="Text area with label, label icon, action and action icon"
                        labelIcon='user_user'
                        errorMessage='Test error'
                        onChange={setTextAreaValue4}
                        action={() => { }}
                        actionIconRight="operation_add"
                        actionIconLeft="operation_remove"
                        actionLabel='Some action'
                        actionSize={FieldActionSize.BASE}
                        actionType={FieldActionType.DEFAULT}
                    />
                </FormField>
            </Form>
            <DatePicker3 handleChange={() => null} />

        </div>
        {/* <div className='flex space-x-2'>
                <Input type='text' name='foo' value={''} icon='accessible_forward' iconPosition='right' />
                <Input type='text' name='foo' value={''} label={'Capacity'} />
                <Input type='text' name='foo' value={''} labelPosition='right' label={'After long text'} />
                <DatePicker3 handleChange={() => null} />
            </div> */}
        <div className='sm:w-1/3 pb-2 px-2 mt-4 mb-8 pt-2 border shadow-lg bg-pink-100'>
            <ul className='py-2'>
                <li>Option 1</li>
                <li>Option 2</li>
                <li>Option 3</li>
            </ul>
        </div>
        <div className='space-y-2 pb-4'>
            <Button primary large>Okay, do it</Button>
            <Button primary>Okay, do it</Button>
            <Button primary small>Okay, do it</Button>
        </div>
        <div className='space-y-2 pb-4'>
            <Button secondary>A button</Button>
            <Button alert className='items-center flex'>Stop<Icon className='ml-1 text-lg leading-none items-center' name='operation_get-help' /></Button>
            <Button>Just plain</Button>

        </div>
        <div className='space-y-2 pb-4'>
            <Label large>Label</Label>
            <Label>Label</Label>
            <Label small>Label</Label>
        </div>
        <div className='pb-4 flex space-x-2'>
            <SpinnerIcon className='text-brand' />
            <div>Spinner icon for buttons and inline use</div>

        </div>
        <Loader active={true}>
            <div className='flex flex-col w-full items-center justify-center'>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
                <div className=''>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff</div>
            </div>
        </Loader>

        <div className='pb-8 space-y-4' >
            {/* <Widgets.UserInitials showInfo user={user} />
                <Widgets.UserInitialsText user={user} />
                <Widgets.UserLine user={user} /> */}

            <div><code>{JSON.stringify(user)}</code></div>
        </div>

        <TabsMenu
            path='foo'
            active='3'
            items={[
                {
                    labelId: 'Planned',
                    id: '1',
                },
                {
                    labelId: 'In progress',
                    id: '2',
                },
                {
                    labelId: 'Done',
                    id: '3',
                }
            ]} />
    </div>} headerText={'Tabs'} />

    const listviewHeaderItems = [
        { label: 'Header 1', key: 'h1' },
        { label: 'Header 2', key: 'h2' },
        { label: 'Header 3', key: 'h3' },
    ];
    const listView = <ListView>
        <ListViewHeader items={listviewHeaderItems} />
        <ListViewLines>
            <ListViewLine>
                <ListViewItem>Item 1</ListViewItem>
                <ListViewItem>Item 2</ListViewItem>
                <ListViewItem>Item 3</ListViewItem>
            </ListViewLine>
            <ListViewLine>
                <ListViewItem>Item 1</ListViewItem>
                <ListViewItem>Item 2</ListViewItem>
                <ListViewItem>Item 3</ListViewItem>
            </ListViewLine>
            <ListViewLine>
                <ListViewItem>Item 1</ListViewItem>
                <ListViewItem>Item 2</ListViewItem>
                <ListViewItem>Item 3</ListViewItem>
            </ListViewLine>
        </ListViewLines>
    </ListView>;

    const modal = <ContentSection headerText={'Modal'} content={<Modal
        open={showModal}
        closeOnEscape
        closeIcon
        trigger={<span><Button primary>Show modal</Button></span>}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
    >
        <Modal.Header>A modal</Modal.Header>
        <Modal.Content>Modal stuff</Modal.Content>
        <Modal.Actions className='flex justify-end'>
            <Button primary onClick={() => setShowModal(false)}>Ok</Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>

        </Modal.Actions>
    </Modal>} />

    const deleteConfirm = <ContentSection
        headerText='Delete confirms'
        content={<div className='flex'>
            <DeleteConfirm
                deleteFunction={() => null}
                trigger={<Button small secondary>Delete</Button>}
            />
            <DeleteConfirm
                deleteFunction={() => null}
                alert
                alertHeader={'Really delete?'}
                alertText={'Delete stuff'}
                type='alert'
                trigger={<Button small alert>Delete modal</Button>}
            />
        </div>} />
    const figmaColorsContent = () => {

        const getType = (header: string, file, prefix: string) => {
            return <div className='pt-2 pb-8'>
                <h4>{header}</h4>
                {file && Object.entries(file).map((fc, i) => {
                    const s = fc[0];
                    return <div className=' py-2 flex w-full' key={s}>
                        <div className='w-1/2 sm:w-1/4'>{s as any}</div>
                        <div className={twMerge('w-1/2 sm:w-72 flex p-2 rounded', prefix === 'border' && 'border-2', prefix === 'bg' && 'border', prefix + '-' + s as any)}>{prefix + '-' + s}</div></div>
                })}</div>
        }

        const borders = getType('Borders', figmaBorderColors, 'border');
        const bgs = getType('Backgrounds', figmaBgColors, 'bg');
        const texts = getType('Text colors', figmaTextColors, 'text');


        return <ContentSection headerText={'Figma colors'} content={<div className='w-full'>{[bgs, borders, texts]}</div>} />

    }
    const figmaIconsContent = () => {
        const icons = <div className='grid grid-cols-12 gap-8'>{Object.keys(IconMappings).map((im: Icons) => {
            return <div className='col-span-3'>
                <div className='flex gap-3 items-center'><Icon name={im} /><div className='text-secondary'>{im}</div></div>
            </div>
        })}</div>;
        return <ContentSection headerText={'Figma icons'} content={<div className='w-full'>{icons}</div>} />
    }
    useEffect(() => {

    }, [])
    return <>

        <PageContainer
            header={<div>Page container</div>}
            className=''
        >
            <Switch>
                <Route exact path='/developer/frontend-elements/test' component={FETest}></Route>
            </Switch>
            {fields}
            {figmaTest}
            {newDesignElements}
            {contentSection1}
            {contentSection2}
            {tabs}
            {modal}
            <h3>List view</h3>
            {listView}
            {deleteConfirm}
            <span>{figmaIconsContent()}</span>
            <span>{figmaColorsContent()}</span>
        </PageContainer>
    </>
};

export default FrontendElements;
