import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from './basic/Button';

type Props = {
    left?: string;
    top?: string;
    alwaysShow?: boolean;
    anchor?: string;
}
const ToTop = (props: Props): React.ReactElement => {
    const {alwaysShow = false, left, top, anchor} = props;
    const VISIBLE_AT_POSITION = 1400;
    const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
    useScrollPosition(({ currPos }) => {
        setScrollPosition(currPos)
    }, []);

    return (alwaysShow || (scrollPosition.y < -VISIBLE_AT_POSITION)) && <Button style={{ left: left || '65%', top: top || '80%' }} className={twMerge(!alwaysShow && 'fixed', 'z-1 bg-white')} onClick={() => anchor ? document.location.href = anchor : scrollTo(0, 0)}><Icon name='chevron up' color='green' /><FormattedMessage id='inspections.to_top' /></Button>;
}
export default ToTop;
