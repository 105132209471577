import React, { ReactChild } from 'react';

type BigLoaderProps = {
    active: boolean;
    children: ReactChild;
}

const BigLoader = (props: BigLoaderProps): React.ReactElement => {
    const visibility = props.active ? 'visible' : 'hidden';
    const text = props.children || 'Loading...';
    return (
        <div className={['loader', 'big', visibility].join(' ')}>
            {text}
        </div>);
};

export default BigLoader;
