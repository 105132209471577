import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './actionTypes';
import { ChecklistsState } from './models';

const initialState: ChecklistsState = {
    selectors: [],
    selectorsById: {},

    isFetching: false,
    checklists: [],

    isFetchingInspectionChecklists: false,
    inspectionChecklists: [],

    isFetchingAuditChecklists: false,
    auditChecklists: [],

    content: {
        description: '',
        headers: [],
        type: undefined,
        revision: 0,
        languages: [],
    },
};

const reducer = handleActions({
    // Get checklist content actions
    [types.GET_CHECKLIST_CONTENT]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLIST_CONTENT>) => {
        return (
            {
                ...state,
                isFetching: action.payload.isFetching,
                content: state.content
            }
        );
    },
    [types.GET_CHECKLIST_CONTENT_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLIST_CONTENT_SUCCESS>) => {
        return (
            {
                ...state,
                isFetching: action.payload.isFetching,
                content: {
                    ...state.content,
                    ...action.payload.data
                }
            }
        );
    },
    [types.DELETE_CHECKLIST_CHECKPOINT_FAILED]: (state: ChecklistsState = initialState, action: Action<types.DELETE_CHECKLIST_CHECKPOINT_FAILED>) => {
        return (
            {
                ...state,
                content: {
                    ...state.content,
                    deleteCheckpointFailed: action.payload.failed
                }
            }
        )
    },
    [types.DELETE_CHECKLIST_CHECKPOINT_SUCCESS_FORCE_STATE]: (state: ChecklistsState = initialState, action: Action<types.DELETE_CHECKLIST_CHECKPOINT_SUCCESS_FORCE_STATE>) => {
        return (
            {
                ...state,
                content: {
                    ...state.content,
                    deleteCheckpointSuccess: action.payload.success
                }
            }
        )
    },
    [types.GET_CHECKLIST_CONTENT_FAILURE]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLIST_CONTENT_FAILURE>) => {
        return (
            Object.assign({}, state, {
                isFetching: action.payload.isFetching,
                status: action.payload.status,
                message: action.payload.message,
            })
        );
    },
    // Get checklists actions
    [types.GET_CHECKLISTS]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS>) => {
        return (
            {
                ...state,
                isFetching: action.payload.isFetching,
            }
        );
    },
    [types.GET_CHECKLISTS_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS_SUCCESS>) => {
        return (
            Object.assign({}, state, {
                isFetching: action.payload.isFetching,
                checklists: action.payload.data,
            })
        );
    },
    [types.GET_AUDIT_CHECKLISTS_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.GET_AUDIT_CHECKLISTS_SUCCESS>) => {
        return (
            Object.assign({}, state, {
                isFetchingAuditChecklists: action.payload.isFetching,
                auditChecklists: action.payload.data,
            })
        );
    },
    [types.GET_INSPECTION_CHECKLISTS_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.GET_INSPECTION_CHECKLISTS_SUCCESS>) => {
        const options = [];
        action.payload.data.forEach((opt) => {
            const obj: any = opt;
            obj.text = opt.description;
            obj.value = opt.id;
            obj.key = opt.id;
            options.push(obj);
        });
        return (
            Object.assign({}, state, {
                isFetchingInspectionChecklists: action.payload.isFetching,
                inspectionChecklists: options,
            })
        );
    },
    [types.GET_CHECKLISTS_FAILURE]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS_FAILURE>) => {
        return (
            Object.assign({}, state, {
                isFetching: action.payload.isFetching,
                status: action.payload.status,
                message: action.payload.message,
            })
        );
    },
    // Selector actions below
    [types.GET_CHECKLISTS_SELECTOR]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS_SELECTOR>) => {
        return (
            {
                ...state,
                isFetching: action.payload.isFetching
            }
        );
    },
    [types.GET_CHECKLISTS_SELECTOR_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS_SELECTOR_SUCCESS>) => {
        if (action.payload.inspectionId) {
            const selectorsById = Object.assign({}, state.selectorsById);
            const inspectionId = action.payload.inspectionId;
            selectorsById[inspectionId] = action.payload.data;
            return {
                ...state,
                selectorsById,
            };
        } else {
            return {
                ...state,
                isFetching: action.payload.isFetching,
                selectors: action.payload.data,
            };
        }
    },
    [types.GET_CHECKLISTS_SELECTOR_FAILURE]: (state: ChecklistsState = initialState, action: Action<types.GET_CHECKLISTS_SELECTOR_FAILURE>) => {
        return (
            Object.assign({}, state, {
                isFetching: action.payload.isFetching,
                status: action.payload.status,
                message: action.payload.message,
            })
        );
    },
    [types.CREATE_CHECKLIST_SUCCESS]: (state: ChecklistsState = initialState, action: Action<types.CREATE_CHECKLIST_SUCCESS>) => {
        return (
            Object.assign({}, state, {
                isFetching: action.payload.isFetching,
            })
        );
    },
}, initialState);

export default reducer;