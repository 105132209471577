import FileSaver from 'file-saver';
import { createAction } from 'typesafe-actions';
import { SERVICES_PREFIX } from '../../base/config';
import messages from '../messages';
import * as types from './types';

const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;

const exportInspectionAsExcelRequest =
    createAction(types.EXPORT_INSPECTIONS_AS_EXCEL_REQUEST, (startDate: string, endDate: string, isSaving: boolean) => {
        return {
            type: types.EXPORT_INSPECTIONS_AS_EXCEL_REQUEST, payload: {
                startDate,
                endDate,
                isSaving,
            },
        };
    });
const exportInspectionAsExcelSuccess =
    createAction(types.EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS, (data: unknown, isSaving: boolean) => {
        return {
            type: types.EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS, payload: {
                data,
                isSaving,
            },
        };
    });
const exportInspectionAsExcelFailure =
    createAction(types.EXPORT_INSPECTIONS_AS_EXCEL_FAILURE, (status: number, error: any, isSaving: boolean) => {
        return {
            type: types.EXPORT_INSPECTIONS_AS_EXCEL_FAILURE, payload: {
                status,
                error,
                isSaving,
            },
        };
    });
export const exportExcelSavingState =
    createAction(types.EXPORT_EXCEL_SAVING_STATE, (isSaving: boolean) => {
        return {
            type: types.EXPORT_EXCEL_SAVING_STATE, payload: {
                isSaving,
            },
        };
    });

export const exportInspectionAsExcel = (startDate: string, endDate: string) => {
    return ((dispatch): void => {
        dispatch(exportInspectionAsExcelRequest(startDate, endDate, true));
        const oReq = new XMLHttpRequest(); // TODO: this should be replaced by using ky
        const url = SERVICES_PREFIX + '/inspections/export/excel?start_date=' + startDate + '&end_date=' + endDate;
        const token = sessionStorage.getItem('id_token') || sessionStorage.getItem('url_token');
        oReq.open('GET', url, true);
        oReq.responseType = 'blob';
        oReq.setRequestHeader('Authorization', 'Bearer: ' + token);
        oReq.send();
        oReq.onreadystatechange = (): void => {
            if (oReq.readyState === 4) {
                if (oReq.status === 200) {
                    const blob = oReq.response;
                    dispatch(exportInspectionAsExcelSuccess(oReq.response, false));
                    dispatch(sendStatusMessage(['status_message.data_was_succesfully_exported'], 5000));
                    FileSaver.saveAs(blob, 'inspections_' + startDate + '-' + endDate + '.xlsx');
                } else {
                    dispatch(exportInspectionAsExcelFailure(oReq.status, oReq.response, false));
                    dispatch(sendErrorMessage(['error_message.the_export_did_not_complete']));
                }
            }
        };
    });
};
