import React from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';
import { WeekviewUser } from '../../../../../../backend_api/models';
import users from '../../../../../users';

const UserLine = users.components.Widgets.UserLine;

interface InspectorProps {
    inspector: WeekviewUser;
    inspCnt: number;
}
export const WeekViewInspector = (props: InspectorProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const { inspector, inspCnt } = props;
    const trigger = <div className='flex w-full link noUnderline px-2 py-1 items-start space-x-1 text-sml justify-between'>
        <div className='flex gap-1' >
            <Icon name='user' />
            <UserLine user={inspector} />
        </div>
        {inspCnt > 0 && <div className='text-smx text-secondary'>{inspCnt}</div>}
    </div>;
    return (
        <div className='flex'>
            <Popup className='weekViewPopup' trigger={<div className='flex justify-between w-full'>{trigger}</div>}>
                <Popup.Header>
                    {inspector.firstname} {inspector.lastname}
                </Popup.Header>
                <Popup.Content>
                    {inspector.email}
                    <div className='info'>
                        <FormattedMessage
                            id='inspections.week_view.inspections_this_week'
                            values={{
                                inspCnt,
                                ordersLabel: (<FormattedPlural
                                    value={inspCnt}
                                    one={formatMessage({ id: 'inspections.week_view.inspection' })}
                                    other={formatMessage({ id: 'inspections.week_view.inspections' })}
                                />),
                            }}
                        />
                    </div>
                </Popup.Content></Popup>
        </div>
    );
};
