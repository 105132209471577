import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CorrectiveActionsStatuses } from '../../../../backend_api/models';
import { CreateCorrectiveActionRequest } from '../../../../backend_api/models/CreateCorrectiveActionRequest';
import { AppState } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import AuditCorrectiveActionStatusPie from '../../../audit/components/AuditCorrectiveActionStatusPie';
import CorrectiveActionCompactList from '../../../correctiveActions/components/CorrectiveActionCompactList';
import CorrectiveActionMediumList from '../../../correctiveActions/components/CorrectiveActionMediumList';
import CreateCorrectiveActionDialog from '../../../correctiveActions/components/CreateCorrectiveActionDialog';
import { createCorrectiveActionOnAudit, getCorrectivationsForAudit } from '../../../correctiveActions/correctiveActionsSlice';
import { auditActionsSelector, isUpdatingSelector } from '../../../correctiveActions/selectors/correctiveActionSelectors';
import ContentSection from '../../../pageLayouts/components/ContentSection';
import { HasFeature } from '../../../profile/containers';

type Props = {
    auditId: string;
    auditName: string;
}

const AuditCorrectiveActionList = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const correctiveActions = useSelector((state: AppState) => auditActionsSelector(state, props.auditId));
    const hasCorrectiveActions = correctiveActions.length > 0;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [caMap, setCaMap] = useState<CorrectiveActionsStatuses>(null);
    const isUpdating = useSelector(isUpdatingSelector);
    useEffect(() => {
        if (!isUpdating) {
            dispatch(getCorrectivationsForAudit(props.auditId))
        }
    }, [isUpdating])
    useEffect(() => {
        const m = {
            finding: correctiveActions.filter((ca) => ca.state === 'finding').length,
            closed: correctiveActions.filter((ca) => ca.state === 'closed').length,
            review: correctiveActions.filter((ca) => ca.state === 'review').length,
            in_progress: correctiveActions.filter((ca) => ca.state === 'in_progress').length,
        };
        setCaMap(m);
    }, [correctiveActions])
    const create = (ca: CreateCorrectiveActionRequest) => {
        ca.audit_links = [props.auditId]
        dispatch(createCorrectiveActionOnAudit({ 'list': [ca] }));
        dispatch(getCorrectivationsForAudit(props.auditId));
    }
    const expandedContent = <CorrectiveActionMediumList correctiveActions={correctiveActions} className='py-4' />;
    const caPie = caMap ? <AuditCorrectiveActionStatusPie correctiveActionStatus={caMap} w={25} h={25} showEmptyCorrectiveAction={false} auditId={props.auditId} /> : null;

    const content = <Fragment>
        <CorrectiveActionCompactList correctiveActions={correctiveActions} />
        <HasFeature feature='u_create_corrective_action'>
            <Button onClick={() => setOpenModal(true)} data-test-id="button-create-corrective-action"><FormattedMessage id='corrective_actions.create_button' /></Button>
        </HasFeature>
        <CreateCorrectiveActionDialog open={openModal} close={() => setOpenModal(false)} create={create} />
    </Fragment>

    return <ContentSection
        showExpanded={hasCorrectiveActions}
        headerTextExpanded={intl.formatMessage({ id: 'corrective_actions.medium_list.cas_on_audit' }, { name: props.auditName })}
        headerText={<div className='flex justify-between items-center w-full'>
            <div>{intl.formatMessage({ id: 'corrective_actions.section_header' })}</div>
            <div className='pr-2'>{caPie}</div>
        </div>}
        content={content}
        expandedContent={expandedContent}
    />
}

export default AuditCorrectiveActionList;