import qs from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Popup, Table } from 'semantic-ui-react';
import Link from '../../../../base/components/basic/Link';
import { objectHasKeys, urlParamsToArray } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { DefectCount } from '../../../globals/components/DefectsWidgets';
import { updateDefectsCategoryTableData } from '../../dashboardActions';

type DefectCategoriesProps = {
    defectTypes: any;
    colors: any;
};

const DefectCategories = (props: DefectCategoriesProps): ReactElement<any> => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [data, updateData] = useState(props.defectTypes);

    const popup = (type: string, data, trigger: React.ReactElement): React.ReactElement => {
        return <Popup
            trigger={<span>{trigger}</span>}>
            {type !== 'whole_lot' && <div>
                <div className='font-bold'><FormattedMessage id='inspections.defects.defect_type' values={{ type: intl.formatMessage({ id: 'inspections.' + type }) }} /></div>
                <div>{data.defect_type && data.defect_type.name}</div>
                <div><FormattedMessage id='inspections.items_affected' /> {data[type]}</div>
            </div>
            }
            {type === 'whole_lot' &&
                <div>
                    <div className='font-bold'>General defects</div>
                    <div>{data.defect_type && data.defect_type.name}</div>
                    <div><FormattedMessage id='inspections.defects.no_of_reports' values={{ cnt: data.whole_lot }} /></div>
                </div>}
        </Popup>
    }
    
    const getLink = (defectTypeId: string) => {
        const filterStr: string = qs.stringify(urlParamsToArray(history.location.search));
        return '/dashboard/defects/browse/' + defectTypeId + '?' + filterStr;
    }
    const updateDefectsCategory = (id: string) => {
        dispatch(updateDefectsCategoryTableData(id));
    }
    useEffect(() => {
        updateData(props.defectTypes);
    }, [props.defectTypes]);

    if (data) {
        const isTopLevel = !objectHasKeys(data.defect_type);
        return (
            <div className='table'>
                <Table basic>
                    <Table.Header>
                        <Table.HeaderCell><FormattedMessage id='dashboard.defects.category_table.header.category_name' /></Table.HeaderCell>
                        <Table.HeaderCell className=''><FormattedMessage id='dashboard.defects.category_table.header.code' /></Table.HeaderCell>
                        <Table.HeaderCell className=''><FormattedMessage id='dashboard.defects.category_table.header.minor' /></Table.HeaderCell>
                        <Table.HeaderCell className=''><FormattedMessage id='dashboard.defects.category_table.header.major' /></Table.HeaderCell>
                        <Table.HeaderCell className=''><FormattedMessage id='dashboard.defects.category_table.header.critical' /></Table.HeaderCell>
                        <Table.HeaderCell className=''><FormattedMessage id='dashboard.defects.category_table.header.whole_lot' /></Table.HeaderCell>
                        <Table.HeaderCell className=''></Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row onClick={(): void => {
                            if (!isTopLevel) {
                                updateDefectsCategory('');
                            }
                        }}>
                            <Table.Cell className={!isTopLevel ? 'link' : null}>{!isTopLevel ? data.defect_type && data.defect_type.name : intl.formatMessage({ id: 'dashboard.defects.category_table.name.all' })}</Table.Cell>
                            <Table.Cell className=''></Table.Cell>
                            <Table.Cell className=''>{isTopLevel ? popup('minor', data, <DefectCount type={'minor'} cnt={data.minor} />) : popup('minor', data.defect_type, <DefectCount type={'minor'} cnt={data.defect_type.minor} />)}</Table.Cell>
                            <Table.Cell className=''>{isTopLevel ? popup('major', data, <DefectCount type={'major'} cnt={data.major} />) : popup('major', data.defect_type, <DefectCount type={'major'} cnt={data.defect_type.major} />)}</Table.Cell>
                            <Table.Cell className=''>{isTopLevel ? popup('critical', data, <DefectCount type={'critical'} cnt={data.critical} />) : popup('critical', data.defect_type, <DefectCount type={'critical'} cnt={data.defect_type.critical} />)}</Table.Cell>
                            <Table.Cell className=''>{popup('whole_lot', data, data.whole_lot)}</Table.Cell>
                            <Table.Cell className=''></Table.Cell>
                        </Table.Row>
                        {data.children.map((defectType: any, index: number) => {
                            const hasChildren = defectType.children.length;
                            const total = defectType.critical + defectType.major + defectType.minor + defectType.whole_lot;
                            const colour = props.colors[defectType.defect_type.name];
                            return <Table.Row key={index} className={`${hasChildren > 0 ? 'link noUnderline' : ''}`}
                                onClick={(): void => {
                                    if (hasChildren) {
                                        updateDefectsCategory(defectType.defect_type.id);
                                    }
                                }}>
                                <Table.Cell className='name'>
                                    <div className='colorMarker' style={{ backgroundColor: colour }}></div>
                                    <div className='text'>{defectType.defect_type.name}</div>
                                </Table.Cell>
                                <Table.Cell className=''>{defectType.defect_type.code}</Table.Cell>
                                <Table.Cell className=''>{popup('minor', defectType, <DefectCount type={'minor'} cnt={defectType.minor} />)}</Table.Cell>
                                <Table.Cell className=''>{popup('major', defectType, <DefectCount type={'major'} cnt={defectType.major} />)}</Table.Cell>
                                <Table.Cell className=''>{popup('critical', defectType, <DefectCount type={'critical'} cnt={defectType.critical} />)}</Table.Cell>
                                <Table.Cell className=''>{popup('whole_lot', defectType, defectType.whole_lot)}</Table.Cell>
                                <Table.Cell className=''>{!hasChildren && total > 0 && <Popup trigger={
                                    <Link underline className='text-sml' to={getLink(defectType.defect_type.id)}>
                                        <FormattedMessage id='defects.images.browse' />
                                    </Link>}>
                                    <FormattedMessage id='defects.images.browse_popup' values={{ defectType: defectType.defect_type.code }} />
                                </Popup>}</Table.Cell>
                            </Table.Row>;
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    }
    return <div><FormattedMessage id='dashboard.defects.category_table.no_data' /></div>;
};

export default DefectCategories;
