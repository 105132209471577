import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AddCommentBodyEntityTypeEnum, SupplierFeaturesDto, SupplierServiceDetailedSupplier } from '../../../backend_api/models';
import { setLayoutAttribute } from '../../../base/baseSlice';
import Loader from '../../../base/components/Loader';
import BackLink from '../../../base/components/basic/BackLink';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import { getFeaturesSelector, getLayoutSelector } from '../../../base/selectors';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ContentSection from '../../pageLayouts/components/ContentSection';
import DetailedProductionUnitCustomFields from '../../productionUnits/components/DetailedProductionUnitCustomFields';
import { NewProductionUnitParams } from '../../productionUnits/components/NewProductionUnitParams';
import { useHasIndependentProductionUnits } from '../../productionUnits/hooks/useHasIndependentProductionUnits';
import { createProductionUnitWithSupplier, deleteProductionUnit } from '../../productionUnits/slice/productionUnitsSlice';
import { linkProductionUnitAndSupplier, unlinkProductionUnitAndSupplier } from '../../supplierAndPuRelations/slice/relationSlice';
import { addCommentToSupplier, getDetailedSupplier, getDetailedSupplierLoadingSelector, getDetailedSupplierSelector, getSupplierHistory, getSupplierHistoryLoadingSelector, getSupplierHistorySelector, removeDetailedSupplier, resetSupplierDetail, setDetailedSupplierGroups } from '../slice/suppliersSlice';
import DetailedSupplierAudits from './DetailedSupplierAudits';
import DetailedSupplierGroups from './DetailedSupplierGroups';
import DetailedSupplierInfo from './DetailedSupplierInfo';
import DetailedSupplierProductionUnits from './DetailedSupplierProductionUnits';
import DetailedSupplierUsers from './DetailedSupplierUsers';
import { ProductionUnitOrSupplierHistoryEntry } from '../../productionUnits/components/HistorySection';
import CommentInput from '../../../base/components/comments/CommentInput';
import { Icon } from 'semantic-ui-react';

type Props = {
    className?: string;
    supplierId: string;
};

const CREATE_PRODUCTION_UNIT = 'create_production_units';


const DetailedSupplier = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const supplierIds = location.pathname.split('/')[2];
    const supplierId = supplierIds;

    const supplierData = useSelector(getDetailedSupplierSelector);
    const supplierHistory = useSelector(getSupplierHistorySelector);
    
    const allowedToDelete = supplierData && supplierData.features.includes('delete');

    const features = useSelector(getFeaturesSelector);
    const customFieldsEnabled = features.includes('org_supplier_custom_fields');

    const [data, setData] = useState<SupplierServiceDetailedSupplier>(supplierData);
    const scrollToTop = !useSelector(getLayoutSelector).dontScrollToTopAfterAction;
    const loading = useSelector(getDetailedSupplierLoadingSelector);
    const loadingHistory = useSelector(getSupplierHistoryLoadingSelector);

    const allowedToCreateProductionUnits = supplierData && (supplierData.features || []).includes(CREATE_PRODUCTION_UNIT);
    const allowedToModifySupplier = supplierData && (supplierData.features || []).includes(SupplierFeaturesDto.Modify);

    const independProductionUnits = useHasIndependentProductionUnits();

    useEffect(() => {
        const getSupplierPromise = dispatch(getDetailedSupplier(supplierId));
        const getHistoryPromise = dispatch(getSupplierHistory({ id:  props.supplierId}));
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        return () => {
            dispatch(setLayoutAttribute('dontScrollToTopAfterAction', false));
            dispatch(resetSupplierDetail());
            getHistoryPromise.abort?.();
            getSupplierPromise.abort?.();
        }
    }, [supplierId]);

    useEffect(() => {
        setData(supplierData);
        scrollToTop && window.scrollTo(0, 0);
    }, [supplierData]);

    /* TODO: Block the page while reloading data! */
    function onUserWantsToCreateAndLinkProductionUnit(params: NewProductionUnitParams) {
        /* Create production unit on supplier */
        dispatch(createProductionUnitWithSupplier({
            production_unit_address: [],
            production_unit_group_ids: params.groups.map(g => g.id),
            production_unit_name: params.name,
            production_unit_number: params.number,
            supplier_id: props.supplierId,
            production_unit_contact_person: undefined,
            production_unit_location: undefined
        })).then(() => {
            dispatch(getDetailedSupplier(supplierId))
        })
    }

    function addComment(text: string) {
        dispatch(addCommentToSupplier({
            entity_id: props.supplierId,
            entity_type: AddCommentBodyEntityTypeEnum.Supplier,
            text
        }))
    }

    function confirmDeleteTextId() {
        if (independProductionUnits) {
            return 'globals.confirmation.remove'
        } else {
            return 'globals.confirmation.delete'
        }
    }

    function onUserWantsToUnlinkProductionUnit(productionUnitId: string) {
        if (independProductionUnits) {
            /* unlink */
            dispatch(unlinkProductionUnitAndSupplier({
                production_unit_id: productionUnitId,
                supplier_id: props.supplierId
            })).then(() => {
                dispatch(getDetailedSupplier(supplierId))
            })
        } else {
            /* delete production unit */
            dispatch(deleteProductionUnit({
                production_unit_id: productionUnitId
            })).then(() => {
                dispatch(getDetailedSupplier(supplierId))
            })
        }
    }

    function onUserWantsToLinkProductionUnit(productionUnitId: string) {
        dispatch(linkProductionUnitAndSupplier({
            production_unit_id: productionUnitId,
            supplier_id: props.supplierId
        })).then(() => {
            dispatch(getDetailedSupplier(supplierId))
        })
    }

    const reloadSupplier = () => {
        dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
        dispatch(getDetailedSupplier(supplierId));
    }



    const info = <ContentSection
        headerText={intl.formatMessage({ id: 'supplier.basic_information.label' })}
        content={
            <div className={twMerge('', className)}>
                {supplierData && <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                    <DetailedSupplierInfo name={supplierData.name} number={supplierData.number} supplierId={supplierId} />
                </HasFeatureAccess>}
            </div>}
    />;

    const historySection = <ContentSection 
        headerText={intl.formatMessage({ id: 'production_units.detailed_page.history_and_comments' })}
        content={
            <>
                <CommentInput handleNewComment={addComment} showUpload={false} />
                <div className={loadingHistory && (!supplierHistory || supplierHistory?.length == 0) ? "-m-4" : ""}>
                    <Loader active={loadingHistory} spinner={<Icon name='circle notch' loading color='green' size='large' />}>
                        {
                            supplierHistory?.map(entry => <ProductionUnitOrSupplierHistoryEntry entry={entry} />)
                        }
                    </Loader>
                </div>
                
            </>
        }
    />

    const audits = <ContentSection
        className=''
        headerText={intl.formatMessage({ id: 'supplier.audits.label' })}
        content={<div className={twMerge('', className)}>
            {data && <DetailedSupplierAudits audits={data.audits} supplierId={supplierId} onAuditsUpdated={() => {
                dispatch(getDetailedSupplier(supplierId));
                dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
            }} />}
        </div>}
    />
    const productionUnits = <ContentSection
        className=''
        headerText={intl.formatMessage({ id: 'supplier.production_units.label' })}
        content={<div className={twMerge('', className)}>
            {data && <DetailedSupplierProductionUnits
                /* Right now, there is no specific feature for linking, so we use the modify access instead. */
                allowedToLinkProductionUnits={allowedToModifySupplier}
                confirmDeleteText={confirmDeleteTextId()}
                isUserAllowedToCreateProductionUnits={allowedToCreateProductionUnits}
                onUserWantsToUnlinkProductionUnit={onUserWantsToUnlinkProductionUnit}
                onUserWantsToCreateAndLinkProductionUnit={onUserWantsToCreateAndLinkProductionUnit}
                onUserWantsToLinkProductionUnit={onUserWantsToLinkProductionUnit}
                productionUnits={data && data.production_units} />}
        </div>}
    />
    const customFields =
        data && customFieldsEnabled && <DetailedProductionUnitCustomFields
            type='supplier'
            productionUnitId={data.id}
            allowedToEdit={userIsAllowedTo(supplierData, SupplierFeaturesDto.Modify)}
            customFieldGroups={data.custom_field_groups}
        />;
    const users = <ContentSection
        className=''
        headerText={intl.formatMessage({ id: 'supplier.users.label' })}
        content={<div className={twMerge('', className)}>
            <DetailedSupplierUsers
                supplierId={supplierId}
                users={data && data.users}
                groups={data && data.groups}
                supplierGroup={data && data.primary_group}
                onUserUpdated={() => {
                    dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                    reloadSupplier();
                }} />
        </div>}
    />

    const groups = data && <DetailedSupplierGroups
        allowedToEdit={supplierData && supplierData.features.includes(SupplierFeaturesDto.Modify)}
        onGroupsUpdated={(groups) => {
            dispatch(setDetailedSupplierGroups({ supplierId, groups: groups }))
        }}
        groups={data.groups}
        productionUnitId={data.id} />;

    return <PageContainer
        header={<div>{data && data.name} - {data && data.number}</div>}
    >

        <DocumentTitle title={intl.formatMessage({ id: 'page_title.supplier' }, { supplierName: data && data.name })} />
        <Loader active={loading}>
            <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                <div className='w-full md:w-8/12 order-last md:order-first'>
                    {info}
                    {customFields}
                    {productionUnits}
                    {audits}
                    {users}
                    {groups}
                    <BackLink to='/suppliers' className='mb-2' />
                    {allowedToDelete && <div className='flex'>
                        <DeleteConfirm
                            disabled={!allowedToDelete}
                            deleteFunction={() => dispatch(removeDetailedSupplier({ supplierId }))}
                            type='alert'
                            alert
                            alertHeader={intl.formatMessage({ id: 'suppliers.remove_supplier_header' }, { name: supplierData.name && supplierData.name })}
                            alertText={intl.formatMessage({ id: 'suppliers.remove_supplier_confirm' })}
                            trigger={<span className={allowedToDelete ? 'cursor-pointer' : ''}>
                                <Button negative className={!allowedToDelete && 'cursor-default'}><FormattedMessage id='supplier.delete_supplier' /></Button>
                            </span>} />
                    </div>}
                </div>
                <div className='w-full md:w-4/12 order-first md:order-last pt-0 pb-2'>
                    {historySection}
                </div>
            </div>
        </Loader>

    </ PageContainer >
}

function userIsAllowedTo(supplier: SupplierServiceDetailedSupplier, action: string): boolean {
    if (!supplier) {
        return false;
    }

    return supplier.features.includes(action);
}
export default DetailedSupplier;
