import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Sticky from 'react-sticky-el';
import { Divider, Header, Icon, List, Popup, Segment } from 'semantic-ui-react';
import { Audit } from '../../../../backend_api/models/Audit';
import { CompleteApprover } from '../../../../backend_api/models/CompleteApprover';
import { ListUsersUser } from '../../../../backend_api/models/ListUsersUser';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import { HEADER_HEIGHT } from '../../../../base/config';
import { convertConclusionCategoy, getLocaleLanguageString, getLocaleSpecificString, getObjectByIdCount, useIsMobileDevice } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import ReportApprovalItemShared from '../../../approval/components/ReportApprovalItemShared';
import { CustomConclusion } from '../../../approval/types';
import { getComments as getCommentsAction } from '../../../comments/actions';
import Comments from '../../../comments/components/Comments';
import { WriteComment } from '../../../comments/components/WriteComment';
import { getComments, showTranslatedCommentsSelector } from '../../../comments/selectors';
import { TranslateText } from '../../../globals/components';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { getOrderUsersSelector } from '../../../users/selectors';
import { changeApprovalStep as changeApprovalStepAction, changeInspectionConclusion } from '../../slices/inspectionReportSlice';
import { Inspection } from '../../types';
import ChangeConclusionDialog from '../ChangeConclusionDialog';
import { Reinspect } from '../Reinspect';
import { ReportStatus, ReportStatusCompact } from '../edit/Status';
import { ApprovalStatusWrapper } from './ApprovalStatus';
import ApprovalSteps from './ApprovalSteps';
type ElementType = Inspection & Audit;

type ReportApprovalProps = {
    element?: ElementType;
    inspection?: Inspection;
    auditorComment?: any;
    isBulk?: boolean;
    watchers?;
};

const ReportApproval = (props: ReportApprovalProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { inspection, isBulk, auditorComment } = props;
    const isInspection = auditorComment === undefined;
    const icons = { approved: 'icon check', rejected: 'icon remove', pending: 'icon refresh', not_set: 'icon refresh' };
    const users: ListUsersUser[] = useSelector(getOrderUsersSelector);
    const isMobile = useIsMobileDevice();
    const reinspectDisabled = inspection && (inspection.status === 'ongoing' || inspection.status === 'planned');
    const approvalFlow = inspection && inspection.approval_flow;
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    const inspectorsConclusion = inspection.inspector_conclusion.conclusion_category;
    const hasInspectorConclusion = approvalFlow && (!(approvalFlow.length === 0 && inspection.inspector_conclusion.conclusion_category === 'not_set' || inspection.inspector_conclusion.conclusion_category === 'not_set'));
    const canChangeConclusion = inspection.features && inspection.features.includes('change_conclusion');
    const disabled = inspection.status === 'ongoing' || inspection.status === 'planned' || !canChangeConclusion;
    const [expanded, setExpanded] = useState(false);
    const [type, setType] = useState(undefined);
    const [approvalStep, setApprovalStep] = useState<CompleteApprover>(undefined);
    const [notDoneFlowsCnt, setNotDoneFlowsCnt] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [conclusion, setConclusion] = useState(inspection.conclusion);
    const canCreateComments = inspection.features.includes('create_comments')
    const comments = useSelector(getComments);
    const commentsCnt = comments ? getObjectByIdCount(comments) : 0;

    const expand = (expanded: boolean): void => {
        dispatch(setLayoutAttribute('reportApprovalExpanded', expanded));
        setExpanded(expanded);
        if (expanded) {
            scrollTo(0, HEADER_HEIGHT - 2);
        }
    };
    const getStatusClass = (): string => {
        let status = ''
        if (inspection.status === 'ongoing') {
            status = 'inProgress';
        }
        else if (inspection.status === 'report') {
            status = convertConclusionCategoy(inspection.conclusion.conclusion_category);
        }
        return classnames('status approval-header ', { [status]: true });
    };
    const handleCancel = (): void => {
        setShowModal(false);
        setType(undefined);
        setApprovalStep(undefined);
    };

    const handleClose = (): void => {
        handleCancel();
    };
    const handleSave = (conclusion: CustomConclusion, comment: string): void => {
        if (type === 'conclusion') {
            changeConclusion(conclusion, comment);
        }
        if (type === 'approvalStep') {
            changeApprovalStep(conclusion, comment);
        }
    };
    const changeConclusion = (conclusion: CustomConclusion, comment: string): void => {
        dispatch(changeInspectionConclusion({ inspectionId: inspection.inspection_id, conclusion: { conclusion, comment: comment, conclusionName: getLocaleSpecificString(conclusion.full_name) } }));
        dispatch(getCommentsAction(inspection.inspection_id));
        handleClose();
    };
    const changeApprovalStep = (conclusion: CustomConclusion, comment: string): void => {
        dispatch(changeApprovalStepAction({ inspectionId: inspection.inspection_id, approvalStepId: approvalStep.id, conclusion: { conclusion, comment: comment, conclusionName: getLocaleSpecificString(conclusion.full_name) } }));
        dispatch(getCommentsAction(inspection.inspection_id));
        handleClose();
    };
    const handleOpen = (type: string, approval: CompleteApprover): void => {
        let notDoneFlowsCnt = 0;
        if (type === 'conclusion') {
            notDoneFlowsCnt = getNotDoneFlowsCnt();
        }
        if (approval) {
            setConclusion(approval.approved_status.conclusion);
        }
        else {
            setConclusion(inspection.conclusion);
        }
        setShowModal(true);
        setType(type);
        setApprovalStep(approval);
        setNotDoneFlowsCnt(notDoneFlowsCnt);
    };
    const getNotDoneFlowsCnt = (): number => {
        const flow = approvalFlow;
        let ret = [];
        flow.forEach((outerItem) => {
            ret = outerItem.filter((item) => {
                return item.approved_status.conclusion.conclusion_category === 'not_set';
            });
        });
        return ret.length;
    };

    const inspectorComment = (): React.ReactElement => {
        const comment = isInspection ? inspection.inspector_comment : auditorComment;
        return (
            comment && <div className='approver conclusion_text break-words whitespace-pre-wrap'>
                <TranslateText
                    showTranslatedComments={showTranslatedComments}
                    translateObj={comment.translated_comment}
                    originalText={comment.comment}
                    originalLanguage={comment.comment_language}
                    showToggleActions={true}
                /></div>
        );
    }

    const mobileStatus =
        <Sticky stickyStyle={{ zIndex: 5 }} boundaryElement='.top-header' className='report-info' pushing={false}>
            <Segment className={classnames('mobileHeader') + ' actions'}>
                <div className='flex flex-col w-full space-y-1'>
                    <div className='pb-4 border-b'>
                        <ReportStatusCompact inspection={inspection} />
                    </div>
                    <div className='justify-center flex pt-4 pb-1'>
                        <div className='flex justify-center space-x-2 link noUnderline' data-test-id='btn-info-show-more' onClick={(): void => expand(true)}><div><FormattedMessage id='inspections.show_more' />({commentsCnt})</div><Icon name='chevron down' /></div>
                    </div>
                </div>
            </Segment >
        </Sticky >

    const statusButton =
        <HasFeatureAccess feature='change_conclusion' type='inspection' inspection={inspection}>
            <Button onClick={(): void => handleOpen('conclusion', null)} disabled={disabled} className='text-sml'>
                {approvalFlow && approvalFlow.length === 0 && <span className='' data-test-id='btn-set-status'><FormattedMessage id='inspections.set_status' /></span>}
                {approvalFlow && approvalFlow.length > 0 && <span className='' data-test-id='btn-set-status'><FormattedMessage id='inspections.change_status' /></span>}
            </Button>
            <Divider />
        </HasFeatureAccess>;
    const auditorCommentData =
        <Fragment>
            <List.Item className='inspector' key='approvalItem_inspectors_comments'>
                <List.Content className='header'>
                    <FormattedMessage id='audit_report.auditors_comment' />
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Content className=''>
                    {auditorComment && auditorComment.comment ? <Fragment><i className='mi comment'>comment</i>{auditorComment.comment}</Fragment> : <FormattedMessage id='audit_report.auditor_no_comment' />}
                </List.Content>
            </List.Item>
        </Fragment>;
    const inspAppr = <ApprovalStatusWrapper
        conclusionCategory={inspection.inspector_conclusion.conclusion_category}
        shortName={hasInspectorConclusion ? (inspection.inspector_conclusion.short_name) : { [getLocaleLanguageString(intl.locale)]: intl.formatMessage({ id: 'inspections.not_set' }) }} />
    const inspectorConclusionData =
        <>
            <ReportApprovalItemShared
                user={inspection.assigned_user}
                heading={<FormattedMessage id='inspections.inspectors_conclusion' />}
                // status={<Label circular size='small' className={classnames('noaction', 'status_' + inspectorsConclusion)}>
                // <Icon className={icons[inspectorsConclusion]} />
                // <span>{hasInspectorConclusion ? getLocaleSpecificString(inspection.inspector_conclusion.short_name) : <FormattedMessage id='inspections.not_set' />}</span>
                // </Label>}
                status={inspAppr}
                comment={inspectorComment()} />
        </>;
    const inProgressPopupContent = <Fragment>
        <Popup.Header>
            <Icon name='info circle' color='blue' />
            <FormattedMessage id='inspections.report_in_progress' /></Popup.Header>
        <Popup.Content><FormattedMessage id='inspections.report_in_progress_popup_content' /></Popup.Content>
    </Fragment>;
    const modalData = (): React.ReactElement => {
        return (<span className='reportApproval'>
            {showModal &&
                <ChangeConclusionDialog
                    showModal={showModal}
                    handleClose={handleClose}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                    notDoneFlowsCnt={notDoneFlowsCnt}
                    isMobile={isMobile}
                    conclusion={conclusion}
                />
            }
        </span>);
    }
    const approvalData = approvalFlow && approvalFlow.map((approvalFlowItems: CompleteApprover[], i: number) => {
        return (<ApprovalSteps
            key={'approvalFlowItem' + i}
            approvalSteps={approvalFlowItems}
            index={i}
            disabled={disabled}
            handleOpen={handleOpen}
            element={inspection}
        />);
    });
    const actionButtons = <List.Item>
        <List.Content>
            {disabled && canChangeConclusion && <Popup
                trigger={<span>{statusButton}</span>}>
                {inProgressPopupContent}
            </Popup>}
            {disabled && !canChangeConclusion && statusButton}
            {!disabled && statusButton}
            {!isBulk &&
                <Fragment>
                    <Reinspect
                        inspection={inspection}
                        disabled={reinspectDisabled}
                    />

                </Fragment>}
        </List.Content>
    </List.Item>;
    const inspectionId = props.inspection.inspection_id;
    const mobileComments =
        <Fragment>
            <Divider />
            <List.Item>
                <List.Content>
                    <div className='commentsContainer _c'>
                        <FormattedMessage id='inspections.comments' /> ({commentsCnt})
                        <Fragment>
                            {canCreateComments && <WriteComment type='inspection' id={inspectionId} showModal={false} />}
                            <Comments typeId={inspectionId} inspectionId={inspectionId} inspection={props.inspection} showCollapsed={true} />
                            <Fragment>
                                <Divider />
                                <Header as='h4'><FormattedMessage id='inspections.watchers' /></Header>
                                {props.watchers}
                            </Fragment>
                        </Fragment>
                    </div>
                </List.Content>
            </List.Item>
        </Fragment>

    const data = <Segment.Group raised>
        <Segment className={getStatusClass()} onClick={(): void => expand(false)}>
            <div className='flex'>
                <div className='w-6/16 items-center flex'>
                    <div className='font-medium text-lg'><FormattedMessage id='inspections.status' /></div>
                </div>
                <div className='w-10/16 items-center flex text-center'>
                    <ReportStatus inspection={inspection} />
                </div>
            </div>
        </Segment>
        <Segment className='conclusion report-info'>
            <List verticalAlign='top'>
                {isInspection && inspectorConclusionData}
                {!isInspection && auditorCommentData}
                {approvalData}
                {isInspection && actionButtons}
                {modalData()}
                {isMobile && mobileComments}
            </List>
            {isMobile && expanded &&
                <div className='flex justify-center space-x-2 link noUnderline' data-test-id='btn-info-show-more' onClick={(): void => expand(false)}><div><FormattedMessage id='inspections.hide' /></div><Icon name='chevron up' /></div>
            }
        </Segment>
    </Segment.Group>;
    useEffect(() => {
        return () => { dispatch(setLayoutAttribute('reportApprovalExpanded', false)); }
    }, [])
    return <div className={classnames('reportApproval', { expanded })}>
        {isMobile && !expanded && isInspection && mobileStatus}
        {isMobile && !expanded && !isInspection && auditorCommentData}
        {isMobile && expanded && data}
        {!isMobile && data}
    </div>
}

export default ReportApproval;
