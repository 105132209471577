import React from 'react';
import { useSelector } from 'react-redux';
import { Audit } from '../../../backend_api/models';
import { AppState } from '../../../base/types';
import { useAppDispatch } from '../../../store';
import CorrectiveActionStatusPie from '../../correctiveActions/components/CorrectiveActionStatusPie';
import CorrectiveActionStatusPopup from '../../correctiveActions/components/CorrectiveActionStatusPopup';
import { getCorrectiveActionsOnDashboardSupplier, getCorrectiveActionsSelector, getIsFetchingCorrectiveActionsSelector } from '../slice/suppliersSlice';

type Props = {
    correctiveActionStatus: Audit['corrective_actions_status'];
    supplierId: string;
    className?: string;
};

const SupplierCorrectiveActionStatusPie = (props: Props): React.ReactElement => {
    const { correctiveActionStatus } = props;
    const dispatch = useAppDispatch();
    const correctiveActions = useSelector((state: AppState) => getCorrectiveActionsSelector(state));
    const isFetching = useSelector((state: AppState) => getIsFetchingCorrectiveActionsSelector(state));

    const loadCorrectiveActions = () => {
        dispatch(getCorrectiveActionsOnDashboardSupplier(props.supplierId))
    }

    const trigger = <span><CorrectiveActionStatusPie correctiveActionStatus={correctiveActionStatus} /></span>
    return <CorrectiveActionStatusPopup isFetching={isFetching} loadCorrectiveActions={loadCorrectiveActions} correctiveActions={correctiveActions} context={'supplier'} correctiveActionStatus={correctiveActionStatus} trigger={trigger} />;
}
export default SupplierCorrectiveActionStatusPie;