import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Grid, Icon, Label, Modal, Pagination, Popup, PopupContent, Segment } from 'semantic-ui-react';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import { AppState, byId } from '../../../base/types';
import { objectHasKeys } from '../../../base/utils';
import { GroupData } from '../../groups/types';
import GroupFilter from '../components/groupFilter';
import SearchFilter from '../components/searchFilter';
import { getUsers } from '../selectors';
import { ById } from '../types';

type UsersSelectorModalProps = {
    users: byId<ListUsersUser>;
    groups: GroupData[];
    groupsById: ById<GroupData>;
    allowMultipleSelection: boolean;
    disabled: boolean;
    showUnassigned?: boolean;
    showDeactivated?: boolean;
    selectedUsers: string[];
    handleModalSelections(selectedUsers: string[]);
    withIcon?: boolean;
    open?: boolean;
};

const UsersSelectorModal = (props: UsersSelectorModalProps): React.ReactElement => {
    const { users, groups, groupsById, handleModalSelections, allowMultipleSelection, showUnassigned = false, open = false, showDeactivated = false } = props;
    const [init, setInit] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>(props.selectedUsers);
    const [showDialog, setShowDialog] = useState(open || false);
    const [filters, setFilters] = useState<ById<string | string[]>>({});
    const [active, setActive] = useState({ groups: false })
    const [checkedAllPages, setCheckAllPage] = useState<number[]>([]);
    const [pagination, setPagination] = useState<{ currentPage?: number; itemsPerPage?: number; lastPage?: number }>({ currentPage: 1, itemsPerPage: 8, lastPage: 1 });
    const [showHint, setShowHint] = useState(false);
    const [sUsers, setUsers] = useState(() => {
        objectHasKeys(users) && Object.keys(users).filter((k) => {
            return users[k];
        });
        return [];
    });
    const allUsers = (useSelector((state: AppState) => getUsers(state, !showDeactivated)));
    useEffect(() => {
        if (!allowMultipleSelection && selectedUsers.length > 0 && init) {
            applySelections()
        }
    }, [selectedUsers]);

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (showDialog) {
            setUsers(allUsers)
        }
    }, [showDialog]);

    const toggleDialog = (): void => {
        setShowDialog(!showDialog);
    }

    const handleChange = (item): void => {
        const selectedFilters = Object.assign({}, filters);
        selectedFilters[item.name] = item.value;
        setFilters(selectedFilters);
    }

    const handlePaginationChange = (e, { activePage = '1' }: { activePage?: number | string }): void => setPagination({ ...pagination, currentPage: +activePage ? +activePage : 1 });

    const handleUserClick = (userId: string): void => {
        allowMultipleSelection ? handleMultipleSelection(userId) : handleSimpleSelection(userId)
    }

    const handleMultipleSelection = (userId: string): void => {
        if (selectedUsers.includes(userId)) {
            const reducedArray = selectedUsers.filter((selectedUserId) => userId !== selectedUserId)
            setSelectedUsers(reducedArray);
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    }
    const handleSimpleSelection = (userId: string): void => {
        setSelectedUsers([userId])
    }
    const checkAll = ({ checked = false }: { checked?: boolean }): void => {
        const newSelected = checked ? filteredUsersArray.map(u => u.id) : selectedUsers.filter(u => !filteredUsersArray.map(user => user.id).includes(u));
        if (checkedAllPages.includes(pagination.currentPage)) {
            setSelectedUsers(newSelected);
            setCheckAllPage(checked ? checkedAllPages : checkedAllPages.filter(p => p !== pagination.currentPage))
        } else {

            setSelectedUsers([...new Set([...selectedUsers, ...newSelected])]);
            setCheckAllPage(checked ? [...checkedAllPages, pagination.currentPage] : checkedAllPages)
        }
    }

    const activateFilter = (filterName: string, isActive: boolean): void => {
        const activeFilters = Object.assign({}, active);
        activeFilters[filterName] = isActive;
        setActive(activeFilters);
    }

    const applyFilter = (): ListUsersUser[] => {
        return filters ?
            sUsers.filter((user) => {
                let satisfy = true;
                Object.keys(filters).forEach((key) => {

                    let selectedItems = filters[key];
                    if (!Array.isArray(selectedItems)) {
                        selectedItems = [selectedItems];
                    }
                    const name = `${user['firstname']} ${user['lastname']}`.toLowerCase();
                    if (key === 'search' && selectedItems.length
                        && !name.includes(selectedItems[0].toLowerCase())
                        && !user['email'].toLowerCase().includes(selectedItems[0].toLowerCase())) {
                        satisfy = false;
                        return;
                    }
                    if (key === 'groups' && selectedItems.length && !user[key].some(({ id }) => selectedItems.includes(id))) {
                        satisfy = false;
                        return;
                    }
                })
                return satisfy;

            })
            : sUsers;
    }

    const applyPagination = (users: ListUsersUser[]): ListUsersUser[] => {
        const { currentPage, itemsPerPage, lastPage } = pagination;
        const _lastPage = Math.ceil(users.length / itemsPerPage);
        if (lastPage !== _lastPage) {
            setPagination({ ...pagination, lastPage: _lastPage })
        }
        const length = users.length;
        const from = ((currentPage - 1) * itemsPerPage);
        const to = currentPage === _lastPage && _lastPage !== 1 ? length : currentPage * itemsPerPage;
        return users.slice(from, to)
    }

    const filteredUsersArray = applyPagination(applyFilter());

    const isSelected = (user: ListUsersUser): boolean => selectedUsers.includes(user.id);
    const isAllChecked = (): boolean => {
        let satisfy = true;
        if (filteredUsersArray.map(u => u.id).some((userId) => !selectedUsers.includes(userId))) {
            satisfy = false;
        }
        return satisfy;
    }

    const cancelSelections = (): void => {
        setSelectedUsers(props.selectedUsers);
        setActive({ groups: false })
        setPagination({ currentPage: 1, itemsPerPage: 8, lastPage: 1 });
        handleModalSelections(selectedUsers);
        setShowDialog(false);
    }

    const applySelections = (): void => {
        handleModalSelections(selectedUsers);
        setFilters({});
        setActive({ groups: false })
        setPagination({ currentPage: 1, itemsPerPage: 8, lastPage: 1 });
        setShowDialog(false);
    }

    const PaginationElement = (): React.ReactElement => (
        <Pagination
            activePage={pagination.currentPage}
            size='mini'
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            totalPages={pagination.lastPage}
            onPageChange={handlePaginationChange}
        />
    )

    const selectedUsersHint = (): React.ReactElement => {
        return allowMultipleSelection ? <div>
            <span className="label">
                <FormattedMessage id='users.users_selected' />{selectedUsers.length}
            </span>
            <span onClick={(): void => setSelectedUsers([])} className='link clearall'>
                <FormattedMessage id='users.clear_all' />
            </span>
        </div>
            : <div>
                <span className="label">
                    <FormattedMessage id='users.selected_user' />
                </span>
                {users[selectedUsers[0]] && <Label as='a' >
                    {selectedUserName()}
                    <Icon name='delete' onClick={(): void => showUnassigned ? setSelectedUsers([null]) : setSelectedUsers([])} />
                </Label>}
            </div>
    }

    const selectedUserName = (user = selectedUsers[0]): string => {
        return selectedUsers.length ?
            users[user] ? users[user].firstname + ' ' + users[user].lastname : 'Unassigned'
            : '';
    }

    return <Modal
        onClick={(e): void => e.stopPropagation()} // Apparently, the modal has an error causing click events inside the modal to go through the modal triggering any underlying click events (e.g. buttons or links)
        dimmer={'inverted'}
        className='usersSelectorDialog'
        onClose={cancelSelections}
        open={showDialog}
        disabled={props.disabled}
        trigger={<Popup trigger={<Button disabled={props.disabled} onClick={toggleDialog} className='showDialog' icon='ellipsis horizontal' />}>
            <PopupContent><Icon name='user' /><FormattedMessage id='users.click_to_show_expanded_dialog' /></PopupContent>
        </Popup>}
    >
        <Modal.Header>
            <Grid className="modalHeaderContent">
                <Grid.Row>
                    <div className="flex space-x-4">
                        <SearchFilter
                            query={filters['search'] || []}
                            doSearch={handleChange}
                        />
                        <GroupFilter
                            groups={groups}
                            groupsById={groupsById}
                            handleChange={handleChange}
                            selectedGroups={filters['groups'] || []}
                            setActive={activateFilter}
                            active={active.groups}
                        />
                    </div>
                </Grid.Row>
                {!!selectedUsers.length && <Grid.Row className="rowWithPins" >
                    <div className="accordionWrap">
                        <div className="selectedUsersHint">
                            {selectedUsersHint()}
                            {allowMultipleSelection && <Icon className={showHint ? 'icon-arrow-up' : 'icon-arrow-down'} onClick={(): void => setShowHint(!showHint)} />}
                        </div>
                        {showHint && <div className="containerWithPins">
                            {selectedUsers.map((user, index: number) => {
                                return <Label as='a' key={index}>
                                    {selectedUserName(user)}
                                    <Icon name='delete' onClick={(): void => { handleUserClick(user) }} />
                                </Label>
                            })}
                        </div>}
                    </div>
                </Grid.Row>}
            </Grid>

        </Modal.Header>
        <Modal.Content scrolling>
            {!!filteredUsersArray.length && <div className='users-wrapper'>
                <Grid relaxed className='user-grid-header'>
                    <Grid.Row columns={16}>
                        {allowMultipleSelection && <Grid.Column width={1}>
                            <Checkbox
                                checked={isAllChecked()}
                                onChange={(evt, item): void => {
                                    checkAll(item);
                                }} />
                        </Grid.Column>}
                        <Grid.Column width={4} className='header'><FormattedMessage id='users.name' /></Grid.Column>
                        <Grid.Column width={6} className='header'><FormattedMessage id='users.email_address' /></Grid.Column>
                        <Grid.Column width={4} className='header'><FormattedMessage id='users.groups' /></Grid.Column>
                        <Grid.Column width={1} className='header'></Grid.Column>
                    </Grid.Row>
                </Grid>
                <Segment raised>
                    <Grid className='user-grid-content'>
                        {filteredUsersArray.map((user: ListUsersUser) => {
                            const key = 'key_' + user.id
                            const isSupplierQC = user.groups[0] && user.groups[0].is_supplier_group;
                            return (
                                <Grid.Row columns={16} key={key} className={`user-grid-line ${isSelected(user) ? 'active' : ''}`} onClick={(): void => handleUserClick(user.id)} verticalAlign='middle'>
                                    {allowMultipleSelection && <Grid.Column width={1}>
                                        <Checkbox
                                            checked={isSelected(user)} />
                                    </Grid.Column>}
                                    <Grid.Column width={4} className='item'>
                                        <div className='name'>{user.firstname} {user.lastname}</div>
                                    </Grid.Column>
                                    <Grid.Column width={6} className='item'>
                                        <div className='email'>{user.email}</div>
                                    </Grid.Column>
                                    <Grid.Column width={4} className='item'>
                                        {user.groups.map((group, index: number) => <Label key={index}>{group.name}</Label>)}
                                    </Grid.Column>
                                    <Grid.Column width={1} className='item'>
                                        {isSupplierQC && props.withIcon && <Icon name='factory' />}
                                    </Grid.Column>
                                </Grid.Row>)
                        })}
                    </Grid>
                    {pagination.lastPage > 1 && <Grid centered className='user-grid-footer'>
                        <Grid.Row cols={3}>
                            <Grid.Column >{PaginationElement()}</Grid.Column>
                        </Grid.Row>
                    </Grid>}
                </Segment>
            </div>}
            {filteredUsersArray.length === 0 && <div className="no-users">
                <div className='users-info'><Icon color='red' name='warning circle' />
                    <FormattedMessage id='users.no_users_found' /></div>
            </div>}
        </Modal.Content>
        <Modal.Actions>

            {allowMultipleSelection &&
                <Button positive onClick={applySelections}>
                    <Icon name='checkmark' /> <FormattedMessage id='users.apply' />
                </Button>}
            <Button onClick={cancelSelections}>
                <Icon name='remove' /> <FormattedMessage id='users.cancel' />
            </Button>
        </Modal.Actions>
    </Modal>;
}

export default UsersSelectorModal;