import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Form, Modal, TextArea, TextAreaProps } from 'semantic-ui-react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import { AppState } from '../../../base/types';
import { preventDefaultAndStopPropagation, stopPropagation, useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAttachments as getAttachmentsSelector } from '../../attachments/selectors';
import { Attachment } from '../../attachments/types';
import { addComment } from '../actions';
import { getCommentsSavingSelector } from '../selectors';
import { CommentTypes } from '../types';
import AddCommentAttachment from './AddCommentAttachment';

type WriteCommentProps = {
    showModal: boolean;
    type?: CommentTypes;
    id?: string;
    parentId?: string;
    commentId?: string;
    imageId?: string;
    title?: string;
    saving?: boolean;
    isReply?: boolean;
    imageUrl?: string;
    attachment?: Attachment[];
    trigger?: React.ReactElement;
    autoFocus?: boolean;
    clearReplies?(): void;
}

export function WriteComment(props: WriteCommentProps): React.ReactElement {
    const { commentId = 'root', isReply = false, id, type, parentId, imageUrl, autoFocus = false, trigger } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [newComment, setComment] = useState('');
    const [newCommentHasFocus, setNewCommentHasFocus] = useState(false);
    const [saving, setSaving] = useState(false);
    const [sendNotification, setSendNotification] = React.useState(true);
    const [showAddComment, updateShowAddComment] = useState(false);
    const isMobile = useIsMobileDevice();
    const attachments = useSelector((state: AppState) => getAttachmentsSelector(state, commentId, 'comments'));
    const isSaving = useSelector(getCommentsSavingSelector);
    const defaultTitle: string = intl.formatMessage({ id: 'comments.write_a_comment' });
    const title = props.title || defaultTitle;

    const hasAttachment = attachments.length > 0;
    const hasComment = newComment.length > 0;
    const notificationBoxVisible = hasAttachment || hasComment || newCommentHasFocus;

    const getForm = (title: string): React.ReactElement => {

        const sendButton = intl.formatMessage({ id: 'comments.send' });
        const sendAndNotifyButton = intl.formatMessage({ id: 'comments.send_and_notify' });

        const attachButton = intl.formatMessage({ id: 'comments.attach' });
        const attachAndSendButton = intl.formatMessage({ id: 'comments.attach_and_send' });

        const attachAndNotifyButton = intl.formatMessage({ id: 'comments.attach_and_notify' });
        const attachAndSendAndNotifyButton = intl.formatMessage({ id: 'comments.attach_and_send_and_notify' });

        const rows = isReply ? 2 : 3;
        const size = isReply ? 'mini' : 'small';

        const formTitle = title || defaultTitle;

        const buttonLabel =
            (hasAttachment && hasComment && sendNotification) ? attachAndSendAndNotifyButton :
                (hasAttachment && hasComment) ? attachAndSendButton :
                    (hasAttachment && sendNotification) ? attachAndNotifyButton :
                        (hasAttachment) ? attachButton :
                            (sendNotification && hasComment) ? sendAndNotifyButton : sendButton;



        return (<Form className='writeComment'>
            <Form.Field>
                <TextArea
                    onKeyDown={stopPropagation}
                    autoFocus={autoFocus || isReply}
                    value={newComment}
                    onClick={(evt): void => preventDefaultAndStopPropagation(evt)}
                    onChange={handleChange}
                    rows={rows}
                    placeholder={formTitle}
                    onFocus={() => setNewCommentHasFocus(true)}
                    onBlur={() => setNewCommentHasFocus(false)} />
            </Form.Field>
            <Form.Field>
                <AddCommentAttachment
                    attachedToId={commentId}
                    attachedToType='comments'
                    visible={true}
                    autoclear={false}
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        className=''
                        small={isReply}
                        data-test-id='btn-send-comment'
                        primary
                        disabled={!hasComment && !hasAttachment}
                        onClick={sendComment}>
                        {buttonLabel} <Icon className='text-xl leading-3' name='chevron_right' />
                    </Button>
                    {notificationBoxVisible &&
                        <Popup
                            on='hover'
                            trigger={
                                <Checkbox
                                    label={intl.formatMessage({ id: 'report.comment.send_notification' })}
                                    checked={sendNotification}
                                    onChange={(e, item): void => {
                                        setSendNotification(item.checked);
                                        preventDefaultAndStopPropagation(e);
                                    }}
                                />
                            }
                        >
                            <Popup.Content>
                                <FormattedMessage id="comments.comment_and_notify.tooltip" />
                            </Popup.Content>
                        </Popup>
                    }
                </div>
            </Form.Field>
        </Form>);
    };

    const handleChange = (evt, data: TextAreaProps): void => {
        setComment(data.value.toString());
    };

    const sendComment = (): void => {
        let _type = type;
        if (type === 'checkpoint_video') {
            _type = 'checkpoint_image';
        }
        dispatch(addComment({
            comment: newComment,
            id: id,
            type: _type,
            parentId: parentId,
            commentId: commentId,
            imageUrl: imageUrl,
            sendNotification: sendNotification
        }));
        if (props.showModal) {
            closeAddComment();
        }
        setComment('');
        setSaving(true);
        setTimeout(() => {
            if (props.clearReplies) {
                props.clearReplies(); // FIXME: hack to fake a visual delay after adding comment
            }
        }, 500);
    };

    const setShowAddComment = (): void => {
        updateShowAddComment(true);
    };

    const closeAddComment = (): void => {
        updateShowAddComment(false);
    };

    useEffect(() => {
        setSaving(isSaving);
    }, [isSaving]);

    if (props.showModal) {
        return (
            <span>
                <Modal
                    style={{ maxWidth: '500px', top: isMobile && '3rem' }}
                    className={'write-comment-modal'}
                    open={showAddComment}
                    closeIcon={true}

                    onClose={closeAddComment}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>
                        {getForm(title)}
                    </Modal.Content>
                    <Modal.Actions className='flex justify-end'>
                        <Button small onClick={closeAddComment}><FormattedMessage id='globals.cancel' /></Button>
                    </Modal.Actions>
                </Modal>
                {trigger ? <span onClick={(): void => setShowAddComment()}>{trigger}</span> : <Button onClick={(): void => setShowAddComment()}><FormattedMessage id='comments.add_comment' /></Button>}

            </span>
        );
    }
    return <Fragment>
        {getForm(title)}
    </Fragment>;
}

export default WriteComment;
