import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { NotifiedOnOverdueEvent as NotifiedOnOverdueEventType } from '../../../backend_api/models';

type Props = {
    event: NotifiedOnOverdueEventType
}

const NotifiedOnOverdueEvent = (props: Props) => {
    return <Fragment>
        <label className='mb-1'><FormattedMessage id={'corrective_actions.events.notified_on_overdue'} /></label>
        {props.event.notified_emails.map((email) => {
            return <div key={email} className='flex items-center text-secondary'>
                {email}
            </div>
        })}
    </Fragment>
}

export default NotifiedOnOverdueEvent;