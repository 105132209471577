import { SisenseContextProvider, ThemeProvider } from "@sisense/sdk-ui";
import qs from 'query-string';
import React from 'react';
import { twMerge } from '../../../../base/utils';

type Props = {
    className?: string;
};

export const SisenseUrl = "https://qarma.sisensepoc.com";
const themeId = qs.parse(location?.search)?.themeId as string || '66d9b083726c81003316dea8';
const dashboardId = qs.parse(location?.search)?.dashboardId as string || '66dfea86cd6a9d00339d2522';
const src = 'https://qarma.sisensepoc.com/app/main/dashboards/' + dashboardId + '?embed=true&theme=' + themeId;

const SisenseIFrame = (props: Props): React.ReactElement => {
    const { className } = props;

    return <div className={twMerge('p-8', className)}>
        <h1>Testing Sisense iframe dashboard</h1>
        <div className='h-screen'>
            <SisenseContextProvider url={SisenseUrl} ssoEnabled={true} enableSilentPreAuth={true}>
                <ThemeProvider theme={themeId}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={src}
                    ></iframe>

                </ThemeProvider>
            </SisenseContextProvider>
        </div>
    </div>
}
export default SisenseIFrame;
