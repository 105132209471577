import { ResponsiveBar } from '@nivo/bar';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Dimmer, Header, Loader } from 'semantic-ui-react';
import { capitaliseWord, objectHasKeys } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { HasFeature } from '../../../profile/containers';
import config from '../../config';
import { getDefectsStatusBySeverity } from '../../dashboardActions';
import { getDefectsStatusBySeveritySelector, isDashboardLoadingSelector } from '../../selectors';
import NoData from '../NoData';
import Stats from '../Stats';
import DefectCategories from './DefectCategories';
import DefectsPie from './DefectPie';

const DefectsStatus = (): ReactElement<any> => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    let data;
    let types;
    let summary;
    let defectTypesPie;
    let defectTypesTable;

    const response: { data: []; result: []; types: []; summary: any; defectTypesPie: []; defectTypesTable: any; colors: [] } = useSelector(getDefectsStatusBySeveritySelector);
    if (response) {
        data = response.data;
        types = response.types;
        summary = response.summary;
        defectTypesPie = response.defectTypesPie;
        defectTypesTable = response.defectTypesTable;
    }
    const isLoading: boolean = useSelector(isDashboardLoadingSelector);

    useEffect(() => {
        if (history.action !== 'REPLACE') {
            dispatch(getDefectsStatusBySeverity());
        }
    }, [location.search]);
    return <Fragment>
        <Dimmer active={isLoading} inverted>
            <Loader className='dimLoader'><FormattedMessage id='dashboard.loading_data_please_wait' /></Loader>
        </Dimmer>
        <Stats summary={summary} />
        <Header as='h3'><FormattedMessage id='dashboard.page.title.defects_by_severity' /></Header>
        <div className='defectsStatus'>
            <HasFeature feature='m_defect_category' >
                {defectTypesPie ? <div className='pies flex flex-col'>
                    <div className='flex flex-row'>
                        <DefectsPie data={defectTypesPie} colors={response && response.colors} isTopLevel={!objectHasKeys(defectTypesTable.defect_type)} />
                        <DefectCategories defectTypes={defectTypesTable} colors={response && response.colors} />
                    </div>
                    <div className='text-sm text-gray-600 pt-6'><FormattedMessage id='dashboard.defects.general_defects_counted_as_one' /></div>
                </div> : null}
            </HasFeature>
            <Header as='h3'><FormattedMessage id='dashboard.page.title.defects_by_month_and_severity' /></Header>
            <div className='bars'>
                {data && data.length > 0 && <ResponsiveBar
                    data={data}
                    keys={types}
                    indexBy='month'
                    theme={config.bar.theme}
                    padding={config.bar.padding}
                    colors={config.defectsBarColors.colors}
                    borderRadius={2}
                    labelTextColor={config.defectsBarColors.labelTextColor}
                    labelSkipHeight={config.bar.labelSkipHeight}
                    axisBottom={config.bar.axisBottom}
                    axisLeft={config.bar.axisLeft}
                    tooltip={(d) => (
                        <div className='px-2 py-1 bg-white shadow-xs'>
                            {d.indexValue}. {d.id !== '' ? capitaliseWord(d.id.toString()) : 'No type'}: {d.value}
                        </div>
                    )}
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                        },
                    ]}
                />}
                {data && data.length === 0 && <NoData />}
                <div className='text-sm text-gray-600'><FormattedMessage id='dashboard.defects.general_defects_counted_as_one' /></div>
            </div>
        </div>
    </Fragment>;
};

export default DefectsStatus;
