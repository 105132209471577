import React, { Fragment, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';
import { SupplierLink } from '../../../backend_api/models';

type Props = {
    supplierLinks: SupplierLink[];
}

const SupplierLinkList = (props: Props) => {
    return <Fragment>
        {props.supplierLinks.map((link) =>
            <LinkComponent
                key={link.supplier_id}
                link={`/suppliers/${link.supplier_id}`}
                content={renderSupplierLink(link)} />
        )}
    </Fragment>
}

export function renderSupplierLink(supplier: { name: string, number?: string }) {
    return supplier.number
        ? `${supplier.name}: ${supplier.number}`
        : supplier.name;
}

export function renderProductionUnitLink(pu: { name?: string | undefined, number?: string | undefined }) {
    if (pu.number && pu.name) {
        return `${pu.name}: ${pu.number}`
    }

    return pu.name || pu.number
}

export function LinkComponent(props: {
    /* app route */
    link: string,
    /* Visible to the user, overrides children. */
    content?: string
    /* optional override for behaviour. Default behaviour follows link */
    onClick?: () => void,
} & PropsWithChildren) {
    const history = useHistory()

    function onClick() {
        if (props.onClick) {
            return props.onClick()
        } else {
            history.push(props.link)
        }
    }

    return (
        <div className='cursor-pointer' onClick={onClick} >
            <span className='link'>{props.content || props.children}</span>
        </div>
    )
}

export default SupplierLinkList;