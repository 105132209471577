import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { InputCustomFieldDefinition, InputCustomFieldDefinitionDateTypeEnum, InputCustomFieldDefinitionMultipleChoiceTypeEnum, InputCustomFieldDefinitionNumberTypeEnum, InputCustomFieldDefinitionTextTypeEnum, NumberFilter } from '../../../backend_api/models';
import Icon from '../../../base/components/basic/Icon';
import Input from '../../../base/components/basic/Input';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { Context } from '../../../base/types';
import { isUndefinedOrNullOrEmptyString, objectIsEmpty, sortArrayByKey, twMerge, useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getProductionUnitsCustomFieldsListSelector } from '../../productionUnits/slice/productionUnitsSlice';
import { getSupplierCustomFieldsListSelector } from '../../suppliers/slice/suppliersSlice';
import { setFilter } from '../filtersActions';
import DateFilter from './DateFilter';
import DropdownFilter from './DropdownFilter';

type Props = {
    className?: string;
    isFetching?: boolean;
    type: Context.Suppliers | Context.ProductionUnits;
};

const selectedClass = 'border-brand border rounded-lg';
const SupplierCustomFieldsFilter = (props: Props): React.ReactElement => {
    const { className, type } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const currentFilters = qs.parse(location.search as string).scf_ids && JSON.parse(qs.parse(location.search as string).scf_ids as string);
    const customFieldsList = useSelector(type === Context.Suppliers ? getSupplierCustomFieldsListSelector : getProductionUnitsCustomFieldsListSelector);
    const [selectedFilters, setSelectedFilters] = useState<any>(currentFilters);
    const [selectedFiltersList, setSelectedFiltersList] = useState<any>([]);
    const [showFilters, setShowFilters] = useState(true);

    const updateSelectedFilters = (filterId: string, value: any) => {
        const _selectedFilters = { ...selectedFilters }
        if (!_selectedFilters[filterId]) {
            _selectedFilters[filterId] = {};
        }

        let isSet = true;
        if (Array.isArray(value)) {
            if (value.length === 0) {
                isSet = false
            } else {
                if (value.length === 2) {
                    if (isUndefinedOrNullOrEmptyString(value[0]) && isUndefinedOrNullOrEmptyString(value[1])) {
                        isSet = false;
                    }
                }
            }
        }
        else {
            isSet = !objectIsEmpty(value);
        }
        if (isSet) {
            const o = { definition_id: filterId, value }
            _selectedFilters[filterId] = o;
        } else {
            delete _selectedFilters[filterId];
        }
        setSelectedFilters(_selectedFilters);
        setSelectedFiltersList(Object.keys(_selectedFilters));
        dispatch(setFilter('scf_ids', objectIsEmpty(_selectedFilters) ? [] : [JSON.stringify(_selectedFilters)]));
    }


    useEffect(() => {
        // dispatch(type === Context.Suppliers ? getSupplierCustomFieldsList() : getProductionUnitsCustomFieldsList());
    }, []);
    useEffect(() => {
        if (!currentFilters) {
            setSelectedFilters(undefined);
        }
    }, [currentFilters])
    if (!showFilters) {
        return <div className='p-3 bg-white cursor-pointer' onClick={() => setShowFilters(!showFilters)}></div>
    }
    return showFilters && <div className={twMerge('', className)}>
        <div className='flex flex-col px-0'>
            {customFieldsList && customFieldsList.groups.length === 0 && <div className='flex px-4 pt-4'>
                <div className='mr-2'><FormattedMessage id='filters.no_custom_field_filters_created' /></div>
                <div className='space-x-1 text-secondary flex items-center'>
                    <div className='space-x-2 flex mx-2 font-bold'>
                        <Icon name='tips_and_updates' className='' />
                        <div>
                            <FormattedMessage id='filters.no_custom_field_filters.tip' />
                        </div>
                    </div>
                    <FormattedMessage id={type === Context.Suppliers ? 'filters.no_custom_field_filters.tip_for_setup.suppliers' : 'filters.no_custom_field_filters.tip_for_setup.production_units'} />.
                    <a className='link' href='https://support.qarmainspect.com/en/support/solutions/articles/43000716913-filters-for-suppliers-and-production-units'><FormattedMessage id='filters.no_custom_field_filters.learn_more' /></a>
                </div>
            </div>}
            {customFieldsList && customFieldsList.groups.map((cfg, i) => {
                const onlyText = (cfg.custom_field_definitions.filter((d) => d.type.type === InputCustomFieldDefinitionTextTypeEnum.Text).length) === cfg.custom_field_definitions.length;
                return !onlyText && <div key={'cfg_' + cfg.id} className={twMerge('flex flex-wrap pb-3 pt-3 w-full px-4', i < customFieldsList.groups.length - 1 && ' border-b')}>
                    <div className='text-sm w-full font-bold text-secondary'>{cfg.name.text}</div>
                    {cfg.custom_field_definitions.map((cfd) => {
                        return !cfd.hidden && cfd.type.type !== InputCustomFieldDefinitionTextTypeEnum.Text &&
                            <div key={'cfd_' + cfd.id} className='py-1 flex flex-col space-y-2 pr-4 w-full sm:w-1/4'>
                                <div className='text-secondary text-sm'>{cfd.name.text}</div>
                                <FilterInput customField={cfd} groupId={cfg.id} onFilterUpdated={(id, isSet) => {
                                    updateSelectedFilters(id, isSet);
                                }}
                                    isFetching={props.isFetching} />
                            </div>
                    })}
                </div>
            })}
        </div>
    </div>
}
type NumberIntervalProps = {
    id: string;
    updateInterval(id: string, value: any[]);
    value: NumberFilter;
    disabled?: boolean;
}
const NumberInterval = (props: NumberIntervalProps) => {
    // const [hasFocus, setHasFocus] = useState(false);
    const intl = useIntl();
    const [hasFocus, setHasFocus] = useState(undefined);
    const [lowerBound, setLowerBound] = useState(props.value && props.value.lower_bound);
    const [upperBound, setUpperBound] = useState(props.value && props.value.upper_bound);
    const [value, setValue] = useState([props.value && props.value.lower_bound, props.value && props.value.upper_bound]);
    const isMobile = useIsMobileDevice();

    let prevValue = null;

    const update = (isLower: boolean, val) => {
        if (isLower) {
            setLowerBound(val)
        } else {
            setUpperBound(val);
        }
    };

    const hasChanged = (v, pos) => {
        if (props.value) {
            const c = pos === 0 ? props.value.lower_bound : props.value.upper_bound;
            if (isUndefinedOrNullOrEmptyString(c) && isUndefinedOrNullOrEmptyString(v)) {
                return false;
            }
            if (v !== c) {
                return true;
            }
        } else {
            if (value && value[pos]) {
                return prevValue !== value[pos].toString();
            }
            return !isUndefinedOrNullOrEmptyString(v);
        }
        return false
    };

    const clear = () => {
        setValue([undefined, undefined]);
        setUpperBound(null);
        setLowerBound(null);
        props.updateInterval(props.id, ['', '']);
    };

    const updateInterval = () => {
        props.updateInterval(props.id, [lowerBound, upperBound]);
    };

    const handleKeyUp = (e) => {
        const keyCode = e.keyCode;
        if (keyCode === 13) {
            updateInterval();
            if (isMobile) {
                e.target.inputMode = 'none'
                e.target.blur();
            }
        }
    };

    useEffect(() => {
        if (hasFocus === false) {
            updateInterval();
        }
    }, [hasFocus]);

    useEffect(() => {
        if (props.value) {
            if (props.value.lower_bound && props.value.lower_bound !== lowerBound) {
                setLowerBound(props.value.lower_bound);
            }
            if (props.value.upper_bound && props.value.upper_bound !== upperBound) {
                setUpperBound(props.value.upper_bound);
            }
        } else {
            setLowerBound(null);
            setUpperBound(null);
        }
    }, [props.value])
    return <div className='flex items-center space-x-2'>
        <div className={twMerge('', lowerBound && selectedClass)}>
            <Input
                readOnly={props.disabled}
                type='number'
                label={intl.formatMessage({ id: 'custom_fields.filters.number_interval.from' })}
                value={lowerBound ?? ''}
                name='one'
                onFocus={(e) => {
                    setHasFocus(true);
                    if (isMobile) {
                        e.target.inputMode = 'numeric'
                    }
                }}
                onKeyUp={!props.disabled ? handleKeyUp : null}
                onChange={(e) => {
                    prevValue = (lowerBound);
                    let v = e.currentTarget.value;
                    if (v === '') {
                        v = null;
                    }
                    update(true, v);
                }}
                onBlur={(e) => {
                    if (hasChanged(e.currentTarget.value, 0)) {
                        setHasFocus(false);
                        if (isMobile) {
                            // Hide the virtual keyboard
                            e.target.inputMode = 'none'
                        }
                    }
                }} />
        </div>
        <div className={twMerge('', upperBound && selectedClass)}>
            <Input
                readOnly={props.disabled}
                type='number'
                name='zero'
                label={intl.formatMessage({ id: 'custom_fields.filters.number_interval.to' })}
                value={upperBound ?? ''}
                onFocus={(e) => {
                    setHasFocus(true);
                    if (isMobile) {
                        e.target.inputMode = 'numeric'
                    }
                }}
                onKeyUp={!props.disabled ? handleKeyUp : null}
                onChange={(e) => {
                    prevValue = (upperBound);
                    let v = e.currentTarget.value;
                    if (v === '') {
                        v = null;
                    }
                    update(false, v);
                }}
                onBlur={(e) => {
                    if (hasChanged(e.currentTarget.value, 1)) {
                        setHasFocus(false);
                        if (isMobile) {
                            // Hide the virtual keyboard
                            e.target.inputMode = 'none'
                        }
                    }
                }} />
        </div>
        {(lowerBound || upperBound) && <div onClick={() => clear()}><RemoveIcon className='text-sm text-secondary cursor-pointer' /></div>}
    </div>
}
const FilterInput = (props: { isFetching?: boolean; customField: InputCustomFieldDefinition, groupId: string, onFilterUpdated(id: string, value: any) }) => {

    const locationParsed = qs.parse(location.search as string);
    const currentFilters = locationParsed.scf_ids && (locationParsed.scf_ids && JSON.parse(locationParsed.scf_ids as string)) as any || null;
    const { customField, onFilterUpdated } = props;
    const [selectedValue, setSelectedValue] = useState({});
    const updateMultipleChoice = (id: string, value: any, multipleAllowed: boolean) => {
        const s = { ...selectedValue };
        if (!s[customField.id]) {
            s[customField.id] = {}
        }
        if (value === null) {
            s[customField.id] = {};
        } else {
            s[customField.id].options = value;
        }
        onFilterUpdated(customField.id, s[customField.id]);
    }
    const updateIntervalDo = (id: string, value: any[]) => {
        const s = { ...selectedValue };
        if (!s[customField.id]) {
            s[customField.id] = {}
        }
        if (isUndefinedOrNullOrEmptyString(value) || (isUndefinedOrNullOrEmptyString(value[0]) && isUndefinedOrNullOrEmptyString(value[1]))) {
            s[customField.id] = {}
        } else {
            s[customField.id].lower_bound = value[0];
            s[customField.id].upper_bound = value[1];
        }
        setSelectedValue(s);
        onFilterUpdated(customField.id, s[customField.id]);
    }
    const updateDates = (id: string, startDate, endDate) => {
        const s = { ...selectedValue };
        if (!s[customField.id]) {
            s[customField.id] = {}
        }
        if (isUndefinedOrNullOrEmptyString(startDate) && isUndefinedOrNullOrEmptyString(endDate)) {
            s[customField.id] = {};
        } else {
            !isUndefinedOrNullOrEmptyString(startDate) ? s[customField.id].start_date = startDate : s[customField.id].start_date = null;
            !isUndefinedOrNullOrEmptyString(endDate) ? s[customField.id].stop_date = endDate : s[customField.id].stop_date = null;
        }
        setSelectedValue(s);
        onFilterUpdated(customField.id, s[customField.id]);
    }
    if (customField.type.type === InputCustomFieldDefinitionMultipleChoiceTypeEnum.MultipleChoice) {
        const multipleAllowed = customField.type.multiple_selection_allowed;
        const options = customField.type.options.map((opt) => ({ value: opt.option_id, id: opt.option_id, text: opt.name.text }));
        sortArrayByKey(options, 'text');
        const hasValue = selectedValue && selectedValue[customField.id] || false;
        const selected = (currentFilters && currentFilters[customField.id] && currentFilters[customField.id].value.options) || (selectedValue && selectedValue[customField.id] && selectedValue[customField.id]) || [];
        const dd = <div className={twMerge('flex w-full', selected.length > 0 && selectedClass)}>
            <DropdownFilter
                placeholder={!hasValue && 'Not selected'}
                name='production_units'
                options={options}
                multiple={true}
                selectedItems={selected}
                handleSave={(value): void => {
                    const v = value.length === 0 ? null : value;
                    updateMultipleChoice(customField.id, multipleAllowed ? v : v, true);
                }}
                showLoader={true}
                disabled={false}
                search={true}
                fluid={true}
            />
        </div>
        return <div className='w-full pb-2 flex'>{dd}</div>

    }

    if (customField.type.type === InputCustomFieldDefinitionNumberTypeEnum.Number) {
        return <div className='flex items-center space-x-2 pb-2'>
            <NumberInterval id={customField.id} updateInterval={(id, value) => {
                updateIntervalDo(id, value);
            }}
                value={currentFilters && currentFilters[customField.id] && currentFilters[customField.id].value || null}
                disabled={props.isFetching}
            />
        </div>
    }
    if (customField.type.type === InputCustomFieldDefinitionTextTypeEnum.Text) {
        return <div className='flex w-1/3 items-center space-x-2'>
            Text - SHOULD NOT BE DISPLAYED
        </div>
    }
    if (customField.type.type === InputCustomFieldDefinitionDateTypeEnum.Date) {
        return <div className='flex pb-2'>
            <div className={twMerge('flex w-full items-center space-x-2', currentFilters && currentFilters[customField.id] && currentFilters[customField.id].value && selectedClass)}>
                <DateFilter
                    dontUseGlobalDate
                    startDate={currentFilters && currentFilters[customField.id] && currentFilters[customField.id].value && currentFilters[customField.id].value.start_date}
                    endDate={currentFilters && currentFilters[customField.id] && currentFilters[customField.id].value.stop_date}
                    showIcon
                    className='w-full text-[#888]' // todo: Michael fix this color
                    onChangeReturnAsSeparateDates={(s, e) => updateDates(customField.id, s, e)}
                    selectedClassName={selectedClass} />
            </div>
        </div>
    }
}

export default SupplierCustomFieldsFilter;
