import { CorrectiveActionsState } from '../../backend_api/models';

export const getDisplayState = (state: CorrectiveActionsState): string => {
    switch (state) {
        case CorrectiveActionsState.Finding:
            return 'Open';
        case CorrectiveActionsState.Review:
            return 'In Review';
        case CorrectiveActionsState.Closed:
            return 'Closed';
        case CorrectiveActionsState.InProgress:
            return 'In Progress'
    }
}

export const getFullStateName = (state: CorrectiveActionsState): string => {
    switch (state) {
        case CorrectiveActionsState.Finding:
            return 'Open';
        case CorrectiveActionsState.Review:
            return 'In Review';
        case CorrectiveActionsState.Closed:
            return 'Closed';
        case CorrectiveActionsState.InProgress:
            return 'In Progress'
    }
}

export const getColor = (state) => {
    switch (state) {
        case CorrectiveActionsState.Closed:
            return 'status-approved';
        case CorrectiveActionsState.Finding:
            return 'status-planned';
        case CorrectiveActionsState.Review:
            return 'status-pending';
        case CorrectiveActionsState.InProgress:
            return 'status-in-progress';
    }
}