import { createAsyncThunk } from '@reduxjs/toolkit';
import { request, request2 } from '../../../base/api';
import { showModal } from '../../../base/baseSlice';
import { AppThunk } from '../../../base/types';
import history from '../../../store/history';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import { areAllInspectionsEqual, orderWithInspectionsRequestSuccess } from './editInspectionActions';

const catchException = errorHandling.handler.catchException;

const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;


export const duplicateCombinedInspection = createAsyncThunk<void, { combinedInspectionId: string, orderId: string, inspectionName: string, reloadData: boolean }>(
    'duplicateCombinedInspection',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const url = 'inspections/' + params.combinedInspectionId + '/duplicate_combined/' + params.orderId;
        const rq = await request2(url, { method: 'post' });
        const status = rq.status;
        if (!rq.ok) {
            const error = await rq.json();
            dispatch(sendErrorMessage([{ text: 'error_message.the_inspection_was_not_duplicated', values: { inspectionName: params.inspectionName } }]));
            catchException('duplicateInspection', {
                endpoint: 'inspections/:inspectionId/duplicate_combined/:orderId',
                request: url,
                status: rq.status,
            }, { error, rq, body: params, method: 'post' });
            return rejectWithValue;
        }
        const data = await rq.json();
        if (status === 200) {
            if (params.reloadData) {
                dispatch(orderWithInspectionsRequestSuccess(data.order));
                dispatch(areAllInspectionsEqual());
            }
            dispatch(sendStatusMessage([{ text: 'status_message.the_inspection_was_succesfully_duplicated', values: { inspectionName: params.inspectionName } }], 10000));
            dispatch(showModal({ id: 'new_combined_inspection_created', show: true, data: data.master_order_id }))
        }
        if (status === 202) {
            dispatch(showModal({ id: 'new_combined_inspection_creation_takes_long_time_check_email', show: true, data: data.master_order_id }))
        }
        return await rq.json();
    });


export const duplicateInspection = (inspectionId: string, orderId: string, inspectionName: string): AppThunk => {
    return ((dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/duplicate/' + orderId;
        return request(url, {
            method: 'post',
        })
            .then((data) => {
                if (history.location.pathname.includes(orderId)) {
                    dispatch(orderWithInspectionsRequestSuccess(data));
                    dispatch(areAllInspectionsEqual());
                } else {
                    history.replace('/inspections/edit_inspection/' + orderId);
                }
                dispatch(sendStatusMessage([{ text: 'status_message.the_inspection_was_duplicated_and_succesfully_added_to_this_order', values: { inspectionName } }], 10000));
            })
            .catch((error) => {
                dispatch(sendErrorMessage([{ text: 'error_message.the_inspection_was_not_duplicated', values: { inspectionName } }]));
                catchException('duplicateInspection', {
                    endpoint: 'inspections/[inspectionId]/duplicate/[orderId]',
                    request: url,
                    status: error.status,
                }, { error, inspectionId, orderId });
            });
    });
};
