/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UUIDRequired } from './UUIDRequired';

/**
 * A link between a CA and an Inspection
 *
 */
export type InspectionLink = {
    inspection_id: UUIDRequired;
    /**
     * The inspection link's item name
     */
    item_name: string;
    /**
     * The inspection link's item number
     */
    item_number: string;
    /**
     * The inspection link's order number
     */
    order_number: string;
    type: InspectionLink.type;
};

export namespace InspectionLink {

    export enum type {
        INSPECTION_LINK = 'InspectionLink',
    }


}

