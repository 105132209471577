import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TrainingVideoData } from '../types';
import Video from './Video';

interface TrainingVideoProps {
    trainingVideo: TrainingVideoData;
    index: number;
}
const TrainingVideo = (props: TrainingVideoProps): React.ReactElement => {
    return (
        <div className='training-video-video'>
            <Video
                thumbnail={props.trainingVideo.video.thumbnail}
                videoResources={[{ url: props.trainingVideo.video.src, mime_type: 'video/mp4' }]}
            />
            <div className='training-video-info'>
                <div className='training-video-info-header'>
                    {props.index + '. ' + props.trainingVideo.header}
                </div>
                <div className='training-video-info-description'>
                    {props.trainingVideo.description}
                </div>
                <div>
                    <FormattedMessage id='trainingvideos.learningpoints' />
                    <ul className='bullet-list'>
                        {props.trainingVideo.learningpoints.map((point) => {
                            return (
                                <li key={JSON.stringify(point)}>
                                    {point}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TrainingVideo;