import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';

type Props =  {
   className?: string;
   children?: ComponentOrStringType;
};

const FormField = (props: Props): React.ReactElement => {
   const { className } = props;
   const location = useLocation();
   const  dispatch = useDispatch();
   const  intl = useIntl();
   const  s = useState();
   useEffect(() => {
    //
}, []);
   return <div className={twMerge('py-2 w-full flex flex-col', className)}>{props.children}</div>
}
export default FormField;
