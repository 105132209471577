import React from 'react';

type Props = {
    comment: string;
}

const CommentEventDetails = (props: Props) => {
    return <div className='flex flex-col mb-4'>
        {/* <label><FormattedMessage id='corrective_actions.events.comment.label' /></label> */}
        <div className='flex text-secondary whitespace-pre-wrap'>
            {props.comment}
        </div>
    </div>
}


export default CommentEventDetails;