import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setFilter } from '../filtersActions';
import { DropdownFilter } from './DropdownFilter';
import Filter from './Filter';

const filterName = 'compliance_req_cat';

type Props = {
    context?: string;
}

const ComplianceRequirementCategoryFilter = (props: Props) => {
    const labelId = 'compliance.filters.requirements_category.label';
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const selectedStates = getLocationEntry(location, filterName);
    const options = [{text: 'Product safety', value:'cat_1'},{text:'Certification', value:'cat_2'}, {text: 'Chemicals', value:'cat_3'}]
    return <Filter labelId={labelId}>
        <DropdownFilter
            name={filterName}
            options={options}
            selectedItems={selectedStates || []}
            handleSave={(items) => dispatch(setFilter(filterName, items))}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.compliance_requirements_category.placeholder' })}
        />
    </Filter>
}

export default ComplianceRequirementCategoryFilter;