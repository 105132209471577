import React from 'react';
import { useIntl } from 'react-intl';
import { CorrectiveActionEvent } from '../../../backend_api/models';
import ApproveRejectResolveStatus from './ApproveRejectResolveStatus';
import CommentEventDetails from './CommentEventDetails';
import NotifiedEvent from './NotifiedEvent';

type Props = {
    event: CorrectiveActionEvent;
}

const ApproveEventDetails = (props: Props) => {
    const intl = useIntl();
    return <div className='flex space-y-1 w-full flex-col'>
        <ApproveRejectResolveStatus positive={true} labelText={intl.formatMessage({ id: 'corrective_actions.events.status.approved' })} />
        <CommentEventDetails comment={props.event.approved_event.comment} />
        <NotifiedEvent event={props.event} />
    </div>
}

export default ApproveEventDetails;