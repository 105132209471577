import { createSelector } from 'reselect';
import { AppState, byId } from '../../base/types';
import { Order } from './types';

const editInspectionDataReadySelector = (state: AppState): boolean => state.app.inspections.editInspectionDataReady;
export const getEditInspectionDataReady = createSelector(
    [editInspectionDataReadySelector],
    (ready) => ready,
);
const isCreatingOrder = (state: AppState): boolean => state.app.inspections.isCreatingOrder;
export const isCreatingOrderSelector = createSelector(
    [isCreatingOrder],
    (isCreating) => isCreating,
);

const editInspectionChangedInspectionsSelector = (state: AppState): byId<{ [name: string]: unknown }> => {
    return state.app.inspections.changedInspections;
};
export const getEditInspectionChangedInspections = createSelector(
    [editInspectionChangedInspectionsSelector],
    (changed) => changed,
);

const isSavingSelector = (state: AppState): boolean => state.app.inspections.isSaving;
export const getEditInspectionIsSaving = createSelector(
    [isSavingSelector],
    (isSaving) => isSaving,
);

const isAddingSelector = (state: AppState): boolean => state.app.inspections.isAddingInspection;
export const getIsAddingInspection = createSelector(
    [isAddingSelector],
    (adding) => adding,
);

const getOrderWithInspections = (state): Order => state.app.inspections.order;
export const getOrderWithInspectionsSelector = createSelector(
    [getOrderWithInspections],
    (order) => order,
);

