import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Table } from 'semantic-ui-react';
import { deepCopy } from '../../../base/utils';
import MultiLangualText from '../../globals/components/MultiLangualText';
import { MultiLanguageText } from '../../globals/types';
import { TextTable } from '../types';

type TextTableDetailsProps = {
    data: TextTable;
    language: string;
    update(data: TextTable);
    show: boolean;
    cancel();
}

const TextTableDialog = (props: TextTableDetailsProps): React.ReactElement => {
    const intl = useIntl();
    const [data, setData] = useState<TextTable>(props.data);
    const [focus, setFocus] = useState<{ row: number; column: number }>({ row: -1, column: -1 });

    useEffect(() => {
        if (!props.data) {
            props.update({ columns: [{ name: { C: '' } }], rows: [{ name: { C: '' } }], values: [], version: 1, use_custom_table: false, custom_table_definition_id: null });
        }
        else {
            setData(props.data);
        }
    }, [props.data])

    const changeColumn = (column: MultiLanguageText, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.columns[index] = { name: column };
        setData(dataCopy);
    }

    const changeRow = (column: MultiLanguageText, index: number): void => {
        const dataCopy = deepCopy(data);
        dataCopy.rows[index] = { name: column };
        setData(dataCopy);
    }

    const addColumn = (): void => {
        const dataCopy = deepCopy(data);
        dataCopy.columns.push({ name: { C: '' } });
        setData(dataCopy);
        setFocus({ row: -1, column: dataCopy.columns.length - 1 })
    }

    const removeColumn = (index: number): void => {
        if (data.columns.length > 1) {
            const dataCopy = deepCopy(data);
            dataCopy.columns.splice(index, 1);
            setData(dataCopy);
        }
    }

    const addRow = (): void => {
        const dataCopy = deepCopy(data);
        dataCopy.rows.push({ name: { C: '' } });
        setData(dataCopy);
        setFocus({ row: dataCopy.rows.length - 1, column: -1 })
    }

    const removeRow = (index: number): void => {
        if (data.rows.length > 1) {
            const dataCopy = deepCopy(data);
            dataCopy.rows.splice(index, 1);
            setData(dataCopy);
        }
    }

    const cancel = (): void => {
        props.cancel();
    }

    const paddingCells = []

    if (data) {
        for (let i = 0; i < data.columns.length + 1; i++) {
            paddingCells.push(<Table.Cell />)
        }
    }

    return (
        <Modal
            style={{ maxWidth: '1200px', width: 'unset' }}
            open={props.show}
            closeIcon={true}
            onClose={cancel}
        >
            <Modal.Header>
                <FormattedMessage id='checklists.editor.text_table.dialog_header' />
            </Modal.Header>
            <Modal.Content >
                <div style={{ overflowY: 'auto' }}>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell />
                            {data && data.columns.map((column, index) => {
                                return (
                                    <Table.HeaderCell key={index} data-test-id="text-table-column">
                                        <div style={{ display: 'flex' }}>
                                            <MultiLangualText
                                                style={{ marginRight: '5px', width: '150px' }}
                                                presentation='area'
                                                originalText={column.name}
                                                language={props.language}
                                                placeholder={intl.formatMessage({ id: 'checklists.editor.text_table.placeholder' })}
                                                save={(name): void => changeColumn(name, index)}
                                                focus={focus.column === index}
                                            />
                                            <Button className="inverted-color" disabled={props.language !== 'C'} onClick={(): void => removeColumn(index)} data-test-id="remove-text-table-column">-</Button>
                                        </div>
                                    </Table.HeaderCell>
                                );
                            })}
                            <Table.HeaderCell key={'add-column'}>
                                <Button className="inverted-color" disabled={props.language !== 'C'} onClick={addColumn} data-test-id="add-text-table-column">+</Button>
                            </Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {data && data.rows.map((row, index) => {
                                return (
                                    <Table.Row key={index} data-test-id="text-table-row">
                                        <Table.Cell>
                                            <div style={{ display: 'flex' }}>
                                                <Button disabled={props.language !== 'C'} onClick={(): void => removeRow(index)} data-test-id="remove-text-table-row">-</Button>
                                                <MultiLangualText
                                                    style={{ width: '150px' }}
                                                    presentation='area'
                                                    originalText={row.name}
                                                    language={props.language}
                                                    placeholder={intl.formatMessage({ id: 'checklists.editor.text_table.placeholder' })}
                                                    save={(name): void => changeRow(name, index)}
                                                    focus={focus.row === index}
                                                />
                                            </div>
                                        </Table.Cell>
                                        {paddingCells}
                                    </Table.Row>
                                );
                            })}
                            <Table.Row>
                                <Table.Cell>
                                    <Button disabled={props.language !== 'C'} onClick={addRow} data-test-id="add-text-table-row">+</Button>
                                </Table.Cell>
                                {paddingCells}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={(): void => props.update(data)} content={intl.formatMessage({ id: 'globals.confirmation.save' })} />
                <Button onClick={cancel} content={intl.formatMessage({ id: 'globals.confirmation.cancel' })} />
            </Modal.Actions>
        </Modal>
    );
}

export default TextTableDialog;