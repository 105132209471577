/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Represents a value that has not been set or is being unset.
 *
 */
export type AuditCustomFieldNotSet = {
    type: AuditCustomFieldNotSet.type;
};

export namespace AuditCustomFieldNotSet {

    export enum type {
        NOT_SET = 'not_set',
    }


}

