import classnames from 'classnames';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Sticky from 'react-sticky-el';
import { Icon, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit, AuditFeaturesEnum } from '../../../../backend_api/models';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import Loader from '../../../../base/components/Loader';
import Button from '../../../../base/components/basic/Button';
import TranslateComment from '../../../../base/components/comments/TranslateComment';
import { HEADER_HEIGHT } from '../../../../base/config';
import { AppState } from '../../../../base/types';
import { useIsMobileDevice } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import ReportApprovalItemShared from '../../../approval/components/ReportApprovalItemShared';
import AuditChangeConclusionDialog from '../../../audit/components/AuditChangeConclusionDialog';
import AuditConclusion from '../../../audit/components/AuditConclusion';
import AuditConclusionSegment from '../../../audit/components/AuditConclusionSegment';
import AuditEditApprovalFlow from '../../../audit/components/AuditEditApprovalFlow';
import AuditStatus from '../../../audit/components/AuditStatus';
import AuditWatchers from '../../../audit/components/AuditWatchers';
import AuditsCommentsAndHistory from '../../../audit/components/AuditsCommentsAndHistory';
import { getAuditCustomConclusionsSelector, getAuditHistoryLengthSelector, getAuditsLoadingSelector } from '../../../audit/selectors/auditSelectors';
import ContentSection from '../../../pageLayouts/components/ContentSection';
import { UserNameText } from '../../../users/components/Widgets';

interface SharedProps {
    isBulk?: boolean;
}
type AuditType = {
    audit: Audit;
    auditorComment: any;
};

type ReportApprovalProps = (AuditType) & SharedProps;


const AuditReportApproval = (props: ReportApprovalProps): React.ReactElement => {

    const dispatch = useAppDispatch();
    const audit = props.audit;
    const auditorComment = props.auditorComment;
    const loading = useSelector(getAuditsLoadingSelector);
    const commentsCnt = useSelector(getAuditHistoryLengthSelector);
    const isMobile = useIsMobileDevice();
    const [expanded, setExpanded] = useState(isMobile ? false : true);
    const hasAuditorConclusion = audit && audit.auditor_conclusion !== null;
    const hasFinalConclusion = audit && audit.final_conclusion;
    const conclusions = useSelector((state: AppState) => getAuditCustomConclusionsSelector(state, audit.audit_type && audit.audit_type.id));
    const hasConclusions = conclusions.length > 0;
    const canChangeConclusion = audit.features.includes(AuditFeaturesEnum.SetFinalConclusion) && audit.approval_flow_status !== 'not_set' && audit.status === 'report';
    const canEditApprovalFlow = audit.features.includes(AuditFeaturesEnum.EditApprovalFlow);
    const statusButton = canChangeConclusion && hasConclusions && <div className='border-t p-4'>
        <AuditChangeConclusionDialog audit={audit} trigger={<Button disabled={audit.status !== 'report'} className='text-sml'>{hasFinalConclusion ? <FormattedMessage id='audits.conclusion.change_conclusion' /> : <FormattedMessage id='audits.conclusion.set_conclusion' />}</Button>} /></div>;
    const expand = (_expanded: boolean): void => {
        dispatch(setLayoutAttribute('reportApprovalExpanded', _expanded));
        setExpanded(_expanded);
        if (!_expanded) {
            scrollTo(0, HEADER_HEIGHT - 2);
        }
    };
    const comments = <AuditsCommentsAndHistory auditId={audit.id} loadComments={false} />;
    const data = <ContentSection content={
        <div className='-m-4'><Loader active={loading} className='my-auto' spinner={<Icon name='circle notch' loading color='green' size='large' />}>
            {!expanded && isMobile &&
                <Sticky stickyStyle={{ zIndex: 5 }} boundaryElement='.top-header' pushing={false}>
                    <div className='flex flex-col p-4 bg-white pb-2 border-b shadow-sm'>
                        <div className='flex'>
                            <div className='flex space-x-2 justify-between w-full pb-4'>
                                <div className='w-2/4 space-y-2'>
                                    <div className='font-bold'><FormattedMessage id='audits.status.inspector_label' /></div>
                                    <div><UserNameText user={audit.assigned_user} /></div>
                                </div>
                                {/* <div className='w-full'><AuditConclusionSegment inverted={false} audit={audit} /></div> */}
                                <div className='w-2/4 space-y-2'>
                                    <div className='font-bold'><FormattedMessage id='audits.status.status_label' /></div>
                                    <AuditStatus className={'w-full'} audit={audit} />
                                </div>
                            </div>
                        </div>
                        <div className=' justify-center flex border-t lg:hidden pt-4 pb-2'>
                            <div className='flex justify-center space-x-2 link noUnderline' data-test-id='btn-info-show-more' onClick={(): void => expand(true)}><div><FormattedMessage id='inspections.show_more' /></div>({commentsCnt})<Icon name='chevron down' /></div>
                        </div>
                    </div>
                </Sticky>}
            {expanded && <Fragment>
                <AuditConclusionSegment audit={audit} />
                <ContentSection className={twMerge('border-0 mb-4', hasConclusions && 'border-b')} content={<>
                    {(hasAuditorConclusion || hasConclusions) && <ReportApprovalItemShared
                        heading={<FormattedMessage id='audits.conclusion.auditors_conclusion' />}
                        user={audit.assigned_user}
                        status={hasAuditorConclusion ? <AuditChangeConclusionDialog disabled={!hasConclusions || !canChangeConclusion} audit={audit} trigger={<span><AuditConclusion type='auditor' audit={audit} className='px-3 py-2 text-sm' /></span>} /> : <AuditConclusion audit={audit} className='p-3 text-sm' notDone />}
                    />}
                    <ReportApprovalItemShared
                        heading={<FormattedMessage id='audits.conclusion.auditors_comment' />}
                        comment={auditorComment && auditorComment.comment ? <div><TranslateComment comment={auditorComment} /></div> : <FormattedMessage id='audit_report.auditor_no_comment' />}
                        showCommentIcon
                    />
                </>}
                />
                {hasConclusions && <AuditEditApprovalFlow canEditApprovalFlow={canEditApprovalFlow} auditId={audit.id} approvalFlow={audit.approval_flow} type='report' />}
                {audit.status === 'report' ? statusButton : <Popup trigger={statusButton}>
                    <Popup.Header>
                        <Icon name='info circle' color='blue' />
                        <FormattedMessage id='audits.audit_in_progress_warning.header' /></Popup.Header>
                    <Popup.Content><FormattedMessage id='audits.audit_in_progress_warning.text' /></Popup.Content></Popup>}

                {isMobile && comments}
                {isMobile && <AuditWatchers auditId={audit.id} watchers={audit.watchers} isReport={true} />}
                {expanded && <div className='justify-center flex p-1 lg:hidden pt-4 pb-4'>
                    <div className='flex justify-center space-x-2 link noUnderline' data-test-id='btn-info-show-more' onClick={(): void => expand(false)}><div><FormattedMessage id='inspections.hide' /></div><Icon name='chevron up' /></div>
                </div>}
            </Fragment>}
        </Loader></div>} />;
    return <div className={classnames('reportApproval', { expanded })}>
        {isMobile && data}
        {!isMobile && data}
    </div>
}

export default AuditReportApproval;
