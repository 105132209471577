import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { UserInitialsText } from '../../../users/components/Widgets';
import { isInspectionPartOfCombinedInspection, selectPlannedFromGroupedInspections, selectTypedFromInspections } from '../../Utils';
import { getInspectionsSelectedForCombineSelector, getTypeSelectedForCombineSelector, inspectionsListSlice } from '../../slices/inspectionsListSlice';
import { Inspection } from '../../types';
import OriginalQtyInfo from '../report/OriginalQtyInfo';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';

type SelectCombineInspectionProps = {
    inspection: Inspection;
    hasInspectionTypes?: boolean;
    inspectionsTypeGroup: Inspection[];
};

const SelectCombineInspection = (props: SelectCombineInspectionProps): React.ReactElement => {
    const { inspection, hasInspectionTypes, inspectionsTypeGroup } = props;
    const combinedInspectionType = useSelector(getTypeSelectedForCombineSelector);
    const combineInspections = useSelector(getInspectionsSelectedForCombineSelector);
    const dispatch = useAppDispatch();
    const select = (inspection: Inspection, checked: boolean): void => {
        dispatch(checked ? inspectionsListSlice.actions.removeInspectionForCombine(inspection) : inspectionsListSlice.actions.addInspectionForCombine(inspection));
    }
    let checked = false;
    const sI = selectPlannedFromGroupedInspections(inspectionsTypeGroup);
    const iKeys = [];

    combineInspections.forEach((inspection) => iKeys.push(inspection.inspection_id));
    const getButtonText = (total: number, checkedCnt: number) => {
        if (total === 1) {
            return <FormattedMessage id={checkedCnt === 1 ? 'inspections.create_combined_inspection.remove' : 'globals.select'} />;
        }
        if (total === checkedCnt) {
            return <FormattedMessage id='combined_inspection.select_for_combine.all_selected.label' />;
        }
        if (checkedCnt === 0) {
            return <FormattedMessage id='combined_inspection.select_for_combine.select.label' values={{ total }} />
        }
        return <FormattedMessage id='combined_inspection.select_for_combine.selecteded.label' values={{ selected: checkedCnt, total }} />
    }
    const getButton = (inspections: Inspection[], checked: boolean, cnt?: number): React.ReactElement => {
        const checkedList = inspections.filter((inspection) => iKeys.includes(inspection.inspection_id));

        const button = <Button
            className='combineAction' // NB: please do not remove the combineAction class. It is being used by the smoketest 
            primary small onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                cnt > 1 ? null : select(inspections[0], checked);
            }}>
            {getButtonText(inspections.length, checkedList.length)}
        </Button>;
        return cnt > 1 ? <Popup on={'click'} trigger={button}>
            <h4><FormattedMessage id='combined_inspection.select_for_combine.popup_header'/></h4>
            {inspections.map((inspection) => {
                return <div key={'ii_' + inspection.inspection_id} className={twMerge('cursor-pointer p-1 mb-2 items-center', checkedList.includes(inspection) && 'bg-gray-100')}>

                    <div className='flex flex-col' onClick={(e) => {
                        select(inspection, checkedList.includes(inspection));
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}>
                        <div className='w-full'><SplitShipmentsMarker inspection={inspection} /> {inspection.item_number}. {inspection.item_name}. <UserInitialsText className='inline' user={inspection.assigned_user && inspection.assigned_user} /></div>
                        <OriginalQtyInfo inspection={inspection} type='dashboard' /></div>
                </div>
            })}

        </Popup> : button;
    }
    if (hasInspectionTypes) {
        if (combinedInspectionType === null) {
            checked = iKeys.includes(props.inspection.inspection_id);
            return !inspection.inspection_type && inspection.status === 'planned' && inspection.locked !== true && !isInspectionPartOfCombinedInspection(inspection) ?
                getButton([inspection], checked)
                :
                null
        } else {
            const sIs = selectTypedFromInspections(sI, combinedInspectionType.id).filter((insp) => insp.master_inspection === null);
            const sI2 = sIs[0]
            if (sI2 && !isInspectionPartOfCombinedInspection(sI2)) {
                if (combinedInspectionType !== null && inspection.inspection_type && sI2.status === 'planned' && sI2.locked !== true && sI.length > 0 && combinedInspectionType.id === sI2.inspection_type.id) {
                    checked = iKeys.includes(sI2.inspection_id);
                    return getButton(sIs, checked, sIs.length);
                }
            }
        }
    }
    if (!hasInspectionTypes && inspection.status === 'planned' && !inspection.locked && !isInspectionPartOfCombinedInspection(inspection)) {
        checked = combineInspections.includes(props.inspection);
        return getButton([inspection], checked)
    }
    return null;
};

export default SelectCombineInspection;
