import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Audit, AuditFeaturesEnum } from '../../../backend_api/models';
import { getPrettyDate, twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { ListView } from '../../globals/components';
import { ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { UserInitialsText } from '../../users/components/Widgets';
import { getAuditDate } from '../util';
import AuditCorrectiveActionStatusBar from './AuditCorrectiveActionStatusPie';
import AuditStatus from './AuditStatus';
import AuditTypeTag from './AuditTypeTag';

type Props = {
    className?: string;
    audits?: Audit[];
};

const MinimalAuditList = (props: Props): React.ReactElement => {
    const { className, audits } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <div className={twMerge('', className)}>
        {audits.length === 0 && <div><FormattedMessage id='audits.no_audits'/></div>}
        <ListView>
            {audits.map((audit) => {
                const canEdit = audit.features.includes(AuditFeaturesEnum.Edit);
                const downloaded = audit.locked && audit.status === 'planned';
                const link = !downloaded && (audit.status !== 'planned' ? '/audits/report/' + audit.id : canEdit ? '/audits/edit_audit/' + audit.id : null);
                
                return <ListViewLine key={'audit_' + audit.id} link={link} className={twMerge('auditLine text-primary hover:text-primary hover:bg-highlight-green cursor-pointer space-x-1')}>
                <ListViewItem className='order-1 sm:w-1/24'><AuditTypeTag auditStatus={audit.status} auditType={audit.audit_type} auditConclusion={audit.final_conclusion}/></ListViewItem>
                <ListViewItem className='order-1 sm:w-5/24'>
                    {audit.target_supplier && audit.target_supplier.name || '-'}
                </ListViewItem>
                <ListViewItem className='productionUnit order-2 sm:w-4/24 w-full'>
                    {audit.target_production_unit && audit.target_production_unit.name || <FormattedMessage id='audits.list.production_unit_not_set' />}
                </ListViewItem>
                <ListViewItem className='inspector order-4 w-2/12 sm:w-2/24'>
                    {/* <UserInitials disabled user={audit.assigned_user} showInfo /> */}
                    <UserInitialsText user={audit.assigned_user} />
                </ListViewItem>
                <ListViewItem className='date order-2 w-full sm:w-3/24 sm:order-4 sm:mr-1'>{getPrettyDate(getAuditDate(audit))}</ListViewItem>
                <ListViewItem className='order-3 sm:order-4 w-full sm:w-4/24 sm:pr-3'>                    
                        {audit.checklist_name || '-'}                    
                </ListViewItem>
                <ListViewItem className='status order-6 sm:w-2/24 justify-center'>
                    <AuditCorrectiveActionStatusBar auditId={audit.id} correctiveActionStatus={audit.corrective_actions_status} />
                </ListViewItem>
                <ListViewItem className='status order-6 sm:w-3/24'>
                    <AuditStatus audit={audit} />
                </ListViewItem>
            </ListViewLine>
            })}
        </ListView>

    </div>
}
export default MinimalAuditList;
