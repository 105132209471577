import classnames from 'classnames';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ListableCorrectiveAction, PaginatedCorrectiveActions } from '../../../backend_api/models';
import Loader from '../../../base/components/Loader';
import { getPrettyDate, twMerge } from '../../../base/utils';
import TranslatedLabel from '../../globals/components/TranslatedLabel';
import { InfoIcon } from '../../globals/components/Utils';
import { ListView, ListViewHeader, ListViewItem, ListViewLine, ListViewLines } from '../../globals/components/views/list/ListView';
import { UserInitials } from '../../users/components/Widgets';
import { getCorrectiveActionsFetchingSelector } from '../correctiveActionsSlice';
import CorrectiveActionStatus from './CorrectiveActionStatus';

type Props = {
    correctiveActions: PaginatedCorrectiveActions;
}

const CorrectiveActionsBasicList = (props: Props) => {
    const intl = useIntl();
    const isFetching = useSelector(getCorrectiveActionsFetchingSelector);
    const history = useHistory();
    const correctiveActionsData: PaginatedCorrectiveActions = props.correctiveActions;
    const correctiveActions = correctiveActionsData && correctiveActionsData.corrective_actions || [];
    const hasCorrectiveActions = correctiveActions.length > 0;
    const openDetailedPage = (action: ListableCorrectiveAction) => {
        history.push('/ca/details/' + action.id);
    };

    return <Fragment>
        {!hasCorrectiveActions && <Loader active={isFetching}><div className={twMerge('justify-center flex p-4')}><InfoIcon compact text={intl.formatMessage({ id: 'corrective_actions.list_no_items' })} /></div></Loader>}
        <ListView className='mb-0'>

            {hasCorrectiveActions && <><ListViewHeader
                className={classnames('top-0')}
                items={[
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.finding' }), className: 'w-2/16', key: 'item' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.supplier' }), className: 'w-3/16', key: 'supplier' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.production_unit' }), className: 'w-3/16', key: 'pu' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.assigned' }), className: 'w-2/24', key: 'assigned' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.created_date' }), className: 'w-2/24', key: 'created' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.resolved_date' }), className: 'w-2/24', key: 'resolve' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.due_date' }), className: 'w-2/24', key: 'duedate' },
                    { label: intl.formatMessage({ id: 'corrective_actions.list.header.status' }), className: 'w-2/24', key: 'state' },
                ]} />
                <Loader active={isFetching}>
                    <ListViewLines className='border border-b-0'>
                        {correctiveActions.map((action) => {
                            const approver = action.approver ? <UserInitials showInfo user={action.approver} /> : '-';
                            const responsible = action.responsible ? <UserInitials showInfo user={action.responsible} /> : '-';
                            return (
                                <ListViewLine onClick={() => openDetailedPage(action)} key={action.id} className='bg-white cursor-pointer border-b sm:border-b-0'>
                                    <ListViewItem className='w-8/12 sm:w-2/16 order-1 pb-4 sm:pb-0'>
                                        <TranslatedLabel text={action.finding} />
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-3/16 order-3'>
                                        {action.supplier_links && action.supplier_links[0] && action.supplier_links[0].name}
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-3/16 order-4'>
                                        {action.production_unit_links && action.production_unit_links[0] && action.production_unit_links[0].name}
                                    </ListViewItem>
                                    <ListViewItem className='w-full sm:w-2/24 order-5  pb-4 sm:pb-0'>
                                        {responsible} <span className='text-xs text-secondary px-1'>/</span>  {approver}
                                    </ListViewItem>
                                    <ListViewItem className='flex w-1/3 sm:w-2/24 order-6'>
                                        {action.created_at && getPrettyDate(action.created_at)}
                                    </ListViewItem>
                                    <ListViewItem className='flex w-1/3 sm:w-2/24  order-7'>
                                        {action.resolve_date && getPrettyDate(action.resolve_date)}
                                    </ListViewItem>
                                    <ListViewItem className='w-1/3 sm:w-2/24  order-8'>
                                        {action.due_date_at && getPrettyDate(action.due_date_at)}
                                    </ListViewItem>
                                    <ListViewItem className='w-4/12 order-2 sm:order-last sm:w-2/24 justify-end'>
                                        <CorrectiveActionStatus state={action.state} />
                                    </ListViewItem>
                                </ListViewLine>)
                        })}
                    </ListViewLines>
                </Loader></>}
        </ListView>
    </Fragment>
}

export default CorrectiveActionsBasicList;