import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

type SpinnerProps = {
    label: string;
    active: boolean;
};

const Spinner = (props: SpinnerProps): React.ReactElement => {
    return (
        <Dimmer active={props.active} inverted>
            <Loader className='dimLoader'>{props.label}</Loader>
        </Dimmer>
    );
};
export default Spinner;
