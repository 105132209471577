import classnames from 'classnames';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ContextMenu from '../../../../base/components/basic/ContextMenu';
import { isInspectionPartOfCombinedInspection } from '../../../inspections/Utils';
import SplitShipments from '../../../inspections/components/shared/SplitShipments';
import SplitShipmentsTrigger from '../../../inspections/components/shared/SplitShipmentsTrigger';
import { Inspection } from '../../../inspections/types';

type BookingInspectionsContextMenuProps = {
    inspection: Inspection & { id: string }; // TODO: booking inspection type has id, not inspection_id attribute
    bookingId: string;
    className?: string;
    children?: React.ReactElement;
    pointer?: boolean;
    isCombinedInspection?: boolean;
    deleteDisabled?: boolean;
    isSaving?: boolean;
    unlockOnly?: boolean;
};

const BookingInspectionsContextMenu = (props: BookingInspectionsContextMenuProps): React.ReactElement => {
    const { inspection, bookingId, isCombinedInspection, children, pointer = false, deleteDisabled = false, isSaving = false, unlockOnly = false } = props;
    const [fopen, setIsOpen] = useState(false);
    const done = inspection.status === 'report';
    const locked = inspection.locked;
    const planned = inspection.status === 'planned' && !locked;
    const downloaded = inspection.status === 'planned' && locked;
    const ongoing = inspection.status === 'ongoing';
    const isPartOfCombinedInspection = isInspectionPartOfCombinedInspection(inspection);
    const canSplit = inspection.features.includes('split_planned_shipment') && inspection.split_shipment_allowed && planned && inspection.total_quantity;
    const canViewReport = inspection.features.includes('read_report_data');
    const closeFoo = () => {
        setIsOpen(!fopen);

    }
    const getItem = (item: React.ReactElement, doClose = true, disabled = false) => {
        return <div style={{ textIndent: '1rem' }} className={classnames(disabled ? 'hover:bg-white cursor-default' : 'hover:bg-surface-plain', 'text-sm hover:text-primary -mx-4  link noUnderline')} onClick={() => doClose ? closeFoo() : null}>{item}</div>;
    }
    const getText = () => {
        const inProgress = (!isCombinedInspection && !isPartOfCombinedInspection || isCombinedInspection) && ongoing;
        return <div className='text-sm border-b pb-2 px-4 -mx-4'>
            <h5>
                {done && <Fragment><FormattedMessage id='inspections.finished_inspection' /></Fragment>}
                {downloaded && <Fragment><FormattedMessage id='inspections.downloaded_inspection' /></Fragment>}
                {planned && <Fragment><FormattedMessage id='supplier_booking.planned_booking' /></Fragment>}
                {ongoing && <Fragment><FormattedMessage id='inspections.inspection_in_progress' /></Fragment>}
            </h5>
            {downloaded && unlockOnly && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_downloaded_you_can_unlock_it' /></p>}
            {downloaded && !unlockOnly && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_downloaded_you_can_unlock_it_or_reassign_it_to_a_different_inspector' /></p>}
            {inProgress && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_in_progress_You_can_reassign_it_to_a_different_inspector' />{isCombinedInspection &&
                <p><strong><FormattedMessage id='inspections.CI_please_note' /></strong><FormattedMessage id='inspections.other_inspections_on_CI_will_be_reassigned_too' /></p>}</p>}
            {done && <p><FormattedMessage id='inspections.the_inspection_is_finished' /></p>}
            {!isCombinedInspection && isPartOfCombinedInspection && <Fragment><FormattedMessage id='inspections.this_inspection_is_part_of_a_combined_inspection_and_cannot_be_edited' />
                {getItem(<Link className='link noUnderline' to={'/inspections/edit_inspection/' + inspection.master_inspection.order_id}><FormattedMessage id='inspections.view_combined_inspection' /></Link>)}
            </Fragment>
            }
            {isCombinedInspection && <Fragment><FormattedMessage id='inspections.this_inspection_is_a_combined_inspection_and_cannot_be_edited' />
                {getItem(<Link className='link noUnderline' to={'/inspections/edit_inspection/' + inspection.order_id}><FormattedMessage id='inspections.view_original_inspection' /></Link>)}

            </Fragment>}
            {(done || ongoing) && canViewReport &&
                getItem(<Link to={['/inspections/reports/', isPartOfCombinedInspection ? inspection.master_inspection.id : inspection.inspection_id].join('')} className='link noUnderline'>
                    <FormattedMessage id='inspections.view_report' />
                </Link>)}
        </div>
    }
    if (canSplit) {
        return <Fragment>
            <SplitShipments inspection={inspection} context='booking' useTrigger={true} />
            <ContextMenu
                open={fopen}
                close={closeFoo}
                trigger={children ? children : null}
                pointer={pointer}
            >
                {getText()}
                {canSplit && getItem(<SplitShipmentsTrigger className='py-2' type='link' context='booking' bookingId={bookingId} inspectionId={inspection.id} />)}
            </ContextMenu>
        </Fragment>
    }
    return children || null;
}
export default BookingInspectionsContextMenu;
