

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Dropdown, DropdownItemProps, Grid } from 'semantic-ui-react';
import { ChecklistType } from '../../../backend_api/models';
import { deepCopy } from '../../../base/utils';
import { getCustomMeasurementTables } from '../../customFields/selectors';
import { CustomTable } from '../../customFields/types';
import { MeasurementSpecification, MeasurementTable, MeasurementTablesData } from '../types';
import { makeEmptyCustomString } from './BarcodeDetails';
import MeasurementTableDialog from './MeasurementTableDialog';

export const measurementTableVersion = 1

type MeasurementTableDetailsProps = {
    checklistType: ChecklistType;
    data: MeasurementTablesData;
    language: string;
    saveAndUpdate(data: MeasurementTablesData);
    save(data: MeasurementTablesData);
}

const MeasurementTableDetails = (props: MeasurementTableDetailsProps): React.ReactElement => {
    const intl = useIntl()
    const checklistType = props.checklistType;
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [selectedField, setSelectedField] = useState<string>()
    const customTables: CustomTable[] = useSelector(getCustomMeasurementTables)
    const [useCustom, setUseCustom] = useState<boolean>(false)
    const allowCustomFieldsOrTables = checklistType !== 'audit';

    useEffect(() => {
        if (props.data) {
            // TODO. Either custom_table_definition_id = null and use_custom_table = false, OR
            // custom_table_definition = "some string" and use_custom_table = true
            let customTableId = props.data.custom_table_definition_id;
            if (!customTableId && customTables.length > 0) {
                customTableId = customTables[0].id;
            }
            setSelectedField(customTableId);
            setUseCustom(props.data.use_custom_table);
        }
        else {
            props.saveAndUpdate(createDummyData());
        }
    }, [props.data])

    const options: DropdownItemProps[] = []
    customTables.forEach((table) => {
        options.push({
            text: table.name.C,
            value: table.id,
        })
    })

    const settingSelectedField = (value: string): void => {
        const dataCopy: MeasurementTablesData = deepCopy(props.data)
        if (value) {
            dataCopy.custom_table_definition_id = value;
            dataCopy.use_custom_table = true;
        } else {
            dataCopy.custom_table_definition_id = null;
            dataCopy.use_custom_table = false;
        }
        props.save(dataCopy)
        setSelectedField(value)
    }

    const settingUseCustomField = (use: boolean): void => {
        setUseCustom(use);
        const dataCopy: MeasurementTablesData = deepCopy(props.data)
        dataCopy.use_custom_table = use;
        if (use) {
            dataCopy.custom_table_definition_id = selectedField;
        }
        else {
            dataCopy.custom_table_definition_id = null;
        }
        props.save(dataCopy);
    }
    const customFieldsAvailable = allowCustomFieldsOrTables && options.length > 0;
    const tableButtonPlacement = customFieldsAvailable ? 'space-between' : 'center';
    return (
        <Grid style={{ margin: 'unset' }}>
            {
                customFieldsAvailable
                    ? <FormattedMessage id="checklists.editor.measurementtable.custom_field.dropdown" />
                    : null
            }
            <Grid.Row style={{ display: 'flex', justifyContent: tableButtonPlacement, alignItems: 'center' }}>
                {customFieldsAvailable &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox style={{ marginRight: '10px' }} checked={useCustom} onChange={(evt, item): void => settingUseCustomField(item.checked)} />
                        <FormattedMessage id="checklists.editor.measurementtable.custom_field.checkbox" />
                    </div>}
                {useCustom && customFieldsAvailable ?
                    <Dropdown selection
                        closeOnBlur
                        options={options}
                        onChange={(evt, item): void => settingSelectedField(item.value as string)}
                        value={selectedField}
                    /> :
                    <Button positive onClick={(): void => setShowDialog(true)} content={intl.formatMessage({ id: 'checklists.editor.measurementtable.button.text' })} data-test-id='open-measurement-dialog-button' />}
            </Grid.Row>
            {props.data && <MeasurementTableDialog
                show={showDialog}
                checklistType={checklistType}
                cancel={(): void => setShowDialog(false)}
                saveAndUpdate={(data): void => {
                    setShowDialog(false)
                    props.saveAndUpdate(data)
                }}
                data={props.data}
                language={props.language}
            />}
        </Grid>
    );
}

function createDummyData(): MeasurementTablesData {
    return {
        tables: [createEmptyTable()],
        custom_table_definition_id: null,
        use_custom_table: false,
        version: measurementTableVersion,
    }
}

export function createEmptyTable(): MeasurementTable {
    return {
        name: { C: '' },
        samples: [[{
            value: null
        }]],
        specifications: [createEmptySpecification()]
    }
}

export function createEmptySpecification(): MeasurementSpecification {
    const spec: MeasurementSpecification = {
        name: { C: '' },
        unit: '',
        target: makeEmptyCustomString(),
        min: null,
        max: null,
        deviation: null,
    }

    return spec;
}

export default MeasurementTableDetails