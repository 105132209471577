/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * EntityRemovedFromField history event
 *
 */
export type HistoryEventEntityRemovedFromField = {
    entity_id: string;
    entity_name: string;
    entity_number: string;
    entity_type: HistoryEventEntityRemovedFromField.entity_type;
    field: HistoryEventEntityRemovedFromField.field;
};

export namespace HistoryEventEntityRemovedFromField {

    export enum entity_type {
        SUPPLIER = 'supplier',
        PRODUCTION_UNIT = 'production_unit',
    }

    export enum field {
        RELATED_SUPPLIERS = 'related_suppliers',
        PRODUCTION_UNITS = 'production_units',
        UPSTREAM_PRODUCTION_UNITS = 'upstream_production_units',
        DOWNSTREAM_PRODUCTION_UNITS = 'downstream_production_units',
    }


}

