import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid, Header, Segment } from 'semantic-ui-react';
import Icon from '../../../../base/components/basic/Icon';
import history from '../../../../store/history';
import { getTemporaryUserSelector } from '../../selectors/auditSelectors';

const AuditLinkExpiredPage = (): React.ReactElement => {
    const temporaryUser = useSelector(getTemporaryUserSelector);

    if(!temporaryUser) {
        history.push("/audits/error");
    }

    return (
        <Grid container className='inspections-menu'>
            <span className='pageContainer'>
                <Header as='h2' className='flex flex-row text-lg font-bold'><Icon name='link_off' className='mr-1'/> <FormattedMessage id='web_based_audit.link_has_expired'/></Header>
                <Segment.Group raised>
                    <Segment padded>
                        <DocumentTitle title={`Invalid access - Qarma`} />
                        <p>
                            <FormattedMessage
                                id='web_based_audit.link_has_expired_explanation'
                                values={{
                                    auditType: <b className='px-1'>{temporaryUser?.reference?.reference_name}</b>,
                                }} 
                            />
                        </p>
                    </Segment>
                </Segment.Group>
            </span>
        </Grid>
    );
};

export default AuditLinkExpiredPage;
