import { createSelector } from '@reduxjs/toolkit';
import { CorrectiveAction, CorrectiveActionHistory, PaginatedCorrectiveActions } from '../../../backend_api/models';
import { AppState } from '../../../base/types';

const getCorrectiveActionsList = (state: AppState): PaginatedCorrectiveActions => state.app.correctiveActions.correctiveActions;
export const getCorrectiveActionsListSelector = createSelector(
    [getCorrectiveActionsList],
    (list) => list,
);

const getCorrectiveAction = (state: AppState): CorrectiveAction => state.app.correctiveActions.correctiveAction;
export const getCorrectiveActionSelector = createSelector(
    [getCorrectiveAction],
    (list) => list,
);

const isFetching = (state: AppState): boolean => state.app.correctiveActions.isFetching || false;
export const isFetchingSelector = createSelector(
    [isFetching],
    (fetching) => fetching,
);

const isUpdating = (state: AppState): boolean => state.app.correctiveActions.isUpdating;
export const isUpdatingSelector = createSelector(
    [isUpdating],
    (updating) => updating,
);

const auditActions = (state: AppState, id: string): CorrectiveAction[] => state.app.correctiveActions.auditActions[id];
export const auditActionsSelector = createSelector(
    [auditActions],
    (list) => {
        if (!list) {
            return [];
        }
        else {
            return list;
        }
    },
);

const isFetchingHistory = (state: AppState): boolean => state.app.correctiveActions.isFetchingHistory;
export const isFetchingHistorySelector = createSelector(
    [isFetchingHistory],
    (fetching) => fetching,
);

const getHistory = (state: AppState): CorrectiveActionHistory => state.app.correctiveActions.correctiveActionHistory;
export const getHistorySelector = createSelector(
    [getHistory],
    (history) => history,
);
