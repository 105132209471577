import { BasicUser } from '../../backend_api/models';
import { ListUsersUser } from '../../backend_api/models/ListUsersUser';
import { byId } from '../../base/types';
import { deepCopy } from '../../base/utils';


export const handleSaveReport = () => {
    return function update(state, props): any {
        const watchers = Object.assign([], this.state.watchers.concat(this.state.selectedWatchers));
        watchers.forEach((watcherId) => {
            this.props.actions.addWatcherToInspection(props.inspectionId, watcherId);
        });
        return { showAddWatchers: false, selectedWatchers: [], watchers: [], isSaving: true };
    };
};

export const handleRemoveWatcherReport = (watcherId: string) => {
    return function update(state, props): void {
        this.props.actions.removeWatcherFromInspection(props.inspectionId, watcherId);
    };
};

export const handleAddWatcher = (): any => {
    return ({ showAddWatchers: true });
};

export const handleChange = (item: any) => {
    return function update(): any {
        return { selectedWatchers: item.value };
    };
};

export const handleCancel = (): { showAddWatchers: boolean, selectedWatchers: [] } => {
    return { showAddWatchers: false, selectedWatchers: [] };
};

export const getUsersThatAreNotWatchers = (currentWatchers: { id: string }[], users: byId<ListUsersUser>): byId<ListUsersUser> => {
    const usersCopy = deepCopy(users);

    if (currentWatchers) {
        currentWatchers.forEach((cw) => {
            if (usersCopy[cw.id]) {
                delete usersCopy[cw.id];
            }
        });
    }
    return usersCopy;
};


