import React, { Fragment } from 'react';
import { Inspection } from '../../types';

type SampleQtyProps = {
    inspection: Inspection;
};
const SampleQty = (props: SampleQtyProps): React.ReactElement => {
    const { inspection } = props;
    const hasInspectedQty = inspection.inspected_quantity !== null && inspection.inspected_quantity > 0;

    if (inspection.calculated_sample_quantity && inspection.sample_quantity !== inspection.calculated_sample_quantity) {
        return <Fragment>{inspection.calculated_sample_quantity}/{inspection.sample_quantity}</Fragment>;
    }
    return <Fragment>{inspection.sample_quantity}{hasInspectedQty && <Fragment>/{inspection.inspected_quantity}</Fragment>}</Fragment>;
};

export default SampleQty;
