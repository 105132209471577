import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { GroupData } from '../types';

interface GroupProps {
    group: GroupData;
    message?: string;
    toDelete?: boolean;
    handleRemove?(id: string): void;
}
const Group = (props: GroupProps): React.ReactElement => {
    const { group, toDelete } = props;
    return (
        <Label size='mini' className={`group ${group.is_supplier_group ? 'is_supplier_group' : ''}`}>{group.name}{toDelete && <Icon name='delete' onClick={(): void => props.handleRemove(group.id)} />}</Label>
    );
};

export default Group;
