import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { request2 } from '../../../base/api';
import { AppState } from '../../../base/types';
import { getLocaleSpecificString, isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import history from '../../../store/history';
import { CustomConclusion } from '../../approval/types';
import { switchOrganisationSameUrl } from '../../authentication/authenticationSlice';
import { CheckpointWithRemark } from '../../checklists/types';
import { getComments, getComments as getCommentsById } from '../../comments/actions';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';
import { getGroupedInspectionsLatestInSequence, getInspectionsGroupedAndNoTypes } from '../Utils';
import { getCheckpointsObject, loadDefectsAndCheckpointsImagesForLB } from '../actions/reportActions';
import { ApproveBulkInspectionRequest, ApproveBulkStepsRequest, Inspection, ReportMatrix, ReportState, reportState } from '../types';

const initialState = reportState;

export const getInspectionReport = createAsyncThunk<ReportState, { inspectionId: string, orderId?: string, hideFetching?: boolean }>(
    'getInspectionReport',
    async (params, { dispatch, rejectWithValue }) => {
        let url = 'inspections/' + params.inspectionId + '/report';
        if (!isUndefinedOrNullOrEmptyString(params.orderId)) {
            url = url + '?order_id=' + params.orderId;
        }
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            if (rq.status && rq.status === 303) { // Redirect to report on other organisation
                const redirectResponse: { organization_id: string, type: string } = await rq.json();
                dispatch(switchOrganisationSameUrl(redirectResponse.organization_id));
            } else {
                console.log('Getting inspection report data failed...', rq.statusText);

                if (rq.status && rq.status === 404) {
                    history.replace('/404?type=report_not_found&id=' + params.inspectionId + '&error=' + JSON.stringify(rq));
                }
            }
            catchException('getReport', {
                endpoint: 'inspections/[inspectionId]/report?order_id=[orderId]',
                request: 'inspections/' + params.inspectionId + '/report',
                status: rq.status,
            }, { rq, inspectionId: params.inspectionId });
            return rejectWithValue(rq as Response)
        }
        const reportData = await rq.json() as ReportState;
        return reportData;
    });

export const getReportMatrixData = createAsyncThunk<ReportMatrix, string>(
    'getReportMatrixData',
    async (orderId: string, { rejectWithValue }) => {
        const url = 'orders/' + orderId + '/inspections_checkpoints_matrix_2';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            catchException('getReportMatrixData', {
                endpoint: 'orders/[orderId]/inspections_checkpoints_matrix_2',
                request: 'orders/' + orderId + '/inspections_checkpoints_matrix_2',
                status: rq.status,
            }, { rq, orderId });
            return rejectWithValue(rq as Response)
        }
        const matrixData = await rq.json() as ReportMatrix;
        matrixData.map((e) => {
            const inspectionsMap = {};
            e.matrices.map((element) => {
                element.inspections.map((inspection: Inspection) => {
                    inspectionsMap[inspection.inspection_id] = inspection;
                });
                element.inspectionsMap = inspectionsMap;
            });
        });
        return matrixData;
    });

export const changeInspectionConclusion = createAsyncThunk<ReportState, { inspectionId: string, conclusion: { conclusion: CustomConclusion; comment: string; conclusionName: string } }>(
    'changeInspectionConclusion',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('inspections/' + params.inspectionId + '/conclusion', {
            method: 'post',
            body: JSON.stringify(params.conclusion),
        });
        if (!rq.ok) {
            catchException('changeInspectionConclusion', {
                endpoint: 'inspections/[inspectionId]/conclusion',
                request: 'inspections/' + params.inspectionId + '/conclusion',
                status: rq.status,
            }, { rq, inspectionId: params.inspectionId, body: params.conclusion, method: 'POST' });

            return rejectWithValue(rq as Response)
        }
        dispatch(getComments(params.inspectionId)); // Conclusion stores a comment, so comments need to be reloaded after change
        dispatch(sendStatusMessage([{ text: 'inspection.status_changed', values: { conclusion: getLocaleSpecificString(params.conclusion.conclusion.full_name) } }], 3000));
        return await rq.json() as ReportState;
    });

export const changeApprovalStep = createAsyncThunk<ReportState, { inspectionId: string, approvalStepId: string, conclusion: { conclusion: CustomConclusion; comment: string; conclusionName: string } }>(
    'changeApprovalStep',
    async (params, { dispatch, rejectWithValue }) => {
        const rq = await request2('inspections/' + params.inspectionId + '/approvals/' + params.approvalStepId, {
            method: 'put',
            body: JSON.stringify(params.conclusion),
        });
        if (!rq.ok) {
            catchException('changeApprovalStep', {
                endpoint: 'inspections/[inspectionId]/approvals/[approvalStepId]',
                request: 'inspections/' + params.inspectionId + '/approvals/' + params.approvalStepId,
                status: rq.status,
            }, { rq, params, body: params.conclusion, method: 'PUT' });

            return rejectWithValue(rq as Response)
        }
        dispatch(getComments(params.inspectionId)); // Conclusion stores a comment, so comments need to be reloaded after change
        dispatch(sendStatusMessage([{ text: 'inspection.status_changed', values: { conclusion: getLocaleSpecificString(params.conclusion.conclusion.full_name) } }], 3000));
        return await rq.json() as ReportState;
    });

export const setDefectSeverity = createAsyncThunk<any, { inspectionId: string, severity: string, defectId: string }>(
    'setDefectSeverity',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'inspections/' + params.inspectionId + '/defects/' + params.defectId + '/severity/' + params.severity;
        const rq = await request2(url, { method: 'post' });
        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.the_defect_severity_was_not_changed']));
            catchException('setDefectSeverity', {
                endpoint: 'defects/[defectId]/severity/[severity]',
                request: 'defects/' + params.defectId + '/severity/' + params.severity,
                status: rq.status,
            }, { rq, defectId: params.defectId, severity: params.severity, method: 'POST' });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.the_defect_severity_was_changed'], 3000));
        dispatch(getCommentsById(params.inspectionId));  // Changing severity stores a new comment, so comments need to be reloaded after change
        return await rq.json() as ReportState;
    });

export const changeApprovalStepsBulk = createAsyncThunk<ReportState[], { body: ApproveBulkStepsRequest, inspectionId: string }>(
    'changeApprovalStepsBulk',
    async (params, { rejectWithValue }) => {
        const url = '/inspections/approvals/set_bulk_approval';
        const rq = await request2(url, { method: 'put', body: JSON.stringify(params.body) });
        if (!rq.ok) {
            catchException('changeApprovalStepsBulk', {
                endpoint: '/inspections/approvals/set_bulk_approval',
                request: '/inspections/approvals/set_bulk_approval',
                status: rq.status,
            }, { rq, body: params.body, method: 'put' });
            return rejectWithValue(rq as Response)
        }
        return await rq.json() as ReportState[];
    });

export const changeInspectionConclusionBulk = createAsyncThunk<ReportState[], { body: ApproveBulkInspectionRequest, inspectionId: string }>(
    'changeInspectionConclusionBulk',
    async (params, { dispatch, rejectWithValue }) => {
        const url = '/inspections/conclusions/set_bulk_conclusions'
        const rq = await request2(url, { method: 'put', body: JSON.stringify(params.body) });
        if (!rq.ok) {
            catchException('changeInspectionConclusionBulk', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { rq, body: params.body, method: 'put' });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage([{ text: 'status_message.report.change_inspection_conclusion_bulk', values: { name: params.body.conclusion.full_name.C } }], 3000));
        return await rq.json() as ReportState[];
    });

const inspectionReportSlice = createSlice({
    name: 'inspectionReport',
    initialState,
    reducers: {
        reportScrollToTop: (state, action: { payload: boolean }): void => {
            state.report.dontScrollToTop = !action.payload;
        },
        updateAndReloadReportData: (state, action: { payload: ReportState }): void => {
            updateAndReload(state, action);
        }
    },
    extraReducers: builder => {
        builder.addCase(setDefectSeverity.fulfilled, (state, action) => {
            updateAndReload(state, action);
        });
        builder.addCase(getInspectionReport.fulfilled, (state, action) => {
            state.report.isFetching = false;
            updateAndReload(state, action);
        });
        builder.addCase(getInspectionReport.pending, (state, action) => {
            state.report.isFetching = action.meta.arg.hideFetching ? false : true;
        });
        builder.addCase(getReportMatrixData.fulfilled, (state, action) => {
            state.orderMatrix = action.payload;
        });
        builder.addCase(changeInspectionConclusion.fulfilled, (state, action) => {
            updateAndReload(state, action);
        });
        builder.addCase(changeApprovalStep.fulfilled, (state, action) => {
            updateAndReload(state, action);
        });
        builder.addCase(changeApprovalStepsBulk.fulfilled, (state) => {
            state.changingBulkStatus = true;
        });
        builder.addCase(changeApprovalStepsBulk.pending, (state) => {
            state.changingBulkStatus = false;
        });
        builder.addCase(changeInspectionConclusionBulk.fulfilled, (state) => {
            state.changingBulkStatus = true;
        });
        builder.addCase(changeInspectionConclusionBulk.pending, (state) => {
            state.changingBulkStatus = false;
        });
    },
});
const updateAndReload = (state, action) => {
    let data = action.payload;
    data = getCheckpointsObject(data);
    const order = data.order;
    const groupedAndNoTypes = getInspectionsGroupedAndNoTypes(order.inspections);
    const groupedInspections = getGroupedInspectionsLatestInSequence(groupedAndNoTypes.withTypes);
    const imagesItems = loadDefectsAndCheckpointsImagesForLB(data.inspection);

    order.inspectionsGrouped = groupedAndNoTypes.grouped;
    order.inspectionsNoTypes = groupedAndNoTypes.noTypes;
    order.statusInspections = groupedInspections.concat(groupedAndNoTypes.noTypes);
    state.report.imagesItems.images = imagesItems.images;
    state.report.imagesItems.items = imagesItems.items;

    data.order = order;
    data.orderMatrix = undefined;
    state.report.data = data;

    const report = Object.assign({}, state.report);
    let remarksIds: CheckpointWithRemark[] = [];
    const cnt = order.inspectionsNoTypes.length + Object.keys(order.inspectionsGrouped).length;
    report.isFetching = false;
    report.data = data;
    report.allInspectionsCount = cnt;
    data.checkpointsRemarks && Object.keys(data.checkpointsRemarks).forEach((key) => {
        remarksIds = remarksIds.concat(data.checkpointsRemarks[key].checkpointsWithRemarks);
    });
    report.data.checkpointWithRemarksIds = remarksIds;
}

export const getReportSelector = createSelector([(state: AppState) => state.app.inspectionReport && state.app.inspectionReport.report.data], report => report);
export const getReportDefectsSelector = createSelector([(state: AppState) => state.app.inspectionReport && state.app.inspectionReport.report.data && state.app.inspectionReport.report.data.inspection && state.app.inspectionReport.report.data.inspection.defects], report => report);
export default inspectionReportSlice.reducer;
export const { updateAndReloadReportData, reportScrollToTop } = inspectionReportSlice.actions; 
