import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InspectionsWeekviewSupplierOrders, WeekviewUser } from '../../../../../../../backend_api/models';
import { useAppDispatch } from '../../../../../../../store';
import { WeekViewStoreObject, getWeekViewDataFiltered, storeWeekViewData } from '../../../../../slices/weekViewSlice';
import WeekViewReassignOrder from '../../../../WeekViewReassignOrder';
import SupplierItem from '../SupplierItem';
import { InspectionsWeekviewOrderExtended, WeekViewItemType } from '../WeekView2';
import WeekViewOrdersItem from '../WeekViewOrdersItem';
import CompactItem from './CompactItem';

type WeekViewItemProps = {
    className?: string;
    supplierObj: InspectionsWeekviewSupplierOrders;
    type: WeekViewItemType;
    date: Date;
    user: WeekviewUser;
    id: string;
    isOpen: boolean;
    toggleCompact(e, id): void;
};

const WeekViewItem = (props: WeekViewItemProps): React.ReactElement => {
    const { className, id, supplierObj, type, date, user, isOpen, toggleCompact } = props;
    const [modalOpen, setModalIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [dateFoo, setDate] = useState(date);
    const [assignedUserId, setAssignedUserId] = useState(undefined);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const save = () => {
        const storeObj: WeekViewStoreObject = {
            inspection_ids: [],
            assigned_user_id: assignedUserId || null,
            scheduled_inspection_date: dateFoo,
            supplier_qc: false,
        };
        // item.to.itemType === 'booked' ? storeObj['booking_date'] = item.to.date : storeObj['scheduled_inspection_date'] = item.to.date;
        supplierObj.orders.forEach((order) => {
            order.inspections.forEach((inspection) => {
                storeObj.inspection_ids.push(inspection.inspection_id);
            })
        });
        dispatch(storeWeekViewData(storeObj));
        dispatch(getWeekViewDataFiltered({ date: '2022-04-25' }))
        setModalIsOpen(false);
    }
    return <span onMouseEnter={() => setTimeout(() => setVisible(true), 500)} onMouseLeave={() => setTimeout(() => setVisible(false), 500)}  >
        <SupplierItem
            id={supplierObj.supplier && supplierObj.supplier.id}
            data={supplierObj}
            itemType={type}
            date={date}
            user={user}
        >
            <span onClick={(e): void => toggleCompact(e, id)}>
                <CompactItem
                    orders={supplierObj.orders  as InspectionsWeekviewOrderExtended[]}
                    user={user}
                    isOpen={isOpen}
                    hasInspectionTypes={true}
                />
            </span>
            <div className={'flex flex-col'}>
                {isOpen && <div><WeekViewOrdersItem
                    data={supplierObj}
                    orders={supplierObj.orders as InspectionsWeekviewOrderExtended[]}
                    date={date}
                    isCollapsed={false}
                    itemType={type}
                    user={user}
                    key={'order_' + id} />
                    <div className='flex justify-between'>
                        <div className='text-smx link' onClick={(e): void => toggleCompact(e, id)}>
                            {intl.formatMessage({ id: 'inspections.week_view.compact_order_close' })}</div>
                        <div className='text-smx link'><WeekViewReassignOrder
                            orderId={supplierObj.orders[0] && supplierObj.orders[0].inspections[0].order_id + '_' + date}
                            date={date}
                            supplierObj={supplierObj}
                            assignedUserId={user && user.id}
                            itemType={type}
                            trigger={<div className='cursor-pointer link'><FormattedMessage id='inspections.week_view.reassign_order.trigger' /></div>} />
                    </div></div>
                </div>}
            </div>
        </SupplierItem></span>
}
export default WeekViewItem;
