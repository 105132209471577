import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { InspectionLink, MasterInspectionLink } from '../../../backend_api/models';
import Link from '../../../base/components/basic/Link';
import { getInspectionLabel } from '../../../base/utils';
import { TextItem } from '../../globals/components';

type Props = {
    className?: string;
    links: (InspectionLink | MasterInspectionLink)[];
};

const InspectionsLinkList = (props: Props): React.ReactElement => {
    const intl = useIntl();
    
    return <Fragment>
        {props.links.map((link) => {
            const isCI = link.type === 'MasterInspectionLink';
            const label = !isCI ? <Popup trigger={<span>{getInspectionLabel(link.order_number, link.item_name, link.item_number)}</span>}>
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.order_number' })} text={link.order_number} />
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.item_name' })} text={!isCI && link.item_name} />
                <TextItem label={intl.formatMessage({ id: 'corrective_actions.inspections_links.item_number' })} text={link.item_number} />
            </Popup> : getInspectionLabel(link.order_number);
            return <div key={'insp_link' + link.inspection_id}>
                <Link underline to={'/inspections/reports/' + link.inspection_id}>{label}</Link></div>
        })}
    </Fragment>
}
export default InspectionsLinkList;
