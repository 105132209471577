/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request with the parameters that needs to be edited
 * @export
 * @interface EditCorrectiveActionRequest
 */
export interface EditCorrectiveActionRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCorrectiveActionRequest
     */
    approver_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditCorrectiveActionRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditCorrectiveActionRequest
     */
    due_date_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditCorrectiveActionRequest
     */
    finding?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCorrectiveActionRequest
     */
    groups?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EditCorrectiveActionRequest
     */
    requires_evidence_from_app?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditCorrectiveActionRequest
     */
    responsible_id?: string | null;
}

export function EditCorrectiveActionRequestFromJSON(json: any): EditCorrectiveActionRequest {
    return EditCorrectiveActionRequestFromJSONTyped(json, false);
}

export function EditCorrectiveActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditCorrectiveActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver_id': !exists(json, 'approver_id') ? undefined : json['approver_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'due_date_at': !exists(json, 'due_date_at') ? undefined : json['due_date_at'],
        'finding': !exists(json, 'finding') ? undefined : json['finding'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'requires_evidence_from_app': !exists(json, 'requires_evidence_from_app') ? undefined : json['requires_evidence_from_app'],
        'responsible_id': !exists(json, 'responsible_id') ? undefined : json['responsible_id'],
    };
}

export function EditCorrectiveActionRequestToJSON(value?: EditCorrectiveActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver_id': value.approver_id,
        'description': value.description,
        'due_date_at': value.due_date_at,
        'finding': value.finding,
        'groups': value.groups,
        'requires_evidence_from_app': value.requires_evidence_from_app,
        'responsible_id': value.responsible_id,
    };
}


