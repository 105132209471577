import { Fragment, default as React } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { createCombinedOrder as createCombinedOrderAction } from '../../actions/combineActions';
import { getInspectionsSelectedForCombineSelector, getTypeSelectedForCombineSelector, inspectionsListSlice, setShowCombineCollector, showCombineCollectorSelector } from '../../slices/inspectionsListSlice';
import { Inspection, InspectionType } from '../../types';
import InspectionCollection from '../InspectionCollection';

type CollectCombineProps = {
    className?: string;
    hasInspectionTypes: boolean;
};

const CollectCombine = (props: CollectCombineProps): React.ReactElement => {
    const { className, hasInspectionTypes } = props;
    const dispatch = useAppDispatch();
    const setInspectionsForCombine = inspectionsListSlice.actions.setInspectionsForCombine;
    const combineInspections = useSelector(getInspectionsSelectedForCombineSelector);
    const showCombine = useSelector(showCombineCollectorSelector);
    const setShowCombine = (show: boolean): void => { dispatch(setShowCombineCollector(show)); };
    const combinedInspectionType = useSelector(getTypeSelectedForCombineSelector);
    const hasItems = combineInspections.length > 0;
    const hasOneItem = combineInspections.length === 1;

    const toggleCombine = (type: InspectionType): void => {
        setShowCombine(type !== null);
        dispatch(setInspectionsForCombine([]));
        dispatch(inspectionsListSlice.actions.setInspectionTypeForCombine(type));
    };
    const removeInspection = (inspection: Inspection): void => {
        dispatch(inspectionsListSlice.actions.removeInspectionForCombine(inspection));
    };
    const createCombinedOrder = (create: boolean): void => {
        const inspections = [...combineInspections];
        const inspectionIds = inspections.map((inspection: Inspection) => inspection.inspection_id);
        if (create) {
            dispatch(createCombinedOrderAction(inspectionIds));
        }
        toggleCombine(null);
    };

    const header = <Fragment><FormattedMessage id='inspections.inspections_to_combine' />{hasInspectionTypes && <span> - {combinedInspectionType && combinedInspectionType.name || 'None'}</span>}</Fragment>
    const action = <Button primary disabled={hasOneItem || !hasItems} dataTestId="create-combined-inspections" onClick={(): void => createCombinedOrder(true)}><FormattedMessage id='inspections.create_combined_inspection' /></Button>
    const warning = <Fragment>{hasOneItem && <p className='bg-attention self-start'><FormattedMessage id='inspections.add_one_more_to_combine' /></p>}</Fragment>
    return <InspectionCollection
        className={className}
        collectedInspections={combineInspections}
        header={header}
        action={action}
        warning={warning}
        enable={showCombine}
        removeInspection={removeInspection}
        cancel={(): void => createCombinedOrder(false)}
    />
}
export default CollectCombine;

