/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TempUserContextReference } from './TempUserContextReference';

/**
 * Authorization information for TemporaryUser.
 *
 */
export type TempUserAuthorization = {
    context_reference: TempUserContextReference;
    last_login: string | null;
    organization_id: string;
    organization_logo_url: string | null;
    organization_name: string;
    session_id: string;
    token: string;
    type: TempUserAuthorization.type;
    user_id: string;
};

export namespace TempUserAuthorization {

    export enum type {
        AUTHORIZATION = 'authorization',
    }


}

