import classnames from 'classnames';
import React from 'react';

const DefectsLine = (props): React.ReactElement => {
    const defects = props.defects;
    let criticalClass = 'critical';
    let majorClass = 'major';
    let minorClass = 'minor';
    const cn = 'flex text-sm px-2 justify-center rounded-sm';

    if (defects.critical > 0) {
        criticalClass = 'defect critical attention';
    }
    if (defects.major > 0) {
        majorClass = 'defect major attention';
    }
    if (defects.minor > 0) {
        minorClass = 'defect minor attention';
    }
    return (
        <span className='defectsLine'>
            {defects.critical > 0 && <span className={classnames(cn, criticalClass)}>{defects.critical}</span>}
            {defects.major > 0 && <span className={classnames(cn, majorClass)}>{defects.major}</span>}
            {defects.minor > 0 && <span className={classnames(cn, minorClass)}>{defects.minor}</span>}
        </span>
    );
};
export { DefectsLine };

