import { createSelector } from 'reselect';

const messageSelector = (state): any[] => state.app.messages.items;
const errorMessageSelector = (state): any[] => state.app.messages.errorItems || [];
const statusMessageSelector = (state): any[] => state.app.messages.statusItems || [];
const globalMessageSelector = (state): any[] => state.app.messages.globalItems || [];

export const getMessages: any = createSelector(
    [messageSelector],
    (messages) => messages,
);

export const getStatusMessages: any = createSelector(
    [statusMessageSelector],
    (messages) => messages,
);

export const getErrorMessages: any = createSelector(
    [errorMessageSelector],
    (messages) => messages,
);

export const getGlobalMessages: any = createSelector(
    [globalMessageSelector],
    (messages) => messages,
);
