import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Segment } from 'semantic-ui-react';
import { CorrectiveAction, CorrectiveActionAccessibleFeaturesEnum, CorrectiveActionsState } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { getColor } from '../CorrectiveActionDisplayState';
import { reopenCorrectiveAction } from '../correctiveActionsSlice';
import ApproveCorrectiveActionDialog from './ApproveCorrectiveActionDialog';
import CorrectiveActionStatus from './CorrectiveActionStatus';
import ResolveCorrectiveActionDialog from './ResolveCorrectiveActionDialog';

type Props = {
    correctiveAction: CorrectiveAction
}

const CorrectiveActionStatusSegment = (props: Props) => {
    const dispatch = useAppDispatch();
    const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);
    const [resolveModalOpen, setResolveModalOpen] = useState<boolean>(false);
    const backgroundColor = 'bg-' + getColor(props.correctiveAction.state)
    const state = props.correctiveAction.state
    const reopen = () => {
        dispatch(reopenCorrectiveAction(props.correctiveAction.id))
    }
    return <Fragment>
        <Segment className={'p-0!'}>
            <div className={'flex flex-row justify-between items-center p-segment' + ' ' + backgroundColor}>
                <label className='text-white'><FormattedMessage id='corrective_actions.details.status' /></label>
                <CorrectiveActionStatus state={props.correctiveAction.state} inverted />
            </div>
            <div className={'flex flex-row justify-between'}>
                <div>
                    <HasFeatureAccess type='corrective_action' correctiveAction={props.correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.Approve} >
                        {state == CorrectiveActionsState.Review && <div className='p-segment'>
                            <Button onClick={() => setApproveModalOpen(true)} data-test-id="approve-button"><FormattedMessage id='corrective_actions.details.approve_button' /></Button>
                        </div>}
                    </HasFeatureAccess>
                    <HasFeatureAccess type='corrective_action' correctiveAction={props.correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.Resolve} >
                        {props.correctiveAction.can_be_resolved ? <div className='p-segment'>
                            <Button onClick={() => setResolveModalOpen(true)}><FormattedMessage id='corrective_actions.details.resolve_button' /></Button>
                        </div> : null}
                    </HasFeatureAccess>
                </div>
                {(state == CorrectiveActionsState.Closed || state == CorrectiveActionsState.Review) &&
                    <HasFeatureAccess type='corrective_action' correctiveAction={props.correctiveAction} feature={CorrectiveActionAccessibleFeaturesEnum.Approve} >
                        <div className='p-segment'><Button onClick={reopen}><FormattedMessage id='corrective_actions.details.reopen_button' /></Button></div>
                    </HasFeatureAccess>
                }
            </div>
        </Segment>
        <ApproveCorrectiveActionDialog id={props.correctiveAction.id} open={approveModalOpen} close={() => setApproveModalOpen(false)} />
        <ResolveCorrectiveActionDialog description={props.correctiveAction.finding.text} id={props.correctiveAction.id} open={resolveModalOpen} close={() => setResolveModalOpen(false)} />
    </Fragment>
}

export default CorrectiveActionStatusSegment;