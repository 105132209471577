import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../base/components/basic/Icon';

type Props = {
    loginSucceded: boolean;
    loginFailed: boolean;
};

const TwoFactorInputMessage = (props: Props): React.ReactElement => {
    const { loginSucceded, loginFailed } = props;
    return <Fragment>
        {loginFailed && <div className='flex items-center justify-center p-2 my-4 text-primary bg-attention rounded w-full'>
            <Icon className='text-xl text-yellow-500 pr-1' name='error' /><div><FormattedMessage id='twoFactor.login_failed.text' /></div>
        </div>}
        {loginSucceded && <div className='flex justify-center items-center p-2 my-4 text-primary bg-highlight-green rounded w-full'>
            <Icon name='check_circle' className='text-lg text-brand pr-1' /><div><FormattedMessage id='twoFactor.login_succeded.text' /></div>
        </div>}
    </Fragment>
}
export default TwoFactorInputMessage;
