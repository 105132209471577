import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { MultiLanguageText } from '../../globals/types';

type TranslationTextProps = {
    text: MultiLanguageText;
    language: string;
    isHeader?: boolean;
}

const TranslationText = (props: TranslationTextProps): React.ReactElement => {
    const isPlaceholder: boolean = !props.text[props.language] || props.text[props.language].length < 1
    const text: string = isPlaceholder ? props.text.C : props.text[props.language]
    const style = isPlaceholder ? { color: 'rgba(34,36,38,.15)' } : {}

    return (
        <Fragment>
            {props.isHeader &&
                <Header style={style}>
                    {text}
                </Header>
            }
            {!props.isHeader &&
                <div style={style}>
                    {text}
                </div>
            }
        </Fragment>
    );
}

export default TranslationText;