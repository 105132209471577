import queryString from 'query-string';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import PageContainer from '../../globals/components/PageContainer';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getIsSavingSelector } from '../selectors';
import DataExportAudit from './DataExportAudit';
import DataExportInspection from './DataExportInspection';
import DataExportSuppliers from './DataExportSuppliers';

const DataExportContainer = (): React.ReactElement => {
    const intl = useIntl();
    const isSaving = useSelector(getIsSavingSelector);
    const history = useHistory();
    const qs = queryString.parse(history.location.search);
    return (
        <PageContainer header={<FormattedMessage id='statistics.export_data_to_excel' />}>
            <Dimmer active={isSaving} inverted>
                <Loader className='dimLoader'><FormattedMessage id='statistics.exporting_data_please_wait' /></Loader>
            </Dimmer>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.statistics' })} />
            <ContentSection content={<>
                <DataExportInspection email={qs.email as string} />
            </>}
                headerText={intl.formatMessage({ id: 'statistics.export_inspections_to_excel' })} />
            <ContentSection content={<>
                <HasFeatureAccess feature='u_audits'>
                    <DataExportAudit email={qs.email as string} />
                </HasFeatureAccess>
            </>}
                headerText={intl.formatMessage({ id: 'statistics.export_audits_to_excel' })} />
            <ContentSection content={<>
                <DataExportSuppliers email={qs.email as string} />
            </>}
                headerText={intl.formatMessage({ id: 'statistics.export_suppliers_to_excel' })} />
        </PageContainer>
    );
};

export default DataExportContainer;