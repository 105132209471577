import React from 'react';
import { WeekviewUser } from '../../../../../../backend_api/models';
import { InspectionsWeekviewOrderExtended, WeekViewItemType } from './WeekView2';
import WeekViewOrderItem from './WeekViewOrderItem';

type OrdersItemProps = {
    orders: InspectionsWeekviewOrderExtended[];
    date: Date;
    data: any;
    user: WeekviewUser;
    className?: string;
    isCollapsed: boolean;
    itemType: WeekViewItemType;
};

const WeekViewOrdersItem = (props: OrdersItemProps): React.ReactElement => {
    const { className, orders, date, itemType, data, user } = props;
    return <div>{orders.map((order, cnt) => {
        return <WeekViewOrderItem key={cnt} order={order} date={date} itemType={itemType} data={data} cnt={cnt} user={user} />
    })}</div>

}
export default WeekViewOrdersItem;
