import { Theme } from '@nivo/core';

const colors = ['#A5CCC2', '#418070', '#CFFFF3', '#82FFE0', '#B8E2D8', '#CCBD91', '#806D34', '#FFEDB5', '#FFD969', '#D5C697', '#CC9390', '#803734', '#FFB8B5', '#FF6E69', '#664948', '#6474CC', '#A1A8CD', '#A5CCC2', '#B7BFE9', '#7E91FF'];

const inspectionBarColors = {
    colors,
    labelTextColor: 'black',
};

const defectsBarColors = {
    colors: ['#c3e2f8', '#fce2ad', '#ffdedc'], //blue, yellow, pink 
    labelTextColor: '#000',
};

const conclusionColors = { approved: '#529e64', rejected: '#d84444', pending: '#fdc35a' };
const supplierQCColors = { supplier_qc: '#A5CCC2', non_supplier_qc: '#418070' };

const barTheme: Theme = {
    axis: {
        domain: {
            line: {
                stroke: 'transparent',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                strokeWidth: 1,
            },
            text: {
                fill: '#888',
                fontSize: 12,
            },
        },
        legend: {
            text: {
                fontSize: 12,
            },
        },
    },
};

const bar = {
    theme: barTheme,
    axisLeft: {
        tickSize: 0,
        tickPadding: 20,
    },
    axisBottom: {
        tickSize: 0,
        tickPadding: 20,
    },
    axisBottomRotated: {
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 25
    },
    labelSkipHeight: 20,
    padding: 0.3,
};

const pie = {
    width: 250,
    textColor: '#fff',
};

const getPieColors = (pieType: string, colorId?: string): string => [pieType][colorId];

export default { bar, pie, colors, inspectionBarColors, defectsBarColors, conclusionColors, supplierQCColors, getPieColors };
