import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Divider, Icon, List, Popup } from 'semantic-ui-react';
import { CompleteApprover } from '../../../../backend_api/models/CompleteApprover';
import { isUndefinedOrNullOrEmptyString } from '../../../../base/utils';
import ReportApprovalItemShared from '../../../approval/components/ReportApprovalItemShared';
import { TranslateText } from '../../../globals/components';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { getProfileSelector } from '../../../users/selectors';
import ApprovalStatus from './ApprovalStatus';

type ApprovalStepsProps = {
    approvalSteps: CompleteApprover[];
    element: any;
    disabled: boolean;
    index: number;
    handleOpen(type: string, approval: CompleteApprover);
}

const ApprovalSteps = (props: ApprovalStepsProps): React.ReactElement => {
    const canChangeConclusion = props.element.features && props.element.features.includes('change_conclusion');
    const loggedInUserId = useSelector(getProfileSelector).profile.id;
    return (<Fragment key={'approvalStep_' + props.index}>
        <List.Item className='inspector' key={'approvaOuterItem_' + props.index}>
            <List.Content className='header'>
                <FormattedMessage id='inspections.approver' />
            </List.Content>
        </List.Item>
        
        {props.approvalSteps.map((approval: CompleteApprover, i: number) => {
            const hasComment = !isUndefinedOrNullOrEmptyString(approval.approved_status.comment) && !isUndefinedOrNullOrEmptyString(approval.approved_status.comment.comment);
            const noStatusLabel = <HasFeatureAccess feature='change_conclusion' type='inspection' inspection={props.element} showDisabled={true}>
                <ApprovalStatus disabled={props.disabled} type='notSet' approval={approval} onChange={props.handleOpen} />
            </HasFeatureAccess>;
            const statusLabel = <HasFeatureAccess feature='change_conclusion' type='inspection' inspection={props.element} showDisabled={true}>
                <ApprovalStatus type='statusLabel' approval={approval} onChange={props.handleOpen} />
            </HasFeatureAccess>;
            const onbehalfOf = approval.approved_status.by && approval.approved_status.by.id !== loggedInUserId || approval.intended && approval.intended.id !== loggedInUserId || false;
            const hasApprovalStatus = approval.approved_status && approval.approved_status.conclusion.conclusion_category !== 'not_set';
            const completeStatus = <>{props.disabled && canChangeConclusion && <Popup
                trigger={<span>{hasApprovalStatus ? statusLabel : noStatusLabel}</span>}>
                <Fragment>
                    <Popup.Header>
                        <Icon name='info circle' color='blue' />
                        <FormattedMessage id='inspections.report_in_progress' /></Popup.Header>
                    <Popup.Content><FormattedMessage id='inspections.report_in_progress_popup_content' /></Popup.Content>
                </Fragment>
            </Popup>}
                {props.disabled && !canChangeConclusion && <Fragment>{hasApprovalStatus ? statusLabel : noStatusLabel}</Fragment>}
                {hasApprovalStatus && !props.disabled && (onbehalfOf ?
                    <HasFeatureAccess feature='delegated_approval_step' type='inspection' inspection={props.element} showDisabled={true}>
                        <HasFeatureAccess feature='change_conclusion' type='inspection' inspection={props.element} showDisabled={true}>
                            <ApprovalStatus type='statusLabel' approval={approval} onChange={props.handleOpen} />
                        </HasFeatureAccess>
                    </HasFeatureAccess> : statusLabel)}
                {!hasApprovalStatus && !props.disabled && (onbehalfOf ?
                    <HasFeatureAccess feature='delegated_approval_step' type='inspection' inspection={props.element} showDisabled={true}>
                        {noStatusLabel}
                    </HasFeatureAccess> : noStatusLabel)}</>;
            return (<>
                <ReportApprovalItemShared
                    user={hasApprovalStatus ? approval.approved_status.by : approval.intended}
                    heading=''
                    status={completeStatus}
                    showCommentIcon = {hasComment}
                    comment={hasComment && <div className='comment approver conclusion_text'>
                        <TranslateText
                            showTranslatedComments={true}
                            translateObj={approval.approved_status.comment.translated_comment}
                            originalLanguage={approval.approved_status.comment.comment_language}
                            originalText={approval.approved_status.comment.comment}
                            showToggleActions={true}
                        />
                    </div>}
                />
            </>)
        })}<Divider />
    </Fragment>);
}

export default ApprovalSteps;