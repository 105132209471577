/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Status of an audit.
 *
 */
export enum AuditStatus {
    PLANNED = 'planned',
    DOWNLOADED = 'downloaded',
    ONGOING = 'ongoing',
    REPORT = 'report',
}
