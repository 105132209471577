import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../base/components/BaseComponent';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import messages from '../../../modules/messages';
import { ErrorMessage as em } from '../components';

const ErrorMessage = em.ErrorMessage;

class ErrorMessages extends BaseComponent<any, any> {
    public doRender(): React.ReactElement {
        const messageCnt = this.props.errorMessages.length;

        if (messageCnt > 0) {
            return (
                <div className={['errorMessageContainer', messageCnt === 0 ? 'hide' : null].join(' ')} onClick={this.props.actions.clearErrorMessage}>
                    <div className='errorItem'>
                        <div className='errorText flex'><ErrorMessage messages={this.props.errorMessages} /><span className='-mr-4 ml-2 cursor-pointer'><RemoveIcon/></span></div>
                    </div>
                </div>
            );
        }
        return (null);
    }

    public componentWillReceiveProps(nextProps): void {
        this.setMessageTimeout(nextProps);
    }

    private setMessageTimeout(props): void {
        if (props.errorMessages.length > 0) {
            const hideAfterMs = props.errorMessages[0].hideAfterMs;
            if (hideAfterMs && hideAfterMs > 0) {
                const timer = setTimeout(() => {
                    this.clear();
                    clearTimeout(timer);
                }, hideAfterMs);
            }
        }
    }
    private clear(): void {
        this.props.actions.clearErrorMessage();
    }
}

export const mapStateToProps = (state): any => {
    return {
        errorMessages: messages.selectors.getErrorMessages(state),
    };
};

export const mapDispatchToProps = (dispatch): any => {
    const clearErrorMessage = messages.actions.clearErrorMessage;
    return {
        actions: bindActionCreators({
            clearErrorMessage,
        }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessages);
