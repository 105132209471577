import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { OptionsType } from '../../../base/types';
import { searchOptionTextAndDescription } from '../../inspections/Utils';

type Props = {
    className?: string;
    limitItemsCount?: number;
    options?: DropdownItemProps[];
} & DropdownProps;

const LimitedDropdown = (props: Props): React.ReactElement => {
    const { className, limitItemsCount = 0, search, options, onChange, ...otherProps } = props;
    const [limitedOptions, setLimitedOptions] = useState([]);
    const [value, setValue] = useState(props.multiple ? props.value : props.value && props.value[0]);

    const limitedSearch = (searchOptions, query) => {
        if (limitItemsCount > 0) {
            const filtered = searchOptionTextAndDescription(options as any, query);
            if (filtered.length > limitItemsCount) {
                return filtered.slice(0, limitItemsCount).concat([beMoreSpecificOption(limitItemsCount)])
            } else {
                return filtered;
            }
        }
        return searchOptionTextAndDescription(searchOptions, query);
    }
    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    useEffect(() => {
        const _options =
            (props.options.length > limitItemsCount && limitItemsCount > 0)
                ? props.options.slice(0, limitItemsCount).concat([beMoreSpecificOption(limitItemsCount)])
                : props.options;

        if (props.value) {
            const value = props.value as any; // TODO: Michael fix type
            if (typeof (value) === 'string') {
                //TODO: Simplify below code
                if (_options.filter((fo) => fo.value === value).length === 0) {
                    if (props.options.filter((opt) => opt.value === value).length > 0) {
                        _options.unshift(props.options.filter((opt) => opt.value === value)[0]);
                    }
                }
            } else {
                value.map((v) => {
                    if (_options.filter((fo) => fo.value === v).length === 0) {
                        if (props.options.filter((opt) => opt.value === v).length > 0) {
                            _options.unshift(props.options.filter((opt) => opt.value === v)[0]);
                        }
                    }
                })
            }
        }
        setLimitedOptions(_options);
    }, [props.options]);

    const dd =
        <Dropdown
            id="supplier-dd"
            fluid={props.fluid}
            value={value && value}
            options={limitedOptions}
            search={search && limitedSearch}
            onChange={(e, { value }) => {
                if (limitItemsCount > 0) {
                    const opts = [...limitedOptions];
                    // We need to check if the selected option is part of the limited options - if not, we add it manually to the options list
                    //TODO: Simplify below code
                    if (otherProps.multiple) {
                        (value as any).map((optionValue) => {
                            if (limitedOptions.filter((fo) => fo.value === optionValue).length === 0) {
                                opts.unshift(props.options.filter((opt) => opt.value === optionValue)[0]);
                            }
                        })
                    } else {
                        if (limitedOptions.filter((fo) => fo.value === value).length === 0) {
                            opts.unshift(props.options.filter((opt) => opt.value === value)[0]);
                        }
                    }
                    setLimitedOptions(opts);
                }
                props.onChange && (props.multiple ? props.onChange(e, { value }) : props.onChange(e, { value }));
            }}
            {...otherProps} />

    return dd;
}

function beMoreSpecificOption(limitItemsCount): OptionsType {
    return {
        key: 'more-specific-item',
        text: (
            <span onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }} className='flex text-sm -my-3 py-2 pr-6 -ml-4 -mr-4 px-4 bg-attention'>
                <FormattedMessage id='limited_dropdown.showing_n_items_search_to_find_more' values={{ cnt: limitItemsCount }} />
            </span>
        ),
        value: 'more-specific-item'
    };
}
function enterMoreThanThreeCharactersOption(): OptionsType {
    return {
        key: 'search-item',
        text: (
            <span
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className='flex text-sm w-full -my-3 py-2 pr-6 -ml-4 -mr-4 px-4 '>
                <FormattedMessage id='limited_dropdown.enter_three_chars_to_search' />
            </span>
        ),
        value: 'search-item'
    }
}

export default LimitedDropdown;
