/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * History entry user reference
 * @export
 * @interface HistoryEntryUserReference
 */
export interface HistoryEntryUserReference {
    /**
     * 
     * @type {boolean}
     * @memberof HistoryEntryUserReference
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryUserReference
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryUserReference
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryUserReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryUserReference
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryUserReference
     */
    source_reference_type: HistoryEntryUserReferenceSourceReferenceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum HistoryEntryUserReferenceSourceReferenceTypeEnum {
    Audit = 'audit',
    User = 'user',
    ImportJob = 'import_job'
}

export function HistoryEntryUserReferenceFromJSON(json: any): HistoryEntryUserReference {
    return HistoryEntryUserReferenceFromJSONTyped(json, false);
}

export function HistoryEntryUserReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntryUserReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': json['disabled'],
        'email': json['email'],
        'firstname': json['firstname'],
        'id': json['id'],
        'lastname': json['lastname'],
        'source_reference_type': json['source_reference_type'],
    };
}

export function HistoryEntryUserReferenceToJSON(value?: HistoryEntryUserReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'email': value.email,
        'firstname': value.firstname,
        'id': value.id,
        'lastname': value.lastname,
        'source_reference_type': value.source_reference_type,
    };
}


