import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../base/components/BaseComponent';
import messages from '../../../modules/messages';
import { clearAllMessages, clearErrorMessage, sendErrorMessage } from '../actions';
import { StatusMessage as sm } from '../components';

const StatusMessage = sm.StatusMessage;
let gtimers = [];

class StatusMessages extends BaseComponent<any, any> {
    public doRender(): React.ReactElement {
        const isStandalone = this.props.sa;
        const messageCnt = this.props.statusMessages.length;
        return (
            <div className={['statusMessageContainer', messageCnt === 0 ? 'hide' : null].join(' ')} onClick={this.props.actions.clearStatusMessage}>
                <div className='statusItem'>
                    <div className='statusText'>{messageCnt > 0 && <StatusMessage messages={this.props.statusMessages} />}</div>
                </div>
            </div>/*  : <div className='statusItem'>
                <div className='statusText text-sm'>{messageCnt > 0 && <StatusMessage messages={this.props.statusMessages} />}</div>
            </div> */
        );
    }
    public componentWillReceiveProps(nextProps): void {
        this.setMessageTimeout(nextProps);
        if (nextProps.statusMessages.length === 0 || nextProps.statusMessages[0].showErrorMessages === false) {
            gtimers.forEach((timer) => {
                clearTimeout(timer);
            });
            if (gtimers.length > 0) {
                setTimeout(() => {
                    this.props.actions.clearErrorMessage();
                }, 100);
            }
            gtimers = [];
        }
        if (nextProps.statusMessages[0] && nextProps.statusMessages[0].showErrorMessages === true) {
            this.messageTimerSetup();
        }
    }
    private messageTimerSetup(): void {
        const doSendErrorMessage = this.props.actions.sendErrorMessage;
        let gtimer;
        const messagesObj = [
            { text: 'error_message.wait_5', wait: 5000 },
            { text: 'error_message.wait_10', wait: 10000 },
            { text: 'error_message.wait_20', wait: 20000 },
            { text: 'error_message.wait_35', wait: 35000 },
        ];
        let cnt = 0;
        (function messageTimer(): void {
            if (messagesObj[cnt]) {
                const wait = messagesObj[cnt].wait;
                gtimer = setTimeout(() => {
                    doSendErrorMessage([messagesObj[cnt].text]);
                    clearTimeout(gtimer);
                    cnt++;
                    messageTimer();
                }, wait);
                gtimers.push(gtimer);
            } else {
                clearTimeout(gtimer);
                const index = gtimers.indexOf(gtimer);
                gtimers.splice(index, 1);
            }
        }());
    }

    private setMessageTimeout(props): void {
        if (props.statusMessages.length > 0) {
            const hideAfterMs = props.statusMessages[0].hideAfterMs;
            if (hideAfterMs && hideAfterMs > 0) {
                const timer = setTimeout(() => {
                    this.props.actions.clearStatusMessage();
                    clearTimeout(timer);
                    gtimers.forEach((t) => {
                        clearTimeout(t);
                    });
                }, hideAfterMs);
            }
        }
    }
}

export const mapStateToProps = (state): any => {
    return {
        statusMessages: messages.selectors.getStatusMessages(state),
    };
};

export const mapDispatchToProps = (dispatch): any => {
    const clearStatusMessage = messages.actions.clearMessage;
    return {
        actions: bindActionCreators({
            clearStatusMessage,
            clearErrorMessage,
            clearAllMessages,
            sendErrorMessage,
        }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusMessages);
