import { format } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dimmer, Dropdown, DropdownItemProps, Form, Loader } from 'semantic-ui-react'
import Button from '../../../base/components/basic/Button'
import DatePicker from '../../../base/components/basic/DatePicker3'
import Icon from '../../../base/components/basic/Icon'
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config'

type Props = {
    statusOptions?: DropdownItemProps[];
    extraOptions?: DropdownItemProps[];
    exportObjects?: string[];
    hasDates?: boolean;
    description?: string;
    exportInProgressText: string;
    export(params: ExportParams);
}

export type ExportParams = {
    additional_export_objects: string[]
    filters: {
        end_date: string,
        start_date: string,
        statuses: string[]
    }
}

const DataExportInput = (props: Props) => {
    const { hasDates = true, exportObjects } = props;
    const intl = useIntl();
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [okToDownload, setOkToDownload] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [isSaving] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [selectedExtra, setSelectedExtra] = useState<string[]>([]);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    useEffect(() => {
        if (hasDates) {
            if (startDate && endDate) {
                if (startDate > endDate) {
                    setOkToDownload(false);
                    setDateError(true);
                } else {
                    setOkToDownload(true);
                    setDateError(false);
                }
            } else {
                setOkToDownload(false);
                setDateError(false);
            }
        } else {
            setOkToDownload(true);
        }
    }, [[endDate], [startDate]])

    useEffect(() => {
        setSelectedStatus(initialStatusValues());
    }, [props.statusOptions]);

    const done = () => {
        setIsExporting(false);
        setSelectedExtra([]);
        // setSelectedStatus([]);
        setSelectedStatus(initialStatusValues());
        setStartDate(null);
        setEndDate(null);
    }
    const initialStatusValues = (): string[] => {
        const values = [];
        props.statusOptions && props.statusOptions.forEach((option) => values.push(option.value))
        return values;
    }

    const exportStats = (): void => {
        const params = {
            filters: {
                end_date: endDate,
                start_date: startDate,
                statuses: selectedStatus
            },
            additional_export_objects: selectedExtra,
            export_objects: exportObjects,
        }
        props.export(params)
        setIsExporting(true)
    }


    const handleDateChange = (m: Date, item: { name: 'startDate' | 'endDate' }): void => {
        if (m) {
            const date = format(m, DEFAULT_DATE_FORMAT2);
            item.name === 'startDate' ? setStartDate(date) : setEndDate(date);
        }else {
            item.name === 'startDate' ? setStartDate(null) : setEndDate(null);
        }
    }
    return <Fragment>{!isExporting && <Fragment>
        <Dimmer active={isSaving} inverted>
            <Loader className='dimLoader'><FormattedMessage id='statistics.exporting_data_please_wait' /></Loader>
        </Dimmer>
        <Form>
            <Form.Group>
                {hasDates && <Form.Field inline className='datepicker' width={3}>
                    <label><FormattedMessage id='statistics.start_date' /></label>
                    <DatePicker
                        date={startDate}
                        handleChange={(date): void => handleDateChange(date, { name: 'startDate' })}
                        pastDates={true}
                        name={'startDate'} />
                </Form.Field>}
                {hasDates && <Form.Field inline className='datepicker' width={3}>
                    <label><FormattedMessage id='statistics.end_date' /></label>
                    <DatePicker
                        date={endDate}
                        handleChange={(date): void => handleDateChange(date, { name: 'endDate' })}
                        pastDates={true} name={'endDate'} />
                </Form.Field>}
                {props.statusOptions && <Form.Field>
                    <label><FormattedMessage id='statistics.status' /></label>
                    <Dropdown
                        multiple={true}
                        selection
                        value={selectedStatus}
                        options={props.statusOptions}
                        onChange={(evt, item): void => setSelectedStatus(item.value as string[])}
                        placeholder={intl.formatMessage({ id: 'statistics.status_placeholder' })}
                    />
                </Form.Field>}
                {props.extraOptions && <Form.Field>
                    <label><FormattedMessage id='statistics.extra' /></label>
                    <Dropdown
                        multiple={true}
                        selection
                        value={selectedExtra}
                        options={props.extraOptions}
                        onChange={(evt, item): void => setSelectedExtra(item.value as string[])}
                        placeholder={intl.formatMessage({ id: 'statistics.extra_placeholder' })}
                    />
                </Form.Field>}
                {props.description && <div className='p-2'>{props.description}</div>}
            </Form.Group>
            {dateError && <div className='pb-4 -mt-8'><span className='bg-attention'><FormattedMessage id='statistics.date_range_is_not_valid_make_sure_start_date_is_before_end_date' /></span>
            </div>}
            
            <Button disabled={!okToDownload || props.statusOptions && selectedStatus.length == 0} primary onClick={exportStats}><Icon name='text_snippet' className='mr-1' /><FormattedMessage id='statistics.export' /></Button>
        </Form>
    </Fragment>}
        {isExporting && <div className='flex items-center justify-between p-3 rounded bg-attention'>
            {props.exportInProgressText}
            <Button small primary onClick={done}><FormattedMessage id='globals.ok'/></Button>
        </div>}
    </Fragment>
}

export default DataExportInput