import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import { resolveCorrectiveAction } from '../correctiveActionsSlice';
type Props = {
    id: string;
    description: string;
    open: boolean;
    close();
}

const ResolveCorrectiveActionDialog = (props: Props) => {
    const dispatch = useAppDispatch();
    const resolve = () => {
        const data = {
            id: props.id,
            ca: {
                comment: '',
            }
        }
        dispatch(resolveCorrectiveAction(data));
        close();
    }
    const close = () => {
        props.close();
    }
    return (
        <Modal
            size='small'
            open={props.open}
            closeOnEscape={true}
            closeIcon={true}
            onClose={close}
        >
            <Modal.Header>
                {<FormattedMessage id='corrective_actions.reject_modal.header' />}
            </Modal.Header>
            <Modal.Content>
                <FormattedMessage id='corrective_actions.resolve_text'/>: <b>{props.description}</b>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={resolve} primary data-test-id="confirm-resolve-button"><FormattedMessage id='corrective_actions.reject_modal.resolve_button' /></Button>
                <Button onClick={close}><FormattedMessage id="globals.confirmation.cancel" /></Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ResolveCorrectiveActionDialog;