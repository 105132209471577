import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { Audit, AuditFeaturesEnum, AuditStatus } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import Label from '../../../base/components/basic/Label';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { getUsersById } from '../../users/selectors';
import { ReassignAuditModal } from './web-based-audit/ReassignAuditModal';

export function ExternalUserIcon(props: {
    audit: Audit,
    onResendEmail: (audit: Audit) => void
}) {
    const {audit, onResendEmail} = props;
    const assignedUser = audit?.assigned_user;
    const users = useSelector(getUsersById);
    const userId = audit?.assigned_user?.id;
    const isAssignedUserExternal = userId && !users[audit?.assigned_user?.id];
    const canAssignAuditViaLink = audit?.features?.includes(AuditFeaturesEnum.AssignAuditViaLink); 

    if(!isAssignedUserExternal) {
        return null;
    }

    const plannedAuditAction = <div>
        <div className='text-base font-bold'><FormattedMessage id='audits.list.send_to_external_user'/></div>
        <div className='text-base my-4'>{audit?.assigned_user?.email}</div>
        {
            canAssignAuditViaLink &&
            <Button className='' small onClick={(e) => {
                preventDefaultAndStopPropagation(e);
                onResendEmail(audit);
            }}><FormattedMessage id='audits.list.resend_link_button'/></Button>
        }
    </div>
    const onlyNameAndEmail = <div>
        <div className='text-base font-bold'>{[assignedUser.firstname || '-', ' ', assignedUser.lastname || '-']}</div>
        <div className='text-base'>{audit?.assigned_user?.email}</div>
    </div>

    if(audit?.status == AuditStatus.Ongoing && !audit.locked) {
        return <ReassignAuditModal audit={audit} />
    }

    return <Popup trigger={
        <Label small className='text-primary bg-surface-plain border-default whitespace-pre'>
            <span className={'flex justify-center px-2'}>
                <Icon name='forward_to_inbox' className='text-lg'/>
            </span>
        </Label>
    } hoverable>
        { (audit?.status == AuditStatus.Planned || audit?.status == AuditStatus.Downloaded && !audit.locked) && plannedAuditAction}
        { (audit?.status == AuditStatus.Report || audit.locked) && onlyNameAndEmail}
    </Popup>

}