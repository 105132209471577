import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ComponentOrStringType } from '../../../../../base/types';
import { isUndefinedOrNull } from '../../../../../base/utils';

type Props = {
    className?: string;
    overlayContent: ComponentOrStringType;
    overlayCss?: string;
    top?: boolean;
    bottom?: boolean;
};

const PhotoOverlay = (props: Props): React.ReactElement => {
    const { className, overlayCss, overlayContent, top, bottom } = props;
    const noOverlayCss = isUndefinedOrNull(overlayCss);
    return overlayContent && <div className={twMerge('photoOverlay relative z-10')}>
        <div className={twMerge('absolute w-full', className, top && 'top-0 rounded-t-md', bottom && 'bottom-0 rounded-b-md', noOverlayCss && ' text-white px-2 py-2')} style={noOverlayCss ? { background: 'rgba(0, 0, 0, 0.61)'} : null }>{overlayContent}</div>
    </div>
}
export default PhotoOverlay;
