import qs from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { addToUrlParams } from '../../../base/utils';
import { getListDateSelector } from '../slices/auditSlice';
import Audits from './Audits';


const AuditContainer = () => {
    const defaultDate: string = useSelector(getListDateSelector);
    const location = useLocation();
    const loc = qs.parse(location.search);
    let newUrl;
    if (defaultDate) {
        newUrl = addToUrlParams([{ key: 'status', value: loc.status as string || 'planned' }, { key: 'date', value: defaultDate }], location.search);
    } else {
        newUrl = addToUrlParams([{ key: 'status', value: loc.status as string || 'planned' }], location.search);
    }
    if (loc.status === undefined || (defaultDate && loc.date === undefined)) {
        return <Redirect
            exact
            to={{
                pathname: '/audits',
                search: '?' + newUrl,
                push: false,
            }}
        />
    }
    return <Audits />
}

export default AuditContainer;