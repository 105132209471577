import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Audit, AuditCheckpoint, SetStatusText } from '../../../../backend_api_2';
import Icon from '../../../../base/components/basic/Icon';
import { useAppDispatch } from '../../../../store';
import { updateCheckpointStatus } from '../../slices/auditSlice';
import { isAuditImmutable } from './PerformAuditPage';

export function WebAuditCheckpointStatus(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const [onFocusStatus, setOnFocusStatus] = React.useState(AuditCheckpoint.checkpoint_status.EMPTY);
    const [showSetStatusButton, setShowSetStatusButton] = React.useState(checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setShowSetStatusButton(checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY);
    }, [checkpoint.checkpoint_status]);

    function onStatusHover(status: AuditCheckpoint.checkpoint_status) {
        setOnFocusStatus(status);
    }

    function onStatusLoseFocus() {
        setOnFocusStatus(AuditCheckpoint.checkpoint_status.EMPTY);
    }

    function doUpdateStatus(status: AuditCheckpoint.checkpoint_status) {
        if(!isAuditImmutable(audit)) {
            dispatch(updateCheckpointStatus({
                audit_id: audit.id,
                checkpoint_id: checkpoint.id,
                status_text: getSetStatusText(status)
            }))
        }
    }

    function getSetStatusText(status: AuditCheckpoint.checkpoint_status): SetStatusText.status_text {
        switch(status) {
            case AuditCheckpoint.checkpoint_status.CHECKED_OK:
                return SetStatusText.status_text.CHECKED_OK;
            case AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK:
                return SetStatusText.status_text.CHECKED_NOT_OK;
            case AuditCheckpoint.checkpoint_status.PENDING:
                return SetStatusText.status_text.PENDING;
            case AuditCheckpoint.checkpoint_status.NOT_APPLICABLE:
                return SetStatusText.status_text.NOT_APPLICABLE;
            default:
                return SetStatusText.status_text.EMPTY;

        }
    }


    function getStatusText() {
        switch(onFocusStatus) {
            case AuditCheckpoint.checkpoint_status.CHECKED_OK:
                return "Checked - OK";
            case AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK:
                return "Checked - Not OK";
            case AuditCheckpoint.checkpoint_status.PENDING:
                return "Pending";
            case AuditCheckpoint.checkpoint_status.NOT_APPLICABLE:
                return "Not Applicable";
            default:
                return "";
        }
    }

    if(!checkpoint.status_enabled) {
        return null;
    }

    if(showSetStatusButton) {
        return (
            <div className='mb-4'>
                {
                    triedToSubmit && checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY && checkpoint.mandatory &&
                    <div className='text-sm flex flex-row text-status-rejected items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <div><FormattedMessage id='web_based_audit.status_required.warning'/></div>
                    </div>
                }
                <div className='flex flex-row'>
                    <div className='flex flex-row border justify-between items-center w-fit rounded cursor-pointer'>
                        <div className='px-3 py-1'>
                            <FormattedMessage id='inspections.set_status'/>
                        </div>
                        <div className='flex flex-row' onMouseLeave={onStatusLoseFocus}>
                            <button className={`flex flex-row items-center audit-status-approved`} 
                                onMouseEnter={() => onStatusHover(AuditCheckpoint.checkpoint_status.CHECKED_OK)} 
                                onClick={() => doUpdateStatus(AuditCheckpoint.checkpoint_status.CHECKED_OK)}>
                                <Icon name='check_circle' className='px-2 py-1 text-2xl leading-none'/>
                            </button>
                            <button className={`flex flex-row items-center audit-status-rejected`} 
                                onMouseEnter={() => onStatusHover(AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK)} 
                                onClick={() => doUpdateStatus(AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK)}>
                                <Icon name='error' className='px-2 py-1 text-2xl leading-none'/>
                            </button>
                            <button className={`flex flex-row items-center audit-status-pending`} 
                                onMouseEnter={() => onStatusHover(AuditCheckpoint.checkpoint_status.PENDING)} 
                                onClick={() => doUpdateStatus(AuditCheckpoint.checkpoint_status.PENDING)}>
                                <Icon name='help' className='px-2 py-1 text-2xl leading-none'/>
                            </button>
                            <button className={`flex flex-row items-center audit-status-not-applicable`} 
                                onMouseEnter={() => onStatusHover(AuditCheckpoint.checkpoint_status.NOT_APPLICABLE)} 
                                onClick={() => doUpdateStatus(AuditCheckpoint.checkpoint_status.NOT_APPLICABLE)}>
                                <Icon name='block' className='px-2 py-1 text-2xl leading-none'/>
                            </button>
                        </div>
                    </div>
                    
                    <div className='flex mx-2 items-center'>
                        {getStatusText()}
                    </div>
                </div>
            </div>
            
        )
    }

    return <button className='w-fit-content' onClick={() => doUpdateStatus(AuditCheckpoint.checkpoint_status.EMPTY)}>
        {getCheckpointStatus(checkpoint.checkpoint_status)}
    </button>

    
}

function CheckedOKStatus() {
    return (
        <div className={`flex flex-row items-center text-white audit-status-approved my-2 pr-2 w-fit-content border rounded`}>
            <Icon name='check_circle' className='px-2 py-1 text-2xl leading-none'/>
            <span className='flex justify-center'><FormattedMessage id="checkpoint.status.1"/></span>
        </div>
    )
}

function CheckedNotOKStatus() {
    return (
        <div className={`flex flex-row items-center text-white audit-status-rejected my-2 pr-2 w-fit-content border rounded`}>
            <Icon name='error' className='px-2 py-1 text-2xl leading-none'/>
            <div className='flex justify-center'><FormattedMessage id="checkpoint.status.2"/></div>
        </div>
    )
}

function CheckedPendingStatus() {
    return (
        <div className={`flex flex-row items-center text-white audit-status-pending my-2 pr-2 w-fit-content border rounded`}>
            <Icon name='help' className='px-2 py-1 text-2xl leading-none'/>
            <div className='flex justify-center'><FormattedMessage id="checkpoint.status.4"/></div>
        </div>
    )
}

function CheckedNotApplicableStatus() {
    return (
        <div className={`flex flex-row items-center text-white audit-status-not-applicable my-2 pr-2 w-fit-content border rounded`}>
            <Icon name='block' className='px-2 py-1 text-2xl leading-none'/>
            <div className='flex justify-center'><FormattedMessage id="checkpoint.status.3"/></div>
        </div>
    )
}

function getCheckpointStatus(status: AuditCheckpoint.checkpoint_status) {
    switch(status) {
        case AuditCheckpoint.checkpoint_status.CHECKED_OK:
            return <CheckedOKStatus />
        case AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK:
            return <CheckedNotOKStatus />
        case AuditCheckpoint.checkpoint_status.PENDING:
            return <CheckedPendingStatus />
        case AuditCheckpoint.checkpoint_status.NOT_APPLICABLE:
            return <CheckedNotApplicableStatus />
    }
}