import React from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit } from '../../../backend_api/models';
import { TextItem } from '../../globals/components';
import { getColor } from './AuditDisplayState';

type AuditTypeTagProps = {
    className?: string;
    showName?: boolean;
    auditType: Audit['audit_type'];
    auditStatus: Audit['status'];
    auditConclusion?: Audit['final_conclusion'];
};

const AuditTypeTag = (props: AuditTypeTagProps): React.ReactElement => {
    const { className, auditStatus, auditType, showName, auditConclusion } = props;
    const intl = useIntl();
    const conclusionColor = auditConclusion && auditConclusion.background_color;
    const cn = conclusionColor ? 'bg-[' + conclusionColor + ']' : 'bg-' + getColor(auditStatus);
    const trigger = <div style={conclusionColor && {backgroundColor: conclusionColor}} className={twMerge('inline px-2 text-white rounded text-sm', cn, className)}>{auditType && (showName ? auditType.name : auditType.tag) || '-'}</div>;
    return (
        <Popup className='flex' flowing trigger={trigger}>
            <TextItem column label={intl.formatMessage({ id: 'audits.audit_type' })} text={auditType && auditType.name || intl.formatMessage({ id: 'audits.audit_type.no_type' })} />
        </Popup>
    );
}
export default AuditTypeTag;
