import { createAction } from 'typesafe-actions';
import { request } from '../../../base/api';
import { AppThunk } from '../../../base/types';
import errorHandling from '../../../modules/errorHandling';
import globals from '../../../modules/globals';
import messages from '../../../modules/messages';
import { getInspectionsMap } from '../selectors/selectors';
import * as types from '../types';
import { isOrderCombinedInspection } from '../Utils';
import { indexInspections } from './addInspectionActions';
import { areAllInspectionsEqual } from './editInspectionActions';

const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;
const catchException = errorHandling.handler.catchException;

const MESSAGE_HIDE_AFTER = 10000;

const deleteInspectionRequest = createAction(types.DELETE_INSPECTION_REQUEST,
    (isSaving: boolean, orderId: string, inspectionId: string) => {
        return {
            type: types.DELETE_INSPECTION_REQUEST,
            payload: {
                isSaving,
                orderId,
                inspectionId,
            },
        };
    });

const deleteInspectionSuccess = createAction(types.DELETE_INSPECTION_SUCCESS,
    (isSaving: boolean, orderId: string, inspectionId: string, data: any) => {
        return {
            type: types.DELETE_INSPECTION_SUCCESS,
            payload: {
                isSaving,
                orderId,
                inspectionId,
                data,
            },
        };
    });
const deleteInspectionFailure = createAction(types.DELETE_INSPECTION_FAILURE,
    (isSaving: boolean, orderId: string, inspectionId: string, status: number, message: any) => {
        return {
            type: types.DELETE_INSPECTION_FAILURE,
            payload: {
                isSaving,
                orderId,
                inspectionId,
                status,
                message,
            },
        };
    });

// Delete an inspection on an order
export const deleteInspection = (orderId: string, inspectionId: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        let url;
        const order: types.Order = Object.assign({}, getState().app.inspections.order);
        const inspectionsMap = Object.assign({}, getInspectionsMap(getState()));
        const isCombinedInspection = isOrderCombinedInspection(order);
        const method = isCombinedInspection ? 'post' : 'delete';
        if (isCombinedInspection) {
            url = 'inspections/' + orderId + '/remove_sub_inspection/' + inspectionId;
        } else {
            url = 'orders/' + orderId + '/inspections/' + inspectionId;
        }
        dispatch(deleteInspectionRequest(true, orderId, inspectionId));
        request(url, {
            method,
        })
            .then(() => {
                let inspections: types.Inspection[];
                if (isCombinedInspection) {
                    inspections = order.inspections[0].sub_inspections;
                } else {
                    inspections = order.inspections;
                }
                let index = -1;
                // Find the deleted inspection and remove it from data
                inspections.forEach((inspection: types.Inspection, i: number) => {
                    if (inspection.inspection_id === inspectionId) {
                        index = i;
                    }
                });
                inspections.splice(index, 1);
                delete inspectionsMap[inspectionId];
                dispatch(indexInspections(inspections));
                dispatch(deleteInspectionSuccess(false, orderId, inspectionId, { inspections, inspectionsMap }));
                dispatch(areAllInspectionsEqual());
                dispatch(clearAllMessages());
                dispatch(sendStatusMessage(['status_message.the_inspection_was_succesfully_deleted'], MESSAGE_HIDE_AFTER));
            })
            .catch((error) => {
                // An error occured on trying to delete the inspectionaddInspection
                dispatch(deleteInspectionFailure(false, orderId, inspectionId, error.status, error));
                dispatch(sendErrorMessage(['error_message.the_inspection_was_not_deleted'], 10000));
                catchException('deleteInspectionSuccess', {
                    endpoint: 'orders/[orderId]/inspections/[inspectionId]',
                    request: url,
                    status: error.status,
                }, { error, method: 'DELETE', orderId, inspectionId });
            });
    };
};
const deleteOrderRequest = createAction(types.DELETE_ORDER_REQUEST,
    (isFetching: boolean, isSaving: boolean, orderId: string) => {
        return {
            type: types.DELETE_ORDER_REQUEST,
            payload: {
                isSaving,
                orderId,
            },
        };
    });

const deleteOrderSuccess = createAction(types.DELETE_ORDER_SUCCESS,
    (isFetching: boolean, isSaving: boolean, orderId: string, data: any) => {
        return {
            type: types.DELETE_ORDER_SUCCESS,
            payload: {
                isSaving,
                orderId,
                data,
            },
        };
    });
const deleteOrderFailure = createAction(types.DELETE_ORDER_FAILURE,
    (isSaving: boolean, orderId: string, status: number, message: any) => {
        return {
            type: types.DELETE_ORDER_FAILURE,
            payload: {
                isSaving,
                orderId,
                status,
                message,
            },
        };
    });

// Delete an order
export const deleteOrder = (orderId: string): AppThunk => {
    const redirectTo = globals.actions.redirectTo;
    return async (dispatch): Promise<void> => {
        const url = 'orders/' + orderId;
        dispatch(deleteOrderRequest(false, true, orderId));
        request(url, {
            method: 'delete',
        })
            .then((data) => {
                dispatch(deleteOrderSuccess(false, false, orderId, data));
                dispatch(clearAllMessages());
                dispatch(redirectTo('/inspections'));
                dispatch(sendStatusMessage(['status_message.the_order_was_succesfully_deleted'], MESSAGE_HIDE_AFTER));
            })
            .catch((error) => {
                // An error occured on trying to delete the inspection
                dispatch(deleteOrderFailure(false, orderId, error.status, error));
                dispatch(sendErrorMessage(['error_message.the_order_was_not_deleted']));
                catchException('deleteInspection', {
                    endpoint: 'orders/[orderId]',
                    request: url,
                    status: error.status,
                }, { error, method: 'DELETE', orderId });
            });
    };
};
