/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldNotSet } from './SetCustomFieldNotSet';
import type { SetCustomFieldNull } from './SetCustomFieldNull';
import type { SetCustomFieldNumber } from './SetCustomFieldNumber';

/**
 *   Value for a date based custom field
 *
 */
export type SetCustomFieldNumberValue = {
    new_value: (SetCustomFieldNotSet | SetCustomFieldNull | SetCustomFieldNumber);
    type: SetCustomFieldNumberValue.type;
};

export namespace SetCustomFieldNumberValue {

    export enum type {
        NUMBER = 'Number',
    }


}

