import { GetProfileResponse } from '../../backend_api/models/GetProfileResponse';
import { ProfileData } from './types';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export type PROFILE_REQUEST = any;
export const PROFILE_REQUEST_SUCCESS = 'PROFILE_REQUEST_SUCCESS';
export type PROFILE_REQUEST_SUCCESS = { profile: GetProfileResponse };
export const PROFILE_REQUEST_FAILURE = 'PROFILE_REQUEST_FAILURE';
export type PROFILE_REQUEST_FAILURE = any;

export const IS_UPDATING_PROFILE = 'IS_UPDATING_PROFILE';
export type IS_UPDATING_PROFILE = {
    isUpdatingProfile: boolean;
};
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export type UPDATE_PROFILE = { userId: string; profile: ProfileData };

