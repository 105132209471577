import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { getShowModalSelector, showModal } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import { useAppDispatch } from '../../../../store';
import { InfoIcon } from '../../../globals/components/Utils';

type Props = {
    className?: string;
};

const DuplicateCombinedInspectionTimeoutInfo = (props: Props): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const modalOpen = useSelector(getShowModalSelector);
    const closeCIInfoModal = () => dispatch(showModal({ id: 'new_combined_inspection_creation_takes_long_time_check_email', show: false }));

    return <Modal
        size='tiny'
        onClose={() => closeCIInfoModal()}
        closeIcon
        closeOnDimmerClick
        closeOnDocumentClick
        open={modalOpen['new_combined_inspection_creation_takes_long_time_check_email'] && modalOpen['new_combined_inspection_creation_takes_long_time_check_email'].show}>
        <Modal.Header><FormattedMessage id='inspections.duplicate_combined_inspection.header_longtime_info' /></Modal.Header>
        <Modal.Content>
            <div className='flex space-y-2 flex-col'>
                <div className=''>
                    <div><InfoIcon compact text={intl.formatMessage({id: 'inspections.duplicate_combined_inspection.longtime_info'})}/></div>
                    <FormattedMessage id='inspections.duplicate_combined_inspection.longtime_info_2'/>
                </div>                
            </div>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => closeCIInfoModal()}>Close</Button>
        </Modal.Actions>
    </Modal>
}
export default DuplicateCombinedInspectionTimeoutInfo;
