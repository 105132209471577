import moment from 'moment';
import { Dispatch } from 'redux';
import { InputProps } from 'semantic-ui-react';
import { createAction } from 'typesafe-actions';
import { BasicUser } from '../../../backend_api_2';
import { request } from '../../../base/api';
import { DEFAULT_DATE_FORMAT } from '../../../base/config';
import { Action } from '../../../base/genericActions';
import { AppThunk, RequestError } from '../../../base/types';
import { compareObjects } from '../../../base/utils';
import history from '../../../store/history';
import { getInspectionChecklists } from '../../checklists/actions/actions';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import { getSamplingProtocols } from '../../samplingProtocols/slices/samplingProtocolsSlice';
import { getOrderUsers } from '../../users/actions/actions';
import { getFirstInspectionNotLockedOrDone, getFirstNotLockedInspection, getIsPropertyInSameForAllInspections, inspectionPropertyChanged, isInspectionNotLockedAndPlanned, isOrderCombinedInspection } from '../Utils';
import { CREATE_ORDER_AND_INSPECTIONS_SUCCESS } from '../actionTypes';
import { updateOrder, updateSingleInspection } from '../actions';
import { getEditInspectionChangedInspections, getOrderWithInspectionsSelector } from '../editInspectionSelectors';
import { getCurrentOrderIdSelector, getFocussedInspectionId, getInspectionsMap, getIsSameForAllInspections, getLastViewedInspectionPage } from '../selectors/selectors';
import { CLEAR_ORDER_AND_INSPECTIONS_DATA, EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL, EDIT_INSPECTION_CREATED_NEW, EDIT_INSPECTION_CREATED_NEW_CANCEL, EDIT_INSPECTION_DATA_READY, EDIT_INSPECTION_SET_FOCUSSED_INSPECTION, EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS, Inspection, ORDER_AND_INSPECTIONS_REQUEST, ORDER_AND_INSPECTIONS_REQUEST_FAILURE, ORDER_AND_INSPECTIONS_REQUEST_SUCCESS, Order, SET_SAME_ON_ALL_INSPECTIONS } from '../types';

const catchException = errorHandling.handler.catchException;
const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;
const clearStatusMessage = messages.actions.clearStatusMessage;
const clearErrorMessage = messages.actions.clearErrorMessage;
const sendGlobalMessage = messages.actions.sendGlobalMessage;

type PermissionsType = { assigned_user_id: boolean, scheduled_inspection_date: boolean, manage_watchers: boolean, setup_approval_flow: boolean, supplier_qc: boolean };


export const initializeEditInspection = (orderId: string): AppThunk => {
    return async (dispatch): Promise<any> => {
        if (orderId && orderId !== 'new') {
            Promise.all([
                dispatch(getOrderWithInspections(orderId, false, true)),
                dispatch(getOrderUsers(orderId, true)),
                dispatch(getInspectionChecklists()),
                dispatch(getSamplingProtocols()),
            ]).then(([order, users, chl, protocols]) => {
                if (order !== undefined && users !== undefined && chl !== undefined && protocols !== undefined) {
                    dispatch({ type: EDIT_INSPECTION_DATA_READY, payload: { ready: true } });
                } else {
                    dispatch({ type: EDIT_INSPECTION_DATA_READY, payload: { ready: false } });
                }
            });
        } else {
            Promise.all([
                dispatch(getInspectionChecklists()),
            ])
            dispatch({ type: EDIT_INSPECTION_CREATED_NEW, payload: { ready: false } });
        }
    };
};


// Reload existing order with inspection lines
export const reloadOrderWithInspections = (sendMessage = false, isFetching?: boolean): AppThunk => {
    return async (dispatch, getState): Promise<any> => {
        const orderId = getCurrentOrderIdSelector(getState());
        dispatch(getOrderWithInspections(orderId, sendMessage, isFetching));
    }
}
export const getOrderWithInspections = (orderId: string, sendMessage = false, isFetching?: boolean): AppThunk => {
    return async (dispatch): Promise<any> => {
        if (orderId) {
            const url = 'orders/' + orderId;
            if (sendMessage) {
                dispatch(sendStatusMessage(['status_message.loading_order_data'], 0, true));
            }
            dispatch(orderWithInspectionsRequest(isFetching));
            return request(url, {})
                .then((order: Order) => {
                    // order = id;
                    if (sendMessage) {
                        dispatch(clearStatusMessage());
                    }
                    dispatch(orderWithInspectionsRequestSuccess(order));
                    return order;
                })
                .catch((error: RequestError) => {
                    catchException('getOrderWithInspections', {
                        endpoint: 'orders/[orderId]',
                        request: url,
                        status: error.status,
                    }, { error, orderId });
                    console.log('An error occured loading order with orderId: ' + orderId + '. Error code: ', error.status);
                    if (error.status && error.status === 404) {
                        dispatch(clearAllMessages());
                        dispatch(clearErrorMessage());
                        history.replace('/404?type=order_not_found&id=' + orderId + '&error=' + JSON.stringify(error));
                    }
                    console.log('404 ', error);
                    dispatch(sendErrorMessage(['error_message.order_data_may_not_have_loaded_properly']));
                    dispatch(orderWithInspectionsRequestFailure(error.errorText || 'statusText: n/a', error.status));
                });
        }
    };
};

// Requesting order with inspections
export const orderWithInspectionsRequest = createAction(ORDER_AND_INSPECTIONS_REQUEST, (isFetching?: boolean) => {
    return {
        type: ORDER_AND_INSPECTIONS_REQUEST,
        payload: {
            isFetching: isFetching !== null ? isFetching : true,
        },
    };
});

// Requesting order with inspections was a success
export const orderWithInspectionsRequestSuccess = createAction(ORDER_AND_INSPECTIONS_REQUEST_SUCCESS, (order: Order) => {
    return {
        type: ORDER_AND_INSPECTIONS_REQUEST_SUCCESS,
        payload: {
            isFetching: false,
            order,
        },
    };
});

// Requesting order with inspections failed
const orderWithInspectionsRequestFailure = createAction(ORDER_AND_INSPECTIONS_REQUEST_FAILURE, (message: string, status: number) => {
    return {
        type: ORDER_AND_INSPECTIONS_REQUEST_FAILURE,
        payload: {
            isFetching: false,
            message,
            status,
        },
    };
});

export const clearOrderWithInspections = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(clearOrderWithInspectionsRequest());
    };
};

// Create an order with one default inspection
export const createOrderWithInspections = (order: Order): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        // Setting up a new order with scheduled inspection date as today and a default checklist (the first in the checklist list)
        const checklists = getState().app.checklists;
        order.inspections = [({
            item_number: null,
            item_name: null,
        } as Inspection)];
        if (checklists && checklists.selectors.length > 0) {
            const chlId = checklists.selectors[0].id;
            if (order.inspections[0]) {
                order.inspections[0].checklist_id = chlId;
            }
        }
        return request('orders', {
            method: 'post',
            body: JSON.stringify(order),
        })
            .then((data: any) => {
                dispatch(creatingOrderWithInspectionsSuccess(data.order_id, data));
                dispatch(sendStatusMessage(['status_message.the_order_was_created'], 3000));
                dispatch(createOrderDone());
                history.push('/inspections/edit_inspection/' + data.order_id);
            })
            .catch((error: RequestError) => {
                // TODO: remember failure action
                catchException('createOrderWithInspections', {
                    endpoint: 'orders',
                    request: 'orders',
                    status: error.status,
                }, { error, body: order, method: 'POST' });
            });
    };
};

export const createOrderDone = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(cancelCreateOrderRequest());
    }
};

export const cancelCreateOrder = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(cancelCreateOrderRequest());
    }
};

export const cancelCreateOrderRequest = (): Action<EDIT_INSPECTION_CREATED_NEW_CANCEL> => {
    return {
        type: EDIT_INSPECTION_CREATED_NEW_CANCEL,
        payload: {},
    };
};

// Creating an order with inspections was a success
const creatingOrderWithInspectionsSuccess =
    (orderId: string, data: any): Action<CREATE_ORDER_AND_INSPECTIONS_SUCCESS> => {
        return {
            type: CREATE_ORDER_AND_INSPECTIONS_SUCCESS,
            payload: {
                isFetching: false,
                orderId,
                data,
                isNew: true,
            },
        };
    };

export const handleBlurredInspection = (event: any, item: InputProps, inspectionId: string): AppThunk => {
    let name;
    let value;
    if (event) {
        name = event.target.name;
        value = event.target.value;
    } else {
        name = item.name;
        value = item.value;
    }
    const obj = { name, value };
    return async (dispatch): Promise<void> => {
        dispatch(setFocussedInspectionId('NONE'));
        setTimeout(() => {
            dispatch(handleChangedInspection(inspectionId, obj));
        }, 250);
    };
};

export const handleFocussedInspection = (evt: React.SyntheticEvent, inspectionId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(setFocussedInspectionId(inspectionId));
    };
};

export const handleChangedInspection = (inspectionId: string, changedObj: { name: string; value: string }): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const order = getOrderWithInspectionsSelector(getState());
        const isCombinedInspection = isOrderCombinedInspection(order);
        const currentInspection = isCombinedInspection ? order.inspections[0] : getInspectionsMap(getState())[inspectionId];
        const hasChanged = inspectionPropertyChanged(currentInspection, changedObj);
        if (hasChanged) {
            dispatch(updateChangedInspection(inspectionId, { [changedObj.name]: changedObj.value }));
        }
        const changedInspections: any = Object.assign([], getEditInspectionChangedInspections(getState()));
        const focussedInspectionId = getFocussedInspectionId(getState());
        if (focussedInspectionId !== inspectionId && (hasChanged || changedInspections[inspectionId])) {
            const body = changedInspections[inspectionId];
            dispatch(updateSingleInspection(inspectionId, body));
            dispatch(clearChangedInspection(inspectionId));
        }
    };
};

export const updateChangedInspection = (inspectionId: string, obj: any): AppThunk => {
    const key = Object.keys(obj)[0];
    return async (dispatch, getState): Promise<void> => {
        const changedInspections: any = Object.assign([], getEditInspectionChangedInspections(getState()));
        if (changedInspections[inspectionId]) {
            const chId = Object.assign({}, changedInspections[inspectionId]);
            chId[key] = obj[key];
            changedInspections[inspectionId] = Object.assign(obj, chId);

        } else {
            changedInspections[inspectionId] = obj;
        }
        dispatch(updateChangedInspectionRequest(changedInspections));
    };
};

export const clearChangedInspection = (inspectionId: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const changedInspections = Object.assign([], getEditInspectionChangedInspections(getState()));
        if (changedInspections[inspectionId]) {
            delete changedInspections[inspectionId];
        }
        dispatch(updateChangedInspectionRequest(changedInspections));
    };
};

export const updateChangedInspectionRequest = createAction(EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS, (changedInspections) => {
    return {
        type: EDIT_INSPECTION_UPDATE_CHANGED_INSPECTIONS,
        payload: { changedInspections },
    };
});

export const setSameForAllInspectionsRequest = createAction(SET_SAME_ON_ALL_INSPECTIONS, (isSame: boolean, permissions?: any, order?: Order, inspectionsMap?: any) => {
    return {
        type: SET_SAME_ON_ALL_INSPECTIONS,
        payload: {
            isSame,
            permissions,
            order,
            inspectionsMap,
        },
    };
});

export const setSameForAllInspections = (isSame: boolean): AppThunk => {
    return async (dispatch: Dispatch<any>, getState): Promise<void> => {
        const order: Order = getOrderWithInspectionsSelector(getState());
        const inspections: Inspection[] = order.inspections;
        const permissions = getSameForAllPermissions(inspections);
        if (isSame) {
            const inspectionsMap: { [inspectionId: string]: Inspection } = getInspectionsMap(getState());
            const firstInspection: Inspection = getFirstInspectionNotLockedOrDone(inspections) as Inspection;
            inspections.forEach((inspection: Inspection) => {
                if (isInspectionNotLockedAndPlanned(inspection)) {
                    if (!inspection.readonly.includes('scheduled_inspection_date')) {
                        inspection.scheduled_inspection_date = firstInspection.scheduled_inspection_date;
                    }
                    if (!inspection.readonly.includes('assigned_user_id')) {
                        inspection.assigned_user_id = firstInspection.assigned_user_id;
                    }
                    if (inspection.features.includes('manage_watchers')) {
                        inspection.watchers = firstInspection.watchers;
                    }
                    if (inspection.features.includes('setup_approval_flow')) {
                        inspection.approval_flow = firstInspection.approval_flow;
                    }
                    inspection.supplier_qc = firstInspection.supplier_qc;
                    if (inspectionsMap[inspection.inspection_id]) {
                        inspectionsMap[inspection.inspection_id] = inspection;
                    }
                }
            });
            dispatch(setSameForAllInspectionsRequest(true, permissions, order, inspectionsMap));
            dispatch(updateOrder(order.order_id, order, false));

        } else {
            dispatch(setSameForAllInspectionsRequest(false, permissions));
        }
    };
};

export const areAllInspectionsEqual = (): AppThunk => {
    return async (dispatch, getState): Promise<{ equal: boolean; permissions: PermissionsType }> => {
        const order: Order = getOrderWithInspectionsSelector(getState());
        const allApprovalFlowsEqual = order.all_approval_flows_equal;
        const inspections: Inspection[] = order.inspections;
        const firstInspection: Inspection = getFirstNotLockedInspection(inspections);
        const permissions = getSameForAllPermissions(inspections);
        const removeWatcherActivity = (watchers) => {
            watchers.forEach((w: BasicUser) => {
                delete w.last_activity;
            });
            return watchers;
        }
        let equal = true;
        if (firstInspection) {
            const inspector = firstInspection.assigned_user_id;
            const date = firstInspection.scheduled_inspection_date;
            const watchers = removeWatcherActivity(firstInspection.watchers);
            const supplierQCs = firstInspection.supplier_qc;
            inspections.forEach((inspection) => {
                const inspectionWatchers = removeWatcherActivity(inspection.watchers);            
                let isSame = false;
                if (isInspectionNotLockedAndPlanned(inspection)) {
                    isSame = compareObjects(date, inspection.scheduled_inspection_date) &&
                        compareObjects(inspector, inspection.assigned_user_id) &&
                        compareObjects(watchers, inspectionWatchers) &&
                        compareObjects(supplierQCs, inspection.supplier_qc) &&
                        allApprovalFlowsEqual;
                    if (!isSame) {
                        equal = false;
                    }
                }
            });
        }
        dispatch(setSameForAllInspectionsRequest(equal, permissions, undefined, undefined));
        dispatch(areAllInspectionsEqualRequest(equal))
        return { equal, permissions: permissions.permissions };
    };
};

export const getSameForAllPermissions = (inspections: Inspection[]): { permissions: PermissionsType; hasPermissions: boolean } => {
    const permissions = { assigned_user_id: false, scheduled_inspection_date: false, manage_watchers: false, setup_approval_flow: false, supplier_qc: false };
    inspections.forEach((inspection) => {
        if (isInspectionNotLockedAndPlanned(inspection)) {

            if (inspection.readonly.includes('assigned_user_id')) {
                permissions.assigned_user_id = false;
            }
            if (inspection.readonly.includes('scheduled_inspection_date')) {
                permissions.scheduled_inspection_date = false;
            }
            if (inspection.features.includes('manage_watchers')) {
                permissions.manage_watchers = true;
            }
            if (inspection.features.includes('setup_approval_flow')) {
                permissions.setup_approval_flow = true;
            }
            if (inspection.readonly.includes('supplier_qc')) {
                permissions.supplier_qc = false;
            }
        }
    });
    return { permissions, hasPermissions: Object.values(permissions).filter((e) => e).length > 0 };
};

export const areAllInspectionsEqualRequest = createAction(EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL, (areEqual: boolean) => {
    return {
        type: EDIT_INSPECTION_ARE_ALL_INSPECTIONS_EQUAL,
        payload: { areEqual },
    };
});
export const setFocussedInspectionId = createAction(EDIT_INSPECTION_SET_FOCUSSED_INSPECTION, (inspectionId: string) => {
    return {
        type: EDIT_INSPECTION_SET_FOCUSSED_INSPECTION,
        payload: { inspectionId },
    };
});

export const datePickerChange = (dateObj: moment.Moment, type: 'etd' | 'scheduled_inspection_date', inspectionId?: string): AppThunk => {
    console.log('datePickerChange ', dateObj)
    return async (dispatch, getState): Promise<void> => {
        const date = dateObj !== null ? new Date(dateObj && dateObj.format(DEFAULT_DATE_FORMAT)) : null;

        // const isSameForAllInspections = true
        const isSameForAllInspections = getIsSameForAllInspections(getState());
        const inspectionsMap = Object.assign({}, getInspectionsMap(getState()));
        const order: Order = Object.assign({}, getOrderWithInspectionsSelector(getState()));
        const inspections = order.inspections;
        const name = type;
        if (name === 'etd') {
            inspections.forEach((inspection: Inspection) => {
                if (isInspectionNotLockedAndPlanned(inspection)) {
                    inspection[name] = date;
                    if (inspectionsMap[inspection.inspection_id]) {
                        inspectionsMap[inspection.inspection_id][name] = date;
                    }
                }
            });
            dispatch(updateOrder(order.order_id, order, false));
        }
        if (name === 'scheduled_inspection_date') {
            if (isSameForAllInspections) {
                // Setting same values on all scheduled inspection dates
                inspections.forEach((inspection: Inspection) => {
                    if (isInspectionNotLockedAndPlanned(inspection)) {
                        inspection[name] = date;
                        if (inspectionsMap[inspection.inspection_id]) {
                            inspectionsMap[inspection.inspection_id][name] = date;
                        }
                    }
                });
                dispatch(updateOrder(order.order_id, order, false));
            } else {
                inspectionsMap[inspectionId][name] = date;
                dispatch(updateSingleInspection(inspectionId, { [name]: date }));
            }
        }
    };
};

export const handleAction = (item: { name: string; value: any }, inspectionId: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const isSameForAllInspections = getIsSameForAllInspections(getState());
        const order: Order = getOrderWithInspectionsSelector(getState());
        const isCombinedInspection = isOrderCombinedInspection(order);
        if (inspectionId) {
            const isPropertyInForAllInspections = getIsPropertyInSameForAllInspections(item.name);
            if (isSameForAllInspections && isPropertyInForAllInspections && !isCombinedInspection) {
                const inspectionsMap = getInspectionsMap(getState());
                const inspections = order.inspections;
                inspections.forEach((inspection: Inspection) => {
                    if (isInspectionNotLockedAndPlanned(inspection)) {
                        inspection[item.name] = item.value;
                        inspectionsMap[inspection.inspection_id][item.name] = item.value;
                    }
                });
                dispatch(updateOrder(order.order_id, order, true));
            } else {
                dispatch(updateSingleInspection(inspectionId, { [item.name]: item.value }));
            }
        } else {
            const orderId = order.order_id;
            dispatch(updateOrder(orderId, { [item.name]: item.value }));
        }
    };
};

export const handleChange = (event: React.SyntheticEvent, item: InputProps, inspectionId?: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const order: Order = getOrderWithInspectionsSelector(getState());
        const inspectionsMap = getInspectionsMap(getState());
        const isCombinedInspection = isOrderCombinedInspection(order);
        if (inspectionId) {
            const { name, value } = item;
            const isSameForAllInspections = getIsSameForAllInspections(getState());
            const isPropertyInForAllInspections = getIsPropertyInSameForAllInspections(name);
            if (isSameForAllInspections && isPropertyInForAllInspections && !isCombinedInspection) {
                const inspections = order.inspections;
                inspections.forEach((inspection: Inspection) => {
                    if (isInspectionNotLockedAndPlanned(inspection)) {
                        inspection[name] = item.value;
                        if (inspectionsMap[inspection.inspection_id]) {
                            inspectionsMap[inspection.inspection_id][name] = item.value;
                        }
                    }
                });
                dispatch(updateOrder(order.order_id, order, true));
            } else {
                const body = { [name]: value };
                dispatch(updateSingleInspection(inspectionId, body));
            }
        } else {
            const orderId = order.order_id;
            dispatch(updateOrder(orderId, { [item.name]: item.value }));
        }
    };
};

const clearOrderWithInspectionsRequest = createAction(CLEAR_ORDER_AND_INSPECTIONS_DATA, () => {
    return {
        type: CLEAR_ORDER_AND_INSPECTIONS_DATA,
    };
});

export const saveOrder = (notifyInspectors: boolean): AppThunk =>
    async (dispatch, getState): Promise<void> => {
        const order: Order = getOrderWithInspectionsSelector(getState());
        Promise.all([dispatch(updateOrder(order.order_id, order, false, false, notifyInspectors))]).then((response) => {
            if (response && !response[0].error) {
                const redirecturl = getLastViewedInspectionPage(getState());
                history.push(redirecturl);
                notifyInspectors ?
                    dispatch(sendGlobalMessage(['status_message.order_was_saved_and_inspector_notified'], 5000))
                    :
                    dispatch(sendGlobalMessage(['status_message.order_was_saved'], 5000));
            }
        }).catch((e) => {
            console.log('Edit page save error ', e)
        });

    };
