import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalProps } from 'semantic-ui-react';

interface ConfirmProps {
    show: boolean;
    children?: any;
    handleCancel();
    confirmAction();
    className?: string;
    headerTextKey?: string;
    type?: 'secondary' | 'primary'
    size?: ModalProps['size'];
}

const Confirm = (props: ConfirmProps): React.ReactElement => {
    const { type = 'secondary', size = 'mini' } = props;
    return (
        <Modal
            open={props.show}
            size={size}
            className={props.className}>
            <Modal.Header><FormattedMessage id={props.headerTextKey || 'inspections.proceed'} /></Modal.Header>
            <Modal.Content>
                {props.children}
            </Modal.Content>
            <Modal.Actions>
                <Button primary={type === 'primary'} secondary={type === 'secondary'} onClick={(): void => {
                    props.confirmAction();
                    props.handleCancel();
                }}><FormattedMessage id='inspections.ok' /></Button>
                <Button onClick={(): void => props.handleCancel()}><FormattedMessage id='inspections.cancel' /></Button>
            </Modal.Actions>
        </Modal>
    );
};

export default Confirm;
