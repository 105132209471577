import { handleActions } from 'redux-actions';
import { Action } from './../../base/genericActions';
import * as types from './types';

const initialState: types.STUFF = {
    stuff: undefined,
};

export const reducer = handleActions({
    [types.STORE_STUFF]: (state = initialState, action: Action<types.STORE_STUFF>) => {
        const stuff = action.payload.stuff;
        return {
            ...state,
            stuff,
        };
    },
}, initialState);

export default reducer;
