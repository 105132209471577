import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { CorrectiveAction } from '../../../backend_api/models';
import Link from '../../../base/components/basic/Link';
import { getLocaleSpecificString } from '../../../base/utils';

type Props = {
    correctiveAction: CorrectiveAction;
}

const CheckpointLinkList = (props: Props) => {
    const { correctiveAction } = props;
    const history = useHistory();
    const checkpointLinks = props.correctiveAction.checkpoint_links
    const context = correctiveAction.inspection_links.length > 0 ? 'inspection' : 'audit';
    return <Fragment>
        {checkpointLinks.map((cpLink) => {
            const link = context === 'inspection' ? '/inspections/reports/' + correctiveAction.inspection_links[0].inspection_id + '#checkpoint_' + cpLink.checkpoint_id : '/audits/report/' + correctiveAction.audit_links[0].audit_id + '#checkpoint_' + cpLink.checkpoint_id;
            return <Link key={'link_' + link} to={link}>{getLocaleSpecificString(cpLink.instruction_text)}</Link>
        })}
    </Fragment>
}

export default CheckpointLinkList;