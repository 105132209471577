/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.5.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Entities to export with audits.
 * @export
 * @enum {string}
 */
export enum AuditsExportAdditionalObjects {
    Checkpoints = 'checkpoints',
    CorrectiveActions = 'corrective_actions'
}

export function AuditsExportAdditionalObjectsFromJSON(json: any): AuditsExportAdditionalObjects {
    return AuditsExportAdditionalObjectsFromJSONTyped(json, false);
}

export function AuditsExportAdditionalObjectsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditsExportAdditionalObjects {
    return json as AuditsExportAdditionalObjects;
}

export function AuditsExportAdditionalObjectsToJSON(value?: AuditsExportAdditionalObjects | null): any {
    return value as any;
}

