import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { twMerge } from '../../../base/utils';
import { getFilterDataIsFetchingSelector } from '../selectors';

type DialogFilterProps = {
    name: string;
    label: string;
    trigger: {
        text?: string | React.ReactElement;
        isSelected?: boolean;
        popup?: React.ReactElement;
        showLabels?: boolean;
        simple?: string | React.ReactElement;
        className?: string;
        icon?: string;
    };
    header?: React.ReactElement | string;
    content?: React.ReactElement;
    actionButtonText?: string;
    actions?: React.ReactElement;
    fluid?: boolean;
    disabled?: boolean;
    disableClear?: boolean;
    save?(): void;
    cancel?(): void;
    remove?(): void;
    onOpen?(): void;
    onClose?(): void;
};

const DialogFilter = (props: DialogFilterProps): React.ReactElement => {
    const { name, label, actions, actionButtonText, content, header, trigger, fluid = false, disabled = false, disableClear = false } = props;
    const [show, setShow] = useState(false);
    const fetchingFilterData: boolean = useSelector(getFilterDataIsFetchingSelector);

    const cancel = (): void => {
        setShow(false);
        if (props.cancel) {
            props.cancel();
        }
    };

    const remove = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        cancel();
        if (props.remove) {
            props.remove();
        }
    };

    const saveFilter = (): void => {
        setShow(false);
        if (props.save) {
            props.save();
        }
    };

    const dialogTrigger = (): React.ReactElement => {
        const hasRemoveAction = () => {
            if(trigger.isSelected) {
                return !disableClear;
            }
        }
        let text;
        if (trigger.simple && trigger.simple) {
            text = <div className='link'>{trigger.simple}</div>;
        }
        if (trigger.text) {
            text = <span className={twMerge('w-full filter', name)}>
                {trigger.showLabels && <div className={twMerge(' sm:text-secondary text-primary font-bold sm:font-normal text-sm mb-1')}>{label}</div>}
                <div className={twMerge('bg-white flex w-full justify-between rounded border-default border')}>
                    <div className='flex'>{trigger.icon && trigger.icon && <i className={twMerge('mi text-2xl px-2 py-1 text-gray-500 border-input border-r')}>{trigger.icon}</i>}
                        <Button
                            className={twMerge('px-3 bg-white flex border-0 truncate', trigger.className)} >
                            {trigger.text}

                        </Button >
                    </div>
                    {hasRemoveAction() && <div className='items-center flex border-0 border-default px-2 bg-surface-plain rounded-r-md border-l'>
                        <RemoveIcon
                            className='cursor-pointer'
                            dataTestId='remove-filter-icon'
                            onClick={(e): void => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                remove(e);
                            }}
                        />

                    </div>}
                </div>
            </span>;
        }
        return text;
    }

    const dialog = <Modal
        disabled={disabled}
        trigger={dialogTrigger()}
        open={show}
        onOpen={(): void => {
            setShow(true);
            if (props.onOpen) {
                props.onOpen();
            }
        }}
        closeIcon={true}
        closeOnEscape={true}
        onClose={(): void => {
            setShow(false);
            if (props.onClose) {
                props.onClose();
            }
        }}
    >
        {header && <Modal.Header>{header}</Modal.Header>}
        {fetchingFilterData && <Modal.Content><FormattedMessage id='globals.loading_data' /></Modal.Content>}
        {!fetchingFilterData && content && <Modal.Content>{content}</Modal.Content>}
        <Modal.Actions>{actions ? actions :
            <div className='flex pb-4 sm:pb-0'>
                <Button className='' onClick={saveFilter} primary>{actionButtonText ? actionButtonText : <FormattedMessage id='filters.date.button_ok' />}</Button>
                <Button onClick={cancel}><FormattedMessage id='globals.cancel' /></Button>
            </div>
        }</Modal.Actions>
    </Modal>;
    return dialog;
};

export default DialogFilter;
