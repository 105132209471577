import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { Audit } from '../../../backend_api/models/Audit';
import { getPrettyDate, useIsMobileDevice, useIsMobileDevicePortrait } from '../../../base/utils';
import { TextItem } from '../../globals/components';
import LocationMap from '../../inspections/components/LocationMap';
import DownloadReport from '../../inspections/components/report/report2/DownloadReport';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getSupplierNameAndNumber } from '../../suppliers/util';
import { UserLine } from '../../users/components/Widgets';
import { getUsersById } from '../../users/selectors';
import AuditAttachments from './AuditAttachments';
import ExpandItem from './ExpandItem';

type AuditInfoProps = {
    audit: Audit;
};

const AuditInfo = (props: AuditInfoProps): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const users = useSelector(getUsersById);
    const { audit } = props;

    const isAssignedUserExternal = audit?.assigned_user && !users[audit?.assigned_user?.id];
    const isMobilePortrait = useIsMobileDevicePortrait();
    const isMobile = useIsMobileDevice();
    
    const getChecklistNameAndRevisionText = (audit: Audit): React.ReactElement => {
        let content;
        if (audit.checklist_revision) {
            const text = intl.formatMessage({ id: 'inspections.report.info.checklist_name_and_revision' }, { checklistName: audit.checklist_name, checklistRevision: audit.checklist_revision });
            content = <Popup position='top center' flowing trigger={<span>{text}</span>}>
                <Popup.Header>{audit.checklist_name}</Popup.Header>
                <Popup.Content>
                    <TextItem label={intl.formatMessage({ id: 'inspections.report.info.checklist_revision_label' })} text={audit.checklist_revision} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.report.info.checklist_revision_time_label' })} text={getPrettyDate(audit.checklist_revision_timestamp)} />
                </Popup.Content>
            </Popup>
        } else {
            content = audit.checklist_name || '-';
        }
        return <TextItem className='cursor-default' column={isMobilePortrait} label={formatMessage({ id: 'inspections.checklist' })} text={content} />;
    }

    return <ContentSection

        headerText={<div className='flex w-full justify-between items-center'><FormattedMessage id='audit_report.info.details' />{!isMobile && <DownloadReport inspectionId={audit.id} context='audit' />}</div>}
        content={<>
            <TextItem label={formatMessage({ id: 'audit_report.info.production_unit' })} text={audit.target_production_unit && audit.target_production_unit.name || '-'} />
            <TextItem label={formatMessage({ id: 'audit_report.info.supplier' })} text={getSupplierNameAndNumber(audit.target_supplier &&audit.target_supplier.name, audit.target_supplier && audit.target_supplier.number)} />

            <TextItem label={formatMessage({ id: 'audit_report.info.date' })} text={getPrettyDate(audit.end_time, '') || '-'} />
            <TextItem label={formatMessage({ id: 'audit_report.info.inspector' })} text={audit.assigned_user && <UserLine isExternalUser={isAssignedUserExternal} user={audit.assigned_user} /> || '-'} />
            {getChecklistNameAndRevisionText(audit)}
            <TextItem label={formatMessage({ id: 'audit_report.info.audit_type' })} text={audit.audit_type && audit.audit_type.name || intl.formatMessage({ id: 'audits.audit_type.no_type' })} />
            <TextItem label={formatMessage({ id: 'audit_report.info.gps_location' })} text={audit.last_tracked_location && <LocationMap location={audit.last_tracked_location} /> || '-'} />            
            <ExpandItem label={intl.formatMessage({id: 'audits.edit_audit.attachments.label'})} cnt={audit.attachments && audit.attachments.length} className='border-t pt-4' hideclassName=''><AuditAttachments isReport attachments={audit.attachments} auditId={audit.id} /></ExpandItem>




        </>
        } />
}
export default AuditInfo;
