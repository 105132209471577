import React from 'react';
import { DetailedSupplier } from '../../../backend_api/models';
import { useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { getLegacySuppliersList, getLegacySuppliersListLoadingSelector, getLegacySuppliersListSelector } from '../../suppliers/slice/suppliersSlice';

export function useSuppliers(): DetailedSupplier[] | 'loading' {
    const dispatch = useAppDispatch();
    const state = useSelector(getLegacySuppliersListSelector);
    const isLoading = useSelector(getLegacySuppliersListLoadingSelector)

    React.useEffect(() => {
        dispatch(getLegacySuppliersList())
    }, [])

    if (isLoading && isEmpty(state)) {
        return 'loading'
    }

    return state;
}

function isEmpty(list: undefined | any[]) {
    if (!list) {
        return true
    }
    return list.length === 0;
}