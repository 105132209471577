import classnames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { getPrettyDate } from '../../../../base/utils';

type BookingETDProps = {
    date: string;
    isOverdue?: boolean;
};

const BookingETD = (props: BookingETDProps): React.ReactElement => {
    const intl = useIntl();
    const cn = classnames({
        etd: true,
        overdue: props.isOverdue,
    });
    return <div className={cn}>{getPrettyDate(props.date, intl.locale)}</div>;
};

export default BookingETD;
