import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { convertListToDictionary, deepCopy, isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { getCustomFields } from '../../customFields/selectors';
import { CustomField } from '../../customFields/types';
import { OptionsType } from '../../../base/types';
import Button from '../../../base/ui/components/buttons/Button';
import { Sizes } from '../../../base/ui/components/types';
import DnDList from '../../sortableList/components/DnDList';

type InspectionCustomFieldsProps = {
    data: string[];
    update(customFieldIds: string[]);
}

const InspectionCustomFields = (props: InspectionCustomFieldsProps): React.ReactElement => {
    const {data, update} = props;
    const intl = useIntl();

    const allCustomFields: CustomField[] = useSelector(getCustomFields);
    const allCustomFieldsMap = convertListToDictionary(allCustomFields, customField => customField.id);
    const [selectedValue, setSelectedValue] = React.useState<string | undefined>();

    const selectedCustomFieldIds = data || [];
    const selectedCustomFields = selectedCustomFieldIds.map(id => allCustomFieldsMap[id]);
    const selectedCustomFieldsMap = convertListToDictionary(selectedCustomFields, customField => customField.id);
    const [showOrderData, setShowOrderData] = React.useState(selectedCustomFieldIds?.length > 0);
    
    const customFieldOptions = convertCustomFieldsToOptionTypes(allCustomFields.filter(cf => !selectedCustomFieldsMap[cf.id]));

    const dndItems = <DnDList items={selectedCustomFields.map((cf, index) => {
        return <div className=' pl-2 hover:bg-gray-200 border-gray-100 border items-center cursor-move py-2 w-full flex justify-between' key={cf.name}>
            {cf.name}<Button className='bg-white text-xs' size={Sizes.Small} onPress={() => removeCustomField(index)}>
                <FormattedMessage id='checklists.editor.custom_fields.remove' />
            </Button></div>
    })}
        itemMoved={(from, to) => {
            const deepCopied = deepCopy(selectedCustomFieldIds);
            const temp = deepCopied[from];
            deepCopied[from] = deepCopied[to];
            deepCopied[to] = temp;
            update(deepCopied);
        }}
        enableDrage={true} />

    function onChoiceChanged(evt, item) {
        setSelectedValue(item.value)
    }

    function removeCustomField(indexToRemove) {
        const deepCopied = deepCopy(selectedCustomFieldIds);
        const idsToUpdate = deepCopied.filter((id, index) => index != indexToRemove);
        update(idsToUpdate);
    }

    function addCustomField() {
        const customField = allCustomFieldsMap[selectedValue];
        const isCFAlreadySelected = !!selectedCustomFieldsMap[customField.id];
        if(customField && !isCFAlreadySelected) {
            const deepCopied = deepCopy(selectedCustomFieldIds);
            deepCopied.push(customField.id);
            update(deepCopied);
        }
    }

    function buildChoicesForDropdown(query?: string) {
        return customFieldOptions.filter(option => option?.text?.toString().toLowerCase().includes(query?.toLowerCase()));
    }

    return (
        <div className='py-4'>
            {
                showOrderData ?
                <>
                    <div className="self-stretch text-black text-base font-bold leading-normal">
                        <FormattedMessage id='checklists.editor.custom_fields.order_data' />
                    </div>
                    {dndItems}
                    <div className='flex space-x-1  pt-2'>
                        <Dropdown selection
                            placeholder={intl.formatMessage({ id: 'checklists.editor.custom_fields.selector.select_order_data' })}
                            fluid
                            closeOnBlur
                            search={function (choices, query: string) {
                                return buildChoicesForDropdown(query);
                            }}
                            value={selectedValue}
                            options={customFieldOptions}
                            onChange={onChoiceChanged}
                            data-test-id='checkpoint-type-dropdown'
                        />
                        <Button primary size={Sizes.Medium} disabled={isUndefinedOrNullOrEmptyString(selectedValue)} className='leading-normal whitespace-nowrap' onPress={addCustomField}>
                            {intl.formatMessage({ id: 'checklists.editor.custom_fields.add' })}
                        </Button>
                    </div>
                </> :
                <button className='text-brand' onClick={() => setShowOrderData(true)}>
                    {intl.formatMessage({ id: 'checklists.editor.custom_fields.add_order_data'})}
                </button>
            }
        </div>
    )
}

export default InspectionCustomFields;

function convertCustomFieldsToOptionTypes(customFields: CustomField[]): OptionsType[] {
    if(!customFields) {
        return [];
    }

    return customFields.map(customField => {
        return {
            id: customField.id,
            key: customField.name,
            value: customField.id,
            text: customField.name
        }
    })
}