import { createSelector } from 'reselect';
import { ReportCheckpointHeader, ReportDefect } from '../../../backend_api/models';
import { hasFeatureSelector } from '../../../base/selectors';
import { AppState } from '../../../base/types';
import { CheckpointRemark } from '../../checklists/types';
import { ImageItems, Inspection, ReportDataState } from '../types';

type rs = { app: { inspectionReport: ReportDataState } };

const reportDefectsSummary = (state): ReportDefect[] => state.app.inspectionReport.report.data && state.app.inspectionReport.report.data.defectsSummary;
export const getReportDefectsSummarySelector = createSelector(
    [reportDefectsSummary],
    (summary) => summary,
);

const reportCheckpointsWithRemarks = (state): CheckpointRemark[] => state.app.inspectionReport.report.data && state.app.inspectionReport.report.data.checkpointsRemarks || [];
export const reportCheckpointsWithRemarksSelector = createSelector(
    [reportCheckpointsWithRemarks],
    (remarks) => remarks,
);

const reportIsFetching = (state: rs): boolean => state.app.inspectionReport && state.app.inspectionReport.report.isFetching;
export const getReportIsFetchingSelector = createSelector(
    [reportIsFetching],
    (isFetching) => isFetching,
);

const settingBulk = (state: rs): boolean => state.app.inspectionReport.changingBulkStatus;
export const getChangingBulkStatus: any = createSelector(
    [settingBulk],
    (bool) => bool,
)


const reportCheckpoints = (state: rs): ReportCheckpointHeader[] => state.app.inspectionReport.report.data && state.app.inspectionReport.report.data.checkpoints || [];
export const getReportCheckpointsSelector = createSelector(
    [reportCheckpoints],
    (checkpoints) => checkpoints,
);
const getReportCeckpointWithRemarksIds = (state: rs) => state.app.inspectionReport.report.data && state.app.inspectionReport.report.data.checkpointWithRemarksIds || [];
export const getReportCeckpointWithRemarksIdsSelector = createSelector(
    [getReportCeckpointWithRemarksIds],
    (ids) => ids,
);

const reportImages = (state: rs): ImageItems => state.app.inspectionReport.report.imagesItems;
export const getReportImagesSelector = createSelector(
    [reportImages],
    (images) => images,
);

const reportMatrixData = (state: rs) => state.app.inspectionReport.orderMatrix;
export const getReportMatrixDataSelector = createSelector(
    [reportMatrixData],
    (matrixData) => matrixData,
);

const reportInspectionsByTypesId = (state: rs, typeIds: string[]): Inspection[] => {
    const inspections = state.app.inspectionReport.report.data.order.inspections;
    if (typeIds.length > 0) {
        return inspections.filter((inspection: Inspection) => {
            return inspection.inspection_type && typeIds.includes(inspection.inspection_type.id);
        });
    }
    return inspections;
};
export const getReportInspectionsByTypesSelector: any = createSelector(
    [reportInspectionsByTypesId],
    (date) => date,
);


const reportNoGroupedInspections = (state: rs): Inspection[] => state.app.inspectionReport.report.data.order.inspectionsNoTypes;
export const getReportNoGroupedInspectionsSelector: any = createSelector(
    [reportNoGroupedInspections],
    (noGroupData) => noGroupData,
);

const reportGroupedInspections = (state: AppState): any => state.app.inspectionReport.report.data.order.inspectionsGrouped;
const reportGroupedInspectionsByTypeId = (state: AppState, typeIds: string[]): { byId: Inspection[] } | any => {
    const gi = reportGroupedInspections(state);
    const hasGroups = hasFeatureSelector(state, 'u_grouped_inspections');
    const retObj = {};
    if (typeIds.length > 0) {
        Object.keys(gi).map((groupById: string) => {
            gi[groupById].forEach((inspection: Inspection) => {
                if (typeIds.includes(inspection.inspection_type.id)) {
                    if (!retObj[groupById]) {
                        retObj[groupById] = [];
                    }
                    retObj[groupById].push(inspection);
                }
            });
        });
        return retObj;
    }
    return hasGroups ? gi : {};
};
export const getGroupedInspectionsByTypeIdSelector = createSelector(
    [reportGroupedInspectionsByTypeId],
    (dataByTypeId) => dataByTypeId,
);

const dontScrollToTop = (state: rs): boolean => state.app.inspectionReport.report.dontScrollToTop;
export const getReportDontScrollToTop = createSelector(
    [dontScrollToTop],
    (noGroupData) => noGroupData,
);

