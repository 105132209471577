import React from 'react';
import { useIntl } from 'react-intl';
import { InfoIcon } from '../../../globals/components/Utils';
import { Inspection } from '../../types';

type SampleQtyInfoProps = {
    inspection: Inspection;
    hover?: boolean;
    inline?: boolean;
};

const SampleQtyInfo = (props: SampleQtyInfoProps): React.ReactElement => {
    const intl = useIntl();
    const { inspection, hover = false, inline = false } = props;
    if (inspection.calculated_sample_quantity && inspection.sample_quantity !== inspection.calculated_sample_quantity) {
        const sampleQty = inspection.sample_quantity;
        const calcQty = inspection.calculated_sample_quantity;
        const text = intl.formatMessage({ id: 'report.info.sample_qty_was_changed_by_the_inspector' }, { calcQty, sampleQty });
        return hover ? <InfoIcon compact={true} noMargins={true} inline={inline} hoverText={text} /> : <InfoIcon inline={inline} compact={true} text={text} />;
    }
    return null;
};

export default SampleQtyInfo;
