import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { CorrectiveActionsStatuses, CreateCorrectiveActionRequest } from '../../../../../backend_api/models';
import SpinnerIcon from '../../../../../base/components/basic/SpinnerIcon';
import { useAppDispatch } from '../../../../../store';
import AuditCorrectiveActionStatusPie from '../../../../audit/components/AuditCorrectiveActionStatusPie';
import CorrectiveActionCompactList from '../../../../correctiveActions/components/CorrectiveActionCompactList';
import CorrectiveActionMediumList from '../../../../correctiveActions/components/CorrectiveActionMediumList';
import CreateCorrectiveActionDialog from '../../../../correctiveActions/components/CreateCorrectiveActionDialog';
import { createCorrectiveActionOnInspection, getCorrectiveActionsLoadingSelector, getCorrectiveActionsOnInspection, getCorrectiveActionsOnInspectionListSelector } from '../../../../correctiveActions/correctiveActionsSlice';
import { CorrectiveActionRelation } from '../../../../correctiveActions/types';
import ContentSection from '../../../../pageLayouts/components/ContentSection';

type Props = {
    inspectionId: string;
    name: string;
    relation?: CorrectiveActionRelation;
    isAllowedToCreateCA?: boolean;
}

const InspectionsCorrectiveActionsList = (props: Props): React.ReactElement => {
    const { relation = CorrectiveActionRelation.Audit, name, isAllowedToCreateCA = false } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const correctiveActions = useSelector(getCorrectiveActionsOnInspectionListSelector);
    const isFetching = useSelector(getCorrectiveActionsLoadingSelector);
    const hasCorrectiveActions = correctiveActions && correctiveActions.length > 0;
    const [caMap, setCaMap] = useState<CorrectiveActionsStatuses>(null);
    useEffect(() => {
        dispatch(getCorrectiveActionsOnInspection({ inspectionId: props.inspectionId }))
    }, [])
    useEffect(() => {
        if (Array.isArray(correctiveActions)) {
            const m = {
                finding: correctiveActions.filter((ca) => ca.state === 'finding').length,
                closed: correctiveActions.filter((ca) => ca.state === 'closed').length,
                review: correctiveActions.filter((ca) => ca.state === 'review').length,
                in_progress: correctiveActions.filter((ca) => ca.state === 'in_progress').length,
            };
            setCaMap(m);
        }
    }, [correctiveActions])
    const create = (ca: CreateCorrectiveActionRequest) => {
        ca.audit_links = [props.inspectionId]
        dispatch(createCorrectiveActionOnInspection({ inspectionId: props.inspectionId, 'list': [ca] }));
    }

    const expandedContent = correctiveActions && <CorrectiveActionMediumList correctiveActions={correctiveActions} className='py-4' />;
    const caPie = caMap ? <AuditCorrectiveActionStatusPie correctiveActionStatus={caMap} w={25} h={25} showEmptyCorrectiveAction={false} auditId={'props.auditId'} /> : null;

    const content = <div id={correctiveActions && correctiveActions.length > 0 ? 'inspectionReportCorrectiveActionsSummary' : 'inspectionReportCorrectiveActionsSummaryNoItems'}>
        <CorrectiveActionCompactList className='' correctiveActions={correctiveActions} relation={CorrectiveActionRelation.Inspection} />
        {isAllowedToCreateCA && <>
            <Button onClick={() => setOpenModal(true)} data-test-id="button-create-corrective-action" disabled={isFetching}>
                <div className='flex space-x-1'>
                    {isFetching && <SpinnerIcon className='text-brand' />}
                    <div><FormattedMessage id='corrective_actions.create_button' /></div>
                </div>
            </Button>
            <CreateCorrectiveActionDialog open={openModal} close={() => setOpenModal(false)} create={create} />
        </>}
    </div >

    return correctiveActions && <ContentSection
        showExpanded={hasCorrectiveActions}
        headerTextExpanded={intl.formatMessage({ id: 'corrective_actions.medium_list.cas_on_' + relation }, { name })}
        headerText={<div className='flex justify-between items-center w-full'>
            <div>{intl.formatMessage({ id: 'corrective_actions.section_header' })}</div>
            <div className='pr-2'>{caPie}</div>
        </div>}
        content={content}
        expandedContent={expandedContent}
    />
}

export default InspectionsCorrectiveActionsList;
