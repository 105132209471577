import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { DetailedSupplier, ProductionUnit } from '../../../../backend_api/models';
import { BasicUser } from '../../../../backend_api/models/BasicUser';
import Image from '../../../../base/components/Image';
import InspectorIcon from '../../../../base/components/basic/InspectorIcon';
import { byId } from '../../../../base/types';
import { containsLongAndUnbrokenString, objectHasKeys, useIsMobileDevice } from '../../../../base/utils';
import { TextItem } from '../../../globals/components';
import LineTogglerButton from '../../../globals/components/views/list/LineTogglerButton';
import { ListViewItem, ListViewLine } from '../../../globals/components/views/list/ListView';
import { getSupplierNameAndNumber } from '../../../suppliers/util';
import { UserInitials, UserInitialsText } from '../../../users/components/Widgets';
import { getFirstAssignedUser } from '../../Utils';
import { OrderListInspectionExtended, OrderListOrderExtended } from '../../slices/inspectionsListSlice';
import { Inspection } from '../../types';
import ReassignInspector from '../ReassignInspector';
import { OrderStatus } from '../edit/Status';
import OrderDate from './views/listView/OrderDate';

type OrderProps = {
    order: OrderListOrderExtended;
    open: boolean;
    index: number;
    isOnlyOrder: boolean;
    toggleInspection(key: string): void;
};

const Order = (props: OrderProps): React.ReactElement => {
    const { open = false, index, toggleInspection } = props;
    const intl = useIntl();
    const [order, updateOrder] = useState(props.order || null);
    const [selectedInspector, setSelectedInspector] = useState(getFirstAssignedUser(order.inspections, true));
    const isMobile = useIsMobileDevice();
    const getTitle = (inspections: OrderListInspectionExtended[], ordersCnt: number): string => {
        let title = inspections[0] && inspections[0].item_name;
        if (title === null) {
            title = '';
        }
        if (ordersCnt > 1) {
            title += ' + ' + (ordersCnt - 1) + ' ' + intl.formatMessage({ id: 'inspections.more' });
        }
        return title;
    };
    const getToggler = (open: boolean): React.ReactElement => {
        return <>
            <span className='hidden md:flex inspection-toggler'><LineTogglerButton open={open} itemsCnt={order.cnt} /></span>
            <span className='md:hidden justify-center items-center flex'>{order.cnt}<i className='mi text-secondary'>{open ? 'expand_less' : 'expand_more'}</i></span>
        </>;
    };
    const getUsersOnOrder = (order): byId<BasicUser> => {
        const u: byId<BasicUser> = {};
        order.inspections.forEach((inspection) => {
            if (inspection.assigned_user) {
                u[inspection.assigned_user.id] = inspection.assigned_user;
            }
        });
        return u;
    };
    const even = index % 2 === 0;
    const usersOnOrder = getUsersOnOrder(order);
    const usersCtn = Object.keys(usersOnOrder).length;

    useEffect(() => {
        updateOrder(props.order)
    }, []);
    useEffect(() => {
        updateOrder(props.order);
        setSelectedInspector(getFirstAssignedUser(props.order.inspections, true));
    }, [props.order]);
    const getOrderImage = (order: OrderListOrderExtended): React.ReactElement => {
        const img = order.inspections.filter((inspection) => inspection.item_image);
        return img.length > 0 ? <div className='w-12 h-12 sm:w-16 sm:h-12'><Image squared url={img[0].item_image + '?w=150'} /></div> : null;
    }
    const getSupplier = (inspections: Inspection[]): string | React.ReactElement => {
        let hasPU = false;
        const suppliers: byId<{ supplier: DetailedSupplier, productionUnit: ProductionUnit }> = {}
        inspections.forEach((insp) => {
            if (insp.supplier_entity && !suppliers[insp.supplier_entity.id] && insp.supplier_entity.id) {
                suppliers[insp.supplier_entity.id] = { supplier: insp.supplier_entity, productionUnit: insp.linked_production_unit };
                if (!hasPU && insp.linked_production_unit) {
                    hasPU = true;
                }
            }
        });
        if (objectHasKeys(suppliers)) {
            const values = (Object.values(suppliers));
            const supplierCnt = values.length;
            let ret = values[0].supplier.name;
            if (supplierCnt > 1) {
                ret += ' + ' + (supplierCnt - 1).toString();
            }
            return (hasPU || supplierCnt > 1) ? <Popup
                style={{ minWidth: '350px' }}
                trigger={<div>{ret}</div>}>
                {supplierCnt > 1 && <div><FormattedMessage id='inspections.suppliers_on_order' values={{ cnt: supplierCnt }} /></div>}
                {values.map((val, i) => {
                    return <div key={'supp_' + val.supplier.id + i} className={twMerge('mt-2 mb-4', supplierCnt > 1 && 'pt-4 -mx-4 px-4 border-t')}>
                        {supplierCnt > 0 && <div className='font-bold mb-2'>{getSupplierNameAndNumber(val.supplier.name, val.supplier.number)}</div>}
                        {supplierCnt > 0 && <div className='font-bold mb-2 text-sm'><FormattedMessage id='inspections.production_unit_info.header' /></div>}
                        {!hasPU && <div><FormattedMessage id='inspections.no_production_unit' /></div>}
                        {hasPU && <>
                            <TextItem labelFixed={true} labelClass={'font-normal'} label={intl.formatMessage({ id: 'inspections.production_unit_info.name' })} text={val.productionUnit && val.productionUnit.name || '-'} />
                            <TextItem labelFixed={true} labelClass={'font-normal'} label={intl.formatMessage({ id: 'inspections.production_unit_info.address' })} text={val.productionUnit && val.productionUnit.address || '-'} />
                        </>}
                    </div>
                })}
            </Popup> : ret;
        }
        return null;
    }

    const getInspector = (selectedInspector: BasicUser, showIcon: boolean): React.ReactElement => {
        const user = selectedInspector;
        const trigger = showIcon ? <UserInitialsText user={user} appendText={usersCtn > 1 && <span className='font-normal user-initials'> + {(usersCtn - 1).toString()}</span>} /> : <UserInitials user={user && user} smallText={usersCtn > 1} appendText={usersCtn > 1 && <span className='font-normal user-initials'> + {(usersCtn - 1).toString()}</span>} />;
        return <div data-test-id={showIcon ? '' : 'div-assign-inspector'} className='flex'>
            {showIcon && <InspectorIcon />}
            {user ?
                (<Popup
                    disabled={isMobile}
                    on='hover'
                    trigger={<div>{trigger}</div>}>

                    {usersCtn > 1 && <div className='mb-2'><FormattedMessage id='inspections.list.number_of_inspectors' values={{ cnt: usersCtn }} /></div>}
                    {usersCtn === 1 && <div className=' mb-2 mt-2'>
                        <div className='font-bold'>
                            {[user.firstname || '-', ' ', user.lastname || '-']}
                        </div>
                        {user.email}
                        {user.disabled && <div><FormattedMessage id='users.user_is_disabled' /></div>}
                    </div>}
                    {usersCtn > 1 && Object.values(usersOnOrder).map((user: BasicUser) => {
                        if (user.id !== 'selectedInspector') {
                            return <div className=' mb-2'>
                                <div className='font-bold'>
                                    {[user.firstname || '-', ' ', user.lastname || '-']}
                                </div>
                                {user.email}
                                {user.disabled && <div><FormattedMessage id='users.user_is_disabled' /></div>}
                            </div>
                        }
                    })}
                </Popup>) : (trigger)
            }
        </div>
    }
    const getReassign = (isMobile: boolean) => <ReassignInspector order={order} trigger={getInspector(selectedInspector, isMobile)} />
    const cn = twMerge('text-lg md:text-base border-b-2 cursor-pointer hover:bg-highlight-green order flex flex-wrap md:flex-row items-start md:items-center md:pb-3 md:pt-3 pb-3 pt-3',

        index === 0 && 'rounded-t',
        !order.seen_by_user && 'md:font-bold',
        even && 'even',
        !even && 'odd',
        open && 'open',
        !open && 'closed',
        'orderLine', //< NB: please do not remove this class - it is used by the smoketest
    );
    const orderTitle = getTitle(order.inspections, order.cnt);
    return <ListViewLine className={cn} onClick={(): void => toggleInspection(order.order_id)}>
        <div className='md:hidden grid grid-cols-12 w-full gap-2'>
            <div className={twMerge('flex col-span-10 space-x-2 font-bold text-lg', (order.order_number && containsLongAndUnbrokenString(order.order_number, 30) && 'break-all'))}>
                {order.order_number}
            </div>
            <div className='col-span-2 row-span-2'>
                {getOrderImage(order)}
            </div>
            <div className={twMerge('flex col-span-10', containsLongAndUnbrokenString(orderTitle, 35) && 'break-all')}>{orderTitle}</div>
            <div className='flex col-span-12 text-secondary'>{getSupplier(order.inspections)}</div>
            <div className='flex col-span-12 text-secondary space-x-2'><OrderDate inspections={order.inspections} isPlanned={status === 'planned'} /><div>|</div>{isMobile && getReassign(true)}</div>
            <div className='flex col-span-10 text-secondary space-x-2 h-2 my-2 self-center'><OrderStatus order={order} inspections={order.inspections} /></div>
            <div className='flex col-span-2 text-secondary space-x-2'>{getToggler(open)}</div>
        </div>
        <ListViewItem className='hidden md:block md:w-1/16 right-0 mt-2 md:mt-0 md:mr-0'>{getOrderImage(order)}</ListViewItem>
        <ListViewItem className={twMerge('hidden md:flex order-1 md:w-2/16 w-11/16 font-bold text-lg md:text-base orderNo', order.seen_by_user && 'md:font-normal', (order.order_number && containsLongAndUnbrokenString(order.order_number, 18) && 'break-all'))}>{order.order_number}</ListViewItem>
        <ListViewItem className={twMerge('hidden md:flex order-2 md:w-4/16 w-13/16 pr-8', (orderTitle && containsLongAndUnbrokenString(orderTitle, 35)) && 'break-all')}>{orderTitle}</ListViewItem>
        <ListViewItem className='hidden md:flex order-3 md:w-2/16 text-secondary md:text-primary' ><OrderDate inspections={order.inspections} isPlanned={status === 'planned'} /></ListViewItem>
        <ListViewItem className='hidden md:flex order-4 md:w-3/16 w-11/16 text-secondary md:text-primary'>{getSupplier(order.inspections)}</ListViewItem>
        <ListViewItem className='hidden md:flex order-5 md:w-1/16 w-15/16 text-secondary md:text-primary'>{!isMobile && getReassign(false)}</ListViewItem>
        <ListViewItem className='hidden md:flex order-1 md:w-2/16 md:order-5'><div className='flex h-3 w-2/3'><OrderStatus order={order} inspections={order.inspections} /></div></ListViewItem>
        <ListViewItem className='hidden md:flex order-last md:order-last md:w-1/16 w-1/16 justify-end'>{getToggler(open)}</ListViewItem>
    </ListViewLine>
};

export default Order;
