/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Authorization information.
 *
 */
export type AuthenticationAuthorization = {
    account_id: string;
    last_login: string | null;
    organization_id: string;
    organization_logo_url: string | null;
    organization_name: string;
    token: string;
    type: AuthenticationAuthorization.type;
    user_id: string;
};

export namespace AuthenticationAuthorization {

    export enum type {
        AUTHORIZATION = 'authorization',
    }


}

