import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dimmer, Header, Loader as QLoader } from 'semantic-ui-react';
import Loader from '../../../../base/components/Loader';
import { getFeaturesSelector } from '../../../../base/selectors';
import { AppState } from '../../../../base/types';
import { capitaliseWord } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import config from '../../config';
import { getInspectionsStatus } from '../../dashboardActions';
import { getInspectionsStatusByTypeSelector, inspectionsStatusByConclusionSelector, inspectionsStatusBySupplierQCSelector, isDashboardFetchingSelector } from '../../selectors';
import NoData from '../NoData';
import Stats from '../Stats';
import ConclusionPies from './ConclusionPies';
import SupplierQCPies from './SupplierQCPies';


const InspectionStatus = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    let data;
    let types;
    let summary;
    const hasSupplierQc = useSelector(getFeaturesSelector).includes('supplier_qc');
    const response: { data: []; types: []; summary: any } = useSelector(getInspectionsStatusByTypeSelector);
    if (response) {
        data = response && response.data || [];
        types = response && response.types || [];
        summary = response && response.summary || {};
    }
    const piesData: [] = useSelector(inspectionsStatusByConclusionSelector);
    let piesDataQC: [] = [];
    if (hasSupplierQc) {
        piesDataQC = useSelector(inspectionsStatusBySupplierQCSelector);
    }
    const isBarsFetching = useSelector((state: AppState) => isDashboardFetchingSelector(state, 'inspectionBars'));
    const isPiesFetching = useSelector((state: AppState) => isDashboardFetchingSelector(state, 'inspectionPies'));
    const isQCPiesFetching = useSelector((state: AppState) => isDashboardFetchingSelector(state, 'inspectionQCPies'));
    useEffect(() => {
        dispatch(getInspectionsStatus());
    }, [location.search]);
    const axisBottom = data && data.length > 18 ? config.bar.axisBottomRotated : config.bar.axisBottom;
    return <div>
        <Dimmer active={isBarsFetching} inverted>
            <QLoader active={isBarsFetching}><FormattedMessage id='dashboard.loading_data_please_wait' /></QLoader>
        </Dimmer>

        <Stats summary={summary} />
        <Header as='h3'><FormattedMessage id='dashboard.page.title.inspections_by_month_and_type' /></Header>
        <div className='inspectionStatus'>

            <div className='bars'>
                {data && data.length > 0 && <ResponsiveBar
                    data={data}
                    keys={types}
                    indexBy='month'
                    theme={config.bar.theme}
                    padding={config.bar.padding}
                    colors={config.inspectionBarColors.colors}
                    borderRadius={2}
                    labelTextColor={config.inspectionBarColors.labelTextColor}
                    labelSkipHeight={config.bar.labelSkipHeight}
                    axisBottom={axisBottom}
                    axisLeft={config.bar.axisLeft}
                    tooltip={(d) => (
                        <div className='px-2 py-1 bg-white shadow-xs'>
                            {d.indexValue}. {d.id !== '' ? capitaliseWord(d.id.toString()) : 'No type'}: {d.value}
                        </div>
                    )}
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                        },
                    ]}
                />}
                {data && data.length === 0 && <NoData />}
            </div>
            <Loader small active={isPiesFetching}><ConclusionPies data={piesData} /></Loader>
            {hasSupplierQc && <Loader small active={isQCPiesFetching}><SupplierQCPies data={piesDataQC} /></Loader>}
        </div>
    </div>;
};

export default InspectionStatus;
