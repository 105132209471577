import ISO6391 from 'iso-639-1';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { SUPPORTED_LANGUAGES } from '../../../base/config';
import { Language } from '../../../base/types';

type props = {
    languageSelected(language: Language);
}

type Option = {
    text: string;
    value: number;
}

type LanguageOption = Option & Language;

const LanguageSelectionModal = (props: props): React.ReactElement => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const [showDialog, setShowDialog] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption>(null);
    const onClose = (): void => {
        setShowDialog(false);
    }
    const buttonAction = (): void => {
        if (selectedLanguage === null) {
            setShowDialog(true);
        }
        else {
            setSelectedLanguage(null);
            props.languageSelected({ name: 'Default', code: 'C' });
        }
    }

    const cancel = (): void => {
        onClose();
        setSelectedLanguage(null);
    }

    const options: LanguageOption[] = [];
    SUPPORTED_LANGUAGES.map((lang, i) => {
        options.push(createLanguageOption(ISO6391.getName(lang), lang, i));

    })

    return (
        <Modal
            dimmer={'inverted'}
            className='usersSelectorDialog'
            onClose={cancel}
            open={showDialog}
            trigger={<Button style={{ marginLeft: '5px' }} onClick={buttonAction} className='showDialog'>{selectedLanguage === null ? <FormattedMessage id='checklists.editor.translate.button.start' /> : <FormattedMessage id='checklists.editor.translate.button.stop' />}</Button>}>
            <Modal.Header>
                <FormattedMessage id='checklists.editor.translate.header' />
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    selectOnBlur={false}
                    className="selection"
                    closeOnBlur
                    openOnFocus
                    value={selectedLanguage && selectedLanguage.value}
                    options={options}
                    onChange={(evt, item): void => setSelectedLanguage(options[item.value as number])}
                    placeholder={formatMessage({ id: 'checklists.editor.translate.placeholder' })}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={selectedLanguage === null} primary onClick={(): void => {
                    onClose()
                    props.languageSelected(selectedLanguage);
                }}><FormattedMessage id='globals.ok' /></Button>

                <Button onClick={cancel
                }><FormattedMessage id='globals.cancel' /></Button>
            </Modal.Actions>
        </Modal>
    );
}

function createLanguageOption(name: string, code: string, index: number): LanguageOption {
    return { text: name, value: index, name: name, code: code };
}

export default LanguageSelectionModal;