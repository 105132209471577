import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './types';

const initialState = {
    isSaving: false,
};

const reducer = handleActions({
    [types.EXPORT_EXCEL_SAVING_STATE]: (state = initialState, action: Action<types.EXPORT_EXCEL_SAVING_STATE>) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isSaving,
        });
    },
    [types.EXPORT_INSPECTIONS_AS_EXCEL_REQUEST]: (state = initialState, action: Action<types.EXPORT_INSPECTIONS_AS_EXCEL_REQUEST>) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isSaving,
        });
    },
    [types.EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS]: (state = initialState, action: Action<types.EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS>) => {
        return Object.assign({}, state, {
            data: action.payload.data,
            isSaving: action.payload.isSaving,
        });
    },
    [types.EXPORT_INSPECTIONS_AS_EXCEL_FAILURE]: (state = initialState, action) => {
        return Object.assign({}, state, {
            isSaving: action.payload.isSaving,
        });
    },
}, initialState);

export default reducer;
