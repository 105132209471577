import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonProps, Checkbox, CheckboxProps, Dimmer, Divider, Form, Grid, Header, Loader, Segment } from 'semantic-ui-react';
import { UserPreferences } from '../../users/types';
import { ProfileData } from '../types';

interface ProfileProps {
    isUpdatingProfile: boolean;
    profile: ProfileData;
    userPreferences: UserPreferences;
    handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
    handleUserPreferenceChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void;
    handleSave(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps): void;
}
const Profile = (props: ProfileProps): React.ReactElement => {
    const intl = useIntl();
    if (props.userPreferences && props.profile) {
        return (
            <Grid container className='inspections-menu profileContainer'>
                <DocumentTitle title={intl.formatMessage({ id: 'page_title.profile' })} />
                <Dimmer active={props.isUpdatingProfile} inverted>
                    <Loader />
                </Dimmer>
                <Header as='h1'><FormattedMessage id='profile.header' /></Header>
                <Segment.Group raised>
                    <Segment secondary>
                        <Form className='profile'>
                            <Form.Field>
                                <label><FormattedMessage id='profile.first_name' /></label>
                                <input type='text' name='firstname' value={props.profile.firstname}
                                    onChange={props.handleChange} autoComplete='off' />
                            </Form.Field>
                            <Form.Field>
                                <label><FormattedMessage id='profile.last_name' /></label>
                                <input placeholder='' name='lastname' value={props.profile.lastname}
                                    onChange={props.handleChange} autoComplete='off' />
                            </Form.Field>
                            <Form.Field>
                                <label><FormattedMessage id='profile.email' /></label>
                                <input placeholder='' name='email' value={props.profile.email}
                                    onChange={props.handleChange} autoComplete='off' />
                            </Form.Field>
                            <Divider hidden />
                            <Form.Field>
                                <label><FormattedMessage id='user_preferences.label' /></label>
                                <Checkbox checked={props.userPreferences.commentsShowAllTranslations} name='commentsShowAllTranslations' onChange={props.handleUserPreferenceChange} label='' />
                                <span className='checkboxLabel'><FormattedMessage id='user_preferences.translate_report_comments_automatically' />.</span>
                            </Form.Field>
                            <Divider hidden />
                            <Button onClick={props.handleSave} positive><FormattedMessage id='profile.save' /></Button>
                        </Form>
                    </Segment>
                </Segment.Group>
            </Grid>);
    }
    return null;
};

export default Profile;
