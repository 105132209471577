import React, { useEffect, useState } from 'react';
import { PressEvent } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { SizeMappings, Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import { Icons } from '../icons/iconTypes';
import Chip, { ChipAddButton } from './Chip';

type ClipType = {
    className?: string;
    removable?: boolean;
    onRemove?(e: PressEvent): void;
    label: ComponentOrStringType;
    icon?: Icons;
};
type Props = {
    className?: string;
    clips: ClipType[];
    size?: Sizes.XSmall | Sizes.Small | Sizes.Medium;
    showAdd?: boolean;
    noValueLabel?: string;
    onRemoveClip?(clipIndex: number): void;
    onAdd?(): void;
};

const ChipStack = (props: Props): React.ReactElement => {
    const { className, noValueLabel, onRemoveClip, onAdd, showAdd, size = Sizes.Medium } = props;
    const [clips, updateClips] = useState(props.clips);
    const addButton = <ChipAddButton onAdd={onAdd} size={size} show={showAdd} />
    const remove = (index: number) => {
        onRemoveClip && onRemoveClip(index);
    }
    useEffect(() => {
        updateClips(props.clips);
    }, [props.clips]);
    return <div className={twMerge('flex gap-1 flex-wrap', className)}>
        {clips.length === 0 && <div className={twMerge(SizeMappings[size], ' italic items-center flex text-secondary')}>{noValueLabel || <FormattedMessage id='globals.no_value' />}</div>}
        {clips.map((clip, index) => {
            return <Chip
                size={size}
                onRemove={() => remove(index)}
                {...clip}
            />
        })}
        {addButton}
    </div>
}
export default ChipStack;
