import React from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { getDefaultFilterParams } from '../../../base/routes';
import { getFeaturesSelector } from '../../../base/selectors';
import { checkHasFeature } from '../../profile/containers/HasFeature';
import { getListDateSelector } from '../correctiveActionsSlice';
import CorrectiveActionDetails from './CorrectiveActionDetails';
import CorrectiveActionOverview from './CorrectiveActionOverview';


const CorrectiveActionsContainer = () => {
    const defaultDate: string = useSelector(getListDateSelector);
    const location = useLocation();
    const features = useSelector(getFeaturesSelector);
    return <>
        <DocumentTitle title={'Corrective Actions'} />
        <Switch>
            <Route exact path='/ca' render={(): React.ReactElement => {
                if (checkHasFeature(features, 'u_corrective_actions')) {
                    const filterParams = getDefaultFilterParams(defaultDate, location);
                    if (filterParams) {
                        return <Redirect
                            to={{
                                pathname: '/ca',
                                search: '?' + filterParams,
                                push: false,
                            }}
                        />
                    }
                    return <CorrectiveActionOverview />;
                }
                return <Redirect to={'/'} push={false} />
            }} />
            <Route path='/ca/details/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <CorrectiveActionDetails correctiveActionId={props.match.params['id']} />;
            }} />
        </Switch>
    </>
}

export default CorrectiveActionsContainer;