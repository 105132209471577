import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'semantic-ui-react';
import { ProductionUnit } from '../types';
import ProductionUnitMap from './ProductionUnitMap';

type ProductionUnitProps = {
    productionUnit: ProductionUnit;
    supplierId: string;
    canEdit?: boolean;
};
const ProductionUnitReadOnly = (props: ProductionUnitProps): React.ReactElement => {
    const { productionUnit, canEdit = true } = props;
    const intl = useIntl();
    if (props.productionUnit) {
        return (
            <span className='productionUnit readOnly'>
                <Card fluid>
                    {props.productionUnit.location && <span className='puMap'>
                        <ProductionUnitMap location={props.productionUnit.location} />
                    </span>}
                    <Card.Content>
                        <Card.Header>{productionUnit.name || intl.formatMessage({ id: 'production_units.production_unit.label' })}</Card.Header>
                        {canEdit && <Card.Meta><Link className='link noUnderline' to={'/production_units/' + productionUnit.id}><FormattedMessage id='production_unit.edit_production_unit' /></Link></Card.Meta>}
                    </Card.Content>
                    {productionUnit.address && productionUnit.address.length > 0 && <Card.Content extra>
                        <span className='contact'><Icon name='building' />{productionUnit.address.toString()}</span>
                    </Card.Content>}
                    {productionUnit.contact_person && <Card.Content extra>
                        <span className='contact'><Icon name='user' />{productionUnit.contact_person}</span>
                    </Card.Content>}
                </Card>
            </span>
        );
    }
    return null;
};

export default ProductionUnitReadOnly;
