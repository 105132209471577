import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Audit } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import { useClickOutsideTrigger } from '../../../base/utils';

export function SelfAssignedAuditPopup(props: {
    x: number,
    y: number,
    close: () => void,
    audit: Audit
}) {
    const { audit, x, y, close } = props;
    const history = useHistory();
    const isAuditPlanned = audit.status === 'planned';

    const ref = React.useRef();

    useClickOutsideTrigger(ref, close);

    function viewReport() {
        history.push('/audits/report/' + audit.id);
    }
    function goToEditPage() {
        history.push('/audits/edit_audit/' + audit.id);
    }

    function goToPerformPage() {
        history.push('/audits/perform_audit/' + audit.id);
    }

    if(isAuditPlanned) {
        return <div
            ref={ref}
            className='fixed bg-white border shadow-md py-4 px-4'
            style={{
                top: y - 150,
                left: x,
                zIndex: 1000,
            }} >
            <div className='font-bold text-lg'><FormattedMessage id='audits.start_audit_planned_popup.title'/></div>
            <div className='mt-2 mb-4'><FormattedMessage id='audits.start_audit_planned_popup.description'/></div>
            <div className='flex flex-row justify-between items-center'>
                <Button className="flex justify-center text-base items-center grow mr-2" primary onClick={goToPerformPage}>
                    <FormattedMessage id='audits.start_audit'/>
                </Button>
                <Button className="flex flex-row justify-between items-center text-primary" onClick={goToEditPage}>
                    <Icon name="edit"/>
                    <FormattedMessage id='globals.edit'/>
                </Button>
            </div>
        </div>
    }

    return <div
        ref={ref}
        className='fixed bg-white border shadow-md py-4 px-4'
        style={{
            top: y - 150,
            left: x,
            zIndex: 1000,
        }} >
        <div className='font-bold text-lg'><FormattedMessage id='audits.start_audit_ongoing_popup.title' /></div>
        <div className='mt-2 mb-4'><FormattedMessage id={'audits.start_audit_ongoing_popup.description'}/></div>
        <div className='flex flex-row justify-between items-center'>
            <Button className="flex justify-center text-base items-center grow mr-2" primary onClick={goToPerformPage}>
                <FormattedMessage id='audits.continue_audit'/>
            </Button>
            <Button className="flex flex-row justify-between items-center text-primary" onClick={viewReport}>
                <Icon name="edit"/>
                <FormattedMessage id='audits.view_report'/>
            </Button>
        </div>
    </div>
}