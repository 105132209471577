/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a text
 *
 */
export type SetCustomFieldText = {
    text: string;
    type: SetCustomFieldText.type;
};

export namespace SetCustomFieldText {

    export enum type {
        TEXT = 'text',
    }


}

