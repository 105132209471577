import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useAppDispatch } from '../../../../store';
import { getAddInspectionObject } from '../../actions/addInspectionActions';
import DuplicateInspections from '../../containers/DuplicateInspections';
import { getEditInspectionIsSaving, getIsAddingInspection } from '../../editInspectionSelectors';
import { getFetching } from '../../selectors/selectors';

interface AddInspectionButtonsProps {
    orderId: string;
    disabled?: boolean;
    isCombinedInspection: boolean;
}
export const AddInspectionButtons = (props: AddInspectionButtonsProps): React.ReactElement => {
    const { isCombinedInspection, orderId } = props;
    const dispatch = useAppDispatch();
    const isSaving = useSelector(getEditInspectionIsSaving);
    const isFetching = useSelector(getFetching);
    const isAdding = useSelector(getIsAddingInspection);
    const disabled = isAdding || isSaving || isAdding || props.disabled;
    const addItem = (): void => {
        dispatch(getAddInspectionObject());
    }
    return (
        <div className='mt-2'>
            {!props.isCombinedInspection && <span>
                {(isSaving || isFetching) &&
                    <Popup
                        on='click'
                        hideOnScroll
                        trigger={
                            <span><Button primary className='addInspectionButton' disabled={disabled}>
                                <FormattedMessage id='edit_inspection.add_inspection_action' />
                            </Button></span>}
                    >
                        <h4>
                            <Icon name='warning circle' color='red' /><FormattedMessage id='inspections.cannot_add_inspection' />
                        </h4>
                        <p><FormattedMessage id='inspections.order_data_is_being_saved_right_now_so_we_cannot_add_a_new_inspection_please_wait_a_moment' /></p></Popup>
                }
                {(!isSaving && !isFetching) &&
                    <Button primary disabled={disabled} className='addInspectionButton' onClick={addItem}>
                        <FormattedMessage id='edit_inspection.add_inspection_action' />
                    </Button>}
            </span>}
            {!isCombinedInspection && <DuplicateInspections
                disabled={disabled}
                orderId={orderId}
            />}
            {isAdding && <span className='addingInspection'><FormattedMessage id='inspections.please_wait' /></span>}
        </div>
    );
}