/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Supplier2 } from './Supplier2';

/**
 * List of supplier, which might be incomplete
 *
 */
export type SupplierList = {
    status: SupplierList.status;
    suppliers: Array<Supplier2>;
};

export namespace SupplierList {

    export enum status {
        COMPLETE = 'complete',
        INCOMPLETE = 'incomplete',
        FETCHING = 'fetching',
    }


}

