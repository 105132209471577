/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 92.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TextWithTranslation
 */
export interface TextWithTranslation {
    /**
     * 
     * @type {string}
     * @memberof TextWithTranslation
     */
    language: string | null;
    /**
     * 
     * @type {string}
     * @memberof TextWithTranslation
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof TextWithTranslation
     */
    translation_method?: TextWithTranslationTranslationMethodEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TextWithTranslation
     */
    translations: { [key: string]: string; } | null;
}

/**
* @export
* @enum {string}
*/
export enum TextWithTranslationTranslationMethodEnum {
    Manual = 'manual',
    Automatic = 'automatic'
}

export function TextWithTranslationFromJSON(json: any): TextWithTranslation {
    return TextWithTranslationFromJSONTyped(json, false);
}

export function TextWithTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextWithTranslation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': json['language'],
        'text': json['text'],
        'translation_method': !exists(json, 'translation_method') ? undefined : json['translation_method'],
        'translations': json['translations'],
    };
}

export function TextWithTranslationToJSON(value?: TextWithTranslation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'text': value.text,
        'translation_method': value.translation_method,
        'translations': value.translations,
    };
}


