import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Divider, Header, List } from 'semantic-ui-react';
import { InfoIcon } from '../../globals/components/Utils';
import { ProductionUnit as ProductionUnitType } from '../types';
import { NewProductionUnitParams } from '../../productionUnits/components/NewProductionUnitParams';
import { CreateProductionUnitModal } from '../../productionUnits/components/CreateProductionUnitModal';
import { useHistory } from 'react-router';
import { useIsAllowedToCreateProductionUnits } from '../../productionUnits/hooks/useIsAllowedToCreateProductionUnits';

type ProductionUnitsProps = {
    productionUnits: ProductionUnitType[];
    supplierId: string;
    disabled?: boolean;
    onUserWantedToAddProductionUnit(productionUnitId: string): void
    onUserWantedToCreateProductionUnit(params: NewProductionUnitParams): void
}
const ProductionUnits = (props: ProductionUnitsProps): React.ReactElement => {
    const intl = useIntl();
    const history = useHistory();

    const allowedToCreateProductionUnits = useIsAllowedToCreateProductionUnits()

    const [showCreateProductionUnitModal, setShowCreateProductionUnitModal] = React.useState(false);

    if (props.productionUnits) {
        const hasUnits = props.productionUnits.length > 0;
        return (
            <span className='productionUnits'>
                <Divider hidden />
                <Header as='h3'><FormattedMessage id='production_units.production_units' /></Header>
                {!hasUnits && <InfoIcon compact text={intl.formatMessage({ id: 'production_unit.no_production_units' })} />}
                {
                    hasUnits && <List>
                        {props.productionUnits.map((productionUnit: ProductionUnitType) => {
                            let puNumberAndName = ''
                            if (productionUnit.number && productionUnit.number != '') {
                                puNumberAndName = productionUnit.number + ' - '
                            }
                            puNumberAndName = puNumberAndName + productionUnit.name;
                            const content = <Fragment>
                                {productionUnit.name && puNumberAndName}
                                {(!productionUnit.name && productionUnit.address.length > 0 && productionUnit.address[0].toString()) || (!productionUnit.name && 'No name or address data on production unit')}
                            </Fragment>
                            return <List.Item key={productionUnit.id}>
                                {props.disabled ? <div className='link noUnderline noCursor'>{content}</div> : <Link className='link' to={`/suppliers/${props.supplierId}/${productionUnit.id}`}>{content}</Link>}
                            </List.Item>;
                        })}
                    </List>
                }
                {
                    !props.disabled && allowedToCreateProductionUnits &&
                    (
                        <CreateProductionUnitModal
                            groupsEnabled={true}
                            open={showCreateProductionUnitModal}
                            onModalClosed={() => setShowCreateProductionUnitModal(false)}
                            onModalOpened={() => setShowCreateProductionUnitModal(true)}
                            allowExistingProductionUnits={true}
                            onExistingProductionUnitSelected={(productionUnitId) => {
                                history.push(`/suppliers/${props.supplierId}/${productionUnitId}`);
                                setShowCreateProductionUnitModal(false);
                            }}
                            onUserWantedToCreateProductionUnit={props.onUserWantedToCreateProductionUnit}
                            renderButton={() => (
                                <Button primary size='tiny'>
                                    <FormattedMessage id='suppliers.create_production_unit' />
                                </Button>
                            )}
                            selectableProductionUnits={props.productionUnits}
                        />
                    )

                }
                {/* {!props.disabled && <CreateProductionUnit supplierId={props.supplierId}>
                    <Button primary size='tiny'><FormattedMessage id='production_unit.create_production_unit' /></Button>
                </CreateProductionUnit>} */}
                <Divider />
            </span >
        );
    }
    return null;
}

export default ProductionUnits;
