/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 49.2.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    VideoResource,
    VideoResourceFromJSON,
    VideoResourceToJSON
} from './';

/**
 * PEDING
 * @export
 * @interface VideoFileResource
 */
export interface VideoFileResource {
    /**
     * 
     * @type {string}
     * @memberof VideoFileResource
     */
    video_id: string | null;
    /**
     * 
     * @type {Array<VideoResource>}
     * @memberof VideoFileResource
     */
    video_resources: Array<VideoResource>;
}

export function VideoFileResourceFromJSON(json: any): VideoFileResource {
    return VideoFileResourceFromJSONTyped(json, false);
}

export function VideoFileResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoFileResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'video_id': json['video_id'],
        'video_resources': ((json['video_resources'] as Array<any>).map(VideoResourceFromJSON)),
    };
}

export function VideoFileResourceToJSON(value?: VideoFileResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'video_id': value.video_id,
        'video_resources': ((value.video_resources as Array<any>).map(VideoResourceToJSON)),
    };
}


