import { handleActions } from 'redux-actions';
import { Action } from './../../base/genericActions';
import { CLEAR_ALL_MESSAGES, CLEAR_ERROR_MESSAGE, CLEAR_GLOBAL_MESSAGE, CLEAR_MESSAGE, CLEAR_STATUS_MESSAGE, SEND_ERROR_MESSAGE, SEND_GLOBAL_MESSAGE, SEND_MESSAGE, SEND_STATUS_MESSAGE } from './actionTypes';

const initialState = {
    items: [],
    statusItems: [],
    errorItems: [],
    globalItems: [],
};

const reducer = handleActions({
    [SEND_MESSAGE]: (state = initialState, action: Action<SEND_MESSAGE>) => {
        return {
            ...state,
            items: [action.payload],
        };
    },
    [SEND_STATUS_MESSAGE]: (state = initialState, action: Action<SEND_MESSAGE>) => {
        return {
            ...state,
            statusItems: [action.payload],
        };
    },
    [SEND_ERROR_MESSAGE]: (state = initialState, action: Action<SEND_ERROR_MESSAGE>) => {
        return {
            ...state,
            errorItems: [action.payload],
        };

    },
    [SEND_GLOBAL_MESSAGE]: (state = initialState, action: Action<SEND_GLOBAL_MESSAGE>) => {
        return {
            ...state,
            globalItems: [action.payload],
        };
    },
    [CLEAR_MESSAGE]: (state = initialState) => {
        return {
            ...state,
            statusItems: [],
            items: [],
        };
    },
    [CLEAR_ERROR_MESSAGE]: (state = initialState) => {
        return {
            ...state,
            errorItems: [],
            items: [],
        };
    },
    [CLEAR_STATUS_MESSAGE]: (state = initialState) => {
        return {
            ...state,
            statusItems: [],
            items: [],
        };
    },
    [CLEAR_GLOBAL_MESSAGE]: (state = initialState) => {
        return {
            ...state,
            globalItems: [],
            items: [],
        };
    },
    [CLEAR_ALL_MESSAGES]: (state = initialState) => {
        return {
            ...state,
            statusItems: [],
            errorItems: [],
            globalItems: [],
            items: [],
        };
    },
}, initialState);

export default reducer;
