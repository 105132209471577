/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentAppliesToSupplier
 */
export interface DocumentAppliesToSupplier {
    /**
     * 
     * @type {string}
     * @memberof DocumentAppliesToSupplier
     */
    supplier_id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAppliesToSupplier
     */
    type: DocumentAppliesToSupplierTypeEnum;
}


/**
 * @export
 */
export const DocumentAppliesToSupplierTypeEnum = {
    DocumentAppliesToSuppliers: 'document_applies_to_suppliers'
} as const;
export type DocumentAppliesToSupplierTypeEnum = typeof DocumentAppliesToSupplierTypeEnum[keyof typeof DocumentAppliesToSupplierTypeEnum];


/**
 * Check if a given object implements the DocumentAppliesToSupplier interface.
 */
export function instanceOfDocumentAppliesToSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supplier_id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function DocumentAppliesToSupplierFromJSON(json: any): DocumentAppliesToSupplier {
    return DocumentAppliesToSupplierFromJSONTyped(json, false);
}

export function DocumentAppliesToSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentAppliesToSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supplier_id': json['supplier_id'],
        'type': json['type'],
    };
}

export function DocumentAppliesToSupplierToJSON(value?: DocumentAppliesToSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplier_id': value.supplier_id,
        'type': value.type,
    };
}

