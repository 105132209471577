export const config = {
    widths: {
        image: 'sm:w-1/16',
        // image: 'sm:w-1/24',
        itemNo: 'sm:w-3/16',
        // itemNo: 'sm:w-5/24',
        itemName: 'sm:w-4/16',
        // itemName: 'sm:w-6/24',
        qty: 'sm:w-1/16',
        // qty: 'sm:w-2/24',
        types: 'sm:w-3/16',
        // types: 'sm:w-5/24',
        date: 'sm:w-2/16',
        // date: 'sm:w-2/24',
        inspector: 'sm:w-2/24',
        status: 'sm:w-2/16',
        // status: 'sm:w-3/24',
        defects: 'sm:w-2/24',
    }
}