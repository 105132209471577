import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Label, Progress } from 'semantic-ui-react';
import { UploadingFile } from '../types';
import { calculatePrettySize } from './handlers';

type UploadingItemProps = {
    inline: boolean;
    file: UploadingFile;
    reUploadFile(file: File);
}

const UploadingItem = (props: UploadingItemProps): React.ReactElement => {
    const { file, inline = false } = props;
    const [show, setShow] = useState<boolean>(true);

    const reUpload = () => {
        setShow(false);
        props.reUploadFile(file.file);
    }

    if (show) {
        if (inline) {
            return (
                <div className='file'>
                    {(file.status === 'ok' && file.progress < 100 && file.progress > 0) && <div className='fileName inline'>
                        {file.file.name}
                    </div>}
                    <div className='fileProgress'>
                        {(file.status === 'ok' && file.progress === 0) &&
                            <Progress active size='tiny' color='green' percent={100} />
                        }
                        {(file.status === 'ok' && file.progress < 100 && file.progress > 0) &&
                            <Progress indicating size='tiny' percent={file.progress} color='green' />
                        }
                    </div>
                </div>
            );
        }
        return (<div className='file'>
            <div className='fileName'>
                {file.file.name}
            </div>
            <div className='fileSize' >
                {calculatePrettySize(file.file.size)}
            </div>
            <div className='fileProgress'>
                {(file.status === 'ok' && file.progress === 0) &&
                    <Progress active size='small' color='green' percent={100} />
                }
                {(file.status === 'ok' && file.progress < 100 && file.progress > 0) &&
                    <Progress percent={file.progress} size='small' progress color='green' />
                }
                {(file.status === 'ok' && file.progress === 100) && <Label color='green' size='medium'><Icon name='check' /><FormattedMessage id='attachments.statusbar.done' /></Label>}
                {file.status === 'error' && <div className="flex justify-between">
                    <div className='w-full flex pr-2'><Label className='w-full' size='medium' color='red'><Icon name='remove' /><FormattedMessage id='attachments.statusbar.error' /></Label></div>
                    <Button onClick={reUpload}><FormattedMessage id='attachments.reupload.button' /></Button>
                </div>}
            </div>
        </div>);
    }
    else {
        return null;
    }
};

export default UploadingItem;
