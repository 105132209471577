/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.2.5 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    EditEvent = 'edit_event',
    CommentEvent = 'comment_event',
    ApprovedEvent = 'approved_event',
    ResolvedEvent = 'resolved_event',
    CreatedEvent = 'created_event',
    EvidenceAddedEvent = 'evidence_added_event',
    AuditsAddedEvent = 'audits_added_event',
    EvidenceDeletedEvent = 'evidence_deleted_event',
    ReopenEvent = 'reopen_event',
    DescriptionOfResolutionEvent = 'description_of_resolution_event',
    ResponsibleNotifiedEvent = 'responsible_notified_event',
    NotifiedEvent = 'notified_event',
    NotifiedOnOverdueEvent = 'notified_on_overdue_event',
    NotifiedOnExpiryEvent = 'notified_on_expiry_event',
    CreatedByMigration = 'created_by_migration',
    UpdatedByImporter = 'updated_by_importer',
    CreatedByImporter = 'created_by_importer'
}

export function EventTypeFromJSON(json: any): EventType {
    return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventType {
    return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
    return value as any;
}

