import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { twMerge } from 'tailwind-merge';
import { WeekviewUser } from '../../../../../../backend_api/models';
import { getMoment } from '../../../../../../base/utils';
import { useAppDispatch } from '../../../../../../store';
import { UserLine } from '../../../../../users/components/Widgets';
import { moveWeekViewItem } from '../../../../slices/weekViewSlice';
import { DragType, WeekViewFromDataItem, WeekViewItemType } from './WeekView2';

type OwnProps = {
    date: Date;
    user: WeekviewUser;
    type: WeekViewItemType;
    label: string;
    children?: React.ReactNode;
}
type EmptyItemProps = OwnProps;

const EmptyItem = (props: EmptyItemProps): React.ReactElement => {
    const { date, user, type, label } = props;
    const dispatch = useAppDispatch();
    const [okToDrop, setOkToDrop] = useState(false);
    const [collectedProps, drop] = useDrop({
        accept: [DragType.WEEKVIEW_INSPECTION_ITEM, DragType.WEEKVIEW_ORDER_ITEM, DragType.WEEKVIEW_SUPPLIER_ITEM, DragType.WEEK_VIEW_PARKING_ITEM],
        drop: (item: WeekViewFromDataItem & { type: string }) => {

            dispatch(moveWeekViewItem({ from: item, to: { date, user, itemType: props.type, data: null } }));
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
        canDrop: (item, monitor) => {
            let ret = true;
            const isDiff = item.date !== date || item.user !== user || item.itemType !== props.type;
            setOkToDrop(isDiff);
            
            if (item.type === 'inspection' && props.type === 'booked') {
                ret = item.inspection.features.includes('change_booking_data');
                setOkToDrop(ret && isDiff)
                return ret && isDiff;
            }
            if (item.type === 'order' && props.type === 'booked') {
                ret = item.order.features.includes('order_change_booking_data');
                setOkToDrop(ret && isDiff)
                return ret && isDiff;
            }
            if (item.type === 'supplier' && props.type === 'booked') {
                ret = item.data.orders[0].features.includes('order_change_booking_data')
                setOkToDrop(ret && isDiff);
                return ret && isDiff;
            }
            return ret && isDiff;
        }

    });
    const [collectedDragProps, drag] = useDrag({
        type: DragType.WEEKVIEW_EMPTY_ITEM, item: { type: DragType.WEEKVIEW_EMPTY_ITEM, date },
    });
    const isOver = collectedProps.isOver && okToDrop;
    const cn = twMerge('flex flex-grow flex-col', isOver && 'bg-surface-secondary rounded')
    return <div className={cn} ref={drop}><div className=''>
        {isOver &&
            <div className='flex flex-col whitespace-nowrap truncate overflow-hidden'>
                <span className={twMerge(' truncate overflow-hidden text-sm z-20 p-1 -mt-12 absolute bg-surface-secondary text-white rounded flex flex-wrap space-x-1')}>
                    <div className=' truncate flex whitespace-nowrap overflow-auto'>{getMoment(date).format('ddd MMMM DD')}.</div>{user && <UserLine user={user} />}{!user && <div>{label}</div>}
                </span>
                <div className='transform w-5 h-5 -mt-8 ml-6 bg-surface-secondary left-auto right-auto rotate-45 absolute z-10' style={{  }}></div>
            </div>}
        {props.children}
    </div></div>;
};

export default EmptyItem;
