import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { DropdownItemProps } from 'semantic-ui-react';
import { CorrectiveActionsState } from '../../../backend_api/models';
import { getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getFullStateName } from '../../correctiveActions/CorrectiveActionDisplayState';
import { setFilter } from '../filtersActions';
import { DropdownFilter } from './DropdownFilter';
import Filter from './Filter';

const filterName = 'ca_states';

type Props = {
    context?: string;
}

const CorrectiveActionStateFilter = (props: Props) => {
    const labelId = props.context && props.context == 'audit_list' ? 'filters.ca_state.label.audit_list' : 'filters.ca_state.label'
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const selectedStates = getLocationEntry(location, filterName);
    const getStateOptions = (): DropdownItemProps[] => {
        const options = [];
        for (const state in CorrectiveActionsState) {
            const id = CorrectiveActionsState[state]
            options.push({
                id: id,
                text: getFullStateName(id),
                value: id
            })
        }
        return options;
    }
    return <Filter labelId={labelId}>
        <DropdownFilter
            name={filterName}
            options={getStateOptions()}
            selectedItems={selectedStates || []}
            handleSave={(items) => dispatch(setFilter(filterName, items))}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.ca_state.placeholder' })}
        />
    </Filter>
}

export default CorrectiveActionStateFilter;