import { createAction } from 'typesafe-actions';
import { SimpleSupplier } from '../../backend_api/models';
import { DetailedSupplier } from '../../backend_api/models/DetailedSupplier';
import { request, RequestType } from '../../base/api';
import { setContextData } from '../../base/baseSlice';
import { AppState, AppThunk, Context, RequestError } from '../../base/types';
import { getLocationEntries } from '../../base/utils';
import history from '../../store/history';
import errorHandling from '../errorHandling';
import { parseFiltersObj } from '../filters/util';
import { updateOrder } from '../inspections/actions';
import { getOrderWithInspectionsSelector } from '../inspections/editInspectionSelectors';
import { getInspectionsMap } from '../inspections/selectors/selectors';
import { Inspection, Order } from '../inspections/types';
import { isInspectionNotLockedAndPlanned } from '../inspections/Utils';
import messages from '../messages';
import * as types from './actionTypes';
import { SET_BOOKING_DEFAULT_DATE } from './actionTypes';
import { getConfirmDeleteProductionUnitSelector, getConfirmDeleteSupplierSelector, getProductionUnitsSelector, getSuppliersSelector } from './selectors';
import { Booking, ProductionUnit, SupplierBooking, SupplierBookingInspection } from './types';

const catchException = errorHandling.handler.catchException;
const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;

export function addProductionUnitToSupplier(supplierId: string, productionUnitId: string): AppThunk {
    return async (dispatch): Promise<void> => {
        const url = 'suppliers/' + supplierId + '/add_production_unit_to_supplier'
        const options = {
            method: 'POST',
            body: JSON.stringify({ 'production_unit_id': productionUnitId })
        }
        return request(url, options)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then(function onAddProductionUnitToSupplierResponse(response: SimpleSupplier) {
                // reload the supplier ( for the edit supplier page )
                // getSupplierData(supplierId)
            })
            .catch(function onAddProductionUnitToSupplierErrorResponse(error: RequestError) {
                console.log('Error when adding production unit to supplier', error)
                dispatch(sendErrorMessage(['error_message.supplier.add_production_unit'], 3000))
                catchException('editSupplier', {
                    endpoint: 'suppliers/[id]/add_production_unit_to_supplier',
                    request: url,
                    status: error.status
                }, { error, supplierId, productionUnitId })

            })
    }
}

export const getSupplierBooking = (orderId: string, token?: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        const options: RequestType['options'] = {};
        if (token) {
            options.token = token;
        }
        dispatch(getSupplierBookingRequest('orderId', true));
        return request('supplier_booking/' + orderId, options)
            .then((bookingData: SupplierBooking) => {
                bookingData = normalizeBookingData(bookingData);
                dispatch(getSupplierBookingSuccess(orderId, bookingData));
            })
            .catch((error: RequestError) => {
                console.log('supplier booking error: ', error);
                dispatch(getSupplierBookingFailure(orderId, false, error));
                dispatch(sendErrorMessage(['error_message.could_not_load_booking_form_data'], 3000));
                catchException('getSupplierBooking', {
                    endpoint: 'supplier_booking/[id]',
                    request: 'supplier_booking/' + orderId,
                    status: error.status,
                }, { error, orderId });
            });

    };
};

export const updateBookingDateBulk = (date: moment.Moment, inspectionIds: string[], perPage: number): AppThunk => {
    return async (dispatch): Promise<void> => {
        const body = {
            booking_date: date.format('YYYY-MM-DD'),
            inspection_ids: inspectionIds
        }
        return request('booking/bulk', {
            method: 'put',
            body: JSON.stringify(body),
        })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.supplier_booking_data_was_succesfully_updated'], 4000));
                dispatch(getBookings(0, perPage))
            })
            .catch((error: RequestError) => {
                console.log('booking bulk error: ', error);
                catchException('getSuppliers', {
                    endpoint: 'booking/bulk',
                    request: 'booking/bulk',
                    status: error.status,
                }, { error });
            });
    };
}

export const updateSupplierBooking = (orderId: string, bookingData2: SupplierBooking): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(getSupplierBookingRequest('orderId', false));
        return request('supplier_booking/' + orderId, {
            method: 'put',
            body: JSON.stringify(bookingData2),
        })
            .then((bookingData: SupplierBooking) => {
                bookingData = normalizeBookingData(bookingData);
                dispatch(sendStatusMessage(['status_message.supplier_booking_data_was_succesfully_updated'], 3000));
                dispatch(getSupplierBookingSuccess(orderId, bookingData));
            })
            .catch((error: RequestError) => {
                console.log('update supplier booking error: ', error);
                catchException('updateSupplierBooking', {
                    endpoint: 'supplier_booking/[id]',
                    request: 'supplier_booking/' + orderId,
                    status: error.status,
                }, { error, orderId, bookingData2 }); // TODO: Check if we need booking data here;
            });

    }
}
export const getSuppliers = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(getSupplierDataRequest());
        return request('suppliers2', {},)
            .then((data: DetailedSupplier[]) => {
                dispatch(getSuppliersSuccess(data));
            })
            .catch((error: RequestError) => {
                console.log('supplier error: ', error);
                catchException('getSuppliers', {
                    endpoint: 'suppliers2',
                    request: 'suppliers2',
                    status: error.status,
                }, { error });
            });
    };
};

export const getSupplierAndProductionUnitRelations = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(getSupplierDataRequest());
        return request('suppliers2/links', {},)
            .then((data: [string, string][]) => {
                dispatch(getSupplierAndProductionUnitRelationsSuccess(data));
            })
            .catch((error: RequestError) => {
                console.log('supplier error: ', error);
                catchException('getSuppliers', {
                    endpoint: 'suppliers2',
                    request: 'suppliers2',
                    status: error.status,
                }, { error });
            });
    };
};

export const getSupplierData = (supplierId: string) => {
    return async (dispatch, getState: () => AppState): Promise<void> => {
        dispatch(getSupplierDataRequest());
        return request('suppliers/' + supplierId, {})
            .then((data: DetailedSupplier) => {
                dispatch(getSupplierDataSuccess(data, supplierId));
                dispatch(getProductionUnits(supplierId));
                if (getSuppliersSelector(getState()) === null) {
                    dispatch(getSuppliers());
                }
            })
            .catch((error: RequestError) => {
                console.log('supplier error: ', error);
                catchException('getSuppliers', {
                    endpoint: 'suppliers',
                    request: 'suppliers',
                    status: error.status,
                }, { error });
            });

    }
};

export const updateSupplierData = (supplierId: string, supplierData: DetailedSupplier): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('suppliers/' + supplierId,
            {
                method: 'put',
                body: JSON.stringify(supplierData)
            })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.supplier_data_was_updated'], 3000));
            })
            .catch((error: RequestError) => {
                console.log('supplier error: ', error);
                catchException('updateSupplierData', {
                    endpoint: 'suppliers/:supplierId',
                    request: 'suppliers/' + supplierId,
                    status: error.status,
                }, { error });
            });
    }
};

export const createSupplier = (supplierData: DetailedSupplier, openAfterCreation?: boolean): AppThunk => {
    return async (dispatch, getState: () => AppState): Promise<DetailedSupplier | void> => {
        return request(
            'suppliers2',
            {
                method: 'post',
                body: JSON.stringify(supplierData)
            })
            .then((supplier: DetailedSupplier) => {
                dispatch(sendStatusMessage(['status_message.the_supplier_was_succesfully_created'], 3000));
                if (openAfterCreation) {
                    history.push('/suppliers/' + supplier.id);
                } else {
                    const suppliers = getSuppliersSelector(getState());
                    suppliers.push(supplier);
                    dispatch(createSupplierSuccess(suppliers, supplier));
                }
                return supplier as DetailedSupplier;
            })
            .catch((error: RequestError) => {
                catchException('createSupplier', {
                    endpoint: 'suppliers2',
                    request: 'suppliers2',
                    status: error.status,
                }, { error, supplierData, });
            });
    }
};

export const deleteSupplierRequest = (confirm: boolean, supplierId?: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(setConfirmDeleteSupplierId(confirm, supplierId));
    };
};

export const deleteSupplier = (): AppThunk => {
    return async (dispatch, getState: () => AppState): Promise<void> => {
        const supplierId = getConfirmDeleteSupplierSelector(getState()).supplierId;
        return request('suppliers/' + supplierId,
            {
                method: 'delete',
            })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.the_supplier_was_succesfully_deleted'], 3000));
                dispatch(setConfirmDeleteSupplierId(false, undefined));
                dispatch(getSuppliers());
                dispatch(goToSuppliersList());
            })
            .catch((error: RequestError) => {
                catchException('deleteSupplier', {
                    endpoint: 'suppliers',
                    request: 'suppliers',
                    status: error.status,
                }, { error, supplierId });
            });
    };
};

export const deleteProductionUnitRequest = (confirm: boolean, productionUnitId?: string, supplierId?: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(setConfirmDeleteProductionUnitId(confirm, productionUnitId, supplierId));
    };
};

export const deleteProductionUnit = (): AppThunk => {
    return async (dispatch, getState: () => AppState): Promise<void> => {
        const productionUnitId = getConfirmDeleteProductionUnitSelector(getState()).productionUnitId;
        const supplierId = getConfirmDeleteProductionUnitSelector(getState()).supplierId;
        return request('production_units/' + productionUnitId,
            {
                method: 'delete',
            })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.the_production_unit_was_succesfully_deleted'], 3000));
                dispatch(getSupplierData(supplierId));
                dispatch(setConfirmDeleteProductionUnitId(false, undefined));
                dispatch(goToSupplier(supplierId));
            })
            .catch((error: RequestError) => {
                catchException('deleteSupplier', {
                    endpoint: 'suppliers',
                    request: 'suppliers',
                    status: error.status,
                }, { error, productionUnitId });
            });
    }
};

export const getProductionUnits = (supplierId: string, isList?: boolean): AppThunk => {
    return async (dispatch): Promise<void> => {
        if (!isList) {
            dispatch(getSupplierDataRequest());
        }
        return request('suppliers/' + supplierId + '/production_units', {})
            .then((data: ProductionUnit[]) => {
                dispatch(getProductionUnitsSuccess(data, supplierId, isList));
            })
            .catch((error: RequestError) => {
                dispatch(sendErrorMessage(['error_message.the_production_units_could_not_be_loaded'], 3000));
                catchException('getProductionUnits', {
                    endpoint: 'suppliers/[supplierId]/production_units',
                    request: 'suppliers/' + supplierId + '/production_units',
                    status: error.status,
                }, { error, supplierId });
            });

    }
};

export const updateInspectionSupplier = (orderId: string, supplierId: string, isFirst: boolean, force?: boolean): AppThunk => {
    return async (dispatch, getState: () => AppState): Promise<void> => {
        let hasLinkedProductionUnit = false;
        const order: Order = getOrderWithInspectionsSelector(getState());
        const inspectionsMap = getInspectionsMap(getState());
        const inspections = order.inspections;
        inspections.forEach((inspection: Inspection) => {
            if (isInspectionNotLockedAndPlanned(inspection)) {
                if (inspection.linked_production_unit !== null && !force) {
                    hasLinkedProductionUnit = true;
                    return;
                }
                if (supplierId === null) {
                    inspectionsMap[inspection.inspection_id].supplier_entity = null;
                    inspectionsMap[inspection.inspection_id].supplier_entity = null;

                } else {
                    inspection.supplier_entity = { id: supplierId } as DetailedSupplier;
                    inspectionsMap[inspection.inspection_id].supplier_entity = { id: supplierId } as DetailedSupplier;

                }
                inspection.linked_production_unit = null;
                inspectionsMap[inspection.inspection_id].linked_production_unit = null;
            }
        });
        if (!hasLinkedProductionUnit) {
            dispatch(updateOrder(orderId, order));
            dispatch(setInspectionSupplierId(orderId, supplierId));
            dispatch(setInspectionProductionUnitWillBeOverwritten(false));
        } else {
            dispatch(setInspectionProductionUnitWillBeOverwritten(true));
        }
    };
};

export const updateInspectionProductionUnit = (orderId: string, productionUnitId: string): AppThunk => {
    return async (dispatch, getState: () => AppState): Promise<void> => {
        const order: Order = getOrderWithInspectionsSelector(getState());
        const inspectionsMap = getInspectionsMap(getState());
        const inspections = order.inspections;
        inspections.forEach((inspection: Inspection) => {
            if (isInspectionNotLockedAndPlanned(inspection)) {
                let fragment: { id?: string };
                productionUnitId === null ? fragment = null : fragment = { id: productionUnitId }
                inspection.linked_production_unit = fragment;
                inspectionsMap[inspection.inspection_id].linked_production_unit = fragment;
            }
        });
        dispatch(updateOrder(orderId, order));
    };
};

export const updateProductionUnit = (supplierId: string, productionUnitId: string, productionUnitData: ProductionUnit): AppThunk => {
    return async (dispatch): Promise<void> => {
        return request('production_units/' + productionUnitId,
            {
                method: 'put',
                body: JSON.stringify(productionUnitData)
            })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.production_unit_data_was_updated'], 3000));
            })
            .catch((error: RequestError) => {
                catchException('updateProductionUnit', {
                    endpoint: 'production_units/:productionUnitId',
                    request: 'production_units/' + productionUnitId,
                    status: error.status,
                }, { error });
            });
    }
};

export const createProductionUnit = (supplierId: string, productionUnitData: ProductionUnit, isStandalone?: boolean, auditId?: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        return request('suppliers/' + supplierId + '/production_units',
            {
                method: 'post',
                body: JSON.stringify(productionUnitData)
            })
            .then((productionUnit: ProductionUnit) => {
                const productionUnits = getProductionUnitsSelector(getState(), supplierId);

                // TODO: Side effects should not happen like this. You need to dispatch and action, and let a reducer update productionUnits
                if (productionUnits) {
                    productionUnits.push(productionUnit)
                }

                // TODO: If we do not change screen here, the selectors for production units as suppliers + relations are not updated.
                if (auditId) {
                    history.push('/audit/edit_pu/' + supplierId + '/' + productionUnit.id + '/' + auditId);
                }
                else if (!isStandalone) {
                    history.push('/suppliers/' + supplierId + '/' + productionUnit.id);
                }
                dispatch(sendStatusMessage(['status_message.the_production_unit_was_succesfully_created'], 3000));
                // TODO: This will not work with independent production units.
                dispatch(getProductionUnits(supplierId));
            })
            .catch((error: RequestError) => {
                catchException('createProductionUnit', {
                    endpoint: 'suppliers/[supplierId]/production_units',
                    request: 'suppliers/' + supplierId + '/production_units',
                    status: error.status,
                }, { error, productionUnitData, });
            });

    };
};

export const goToSuppliersList = (): AppThunk => {
    return async (): Promise<void> => {
        history.push('/suppliers');
    };
};

export const goToSupplier = (supplierId: string): AppThunk => {
    return async (): Promise<void> => {
        history.push('/suppliers/' + supplierId);
    };
};

export const getBookings = (start: number, perPage: number): AppThunk => {
    return async (dispatch): Promise<void> => {
        const filters: { [type: string]: string[] } = getLocationEntries(history.location as any)
        let filterStr: string = parseFiltersObj(filters, 'bracket', ['date', 'booking_filter']);
        if (filters.date) {
            filterStr += '&date=' + filters.date;
        }
        if (filters.booking_filter) {
            filterStr += '&booking_filter=' + filters.booking_filter;
        }
        if (filters.date_filter_context) {
            filterStr += '&date_filter_context=' + filters.date_filter_context
        }
        const isFirst = start === 0;
        dispatch(getBookingsRequest());
        const params = 'start=' + start + '&per_page=' + perPage + '&' + filterStr;
        return request('booking/overview?' + params, {})
            .then((data: Booking) => {
                dispatch(getBookingsSuccess(data, isFirst));
                dispatch(setContextData({ context: Context.Bookings, metaData: { list: { total: data.total } } }))
            })
    };
};

const normalizeBookingData = (bookingData: SupplierBooking): SupplierBooking => {
    const inspections = bookingData.inspections;
    const inspectionsMap = {};
    const subInspectionsMap = {};
    inspections.forEach((inspection: SupplierBookingInspection) => {
        if (!inspectionsMap[inspection.item_number]) {
            inspectionsMap[inspection.item_number] = [];
        }
        inspection.sub_inspections.forEach((si) => {
            si.features = inspection.features;
            si.booking_date = inspection.booking_date;
            si.scheduled_inspection_date = inspection.scheduled_inspection_date;
            si.confirmed = inspection.confirmed;
            si.must_confirm = inspection.must_confirm
            si.last_confirmed_at = inspection.last_confirmed_at
            si.master_id = inspection.id;
            if (!subInspectionsMap[si.item_number]) {
                subInspectionsMap[si.item_number] = [si];
            } else {
                subInspectionsMap[si.item_number].push(si);
            }
        });
        if (inspection.sub_inspections.length === 0) {
            inspectionsMap[inspection.item_number].push(inspection);
        }
    });
    Object.keys(subInspectionsMap).forEach((si) => {
        if (!inspectionsMap[si]) {
            inspectionsMap[si] = [];
        }
        subInspectionsMap[si].forEach((si2) => {
            inspectionsMap[si].push(si2);
        });
    });
    let returnArr = [];
    Object.keys(inspectionsMap).forEach((id: string) => {
        returnArr = returnArr.concat(inspectionsMap[id]);
    });
    bookingData.inspectionsMap = returnArr;
    return bookingData;
};

export const setBookingDefaultDate = (date: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch({
            type: SET_BOOKING_DEFAULT_DATE,
            payload: { date },
        });
    };
};


export const getSupplierBookingSuccess = createAction(types.GET_SUPPLIER_BOOKING_SUCCESS, (orderId: string, bookingData: SupplierBooking) => {
    return {
        type: types.GET_SUPPLIER_BOOKING_SUCCESS,
        payload: {
            orderId,
            bookingData,
        },
    };
});

export const getSupplierBookingRequest = createAction(types.GET_SUPPLIER_BOOKING_REQUEST, (orderId: string, isFetching: boolean) => {
    return {
        type: types.GET_SUPPLIER_BOOKING_REQUEST,
        payload: {
            orderId,
            isFetching,
        },
    };
});
export const getSupplierBookingFailure = createAction(types.GET_SUPPLIER_BOOKING_FAILURE, (orderId: string, isFetching: boolean, error: RequestError) => {
    return {
        type: types.GET_SUPPLIER_BOOKING_FAILURE,
        payload: {
            orderId,
            isFetching,
            error,
        },
    };
});

export const updateSupplierBookingRequest = createAction(types.UPDATE_SUPPLIER_BOOKING_REQUEST, (orderId: string, bookingData: SupplierBooking) => {
    return {
        type: types.UPDATE_SUPPLIER_BOOKING_REQUEST,
        payload: {
            orderId,
            bookingData,
        },
    };
});

export const createSupplierSuccess = createAction(types.CREATE_SUPPLIER, (suppliers: DetailedSupplier[], supplierData: DetailedSupplier) => {
    return {
        type: types.CREATE_SUPPLIER,
        payload: {
            supplierData,
            suppliers,
        },
    };
});

export const createProductionUnitSuccess = createAction(types.CREATE_PRODUCTION_UNIT, (productionUnits: ProductionUnit[], productionUnit: ProductionUnit, supplierId: string) => {
    return {
        type: types.CREATE_PRODUCTION_UNIT,
        payload: {
            productionUnit,
            productionUnits,
            supplierId,
        },
    }
});

export const getSuppliersSuccess = createAction(types.GET_SUPPLIERS, (suppliers: DetailedSupplier[]) => {
    return {
        type: types.GET_SUPPLIERS,
        payload: {
            suppliers,
        },
    }
});

export const getSupplierAndProductionUnitRelationsSuccess = createAction(types.GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS, (data: [string, string][]) => {
    return {
        type: types.GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS,
        payload: {
            relations: data
        }
    };
})

export const getSupplierDataSuccess = createAction(types.GET_SUPPLIER_DATA, (supplierData: DetailedSupplier, supplierId: string) => {
    return {
        type: types.GET_SUPPLIER_DATA,
        payload: {
            supplierData,
            supplierId,
        },
    }
});

export const getSupplierDataRequest = createAction(types.GET_SUPPLIER_DATA_REQUEST, () => {
    return {
        type: types.GET_SUPPLIER_DATA_REQUEST,
    };
});

export const getProductionUnitsSuccess = createAction(types.GET_PRODUCTION_UNITS_SUCCESS, (productionUnits: ProductionUnit[], supplierId: string, isList = false) => {
    return {
        type: types.GET_PRODUCTION_UNITS_SUCCESS,
        payload: {
            productionUnits,
            supplierId,
            isList,
        },
    };
});

export const setInspectionSupplierId = createAction(types.SET_INSPECTION_SUPPLIER_ID, (inspectionId: string, supplierId: string) => {
    return {
        type: types.SET_INSPECTION_SUPPLIER_ID,
        payload: {
            supplierId,
            inspectionId,
        },
    };
});

export const setInspectionProductionUnitWillBeOverwritten = createAction(types.SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN, (overwrite: boolean) => {
    return {
        type: types.SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN,
        payload: {
            overwrite,
        },
    };
});

export const setConfirmDeleteSupplierId = createAction(types.SET_CONFIRM_SUPPLIER_DELETE, (confirm: boolean, supplierId?: string) => {
    return {
        type: types.SET_CONFIRM_SUPPLIER_DELETE,
        payload: {
            confirm,
            supplierId,
        },
    };
});

export const setConfirmDeleteProductionUnitId = createAction(types.SET_CONFIRM_PRODUCTION_UNIT_DELETE, (confirm: boolean, productionUnitId?: string, supplierId?: string) => {
    return {
        type: types.SET_CONFIRM_PRODUCTION_UNIT_DELETE,
        payload: {
            confirm,
            productionUnitId,
            supplierId,
        },
    };
});

export const getBookingsRequest = createAction(types.GET_BOOKINGS_REQUEST, () => ({
    type: types.GET_BOOKINGS_REQUEST,
}));
export const getBookingsSuccess = createAction(types.GET_BOOKINGS_SUCCESS, (data: Booking, isFirst: boolean) => ({
    type: types.GET_BOOKINGS_SUCCESS,
    payload: { data, isFirst },
}));
