import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { BasicUserOptional, CorrectiveActionEditedEvent } from '../../../backend_api/models';
import Change from './Change';

type Props = {
    event: CorrectiveActionEditedEvent;
}

const EditEventDetails = (props: Props) => {
    const intl = useIntl()
    const convertUserChangeEventToString = (change: BasicUserOptional | null) => {
        if (change) {
            return change.firstname + ' ' + change.lastname
        }
        else {
            return null
        }
    }
    return <Fragment>
        {props.event.finding_change && <Change previousValue={props.event.finding_change.previous_value} newValue={props.event.finding_change.next_value} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.finding' })} />}
        {props.event.description_change && <Change previousValue={props.event.description_change.previous_value} newValue={props.event.description_change.next_value} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.description' })} />}
        {props.event.approver_change && <Change previousValue={convertUserChangeEventToString(props.event.approver_change.previous_value)} newValue={convertUserChangeEventToString(props.event.approver_change.next_value)} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.approver' })} />}
        {props.event.responsible_change && <Change previousValue={convertUserChangeEventToString(props.event.responsible_change.previous_value)} newValue={convertUserChangeEventToString(props.event.responsible_change.next_value)} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.responsible' })} />}
        {props.event.due_date_change && <Change previousValue={props.event.due_date_change.previous_value} newValue={props.event.due_date_change.next_value} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.due_date' })} />}
        {props.event.requires_evidence_from_app_change && <Change previousValue={props.event.requires_evidence_from_app_change.previous_value} newValue={props.event.requires_evidence_from_app_change.next_value} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.requires_evidence_from_app_change' })} />}
        {props.event.groups_change && <Change previousValue={props.event.groups_change.previous_value} newValue={props.event.groups_change.next_value} label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.groups_change' })} />}
    </Fragment>
}

export default EditEventDetails;