/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 73.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Basic Information about a User.
 * This type is used for transfer between modules and for external APIs.
 * @export
 * @interface BasicUser
 */
export interface BasicUser {
    /**
     * 
     * @type {boolean}
     * @memberof BasicUser
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    last_activity: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    lastname: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasicUser
     */
    supplier_user: boolean;
}

export function BasicUserFromJSON(json: any): BasicUser {
    return BasicUserFromJSONTyped(json, false);
}

export function BasicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': json['disabled'],
        'email': json['email'],
        'firstname': json['firstname'],
        'id': json['id'],
        'last_activity': json['last_activity'],
        'lastname': json['lastname'],
        'supplier_user': json['supplier_user'],
    };
}

export function BasicUserToJSON(value?: BasicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'email': value.email,
        'firstname': value.firstname,
        'id': value.id,
        'last_activity': value.last_activity,
        'lastname': value.lastname,
        'supplier_user': value.supplier_user,
    };
}


