import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { twMerge } from '../../utils';

type Props = {
    className?: string;
    children: React.ReactNode;
    to: string;
    underline?: boolean;
    isAbsolute?: boolean;
};

const Link = (props: Props): React.ReactElement => {
    const { to, className, underline, children } = props;
    if (props.isAbsolute) {
        return <a href={to} target='_blank' rel='noreferrer' className={twMerge('text-brand hover:text-brand noUnderline', className, underline && 'underline')}>{children}</a>
    }
    return <RRLink to={to} className={twMerge('text-brand hover:text-brand noUnderline', className, underline && 'underline')}>{children}</RRLink>
}
export default Link;
