import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionEvidenceAddedEvent } from '../../../backend_api/models/CorrectiveActionEvidenceAddedEvent';

type Props = {
    event: CorrectiveActionEvidenceAddedEvent;
}

const EvidenceAddedEvent = (props: Props) => {
    return (<Fragment>
        <FormattedMessage id={'corrective_actions.events.evidence_added'} />
        {props.event.files.map((file) => {
            return <div className='py-2' key={file.name}><a className='link' href={file.preview_url}>{file.name}</a></div>
        })}
    </Fragment>)
}

export default EvidenceAddedEvent;