import classnames from 'classnames';
import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TabsMenu from '../../../../base/components/TabsMenu';
import { Breakpoints } from '../../../../base/config';
import { getLayoutSelector } from '../../../../base/selectors';
import { getLocationEntry, useIsSmallMobileDevice, useMaxWidthBreak, useMinWidthBreak } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { DateFilter } from '../../../filters/components';
import AddFilters from '../../../filters/components/AddFilters';
import FiltersListNew from '../../../filters/components/FiltersListNew';
import PageContainer from '../../../globals/components/PageContainer';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import CollectCombine from '../../components/combine/CollectCombine';
import NewInspection from '../../components/dashboard/NewInspection';
import InspectionsList from '../../components/dashboard/views/InspectionsList';
import { getInspectionTypes, hasInspectionTypes as hasInspectionTypesSelector } from '../../selectors/selectors';
import { getTypeSelectedForCombineSelector, inspectionsListFetchingSelector, inspectionsListSlice, setListViewDefaultDate, setShowCombineCollector, showCombineCollectorSelector } from '../../slices/inspectionsListSlice';
import { InspectionType } from '../../types';

const InspectionsDashboard = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const location = useLocation();
    const query = getLocationEntry(location, 'search')[0];
    const status = getLocationEntry(location, 'status')[0];
    const isMobileMenuOpen = useSelector(getLayoutSelector).mobileMenuOpen;
    const inspectionTypes = useSelector(getInspectionTypes);
    const hasInspectionTypes = useSelector(hasInspectionTypesSelector);
    const combinedInspectionType = useSelector(getTypeSelectedForCombineSelector);
    const showCombine = useSelector(showCombineCollectorSelector);
    const isMaxSM = useMaxWidthBreak(Breakpoints.SM);
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    const isMinLG = useMinWidthBreak(Breakpoints.LG);
    const isMaxLG = useMaxWidthBreak(Breakpoints.LG);
    const isMaxXL = useMaxWidthBreak(Breakpoints.XL);
    const isMinXL = useMinWidthBreak(Breakpoints.XL);
    const listFetching = useSelector(inspectionsListFetchingSelector);
    const setShowCombine = (show: boolean): void => { dispatch(setShowCombineCollector(show)) };
    const toggleCombine = (type: InspectionType): void => {
        setShowCombine(true);
        dispatch(inspectionsListSlice.actions.setInspectionsForCombine([]));
        dispatch(inspectionsListSlice.actions.setInspectionTypeForCombine(type));
    };
    const [showCI, toggleCI] = useState(false);
    const filters = ['inspector-filter', 'conclusion-filter', 'approver-filter', 'inspection-types-filter', 'custom-field-filter', 'supplier-filter']
    if (isMaxLG) {
        filters.push('date-filter');
    }
    const getTitle = (): string => {
        const statusText = intl.formatMessage({ id: 'page_title.list_view_' + status });
        return query ? intl.formatMessage({ id: 'page_title.list_view_search' }, { query, status }) : status ? intl.formatMessage({ id: 'page_title.list_view_status' }, { status: statusText }) : intl.formatMessage({ id: 'page_title.list_view' });
    }
    const newAndCI = <HasFeatureAccess feature='u_inspections'>
        <div className={classnames('md:flex mt-2 md:mt-0', { 'hidden': !showCI })}>
            <NewInspection
                showCombine={showCombine}
                toggleCombine={toggleCombine}
                hasInspectionTypes={hasInspectionTypes}
                inspectionTypes={inspectionTypes}
                combinedInspectionType={combinedInspectionType} />
        </div>
    </HasFeatureAccess>;
    return <PageContainer header={<FormattedMessage id='inspections.header'/>}>
        <DocumentTitle title={getTitle()} />
        <div className={classnames('flex flex-col md:items-center md:flex-row md:mx-2 md:space-x-4 md:justify-between md:mb-8 top-0 md:relative', { 'sticky': !showCombine && !isMobileMenuOpen })}>
            <div className='flex flex-col md:flex-row md:space-x-4 md:items-center '>
                <div className='flex'>
                    <TabsMenu
                        active={getLocationEntry(location, 'status')[0]}
                        path='inspections'
                        items={[
                            { labelId: 'tabs.item.planned', query: { status: 'planned' }, id: 'planned' },
                            { labelId: 'tabs.item.ongoing', query: { status: 'ongoing' }, id: 'ongoing' },
                            { labelId: 'tabs.item.reports', query: { status: 'report' }, id: 'report' },
                        ]} />
                    <div className={classnames('md:hidden cursor-pointer py-3 px-4 border-l border-gray-400 flex bg-white font-bold', { 'mobileShowCI ': useIsSmallMobileDevice() })} onClick={(): void => toggleCI(!showCI)}>{showCI ? '-' : '+'}</div>
                </div>
                {(!showCombine && isMaxMD) && newAndCI}
                {isMinMD && <DateFilter onChange={setListViewDefaultDate} />}
                {!isMaxMD && <AddFilters/>}
            </div>
            {isMaxMD && <div className='p-2 justify-center w-full flex'><AddFilters/></div>}                
            {isMinMD && newAndCI}
        </div>
        <FiltersListNew
            isFetching={listFetching}
            filters={filters as any}
        />

        <HasFeatureAccess feature='u_inspections'>
            <CollectCombine hasInspectionTypes={hasInspectionTypes} />
            <InspectionsList showCombine={showCombine} />
        </HasFeatureAccess>
    </PageContainer>;
}

export default InspectionsDashboard;
