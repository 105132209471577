import moment from 'moment';
/* eslint-disable no-undef */
export const BASE_PATH: string = process.env.BASE_PATH;
export const BASE_URL: string = process.env.BASE_URL;
export const SERVICES_PREFIX: string = process.env.SERVICES_PREFIX;
export const SERVICES_PREFIX_V1: string = process.env.SERVICES_PREFIX_V1;
export const SERVICES_PREFIX_COMPLIANCE: string = process.env.SERVICES_PREFIX_COMPLIANCE;
export const API_PROXY_PATH_TEMPORARY_USER: string = process.env.API_PROXY_PATH_TEMPORARY_USER;
export const APP_PREFIX: string = process.env.APP_PREFIX;
export const ENVIRONMENT: string = process.env.NODE_ENV;
export const RELEASE: string = process.env.RELEASE;
export const MAX_UPLOAD_FILE_SIZE: number = parseInt(process.env.MAX_UPLOAD_FILE_SIZE);
export const WARN_UPLOAD_FILE_SIZE: number = parseInt(process.env.WARN_UPLOAD_FILE_SIZE);
export const FILTERS: string[] = 'scf_ids,production_unit_status,production_unit_id,compliance_req_cat,ca_states,final_conclusion_ids,audit_type,start,per_page,groups,status,orderId,type_id,booking_filter,type,search,d,date,datefrom,dateto,assigned_user,conclusion,custom_conclusion_ids,approver,supplier_id,supplier_ids,inspection_type,custom_field_values,inspections'.split(',') || [];
export const FILTERS_MINUS_DATES: string[] = 'scf_ids,production_unit_status,production_unit_id,compliance_req_cat,final_conclusion_ids,audit_type,ca_states,responsible_ids,groups,assigned_user,conclusion,custom_conclusion_ids,approver,supplier_id,supplier_ids,inspection_type,custom_field_values'.split(',') || [];
export const INSPECTIONS_BASE_URL = '/inspections';
export const INSPECTIONS_GROUP_KEY = '_inspection_group_id';
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const ROLES: string[] = require('./../../data/roles.json');
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT2 = 'yyyy-MM-dd';
export const LIST_VIEW_DEFAULT_DATE = moment.utc().subtract(21, 'days').format(DEFAULT_DATE_FORMAT) + '<' + moment.utc().add(21, 'days').format(DEFAULT_DATE_FORMAT);
export const BOOKINGS_DEFAULT_DATE = moment.utc().subtract(21, 'days').format(DEFAULT_DATE_FORMAT) + '<' + moment.utc().add(21, 'days').format(DEFAULT_DATE_FORMAT);

export const PLANNED_COLOR = 'var(--status-planned)';
export const DOWNLOADING_COLOR = 'var(--status-downloaded)';
export const IN_PROGRESS_COLOR = 'var(--status-in-progress)';
export const PENDING_COLOR = 'var(--status-pending)';
export const APPROVED_COLOR = 'var(--status-approved)';
export const REJECTED_COLOR = 'var(--status-rejected)';

export enum Breakpoints { SM = 640, MD = 768, LG = 1024, XL = 1280, XL2 = 1536 }
export const MOBILE_SMALL_BREAKPOINT = 767;
export const MOBILE_BREAKPOINT = 1023;
export const TABLET_BREAKPOINT = 1224;
export const DESKTOP_BREAKPOINT = 1600;
export const BIG_SCREEN_BREAKPOINT = 1824;

export const HEADER_HEIGHT = 65;

export const DEFAULT_FILE_UPLOAD_ENDPOINT = SERVICES_PREFIX + '/upload/file_resource/'

export type BREAKPOINTS = typeof Breakpoints.SM | typeof Breakpoints.MD | typeof Breakpoints.LG | typeof Breakpoints.XL | typeof Breakpoints.XL2;

export const SUPPORTED_LANGUAGES = ['bn', 'my', 'zh', 'da', 'en', 'fr', 'de', 'hi', 'id', 'pl', 'pt', 'ro', 'es', 'tr', 'uk', 'vi', 'it', 'bg', 'lt'];

export const DEFAULT_LOCALE_LANG = 'en-US';
export const DEFAULT_LOCALE = 'en';
export const MAIN_WEBSITE_URL = 'https://qarmainspect.com';
export const DEFAULT_PASSWORD_LENGTH = process.env.NODE_ENV !== 'production' ? 1 : 8;
export const DEFAULT_SEARCH_RESULT_PAGE_SIZE = 50;
export const DEFAULT_LIMIT_LIST_SIZE = 50;
export const LIMITED_SELECTOR_ITEMS_COUNT = 100;
export const ACCEPTED_UPLOAD_IMAGE_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/heic',
    'image/webp',
];
