import React, { ReactNode } from 'react';
import { BasicUserOptional } from '../../../backend_api_2';
import { twMerge } from '../../../base/utils';
import { UserNameText } from '../../users/components/Widgets';

type Props = {
    className?: string;
    heading?: string | ReactNode;
    status?: any;
    user?: BasicUserOptional | ReactNode;
    editUserSelector?: ReactNode;
    comment?: any;
    showCommentIcon?: boolean;
};

const ReportApprovalItemShared = (props: Props): React.ReactElement => {
    const { showCommentIcon } = props;
    return <div className={twMerge('w-full', !props.editUserSelector ? 'mb-0' : '-mb-2')}>
        <div className='font-bold uppercase pb-1'>
            {props.heading}
        </div>
        <div className='approver flex flex-col pb-2 mb-1' key='approvalItem_inspectors_conclusion-2'>
            <div className='flex justify-between items-center'>
                {!props.editUserSelector && props.user && <div className='name text-secondary'><UserNameText user={props.user as BasicUserOptional} /></div>}
                {props.editUserSelector && <div className='name text-secondary flex w-full items-center'>{props.editUserSelector}</div>}
                <div className=''>{props.status}</div>
            </div>
            {showCommentIcon && <div className='flex pt-2 pb-1 w-full'>
                {showCommentIcon && <i className='mi comment'>notes</i>}{props.comment}
            </div>}
            {!showCommentIcon && <div className='flex pt-2 pb-1 w-full'>
                {props.comment}
            </div>}
        </div>
    </div>
}
export default ReportApprovalItemShared;
