import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Popup } from "semantic-ui-react";
import { twMerge } from "tailwind-merge";
import { HistoryEntryAuditReferenceSourceReferenceTypeEnum, HistoryEntryImportJobReferenceSourceReferenceTypeEnum, HistoryEntryUserReferenceSourceReferenceTypeEnum, HistoryEventCommentAdded, HistoryEventCommentAddedReply, HistoryEventEntityAddedToField, HistoryEventEntityAddedToFieldFieldEnum, HistoryEventEntityRemovedFromField, HistoryEventEntityRemovedFromFieldFieldEnum, HistoryEventFieldChanged, HistoryEventStatusSet, SupplierServiceHistoryEntry, SupplierServiceHistoryEntryHistoryEventTypeEnum } from "../../../backend_api/models";
import Button from "../../../base/components/basic/Button";
import { getPrettyDatetime, capitaliseWord, isUndefinedOrNull, prettyClipText, getLocaleLanguageString, getTranslation, getDisplayUser, preventDefaultAndStopPropagation } from "../../../base/utils";
import { TranslateText } from "../../globals/components";
import { Link } from 'react-router-dom';
import { Comment } from 'semantic-ui-react';
import CommentInput from "../../../base/components/comments/CommentInput";
import { useAppDispatch } from "../../../store";
import { getDetailedProductionUnitListSelector, replyToProductionUnitCommentThread } from "../slice/productionUnitsSlice";
import { useSelector } from "react-redux";
import { getDetailedSupplierSelector, replyToSupplierCommentThread } from "../../suppliers/slice/suppliersSlice";

export function ProductionUnitOrSupplierHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;

    switch(entry.history_event_type) {
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.ProductionUnitCreated:
            return <ProductionUnitCreatedHistoryEntry entry={entry} />
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.SupplierCreated:
            return <SupplierCreatedHistoryEntry entry={entry} />
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.StatusSet:
            return <StatusSetHistoryEntry entry={entry} /> 
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.EntityAddedToField:
            return <EntityAddedToFieldHistoryEntry entry={entry} /> 
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.EntityRemovedFromField:
            return <EntityRemovedFromFieldHistoryEntry entry={entry} /> 
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.FieldsChanged:
            return <FieldChangedHistoryEntry entry={entry} /> 
        case SupplierServiceHistoryEntryHistoryEventTypeEnum.CommentAdded:
            return <CommentAddedEvent entry={entry}/> 
        default:
            return <></>
    }

}

function ProductionUnitCreatedHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;

    return (
        <div className="pt-4">
            <div className='flex flex-row justify-between items-center mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className="mb-1"><FormattedMessage id="production_units.detailed_page.history.created_event"/></div>
        </div>
    )
}

function renderHistoryEntrySourceReference(entry: SupplierServiceHistoryEntry): string {
    const intl = useIntl();
    const source = entry.source_reference;
    switch(source?.source_reference_type) {
        case HistoryEntryUserReferenceSourceReferenceTypeEnum.User:
            return getDisplayUser(source);
        case HistoryEntryAuditReferenceSourceReferenceTypeEnum.Audit:
            return intl.formatMessage({id: 'suppliers.detail_page.history.from_audit'}) + " - " + getDisplayUser(source)
        case HistoryEntryImportJobReferenceSourceReferenceTypeEnum.ImportJob:
            return intl.formatMessage({id: 'suppliers.detail_page.history.importer_source'});
        default:
            return "";
    }
}

function SupplierCreatedHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    return (
        <div className="py-4">
            <div className='flex flex-row justify-between items-center mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className="mb-1"><FormattedMessage id="suppliers.detail_page.history.created_event"/></div>
        </div>
    )
}

function StatusSetHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const event = entry.history_event as HistoryEventStatusSet;

    return (
        <div className="border-b border-dotted py-4">
            <div className='flex flex-row justify-between items-center mb-2 mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className=''>
                <div className="mb-1 font-bold"><FormattedMessage id="production_units.detailed_page.history.status_changed"/></div>
                <div className='flex flex-row justify-start items-center gap-1 mb-4'>
                    <div className={twMerge('text-xs', event.previous_status?.background_color && event.previous_status.text_color ? 'p-1 rounded-md' : 'text-text-default')}
                    style={{backgroundColor: event.previous_status.background_color, color: event.previous_status.text_color}}>
                        <FormattedValue 
                            value={<TranslateText
                                showTranslatedComments={true}
                                translateObj={event.previous_status.name}
                                originalLanguage={event.previous_status.name?.language}
                                originalText={event.previous_status.name?.text}
                                showToggleActions={true}
                            />} 
                        />
                    </div>
                    → 
                    <div className={twMerge('text-xs', event.new_status?.background_color && event.new_status.text_color ? 'p-1 rounded-md' : 'text-text-default')}
                        style={{backgroundColor: event.new_status.background_color, color: event.new_status.text_color}}>
                        <TranslateText
                            showTranslatedComments={true}
                            translateObj={event.new_status.name}
                            originalLanguage={event.new_status.name?.language}
                            originalText={event.new_status.name?.text}
                            showToggleActions={true}
                        />
                    </div>
                </div>
                <Popup
                    open={false}
                    trigger={<span><TranslateText
                        showTranslatedComments={event.comment?.language && event.comment?.language != "unknown"}
                        translateObj={event.comment}
                        originalLanguage={event.comment.language}
                        originalText={event.comment.text}
                        showToggleActions={event.comment?.language && event.comment?.language != "unknown"}
                    /></span>}
                    className='commentsTranslationsPopup'
                    position='left center'
                >
                    <Popup.Header><FormattedMessage id='comments.translate_translate_header' /></Popup.Header>
                    <Popup.Content><p><FormattedMessage id='comments.translate_automatically' /></p>
                        <Button onClick={(): void => {}}><FormattedMessage id='comments.translate_yes_please'/></Button>
                        <Button onClick={(): void => {}}><FormattedMessage id='comments.translate_no_thanks'/></Button>
                        <p className='profile'><FormattedMessage
                            id='comments.translate_can_be_changed_on_settings_page'
                            values={{ profilePageLink: <Link to={'/profile'}><FormattedMessage id='comments.translate_profile_page' /></Link> }}
                        /></p>
                    </Popup.Content>
                </Popup>
                
            </div>
        </div>
    )
}

function FieldChangedHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const fieldsChanged = entry.history_event as HistoryEventFieldChanged[];

    return (
        <div className="border-b border-dotted py-4">
            <div className='flex flex-row justify-between items-center mb-2 mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className=''>
                <div className="mb-1"><FormattedMessage id="production_units.detailed_page.history.fields_changed"/></div>
                {
                    fieldsChanged.map(field => {
                        return (
                            <div className='mb-1'>
                                <div className="flex flex-row font-bold">{capitaliseWord(field.field)}</div>
                                <div className='flex flex-wrap gap-1 justify-start text-secondary'>
                                    <FormattedValue value={field.previous_value} />
                                    <span>→</span>
                                    <FormattedValue value={field.new_value}/>
                                </div>
                            </div>  
                        )
                    })
                }
            </div>
        </div>
    )
}

function CommentAddedEvent(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const lang = getLocaleLanguageString(intl.locale);

    const event = entry.history_event as HistoryEventCommentAdded;
    const translatedText = getTranslation(event.text, lang);
    const [reply, setReply] = React.useState(false);
    const dispatch = useAppDispatch();
    const detailedProductionUnit = useSelector(getDetailedProductionUnitListSelector);
    const detailedSupplier = useSelector(getDetailedSupplierSelector);

    function addComment(text: string) {
        if(detailedProductionUnit) {
            dispatch(replyToProductionUnitCommentThread({
                productionUnitId: detailedProductionUnit?.id,
                threadId: event.thread_id,
                reply: {
                    text: text,
                },
            }))
        } else {
            dispatch(replyToSupplierCommentThread({
                supplierId: detailedSupplier?.id,
                threadId: event.thread_id,
                reply: {
                    text: text,
                },
            }))
        }
        setReply(false);
    }


    return (
        <div className="border-b border-dotted py-4">
            <div className='flex flex-row justify-between items-center mb-2 mr-1'>
                <div className='text-secondary font-medium'>{getDisplayUser(event.commenter)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className='flex flex-col mb-4'>
                <div className='flex text-secondary'>
                {translatedText}
                </div>
            </div>
            <Comment.Actions className='my-1'>
                <Comment.Action className='link noUnderline text-sm'
                    onClick={(e): void => {
                        preventDefaultAndStopPropagation(e);
                        setReply(!reply);
                    }}>
                    <FormattedMessage id='comments.reply' />
                </Comment.Action>
            </Comment.Actions>
            {reply && <CommentInput handleNewComment={addComment} showUpload={false} />}
            {
                event.replies?.length > 0 &&
                <Comment.Group className='pl-8 border-l' >
                    {event.replies?.map(entry => <CommentAddedReplyEntry threadId={event.thread_id} entry={entry} />)}
                </Comment.Group>
            }
        </div>
    )
}

function CommentAddedReplyEntry(props: {
    threadId: string,
    entry: HistoryEventCommentAddedReply
}) {
    const {threadId, entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const lang = getLocaleLanguageString(intl.locale);
    const [reply, setReply] = React.useState(false);
    const dispatch = useAppDispatch();
    const detailedProductionUnit = useSelector(getDetailedProductionUnitListSelector);
    const detailedSupplier = useSelector(getDetailedSupplierSelector);


    function addComment(text: string) {
        if(detailedProductionUnit) {
            dispatch(replyToProductionUnitCommentThread({
                productionUnitId: detailedProductionUnit?.id,
                threadId: threadId,
                reply: {
                    text: text,
                    parent_reply_id: entry.reply_id
                },
            }))
        } else {
            dispatch(replyToSupplierCommentThread({
                supplierId: detailedSupplier?.id,
                threadId: threadId,
                reply: {
                    text: text,
                    parent_reply_id: entry.reply_id
                },
            }))
        }
        setReply(false);
    }
    return (
        <Fragment>
            {entry && <Comment.Group>
                <div className='flex flex-row justify-between mb-2'>
                    <Comment.Author as='a' className='text-secondary font-medium'>{getDisplayUser(entry.replier)}</Comment.Author>
                    <Comment.Metadata className='text-sm text-gray-500'>
                        <div>{getPrettyDatetime(entry.created_at, locale || null)}</div>
                    </Comment.Metadata>
                </div>
                {getTranslation(entry.text, lang)}
                <Comment.Actions className='my-1'>
                    <Comment.Action className='link noUnderline text-sm'
                        data-test-id='btn-comment-reply'
                        onClick={(e): void => {
                            preventDefaultAndStopPropagation(e);
                            setReply(!reply);
                        }}>
                        <FormattedMessage id='comments.reply' />
                    </Comment.Action>
                </Comment.Actions>
                {reply && <CommentInput handleNewComment={addComment} showUpload={false} />}
                {
                    entry.replies?.length > 0 &&
                    <Comment.Group className='pl-8 border-l' >
                        {entry.replies?.map(reply => <CommentAddedReplyEntry threadId={threadId} entry={reply} />)}
                    </Comment.Group>
                }
            </Comment.Group>}
        </Fragment>
    )
}


function FormattedValue(props: {value: string | string[] | React.ReactElement}) {
    const {value} = props;
    if(isUndefinedOrNull(value)) {
        return <div className={twMerge("italic")}><FormattedMessage id='globals.no_value'/></div>
    }

    if(Array.isArray(value) && value.length == 0) {
        return <div className={twMerge("italic")}><FormattedMessage id='globals.no_value'/></div>
    }

    if(Array.isArray(value)) {
        return <div>{prettyClipText(value?.join?.(", "), 100)}</div>
    }

    if(typeof value == 'string') {
        return <div>{prettyClipText(value, 100)}</div>
    }

    return <div>{value}</div>
} 

function EntityAddedToFieldHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const event = entry.history_event as HistoryEventEntityAddedToField;

    function getEventDescription() {
        switch(event.field) {
            case HistoryEventEntityAddedToFieldFieldEnum.ProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.related_pu_is_added" />
            case HistoryEventEntityAddedToFieldFieldEnum.RelatedSuppliers:
                return <FormattedMessage id="production_units.detailed_page.history.related_supplier_is_added" />
            case HistoryEventEntityAddedToFieldFieldEnum.DownstreamProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.downstream_pu_is_added" />
            case HistoryEventEntityAddedToFieldFieldEnum.UpstreamProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.uptream_pu_is_added" />
            default:
                return '';
        }
    }

    function getEventLink() {
        switch(event.field) {
            case HistoryEventEntityAddedToFieldFieldEnum.ProductionUnits:
                return `/production_units/${event.entity_id}`;
            case HistoryEventEntityAddedToFieldFieldEnum.RelatedSuppliers:
                return `/suppliers/${event.entity_id}`;
            case HistoryEventEntityAddedToFieldFieldEnum.DownstreamProductionUnits:
                return `/production_units/${event.entity_id}`;
            case HistoryEventEntityAddedToFieldFieldEnum.UpstreamProductionUnits:
                return `/production_units/${event.entity_id}`;
            default:
                return '';
        }
    }

    return (
        <div className="border-b border-dotted py-4">
            <div className='flex flex-row justify-between items-center mb-2 mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className="mb-1">{getEventDescription()}</div>
            <div className='flex flex-row justify-start items-center'>
                <Link
                    className='link'
                    to={getEventLink()}>
                    {formatNameAndNumber(event.entity_name, event.entity_number)}
                </Link>
            </div>
        </div>
    )
}

function EntityRemovedFromFieldHistoryEntry(props: {
    entry: SupplierServiceHistoryEntry
}) {
    const {entry} = props;
    const intl = useIntl();
    const locale = intl.defaultLocale;
    const event = entry.history_event as HistoryEventEntityRemovedFromField;

    function getEventDescription() {
        switch(event.field) {
            case HistoryEventEntityRemovedFromFieldFieldEnum.ProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.related_pu_is_removed" />
            case HistoryEventEntityRemovedFromFieldFieldEnum.RelatedSuppliers:
                return <FormattedMessage id="production_units.detailed_page.history.related_supplier_is_removed" />
            case HistoryEventEntityRemovedFromFieldFieldEnum.DownstreamProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.downstream_pu_is_removed" />
            case HistoryEventEntityRemovedFromFieldFieldEnum.UpstreamProductionUnits:
                return <FormattedMessage id="production_units.detailed_page.history.uptream_pu_is_removed" />
            default:
                return '';
        }
    }

    function getEventLink() {
        switch(event.field) {
            case HistoryEventEntityRemovedFromFieldFieldEnum.ProductionUnits:
                return `/production_units/${event.entity_id}`;
            case HistoryEventEntityRemovedFromFieldFieldEnum.RelatedSuppliers:
                return `/suppliers/${event.entity_id}`;
            case HistoryEventEntityRemovedFromFieldFieldEnum.DownstreamProductionUnits:
                return `/production_units/${event.entity_id}`;
            case HistoryEventEntityRemovedFromFieldFieldEnum.UpstreamProductionUnits:
                return `/production_units/${event.entity_id}`;
            default:
                return '';
        }
    }

    return (
        <div className="border-b border-dotted py-4">
            <div className='flex flex-row justify-between items-center my-2 mr-1'>
                <div className='text-secondary font-medium'>{renderHistoryEntrySourceReference(entry)}</div>
                <div className='text-sm text-gray-500 mt-1'>{getPrettyDatetime(entry.created_at, locale || null)}</div>
            </div>
            <div className="mb-1">{getEventDescription()}</div>
            <div className='flex flex-row justify-start items-center'>
                <Link
                    className='link'
                    to={getEventLink()}>
                {formatNameAndNumber(event.entity_name, event.entity_number)}
                </Link>
            </div>
        </div>
    )
}

function formatNameAndNumber(name: string, number: string): string {
    if(name && number) {
        return number + " - " + name;
    }

    if(name) {
        return name;
    }

    return number;
}