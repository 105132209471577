import React from 'react';
import { useIntl } from 'react-intl';
import { InfoIcon } from '../../../globals/components/Utils';
import { Inspection } from '../../types';

type AQLInfoProps = {
    inspection: Inspection;
    hover?: boolean;
    inline?: boolean;
};
const AQLInfo = (props: AQLInfoProps): React.ReactElement => {
    const intl = useIntl();
    const { inspection, hover = false, inline = false } = props;
    if ((inspection.sampling_protocol && inspection.original_sampling_protocol_id) && inspection.original_sampling_protocol_id !== inspection.sampling_protocol.id) {
        const newLevel = inspection.sampling_protocol.name;
        const oldLevel = inspection.original_sampling_protocol.name;
        const text = intl.formatMessage({ id: 'report.info.AQL_protocol_was_changed_by_inspector' }, { newLevel, oldLevel });
        return hover ? <InfoIcon compact={true} noMargins={true} inline={inline} hoverText={text} /> : <InfoIcon inline={inline} compact={true} text={text} />;
    }
    return null;
};

export default AQLInfo;
