/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.7 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputCustomFieldMultipleChoiceValue
 */
export interface InputCustomFieldMultipleChoiceValue {
    /**
     * 
     * @type {Array<string>}
     * @memberof InputCustomFieldMultipleChoiceValue
     */
    option_ids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InputCustomFieldMultipleChoiceValue
     */
    type: InputCustomFieldMultipleChoiceValueTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum InputCustomFieldMultipleChoiceValueTypeEnum {
    MultipleChoice = 'MultipleChoice'
}

export function InputCustomFieldMultipleChoiceValueFromJSON(json: any): InputCustomFieldMultipleChoiceValue {
    return InputCustomFieldMultipleChoiceValueFromJSONTyped(json, false);
}

export function InputCustomFieldMultipleChoiceValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputCustomFieldMultipleChoiceValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'option_ids': json['option_ids'],
        'type': json['type'],
    };
}

export function InputCustomFieldMultipleChoiceValueToJSON(value?: InputCustomFieldMultipleChoiceValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'option_ids': value.option_ids,
        'type': value.type,
    };
}


