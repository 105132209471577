import { createAction } from 'typesafe-actions';
import { VideoResource } from '../../backend_api/models';
import { request } from '../../base/api';
import { AppThunk } from '../../base/types';
import { catchException } from '../errorHandling/handler';
import { VIDEO_REQUEST_SUCCESS } from './actionTypes';

export const getVideo = (videoId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        const url = 'video_info/' + videoId;
        return request(url, {})
            .then((data) => {
                dispatch(getVideoSuccess(data));
            }).catch((error) => {
                console.log('Error', error)
                catchException('getVideo', {
                    endpoint: 'video_info/:videoId',
                    request: url,
                    status: error.status,
                }, { error, videoId });
            });
    };
}

export const getVideoSuccess = createAction(VIDEO_REQUEST_SUCCESS, (video: VideoResource) => {
    return {
        type: VIDEO_REQUEST_SUCCESS,
        payload: {
            video
        },
    };
});
