import classnames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import Image from '../../../../base/components/Image';
import { AppState } from '../../../../base/types';
import { useIsMobileDevice } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { TextItem } from '../../../globals/components';
import users from '../../../users';
import { getGroupedInspectionsByTypeIdSelector } from '../../selectors/reportSelectors';
import { Inspection } from '../../types';
import { getDoneOrOngoingInspections, getInspectionDate, isInspectionPartOfCombinedInspection } from '../../Utils';
import { InspectionTypeStatus } from '../edit/Status';
import { ReinspectLabel } from '../Reinspect';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import IsEditPopup from './IsEditPopup';

const WIDE_PAGE = 1990;
const UserInitials = users.components.Widgets.UserInitialsText;

type SideSwitcherProps = {
    inspections: Inspection[];
    currentInspectionId: string;
    isCollapsed: boolean;
    isFrontpage: boolean;
    orderId: string;
};

const SideSwitcher = (props: SideSwitcherProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isMobile = useIsMobileDevice();
    const [collapsed, setCollapsed] = useState(props.isCollapsed);
    const inspectionsMap = useSelector((state: AppState) => getGroupedInspectionsByTypeIdSelector(state, []));
    const inspections: Inspection[] = Object.values(inspectionsMap);
    const inspection = getDoneOrOngoingInspections(props.inspections, true)[0];
    const swEl = useRef(null);
    const toggleCollapsed = (): void => {
        setCollapsed(!collapsed);
    };
    useEffect(() => {
        if (swEl.current) {
            const parentHeight = document.getElementsByClassName('main')[0].clientHeight;
            swEl.current.style.height = parentHeight;
        }
    });
    useEffect(() => {
        if (isMobile) {
            dispatch(setLayoutAttribute('bodyFixed', !collapsed));
        }
    }, [collapsed]);
    useEffect(() => {
        if (swEl.current) {
            const parentW = window.innerWidth;
            setCollapsed(parentW < WIDE_PAGE);
        }
    }, []);
    const orderItem = (inspection: Inspection, selected: boolean, isFp: boolean): React.ReactElement => {
        let trigger;
        const typeLabel = <span className='status'><InspectionTypeStatus emptyText={true} inspection={inspection} isTag={true} isFluid={false} /></span>;
        let textLabel;
        let link = '/inspections/reports/';
        link += isFp ? (props.inspections[0].inspection_id) : (isInspectionPartOfCombinedInspection(inspection) ? inspection.master_inspection.id : inspection.inspection_id);
        link += '?orderId=' + props.orderId;
        if (isFp) {
            link += '&overview=true';
            if (collapsed) {
                textLabel = <Popup
                    popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
                    offset={[0, 5]}
                    hideOnScroll={false}
                    inverted
                    size='tiny'
                    position='right center'
                    trigger={<div className='content'><Icon name='th' /></div>}
                    className=''>
                    <Popup.Content>{intl.formatMessage({ id: 'report.side_switcher.overview' })}</Popup.Content>
                </Popup>;
            } else {
                textLabel = <div className='content'>{intl.formatMessage({ id: 'report.side_switcher.overview' })}</div>;
            }
        } else {
            trigger = <div className='content'>{!collapsed && <span className='flex space-x-2'><div>{inspection.item_name}</div></span>}{typeLabel}</div>;
            const reinspect = inspection.reinspected_by.length > 0 || inspection.reinspection_based_on !== null;
            textLabel = <Popup
                popperModifiers={[{ name: 'preventOverflow', options: { boundary: 'window' } }]}
                offset={[0, 5]}
                hideOnScroll={false}
                inverted
                size='tiny'
                position='right center'
                trigger={trigger}
                hoverable={true}
                className='sideSwitcherPopup'>
                <Popup.Content>
                    {inspection.item_image && <Image squared url={inspection.item_image} className='mb-2' h={24} w={24} />}
                    <TextItem label={intl.formatMessage({ id: 'inspections.item_name' })} text={inspection.item_name} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.item_no' })} text={inspection.item_number} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.total_qty' })} text={inspection.total_quantity} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.date' })} text={getInspectionDate([inspection], intl.locale)} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.inspector' })} text={<UserInitials user={inspection.assigned_user} />} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.inspection_type' })} text={(inspection.inspection_type && inspection.inspection_type.name) || '-'} />
                    {reinspect && <TextItem label={'Reinspection'} text={<ReinspectLabel inspection={inspection} />} />}
                    {inspection.involved_in_split_shipment && <TextItem label={intl.formatMessage({ id: 'inspections.split_shipment' })} text={<SplitShipmentsMarker inspection={inspection} />} />}

                </Popup.Content>
            </Popup>;
        }
        if (inspection.status === 'planned') {
            //const trigger = textLabel;
            link = '/inspections/edit_inspection/' + inspection.order_id;
            return <div className='link noUnderline'><IsEditPopup
                plannedUrl={link}
                trigger={<div className={selected ? 'orderItem selected' : 'orderItem'}>
                    {isMobile ? trigger : textLabel}
                    <div className='arrow left'></div>
                </div>}
                inspection={inspection}
            /></div>;
        }
        return (
            <Link to={link}>
                <div className={selected ? 'orderItem selected' : 'orderItem'}>
                    {isMobile ? (isFp ? textLabel : trigger) : textLabel}
                    <div className='arrow left'></div>
                </div>
            </Link>
        );
    };
    const innerElements = <div className='inner'>
        <div className='top'>
            <div className='orderItem back'></div>
            {!collapsed && <div className='orderItem cnt'>
                <div className='content'>
                    {inspection.order_number}
                </div>
                {inspections.length > 1 && <div className='itemsCount'>{inspections.length}</div>}
            </div>}
            {collapsed && inspections.length > 1 && <div className='itemsCount'>{inspections.length}</div>}
        </div>
        <Fragment>{
            inspections.length > 0 ? orderItem(inspection, props.isFrontpage && props.currentInspectionId === inspection.inspection_id, true)
                :
                null
        }
        </Fragment>
        {Object.values(inspectionsMap).map((inspections: Inspection[]) => {
            return inspections.map((inspection: Inspection) => {
                let selected = false;
                if (!props.isFrontpage) {
                    if (!inspection.master_inspection) {
                        selected = inspection.inspection_id === props.currentInspectionId;
                    }
                }
                return orderItem(inspection, selected, false);
            });
        })}
    </div>;
    return (
        <div ref={swEl} className={classnames('sideSwitcher', { collapsed, mobile: isMobile })}>
            {isMobile && <Menu isOpen={!collapsed} onClose={(): void => { toggleCollapsed(); setCollapsed(true); }} noOverlay customBurgerIcon={false} >{innerElements}</Menu>}
            {!isMobile && innerElements}
            <div className={classnames('expand', { collapsed, mobile: isMobile })} onClick={(): void => toggleCollapsed()}>{isMobile && <div className='cnt'>{inspections.length}</div>}</div>
        </div>
    );
};

export default SideSwitcher;
