import React, { ChangeEvent, Component, SyntheticEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { Breadcrumb, Button, Checkbox, Dimmer, Divider, Form, Header, Icon, Input, Label, Loader, Modal, Popup, Segment, Table } from 'semantic-ui-react';
import { DetailedSupplier, SupplierFeaturesDto, SupplierUser, UserRoleBrief } from '../../../backend_api/models';
import { OptionsType, Role } from '../../../base/types';
import { getLocaleLanguageString } from '../../../base/utils';
import AuditList from '../../audit/components/AuditList';
import { createAudit } from '../../audit/slices/auditSlice';
import { Locale } from '../../globals';
import { InfoIcon } from '../../globals/components/Utils';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import Groups from '../../groups/components/Groups';
import { NewProductionUnitParams } from '../../productionUnits/components/NewProductionUnitParams';
import CreateUserModal from '../../users/containers/CreateUserModal';
import DeactivateUser from '../../users/containers/DeactivateUser';
import { createProductionUnit } from '../actions';
import { ProductionUnit as ProductionUnitType } from '../types';
import DeleteSupplier from './DeleteSupplier';
import ProductionUnits from './ProductionUnits';

type OwnProps = {
    supplierData: DetailedSupplier;
    productionUnits: ProductionUnitType[];
    isDirty: boolean;
    roles: UserRoleBrief[];
    locales: Locale[];
    isFetching: boolean;
    handleChange(evt: ChangeEvent | SyntheticEvent, item: { name?: string; value: string; checked?: boolean }, callback?): void;
    handleEditUpdate(): void;
    onProductionUnitIdAdded(productionUnitId: string): void,
    reloadSupplierData(): void;
};
type DispatchProps = {
    actions: {
        createProductionUnit: typeof createProductionUnit;
        createAudit: typeof createAudit;
    }
};
const initialState = { dirty: false, showCreateUser: false, editNumber: false, editName: false, showEditModal: false, editApproved: false };
type State = Readonly<typeof initialState>;
type SupplierProps = OwnProps & DispatchProps & WrappedComponentProps & RouteComponentProps<any>;

class EditSupplier extends Component<SupplierProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.showUserDialog = this.showUserDialog.bind(this);
    }
    render(): React.ReactElement {
        const supplierData: DetailedSupplier = this.props.supplierData;
        const lang = getLocaleLanguageString(this.props.intl.locale);

        if (supplierData && this.props.match.params.supplierId === supplierData.id) {
            const hasUsers: boolean = supplierData.users && supplierData.users.length > 0;
            const formattedRoles: OptionsType[] = [];
            const disabled: boolean = supplierData.deleted;
            this.props.roles.forEach((role: Role) => {
                formattedRoles.push({ key: role.id, value: role.id, text: role.name[lang] || role.name.C });
            });

            return <span className='editSupplier'>
                <Header as='h2'>{this.props.supplierData.name}: {this.props.supplierData.number} {this.props.supplierData.deleted && <Label color='red'>Deleted</Label>}</Header>
                <Breadcrumb>
                    <Breadcrumb.Section>
                        <Link className='link noUnderline' to='/suppliers'><FormattedMessage id='suppliers.suppliers' /></Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section>
                        <FormattedMessage id='production_unit.breadcrumb.edit_supplier' />
                    </Breadcrumb.Section>
                </Breadcrumb>
                <Dimmer active={this.props.isFetching} inverted>
                    <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
                </Dimmer>
                <Segment raised container="true">
                    <Segment basic>
                        <Header as='h3'><FormattedMessage id='suppliers.basic_information' /></Header>
                        <Form>
                            <Form.Field width={8} inline disabled={disabled}>
                                <label><FormattedMessage id='suppliers.input_label.supplier_number' /></label>
                                <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                                    <div className='flex space-x-2 items-center'>
                                        <Input className='' disabled={!this.state.editNumber} type='text' name='number' value={supplierData.number} onChange={this.props.handleChange} onBlur={this.props.handleEditUpdate} />
                                        <Icon onClick={(): void => this.editNumber()} name="pencil" />
                                    </div>
                                </HasFeatureAccess>
                            </Form.Field>
                            <Form.Field width={8} inline disabled={disabled}>
                                <label><FormattedMessage id='suppliers.input_label.supplier_name' /></label>
                                <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                                    <div className='flex space-x-2 items-center'>
                                        <Input disabled={!this.state.editName} type='text' name='name' value={supplierData.name} onChange={this.props.handleChange} onBlur={this.props.handleEditUpdate} />
                                        <Icon onClick={(): void => this.editName()} name="pencil" />
                                    </div>
                                </HasFeatureAccess>
                            </Form.Field>
                            {!supplierData.deleted && <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Delete} supplier={supplierData}><DeleteSupplier supplierId={supplierData.id} /></HasFeatureAccess>}
                        </Form>
                        {/* <HasFeatureAccess
                            type='supplier'
                            feature={SupplierFeaturesDto.ManageProductionUnits}
                            supplier={supplierData}
                            showDisabled={true}> */}

                            <ProductionUnits
                                onUserWantedToAddProductionUnit={(productionUnitId: string) => {
                                    this.props.onProductionUnitIdAdded(productionUnitId)
                                }}
                                onUserWantedToCreateProductionUnit={(params: NewProductionUnitParams) => {
                                    this.props.actions.createProductionUnit(supplierData.id, {
                                        name: params.name,
                                        number: params.number,
                                    });
                                }}
                                productionUnits={this.props.productionUnits}
                                supplierId={supplierData.id} />

                        {/* </HasFeatureAccess> */}
                        <HasFeatureAccess feature='u_audits'>
                            <AuditList supplierId={supplierData.id} />
                        </HasFeatureAccess>
                        <HasFeatureAccess feature='u_create_audits'>
                            <Button primary onClick={(): void => {
                                this.props.actions.createAudit({ target_supplier: { id: supplierData.id } });
                            }}><FormattedMessage id='audits.create_audit' /></Button>
                            <Divider basic="true" />
                        </HasFeatureAccess>
                        <Header as='h3'><FormattedMessage id='suppliers.users' /></Header>
                        {hasUsers && <Table className='users'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell><FormattedMessage id='suppliers.users.firstname' /></Table.HeaderCell>
                                    <Table.HeaderCell><FormattedMessage id='suppliers.users.lastname' /></Table.HeaderCell>
                                    <Table.HeaderCell><FormattedMessage id='suppliers.users.email' /></Table.HeaderCell>
                                    <Table.HeaderCell><FormattedMessage id='suppliers.users.role' /></Table.HeaderCell>
                                    <Table.HeaderCell><FormattedMessage id='suppliers.users.contact' /></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {supplierData.users.map((user: SupplierUser, index: number) => {
                                    const userEmail = user.did_login_once ? user.email : <div className='flex space-x-1'>
                                        <Popup
                                            on='hover'
                                            trigger={<Label className='cursor-pointer w-fit-content' size='mini'><FormattedMessage id='users.invitation_pending' /></Label>}
                                        >
                                            <Popup.Content>
                                                <FormattedMessage id='users.resend_invitation.popup' />
                                            </Popup.Content>
                                        </Popup>
                                        <div data-test-id='users-email'>
                                            {user.email}
                                        </div>
                                    </div>
                                    return <Table.Row key={index}>
                                        <Table.Cell>{user.firstname}</Table.Cell>
                                        <Table.Cell>{user.lastname}</Table.Cell>
                                        <Table.Cell>{userEmail}</Table.Cell>
                                        <Table.Cell>{this.getRoleName(user.user_role_id)}</Table.Cell>
                                        <Table.Cell>
                                            <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                                                <Checkbox onChange={(evt: SyntheticEvent, item: { value: string }): void => {
                                                    this.props.handleChange(evt, item);
                                                    this.props.handleEditUpdate();
                                                }}
                                                    name='user' value={user.email} checked={user.contact} />
                                            </HasFeatureAccess>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData}>
                                                <DeactivateUser
                                                    userId={user.id}
                                                    done={this.props.reloadSupplierData}
                                                ><span className='link'><FormattedMessage id='suppliers.deactivate_user' /></span></DeactivateUser>
                                            </HasFeatureAccess>
                                        </Table.Cell>
                                    </Table.Row>;
                                })}
                            </Table.Body>
                        </Table>}
                        {!hasUsers && <InfoIcon compact text={this.props.intl.formatMessage({ id: 'suppliers.no_users' })} />}
                        <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                            <Button primary size='tiny' onClick={(): void => this.showUserDialog(true)}><FormattedMessage id='suppliers.add_user' /></Button>
                        </HasFeatureAccess>
                        <Divider />
                        <Header as='h3'><FormattedMessage id='suppliers.groups' /></Header>
                        <HasFeatureAccess type='supplier' feature={SupplierFeaturesDto.Modify} supplier={supplierData} showDisabled={true}>
                            <Groups
                                selectedGroups={supplierData.groups}
                                hideHeader={true}
                                excludeSuppliers={true}
                                handleChange={(evt, item): void => {
                                    this.props.handleChange(evt, item, this.props.handleEditUpdate);
                                }}
                            />
                        </HasFeatureAccess>
                        <Divider />
                        <Button disabled={!this.props.isDirty} positive onClick={this.props.handleEditUpdate}><FormattedMessage id='suppliers.button_save' /></Button>
                        <Divider hidden />
                        {!this.props.isDirty && <Breadcrumb>
                            <Breadcrumb.Divider icon='left angle' />
                            <Breadcrumb.Section>
                                <Link className='link noUnderline' to='/suppliers'><FormattedMessage id='suppliers.back_to_suppliers' /></Link>
                            </Breadcrumb.Section>
                        </Breadcrumb>}
                        {this.state.showCreateUser && <CreateUserModal
                            showCreateUser={this.state.showCreateUser}
                            cancel={(): void => this.showUserDialog(false)}
                            done={(): void => this.showUserDialog(false)}
                            roles={formattedRoles}
                            groups={[supplierData.primary_group]}
                            hideGroups={true}
                            locales={this.props.locales}
                        />}
                    </Segment>
                </Segment>
                <Modal
                    open={this.state.showEditModal}
                >
                    <Modal.Content>
                        <FormattedMessage id={'suppliers.edit_warning'} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={(): void => {
                            this.approveEdit();
                        }}><FormattedMessage id={'globals.confirmation.ok'} /></Button>
                        <Button onClick={(): void => {
                            this.setState({ editName: false, editNumber: false, showEditModal: false })
                        }}><FormattedMessage id={'globals.confirmation.cancel'} /></Button>
                    </Modal.Actions>
                </Modal>
            </span>;
        }
        return (<span className='editSupplier'>
            <Dimmer active={this.props.isFetching} inverted>
                <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
            </Dimmer>
            <Header as='h2'></Header>
            <Segment raised container="true">
            </Segment>
        </span>);
    }
    approveEdit(): void {
        this.setState({ editApproved: true, showEditModal: false })
    }
    editName(): void {
        this.setState({ editName: !this.state.editName, showEditModal: !this.state.editApproved && !this.state.editName });
    }
    editNumber(): void {
        this.setState({ editNumber: !this.state.editNumber, showEditModal: !this.state.editApproved && !this.state.editNumber });
    }
    showUserDialog(show: boolean): void {
        this.setState({ showCreateUser: show });
        if (!show) {
            setTimeout(() => this.props.reloadSupplierData(), 1500);
        }
    }
    componentWillUnmount(): void {
        this.setState({ dirty: false, showCreateUser: false });
    }

    getRoleName(id: string): string {
        const lang = getLocaleLanguageString(this.props.intl.locale);
        let roleIndex;
        for (roleIndex in this.props.roles) {
            const role = this.props.roles[roleIndex]
            if (role.id == id) {
                return role.name[lang] || role.name.C;
            }
        }
        return '';
    }

}
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        actions: bindActionCreators({
            createAudit,
            createProductionUnit,
        }, dispatch),
    };
}

export default injectIntl(withRouter((connect(null, mapDispatchToProps)(EditSupplier))));
