import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import DatePicker from '../../../../base/components/basic/DatePicker3';
import { getPrettyDate, getPrettyDatetime } from '../../../../base/utils';
import { InfoIcon } from '../../../globals/components/Utils';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import { isInspectionLockedOrDone } from '../../../inspections/Utils';
import { CombinedInspectionLabel } from '../../../inspections/components/combine/CombinedInspections';
import { PlainStatus } from '../../../inspections/components/edit/Status';
import QInput from '../../../inspections/components/edit/formelements/QInput';
import { InspectionTypeMarker } from '../../../inspections/components/inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../../../inspections/components/shared/SplitShipmentsMarker';
import { getOrganisationNameSelector } from '../../../users/selectors';
import { InspectionsItemType } from '../../containers/SupplierBooking';
import { SupplierBookingInspection } from '../../types';
import BookingInspectionsContextMenu from './BookingInspectionContextMenu';
import BookingCompletedLabel from './overview/BookingCompletedLabel';

type BookingInspectionLineProps = {
    inspection: SupplierBookingInspection;
    bookingId: string;
    type: 'edit' | 'view';
    cnt: number;
    hasInspectionTypes: boolean;
    isFetching: boolean;
    handleInspectionChange(d: Date, item: InspectionsItemType, id: string): void;
};

const BookingInspectionLine = (props: BookingInspectionLineProps): React.ReactElement => {
    const intl = useIntl();
    const { inspection, bookingId } = props;
    const hasInspectionTypes = true;

    const dateSet: boolean = inspection.scheduled_inspection_date !== null && inspection.scheduled_inspection_date !== undefined;
    const isDone: boolean = isInspectionLockedOrDone(inspection);
    const organisationName: string = useSelector(getOrganisationNameSelector);
    const placeholderKey: string = dateSet ? 'supplier_booking.line.date_disabled' : null;
    const prettyPlannedDate: string = getPrettyDate(inspection.scheduled_inspection_date, intl.locale);

    const hasCi = inspection.master_id;
    const hasSs = inspection.sequence_identifier && inspection.sequence_identifier.length > 0;

    const datePicker = <HasReadonlyAccess actionId='booking_date' type='inspection' inspection={inspection}>
        <DatePicker
            className={(hasCi || hasSs) && 'w-48'}
            name='booking_date'
            date={inspection.booking_date || null}
            readOnly={props.type === 'view' || dateSet || props.isFetching || false}
            handleChange={(d): void => {
                props.handleInspectionChange(d, { name: 'booking_date', value: undefined }, inspection.master_id || inspection.id);
            }}
            placeholderKey={placeholderKey}
        /></HasReadonlyAccess>;

    const datepickerItem = dateSet ? <Popup on='click' hoverable={false} hideOnScroll={true} trigger={<span>{datePicker}</span>}>
        <Popup.Content>
            <InfoIcon compact={true} inline={true} text={intl.formatMessage({ id: 'supplier_booking.line.cannot_update_booking_date_because_planned_date_is_set' }, { prettyPlannedDate })} />
            <FormattedMessage id='supplier_booking.line.cannot_update_booking_date_because_planned_date_is_set_2' values={{ organisationName }} />
        </Popup.Content>
    </Popup> : datePicker;
    return (
        <div className={props.isFetching && 'disabled'}>
            <div className='flex md:flex-row md:space-x-1 lg:space-x-2 lg:mr-8 items-center mb-3 pl-1 w-full'>
                {hasInspectionTypes && <div className={classnames('w-1/24')}>
                    <InspectionTypeMarker type='editInspection' inspections={[inspection]} />
                </div>}

                <div className='w-5/24'>
                    <QInput
                        type='text'
                        name='item_number'
                        value={inspection.item_number}
                        placeholder={''}
                        handleBlur={(): void => null}
                        handleFocus={(): void => null}
                        disabled={true}
                        label={inspection.involved_in_split_shipment && <div className=''><SplitShipmentsMarker inspection={inspection} /></div> || null}
                    />
                </div>
                <div className={classnames('w-6/24')}>
                    <QInput
                        type='text'
                        name='item_name'
                        value={inspection.item_name}
                        placeholder={''}
                        handleBlur={(): void => null}
                        handleFocus={(): void => null}
                        disabled={true}
                    />
                </div>
                <div className={classnames('w-2/24')}>
                    <QInput
                        type='text'
                        name='total_quantity'
                        value={inspection.total_quantity}
                        placeholder={''}
                        handleBlur={(): void => null}
                        handleFocus={(): void => null}
                        readOnly={true}
                    />
                </div>
                <div className={classnames('w-4/24 items-center flex')}>
                    {(hasCi || hasSs) && <div className='pl-1 pr-2'>
                        <SplitShipmentsMarker inspection={inspection} />
                        <CombinedInspectionLabel inspection={inspection} type='short' />
                    </div>}
                    {!isDone && datepickerItem}
                    {isDone && <BookingCompletedLabel className='w-full' />}
                </div>
                <div className={classnames('w-4/24 flex items-center')}>
                    {!isDone &&
                        <HasReadonlyAccess actionId='scheduled_inspection_date' type='inspection' inspection={inspection}>
                            <DatePicker
                                name='scheduled_inspection_date'
                                date={inspection.scheduled_inspection_date}
                                handleChange={(d): void => {
                                    props.handleInspectionChange(d, { name: 'scheduled_inspection_date', value: undefined }, inspection.master_id || inspection.id);
                                }}
                                disabled={props.isFetching}
                            />
                        </HasReadonlyAccess>
                    }
                    {isDone && <BookingCompletedLabel className='w-full' />}
                </div>
                <div className='flex w-2/24 items-center space-x-1'>
                    <PlainStatus classNames='h-10 w-5/6' inspection={inspection} />
                    <div className='w-1/6'><BookingInspectionsContextMenu bookingId={bookingId} inspection={inspection} /></div>
                </div>

            </div>
            {!inspection.confirmed && inspection.must_confirm && <div className='flex justify-end mr-2'><InfoIcon inline compact={true} text={intl.formatMessage({ id: 'supplier_booking.line.please_confirm_booking' })} color='red' /></div>}
            {inspection.last_confirmed_at && <div className='flex justify-end mr-4 pb-3'>
                {inspection.last_confirmed_at && <span><Icon name='check circle' color='green' /></span>}
                {inspection.last_confirmed_at && intl.formatMessage({ id: 'supplier_booking.last_confirmed_date' }, { date: getPrettyDatetime(inspection.last_confirmed_at) })}
            </div>}
        </div >
    );
};

export default BookingInspectionLine;
