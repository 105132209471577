import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import InvalidFieldMessage from '../../../base/ui/components/labels/InvalidFieldMessage';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';

type Props = {
    className?: string;
    productName?: string;
    productNumber?: string;
    handleProductUpdate(string, any): void;
    save(): void;
    cancelCreate(): void;
};

const ComplianceCreateProductModal = (props: Props): React.ReactElement => {
    const { className, handleProductUpdate, cancelCreate } = props;
    const intl = useIntl();
    const [productNumber, setProductNumber] = useState(props.productNumber);
    const [productName, setProductName] = useState(props.productName);
    const [errorMessage, setErrorMessage] = useState({ number: null, name: null });
    const checkValidation = () => {
        const numberValid = !isUndefinedOrNullOrEmptyString(productNumber);
        const nameValid = !isUndefinedOrNullOrEmptyString(productName);
        setErrorMessage({
            ...errorMessage,
            number: numberValid ? null : intl.formatMessage({ id: 'compliance.product_create.error.please_set_product_number' }),
            name: nameValid ? null : intl.formatMessage({ id: 'compliance.product_create.error.please_set_product_name' }),
        })
        return numberValid && nameValid;
    }
    const save = () => {
        if (checkValidation()) {
            props.save();
            setErrorMessage({ number: null, name: null });
        }
    }
    const createModal = <Modal
        onOpenChange={(c) => !c && cancelCreate()}
        actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.product_create.label.create_product' }), stayOpen: true, event: save, /* disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) */ },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: cancelCreate },
        ]}
        isOpen={true}
        width={500}
        header='New product'
    >
        <div className='flex flex-col p-6 gap-y-4'>
            <div>
                <Field
                    label={{ text: intl.formatMessage({ id: 'compliance.product_create.product_number' }) }}
                    value={productNumber}
                    onChange={setProductNumber}
                    onBlur={() => {
                        handleProductUpdate('product_number', productNumber);
                        checkValidation();
                    }}
                    errorMessage={errorMessage.number}
                />
                <InvalidFieldMessage className='' message={errorMessage.number} />
            </div>
            <div>
                <Field
                    value={productName}
                    label={{ text: intl.formatMessage({ id: 'compliance.product_create.product_name' }) }}
                    onChange={setProductName}
                    onBlur={() => {
                        handleProductUpdate('product_name', productName);
                        checkValidation();
                    }}
                    errorMessage={errorMessage.name}
                />
                <InvalidFieldMessage className='' message={errorMessage.name} />
            </div>
        </div>
    </Modal >

    return createModal;
}
export default ComplianceCreateProductModal;
