import { CorrectiveAction, CorrectiveActionHistory, PaginatedCorrectiveActions } from '../../backend_api/models';
import { byId } from '../../base/types';

export type CorrectiveActionState = {
    correctiveAction: CorrectiveAction,
    correctiveActions: PaginatedCorrectiveActions,
    correctiveActionsOnInspection: CorrectiveAction[],
    inspectionsCorrectiveActions: PaginatedCorrectiveActions,
    correctiveActionHistory: CorrectiveActionHistory,
    auditActions: byId<CorrectiveAction[]>,
    isFetchingHistory: boolean,
    isFetching: boolean,
    isUpdating: boolean,
    listFilterDate: string
};

export type CorrectiveActionCreationObject = {
    id: string,
    approver_id: string,
    creator_id: string,
    description: string,
    finding: string,
    inspection_links: string[],
    responsible_id: string,
    audit_links: string[],
}

export type CorrectiveActionsListMeta = {
    per_page: number;
    start: number;
    total: number;
    isFetching: boolean;
};

export enum CorrectiveActionRelation {
    Audit = 'audit',
    Inspection = 'inspection',
}
