import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { ItemType } from '../types';


export interface ListItemProps {
    index: number;
    listItem: any;
    moveItem(dragIndex, hoverIndex);
    drop(itemId: number);
    enabledDrag: boolean;
}

interface DragItem {
    index: number;
    type: string;
    id: number;
}

const ListItemWrapper = (props: ListItemProps): React.ReactElement => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemType.LIST_ITEM,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            props.moveItem(dragIndex, hoverIndex)

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType.LIST_ITEM, item: { type: ItemType.LIST_ITEM, index: props.index, id: props.index },
        canDrag: props.enabledDrag,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end(item: DragItem) {
            props.drop(item.id);
        }
    })

    drag(drop(ref))
    const opacity = isDragging ? 0 : 1;
    return (
        <div ref={ref} style={{ opacity }} >
            {props.listItem}
        </div>
    )
}
export default ListItemWrapper;