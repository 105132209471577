import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Divider, DropdownProps, Header, Icon } from 'semantic-ui-react';
import { CompleteApprover } from '../../../backend_api/models/CompleteApprover';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import BaseComponent from '../../../base/components/BaseComponent';
import { AppState, DropdownValue } from '../../../base/types';
import { handleChange } from '../../inspections/actions/editInspectionActions';
import { Inspection } from '../../inspections/types';
import { getOrderUsersSelector, getUsersById } from '../../users/selectors';
import { ById } from '../../users/types';
import { addApprover, getUsersThatAreNotApprovers, hideApprovers, removeApprovalStep, removeApprover, saveApprovalStep, setupApprovalStep, showApprovers } from '../handlers';
import { InitialState } from '../types';
import ApprovalItem from './ApprovalItem';
import ApproverSelector from './ApproverSelector';

export interface OwnProps {
    inspection: Inspection;
    disabled?: boolean;
    handleChange: typeof handleChange;
}
type ApprovalStateProps = {
    users?: ListUsersUser[];
    usersById?: ById<ListUsersUser>;
};
const initialState = InitialState;
type State = Readonly<typeof initialState>;

export type ApprovalProps = OwnProps & ApprovalStateProps;
class Approval extends BaseComponent<ApprovalProps, State> {

    constructor(props: ApprovalProps, state: State) {
        super(props, state);
        this.state = initialState;
        this.addApprover = this.addApprover.bind(this);
        this.saveApprovalStep = this.saveApprovalStep.bind(this);
        this.removeApprovalStep = this.removeApprovalStep.bind(this);
        this.removeApprover = this.removeApprover.bind(this);
        this.showApprovers = this.showApprovers.bind(this);
        this.cancelSetupApprovalStep = this.cancelSetupApprovalStep.bind(this);
    }
    public doRender(): React.ReactElement {
        const setupStep = this.state.setupStep;
        const currentLevel = this.state.approvalFlow.length || 0;
        const approvalFlow = this.state.approvalFlow || [];
        const disabled = this.props.disabled;

        return (
            <div className='flex flex-col'>
                <Header as='h4'><FormattedMessage id='approval.approvers' /></Header>
                {approvalFlow.map((flow: CompleteApprover[], i: number) => {
                    const currentApprovers = flow.map((approver: CompleteApprover) => approver.intended.id);
                    const users2 = getUsersThatAreNotApprovers(currentApprovers, this.props.users);
                    const header = (<Header as='h5'>
                        <FormattedMessage id='approval.approver' /> {i + 1}</Header>);
                    const approvers =
                        (<div className='flex'>
                            <div className='flex flex-wrap w-full'>{flow && flow.map((approver: CompleteApprover) => {
                                return (<ApprovalItem key={'approvalItem_' + i} handleRemoveApprover={(): void => this.removeApprover(i, approver.intended.id)} user={approver.intended} />);
                            })}
                                {users2.length > 0 && !disabled && <div className='w-full mt-4 pb-2'><Button className='add pb-2' size='small' disabled={this.state.showAddApprovers[i]} onClick={(): void => this.showApprovers(i)}>
                                    <Icon className='icon icon-add' /><FormattedMessage id='approval.add_approver' />
                                </Button></div>}
                                {users2.length === 0 && <div className='py-2'><FormattedMessage id='approval.no_more_users_to_add' /></div>}
                                {this.state.showAddApprovers[i] && <div className='flex w-full'>
                                    <ApproverSelector
                                        users={users2}
                                        handleChange={(data: DropdownProps): void =>  this.addApprover(data.value, i)}
                                        handleCancel={(): void => this.hideApprovers(i)}
                                        handleSave={(): void => this.saveApprovalStep(i)}
                                        showSelector={true}
                                        showEmailInSelector={true}
                                        hasSelection={this.state.selectedApprovers[i] && this.state.selectedApprovers[i].length > 0}
                                        value={this.state.selectedApprovers[i] as unknown as string[]}
                                        disabled={disabled}
                                        className='flex w-1/2'
                                    /></div>}
                            </div>
                        </div>);
                    return [header, approvers];
                })}
                {approvalFlow.length === 0 && <div className='noApproversLabel'><FormattedMessage id='approval.no_approvers_set' /></div>}
                <div className='mt-4'>
                    {approvalFlow.length > 0 && !disabled && <Button disabled={disabled} onClick={(): void => this.removeApprovalStep(currentLevel)} size='small'>
                        <Icon className='icon remove red' /><FormattedMessage id='approval.delete_approval_step' /> {approvalFlow.length}
                    </Button>}
                    {!disabled && <Button onClick={(): void => this.setupApprovalStep()} className='add' size='small'>
                        <Icon className='icon icon-add' /><FormattedMessage id='approval.add_approver' /> {currentLevel + 1}
                    </Button>}
                </div>
                {setupStep && <div className='flex w-full flex-col'>
                    <div className='py-4'><FormattedMessage id='approval.please_select_an_approver_each_step_must_have_at_least_one_approver' /></div>
                    <ApproverSelector
                        users={getUsersThatAreNotApprovers([], this.props.users)}
                        handleChange={(data: DropdownProps): void => this.addApprover(data.value, currentLevel)}
                        handleCancel={(): void => this.hideApprovers(currentLevel)}
                        handleSave={(): void => this.saveApprovalStep(currentLevel)}
                        showSelector={true}
                        showEmailInSelector={true}
                        hasSelection={this.state.selectedApprovers[currentLevel] && this.state.selectedApprovers[currentLevel].length > 0}
                        value={this.state.selectedApprovers[currentLevel] as unknown as string[]}
                        className='flex w-1/2'  
                    />
                </div>}
                <Divider hidden />
            </div>
        );
    }

    public componentDidMount(): void {
        const approvalFlow = this.props.inspection.approval_flow;
        this.setState({ approvalFlow: approvalFlow || [], currentLevel: approvalFlow && approvalFlow.length || 0 });
    }

    public componentWillReceiveProps(nextProps: ApprovalProps): void {
        if (nextProps.inspection.approval_flow) {
            this.setState({ approvalFlow: nextProps.inspection.approval_flow || [], currentLevel: nextProps.inspection.approval_flow.length || 0 });
        }
    }

    private setupApprovalStep(): void {
        this.setState(setupApprovalStep(this.state.currentLevel));
    }
    private cancelSetupApprovalStep(): void {
        this.setState({ setupStep: false });
    }
    private showApprovers(level): void {
        this.setState(showApprovers(level));
    }
    private hideApprovers(level): void {
        this.setState({ setupStep: false });
        this.setState(hideApprovers(level));
    }

    private addApprover(value: DropdownValue, level: number): void {
        this.setState(addApprover(level, value));
    }

    private saveApprovalStep(step: number): void {
        this.setState(saveApprovalStep(this.props.inspection.inspection_id, step));
    }

    private removeApprover(level: number, approverId: string): void {
        this.setState(removeApprover(this.props.inspection.inspection_id, level, approverId));
    }

    private removeApprovalStep(level: number): void {
        this.setState(removeApprovalStep(this.props.inspection.inspection_id, level));
    }
}

const mapStateToProps = (state: AppState): ApprovalStateProps => {
    return {
        usersById: getUsersById(state),
        users: getOrderUsersSelector(state),
    };
};

export default connect<ApprovalStateProps, any, OwnProps>(mapStateToProps, null)(Approval);
