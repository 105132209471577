import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Icon, TextArea } from 'semantic-ui-react';

type Props = {
    handleNewComment(comment: string);
}
const CommentInput = (props: Props) => {
    const intl = useIntl();
    const [newComment, setNewComment] = useState<string>(null);
    return <Form className='writeComment'>
        <Form.Field>
            <TextArea
                autoFocus={true}
                value={newComment}
                onChange={(evt, item) => setNewComment(item.value as string)}
                placeholder={intl.formatMessage({ id: 'comments.write_a_comment' })} />
        </Form.Field>
        <Form.Field>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Button
                    data-test-id='btn-send-comment'
                    size={'small'}
                    primary
                    onClick={() => {
                        props.handleNewComment(newComment);
                        setTimeout(() => setNewComment(''), 500);
                    }}
                >
                    {intl.formatMessage({ id: 'comments.send' })}
                    <Icon name='chevron right' />
                </Button>
            </div>
        </Form.Field>
    </Form>
}

export default CommentInput;