import ky, { ResponsePromise } from 'ky';
import qs from 'query-string';
import queue from 'queue';
import { globalStore } from '../containers/Root';
import { loginExpired } from '../modules/authentication/authenticationSlice';
import messages from '../modules/messages';
import history from '../store/history';
import { networkBusy } from './baseSlice';
import { API_PROXY_PATH_TEMPORARY_USER, SERVICES_PREFIX, SERVICES_PREFIX_COMPLIANCE, SERVICES_PREFIX_V1 } from './config';
import { RequestError } from './types';

const sendErrorMessage = messages.actions.sendErrorMessage;
const q = queue({ concurrency: 1, autostart: true });
let requstTimer = null;
const TIMEOUT_IN_MS = 300000;

export type RequestType = {
    endpoint: string;
    options?: {
        v1?: boolean;
        noAuth?: boolean;
        method?: string;
        body?: any;
        headers?: {
            Authorization?: string;
            Range?: string;
            'Content-Type'?: string;
        };
        timeout?: number;
        wait?: {
            messages: Array<{
                text: string;
                wait: number;
            }>;
        };
        retries?: number;
        rawResponse?: boolean;
        token?: string;
    };
    json: boolean;
};
export const request = (endpoint: RequestType['endpoint'], options?: RequestType['options']): Promise<unknown> => {
    clearTimeout(requstTimer);
    globalStore.dispatch(networkBusy(true));
    endpoint = getEndpoint(endpoint, options && options.v1 || false);
    options = getOptions(options);
    const opts = Object.assign({}, options, { throwHttpErrors: true, retries });
    if (isQueueMethod(options.method)) {
        return new Promise((resolve) => {
            q.push(async () => {
                const res = getRequestPromise(endpoint, opts);
                resolve(res);
            });
        })
    } else {
        return new Promise((resolve) => {
            const res = getRequestPromise(endpoint, opts);
            resolve(res);
        });
    }
};

const getRequestPromise = (endpoint: RequestType['endpoint'], options: RequestType['options']): Promise<unknown> => {
    return new Promise((resolve, reject) => {
        const method = options && options.method || 'get';
        ky[method.toLowerCase()](endpoint, options)
            .then((response: ResponsePromise) => {
                updateRequestTimer();
                const body = response.json();
                resolve(body);
                return body;
            })
            .catch((e) => {
                if (e.toString() === 'TypeError: Failed to fetch') {
                    reject(e);
                    return e;
                }
                getError(e).then((error) => {
                    reject(error);
                    return error;
                });
            });

    });
}
const getError = async (e): Promise<RequestError> => {
    const errorDetail = await e.response.json();
    const error = { error: true, status: e.response && e.response.status, errorText: e.response && e.response.statusText, errorDetail: errorDetail.error };
    if (error.status === 303) {
        error.errorDetail = { detail: errorDetail.organization_id };
    }
    if (error.status === 498) { // HTTP error status code 498
        updateRequestTimer();
        globalStore.dispatch(loginExpired());
        
    } else {
        updateRequestTimer();
    }
    return error;
};

const updateRequestTimer = (): void => {
    clearTimeout(requstTimer);
    requstTimer = setTimeout(() => { globalStore.dispatch(networkBusy(false)); }, 1000);
};

const isQueueMethod = (method: string): boolean => {
    if (typeof method === 'string') {
        const queueMethods = ['post', 'put'];
        return queueMethods.includes(method.toLowerCase());
    }
    return false;
}

const retries = (retry): number => {
    return retry === 20 ? 0 : 500;
};

const getOptions = (options: RequestType['options']): RequestType['options'] => {
    if (!options) {
        options = {};
    }
    if (!options.headers) {
        options.headers = {};
    }
    if (!options.timeout) {
        options.timeout = TIMEOUT_IN_MS;
    }
    if (options.headers) {
        if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }
        if (!options.noAuth && !options.headers.Authorization) {
            let hasToken = false;
            let urlToken;
            const params = qs.parse(history.location.search);
            if (params && params.token) {
                urlToken = params.token;
                if (sessionStorage.getItem('url_token') === null || sessionStorage.getItem('url_token') !== urlToken) {
                    sessionStorage.setItem('url_token', urlToken.toString());
                    if (params.redir) {
                        window.location.href = params.redir.toString();
                    } else {
                        window.location.reload();
                    }
                } else {
                    if (params.redir) {
                        window.location.href = params.redir.toString();
                    }
                }
                hasToken = true;
                options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('url_token');
            }
            if (hasToken && sessionStorage.getItem('id_token') !== null) {
                options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('url_token');
            }
            if (hasToken && sessionStorage.getItem('id_token') === null) {
                options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('url_token');
            }
            if (!hasToken && sessionStorage.getItem('id_token') !== null) {
                options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('id_token');
            }
            if (!hasToken && sessionStorage.getItem('id_token') === null && sessionStorage.getItem('url_token') !== null) {
                options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('url_token');
            }
        }
    }
    return options;
};

const getEndpoint = (endpoint: string, isV1: boolean): string => {
    if ((endpoint.indexOf('http://') === -1) &&
        (endpoint.indexOf('https://') === -1) &&
        // eslint-disable-next-line no-undef
        (process.env.PDF_GENERATOR_PATH === '' || (endpoint.indexOf(process.env.PDF_GENERATOR_PATH) === -1))) {
        if (isV1) {
            endpoint = SERVICES_PREFIX_V1 + '/' + endpoint;
        } else {
            endpoint = SERVICES_PREFIX + '/' + endpoint;
        }
    }
    return endpoint;
};

const getComplianceEndpoint = (endpoint: string): string => {
    if ((endpoint.indexOf('http://') === -1) &&
        (endpoint.indexOf('https://') === -1)) {
        endpoint = SERVICES_PREFIX_COMPLIANCE + '/' + endpoint;
    }
    return endpoint;
};

const getTemporaryUserEndpoint = (endpoint: string): string => {
    if ((endpoint.indexOf('http://') === -1) &&
        (endpoint.indexOf('https://') === -1)) {
        endpoint = API_PROXY_PATH_TEMPORARY_USER + "/" + endpoint;
    }
    return endpoint;
};

export const request2 = async (endpoint: RequestType['endpoint'], options?): Promise<Response> => {
    const method = options && options.method || 'get';
    endpoint = getEndpoint(endpoint, options && options.v1 || false)
    options = getOptions(options);
    const opts = Object.assign({}, options, { throwHttpErrors: true, retries });
    const getReq = async (): Promise<Response> => {
        try {
            return await ky[method.toLowerCase()](endpoint, opts)
        } catch (err) {
            const err2: Response = await err.response;
            if (!err2) {
                return err;
            }
            return err2;
        }
    }
    if (isQueueMethod(options.method)) {
        return new Promise((resolve) => {
            q.push(async () => {
                const res = getReq();
                resolve(res);
                return res;
            });
        })
    } else {
        return getReq();
    }
};

export const requestCompliance = async (endpoint: RequestType['endpoint'], options?): Promise<Response> => {
    const method = options && options.method || 'get';
    endpoint = getComplianceEndpoint(endpoint)
    options = getOptions(options);
    const opts = Object.assign({}, options, { throwHttpErrors: true, retries });
    const getReq = async (): Promise<Response> => {
        try {
            return await ky[method.toLowerCase()](endpoint, opts)
        } catch (err) {
            const err2: Response = await err.response;
            if (!err2) {
                return err;
            }
            return err2;
        }
    }
    if (isQueueMethod(options.method)) {
        return new Promise((resolve) => {
            q.push(async () => {
                const res = getReq();
                resolve(res);
                return res;
            });
        })
    } else {
        return getReq();
    }
};

export const sendTemporaryUserRequest = async (endpoint: RequestType['endpoint'], options?): Promise<Response> => {
    const method = options && options.method || 'get';
    endpoint = getTemporaryUserEndpoint(endpoint);
    options = getOptions(options);
    const opts = Object.assign({}, options, { throwHttpErrors: true, retries });
    const getReq = async (): Promise<Response> => {
        try {
            return await ky[method.toLowerCase()](endpoint, opts)
        } catch (err) {
            const err2: Response = await err.response;
            if (!err2) {
                return err;
            }
            return err2;
        }
    }
    if (isQueueMethod(options.method)) {
        return new Promise((resolve) => {
            q.push(async () => {
                const res = getReq();
                resolve(res);
                return res;
            });
        })
    } else {
        return getReq();
    }
};

export type RequestResponse = {
    data?: any;
    error?: boolean;
    status?: number;
    errorText?: string;
};
