import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import logoImg from '../../../../images/logo_only.png';
import { setSiteType } from '../../../base/baseSlice';
import { MAIN_WEBSITE_URL } from '../../../base/config';
import { getRedirectPath } from '../../../base/routes';
import { getFeaturesSelector } from '../../../base/selectors';
import { useAppDispatch } from '../../../store';
import { TEMPORARY_USER_MODE } from '../../authentication/authenticationSlice';
import { IsSavingInfo } from '../../globals/components/Utils';
import { getListViewDefaultDateSelector } from '../../inspections/slices/inspectionsListSlice';
import { HasFeature } from '../../profile/containers';

type LogoProps = {
    isAuthenticated: boolean;
    isSaving: boolean;
}
const Logo = (props: LogoProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const date = useSelector(getListViewDefaultDateSelector);
    const features = useSelector(getFeaturesSelector);
    const temporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE) || location.pathname.includes("/direct/login/");

    const getUrl = (): string => {
        if (props.isAuthenticated) {
            return getRedirectPath(features, date);
        }
        return MAIN_WEBSITE_URL;

    }
    const logo = <img src={logoImg} />;
    if (props.isSaving) {
        return (
            <IsSavingInfo
                on='click'
                trigger={<div>{
                    <div className='logo'>{logo}</div>
                }</div>}
                position='bottom center'
            />
        );
    }
    if (props.isAuthenticated) {
        return (<div className='logo'>
            {
                temporaryUserMode ? logo :
                <Link to={getUrl()}>
                    {logo}
                </Link>
            }
            <HasFeature feature='t_product_compliance'>
                <HasFeature feature='u_enable_compliance'>
                    <Menu secondary className='top mt-4' size='large'>
                        <Menu.Menu position='right' className='rm item' key='rm' >
                            <Dropdown>
                                <Dropdown.Menu style={{ marginTop: '10px' }}>
                                    <Dropdown.Item className='bg-yellow-300'>
                                        <Link className='' onClick={() => dispatch(setSiteType('quality'))} to='/'>Quality</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item className='p-0 text-primary'>
                                        <Link className='' onClick={() => dispatch(setSiteType('compliance'))} to='/compliance'>Compliance</Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu>
                </HasFeature>
            </HasFeature>
        </div >);
    }
    return (<div className='logo'><a href={getUrl()}>
        {logo}
    </a>
    </div>);
};
export default Logo;
