import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../base/types';
import { BulkApprovalFlow } from '../inspections/types';
import { ApprovalFlow, CustomConclusion } from './types';

const approvalFlowSelector = (state: AppState, inspectionId: string): ApprovalFlow => {
    if (state.app.approval[inspectionId] !== undefined) {
        return state.app.approval[inspectionId].flow;
    }
    return null;
};

export const getApprovalFlow = createSelector(
    [approvalFlowSelector],
    (approvalFlow) => approvalFlow,
);

const bulkApprovalFlowSelector = (state: AppState): BulkApprovalFlow => {
    if (state.app.approval.bulkApprovalFlow) {
        return state.app.approval.bulkApprovalFlow.flow;
    }
    return null;
};

export const getBulkApprovalFlowSelector = createSelector(
    [bulkApprovalFlowSelector],
    (flow) => flow,
);

const getApprovalConclusionsSelector = (state: AppState): CustomConclusion[] => state.app.approval.conclusions;
export const getApprovalConclusions = createSelector(
    [getApprovalConclusionsSelector],
    (c) => c,
);
