import { DashboardById, SisenseContextProvider, ThemeProvider } from "@sisense/sdk-ui";
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';
import { twMerge } from '../../../../base/utils';

type Props = {
    className?: string;
};

export const SisenseUrl = "https://qarma.sisensepoc.com";

const SisenseDashboard = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const themeId = qs.parse(location?.search)?.themeId as string || '66d9b083726c81003316dea8';
    const dashboardId = qs.parse(location?.search)?.dashboardId as string || '66dfea86cd6a9d00339d2522';
    
    return (
        <div className={twMerge('', className)}>
            <div className="p-8">
                <h1>Testing embedded Sisense Dashboard</h1>

                <SisenseContextProvider url={SisenseUrl}  ssoEnabled={true} enableSilentPreAuth={true}>
                    <ThemeProvider theme={themeId}>
                        <DashboardById dashboardOid={dashboardId} />
                    </ThemeProvider>
                </SisenseContextProvider>
            </div>
        </div>);
}
export default SisenseDashboard;
