import React, { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { Attachment as AttachmentType } from '../types';
import Attachment from './Attachment';

interface AttachmentsListProps {
    disabled: boolean;
    showEmpty?: boolean;
    inline?: boolean;
    showDelete?: boolean;
    loading?: boolean;
    showLinks?: boolean;
    attachments: AttachmentType[];
    removeAttachment(url: string);
}
const AttachmentsList = (props: AttachmentsListProps): React.ReactElement => {
    const { showEmpty = false, inline = false, showDelete = true, showLinks = true } = props;
    if (props.attachments && props.attachments.length > 0) {
        const items = props.attachments.map((attachment, i) => {
            return <Attachment
                attachment={attachment}
                removeAttachment={props.removeAttachment}
                inline={inline}
                disabled={props.disabled}
                key={i}
                showDelete={showDelete}
                showLink={showLinks}
            />;
        });
        return (
            <Table basic className='attachmentsList' divided verticalAlign='middle'>
                <ReactCSSTransitionGroup component='tbody'
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}
                    transitionLeave={false}
                    transitionName={{
                        enter: 'animated',
                        enterActive: 'bounceIn',
                        leave: 'animated',
                        leaveActive: 'bounceOut',
                    }}>
                    {items}
                </ReactCSSTransitionGroup>
            </Table>
        );
    }
    if (!showEmpty) {
        return (<span>{props.loading && <span><FormattedMessage id='attachments.getting_attachments' /></span>}
            {!props.loading && null}</span>);
    }
    return (
        <Fragment>
            {props.loading && <span><FormattedMessage id='attachments.getting_attachments' /></span>}
            {!props.loading && <span><FormattedMessage id='attachments.no_attachments' /></span>}
        </Fragment >
    );
};

export default AttachmentsList;
