import { UploadRequest } from '@navjobs/upload';
import { SERVICES_PREFIX } from '../../../base/config';

export type AttachmentResponse = {
    mimetype: string;
    name: string;
    size: number;
    type: string;
    url: string;
    error?: boolean;
}

export const uploadImage = (
    imageFile: any,
    setProgress,
    doneCallback: (error: any, response: AttachmentResponse) => void) => {

    return (): void => {
        const endpoint = SERVICES_PREFIX + '/upload/generic/' + encodeURIComponent(imageFile.name);
        const token = sessionStorage.getItem('id_token') || sessionStorage.getItem('url_token');
        UploadRequest(
            {
                request: {
                    url: endpoint,
                    headers: {
                        'Content-Type': imageFile.type,
                        'Authorization': 'Bearer ' + token,
                    },
                },
                files: [imageFile],
                progress: (progress) => {
                    setProgress(imageFile, progress);
                },
            },
        ).then((data: { response: AttachmentResponse; error: any }) => {

            // do not keep other fields, if there are any.
            const attachment: AttachmentResponse = {
                mimetype: data.response.mimetype,
                name: data.response.name,
                size: data.response.size,
                type: data.response.type,
                url: data.response.url,
            }

            doneCallback(data.error, attachment);
        });
    };
};