import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Audit } from '../../../backend_api/models/Audit';
import LoadMore from '../../../base/components/LoadMore';
import Loader from '../../../base/components/Loader';
import { DEFAULT_LIMIT_LIST_SIZE } from '../../../base/config';
import { getLocationEntry, isUndefinedOrNull, urlParamsToArray } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { getAuditChecklists } from '../../checklists/actions/actions';
import { getAuditsLoadingSelector, getPaginatedAuditListSelector } from '../selectors/auditSelectors';
import { PaginatedAudits, getAuditList } from '../slices/auditSlice';
import BasicAuditList from './BasicAuditList';

type AuditListProps = {
    supplierId?: string;
    hideHeading?: boolean;
    isInline?: boolean;
    audits?: PaginatedAudits;
    onUpdate?();
};

const AuditList = (props: AuditListProps): React.ReactElement => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const auditListData = !isUndefinedOrNull(props.audits) ? props.audits : useSelector(getPaginatedAuditListSelector);
    const [auditList, setAuditList] = useState<Audit[]>(auditListData.audits);
    const loading = useSelector(getAuditsLoadingSelector);
    const [total, setTotal] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(DEFAULT_LIMIT_LIST_SIZE);
    const [offset, setOffset] = useState<number>(0);
    const [status, setStatus] = useState<string>(getLocationEntry(location, 'status')[0]);
        
    useEffect(() => {
        if (auditListData.pagination) {
            setOffset(auditListData.pagination.offset);
            setLimit(auditListData.pagination.limit);
            setTotal(auditListData.pagination.total);
            setCount(parseInt(auditListData.pagination.limit as unknown as string, 10) + parseInt(auditListData.pagination.offset as unknown as string, 10));
        }
    }, [auditListData]);

    useEffect(() => {
        dispatch(getAuditChecklists());
        scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const _status = getLocationEntry(location, 'status')[0];
        let _limit = limit;
        let _offset = offset;
        if (history.action === 'PUSH' || history.action === 'REPLACE' || history.action === 'POP') {
            setStatus(_status);
            setOffset(0);
            setLimit(DEFAULT_LIMIT_LIST_SIZE);
            _limit = DEFAULT_LIMIT_LIST_SIZE;
            _offset = 0;
            dispatch(getAuditList({ supplierId: props.supplierId, filters: urlParamsToArray(location.search), limit: _limit, offset: _offset }));
        }
    }, [history.location.search]);
    useEffect(() => {
        setAuditList(auditListData.audits);
    }, [auditListData]);
    return (
        <Fragment>
            <Loader active={loading}>
                <BasicAuditList audits={auditListData.audits} className='border border-b-0 mb-0' />
            </Loader>
            {total > 0 && <LoadMore className='border border-t-0'
                disabled={loading}
                onLoadMore={(limit: number, offset: number) => {
                    dispatch(getAuditList({ supplierId: props.supplierId, filters: urlParamsToArray(location.search), limit, offset }))
                }}
                chunk={limit}
                total={total}
                count={count}
            />}
        </Fragment >);
}
export default AuditList;
