import { DetailedSupplier } from '../../backend_api/models';
import { RequestError } from '../../base/types';
import { ProductionUnit, Supplier, SupplierBooking, SupplierData } from './types';

export const GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS = 'GET_SUPPLIERS_AND_PRODUCTION_UNIT_RELATIONS'
export type GET_SUPPLIER_AND_PRODUCITON_UNIT_RELATIONS = {
    // [supplier_id, production_unit_id][]
    relations: [string, string][]
}

export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export type GET_SUPPLIERS = {
    suppliers: Supplier[];
};

export const GET_SUPPLIER_DATA = 'GET_SUPPLIER_DATA';
export type GET_SUPPLIER_DATA = {
    supplierData: SupplierData;
    supplierId: string;
};

export const GET_SUPPLIER_DATA_REQUEST = 'GET_SUPPLIER_DATA_REQUEST';

export const GET_SUPPLIER_BOOKING_REQUEST = 'GET_SUPPLIER_BOOKING_REQUEST';
export type GET_SUPPLIER_BOOKING_REQUEST = {
    orderId: string;
    isFetching: boolean;
};

export const GET_SUPPLIER_BOOKING_SUCCESS = 'GET_SUPPLIER_BOOKING_SUCCESS';
export type GET_SUPPLIER_BOOKING_SUCCESS = {
    orderId: string;
    bookingData: SupplierBooking;
};

export const GET_SUPPLIER_BOOKING_FAILURE = 'GET_SUPPLIER_BOOKING_FAILURE';
export type GET_SUPPLIER_BOOKING_FAILURE = {
    orderId: string;
    error: RequestError;
    isFetching: boolean;
};

export const UPDATE_SUPPLIER_BOOKING_REQUEST = 'UPDATE_SUPPLIER_BOOKING_REQUEST';
export type UPDATE_SUPPLIER_BOOKING_REQUEST = {
    orderId: string;
    bookingData: SupplierBooking;
};

export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export type CREATE_SUPPLIER = {
    supplierData: DetailedSupplier;
    suppliers: DetailedSupplier[];
};

export const CREATE_PRODUCTION_UNIT = 'CREATE_PRODUCTION_UNIT';
export type CREATE_PRODUCTION_UNIT = {
    productionUnit: ProductionUnit;
    productionUnits: ProductionUnit[];
    supplierId: string;
};

export const GET_PRODUCTION_UNITS_SUCCESS = 'GET_PRODUCTION_UNITS_SUCCESS';
export type GET_PRODUCTION_UNITS_SUCCESS = {
    productionUnits: ProductionUnit[];
    supplierId: string;
    isList: boolean;
};

export const SET_INSPECTION_SUPPLIER_ID = 'SET_INSPECTION_SUPPLIER_ID';
export type SET_INSPECTION_SUPPLIER_ID = {
    supplierId: string;
    inspectionId: string;
};

export const SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN = 'SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN';
export type SET_INSPECTION_PRODUCTION_UNIT_TO_BE_OVERWRITTEN = {
    overwrite: boolean;
};

export const SET_CONFIRM_SUPPLIER_DELETE = 'SET_CONFIRM_SUPPLIER_DELETE';
export type SET_CONFIRM_SUPPLIER_DELETE = {
    confirm: boolean;
    supplierId?: string;
};

export const SET_CONFIRM_PRODUCTION_UNIT_DELETE = 'SET_CONFIRM_PRODUCTION_UNIT_DELETE';
export type SET_CONFIRM_PRODUCTION_UNIT_DELETE = {
    confirm: boolean;
    productionUnitId?: string;
    supplierId?: string;
};

export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST';

export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export type GET_BOOKINGS_SUCCESS = {
    data: any;
    isFirst: boolean;
};

export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';
export type GET_BOOKINGS_FAILURE = {
    error: RequestError;
};

export const SET_BOOKING_DEFAULT_DATE = 'SET_BOOKING_DEFAULT_DATE';
export type SET_BOOKING_DEFAULT_DATE = {
    date: string;
};