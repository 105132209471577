/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.7 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditReportCustomFieldsDate
 */
export interface AuditReportCustomFieldsDate {
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDate
     */
    date: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDate
     */
    previous_date: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsDate
     */
    type: AuditReportCustomFieldsDateTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsDateTypeEnum {
    Date = 'Date'
}

export function AuditReportCustomFieldsDateFromJSON(json: any): AuditReportCustomFieldsDate {
    return AuditReportCustomFieldsDateFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'previous_date': json['previous_date'],
        'type': json['type'],
    };
}

export function AuditReportCustomFieldsDateToJSON(value?: AuditReportCustomFieldsDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'previous_date': value.previous_date,
        'type': value.type,
    };
}


