export const NAME = 'attachments';

export const GET_ATTACHMENTS_REQUEST = '@@attachments/GET_ATTACHMENTS_REQUEST';
export const GET_ATTACHMENTS_SUCCESS = '@@attachments/GET_ATTACHMENTS_SUCCESS';
export const GET_ATTACHMENTS_FAILURE = '@@attachments/GET_ATTACHMENTS_FAILURE';
export const ADD_ATTACHMENT_REQUEST = '@@attachments/ADD_ATTACHMENT_REQUEST';
export const ADD_ATTACHMENT_SUCCESS = '@@attachments/ADD_ATTACHMENT_SUCCESS';
export const ADD_ATTACHMENT_FAILURE = '@@attachments/ADD_ATTACHMENT_FAILURE';
export const ADD_ATTACHMENT_PROGRESS = '@@attachments/ADD_ATTACHMENT_PROGRESS';
export const REMOVE_ATTACHMENT_REQUEST = '@@attachments/REMOVE_ATTACHMENT_REQUEST';
export const REMOVE_ATTACHMENT_SUCCESS = '@@attachments/REMOVE_ATTACHMENT_SUCCESS';
export const REMOVE_ATTACHMENT_FAILURE = '@@attachments/REMOVE_ATTACHMENT_FAILURE';
export const CLEAR_UPLOADED_ATTACHMENTS = '@@attachments/CLEAR_UPLOADED_ATTACHMENTS';

export const attachmentsInitialState = {
	loaded: false,
	attachments: [],
	upload: {
		showWarn: false,
		droppedFiles: [],
		progress: 0,
		status: 'ok',
		isComplete: false,
		warnFiles: [],
		tooBigFiles: [],
		okFiles: [],
		filesToUpload: [],
	},
	uploading: [],
	waitingForUploadedAttachments: 0,
};