import React, { useCallback, useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ChecklistType } from '../../../backend_api/models/ChecklistType';
import { useAppDispatch } from '../../../store';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import PageContainer from '../../globals/components/PageContainer';
import { sendStatusMessage } from '../../messages/actions';
import {
    dispatchCopyChecklist,
    dispatchCreateChecklist,
    dispatchDeleteChecklist, dispatchGetInspectionChecklists, getAuditChecklists
} from '../actions/actions';
import { AuditChecklists } from '../components/AuditChecklists';
import { InspectionChecklists } from '../components/InspectionChecklists';
import {
    getAuditChecklistsFetchingSelector,
    getAuditChecklistsSelector,
    getInspectionChecklistsFetchingSelector,
    getInspectionChecklistsSelector
} from '../selectors';



type OwnProps = {
    isStandalone: boolean;
};


export function ChecklistsContainer(props: OwnProps): React.ReactElement {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const auditChecklists = useSelector(getAuditChecklistsSelector);
    const isFetchingAuditChecklists = useSelector(getAuditChecklistsFetchingSelector);

    const inspectionChecklists = useSelector(getInspectionChecklistsSelector);
    const isFetchingInspectionChecklists = useSelector(getInspectionChecklistsFetchingSelector);

    // The whole part with functions could just be moved into the child components, such
    // that each child components uses useDispatch and dispatches the actions themselves.
    // For now, copy, create and delete are shared, so I think this is OK.

    // For now, we just manually wrap functions.
    // By using useCallback, we prevent unnecessary re-rendering. See
    // https://react-redux.js.org/next/api/hooks#usedispatch

    const doGetAuditChecklists = useCallback(
        function doGetAuditChecklists() {
            // One way to do it. Using the existing functions.
            return getAuditChecklists()(dispatch);
        },
        [dispatch]
    )

    const doGetInspectionChecklists = useCallback(
        function doGetInspectionChecklists() {
            // Another way to do this. Using an existing inner function.
            return dispatchGetInspectionChecklists(dispatch);
        },
        [dispatch]
    )

    const doCopyChecklist = useCallback(
        function doCopyChecklist(checklistId: string, description: string) {
            return dispatchCopyChecklist(dispatch, checklistId, description);
        },
        [dispatch]
    )

    const doCreateChecklist = useCallback(
        function doCreateChecklist(description: string, type: ChecklistType) {
            return dispatchCreateChecklist(dispatch, description, type)
        },
        [dispatch]
    )

    const doDeleteChecklist = useCallback(
        function doDeleteChecklist(checklistId) {
            return dispatchDeleteChecklist(dispatch, checklistId);
        },
        [dispatch]
    )

    const doSendStatusMessage = useCallback(
        function doSendStatusMessage(text: any[], hideAfterMs?: number, showErrorMessages?: boolean, header?: string, type?: string, showIcon?: boolean) {
            return dispatch(sendStatusMessage(text, hideAfterMs, showErrorMessages, header, type, showIcon))
        },
        [dispatch]
    )

    const actions = useMemo(() => ({
        sendStatusMessage: doSendStatusMessage,
        copyChecklist: doCopyChecklist,
        getInspectionChecklists: doGetInspectionChecklists,
        getAuditChecklists: doGetAuditChecklists,
        createChecklist: doCreateChecklist,
        deleteChecklist: doDeleteChecklist,
    }), [dispatch]); // Do not need to pass all values explicitly. We known that when dispatch changes, they all change.

    return (
        <PageContainer>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.checklists' })} />
            <InspectionChecklists
                isStandalone={props.isStandalone}
                actions={actions}
                checklists={inspectionChecklists}
                isFetching={isFetchingInspectionChecklists}
            />
            <HasFeatureAccess feature='u_audits'>
                <AuditChecklists
                    isStandalone={props.isStandalone}
                    actions={actions}
                    checklists={auditChecklists}
                    isFetching={isFetchingAuditChecklists}
                />
            </HasFeatureAccess>
        </PageContainer>
    )
}
