import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Popup } from 'semantic-ui-react';
import { BasicUserOptional } from '../../../backend_api_2';
import Label from '../../../base/components/basic/Label';
import { UserInitialsText } from '../../users/components/Widgets';

type ApprovalItemProps = {
    user: BasicUserOptional;
    disabled?: boolean;
    handleRemoveApprover(): void;
};

const ApprovalItem = (props: ApprovalItemProps): React.ReactElement => {
    const { user, handleRemoveApprover } = props;
    return (
        <Popup
            position='top center'
            trigger={<span><Label disabled={user.disabled} className='flex'>
                <span className='flex justify-between space-x-2'>
                    <span className={classnames({ 'line-through': user.disabled })}>{user.firstname} {user.lastname}</span>
                    {!props.disabled && <Icon color='grey' className='cursor-pointer' onClick={(): void => handleRemoveApprover()} name='delete' />}
                </span>
            </Label></span>}>
            {user.disabled && <div className='font-bold'><FormattedMessage id='watchers.user_is_disabled' /></div>}
            <div>{<UserInitialsText user={user} />}</div><div>{user.email}</div></Popup>
    );
}
export default ApprovalItem;
