import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Segment from '../../../base/components/basic/Segment';
import { TwoFactorResetProcedure } from './Login';

type Props = {
    clearUserData(): void;
    className?: string;
    organizationName?: string;
    twoFactorResetProcedure: TwoFactorResetProcedure;
    resetLogin();
};

const TwoFactorResetContactSupport = (props: Props): React.ReactElement => {
    const { className, twoFactorResetProcedure, organizationName } = props;
    const intl = useIntl();
    const headingKey = twoFactorResetProcedure === 'contact_support_required' ? 'twoFactor.pw_reset.contact_qarma_support' : 'twoFactor.pw_reset.contact_administrator';
    return <div className='flex justify-center mx-4 md:mx-0 md:mb-32 mb-0'>

        <Segment className='w-136 md:pb-4'
            header={organizationName ? organizationName : null}
            heading={intl.formatMessage({ id: headingKey })}
            footer={<Link to='/login' onClick={() => props.resetLogin()} className='link noUnderline'><FormattedMessage id='login.go_to_login' /></Link>}
        >

            {twoFactorResetProcedure === 'contact_support_required' && <>
                <p>
                    <FormattedMessage id='twoFactor.pw_reset.code_needs_reset.contact_qarma_support' />
                </p>
                <p>
                    <FormattedMessage id='twoFactor.pw_reset.code_needs_reset.contact_qarma_support_1' />
                </p>
            </>}
            {twoFactorResetProcedure === 'reset_required' && <>
                <p>
                    <FormattedMessage id='twoFactor.pw_reset.code_needs_reset.contact_administrator' />
                </p>
                <p>
                    <FormattedMessage id='twoFactor.pw_reset.code_needs_reset.contact_administrator_1' />
                </p>
            </>}
        </Segment>

    </div>
}
export default TwoFactorResetContactSupport;
