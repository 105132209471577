import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { DetailedSupplier } from '../../../backend_api/models';
import { AppState } from '../../../base/types';
import Groups from '../../groups/components/Groups';
import { getGroupsSelector } from '../../groups/selectors';
import { GroupData } from '../../groups/types';
import { createSupplier } from '../actions';

type OwnProps = {
    children?: React.ReactNode;
    show?: boolean;
    close?();
};

type StateProps = {
    groups: GroupData[];
};

type DispatchProps = {
    actions: {
        createSupplier: typeof createSupplier;
    };
};

const initialState = {
    supplierData: {
        name: '',
        number: '',
        groups: [],
        deleted: false,
        features: [],
        id: '',
        primary_group: undefined,
        users: undefined,
    },
    dirty: false, show: false, valid: false,
};

type State = {
    supplierData: DetailedSupplier;
    dirty: boolean;
    show: boolean;
    valid: boolean;
};

type CreateSupplierProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;

class CreateSupplier extends Component<CreateSupplierProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.createSupplier = this.createSupplier.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    render(): React.ReactElement {
        const supplierData = this.state.supplierData;
        return (
            <Modal
                open={this.state.show}
                onOpen={(): void => this.setState({ show: true })}
                onClose={this.cancel}
                closeIcon={true}
                size='small'
                trigger={this.props.children}
                className='createSupplier'
                closeOnDimmerClick={false}
            >
                <Modal.Header><FormattedMessage id='suppliers.add_supplier' /></Modal.Header>
                <Modal.Content className='space-y-8'>
                    <Form>
                        <Form.Field width={16} inline required>
                            <label><FormattedMessage id='suppliers.input_label.supplier_name' /></label>
                            <Input autoFocus={true} type='text' name='name' value={supplierData.name} onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field width={16} inline>
                            <label><FormattedMessage id='suppliers.input_label.supplier_number' /></label>
                            <Input type='text' name='number' value={supplierData.number} onChange={this.handleChange} />
                        </Form.Field>
                    </Form>
                    <Groups
                        selectedGroups={supplierData.groups}
                        handleChange={this.handleChange} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive disabled={!this.state.valid} onClick={this.createSupplier}><FormattedMessage id='suppliers.create_supplier' /></Button>
                    <Button onClick={this.cancel}><FormattedMessage id='suppliers.button_cancel' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }

    handleChange(evt, item): void {
        const supplierData = Object.assign({}, this.state.supplierData);
        supplierData[item.name] = item.value;
        this.setState({ supplierData }, () => {
            this.setState({ valid: this.isValid() });
        });
    }

    createSupplier(): void {
        this.props.actions.createSupplier(this.state.supplierData, true);
        this.setState(initialState);
    }

    cancel(): void {
        this.setState(initialState);
    }

    isValid(): boolean {
        return this.state.supplierData.name !== '';
    }
}

export const mapStateToProps = (state: AppState): StateProps => {
    return {
        groups: getGroupsSelector(state, false),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    const actions = bindActionCreators({
        createSupplier,
    }, dispatch);
    return { actions };
};

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(injectIntl(CreateSupplier));