import { Dispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { createAction } from 'typesafe-actions';
import { Action } from '../../base/genericActions';
import { AppState } from '../../base/types';
import { getLocaleString } from '../../base/utils';
import history from '../../store/history';
import { TEMPORARY_USER_MODE } from '../authentication/authenticationSlice';
import { Locale } from '../globals';
import users from '../users';
import * as actionTypes from './actionTypes';
import { getLocales, getUserDefaultLocale } from './selectors';
import * as types from './types';

export const setLocaleDefault = () => {
    return (dispatch: Dispatch<any>, getState: () => AppState): void => {
        const defaultLocale: Locale = getUserDefaultLocale(getState());
        const userId: string = users.selectors.getUserIdSelector(getState());
        if (defaultLocale) {
            moment.locale(getLocaleString(defaultLocale));
            dispatch(updateLocale(defaultLocale));
            const payload = {
                locale: defaultLocale
            };
            const isTemporaryUserMode = !!sessionStorage.getItem(TEMPORARY_USER_MODE);
            if(!isTemporaryUserMode) {
                dispatch(users.actions.updateUser(userId, payload, false, false));
            }
        }
    };
};

export const setLocale = (locale: Locale) => {
    return (dispatch: Dispatch<any>, getState: () => AppState): void => {
        const userId: string = users.selectors.getUserIdSelector(getState());
        dispatch(updateLocale(locale));
        const payload = {
            locale
        };
        dispatch(users.actions.updateUser(userId, payload, false, false));
    };
};

export const setLocaleById = (localeId: string) => {
    return (dispatch: Dispatch<any>, getState: () => AppState): void => {
        const locales = getLocales(getState());
        locales.forEach((locale: Locale) => {
            if (localeId === locale.id) {
                dispatch(updateLocale(locale));
            }
        });
    };
};

export const updateLocale = (locale: Locale): Action<actionTypes.SET_LOCALE> => {
    return {
        type: actionTypes.SET_LOCALE,
        payload: {
            locale,
        },
    };
};

export const fetching = (isFetching: boolean): Action<actionTypes.FETCHING_DATA> => {
    return {
        type: actionTypes.FETCHING_DATA,
        payload: {
            isFetching,
        },
    };
};

export const redirectToRequest = createAction(types.REDIRECT_TO_URL, () => {
    return {
        type: types.REDIRECT_TO_URL,
        payload: {},
    };
});

export const redirectTo = (url: string) => {
    return (dispatch: Dispatch): void => {
        dispatch(redirectToRequest());
        history.push(url);
    };
};
