
import { createSelector } from '@reduxjs/toolkit';
import { ListUsersUser } from '../../backend_api/models/ListUsersUser';
import { AppState, byId } from '../../base/types';
import { Group } from '../inspections/types';
import { ProfileState } from '../profile/types';

const myGroups = (state: AppState) => {
    return state.app.users.groups;
}

const applyFilter = (state: AppState, excludeDisabled?: boolean) => (users) => {
    if (!users) {
        return null;
    }
    const { filters } = state.app.users;
    return filters ?
        users.filter((user) => {
            let satisfy = true;
            if (excludeDisabled && user.disabled) {
                return false;
            }
            Object.keys(filters).forEach((key) => {

                let selectedItems = filters[key];
                if (!Array.isArray(selectedItems)) {
                    selectedItems = [selectedItems];
                }
                const name = `${user['firstname']} ${user['lastname']}`.toLowerCase();
                if (key === 'search' && selectedItems.length
                    && !name.includes(selectedItems[0].toLowerCase())
                    && !user['email'].toLowerCase().includes(selectedItems[0].toLowerCase())) {
                    satisfy = false;
                    return;
                }
                if (key === 'roles' && selectedItems.length && !selectedItems.includes(user['user_role_id'])) {
                    satisfy = false;
                    return;
                }
                if (key === 'groups' && selectedItems.length && !user[key].some(({ id }) => selectedItems.includes(id))) {
                    satisfy = false;
                    return;
                }
            })
            return satisfy;

        })
        : users;
}
const applyStatus = (state: AppState) => (users) => {
    if (!users || !users.length) {
        return null;
    }
    const { status } = state.app.users;
    switch (status) {
        case 'all':
            return users;
        case 'disabled':
            return users.filter(u => !u.active);
        case 'active':
            return users.filter(u => u.active);
    }
}


const pipe = (...fns) => x => fns.reduce((y, f) => f(y), x);

const usersAdminSelector = (state: AppState) => pipe((state: AppState) => state.app.users.admin.persons, applyStatus(state), applyFilter(state))(state);
const getAllAdminUsers = (state: AppState) => state.app.users.admin.persons;
const usersIsFetchingSelector = (state: AppState): boolean => state.app.users.isFetching;
const createUserDialogOpenSelector = (state: AppState): boolean => state.app.users.createUserDialogOpen;
const groupsSelector = (state: AppState): Group[] => myGroups(state) || [];
const groupsByIdSelector = (state: AppState): byId<Group> => state.app.users.groupsById || {};
const profileSelector = (state: AppState): ProfileState => state.app.profile || undefined;
const userIdSelector = (state: AppState): any => state.app.profile.profile.id || null;
const userEmailSelector = (state: AppState): any => state.app.profile.profile.email || '';
const getOrganisationName = (state: AppState): string => state.app.profile.profile && state.app.profile.profile.organization && state.app.profile.profile.organization.name || '-';

const getUserPreferences = (state: AppState): any => state.app.users.preferences;
const getRoles = (state: AppState) => state.app.roles.rolesList;
const getRolesById = (state: AppState) => Object.fromEntries(state.app.roles.rolesList.map(x => [x.id, x]));

const usersSelector = (state: AppState, excludeDisabled?: boolean): ListUsersUser[] => applyFilter(state, excludeDisabled)(state.app.users.persons) || [];
const allUsersSelector = (state: AppState) => state.app.users.persons;

export const getUsers = createSelector([usersSelector],
    (users) => users,
);

export const getAllUsers = createSelector([allUsersSelector],
    (users) => users,
);

const usersByIdSelector = (state: AppState): byId<ListUsersUser> => state.app.users.byId || {};

export const getUsersById = createSelector(
    [usersByIdSelector],
    (users) => users,
);

const usersSelectorSelector = (state: AppState): [] => {
    const usersById = state.app.users.byId;
    const options: any = [];
    Object.keys(usersById).map((userId: string) => {
        const user: ListUsersUser = usersById[userId];
        options.push({
            value: user.id,
            key: user.id,
            text: user.firstname + ' ' + user.lastname,
        });
    });
    return options;
};

export const getUsersSelector: any = createSelector(
    [usersSelectorSelector],
    (users) => users,
);

export const getAllAdminUsersSelector = createSelector(
    [getAllAdminUsers],
    (users) => users,
);
export const getAdminUsers = createSelector(
    [usersAdminSelector],
    (users) => users,
);

export const getAdminUsersIsFetching: any = createSelector(
    [usersIsFetchingSelector],
    (isFetching) => isFetching,
);

const getOrderUsersById = (state: AppState) => {
    return state.app.users.orderSpecificById;
};
export const getOrderUsersByIdSelector = createSelector([getOrderUsersById],
    (users) => users,
);

const getOrderUsers = (state: AppState): ListUsersUser[] => {
    return state.app.users.orderSpecific || [];
};
export const getOrderUsersSelector: any = createSelector([getOrderUsers],
    (users) => users,
);

export const getCreateUserDialogIsOpen: any = createSelector(
    [createUserDialogOpenSelector],
    (createUserDialogOpen) => createUserDialogOpen,
);

export const getGroups = createSelector(
    [groupsSelector],
    (groups) => groups,
);

export const getGroupsById = createSelector(
    [groupsByIdSelector],
    (groups) => groups,
);

export const getProfileSelector = createSelector([profileSelector],
    (profile) => profile,
);

export const getUserIdSelector: any = createSelector([userIdSelector],
    (id) => id,
);

export const getUserEmailSelector: any = createSelector([userEmailSelector],
    (email) => email,
);

export const getOrganisationNameSelector = createSelector([getOrganisationName],
    (name) => name,
);

export const getUserPreferencesSelector: any = createSelector([getUserPreferences],
    (preferences) => preferences,
);

export const getRolesSelector = createSelector(
    [getRoles],
    (roles) => roles,
);
export const getRolesByIdSelector = createSelector(
    [getRolesById],
    (roles) => roles,
);

const getWeekViewUsers = (state: AppState) => {
    const filters = state.app.inspections.weekView.filters || [];
    let ret: ListUsersUser[] = [];
    const groups = state.app.users.byGroupId;
    if (filters.length > 0) {
        filters.forEach((filter: { name: string; value: string }) => {
            if (filter.name === 'groups') {
                const users = groups[filter.value];
                if (users) {
                    users.forEach((user: ListUsersUser) => {
                        if (!user.disabled) {
                            ret.push(user);
                        }
                    });
                }
            }
        });
    } else {
        ret = getAllUsers(state).filter((user) => !user.disabled);
    }
    return ret;
};


export const getWeekViewUsersSelector = createSelector(
    [getWeekViewUsers], (users) => users,
);

const usersFiltersVisibilitySelector = (state: AppState): boolean => state.app.users.filtersVisible;
export const getUsersFiltersVisibility: any = createSelector(
    [usersFiltersVisibilitySelector],
    (filtersVisible) => filtersVisible,
);

const usersFiltersSelector = (state: AppState): any => state.app.users.filters;
export const getUsersFilters: any = createSelector(
    [usersFiltersSelector],
    (filters) => filters,
);

const usersFiltersHiddenSelector = (state: AppState): any => state.app.users.activeHiddenFilters;
export const getUsersHiddenFilters: any = createSelector(
    [usersFiltersHiddenSelector],
    (activeHiddenFilters) => activeHiddenFilters,
);

const usersCurrentPageSelector = (state: AppState): any => state.app.users.currentPage;
export const getUsersCurrentPage: any = createSelector(
    [usersCurrentPageSelector],
    (currentPage) => currentPage,
);

const usersLastPageSelector = (state: AppState): any => state.app.users.lastPage;
export const getUsersLastPageFilters: any = createSelector(
    [usersLastPageSelector],
    (lastPage) => lastPage,
);

const usersItemsPerPageSelector = (state: AppState): any => state.app.users.itemsPerPage;
export const getUsersItemsPerPage: any = createSelector(
    [usersItemsPerPageSelector],
    (itemsPerPage) => itemsPerPage,
);
const usersShowStatusSelector = (state: AppState): any => state.app.users.status;
export const getUsersShowStatus: any = createSelector(
    [usersShowStatusSelector],
    (status) => status,
);