import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { DashboardPopupInspection } from '../../../backend_api/models/DashboardPopupInspection';
import { request2 } from '../../../base/api';
import { AppState, byId } from '../../../base/types';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';
import { getSupplierBooking } from '../../suppliers/actions';
import { getBookingIdSelector } from '../../suppliers/selectors';
import { reloadOrderWithInspections } from '../actions/editInspectionActions';

export type SplitShipmentsState = {
    splitShipments: byId<DashboardPopupInspection[]>
}

export type SplitShipmentsContext = 'inspection' | 'booking';
const initialState: SplitShipmentsState = {
    splitShipments: {}
};

export const splitShipment = createAsyncThunk<void, { inspectionId: string, qty: number, context: SplitShipmentsContext }>(
    'splitShipment',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const url = 'inspections/split_shipment/planned_standalone_inspection';
        const rq = await request2(url, {
            method: 'post', body: JSON.stringify({
                source_inspection_id: params.inspectionId,
                source_inspection_quantity: params.qty,
            })
        });
        if (!rq.ok) {
            console.log('There was an error splitting the inspection...');
            dispatch(sendErrorMessage(['error_message.the_split_shipment_could_not_be_done']));
            catchException('splitShipmentStandalonePlanned', {
                endpoint: 'inspections/split_shipment/planned_standalone_inspection',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq.json(), url });
            return rejectWithValue(rq as Response);
        }
        dispatch(sendStatusMessage(['status_message.the_split_shipment_did_finish_succesfully'], 3000));
        switch (params.context) {
            case 'inspection':
                dispatch(reloadOrderWithInspections(false, false));
                break;
            case 'booking': {
                const id = getBookingIdSelector(getState() as AppState);
                dispatch(getSupplierBooking(id));
                break;
            }
            default:
                break;
        }
        return await rq.json();
    });
export const splitShipmentCombined = createAsyncThunk<void, { inspectionId: string, sourceSubInspectionQuantities: byId<string> }>(
    'splitShipmentCombined',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'inspections/split_shipment/planned_combined_inspection';
        const rq = await request2(url, {
            method: 'post', body: JSON.stringify({
                source_inspection_id: params.inspectionId,
                source_sub_inspection_quantities: params.sourceSubInspectionQuantities,
            }),
        });
        if (!rq.ok) {
            console.log('There was an error splitting the combined inspection...');
            dispatch(sendErrorMessage(['error_message.the_split_shipment_could_not_be_done']));
            catchException('splitShipmentCombinedPlanned', {
                endpoint: 'inspections/split_shipment/planned_combined_inspection',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq.json(), url });
            return rejectWithValue(rq as Response);
        }
        dispatch(reloadOrderWithInspections(false, false));
        dispatch(sendStatusMessage(['status_message.the_split_shipment_did_finish_succesfully'], 3000));
        return await rq.json();
    });

export const getSplitShipments = createAsyncThunk<{ inspectionId: string, shipments: DashboardPopupInspection[] }, string>(
    'getSplitShipments',
    async (inspectionId, { dispatch, rejectWithValue }) => {
        const url = 'inspections/split_shipment/get_split_shipments/' + inspectionId;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('There was an error splitting the combined inspection...');
            dispatch(sendErrorMessage(['error_message.the_split_shipment_could_not_be_done']));
            catchException('splitShipmentCombinedPlanned', {
                endpoint: 'inspections/split_shipment/planned_combined_inspection',
                request: url,
                status: rq.status,
                statusText: rq.statusText,
            }, { error: rq.json(), url });
            return rejectWithValue(rq as Response);
        }
        const shipments = await rq.json()
        return { inspectionId: inspectionId, shipments: shipments };
    });

export const splitShipmentsSlice = createSlice({
    name: 'splitShipments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSplitShipments.fulfilled, (state, action) => {
            state.splitShipments[action.payload.inspectionId] = action.payload.shipments;
        });
    }
});

export const getSplitShipmentsSelector = createSelector(
    [(state: AppState, inspectionId: string): DashboardPopupInspection[] => {
        return state.app.splitShipments.splitShipments[inspectionId]
    }],
    (defaultDate) => defaultDate,
);

const { reducer } = splitShipmentsSlice;
export default reducer;
