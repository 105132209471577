import qs from 'query-string';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import QLink from '../../modules/globals/components/QLink';
import { twMerge } from '../utils';

type TabsMenuProps = {
    items: { labelId: string; query?: any; linkPath?: string; id: string }[];
    path: string;
    active?: string;
    includeCI?: boolean;
};

const TabsMenu = (props: TabsMenuProps): React.ReactElement => {
    const { items, path, active, includeCI = false } = props;
    const [showCI, toggleCI] = useState(false);
    return <div className='flex w-full md:w-auto items-center'>
        {items.map((item, i) => {
            const q = qs.stringify(item.query);
            const isActive = active === item.id;
            const to = (): string => {
                let p = path;
                if (item.linkPath) {
                    p += '/' + item.linkPath;
                }
                if (q) {
                    p += '?' + q;
                }
                return p;
            };
            return <div
                className={twMerge('flex-1 md:flex-none',
                    i === (items.length - 1) && 'md:rounded-r-md',
                    i === 0 && 'md:rounded-l-md',
                    isActive ? 'bg-surface-secondary' : 'bg-surface-light',
                )}
                key={'tab_link_' + i}>
                <QLink
                    className={twMerge('flex justify-center md:justify-left p-3 md:p-6 truncate',
                        isActive ? 'text-on-surface-secondary hover:text-on-surface-secondary' : 'text-on-surface-light hover:text-on-surface-light')}
                    to={to()}
                    excludeParams={item.query && Object.keys(item.query)}
                >
                    <FormattedMessage id={item.labelId} />
                </QLink></div>
        })}
        {includeCI && <div className='py-3 px-4 border-l border-gray-400 flex bg-white font-bold' onClick={(): void => toggleCI(!showCI)}>+</div>}
    </div >
}

export default TabsMenu;
