/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCustomFieldNotSet } from './AuditCustomFieldNotSet';
import type { AuditCustomFieldNull } from './AuditCustomFieldNull';
import type { AuditCustomFieldNumber } from './AuditCustomFieldNumber';

/**
 *   Value for a date based custom field
 *
 */
export type AuditCustomFieldNumberValue = {
    new_value: (AuditCustomFieldNotSet | AuditCustomFieldNull | AuditCustomFieldNumber);
    previous_value: string | null;
    type: AuditCustomFieldNumberValue.type;
};

export namespace AuditCustomFieldNumberValue {

    export enum type {
        NUMBER = 'Number',
    }


}

