import React, { useRef, useState } from 'react';
import { VideoResource } from '../../../backend_api/models';
import Icon from '../../../base/components/basic/Icon';
import { twMerge } from '../../../base/utils';

interface VideoProps {
    thumbnail: string;
    videoResources: VideoResource[];
    className?: string;
    usePlayIcon?: boolean;
    preload?: boolean;
}

const Video = (props: VideoProps): React.ReactElement => {
    const ref = useRef<HTMLDivElement>();
    const [isPlaying, setIsPlaying] = useState<boolean>(false)

    const playPause = (): void => {
        if (ref && ref.current) {
            const videoContainer = ref.current;
            const video = videoContainer.firstChild as HTMLVideoElement;
            if (video.paused) {
                video.play();
            }
            else {
                video.pause();
            }
        }
    }

    const updateIcon = (): void => {
        if (ref && ref.current) {
            const videoContainer = ref.current;
            const video = videoContainer.firstChild as HTMLVideoElement;
            if (video.paused) {
                setIsPlaying(false);
            }
            else {
                setIsPlaying(true);
            }
        }
    }
    const preload = props.preload ? 'metadata' : 'none';
    return (
        <div className={twMerge('wrapper', props.className)} ref={ref}>
            <video onPause={updateIcon} onPlay={updateIcon} controls preload={preload} poster={props.thumbnail}>
                {props.videoResources.map((resource) => {
                    return <source key={resource.url} type={resource.mime_type} src={resource.url} />
                })}
            </video>
            {props.usePlayIcon && !isPlaying &&
                <Icon onClick={playPause} name='play_circle_outlined' className='mi self-center flex w-full absolute top-0 
                text-8xl text-white cursor-pointer transform translate-x-44 items-center h-1/2'/>
            }
        </div>
    )
}

export default Video;