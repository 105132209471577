import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { Comment, MultiLingualText, TextWithTranslation } from '../../../backend_api/models';
import { getLocaleLanguage } from '../../utils';

type Props = {
    className?: string;
    comment: TextWithTranslation & Comment; // TODO: right now the auditor conclusion has a different (old) type (Comment) then audit history (TextWithTranslation). This will be resolved at a later point. See: https://qarmainspect.atlassian.net/browse/QARMA-8526
};

const TranslateComment = (props: Props): React.ReactElement => {
    const { className, comment } = props;
    const [showOriginal, setShowOriginal] = useState(false);
    const intl = useIntl();
    const locale = getLocaleLanguage(intl.locale);
    let translatedComments: MultiLingualText;
    let showTranslation: boolean;
    let commentText: string;
    if (comment.translations) {
        translatedComments = comment.translations;
        showTranslation = comment.language !== locale;
        commentText = comment.text;
    } else {
        if (comment.translated_comment) {
            translatedComments = comment.translated_comment;
            showTranslation = comment.comment_language !== locale;
            commentText = comment.comment;
        } else {
            if (comment.comment) {
                commentText = comment.comment;
            }
        }
    }
    const hasTranslation = translatedComments && translatedComments[locale] !== undefined;
    const langLabel = intl.formatMessage({ id: 'globals.locale.' + intl.locale });
    const langLabelFrom = <FormattedMessage id='comments.translations_translated_from_unknow_language' />;
    useEffect(() => {
        setShowOriginal(false);
    }, [locale]);
    return <span className={twMerge('whitespace-pre-wrap', className)}>
        {showOriginal && hasTranslation && <span>{commentText} <div className='flex text-xss'><div className='link' onClick={() => setShowOriginal(false)}><FormattedMessage id='comments.translate_to_lang' values={{ lang: langLabel }} /></div></div></span>}
        {showOriginal && !hasTranslation && <span>{commentText}</span>}
        {!showOriginal && (!showTranslation || !hasTranslation) && <span>{commentText}</span>}
        {!showOriginal && showTranslation && hasTranslation && <span>{translatedComments[locale]} <div className='flex text-xss space-x-1 text-link-color uppercase'><div className='link noUnderline'>{langLabelFrom}</div><div>|</div><div className='link' onClick={() => setShowOriginal(true)}><FormattedMessage id='comments.translate_show_original' /></div></div></span>}
    </span>
}
export default TranslateComment;
