/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This type describes an interval in time.
 * The start and end can be open, by leaving 'from', 'until' or both as `null`.
 * @export
 * @interface TimeFrameNoExpiration
 */
export interface TimeFrameNoExpiration {
    /**
     * 
     * @type {string}
     * @memberof TimeFrameNoExpiration
     */
    from: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameNoExpiration
     */
    type: TimeFrameNoExpirationTypeEnum;
}


/**
 * @export
 */
export const TimeFrameNoExpirationTypeEnum = {
    TimeFrameNoExpiration: 'time_frame_no_expiration'
} as const;
export type TimeFrameNoExpirationTypeEnum = typeof TimeFrameNoExpirationTypeEnum[keyof typeof TimeFrameNoExpirationTypeEnum];


/**
 * Check if a given object implements the TimeFrameNoExpiration interface.
 */
export function instanceOfTimeFrameNoExpiration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function TimeFrameNoExpirationFromJSON(json: any): TimeFrameNoExpiration {
    return TimeFrameNoExpirationFromJSONTyped(json, false);
}

export function TimeFrameNoExpirationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeFrameNoExpiration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['from'],
        'type': json['type'],
    };
}

export function TimeFrameNoExpirationToJSON(value?: TimeFrameNoExpiration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'type': value.type,
    };
}

