import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown, Icon, Popup } from 'semantic-ui-react';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import Button from '../../../base/components/basic/Button';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { AppState, byId } from '../../../base/types';
import { isUndefinedOrNull, twMerge, useFormatMessage } from '../../../base/utils';
import { GroupData } from '../../groups/types';
import { searchOptionTextAndDescription } from '../../inspections/Utils';
import { getGroups, getGroupsById, getUsersById } from '../selectors';
import UsersSelectorModal from './UsersSelectorModal';

export type UsersSelectorProps = {
    users?: byId<ListUsersUser | any>;
    done?: boolean;
    locked?: boolean;
    disabled?: boolean;
    search?: boolean;
    withIcon?: boolean;
    showEmailInSelector?: boolean;
    showExpanded?: boolean;
    allowMultipleSelection?: boolean;
    showUnassigned?: boolean;
    showDeactivated?: boolean;
    fluid?: boolean;
    placeholder?: string;
    value?: string[];
    name?: string;
    className?: string;
    dataTestId?: string;
    disabledUserLabel?: string;
    open?: boolean;
    fixedWidth?: boolean;
    hideSelected?: boolean;
    handleChange?(value): void;
    handleBlur?(value): void;
    clear?(): void;
    onActive?(): void;
    onDeactive?(selectedUsers: string[]): void;

};

type dropdownValue = string | number | boolean | (string | number | boolean)[];

const UsersSelector = (props: UsersSelectorProps): React.ReactElement => {
    const nameInput = useRef(null);
    const { fixedWidth = true, disabled = false, open = false, showDeactivated = false, search = true, hideSelected = false } = props;
    const style = fixedWidth ? {} : { width: '100%' };
    const { showExpanded = false, showEmailInSelector, placeholder, allowMultipleSelection = true, withIcon = false, showUnassigned = false, fluid = true } = props;

    const usersFromSelector = useSelector(getUsersById)

    const users = props.users || usersFromSelector

    const placeholderStr = placeholder ? placeholder : useFormatMessage('users.select_users');
    const groups = useSelector<AppState, GroupData[]>(getGroups);
    const groupsById = useSelector<AppState, byId<GroupData>>(getGroupsById);

    const [selectedUsers, setSelectedUsers] = useState<string[]>(props.value || []);
    const [active, setActive] = useState({ usersDropdown: false });
    const [options, setOptions] = useState<ListUsersUser[]>();
    const [dropdownValue, setDropdownValue] = useState<dropdownValue>();

    useEffect(() => {
        if (nameInput.current) {
            if (active.usersDropdown) {
                nameInput.current.open();
            } else {
                nameInput.current.close();
            }
        }
    }, [active]);

    useEffect(() => {
        let optionsUsers = users;
        if (!active.usersDropdown) {
            const key = (props.value && props.value[0]) || Object.keys(users)[0];
            optionsUsers = { [key]: users[key] };
        }
        setOptions(createOptions(optionsUsers, showEmailInSelector, withIcon, showUnassigned, showDeactivated));
    }, [users, active]);

    useEffect(() => {
        if (props.value) {
            setSelectedUsers(props.value);
        }
    }, [props.value]);

    const selectUser = ({ value }: { value?: dropdownValue }): void => {
        !hideSelected && setSelectedUsers(convertToArray(value));
        setActive({ usersDropdown: allowMultipleSelection ? true : false })
        if (!allowMultipleSelection) {
            props.onDeactive && props.onDeactive(convertToArray(value));
        }
        props.handleChange && props.handleChange({ value: value });
        hideSelected && removeUser(value as string);
    };

    const convertToArray = (value): string[] => {
        return Array.isArray(value) ? value : [value];
    };

    const activateUserSelector = (): void => {
        if (!active.usersDropdown) {
            setActive({ usersDropdown: true });
            props.onActive && props.onActive();
        }
    };

    const deActivateUserSelector = (): void => {
        setActive({ usersDropdown: false });
        props.onDeactive && props.onDeactive(selectedUsers);
    };

    const onBlur = (): void => {
        setActive({ usersDropdown: false });
        props.onDeactive && props.onDeactive(selectedUsers);
        props.handleBlur && props.handleBlur(selectedUsers);
    };

    const getNotActiveLabel = (usersId: string | string[]): React.ReactElement => {
        const usersCnt = selectedUsers.length - 1;
        return <span>{users[usersId[0]].firstname + ' ' + users[usersId[0]].lastname} {usersCnt > 0 && <span className='plusSome'> + {usersCnt}</span>}</span>;
    };
    const getNotActiveLabelString = (usersId: string | string[]): string => {
        return users[usersId[0]] && (users[usersId[0]].firstname + ' ' + users[usersId[0]].lastname);
    };

    const handleModalSelections = (selectedInModal: string[]): void => {
        setSelectedUsers(selectedInModal);
        const value = allowMultipleSelection ? selectedInModal : selectedInModal[0];
        props.handleChange && props.handleChange({ value: value });
        props.onDeactive && props.onDeactive(selectedInModal);
    };

    const clear = (): void => {
        setSelectedUsers([]);
        props.clear && props.clear();
    };

    const getPopup = (trigger: React.ReactElement): React.ReactElement => {
        return selectedUsers.length > 1 ? <Popup
            position='top center'
            trigger={trigger}>
            <Popup.Header><FormattedMessage id='users.selected_users' /> ({selectedUsers.length})</Popup.Header>
            <Popup.Content>
                {selectedUsers.map((userId) => {
                    return (<div key={'pop_user_' + userId}>{users[userId].firstname + ' ' + users[userId].lastname}</div>);
                })}
            </Popup.Content>
        </Popup> : trigger;
    };
    const hasDropdownValue = (dropdownValue: string | number | boolean | (string | number | boolean)[]): boolean => {
        if (Array.isArray(dropdownValue)) {
            return dropdownValue.length > 0;
        }
        return !isUndefinedOrNull(dropdownValue);
    }

    const removeUser = (id: string) => {
        delete users[id];
        setOptions(createOptions(users, showEmailInSelector, withIcon, showUnassigned, showDeactivated));
    }
    useEffect(() => {
        if (allowMultipleSelection) {
            setDropdownValue(selectedUsers);
        }
        else {
            setDropdownValue(selectedUsers.length > 0 ? selectedUsers[0] : null);
        }
    }, [selectedUsers])

    return <div className={twMerge('usersSelector', props.className)}>
        {!active.usersDropdown && selectedUsers.length > 0 && props.clear &&
            getPopup(<div className='flex justify-between w-full border rounded border-default'>
                <Button
                    onClick={(e): void => {
                        activateUserSelector();
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }} className='w-full bg-white flex justify-between border-0 pl-4' >
                    {getNotActiveLabel(selectedUsers)}

                </Button >
                <div className='items-center flex border-l px-2 bg-surface-plain'><RemoveIcon
                    className='cursor-pointer'
                    onClick={(e): void => {
                        clear();
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                />

                </div>
            </div>)
        }
        {allowMultipleSelection && !active.usersDropdown && selectedUsers.length > 1 && !props.clear && hideSelected &&
            <Dropdown style={style}
                className={classnames({ buttonRight: showExpanded })}
                selection
                fluid={fluid}
                search={search ? searchOptionTextAndDescription : false}
                onOpen={activateUserSelector}
                name={props.name}
                data-test-id={props.dataTestId}
                placeholder={placeholderStr}
            />
        }
        {allowMultipleSelection && !active.usersDropdown && selectedUsers.length > 1 && !props.clear && !hideSelected &&
            <Popup
                position='top center'
                trigger={<div><Dropdown style={style}
                    /* renderLabel={(label) => ({
                        content: 'foo',
                    })} */
                    className={classnames({ buttonRight: showExpanded })}
                    selection
                    fluid={fluid}
                    search={search ? searchOptionTextAndDescription : false}
                    onOpen={activateUserSelector}
                    name={props.name}
                    data-test-id={props.dataTestId}
                >{getNotActiveLabel(selectedUsers)}
                </Dropdown></div>}>
                <Popup.Header><FormattedMessage id='users.selected_users' /> ({selectedUsers.length})</Popup.Header>
                <Popup.Content>
                    {selectedUsers.map((userId) => {
                        return (<div key={'pop_user_' + userId}>{users[userId].firstname + ' ' + users[userId].lastname}</div>);
                    })}
                </Popup.Content>
            </Popup>}
        {allowMultipleSelection && !active.usersDropdown && selectedUsers && selectedUsers.length === 1 && !props.clear && <Dropdown style={style}
            className={classnames({ buttonRight: showExpanded })}
            selection
            fluid={fluid}
            search={search ? searchOptionTextAndDescription : false}
            onOpen={activateUserSelector}
            name={props.name}
            data-test-id={props.dataTestId}
        >{hideSelected ? <div>{placeholderStr}</div> : getNotActiveLabel(selectedUsers)}
        </Dropdown>}
        {(active.usersDropdown || selectedUsers.length === 0 || !allowMultipleSelection) && <Dropdown style={style}
            renderLabel={(label) => {
                return hideSelected ? null: {content: <div className='inline-block'>{label.content}</div>}
            }}
            className={classnames({ active_dropdown: active.usersDropdown, buttonRight: showExpanded })}
            options={options}
            closeOnChange={false}
            onOpen={activateUserSelector}
            openOnFocus
            selection
            text={props.disabledUserLabel ? props.disabledUserLabel : (!active.usersDropdown ? (selectedUsers.length > 0 && getNotActiveLabelString(selectedUsers)) : null)}
            search={search ? searchOptionTextAndDescription : false}
            multiple={allowMultipleSelection}
            placeholder={hasDropdownValue(dropdownValue) ? '' : placeholderStr}
            value={dropdownValue}
            onChange={(evt, item): void => {
                selectUser(item);
            }}
            selectOnNavigation={false}
            ref={nameInput}
            onClose={deActivateUserSelector}
            name={props.name}
            data-test-id={props.dataTestId}
            disabled={disabled}
            fluid={fluid}
        />
        }
        {showExpanded && !active.usersDropdown && (!props.clear || !(selectedUsers.length > 0)) &&
            <UsersSelectorModal
                withIcon={props.withIcon}
                disabled={disabled}
                users={users}
                groups={groups}
                groupsById={groupsById}
                allowMultipleSelection={allowMultipleSelection}
                showUnassigned={showUnassigned}
                showDeactivated={showDeactivated}
                selectedUsers={selectedUsers}
                handleModalSelections={handleModalSelections}
                open={open} />
        }
    </div>
}

function createContent(user: ListUsersUser, withIcons: boolean): React.ReactElement {
    const isSupplierQC = user.groups[0] && user.groups[0].is_supplier_group;
    const isDisabled = user.disabled;
    if ((isSupplierQC || isDisabled) && withIcons) {
        return <div className='flex flex-row-reverse justify-between'>
            <div className='flex'>
                {isSupplierQC && <div>
                    <Popup position={'top right'} trigger={<Icon name='factory' />}>
                        <FormattedMessage id='users.user_is_supplier_qc' />
                    </Popup>
                </div>}
                {isDisabled && <div>
                    <Popup position={'top right'} trigger={<Icon name='remove' />}>
                        <FormattedMessage id='users.user_is_disabled' />
                    </Popup>
                </div>}
            </div>
            <div>
                {user.firstname + ' ' + user.lastname}
            </div>
        </div>
    }
    else {
        return <div>
            {user.firstname + ' ' + user.lastname}
        </div>
    }
}


const createOptions = (users: byId<ListUsersUser>, showEmailInSelector: boolean, withIcon: boolean, showUassigned: boolean, showDeactivated: boolean): ListUsersUser[] => {
    const options = [];
    if (showUassigned) {
        options.push({ text: <b><FormattedMessage id='users.unassigned' /></b>, description: '', key: null, value: null });
    }
    Object.values(users).forEach((user: ListUsersUser) => {
        if (user) {
            const text = user.firstname + ' ' + user.lastname;
            const content = createContent(user, withIcon);
            const obj = { description: undefined, content, key: user.id, value: user.id, text };
            let description;
            if (showEmailInSelector) {
                description = user.email;
                obj.description = description;
            }
            if (user.disabled) {
                if (showDeactivated) {
                    options.push(obj);
                }
            } else {
                options.push(obj);
            }
        }
    });
    return options;
}

export default UsersSelector;
