import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { InfoIcon } from '../../../globals/components/Utils';


export function loadingSupplierMessage() {
    return <Fragment><Icon name='circle notched' color='green' loading /><FormattedMessage id='suppliers.selection.loading_suppliers' /></Fragment>;
}

export function loadingProductionUnitMessage() {
    return <Fragment><Icon name='circle notched' color='green' loading /><FormattedMessage id='audits.loading_production_units' /></Fragment>;
}

export function noProductionUnitsInfoIcon(intl) {
    return <InfoIcon text={intl.formatMessage({ id: 'audits.no_production_units' }) + '.'} noMargins={true} compact={true} />;
}

