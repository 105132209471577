import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { Audit, CorrectiveAction, CorrectiveActionsState } from '../../../backend_api/models';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { getColor, getFullStateName } from '../CorrectiveActionDisplayState';
import CorrectiveActionMediumList from './CorrectiveActionMediumList';

type Props = {
    correctiveActionStatus: Audit['corrective_actions_status'];
    trigger: React.ReactElement;
    className?: string;
    context: 'audit' | 'supplier' | 'production unit';
    loadCorrectiveActions?();
    correctiveActions?: CorrectiveAction[];
    isFetching?: boolean;
};

const CorrectiveActionStatusPopup = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const { correctiveActionStatus, trigger, context } = props;
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const total = correctiveActionStatus.closed + correctiveActionStatus.finding + correctiveActionStatus.in_progress + correctiveActionStatus.review;
    const allDone = total > 0 && correctiveActionStatus.closed === total;
    let listHeaderText
    let noCorrectiveActionsText
    let allCorrectiveactionsDoneText
    let notAllDoneText
    switch (context) {
        case 'audit':
            listHeaderText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.audits' });
            noCorrectiveActionsText = intl.formatMessage({ id: 'audit.corrective_actions_tag.popup.no_cas_on_audit' });
            allCorrectiveactionsDoneText = intl.formatMessage({ id: 'audit.corrective_actions_tag.popup.cas_on_audit_in_all' });
            notAllDoneText = <FormattedMessage id='audit.corrective_actions_tag.popup.cas_on_audit_in_all' values={{ cnt: total }} />
            break;
        case 'supplier':
            listHeaderText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.supplier' })
            noCorrectiveActionsText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.supplier.no_cas' });
            allCorrectiveactionsDoneText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.supplier.all_done' });
            notAllDoneText = <FormattedMessage id='corrective_actions.status_popup.list_header.supplier.not_all_done' values={{ cnt: total }} />
            break;
        case 'production unit':
            listHeaderText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.production_unit' })
            noCorrectiveActionsText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.production_unit.no_cas' });
            allCorrectiveactionsDoneText = intl.formatMessage({ id: 'corrective_actions.status_popup.list_header.production_unit.all_done' });
            notAllDoneText = <FormattedMessage id='corrective_actions.status_popup.list_header.production_unit.not_all_done' values={{ cnt: total }} />
            break;
    }

    const caMedPop = <Modal
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        onClose={(e) => {
            preventDefaultAndStopPropagation(e)
            setShowModal(false)
        }}
        open={showModal}
        onOpen={(e) => {
            preventDefaultAndStopPropagation(e)
        }}
        trigger={<div></div>}>
        <ContentSection headerText={listHeaderText} content={<CorrectiveActionMediumList correctiveActions={props.correctiveActions} isFetching={props.isFetching} />} />
    </Modal>

    return <Fragment><Popup
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        hoverable
        open={open}
        trigger={trigger}>
        <h4><FormattedMessage id='audit.corrective_actions_tag.popup.header' /></h4>
        <Popup.Content>
            {!allDone && total > 0 && <div className='py-2'>{notAllDoneText}</div>}
            {allDone && <div className='py-2'>{allCorrectiveactionsDoneText}</div>}
            {total === 0 && <div>{noCorrectiveActionsText}</div>}
            {total > 0 && <div className='flex text-sm flex-col space-y-2 w-2/3'>
                {correctiveActionStatus.finding > 0 && <div className='flex justify-between'><div className={twMerge('w-32 justify-center rounded text-white px-1 flex', 'bg-' + getColor(CorrectiveActionsState.Finding))}>{getFullStateName(CorrectiveActionsState.Finding)}</div><div>{correctiveActionStatus.finding}</div></div>}
                {correctiveActionStatus.in_progress > 0 && <div className='flex justify-between'><div className={twMerge('flex w-32 justify-center rounded text-white', 'bg-' + getColor(CorrectiveActionsState.InProgress))}>{getFullStateName(CorrectiveActionsState.InProgress)}</div><div>{correctiveActionStatus.in_progress}</div></div>}
                {correctiveActionStatus.review > 0 && <div className='flex justify-between'><div className={twMerge('flex w-32 justify-center rounded text-white px-1', 'bg-' + getColor(CorrectiveActionsState.Review))}>{getFullStateName(CorrectiveActionsState.Review)}</div><div>{correctiveActionStatus.review}</div></div>}
                {correctiveActionStatus.closed > 0 && <div className='flex justify-between'><div className={twMerge('flex w-32 justify-center rounded text-white px-1', 'bg-' + getColor(CorrectiveActionsState.Closed))}>{getFullStateName(CorrectiveActionsState.Closed)}</div><div>{correctiveActionStatus.closed}</div></div>}
            </div>}
            {total > 0 && props.loadCorrectiveActions && <div className='link text-xs mt-2'
                onClick={(e) => {
                    setOpen(false)
                    setShowModal(true)
                    props.loadCorrectiveActions()
                    preventDefaultAndStopPropagation(e);
                }}
            ><FormattedMessage id='audits.list.pie.popup.show_more' /></div>}
        </Popup.Content>
    </Popup>{caMedPop}</Fragment>
}

export default CorrectiveActionStatusPopup;
