import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'semantic-ui-react';
import Button from '../../../../base/components/basic/Button';

export function StartSelfAssignedAuditModal(props: {
    showing: boolean,
    start: () => void,
    cancel: () => void,
}) {
    const {showing, start, cancel} = props;
    return <Modal
        open={showing}
        closeOnDimmerClick
        closeOnEscape
        size='tiny'>
        <Modal.Header><FormattedMessage id='audits.edit.audit_saved'/></Modal.Header>
        <Modal.Content>
           <div><FormattedMessage id='audits.edit.audit_self_assigned'/></div>
           <div className='font-bold'><FormattedMessage id='audits.edit.want_start_audit_now'/></div>
        </Modal.Content>
        <Modal.Actions className='flex flex-row justify-end my-2'>
            <Button className='' primary onClick={start}><FormattedMessage id='audits.edit.start_audit_now'/></Button>
            <Button className='' onClick={cancel}><FormattedMessage id='audits.edit.later'/></Button>
        </Modal.Actions>
    </Modal>
}
