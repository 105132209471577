import qs from 'query-string';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Button, Checkbox } from 'semantic-ui-react';
import TabsMenu from '../../../../../base/components/TabsMenu';
import { getLocationEntry } from '../../../../../base/utils';
import { DateFilter } from '../../../../filters/components';
import AddFilters from '../../../../filters/components/AddFilters';
import FiltersListNew from '../../../../filters/components/FiltersListNew';
import { setFilter } from '../../../../filters/filtersActions';
import { setBookingDefaultDate } from '../../../actions';

type BookingMenuProps = {
    enableMultiselect();
    multiSelectingEnabled: boolean;
};

enum DateFilterContext {
    etd = 'etd',
    bookingDates = 'booking_dates'
}

const BookingMenu = (props: BookingMenuProps): ReactElement<any> => {
    const location = useLocation();
    const intl = useIntl()
    const query = { ...qs.parse(location.search) };
    const [dateFilterContext, setDateFilterContext] = useState<DateFilterContext>(DateFilterContext.etd)
    if (query.booking_filter !== null) {
        delete query.booking_filter;
    }
    const onChange = (date) => {
        setBookingDefaultDate(date)
        return setFilter('date_filter_context', dateFilterContext as any);
    }

    useEffect(() => {
        if (query.date_filter_context) {
            setDateFilterContext(query.date_filter_context as DateFilterContext)
        }
    }, [])

    const dateContextSelector = <div className='flex flex-col pt-4'>
        <FormattedMessage id="supplier_booking.bookings.menu.data_filter_context" />
        <Checkbox radio
            checked={dateFilterContext === DateFilterContext.etd}
            onClick={(): void => setDateFilterContext(DateFilterContext.etd)}
            label={intl.formatMessage({ id: 'supplier_booking.bookings.order_line.etd' })} />
        <Checkbox radio
            checked={dateFilterContext === DateFilterContext.bookingDates}
            onClick={(): void => setDateFilterContext(DateFilterContext.bookingDates)}
            label={intl.formatMessage({ id: 'supplier_booking.bookings.order_line.booking_dates' })} />
    </div>
    return (
        <Fragment>
            <div className="flex justify-between">
                <div className='flex flex-row space-x-4 pb-8 items-center'>
                    <TabsMenu
                        active={getLocationEntry(location, 'booking_filter')[0] || 'all'}
                        path='bookings'
                        items={[
                            { labelId: 'supplier_booking.bookings.menu.all', query: { booking_filter: 'all' }, id: 'all' },
                            { labelId: 'supplier_booking.bookings.menu.unconfirmed', query: { booking_filter: 'unconfirmed' }, id: 'unconfirmed' },
                            { labelId: 'supplier_booking.bookings.menu.overdue', query: { booking_filter: 'overdue' }, id: 'overdue' },
                        ]} />
                    <DateFilter dateContextSelector={dateContextSelector} showFuture={true} showPast={false} onChange={onChange} hideAllOption={true} disableClear={true} />                    
                    <AddFilters />
                </div>
                <div className="justify-end">
                    <Button disabled={props.multiSelectingEnabled} secondary onClick={props.enableMultiselect}><FormattedMessage id="supplier_booking.bookings.menu.multiselect" /></Button>
                </div>
            </div>
            <FiltersListNew
                noStatusAfterClear={true}
                filters={['inspector-filter', 'approver-filter', 'inspection-types-filter', 'custom-field-filter', 'supplier-filter']}
            />
        </Fragment>
    );
};

export default BookingMenu;
