import React, { ReactNode } from 'react';
import { createIntl, createIntlCache, IntlProvider, IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { DEFAULT_LOCALE, DEFAULT_LOCALE_LANG } from '../base/config';
import { getLocaleString } from '../base/utils';
import globals from '../modules/globals';

type Props = {
    className?: string;
    children?: ReactNode;
};

const ConnectedIntlProvider = (props: Props) => {
    const defaultLocale: string = getLocaleString(useSelector(globals.selectors.getDefaultLocale));
    const currentLocale = getLocaleString(useSelector(globals.selectors.getCurrentLocale));
    let locale = currentLocale;
    const localeData = getLocaleData(locale, defaultLocale);

    let messages = null;
    if (localeData[locale] == null) {
        console.warn('There are no translations data for locale: ' + locale);
        console.warn('Using translations data for : ' + defaultLocale);
        locale = defaultLocale;
    }
    if (locale !== defaultLocale) {
        messages = Object.assign({}, getLocaleData(defaultLocale, defaultLocale)[defaultLocale], localeData[locale]);
    } else {
        messages = localeData[locale];
    }
    return (<IntlProvider messages={messages} locale={locale}>{props.children}</IntlProvider>)

}
const getBrowserTranslations = (): any => {
    const defaultLocale = DEFAULT_LOCALE_LANG;
    const nl = navigator.language.split('-')[0];
    const browserLocale = languageMap[nl] || nl;
    const localeData = getLocaleData(browserLocale, defaultLocale);
    if (localeData && localeData[browserLocale]) {
        return localeData[browserLocale];
    }
    return localeData[defaultLocale];
}

const getLocalTranslationsIntl = (language: string): IntlShape => {
    const defaultLocale = DEFAULT_LOCALE;
    const mappedLanugage = mapLanguageString(language);
    const localeData = getLocaleData(mappedLanugage, defaultLocale);
    let data;
    if (localeData && localeData[mappedLanugage]) {
        data = localeData[mappedLanugage];
    }
    else {
        data = localeData[defaultLocale]
    }
    const cache = createIntlCache();
    const intl = createIntl({
        locale: mappedLanugage,
        messages: data
    }, cache)
    return intl;
}

const mapLanguageString = (language: string): string => {
    if (languageMap[language] == undefined) {
        return language;
    }
    else {
        return languageMap[language];
    }
}

const languageMap = {
    en: 'en-US',
    zh: 'zh-CN',
    vi: 'vi-VN',
    de: 'de-DE',
    pl: 'pl-PL',
    uk: 'uk-UA',
}

export function getLocaleData(locale, defaultLocale): any {
    let localeData;
    try {
        localeData = require('../../data/translations.' + locale + '.json');
    } catch (ex) {
        localeData = require('../../data/translations.' + defaultLocale + '.json');
    }
    return localeData
}

export default ConnectedIntlProvider;
export { getBrowserTranslations, getLocalTranslationsIntl };
