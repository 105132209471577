/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditCustomFieldDate } from './AuditCustomFieldDate';
import type { AuditCustomFieldNotSet } from './AuditCustomFieldNotSet';
import type { AuditCustomFieldNull } from './AuditCustomFieldNull';

/**
 *   Value for a date based custom field
 *
 */
export type AuditCustomFieldDateValue = {
    new_value: (AuditCustomFieldNotSet | AuditCustomFieldNull | AuditCustomFieldDate);
    previous_value: string | null;
    type: AuditCustomFieldDateValue.type;
};

export namespace AuditCustomFieldDateValue {

    export enum type {
        DATE = 'Date',
    }


}

