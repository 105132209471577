/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 73.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * To log in to this organization, the user needs to supply his second factor token.
 * @export
 * @interface TwoFactorRequired
 */
export interface TwoFactorRequired {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    account_id: string;
    /**
     * 
     * @type {Date}
     * @memberof TwoFactorRequired
     */
    last_login: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    organization_id: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    organization_logo_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    organization_name: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    two_factor_state: TwoFactorRequiredTwoFactorStateEnum;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    type: TwoFactorRequiredTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorRequired
     */
    user_id: string;
}

/**
* @export
* @enum {string}
*/
export enum TwoFactorRequiredTwoFactorStateEnum {
    SetupRequired = 'setup_required',
    ResetRequired = 'reset_required',
    Ready = 'ready'
}/**
* @export
* @enum {string}
*/
export enum TwoFactorRequiredTypeEnum {
    TwoFactorRequired = 'two_factor_required'
}

export function TwoFactorRequiredFromJSON(json: any): TwoFactorRequired {
    return TwoFactorRequiredFromJSONTyped(json, false);
}

export function TwoFactorRequiredFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwoFactorRequired {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': json['account_id'],
        'last_login': (json['last_login'] === null ? null : new Date(json['last_login'])),
        'organization_id': json['organization_id'],
        'organization_logo_url': json['organization_logo_url'],
        'organization_name': json['organization_name'],
        'two_factor_state': json['two_factor_state'],
        'type': json['type'],
        'user_id': json['user_id'],
    };
}

export function TwoFactorRequiredToJSON(value?: TwoFactorRequired | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'last_login': (value.last_login === null ? null : value.last_login.toISOString()),
        'organization_id': value.organization_id,
        'organization_logo_url': value.organization_logo_url,
        'organization_name': value.organization_name,
        'two_factor_state': value.two_factor_state,
        'type': value.type,
        'user_id': value.user_id,
    };
}


