import { handleActions } from 'redux-actions';
import editInspection from './reducers/editInspectionReducer';
import reducer from './reducers/reducers';
import setupAndInfo from './reducers/setupAndInfoReducer';
import weekView from './reducers/weekViewReducer';

const m1 = reducer.map;
const m2 = weekView.map;
const m3 = setupAndInfo.map;
const m4 = editInspection.map;
const is1 = reducer.initialState;
const is2 = weekView.initialState;
const is3 = setupAndInfo.initialState;
const is4 = editInspection.initialState;
const map = Object.assign({}, m1, m2, m3, m4);
export const initialState = Object.assign({}, is1, is2, is3, is4);
const combinedReducer = handleActions(map, initialState);
export default combinedReducer;
