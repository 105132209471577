/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MultiLingualText } from './MultiLingualText';
import type { UUIDRequired } from './UUIDRequired';

/**
 * A per-organization defined conclusion.
 *
 */
export type Conclusion = {
    approval_conclusion: boolean;
    conclusion_category: Conclusion.conclusion_category;
    deprecated: boolean;
    full_name: MultiLingualText;
    id: UUIDRequired;
    inspector_conclusion: boolean;
    presentation_order: number;
    priority: number;
    short_name: MultiLingualText;
};

export namespace Conclusion {

    export enum conclusion_category {
        PENDING = 'pending',
        REJECTED = 'rejected',
        APPROVED = 'approved',
        NOT_SET = 'not_set',
    }


}

