import classnames from 'classnames';
import React, { Fragment, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { WeekviewUser } from '../../../../../../../backend_api/models';
import { Inspection } from '../../../../../types';
import { CompactWeekviewPopup, InspectionTypeMarker } from '../../../../inspectionTypes/InspectionTypes';
import { InspectionsWeekviewOrderExtended } from '../WeekView2';

type CompactItemProps = {
    orders: InspectionsWeekviewOrderExtended[];
    user: WeekviewUser;
    isOpen: boolean;
    hasInspectionTypes: boolean;
};

const CompactItem = (props: CompactItemProps): React.ReactElement => {
    const item = useMemo(() => {
        const orders = props.orders;
        const isOpen = props.isOpen;
        const name = orders[0] && orders[0].inspections.length > 0 && orders[0].inspections[0].supplier_entity ? orders[0].inspections[0].supplier_entity.name : '';
        let inspections = [];
        orders.forEach((order) => {
            inspections = inspections.concat(order.inspections);
        });
        let inspectionsCnt = 0;
        Object.entries(orders).forEach((obj) => {
            if (obj[1].inspections[0] && obj[1].inspections[0].combined) {
                inspectionsCnt += obj[1].inspections[0].sub_inspections.length;
            } else {
                inspectionsCnt += obj[1].inspections.length;
            }
        });
        const types = <div className={twMerge('w-10 items-center justify-center block')}>{getTypes(inspections)}</div>;
        const popup = <CompactWeekviewPopup trigger={types} supplierObj={orders} name={name} orderCnt={orders.length} user={props.user} hasInspectionTypes={props.hasInspectionTypes} />;
        const compact = <div style={{ cursor: isOpen ? 'n-resize' : 's-resize' }} className={classnames('bg-week-view-compact space-x-2 mt-1 flex items-center justify-between p-1 text-smx text-white pl-2', isOpen ? 'rounded-t border-b' : 'rounded')}>
            <div className='supplier truncate w-5/7'>
                {name}
            </div>
            <div className='cnt flex whitespace-nowrap mr-1'>{inspectionsCnt} / {orders.length}</div><div>{popup}</div>
        </div>;
        return <Fragment>{compact}</Fragment>;
    }, [props.orders, props.user, props.hasInspectionTypes, props.isOpen]);
    return item;
}

const getTypes = (inspections: Inspection[]): React.ReactElement | string => {
    const typesElement = '';
    if (inspections.length > 0) {
        return <InspectionTypeMarker inspections={inspections} type={'compactWeekView'} />
    }
    return typesElement;
};

export default CompactItem;
