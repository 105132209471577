import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { TextWithTranslation } from '../../../backend_api/models';
import { deepCopy } from '../../../base/utils';
import QarmaInput from './QarmaInput';
import QarmaTextArea from './QarmaTextArea';
import TranslatedLabel from './TranslatedLabel';

type Props = {
    value: TextWithTranslation;
    onChange?(value: TextWithTranslation);
    onBlur?();
    onFocus?();
    type: string;
    className?: string;
    disabled?: boolean;
    placeholder?: string
    name?: string
}

const TranslatedTextField = (props: Props) => {
    const { type = 'text' } = props
    const [editing, setEditing] = useState<boolean>(false)

    const onBlur = () => {
        setEditing(false)
        props.onBlur()
    }
    const onFocus = () => {
        setEditing(true);
        props.onFocus && props.onFocus();
    }
    const onClick = () => {
        setEditing(!props.disabled)
    }

    const onChange = (env, item) => {
        const textWithTranslation = deepCopy(props.value)
        textWithTranslation.text = item.value
        props.onChange(textWithTranslation)
    }

    if (editing && type == 'text-area') {
        return <div>
            <QarmaTextArea
                value={props.value.text}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                className={props.className}
                name={props.name}
                focus={true}
            />
        </div>
    }
    if (editing && type == 'text') {
        return <div>
            <QarmaInput
                value={props.value.text}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                type={type}
                className={props.className}
                name={props.name}
                focus={true}
            />
        </div>
    }
    else {
        return <div className='flex flex-row' data-test-id={props.name} onClick={onClick}>
            <TranslatedLabel text={props.value} placeholder={props.placeholder} />{!props.disabled && <Icon className='px-1' name="pencil" />}
        </div>
    }
}

export default TranslatedTextField