/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * History entry import job reference
 * @export
 * @interface HistoryEntryImportJobReference
 */
export interface HistoryEntryImportJobReference {
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryImportJobReference
     */
    import_job_id: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntryImportJobReference
     */
    source_reference_type: HistoryEntryImportJobReferenceSourceReferenceTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum HistoryEntryImportJobReferenceSourceReferenceTypeEnum {
    Audit = 'audit',
    User = 'user',
    ImportJob = 'import_job'
}

export function HistoryEntryImportJobReferenceFromJSON(json: any): HistoryEntryImportJobReference {
    return HistoryEntryImportJobReferenceFromJSONTyped(json, false);
}

export function HistoryEntryImportJobReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntryImportJobReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'import_job_id': json['import_job_id'],
        'source_reference_type': json['source_reference_type'],
    };
}

export function HistoryEntryImportJobReferenceToJSON(value?: HistoryEntryImportJobReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'import_job_id': value.import_job_id,
        'source_reference_type': value.source_reference_type,
    };
}


