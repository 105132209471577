/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DisplayCustomFieldNumberValue = {
    number: string | null;
    type: DisplayCustomFieldNumberValue.type;
};

export namespace DisplayCustomFieldNumberValue {

    export enum type {
        NUMBER = 'Number',
    }


}

