/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 */
export type AuditReportCustomFieldsNumber = {
    number: string | null;
    previous_number: string | null;
    type: AuditReportCustomFieldsNumber.type;
};

export namespace AuditReportCustomFieldsNumber {

    export enum type {
        NUMBER = 'Number',
    }


}

