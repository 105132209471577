/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 77.0.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuditEventType {
    ApprovalFlowUpdated = 'approval_flow_updated',
    ApprovalStepUpdated = 'approval_step_updated',
    AttachmentEvent = 'attachment_event',
    CommentEvent = 'comment_event',
    FinalConclusionEvent = 'final_conclusion_event'
}

export function AuditEventTypeFromJSON(json: any): AuditEventType {
    return AuditEventTypeFromJSONTyped(json, false);
}

export function AuditEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEventType {
    return json as AuditEventType;
}

export function AuditEventTypeToJSON(value?: AuditEventType | null): any {
    return value as any;
}

