import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { DashboardPopupInspection } from '../../../../backend_api/models';
import { setLayoutAttribute } from '../../../../base/baseSlice';
import { Breakpoints } from '../../../../base/config';
import { getPrettyDate, useIsMobileDevice, useMinWidthBreak } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { TextItem } from '../../../globals/components';
import { UserInitialsText } from '../../../users/components/Widgets';
import { getInspectionDate, getInspectionETD, getInspectionReportLink, getInspectionsByInspectionSequence, inspectionIsReport } from '../../Utils';
import { Inspection } from '../../types';
import { CombinedInspectionLabel } from '../combine/CombinedInspections';
import { InspectionTypeStatus, PlainStatus } from '../edit/Status';
import OriginalQtyInfo from '../report/OriginalQtyInfo';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import { getInspectionLink } from './InspectionTypes';

type DashboardPopupProps = {
    inspections: DashboardPopupInspection[] | Inspection[];
    trigger: React.ReactElement;
    header?: React.ReactElement;
    inline?: boolean;
    subHeader?: React.ReactElement;
    className?: string;
    selectedInspectionId?: string;
};

export const DashboardPopup = (props: DashboardPopupProps): React.ReactElement => {
    const { trigger, inspections, header, subHeader, inline = false, selectedInspectionId } = props;
    const [show, setShow] = useState(false);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    let cnt = 0;
    const hide = (): void => {
        setShow(false);
        dispatch(setLayoutAttribute('bodyFixed', false));
    }
    const content = <>
        <h4 className='break-words w-22/24'>{header ? header : (<div><FormattedMessage id='inspections.inspection_types' />. {inspections[0].item_name}</div>)}</h4>
        {subHeader && subHeader}
        {inspections[0] && getInspectionETD(inspections) !== '-' && <div className='pb-4'>
            <TextItem labelFixed={true} text={getPrettyDate(getInspectionETD(inspections))} label={intl.formatMessage({ id: 'inspections.inspection_types.etd' })} />
        </div>}
        {Object.values(getInspectionsByInspectionSequence(inspections)).map((inspectionArr: any, i: number) => {
            return inspectionArr.map((inspection: DashboardPopupInspection) => {
                cnt++;
                const isLast = cnt < inspections.length;
                const isReport = inspectionIsReport(inspection);
                const linkPath = isReport ? '/inspections/reports/' : '/inspections/edit_inspection/';
                const reportLink = getInspectionReportLink(inspection);
                const toLink = getInspectionLink(isReport, reportLink, inspection.order_id, inspections[cnt - 1], linkPath);
                const inspType = <InspectionTypeStatus inspection={inspection} isTag={true} />;
                const initials = <UserInitialsText user={inspection.assigned_user} />;
                const qty = <OriginalQtyInfo inspection={inspection} type='dashboard' />;
                const labels = <div className='flex space-x-2 justify-center'>
                    <CombinedInspectionLabel className='' containerClassName='m-0 p-0' type='short' inspection={inspection} />
                    <SplitShipmentsMarker inspection={inspection} className='px-2 py-0' />
                </div>;
                const link = <Link className='link noUnderline' to={toLink}>{isReport ? <FormattedMessage id='inspections.inspection_types.view' /> : <FormattedMessage id='inspections.inspection_types.edit' />}</Link>;
                return (
                    <Fragment key={i} >
                        {<Link key={i} className='item flex-wrap flex md:flex text-primary hover:text-primary' to={toLink}>
                            {inspection.inspection_id && <div className={twMerge('p-1 type flex-wrap flex md:flex w-full md:space-x-1 pb-8 md:pb-0', selectedInspectionId === inspection.inspection_id && 'font-bold')}>
                                {!isMinMD && <div className={twMerge('grid grid-cols-12 w-full items-center gap-2 text-sm')}>
                                    <div className='flex col-span-1'>{inspType}</div>
                                    <div className='flex col-span-3'>{inspection.inspection_type && inspection.inspection_type.name || '-'}</div>
                                    <div className='flex col-span-1'>{initials}</div>
                                    <div className='flex col-span-3'>{getInspectionDate([inspection], intl.locale)}</div>
                                    <div className='flex col-span-4'><PlainStatus classNames='h-5 w-full' inspection={inspection} /></div>
                                    <div className='flex col-span-10 items-center space-x-1'><div className='flex space-x-1'>{labels}</div><div className='flex'>{qty}</div></div>
                                    <div className='flex col-span-2 justify-end pr-2'>{link}</div>
                                </div>
                                }
                                {isMinMD && <>
                                    <div className='pt-1 md:pt-0 w-2/24 md:w-12 border-0 flex'>{inspType}</div>
                                    <div className='w-6/24 md:w-40 border-0'>{inspection.inspection_type && inspection.inspection_type.name || '-'}</div>
                                    <div className='w-4/24 md:w-10 border-0'>{initials}</div>
                                    <div className='w-5/24 md:w-32 border-0'>{getInspectionDate([inspection], intl.locale)}</div>
                                    <div className='pt-1 md:pt-0 w-6/24 md:w-32'><PlainStatus classNames='h-5 md:h-6 w-full' inspection={inspection} /></div>
                                    <div className='hidden w-4/24 md:flex md:w-32'>{qty}</div>
                                    <div className='flex space-x-1 md:w-20 w-21/24 items-center'>
                                        {labels}
                                    </div>
                                    <div className='text-sm'>{link}</div>
                                </>}
                            </div>}
                        </Link>}
                        {isLast && <div className='hidden justify-center text-secondary md:flex items-center w-6 -mt-1 -mb-1'>|</div>}
                    </Fragment>
                );
            });
        })}
        {!isMinMD && <div className='link noUnderline' onClick={(): void => hide()}><FormattedMessage id='inspections.inspection_types.hide' /></div>}
    </>;
    if (useIsMobileDevice()) {
        return (
            <Modal
                trigger={trigger}
                onOpen={(): void => setShow(true)}
                onClose={(): void => setShow(false)}
                closeOnDimmerClick={true}
                closeIcon={true}
                open={show}>
                <Modal.Content>
                    {content}
                </Modal.Content>
            </Modal>
        )
    }
    return (
        inline ? content : <Popup
            trigger={trigger}
            className=''
            hoverable
            flowing
            open={show}
            onOpen={(): void => {
                setShow(true);
            }}
            onClose={(): void => {
                setShow(false);
            }}
            mouseLeaveDelay={400}
            mouseEnterDelay={400}
        >
            <Popup.Content className='flex flex-col mb-4'>
                {content}
            </Popup.Content>
        </Popup >
    );
}

export default DashboardPopup;

