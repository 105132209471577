import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import { request2 } from '../../base/api';
import { catchException } from '../errorHandling/handler';
import { sendErrorMessage } from '../messages/actions';

export const exportAudits = createAsyncThunk<any, any>(
    'exportAudit',
    async (data: any, { dispatch, rejectWithValue }) => {
        const paramString = parseQueryString(data)
        console.log(paramString)
        const rq = await request2('export/audits?' + paramString);
        if (!rq.ok) {
            console.log('There was an error exporting audits', rq);
            catchException('exportAudit', {
                endpoint: 'export/audits?',
                request: 'export/audits?' + paramString,
                status: rq.status,
            }, { error: rq });
            dispatch(sendErrorMessage(['error_message.export_failed'], 3000));
            rejectWithValue(rq as Response);
        }
    });

export const exportInspections = createAsyncThunk<any, any>(
    'exportInspections',
    async (data: any, { dispatch, rejectWithValue }) => {
        const paramString = parseQueryString(data)
        const rq = await request2('export/inspections?' + paramString);
        if (!rq.ok) {
            console.log('There was an error exporting inspections', rq);
            catchException('exportInspections', {
                endpoint: 'export/inspections?',
                request: 'export/inspections?' + paramString,
                status: rq.status,
            }, { error: rq });
            dispatch(sendErrorMessage(['error_message.export_failed'], 3000));
            rejectWithValue(rq as Response);
        }
    });

export const exportSuppliers = createAsyncThunk<any, any>(
    'exportSuppliers',
    async (data: any, { dispatch, rejectWithValue }) => {
        const paramString = parseQueryString(data);
        const rq = await request2('export/suppliers?' + paramString);
        if (!rq.ok) {
            console.log('There was an error exporting suppliers', rq);
            catchException('exportSuppliers', {
                endpoint: 'export/suppliers?',
                request: 'export/suppliers?' + paramString,
                status: rq.status,
            }, { error: rq });
            dispatch(sendErrorMessage(['error_message.export_failed'], 3000));
            rejectWithValue(rq as Response);
        }
    });


    const parseQueryString = (params: any) => {
        return qs.stringify(params, { encode: true, arrayFormat: 'brackets', skipNulls: true })
    }