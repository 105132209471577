import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { preventDefaultAndStopPropagation } from '../../../utils';
import Next from '../items/Next';
import Previous from '../items/Previous';

type Props = {
    className?: string;
    nextFile();
    previousFile();
    fileCount: number;
    currentFileNumber?: number;
    onCountChanged?(n: number): void;
};

const FileCounter = (props: Props): React.ReactElement => {
    const { className, previousFile, nextFile, fileCount, currentFileNumber, onCountChanged } = props;
    const intl = useIntl();
    const [_currentFileNumber, setCurrentFileNumber] = useState<number>(currentFileNumber || 0);
    useEffect(() => {
        setCurrentFileNumber(currentFileNumber);
    }, [currentFileNumber]);
    useEffect(() => {
        onCountChanged(_currentFileNumber);
    }, [_currentFileNumber]);
    return <div className='flex w-32 items-center text-secondary'>
        <button onClick={(e) => {
            setCurrentFileNumber(_currentFileNumber - 1);
            previousFile();
            preventDefaultAndStopPropagation(e);
        }}
            disabled={_currentFileNumber === 0}>
            <Previous disabled={_currentFileNumber === 0} className='p-0' />
        </button>
        <div className='whitespace-nowrap text-sm w-11 flex justify-center'>{_currentFileNumber + 1} of {fileCount}</div>
        <button
            onClick={(e) => {
                setCurrentFileNumber(_currentFileNumber + 1);
                nextFile();
                preventDefaultAndStopPropagation(e);
            }}
            disabled={_currentFileNumber >= fileCount - 1}
        >
            <Next className='p-0' disabled={_currentFileNumber >= fileCount - 1} />
        </button>
    </div>
}
export default FileCounter;
