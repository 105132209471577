import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { DropdownValue, ShowStatus } from '../types';

interface StatusFilterProps {
    selectedStatus: ShowStatus;
    handleChange(item: DropdownValue, append: boolean, apply: boolean);
}

function StatusFilter(props: StatusFilterProps) {
    const {selectedStatus, handleChange} = props;
    const intl = useIntl();
    const statusFilterOptions = [
        { key: 'active', value: 'active', text: intl.formatMessage({id: "users.active"}) },
        { key: 'all', value: 'all', text: intl.formatMessage({id: "users.all"}) },
        { key: 'disabled', value: 'disabled', text: intl.formatMessage({id: "users.disabled"}) }
    ];

    const [status, setStatus] = React.useState<any>(selectedStatus);

    function updateSelectedStatus({value}: { value?: DropdownValue }) {
        setStatus(value.toString());
        handleChange(value, false, true);
    }

    return (
        <div className='filter approver'>
            <div className='label'><FormattedMessage id='users.filters.select_status' /></div>
            <div className='content'>
                <Fragment>
                    <Dropdown
                        name='groups'
                        options={statusFilterOptions}
                        selection
                        value={selectedStatus}
                        onChange={(evt, item): void => {
                            updateSelectedStatus(item);
                        }}
                    />
                </Fragment>
            </div>
        </div>
    )
}

export default StatusFilter;