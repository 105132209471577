import { BasicUser, FileResource } from '../../backend_api/models';
import { CustomConclusion } from '../approval/types';
import { Checkpoint } from '../checklists/types';
import { ConclusionTypes, Defect } from '../inspections/types';


export type Comment = {
    attached_file_resources?: Array<FileResource>;
    user: BasicUser;
    timestamp: string;
    snapshot: Snapshot;
    notification_sent: boolean;
    parent_id: string;
    inspector_comment: boolean;
    inspection_id: string;
    image_url: string;
    id: string;
    defect_id: string;
    defect: Defect;
    comment: string;
    comment2: string;
    status: string;
    checkpoint_id: string;
    checkpoint: Checkpoint;
    follow_up_task_id: string;
    follow_up_task: {
        title: string;
        status: 'open' | 'resolved' | 'reopened';
        id: string;
    };
    attachments: [{
        name: string;
        url: string;
    }];
    approval_step_id: string;
    translated_comment?: {
        [language: string]: { commentText: string };
    };
    comment_language: string;
};

export type Snapshot = SnapshotV2 | SnapshotV3;

export type SnapshotV2 = SnapshotBase & {
    conclusion: ConclusionTypes;
}

export type SnapshotV3 = SnapshotBase & {
    conclusion: CustomConclusion;
}

type SnapshotBase = {
    type: {
        version: string;
        id: string;
    };
    intended_user: BasicUser;
    intended_user_id: string;
}

export type CommentsState = {
    comments: { [commentId: string]: Comment[] };
    showTranslatedComments: boolean;
    hasTranslatedComments: boolean;
    showTranslatedCommentsAsked: boolean;
    isFetching: boolean;
    isSaving: boolean;

    users: BasicUser[];
}
export type CommentsState2 = {
    app: {
        comments: {
            comments: { [commentId: string]: Comment[] };
            showTranslatedComments: boolean;
            hasTranslatedComments: boolean;
            showTranslatedCommentsAsked: boolean;
            isFetching: boolean;
            isSaving: boolean;
        };
        users: BasicUser[];
    };
};


export type CommentTarget = 'inspection' | 'defect' | 'checkpoint' | 'follow_up_task' | 'defect_image' | 'checkpoint_image' | 'defect_video' | 'checkpoint_video';
export type CommentTypes = 'inspection' | 'defect' | 'checkpoint' | 'follow_up_task' | 'defect_image' | 'checkpoint_image' | 'defect_video' | 'checkpoint_video';

export const GET_COMMENTS_REQUEST = '@@comments/GET_COMMENTS_REQUEST';
export type GET_COMMENTS_REQUEST = {
    inspectionId: string;
    isFetching: boolean;
};

export const GET_COMMENTS_SUCCESS = '@@comments/GET_COMMENTS_SUCCESS';
export type GET_COMMENTS_SUCCESS = {
    inspectionId: string;
    comments: { [commentId: string]: Comment[] };
};

export const GET_COMMENTS_FAILURE = '@@comments/GET_COMMENTS_FAILURE';

export const CLEAR_COMMENTS = '@@comments/CLEAR_COMMENTS';

export const ADD_COMMENT_REQUEST = '@@comments/ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = '@@comments/ADD_COMMENT_SUCCESS';
export type ADD_COMMENT_SUCCESS = {
    comments: { [commentId: string]: Comment[] };
}
export const ADD_COMMENT_FAILURE = '@@comments/ADD_COMMENT_FAILURE';

export const REMOVE_COMMENT_REQUEST = '@@comments/REMOVE_COMMENT_REQUEST';
export const REMOVE_COMMENT_SUCCESS = '@@comments/REMOVE_COMMENT_SUCCESS';
export const REMOVE_COMMENT_FAILURE = '@@comments/REMOVE_COMMENT_FAILURE';

export const REMOVE_COMMENT_ATTACHMENT_REQUEST = '@@comments/REMOVE_COMMENT_ATTACHMENT_REQUEST';

export const ADD_DEFECT_COMMENT_SUCCESS = 'ADD_DEFECT_COMMENT_SUCCESS';
export type ADD_DEFECT_COMMENT_SUCCESS = {
    comments: { [commentId: string]: Comment[] };
};

export const HAS_TRANSLATED_COMMENTS = 'HAS_TRANSLATED_COMMENTS';
export type HAS_TRANSLATED_COMMENTS = {
    hasTranslatedComments: boolean;
};

export const SHOW_TRANSLATED_COMMENTS = 'SHOW_TRANSLATED_COMMENTS';
export type SHOW_TRANSLATED_COMMENTS = {
    showTranslatedComments: boolean;
};
export const SHOW_TRANSLATED_COMMENTS_ASKED = 'SHOW_TRANSLATED_COMMENTS_ASKED';
export type SHOW_TRANSLATED_COMMENTS_ASKED = {
    asked: boolean;
};
