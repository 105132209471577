import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { FormField, Modal } from 'semantic-ui-react';
import { Audit } from '../../../../backend_api_2';
import Button from '../../../../base/components/basic/Button';
import Input from '../../../../base/components/basic/Input';
import { ListItem, ListItems, MainActionEnum } from '../../../../base/ui/components/listitems/ListItem';
import { isMobileDevice } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { TEMPORARY_USER_MODE } from '../../../authentication/authenticationSlice';
import { getLocale } from '../../../globals/selectors';
import { temporaryUserUpdate } from '../../slices/auditSlice';
import { getAppLink, isAuditImmutable } from './PerformAuditPage';
import { getAuditContentByIdSelector } from '../../selectors/auditSelectors';

export function WebAuditInfo(props: {
    audit: Audit
}) {
    const {audit} = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const locale = useSelector(getLocale);
    const defaultLocaleId =  locale?.selected?.id || "";
   
    const temporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE);
    const auditWithContent = useSelector(getAuditContentByIdSelector);

    const [showOpenInAppModal, setShowOpenInAppModal] = React.useState(false);
    const [showEditAuditorModal, setShowEditAuditorModal] = React.useState(false);
    const [firstName, setFirstName] = React.useState(audit.assigned_user?.firstname);
    const [lastName, setLastName] = React.useState(audit.assigned_user?.lastname);
    const [email, setEmail] = React.useState(audit.assigned_user?.email);

    function doUpdateAuditor() {
        if(!isAuditImmutable(audit)) {
            dispatch(temporaryUserUpdate({
                email, firstname: firstName, lastname: lastName, locale_id: defaultLocaleId
            }))
        }
        setShowEditAuditorModal(false);
    }

    React.useEffect(() => {
        if(auditWithContent?.audit?.locked) {
            setShowOpenInAppModal(false);
        }
    }, [auditWithContent]);

    return (
        <div>
            <ListItems className={'border rounded border-b-0 bg-white'}>                
                <ListItem
                    icon={'user_user'}
                    meta={
                        { metaAbove: { firstItem: intl.formatMessage({id: 'audits.audit_type.label.auditor'}), }}
                    }
                    mainAction={temporaryUserMode ? { type: MainActionEnum.Edit, onMainActionClick: () => setShowEditAuditorModal(true) } : null}
                >
                    <div className='flex flex-col'>
                        <div>{firstName} {lastName}</div>
                        <div>{email}</div>
                    </div>
                </ListItem>
                {
                    audit.target_production_unit &&
                    <ListItem
                        icon={'user_production-unit'}
                        meta={
                            { metaAbove: { firstItem: intl.formatMessage({id: 'production_units.production_unit.label'}), }}
                        }
                    >
                        <div className='flex flex-col'>
                            <div>{audit.target_production_unit.number} - {audit.target_production_unit.name}</div>
                        </div>
                    </ListItem>
                }
                {
                    audit.target_supplier &&
                    <ListItem
                        icon={'user_supplier'}
                        meta={
                            { metaAbove: { firstItem: intl.formatMessage({id: 'audit_list.header.supplier'}), }}
                        }
                    >
                        <div className='flex flex-col'>
                            <div>{audit.target_supplier.number} - {audit.target_supplier.name}</div>
                        </div>
                    </ListItem>
                }
                <ListItem>
                    <Button medium onClick={() => setShowOpenInAppModal(true)}>
                        <FormattedMessage id="web_based_audit.edit_in_app" />
                    </Button>
                </ListItem>
            </ListItems>

            <Modal
                open={showOpenInAppModal}
                onOpen={() => setShowOpenInAppModal(true)}
                onClose={() => setShowOpenInAppModal(false)}
                closeOnDimmerClick
                closeOnEscape
                size='tiny'
            >
                <Modal.Header><FormattedMessage id='web_based_audit.open_in_app.title'/></Modal.Header>
                <Modal.Content>
                    {
                        isMobileDevice() ?
                        <div className='flex flex-row items-center bg-gray-50 rounded-md border px-6'>
                             <Button
                                className="justify-center text-base my-2 shrink-0 mr-4"
                                primary
                                large
                                behaveAsLink
                                onClick={() => window.location.href = getAppLink({auditId: audit.id})}
                            >
                                <FormattedMessage id="web_based_audit.go_to_app" />
                            </Button>
                            <div className='flex flex-col pl-6 justify-between'>
                                <div className='font-bold'><FormattedMessage id='web_based_audit.open_in_app.description_1'/></div>
                                <div className='text-sm'><FormattedMessage id='web_based_audit.open_in_app.description_2'/></div>
                            </div>
                        </div>
                        :
                        <div className='flex flex-row items-center bg-gray-50 rounded-md border px-6'>
                            <QRCode value={getAppLink({auditId: audit.id})} size={256}/>
                            <div className='flex flex-col pl-6 justify-between'>
                                <div className='font-bold'><FormattedMessage id='web_based_audit.open_in_app.description_1'/></div>
                                <div className='text-sm'><FormattedMessage id='web_based_audit.open_in_app.description_2'/></div>
                            </div>
                        </div>
                    }
                    
                </Modal.Content>
                <Modal.Actions className='flex justify-end'>
                    <Button className='' onClick={() => setShowOpenInAppModal(false)}><FormattedMessage id='web_based_audit.open_in_app.continue_in_browser'/></Button>
                </Modal.Actions>
            </Modal>

            <Modal
                open={showEditAuditorModal}
                onOpen={() => setShowEditAuditorModal(true)}
                onClose={() => setShowEditAuditorModal(false)}
                closeOnDimmerClick
                closeOnEscape
                size='tiny'
            >
                <Modal.Header><FormattedMessage id='web_based_audit.edit_auditor.title'/></Modal.Header>
                <Modal.Content>
                    <Fragment>
                        <div className="flex flex-row justify-between items-center">
                            <FormField className="mr-4">
                                <label className='text-sm text-gray-500'><FormattedMessage id='profile.first_name'/></label>
                                <Input
                                    type="text"
                                    name="firstname"
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    value={firstName}
                                    iconPosition="left"
                                    iconClass="text-brand text-lg w-12 justify-center flex"
                                />
                            </FormField>
                            <FormField className="">
                                <label className='text-sm text-gray-500'><FormattedMessage id='profile.last_name'/></label>
                                <Input
                                    type="text"
                                    name="lastname"
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    value={lastName}
                                    iconPosition="left"
                                    iconClass="text-brand text-lg w-12 justify-center flex"
                                />
                            </FormField>
                        </div>

                        <FormField>
                            <label className='text-sm text-gray-500'><FormattedMessage id='profile.email'/></label>
                            <Input
                                type="text"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                iconPosition="left"
                                iconClass="text-brand w-12 justify-center flex"
                            />
                        </FormField>
                    </Fragment>
                </Modal.Content>
                <Modal.Actions className='flex justify-end'>
                    <Button primary className='' onClick={doUpdateAuditor}><FormattedMessage id='profile.save'/></Button>
                    <Button className='' onClick={() => setShowEditAuditorModal(false)}><FormattedMessage id='users.cancel'/></Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}