/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 97.2.5 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditLink,
    AuditLinkFromJSON,
    AuditLinkFromJSONTyped,
    AuditLinkToJSON,
    BasicUser,
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
    CorrectiveActionApprovedEvent,
    CorrectiveActionApprovedEventFromJSON,
    CorrectiveActionApprovedEventFromJSONTyped,
    CorrectiveActionApprovedEventToJSON,
    CorrectiveActionDescriptionOfResolutionEvent,
    CorrectiveActionDescriptionOfResolutionEventFromJSON,
    CorrectiveActionDescriptionOfResolutionEventFromJSONTyped,
    CorrectiveActionDescriptionOfResolutionEventToJSON,
    CorrectiveActionEditedEvent,
    CorrectiveActionEditedEventFromJSON,
    CorrectiveActionEditedEventFromJSONTyped,
    CorrectiveActionEditedEventToJSON,
    CorrectiveActionEvidenceAddedEvent,
    CorrectiveActionEvidenceAddedEventFromJSON,
    CorrectiveActionEvidenceAddedEventFromJSONTyped,
    CorrectiveActionEvidenceAddedEventToJSON,
    CorrectiveActionEvidenceDeletedEvent,
    CorrectiveActionEvidenceDeletedEventFromJSON,
    CorrectiveActionEvidenceDeletedEventFromJSONTyped,
    CorrectiveActionEvidenceDeletedEventToJSON,
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
    NotifiedEvent,
    NotifiedEventFromJSON,
    NotifiedEventFromJSONTyped,
    NotifiedEventToJSON,
    NotifiedOnExpiryEvent,
    NotifiedOnExpiryEventFromJSON,
    NotifiedOnExpiryEventFromJSONTyped,
    NotifiedOnExpiryEventToJSON,
    NotifiedOnOverdueEvent,
    NotifiedOnOverdueEventFromJSON,
    NotifiedOnOverdueEventFromJSONTyped,
    NotifiedOnOverdueEventToJSON,
    NotifiedResponsibleEvent,
    NotifiedResponsibleEventFromJSON,
    NotifiedResponsibleEventFromJSONTyped,
    NotifiedResponsibleEventToJSON,
    UpdatedByImporter,
    UpdatedByImporterFromJSON,
    UpdatedByImporterFromJSONTyped,
    UpdatedByImporterToJSON,
} from './';

/**
 * An event in the history of a Corrective Action.
 * @export
 * @interface CorrectiveActionEvent
 */
export interface CorrectiveActionEvent {
    /**
     * 
     * @type {CorrectiveActionApprovedEvent}
     * @memberof CorrectiveActionEvent
     */
    approved_event?: CorrectiveActionApprovedEvent;
    /**
     * 
     * @type {Array<AuditLink>}
     * @memberof CorrectiveActionEvent
     */
    audits_added_event?: Array<AuditLink>;
    /**
     * 
     * @type {BasicUser}
     * @memberof CorrectiveActionEvent
     */
    caller: BasicUser;
    /**
     * 
     * @type {string}
     * @memberof CorrectiveActionEvent
     */
    comment_event?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CorrectiveActionEvent
     */
    corrective_action_id: string;
    /**
     * Event on a corrective action when corrective action is created by the importer.
     * @type {object}
     * @memberof CorrectiveActionEvent
     */
    created_by_importer?: object;
    /**
     * Event on a corrective action when corrective action is created by migrating follow up tasks.
     * @type {object}
     * @memberof CorrectiveActionEvent
     */
    created_by_migration?: object;
    /**
     * Describes creation of a CA.
     * @type {object}
     * @memberof CorrectiveActionEvent
     */
    created_event?: object;
    /**
     * 
     * @type {CorrectiveActionDescriptionOfResolutionEvent}
     * @memberof CorrectiveActionEvent
     */
    description_of_resolution_event?: CorrectiveActionDescriptionOfResolutionEvent;
    /**
     * 
     * @type {CorrectiveActionEditedEvent}
     * @memberof CorrectiveActionEvent
     */
    edit_event?: CorrectiveActionEditedEvent;
    /**
     * 
     * @type {EventType}
     * @memberof CorrectiveActionEvent
     */
    event_type: EventType;
    /**
     * 
     * @type {Array<CorrectiveActionEvent>}
     * @memberof CorrectiveActionEvent
     */
    events?: Array<CorrectiveActionEvent>;
    /**
     * 
     * @type {CorrectiveActionEvidenceDeletedEvent}
     * @memberof CorrectiveActionEvent
     */
    evidence_deleted_event?: CorrectiveActionEvidenceDeletedEvent;
    /**
     * 
     * @type {CorrectiveActionEvidenceAddedEvent}
     * @memberof CorrectiveActionEvent
     */
    evidence_event?: CorrectiveActionEvidenceAddedEvent;
    /**
     * 
     * @type {string}
     * @memberof CorrectiveActionEvent
     */
    id?: string;
    /**
     * 
     * @type {NotifiedEvent}
     * @memberof CorrectiveActionEvent
     */
    notified_event?: NotifiedEvent;
    /**
     * 
     * @type {NotifiedOnExpiryEvent}
     * @memberof CorrectiveActionEvent
     */
    notified_on_expiry_event?: NotifiedOnExpiryEvent;
    /**
     * 
     * @type {NotifiedOnOverdueEvent}
     * @memberof CorrectiveActionEvent
     */
    notified_on_overdue_event?: NotifiedOnOverdueEvent;
    /**
     * 
     * @type {NotifiedResponsibleEvent}
     * @memberof CorrectiveActionEvent
     */
    responsible_notified_event?: NotifiedResponsibleEvent;
    /**
     * 
     * @type {number}
     * @memberof CorrectiveActionEvent
     */
    sequence: number;
    /**
     * 
     * @type {Date}
     * @memberof CorrectiveActionEvent
     */
    timestamp: Date | null;
    /**
     * 
     * @type {UpdatedByImporter}
     * @memberof CorrectiveActionEvent
     */
    updated_by_importer?: UpdatedByImporter;
}

export function CorrectiveActionEventFromJSON(json: any): CorrectiveActionEvent {
    return CorrectiveActionEventFromJSONTyped(json, false);
}

export function CorrectiveActionEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approved_event': !exists(json, 'approved_event') ? undefined : CorrectiveActionApprovedEventFromJSON(json['approved_event']),
        'audits_added_event': !exists(json, 'audits_added_event') ? undefined : ((json['audits_added_event'] as Array<any>).map(AuditLinkFromJSON)),
        'caller': BasicUserFromJSON(json['caller']),
        'comment_event': !exists(json, 'comment_event') ? undefined : json['comment_event'],
        'corrective_action_id': json['corrective_action_id'],
        'created_by_importer': !exists(json, 'created_by_importer') ? undefined : json['created_by_importer'],
        'created_by_migration': !exists(json, 'created_by_migration') ? undefined : json['created_by_migration'],
        'created_event': !exists(json, 'created_event') ? undefined : json['created_event'],
        'description_of_resolution_event': !exists(json, 'description_of_resolution_event') ? undefined : CorrectiveActionDescriptionOfResolutionEventFromJSON(json['description_of_resolution_event']),
        'edit_event': !exists(json, 'edit_event') ? undefined : CorrectiveActionEditedEventFromJSON(json['edit_event']),
        'event_type': EventTypeFromJSON(json['event_type']),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(CorrectiveActionEventFromJSON)),
        'evidence_deleted_event': !exists(json, 'evidence_deleted_event') ? undefined : CorrectiveActionEvidenceDeletedEventFromJSON(json['evidence_deleted_event']),
        'evidence_event': !exists(json, 'evidence_event') ? undefined : CorrectiveActionEvidenceAddedEventFromJSON(json['evidence_event']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'notified_event': !exists(json, 'notified_event') ? undefined : NotifiedEventFromJSON(json['notified_event']),
        'notified_on_expiry_event': !exists(json, 'notified_on_expiry_event') ? undefined : NotifiedOnExpiryEventFromJSON(json['notified_on_expiry_event']),
        'notified_on_overdue_event': !exists(json, 'notified_on_overdue_event') ? undefined : NotifiedOnOverdueEventFromJSON(json['notified_on_overdue_event']),
        'responsible_notified_event': !exists(json, 'responsible_notified_event') ? undefined : NotifiedResponsibleEventFromJSON(json['responsible_notified_event']),
        'sequence': json['sequence'],
        'timestamp': (json['timestamp'] === null ? null : new Date(json['timestamp'])),
        'updated_by_importer': !exists(json, 'updated_by_importer') ? undefined : UpdatedByImporterFromJSON(json['updated_by_importer']),
    };
}

export function CorrectiveActionEventToJSON(value?: CorrectiveActionEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approved_event': CorrectiveActionApprovedEventToJSON(value.approved_event),
        'audits_added_event': value.audits_added_event === undefined ? undefined : ((value.audits_added_event as Array<any>).map(AuditLinkToJSON)),
        'caller': BasicUserToJSON(value.caller),
        'comment_event': value.comment_event,
        'corrective_action_id': value.corrective_action_id,
        'created_by_importer': value.created_by_importer,
        'created_by_migration': value.created_by_migration,
        'created_event': value.created_event,
        'description_of_resolution_event': CorrectiveActionDescriptionOfResolutionEventToJSON(value.description_of_resolution_event),
        'edit_event': CorrectiveActionEditedEventToJSON(value.edit_event),
        'event_type': EventTypeToJSON(value.event_type),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(CorrectiveActionEventToJSON)),
        'evidence_deleted_event': CorrectiveActionEvidenceDeletedEventToJSON(value.evidence_deleted_event),
        'evidence_event': CorrectiveActionEvidenceAddedEventToJSON(value.evidence_event),
        'id': value.id,
        'notified_event': NotifiedEventToJSON(value.notified_event),
        'notified_on_expiry_event': NotifiedOnExpiryEventToJSON(value.notified_on_expiry_event),
        'notified_on_overdue_event': NotifiedOnOverdueEventToJSON(value.notified_on_overdue_event),
        'responsible_notified_event': NotifiedResponsibleEventToJSON(value.responsible_notified_event),
        'sequence': value.sequence,
        'timestamp': (value.timestamp === null ? null : value.timestamp.toISOString()),
        'updated_by_importer': UpdatedByImporterToJSON(value.updated_by_importer),
    };
}


