import React from 'react';
import { useIntl } from 'react-intl';
import { getLocaleLanguageString } from '../../../base/utils';
import AppDownload from '../components/AppDownload';
import TrainingVideoSection from '../components/TraningVideoSection';
const appJsonEn = require('../training_videos_app_en.json')
const desktopJsonEn = require('../training_videos_desktop_en.json')
const appJsonCn = require('../training_videos_app_cn.json')
const desktopJsonCn = require('../training_videos_desktop_cn.json')

const TrainingVideosContainer = (): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    let appJson = appJsonEn;
    let desktopJson = desktopJsonEn;
    if (lang == 'zh') {
        appJson = appJsonCn;
        desktopJson = desktopJsonCn;
    }
    return (
        <div className='pageContainer'>
            <TrainingVideoSection
                videos={appJson.videos}
                header={appJson.header}
                description={appJson.description}
                columns={4}
                mobile
            />
            <TrainingVideoSection
                videos={desktopJson.videos}
                header={desktopJson.header}
                description={desktopJson.description}
                columns={3}
                mobile={false}
            />
        </div>
    )
}

export default TrainingVideosContainer;