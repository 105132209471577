import React, { useState } from 'react';
import { ReactBingmaps } from 'react-bingmaps';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, SemanticICONS } from 'semantic-ui-react';
import { MapLocation } from '../../../base/types';
import { getMapLocation } from '../../../base/utils';

type ProductionUnitMapProps = {
    location: MapLocation;
    setMapLocation?(location: MapLocation): void;
};

const ProductionUnitMap = (props: ProductionUnitMapProps): React.ReactElement => {
    const [location, setLocation] = useState({ latitude: props.location && props.location.latitude || undefined, longitude: props.location && props.location.longitude || undefined, first: true });
    let mapLocation = getMapLocation(props.location);
    if (location.latitude && location.longitude && !location.first) {
        mapLocation = [0, 0];
    }
    const posLabel = !location.first ? (location.latitude).toFixed(5) + ', ' + (location.longitude).toFixed(5) : '';
    return (
        <div className='puMap'>
            {!location.first &&
                <div className='changedLocation'>
                    <Icon color='blue' name={('map marker alternate' as SemanticICONS)} />
                    <FormattedMessage id='production_units.use_location_as_new_position' values={{ position: <span className='position'>{posLabel}</span> }} />
                    <Button size='tiny' positive onClick={(): void => {
                        setLocation({ latitude: undefined, longitude: undefined, first: true });
                        props.setMapLocation(location);
                    }}>Ok</Button>
                </div>
            }
            <ReactBingmaps
                zoom={6}
                bingmapKey='AuNjAlN_Sq3pRGRH5yygB1rlSxgcZPsgvmHPCM2REtJADzPVJpEALzAXhJXS7xMW'
                center={mapLocation}
                getLocation={
                    {
                        addHandler: 'click', callback: (evt: { latitude: number; longitude: number }): void => {
                            setLocation({ latitude: evt.latitude, longitude: evt.longitude, first: false });
                        },
                    }
                }
                pushPins={
                    [
                        {
                            location: [props.location && props.location.latitude || undefined, props.location && props.location.longitude || undefined], option: { color: 'green' },
                        },
                        {
                            location: [location && location.latitude || undefined, location && location.longitude || undefined], option: { color: '#2185d0' },
                        },
                    ]
                }
            >
            </ReactBingmaps>
        </div>
    );
};

export default ProductionUnitMap;
