/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Temporary User Context Reference
 *
 */
export type TempUserContextReference = {
    reference_id: string;
    reference_name: string;
    reference_type: TempUserContextReference.reference_type;
};

export namespace TempUserContextReference {

    export enum reference_type {
        AUDIT = 'audit',
    }


}

