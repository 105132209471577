import { createSelector } from '@reduxjs/toolkit';
import { UserRole } from '../../backend_api/models/UserRole';
import { UserRoleBrief } from '../../backend_api/models/UserRoleBrief';
import { RoleState } from './reducers';

const roleSelector = (state: any, id: string): UserRole => {
    return state.app.roles.userRoles && state.app.roles.userRoles[id];
}
export const getRoleSelector = createSelector(
    [roleSelector],
    (role) => role,
);

const cratingRoleSelector = (state: { app: { roles: RoleState } }): boolean => {
    return state.app.roles.isCreatingRole;
}
export const getCratingRoleSelector = createSelector(
    [cratingRoleSelector],
    (isCreating) => isCreating,
);

const roleListSelector = (state): UserRoleBrief[] => state.app.roles.rolesList;
export const getRolesList = createSelector(
    [roleListSelector],
    (roles) => roles,
);