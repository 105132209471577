import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { TextArea } from 'semantic-ui-react';
import SpinnerIcon from '../../../../base/components/basic/SpinnerIcon';
import { Sizes } from '../../../../base/components/types';
import Button, { ButtonTypes } from '../../../../base/ui/components/buttons/Button';
import Icon from '../../../../base/ui/components/icons/Icon';
import { ListItem, ListItems } from '../../../../base/ui/components/listitems/ListItem';
import QModal, { ActionTypes } from '../../../../base/ui/components/modals/Modal';
import StatusIcon from '../../../../base/ui/components/status/StatusIcon';
import { showSnackbar } from '../../../../base/ui/uiSlice';
import { isUndefinedOrNullOrEmptyString, twMerge } from '../../../../base/utils';
import { DetailedDocumentViewApprovalEnum } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import { complianceApproveDocument, complianceRejectDocument, complianceResetDocument, complianceSubmitDocumentForApproval } from '../../complianceSlice';
import { PLA } from '../../types';

type Props = {
    className?: string;
    state: DetailedDocumentViewApprovalEnum;
    numberOfFiles: number;
    productId: string;
    documentId: string;
    deadlineId: string;
    rejectReason?: string;
    datesAreValid: boolean;
    userCanRequestAndSubmitApproval: boolean;
    userCanApproveRejectReset: boolean;
    onDeleteAllFiles(): void;
    onDisplayDatesAreNotValid(): void;
    onClose(): void;
};

const ComplianceDocumentApproval = (props: Props): React.ReactElement => {
    const { className, numberOfFiles, productId, documentId, datesAreValid, deadlineId, rejectReason, onDeleteAllFiles, onClose, onDisplayDatesAreNotValid, userCanRequestAndSubmitApproval, userCanApproveRejectReset } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const userCanApprove = true;
    const [updating, setIsUpdating] = useState(false);
    const [rejectModal, showRejectModal] = useState(false);
    const [addNewFilesModal, showAddNewFilesModal] = useState(false);
    const [rejectComment, setRejectComment] = useState<string>(null);
    const [resetApprovalModal, showResetApprovalModal] = useState(false);
    const [resetRejectModal, showResetRejectModal] = useState(false);
    const [unsubmitModal, showUnsubmitModal] = useState(false);
    const [state, setState] = useState(props.state);
    const context = { product_id: productId, deadline_id: deadlineId };

    const submitForApproval = () => {
        if (datesAreValid) {
            dispatch(complianceSubmitDocumentForApproval({ document_id: documentId, product_id: productId, context }));
            onClose();
        } else {
            onDisplayDatesAreNotValid();
        }
    }
    const submitForReapproval = () => {
        dispatch(complianceSubmitDocumentForApproval({ document_id: documentId, product_id: productId, context }));
        onClose();
    }
    const approve = () => {
        dispatch(complianceApproveDocument({ document_id: documentId, product_id: productId, context }));
        setIsUpdating(true);
        onClose();
    }
    const reject = () => {
        dispatch(complianceRejectDocument({ document_id: documentId, comment: rejectComment, product_id: productId, context }));
        setIsUpdating(true);
        showResetRejectModal(false);
        onClose();
    }
    const showResetApproval = () => {
        showResetApprovalModal(true);
    }
    const showUnsubmit = () => {
        showUnsubmitModal(true);
    }
    const addNewFiles = (removeCurrent: boolean) => {
        removeCurrent && onDeleteAllFiles();
        // alert('File upload coming here...')
        document.getElementById('fileToolbarSelector').click();

    }
    const showAddNewFiles = () => {
        showAddNewFilesModal(true);
    }
    const resetApproval = () => {
        dispatch(complianceResetDocument({ document_id: documentId, context: { product_id: productId, deadline_id: deadlineId } }));
    }
    const unsubmit = () => {
        dispatch(complianceResetDocument({ document_id: documentId, context: { product_id: productId, deadline_id: deadlineId } }));
    }
    const showResetReject = () => {
        showResetRejectModal(true);
    }
    const resetReject = () => {
        dispatch(complianceResetDocument({ document_id: documentId, context: { product_id: productId, deadline_id: deadlineId } })).then((pla: PLA) => {
            dispatch(showSnackbar({ content: pla.meta.requestStatus }));
            /* if (pla.meta.requestStatus === 'fulfilled') {
                dispatch(showSnackbar({ content: intl.formatMessage({ id: 'compliance.document.reset_was_done' }) }));
            } */
        });


    }
    const getNumberOfFilesOnDocument = () => {
        let key: string;
        switch (numberOfFiles) {
            case 0: {
                key = 'compliance.document_viewer.approval.no_files_on_document';
                break;
            }
            case 1: {
                key = 'compliance.document_viewer.approval.one_file_on_document';
                break;
            }

            default: {
                key = 'compliance.document_viewer.approval.more_files_on_document';
                break;
            }
        }
        return intl.formatMessage({ id: key }, { numberOfFiles });
    };

    const getNumberOfFilesInreview = () => {
        let key: string;
        switch (numberOfFiles) {
            case 0: {
                key = 'compliance.document_viewer.approval.no_files_in_review';
                break;
            }
            case 1: {
                key = 'compliance.document_viewer.approval.one_file_in_review';
                break;
            }

            default: {
                key = 'compliance.document_viewer.approval.more_files_in_review';
                break;
            }
        }
        return intl.formatMessage({ id: key }, { numberOfFiles });
    };

    useEffect(() => {
        setState(props.state);
    }, [props.state]);
    return <div className={twMerge('', className)}>
        <ListItems className={'border border-default border-b-0 rounded-md bg-default'}>
            {state === DetailedDocumentViewApprovalEnum.NotReadyForApproval &&
                <>
                    <ListItem icon={'object_file'} className='font-bold'>{getNumberOfFilesOnDocument()}</ListItem>

                    <ListItem className='' icon={!userCanRequestAndSubmitApproval ? 'user_user' : null} meta={!userCanRequestAndSubmitApproval ? { metaAbove: { firstItem: 'Responsible for submit' } } : null}>
                        <div className='flex gap-2 flex-1'>
                            {!userCanRequestAndSubmitApproval && <span>-</span>}
                            {userCanApproveRejectReset && <>
                                {!updating && <Button primary isDisabled={!userCanApproveRejectReset} onPress={approve} className='flex gap-x-4 flex-1' icon='status_approved-circle'><FormattedMessage id='compliance.document_viewer.approval.approve' /></Button>}
                                {updating && <Button primary className='flex-1'><SpinnerIcon className='bg-qarma-green' /></Button>}
                                {/* <Button isDisabled={!userCanRequestAndSubmitApproval} className='flex-1' secondary onPress={submitForApproval}><FormattedMessage id='compliance.document_viewer.approval.submit_for_approval' /></Button> */}
                            </>}
                            {userCanRequestAndSubmitApproval && <>
                                <Button isDisabled={!userCanRequestAndSubmitApproval} className='flex-1' secondary onPress={submitForApproval}><FormattedMessage id='compliance.document_viewer.approval.submit_for_approval' /></Button>
                            </>}
                        </div>

                    </ListItem>
                </>
            }
            {state === DetailedDocumentViewApprovalEnum.ReadyForApproval &&
                <>

                    {<ListItem
                        inlineButton={userCanRequestAndSubmitApproval && <Button className='font-normal' size={Sizes.XSmall} onPress={showUnsubmit}>Unsubmit</Button>}
                        icon={<StatusIcon status='pending' />} className='font-bold'>{getNumberOfFilesInreview()}</ListItem>}
                    <ListItem className='' icon={!userCanApproveRejectReset ? 'user_user' : null} meta={!userCanApproveRejectReset ? { metaAbove: { firstItem: 'Responsible for approval' } } : null} >
                        <div className='flex flex-1 gap-x-4'>
                            {!userCanApproveRejectReset && <span>-</span>}
                            {userCanApproveRejectReset && <>
                                {!updating && <Button onPress={approve}
                                    isDisabled={!userCanApproveRejectReset}
                                    className='bg-status-approved text-white flex gap-x-4 flex-1'><Icon name='status_approved-circle' className='text-white' size={Sizes.Small} /><FormattedMessage id='compliance.document_viewer.approval.approve' /></Button>}
                                {updating && <Button primary><SpinnerIcon className='bg-qarma-green flex-1' /></Button>}
                                <Button className='bg-status-rejected text-white flex-1'
                                    isDisabled={!userCanApproveRejectReset}
                                    onPress={() => showRejectModal(true)}><Icon name='status_rejected-circle' className='text-white' size={Sizes.Small} /><FormattedMessage id='compliance.document_viewer.approval.reject' /></Button>
                            </>}
                            {!userCanApproveRejectReset && <>

                            </>}
                        </div>
                    </ListItem>
                </>
            }
            {state === DetailedDocumentViewApprovalEnum.Approved &&
                <ListItem className='bg-status-approved text-on-status rounded-md font-bold'
                    /* meta={{
                        metaBelow: {
                            className: 'text-on-status',
                            firstItem: 'Ole Madsen',
                            secondItem: getPrettyDate(new Date())
                        },
                    }} */
                    icon={<StatusIcon status='approved' inverted border />}
                    inlineButton={userCanApproveRejectReset && <Button size={Sizes.XSmall} onPress={showResetApproval} className='font-normal'>Reset</Button>}>
                    <FormattedMessage id='compliance.document_viewer.approval.approved' />
                </ListItem>

            }

            {state === DetailedDocumentViewApprovalEnum.Rejected &&
                <><ListItem className='bg-status-rejected text-on-status rounded-t-md font-bold'
                    /* meta={{
                        metaBelow: {
                            className: 'text-on-status font-normal',
                            firstItem: 'Ole Madsen',
                            secondItem: getPrettyDate(new Date()),
    
                        },
                    }} */
                    icon={<StatusIcon status='rejected' inverted />}
                    inlineButton={userCanApproveRejectReset && <Button size={Sizes.XSmall} onPress={showResetReject} className='font-normal'>Reset</Button>}>
                    <div className='text-on-status'><FormattedMessage id='compliance.document_viewer.approval.rejected' /></div>
                </ListItem>
                    <ListItem
                        icon={'object_comment'}
                        meta={{ metaAbove: { firstItem: 'Reason for rejection' } }}
                        className='whitespace-pre-line'>
                        {rejectReason}
                    </ListItem>
                    <ListItem>
                        <Button className='' buttonType={ButtonTypes.Primary} onPress={showAddNewFiles}>Add new files</Button>
                        <Button className='' buttonType={ButtonTypes.Secondary} onPress={submitForReapproval}>Submit for re-approval</Button>
                    </ListItem>
                </>
            }

        </ListItems>
        <QModal
            width={500}
            isOpen={addNewFilesModal}
            header='Keep current files'
            content={<>
                There are currently {numberOfFiles} files on the document. Do you want to keep the current files or delete them?
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: 'Delete current files', buttonType: ButtonTypes.Danger, event: () => addNewFiles(true) },
                { type: ActionTypes.Action, text: 'Keep files', buttonType: ButtonTypes.Primary, event: () => addNewFiles(false) },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showAddNewFilesModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={resetApprovalModal}
            header='Reset approval'
            content={<>
                This will remove approval of the document. Do you want to proceed?
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: 'Yes, reset approval', buttonType: ButtonTypes.Primary, event: resetApproval },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showResetApprovalModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={unsubmitModal}
            header='Unsubmit document'
            content={<>
                This will remove approval of the document. Do you want to proceed?
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: 'Yes, unsubmit', buttonType: ButtonTypes.Primary, event: unsubmit },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showUnsubmitModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={resetRejectModal}
            header={intl.formatMessage({ id: 'compliance.document.reset_rejection' })}
            content={<>
                <FormattedMessage id='compliance.document.reset_rejection.text' />
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: intl.formatMessage({ id: 'compliance.document.reset_rejection.confirm' }), buttonType: ButtonTypes.Primary, event: resetReject },
                { type: ActionTypes.CancelAction, text: intl.formatMessage({ id: 'globals.cancel' }), buttonType: ButtonTypes.Plain, event: () => showResetRejectModal(false) },
            ]}
        />
        <QModal
            width={500}
            isOpen={rejectModal}
            header='Reject document'
            content={<>
                <div className='pb-1 text-sm text-input-text-label'>* Reason for rejection</div>
                <TextArea onChange={(e) => setRejectComment(e.currentTarget.value)}>
                    {rejectComment}
                </TextArea>
            </>}
            actionItems={[
                { type: ActionTypes.Action, text: 'Reject document', buttonType: ButtonTypes.Danger, event: reject, disabled: isUndefinedOrNullOrEmptyString(rejectComment) },
                { type: ActionTypes.CancelAction, text: 'Cancel', buttonType: ButtonTypes.Plain, event: () => showRejectModal(false) },
            ]}
        />
    </div>
}
export default ComplianceDocumentApproval;
