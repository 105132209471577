import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CorrectiveActionEvidenceDeletedEvent } from '../../../backend_api/models';

type Props = {
    event: CorrectiveActionEvidenceDeletedEvent;
}

const EvidenceDeletedEvent = (props: Props) => {
    return (<Fragment>
        <FormattedMessage id={'corrective_actions.events.evidence_deleted'} />
        {props.event.files.map((file) => {
            return <div className='py-2' key={file.name}><a className='link' href={file.preview_url}>{file.name}</a></div>
        })}
    </Fragment>)
}

export default EvidenceDeletedEvent;