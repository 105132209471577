import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DialogFilter, DropdownFilter } from '.';
import { getLocationEntry, objectHasKeys } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import TextItem from '../../globals/components/TextItem';
import { InfoIcon } from '../../globals/components/Utils';
import { getCustomFieldsData, setFilter } from '../filtersActions';
import { getFilterDataSelector, getFiltersSelector } from '../selectors';
import { CustomFieldDataType } from '../types';
import FilterTrigger from './FilterTrigger';
type CustomFieldFilterProps = {
    disabled?: boolean;
    search?: boolean;
};
const CustomFieldFilter = (props: CustomFieldFilterProps): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { disabled = false, search = true } = props;
    let cfvs: CustomFieldDataType[] = useSelector((state: any) => getFilterDataSelector(state, 'custom_fields')) || [];
    const customFieldValuesByDefinitionId = useSelector((state: any) => getFilterDataSelector(state, 'customFieldValues')) || {};
    const currentFilters = useSelector(getFiltersSelector);
    const [currentItems, storeItem] = useState([]);
    const [tempItems, storeTempItem] = useState([]);
    const date = getLocationEntry(location, 'date')[0];
    const [d, setD] = useState(undefined);

    useEffect(() => {
        const currentCfvFilters = currentFilters.custom_field_values ? JSON.parse(currentFilters.custom_field_values[0]) : [];
        storeItem(currentCfvFilters);
        storeTempItem(currentCfvFilters);
    }, [currentFilters]);
    useEffect(() => {
        if (date !== d || d === undefined) {
            dispatch(getCustomFieldsData(ids));
            setD(date);
        }
    }, [date]);
    const saveItems = ((): void => {
        dispatch(setFilter('custom_field_values', objectHasKeys(tempItems) ? [JSON.stringify(tempItems)] : null));
        storeItem(tempItems);
        storeTempItem([]);
    });
    const cancel = (): void => {
        if (Array.isArray(currentItems) && currentItems.length === 0) {
            dispatch(setFilter('custom_field_values', null));
        } else {
            dispatch(setFilter('custom_field_values', objectHasKeys(currentItems) ? [JSON.stringify(currentItems)] : null));
        }
    };
    const clearAll = (): void => {
        storeTempItem([]);
        storeTempItem([]);
    };
    const remove = (): void => {
        storeItem([]);
        storeTempItem([]);
        dispatch(setFilter('custom_field_values', null));
    };
    const onOpen = (): void => {
        storeTempItem(currentItems);
    };
    const ids: string[] = [];
    const cfvsById: string[] = [];
    if (cfvs.length > 0) {
        cfvs = cfvs.filter((cf) => {
            cfvsById[cf.id] = cf;
            if (cf.is_filter) {
                ids.push(cf.id);
                return cf;
            }
        });
    }
    const getTrigger = (): any => {
        const defaultLabel = intl.formatMessage({ id: 'filters.cfv.select_custom_fields' });
        let isSelected = false;
        let trigger: string;
        const labels: React.ReactNode[] = [];
        let header: string;
        let cnt = 0;
        if (Object.keys(currentItems).length === 0) {
            trigger = defaultLabel;
        }
        if (Object.keys(currentItems).length > 0) {
            isSelected = true;
            const id = Object.keys(currentItems)[0];
            const len = Object.keys(currentItems).length;
            if ((len > 0)) {
                Object.keys(currentItems).forEach((ciId: string) => {
                    const itemsMap = currentItems[ciId];
                    const labelItem = <TextItem key={ciId} label={cfvsById[ciId].name} text={itemsMap.map((item, i: number) => {
                        cnt++;
                        return <span key={ciId}>{item}{i < itemsMap.length - 1 && ', '}</span>;
                    })}
                    />;
                    labels.push(labelItem);
                });
                trigger = (cfvsById[id].name + ': ' + currentItems[id][0]);
            } else {
                trigger = currentItems[id];
            }
            header = intl.formatMessage({ id: 'filters.dropdown.header.selected_cfvs' }) + '(' + cnt + ')';
        }
        return { triggerObj: <FilterTrigger trigger={trigger} popup={{ labels, header, cnt: cnt - 1 }} />, isSelected };
    };
    const content = <Fragment><div className='matrix flex flex-wrap'>
        {cfvs.map((cfv: any, index: number) => {
            const options = [];
            const cfValues = customFieldValuesByDefinitionId[cfv.id] || [];
            cfValues.forEach((v) => {
                options.push({ text: v, value: v, id: v });
            });
            return <div key={index} className='mb-2 w-72 mr-2'><div className='text-sm sm:text-secondary text-primary mb-1 font-bold sm:font-normal'>{cfv.name}</div>
                <div className='flex'>
                    <DropdownFilter
                        name='cfv'
                        search={search}
                        options={options}
                        multiple={true}
                        fluid={true}
                        disabled={disabled}
                        placeholder={intl.formatMessage({ id: 'filters.cfv.select_cfv' })}
                        selectedItems={tempItems && tempItems[cfv.id] || []}
                        handleSave={(items): void => {
                            if (items.length === 0) {
                                const obj = { ...tempItems };
                                delete obj[cfv.id];
                                if (objectHasKeys(obj)) {
                                    storeTempItem(obj);
                                } else {
                                    storeTempItem([]);
                                }
                            } else {

                                const obj = { ...tempItems, [cfv.id]: items };
                                storeTempItem(obj);
                            }
                        }}
                        remove={(): void => {
                            const id = cfv.id;
                            const obj = { ...tempItems };
                            delete obj[id];
                            storeTempItem(obj);
                        }}
                        inline={false}
                        showLoader={false}
                        loadingMessage={intl.formatMessage({ id: 'filters.loading_data' })}
                    /></div>
            </div>;
        })}
        {cfvs.length === 0 && <div><InfoIcon text={intl.formatMessage({ id: 'filters.cfv.not_active_custom_fields' })} /></div>}
    </div>
        {objectHasKeys(tempItems) && <div className='clearAll link' onClick={(): void => clearAll()}><FormattedMessage id='filters.cfv.clear_all_temp_items' /></div>}
    </Fragment>;

    const df = <DialogFilter
        name='customFields'
        label={intl.formatMessage({ id: 'filters.cfvs.label_cfvs' })}
        header={<FormattedMessage id='filters.cfv.header.select_cfv' />}
        content={content}
        actionButtonText={intl.formatMessage({ id: 'filters.cfv.save_filter' })}
        save={saveItems}
        cancel={cancel}
        onOpen={onOpen}
        remove={remove}
        trigger={{ text: getTrigger().triggerObj, isSelected: getTrigger().isSelected, showLabels: true, popup: undefined }}
    />;
    return df;
};

export default CustomFieldFilter;
