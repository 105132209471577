import { format } from 'date-fns';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';
import { DialogFilter } from '.';
import DatePicker from '../../../base/components/basic/DatePicker3';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { getMoment, getPrettyDate, isUndefinedOrNull } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setFilter } from '../filtersActions';
import { getDateParam, parseDateParam } from '../util';

type DateFilterProps = {
    startDate?: string;
    endDate?: string;
    type?: 'label';
    showLabels?: boolean;
    showFuture?: boolean;
    showPast?: boolean;
    hideAllOption?: boolean;
    disableClear?: boolean;
    disabled?: boolean;
    showIcon?: boolean;
    dateContextSelector?: React.ReactElement;
    className?: string;
    selectedClassName?: string;
    dontUseGlobalDate?: boolean;
    onChange?(date: string | string[]): void;
    onChangeReturnAsSeparateDates?(startDate, endDate): void;
};
const DateFilter = (props: DateFilterProps): React.ReactElement => {
    const intl = useIntl();
    const location = useLocation();
    const { showPast = true, showFuture = false, showLabels = false, type, disabled = false, hideAllOption = false, disableClear = false, selectedClassName, className, showIcon, dontUseGlobalDate = false } = props;
    const dispatch = useAppDispatch();
    const [d] = useState<any>({})
    const [startDate, setStartDate] = useState(d.startDate || '');
    const [endDate, setEndDate] = useState(d.endDate || '');
    const [currentId, setId] = useState('all');
    const [prevValues, setPrevValues] = useState({ id: undefined, startDate: '', endDate: '' });
    const hasDate = (startDate !== '' || endDate !== '') && (!isUndefinedOrNull(startDate) || !isUndefinedOrNull(endDate));
    type PrefixedDate = {
        type: string;
        offset?: number;
        id: string;
    };

    const prefixedDates: PrefixedDate[] = [];
    if (!hideAllOption) {
        prefixedDates.push({ type: 'all', id: 'all' });
    }
    prefixedDates.push({ type: 'w', offset: 0, id: 'w0' });
    prefixedDates.push({ type: 'month', offset: 0, id: 'm0' });
    prefixedDates.push({ type: 'year', offset: 0, id: 'y-1' });
    if (showPast) {
        prefixedDates.push({ type: 'w', offset: -1, id: 'w-1' });
        prefixedDates.push({ type: 'month', offset: -1, id: 'm-1' });
    }
    if (showFuture) {
        prefixedDates.push({ type: 'w', offset: 1, id: 'w1' });
        prefixedDates.push({ type: 'month', offset: 1, id: 'm1' });
    }
    useEffect(() => {
        if (!dontUseGlobalDate) {
            const date = qs.parse(location.search).date;
            if (date) {
                const d = parseDateParam(date as string)
                parseDates(d.startDate, d.endDate);
            } else {
                setStartDate(undefined);
                setEndDate(undefined);
            }
        }

    }, [qs.parse(location.search).date]);

    useEffect(() => {
        if (dontUseGlobalDate) {
            setStartDate(props.startDate);
            setEndDate(props.endDate);
        }
    }, [props]);
    const parseDates = (__startDate: string, __endDate: string): void => {
        const moment = getMoment();
        let id;
        const y1 = moment.add(1, 'year').startOf('year');
        const m = moment.add(0, 'month').startOf('month');
        const m1 = moment.add(1, 'month').startOf('month');
        const m2 = moment.add(2, 'month').startOf('month');
        const m6 = moment.add(5, 'month').startOf('month');
        const mLast3 = moment.add(-3, 'month').startOf('month');
        const wLast = moment.add(-1, 'w').startOf('isoWeek');
        const yearLast = moment.add(-1, 'y').startOf('year');
        const w = moment.add(0, 'w').startOf('isoWeek');
        const w1 = moment.add(1, 'w').startOf('isoWeek');
        const w2 = moment.add(2, 'w').startOf('isoWeek');
        const s = getMoment(startDate);
        const e = getMoment(endDate);
        let isPredefined = false;
        if (w.isSame(s) && w1.isSame(e)) {
            id = 'w0';
            isPredefined = true;
        }
        if (w1.isSame(s) && w2.isSame(e)) {
            id = 'w1';
            isPredefined = true;
        }
        if (wLast.isSame(s) && w.isSame(e)) {
            id = 'w-1';
            isPredefined = true;
        }
        if (mLast3.isSame(s) && m.isSame(e)) {
            id = 'm-3';
            isPredefined = true;
        }
        if (m.isSame(s) && m1.isSame(e)) {
            id = 'm0';
            isPredefined = true;
        }
        if (m1.isSame(s) && m2.isSame(e)) {
            id = 'm1';
            isPredefined = true;
        }
        if (m.isSame(s) && m6.isSame(e)) {
            id = 'm6';
            isPredefined = true;
        }
        if (y1.isSame(s) && yearLast.isSame(e)) {
            id = 'ylast';
            isPredefined = true;
        }
        if (!isPredefined) {
            setId('');
        } else {
            setId(id);
        }
        setStartDate(__startDate);
        setEndDate(__endDate);
        setPrevValues({ id, startDate: __startDate, endDate: __endDate });
    };

    const setDate = (f, dateObj): void => {
        const type = dateObj.type;
        const start = dateObj.start;
        const offset = dateObj.offset;
        let startDateTmp;
        let endDateTmp;

        setPrevValues({ id: currentId, startDate, endDate });
        if (type === 'all') {
            startDateTmp = '';
            endDateTmp = '';
        }
        if (type === 'w') {
            startDateTmp = getMoment()
                .add(start !== undefined ? start : offset, 'w')
                .startOf('isoWeek')
                .format('YYYY-MM-DD');
            endDateTmp = getMoment()
                .add(offset + 1, 'w')
                .startOf('isoWeek')
                .format('YYYY-MM-DD');
        }
        if (type === 'month') {
            startDateTmp = getMoment()
                .startOf('month')
                .add(start !== undefined ? start : offset, 'month')
                .format('YYYY-MM-DD');
            endDateTmp = getMoment()
                .startOf('month')
                .add(offset + 1, 'month')
                .add(-1, 'd')
                .format('YYYY-MM-DD');
        }
        if (type === 'year') {
            startDateTmp = getMoment()
                .startOf('d')
                .add(start !== undefined ? start : offset - 1, 'y')
                .format('YYYY-MM-DD');
            endDateTmp = getMoment()
                .startOf('d')
                .add(0, 'd')
                .format('YYYY-MM-DD');
        }
        setId(dateObj.id);
        setStartDate(startDateTmp);
        setEndDate(endDateTmp);
    };

    const cancel = (): void => {
        setStartDate(prevValues.startDate);
        setEndDate(prevValues.endDate);
        setId(prevValues.id);
    };

    const remove = (): void => {
        dispatch(setFilter('date', null));
        if (props.onChange) {
            dispatch(props.onChange(null));
        }
        if (props.onChangeReturnAsSeparateDates) {
            (props.onChangeReturnAsSeparateDates(null, null));
        }
        setStartDate('');
        setEndDate('');
        setId('');
        setPrevValues({ id: undefined, startDate: '', endDate: '' });
    };

    const getTriggerText = (): string => {
        if (type === 'label') {
            if (startDate || endDate) {
                return getPrettyDate(startDate, intl.locale) + '-' + getPrettyDate(endDate, intl.locale);
            }
            return undefined;
        }
        let label = intl.formatMessage({ id: 'filters.date.select_date' });
        if (startDate && endDate) {
            label = getPrettyDate(startDate, intl.locale) + '-' + getPrettyDate(endDate, intl.locale);
        } else {
            if (startDate || endDate) {
                label = startDate ? getPrettyDate(startDate, intl.locale) : '-';
                label += endDate ? getPrettyDate(endDate, intl.locale) : '-';
            }
        }
        return label;
    };

    const save = (): void => {
        !dontUseGlobalDate && dispatch(setFilter('date', [getDateParam(startDate, endDate)]));
        if (props.onChange) {
            dispatch(props.onChange(getDateParam(startDate, endDate)));
        }
        if (props.onChangeReturnAsSeparateDates) {
            (props.onChangeReturnAsSeparateDates(startDate, endDate));
        }
    };

    const content = <>
        <div className='flex flex-wrap md:flex-row md:w-full pb-4'>
            {prefixedDates.map((prefixedDate: PrefixedDate) => {
                return <div className='w-6/12 md:w-3/12 mb-3 md:mb-2' key={prefixedDate.id}>
                    <Checkbox radio
                        checked={currentId === prefixedDate.id}
                        onClick={(e): void => setDate(e, prefixedDate)}
                        label={intl.formatMessage({ id: 'filters.date.selector_' + prefixedDate.id })} />
                </div>;
            })}
        </div>
        <div className='flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0'>
            <div className=''>
                <FormattedMessage id='inspections.from' />
                <DatePicker
                    handleChange={(date): void => {
                        setId('custom');
                        setStartDate(isUndefinedOrNull(date) ? null : format(date, DEFAULT_DATE_FORMAT2));
                    }}
                    pastDates={true}
                    date={startDate}
                />
            </div>
            <div className=''><FormattedMessage id='inspections.to' />
                <DatePicker
                    handleChange={(date): void => {
                        setId('custom');
                        setEndDate(isUndefinedOrNull(date) ? null : format(date, DEFAULT_DATE_FORMAT2));
                    }}
                    pastDates={true}
                    date={endDate}
                />
            </div>
        </div>
        {props.dateContextSelector}
    </>;

    return <DialogFilter
        name='date'
        label={intl.formatMessage({ id: 'filters.date.label_date' })}
        header={<FormattedMessage id='inspections.select_dates' />}
        content={content}
        save={save}
        cancel={cancel}
        remove={remove}
        disableClear={disableClear}
        disabled={disabled}
        trigger={type === 'label' ? { simple: getTriggerText() } : { text: getTriggerText(), className, isSelected: hasDate, showLabels, popup: undefined, icon: showIcon && 'calendar_month' }}
    />;
};

export default DateFilter;
