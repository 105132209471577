import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, DropdownItemProps, Popup } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { removeArrayFromArray } from '../../../base/utils';
import { searchOptionTextAndDescription } from '../../inspections/Utils';
import LimitedDropdown from './LimitedDropdown';

type FilterProps = {
    name: string;
    options: DropdownItemProps[];
    selectedItems: string[];
    placeholder?: string;
    loadingMessage?: string;
    id?: string;
    limitItemsCount?: number;
    multiple?: boolean;
    inline?: boolean;
    disabled?: boolean;
    fluid?: boolean;
    showCancel?: boolean;
    showLoader?: boolean;
    search?: boolean;
    remove?(items: string[] | string[][]): void;
    handleSave(items: string[] | string[][] | any): void;
};

export const DropdownFilter = (props: FilterProps): React.ReactElement => {
    const intl = useIntl();
    const { multiple = false, options = [], placeholder, loadingMessage = '', name, fluid = true, search = true, showCancel = false, showLoader = false, disabled = false, limitItemsCount = 0 } = props;
    const [selectedItems, setSelectedItem] = useState(props.selectedItems);
    const [justAdded, setJustAdded] = useState([]);
    const [hasChanged, setChanged] = useState(false);
    const [isDone, setDone] = useState(true);

    useEffect(() => {
        setSelectedItem(props.selectedItems);
    }, [props.selectedItems]);

    useEffect(() => {
        if (!multiple) {
            save();
        }
    }, [selectedItems]);

    const cancel = (): void => {
        setSelectedItem(removeArrayFromArray(selectedItems, justAdded));
        setDone(true);
    };

    const handleChange = (e, { value }): void => {
        if (multiple) {
            const ps = removeArrayFromArray(value, selectedItems);
            setSelectedItem(value);
            setJustAdded(justAdded.concat(ps));
            setDone(false);
            setChanged(true);
        } else {
            setSelectedItem([value]);
        }
    };

    const save = (): void => {
        const saveItems = multiple ? selectedItems : [selectedItems];
        props.handleSave(saveItems);
        setDone(true);
        setJustAdded([]);
    };

    const remove = (): void => {
        setDone(true);
        setSelectedItem([]);
        (props.remove) ? props.remove(selectedItems) : props.handleSave([]);
    };

    const dd = <LimitedDropdown
        limitItemsCount={limitItemsCount}
        fluid={fluid}
        value={multiple ? selectedItems : selectedItems[0]}
        multiple={multiple}
        selection
        search={search ? searchOptionTextAndDescription : false}
        options={options}
        onChange={handleChange}
        placeholder={selectedItems.length === 0 ? placeholder : null}
        open={multiple ? !isDone : undefined}
        onClose={(): void => save()}
        closeOnBlur={true}
        loading={showLoader && options.length === 0}
        noResultsMessage={showLoader ? loadingMessage : intl.formatMessage({ id: 'filters.no_value' })}
        disabled={disabled}
    />;

    const getFilterLabel = (): React.ReactElement => {
        const labelElements: DropdownItemProps = [];
        let trigger = [<span key={'trigger_0'}>trigger</span>];
        const popup = [];
        let content = [];
        if (options) {
            options.forEach((opt) => {
                if (selectedItems.includes(opt.id) || selectedItems.includes(opt.value as string)) {
                    labelElements.push(opt);
                }
            });
        }
        if (selectedItems.length > 0 && labelElements.length > 0) {
            trigger = [<span className='text-base leading-none' key={'trigger_0'}>{labelElements[0].text}</span>];
            content[0] = trigger;
            if (selectedItems.length > 1) {
                trigger.push(<span className='plusSome'> + {selectedItems.length - 1}</span>);
                popup.push(<Popup
                    trigger={<span>{trigger}</span>}>
                    <Popup.Header><FormattedMessage id={`filters.dropdown.header.selected_${name}`} /> ({selectedItems.length})</Popup.Header>
                    <Popup.Content>
                        {labelElements.map((l: DropdownItemProps, i) => {
                            return <div key={'le_' + i}>{l.text}</div>;
                        })}
                    </Popup.Content>
                </Popup>);
                content = popup;
            }
        } else {
            trigger = [<span key={'trigger_0'}>{placeholder}</span>];
            content[0] = trigger;
        }

        return selectedItems.length === 0 ? <Dropdown
            onClick={(): void => {
                setDone(false);
            }}
            selection
            className='selector nonSelected default text'
            fluid={fluid}
            closeOnBlur={false}
            loading={showLoader && options.length === 0}
            disabled={disabled}
            search={search}
        >
            <Fragment>{content}</Fragment>
        </Dropdown>
            :
            <div className='flex justify-between w-full border rounded border-default'>
                <Button
                    onClick={(e): void => {
                        setDone(false);
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }} className='w-full bg-white flex justify-between border-0 pl-4' >
                    {content}

                </Button >
                <div className='items-center flex border-l px-2 bg-surface-plain'><RemoveIcon
                    className='cursor-pointer'
                    onClick={(e): void => {
                        remove();
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                />

                </div>
            </div>;
    };

    return (<Fragment>
        {!multiple && dd}
        {multiple && !isDone && dd}
        {multiple && !isDone && <Button className='sm:text-white h-fit-content' disabled={!hasChanged} primary small onClick={(): void => save()}><FormattedMessage id='filters.dropdown.label.ok' /></Button>}
        {multiple && !isDone && showCancel && <Button small className='cancel' onClick={(): void => cancel()}><FormattedMessage id='filters.dropdown.label.cancel' /></Button>}
        {multiple && isDone && getFilterLabel()}
    </Fragment>
    );
};

export default DropdownFilter

