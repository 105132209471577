import { createSelector } from 'reselect';
import { AppState as CommentsState, byId } from '../../base/types';
import { Comment, CommentTypes } from './types';
import util from './util';

const getCommentsSelector = (state: CommentsState): byId<Comment[]> => state.app.comments.comments;
export const getComments = createSelector(
    [getCommentsSelector],
    (comments) => comments,
);
const getCommentsByType = (state: CommentsState, type: CommentTypes, id: string, url?: string): byId<Comment[]> => {
    const comments = getCommentsSelector(state);
    return util.getCommentsByType(comments, type, id, url);
}
export const getCommentsByTypeSelector = createSelector(
    [getCommentsByType],
    (comments) => comments,
);

const getCommentsFetching = (state: CommentsState): boolean => state.app.comments.isFetching;
export const getCommentsFetchingSelector = createSelector(
    [getCommentsFetching],
    (isFetching) => isFetching,
);

const getCommentsSaving = (state: CommentsState): boolean => state.app.comments.isSaving;
export const getCommentsSavingSelector = createSelector(
    [getCommentsSaving],
    (isSaving) => isSaving,
);

const showTranslatedComments = (state: CommentsState): boolean => state.app.users.preferences.commentsShowAllTranslations;
export const showTranslatedCommentsSelector = createSelector(
    [showTranslatedComments],
    (showComments) => showComments,
);
const showTranslatedCommentsAsked = (state: CommentsState): boolean => state.app.comments.showTranslatedCommentsAsked || false;
export const showTranslatedCommentsAskedSelector = createSelector(
    [showTranslatedCommentsAsked],
    (asked) => asked,
);

const getHasTranslatedComments = (state: CommentsState): boolean => state.app.comments.hasTranslatedComments;
export const getHasTranslatedCommentsSelector = createSelector(
    [getHasTranslatedComments],
    (hasComments) => hasComments,
);
