import classnames from 'classnames';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup, Table } from 'semantic-ui-react';
import { MeasurementTable, MeasurementTables as MeasurementTablesType } from '../../../../../backend_api/models';
import { getLocaleLanguage, isUndefinedOrNull } from '../../../../../base/utils';
import TextItem from '../../../../globals/components/TextItem';

type MeasurementTablesProps = {
    tables: MeasurementTablesType;
}
const MeasurementTables = (props: MeasurementTablesProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const tables = props.tables.tables;

    return <Fragment>
        {tables && tables.map((table: MeasurementTable, n: number) => {
            return <div className='measurementTable' key={'table_' + n}>
                <Table unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {table.name[lang] || table.name.C}
                            </Table.HeaderCell>
                            <Table.HeaderCell className='value'>
                                <div className='text-sm sm:text-base'><FormattedMessage id='checklist.measurement_table.specs' /></div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='value'>
                                <div className='text-sm sm:text-base'><FormattedMessage id='checklist.measurement_table.tolerance' /></div>
                            </Table.HeaderCell>
                            {table.samples.length === 1 && <Table.HeaderCell className='value' key={'header_0'}><div className='container'><FormattedMessage id='checklist.measurement_table.observation' /></div></Table.HeaderCell>}
                            {table.samples.length > 1 && table.samples.map((sample, i: number) => {
                                return <Table.HeaderCell className='value' key={'header_' + i}><div className='container'>{i + 1}</div></Table.HeaderCell>
                            })}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {table.specifications.map((specifiction, i: number) => {
                            const target = specifiction.target;
                            const targetValueStr = target && target.value || '-';
                            let specName = specifiction.name[lang] || specifiction.name.C;
                            if (specifiction.unit && specifiction.unit.trim() != '') {
                                specName = specName + ' (' + specifiction.unit + ')';
                            }
                            return <Table.Row key={'row_' + i}>
                                <Table.Cell>{specName}</Table.Cell>
                                <Table.Cell className='value'><div className='container'>{targetValueStr}</div></Table.Cell>
                                <Table.Cell className='value'><div className='container'>{specifiction.specification_string}</div></Table.Cell>
                                {table.samples.map((sample, j: number) => {
                                    const sampleValue = sample[i] ? sample[i].value : 0;
                                    const withInSpec = isUndefinedOrNull(sample[i].within_spec) ? true : sample[i].within_spec;
                                    const diff = sample[i] ? sample[i].formatted_diff : null;
                                    const cn = classnames({ sampleError: !withInSpec, value: true });
                                    return <Popup
                                        key={'deviationPopup_' + i}
                                        className='deviationPopup'
                                        position='right center'
                                        basic
                                        trigger={<Table.Cell key={'cell_' + j} className={cn}><div className='container'>{sampleValue}</div></Table.Cell>}>
                                        <Popup.Header><FormattedMessage id='checklist.measurement_table.deviation' /> {specifiction.name[lang] || specifiction.name.C}</Popup.Header>
                                        <Popup.Content>
                                            <TextItem width={150} label={intl.formatMessage({ id: 'checklist.measurement_table.actual_measurement_short' })} text={sampleValue} align='right' />
                                            <TextItem width={150} label={intl.formatMessage({ id: 'checklist.measurement_table.specs' })} text={specifiction.target.value} align='right' />
                                            <TextItem width={150} label={intl.formatMessage({ id: 'checklist.measurement_table.diff' })} text={diff} align='right' />
                                            <TextItem width={150} label={intl.formatMessage({ id: 'checklist.measurement_table.tolerance' })} text={specifiction.specification_string} align='right' />
                                        </Popup.Content>
                                    </Popup>
                                })}
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </div>
        })}
    </Fragment>
};
export default MeasurementTables;