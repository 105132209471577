import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { showModal } from '../../../../base/baseSlice';
import Button from '../../../../base/components/basic/Button';
import { AppState } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { getBookingInspectionDataByIdSelector } from '../../../suppliers/selectors';
import { SupplierBookingInspection } from '../../../suppliers/types';
import { getInspectionByIdSelector } from '../../selectors/selectors';
import { Inspection } from '../../types';

type SplitShipmentsTriggerProps = {
    inspectionId: string;
    bookingId?: string;
    labelKey?: string;
    type: 'link' | 'button';
    context?: 'inspection' | 'booking';
    className?: string;
};

const SplitShipmentsTrigger = (props: SplitShipmentsTriggerProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    let canSplit = false;
    const { type = 'button', inspectionId, bookingId, context = 'inspection' } = props;
    let inspection;
    if (context === 'inspection') {
        inspection = useSelector((state: AppState): Inspection => getInspectionByIdSelector(state, inspectionId));
    } else {
        inspection = useSelector((state: AppState): SupplierBookingInspection => getBookingInspectionDataByIdSelector(state, bookingId, inspectionId));

    }
    if (inspection) {
        const planned = inspection.status === 'planned' && !inspection.locked && inspection.total_quantity;
        canSplit = inspection.features.includes('split_planned_shipment') && inspection.split_shipment_allowed && planned;
    }

    if (canSplit) {
        return <Fragment>
            {type === 'button' && <Button className={twMerge('text-sm', props.className)} noBorder onClick={() => {
                dispatch(showModal({ show: true, id: props.inspectionId }));
            }}><FormattedMessage id={props.labelKey || 'split_shipments.action.split'} /></Button>}
            {type === 'link' && <div className={twMerge(props.className)} onClick={() => {
                dispatch(showModal({ show: true, id: props.inspectionId }));
            }}><FormattedMessage id={props.labelKey || 'split_shipments.action.split_shipment'} /></div>}
        </Fragment>
    }
    return null;
}

export default SplitShipmentsTrigger;
