import classnames from 'classnames';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, Image } from 'semantic-ui-react';
import { getPrettyDate, useIsMobileDevice } from '../../../../../base/utils';
import { Space } from '../../../../globals/components/Utils';
import CustomerLogo from '../../../../profile/components/CustomerLogo';
import { getCustomerLogoPath } from '../../../../profile/selectors';
import { Inspection } from '../../../types';
import { InspectionTypeMarker } from '../../inspectionTypes/InspectionTypes';
import SplitShipmentsMarkerOrder from '../../shared/SplitShipmentsMarkerOrder';
import DownloadReport from './DownloadReport';

type ReportHeaderProps = {
    inspection: Inspection;
    isCombinedInspection?: boolean;
    showLabels?: boolean;
    showOrderNumber?: boolean;
    isFrontpage: boolean;
};

const ReportHeader = (props: ReportHeaderProps): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const { inspection, showOrderNumber, isCombinedInspection, isFrontpage } = props;
    const intl = useIntl();
    const locale = intl.locale;
    const ciLabel = intl.formatMessage({ id: 'report.header.ci_label' });
    const customerLogoPath = useSelector(getCustomerLogoPath);

    const getOrderNo = <span className='orderNo'>{inspection.order_number}{inspection.order_number && <Fragment>.<Space /></Fragment>}</span>;

    const getDate = <div className='date'>{isFrontpage ? <span>ETD: {getPrettyDate(inspection.etd, locale) || '-'}</span>
        :
        (getPrettyDate(inspection.inspection_end, locale) || getPrettyDate(inspection.scheduled_inspection_date, locale) || getPrettyDate(inspection.etd, locale) || '-')}</div>;

    const getName = <div className='name mr-4'>
        <Header as='h3' className={`${isFrontpage ? 'fp' : null}`}>
            {isFrontpage && <Header.Content>{intl.formatMessage({ id: 'report.header.inspection_label' })}
                <Header.Subheader>{inspection.order_number}</Header.Subheader>
            </Header.Content>}
            {!isFrontpage && showOrderNumber && <Header.Content>
                {inspection.order_number}
                <Header.Subheader>{isCombinedInspection ? ciLabel : inspection.item_name + ' - ' + inspection.item_number}</Header.Subheader>
            </Header.Content>}
            {!isFrontpage && !showOrderNumber && <span>{isCombinedInspection ? ciLabel : inspection.item_name + ' - ' + inspection.item_number}</span>}
        </Header>
    </div>;
    const getLabels = <div className='flex flex-col space-y-1 mr-2 w-10'>
        <InspectionTypeMarker inspections={[inspection]} type='report' />
        <SplitShipmentsMarkerOrder inspection={inspection} />

    </div>;
    return (
        <Fragment>
            <div className={classnames('reportHeader2', { fp: isFrontpage })}>
                {isMobile && <div>
                    <div className='r1'>{getLabels}{getOrderNo}</div>
                    <div>{getName}</div>
                </div>}
                {!isMobile && <Fragment><div className='left'>
                    {!isFrontpage && inspection.item_image && <div className='productPhoto'>
                        <Image key={inspection.item_image} rounded src={inspection.item_image} />
                    </div>}
                    {getLabels}
                    <div>
                        <div className='info'>{getOrderNo}{getDate}</div>
                        {getName}</div>
                </div>
                    <div className='right'>
                        <div className='customerLogo'>
                            <CustomerLogo path={customerLogoPath} />
                        </div>
                    </div></Fragment>}
                {isMobile && !isFrontpage && <div className='download'>
                    <DownloadReport
                        inspectionId={inspection.inspection_id}
                        isGroupedInspection={false}
                        showLabel={false} />
                </div>}
            </div>
        </Fragment >
    );
};

export default ReportHeader;
