import React, { Fragment, ReactElement, ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';

type FilterTriggerProps = {
    trigger: string | ReactNode;
    popup?: {
        header: string;
        labels: string[] | ReactNode[];
        cnt?: number;
    };
};

const FilterTrigger = (props: FilterTriggerProps): ReactElement<any> => {
    let trigger = props.trigger;
    const { popup, } = props;
    if (!popup || popup.labels.length === 0) {
        return <div>{trigger}</div>;
    }
    if (popup.labels && popup.labels.length > 0) {
        trigger = <Fragment>{trigger}{popup.cnt > 0 && <span className='plusSome'> + {(popup.cnt || popup.labels.length)}</span>}</Fragment>;
    }
    return (
        <Popup
            trigger={<span>{trigger}</span>}>
            <Popup.Header>{popup.header}</Popup.Header>
            <Popup.Content>{popup.labels}
            </Popup.Content>
        </Popup >);
};

export default FilterTrigger;
