import React, { useMemo } from 'react';
import Groups from './Groups';
import { Group } from '../../../backend_api/models';
import { ControlledDelayedGroupsSelector } from './GroupsSelector';
import { useGroups } from '../hooks';
import { GroupData } from '../types';

export function GroupsWrapper(props: { onGroupsChanged(groups: Group[]): void, groupsInput: Group[] }) {

    const groups: GroupData[] = useGroups().filter((g) => !g.is_supplier_group);

    const groupIds = useMemo(() => props.groupsInput.map(g => g.id), [props.groupsInput])

    return (
        <ControlledDelayedGroupsSelector
            selectableGroups={groups}
            onSelectingGroupsDone={(ids) => props.onGroupsChanged(
                ids.map(id => groups.find(g => g.id === id)) as Group[]
            )}
            selectedGroupIds={groupIds}
        />
    )
}