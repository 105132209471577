import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './types';
import { FiltersState } from './types';

const initialState: FiltersState = {
    active: {},
    data: {},
    filtersVisible: false,
    isFetching: false,
    conclusions: [],
};

export const reducer = handleActions({
    [types.SET_FILTER]: (state = initialState, action: Action<types.SET_FILTER>) => {
        const activeFilters = state.active;
        if (action.payload.value === '' || action.payload.value === null || (Array.isArray(action.payload.value) && action.payload.value.length === 0)) {
            delete activeFilters[action.payload.name];
        } else {
            activeFilters[action.payload.name] = action.payload.value;
        }
        return {
            ...state,
            active: activeFilters,
        };
    },
    [types.GET_FILTER_CONCLUSION_SUCCESS]: (state = initialState, action: Action<types.GET_FILTER_CONCLUSION_SUCCESS>) => {
        return {
            ...state,
            conclusions: action.payload.conclusions,
        }
    },
    [types.SET_FILTERS]: (state = initialState, action) => {
        const active = action.payload.filters;
        return {
            ...state,
            active,
        };
    },
    [types.SET_FILTERS_VISIBLE]: (state = initialState, action: Action<types.SET_FILTERS_VISIBLE>) => {
        const filtersVisible = action.payload.visible;
        return {
            ...state,
            filtersVisible,
        };
    },
    [types.GET_CUSTOM_FIELDS_SUCCESS]: (state = initialState, action: Action<types.GET_CUSTOM_FIELDS_SUCCESS>) => {
        const data = state.data;
        data.custom_fields = action.payload.data;
        return {
            ...state,
            data,
        };
    },
    [types.GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS]: (state = initialState, action: Action<types.GET_CUSTOM_FIELD_FILTER_DATA_SUCCESS>) => {
        const cfValues = action.payload.data.custom_field_values;
        const ret = {};
        Object.keys(cfValues).forEach((defId: string) => {
            ret[defId] = cfValues[defId];
        });
        const data = { ...state.data, customFieldValues: ret };
        return {
            ...state,
            data,
            isFetching: false,
        };
    },
    [types.GET_CUSTOM_FIELD_FILTER_DATA_REQUEST]: (state = initialState) => {
        return {
            ...state,
            isFetching: true,
        };
    },
}, initialState);

export default reducer;
