import { RequestError } from '../../base/types';
import { BulkApprovalFlow } from '../inspections/types';
import { ApprovalFlow, CustomConclusion } from './types';

export const APPROVAL_GET_CONCLUSIONS_SUCCESS = 'APPROVAL_GET_CONCLUSIONS_SUCCESS';
export type APPROVAL_GET_CONCLUSIONS_SUCCESS = {
    conclusions: CustomConclusion[];
}

export const APPROVAL_GET_FLOW_REQUEST = 'APPROVAL_GET_FLOW_REQUEST';
export type APPROVAL_GET_FLOW_REQUEST = {
    isFetching: boolean;
    inspectionId: string;
};
export const BULK_APPROVAL_GET_FLOW_REQUEST = 'BULK_APPROVAL_GET_FLOW_REQUEST';
export type BULK_APPROVAL_GET_FLOW_REQUEST = {
    isFetching: boolean;
    inspectionIds: string[];
};
export const APPROVAL_GET_FLOW_SUCCESS = 'APPROVAL_GET_FLOW_SUCCESS';
export type APPROVAL_GET_FLOW_SUCCESS = {
    isFetching: boolean;
    inspectionId: string;
    data: ApprovalFlow;
};
export const BULK_APPROVAL_GET_FLOW_SUCCESS = 'BULK_APPROVAL_GET_FLOW_SUCCESS';
export type BULK_APPROVAL_GET_FLOW_SUCCESS = {
    isFetching: boolean;
    inspectionIds: string[];
    data: BulkApprovalFlow;
};
export const APPROVAL_GET_FLOW_FAILURE = 'APPROVAL_GET_FLOW_FAILURE';
export type APPROVAL_GET_FLOW_FAILURE = {
    isFetching: boolean;
    inspectionId: string;
    status: number;
    message: string;
};

export const BULK_APPROVAL_GET_FLOW_FAILURE = 'BULK_APPROVAL_GET_FLOW_FAILURE';
export type BULK_APPROVAL_GET_FLOW_FAILURE = {
    isFetching: boolean;
    inspectionId: string[];
    error: RequestError;
};

export const APPROVAL_ADD_STEP_REQUEST = 'APPROVAL_ADD_STEP_REQUEST';
export type APPROVAL_ADD_STEP_REQUEST = {
    isFetchting: boolean;
};

export const APPROVAL_ADD_STEP_SUCCESS = 'APPROVAL_ADD_STEP_SUCCESS';
export type APPROVAL_ADD_STEP_SUCCESS = {
    isFetchting: boolean;
    inspectionId: string;
    data: ApprovalFlow;
};

export const APPROVAL_ADD_STEP_FAILURE = 'APPROVAL_ADD_STEP_FAILURE';
export type APPROVAL_ADD_STEP_FAILURE = {
    isFetchting: boolean;
    inspectionId: string;
    status: number;
    message: string;
};

export const APPROVAL_ADD_APPROVER_REQUEST = 'APPROVAL_ADD_APPROVER_REQUEST';
export type APPROVAL_ADD_APPROVER_REQUEST = {
    isFetchting: boolean;
};

export const APPROVAL_ADD_APPROVER_SUCCESS = 'APPROVAL_ADD_APPROVER_SUCCESS';
export type APPROVAL_ADD_APPROVER_SUCCESS = {
    isFetchting: boolean;
    inspectionId: string;
    step: number;
    data: ApprovalFlow;
};

export const APPROVAL_ADD_APPROVER_FAILURE = 'APPROVAL_ADD_APPROVER_FAILURE';
export type APPROVAL_ADD_APPROVER_FAILURE = {
    isFetchting: boolean;
    inspectionId: string;
    status: number;
    message: string;
};
