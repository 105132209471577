/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * pending
 * @export
 * @interface UpdateRequirementTemplateAction
 */
export interface UpdateRequirementTemplateAction {
    /**
     * 
     * @type {string}
     * @memberof UpdateRequirementTemplateAction
     */
    requirement_template_description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequirementTemplateAction
     */
    requirement_template_name?: string;
}

export function UpdateRequirementTemplateActionFromJSON(json: any): UpdateRequirementTemplateAction {
    return UpdateRequirementTemplateActionFromJSONTyped(json, false);
}

export function UpdateRequirementTemplateActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRequirementTemplateAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requirement_template_description': !exists(json, 'requirement_template_description') ? undefined : json['requirement_template_description'],
        'requirement_template_name': !exists(json, 'requirement_template_name') ? undefined : json['requirement_template_name'],
    };
}

export function UpdateRequirementTemplateActionToJSON(value?: UpdateRequirementTemplateAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requirement_template_description': value.requirement_template_description,
        'requirement_template_name': value.requirement_template_name,
    };
}


