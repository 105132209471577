/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InputCustomFieldDefinitionDate = {
    type: InputCustomFieldDefinitionDate.type;
};

export namespace InputCustomFieldDefinitionDate {

    export enum type {
        DATE = 'Date',
    }


}

