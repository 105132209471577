import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Grid, Header, Modal, Segment } from 'semantic-ui-react';
import appstoreImgCH from '../../../../images/app_Store_Badge_CNSC.png';
import appStoreImgEN from '../../../../images/app_Store_Badge_US-UK.png';
import chineseFlag from '../../../../images/chinese-flag.jpeg';
import googlePlayImgCH from '../../../../images/google-play-badge-ch.png';
import googlePlayImgEN from '../../../../images/google-play-badge-en.png';
import { request } from '../../../base/api';
import { getBrowserTranslations } from '../../../containers/ConnectedIntlProvider';

(String.prototype as any).format = function (...args): any {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let formatted = this;
    for (let i = 0; i < arguments.length; i++) {
        const regexp = new RegExp('\\{' + i + '\\}', 'gi');
        formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
};

type OwnProps = {
    actions: any;
}

type WelcomInspectorProps = OwnProps & WrappedComponentProps;

class WelcomInspectorContainer extends Component<WelcomInspectorProps, any> {
    public state = {
        downloadLink: { apk_url: '', version_number: '' },
        downloadDialogOpen: false,
    }

    public componentDidMount(): void {

        request('get_latest_app_version', {
            method: 'POST',
            v1: true,
            noAuth: true,
        })
            .then((response: any) => {
                if (response && response.response) {
                    this.setState({ downloadLink: response.response });
                }
            });
    }

    public render(): React.ReactElement {
        const browserTranslations = getBrowserTranslations();
        const popUpDialog = (

            <Modal
                open={this.state.downloadDialogOpen}
                onClose={(): void => this.setDialogState(null, false)}
                closeIcon={true}
                size='small'
            >
                <Modal.Header>{browserTranslations['welcomeinspector.download_header']}</Modal.Header>
                <Modal.Content>
                    <ol>
                        <li dangerouslySetInnerHTML={{ __html: browserTranslations['welcomeinspector.download_link'].format(this.state.downloadLink.apk_url) }} />
                        <li>{browserTranslations['welcomeinspector.download_open_file']}</li>
                        <li>{browserTranslations['welcomeinspector.download_install_qarma']}</li>
                    </ol>
                    {browserTranslations['welcomeinspector.download_current_version']}
                    : {this.state.downloadLink.version_number}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={(): void => this.setDialogState(null, false)}>
                        {browserTranslations['welcomeinspector.close']}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
        return (
            <Grid container className='welcome-inspector gridcontainer'>
                <style dangerouslySetInnerHTML={{
                    __html: '.content-wrap { background-color: #x43b16e }'
                }} />
                <DocumentTitle title={browserTranslations['welcomeinspector.title']} />

                <Segment className={'fullwidth'}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={2} />
                            <Grid.Column width={5} textAlign={'center'}>


                                <div className={'welcome-wrapper'}>
                                    <p></p>
                                    {<Header size='huge'>{browserTranslations['welcomeinspector.header_download']}</Header>}
                                    <p>&nbsp;</p>
                                    <Segment basic>
                                        <a href='https://itunes.apple.com/us/app/qarma-inspect/id1176875564?ls=1&mt=8' target='_blank' rel='noreferrer'>
                                            <img style={{ maxWidth: '45%' }} src={this.props.intl.locale.indexOf('en') !== -1 ? appStoreImgEN : appstoreImgCH} />
                                        </a>
                                    </Segment>
                                    <Segment basic>
                                        <a href='https://play.google.com/store/apps/details?id=com.qarma.app' target='_blank' rel='noreferrer'>
                                            <img style={{ maxWidth: '54%' }} src={this.props.intl.locale.indexOf('en') !== -1 ? googlePlayImgEN : googlePlayImgCH} />
                                        </a>
                                    </Segment>
                                    <Segment basic>
                                        <Grid>
                                            <Grid.Row columns={'equal'}>
                                                <Grid.Column>
                                                    <a href='https://app.qarmainspect.com/q/objects/files/109ffd7c-c7e6-41ab-8354-9b964f7a719f/2628ff8e-0e6c-446b-bdf6-674281ea266e/Qarma-sideload-release.apk' target='_blank' rel='noreferrer'>
                                                        <img src={chineseFlag} /></a>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <a href='https://app.qarmainspect.com/q/objects/files/109ffd7c-c7e6-41ab-8354-9b964f7a719f/2628ff8e-0e6c-446b-bdf6-674281ea266e/Qarma-sideload-release.apk' target='_blank' rel='noreferrer'>
                                                        {browserTranslations['welcomeinspector.open_download_page']}</a>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </div>


                            </Grid.Column>
                            <Grid.Column width={2} />
                            <Grid.Column width={5} textAlign={'center'}>
                                <div className={'welcome-wrapper'}>
                                    <p></p>
                                    <Header size='huge'>
                                        {browserTranslations['welcomeinspector.header_watch_video']}
                                    </Header>
                                    <p>&nbsp;</p>

                                    <video width='304' height='540' controls>
                                        <source src='https://app.qarmainspect.com/fileupload/d3/f1511875660926x788485616911202700/Qarma%20app%20introduction.mp4' type='video/mp4' />
                                        <FormattedMessage id='welcomeinspector.your_browser_does_not_support_the_video_tag' />
                                    </video>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={2} />
                        </Grid.Row>
                    </Grid>
                    {<Header size='huge'>{browserTranslations['welcomeinspector.tips_header']}</Header>}
                    <ul dangerouslySetInnerHTML={{ __html: browserTranslations['welcomeinspector.tips_content'] }} />
                </Segment>

                {popUpDialog}
            </Grid>
        );
    }

    private setDialogState = (event, open): void => {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ downloadDialogOpen: open });
    }
}

export default injectIntl(WelcomInspectorContainer);
