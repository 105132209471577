import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { TwoFactorRequired } from '../../../backend_api/models';
import { AuthenticationAuthorization } from '../../../backend_api/models/AuthenticationAuthorization';
import { OtherOrganization } from '../../../backend_api/models/OtherOrganization';
import { useAppDispatch } from '../../../store';
import { logout, switchOrganisation } from '../../authentication/authenticationSlice';
import { getAuthenticationList } from '../../authentication/selectors';
import Confirm from '../../inspections/components/report/globalActions/Confirm';

type OrgMenuItemProps = {
    org: OtherOrganization;
}

const OrgMenuItem = (props: OrgMenuItemProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const authentications = useSelector(getAuthenticationList);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    const selectOrganisation = (orgId: string): void => {
        let authorization: AuthenticationAuthorization | TwoFactorRequired = undefined;
        authentications && authentications.forEach((auth) => {
            if (auth.organization_id === orgId) {
                authorization = auth;
            }
        })
        if (authorization) {
            dispatch(switchOrganisation(authorization))
        }
        else {
            showConfirm();
        }
    };

    const showConfirm = (): void => {
        setOpenConfirm(true);
    }

    return (
        <Fragment>
            <Dropdown.Item key={props.org.organization_id}>
                <div onClick={(): void => selectOrganisation(props.org.organization_id)} className='p-menu-item leading-menu-item text-menu-item '>{props.org.organization_name}</div>
            </Dropdown.Item >
            <Confirm
                show={openConfirm}
                confirmAction={() => {
                    dispatch(logout());
                }}
                size='tiny'
                type='primary'
                headerTextKey='login.switch_org.need_to_sign_in_again_to_switch_org'
                handleCancel={() => setOpenConfirm(false)}
            >
                <p><FormattedMessage id='login.switch_org.text_1' values={{ organization_name: props.org.organization_name }} /></p>
                <p><FormattedMessage id='login.switch_org.text_2' /></p>

            </Confirm>
        </Fragment>
    )
}

export default OrgMenuItem;