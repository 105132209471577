import { createAction } from 'typesafe-actions';
import { UserRole } from '../../backend_api/models/UserRole';
import { UserRoleBrief } from '../../backend_api/models/UserRoleBrief';
import { request } from '../../base/api';
import history from '../../store/history';
import errorHandling from '../errorHandling';
import { sendErrorMessage, sendStatusMessage } from '../messages/actions';
import * as types from './types';

const catchException = errorHandling.handler.catchException;

const getRoleRequestSuccess = createAction(types.GET_ROLE_REQUEST_SUCCESS, (userRole: UserRole) => ({
    type: types.GET_ROLE_REQUEST_SUCCESS,
    payload: { userRole },
}));

export const getRolesListSuccess = createAction(types.GET_ROLES_LIST_SUCCESS, (rolesList) => ({
    type: types.GET_ROLES_LIST_SUCCESS,
    payload: rolesList
}));

export const getRole = (roleId: string): (dispatch: any) => void => {
    const url = 'user_roles/' + roleId
    return (dispatch): any => {
        request(url, { method: 'get' })
            .then((role: UserRole) => {
                dispatch(getRoleRequestSuccess(role));
            })
            .catch((error) => {
                catchException('getRole', {
                    endpoint: '/user_roles/[id]',
                    request: 'url',
                    status: error.status,
                }, { error, roleId, method: 'get' });
            });
    }
};

export const getRoleList = (): (dispatch: any) => void => {
    const url = 'user_roles';
    return (dispatch): any => {
        request(url, { method: 'get' })
            .then((roles: UserRoleBrief[]) => {
                dispatch(getRolesListSuccess(roles));
            })
            .catch((error) => {
                catchException('getRoleList', {
                    endpoint: '/user_roles/',
                    request: url,
                    status: error.status,
                }, { error, method: 'get' });
            });
    }
}

export const saveRole = (roleId: string, role: UserRole, updateRoleList: boolean): (dispatch: any) => void => {
    const url = 'user_roles/' + roleId
    return (dispatch): any => {
        request(url, { method: 'put', body: JSON.stringify(role), })
            .then(() => {
                dispatch(sendStatusMessage(['status_message.role.role_was_saved'], 1500))
                if (updateRoleList) {
                    dispatch(getRoleList());
                }
            })
            .catch((error) => {
                dispatch(sendErrorMessage(['error_message.role.could_not_save_role']))
                catchException('saveRole', {
                    endpoint: '/user_roles/[id]',
                    request: url,
                    status: error.status,
                }, { error, roleId, method: 'put', body: JSON.stringify(role) });
            });
    }
};

export const createRole = (roleName: string): (dispatch: any) => void => {
    const url = 'user_roles/?name=' + encodeURIComponent(roleName);
    return (dispatch): any => {
        request(url, { method: 'post', })
            .then((role: UserRole) => {
                dispatch(getRoleList());
                history.push('/role/edit/' + role.id);
            })
            .catch((error) => {
                catchException('createRole', {
                    endpoint: '/user_roles/',
                    request: url,
                    status: error.status,
                }, { error, roleName, method: 'post' });
            });
    }
}


export const deleteRole = (roleId: string): (dispatch: any) => void => {
    const url = 'user_roles/' + roleId
    return (dispatch): any => {
        request(url, { method: 'delete' })
            .then(() => {
                dispatch(getRoleList());
            })
            .catch((error) => {
                catchException('deleteRole', {
                    endpoint: '/user_roles/[id]',
                    request: 'url',
                    status: error.status,
                }, { error, roleId, method: 'delete' });
                if (error.status === 423) {
                    dispatch(sendErrorMessage(['error_message.delete_role_failed.role_in_use'], 5000));
                }
                else {
                    dispatch(sendErrorMessage(['error_message.delete_role_failed'], 3000));
                }
            });
    }
};
