import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import classnames from 'classnames';
import { default as React, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Sticky from 'react-sticky-el';
import { Grid } from 'semantic-ui-react';
import Button from '../../../base/components/basic/Button';
import Segment from '../../../base/components/basic/Segment';
import { Inspection } from '../types';
import CollectedInspectionLine from './combine/CollectedInspectionLine';

type InspectionCollectionProps = {
    className?: string;
    collectedInspections: Inspection[];
    header: React.ReactElement;
    action: React.ReactElement;
    warning?: React.ReactElement;
    enable: boolean;
    removeInspection(inspection: Inspection);
    cancel();
};

const InspectionCollection = (props: InspectionCollectionProps): React.ReactElement => {
    const { className } = props;
    const intl = useIntl();
    const combineInspections = props.collectedInspections
    const noneSelectedLabel = intl.formatMessage({ id: 'globals.none_selected' });
    const itemLabel = intl.formatMessage({ id: 'globals.item_lower_case' });
    const itemsLabel = intl.formatMessage({ id: 'globals.items_lower_case' });
    const hasItems = combineInspections.length > 0;

    const [nearTop, setNearTop] = useState(true);
    const [collectorHeight, setCollectorHeight] = useState(0);
    const expand = (): void => {
        scrollTo(0, 0);
        setNearTop(true);
    };
    useScrollPosition(
        ({ currPos }) => {
            const n = Math.abs(currPos.y) < collectorHeight;
            if (n !== nearTop && collectorHeight > 0) {
                setNearTop(n);
            }
        }), [];
    useEffect(() => {
        setCollectorHeight(document.querySelector('.cc') && document.querySelector('.cc').clientHeight || 0);
    }, [props.enable])
    useEffect(() => {
        setCollectorHeight((document.querySelector('.cc') && document.querySelector('.cc').clientHeight) || 0);
    }, [nearTop])

    if (props.enable) {
        if (nearTop) {
            return (<Segment className={classnames('p-4 flex flex-col cc', className)}>
                <h3>{props.header}</h3>
                {!hasItems && <p>
                    <FormattedMessage id='inspections.select_items_to_be_combined_into_one_inspection' />
                </p>}
                {props.warning}
                {hasItems && <Segment className='pb-8 md:pb-4' border={false}>
                    <Grid>
                        {combineInspections.map((inspection: Inspection) => {
                            return (
                                <CollectedInspectionLine
                                    key={inspection.inspection_id}
                                    inspection={inspection}
                                    removeInspection={props.removeInspection}
                                />
                            );
                        })}
                    </Grid>
                </Segment>}
                <div className='flex'>
                    {props.action}
                    <Button onClick={props.cancel}><FormattedMessage id='inspections.cancel' /></Button>
                </div>

            </Segment>
            );
        }
        if (!nearTop) {
            return <Sticky className='w-full'>
                <Segment className='top-0 w-full md:w-4/6 justify-center mx-auto z-20 sticky shadow-lg cc'>
                <div className='justify-center flex items-center'>
                    <div className='w-2/4'>
                        <div className='font-bold'>
                            {props.header}
                        </div>
                    </div >
                    <div className='w-1/4'>
                        <p>{combineInspections.length === 0 ? noneSelectedLabel : combineInspections.length + (combineInspections.length > 1 ? ' ' + itemsLabel : ' ' + itemLabel)}</p>
                    </div>
                    <div className='actions w-1/4 flex'>
                        <Button small primary onClick={(): void => expand()}><FormattedMessage id='inspections.view' /></Button>
                        <Button small onClick={props.cancel}><FormattedMessage id='inspections.cancel' /></Button>
                    </div>
                </div>
            </Segment>
            </Sticky>
        }
    }
    return null;
}
export default InspectionCollection;