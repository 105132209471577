/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 58.0.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventStringChange,
    EventStringChangeFromJSON,
    EventStringChangeFromJSONTyped,
    EventStringChangeToJSON,
} from './';

/**
 * Describes an update to the description of resolution field.
 * @export
 * @interface CorrectiveActionDescriptionOfResolutionEvent
 */
export interface CorrectiveActionDescriptionOfResolutionEvent {
    /**
     * 
     * @type {EventStringChange}
     * @memberof CorrectiveActionDescriptionOfResolutionEvent
     */
    description_of_resolution_change: EventStringChange;
}

export function CorrectiveActionDescriptionOfResolutionEventFromJSON(json: any): CorrectiveActionDescriptionOfResolutionEvent {
    return CorrectiveActionDescriptionOfResolutionEventFromJSONTyped(json, false);
}

export function CorrectiveActionDescriptionOfResolutionEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionDescriptionOfResolutionEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description_of_resolution_change': EventStringChangeFromJSON(json['description_of_resolution_change']),
    };
}

export function CorrectiveActionDescriptionOfResolutionEventToJSON(value?: CorrectiveActionDescriptionOfResolutionEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description_of_resolution_change': EventStringChangeToJSON(value.description_of_resolution_change),
    };
}


