import { DocRenderer } from "@cyntler/react-doc-viewer";
import React from "react";

export const HeicRenderer: DocRenderer = ({
    mainState: { currentDocument },
}) => {
    if (!currentDocument) return null;
    console.log('heic renderer')
    return (
        <div id="my-heic-renderer">
            HEIC:
            <img id="heic-img" src={currentDocument.fileData as string} />
        </div>
    );
};

HeicRenderer.fileTypes = ["heic", "image/heic"];
HeicRenderer.weight = 1;