import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../base/types';
import { getLocaleLanguageString, getLocaleString } from '../../base/utils';
import { FetchingData, Locale, LocaleObj } from './model';

const localeSelector = (state: AppState): LocaleObj => state.app.globals.locale;
const localesSelector = (state: AppState): Locale[] => state.app.globals.locale.locales;
const defaultLocaleSelector = (state: AppState): Locale => state.app.globals.locale.default;
const defaultLocaleFromUserSelector = (state: AppState): Locale => {
    if (state.app.profile.profile && state.app.profile.profile.locale) {
        return state.app.profile.profile.locale;
    }

    const browserLocale = getBrowserPreferedLocale(state);
    if(browserLocale) {
        return browserLocale;
    }
    
    console.warn('User profile contains no locale data. User id: ' + state.app.profile.profile.id);
    console.warn('Using system default locale instead.');
    console.warn('System default locale: ', getLocaleString(state.app.globals.locale.default));
    return state.app.profile.profile.default_locale;
};

const getBrowserPreferedLocale = (state: AppState): Locale => {
    const browserLanguage = getLocaleLanguageString(navigator.language);
    const locales = state.app.globals.locale.locales;
    return locales?.filter(l => l.language == browserLanguage)[0];
}

export const currentLocaleSelector = (state: AppState): Locale => state.app.globals.locale.selected;
const fetchingSelector = (state: AppState): FetchingData => state.app.globals.isFetching;
const savingDoneSelector = (state: AppState): boolean => state.app.globals.savingDone;

export const getLocale = createSelector(
    [localeSelector],
    (locale) => locale,
);

export const getLocales = createSelector(
    [localesSelector],
    (locales) => locales,
);

export const getDefaultLocale = createSelector(
    [defaultLocaleSelector],
    (locale) => locale,
);

export const getUserDefaultLocale = createSelector(
    [defaultLocaleFromUserSelector],
    (locale) => locale,
);

export const getCurrentLocale = createSelector(
    [currentLocaleSelector],
    (locale) => locale,
);

export const getFetching = createSelector(
    [fetchingSelector],
    (isFetching) => isFetching,
);

export const getSavingDone = createSelector(
    [savingDoneSelector],
    (savingDone) => savingDone,
);

const settingDefectSeverity = (state: AppState): boolean => state.app.inspectionReport.settingDefectSeverity;
export const isSettingDefectSeveritySelector = createSelector(
    [settingDefectSeverity],
    (isSetting) => isSetting,
);
