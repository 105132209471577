import React from 'react';
import { twMerge } from '../../utils';

type IconProps = {
    className?: string;
    name: string;
    pointer?: boolean;
    border?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const   Icon = (props: IconProps): React.ReactElement => {
    const { className, name, border = false, ...rest  } = props;
    return <i className={twMerge('mi self-center flex', border && 'rounded hover:text-primary border px-1 hover:border-gray-500 cursor-pointer', className)} { ...rest} >{name}</i>

}
export default Icon;
