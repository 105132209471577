import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { ComponentOrStringType } from '../../types';
import { ButtonTypes } from '../../ui/components/buttons/Button';
import Modal, { ActionTypes } from '../../ui/components/modals/Modal';
import { preventDefaultAndStopPropagation } from '../../utils';
import Button from './Button';

type DeleteConfirmProps = {
    className?: string;
    trigger?: React.ReactElement;
    show?: boolean;
    inline?: boolean;
    alert?: boolean;
    disabled?: boolean;
    type?: 'default' | 'inline' | 'alert';
    deleteText?: string;
    alertText?: ComponentOrStringType;
    alertHeader?: ComponentOrStringType;
    alertButtonText?: string;
    deleteFunction(any?): void;
    onClose?(): void;
};

const DeleteConfirm = (props: DeleteConfirmProps): React.ReactElement => {
    const { className, trigger, inline, type = 'default', alertText, alertHeader, alertButtonText, deleteText, disabled = false, show = false, onClose } = props;
    const [open, setOpen] = useState(show || false);
    const intl = useIntl();
    let content;
    switch (type) {
        case 'inline':
            content = <span>{!open ? <span onClick={() => disabled ? setOpen(true) : null}>{trigger}</span> :
                <span className='flex text-sm items-center px-2 space-x-1'><div><FormattedMessage id='delete_confirm.ok_to_delete' /></div><Button className='px-2 py-1 text-xs' onClick={(e) => {
                    setOpen(false);
                    props.deleteFunction();
                    preventDefaultAndStopPropagation(e);
                }}><FormattedMessage id='globals.ok' /></Button><Button className='px-1 py-1 text-xs' onClick={(e) => { setOpen(false); preventDefaultAndStopPropagation(e); }}><FormattedMessage id='globals.cancel' /></Button></span>
            }</span>
            break;
        case 'alert':
            content = <Modal                
                isOpen={!disabled && open}
                onOpenChange={(open) => { 
                    setOpen(open);
                    if(!open) {
                        onClose?.();
                    }
                }}
                onClose={() => onClose?.()}
                trigger={trigger}
                header={alertHeader}
                content={alertText}
                actionItems={[
                    { type: ActionTypes.Action, buttonType: ButtonTypes.Danger, event: props.deleteFunction, text: alertButtonText ? alertButtonText : intl.formatMessage({ id: 'globals.ok' }), dataTestId: 'confirm-delete-button' },
                    { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, event: onClose, text: intl.formatMessage({ id: 'globals.cancel' }) },
                ]}
                width={375}
            />
            break;
        case 'default':

            content = < Popup
                trigger={trigger}
                open={!disabled && open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    onClose?.();
                    setOpen(false);
                }}
                closeOnDocumentClick={true}
                closeOnPortalMouseLeave
                on='click' >
                <div className='flex'>
                    <Button  
                        data-test-id="confirm-delete-button"
                        className='bg-surface-danger text-white text-sm hover:text-white' onClick={(e) => {
                        setOpen(false);
                        props.deleteFunction();
                        preventDefaultAndStopPropagation(e);
                    }}>{deleteText ? deleteText : <FormattedMessage id='globals.confirmation.delete' />}</Button>
                    <Button className='text-sm' onClick={(e) => { 
                        onClose?.()
                        setOpen(false);
                         preventDefaultAndStopPropagation(e);
                          }}><FormattedMessage id='globals.cancel' /></Button>
                </div>
            </Popup >
            break;
        default:
            break;
    }
    return <div className={classnames('', className)}>{content}</div>;
}

export default DeleteConfirm;
