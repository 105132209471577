import { handleActions } from 'redux-actions';
import { Action } from '../../base/genericActions';
import * as types from './actionTypes';
import { ProfileState } from './types';

const initialState: ProfileState = {
    profile: {
        organization: { name: '', logo: '' },
        locale: undefined,
        lastname: '',
        id: '',
        groups: [],
        firstname: '',
        features: [],
        email: '',
        default_locale: undefined,
        user_role_id: undefined,
        disabled: false,
        role: '',
        other_organizations: [],
    }, isUpdatingProfile: false
};
export const reducer = handleActions({
    [types.PROFILE_REQUEST_SUCCESS]: (state = initialState, action: Action<types.PROFILE_REQUEST_SUCCESS>) => {
        return { ...state, profile: action.payload.profile };
    },
    [types.IS_UPDATING_PROFILE]: (state = initialState, action: Action<types.IS_UPDATING_PROFILE>) => {
        return { ...state, isUpdatingProfile: action.payload.isUpdatingProfile };
    },
}, initialState);

export default reducer;
