const Locale = 'Locale';
interface Locale {
    name: string;
    language: string;
    country: string;
    id: string;
}

const LocaleObj = 'LocaleObj';
interface LocaleObj {
    locales: Locale[];
    selected: Locale;
    default: Locale;
}

const FetchingData = 'FetchingData';
interface FetchingData {
    isFetching: boolean;
}
export { Locale, LocaleObj, FetchingData };

