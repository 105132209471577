import moment from 'moment';
import { FetchingData, LocaleObj } from './model';
export const REDIRECT_TO_URL = '@@globals/REDIRECT_TO_URL';

export type DatePickerProps = {
	date?: string | Date;
	handleChange(date: moment.Moment, props: DatePickerProps);
	pastDates?: boolean;
	placeholderKey?: string;
	name?: string;
	disabled?: boolean;
	readOnly?: boolean;
	didfocus?: boolean;
	autoFocus?: boolean;
	didFocus?();
	handleBlur?();
}

export type MultiLanguageText = {
	[languageCode: string]: string;
}

export type GlobalsState = {
	locale: LocaleObj;
	isFetching: FetchingData;
	savingDone: boolean;
}