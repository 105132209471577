/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 48.0.0 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Describes approval of a CA.
 * @export
 * @interface CorrectiveActionApprovedEvent
 */
export interface CorrectiveActionApprovedEvent {
    /**
     * 
     * @type {string}
     * @memberof CorrectiveActionApprovedEvent
     */
    comment: string | null;
}

export function CorrectiveActionApprovedEventFromJSON(json: any): CorrectiveActionApprovedEvent {
    return CorrectiveActionApprovedEventFromJSONTyped(json, false);
}

export function CorrectiveActionApprovedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorrectiveActionApprovedEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
    };
}

export function CorrectiveActionApprovedEventToJSON(value?: CorrectiveActionApprovedEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
    };
}


