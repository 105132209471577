import React from 'react';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import { Audit } from '../../../backend_api/models';
import AuditChangeConclusionDialog from './AuditChangeConclusionDialog';
import AuditConclusion from './AuditConclusion';
import { getApprovalColor } from './AuditDisplayState';

type AuditConclusionProps = {
    audit: Audit;
    className?: string;
    inverted?: boolean;
};

const AuditConclusionSegment = (props: AuditConclusionProps): React.ReactElement => {
    const { className, audit, inverted = true } = props;
    const trigger = <AuditConclusion audit={audit} inverted={inverted} className='px-4 py-3 w-1/2' />;
    const conclusionDialog = <AuditChangeConclusionDialog audit={audit} trigger={trigger} />;
    const getColors = () => {
        let bg = 'bg-status-ongoing';
        const fg = 'text-white';
        if (!audit.final_conclusion) {
            if (audit.status === 'report' && audit.approval_flow_status) {
                if (audit.approval_flow_status === 'disagree' || audit.approval_flow_status === 'not_set') {
                    bg = 'bg-' + getApprovalColor(audit.approval_flow_status);
                } else {
                    bg = 'bg-status-report';
                }
            } else {
                bg = audit.status === 'report' ? 'bg-status-report' : 'bg-status-ongoing';
            }
        }
        return { bg, fg };
    }
    const content =
        <div className={twMerge('flex flex-row justify-between items-center p-segment text-center', getColors().bg, getColors().fg)}
            style={audit.final_conclusion && audit.status === 'report' && { backgroundColor: audit.final_conclusion.background_color, color: audit.final_conclusion.text_color } || {}}
        >
            <div className={twMerge('space-y-2  sm:space-y-0 flex sm:flex-row w-full items-center justify-between', className)}>
                <span className='font-bold text-lg'><FormattedMessage id='audits.conclusion.status.label' /></span>{conclusionDialog}
            </div>
        </div>
    return content;
}
export default AuditConclusionSegment;
