import { twCascade } from '@mariusmarais/tailwind-cascade';
import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Modal, Popup } from 'semantic-ui-react';
import { InspectionDetails } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import Tag from '../../../base/components/basic/Tag';
import { getLocaleLanguageString, getOrdinal, localizeOrdinal } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import HasFeatureAccess from '../../globals/components/access/HasFeatureAccess';
import { InfoIcon } from '../../globals/components/Utils';
import { scheduleReinspection } from '../actions/reinspectActions';
import { Inspection } from '../types';


type ReinspectProps = {
    inspection: Inspection;
    disabled?: boolean;
};
export const Reinspect = (props: ReinspectProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const intl = useIntl();
    const locale = getLocaleLanguageString(intl.locale);
    const [doReinspect, setDoReinspect] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAssignInfoDialog, setShowAssignInfoDialog] = useState(false);
    const inspection = props.inspection;
    const handleScheduleReinspection = (reinspect: boolean): void => {
        if (reinspect) {
            setDoReinspect(true);
            dispatch(scheduleReinspection(props.inspection.inspection_id));
        }
        setShowModal(false);
    };
    const goToReInspection = (basedOn: boolean): void => {
        let reportUrl;
        if (basedOn) {
            const status = inspection.reinspection_based_on.status;
            if (status === 'Report') {
                reportUrl = inspection.reinspection_based_on.inspection_id;
                history.push('/inspections/reports/' + reportUrl);
            } else {
                history.push('/inspections/edit_inspection/' + inspection.reinspection_based_on.order_id);
            }
        } else {
            const status = inspection.reinspected_by[0].status;
            if (status === 'Report') {
                reportUrl = inspection.reinspected_by[0].inspection_id;
                history.push('/inspections/reports/' + reportUrl);
            } else {
                history.push('/inspections/edit_inspection/' + inspection.reinspected_by[0].order_id);
            }
        }
    };
    const reinspectionBasedOn = inspection.reinspection_based_on !== null && inspection.reinspection_based_on.count > 0;
    const reinspectionBy = inspection.reinspected_by.length > 0;
    const getAssignInfo = () => {
        const url = '/inspections/edit_inspection/' + inspection.reinspected_by[0].order_id;

        return <Modal
            size='small'
            onClose={(): void => setShowAssignInfoDialog(false)}
            closeIcon={true}
            open={showAssignInfoDialog}>
            <Modal.Header><FormattedMessage id='inspections.schedule_reinspection.assign_info_header' /></Modal.Header>
            <Modal.Content>
                <p><InfoIcon compact noMargins /><FormattedMessage id='inspections.schedule_reinspection.assign_info' /></p>
                <p><FormattedMessage id='inspections.schedule_reinspection.assign_link' values={{ linkToReinspection: <Link className='link' to={url}><FormattedMessage id='inspections.schedule_reinspection.assign_link_text' /></Link> }} /></p>
            </Modal.Content>
            <Modal.Actions className='flex justify-end'>
                <Button primary onClick={(): void => setShowAssignInfoDialog(false)}><FormattedMessage id='globals.close' /></Button>
            </Modal.Actions>
        </Modal>
    }
    useEffect(() => {
        if (!showModal && doReinspect && inspection.features.includes('change_assignment_data')) {
            setShowAssignInfoDialog(true);
        }

    }, [doReinspect && inspection.reinspected_by.length > 0])
    return (
        <Fragment>
            {showAssignInfoDialog && getAssignInfo()}
            {(reinspectionBasedOn) &&
                <Popup
                    trigger={<Button data-test-id='btn-re-inspection-goto-next' onClick={(): void => goToReInspection(true)} className='reinspection mb-2'>
                        <FormattedMessage
                            id='inspections.go_to_ordinal_inspection'
                            values={{
                                inspection: <FormattedMessage id="inspections.inspection" />,
                                ordinal: localizeOrdinal(inspection.reinspection_based_on.count, locale)
                            }}
                        />
                    </Button>}>
                    <Popup.Header><Icon color='blue' name='info circle' /><FormattedMessage id='inspections.reinspection' /></Popup.Header>
                    <Popup.Content><FormattedMessage id='inspections.the_current_inspection_is_a_reinspection_click_to_go_to_the_inspection_on_which_it_is_based' /></Popup.Content>
                </Popup>
            }
            {reinspectionBy &&
                <Popup
                    trigger={<Button data-test-id='btn-re-inspection-goto-next' onClick={(): void => goToReInspection(false)} className='reinspection'>
                        <FormattedMessage
                            id='inspections.go_to_ordinal_inspection'
                            values={{
                                inspection: <FormattedMessage id='inspections.inspection' />,
                                ordinal: localizeOrdinal(inspection.reinspected_by[0].count + 1, locale)
                            }}
                        /></Button>
                    }>
                    <Popup.Header><Icon color='blue' name='info circle' /><FormattedMessage id='inspections.reinspection' /></Popup.Header>
                    <Popup.Content><FormattedMessage id='inspections.the_current_inspection_has_been_reinspected_click_to_go_to_the_reinspection' /></Popup.Content>
                </Popup>}
            {!reinspectionBy &&
                <HasFeatureAccess feature='u_create_reinspections' inspection={inspection}>
                    <Button disabled={props.disabled} data-test-id='btn-schedule-re-inspection' onClick={(): void => setShowModal(true)} className='reinspection'><FormattedMessage id='inspections.schedule_reinspection' /></Button>
                </HasFeatureAccess>
            }
            <Modal
                size='small'
                onClose={(): void => setShowModal(false)}
                closeIcon={true}
                open={showModal}>
                <Modal.Header><FormattedMessage id='inspections.schedule_reinspection' /></Modal.Header>
                <Modal.Content><FormattedMessage id='inspections.the_inspection_has_been_rejected_do_you_want_to_schedule_a_reinspection' /></Modal.Content>
                <Modal.Actions className='flex justify-end'>
                    <Button primary onClick={(): void => handleScheduleReinspection(true)}><FormattedMessage id='inspections.yes' /></Button>
                    <Button onClick={(): void => handleScheduleReinspection(false)}><FormattedMessage id='inspections.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};

type ReinspectLabelProps = {
    inspection: Inspection | InspectionDetails;
    className?: string;
    type?: 'short' | 'header' | 'dashboard';
}

export const ReinspectLabel = (props: ReinspectLabelProps): React.ReactElement => {
    const reinspectionLabel = 'flex break-normal bg-default-widget-color inline rounded text-xs text-white px-1 justify-center text-center';
    const { inspection, type, className } = props;
    if (inspection.reinspection_based_on && inspection.reinspection_based_on.count > 0) {
        if (type === 'short') {
            return (
                <span className={classnames(reinspectionLabel, className)}>
                    <Popup trigger={<span>{getOrdinal(inspection.reinspection_based_on.count + 1)}</span>}>
                        <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspection_based_on.count + 1)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                    </Popup></span>
            );
        }
        if (type === 'dashboard') {
            return (
                <Popup trigger={<span><Tag>{getOrdinal(inspection.reinspection_based_on.count + 1)}</Tag></span>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspection_based_on.count + 1)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
        if (type === 'header') {
            return (
                <Popup trigger={<div className={twCascade(reinspectionLabel, 'py-1')}>{getOrdinal(inspection.reinspection_based_on.count + 1)}</div>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspection_based_on.count + 1)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
        if (!type) {
            return (
                <Popup trigger={<span><Tag>{getOrdinal(inspection.reinspection_based_on.count + 1)}</Tag></span>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspection_based_on.count + 1)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
    }
    if (inspection.reinspected_by && inspection.reinspected_by.length > 0) {
        if (type === 'short') {
            return (
                <span className={classnames(reinspectionLabel, className)}>
                    <Popup trigger={<span>{getOrdinal(inspection.reinspected_by[0].count)}</span>}>
                        <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspected_by[0].count)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                    </Popup></span>);
        }
        if (type === 'dashboard') {
            return (
                <Popup trigger={<span><Tag>{getOrdinal(inspection.reinspected_by[0].count)}</Tag></span>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspected_by[0].count)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
        if (type === 'header') {
            return (
                <Popup trigger={<div className={twCascade(reinspectionLabel, 'py-1')}>{getOrdinal(inspection.reinspected_by[0].count)}</div>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspected_by[0].count)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
        if (!type) {
            return (
                <Popup trigger={<span><Tag>{getOrdinal(inspection.reinspected_by[0].count)}</Tag></span>}>
                    <Popup.Content><FormattedMessage id='inspections.this_item_is_a_reinspection_this_is_the' />{getOrdinal(inspection.reinspected_by[0].count)}<FormattedMessage id='inspections.inspection' /></Popup.Content>
                </Popup>
            );
        }
    }
    return (null);
};


