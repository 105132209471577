import classnames from 'classnames';
import React from 'react';
import { ComponentOrStringType } from '../../types';


type TagProps = {
    bg?: string;
    textColor?: string;
    textSize?: string;
    block?: boolean;
    className?: string;
    children: ComponentOrStringType;
};

const Tag = (props: TagProps): React.ReactElement => {
    const {
        bg = props.bg || 'bg-default-widget-color',
        textColor = props.textColor || 'text-white',
        textSize = props.textSize || 'text-xs',
        className,
        children,
    } = props;

    return <div className={classnames('block rounded p-1 text-center',
        className,
        [props.block ? 'block' : 'inline'],
        bg,
        textColor,
        textSize,
    )}>{children}</div>
}
export default Tag;
