/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UUIDRequired } from './UUIDRequired';

/**
 * A link between a corrective action and a master inspection
 */
export type MasterInspectionLink = {
    inspection_id: UUIDRequired;
    /**
     * The master inspection link's item number
     */
    item_number: string;
    /**
     * The master inspection link's order number
     */
    order_number: string;
    type: MasterInspectionLink.type;
};

export namespace MasterInspectionLink {

    export enum type {
        MASTER_INSPECTION_LINK = 'MasterInspectionLink',
    }


}

