/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This type describes which order something belongs to. Instead of having a specific time interval, the entity can follow a specific order, no matter what deadline the order has.
 * @export
 * @interface TimeFrameOrder
 */
export interface TimeFrameOrder {
    /**
     * 
     * @type {string}
     * @memberof TimeFrameOrder
     */
    order_id: string;
    /**
     * 
     * @type {string}
     * @memberof TimeFrameOrder
     */
    type: TimeFrameOrderTypeEnum;
}


/**
 * @export
 */
export const TimeFrameOrderTypeEnum = {
    TimeFrameOrder: 'time_frame_order'
} as const;
export type TimeFrameOrderTypeEnum = typeof TimeFrameOrderTypeEnum[keyof typeof TimeFrameOrderTypeEnum];


/**
 * Check if a given object implements the TimeFrameOrder interface.
 */
export function instanceOfTimeFrameOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "order_id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function TimeFrameOrderFromJSON(json: any): TimeFrameOrder {
    return TimeFrameOrderFromJSONTyped(json, false);
}

export function TimeFrameOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeFrameOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order_id': json['order_id'],
        'type': json['type'],
    };
}

export function TimeFrameOrderToJSON(value?: TimeFrameOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order_id': value.order_id,
        'type': value.type,
    };
}

