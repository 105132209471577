import classnames from 'classnames';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showModal } from '../../../../base/baseSlice';
import ContextMenu from '../../../../base/components/basic/ContextMenu';
import { getFeaturesSelector } from '../../../../base/selectors';
import { useAppDispatch } from '../../../../store';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { isInspectionPartOfCombinedInspection } from '../../Utils';
import { Inspection } from '../../types';
import DuplicateInspection from '../inspectionActions/DuplicateInspection';
import ReassignInspectorOnInspection from '../inspectionActions/ReassignInspectorOnInspection';
import Reset from '../inspectionActions/Reset';
import UnlockInspection from '../inspectionActions/UnlockInspection';
import SplitShipments from '../shared/SplitShipments';
import SplitShipmentsTrigger from '../shared/SplitShipmentsTrigger';
import DeleteInspection from './DeleteInspection';

type EditInspectionsContextMenuProps = {
    inspection: Inspection;
    className?: string;
    children?: React.ReactElement;
    pointer?: boolean;
    isCombinedInspection?: boolean;
    deleteDisabled?: boolean;
    isSaving?: boolean;
    unlockOnly?: boolean;
};

const EditInspectionsContextMenu = (props: EditInspectionsContextMenuProps): React.ReactElement => {
    const { inspection, isCombinedInspection, children, pointer = false, deleteDisabled = false, isSaving = false, unlockOnly = false } = props;
    const [fopen, setIsOpen] = useState(false);
    const features = useSelector(getFeaturesSelector);
    const done = inspection.status === 'report';
    const locked = inspection.locked;
    const planned = inspection.status === 'planned' && !locked;
    const downloaded = inspection.status === 'planned' && locked;
    const ongoing = inspection.status === 'ongoing';
    const isPartOfCombinedInspection = isInspectionPartOfCombinedInspection(inspection);
    const canReassign = inspection.features.includes('change_assignment_data') && !isCombinedInspection && (ongoing || downloaded);
    const canUnlock = downloaded && inspection.features.includes('unlock') && !isCombinedInspection;
    const canReset = inspection.features.includes('reset_inspection') && ongoing && !isCombinedInspection;
    const canSplit = inspection.features.includes('split_planned_shipment') && inspection.split_shipment_allowed && planned && inspection.total_quantity;
    const canViewReport = inspection.features.includes('read_report_data');
    const canDuplicate = features.includes('u_create_order_and_inspection');
    // const canDuplicate = !isCombinedInspection && features.includes('u_create_order_and_inspection');
    const dispatch = useAppDispatch();
    const close = () => {
        setIsOpen(!fopen);

    }
    const getItem = (item: React.ReactElement, doClose = true, disabled = false) => {
        return <div style={{ textIndent: '1rem' }} className={classnames(disabled ? 'hover:bg-white cursor-default' : 'hover:bg-surface-plain', 'text-sm hover:text-primary -mx-4  link noUnderline')} onClick={() => doClose ? close() : null}>
            {item}
        </div>;
    }
    const getText = () => {
        const inProgress = (!isCombinedInspection && !isPartOfCombinedInspection || isCombinedInspection) && ongoing;
        return <div className='text-sm border-b pb-2 px-4 -mx-4'>
            <h5>
                {done && <Fragment><FormattedMessage id='inspections.finished_inspection' /></Fragment>}
                {downloaded && <Fragment><FormattedMessage id='inspections.downloaded_inspection' /></Fragment>}
                {planned && <Fragment><FormattedMessage id='inspections.planned_inspection' /></Fragment>}
                {ongoing && <Fragment><FormattedMessage id='inspections.inspection_in_progress' /></Fragment>}
            </h5>
            {downloaded && unlockOnly && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_downloaded_you_can_unlock_it' /></p>}
            {downloaded && !unlockOnly && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_downloaded_you_can_unlock_it_or_reassign_it_to_a_different_inspector' /></p>}
            {inProgress && !isCombinedInspection && <p><FormattedMessage id='inspections.the_inspection_is_in_progress_You_can_reassign_it_to_a_different_inspector' />{isCombinedInspection &&
                <p><strong><FormattedMessage id='inspections.CI_please_note' /></strong><FormattedMessage id='inspections.other_inspections_on_CI_will_be_reassigned_too' /></p>}</p>}
            {done && <p><FormattedMessage id='inspections.the_inspection_is_finished' /></p>}
            {!isCombinedInspection && isPartOfCombinedInspection && <Fragment><FormattedMessage id='inspections.this_inspection_is_part_of_a_combined_inspection_and_cannot_be_edited' />
                {getItem(<Link className='link noUnderline flex py-2' to={'/inspections/edit_inspection/' + inspection.master_inspection.order_id}><FormattedMessage id='inspections.view_combined_inspection' /></Link>)}
            </Fragment>
            }
            {isCombinedInspection && <Fragment><FormattedMessage id='inspections.this_inspection_is_a_combined_inspection_and_cannot_be_edited' />
                {getItem(<Link className='link noUnderline flex py-2' to={'/inspections/edit_inspection/' + inspection.order_id}><FormattedMessage id='inspections.view_original_inspection' /></Link>)}

            </Fragment>}
            {(done || ongoing) && canViewReport &&
                getItem(<Link to={['/inspections/reports/', isPartOfCombinedInspection ? inspection.master_inspection.id : inspection.inspection_id].join('')} className='link noUnderline flex py-2'>
                    <FormattedMessage id='inspections.view_report' />
                </Link>)}
        </div>
    }
    if ((unlockOnly && downloaded) || !unlockOnly) {
        return <Fragment>
            {canReset && <Reset className='py-2 bg-pink-500' showTrigger={false} inspection={inspection} isCombinedInspection={isCombinedInspection} />}
            <SplitShipments isCombinedInspection={isCombinedInspection} inspection={inspection} context='inspection' useTrigger={true} />
            <ContextMenu
                open={fopen}
                close={close}
                trigger={children ? children : null}
                pointer={pointer}
            >
                {getText()}
                {unlockOnly && canUnlock && getItem(<UnlockInspection type='link' inspectionId={inspection.inspection_id} />)}
                {!unlockOnly && <Fragment>
                    {canReassign && getItem(<ReassignInspectorOnInspection type='link' inspection={inspection} className='py-2' />, false)}
                    {canUnlock && getItem(<UnlockInspection type='link' inspectionId={inspection.inspection_id} />)}
                    {canReset && getItem(<div className='py-2' onClick={() => dispatch(showModal({ show: true, id: 'resetInspectorModal_' + inspection.inspection_id }))}><FormattedMessage id='inspection.reset' /></div>)}
                    {canDuplicate && getItem(<DuplicateInspection className='py-2' type='link' inspectionId={inspection.inspection_id} orderId={inspection.order_id} itemName={inspection.item_name} />)}
                    {canDuplicate && isPartOfCombinedInspection && getItem(<DuplicateInspection isCombinedInspection={true} context={isCombinedInspection ? 'combined' : 'not-combined'} className='py-2' type='link' inspectionId={inspection.master_inspection.id} orderId={inspection.order_id} itemName={inspection.item_name} />)}
                    {canSplit && getItem(<SplitShipmentsTrigger className='py-2' type='link' inspectionId={inspection.inspection_id} />)}
                    {getItem(<HasFeatureAccess type='inspection' feature='delete' inspection={inspection}>
                        <DeleteInspection
                            type='link'
                            disabled={deleteDisabled}
                            isSaving={isSaving}
                            orderId={inspection.order_id}
                            inspectionId={inspection.inspection_id}
                            className='py-2' />
                    </HasFeatureAccess>, false, deleteDisabled)}
                </Fragment>}
            </ContextMenu>
        </Fragment>
    }
    return children;
}
export default EditInspectionsContextMenu;
