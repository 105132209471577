
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Popup } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { getGroupsById, getUsersById } from '../../modules/users/selectors';
import Button from '../ui/components/buttons/Button';
import ContextMenu from '../ui/components/contextMenu/ContextMenu';
import { Field, NumberInterval, Search } from '../ui/components/fields/Field';
import { ListItem, ListItems } from '../ui/components/listitems/ListItem';




function legacyFileOpen() {
    var input = document.createElement('input');
    input.type = 'file';
    input.onchange = function () {
        input.files[0].arrayBuffer().then(function (arrayBuffer) {
            console.log(new TextDecoder().decode(arrayBuffer));
        });
    }
    console.log('legacyFileOpen ', input)
    input.click();
}
const Test = (): React.ReactElement => {
    const history = useHistory();
    const [bookmarksChecked, setBookmarksChecked] = React.useState(true);
    const [urlsChecked, setUrlsChecked] = React.useState(false);
    const [person, setPerson] = React.useState('pedro');
    const [inputValue, setInputValue] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    let [selected, setSelected] = React.useState<any>(null);
    const users = useSelector(getUsersById);
    const groups = useSelector(getGroupsById);
    const [max, setMax] = useState(undefined);
    const [min, setMin] = useState(undefined);
    const [value, setValue] = useState(undefined);

    console.log('history ', history)

    let options = [
        { id: 1, name: 'Adobe Photoshop' },
        { id: 2, name: 'Adobe XD' },
        { id: 3, name: 'Adobe InDesign' },
        { id: 4, name: 'Adobe AfterEffects' },
        { id: 5, name: 'Adobe Illustrator' },
        { id: 6, name: 'Adobe Lightroom' },
        { id: 7, name: 'Adobe Premiere Pro' },
        { id: 8, name: 'Adobe Fresco' },
        { id: 9, name: 'Adobe Dreamweaver' }
    ];

    /* useEffect(() => {
        legacyFileOpen();
    }, []) */
    const cRef = useRef();
    const items = [1, 3, 4]
    return (
        <div className='p-24 gap-y-8 flex flex-col bg-white'>
            <Search
                // onChange={setValue}
            />
            <NumberInterval
                isRequired={true}
                maxValue={max}
                value={min}
                onChange={(v) => {
                    console.log('v ', v)
                    setMin(v);
                    // setValue(v)
                }}
                labelLeft='From'
            />
            <NumberInterval
                isRequired={true}
                minValue={min}
                value={max}
                onChange={(v) => setMax(v)}
                labelLeft='To'
            />

            <Field
                label={{ text: 'Enter email' }}
                isRequired={true}
                iconLeft='callout_info'
                iconRight='expiry_by-order'
                iconRightClass='bg-attention'
            />
            <div className='flex border gap-4 p-8'>
                <div className='flex w-4/12'>
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                    Left left left left left
                </div>
                <div className='flex w-8/12 flex-col'>
                    <div className='flex p-4 border'>
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                    </div>
                    <div className='flex p-4 border'>
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                        Right right right right
                    </div>
                </div>
            </div>

            <div>
                <Button className='float-right'>FR</Button>
                <div>Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                    Fooo andnsdamnas, Fooo andnsdamnas,
                </div>
            </div>
            <ListItems>

                {items.map((item) => {
                    return <ListItem icon='status_checked-ok' iconClass='text-brand'>Scope Certificate</ListItem>

                })}

            </ListItems>

            {/* <ContextMenu
                trigger={<div>Foo</div>}
                overflowItems={[
                    { label: 'Delete', icon: 'operation_delete' },
                    { label: 'Add', icon: 'operation_add' },
                    { label: '', isSplitter: true },
                    { label: 'Ignore requirement', icon: 'toggle_visibility-off' },
                ]} /> */}
            {/*             <ContextMenu
                actions={[{
                    icon: 'object_qr-code',
                    onPress: () => alert('add media'),
                    className: 'bg-yellow-100 border-pin-500 border-4',
                    labelLeft: true,
                    label: 'Foo',
                },
                {
                    icon: 'operation_review',
                    label: 'Review',
                    labelRight: true,
                    onPress: () => alert('add media'),
                }]}
                overflowItems={[
                    { label: 'Delete', icon: 'operation_delete', onPress: () => null },
                    { label: 'Add', icon: 'operation_add' },
                    { label: '', isSplitter: true },
                    { label: 'Ignore requirement', icon: 'toggle_visibility-off' },
                ]} /> */}
            <ContextMenu
                // trigger={trigger}
                actions={[{
                    label: 'Add media',
                    labelLeft: true,
                    icon: 'operation_take-photo',
                    onPress: () => alert('add media'),
                },
                {
                    label: 'Add comment',
                    icon: 'object_comment',
                    onPress: () => alert('add media'),
                }]}
                overflowItems={[
                    { label: 'Delete', icon: 'operation_delete' },
                    { label: 'Add', icon: 'operation_add' },
                    { label: '', isSplitter: true },
                    { label: 'Ignore requirement', icon: 'toggle_visibility-off' },
                ]} />

            {/* <div className='border-b border-default'>Link</div>
            <div className='p-4 border-brand border'>Alert</div>
            <div className='p-4 border-danger border'>Danger</div>
            <Search 
                onChange={setValue}
                value={value}
                />
            <NumberInterval
                isRequired={true}
                maxValue={max}
                value={min}
                onChange={(v) => {
                    console.log('v ', v)
                    setMin(v);
                    setValue(v)
                }}
                labelLeft='From'
            />
            <NumberInterval
                isRequired={true}
                minValue={min}
                value={max}
                onChange={(v) => setMax(v)}
                labelLeft='To'
            />

            <Field
                isRequired={true}
                iconLeft='callout_info'
                iconRight='expiry_by-order'
                iconRightClass='bg-attention'
            /> */}

            {/* <NumberField isRequired={true} minValue={2}>
                <Input />
                <FieldError/>
            </NumberField> */}
            {/* <Field onBlur={(e) => console.log('blur ', e)} onChange={(e) => console.log('change ', e)} iconLeft='object_comment' placeholder='Enter foo'/> */}
            {/* <Popup 
                trigger={<div>FOo</div>}
                on='hover'
                >
                <div>Popup</div>
            </Popup> */}

            {/* <MyComboBox label="Ice cream flavor"
                defaultItems={options}
                inputValue={inputValue}                
                onInputChange={(v) => setInputValue(v)}
                onSelectionChange={(e) => console.log('foo ', e)}                
                onBlur={() => {
                    setSelected(inputValue)
                    setInputValue('');
                }}
            >
                {item => <MyItem>{item.name}</MyItem>}
            </MyComboBox> */}

            {/* <MultiItemsSelector items={[
                {
                    name: 'Groups',
                    type: 'groups',
                    icon: 'user_group',
                    items: Object.values(groups).map((u) => ({ id: u.id, name: u.name })),
                },
                {
                    name: 'Users',
                    type: 'users',
                    items: Object.values(users).map((u) => ({ id: u.id, name: getUserNameText(u) })),
                    
                },
            ]} /> */}

            {/* <div className='bg-surface-bg-clip border-base-border border-4 p-8'>BG</div>
            <KeyValuePair keyItem={{ name: 'Supplier', icon: 'user_supplier' }} value={{ value: 'Peter Nielsen' }} />
            <div className='flex gap-2'>
                <Clip removable icon='callout_warning-circle' label='Clip label' />
                <Clip removable icon='callout_warning-circle' size={Sizes.Small} label='Clip label' />
                <Clip removable icon='callout_warning-circle' size={Sizes.XSmall} label='Clip label' />
            </div>
            <ClipStack clips={[{
                label: 'foo',
                removable: true,
                icon: 'expiry_renewal',
                                
            }]} />
            <ListItems>
                <ListItem icon='status_checked-ok' iconClass='text-brand'>Scope Certificate</ListItem>
                <ListItem icon='operation_review' iconClass='text-status-bg-pending'>Transaction Certificate</ListItem>
            </ListItems> */}
            {/* <ContentSection content={<><Icon size={Sizes.Small} name='like' />Some content</>} />

            <Label label='labelfoo'>Label</Label>
            <h3 className='items-center'>Header with icon <Icon name='info' className='text-blue-500' size={Sizes.Small} /></h3>
            <Icon name='info' className='text-blue-500' />
            <Icon name='like' onClick={() => console.log('icon click')} />
            <MyMeter value={25} label='foo' />

            <MyTagGroup label="Ice cream flavor" selectionMode="multiple" className='p-8 border'
                onRemove={(keys) => console.log(keys)}

                selectedKeys={selected}
                onSelectionChange={setSelected}
            >
                <MyTag id='1'>Chocolate</MyTag>
                <MyTag id='2'>Mint</MyTag>
            </MyTagGroup>

            <p>
                Current selection (controlled):{' '}
                {selected}
            </p> */}



            {/* <Tags selectionMode="multiple">
                <Tag>XZFoo</Tag>
                <Tag>Bar</Tag>
            </Tags> */}
            {/* <div className='items-center flex border p-1 rounded bg-white space-x-1'>
                <Icon name='like' />
                <div>Like</div>
            </div>


            <div className='items-center flex border p-1 rounded bg-white space-x-1'>
                <Icon name='zoom_in' />
                <Icon name='zoom_out' />
                <Icon name='download' />
                <div>Like</div></div>
            <div className='items-center flex border p-1 rounded bg-white space-x-1'><Icon name='like' size={Sizes.XLarge} /><div>Like</div></div>
            <div className='items-center flex border p-1 rounded bg-white space-x-1'><Icon name='foo' size={Sizes.XXLarge} /><div>Foo</div></div> */}
            {/* <div className='animate-in fade-in'>Animate?</div>
            <div className='animate-in fade-in'>Animate?</div>
            <button className="animate-bounce duration-300 ...">Button A</button>
            <button className="animate-bounce duration-300 ...">Button B</button>
            <button className="animate-bounce duration-300 ...">Button C</button>
            <QModal onOpenChange={(open) => console.log('open ', open)} trigger={<div className='link'>Show modal</div>}>
                Foo
            </QModal>
            <Grid columns="3" gap="3">
                <Flex direction="column" gap="3">
                    <Box height="5">
                        DB
                    </Box>
                    <Box height="7">
                        DB
                    </Box>
                    <Box height="9">
                        DB
                    </Box>
                </Flex>

                <Flex direction="column" gap="3">
                    <Box grow="1">
                        DB
                    </Box>
                    <Box height="6">
                        DB
                    </Box>
                </Flex>
    </Grid> */}
            {/* <div className='border-t border-r w-full'>

                <ListItems>
                    <ListItem
                        value='Martin Schmidt m.schmidt@fivestarsupplier.com' />
                    <ListItem
                        value='Mary Schmidt m.schmidt@fivestarsupplier.com'
                    
                    />
                    <ListItem selectable={{ type: SelectableType.Checkbox, id: 'foo1', selected: false, selectedColor: '#ff9933' }} value='Foo' secondaryAction={{ type: SecondaryActionEnum.Add }} />
                </ListItems>

            </div>
            <div className='border-t border-r w-96'>
                <ListItems>
                    <div>x</div>
                    
                </ListItems>
            </div> */}
            {/* <div>
                <DropdownMenu.Root modal>
                    <DropdownMenu.Trigger className='p-2 border cursor-pointer'>
                        <Icon name='more_vert' />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                        <DropdownMenu.Content className='w-96 border bg-white' side='right' align='start'>
                            <DropdownMenu.Label />
                            <DropdownMenu.Item className='border-b p-2'>Baz</DropdownMenu.Item>
                            <DropdownMenu.Item className='border-b p-2'>Baz</DropdownMenu.Item>
                            <DropdownMenu.Item className='border-b p-2'>Baz</DropdownMenu.Item>
                            <ListItem value='Foo' icon={'settings'} secondaryAction={SecondaryActions.Add} />

                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
                </div>*/}
        </div>
    )

};

export default Test;