import React, { useEffect, useState } from 'react';
import { MicroInteractionCollapse, MicroInteractionExpand } from '../../../ui/components/MicroInteractions';
import { twMerge } from '../../../utils';

type Props = {
    className?: string;
    isExpanded?: boolean;
};

const Expand = (props: Props): React.ReactElement => {
    const { className } = props;
    const [isExpanded, setIsExpaned] = useState(props.isExpanded || false);
    useEffect(() => {
        setIsExpaned(props.isExpanded);
    }, [props.isExpanded]);
    return <div className={twMerge('', className)}>
        {isExpanded ? <MicroInteractionCollapse /> : <MicroInteractionExpand />}
    </div>
}
export default Expand;
