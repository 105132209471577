import classnames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import Label from '../../../base/components/basic/Label';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { UserNameText } from '../../users/components/Widgets';

type WatcherProps = {
    user: {
        email: string,
        disabled: boolean,
        firstname: string,
        lastname: string,
        id: string
    };
    disabled: boolean;
    name?: string;
    handleRemoveWatcher(id: string): void;
}

const Watcher = (props: WatcherProps): React.ReactElement => {
    const [disabled, setDisabled] = useState(false);
    return (
        <Popup
            position='top center'
            trigger={<span className='flex'><Label disabled={props.user.disabled} className={classnames('flex watcher-item', props.user.disabled && 'bg-default-widget-color-disabled')}>
                {/* Please don't remove the 'watcher-item' class name - it's used by the smoketest :-) */}
                <span className='flex justify-between space-x-2'>
                    <span className={classnames({ 'line-through': props.user.disabled })}>{props.user.firstname} {props.user.lastname}</span>
                    {/* (!props.disabled) && <Icon color={'grey'} className='cursor-pointer' onClick={(): void => {
                        if(!disabled) { // Ensuring that remove endpoint is not called repeatedly until watcher is removed by next render
                            props.handleRemoveWatcher(props.user.id);
                            setDisabled(true);
                        }
                    }} name='delete' /> */}
                    {/* NB! Please do not remove the watcher-delete-icon class - it is used by the smoketest */}
                    {(!props.disabled) && <RemoveIcon iconClassName='watcher-delete-icon cursor-pointer text-gray-400 font-extrabold text-sm' onClick={(): void => {
                        if (!disabled) { // Ensuring that remove endpoint is not called repeatedly until watcher is removed by next render
                            props.handleRemoveWatcher(props.user.id);
                            setDisabled(true);
                        }
                    }} />}
                </span>
            </Label></span>}>
            {props.user.disabled && <div className='font-bold'><FormattedMessage id='watchers.user_is_disabled' /></div>}
            <div>{props.name ? props.name : <UserNameText user={props.user} />}</div><div>{props.user.email}</div></Popup>
    );
};

export default Watcher;
1