import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CorrectiveAction } from '../../../backend_api/models';
import { getPrettyDate } from '../../../base/utils';
import TranslatedLabel from '../../globals/components/TranslatedLabel';
import { ListView, ListViewHeader, ListViewItem, ListViewLine } from '../../globals/components/views/list/ListView';
import { UserInitialsText } from '../../users/components/Widgets';
import { isFetchingSelector } from '../selectors/correctiveActionSelectors';
import CorrectiveActionStatus from './CorrectiveActionStatus';

type Props = {
    className?: string;
    correctiveActions: CorrectiveAction[];
    isFetching?: boolean;
}

const CorrectiveActionMediumList = (props: Props) => {
    const history = useHistory();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const openDetailedPage = (action: CorrectiveAction) => {
        history.push('/ca/details/' + action.id);
    };
    const isFetching = useSelector(isFetchingSelector);
    const hasCorrectiveActions = props.correctiveActions.length > 0;
    const content =
        <ListView className={props.className}>

            {hasCorrectiveActions && <ListViewHeader className='pb-0' items={[
                { label: formatMessage({ id: 'corrective_actions.medium_list.finding' }), key: '1', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.description' }), key: '2', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.resp_approver' }), key: '3', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.created' }), key: '4', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.resolved' }), key: '5', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.due' }), key: '6', className: 'md:w-1/7 w-full' },
                { label: formatMessage({ id: 'corrective_actions.medium_list.status' }), key: '7', className: 'md:w-1/7 w-full' },
            ]} />}
            {!hasCorrectiveActions && (isFetching || props.isFetching) && <div><FormattedMessage id='corrective_actions.medium_list.loading_data' /></div>}
            {hasCorrectiveActions && props.correctiveActions.map((action) => {
                return (<ListViewLine onClick={() => openDetailedPage(action)} key={action.id} className='space-y-0 bg-white cursor-pointer border-b'>
                    <ListViewItem className='items-start md:w-1/7 w-full py-2'>
                        <TranslatedLabel text={action.finding} />
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        <TranslatedLabel text={action.description} />
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        <UserInitialsText displayIfEmpty='-' className='' user={action.responsible} />
                        /
                        <UserInitialsText className='' displayIfEmpty='-' user={action.approver} />
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        {action.created_at && getPrettyDate(action.created_at || '-')}
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        {action.resolve_date && getPrettyDate(action.resolve_date) || '-'}
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        {action.due_date_at && getPrettyDate(action.due_date_at || '-')}
                    </ListViewItem>
                    <ListViewItem className='items-start md:w-1/7 w-full'>
                        <CorrectiveActionStatus state={action.state} />
                    </ListViewItem>
                </ListViewLine>)
            })}
        </ListView>;
    return content;
}

export default CorrectiveActionMediumList;