import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { AuditTag } from '../../../backend_api/models/AuditTag';
import { getPrettyDate } from '../../../base/utils';
import { getColor } from '../../audit/components/AuditDisplayState';
import { TextItem } from '../../globals/components';

type Props = {
    className?: string;
    auditTypeTag: AuditTag;
};

const SupplierListAuditType = (props: Props): React.ReactElement => {
    const { className, auditTypeTag } = props;
    const intl = useIntl();
    const link = auditTypeTag.status === 'planned' ? 'audits/edit_audit/' + auditTypeTag.id : 'audits/report/' + auditTypeTag.id
    const cn = twMerge('text-white bg-' + getColor(auditTypeTag.status), (auditTypeTag.conclusion && ' bg-[' + auditTypeTag.conclusion.background_color + ']', auditTypeTag.status === null && 'bg-gray-200 p-4'));
    // const cn = twMerge('bg-' + getColor(auditTypeTag.status), (auditTypeTag.conclusion && ' bg-{' + auditTypeTag.conclusion.background_color + '}'));
    
    const finalConclusion = auditTypeTag.conclusion;
    const statusLabel = finalConclusion ? typeof (finalConclusion) === 'string' ? finalConclusion : finalConclusion.name.text : intl.formatMessage({ id: 'status.' + auditTypeTag.status });
    const trigger = <Link to={link}><div className={twMerge('flex w-48 pr-2', className)}>
        {auditTypeTag.id !== 'fake_id' && <div style={{ backgroundColor: finalConclusion && finalConclusion.background_color, color: finalConclusion && finalConclusion.text_color }} className={twMerge('py-1 px-6 w-full inline rounded text-sm', cn, className)}>
            {auditTypeTag.audit_type && <div className='flex space-x-2'>
                <div>{auditTypeTag.audit_type.tag || ''}:</div>
                <div>{statusLabel}</div>
            </div> || <div className='flex space-x-2'><div>{statusLabel}</div></div>}
            <div className='text-xs'>{getPrettyDate(auditTypeTag.date)}</div>
        </div>}
        {auditTypeTag.id === 'fake_id' && <div className={twMerge('flex w-48 pr-2', cn)}>Not set</div>}
    </div>
    </Link>;
    return <Popup className='w-72' trigger={trigger}>
        <TextItem text={auditTypeTag.audit_type && <>{auditTypeTag.audit_type.name}</> || <FormattedMessage id='audits.audit_type.label.no_type' />} label={intl.formatMessage({ id: 'audits.audit_type' })} />
        <TextItem className='font-normal' text={statusLabel} label={intl.formatMessage({ id: 'audits.audit_type.label.status' })} />
        <TextItem text={auditTypeTag.checklist_name} label={intl.formatMessage({ id: 'audits.audit_type.label.checklist' })} />
        {auditTypeTag.assigned_user && <TextItem text={auditTypeTag.assigned_user.firstname + ' ' + auditTypeTag.assigned_user.lastname} label={intl.formatMessage({ id: 'audits.audit_type.label.auditor' })} />}
        <div className='text-secondary'>{getPrettyDate(auditTypeTag.date)}</div>
    </Popup>
}
export default SupplierListAuditType;


