/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 57.1.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCorrectiveActionRequest
 */
export interface CreateCorrectiveActionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionRequest
     */
    approver_id?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCorrectiveActionRequest
     */
    audit_links: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCorrectiveActionRequest
     */
    checkpoint_links?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionRequest
     */
    due_date_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionRequest
     */
    finding: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCorrectiveActionRequest
     */
    requires_evidence_from_app?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCorrectiveActionRequest
     */
    responsible_id?: string | null;
}

export function CreateCorrectiveActionRequestFromJSON(json: any): CreateCorrectiveActionRequest {
    return CreateCorrectiveActionRequestFromJSONTyped(json, false);
}

export function CreateCorrectiveActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCorrectiveActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver_id': !exists(json, 'approver_id') ? undefined : json['approver_id'],
        'audit_links': json['audit_links'],
        'checkpoint_links': !exists(json, 'checkpoint_links') ? undefined : json['checkpoint_links'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'due_date_at': !exists(json, 'due_date_at') ? undefined : json['due_date_at'],
        'finding': json['finding'],
        'requires_evidence_from_app': !exists(json, 'requires_evidence_from_app') ? undefined : json['requires_evidence_from_app'],
        'responsible_id': !exists(json, 'responsible_id') ? undefined : json['responsible_id'],
    };
}

export function CreateCorrectiveActionRequestToJSON(value?: CreateCorrectiveActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver_id': value.approver_id,
        'audit_links': value.audit_links,
        'checkpoint_links': value.checkpoint_links,
        'description': value.description,
        'due_date_at': value.due_date_at,
        'finding': value.finding,
        'requires_evidence_from_app': value.requires_evidence_from_app,
        'responsible_id': value.responsible_id,
    };
}


