import { handleActions } from 'redux-actions';
import { REMOVE_COMMENT_ATTACHMENT_REQUEST } from '../comments/types';
import * as types from './constants';
import { AttacmentsState } from './models';

const initialState: AttacmentsState = {
    isFetching: false,
    isSaving: false,
    inspections: {
        byId: {},
    },
    orders: {
        byId: {},
    },
    checkpoints: {
        byId: {},
    },
    productPhotos: {
        byId: {},
    },
    comments: {
        byId: {},
    },
    followUpTasks: {
        byId: {},
    },
};

const reducer = handleActions({
    [types.ADD_ATTACHMENT_SUCCESS]:
        (state = initialState, action: any) => {
            const id = action.payload.attachToItemId;
            const type = action.payload.attachToType;
            const byId = {};
            const items = Object.assign([], state[type].byId[id].items);
            items.push(action.payload.file);
            byId[id] = {
                items,
                uploading: state[type].byId[id].uploading,
            };
            return {
                ...state,
                [type]: {
                    byId,
                },
            };
        },
    [types.ADD_ATTACHMENT_FAILURE]:
        (state = initialState, action: any) => {
            const id = action.payload.attachToItemId;
            const uuid = action.payload.uuid;
            const type = action.payload.attachToType;
            let uploading = [];
            const byId = state[type].byId;
            uploading = Object.assign([], state[type].byId[id].uploading);
            uploading.forEach((upload) => {
                if (uuid === upload.uuid) {
                    upload.progress = action.payload.progress;
                    upload.status = 'error';
                }
            });
            byId[id] = {
                uploading,
                items: state[type].byId[id] && state[type].byId[id].items || []
            };
            return Object.assign({}, state, {
                [type]: {
                    byId,
                },
            });
        },

    [types.ADD_ATTACHMENT_PROGRESS]:
        (state = initialState, action: any) => {
            const id = action.payload.attachToItemId;
            const uuid = action.payload.uuid;
            const type = action.payload.attachToType;
            let uploading = [];
            const byId = state[type].byId;
            if (state[type].byId[id] && state[type].byId[id].uploading) {
                let hasItem = false;
                uploading = Object.assign([], state[type].byId[id].uploading);
                uploading.forEach((upload) => {
                    if (uuid === upload.uuid) {
                        hasItem = true;
                        upload.progress = action.payload.progress;
                    }
                });
                if (!hasItem) {
                    uploading.push(action.payload);
                }

            } else {
                byId[id] = uploading;
                uploading.push(action.payload);

            }
            byId[id] = {
                uploading,
                items: state[type].byId[id].items,
            };
            return Object.assign({}, state, {
                [type]: {
                    byId,
                },
            });
        },
    ['attachments/GET_ATTACHMENTS_SUCCESS']:
        (state = initialState, action: any) => {
            const id = action.payload.attachToItemId;
            const type = action.payload.attachToType;
            const byId = state[type].byId || {};
            byId[id] = {
                items: action.payload.attachments,
                uploading: state[type].byId[id] && state[type].byId[id].uploading || [],
            };
            const items = Object.assign(state, { [type]: { byId } });
            return Object.assign({}, state, {
                items,
            });
        },
    [types.REMOVE_ATTACHMENT_REQUEST]:
        (state = initialState, action: any) => {
            const id = action.payload.removeFromItemId;
            const url = action.payload.url;
            const type = action.payload.removeFromType;
            const items = Object.assign([], state[type].byId[id].items);
            items.forEach((item) => {
                if (item.url === url) {
                    item.deleting = true;
                }
            });
            return Object.assign({}, state, {
                items,
            });
        },
    [types.CLEAR_UPLOADED_ATTACHMENTS]:
        (state = initialState, action: any) => {
            const id = action.payload.attachToItemId;
            const type = action.payload.attachToType;
            const { clearPrevious = false } = action.payload;
            const byId = state[type].byId || {};
            let items = [];
            if (!clearPrevious) {
                items = state[type].byId[id] && state[type].byId[id].items;
            }
            byId[id] = {
                uploading: [],
                items,
            };
            return Object.assign({}, state, {
                byId,
            });
        },
    [REMOVE_COMMENT_ATTACHMENT_REQUEST]:
        (state = initialState, action: any) => {
            const id = action.payload.commentId;
            const url = action.payload.attachmentURL;
            const byId = state.comments.byId || {};
            const items = state.comments.byId[id] && state.comments.byId[id].items;
            let index = -1;
            items.forEach((item, i: number) => {
                if (item.url === url) {
                    index = i;
                }
            });
            items.splice(index, 1);
            byId[id] = {
                uploading: [],
                items,
            };
            return Object.assign({}, state, {
                byId,
            });
        },
}, initialState);

export default reducer;
