import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { useIsMobileDevice } from '../../../../base/utils';
import { getInspectionDate } from '../../Utils';
import { hasInspectionTypes } from '../../selectors/selectors';
import { Inspection } from '../../types';
import { DefectsLine } from '../InspectionsItems';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import { CombinedInspectionLabel } from './CombinedInspections';

type CollectedInspectionLineProps = {
    inspection: Inspection;
    removeInspection(inspection: Inspection);
}

const CollectedInspectionLine = (props: CollectedInspectionLineProps): React.ReactElement => {
    const intl = useIntl();
    const isMobile = useIsMobileDevice();
    const hasTypes = useSelector(hasInspectionTypes);
    return (<Grid.Row columns={15} className='inspection-grid-content inspection-grid-line sub' verticalAlign='middle'>
        <Grid.Column width={isMobile ? 3 : 2} className='items-center'><CombinedInspectionLabel type='dashboard' inspection={props.inspection} />{/* <ReinspectLabel type='dashboard' inspection={props.inspection} /> */}<SplitShipmentsMarker inspection={props.inspection}/><span className='ml-1'>{props.inspection.item_number}</span></Grid.Column>
        <Grid.Column width={isMobile ? 4 : 5} className=''>{props.inspection.item_name}</Grid.Column>
        <Grid.Column width={2} className=''>{props.inspection.total_quantity}</Grid.Column>
        {!hasTypes && <Grid.Column width={2} className=''>
            <DefectsLine defects={props.inspection.defect_counts} />
        </Grid.Column>}
        {hasTypes && <Grid.Column width={2} className='inspectionTypes'>
            <InspectionTypeMarker inspections={[props.inspection]} type='dashboard' />
        </Grid.Column>}
        <Grid.Column width={2} className=''>{getInspectionDate([props.inspection], intl.locale)}</Grid.Column>
        <Grid.Column width={2} className=''><span className='link' onClick={(): void => props.removeInspection(props.inspection)}><FormattedMessage id='inspections.remove' /></span></Grid.Column>
    </Grid.Row>)
}

export default CollectedInspectionLine;
