import classnames from 'classnames';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dimmer, Dropdown, Form, Input, Loader, TextArea } from 'semantic-ui-react';
import { TextWithTranslation } from '../../../backend_api/models';
import Button from '../../../base/components/basic/Button';
import DatePicker from '../../../base/components/basic/DatePicker3';
import Icon from '../../../base/components/basic/Icon';
import { DEFAULT_DATE_FORMAT2 } from '../../../base/config';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import {
    CreateRequirement, DocumentTemplateScopeProductTypeEnum, DocumentTemplateScopeSupplierOrProductionUnitTypeEnum, ExpirationFromFileTypeEnum, NewDocumentTemplate,
    NoExpirationTypeEnum,
    PerOrderTypeEnum, TagAsList
} from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { createRequirement, getComplianceCategories } from '../complianceSlice';
import { isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceTagsBrowser, { addOrRemoveTag } from './ComplianceTagsBrowser';

type ComplianceCreateRequirementTemplateProps = {
    className?: string;
};

// const defaultScope: DetailedRequiredDocumentDocumentTemplateScope = { type: DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope }
const defaultExpirationRule = { type: ExpirationFromFileTypeEnum.ExpirationFromFile }

function newDefaultDocumentTemplate(): NewDocumentTemplate {
    return {
        name: '',
        description: '',
        expiration_rule: defaultExpirationRule,
        scope: { type: DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope }
    }
}

export function createTextWithTranslation(languageCode, content): TextWithTranslation {
    return {
        language: languageCode,
        text: content,
        translations: {
            languageCode: content
        }
    }
}

export function useDocumentTemplateScopeOptions() {
    const intl = useIntl();

    return useMemo(function () {
        return [
            {
                value: DocumentTemplateScopeProductTypeEnum.DocumentTemplateProductScope,
                text: intl.formatMessage({ id: 'compliance.requirements.document_template_scope.product_scope.text' }),
                description: intl.formatMessage({ id: 'compliance.requirements.document_template_scope.product_scope.description' })
            },
            {
                value: DocumentTemplateScopeSupplierOrProductionUnitTypeEnum.DocumentTemplateSupplierOrProductionUnitScope,
                text: intl.formatMessage({ id: 'compliance.requirements.document_template_scope.supplier_or_production_unit_scope.text' }),
                description: intl.formatMessage({ id: 'compliance.requirements.document_template_scope.supplier_or_production_unit_scope.description' })
            }
        ]
    }, [intl])
}

export function useExpirationRuleOptions() {
    const intl = useIntl();

    return useMemo(function () {
        return [
            // {
            //     value: ExpirationRules.ExpirationOverTime,
            //     text: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.expiration_over_time.text' }),
            //     description: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.expiration_over_time.description' }),
            // },
            {
                value: ExpirationFromFileTypeEnum.ExpirationFromFile,
                text: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.expiration_from_file.text' }),
                description: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.expiration_from_file.description' }),
            },
            {
                value: NoExpirationTypeEnum.NoExpiration,
                text: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.no_expiration.text' }),
                description: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.no_expiration.description' }),
            },
            {
                value: PerOrderTypeEnum.PerOrder,
                text: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.per_order.text' }),
                description: intl.formatMessage({ id: 'compliance.requirements.expiration_rule.per_order.description' }),
            }
        ]
    }, [intl]);

}

const ComplianceCreateRequirementTemplate = (props: ComplianceCreateRequirementTemplateProps): React.ReactElement => {
    const { className } = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const expirationRuleOptions = useExpirationRuleOptions();
    const documentTemplateScopeOptions = useDocumentTemplateScopeOptions();

    const isSaveable = () => {
        return requirement.start_date !== '' && requirement.name !== '';
    }
    const isFetching = useSelector(isFetchingSelector);
    const initialRequirement: CreateRequirement = {
        name: '',
        description: '',
        tags: [],
        document_templates: [newDefaultDocumentTemplate()],
        start_date: ''
    }
    const [requirement, setRequirement] = useState<CreateRequirement>(initialRequirement);

    const handleSave = () => {
        dispatch(createRequirement(requirement));
        setRequirement(initialRequirement);
    }

    useEffect(() => {
        dispatch(getComplianceCategories());
    }, []);

    const handleRequirementUpdate = (name: string, value: string) => {
        const requirementDataCopy: CreateRequirement = Object.assign({}, requirement);
        requirementDataCopy[name] = value;
        setRequirement(requirementDataCopy);
        storeDraft();
    }

    const handleDocumentUpdate = (index: number, name: string, value: string | Record<string, unknown>) => {
        const requirementDataCopy: CreateRequirement = Object.assign({}, requirement);
        requirementDataCopy.document_templates[index][name] = value;
        setRequirement(requirementDataCopy);
        storeDraft();
    }

    const close = () => {
        history.back();
        setRequirement(initialRequirement);
    }

    const deleteDocumentItem = (index: number) => {
        setRequirement({
            ...requirement,
            document_templates: requirement.document_templates.filter((doc, i) => i != index)
        });
    }

    const storeDraft = () => {
        window.localStorage.setItem('requirement-draft', JSON.stringify(requirement));
    }

    const saveTags = (tag: TagAsList) => {
        setRequirement({
            ...requirement,
            tags: addOrRemoveTag(requirement.tags, tag)
        });
        storeDraft();
    }

    const getDocument = (index: number, isLast: boolean) => {
        const documentTemplate = requirement.document_templates[index];
        return <div key={`document-${index}`} className='-mx-4 px-4'>
            <Form.Group className='flex space-x-4 w-full'>
                <Form.Field className='w-48 pb-2 md:pb-0'>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.name' /></label>
                    <Input
                        autoComplete='on'
                        type='text'
                        name={`document_name_${index}`}
                        value={documentTemplate.name}
                        onChange={(_, i) => {
                            handleDocumentUpdate(index, 'name', i.value as string);
                        }} />
                </Form.Field>
                <Form.Field className='w-72 pb-2 md:pb-0'>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.description' /></label>
                    <Input
                        autoComplete='on'
                        type='text'
                        name={`document_description_${index}`}
                        value={documentTemplate.description}
                        onChange={(_, i) => {
                            handleDocumentUpdate(index, 'description', i.value as string);
                        }} />
                </Form.Field>
                <Form.Field className='w-72 pb-2 md:pb-0' required>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.expiration_rule' /></label>
                    <Dropdown
                        className='w-9/12'
                        text={documentTemplate.expiration_rule === null && intl.formatMessage({ id: 'compliance.requirements_list.create_requirement.expiration_rule.select' })}
                        options={expirationRuleOptions}
                        selection
                        value={documentTemplate.expiration_rule && documentTemplate.expiration_rule.type}
                        onChange={(i, r) => {
                            handleDocumentUpdate(index, 'expiration_rule', { type: r.value, months: 6 }); //TODO months should be replaced by proper selector
                        }} />
                </Form.Field>
                <Form.Field className='w-72 pb-2 md:pb-0' required>
                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.scope' /></label>
                    <Dropdown
                        className='w-9/12'
                        options={documentTemplateScopeOptions}
                        selection
                        value={documentTemplate.scope.type}
                        onChange={(i, r) => {
                            handleDocumentUpdate(index, 'scope', { type: r.value }); //TODO months should be replaced by proper selector
                        }} />
                </Form.Field>
                {
                    // index !== 0 ?
                    <Form.Field className='flex flex-row'>
                        <Button disabled={isLast} className='px-2 py-1 text-sm mt-8' onClick={() => deleteDocumentItem(index)}><FormattedMessage id='compliance.requirements_list.create_requirement.expiration_rule.remove' /></Button>
                    </Form.Field>
                }
            </Form.Group>
        </div>
    }

    const addDoc = () => {
        setRequirement({
            ...requirement,
            document_templates: [
                ...requirement.document_templates,
                newDefaultDocumentTemplate()
            ]
        })
    }

    return <PageContainer>
        <Dimmer active={isFetching} inverted>
            <Loader className='dimLoader'><FormattedMessage id='inspections.loading_data_please_wait' /></Loader>
        </Dimmer>
        <div className={classnames(className, 'flex justify-center')}>
            <div className='w-full md:w-8/12 order-last md:order-first'>
                <ContentSection
                    content={<Fragment>
                        <div className='pb-6'>
                            <h3><FormattedMessage id='compliance.requirements_list.create_requirement.title' /></h3>
                            <Form>
                                <Form.Field width={16} inline required>
                                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.name' /></label>
                                    <Input
                                        autoFocus={true}
                                        autoComplete='on'
                                        type='text'
                                        name='name'
                                        value={requirement.name}
                                        onChange={(_, i) => {
                                            handleRequirementUpdate('name', i.value as string);
                                        }} />

                                </Form.Field>
                                <Form.Field width={16}>
                                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.description' /></label>
                                    <TextArea
                                        autoComplete='on'
                                        type='text'
                                        name='description'
                                        value={requirement.description}
                                        onChange={(_, i) => {
                                            handleRequirementUpdate('description', i.value as string);
                                        }} />
                                </Form.Field>
                                <div className='my-4'>
                                    <ComplianceTagsBrowser
                                        tags={requirement.tags}
                                        onSelected={saveTags}
                                        editable={true}
                                        downwardPropagation={true}
                                    />
                                </div>

                                <Form.Field width={6} required>
                                    <label><FormattedMessage id='compliance.requirements_list.create_requirement.start_date' /></label>
                                    <DatePicker
                                        showTwoMonths={false}
                                        pastDates={true}
                                        date={(!isUndefinedOrNullOrEmptyString(requirement.start_date) && new Date(requirement.start_date) || null)}
                                        handleChange={(d) => {
                                            setRequirement({
                                                ...requirement,
                                                start_date: d === null ? '' : format(d, DEFAULT_DATE_FORMAT2)
                                            });
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field width={16} inline className='pt-8'>
                                    <div className='font-bold pb-2'><FormattedMessage id='compliance.requirements_list.create_requirement.document' /></div>
                                    {requirement.document_templates.map((item, i) => {
                                        return getDocument(i, requirement.document_templates.length === 1)
                                    })}
                                    <Button className="bg-blue-500 text-white py-2 px-8" onClick={addDoc}><FormattedMessage id='complinace.requirement.add_document_template.add_button' /></Button>
                                </Form.Field>
                                <div className='mt-10'>
                                    <Form.Field width={16} inline>
                                        <div className='flex flex-row justify-start'>
                                            <Button onClick={() => {
                                                window.localStorage.removeItem('requirement-draft');
                                                close();
                                            }}><FormattedMessage id='globals.cancel' /></Button>
                                            <Button className='mx-4' data-test-id='button-create-requirement-template-save' primary disabled={!isSaveable()} onClick={handleSave}><FormattedMessage id='globals.save' /></Button>
                                        </div>
                                    </Form.Field>
                                </div>
                            </Form>
                        </div>
                    </Fragment>
                    }
                />
                <Link className='link noUnderline items-center flex' to={'/compliance/requirements'}><Icon name='chevron_left' className='text-xl' /><FormattedMessage id='compliance.requirements_list.create_requirement.back' /></Link>
            </div>
        </div>
    </PageContainer>
}
export default ComplianceCreateRequirementTemplate;
