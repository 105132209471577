/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SetCustomFieldNotSet } from './SetCustomFieldNotSet';
import type { SetCustomFieldNull } from './SetCustomFieldNull';
import type { SetCustomFieldText } from './SetCustomFieldText';

/**
 *   Value for a text based custom field
 *
 */
export type SetCustomFieldTextValue = {
    new_value: (SetCustomFieldNotSet | SetCustomFieldNull | SetCustomFieldText);
    type: SetCustomFieldTextValue.type;
};

export namespace SetCustomFieldTextValue {

    export enum type {
        TEXT = 'Text',
    }


}

