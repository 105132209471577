import { UploadField } from '@navjobs/upload';
import React from 'react';
import { FormattedMessage } from 'react-intl';


type UploaderProps = {
    inProgress: boolean;
    updateState(name: string, files: any[]); // TODO: Define files types!!!!!!
    uploadFiles();
}
const uploader = (props: UploaderProps): React.ReactElement => {

    return (<UploadField
        onFiles={(files): void => {
            props.updateState('droppedFiles', files);
            // Needed to ensure that droppedFiles are in place in state before uploading
            // FIXME: remove setTimeout and use setState callback
            setTimeout(() => {
                props.uploadFiles();
            }, 150);
        }}
        containerProps={{
            className: 'browseFiles',
        }}
        uploadProps={{
            // accept: '.jpg, .png., .pdf,.doc,.docx,.txt,.rtf, .dmg', // TODO: get accepted upload file types from config
            multiple: true,
        }}
    >
        {!props.inProgress && <a className='selectFiles'><FormattedMessage id='globals.select_files' /></a>}
    </UploadField>);
};

export default uploader;
