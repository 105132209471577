import React, { Fragment } from 'react';
import { Checkbox, Popup, Table } from 'semantic-ui-react';
import { UserRoleProperty } from '../../../backend_api/models/UserRoleProperty';
import { UserRolePropertyPermission } from '../../../backend_api/models/UserRolePropertyPermission';

export type AccessPropertyProps = {
    property: UserRoleProperty;
    toggleValue(propertieId: string, accessId: string, scopeId: string): void;
    isEditable: boolean;
}

const AccessPropertyView = (props: AccessPropertyProps): React.ReactElement => {
    return <Fragment>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        {props.property.name}
                    </Table.HeaderCell>
                    {props.property.scopes.map((scope, index: number) => {
                        return <Table.HeaderCell key={index} className="scope_cell">
                            <Popup trigger={
                                <div>{scope.name}</div>
                            }>
                                {scope.description}
                            </Popup>
                        </Table.HeaderCell>
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.property.permissions.map((permission: UserRolePropertyPermission, index: number) => {
                    return <Table.Row key={index}>
                        <Table.Cell>
                            <Popup trigger={
                                <div>{permission.name}</div>
                            }>
                                {permission.description}
                            </Popup>
                        </Table.Cell>
                        {props.property.scopes.map((scope, index: number) => {
                            const isFixed = permission.fixed.includes(scope.id);
                            return <Table.Cell key={index} className="scope_cell">
                                {permission.values[scope.id] != undefined &&
                                    <Checkbox disabled={!props.isEditable || isFixed} checked={permission.values[scope.id]} onClick={(): void => {
                                        if (props.isEditable && !isFixed) {
                                            props.toggleValue(props.property.id, permission.id, scope.id)
                                        }
                                    }} />}
                            </Table.Cell>
                        })}
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </Fragment>;
};

export default AccessPropertyView;