import React from 'react';
import DocumentTitle from 'react-document-title';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import TabsMenu from '../../../base/components/TabsMenu';
import { Breakpoints, DEFAULT_DATE_FORMAT } from '../../../base/config';
import { addToUrlParams, getLocationEntry, getMoment, useMaxWidthBreak } from '../../../base/utils';
import { DateFilter } from '../../filters/components';
import AddFilters from '../../filters/components/AddFilters';
import FiltersListNew from '../../filters/components/FiltersListNew';
import DashboardDefectsImages from './defects/DashboardDefectsImages';
import DefectsStatus from './defects/DefectsStatus';
import InspectionStatus from './inspections/InspectionStatus';

const DEFAULT_DASHBOARD_DATE_RANGE = getMoment().add(-3, 'month').startOf('month').format(DEFAULT_DATE_FORMAT) + '<';

const Dashboard = (): React.ReactElement => {
    const location = useLocation();
    const intl = useIntl();
    const isMaxMD = useMaxWidthBreak(Breakpoints.MD);
    return (
        <div className='pageContainer dashboard-new'>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.dashboard' })} />
            <div className='flex flex-row space-x-4 pb-8 items-center'>
                <div className='flex items-center space-x-4'><TabsMenu
                    active={location.pathname.split('/')[2]}
                    path='/dashboard'
                    items={[
                        { labelId: 'dashboard.tabs.inspection_status', linkPath: 'inspections', id: 'inspections' },
                        { labelId: 'dashboard.tabs.defects_status', linkPath: 'defects', id: 'defects' },
                    ]} />
                <DateFilter
                    showLabels={false}
                />
                </div>
                {!isMaxMD && <AddFilters />}
            </div>
            {isMaxMD && <div className='px-2 justify-center w-full flex'><AddFilters /></div>}
            <FiltersListNew
                noStatusAfterClear={true}
                filters={['inspector-filter', 'conclusion-filter', 'approver-filter', 'inspection-types-filter', 'custom-field-filter', 'supplier-filter']}
            />
            <Switch>
                <Route exact path='/dashboard' render={(): React.ReactElement => {
                    const date = getLocationEntry(location, 'date')[0];
                    const newUrl = addToUrlParams([{ key: 'date', value: date ? date : DEFAULT_DASHBOARD_DATE_RANGE }], location.search);
                    return <Redirect
                        to={{
                            pathname: '/dashboard/inspections',
                            search: '?' + newUrl,
                            push: false,
                        }}
                    />
                }} />
                <Route path='/dashboard/inspections' component={InspectionStatus} />
                <Route path='/dashboard/defects/browse/:defectTypeId' component={DashboardDefectsImages} />
                <Route path='/dashboard/defects' component={DefectsStatus} />
                
            </Switch>
        </div>
    );
};

export default Dashboard;
