import classnames from 'classnames';
import qs from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Icon } from 'semantic-ui-react';
import Loader from '../../../../../base/components/Loader';
import Button from '../../../../../base/components/basic/Button';
import { getLayoutSelector } from '../../../../../base/selectors';
import { AppState } from '../../../../../base/types';
import { addArrayItemOrRemoveIfExists, compareObjects, getLocationEntries } from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import { ListView } from '../../../../globals/components';
import { InfoIcon } from '../../../../globals/components/Utils';
import HasFeatureAccess from '../../../../globals/components/access/HasFeatureAccess';
import { ListViewHeader, ListViewHeaderItem, ListViewLines } from '../../../../globals/components/views/list/ListView';
import { clearErrorMessage } from '../../../../messages/actions';
import { getDoneOrOngoingInspections } from '../../../Utils';
import { setLastViewedInspectionPage } from '../../../actions';
import { ListType, OrderListOrderExtended, getFilteredListNew as getFilteredList, getInspectionsListDataSelector } from '../../../slices/inspectionsListSlice';
import InspectionItem from '../InspectionItem';
import Inspections from '../Inspections2';
import Order from '../Order';

type OwnProps = {
    showCombine?: boolean;
    selectCombineInspection?: any;
    inspectionsComponent?: any;
    listType?: ListType;
    params?: { search: string }; // An object containing parameters
    pushWhenToggle?: boolean; // When toggling an order, should it be reflected in the url params
    loadContent?: boolean; // Load lists content per default
}
type InspectionsDashboardProps = OwnProps;

const InspectionsList = (props: InspectionsDashboardProps): React.ReactElement => {
    const { showCombine = true, inspectionsComponent, pushWhenToggle = true, listType, loadContent = true } = props;
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const formatMessage = intl.formatMessage;
    const filters = getLocationEntries(location);
    const [params, setParams] = useState(getLocationEntries(location));
    const [openInspections, setOpenInspections] = useState(params.inspections || []);
    const inspectionsData = useSelector((state: AppState) => getInspectionsListDataSelector(state, listType));
    const hasData = inspectionsData.elements.length > 0;
    const isMobileMenuOpen = useSelector(getLayoutSelector).mobileMenuOpen;
    const dispatch = useAppDispatch();
    const isOnlyOrder = inspectionsData.elements.length === 1;
    const getOrder = (order: (OrderListOrderExtended), index: number): React.ReactElement => {
        return (
            <Order
                order={order}
                open={openInspections.includes(order.order_id) || isOnlyOrder}
                toggleInspection={(toggleInspection)}
                isOnlyOrder={isOnlyOrder}
                index={index}
            />);
    }
    const getInspectionLines = (order: OrderListOrderExtended): React.ReactElement => {
        if (isOnlyOrder || openInspections.includes(order.order_id)) {
            const reports = getDoneOrOngoingInspections(order.inspections, true);
            let reportOverviewUrl;
            if (reports.length > 0) {
                reportOverviewUrl = '/inspections/reports/' + reports[0].inspection_id + '?orderId=' + reports[0].order_id + '&overview=true';
            }
            const plannedUrl = '/inspections/edit_inspection/' + order.order_id;
            const bookingUrl = '/booking/' + order.order_id;
            return (<Fragment>
                <Inspections
                    order={order}
                    type={'dashboard'}
                    inspectionsComponent={inspectionsComponent || InspectionItem}
                    showCombine={showCombine}
                    hasInspectionTypes={true}
                    currentInspectionId={''}
                />
                <div className='border-0 flex justify-center p-2 py-3 md:p-2 md:bg-highlight-light-green border-b-4 border-b-brand'>
                    <Button behaveAsLink link={plannedUrl}><FormattedMessage id='inspections.edit' /></Button>
                    {reportOverviewUrl && <HasFeatureAccess feature='u_grouped_inspections'><Button behaveAsLink link={reportOverviewUrl}><FormattedMessage id='inspections.link_to_report_overview' /></Button></HasFeatureAccess>}
                    <HasFeatureAccess feature='u_show_booking_info'><Button behaveAsLink data-test-id='listview-open-booking-btn' link={bookingUrl}><FormattedMessage id='listview.view_booking' /></Button></HasFeatureAccess>
                </div>
            </Fragment>
            );
        }
        return null;
    };
    const toggleInspection = (key: string): void => {
        let oi = [...openInspections];
        oi = addArrayItemOrRemoveIfExists(oi, key);
        params.inspections = oi;
        if (oi.length > 0) {
            params.inspections = oi;
        } else {
            delete params.inspections;
        }
        const q = qs.stringify(params);
        setOpenInspections(oi);
        if (pushWhenToggle) {
            history.push('?' + q);
        }
    };

    const loadMore = (): void => {
        const prPage = 100;
        const start = inspectionsData.start + inspectionsData.per_page;
        if (props.params && props.params.search) {
            filters.search = [props.params.search];
        }
        dispatch(getFilteredList({ start, prPage, filters, listType }));
    };

    const getIsDone = (): boolean => {
        if (inspectionsData.start === 0 && inspectionsData.returned_for_page === 0) {
            return true;
        }
        return inspectionsData.returned_for_page < inspectionsData.per_page;
    };

    const currentCountLabel = (): React.ReactElement => {
        const total = inspectionsData.total;
        if (total > -1) {
            let done = false;
            let cnt = inspectionsData.start + inspectionsData.per_page;
            if (getIsDone()) {
                cnt = total;
                done = true;
            }
            const id = done ? 'inspections.listview_showing_thats_it' : 'inspections.listview_showing_total';
            return (<FormattedMessage
                id={id}
                values={{
                    cnt,
                    total,
                    ordersLabel: (<FormattedPlural
                        value={cnt}
                        one={formatMessage({ id: 'inspections.listview_order' })}
                        other={formatMessage({ id: 'inspections.listview_orders' })}
                    />),
                }}
            />);
        }
        return <FormattedMessage id='inspections.list_view.nothing_found' />

    };

    const paramsChanged = (): boolean => {
        const pc = { ...params };
        if (pc.inspections) {
            delete pc.inspections;
        }
        const pn = getLocationEntries(location);
        if (pn.inspections) {
            delete pn.inspections;
        }
        dispatch(setLastViewedInspectionPage('/inspections' + location.search));
        return compareObjects(pc, pn);
    }

    useEffect(() => {
        if (!paramsChanged()) {
            dispatch(getFilteredList({ start: 0, prPage: 20, filters, listType }));
        }
        setParams(getLocationEntries(location));
    }, [location.search]);
    useEffect(() => {
        if (loadContent) {
            dispatch(getFilteredList({ start: 0, prPage: 20, filters, listType }));
        }
        return () => {
            dispatch(clearErrorMessage());
        }
    }, []);

    function noErrorAndSomeData(): boolean {
        return !inspectionsData.error;
    }
    
    const headerItems: ListViewHeaderItem[] =
        [
            { label: undefined, className: 'w-1/16', key: 'spacer.1' },
            { label: formatMessage({ id: 'inspections.order_no' }), className: 'w-2/16', key: 'order_no' },
            { label: formatMessage({ id: 'inspections.item' }), className: 'w-4/16', key: 'item' },
            { label: formatMessage({ id: 'inspections.date' }), className: 'w-2/16', key: 'date' },
            { label: formatMessage({ id: 'inspections.supplier' }), className: 'w-3/16', key: 'supplier' },
            { label: formatMessage({ id: 'inspections.inspector' }), className: 'w-1/16', key: 'inspector' },
            { label: formatMessage({ id: 'inspections.status' }), className: 'w-1/16 md:hidden lg:flex', key: 'status' },
            { label: undefined, className: 'w-2/16', key: 'spacer.2' },
        ]


    return <Fragment>
        {!hasData && !inspectionsData.isFetching && <div className='flex justify-center items-center p-4'><InfoIcon compact text={formatMessage({id: 'inspections.no_inspections_found'})}/></div>}
        <ListView className=''>
            {
                noErrorAndSomeData() && hasData &&  
                <ListViewHeader
                    className={classnames('top-0', { 'sticky': !isMobileMenuOpen })}
                    items={headerItems} />
            }
            <Loader active={inspectionsData.isFetching}>

                
                {hasData && <ListViewLines className='bg-white border'>

                    {inspectionsData.elements && inspectionsData.elements.length > 0 && inspectionsData.elements.map((order: OrderListOrderExtended, i: number) => {
                        if (order) {
                            if (isOnlyOrder || openInspections.includes(order.order_id)) {
                                return (<div className={classnames({ 'border-l-0 md:border-l-4 border-l-brand': openInspections.includes(order.order_id) })}>{getOrder(order, i)}{getInspectionLines(order)}</div>);
                            }
                            return (<div className={classnames({ 'border-l-6 md:border-l-4 border-l-brand': openInspections.includes(order.order_id) })}>{getOrder(order, i)}</div>)
                        }
                        return null;
                    })}
                    {!inspectionsData.error && <div className='flex py-6 items-center justify-center'>{currentCountLabel()}</div>}
                    {!getIsDone() && <div className='flex mb-6 items-center justify-center'>
                        <Button primary onClick={(): void => loadMore()}>
                            {inspectionsData.isFetching && <Icon loading className='circle notch' />}
                            <FormattedMessage id='inspections.load_more' /></Button>
                    </div>}
                </ListViewLines>}
            </Loader>
        </ListView>
    </Fragment>;
}

export default InspectionsList;
