import { createSelector } from 'reselect/lib';
import { AppState } from '../../base/types';
import { CustomField, CustomTable } from './types';
import { InputCustomFieldGroups } from '../../backend_api_2';

const getCustomFieldsSelector = (state: AppState): CustomField[] => state.app.customFields.data;
const getCustomTablesSelector = (state: AppState): CustomTable[] => state.app.customFields.customTables;
const getProductionUnitCustomFieldsSelector = (state: AppState): InputCustomFieldGroups => state.app.customFields.productionUnitCustomFields;
const getSupplierCustomFieldsSelector = (state: AppState): InputCustomFieldGroups => {
    return state.app.customFields.supplierCustomFields
};

export const getSupplierCustomFieldGroups = createSelector(
    [getSupplierCustomFieldsSelector],
    (supplierCustomFieldGroups) => supplierCustomFieldGroups
)

export const getProductionUnitCustomFieldGroups = createSelector(
    [getProductionUnitCustomFieldsSelector],
    (InputCustomFieldGroups) => InputCustomFieldGroups
)

export const getCustomFields = createSelector(
    [getCustomFieldsSelector],
    (customFields) => customFields,
);

export const getCustomBarcodeTables = createSelector(
    [getCustomTablesSelector],
    (customTables) => customTables.filter((table) => table.custom_table_format === 'barcode_table'),
);

export const getCustomMeasurementTables = createSelector(
    [getCustomTablesSelector],
    (customTables) => customTables.filter((table) => table.custom_table_format === 'measurement_tables'),
);
