import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Form, Input } from 'semantic-ui-react';
import BaseComponent from '../../../base/components/BaseComponent';

interface SearchFilterProps {
    query: string;
    doSearch(item: { name: string; value: string });
}

const initialState = { query: '' }
type State = Readonly<typeof initialState>;
const intlMessages = defineMessages({
    selectUsers: {
        id: 'users.filters.select_users_placeholder',
    },
});

class SearchFilter extends BaseComponent<SearchFilterProps & WrappedComponentProps, State> {
    readonly state: State = initialState;
    constructor(props) {
        super(props);
    }

    public doRender(): React.ReactElement {
        const placeholderLabel = this.props.intl.formatMessage(intlMessages.selectUsers);
        return (
            <div className='filter approver'>
                <div className='label'><FormattedMessage id='users.filters.search_users' /></div>
                <div >
                    <Form onSubmit={this.doSearch} className='searchFilterForm' data-test-id='users-search-user'>
                        <Input
                            type='text'
                            name='userSearch'
                            placeholder={placeholderLabel}
                            data-test-id='input-search-filter'
                            onChange={this.handleChange}
                            value={this.state.query}
                            action={<Button
                                tabIndex='-1'
                                icon='search'
                                className='doSearch'
                            />}
                        />
                    </Form>
                </div>
            </div>
        );
    }

    public componentDidMount(): void {
        this.setState({ query: this.props.query });
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.query !== this.props.query) {
            this.setState({ query: this.props.query });
        }
    }

    private handleChange = (e, { value }): void => {
        this.setState({ query: value });

    }
    private doSearch = (e): void => {
        e.preventDefault();
        this.props.doSearch({ name: 'search', value: this.state.query });
    }
}

export default injectIntl(SearchFilter);