import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popup } from "semantic-ui-react";
import { Audit } from "../../../backend_api/models";
import { useAppDispatch } from "../../../store";
import { InspectorSelector } from "../../users/components";
import { updateAuditAuditor } from "../slices/auditSlice";

type ReassignAuditProps = {
    audit: Audit;
    children?: React.ReactElement;
};

const ReassignAuditPopup = (props: ReassignAuditProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { children, audit } = props;
    const [show, setShow] = useState(false);
    const [reassignInspector, setReassignInspector] = useState(null);

    const cancel = (): void => {
        setReassignInspector(null);
        setShow(false);
    };

    const reassign = (reassignToUserId: string): void => {
        dispatch(
            updateAuditAuditor({
                audit_id: audit.id,
                user_id: reassignToUserId,
            })
        );
    };

    return (
        <Popup
            on="click"
            open={show}
            onOpen={(): void => setShow(true)}
            onClose={(): void => setShow(false)}
            trigger={children}
            hoverable={true}
            position="top center"
            hideOnScroll={false}
            closeOnPortalMouseLeave={false}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={true}
        >
            <Popup.Header>
                <h3>
                    {intl.formatMessage({
                        id: "audits.unlock.audit_inprogress",
                    })}
                </h3>
            </Popup.Header>
            <Popup.Content>
                <div className="mb-4">
                    <FormattedMessage id="audits.unlock.reassign_audit" />
                </div>
                <Fragment>
                    <div className="mb-4">
                        <div className="text-secondary text-sm mb-1">
                            <FormattedMessage id="audits.reassign.label_current_inspector" />
                        </div>
                        <InspectorSelector
                            selectedInspector={
                                reassignInspector ||
                                (audit.assigned_user && audit.assigned_user.id)
                            }
                            id={audit.id}
                            handleChange={(item): void => {
                                setReassignInspector(item.value);
                            }}
                            showEmailInSelector={false}
                            showExpanded={false}
                        />
                    </div>
                    <div className="flex flex-row justify-between">
                        <Button
                            size="mini"
                            disabled={!reassignInspector}
                            primary
                            onClick={(): void => reassign(reassignInspector)}
                        >
                            <FormattedMessage id="audits.reassign.label_reassign" />
                        </Button>
                        <Button size="mini" onClick={(): void => cancel()}>
                            <FormattedMessage id="globals.cancel" />
                        </Button>
                    </div>
                </Fragment>
            </Popup.Content>
        </Popup>
    );
};
export default ReassignAuditPopup;
