import { Attachment, ChecklistType, MultipleChoice, ReportCheckpointHeader, ReportDefect, VideoResource } from '../../backend_api/models';
import { MultiLingualText } from '../../backend_api/models/MultiLingualText';
import { byId } from '../../base/types';
import { CustomField } from '../customFields/types';
import { MultiLanguageText } from '../globals/types';
import { AttachmentResponse } from '../Images/actions/actions';

export enum CheckpointType {
    Default = 'default',
    MeasurementTables = 'measurement_tables',
    BarcodeTable = 'barcode_table',
    MultipleChoice = 'multiple_choice',
    Text = 'text_inputs',
    TextTable = 'text_table',
    ProductionUnitCustomFields = 'production_unit_custom_fields',
    SupplierCustomFields = 'supplier_custom_fields'
}

export type Checklist = {
    updated_by?: {
        lastname: string;
        id: string;
        firstname: string;
        email: string;
    };
    updated_at?: string;
    id?: string;
    value?: string;
    description?: string;
    text?: string;
    languages?: string[];
    checkpoints?: [Checkpoint];
    allowed_to_edit?: boolean;
    type?: ChecklistType;
    features: string[];

};

export type ChecklistData = {
    description: string;
    headers: CheckpointHeaderData[];
    type: ChecklistType;
    revision: number;
    languages?: string[];
}

export interface ProductionUnitCustomFieldsReference {
    custom_field_definition_id: string;
    name: any
}
export interface ProductionUnitCustomFields {
    custom_fields: ProductionUnitCustomFieldsReference[]
}
export interface CheckpointData {
    id: string;
    measurement_tables: MeasurementTablesData;
    barcode_table: BarcodeTableData;
    multiple_choice: MultipleChoiceData;
    instruction_files: AttachmentResponse[];

    description: MultiLanguageText;
    instruction_photos: string[];
    instruction_details: MultiLanguageText;
    checkpoint_type: CheckpointType;
    custom_fields: string[];
    production_unit_custom_fields: ProductionUnitCustomFields;
    supplier_custom_fields: ProductionUnitCustomFields;
    mandatory: boolean;
    is_picture_mandatory: boolean;
    comments_enabled: boolean;
    pictures_enabled: boolean;
    maximum_score: number;
    status_enabled: boolean;
    has_spacer: boolean;
    text_inputs: TextData;
    text_table: TextTable;
}

export type Checkpoint = {
    instruction_photos?: CheckpointPhoto[];
    instruction_details?: MultiLingualText | null;
    instruction_files?: Array<Attachment>;
    multiple_choice: MultipleChoiceData;
    measurement_tables?: MeasurementTablesData[];
    barcode_table?: BarcodeTableData;
    checkpoint_type?: CheckpointType;
    observed_value?: string;
    observed_photos?: CheckpointPhoto[];
    checkpoint_header_id?: string;
    status?: number | string;
    instruction_text?: {
        [language: string]: string;
    };
    inspection_id?: string;
    id?: string;
    expected_value_type?: string;
    expected_value_definition?: string;
    expected_value?: string;
    comment2?: string;
    translated_comment2?: {
        [language: string]: string;
    };
    comment?: string;
    comment_language?: string;
    sub_checkpoints?: Checkpoint[];
    isEmpty?: boolean;
    defects?: ReportDefect[];
    expected_format: string;
    name: { [language: string]: string };
    status_enabled?: boolean;
    text_inputs: TextData;
    text_table: TextTable;
};

export type TextTable = {
    rows: { name: MultiLanguageText }[];
    columns: { name: MultiLanguageText }[];
    values: TextTableValue[];
    version: number;
    use_custom_table: boolean;
    custom_table_definition_id: string;
}

export type TextTableValue = {
    r: number;
    c: number;
    value: string;
}

export type TextData = {
    inputs: TextInput[];
    version: number;
    use_custom_table: boolean;
    custom_table_definition_id: string;
}

export type TextInput = {
    name: MultiLanguageText;
    value: string;
}

export type Content = {
    checkpoints: Checkpoint[];
    deleteCheckpointFailed: false;
    deleteCheckpointSuccess: false;
};

export type CheckpointHeaderData = {
    checkpoints: CheckpointData[];
    sequence: number;
    id: string;
    title: MultiLanguageText;
    score: Score;
};

export type CheckpointPhoto = {
    url?: string;
    translated_comment?: MultiLanguageText;
    language?: string;
    comment?: string;
    video_resources?: VideoResource[];
};

export type CheckpointRemark = {
    imageComments: CheckpointPhoto[];
    barcode: CheckpointBarcode;
    commentObj: CheckpointComment;
    comment: string;
    comment2: string;
    description: MultiLingualText[];
    id: string;
    hasRemark: boolean;
    isAlert: boolean;
    defects: ReportDefect[];
    status: number | string;
    multiple_choice: MultipleChoice;
    status_enabled: boolean;
    has_spacer: boolean;
};
export type CheckpointWithRemark = { id: string, remarkTypes: string[] };
export type CheckpointRemarks = byId<{ hasHeaders: boolean, checkpointsWithRemarks: CheckpointWithRemark, checkpointHeader: ReportCheckpointHeader, remarks: CheckpointRemark[] }>;
export type CheckpointBarcode = {
    observed_value: string;
    expected_value: string;
};

export type CheckpointComment = {
    comment?: string;
    comment2?: string;
    comment_language: string;
    translated_comment2?: { [language: string]: string };
    status?: string | number;
};

export type CheckpointMeasurementTable = unknown;

export enum CheckpointStatusEnum {
    CHECKED_OK = '1',
    CHECKED_NOT_OK = '2',
    NOT_APPLICABLE = '3',
    PENDING = '4',
    NOT_SET = '-1',
}

export interface MeasurementTablesData {
    tables: MeasurementTable[];
    custom_table_definition_id: string;
    use_custom_table: boolean;
    version: CheckpointTypeVersionNumber;
}

export interface MeasurementTable {
    name: MultiLanguageText;
    specifications: MeasurementSpecification[];
    samples: Sample[];
}

export type Sample = DataPoint[]

export interface DataPoint {
    value: number;
}

export interface MeasurementSpecification {
    name: MultiLanguageText;
    unit: string;
    target: CustomString;
    min: number;
    max: number;
    deviation: number;
}

export interface BarcodeTableData {
    name: MultiLanguageText;
    barcodes: BarcodeSample[];
    use_custom_table: boolean;
    custom_table_definition_id: string;
    version: CheckpointTypeVersionNumber;
}

export type BarcodeSample = {
    name: MultiLanguageText;
    scanned_barcode: string;
    scanned_format: string;
    expected_barcode: CustomString;
    expected_format: CustomString;
    added_by_inspector: boolean;
}

export enum BarcodeFormat {
    QR_CODE = 'QR_CODE',
    DATA_MATRIX = 'DATA_MATRIX',
    UPC_E = 'UPC_E',
    UPC_A = 'UPC_A',
    EAN_8 = 'EAN_8',
    EAN_13 = 'EAN_13',
    CODE_128 = 'CODE_128',
    CODE_39 = 'CODE_39',
    ITF = 'ITF'
}

/**
 * CheckpointTypeVersionNumber should always be an integer, larger than 0
 * */
export type CheckpointTypeVersionNumber = number

export type MultipleChoiceData = {
    options: MultipleChoiceOption[];
    choices: 'any' | 'one';
    version: CheckpointTypeVersionNumber;
}

export type MultipleChoiceOption = {
    name: MultiLanguageText;
    description: MultiLanguageText;
    score: number;
    failed: boolean;
    color: string;
    chosen: boolean;
    editor_reference_id?: string;
}

export type CustomString = {
    value: string;
    custom_field_definition_id: string;
    use_custom_field: boolean;
}

export type Score = {
    score: number;
    max_score: number;
    percentage: number;
}
