import { getStringsSeparatedBy, isUndefinedOrNullOrEmptyString } from '../../base/utils';

export const getSupplierNameAndNumber = (name: string, number: string, sep?: string) => {
    const arr = []
    if (!isUndefinedOrNullOrEmptyString(name)) {
        arr.push(name);
    }
    if (!isUndefinedOrNullOrEmptyString(number)) {
        arr.push(number);
    }
    return arr.length > 0 ? getStringsSeparatedBy(arr.filter((n) => n !== null), sep || ' -') : '-';
}