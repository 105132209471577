import { UploadRequest } from '@navjobs/upload';
import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { v1 as uuidv1 } from 'uuid';
import base from '../../../base';
import { request } from '../../../base/api';
import { RequestError } from '../../../base/types';
import errorHandling from '../../errorHandling';
import * as types from '../constants';
import { Attachment } from '../types';
const SERVICES_PREFIX = base.config.SERVICES_PREFIX;
const catchException = errorHandling.handler.catchException;

const getAttachmentsRequest =
    createAction(types.GET_ATTACHMENTS_REQUEST, (isFetching: boolean, attachToItemId: string, attachToType: string) => {
        return {
            type: types.GET_ATTACHMENTS_REQUEST, payload: {
                isFetching,
                attachToItemId,
                attachToType,
            },
        };
    });

const getAttachmentsSuccess =
    createAction('attachments/GET_ATTACHMENTS_SUCCESS', (isFetching: boolean, attachToItemId: string, attachToType: string, data: Attachment[]) => {
        return {
            type: 'attachments/GET_ATTACHMENTS_SUCCESS', payload: {
                isFetching,
                attachToItemId,
                attachToType,
                attachments: data,
            },
        };
    });

const getAttachmentsFailure =
    createAction(types.GET_ATTACHMENTS_FAILURE, (isFetching: boolean, attachToItemId: string, attachToType: string, status: number, message: string) => {
        return {
            type: types.GET_ATTACHMENTS_FAILURE, payload: {
                isFetching,
                attachToItemId,
                attachToType,
                status,
                message,
            },
        };
    });

// Get a list of an items's attachments
export const getAttachments = (attachToItemId: string, attachToType: string, includeOrder?: boolean) => {
    return (dispatch: Dispatch): Promise<void> => {
        let url = attachToType + '/' + attachToItemId + '/attachments';
        if (includeOrder) {
            url += '?include_order=true';
        }
        dispatch(getAttachmentsRequest(false, attachToItemId, attachToType));
        return request(url, {})
            .then((data: Attachment[]) => {
                dispatch(getAttachmentsSuccess(false, attachToItemId, attachToType, data));
            })
            .catch((error: RequestError) => {
                catchException('get' + attachToType + 'Attachments', {
                    endpoint: attachToType + '/[itemId]/attachments',
                    request: url,
                    status: error.status,
                }, { error, attachToItemId });

                dispatch(getAttachmentsFailure(false, attachToItemId, attachToType, error.status, error.errorText));
            });
    };
};

export const addAttachmentRequest =
    createAction(types.ADD_ATTACHMENT_REQUEST, (isFetching: boolean, attachToItemId: string, attachToType: string) => {
        return {
            type: types.ADD_ATTACHMENT_REQUEST, payload: {
                isFetching,
                attachToItemId,
                attachToType,
            }
        };
    });
export const addAttachmentSuccess =
    createAction(types.ADD_ATTACHMENT_SUCCESS, (isFetching: boolean, attachToItemId: string, attachToType: string, file: File) => {
        return {
            type: types.ADD_ATTACHMENT_SUCCESS, payload: {
                isFetching,
                attachToItemId,
                attachToType,
                file,
            },
        };
    });

const addAttachmentFailure =
    createAction(types.ADD_ATTACHMENT_FAILURE, (isFetching: boolean, attachToItemId: string, attachToType: string, uuid: string, status: number, message: string) => {
        return {
            type: types.ADD_ATTACHMENT_FAILURE, payload: {
                isFetching,
                attachToItemId,
                attachToType,
                uuid,
                status,
                message,
            },
        };
    });

// Add an attachment to an item
export const addAttachment = (attachToItemId: string, attachToType: string, file: File) => {
    return (dispatch: Dispatch<any>): void => {
        const url = attachToType + '/' + attachToItemId + '/attachments/' + encodeURIComponent(file.name);
        const uuid = uuidv1();
        const token = sessionStorage.getItem('id_token') || sessionStorage.getItem('url_token');
        dispatch(addAttachmentRequest(false, attachToItemId, attachToType));
        dispatch(setAttachmentUploadProgress(attachToItemId, attachToType, file, uuid, 0, 'ok'));
        UploadRequest(
            {
                request: {
                    url: SERVICES_PREFIX + '/' + url,
                    headers: {
                        'Content-Type': file.type,
                        'Authorization': 'Bearer ' + token,
                    },
                },
                files: [file],
                progress: (progress) => {
                    dispatch(setAttachmentUploadProgress(attachToItemId, attachToType, file, uuid, progress, 'ok'));
                },
                completed: () => {
                    console.log('upload completed');
                },
                abort: () => {
                    console.log('upload abort!');
                },
                aborted: () => {
                    console.log('upload aborted!');
                },
                instance: () => null,

            },
        ).then((resp) => {
            if (resp.error) {
                const error = resp.error;
                catchException(attachToType + 'AddAttachment', {
                    endpoint: attachToType + '/[itemId]/attachments/[file.name]',
                    request: url,
                    status: error.status,
                }, { error, attachToItemId, file, attachToType });
                dispatch(addAttachmentFailure(false, attachToItemId, attachToType, uuid, error.status, error.message));
            } else {
                dispatch(getAttachments(attachToItemId, attachToType));
            }
        }).catch((error: RequestError) => {
            dispatch(addAttachmentFailure(false, attachToItemId, attachToType, uuid, error.status, error.errorText));
        });
    };
};

const removeAttachmentRequest =
    createAction(types.REMOVE_ATTACHMENT_REQUEST, (isFetching: boolean, removeFromItemId: string, removeFromType: string, url: string) => {
        return {
            type: types.REMOVE_ATTACHMENT_REQUEST, payload: {
                isFetching,
                removeFromItemId,
                removeFromType,
                url,
            },
        };
    });
const removeAttachmentSuccess =
    createAction(types.REMOVE_ATTACHMENT_SUCCESS, (isFetching: boolean, removeFromItemId: string, removeFromType: string) => {
        return {
            type: types.REMOVE_ATTACHMENT_SUCCESS, payload: {
                isFetching,
                removeFromItemId,
                removeFromType,
            },
        };
    });

const removeAttachmentFailure =
    createAction(types.REMOVE_ATTACHMENT_FAILURE, (isFetching: boolean, removeFromItemId: string, removeFromType: string, status: number, message: string) => {
        return {
            type: types.REMOVE_ATTACHMENT_FAILURE, payload: {
                isFetching,
                removeFromItemId,
                removeFromType,
                status,
                message,
            },
        };
    });

// Remove an item's attachments
export const removeAttachment = (removeFromItemId: string, removeFromType: string, attachmentURL: string) => {
    return (dispatch): Promise<void> => {
        const url = removeFromType + '/' + removeFromItemId + '/attachments';
        dispatch(removeAttachmentRequest(false, removeFromItemId, removeFromType, attachmentURL));
        return request(url, {
            method: 'DELETE',
            body: JSON.stringify({ url: attachmentURL }),
        })
            .then(() => {
                dispatch(removeAttachmentSuccess(false, removeFromItemId, removeFromType));
                dispatch(getAttachments(removeFromItemId, removeFromType));
            })
            .catch((error) => {
                catchException('remove' + removeFromType + 'Attachment', {
                    endpoint: removeFromType + '/[itemId]/attachments?url=[attachmentURL]',
                    request: url,
                    status: error.status,
                }, {
                    error, removeFromItemId, body: {
                        url: JSON.stringify(attachmentURL),
                    }, method: 'DELETE',
                });
                dispatch(removeAttachmentFailure(false, removeFromItemId, removeFromType, error.status, error.message));
            });
    };
};

// Set attachment upload progress
export const setAttachmentUploadProgress = createAction(types.ADD_ATTACHMENT_PROGRESS, (attachToItemId: string, attachToType: string, file: File, uuid: string, progress: number, status: string) => {
    return {
        type: types.ADD_ATTACHMENT_PROGRESS,
        payload: {
            attachToItemId,
            attachToType,
            file,
            uuid,
            progress,
            status,
        },
    };
});

export const clearUploads = createAction(types.CLEAR_UPLOADED_ATTACHMENTS, (attachToItemId: string, attachToType: string, clearPrevious?: boolean) => {
    return {
        type: types.CLEAR_UPLOADED_ATTACHMENTS,
        payload: {
            attachToType,
            attachToItemId,
            clearPrevious,
        },
    };
});
