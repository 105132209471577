import React from 'react';
import { ComponentOrStringType } from '../../../types';
import { isUndefinedOrNullOrEmptyString } from '../../../utils';


type Props = {
    className?: string;
    components: ComponentOrStringType[];
    separateBy: string;
    endWithLabel?: string
};

const ComponentsSeparatedBy = (props: Props): any => {
    const { className, components, separateBy, endWithLabel } = props;
    const hasEndWithLabel = !isUndefinedOrNullOrEmptyString(endWithLabel);
    const len = components.length;
    const c = components.map((component, i) => {
        const isLast = (len - 1) === i;
        const is2ndLast = (len - 2) === i;
        return <span className='flex'>
            <span className='flex gap-x-1'>{component}</span>
            {is2ndLast ?
                (hasEndWithLabel ? <span className='px-1'>{endWithLabel}</span> : <span className='pr-1'>{separateBy}</span>):
                !isLast && <span className='pr-1'>{separateBy}</span>
            }
        </span>
    })
    return <div className='flex flex-wrap'>{c}</div>;
}
export default ComponentsSeparatedBy;
