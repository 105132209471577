import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Header, List, Popup } from 'semantic-ui-react';
import { ReportDefect } from '../../../../backend_api/models';
import Link from '../../../../base/components/basic/Link';
import { isUndefinedOrNullOrEmptyString, useIsMobileDevice } from '../../../../base/utils';
import Comments from '../../../comments/components/Comments';
import WriteComment from '../../../comments/components/WriteComment';
import CorrectiveActionStatus from '../../../correctiveActions/components/CorrectiveActionStatus';
import { TranslateText } from '../../../globals/components';
import { SeverityLabel, SeverityToggler } from '../../../globals/components/Severity';
import { Space } from '../../../globals/components/Utils';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { setDefectSeverity } from '../../slices/inspectionReportSlice';
import { DefectType, Inspection } from '../../types';
import ItemsAffected from './ItemsAffected';
import CheckpointImage from './report2/CheckpointImage';

type DefectsItemProps = {
    defect: ReportDefect;
    inspection: Inspection;
    showTranslatedComments: boolean;
};

const DefectsItem = (props: DefectsItemProps): React.ReactElement => {
    const isMobile = useIsMobileDevice();
    const intl = useIntl();
    const { defect, inspection } = props;
    const severity = defect.severity;
    const id = 'defect_' + defect.id;
    const inspectionId = inspection && inspection.inspection_id || 'fooId';
    const title: string = intl.formatMessage({ id: 'report.add_comment_on' });
    const trigger = defect.type ? <div className='defectLabel header'>{getDefectType(defect.type)}</div> : null;
    const itemsAffected = <ItemsAffected pcs={defect.quantity_affected} all={defect.whole_lot} />;
    const severityToggler = (inspection && inspection.status !== 'report') ?
        <SeverityLabel severity={severity} />
        :
        <SeverityToggler
            currentSeverity={severity}
            setDefectSeverity={setDefectSeverity}
            defect={defect}
            inspectionId={inspectionId}
            scrollToTop={false}
        />;
    const writeComment = <WriteComment
        showModal={true}
        id={defect.id}
        type='defect'
        title={title}
        autoFocus
    />;
    return <div className='defect-item'>
        <a id={id}></a>
        <List.Item>
            <List.Content className='description'>
                {!isMobile && <Grid>
                    <Grid.Column width={9}>
                        <h3>{defect.type && getDefectPoup(defect, trigger)}<Space />{getDefectDescription(defect, true, props.showTranslatedComments)}</h3>
                        <List.Content className='affected'>
                            {itemsAffected}
                        </List.Content>
                    </Grid.Column>
                    <Grid.Column width='5' textAlign='right'>
                        <div className='flex justify-end items-center'><HasFeatureAccess type='inspection' feature='create_comments' inspection={props.inspection}>
                            <WriteComment
                                showModal={true}
                                id={defect.id}
                                type='defect'
                                title={title}
                                autoFocus
                            />
                        </HasFeatureAccess>
                            {severityToggler}
                        </div>
                    </Grid.Column>
                </Grid>}
                {isMobile && <Grid>
                    <Grid.Column width={16}>
                        <h3 className='space-y-1'>
                            <div>{defect.type && getDefectPoup(defect, trigger)}</div>
                            <div>{getDefectDescription(defect, true, props.showTranslatedComments)}</div>
                        </h3>

                        <List.Content className='affected'>
                            {itemsAffected}
                        </List.Content>
                    </Grid.Column>
                    <Grid.Column width='16'>
                        <div className='flex items-center'>
                            {severityToggler}
                            {writeComment}
                        </div>
                    </Grid.Column>
                </Grid>}
            </List.Content>
        </List.Item>
        <List.Item>
            <div className='flex flex-col'>
                {defect.corrective_actions.map((ca) => {
                    return <Link to={'/ca/details/' + ca.id} key={'defect_' + defect.id + ca.id}><div className='py-2 w-full flex space-x-1 items-center'><CorrectiveActionStatus state={ca.state} /><div>{ca.finding.text}</div></div></Link>
                })}
            </div>

            <div className='photo-grid'>
                {defect.photos.map((imageURL) => {
                    return <CheckpointImage imageURL={imageURL} inspection={props.inspection} typeId={defect.id} type='defect_image' key={'defectImage_' + imageURL} />
                })}
            </div>
        </List.Item>
        <List.Item className='comments'>
            <div className='defect-comments'>
                <Comments type='defect' typeId={defect.id} showCollapsed={false} hideZero={true} inspection={inspection} />
            </div>
        </List.Item>
    </div>;
};


const getDefectPoup = (defect: ReportDefect, trigger: React.ReactElement | string): React.ReactElement => {
    return (
        <Popup
            className='defectTypePopup'
            trigger={<span>{trigger}</span>}>
            <Popup.Content>
                <Header as='h5'><FormattedMessage id='inspections.defect_type' /></Header>
                <div>{defect.type.name}</div>
                <div>{defect.type.code}</div>
                <div>{defect.type.description}</div>
            </Popup.Content>
        </Popup>
    );
};

export const getDefectDescription = (defect: ReportDefect, showToggleActions = true, showTranslatedComments = false): React.ReactElement => {
    return !isUndefinedOrNullOrEmptyString(defect.description) && <TranslateText
        showTranslatedComments={showTranslatedComments}
        translateObj={defect.translated_description}
        originalLanguage={defect.language}
        originalText={defect.description}
        showToggleActions={showToggleActions}
    />;
};

export const getDefectType = (type: DefectType): React.ReactElement => {
    const hasDefectTypeCode = type && type.code && type.code !== '';
    const hasDefectTypeName = type && type.name && type.name !== '';
    return <Fragment>{hasDefectTypeCode && `${type.code}. `}{hasDefectTypeName && `${type.name} `}</Fragment>;
};

export default DefectsItem;
