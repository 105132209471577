import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { ReportDefect } from '../../../backend_api/models';
import { setLayoutAttribute } from '../../../base/baseSlice';
import Label from '../../../base/components/basic/Label';
import { AppState } from '../../../base/types';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { setDefectSeverity } from '../../inspections/slices/inspectionReportSlice';
import { DefectSeverities } from '../../inspections/types';
import { isSettingDefectSeveritySelector } from '../selectors';

const ReportSeverity = (): void => {
    return null;
};

type SeverityTogglerProps = {
    currentSeverity: DefectSeverities;
    setDefectSeverity: typeof setDefectSeverity;
    defect: ReportDefect;
    inspectionId: string;
    scrollToTop?: boolean;
};

const SeverityToggler = (props: SeverityTogglerProps): React.ReactElement => {
    const [show, setState] = useState(false);
    const dispatch = useAppDispatch();
    const { settingDefectSeverity } = useSelector((state: AppState) => ({
        settingDefectSeverity: isSettingDefectSeveritySelector(state)
    }));
    const { currentSeverity, defect, inspectionId, scrollToTop = false } = props;
    const severity = currentSeverity;
    const severities = ['minor', 'major', 'critical'];
    return (<Popup
        trigger={<span><SeverityLabel disabled={settingDefectSeverity} severity={severity} /></span>}
        on='click'
        onOpen={(): void => {
            setState(true);
        }}
        onClose={(): void => setState(false)}
        open={show}
        hoverable={true}
        hideOnScroll >
        <Popup.Content>

            <h4><FormattedMessage id='inspections.defect_severity' /></h4>
            <p><FormattedMessage id='inspections.set_a_new_severity_for_the_defect' /></p>
            <Fragment>
                {severities.map((severityString) => {
                    const disabled = severity === severityString;
                    return (
                        disabled ? <SeverityLabel disabled={disabled} severity={severityString} />
                            :
                            <span className='cursor-pointer' onClick={(): void => {
                                dispatch(setDefectSeverity({ inspectionId, severity: severityString, defectId: defect.id }));
                                !scrollToTop && dispatch(setLayoutAttribute('dontScrollToTopAfterAction', true));
                                setState(false);
                            }}>
                                <SeverityLabel severity={severityString} />
                            </span>
                    );
                })}
            </Fragment>

        </Popup.Content>
    </Popup>);
};

type SeverityLabelProps = {
    severity: string;
    disabled?: boolean;
    className?: string;
};

const SeverityLabel = (props: SeverityLabelProps) => {
    const intl = useIntl();
    const severityLabel = intl.formatMessage({ id: 'defects.' + props.severity });
    return <Label className={twMerge('py-2 w-32 justify-center flex border-0 severity no-underline', props.severity, props.disabled ? 'text-disabled' : null, props.className)}>{severityLabel}</Label>;
};

export { ReportSeverity, SeverityLabel, SeverityToggler };

