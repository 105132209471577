import { Pie } from '@nivo/pie';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Header, Segment } from 'semantic-ui-react';
import { capitaliseWord } from '../../../../base/utils';
import config from '../../config';

type ConclusionPiesProps = {
    data: any;
};

const ConclusionPies = (props: ConclusionPiesProps): React.ReactElement => {
    const intl = useIntl();
    const getColors = (item): string => {
        return config.conclusionColors[item.data.category];
    }
    return (
        <Segment.Group raised container>
            <Segment secondary>
                <Header as='h3'><FormattedMessage id='dashboard.pie_header.inspections_by_conclusion' /></Header>
            </Segment>
            <Segment>
                <div className='pies'>
                    {props.data && props.data.map((pieData: any, index: number) => {
                        let cnt = 0;
                        pieData.values.map((val) => {
                            cnt += val.value;
                            val.label = capitaliseWord(val.label);
                        });
                        return <div key={index} className='pie flex-col mb-24'>
                            <div className='flex justify-center'>
                                <div className='typeLabel'>{pieData.label === '' ? intl.formatMessage({ id: 'dashboard.inspection_type.no_type' }) : pieData.label} ({cnt})</div>
                            </div>
                            <div className='flex justify-center'>
                                <Pie
                                    width={config.pie.width}
                                    height={config.pie.width}
                                    colors={getColors}
                                    data={pieData.values}
                                    arcLinkLabelsSkipAngle={20}
                                    arcLabelsSkipAngle={20}
                                    enableArcLinkLabels={false}
                                    borderWidth={1}
                                    borderColor={config.pie.textColor}
                                    arcLabelsTextColor={config.pie.textColor}
                                    tooltip={({ datum: { id, value, color } }) => {
                                        let total = 0;
                                        pieData.values.forEach((item) => {
                                            total += item.value;
                                        });
                                        return <div className='px-2 py-1 bg-white shadow-xs flex space-x-2 items-center'>
                                            <div className='w-4 h-4' style={{ background: color }}></div>
                                            <div className='flex space-x-1'><span>{capitaliseWord(id as string)}:</span><span className='font-semibold'>{value}</span><span>({(value / total * 100).toFixed(2)}%)</span></div>
                                        </div>
                                    }}
                                    arcLabel={(datum): string => {
                                        let total = 0;
                                        pieData.values.forEach((item) => {
                                            total += item.value;
                                        });
                                        let ret = capitaliseWord(datum.label as string) + '. ';
                                        ret += total > 0 ? (datum.value / total * 100).toFixed(2) + '%' : datum.value;
                                        return ret;
                                    }}
                                />
                            </div>
                        </div>;
                    })}
                </div>
            </Segment>
        </Segment.Group>
    );
};

export default ConclusionPies;
