import React from 'react';
import Icon from '../../../../../base/components/basic/Icon';
import Label from '../../../../../base/components/basic/Label';
import { twMerge } from '../../../../../base/utils';

type Props =  {
   className?: string;
};

const BookingCompletedLabel = (props: Props): React.ReactElement => {
   const { className } = props;
   return <Label className={twMerge('text-xs border-0 text-white', props.className)}><span className='text-xs items-center flex space-x-1 '><Icon text-red name='check' className='text-base font-black leading-none'/><span>Completed</span></span></Label>;
}
export default BookingCompletedLabel;
