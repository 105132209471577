import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Confirm, Dropdown, Popup } from 'semantic-ui-react';
import { OptionsType } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { InfoIcon } from '../../../globals/components/Utils';
import { SamplingProtocol, doSamplingProtolsDifferSelector, getCurrentSamplingProtocolSelector, getSamplingProtocolsSelector, setSamplingProtocolOnOrder } from '../../../samplingProtocols/slices/samplingProtocolsSlice';
import { getOrderWithInspectionsSelector } from '../../editInspectionSelectors';

type SamplingProtocolSelectorProps = {
    disabled?: boolean;
};

function samplingProtocolsToOptions(samplingProtocols: SamplingProtocol[], query?: string): OptionsType[] {

    if (!query || query === '') {
        return samplingProtocols.map(samplingProtocolToOption);
    }
    const lowerCaseQuery = query.toLocaleLowerCase()

    return samplingProtocols
        .filter(p => {
            if (!p.name) { return false }
            return p.name.toLocaleLowerCase().includes(lowerCaseQuery);
        })
        .map(samplingProtocolToOption)

}

function samplingProtocolToOption(protocol: SamplingProtocol) {
    return { key: protocol.id, value: protocol.id, text: protocol.name }
}

const SamplingProtocolSelector = (props: SamplingProtocolSelectorProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const samplingProtocols = useSelector(getSamplingProtocolsSelector);
    const currentProtocol = useSelector(getCurrentSamplingProtocolSelector);
    const spDiffer = useSelector(doSamplingProtolsDifferSelector);
    const order = useSelector(getOrderWithInspectionsSelector);
    const [selectedProtocolId, setCurrentProtocolId] = useState<string | null>(null);
    const [confirm, setConfirm] = useState(false);
    const warning = formatMessage({ id: 'inspections.samling_protocols_will_be_changed_and_sample_quantities_will_be_recalculated' });

    const options: OptionsType[] = samplingProtocolsToOptions(samplingProtocols)

    function findProtocol(id: string) {
        if (!id) {
            return undefined;
        }

        if (!samplingProtocols) {
            return undefined;
        }

        return samplingProtocols.find(p => p.id === id);
    }

    const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: { value: string }): void => {
        setCurrentProtocolId(data.value);
        setConfirm(true);
    };
    const handleCancel = (): void => {
        setConfirm(false);
    };
    const handleConfirm = (): void => {
        setConfirm(false);
        dispatch(setSamplingProtocolOnOrder({
            orderId: order.order_id,
            samplingProtocol: findProtocol(selectedProtocolId)
        }));
    };
    useEffect(() => {
        if (currentProtocol && currentProtocol.id) {
            setCurrentProtocolId(currentProtocol.id);
        }
    }, [currentProtocol]);
    return (
        <Fragment>
            <Confirm
                open={confirm}
                content={warning}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
            <Dropdown
                search={(_opts, query) => {
                    return samplingProtocolsToOptions(samplingProtocols, query);
                }}
                inverse
                onChange={handleChange}
                options={options}
                selection
                value={selectedProtocolId}
                disabled={props.disabled}
            />
            {spDiffer.differ && <Popup trigger={
                <div className='flex'>
                    <div className='text-sm bg-attention mt-1'><InfoIcon iconOnly /><FormattedMessage id='inspections.heading.sampling_protocol_differs' /> ({spDiffer.cnt})</div>
                </div>
            }>
                <h4><FormattedMessage id='inspections.heading.sampling_protocol_differs' /></h4>
                <ul>
                    <li><FormattedMessage id='inspections.sampling_protocol_differs_from_order_sampling_protocol_cnt' values={{ cnt: <strong>{spDiffer.cnt}</strong> }} /></li>
                </ul>
            </Popup>}
        </Fragment>
    );
};

export default SamplingProtocolSelector;
