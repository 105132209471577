import React, { Fragment, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Popup, Segment } from 'semantic-ui-react';
import { getLanguageString, getLocaleLanguage, prettyClipText } from '../../../../../base/utils';
import { useAppDispatch } from '../../../../../store';
import { Checkpoint } from '../../../../checklists/types';
import { InfoIcon, Spacer } from '../../../../globals/components/Utils';
import { getReportMatrixDataSelector } from '../../../selectors/reportSelectors';
import { getReportMatrixData } from '../../../slices/inspectionReportSlice';
import { Inspection } from '../../../types';
import CheckpointComment from '../CheckpointComment';
import CheckpointsStatusIndicator from '../checkpoints/CheckpointsStatusIndicator';
import CheckpointStatus from './CheckpointStatus';

type ReportMatrixProps = {
    orderId: string;
};

type MatrixCheckpoint = Checkpoint & { data: any };

const ReportMatrix = (props: ReportMatrixProps): React.ReactElement => {
    const intl = useIntl();
    useEffect(() => {
        dispatch(getReportMatrixData(props.orderId));
    }, [props.orderId]);
    const dispatch = useAppDispatch();
    const matrixData = useSelector(getReportMatrixDataSelector);
    const matrix = [];
    const lang = getLocaleLanguage(intl.locale);
    const getMatrix = (matrices: any): React.ReactElement => {
        return (<Fragment>
            <Segment secondary><Header as='h3'><FormattedMessage id='report.matrix.header' />{matrices.inspection_type && <span><Spacer space='right'>.</Spacer>{matrices.inspection_type.name}</span>}</Header></Segment>
            {matrices.matrices.map((data, index: number) => {
                const inspections = data.inspections;
                const headers = data.headers;
                let evenOddCounter = 0;
                return (<Segment key={index}>
                    <div className={`inspections ${inspections.length < 4 ? 'few' : ''}`}>
                        <div className='item name'></div>
                        <div className='inner'>
                            {inspections.map((inspection: Inspection) => {
                                return <Popup
                                    key={inspection.inspection_id}
                                    className='reportMatrix statusPopup'
                                    trigger={<div className='item'><Link className='link noUnderline' to={`/inspections/reports/${inspection.inspection_id}`}>{inspection.item_number}</Link></div>}
                                    hoverable={true}
                                    basic={true}
                                >
                                    <Popup.Content>{inspection.item_number}. {inspection.item_name}</Popup.Content>
                                </Popup>;
                            })}
                        </div>
                    </div>
                    {headers.map((header) => {
                        const checkpoints = header.rows;
                        evenOddCounter++;
                        return (
                            <Fragment key={index}>
                                {header.rows.length > 0 &&
                                    <div className='checkpoints' >
                                        <div key={index} className={`items ${evenOddCounter % 2 === 1 ? 'even' : 'odd'} ${inspections.length < 4 ? 'few' : ''}`}>
                                            <div className='item name header'>{header.title && getLanguageString(header.title, lang)}</div>
                                            <div className='inner'>
                                                {inspections.map((inspection, index: number) => {
                                                    const inspectionCheckpoints = [];
                                                    checkpoints.map((row) => {
                                                        inspectionCheckpoints.push(row.data[index]);
                                                    })
                                                    return (<div className='item compound-status' key={inspections[index].id}><CheckpointsStatusIndicator key={inspections[index].id}
                                                        checkpoints={inspectionCheckpoints}
                                                    /></div>)
                                                })}
                                            </div>
                                        </div>
                                        {checkpoints.map((checkpoint: MatrixCheckpoint) => {
                                            const cpInstructionText = checkpoint.instruction_text[lang] || checkpoint.instruction_text.C;
                                            evenOddCounter++;
                                            return <div key={checkpoint.id} className={`items ${evenOddCounter % 2 === 1 ? 'even' : 'odd'} ${inspections.length < 4 ? 'few' : ''}`}>
                                                <Popup
                                                    className='reportMatrix statusPopup'
                                                    trigger={<Link to={`/inspections/reports/${checkpoint.data[0].inspection_id}/#checkpoint_${checkpoint.data[0].id}`}><div className='item name'>{prettyClipText(cpInstructionText, 60)}</div></Link>}
                                                    hoverable={true}
                                                    basic={true}
                                                    mouseEnterDelay={500}
                                                >
                                                    <Popup.Content>
                                                        <div className='item name'>{cpInstructionText}</div>
                                                    </Popup.Content>
                                                </Popup>
                                                <div className='inner'>
                                                    {checkpoint.data.map((cp, index: number) => {
                                                        return <Popup
                                                            key={index}
                                                            flowing
                                                            className='reportMatrix statusPopup'
                                                            trigger={<Link to={`/inspections/reports/${cp.inspection_id}#checkpoint_${cp.id}`}><div className='item'><CheckpointStatus checkpoint={cp} /></div></Link>}
                                                            hoverable={true}
                                                            basic={true}
                                                            mouseEnterDelay={500}
                                                        >
                                                            <Popup.Header><FormattedMessage id='report.matrix.status_popup.header' /> <Link to={`/inspections/reports/${cp.inspection_id}#checkpoint_${cp.id}`} className='link noUnderline viewInreport'>
                                                                <FormattedMessage id='report.matrix.view_in_report' /></Link></Popup.Header>
                                                            <Popup.Content>
                                                                <div className='itemName'>{inspections[index].item_name}</div>
                                                                <div className='cpText'>{cpInstructionText}</div>
                                                                <div className='cp'>
                                                                    <CheckpointComment checkpoint={cp} showStatus showIcon />
                                                                </div>

                                                                <div className='images'>
                                                                    {cp.observed_photos && cp.observed_photos.length > 0 && cp.observed_photos.map((photo: any, index: number) => {
                                                                        const url = photo.url + '?fit=crop&w=90';
                                                                        return (
                                                                            <div key={index} className='image'>
                                                                                <img src={url} />
                                                                                <div className='photoComment'>{photo.comment}</div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </Popup.Content>
                                                        </Popup>;
                                                    })}
                                                </div>
                                            </div>;
                                        })}
                                    </div>
                                }
                            </Fragment>
                        )
                    }
                    )}
                </Segment>)
            })}
        </Fragment>);
    };
    if (matrixData.length === 0 || (matrixData[0].inspections && matrixData[0].inspections.length === 0)) {
        return (
            <Segment.Group raised className='reportMatrix'>
                <Segment secondary><Header as='h3'><FormattedMessage id='report.matrix.header' /></Header></Segment>
                <Segment>
                    <InfoIcon text={intl.formatMessage({ id: 'report.matrix.no_items_message' })} />
                </Segment>
            </Segment.Group>
        );
    }

    if (matrixData) {
        matrixData.forEach((d) => {
            d.matrices.filter((i) => !!i.inspectionsMap);
            matrix.push(getMatrix(d));
        });
    }
    if (matrix.length > 0) {
        return <Fragment>{matrix.map((m, index: number) => {
            return <Segment.Group key={index} raised className='reportMatrix'>
                {m}
            </Segment.Group>
        })}
        </Fragment>
    }
    if (matrix.length === 0) {
        return (
            <Segment.Group raised className='reportMatrix'>
                <Segment secondary><Header as='h3'><FormattedMessage id='report.matrix.header' /></Header></Segment>
                <Segment>
                    <FormattedMessage id='report.matrix.loading_data' />
                </Segment>
            </Segment.Group>
        );
    }
};

export default ReportMatrix;
