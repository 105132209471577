/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 43.7.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Access features applicable to `AdminCtxUser`.
 * 
 * The features represent the access permissions that the calling user has on the given
 * `AdminCtxUser`.
 * Features are handled by the `User` access control property in the backend.
 * @export
 * @enum {string}
 */
export enum AdminCtxUserFeatures {
    Manage = 'manage',
    List = 'list',
    Edit = 'edit',
    Access = 'access',
    AccessOrgData = 'access_org_data'
}

export function AdminCtxUserFeaturesFromJSON(json: any): AdminCtxUserFeatures {
    return AdminCtxUserFeaturesFromJSONTyped(json, false);
}

export function AdminCtxUserFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCtxUserFeatures {
    return json as AdminCtxUserFeatures;
}

export function AdminCtxUserFeaturesToJSON(value?: AdminCtxUserFeatures | null): any {
    return value as any;
}

