/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.7 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputCustomFieldDateValue
 */
export interface InputCustomFieldDateValue {
    /**
     * 
     * @type {string}
     * @memberof InputCustomFieldDateValue
     */
    date: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputCustomFieldDateValue
     */
    type: InputCustomFieldDateValueTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum InputCustomFieldDateValueTypeEnum {
    Date = 'Date'
}

export function InputCustomFieldDateValueFromJSON(json: any): InputCustomFieldDateValue {
    return InputCustomFieldDateValueFromJSONTyped(json, false);
}

export function InputCustomFieldDateValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputCustomFieldDateValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'type': json['type'],
    };
}

export function InputCustomFieldDateValueToJSON(value?: InputCustomFieldDateValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'type': value.type,
    };
}


