import qs from 'query-string';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { DefectSeverityDefects, DefectTypeOverviewDefectSeverities, DefectTypeOverviewInspections } from '../../../../backend_api/models';
import Image from '../../../../base/components/Image';
import Loader from '../../../../base/components/Loader';
import ToTop from '../../../../base/components/ToTop';
import { getLocationEntries } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import { TextItem } from '../../../globals/components';
import Lightbox from '../../../globals/components/Lightbox';
import { SeverityLabel } from '../../../globals/components/Severity';
import { InfoIcon } from '../../../globals/components/Utils';
import { NakedStatusWithType } from '../../../inspections/components/edit/Status';
import ContentSection from '../../../pageLayouts/components/ContentSection';
import { dashboardDefectsImagesSelector, isDashboardLoadingSelector } from '../../selectors';
import { getDashboardDefectImages } from '../../slice/dashboardSlice';

type Props = {
    className?: string;
};

const DashboardDefectsImages = (props: Props): React.ReactElement => {
    const { className } = props;
    const defectImages = useSelector(dashboardDefectsImagesSelector);
    const images: string[] = [];
    const isLoading = useSelector(isDashboardLoadingSelector);
    const location = useLocation();
    const filterStr = qs.stringify(getLocationEntries(location), { arrayFormat: 'bracket' });
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const hasData = defectImages && defectImages.inspections.length > 0;
    useEffect(() => {
        if (location.hash !== '') {
            setTimeout(() => {
                document.location.href = location.hash;
            }, 1000)
        }
    }, [defectImages]);
    useEffect(() => {
        const defectTypeId = location.pathname.split('browse/')[1];
        dispatch(getDashboardDefectImages({ defectTypeId, filterString: filterStr }));
    }, [filterStr])
    const getPhotos = (d: DefectSeverityDefects, i: number, link: string, item?: React.ReactElement): React.ReactElement => {
        return <div className='w-full flex flex-wrap pt-3' key={'dip_' + i}>
            {d.description && <div className='pl-2 w-full'>{d.description}</div>}
            {d.photos.length === 0 && <div className='p-1'><FormattedMessage id='defects.images.no_photos' /></div>}
            {d.photos.length > 0 && d.photos.map((photo) => {
                images.push(photo);
                const l = link.split('/q/app')[1];
                return <div key={'diphoto_' + photo} className='p-2'>
                    <a id={'defect_' + d.id} />
                    <Lightbox photoURL={photo} images={images} className='cursor-pointer' type={'other'}>
                        <Image className='w-96' url={photo} />
                    </Lightbox>
                    <div className='py-2'><Link className='link' to={l + '#defect_' + d.id} onClick={() => window.location.hash = 'defect_' + d.id}><FormattedMessage id='defects.images.view_defect_in_report' /></Link></div>
                </div>
            })}
        </div>
    }
    const getDefect = (d: DefectTypeOverviewDefectSeverities, i: number, link: string, type: 'critical' | 'major' | 'minor', inspection: DefectTypeOverviewInspections): React.ReactElement => {
        if (d[type].defects.length > 0) {
            const item = (<div className='flex items-center pb-2 space-x-1 w-full justify-between'>
                <div className='flex items-center space-x-1'>
                    <Popup trigger={<span><NakedStatusWithType inspection={inspection as any} className={'py-1 px-2 text-base rounded'} /></span>}>
                        <TextItem column label={intl.formatMessage({ id: 'inspections.inspection_types' })} text={inspection.inspection_type && inspection.inspection_type.name || intl.formatMessage({ id: 'inspections.inspection_types.type_none' })} />
                    </Popup>
                    <SeverityLabel severity={type} className='w-16 sm:py-1' />
                    <div className='text-base'>{d[type].quantity > 0 && <span><FormattedMessage id='defects.images.items_affected' values={{ cnt: d[type].quantity }} />{d[type].general_defects > 0 && '.'}</span>} {d[type].general_defects > 0 && <span><FormattedMessage id='defects.images.general_defects' values={{ cnt: d[type].general_defects }} /></span>}</div>
                </div>
                {inspection.supplier_name && <div className='text-secondary'><FormattedMessage id='defects.images.supplier' />: {inspection.supplier_name}</div>}
            </div>)
            return <div className='flex flex-col'>
                {item}
                {d[type].defects.map((d, i) => {
                    return getPhotos(d, i, link, item);
                })}
            </div>
        }
    }
    return <><Breadcrumb className='pb-4'>
        <Breadcrumb.Section>
            <Link className='link noUnderline' to='/dashboard/defects'><FormattedMessage id='defects.images.defects' /></Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle' />
        <Breadcrumb.Section>
            <FormattedMessage id='defects.images.defects_browse' />
        </Breadcrumb.Section>
    </Breadcrumb>
        <ContentSection content={<div className={twMerge('bg-white', className)}>
            <Loader active={isLoading}>
                <a id='top' />
                <div className='pb-4'>
                    {hasData && <>
                        <h1 className='py-2'>{defectImages.defect_type.code}-{defectImages.defect_type.name}</h1>
                        {defectImages.defect_type.description && <div className='pt-2 text-secondary text-2xl'>{defectImages.defect_type.description}</div>}
                        {defectImages && defectImages.inspections.map((inspection, i) => {
                            const reportLink = inspection.report_link.split('/q/app')[1];
                            return <div className='pb-8 pt-4 w-full flex flex-col pr-8 border-b-2' key={'di_' + inspection.inspection_id}>
                                <h2 className='flex space-x-2'>{inspection.item_name === 'Combined inspection' ? <FormattedMessage id='defects.images.combined_inspection' /> : <span>{inspection.item_number}-{inspection.item_name}.</span>}</h2>
                                {inspection.defect_severities.major.defects.length > 0 && <div className=''>
                                    {getDefect(inspection.defect_severities, i, inspection.report_link, 'major', inspection)}
                                </div>}
                                {inspection.defect_severities.critical.defects.length > 0 && <div className=''>
                                    {getDefect(inspection.defect_severities, i, inspection.report_link, 'critical', inspection)}
                                </div>}
                                {inspection.defect_severities.minor.defects.length > 0 && <div className=''>
                                    {getDefect(inspection.defect_severities, i, inspection.report_link, 'minor', inspection)}
                                </div>}
                                <Link className='pl-2 link' to={reportLink}><FormattedMessage id='defects.images.view_report' /></Link>
                            </div>
                        })}
                    </>}
                    {!hasData && <div className='justify-center flex'><InfoIcon noMargins inline text={intl.formatMessage({ id: 'defects.images.no_defects_found' })} /></div>}
                    <div className='flex w-full justify-end'><ToTop /></div>
                </div>
            </Loader>
        </div>} />
    </>
}
export default DashboardDefectsImages;

