export const CREATE_ORDER_AND_INSPECTIONS = 'CREATE_ORDER_AND_INSPECTIONS';
export type CREATE_ORDER_AND_INSPECTIONS = {
    order_number?: string;
    inspections: [
        {
            item_name: string;
            item_number?: string;
            item_image?: string;
            geographic_location?: string;
            total_quantity?: 1000;
            sample_quantity?: 10;
            sampling_method?: string;
            supplier?: string;
            scheduled_inspection_date?: 'date';
            etd?: 'date';
        }
    ];
    isFetching: boolean;
};

export const CREATE_ORDER_AND_INSPECTIONS_SUCCESS = 'CREATE_ORDER_AND_INSPECTIONS_SUCCESS';
export type CREATE_ORDER_AND_INSPECTIONS_SUCCESS = {
    orderId: string;
    isFetching: boolean;
    data: any;
    isNew: boolean;
};

export const CREATE_ORDER_AND_INSPECTIONS_FAILURE = 'CREATE_ORDER_AND_INSPECTIONS_FAILURE';
export type CREATE_ORDER_AND_INSPECTIONS_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Request for an an order's attachments
export const ORDER_GET_ATTACHMENTS_REQUEST = 'ORDER_GET_ATTACHMENTS_REQUEST';
export type ORDER_GET_ATTACHMENTS_REQUEST = {
    isFetching: boolean;
    orderId: string;
};

// Succesfully retreived an order's attachments
export const ORDER_GET_ATTACHMENTS_SUCCES = 'ORDER_GET_ATTACHMENTS_SUCCES';
export type ORDER_GET_ATTACHMENTS_SUCCES = {
    isFetching: boolean;
    orderId: string;
    attachments: any[];
};

// Failed to retreive an order's attachments
export const ORDER_GET_ATTACHMENTS_FAILURE = 'ORDER_GET_ATTACHMENTS_FAILURE';
export type ORDER_GET_ATTACHMENTS_FAILURE = {
    isFetching: boolean;
    orderId: string;
    message: string;
    status: number;
};

// Request for an an inspection's attachments
export const INSPECTIONS_GET_ATTACHMENTS_REQUEST = 'INSPECTIONS_GET_ATTACHMENTS_REQUEST';
export type INSPECTIONS_GET_ATTACHMENTS_REQUEST = {
    isFetching: boolean;
    inspectionId: string;
};

// Succesfully retreived an inspection's attachments
export const INSPECTIONS_GET_ATTACHMENTS_SUCCES = 'INSPECTIONS_GET_ATTACHMENTS_SUCCES';
export type INSPECTIONS_GET_ATTACHMENTS_SUCCES = {
    isFetching: boolean;
    inspectionId: string;
    attachments: any[];
};

// Failed to retreive an inspection's attachments
export const INSPECTIONS_GET_ATTACHMENTS_FAILURE = 'INSPECTIONS_GET_ATTACHMENTS_FAILURE';
export type INSPECTIONS_GET_ATTACHMENTS_FAILURE = {
    isFetching: boolean;
    inspectionId: string;
    message: string;
    status: number;
};

// Add an attachment to an order. Attachment can be a file or an image
export const ORDER_ADD_ATTACHMENT_REQUEST = 'ORDER_ADD_ATTACHMENT_REQUEST';
export type ORDER_ADD_ATTACHMENT_REQUEST = {
    isFetching: boolean;
    attachmentType: string;
    inspectionId: string;
    files: any;
};
export const ORDER_ADD_ATTACHMENT_SUCCESS = 'ORDER_ADD_ATTACHMENT_SUCCESS';
export type ORDER_ADD_ATTACHMENT_SUCCESS = {
    isFetching: boolean;
    attachmentType: string;
    file: {
        name: string;
    };
};
export const ORDER_ADD_ATTACHMENT_FAILURE = 'ORDER_ADD_ATTACHMENT_FAILURE';
export type ORDER_ADD_ATTACHMENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Add an attachment to an inspection. Attachment can be a file or an image
export const INSPECTIONS_ADD_ATTACHMENT_REQUEST = 'INSPECTIONS_ADD_ATTACHMENT_REQUEST';
export type INSPECTIONS_ADD_ATTACHMENT_REQUEST = {
    isFetching: boolean;
    attachmentType: string;
    inspectionId: string;
    files: any;
};
export const INSPECTIONS_ADD_ATTACHMENT_SUCCESS = 'INSPECTIONS_ADD_ATTACHMENT_SUCCESS';
export type INSPECTIONS_ADD_ATTACHMENT_SUCCESS = {
    isFetching: boolean;
    attachmentType: string;
    file: {
        name: string;
    };
};
export const INSPECTIONS_ADD_ATTACHMENT_FAILURE = 'INSPECTIONS_ADD_ATTACHMENT_FAILURE';
export type INSPECTIONS_ADD_ATTACHMENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

// Remove an attachment from an inspection. Attachment can be a file or an image
export const INSPECTION_REMOVE_ATTACHMENT_REQUEST = 'INSPECTION_REMOVE_ATTACHMENT_REQUEST';
export type INSPECTION_REMOVE_ATTACHMENT_REQUEST = {
    isFetching: boolean;
    attachmentType: string;
    inspectionId: string;
    attachment: string;
};
export const INSPECTION_REMOVE_ATTACHMENT_SUCCES = 'INSPECTION_REMOVE_ATTACHMENT_SUCCES';
export type INSPECTION_REMOVE_ATTACHMENT_SUCCES = {
    isFetching: boolean;
};
export const INSPECTION_REMOVE_ATTACHMENT_FAILURE = 'INSPECTION_REMOVE_ATTACHMENT_FAILURE';
export type INSPECTION_REMOVE_ATTACHMENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};
// Remove an attachment from an order. Attachment can be a file or an image
export const ORDER_REMOVE_ATTACHMENT_REQUEST = 'ORDER_REMOVE_ATTACHMENT_REQUEST';
export type ORDER_REMOVE_ATTACHMENT_REQUEST = {
    isFetching: boolean;
    orderId: string;
    attachment: string;
};
export const ORDER_REMOVE_ATTACHMENT_SUCCES = 'ORDER_REMOVE_ATTACHMENT_SUCCES';
export type ORDER_REMOVE_ATTACHMENT_SUCCES = {
    isFetching: boolean;
};
export const ORDER_REMOVE_ATTACHMENT_FAILURE = 'ORDER_REMOVE_ATTACHMENT_FAILURE';
export type ORDER_REMOVE_ATTACHMENT_FAILURE = {
    isFetching: boolean;
    message: string;
    status: number;
};

export const INSPECTIONS_ADD_ATTACHMENT_PROGRESS = 'INSPECTIONS_ADD_ATTACHMENT_PROGRESS';
export type INSPECTIONS_ADD_ATTACHMENT_PROGRESS = {
    inspectionId: string;
    progress: number;
};

export const ORDER_ADD_ATTACHMENT_PROGRESS = 'ORDER_ADD_ATTACHMENT_PROGRESS';
export type ORDER_ADD_ATTACHMENT_PROGRESS = {
    orderId: string;
    progress: number;
};

export const SET_LAST_VIEWED_INSPECTION_PAGE = 'SET_LAST_VIEWED_INSPECTION_PAGE';
export type SET_LAST_VIEWED_INSPECTION_PAGE = {
    url: string;
};

export const UPDATE_ORDER_FROM_RESPONSE = 'UPDATE_ORDER_FROM_RESPONSE';
export type UPDATE_ORDER_FROM_RESPONSE = {
    data: any;
}
