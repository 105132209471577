import qs from 'query-string';
import { createAction } from 'typesafe-actions';
import { BasicUser } from '../../../../backend_api/models';
import { request } from '../../../../base/api';
import { AppThunk } from '../../../../base/types';
import { filterDisabledUsersById, getLocationEntries, getMoment, getWeekNumberByDate, replaceAll, urlParamsToArray } from '../../../../base/utils';
import history from '../../../../store/history';
import errorHandling from '../../../errorHandling';
import { getUserEmailSelector } from '../../../users/selectors';
import { getWeekViewGroupsSelector } from '../../selectors/selectors';
import { setFavoriteInspectors, setFavoriteInspectorsManually as setFavoriteInspectorsManuallyInReducer } from '../../slices/weekViewSlice';
import * as types from '../../types';
import { WeekViewType } from '../../types';

const catchException = errorHandling.handler.catchException;

const getWeekViewDataRequest = createAction(types.GET_WEEK_VIEW_DATA_REQUEST, (isLoading: boolean) => {
    return {
        type: types.GET_WEEK_VIEW_DATA_REQUEST, payload: {
            isLoading,
        },
    };
});
export const getWeekViewDataSuccess = createAction(types.GET_WEEK_VIEW_DATA_SUCCESS, (startDate: string, endDate: string, weekNumber: number, data: any, filters: any) => {
    return {
        type: types.GET_WEEK_VIEW_DATA_SUCCESS, payload: {
            startDate,
            endDate,
            weekNumber,
            data,
            filters,
        },
    };
});

export const getWeekViewDataFiltered = (date: string, isLoading = true): AppThunk => {
    const dates = getStartAndEndDateByDate(date);
    const weekNumber = getWeekNumberByDate(date);
    return async (dispatch, getState): Promise<void> => {
        dispatch(getFavoriteInspectors());
        const filtersParam: string = qs.stringify(urlParamsToArray(history.location.search), { arrayFormat: 'bracket' });

        const weekView = getState().app.inspections.weekView;
        const favInsp = weekView.favoriteInspectors && weekView.favoriteInspectors !== '' ? weekView.favoriteInspectors.split(',') : [];
        const setInspectorsManually = weekView.setInspectorsManually;
        dispatch(getWeekViewDataRequest(isLoading));
        let url = 'planning/weekview?start_date=' + dates.startDate + '&no_of_days=7&' + filtersParam;
        if (setInspectorsManually) {
            url += '&only_specified_inspectors=true';
        }
        const body = JSON.stringify({ assigned_user_ids: favInsp });
        return request(url, {
            method: 'PUT',
            body,
        })
            .then((data: []) => {
                dispatch(getWeekViewDataSuccess(dates.startDate, dates.endDate, weekNumber, data, getLocationEntries(history.location)));
            })
            .catch((error) => {
                catchException('getPlanningData', {
                    endpoint: 'orders?startDate=[startDate]&endDate=[endDate]',
                    request: url,
                    status: error.status,
                }, { error, startDate: dates.startDate, endDate: dates.endDate });
            });
    };
};

export const getStartAndEndDateByDate = (date: string) => {
    const weekNo = getMoment(date).isoWeek();
    const endDate = getMoment().isoWeek(weekNo).add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
    return { startDate: date, endDate };
};

export const getWeekViewData = (date: string, isLoading = true) => {
    return (dispatch) => {
        dispatch(getWeekViewDataFiltered(date, isLoading));
    };
};

export const getWeekViewCompactData = (data: any) => {
    data.inspectors.forEach((inspector) => {
        inspector.days.forEach((day) => {
            const supplierObj = { none: undefined };
            day.forEach((order: types.Order) => {
                let hasPushed = false;
                order.inspections.forEach((inspection: types.Inspection) => {
                    if (inspection.supplier_entity) {
                        if (!supplierObj[inspection.supplier_entity.id]) {
                            supplierObj[inspection.supplier_entity.id] = [];
                        }
                        if (!supplierObj[inspection.supplier_entity.id].includes(order) && !hasPushed) {
                            supplierObj[inspection.supplier_entity.id].push(order);
                            hasPushed = true;
                        }
                    } else {
                        if (!supplierObj.none) {
                            supplierObj.none = [];
                        }
                        if (!supplierObj.none.includes(order) && !hasPushed) {
                            supplierObj.none.push(order);
                            hasPushed = true;
                        }
                    }
                });
                day.supplier = supplierObj;
            });
        });
    });
    return data;
}

/* const setFavoriteInspectors = createAction(types.SET_FAVORITE_INSPECTORS, (inspectorIds: [string]) => {
    return {
        type: types.SET_FAVORITE_INSPECTORS, payload: {
            inspectorIds,
        },
    };
}); */

export const setFavoriteInspector = (inspector: BasicUser) => {
    return (dispatch, getState) => {
        const profileEmail = getUserEmailSelector(getState());
        const currentGroups = replaceAll(getWeekViewGroupsSelector(getState()).toString(), ',', '_');
        const itemId = profileEmail + '_' + currentGroups + '_favoriteInspectors';
        if (localStorage.getItem(itemId) !== null && localStorage.getItem(itemId) !== '') {
            const inspectors = localStorage.getItem(itemId).split(',');
            if (inspectors.includes(inspector.id)) {
                const index = inspectors.indexOf(inspector.id);
                inspectors.splice(index, 1);
            } else {
                inspectors.push(inspector.id);
            }
            localStorage.setItem(itemId, inspectors.toString());
        } else {
            localStorage.setItem(itemId, inspector.id);
        }
        dispatch(getFavoriteInspectors());
    };
};

export const getFavoriteInspectors = () => {
    return (dispatch, getState) => {
        const users = filterDisabledUsersById(getState().app.users.byId);
        const userIds = Object.keys(users);
        const profileEmail = getUserEmailSelector(getState());
        const currentGroups = replaceAll(getWeekViewGroupsSelector(getState()).toString(), ',', '_');
        const itemId = profileEmail + '_' + currentGroups + '_favoriteInspectors';
        let retval;
        if (itemId !== '' && localStorage.getItem(itemId) !== null) {
            retval = localStorage.getItem(itemId);
            const tmpInsp = retval.split(',');
            let i = tmpInsp.length;
            while (i--) { // Going backwards to ensure array index is intact after splice
                if (!userIds.includes(tmpInsp[i])) {
                    tmpInsp.splice(i, 1);
                }
            }
            dispatch(setFavoriteInspectors(tmpInsp.toString()));
        }

        let m = false;
        if (localStorage.getItem(itemId + '_setFavoriteInspectorsManually')) {
            m = (localStorage.getItem(itemId + '_setFavoriteInspectorsManually') === 'true');
        }
        dispatch(setFavoriteInspectorsManually(m));
    };
};

export const setFavoriteInspectorsManually = (manually: boolean) => {
    return (dispatch, getState) => {
        const profileEmail = getUserEmailSelector(getState());
        const currentGroups = replaceAll(getWeekViewGroupsSelector(getState()).toString(), ',', '_');
        const itemId = profileEmail + '_' + currentGroups + '_favoriteInspectors';
        localStorage.setItem(itemId + '_setFavoriteInspectorsManually', manually.toString());
        dispatch(setFavoriteInspectorsManuallyInReducer(manually));
        
    };
};

export const changeViewType = (type: WeekViewType) => {
    return (dispatch) => {
        dispatch({ type: types.SET_WEEK_VIEW_TYPE, payload: { type } });
    };
};

export const setFilters = (filters: []) => {
    return (dispatch) => {
        dispatch({ type: types.SET_WEEK_VIEW_FILTERS, payload: { filters } });
    };
};
