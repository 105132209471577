/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a text
 *
 */
export type AuditCustomFieldText = {
    text: string;
    type: AuditCustomFieldText.type;
};

export namespace AuditCustomFieldText {

    export enum type {
        TEXT = 'text',
    }


}

