import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { ReportCheckpoint } from '../../../backend_api/models';
import { ReportCheckpointHeader } from '../../../backend_api/models/ReportCheckpointHeader';
import { getLocaleLanguageString } from '../../../base/utils';
import { CheckpointPhoto } from '../../checklists/types';
import { TranslateText } from '../../globals/components';
import CheckpointComment from '../../inspections/components/report/CheckpointComment';
import CheckpointInfo from '../../inspections/components/report/checkpoints/CheckpointInfo';
import MeasurementTables from '../../inspections/components/report/checkpoints/MeasurementTables';
import MultipleChoice from '../../inspections/components/report/checkpoints/MultipleChoice';
import { ProductionUnitCustomFields } from '../../inspections/components/report/checkpoints/ProductionUnitCustomFields';
import { SupplierCustomFields } from '../../inspections/components/report/checkpoints/SupplierCustomFields';
import TextInput from '../../inspections/components/report/checkpoints/TextInput';
import TextTable from '../../inspections/components/report/checkpoints/TextTable';
import CheckpointImage from '../../inspections/components/report/report2/CheckpointImage';
import CheckpointVideo from '../../inspections/components/report/report2/CheckpointVideo';
import ImagesContainer from '../../inspections/components/report/report2/ImagesContainer';
import { HasFeature } from '../../profile/containers';
import AuditContextMenu from './AuditContextMenu';

type AuditCheckpointsProps = {
    checkpointHeaders: ReportCheckpointHeader[];
    auditId: string;
};

const AuditCheckpoints = (props: AuditCheckpointsProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const { checkpointHeaders, auditId } = props;
    const isCheckpointEmpty = false;
    const showTranslatedComments = false;
    const getCheckpointImageComment = (photo: CheckpointPhoto): React.ReactElement | null => {
        return photo.comment ? <TranslateText
            showTranslatedComments={showTranslatedComments}
            translateObj={photo.translated_comment}
            originalLanguage={photo.language}
            originalText={photo.comment}
            showToggleActions={true}
        /> : null;
    }
    const r = checkpointHeaders.map((checkpointHeader) => {
        return <div key={'cp_header_' + checkpointHeader.id} className='auditCheckpoint'>
            <h2>{checkpointHeader.title[lang] || checkpointHeader.title.C}</h2>
            {checkpointHeader.checkpoints.map((checkpoint: ReportCheckpoint) => {
                return <Fragment key={'cp_' + checkpoint.id} >
                    <a id={'checkpoint_' + checkpoint.id} />
                    <div className='flex flex-col'>
                        <div className='flex items-start space-x-2'>
                            <h3 className='flex items-center'>
                                {checkpoint.instruction_text[lang] || checkpoint.instruction_text.C}<CheckpointInfo checkpoint={checkpoint} />
                            </h3>
                            <HasFeature feature='u_create_corrective_action'>
                                <AuditContextMenu ca={{ audit_links: [auditId], finding: checkpoint.instruction_text[lang] || checkpoint.instruction_text.C, checkpoint_links: [checkpoint.id] }} />
                            </HasFeature>
                        </div>
                        <div className=''>
                            {!isCheckpointEmpty && <Fragment><CheckpointComment showIcon={true} showStatus={true} checkpoint={checkpoint} showTranslatedComments={showTranslatedComments} showToggleActions={true} /></Fragment>}
                        </div>
                    </div>
                    {checkpoint.measurement_tables && <MeasurementTables tables={checkpoint.measurement_tables} />}
                    {checkpoint.multiple_choice && <MultipleChoice options={checkpoint.multiple_choice.options} />}
                    {checkpoint.text_inputs && <TextInput textInputs={checkpoint.text_inputs} />}
                    {checkpoint.text_table && <TextTable table={checkpoint.text_table} />}
                    {checkpoint.checkpoint_type == 'supplier_custom_fields' && <SupplierCustomFields customFields={checkpoint.supplier_custom_fields as any} />}
                    {checkpoint.checkpoint_type == 'production_unit_custom_fields' && <ProductionUnitCustomFields customFields={checkpoint.production_unit_custom_fields as any} />}

                    {checkpoint.observed_photos.length > 0 &&
                        <ImagesContainer>
                            {checkpoint.observed_photos.map((image) => {
                                if (image.type == 'video') {
                                    return <CheckpointVideo
                                        image={image}
                                        imageComment={getCheckpointImageComment(image)}
                                        videoURL={image.video_resources[0].url}
                                    />
                                }
                                else {
                                    return <CheckpointImage
                                        imageURL={image.url}
                                        inspection={undefined}
                                        typeId={checkpoint.id} type='checkpoint_image'
                                        imageComment={getCheckpointImageComment(image)}
                                        key={'checkpointImage_' + image.url}
                                        dataType={'audit'}
                                    />
                                }
                            })}
                        </ImagesContainer>}
                </Fragment>
            })}</div>
    });
    return <Fragment>{r}</Fragment>;
}
export default AuditCheckpoints;

