import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { AdminCtxUser, AdminCtxUserFeatures, Audit, AuditFeaturesEnum, BasicUser, CorrectiveAction, CorrectiveActionAccessibleFeaturesEnum, DetailedSupplier, SupplierFeaturesDto, SupplierServiceDetailedSupplier } from '../../../../backend_api/models';
import { getFeaturesSelector } from '../../../../base/selectors';
import { GlobalUFeatures } from '../../../../base/types';
import { Inspection, InspectionFeatureTypes, Order, OrderFeatureTypes } from '../../../inspections/types';
import { InfoIcon } from '../Utils';


enum Type { INSPECTION = 'inspection', ORDER = 'order', SUPPLIER = 'supplier', USER = 'user', CORRECTIVE_ACTION = 'corrective_action', AUDIT = 'audit' }
interface OwnProps {
    showDialog?: boolean;
    showDisabled?: boolean;
    order?: Order;
    inspection?: Inspection;
    supplier?: SupplierServiceDetailedSupplier | DetailedSupplier;
    user?: AdminCtxUser;
    feature: string;
    noFeaturePlaceHolder?: string | React.ReactElement;
    correctiveAction?: CorrectiveAction;
    audit?: Audit;
}
type AcessType =
    {
        type: 'inspection'
        feature: InspectionFeatureTypes,
        inspection: Inspection,
    }
    | {
        type: 'order',
        feature: OrderFeatureTypes,
        order: Order,
    }
    | {
        type?: 'supplier'
        feature: SupplierFeaturesDto,
        supplier: SupplierServiceDetailedSupplier | DetailedSupplier,
    }
    | {
        type: 'user',
        feature: AdminCtxUserFeatures,
        user: BasicUser,
    }
    | {
        type: 'corrective_action',
        feature: CorrectiveActionAccessibleFeaturesEnum,
        correctiveAction: CorrectiveAction,
    }
    | {
        type: 'audit',
        feature: AuditFeaturesEnum,
        audit: Audit,
    }
    | {
        type?: undefined,
        feature: GlobalUFeatures,
    };
type HasFeatureAccessProps = AcessType & OwnProps;

const HasFeatureAccess = (props: PropsWithChildren<HasFeatureAccessProps>): any => {
    const { audit, correctiveAction, type, inspection, order, supplier, user, children, showDialog = true, showDisabled = false, noFeaturePlaceHolder } = props;
    const intl = useIntl();
    const disabledText = intl.formatMessage({ id: 'feature_access.no_access_to_editing.please_contact_admin' });
    const disabledElement = (text: string): React.ReactElement => {
        return getChildren(children, text);
    };
    const getChildren = (children: any, text: string): React.ReactElement => {
        return React.Children.map<React.ReactNode, any>(children, child => {
            if (child) {
                if (child.type.name !== 'HasFeatureAccess' && child.type.name !== 'HasReadonlyAccess') {
                    const cl = React.cloneElement(
                        child, { disabled: true },
                    );
                    return showDialog ? <Popup trigger={<span>{cl}</span>}><InfoIcon compact inline text={text} /></Popup> : cl;
                }
                return getChildren(children.props.children, text);
            }
        });
    }
    if (type === undefined) {
        const features = useSelector(getFeaturesSelector);
        if (!features.includes(props.feature) && showDisabled) {
            return disabledElement(`Disabled feature on inspection (${props.feature})`);
        }
        if (features.includes(props.feature)) {
            return children;
        }
        return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
    }
    if (type === Type.INSPECTION) {
        if (inspection.features && !inspection.features.includes(props.feature as InspectionFeatureTypes) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (inspection.features && !inspection.features.includes(props.feature as InspectionFeatureTypes)) {
            return disabledElement(disabledText);
        }
    }
    if (type === Type.ORDER) {
        if (order.features && !order.features.includes(props.feature as OrderFeatureTypes) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (order.features && !order.features.includes(props.feature as OrderFeatureTypes)) {
            return disabledElement(disabledText);
        }
    }
    if (type === Type.SUPPLIER) {
        if (supplier.features && !supplier.features.includes(props.feature as SupplierFeaturesDto) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (supplier.features && !supplier.features.includes(props.feature as SupplierFeaturesDto)) {
            return disabledElement(disabledText);
        }
    }
    if (type === Type.USER) {
        if (user.features && !user.features.includes(props.feature as AdminCtxUserFeatures) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (user.features && !user.features.includes(props.feature as AdminCtxUserFeatures)) {
            return disabledElement(disabledText);
        }
    }
    if (type === Type.CORRECTIVE_ACTION) {
        if (correctiveAction.accessible_features && !correctiveAction.accessible_features.includes(props.feature as CorrectiveActionAccessibleFeaturesEnum) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (correctiveAction.accessible_features && !correctiveAction.accessible_features.includes(props.feature as CorrectiveActionAccessibleFeaturesEnum)) {
            return disabledElement(disabledText);
        }
    }
    if (type === Type.AUDIT) {
        if (audit.features && !audit.features.includes(props.feature as AuditFeaturesEnum) && !showDisabled) {
            return noFeaturePlaceHolder ? noFeaturePlaceHolder : null;
        }
        if (audit.features && !audit.features.includes(props.feature as AuditFeaturesEnum)) {
            return disabledElement(disabledText);
        }
    }
    return children;
};
export default HasFeatureAccess;
