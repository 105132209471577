import qs from 'query-string';
import { createAction } from 'typesafe-actions';
import { request } from '../../../base/api';
import { FILTERS } from '../../../base/config';
import { AppThunk } from '../../../base/types';
import { getLocationEntries } from '../../../base/utils';
import history from '../../../store/history';
import errorHandling from '../../errorHandling';
import { clearSearch } from '../../search/actions';
import * as types from '../types';
const catchException = errorHandling.handler.catchException;

export const setInspectionFilterRequest = createAction(types.SET_INSPECTION_FILTER, (name: string, value: any) => ({
    type: types.SET_INSPECTION_FILTER, payload: { name, value },
}));

export const setInspectionFilters = () => {
    const allowedFilters = FILTERS;
    const filters = qs.parse(history.location.search);
    Object.keys(filters).forEach((filterName) => {
        if (!allowedFilters.includes(filterName)) {
            delete filters[filterName];
        }
    });
    return ((dispatch) => {
        dispatch({ type: types.SET_INSPECTION_FILTERS, payload: { filters } });
    });
};

export const setInspectionFiltersVisibiltyRequest = createAction(types.SET_INSPECTION_FILTERS_VISIBILITY, (visibility: boolean) => ({
    type: types.SET_INSPECTION_FILTERS_VISIBILITY, payload: { visibility },
}));

export const setInspectionFiltersVisibilty = (visible: boolean) => {
    return ((dispatch) => {
        dispatch({ type: types.SET_INSPECTION_FILTERS_VISIBILITY, payload: { visible } });
    });
};

export const clearInspectionFilters = () => {
    return ((dispatch, getState) => {
        const filters = Object.assign({}, getState().app.inspections.filters);
        const params = qs.parse(history.location.search);
        Object.keys(filters).forEach((filterName) => {
            delete filters[filterName];
            delete params[filterName];

        });
        // delete params.search;
        params.status = 'planned';
        history.push('?' + qs.stringify(params));
        dispatch(clearSearch());
    });
};

export const setActiveHiddenFiltersRequest = createAction(types.SET_INSPECTION_HIDDEN_FILTERS, (filters: any[]) => ({
    type: types.SET_INSPECTION_HIDDEN_FILTERS, payload: { filters },
}));

export const setActiveHiddenFilters = () => {
    return ((dispatch, getState) => {
        const filters: any = [];
        getState().app.inspections.hiddenFilters.forEach((filter) => {
            if (getState().app.inspections.filters[filter]) {
                filters.push(filter);
            }
        });
        dispatch(setActiveHiddenFiltersRequest(filters));
    });
};

const isFetchingSupplisers = createAction(types.SUPPLIERS_IS_FETCHING_REQUEST, (isFetching: boolean) => ({ type: types.SUPPLIERS_IS_FETCHING_REQUEST, payload: { isFetching } }));
const supplierSucces = createAction(types.SUPPLIERS_SUCCESS, (suppliers: any) => ({ type: types.SUPPLIERS_SUCCESS, payload: { suppliers } }));

export const getSuppliers = (): AppThunk => {
    return (async (dispatch): Promise<void> => {
        dispatch(isFetchingSupplisers(true));
        const date = getLocationEntries(history.location).date;
        let url = 'suppliers2';
        if (date) {
            url += '?date=' + date;
        }
        return request(url, {})
            .then((suppliers) => {
                // The users request was a success - dispatch users success action
                dispatch(supplierSucces(suppliers));
            }).catch((error) => {
                dispatch(isFetchingSupplisers(false));
                // The users request failed - dispatch failure action
                // dispatch(usersRequestFailure(false, error));
                // Send error to error handler (Sentry)
                catchException('suppliers', {
                    endpoint: 'suppliers2',
                    request: 'suppliers2',
                    status: error.status || '',
                }, { error });
            });
    });
}