import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup, Table, TableRow } from 'semantic-ui-react';
import { AuditReportCustomField, AuditReportCustomFieldsDate, AuditReportCustomFieldsDateTypeEnum, AuditReportCustomFieldsMultipleChoice, AuditReportCustomFieldsMultipleChoiceTypeEnum, AuditReportCustomFieldsNumber, AuditReportCustomFieldsNumberTypeEnum, AuditReportCustomFieldsText, AuditReportCustomFieldsTextTypeEnum } from '../../../../../../backend_api/models';
import Tag from '../../../../../../base/components/basic/Tag';
import { translateTextWithTranslation } from '../../../../../../base/components/basic/TextWithTranslation';
import { getPrettyDate } from '../../../../../../base/utils';

export function renderCustomFieldValueRow(
    customFieldValue: AuditReportCustomField,
    locale: string | null
) {
    return (
        <TableRow>
            <Table.Cell>
                {translateTextWithTranslation(customFieldValue.name, locale)}
            </Table.Cell>
            <Table.Cell>
                {renderContent(customFieldValue, locale)}
            </Table.Cell>
        </TableRow >
    );
}

function renderContent(
    customFieldValue: AuditReportCustomField,
    locale: string | null
) {
    if (didAuditorChangeCustomFieldValue(customFieldValue)) {
        return renderWhenChanged(customFieldValue, locale);
    }
    if (didAuditorDidConfirmCustomFieldValue(customFieldValue)) {
        return renderWhenConfirmed(customFieldValue, locale);
    }
    return renderWhenNotTouched(customFieldValue, locale);
}

function renderWhenChanged(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderPreviousCustomFieldValue(customFieldValue.value, locale)}
            <span> &rarr; </span>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_changed'} />
        </Popup>
    )
}

function renderWhenNotTouched(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_not_changed'} />
        </Popup>
    )
}

function renderWhenConfirmed(customFieldValue: AuditReportCustomField, locale: string | null) {
    const trigger = (
        <div>
            {renderCustomFieldValue(customFieldValue.value, locale)}
            <span> &#10003; </span>
        </div>
    )

    return (
        <Popup trigger={trigger}>
            <FormattedMessage id={'checkpoints.custom_field_confirmed'} />
        </Popup>
    )
}

export function didAuditorDidConfirmCustomFieldValue(wrapper: AuditReportCustomField) {

    if (!wrapper.set_by_auditor) {
        return false;
    }

    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.every(option => option.selected === option.previously_selected)

    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date === value.previous_date;

    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text === value.previous_text;

    } else {
        return value.number === value.previous_number;
    }
}

export function didAuditorChangeCustomFieldValue(wrapper: AuditReportCustomField) {
    if (!wrapper.set_by_auditor) {
        return false;
    }
    const value = wrapper.value;

    if (value.type === AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        return value.options.some(function (option) {
            return option.previously_selected !== option.selected;
        });
    } else if (value.type === AuditReportCustomFieldsDateTypeEnum.Date) {
        return value.date !== value.previous_date;
    } else if (value.type === AuditReportCustomFieldsTextTypeEnum.Text) {
        return value.text !== value.previous_text;
    } else {
        return value.number !== value.previous_number;
    }
}

export function renderPreviousCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.previously_selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.previous_date) {
            return (<span>{getPrettyDate(value.previous_date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.previous_text) {
            return (<span>{value.previous_text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.previous_number) {
            return (<span>{value.previous_number}</span>);
        }
    }

    return renderEmptyValue();
}

export function renderCustomFieldValue(
    value: AuditReportCustomFieldsMultipleChoice | AuditReportCustomFieldsText | AuditReportCustomFieldsDate | AuditReportCustomFieldsNumber,
    locale: string | null) {
    if (value.type == AuditReportCustomFieldsMultipleChoiceTypeEnum.MultipleChoice) {
        const selectedOptions = value.options.filter(function (option) { return option.selected });
        if (selectedOptions.length > 0) {
            return selectedOptions.map(function (option) {
                return (<Tag className={'m-1'} key={'acf_' + option.name}>{translateTextWithTranslation(option.name, locale)}</Tag>);
            });
        }
    }
    else if (value.type == AuditReportCustomFieldsDateTypeEnum.Date) {
        if (value.date) {
            return (<span>{getPrettyDate(value.date)}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsTextTypeEnum.Text) {
        if (value.text) {
            return (<span>{value.text}</span>);
        }
    }
    else if (value.type == AuditReportCustomFieldsNumberTypeEnum.Number) {
        if (value.number) {
            return (<span>{value.number}</span>);
        }
    }

    return renderEmptyValue();
}

function renderEmptyValue() {
    return <span className='italic text-secondary'><FormattedMessage id='globals.no_value'/></span>
}