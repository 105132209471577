import qs from 'query-string';
import { FILTERS } from '../../base/config';
import { deepCopy } from '../../base/utils';

export const parseFiltersObj = (filters: any, arrayFormat: any, excludeParams?: string[]): string => {
    const okFilters = Object.assign({}, filters);
    Object.keys(filters).forEach((filterKey: string) => {
        if (!FILTERS.includes(filterKey) || excludeParams && excludeParams.includes(filterKey)) {
            delete okFilters[filterKey];
        }
    });
    if (arrayFormat) {
        return qs.stringify(okFilters, { encode: true, arrayFormat, skipNull: true });
    }
    return qs.stringify(okFilters, { encode: true, skipNull: true });
};

export const replaceFilterStringWithArray = (filters): void => {
    const filtersCopy = deepCopy(filters);
    Object.keys(filters).forEach((key: string) => {
        let copyKey = key;
        const value = filters[key];
        if (copyKey.includes('[]')) {
            copyKey = copyKey.split('[]')[0];
            delete filtersCopy[key];
        }
        if (typeof (value) === 'string') {
            filtersCopy[copyKey] = [value];
        } else {
            if (value) {
                filtersCopy[copyKey] = value;
            }
        }
    });
    return filtersCopy;
};

export const getDateParam = (startDate?: string, endDate?: string): string => {
    if (!endDate && !startDate) {
        return undefined;
    }
    if (endDate && startDate) {
        return startDate + '<' + endDate;
    }
    if (endDate) {
        return '<' + endDate;
    }
    return startDate + '<';
};

export const parseDateParam = (date: string): { startDate: string; endDate: string } => {
    const d = date.split('<');
    if (d[0] === '') {
        return { startDate: '', endDate: d[1] };
    }
    if (d[1] === '') {
        return { startDate: d[0], endDate: '' };
    }
    return { startDate: d[0], endDate: d[1] };
};

export const convertFiltersToQueryString = (filtersInput: any) => {
    const filters = deepCopy(filtersInput)
    if (filters.preset) {
        delete filters.preset;
    }
    const getDate = (d: any[]): string => {
        if (d.length === 1) {
            return 'date_interval_start=' + d[0];
        }
        if (d[0] === '' && d[1] === '') {
            return '';
        }
        if (d[0] === '') {
            return 'date_interval_end=' + d[1];
        }
        if (d[1] === '') {
            return 'date_interval_start=' + d[0];
        }
        return 'date_interval_start=' + d[0] + '&date_interval_end=' + d[1];
    }
    let filterStr = '';
    if (filters.date) {
        const d = filters.date[0].split('<');
        filterStr += getDate(d);
        delete filters.date;
    }
    return filterStr += '&' + (filters ? qs.stringify(filters, { encode: false, arrayFormat: 'bracket', skipNull: true }) : '');
}

