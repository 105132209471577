import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { useAppDispatch } from '../../../store';
import globals from '../../globals';
import { logout } from '../authenticationSlice';
const IsSavingInfo = globals.components.Utils.IsSavingInfo;

type LogoutProps = {
    isSaving: boolean;
}

const Logout = (props: LogoutProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const button = <Button disabled={props.isSaving} onClick={(): void => { dispatch(logout()) }}>
        <FormattedMessage id='login.logout' />
    </Button>;

    if (props.isSaving) {
        return (
            <IsSavingInfo
                trigger={button}
                on='hover'
                position='bottom center'
            />
        );
    } else {
        return (<div>{button}</div>);
    }
};

export default Logout;
