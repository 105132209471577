import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormField } from 'semantic-ui-react';
import { Audit, AuditFeaturesEnum, AuditStatus, CreateProductionUnitBody, DetailedSupplier, SimpleProductionUnit } from '../../../../backend_api/models';
import Icon from '../../../../base/components/basic/Icon';
import { useAppDispatch } from '../../../../store';
import history from '../../../../store/history';
import { getAuditChecklists } from '../../../checklists/actions/actions';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import ControlledProductionUnitSelector from '../../../productionUnits/components/ControlledProductionUnitSelector';
import { CreateProductionUnitModal } from '../../../productionUnits/components/CreateProductionUnitModal';
import { NewProductionUnitParams } from '../../../productionUnits/components/NewProductionUnitParams';
import { useHasIndependentProductionUnits } from '../../../productionUnits/hooks/useHasIndependentProductionUnits';
import { areSupplierAndProductionUnitLinked, useSupplierAndProductionUnitSelection } from '../../../productionUnits/hooks/useSupplierAndProductionUnitSelection';
import { createProductionUnitWithoutSupplier, createProductionUnitWithSupplier, useSelectableProductionUnits } from '../../../productionUnits/slice/productionUnitsSlice';
import { linkProductionUnitAndSupplier, useSupplierAndProductionUnitRelations } from '../../../supplierAndPuRelations/slice/relationSlice';
import ControlledSupplierSelector from '../../../suppliers/components/ControlledSupplierSelector';
import { CreateSupplierModal } from '../../../suppliers/components/CreateSupplierModal';
import { createLegacySupplier } from '../../../suppliers/slice/suppliersSlice';
import { getSupplierNameAndNumber } from '../../../suppliers/util';
import { getAuditByIdSelector } from '../../selectors/auditSelectors';
import { getAuditById, updateAudit } from '../../slices/auditSlice';

export function SupplierAndProductionUnitSelector(props: {
    auditId: string
}) {
    const {auditId} = props;
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const audit = useSelector(getAuditByIdSelector);
    const isAuditImmutable = audit?.locked || audit?.status != AuditStatus.Planned;

    const [showCreateSupplierModal, setShowCreateSupplierModal] = React.useState(false);
    const [showCreateProductionUnitModal, setShowCreateProductionUnitModal] = React.useState(false);

    const independentProductionUnits = useHasIndependentProductionUnits();
    const potentiallyLoadingProductionUnits = useSelectableProductionUnits()
    const productionUnitsAreLoading = potentiallyLoadingProductionUnits === 'loading';
    const productionUnits = productionUnitsAreLoading ? [] : potentiallyLoadingProductionUnits;
    const userHasEditFeature = audit?.features?.includes(AuditFeaturesEnum.Edit) || false;

    const {
        suppliers: selectableSuppliers,
        productionUnits: selectableProductionUnits,
        otherProductionUnits,
        otherSuppliers,
        selectedProductionUnitId,
        selectedSupplierId,
        setSelectedProductionUnitId,
        setSelectedSupplierId,
        linked: selectedSupplierAndProductionUnitAreLinked,
        loading: useSupplierLoading
    } = useSupplierAndProductionUnitSelection(independentProductionUnits, fallbackProductionUnit());

    const relations = useSupplierAndProductionUnitRelations();
    const [isCreatingPuOrSupplier, setIsCreatingPuOrSupplier] = React.useState(false);
    const loadingSupplierRelatedData = useSupplierLoading || relations === 'loading' || productionUnitsAreLoading || isCreatingPuOrSupplier;

    React.useEffect(() => {
        dispatch(getAuditChecklists());
        dispatch(getAuditById({ auditId: auditId }))
    }, []);

    React.useEffect(() => {
        if (audit && audit.id == auditId) {
            setSelectedSupplierId(audit?.target_supplier?.id);
            setSelectedProductionUnitId(audit?.target_production_unit?.id);
        }
    }, [audit]);

    function fallbackProductionUnit(): SimpleProductionUnit | undefined {
        if (!audit) {
            return undefined;
        }

        if (!audit?.target_production_unit) {
            return undefined
        }

        return {
            name: audit.target_production_unit.name,
            number: audit.target_production_unit.number,
            id: audit.target_production_unit.id
        }
    }

    function selectableProductionUnitsForCreateModal() {
        if (independentProductionUnits) {
            return productionUnits;
        } else {
            return selectableProductionUnits;
        }
    }

    function onSupplierIdSelectedByUser(supplierId: string | null) {
        setShowCreateSupplierModal(false);
        setSelectedSupplierId(supplierId);
        if (supplierId && selectedProductionUnitId && relations !== 'loading') {
            const areSupplierAndPULinked = areSupplierAndProductionUnitLinked(supplierId, selectedProductionUnitId, relations);
            const targetProductionUnit = areSupplierAndPULinked ? audit.target_production_unit : null
            doUpdateAudit({
                ...audit,
                target_supplier: {...audit.target_supplier, id: supplierId},
                target_production_unit: targetProductionUnit
            });
            setSelectedProductionUnitId(targetProductionUnit ? targetProductionUnit.id : null);
        }
    }

    function onProductionUnitIdSelected(productionUnitId: string | null) {
        setSelectedProductionUnitId(productionUnitId);
        setShowCreateProductionUnitModal(false)

        if (selectedSupplierId && productionUnitId && relations !== 'loading') {
            const areSupplierAndPULinked = areSupplierAndProductionUnitLinked(selectedSupplierId, productionUnitId, relations);
            const targetSupplier = areSupplierAndPULinked ? audit.target_supplier : null

            doUpdateAudit({
                ...audit,
                target_production_unit: {...audit.target_production_unit, id: productionUnitId},
                target_supplier: targetSupplier
            });
            setSelectedSupplierId(targetSupplier ? targetSupplier.id : null);
        }
    }

    function isSupplierIdMissing() {
        if (independentProductionUnits) {
            return false
        } else {
            return !selectedSupplierId
        }
    }

    function isSupplierInputDisabled() {
        return isAuditImmutable || !userHasEditFeature;
    }

    function isProductionUnitSelectedDisabled() {
        return isAuditImmutable || !userHasEditFeature || isSupplierIdMissing();
    }

    function doUpdateAudit(audit: Audit) {
        dispatch(updateAudit({audit}));
    }

    function goToSupplier() {
        const supplierUrl = document.location.origin.concat("/q/app/suppliers/" + selectedSupplierId)
        window.open(supplierUrl, '_blank');
    }

    function goToProductionUnit() {
        const productionUnitUrl = document.location.origin.concat("/q/app/production_units/" + selectedProductionUnitId)
        window.open(productionUnitUrl, '_blank');
    }

    function renderCreateSupplierModalNote() {
        if (selectedProductionUnitId) {
            return (
                <span className={'pa-2'}>{
                    intl.formatMessage(
                        { id: 'suppliers.create_linked_to_production_unit' },
                        { productionUnitName: productionUnitName() }
                    )
                }</span>
            )
        } else {
            return null;
        }
    }

    function renderCreateProductionUnitNote() {
        if (selectedSupplierId) {
            return (
                <span className={'pa-2'}>{
                    intl.formatMessage(
                        { id: 'production_units.create_linked_to_supplier' },
                        { supplierName: supplierName() }
                    )
                }</span>
            )
        } else {
            return null;
        }
    }

    function supplierName(): string | undefined {
        const supplier = findSelectedSupplier()
        if (supplier) {
            return getSupplierNameAndNumber(supplier.name,  supplier.number)
        } else if (audit?.target_supplier?.name) {
            return getSupplierNameAndNumber(audit?.target_supplier?.name, audit?.target_supplier?.number)

        }
    }

    function findSelectedSupplier(): DetailedSupplier | undefined {
        if (!selectedSupplierId) {
            return;
        }

        return selectableSuppliers.find(sup => sup.id === selectedSupplierId);
    }

    function productionUnitName() {
        const pu = findSelectedProductionUnit()
        if (pu) {
            return pu.name
        } else if (audit?.target_production_unit?.name) {
            return audit?.target_production_unit?.name;
        }
    }

    function findSelectedProductionUnit() {
        if (!selectedProductionUnitId) {
            return;
        }
        return selectableProductionUnits.find(pu => pu.id === selectedProductionUnitId)
    }

    function onUserWantedToCreateNewSupplier(params) {
        setIsCreatingPuOrSupplier(true)
        setShowCreateSupplierModal(false)

        /*
            We can use the updated version, because this can only happen, 
            when independent production units are enabled.
        */
        dispatch(createLegacySupplier({
            name: params.name,
            number: params.number,
            groups: params.groups,
            deleted: false,
            users: undefined,
            features: undefined,
            id: undefined,
            primary_group: undefined
        }))
            .then((response) => {
                const supplierId = response.payload.id

                if (selectedProductionUnitId) {
                    return dispatch(
                        linkProductionUnitAndSupplier({
                            production_unit_id: selectedProductionUnitId,
                            supplier_id: supplierId
                        })
                    ).then(() => {
                        setSelectedSupplierId(supplierId);
                        setSelectedProductionUnitId(selectedProductionUnitId);
                        doUpdateAudit({
                            ...audit,
                            target_supplier: {
                                ...audit?.target_supplier,
                                id: supplierId
                            },
                            target_production_unit: {
                                ...audit?.target_production_unit,
                                id: selectedProductionUnitId
                            }
                        })
                    })
                } else {
                    doUpdateAudit({
                        ...audit,
                        target_supplier: {
                            ...audit?.target_supplier,
                            id: supplierId
                        }
                    })
                    setSelectedSupplierId(supplierId);
                }
            })
            .finally(() => {
                setIsCreatingPuOrSupplier(false)
            })
    }

    function onUserWantedToCreateNewProductionUnit(params: NewProductionUnitParams) {
        setIsCreatingPuOrSupplier(true);

        const sharedParams: CreateProductionUnitBody = {
            production_unit_number: params.number,
            production_unit_name: params.name,
            production_unit_address: [],
            production_unit_group_ids: (params.groups || []).map(g => g.id)
        }

        if (selectedSupplierId) {
            const createWithSupplierParams = {
                ...sharedParams,
                supplier_id: selectedSupplierId
            }

            dispatch(createProductionUnitWithSupplier(createWithSupplierParams))
                .then((result) => {
                    setSelectedSupplierId(selectedSupplierId);
                    setSelectedProductionUnitId(result.payload.id);

                    doUpdateAudit({
                        ...audit,
                        target_supplier: {
                            ...audit?.target_supplier,
                            id: selectedSupplierId
                        },
                        target_production_unit: {
                            ...audit?.target_production_unit,
                            id: result.payload.id
                        }
                    })
                })
                .finally(() => setIsCreatingPuOrSupplier(false))
        } else {
            // Create independent of supplier
            dispatch(createProductionUnitWithoutSupplier(sharedParams))
                .then(result => {
                    doUpdateAudit({
                        ...audit,
                        target_production_unit: {
                            ...audit?.target_production_unit,
                            id: result.payload.id
                        }
                    })
                    setSelectedProductionUnitId(result.payload.id);
                    setSelectedSupplierId(null);
                })
                .finally(() => setIsCreatingPuOrSupplier(false))
        }
    }

    return (
        <div className="supplier-and-production-unit border bg-white py-4 px-4 rounded mb-2">
            <div className='text-lg font-bold mb-4'><FormattedMessage id='audits.edit.supplier_and_production_unit'/></div>
            <FormField>
                <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                    <label className='text-sm text-secondary' htmlFor="audits-add-supplier"><FormattedMessage id='audit_list.header.supplier'/></label>
                    {
                        selectedSupplierId &&
                        <button onClick={goToSupplier} className='flex flex-row justify-between items-center text-link-color py-1 cursor-pointer rounded'>
                            <span className='text-sm'><FormattedMessage id='audits.edit.view_supplier'/></span>
                            <Icon name='open_in_new' className='text-sm'/>
                        </button>
                    }
                </div>
                <div className='w-full sm:w-2/3'>
                    <ControlledSupplierSelector
                        loading={loadingSupplierRelatedData}
                        nonMatchingSuppliers={otherSuppliers}
                        onUserWantsToCreateNewSupplier={() => setShowCreateSupplierModal(true)}
                        suppliers={selectableSuppliers}
                        supplierId={selectedSupplierId}
                        onSupplierIdSelected={onSupplierIdSelectedByUser}
                        disabled={isSupplierInputDisabled()}
                        dataTestId='audits-add-supplier'
                    />
                </div>
                <HasFeatureAccess feature='u_add_suppliers'>
                    <CreateSupplierModal
                        renderNote={renderCreateSupplierModalNote}
                        onUserWantedToCreateSupplier={onUserWantedToCreateNewSupplier}
                        open={showCreateSupplierModal}
                        onModalClosed={() => setShowCreateSupplierModal(false)}
                        onExistingSupplierIdSelected={onSupplierIdSelectedByUser}
                        renderButton={() => null} />
                </HasFeatureAccess>
            </FormField>
            <FormField className='mt-4'>
                <div className='flex flex-row justify-between items-center w-full sm:w-2/3 mb-1'>
                    <label className='text-sm text-secondary'><FormattedMessage id='audit_list.header.production_unit'/></label>
                    {
                        selectedProductionUnitId &&
                        <button onClick={goToProductionUnit} className='flex flex-row justify-between items-center text-link-color py-1 cursor-pointer rounded'>
                            <span className='text-sm'><FormattedMessage id='audits.edit.view_production_unit'/></span>
                            <Icon name='open_in_new' className='text-sm'/>
                        </button>
                    }
                </div>
                <div className='w-full sm:w-2/3'>
                    <ControlledProductionUnitSelector
                        loading={loadingSupplierRelatedData}
                        disableUnassignedOption={false}
                        nonMatchingProductionUnits={otherProductionUnits}
                        productionUnitId={selectedProductionUnitId}
                        productionUnits={selectableProductionUnits}
                        onUserWantedToCreateNewProductionUnit={() => setShowCreateProductionUnitModal(true)}
                        onProductionUnitIdSelected={onProductionUnitIdSelected}
                        disabled={isProductionUnitSelectedDisabled()}
                        dataTestId='audits-add-production-unit'
                    />
                    <CreateProductionUnitModal
                        groupsEnabled={true}
                        open={showCreateProductionUnitModal}
                        allowExistingProductionUnits={true}
                        onExistingProductionUnitSelected={onProductionUnitIdSelected}
                        onUserWantedToCreateProductionUnit={onUserWantedToCreateNewProductionUnit}
                        renderButton={() => null}
                        renderNote={renderCreateProductionUnitNote}
                        selectableProductionUnits={selectableProductionUnitsForCreateModal()}
                        onModalClosed={() => {
                            setShowCreateProductionUnitModal(false)
                        }}
                    />
                </div>
            </FormField>
        </div>
    )
}