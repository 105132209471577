import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Header } from 'semantic-ui-react';
import { MAX_UPLOAD_FILE_SIZE, WARN_UPLOAD_FILE_SIZE } from '../../../base/config';
import { useAppDispatch } from '../../../store';
import { FileSelector, WarnBox } from '../../attachments/components';
import Uploading from '../../attachments/components/Uploading';
import { UploadingFile } from '../../attachments/types';
import { AttachmentResponse, uploadImage } from '../actions/actions';
import FileListView from './FileListView';
import { Images } from './Images';

interface FileGalleryProps {
    images: string[];
    disabled: boolean;
    imageRemoved(image: string);
    imagesAdded(attachments: AttachmentResponse[]);
    header?: string;
    fileSelectorLableText?: string;
    allowMultiUpload?: boolean;
    listView?: boolean;
    files?: AttachmentResponse[];
}


const FileGallery = (props: FileGalleryProps): React.ReactElement => {
    const { allowMultiUpload = false } = props;
    const dispatch = useAppDispatch();
    const [filesUploading, setFilesUploading] = useState<UploadingFile[]>([]);
    const [completedFiles, setCompletedFiles] = useState<AttachmentResponse[]>([]);
    const [isUploadComplete, setIsUploadComplete] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([])
    const [showWarn, setShowWarn] = useState<boolean>(false);
    const [warnFiles, setWarnFiles] = useState<File[]>([]);
    const [tooBigFiles, setTooBigFiles] = useState<File[]>([]);
    const [okFiles, setOkFiles] = useState<File[]>([]);
    const [completedFile, setCompletedFile] = useState<AttachmentResponse>(null);

    useEffect(() => {
        if (completedFile) {
            setCompletedFiles([...completedFiles, completedFile]);
        }
    }, [completedFile])

    useEffect(() => {
        if (filesUploading && filesUploading.length > 0) {
            filesUploading.forEach((file: UploadingFile) => {
                uploadFile(file.file);
            });
        }
    }, [filesUploading])

    useEffect(() => {
        if (isUploadComplete) {
            props.imagesAdded(completedFiles);
            clearUploadsHandler();
        }
    }, [isUploadComplete])

    useEffect(() => {
        if (completedFiles.length > 0 && completedFiles.length === filesUploading.length) {
            setIsUploadComplete(true);
        }
    }, [completedFiles])

    const clearUploadsHandler = (): void => {
        setFilesUploading([]);
        setCompletedFiles([]);
        setIsUploadComplete(false);
    }

    const setProgress = (file: File, progress: number): void => {
        const uploadingFile: UploadingFile = filesUploading.find((uploadingFile: UploadingFile) => uploadingFile.file.name === file.name);
        uploadingFile.progress = progress;
    }

    const handleComplete = (error, attachment: AttachmentResponse): void => {
        if (!error) {
            setCompletedFile(attachment);
        }
    }

    const uploadFile = (file: File): void => {
        dispatch(uploadImage(file, setProgress, handleComplete));
    }

    const handleWarning = (proceed: boolean): void => {
        if (proceed) {
            convertAndSetUploadingFiles(Array.from(files));
        } else {
            setFiles([]);
        }
        setShowWarn(false);
    }

    const uploadFiles = (name, files): void => {
        const warnFiles: File[] = [];
        const tooBigFiles: File[] = [];
        const okFiles: File[] = [];
        Array.from(files).forEach((file: File) => {
            if (file.size >= MAX_UPLOAD_FILE_SIZE) {
                tooBigFiles.push(file);
            } else {
                if (file.size >= WARN_UPLOAD_FILE_SIZE) {
                    warnFiles.push(file);
                } else {
                    okFiles.push(file);
                }
            }
        });
        if (warnFiles.length > 0 || tooBigFiles.length > 0) {
            setWarnFiles(warnFiles);
            setTooBigFiles(tooBigFiles);
            setOkFiles(okFiles);
            setFiles(files);
            setShowWarn(true);
        } else {
            convertAndSetUploadingFiles(Array.from(files))
        }
    }

    const convertAndSetUploadingFiles = (files): void => {
        const uploadingFiles: UploadingFile[] = [];
        files.forEach((file) => uploadingFiles.push({ status: 'ok', progress: 0, file: file }));
        setFilesUploading(uploadingFiles);
    }

    const listViewClassName = props.listView ? 'list_view' : ''

    return (
        <span className={['productPhoto ', 'show', listViewClassName].join(' ')}>
            {props.header && <Header as='h4'><FormattedMessage id={props.header} /></Header>}
            {props.listView && <Card>
                <Card.Content>
                    <Uploading
                        files={filesUploading}
                        isComplete={isUploadComplete}
                        clearUploadsHandler={clearUploadsHandler}
                    />
                    <FileSelector
                        updateStateHandler={uploadFiles}
                        disableWhileUploading={true}
                        isComplete={isUploadComplete}
                        label={props.fileSelectorLableText}
                        hasDrop={false}
                        disabled={props.disabled || filesUploading.length > 0}
                        multiple={allowMultiUpload}
                        isUploading={filesUploading.length > 0}
                        inline={true}
                    />
                    <WarnBox
                        tooBigFiles={tooBigFiles}
                        warnFiles={warnFiles}
                        okFiles={okFiles}
                        actionHandler={handleWarning}
                        showWarnBox={showWarn}
                        canRemoveWarnFile={false}
                    />

                </Card.Content>
                <Card.Content>
                    <FileListView
                        files={props.files}
                        delete={(file): void => props.imageRemoved(file.url)}
                    />
                </Card.Content>
            </Card>
            }
            {!props.listView &&
                <Card>
                    <Images
                        image={props.images}
                        removePhoto={props.imageRemoved}
                        disabled={props.disabled}
                        compact={props.listView}
                    />
                    <Card.Content>
                        <Uploading
                            files={filesUploading}
                            isComplete={isUploadComplete}
                            clearUploadsHandler={clearUploadsHandler}
                        />
                        <FileSelector
                            updateStateHandler={uploadFiles}
                            disableWhileUploading={true}
                            isComplete={isUploadComplete}
                            label={props.fileSelectorLableText}
                            hasDrop={false}
                            disabled={props.disabled || filesUploading.length > 0}
                            multiple={allowMultiUpload}
                            isUploading={filesUploading.length > 0}
                            inline={true}
                        />
                        <WarnBox
                            tooBigFiles={tooBigFiles}
                            warnFiles={warnFiles}
                            okFiles={okFiles}
                            actionHandler={handleWarning}
                            showWarnBox={showWarn}
                            canRemoveWarnFile={false}
                        />
                    </Card.Content>
                </Card>
            }
        </span>
    );
}

export default FileGallery;