import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Segment } from 'semantic-ui-react';
import errorHandling from '../../modules/errorHandling';
import Button from './basic/Button';

const catchException = errorHandling.handler.catchException;

class ErrorBoundary extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    public componentDidCatch(error, info): void {
        // Display fallback UI
        this.setState({ hasError: true, error, errorInfo: info });
        // Send error to Sentry
        catchException('Global error', {
            endpoint: error.message,
            request: '',
            status: error.status,
        }, { error, info });

    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <div className='pageContainer'>
                    <h1><Icon name='exclamation circle' color='red'/><FormattedMessage id='base.an_error_occured' /></h1>                        
                    <Segment className='errorBoundary'>
                        <p><FormattedMessage id='base.there_was_a_problem_with_qarma_a_notification_has_been_sent_to_qarma_support_please_try_to_refresh_the_page' /></p>
                        <p>Please try to reload the page to see if the problem goes away.</p>
                        <p><Button className='py-2 px-4' primary  onClick={() => window.location.reload()}>Reload</Button></p>
                        <p><FormattedMessage id='base.if_the_problem_continues_please_contact' /><a href='mailto:support@qarmainspect.com'><FormattedMessage id='base.qarma_support' /></a>.</p>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </Segment>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
