/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a string that represents a date.
 *
 */
export type SetCustomFieldDate = {
    date: string;
    type: SetCustomFieldDate.type;
};

export namespace SetCustomFieldDate {

    export enum type {
        DATE = 'date',
    }


}

