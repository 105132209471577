import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Divider, Form, Grid, Header, Icon, InputProps, Loader, Popup, Segment } from 'semantic-ui-react';
import { AppThunk } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { Attachments } from '../../../attachments/containers';
import { getInspectionChecklistsSelector } from '../../../checklists/selectors';
import { LineTogglerSmall } from '../../../globals/components/Utils';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { Groups } from '../../../groups/components';
import { clearAllMessages } from '../../../messages/actions';
import { setCurrentSamplingProtocol } from '../../../samplingProtocols/slices/samplingProtocolsSlice';
import EditInspectionSupplier from '../../../suppliers/components/EditInspectionSupplier';
import { getOrderDefaultDate, isOrderCombinedInspection, orderOnlyHasPlannedInspections } from '../../Utils';
import { areAllInspectionsEqual, clearOrderWithInspections, datePickerChange, handleBlurredInspection, handleFocussedInspection, handleAction as handleInspectionAction, handleChange as handleInspectionChange, initializeEditInspection, saveOrder } from '../../actions/editInspectionActions';
import { getEditInspectionDataReady, isCreatingOrderSelector } from '../../editInspectionSelectors';
import { getFetching, getOrderWithInspections } from '../../selectors/selectors';
import { getEditInspectionFetchingSelector } from '../../slices/editInspectionsSlice';
import { Inspection } from '../../types';
import CreateOrder from '../create/CreateOrder';
import Delete from '../report/globalActions/Delete';
import { AddInspectionButtons } from './AddInspectionButtons';
import DuplicateCombinedInspectionInfo from './DuplicateCombinedInspectionInfo';
import DuplicateCombinedInspectionTimeoutInfo from './DuplicateCombinedInspectionTimeoutInfo';
import IL from './IL';
import { InfolineHeader } from './InfolineHeader';
import OrderInfo from './OrderInfo';
import Assign from './assign/Assign2';

type EditInspectionProps = {
    orderId?: string;
};

const EditInspection = (props: EditInspectionProps): React.ReactElement => {
    const { orderId } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const intl = useIntl();
    // const order = useSelector(getOrderSelector);
    const order = useSelector(getOrderWithInspections);
    const isCreatingOrder = useSelector(isCreatingOrderSelector);
    const checklists = useSelector(getInspectionChecklistsSelector);
    const eiReady = useSelector(getEditInspectionDataReady);
    const hasInspectionTypes = true;
    const isFetchingEI= useSelector(getEditInspectionFetchingSelector);
    const isFetching = useSelector(getFetching) || isFetchingEI || !eiReady;
    const [elementsOpen, setElementsOpen] = useState([]);
    const [isCombinedInspection, setIsCombinedInspection] = useState(false);
    const orderOpen = elementsOpen.includes(orderId);
    const isSaving = false;

    const handleBlur = (event: React.SyntheticEvent, item: InputProps, inspectionId: string): AppThunk => dispatch(handleBlurredInspection(event, item, inspectionId));
    const handleFocus = (evt: React.SyntheticEvent, inspectionId: string): AppThunk => dispatch(handleFocussedInspection(evt, inspectionId));
    const handleChange = (event: React.SyntheticEvent, item: InputProps, inspectionId?: string): AppThunk => dispatch(handleInspectionChange(event, item, inspectionId));
    const handleDatePickerChange = (dateObj: moment.Moment, item: 'etd' | 'scheduled_inspection_date'): AppThunk => dispatch(datePickerChange(dateObj, item));
    const handleAction = (item, inspectionId: string): AppThunk => dispatch(handleInspectionAction(item, inspectionId));

    const toggleElements = (show: boolean, id: string): void => {
        const open = [...elementsOpen];
        if (show) {
            open.push(id);
        } else {
            const index = open.indexOf(id);
            open.splice(index, 1);
        }
        setElementsOpen(open);
    };

    const lineTogglerOrder = <LineTogglerSmall
        data={{ open: orderOpen }}
        id={orderId}
        toggle={toggleElements}
    />;

    const loader = <Dimmer active={isFetching} inverted>
        <Loader className='dimLoader'><FormattedMessage
            id='edit_inspection.getting_data_please_wait' /></Loader>
    </Dimmer>;

    useEffect(() => {
        return () => {
            dispatch(clearAllMessages());
        }
    }, []);
    useEffect(() => {
        dispatch(initializeEditInspection(orderId));
        return () => {
            dispatch(clearOrderWithInspections());
        }
    }, [orderId]);

    useEffect(() => {
        if (order && order.inspections) {
            dispatch(areAllInspectionsEqual());
            dispatch(setCurrentSamplingProtocol(order.sampling_protocol))
            setIsCombinedInspection(isOrderCombinedInspection(order));
        }
    }, [order && order.inspections]);
    const renderEI = (): React.ReactElement => {
        if (isCreatingOrder) {
            window.scrollTo(0, 0);
            if (history.action === 'POP') {
                history.push('/inspections');
            }
            return <CreateOrder />;
        }
        if (order && eiReady) {
            const inspections = isCombinedInspection ? order.inspections[0].sub_inspections : order.inspections;
            const defaultDate = getOrderDefaultDate(order);
            const pageTitle = order.order_number ? 'PO ' + order.order_number + '. ' + intl.formatMessage({ id: 'page_title.edit_inspections' }) : intl.formatMessage({ id: 'page_title.edit_inspections' });
            const disableOrderLevelEditing = isFetching || !orderOnlyHasPlannedInspections(order.inspections);
            const bookingOrderId = isCombinedInspection ? order.inspections[0] && order.inspections[0].sub_inspections[0] && order.inspections[0].sub_inspections[0].order_id : order.order_id
            return <div className='page-container'>
                {loader}
                <DocumentTitle title={pageTitle} />
                <h2 className='md:pl-2 xl:pl-0'>
                    {orderId !== '' ?
                        (<FormattedMessage id={isCombinedInspection ? 'edit_combined_inspection.header' : 'edit_inspection.header'} />)
                        :
                        (<FormattedMessage id='create_inspection.header' />)
                    }
                </h2>
                <Segment.Group raised container>
                    <Segment secondary>
                        <Grid>
                            <Grid.Column width={16} verticalAlign="middle"><Header as='h3'>
                                <FormattedMessage id='edit_inspection.info_header' />
                            </Header>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment>
                        <HasFeatureAccess type='order' feature='manage_attachments' order={order} showDisabled={true} showDialog={false}>
                            <Attachments
                                attachToType='orders'
                                attachToItemId={orderId}
                                visible={orderOpen}
                                toggle={toggleElements}
                            >
                                <OrderInfo
                                    order={order}
                                    defaultDate={defaultDate}
                                    disabled={disableOrderLevelEditing}
                                    handleBlur={handleBlur}
                                    handleFocus={handleFocus}
                                    handleAction={handleAction}
                                    handleDatePickerChange={handleDatePickerChange}
                                    isCombinedInspection={isCombinedInspection}
                                    lineToggler={lineTogglerOrder}
                                />
                                {orderOpen &&
                                    <Form.Field className='orderLine'>
                                        <HasFeatureAccess feature='u_change_groups_on_orders' showDisabled={true}>
                                            <Groups
                                                selectedGroups={order.groups}
                                                handleChange={handleChange}
                                            />
                                        </HasFeatureAccess>
                                        <Divider hidden />
                                    </Form.Field>}
                            </Attachments>
                        </HasFeatureAccess>
                    </Segment>
                </Segment.Group>
                <Segment.Group raised>
                    <Segment secondary>
                        <Header as='h3'>
                            <FormattedMessage id='edit_inspection.item_info_checklist_header' />
                        </Header>
                    </Segment>
                    <Segment>
                        <InfolineHeader
                            isCombinedInspection={isCombinedInspection}
                            hasInspectionTypes={hasInspectionTypes}
                        />
                        <Fragment>
                            {inspections.map((inspectionFromMap: Inspection, i: number) => {
                                return <IL
                                    checklists={checklists}
                                    key={'IL__' + i + inspectionFromMap.inspection_id}
                                    inspection={{...inspectionFromMap}}
                                    isCombinedInspection={isCombinedInspection}
                                    hasInspectionTypes={hasInspectionTypes}
                                    isSaving={isSaving}
                                    isLastRemaining={order.inspections.length === 1}
                                    disabled={isFetching}
                                />
                            })}
                            {<HasFeatureAccess feature='u_create_order_and_inspection'>
                                <AddInspectionButtons
                                    isCombinedInspection={isCombinedInspection}
                                    orderId={order.order_id}
                                />
                            </HasFeatureAccess>}
                        </Fragment>
                    </Segment>
                </Segment.Group>
                <Assign
                    inspections={order.inspections}
                    isCombinedInspection={isCombinedInspection}
                    intl={intl}
                />
                {!isCombinedInspection &&
                    <EditInspectionSupplier
                        order={order}
                        orderId={order.order_id}
                        supplierId={order.inspections[0].supplier_entity && order.inspections[0].supplier_entity.id}
                        supplierEntity={order.inspections[0].supplier_entity}
                        linkedProductionUnitId={order.inspections[0].linked_production_unit && order.inspections[0].linked_production_unit.id}
                        linkedProductionUnit={order.inspections[0].linked_production_unit}
                        disabled={disableOrderLevelEditing}
                    />
                }
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Popup
                            position='top right'
                            trigger={<Button positive data-test-id='save_order_button'
                                onClick={(): AppThunk => dispatch(saveOrder(false))}
                                disabled={isFetching || isSaving}><FormattedMessage id='edit.save' /></Button>}
                        ><Icon name='info circle' color='blue' /><FormattedMessage id='inspections.save_order_without_notifying_inspector' />
                        </Popup>
                        <Popup
                            position='top center'
                            trigger={<Button positive
                                onClick={(): AppThunk => dispatch(saveOrder(true))}
                                disabled={isFetching || isSaving}><FormattedMessage id='inspections.save_and_notify_inspector' /></Button>}
                        ><Icon name='info circle' color='blue' /><FormattedMessage id='inspections.save_order_and_notify_the_inspector_that_the_order_is_ready_for_inspection' />
                        </Popup>
                        {isCombinedInspection && <HasFeatureAccess feature='u_combine_split_inspections'>
                            <Delete
                                order={order}
                                isCombinedInspection={true}
                            />
                        </HasFeatureAccess>}
                        {!isCombinedInspection && <HasFeatureAccess feature='delete' type='order' order={order}>
                            <Delete
                                order={order}
                                isCombinedInspection={false}
                            />
                        </HasFeatureAccess>}
                    </div>
                    <HasFeatureAccess feature="u_manage_booking_info">
                        <Link className='link noUnderline' to={'/booking/' + bookingOrderId}><FormattedMessage id='inspections.goto_bookings' /></Link>
                    </HasFeatureAccess>
                </div> 
                <DuplicateCombinedInspectionTimeoutInfo />               
                <DuplicateCombinedInspectionInfo />               
            </div>
        }
        return <div className='page-container'>{loader}</div>;
    }
    return renderEI();
};

export default EditInspection;
