export const EXPORT_INSPECTIONS_AS_EXCEL_REQUEST = 'EXPORT_INSPECTIONS_AS_EXCEL_REQUEST';
export type EXPORT_INSPECTIONS_AS_EXCEL_REQUEST = {
    startDate: string;
    endDate: string;
    isSaving: boolean;
};
export const EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS = 'EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS';
export type EXPORT_INSPECTIONS_AS_EXCEL_SUCCESS = {
    data: unknown;
    isSaving: boolean;

};
export const EXPORT_INSPECTIONS_AS_EXCEL_FAILURE = 'EXPORT_INSPECTIONS_AS_EXCEL_FAILURE';
export type EXPORT_INSPECTIONS_AS_EXCEL_FAILURE = {
    status: number;
    error: string;
    isSaving: boolean;
};

export const EXPORT_EXCEL_SAVING_STATE = 'EXPORT_EXCEL_SAVING_STATE';
export type EXPORT_EXCEL_SAVING_STATE = {
    isSaving: boolean;
};

export type StatisticsState = {
    isSaving: boolean;
};
