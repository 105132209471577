import React, { } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'semantic-ui-react';
import { Group, ProductionUnit } from '../../../backend_api/models';
import { NewProductionUnitInputsWithHints } from './NewProductionUnitInputsWithHints';
import { NewProductionUnitParams } from './NewProductionUnitParams';
import { NewProductionUnitInputsWithoutHints } from './NewProductionUnitInputsWithoutHints';


export function CreateProductionUnitModal(props: {
    renderButton(): React.ReactNode,
    renderNote?(): React.ReactNode,
    open?: boolean,
    onModalClosed?: () => void,
    onModalOpened?: () => void,
    onExistingProductionUnitSelected(productionUnitId: string): void,
    onUserWantedToCreateProductionUnit(params: NewProductionUnitParams): void,
    /* Configurable from the outside. 
       For suppliers, only production units from that supplier make sense, 
       unless we have independent production units */
    selectableProductionUnits: ProductionUnit[],
    allowExistingProductionUnits: boolean,
    groupsEnabled: boolean,
}) {
    const [modalVisible, setModalVisible] = React.useState(false)
    const visiblityOverride = props.open === true || props.open === false ? props.open : modalVisible

    const [groupsInput, setGroupsInput] = React.useState<Group[]>([]);
    const [nameInput, setNameInput] = React.useState('');
    const [numberInput, setNumberInput] = React.useState('');

    function notifyClosed() {
        if (props.onModalClosed) {
            props.onModalClosed()
        }
    }

    function notifyOpened() {
        if (props.onModalOpened) {
            props.onModalOpened()
        }
    }

    function onClosed() {
        notifyClosed()
        resetInputs()
    }

    function onOpened() {
        setModalVisible(true);
        notifyOpened();
    }

    function onUserWantsToCreate() {
        props.onUserWantedToCreateProductionUnit({
            groups: props.groupsEnabled ? groupsInput : [],
            name: nameInput,
            number: numberInput
        })
        notifyClosed()
        resetInputs()
    }

    function isValidInput() {
        return nameInput && nameInput.length > 0;
    }

    function resetInputs() {
        setModalVisible(false);
        setGroupsInput([])
        setNameInput(null)
        setNumberInput(null)
    }

    function renderNewProductionUnitInputs() {
        if (props.allowExistingProductionUnits) {
            return (
                <NewProductionUnitInputsWithHints
                    groupsEnabled={props.groupsEnabled}
                    onGroupsChanged={setGroupsInput}
                    onExistingProductionUnitIdSelected={props.onExistingProductionUnitSelected}
                    onProductionUnitNameChanged={setNameInput}
                    onProductionUnitNumberChanged={setNumberInput}
                    groupsInput={groupsInput}
                    productionUnitNameInput={nameInput}
                    productionUnitNumberInput={numberInput}
                    selectableProductionUnits={props.selectableProductionUnits}
                />
            )
        } else {
            return (
                <NewProductionUnitInputsWithoutHints
                    groupsEnabled={props.groupsEnabled}
                    onGroupsChanged={setGroupsInput}
                    groupsInput={groupsInput}
                    onProductionUnitNameChanged={setNameInput}
                    onProductionUnitNumberChanged={setNumberInput}
                    productionUnitNameInput={nameInput}
                    productionUnitNumberInput={numberInput}
                />
            )
        }
    }

    return (
        <Modal
            closeOnEscape
            closeIcon
            onClose={onClosed}
            onOpen={onOpened}
            open={visiblityOverride}
            trigger={props.renderButton()}
        >
            <Modal.Header>
                <FormattedMessage id='production_units.create_production_unit' />
            </Modal.Header>
            <Modal.Content className='space-y-8'>
                {renderNewProductionUnitInputs()}
                {/* {props.renderNote && props.renderNote()} */}
            </Modal.Content>
            <Modal.Actions>
                <Button data-test-id='button-create-audit-save'
                    primary
                    disabled={!isValidInput()}
                    onClick={onUserWantsToCreate}>
                    <FormattedMessage id='globals.ok' />
                </Button>
                <Button onClick={onClosed}>
                    <FormattedMessage id='globals.cancel' />
                </Button>
            </Modal.Actions>
        </Modal>
    )

}