/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 81.0.7 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditReportCustomFieldsText
 */
export interface AuditReportCustomFieldsText {
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsText
     */
    previous_text: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsText
     */
    text: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditReportCustomFieldsText
     */
    type: AuditReportCustomFieldsTextTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AuditReportCustomFieldsTextTypeEnum {
    Text = 'Text'
}

export function AuditReportCustomFieldsTextFromJSON(json: any): AuditReportCustomFieldsText {
    return AuditReportCustomFieldsTextFromJSONTyped(json, false);
}

export function AuditReportCustomFieldsTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditReportCustomFieldsText {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'previous_text': json['previous_text'],
        'text': json['text'],
        'type': json['type'],
    };
}

export function AuditReportCustomFieldsTextToJSON(value?: AuditReportCustomFieldsText | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'previous_text': value.previous_text,
        'text': value.text,
        'type': value.type,
    };
}


