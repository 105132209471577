import { AuditChecklistHeader, AuditChecklistWithContent, AuditCheckpoint, AuditCustomFieldDate, AuditCustomFieldDateValue, AuditCustomFieldMultipleChoiceValue, AuditCustomFieldNumber, AuditCustomFieldNumberValue, AuditCustomFieldOptionIds, AuditCustomFieldText, AuditCustomFieldTextValue, AuditCustomFieldValue } from "../../../../backend_api_2";

export function doesAuditHaveAnyMandatoryCheckpoints(checklist: AuditChecklistWithContent): boolean {
    return checklist?.headers.some(doesChecklistHeaderHaveAnyMandatoryCheckpoints);
}

export function doesChecklistHeaderHaveAnyMandatoryCheckpoints(checklistHeader: AuditChecklistHeader): boolean {
    return checklistHeader?.checkpoints.some(doesCheckpointRequiresInputOrMedia);
}

export function doesCheckpointRequiresInputOrMedia(checkpoint: AuditCheckpoint): boolean {
    return doesCheckpointRequireMandatoryInput(checkpoint) || doesCheckpointRequireMandatoryMedia(checkpoint);
}

export function doesCheckpointRequireMandatoryInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE) {
        return false;
    }
    if(!checkpoint.mandatory) {
        return false;
    }

    if(checkpoint.mandatory) {
        return doesCheckpointRequireStatus(checkpoint)
        || doesTextInputCheckpointRequireInput(checkpoint)
        || doesTextTableCheckpointRequireInput(checkpoint)
        || doesMultipleChoiceCheckpointRequireInput(checkpoint)
        || doesPUCustomFieldCheckpointRequireInput(checkpoint)
        || doesSupplierCustomFieldCheckpointRequireInput(checkpoint)
    }

    return false;
}

function doesCheckpointRequireMandatoryMedia(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE) {
        return false;
    }
    if (checkpoint.is_picture_mandatory) {
        return checkpoint.observed_file_resources.length == 0;
    }

    return false;
}

function doesCheckpointRequireStatus(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.status_enabled) {
        return checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.EMPTY;
    }

    return false;
}

export function doesTextInputCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.TEXT_INPUTS 
        && !!checkpoint.text_inputs
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.text_inputs?.inputs.every(input => {
            const value = input.value;
            return value == undefined || value == null || value == "";
        });
    }
    return false;
}

export function doesTextInputCheckpointHaveInput(checkpoint: AuditCheckpoint): boolean {
    return checkpoint.text_inputs?.inputs?.some(input => {
        const value = input.value;
        return value != undefined && value == null && value == "";
    });
}

export function doesTextTableCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.TEXT_TABLE 
        && !!checkpoint.text_table 
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.text_table?.values.every(v => {
            const value = v.value;
            return value == undefined || value == null || value == "";
        });
    }
    return false;
}

export function doesTextTableCheckpointHaveInput(checkpoint: AuditCheckpoint): boolean {
    return checkpoint.text_table?.values?.some(v => {
        const value = v.value;
        return value == undefined && value == null && value == "";
    });
}

export function doesMultipleChoiceCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE 
        && !!checkpoint.multiple_choice
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.multiple_choice?.options.every(option => !option.chosen);
    }
    return false;
}

export function doesPUCustomFieldCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.production_unit_custom_fields 
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.production_unit_custom_fields.some(c => !doesCustomFieldHaveInput(c));
    }
    return false;
}

export function doesSupplierCustomFieldCheckpointRequireInput(checkpoint: AuditCheckpoint): boolean {
    if(checkpoint.supplier_custom_fields 
        && checkpoint.checkpoint_status != AuditCheckpoint.checkpoint_status.NOT_APPLICABLE
        && checkpoint.mandatory) {
        return checkpoint.supplier_custom_fields.some(c => !doesCustomFieldHaveInput(c));
    }
    return false;
}

function doesCustomFieldHaveInput(customField: AuditCustomFieldValue): boolean {
    return doesCustomFieldHaveTextInput(customField) 
    && doesCustomFieldHaveNumberInput(customField)
    && doesCustomFieldHaveDateInput(customField)
    && doesCustomFieldHaveMultipleChoiceInput(customField)
}

function isTextCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type == AuditCustomFieldTextValue.type.TEXT;
}
function doesCustomFieldHaveTextInput(customField: AuditCustomFieldValue): boolean {
    if(isTextCustomField(customField)) {
        const value = (customField?.value?.new_value as AuditCustomFieldText)?.text;
        return value !== undefined && value !== null && value !== "";
    }
    return true;
}

function isNumberCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type == AuditCustomFieldNumberValue.type.NUMBER;
}
function doesCustomFieldHaveNumberInput(customField: AuditCustomFieldValue): boolean {
    if(isNumberCustomField(customField)) {
        const value = (customField?.value?.new_value as AuditCustomFieldNumber)?.number;
        return value !== undefined && value !== null && value !== "";
    }
    return true;
}

function isDateCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type == AuditCustomFieldDateValue.type.DATE;
}
function doesCustomFieldHaveDateInput(customField: AuditCustomFieldValue): boolean {
    if(isDateCustomField(customField)) {
        const value = (customField?.value?.new_value as AuditCustomFieldDate)?.date;
        return value !== undefined && value !== null && value !== "";
    }
    return true;
}

function isMultipleChoiceCustomField(customField: AuditCustomFieldValue): boolean {
    return customField?.value?.type == AuditCustomFieldMultipleChoiceValue.type.MULTIPLE_CHOICE;
}
function doesCustomFieldHaveMultipleChoiceInput(customField: AuditCustomFieldValue): boolean {
    if(isMultipleChoiceCustomField(customField)) {
        const value = (customField?.value?.new_value as AuditCustomFieldOptionIds)?.option_ids;
        return value !== undefined && value !== null && value.length > 0;
    }
    return true;
}

