import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useIntl } from 'react-intl';
import { getMoment } from '../../../base/utils';

export type DatePickerProps = {
    date?: Date | string;
    key?: string;
    handleChange(date: moment.Moment, props?: DatePickerProps): void;
    pastDates?: boolean;
    placeholderKey?: string;
    name?: string;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    didfocus?: boolean;
    autoFocus?: boolean;
    openDirection?: 'up' | 'down';
    didFocus?(): void;
    handleBlur?(): void;
}

const DatePicker = (props: DatePickerProps): React.ReactElement => {
    const defaultFormat = 'MMM Do, YYYY'; // TODO: localise display format
    const intl = useIntl();
    const [focused, setFocused] = useState(props.autoFocus);
    const [dateCache, setDateCache] = useState(props.date && getMoment(props.date));

    const { pastDates = false, className } = props;
    let placeholderLabel = intl.formatMessage({ id: 'inspections.select_date' });
    if (props.placeholderKey) {
        placeholderLabel = intl.formatMessage({ id: props.placeholderKey });
    }

    const onDateChange = (date: moment.Moment): void => {
        props.handleChange(date, props);
    };
    const onFocusChange = ({ focused }: { focused: boolean }): void => {
        setFocused(focused)
        // TODO: FIXME: didFocus() && handleBlur() are specific to the EditInspection module - should be handled differently
        if (focused && props.didFocus) {
            props.didFocus();
        } else if (props.handleBlur) {
            props.handleBlur();
        }
    };

    const onClick = () => {
        if (!props.disabled) {
            onFocusChange({ focused: true })
        }
    }

    const pickerProps = {
        date: dateCache,
        focused: focused,
        onDateChange: onDateChange,
        onFocusChange: onFocusChange,
        displayFormat: defaultFormat,
        showDefaultInputIcon: true,
        hideKeyboardShortcutsPanel: true,
        placeholder: placeholderLabel,
        openDirection: props.openDirection || 'down',
        isDayHighlighted: (d: moment.Moment): boolean => {
            return d.isSame(props.date);
        },
        disabled: props.disabled,
        readOnly: props.readOnly,
        isOutsideRange: undefined,
    };

    if (pastDates) {
        pickerProps.isOutsideRange = (): boolean => false;
    }
    useEffect(() => {
        if (intl.locale) {
            moment.locale(intl.locale);
        }
    }, []);

    useEffect(() => {
        if (new Date(props.date).getTime() !== 0) {
            setDateCache(props.date && getMoment(props.date));
        }else {
            setDateCache(null)
        }
    }, [props.date]);
    useEffect(() => {
        setDateCache(props.date && getMoment(props.date));
    }, [intl.locale]);

    return (<div onClick={onClick} className={className}><SingleDatePicker showClearDate key={props.key}
        {...pickerProps}
    /></div>);
};
export default DatePicker;
