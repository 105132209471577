import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseComponent from '../../../base/components/BaseComponent';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import { isUploadComplete } from '../../attachments/components/handlers';
import attachments from '../../attachments/index';
import { addCommentAttachment, removeCommentAttachment as removeAttachment } from '../actions';

const FileSelector = attachments.components.FileSelector;
const Uploading = attachments.components.Uploading;
const WarnBox = attachments.components.WarnBox;
const attachmentsState = attachments.constants.attachmentsInitialState;
const handlers = attachments.handlers;

interface AddCommentAttachmentProps {
    attachedToId: string;
    attachedToType: string;
    image: string;
    visible: boolean;
    disabled: boolean;
    parent?: string;
    autoclear?: boolean;
    uploadedItem?: any;
    children?: React.ReactNode;
    actions: {
        addCommentAttachment(attachedToType: string, attachedToId: string, imageFile: File);
        removeAttachment(attachedToId: string, attachmentUrl: string);
        clearUploads(attachedToId: string, attachedToType: string, clearPrevious?: boolean);
    };
}

class AddCommentAttachmentInner extends BaseComponent<AddCommentAttachmentProps & WrappedComponentProps, any> {
    public constructor(props) {
        super(props);
        const ts = { foo: 1, uploading: [], uploadBegin: false };
        this.state = { ...attachmentsState, ts };
        this.updateUploadState = this.updateUploadState.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.clearUploadsHandler = this.clearUploadsHandler.bind(this);
        this.isUploadComplete = this.isUploadComplete.bind(this);
        this.handleWarning = this.handleWarning.bind(this);
        this.removeAttachmentHandler = this.removeAttachmentHandler.bind(this);
    }

    public doRender(): React.ReactElement {
        const label = this.props.intl.formatMessage({ id: 'attachments.attach_files' });
        return (
            <span className={['addCommentAttachment', this.props.visible ? 'show' : 'show'].join(' ')}>
                {this.state.uploadBegin && <span><FormattedMessage id='comments.please_wait' /></span>}
                {this.props.children}

                <FileSelector
                    uploadFiles={this.uploadFiles}
                    updateStateHandler={this.updateUploadState}
                    disableWhileUploading={true}
                    isComplete={!this.state.uploading.length}
                    label={label}
                    hasIcon={false}
                    hasDrop={false}
                    inline={true}
                    disabled={this.props.disabled}
                    multiple={true}
                    isUploading={this.state.uploading.length > 0}
                    autoClear={false}
                />
                <Uploading
                    files={this.state.uploading}
                    isComplete={this.state.upload.isComplete}
                    clearUploadsHandler={this.clearUploadsHandler}
                    autoClear={false}
                    inline={true}
                    showCompleteMessage={false}
                />
                <AttachmentsList
                    attachments={this.props.uploadedItem}
                    removeAttachment={this.removeAttachmentHandler}
                    disabled={this.props.disabled}
                    showEmpty={false}
                    inline={true}
                />
                <WarnBox
                    tooBigFiles={this.state.upload.tooBigFiles}
                    warnFiles={this.state.upload.warnFiles}
                    okFiles={this.state.upload.okFiles}
                    actionHandler={this.handleWarning}
                    removeWarnFileHandler={this.removeWarnFile}
                    showWarnBox={this.state.upload.showWarn}
                    canRemoveWarnFile={false}
                />
            </span>
        );
    }

    public componentWillReceiveProps(nextProps): void {
        if (nextProps.uploading && nextProps.uploading.length > 0) {
            this.setState({ uploading: nextProps.uploading }, () => {
                this.isUploadComplete();
            });
        }
    }

    public componentWillUnmount(): void {
        this.clearUploadsHandler();
    }

    private uploadFiles(): void {
        this.setState(handlers.uploadFiles((files: File) => this.props.actions.addCommentAttachment(this.props.attachedToType, this.props.attachedToId, files)));
    }

    private handleWarning(proceed: boolean): void {
        this.setState(handlers.handleWarning(proceed, (file: File) => this.props.actions.addCommentAttachment(this.props.attachedToType, this.props.attachedToId, file)));
    }

    private isUploadComplete(): void {
        this.setState(isUploadComplete());
    }

    private removeWarnFile(file): void {
        this.setState(handlers.removeWarnFile(file));
    }

    private removeAttachmentHandler(attachmentURL: string): void {
        this.props.actions.removeAttachment(this.props.attachedToId, attachmentURL);
    }

    private clearUploadsHandler(): void {
        this.props.actions.clearUploads(this.props.attachedToId, this.props.attachedToType, true);
        this.setState({ uploading: [] });
    }

    private updateUploadState(item: any, data: any): void {
        this.setState({ uploadBegin: true });
        const upload = Object.assign({}, this.state.upload);
        upload[item] = data;
        this.setState({ upload }, () => {
            this.uploadFiles();
        });
    }
}

const mapStateToProps = (state, ownProps): any => {
    const getUploadingSelector = attachments.selectors.getUploading;
    const getAttachmentsSelector = attachments.selectors.getAttachments;
    return {
        uploading: getUploadingSelector(state, ownProps.attachedToId, ownProps.attachedToType),
        uploadedItem: getAttachmentsSelector(state, ownProps.attachedToId, ownProps.attachedToType),
    };
};

const mapDispatchToProps = (dispatch): any => {
    const clearUploads = attachments.actions.clearUploads;
    return {
        actions: bindActionCreators({
            addCommentAttachment,
            clearUploads,
            removeAttachment,
        }, dispatch),
    };
};

const AddCommentAttachment = (connect<any, any, any>(mapStateToProps, mapDispatchToProps)(injectIntl(AddCommentAttachmentInner)));

export default AddCommentAttachment;
