import { BasicUser, Conclusion, Supplier } from '../../../backend_api/models';
import { byId } from '../../../base/types';
import * as types from '../types';
import { Order } from '../types';

type Data = {
    conclusions: byId<Conclusion>,
    inspection_types: byId<types.InspectionType>,
    days: string[],
    inspectors: {
        days: { orders: Order[], suppliers: Supplier[] }[][],
        type: 'booked' | 'planned' | 'supplier_qc' | 'user',
        user: BasicUser
    }[],
    feature_sets: byId<string[]>,
}
type WeekView = {
    weekView: {
        data: Data;
        isLoading: boolean;
        currentWeek: string;
        datesRange: { startDate: string; endDate: string };
        setInspectorsManually: boolean;
        favoriteInspectors: string;
        viewType: string;
        filters: any;
    }
}
const initialState: WeekView = {
    weekView: {
        data: {
            conclusions: undefined,
            inspection_types: undefined,
            days: [],
            feature_sets: undefined,
            inspectors: [],
        },
        currentWeek: undefined,
        datesRange: { startDate: '', endDate: '' },
        favoriteInspectors: '',
        setInspectorsManually: false,
        viewType: 'normal',
        filters: undefined,
        isLoading: false,

    },

};
const map = {
    [types.GET_WEEK_VIEW_DATA_REQUEST]: (state = initialState, action): any => {
        return {
            ...state,
            weekView: {
                isLoading: action.payload.isLoading,
                data: state.weekView.data,
                currentWeek: state.weekView.currentWeek,
                datesRange: state.weekView.datesRange,
                filters: state.weekView.filters,
                setInspectorsManually: state.weekView.setInspectorsManually,
            },
        };
    },

    [types.GET_WEEK_VIEW_DATA_SUCCESS]: (state = initialState, action: { payload: { startDate: string; endDate: string; weekNumber: string; data: Data; filters: any } }): WeekView => {
        const payload = action.payload;
        const t = new Date().getTime();
        const data = { ...payload.data };
        data.inspectors.forEach((inspector) => {
            inspector.days.forEach((day) => {
                day.forEach((dayObj) => {
                    dayObj.orders.forEach((order) => {
                        order.inspections.forEach((inspection) => {
                            if (inspection.combined) {
                                inspection.sub_inspections.forEach((subInsp) => {
                                    subInsp.conclusion = payload.data.conclusions[subInsp.conclusion_id];
                                })
                            }
                            inspection.conclusion = payload.data.conclusions[inspection.conclusion_id];
                        })
                    })
                })
            })
        })
        const t2 = new Date().getTime();
        console.log('time ', t2 - t);
        return {
            ...state,
            weekView: {
                isLoading: false,
                data: data,
                currentWeek: payload.weekNumber,
                datesRange: { startDate: payload.startDate, endDate: payload.endDate },
                filters: payload.filters,
                setInspectorsManually: state.weekView.setInspectorsManually,
                favoriteInspectors: state.weekView.favoriteInspectors,
                viewType: state.weekView.viewType,
            },
        };
    },
    [types.SET_FAVORITE_INSPECTORS]: (state = initialState, action): any => {
        const weekView = Object.assign({}, state.weekView);
        weekView.favoriteInspectors = action.payload.inspectorIds;
        return {
            ...state,
            weekView,
        };
    },
    [types.SET_FAVORITE_INSPECTORS_MANUALLY]: (state = initialState, action): any => {
        const weekView = Object.assign({}, state.weekView);
        weekView.setInspectorsManually = action.payload.manually;
        return {
            ...state,
            weekView,
        };
    },
    [types.SET_WEEK_VIEW_FILTERS]: (state = initialState, action): any => {
        const weekView = Object.assign({}, state.weekView);
        weekView.filters = action.payload.filters;
        return {
            ...state,
            weekView,
        };
    },
    [types.SET_WEEK_VIEW_TYPE]: (state = initialState, action): any => {
        const weekView = Object.assign({}, state.weekView);
        weekView.viewType = action.payload.type;
        return {
            ...state,
            weekView,
        };
    },

};
export default { map, initialState };
