import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Segment from '../../../base/components/basic/Segment';
import TwoFactorCodeInput from './TwoFactorCodeInput';
import TwoFactorInputLoader from './TwoFactorInputLoader';
import TwoFactorInputMessage from './TwoFactorInputMessage';

type Props = {
    className?: string;
    organisationName: string;
    disabled: boolean;
    isFetching: boolean;
    handleTokenDone(code: string): void;
    resetLogin();
    loginFailed: boolean;
    loginSucceded: boolean;
};

const TwoFactorInput = (props: Props): React.ReactElement => {

    const { loginFailed, loginSucceded, isFetching, organisationName } = props;

    return <div className='flex justify-center mx-4 md:mx-0 md:mb-32 md:pb-4 pb-4'>
        <Segment className='flex w-full sm:w-136 flex-col'
            header={<div>{organisationName}</div>}
            heading={<FormattedMessage id='twoFactor.2fa_verification' />}
            footer={<Link to='/login' onClick={() => {
                props.resetLogin()
            }} className='link noUnderline flex'><FormattedMessage id='globals.cancel' /></Link>}
        >
            <h3 className='justify-center flex'><FormattedMessage id='twoFactor.enterCode.headline' /></h3>
            <TwoFactorCodeInput handleTokenDone={props.handleTokenDone} clear={loginFailed} />
            <TwoFactorInputMessage loginFailed={loginFailed} loginSucceded={loginSucceded} />
            <p><FormattedMessage id='twoFactor.enterCode.text.verify_text' /></p>
            <p className='text-secondary text-sm'><FormattedMessage id='twoFactor.enterCode.text.contact_text' /></p>
            <TwoFactorInputLoader isFetching={isFetching} />
        </Segment>
    </div>
}
export default TwoFactorInput;
