/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 65.2.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InspectionStatus {
    Planned = 'planned',
    Ongoing = 'ongoing',
    Report = 'report',
    Deprecated = 'deprecated'
}

export function InspectionStatusFromJSON(json: any): InspectionStatus {
    return InspectionStatusFromJSONTyped(json, false);
}

export function InspectionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionStatus {
    return json as InspectionStatus;
}

export function InspectionStatusToJSON(value?: InspectionStatus | null): any {
    return value as any;
}

