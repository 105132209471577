import { AuditStatus } from '../../../backend_api/models';

export const getColor = (state: AuditStatus) => {
    switch (state) {
        case AuditStatus.Planned:
            return 'status-planned';
        case AuditStatus.Downloaded:
            return 'status-downloaded';
        case AuditStatus.Ongoing:
            return 'status-ongoing';
        case AuditStatus.Report:
            return 'status-report';
    }
}
export const getApprovalColor = (state: 'not_set' | 'disagree') => {
    return 'status-pending';

}