import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../types';
import { SnackbarProps } from './components/snack/Snackbar';
// eslint-disable-next-line no-undef

const HIDE_AFTER = 3000;

export type UIState = {
    snackbar?: SnackbarProps;
}
const initialState: UIState = {
    snackbar: {
        show: false,
        hideAfter: HIDE_AFTER,
        content: '',
    },
}
export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showSnackbar: (state, action: { payload: SnackbarProps }) => {
            state.snackbar = action.payload;
            state.snackbar.show = true;
        },
        hideSnackbar: (state) => {
            state.snackbar.show = false;
        },
    },
    extraReducers: builder => {
        // Reducer responding to createAsyncThunk's:
    }
});

// Selectors
export const getSnackDataSelector = createSelector(
    [(state: AppState) => state.app.ui.snackbar],
    (showSnack) => showSnack,
);

const { actions } = uiSlice;
export const { showSnackbar, hideSnackbar } = actions;
export default uiSlice.reducer;
