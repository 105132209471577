import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { OrderListInspectionExtended } from '../../../inspections/slices/inspectionsListSlice';
import { Inspection, InspectionReadOnlyTypes, Order, OrderReadOnlyTypes } from '../../../inspections/types';
import { SupplierBookingInspection } from '../../../suppliers/types';
import { InfoIcon } from '../Utils';

enum Type { INSPECTION = 'inspection', ORDER = 'order' }
type OwnProps = {
    children?: React.ReactNode;
    showDialog?: boolean;
    hideDisabled?: boolean;
    order?: Order;
    inspection?: Inspection | OrderListInspectionExtended | SupplierBookingInspection | Inspection[] | OrderListInspectionExtended[] | SupplierBookingInspection[];
}
type AccessType =
    | {
        type: 'inspection'
        actionId: InspectionReadOnlyTypes,
    }
    | {
        type: 'order',
        actionId: OrderReadOnlyTypes,
        order: Order,
    };

type HasReadonlyAccessProps = AccessType & OwnProps;

const HasReadonlyAccess = (props: PropsWithChildren<HasReadonlyAccessProps>): any => {
    const { type, inspection, order, actionId, children, showDialog = true, hideDisabled = false } = props;
    const intl = useIntl();
    const disabledText = intl.formatMessage({ id: 'feature_access.no_access_to_editing.please_contact_admin' });
    if (type === Type.INSPECTION) {
        if (Array.isArray(inspection)) {
            let readonly = false;
            inspection.forEach((insp: Inspection | OrderListInspectionExtended | SupplierBookingInspection) => {
                if (insp.readonly && insp.readonly.includes(actionId as InspectionReadOnlyTypes)) {
                    readonly = true;
                    return;
                }
            });
            if (readonly && hideDisabled) {
                return null;
            }
            if (readonly) {
                return React.Children.map<React.ReactNode, any>(props.children, child => {
                    const cl = React.cloneElement(
                        child, { disabled: true },
                    );
                    return showDialog ? <Popup trigger={<span>{cl}</span>}><InfoIcon inline compact text={disabledText} /></Popup> : cl;
                });
            }
        }
        else if (inspection.readonly && inspection.readonly.includes(actionId as InspectionReadOnlyTypes)) {
            if (hideDisabled) {
                return null;
            }
            return React.Children.map<React.ReactNode, any>(props.children, child => {
                const cl = React.cloneElement(
                    child, { disabled: true },
                );
                return showDialog ? <Popup trigger={<span>{cl}</span>}><InfoIcon inline compact text={disabledText} /></Popup> : cl;
            });
        }
    }
    if (type === Type.ORDER) {
        if (order.readonly && order.readonly.includes(actionId as OrderReadOnlyTypes)) {
            return React.Children.map<React.ReactNode, any>(props.children, child => {
                const cl = React.cloneElement(
                    child, { disabled: true },
                );
                return showDialog ? <Popup trigger={<span>{cl}</span>}><InfoIcon compact inline text={disabledText} /></Popup> : cl;
            });
        }
    }
    return children;
}

export default HasReadonlyAccess;
