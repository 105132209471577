import React from 'react';
import { twMerge } from 'tailwind-merge';
import { InspectionsWeekviewRow, InspectionsWeekviewSupplierOrders } from '../../../../../../../backend_api/models';
import EmptyItem from '../EmptyItem';
import WeekViewItem from './WeekViewItem';

type WeekViewDayItemProps =  {
   className?: string;
   inspector: InspectionsWeekviewRow;
   date: Date;
   days: InspectionsWeekviewSupplierOrders[];
   j: number;
   i: number;
   label: string;
   openItems: string[];
   toggleCompact(e, item): void;
};

const WeekViewDayItem = (props: WeekViewDayItemProps): React.ReactElement => {
   const { className, inspector, date, days, openItems, toggleCompact, label, i, j } = props;
    const user = inspector.user && inspector.user;
    return <div key={'day_' + j} style={{ minHeight: '40px' }} className={twMerge('w-2/16 flex flex-col lineItem', j % 2 !== 0 ? 'even bg-transparent' : 'odd bg-week-view-odd')}>
        <EmptyItem
            user={user}
            date={date}
            type={inspector.type}
            label={label}
        >
            {days.map((supplierObj, index) => {
                const email = user && user.email || 'unassigned';
                const id = email + '_' + j + '_' + i + '_' + (supplierObj.supplier && supplierObj.supplier.id || 'none');
                const isOpen = openItems.includes(id);

                return <div className='flex flex-col mx-1' key={'day_' + j + '_' + index}>
                    <WeekViewItem
                        supplierObj={supplierObj}
                        date={date}
                        type={inspector.type}
                        user={inspector.user}
                        isOpen={isOpen}
                        toggleCompact={toggleCompact}
                        id={id}
                    />
                </div>
            })}
        </EmptyItem>
    </div>
}
export default WeekViewDayItem;
