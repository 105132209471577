import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Checkbox, Dropdown, DropdownItemProps, Form, Modal } from 'semantic-ui-react';
import { BasicUser } from '../../../backend_api/models';
import { getShowModalSelector, showModal as showModalDispatch } from '../../../base/baseSlice';
import Loader from '../../../base/components/Loader';
import Button from '../../../base/components/basic/Button';
import { DEFAULT_DATE_FORMAT } from '../../../base/config';
import { AppState } from '../../../base/types';
import { preventDefaultAndStopPropagation } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { DatePicker } from '../../globals/components';
import { InfoIcon } from '../../globals/components/Utils';
import HasReadonlyAccess from '../../globals/components/access/HasReadonlyAccess';
import { InspectorSelector } from '../../users/components';
import { getFirstAssignedUser, getFirstInspectionNotLockedOrDone, getInspectionDateTimestamp, getNotLockedAndDoneInspections, getNotLockedAndDoneInspectionsByTypeId } from '../Utils';
import { reassignInspectorOnOrder } from '../actions/unlockActions';
import { getInspectionTypesOnOrderSelector, hasInspectionTypes as hasInspectionsTypesSelector } from '../selectors/selectors';
import { OrderListOrderExtended, getFilteredListAccessData, getOrderAccessDataSelector } from '../slices/inspectionsListSlice';
import { Inspection } from '../types';

type Props = {
    className?: string;
    order: OrderListOrderExtended;
    trigger: any;
};

const ReassignInspector = (props: Props): React.ReactElement => {
    const { className, order } = props;
    const dispatch = useAppDispatch();
    const inspectionTypesOnOrder = useSelector((state: AppState) => getInspectionTypesOnOrderSelector(state, order));
    const inspection = getFirstInspectionNotLockedOrDone(order.inspections);
    const getShowTypes = (): boolean => inspectionTypesOnOrder.length !== 1;
    const accessData = useSelector((state: AppState) => getOrderAccessDataSelector(state, order.order_id));
    const [selectedType, setSelectedType] = useState((inspectionTypesOnOrder.length === 1 ? inspectionTypesOnOrder[0].value as any : null));
    const [isSupplierQC, setIsSupplierQC] = useState(inspection && inspection.supplier_qc);
    const [showTypes] = useState(getShowTypes());
    const [selectedInspector, setSelectedInspector] = useState<BasicUser | { id: string } | null>(getFirstAssignedUser(order.inspections, true));
    const [inspectorCanBeChanged] = useState(() => {
        if (selectedType) {
            return getNotLockedAndDoneInspectionsByTypeId(order.inspections, selectedType.id, true).length > 0;
        }
        return getNotLockedAndDoneInspections(order.inspections, true).length > 0;
    });
    const [date, setDate] = useState<Date>(() => {
        const d = getInspectionDateTimestamp(getNotLockedAndDoneInspections(order.inspections));
        return d === null ? null : new Date(d);
    });
    const hasInspectionTypes = useSelector(hasInspectionsTypesSelector);
    const intl = useIntl();
    const modalOpen = useSelector(getShowModalSelector);
    const modalId = 'reassignInspector_' + order.order_id;
    const showModal = (show: boolean) => {
        dispatch(showModalDispatch({ id: modalId, show }));
    }
    const reassign = () => {
        dispatch(reassignInspectorOnOrder(order, selectedInspector && selectedInspector.id, date, selectedType, isSupplierQC));
        showModal(false);
    }
    const toggleSupplierQC = () => {
        setIsSupplierQC(!isSupplierQC);
    }
    const close = (): void => {
        showModal(false);
    }
    const accessInspection = { readonly: accessData && accessData.inspections_access_data[order.inspections[0].inspection_id].inspection_readonly_fields || [] } as unknown as Inspection;
    const allDisabled = accessInspection.readonly.includes('assigned_user_id') && accessInspection.readonly.includes('scheduled_inspection_date');
    const inspectorSelector = <HasReadonlyAccess type='inspection' actionId='assigned_user_id' inspection={accessInspection}>
        <InspectorSelector
            handleChange={(data: { name: string; value: any }) => setSelectedInspector(data.value === null ? null : { id: data.value })}
            id={'il.select_inspector'}
            selectedInspector={selectedInspector && selectedInspector.id}
            disabled={false}
            done={false}
            fixedWidth={false}

        />
    </HasReadonlyAccess>;
    const dateSelector = <HasReadonlyAccess type='inspection' actionId='scheduled_inspection_date' inspection={accessInspection}>
        <DatePicker
            name='assign_inspect_date'
            date={date || null}
            handleChange={(date): void => setDate(date !== null ? new Date(date.format(DEFAULT_DATE_FORMAT)) : null)}
            openDirection='up'
        /></HasReadonlyAccess>;

    const typesSelector = <Dropdown
        placeholder='Select type'
        selectOnNavigation={false}
        name={'checktypes'}
        value={selectedType}
        search
        fluid={true}
        selection
        disabled={inspectionTypesOnOrder.length === 1 || allDisabled}
        options={inspectionTypesOnOrder as DropdownItemProps[]}
        onChange={(evt, { value }): void => setSelectedType(value)}
    />;
    const supplierQCSelector =
        <Form.Field>
            {selectedInspector === null &&
                <HasReadonlyAccess actionId='supplier_qc' type='inspection' inspection={order.inspections}>
                    <HasReadonlyAccess type='inspection' actionId='scheduled_inspection_date' inspection={order.inspections}>
                        <Checkbox
                            checked={isSupplierQC} onChange={() => toggleSupplierQC()} label={intl.formatMessage({ id: 'inspections.reassign_inspector.inspection_is_supplier_qc' })} />
                    </HasReadonlyAccess>
                </HasReadonlyAccess>
            }
        </Form.Field>
        ;
    const content = <div className=''>
        <Form>
            {inspectorCanBeChanged ?
                <Fragment>
                    {hasInspectionTypes && showTypes &&
                        <Form.Field required>
                            <label><FormattedMessage id='inspections.assign_inspector.select_type' /></label>
                            {typesSelector}
                        </Form.Field>}
                    <Form.Field>
                        <label><FormattedMessage id='inspections.assign_inspector.select_inspector' /></label>
                        {inspectorSelector}
                    </Form.Field>
                    <Form.Field>
                        <label><FormattedMessage id='inspections.assign_inspector.select_date' /></label>
                        {dateSelector}
                    </Form.Field>
                    {supplierQCSelector}
                </Fragment> : <div className='info'>
                    <InfoIcon compact={true} text={intl.formatMessage({ id: 'inspections.assign_inspector.no_inspections_on_this_type' })} />
                </div>}
        </Form>

    </div>;

    useEffect(() => {
        modalOpen[modalId] && modalOpen[modalId].show && dispatch(getFilteredListAccessData({ orderId: order.order_id }));
    }, [modalOpen[modalId]]);

    return <Modal
        size='small'
        trigger={props.trigger}
        closeOnEscape
        open={modalOpen[modalId] && modalOpen[modalId].show}
        onOpen={() => showModal(true)}
        onClose={() => showModal(false)}
        onClick={(e) => preventDefaultAndStopPropagation(e)}
    >
        <Modal.Header><FormattedMessage id='inspections.assign_inspector.set_inspector_and_date' /></Modal.Header>
        <Modal.Content>
            {accessData ? content : <Loader active={true} label={intl.formatMessage({ id: 'inspections.list_view.reassign.getting_access_data' })}>{content}</Loader>}
        </Modal.Content>
        <Modal.Actions className='flex justify-end'>
            {inspectorCanBeChanged ? <Button disabled={!accessData || selectedType === null} data-test-id='btn-save-assign-inspector-dialog' primary onClick={(): void => reassign()}><FormattedMessage id='inspections.reassign_inspector.save' /></Button> : ''}
            <Button data-test-id='btn-close-assign-inspector-dialog' onClick={(): void => close()}><FormattedMessage id='globals.cancel' /></Button>
        </Modal.Actions>
    </Modal>

}
export default ReassignInspector;
