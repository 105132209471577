import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { DropdownProps } from 'semantic-ui-react';
import { ListUsersUser } from '../../../backend_api/models/ListUsersUser';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import RemoveIcon from '../../../base/components/basic/RemoveIcon';
import { byId } from '../../../base/types';
import UsersSelector from '../../users/components/UsersSelector';

interface WatcherSelectorProps {
    usersById: byId<ListUsersUser>;
    showSelector: boolean;
    showEmailInSelector: boolean;
    hasSelection: boolean;
    value?: string;
    disabled?: boolean;
    allowMultipleSelection?: boolean;
    handleChange(data: DropdownProps): void;
    handleCancel(): void;
    handleSave(): void;
    handleBlur?(): void;
}
const WatcherSelector = (props: WatcherSelectorProps): React.ReactElement => {
    const intl = useIntl;
    return (<Fragment>
        {props.showSelector && <div className='flex justify-between space-x-1 w-full items-center'>
            {<UsersSelector
                fixedWidth={false}
                fluid
                users={props.usersById}
                placeholder={intl().formatMessage({ id: 'watchers.select_watcher' })}
                handleChange={props.handleChange}
                handleBlur={props.handleBlur}
                showEmailInSelector={props.showEmailInSelector}
                showExpanded={true}
                name='watchers'
                dataTestId='dropdown-select-watcher'
                allowMultipleSelection={props.allowMultipleSelection || true}

            />}
            <div className='flex items-center'>
                {props.hasSelection && <Button primary className='text-sm py-2 px-3' data-test-id='btn-watchers-done' onClick={props.handleSave}><Icon name='check' /></Button>}
                {/* <Button className='h-fit-content' onClick={props.handleCancel}><FormattedMessage id='watchers.cancel' /></Button> */}
                <Button className='py-2 px-3 text-sm ' onClick={props.handleCancel}><RemoveIcon className='' iconClassName='p-0 text-sml' /></Button>
            </div>
        </div>}
    </Fragment>
    );
};

export default WatcherSelector;
