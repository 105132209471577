import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Dropdown } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { DetailedSupplier } from '../../../backend_api/models';
import { OptionsType } from '../../../base/types';
import { useAppDispatch } from '../../../store';
import { useSupplierAndProductionUnitSelection } from '../../productionUnits/hooks/useSupplierAndProductionUnitSelection';

type Props = {
    className?: string;
    placeholder?: string;
    supplierId?: any;
    onSupplierSelected?(v: any): void;
};

const ControlledSupplierSelectorSimple = (props: Props): React.ReactElement => {
    const { className, onSupplierSelected, placeholder, supplierId } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();
    const [st, setSt] = useState(0);


    const {
        suppliers: selectableSuppliers,

    } = useSupplierAndProductionUnitSelection(true);
    useEffect(() => {
        setSt(new Date().getTime());
        console.log('st ', st)

    }, [])
    const convertToSupplierOption = (supplier: DetailedSupplier): OptionsType => {
        return {
            key: supplier.id,
            text: supplier.name,
            value: supplier.id,
            description: supplier.number
        };
    }


    const useMemoizedOptions = (): OptionsType[] => {
        // only recalculate the list, when options changed
        return useMemo<OptionsType[]>(() => {
            if (selectableSuppliers.length > 0) {
            }
            return selectableSuppliers.map((s) => convertToSupplierOption(s));
        }, [selectableSuppliers])
    }
    const dd = <Dropdown
        options={useMemoizedOptions()}
        search
        selection
        fluid
        onChange={(e, item) => onSupplierSelected(item)}
        placeholder={placeholder}
        value={supplierId}
    />


    return <div className={twMerge('', className)}>
        {dd}
    </div>
}
export default ControlledSupplierSelectorSimple;
