import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Modal, Radio } from 'semantic-ui-react';
import { DetailedSupplier, Group } from '../../../backend_api/models';
import { CreateSupplierParams } from './CreateSupplierModal';
import { NewSupplierInputsWithHints } from './NewSupplierInputs';
import { useSuppliers } from '../../productionUnits/hooks/useSuppliers';
import ControlledSupplierSelector from './ControlledSupplierSelector';

export function CreateOrSelectSupplierModal(props: {
    // excludeSupplierIds?: string[],
    renderOpenButton?(): React.ReactNode,
    onSupplierIdSelected(supplierId: string): void,
    onUserWantedToCreateSupplier(params: CreateSupplierParams): void
}) {
    const [showModal, setShowModal] = React.useState(false);

    function onCancel() {
        setShowModal(false)
    }

    function onOpen() {
        setShowModal(true)
    }

    function onSupplierIdSelected(id: string) {
        props.onSupplierIdSelected(id);
        setShowModal(false)
    }

    function onUserWantedToCreateSupplier(params) {
        props.onUserWantedToCreateSupplier(params);
        setShowModal(false)
    }

    return (
        <Modal
            open={showModal}
            onOpen={onOpen}
            onClose={onCancel}
            closeIcon={true}
            size='small'
            trigger={
                props.renderOpenButton
                    ? props.renderOpenButton()
                    : renderDefaultOpenButton()
            }
            className='create-or-select-supplier'
        >
            <ModalHeader />
            <ModalContentAndActions
                // excludeSupplierIds={props.excludeSupplierIds}
                onCancel={onCancel}
                onSupplierIdSelected={onSupplierIdSelected}
                onUserWantedToCreateSupplier={onUserWantedToCreateSupplier}
            />
        </Modal >
    )

}

function ModalHeader() {
    return (
        <Modal.Header>
            <FormattedMessage id='suppliers.add_supplier' />
        </Modal.Header>
    );
}

function ModalContentAndActions(props: {
    // excludeSupplierIds?: string[],
    onCancel(): void,
    onSupplierIdSelected(id: string): void,
    onUserWantedToCreateSupplier(params): void
}) {

    const [useExistingSupplier, setUserExistingSupplier] = React.useState(true);

    const [selectedSupplierId, setSelectedSupplierId] = React.useState<string>()

    const [groupsInput, setGroupsInput] = React.useState<Group[]>([]);
    const [supplierNameInput, setSupplierNameInput] = React.useState<string>('');
    const [supplierNumberInput, setSupplierNumberInput] = React.useState<string>('');

    const selectableSuppliersLoading = useSuppliers();

    const loading = selectableSuppliersLoading === 'loading';
    const selectableSuppliers = loading ? [] : selectableSuppliersLoading;

    function reset() {
        setUserExistingSupplier(false);
        setSelectedSupplierId(null);
        setSupplierNameInput('');
        setSupplierNumberInput('');
        setGroupsInput([]);
    }

    function onSupplierIdSelected(id: string) {
        setUserExistingSupplier(true)
        setSelectedSupplierId(id)
    }

    function onUserWantsToCreateSupplier() {
        props.onUserWantedToCreateSupplier({
            name: supplierNameInput,
            number: supplierNumberInput,
            groups: groupsInput
        })
        reset();
    }

    function onUseSelectedSupplier() {
        props.onSupplierIdSelected(selectedSupplierId);
        reset();
    }

    function renderCancelButton() {
        return (
            <Button onClick={props.onCancel}>
                <FormattedMessage id='globals.cancel' />
            </Button>
        );
    }

    function renderToggle() {
        return <CreateOrSelectSwitch
            setUseExistingSupplier={setUserExistingSupplier}
            useExistingSupplier={useExistingSupplier}
        />
    }

    function renderNewSupplierInputs() {
        return (
            <NewSupplierInputsWithHints
                groupsInput={groupsInput}
                onGroupsChanged={setGroupsInput}
                onExistingSupplierIdSelected={onSupplierIdSelected}
                onSupplierNameChanged={setSupplierNameInput}
                supplierNameInput={supplierNameInput}
                onSupplierNumberChanged={setSupplierNumberInput}
                supplierNumberInput={supplierNumberInput}
                selectableSuppliers={selectableSuppliers}
            />
        )
    }

    function renderContentAndActionsForExistingCase() {
        return (
            <>
                <Modal.Content>
                    {renderToggle()}
                    <ChooseExistingSupplier
                        loading={loading}
                        onSupplierIdSelected={onSupplierIdSelected}
                        onUserWantsToCreateNewSupplier={() => {
                            setUserExistingSupplier(false)
                        }}
                        suppliers={selectableSuppliers}
                        selectedSupplierId={selectedSupplierId} />
                </Modal.Content>
                <Modal.Actions>
                    <Button positive
                        disabled={!selectedSupplierId}
                        onClick={onUseSelectedSupplier}>
                        <FormattedMessage id='globals.ok' />
                    </Button>
                    {renderCancelButton()}
                </Modal.Actions>
            </>
        )
    }

    function renderContentAndActionsForNewCase() {
        return (
            <>
                <Modal.Content>
                    {renderToggle()}
                    {renderNewSupplierInputs()}
                </Modal.Content>
                <Modal.Actions>
                    <Button positive
                        disabled={!isValidSupplierName(supplierNameInput)}
                        onClick={onUserWantsToCreateSupplier}>
                        <FormattedMessage id='suppliers.create_supplier' />
                    </Button>
                    {renderCancelButton()}
                </Modal.Actions>
            </>
        )
    }

    if (useExistingSupplier) {
        return renderContentAndActionsForExistingCase();
    } else {
        return renderContentAndActionsForNewCase();
    }
}

function isValidSupplierName(supplierNameInput: string) {
    return typeof supplierNameInput === 'string' && supplierNameInput.length > 0;
}

function ChooseExistingSupplier(props: {
    loading: boolean;
    suppliers: DetailedSupplier[],
    selectedSupplierId: string,
    onUserWantsToCreateNewSupplier(): void
    onSupplierIdSelected(supplierId: string): void
}) {

    const selectedSupplierIdIsInSuppliers =
        React.useMemo(function calculateIfSupplierIdIsSelectable() {
            return (
                props.selectedSupplierId &&
                !props.suppliers.find((pu) => pu.id === props.selectedSupplierId)
            );
        }, [props.selectedSupplierId, props.suppliers]);

    if (selectedSupplierIdIsInSuppliers) {
        return (
            <Form>
                <Form.Field>
                    <span><FormattedMessage id="suppliers.selection.already_added" /></span>
                    <br></br>
                    <a className="link"
                        onClick={() => props.onSupplierIdSelected(null)} >
                        <FormattedMessage id="suppliers.selection.choose_another" />
                    </a>
                </Form.Field>
            </Form>
        )
    }

    return (
        <Form>
            <Form.Field>
                <label><FormattedMessage id='suppliers.select_supplier' /></label>
                <div className={'flex w-full'}>
                    <ControlledSupplierSelector
                        loading={props.loading}
                        dataTestId='select-existing-production-unit'
                        suppliers={props.suppliers}
                        onSupplierIdSelected={props.onSupplierIdSelected}
                        supplierId={props.selectedSupplierId}
                        onUserWantsToCreateNewSupplier={props.onUserWantsToCreateNewSupplier}
                    />
                </div>
            </Form.Field>
        </Form>
    )
}

function CreateOrSelectSwitch(props: {
    useExistingSupplier: boolean,
    setUseExistingSupplier(value: boolean): void
}) {
    function toggle() {
        props.setUseExistingSupplier(!props.useExistingSupplier)
    }

    const intl = useIntl()

    return (
        <Form className='pb-4'>
            <Form.Field>
                <Radio
                    label={intl.formatMessage({ id: 'suppliers.use_existing' })}
                    checked={props.useExistingSupplier}
                    onChange={toggle}
                />
            </Form.Field>
            <Form.Field>
                <Radio
                    label={intl.formatMessage({ id: 'suppliers.create_new' })}
                    checked={!props.useExistingSupplier}
                    onChange={toggle}
                />
            </Form.Field>
        </Form>
    )
}

function renderDefaultOpenButton() {
    return (
        <Button primary size='tiny'>
            <FormattedMessage id='suppliers.add_supplier' />
        </Button>
    )
}