import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { getShowModalSelector, showModal } from '../../../../base/baseSlice';
import { AppState } from '../../../../base/types';
import { useAppDispatch } from '../../../../store';
import { getInspectionByIdSelector, getOrderWithInspections } from '../../selectors/selectors';
import { splitShipment, splitShipmentCombined, SplitShipmentsContext } from '../../slices/splitShipmentsSlice';
import { Inspection } from '../../types';
import SplitItem from './SplitItem';

type SplitShipmentsProps = {
    inspectionId?: string;
    inspection?: Inspection;
    isCombinedInspection?: boolean;
    context: SplitShipmentsContext;
    useTrigger?: boolean;
    className?: string;
};
export type QtysType = { [id: string]: any };
const SplitShipments = (props: SplitShipmentsProps): React.ReactElement => {
    const { context, useTrigger = false, isCombinedInspection = false } = props;
    const isInspection = context === 'inspection';
    const modalOpen = useSelector(getShowModalSelector);
    const dispatch = useAppDispatch();
    const [inspection, setInspection] = useState<Inspection | undefined>(undefined);
    const [qtys, setQtys] = useState<QtysType>({});
    const [canSplit, setCanSplit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [id, setId] = useState('');
    const [inspections, setInspections] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const order = useSelector(getOrderWithInspections);
    const updateQtys = (f: QtysType) => {
        const qs = { ...qtys };
        qs[f.id] = { first: f.first, second: f.second };
        setCanSave(Object.values(qs).filter((q) => q.second && q.second > 0).length > 0);
        setQtys(qs);
    }
    const loadQtys = () => {
        const obj: QtysType = {};
        inspections.forEach((insp) => obj[isInspection ? insp.inspection_id : insp.id] = { first: insp.total_quantity, second: 0 })
        return obj;
    }
    useEffect(() => {
        let insp: Inspection;
        if (props.inspectionId) {
            insp = useSelector((state: AppState): Inspection => getInspectionByIdSelector(state, props.inspectionId));
            setInspection(insp);
        } else {
            insp = props.inspection;
            setInspection(props.inspection);
        }
        if (insp) {
            const id = isInspection ? insp.inspection_id : insp.id;
            setId(id);
            setOpen(modalOpen[id] && modalOpen[id].show || false);
            setCanSplit(insp.features.includes('split_planned_shipment') && insp.status === 'planned' && insp.split_shipment_allowed);
            let inspections: Inspection[];
            if (isCombinedInspection) {
                inspections = order.inspections[0].sub_inspections;
            } else {
                inspections = [inspection];
            }
            setInspections(inspections);
        }
    }, [inspection]);
    useEffect(() => {
        if (modalOpen[id] && modalOpen[id].show !== isOpen) {
            setOpen(modalOpen[id].show);
            setQtys(loadQtys())
        }
    }, [useTrigger && modalOpen[id]])

    if (canSplit) {
        let inspections: Inspection[];
        if (isCombinedInspection) {
            inspections = order.inspections[0].sub_inspections;
        } else {
            inspections = [inspection];
        }
        const save = (): void => {
            const inspectionId = isInspection ? inspection.inspection_id : inspection.id;
            if (isCombinedInspection) {
                const masterInspectionId = order.inspections[0].inspection_id;
                const sourceSubInspectionQuantities = {};
                Object.keys(qtys).forEach((id) => {
                    if (qtys[id].second && qtys[id].second > 0) {
                        sourceSubInspectionQuantities[id] = qtys[id].first;
                    }
                });
                dispatch(splitShipmentCombined({ inspectionId: masterInspectionId, sourceSubInspectionQuantities }));
            } else {
                const firstQty = qtys[id].first;
                dispatch(splitShipment({ inspectionId, qty: firstQty, context }));
            }
            setOpen(false);
            dispatch(showModal({ show: false, id }));
        }
        const cancel = (): void => {
            setOpen(false);
            dispatch(showModal({ show: false, id }));
        }
        return <Modal
            trigger={!useTrigger && <div><FormattedMessage id='split_shipments.action.split' /></div>}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => cancel()}
            closeIcon={true}
            closeOnDimmerClick={false}
        >
            <Modal.Content>
                <h2><FormattedMessage id='split_shipments.header' /></h2>
                <div>{inspections.map((inspection, i: number) => {
                    const id = isInspection ? inspection.inspection_id : inspection.id;
                    const firstQty = (qtys[id] && qtys[id].first);
                    const secondQty = (qtys[id] && qtys[id].second);
                    return <SplitItem isInspection={isInspection} inspection={inspection} qty1={firstQty} qty2={secondQty} updateQtys={(f) => updateQtys(f)} key={'splitItem_' + i} />;
                })}</div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => cancel()}><FormattedMessage id='globals.cancel' /></Button>
                <Button primary onClick={() => save()} disabled={!canSave}><FormattedMessage id='split_shipments.button.split' /></Button>
            </Modal.Actions>

        </Modal >
    }
    return null;
}
export default SplitShipments;
