import React, { useEffect, useState } from 'react';
import { ReactBingmaps } from 'react-bingmaps';
import { FormattedMessage } from 'react-intl';
import { GPSLocation } from '../../../backend_api_2';
import Button from '../../../base/components/basic/Button';
import Icon from '../../../base/components/basic/Icon';
import SpinnerIcon from '../../../base/components/basic/SpinnerIcon';
import { getMapLocation, isUndefinedOrNull, twMerge } from '../../../base/utils';

type Props = {
    className?: string;
    location: GPSLocation;
    locationCountry: string;
    setMapLocation(GPSLocation);
};
let T;
let tMap;
let tMarker;

const ProductionUnitMap = (props: Props): React.ReactElement => {
    const { className, locationCountry } = props;
    const resetLocation = (first: boolean) => {
        return { latitude: props.location && props.location.latitude || undefined, longitude: props.location && props.location.longitude || undefined, first };
    }
    const [location, setLocation] = useState(resetLocation(true));
    const mapLocation = getMapLocation(location);
    const posLabel = !location.first ? (!isUndefinedOrNull(location.latitude) && location.latitude.toFixed(5) + ', ' + (!isUndefinedOrNull(location.longitude) && location.longitude.toFixed(5))) : '';
    const mapProvider = locationCountry === 'CN' ? 'tianditu' : 'bing';

    const getWorldmap = (long: number, lat: number) => {
        setTimeout(() => {
            const zoom = 16;
            const map = new T.Map('mapDiv');
            const marker = new T.Marker(new T.LngLat(long, lat));
            const z = new T.Control.Zoom();
            tMap = map;
            tMarker = marker;
            map.centerAndZoom(new T.LngLat(long, lat), zoom);
            map.addControl(z);
            map.addOverLay(marker);
            if (document.getElementById('mapLoader')) {
                document.getElementById('mapLoader').innerHTML = '';
            }
            map.addEventListener('click', (evt) => setLocation({ latitude: evt.lnglat.lat, longitude: evt.lnglat.lng, first: false }));
        }, 200)
    }
    const updateTiandituMap = (long, lat) => {
        if (tMap) {
            const map = tMap;
            const marker = new T.Marker(new T.LngLat(long, lat));
            map.centerAndZoom(new T.LngLat(long, lat));
            map.addOverLay(marker);
            map.removeOverLay(tMarker);
            tMarker = marker;
        }
    }
    const getTiandituScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=1662e191edb65576a0f967ed66955ca3';
        script.addEventListener('load', () => {
            T = window.T;
            getWorldmap(mapLocation[1], mapLocation[0]);
        })
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    };

    const getBingMap = () => {
        return <ReactBingmaps
            zoom={6}
            bingmapKey='AuNjAlN_Sq3pRGRH5yygB1rlSxgcZPsgvmHPCM2REtJADzPVJpEALzAXhJXS7xMW'
            center={mapLocation}
            getLocation={
                {
                    addHandler: 'click', callback: (evt: { latitude: number; longitude: number }): void => {
                        setLocation({ latitude: evt.latitude, longitude: evt.longitude, first: false });
                    },
                }
            }
            pushPins={
                [
                    {
                        location: [props.location && props.location.latitude || undefined, props.location && props.location.longitude || undefined], option: { color: 'green' },
                    },
                    {
                        location: [location && location.latitude || undefined, location && location.longitude || undefined], option: { color: '#2185d0' },
                    },
                ]
            }
        >
        </ReactBingmaps>
    }
    useEffect(() => {
        setLocation(resetLocation(location.first));
        if (locationCountry === 'CN') {
            updateTiandituMap(props.location.longitude, props.location.latitude);
        }
    }, [props.location]);
    useEffect(() => {
        if (locationCountry === 'CN') {
            getTiandituScript();
        }
    }, [props.locationCountry])
    return (
        <div>
            <div className={twMerge('w-full border', className)}>
                {!location.first &&
                    <div className='flex p-3 bg-attention items-center justify-between'>
                        <div className='flex items-center'><Icon name={('location_on')} className='text-2xl' />
                            <FormattedMessage id='production_units.use_location_as_new_position' values={{ position: <span className='mx-1 font-bold'>{posLabel}</span> }} />
                        </div>
                        <Button small primary onClick={(): void => {
                            setLocation(resetLocation(true));
                            props.setMapLocation(location);
                        }}><FormattedMessage id='globals.ok' /></Button>
                    </div>
                }
                {locationCountry === 'CN' && <div id='mapDiv' style={{ height: '500px' }}>
                    <div className='pt-8 items-center justify-center w-full space-x-2 flex' id='mapLoader'><SpinnerIcon className='text-brand' /><span><FormattedMessage id='report.please_wait_map_is_loading' /></span></div>
                </div>}
                {locationCountry !== 'CN' && <div style={{ height: '500px' }}>{getBingMap()}</div>}
            </div>
            <div className='text-sm text-secondary py-1 flex space-x-1'><span><FormattedMessage id={'report.map_provided_by'} /></span><span className='font-medium'><FormattedMessage id={'report.map_provider_' + mapProvider} /></span></div>
        </div>
    );
}
export default ProductionUnitMap;
