import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Header, Label, Popup } from 'semantic-ui-react';
import { MultipleChoiceOption } from '../../../../../backend_api/models/MultipleChoiceOption';
import { getLocaleLanguage } from '../../../../../base/utils';

type MultipleChoiceProps = {
    options: MultipleChoiceOption[];
};

const MultipleChoice = (props: MultipleChoiceProps): React.ReactElement => {
    const intl = useIntl();
    const lang = getLocaleLanguage(intl.locale);
    const { options } = props;
    const [showNonSelected, setShowNonSelected] = useState(false);
    const selectedItems = options.filter((optionF: MultipleChoiceOption) => optionF.chosen);
    const notSelectedItems = options.filter((optionF: MultipleChoiceOption) => !optionF.chosen);
    const hasSelected = selectedItems.length > 0;
    return (
        <div className='multipleChoice'>
            <Header as='h4'><FormattedMessage id='report.checkpoint.multiple_choice.selected_items' /></Header>
            {!hasSelected && <div className='selected'><Fragment><FormattedMessage id='report.checkpoint.multiple_choice.no_selection' /></Fragment></div>}
            {hasSelected && <div className='selected'>{selectedItems.map((optionF: MultipleChoiceOption, i) => {
                const color = optionF.color;
                // eslint-disable-next-line react/jsx-key
                return <Fragment>
                    <Label className='mcItem' style={{ backgroundColor: color }} key={'multipleChoice_' + i}>{optionF.name[lang] || optionF.name.C}</Label>
                    <div className='description'>{optionF.description[lang] || optionF.description.C || ''}</div>

                </Fragment>
            })}</div>}
            <div className='notSelected'>
                <span className='cnt link noUnderline' onClick={(): void => setShowNonSelected(!showNonSelected)}><FormattedMessage id='report.checkpoint.multiple_choice.not_selected' /> ({notSelectedItems.length})</span>
                {showNonSelected && <div className='items flex gap-2 cursor-default'>{notSelectedItems.map((optionF: MultipleChoiceOption, i) => {
                    const label = <Label className='mcItem notChosen' key={'multipleChoice_' + i}>{optionF.name[lang] || optionF.name.C}</Label>;
                    const ns = optionF.description[lang] || optionF.description.C ? <Popup trigger={<span>{label}</span>}>{optionF.description[lang] || optionF.description.C}</Popup> : label;
                    return ns;
                })}
                </div>}
                {showNonSelected && <span className='link noColor noUnderline small secondary' onClick={(): void => setShowNonSelected(false)}><FormattedMessage id='report.checkpoint.multiple_choice.hide' /></span>}
            </div>
        </div>
    )
}
export default MultipleChoice;
