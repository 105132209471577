/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DisplayCustomFieldTextValue = {
    text: string | null;
    type: DisplayCustomFieldTextValue.type;
};

export namespace DisplayCustomFieldTextValue {

    export enum type {
        TEXT = 'Text',
    }


}

