import { AnyAction } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';
import { request } from '../../../base/api';
import { AppThunk } from '../../../base/types';
import { isUndefinedOrNull } from '../../../base/utils';
import errorHandling from '../../errorHandling';
import messages from '../../messages';
import { updateInspection, updateOrder, updateOrderWithInspectionsFailure } from '../actions';
import { getInspectionsListDataSelector, ListType, OrderListInspectionExtended, OrderListOrderExtended, reloadFilteredList } from '../slices/inspectionsListSlice';
import * as types from '../types';
import { getNotLockedAndDoneInspections, getNotLockedAndDoneInspectionsByTypeId, isInspectionPartOfCombinedInspection } from '../Utils';
import { orderWithInspectionsRequestSuccess } from './editInspectionActions';

const catchException = errorHandling.handler.catchException;

const sendStatusMessage = messages.actions.sendStatusMessage;
const sendErrorMessage = messages.actions.sendErrorMessage;
const sendGlobalMessage = messages.actions.sendGlobalMessage;
const clearErrorMessage = messages.actions.clearErrorMessage;
const clearAllMessages = messages.actions.clearAllMessages;

export const unlockInspection = (inspectionId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/unlock';
        dispatch(clearErrorMessage());
        dispatch(sendStatusMessage(['status_message.unlocking_inspection'], 0, true));
        dispatch(savingInspectionStart());
        request(url, { method: 'post' })
            .then((data) => {
                dispatch(savingInspectionDone());
                // Update the current order with order data returned from unlock request
                // For now it returns the complete order. This may be optimised at some later point
                dispatch(orderWithInspectionsRequestSuccess(data));
                dispatch(sendStatusMessage(['status_message.the_inspection_was_succesfully_unlocked'], 5000));
            }).catch((error) => {
                dispatch(unlockInspectionFailure(false, inspectionId, error.status, error));
                dispatch(updateOrderWithInspectionsFailure(error, status, false));
                dispatch(sendErrorMessage(['error_message.the_inspection_was_not_unlocked']));
                catchException('reassignLockedInspection', {
                    endpoint: 'inspections/[inspectionId]/unlock',
                    request: url,
                    status: error.status,
                }, { error, inspectionId });
            });
    };
};

export const reassignLockedInspection = (inspectionId: string, inspectorId: string): AppThunk | AnyAction => {
    return async (dispatch): Promise<void> => {
        const url = 'inspections/' + inspectionId + '/reassign/' + inspectorId;
        dispatch(reassignLockedInspectionRequest(true, inspectionId, inspectorId));
        dispatch(clearErrorMessage());
        return request(url, { method: 'post' })
            .then((data) => {
                dispatch(reassignLockedInspectionSuccess(false, inspectionId, inspectorId, data));
                dispatch(sendStatusMessage(['status_message.the_inspection_was_succesfully_reassigned'], 5000));
                // Update the current order with order data returned from unlock request
                // For now it returns the complete order. This may be optimised at some later point
                dispatch(orderWithInspectionsRequestSuccess(data));
            }).catch((error) => {
                dispatch(reassignLockedkInspectionFailure(false, inspectionId, inspectorId, error.status, error));
                dispatch(updateOrderWithInspectionsFailure(error, status, false));
                dispatch(sendErrorMessage(['error_message.the_inspection_was_not_reassigned']));
                catchException('reassignLockedInspection', {
                    endpoint: 'inspections/[inspectionId]/reassign/[inspectorId]',
                    request: url,
                    status: error.status,
                }, { error, inspectionId, inspectorId });
            });
    };
};

export const reassignInspectorOnOrder = (orderObj: (OrderListOrderExtended), inspectorId: string, date: Date, inspectionType: types.InspectionType, isSupplierQC: boolean) => {
    return async (dispatch, getState): Promise<void> => {
        const order = Object.assign({}, orderObj);
        const selectedType = inspectionType ? inspectionType : { id: 'none' };
        const payload = { inspections: [], CI: {} };
        const pa = [];
        const i = [];
        let inspections = getNotLockedAndDoneInspections(order.inspections, true) as OrderListInspectionExtended[];
        if (selectedType) {
            inspections = getNotLockedAndDoneInspectionsByTypeId(order.inspections, selectedType.id, true) as OrderListInspectionExtended[];
        }
        inspections.forEach((_inspection: (OrderListInspectionExtended)) => {
            const inspection = { ..._inspection };
            if (isInspectionPartOfCombinedInspection(inspection)) {
                payload.CI[inspection.master_inspection.id] = {
                    assigned_user_id: inspectorId,
                    scheduled_inspection_date: date,
                    supplier_qc: isSupplierQC,
                };
            } else {
                if (selectedType) {
                    if (selectedType.id === 'none') {
                        if (isUndefinedOrNull(inspection.inspection_type)) {
                            inspection.assigned_user_id = inspectorId;
                            inspection.scheduled_inspection_date = date;
                        }
                    }
                    if (selectedType.id === 'all') {
                        inspection.assigned_user_id = inspectorId;
                        inspection.scheduled_inspection_date = date;
                    }
                    if (inspection.inspection_type && selectedType.id === inspection.inspection_type.id) {
                        inspection.assigned_user_id = inspectorId;
                        inspection.scheduled_inspection_date = date;
                    }
                } else {
                    inspection.assigned_user_id = inspectorId;
                    inspection.scheduled_inspection_date = date;
                }
                inspection.supplier_qc = isSupplierQC;
            }
            i.push(inspection);
        });
        order.inspections = i;
        pa.push(dispatch(updateOrder(order.order_id, order, true, false)));
        Object.keys(payload.CI).forEach((id) => {
            const obj = payload.CI[id];
            pa.push(dispatch(updateInspection(id, obj)));
        });
        const results = Promise.all(pa); // pass array of promises

        results.then(() => {
            dispatch(sendStatusMessage(['status_message.the_inspector_was_succesfully_changed_on_the_order'], 3000));
            dispatch(reloadFilteredList());
            const inspectionsData = getInspectionsListDataSelector(getState(), ListType.Inspections);
            const hasOrder = inspectionsData.elements.filter((orderItem: types.Order) => {
                return orderItem.order_id === orderObj.order_id;
            });
            if (hasOrder.length === 0) {
                dispatch(clearAllMessages());
                dispatch(sendGlobalMessage(['status_message.the_change_is_outside_the_current_search_result'], 10000, '', 'info', false));
            }
        });
    };
};

const reassignLockedInspectionRequest =
    createAction(types.REASSIGN_LOCKED_INSPECTION_REQUEST, (isSaving: boolean, inspectionId: string, inspectorId: string) => {
        return {
            type: types.REASSIGN_LOCKED_INSPECTION_REQUEST, payload: {
                isSaving,
                inspectionId,
                inspectorId,
            },
        };
    });

const reassignLockedInspectionSuccess =
    createAction(types.REASSIGN_LOCKED_INSPECTION_SUCCESS, (isSaving: boolean, inspectionId: string, inspectorId: string, response: any) => {
        return {
            type: types.REASSIGN_LOCKED_INSPECTION_SUCCESS, payload: {
                isSaving,
                inspectionId,
                inspectorId,
                response,
            },
        };
    });

const reassignLockedkInspectionFailure =
    createAction(types.REASSIGN_LOCKED_INSPECTION_FAILURE, (isSaving: boolean, inspectionId: string, inspectorId: string, status: number, message: string) => {
        return {
            type: types.REASSIGN_LOCKED_INSPECTION_FAILURE, payload: {
                isSaving,
                inspectionId,
                inspectorId,
                status,
                message,
            },
        };
    });

export const savingInspectionStart =
    createAction(types.UNLOCK_INSPECTION_REQUEST, () => {
        return {
            type: types.UNLOCK_INSPECTION_REQUEST, payload: {
                isSaving: true
            },
        };
    });

export const savingInspectionDone =
    createAction(types.UNLOCK_INSPECTION_SUCCESS, () => {
        return {
            type: types.UNLOCK_INSPECTION_SUCCESS, payload: {
                isSaving: false
            },
        };
    });

const unlockInspectionFailure =
    createAction(types.UNLOCK_INSPECTION_FAILURE, (isSaving: boolean, inspectionId: string, status: number, message: string) => {
        return {
            type: types.UNLOCK_INSPECTION_FAILURE, payload: {
                isSaving,
                inspectionId,
                status,
                message,
            },
        };
    });
