import { useState } from 'react';
import { DetailedSupplier } from '../../../backend_api/models/DetailedSupplier';
import { ProductionUnit } from '../../../backend_api_2';
import { useSuppliers } from './useSuppliers';
import { useListableProductionUnits } from './useProductionUnits';
import { useSupplierAndProductionUnitRelations } from '../../supplierAndPuRelations/slice/relationSlice';
import { ListableProductionUnit, SimpleProductionUnit } from '../../../backend_api/models';
import { ProductionUnitInSupplierContextState, useProductionUnitsInSupplierContext, useSelectableProductionUnits, useSelectableProductionUnitsWithFallback } from '../slice/productionUnitsSlice';


type SupplierId = string;
type ProductionUnitId = string;
export type SupplierAndProductionUnitRelations = [SupplierId, ProductionUnitId][]


export type SupplierAndProductionUnitSelectionReturn = {
    /* currently selected production unit id */
    selectedProductionUnitId: string | null,
    /* currently selected supplier id */
    selectedSupplierId: string | null,

    /* are the selected supplier and production unit linked together ? */
    linked: boolean,

    /* loading is true, if suppliers, productionUnits or relations are being loaded */
    loading: boolean,

    /* Call this to change the production unit id, and reset the supplier id, if the two do not match */
    setSelectedProductionUnitId(productionUnitId: string | null): void,

    /* Call this to change the supplier id, and reset the production unit id, if the two do not match */
    setSelectedSupplierId(supplierId: string | null)

    /* List of suppliers that match the chosen production unit, or all if there is no production unit selected. */
    suppliers: DetailedSupplier[],

    /* 
        List of production units that match the chosen supplier, or all if there is no supplier selected.
        If production units are not independent, the list is empty if no supplier is selected.
    */
    productionUnits: SimpleProductionUnit[],

    /* 
        otherSuppliers and otherProductionUnits are those that are not linked to the currently selected supplier and production units.
        These are empty, if production units are not independent.
    */
    otherSuppliers: DetailedSupplier[],
    otherProductionUnits: SimpleProductionUnit[]
}

/**
 * 
 * Depending on which supplier and / or production unit has been selected, only linked elements of the other type can be selected.
 * If you select a production unit, you can only select suppliers that are linked to that production unit.
 * If you select a supplier, you can only select production units that are linked to that supplier.
 * 
 * If you select a production unit or supplier that is not selected to the other selected thing, that other thing is reset.
 * 
 * The allowed relations are requested from the backend, together with the list of suppliers and production units.
 */
export function useSupplierAndProductionUnitSelection(independentProductionUnits: boolean, fallbackProductionUnit?: SimpleProductionUnit): SupplierAndProductionUnitSelectionReturn {
    // Data sources can be refactored to be input instead. This might make the hook more useful.

    /*
        We pass a fallback, for the case that the user does not have the selectable PU in his list. 
        Otherwise the currently selected PU can not be shown ( as if selectedProductionUnitId is undefined or null ) 
    */
    const selectableProductionUnits: SimpleProductionUnit[] | 'loading' = useSelectableProductionUnitsWithFallback(fallbackProductionUnit);

    const suppliers: DetailedSupplier[] | 'loading' = useSuppliers();
    const relations: [string, string][] | 'loading' = useSupplierAndProductionUnitRelations();


    const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(null);
    const [selectedProductionUnitId, setSelectedProductionUnitId] = useState<string | null>(null);

    const loading = selectableProductionUnits === 'loading' || relations === 'loading' || suppliers === 'loading'

    const [limitedSuppliers, otherSuppliers] = loading ? [[], []] : limitSuppliers(suppliers, selectedProductionUnitId, relations)
    const [limitedProductionUnits, otherProductionUnits] = loading ? [[], []] : limitProductionUnits(selectableProductionUnits, selectedSupplierId, relations)

    /* Pretty awkward way to limit production units. It would be nice, if we would not try to load the other production units too. */
    /* Consider moving all logic around non- independent pus into another hook, and toogle between the two, instead of having one hook doing everything. */
    const supplierContextProductionUnit: ProductionUnitInSupplierContextState = useProductionUnitsInSupplierContext(selectedSupplierId);

    const supplierContextProductionUnitsLoading = (
        !independentProductionUnits &&
        selectedSupplierId &&
        supplierContextProductionUnit.productionUnits === undefined
    );

    function onSupplierIdSelected(supplierId: string) {
        setSelectedSupplierId(supplierId)
    }

    function onProductionUnitIdSelected(productionUnitId: string) {
        setSelectedProductionUnitId(productionUnitId)
    }

    const sharedResult = {
        loading: loading || supplierContextProductionUnitsLoading,
        selectedProductionUnitId,
        selectedSupplierId,
        linked: loading ? true : areSupplierAndProductionUnitLinked(selectedSupplierId, selectedProductionUnitId, relations),
        setSelectedProductionUnitId: onProductionUnitIdSelected,
        setSelectedSupplierId: onSupplierIdSelected
    }

    if (independentProductionUnits) {
        // production units can be selected, when no supplier is selected
        return {
            ...sharedResult,
            suppliers: loading ? [] : limitedSuppliers,
            productionUnits: loading ? [] : limitedProductionUnits,
            otherSuppliers: loading ? [] : otherSuppliers,
            otherProductionUnits: loading ? [] : otherProductionUnits
        }
    } else {
        // production units can not be selected, unless a supplier has been selected
        return {
            ...sharedResult,
            suppliers: loading ? [] : suppliers,
            productionUnits: (function () {
                if (supplierContextProductionUnitsLoading) {
                    return [];
                }
                if (selectedSupplierId) {
                    return supplierContextProductionUnit.productionUnits;
                }
                return [];
            })(),
            otherSuppliers: [],
            otherProductionUnits: [],
        }
    }
}

function limitProductionUnits<T extends { id?: string }>(productionUnits: T[], supplier_id, relations: SupplierAndProductionUnitRelations): [T[], T[]] {

    if (!supplier_id) {
        return [productionUnits, []];
    }

    const puIdMap: { [productionUnitId: string]: boolean } = {}
    relations.forEach((relation) => {
        if (relation[0] == supplier_id) {
            puIdMap[relation[1]] = true;
        }
    });

    const a: T[] = [];
    const b: T[] = [];

    productionUnits.forEach((element) => {
        if (puIdMap[element.id]) {
            a.push(element);
        } else {
            b.push(element);
        }
    })

    return [a, b];
}

export function areSupplierAndProductionUnitLinked(supplierId: string, productionUnitId: string, relations: SupplierAndProductionUnitRelations): boolean {
    if (!supplierId) {
        return false;
    }
    if (!productionUnitId) {
        return false;
    }

    const end = relations.length;
    // for loop for easy early return.
    for (let i = 0; i < end; i++) {
        if (relations[i][0] === supplierId && relations[i][1] === productionUnitId) {
            return true;
        }
    }
    return false;
}

function limitSuppliers<T extends { id: string }>(suppliers: T[], productionUnitId, relations: SupplierAndProductionUnitRelations): [T[], T[]] {
    if (!productionUnitId) {
        return [suppliers, []];
    }

    const supplierIdMap: { [supplierId: string]: boolean } = {}
    relations.forEach((relation) => {
        if (relation[1] == productionUnitId) {
            supplierIdMap[relation[0]] = true;
        }
    });

    const a: T[] = [];
    const b: T[] = [];

    suppliers.forEach((element) => {
        if (supplierIdMap[element.id]) {
            a.push(element);
        } else {
            b.push(element);
        }
    });

    return [a, b];

}