import { handleActions } from 'redux-actions';
import { Action } from './../../base/genericActions';
import * as types from './actions/actionTypes';

export type PdfState = {
};
const initialState: PdfState = {    
};

export const reducer = handleActions({
    [types.PDF_CLEAR_EXTERNAL_ID]: (state = initialState, action: Action<types.PDF_CLEAR_EXTERNAL_ID>) => {
        const id = action.payload.inspectionId;
        if (state[id]) {
            return {
                ...state,
                [id]: undefined,
            };
        }
        return {
            ...state,
        };
    },

    [types.PDF_SET_EXTERNAL_ID]: (state = initialState, action: Action<types.PDF_SET_EXTERNAL_ID>) => {
        const id = action.payload.id;
        const externalId = action.payload.externalId;
        return {
            ...state,
            [id]: { url: externalId },
        };
    },
}, initialState);

export default reducer;
