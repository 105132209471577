import React from 'react';
import { useIntl } from 'react-intl';
import { CorrectiveActionDescriptionOfResolutionEvent } from '../../../backend_api/models/CorrectiveActionDescriptionOfResolutionEvent';
import Change from './Change';

type Props = {
    event: CorrectiveActionDescriptionOfResolutionEvent
}

const DescriptionOfResloutionEvnet = (props: Props) => {
    const intl = useIntl()
    return <Change
        previousValue={props.event.description_of_resolution_change.previous_value}
        newValue={props.event.description_of_resolution_change.next_value}
        label={intl.formatMessage({ id: 'corrective_actions.history.edit_event.description_of_resolution' })}
    />
}

export default DescriptionOfResloutionEvnet;