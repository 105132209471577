/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UUIDRequired } from './UUIDRequired';

/**
 * History entry user reference
 *
 */
export type HistoryEntryUserReference = {
    disabled: boolean;
    email: string;
    firstname: string;
    id: UUIDRequired;
    lastname: string;
    source_reference_type: HistoryEntryUserReference.source_reference_type;
};

export namespace HistoryEntryUserReference {

    export enum source_reference_type {
        AUDIT = 'audit',
        USER = 'user',
        IMPORT_JOB = 'import_job',
    }


}

