/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 85.5.1 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventBooleanChange,
    EventBooleanChangeFromJSON,
    EventBooleanChangeFromJSONTyped,
    EventBooleanChangeToJSON,
    EventDateChange,
    EventDateChangeFromJSON,
    EventDateChangeFromJSONTyped,
    EventDateChangeToJSON,
    EventStringChange,
    EventStringChangeFromJSON,
    EventStringChangeFromJSONTyped,
    EventStringChangeToJSON,
    EventStringListChange,
    EventStringListChangeFromJSON,
    EventStringListChangeFromJSONTyped,
    EventStringListChangeToJSON,
    EventUserChange,
    EventUserChangeFromJSON,
    EventUserChangeFromJSONTyped,
    EventUserChangeToJSON,
} from './';

/**
 * Event on a corrective action when corrective action is updated by the importer.
 * @export
 * @interface UpdatedByImporter
 */
export interface UpdatedByImporter {
    /**
     * 
     * @type {EventUserChange}
     * @memberof UpdatedByImporter
     */
    approver_change: EventUserChange;
    /**
     * 
     * @type {EventStringChange}
     * @memberof UpdatedByImporter
     */
    description_change: EventStringChange;
    /**
     * 
     * @type {EventDateChange}
     * @memberof UpdatedByImporter
     */
    due_date_change: EventDateChange;
    /**
     * 
     * @type {EventStringListChange}
     * @memberof UpdatedByImporter
     */
    groups_change: EventStringListChange;
    /**
     * 
     * @type {EventBooleanChange}
     * @memberof UpdatedByImporter
     */
    requires_evidence_from_app_change: EventBooleanChange;
    /**
     * 
     * @type {EventUserChange}
     * @memberof UpdatedByImporter
     */
    responsible_change: EventUserChange;
}

export function UpdatedByImporterFromJSON(json: any): UpdatedByImporter {
    return UpdatedByImporterFromJSONTyped(json, false);
}

export function UpdatedByImporterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatedByImporter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'approver_change': EventUserChangeFromJSON(json['approver_change']),
        'description_change': EventStringChangeFromJSON(json['description_change']),
        'due_date_change': EventDateChangeFromJSON(json['due_date_change']),
        'groups_change': EventStringListChangeFromJSON(json['groups_change']),
        'requires_evidence_from_app_change': EventBooleanChangeFromJSON(json['requires_evidence_from_app_change']),
        'responsible_change': EventUserChangeFromJSON(json['responsible_change']),
    };
}

export function UpdatedByImporterToJSON(value?: UpdatedByImporter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'approver_change': EventUserChangeToJSON(value.approver_change),
        'description_change': EventStringChangeToJSON(value.description_change),
        'due_date_change': EventDateChangeToJSON(value.due_date_change),
        'groups_change': EventStringListChangeToJSON(value.groups_change),
        'requires_evidence_from_app_change': EventBooleanChangeToJSON(value.requires_evidence_from_app_change),
        'responsible_change': EventUserChangeToJSON(value.responsible_change),
    };
}


