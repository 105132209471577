/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *   Contains a list of option ids
 *
 */
export type AuditCustomFieldOptionIds = {
    option_ids: Array<string>;
    type: AuditCustomFieldOptionIds.type;
};

export namespace AuditCustomFieldOptionIds {

    export enum type {
        OPTION_IDS = 'option_ids',
    }


}

