import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { CreateCorrectiveActionRequest, EditCorrectiveActionRequest } from '../../../backend_api/models';

type Props = {
    open: boolean;
    close();
    create(ca: CreateCorrectiveActionRequest);
}

const CreateCorrectiveActionDialog = (props: Props) => {
    const [finding, setFinding] = useState<string>('')
    const disableSave = finding == '';

    const onAction = () => {
        const ca = createCAObject()
        props.create(ca as CreateCorrectiveActionRequest)
        close();
    }

    const createCAObject = (): CreateCorrectiveActionRequest | EditCorrectiveActionRequest => {
        const ca = {
            finding: finding,
        }
        return ca;
    }

    const close = () => {
        setFinding('');
        props.close();
    }

    const enterListener = (e: any) => {
        if (e.keyCode == 13) {
            onAction()
        }
    }

    return <Modal
        open={props.open}
        closeOnEscape={true}
        closeIcon={true}
        onClose={close}
    >
        <Modal.Header>
            <FormattedMessage id='corrective_actions.create_modal.header' />
        </Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field>
                    <label><FormattedMessage id='corrective_actions.create_modal.finding' /></label>
                    <Input
                        autoFocus
                        type='text'
                        value={finding}
                        onKeyDown={enterListener}
                        onChange={(evt, item) => setFinding(item.value)}
                        name="finding-input-field"
                    />
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={onAction} disabled={disableSave} primary data-test-id="create-button"> <FormattedMessage id="corrective_actions.create_modal.create" /></Button>
            <Button onClick={close}><FormattedMessage id="globals.confirmation.cancel" /></Button>
        </Modal.Actions>
    </Modal>
}

export default CreateCorrectiveActionDialog;