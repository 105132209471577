import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';

type ListViewShowMoreProps = {
    isLoading: boolean;
    totalCnt: number;
    perPage: number;
    start: number;
    loadMore();
};

const ListViewShowMore = (props: ListViewShowMoreProps): React.ReactElement => {
    const intl = useIntl();
    const { isLoading, totalCnt, perPage, start, loadMore } = props;
    const formatMessage = intl.formatMessage;
    const getPage = (): number => {
        let p = 1;
        if (start > 0) {
            p = (start / perPage) + 1;
        }
        return p;
    };
    const getIsDone = (): boolean => {
        const cnt = page * perPage;
        return cnt >= totalCnt;
    };
    const [page, setPage] = useState(getPage());
    const [isDone, setIsDone] = useState(getIsDone());
    useEffect(() => {
        setIsDone(getIsDone());
        setPage(getPage());
    });
    const currentCountLabel = (): React.ReactElement => {
        const total = totalCnt;
        let done = false;
        let cnt = Math.round(page * perPage);
        if (isDone) {
            cnt = total;
            done = true;
        }
        const id = done ? 'listview.load_more.label.thats_it' : 'listview.load_more.label.showing';
        return (<FormattedMessage
            id={id}
            values={{
                cnt,
                total,
                ordersLabel: (<FormattedPlural
                    value={cnt}
                    one={formatMessage({ id: 'listview.load_more.order' })}
                    other={formatMessage({ id: 'listview.load_more.orders' })}
                />),
            }}
        />);
    };
    return <div className='bg-white items-center flex flex-col p-4 space-y-6'>
        <div className='label'>{currentCountLabel()}</div>
        {!isDone && <div className='action'>
            <Button disabled={isLoading} secondary onClick={(): void => loadMore()} >
                {isLoading && <Icon loading className='icon-load' />}
                <FormattedMessage id='listview.load_more.label' />
            </Button>
        </div>}
    </div>;
};

export default ListViewShowMore;
