import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { Audit, AuditChecklistHeader, AuditCheckpoint, AuditStatus, FileResource, MultiLingualText } from '../../../../backend_api_2';
import ToTop from '../../../../base/components/ToTop';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import {
    getLocaleLanguageString,
    isAndroidOS,
    isMobileDevice,
} from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import history from '../../../../store/history';
import { TEMPORARY_USER_MODE } from '../../../authentication/authenticationSlice';
import PageContainer from '../../../globals/components/PageContainer';
import { getAuditContentByIdSelector, getAuditsLoadingSelector, getFetchingAuditErrorCodeFailedSelector, getPreviousAuditContentByIdSelector } from '../../selectors/auditSelectors';
import { getAuditContentById, resetAuditWithContent, updateCheckpointComment } from '../../slices/auditSlice';
import { DefaultCheckpoint } from './DefaultCheckpoint';
import { MultipleChoiceCheckpoint } from './MultipleChoiceCheckpoint';
import { ProductionUnitCustomFieldCheckpoint, SupplierCustomFieldCheckpoint } from './ProductionUnitCustomFieldCheckpoint';
import { SummaryAndSubmit } from './SummaryAndSubmitAudit';
import { TextInputsCheckpoint } from './TextInputsCheckpoint';
import { TextTableCheckpoint } from './TextTableCheckpoint';
import { WebAuditInfo } from './WebAuditInfo';
import { doesAuditHaveAnyMandatoryCheckpoints, doesChecklistHeaderHaveAnyMandatoryCheckpoints, doesCheckpointRequiresInputOrMedia } from './mandatory';
import { CheckpointHeaderProgressPopup } from './CheckpointHeaderProgressPopup';
import { AddMediaModal } from './AuditCheckpointMedia';
import { MeasurementTableCheckpoint } from './MeasurementTableCheckpoint';
import { TextArea, TextAreaHeight } from '../../../../base/ui/components/TextArea';
import ContextMenu, { ContextMenuActionItemType } from '../../../../base/ui/components/contextMenu/ContextMenu';
import AuditLockedModal from './AuditLockedModal';
import NoAuditAccessPage from './NoAuditAccessPage';

type PerformAuditPageProps = {
    auditId: string;
};

const PerformAuditPage = (props: PerformAuditPageProps) => {
    const {auditId} = props;
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const auditWithContent = useSelector(getAuditContentByIdSelector);
    const previousAudit = useSelector(getPreviousAuditContentByIdSelector);

    const showContinueInBrowserGuide = auditWithContent?.audit?.locked && previousAudit?.audit?.locked === false;
    
    const [showLockedAuditModal, setShowLockedAuditModal] = useState(false);
    const [showRequireInputModal, setShowRequireInputModal] = useState(false);
    const hasMandatoryInput = doesAuditHaveAnyMandatoryCheckpoints(auditWithContent?.checklist);
    const [triedToSubmit, setTriedToSubmit] = React.useState(false);
    const isTemporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE);
    const isLoading = useSelector(getAuditsLoadingSelector);
    const errorCodeFetchingAudit = useSelector(getFetchingAuditErrorCodeFailedSelector);

    function openInApp() {
        window.location.href = getAppLink({auditId});
    }

    useEffect(() => {
        dispatch(getAuditContentById({ auditId }));
        const intervalId = setInterval(() => dispatch(getAuditContentById({ auditId })), 5000);
        return () => {
            clearInterval(intervalId);
            dispatch(resetAuditWithContent());
        }
    }, [auditId]);

    useEffect(() => {
        setShowLockedAuditModal(auditWithContent?.audit?.locked);
    }, [auditWithContent]);

    if(errorCodeFetchingAudit) {
        return <NoAuditAccessPage />
    }

    if(auditWithContent?.submitted) {
        if(!!isTemporaryUserMode) {
            history.push('/audits/completed');
        } else {
            history.push('/audits/report/' + auditId);
        }
    }

    if(auditWithContent?.checklist?.headers?.length > 0 && !isLoading) {
        const {audit, checklist} = auditWithContent;
        return (
            <PageContainer headerClassName='' header={<div className='flex space-x-2 justify-between'>
                    <DocumentTitle title={intl.formatMessage({ id: 'page_title.perform_audit' }, { name: audit.audit_type?.name})} />
                    <div className='px-4 sm:px-0 text-3xl font-bold'>{audit.audit_type?.name}</div>
                </div>}>
                <div className='flex flex-wrap-reverse sm:flex-row space-x-0'>
                    <div className='sm:w-2/3 w-full sm:pr-6'>
                        {checklist.headers.map((header, index) => <AuditCheckpointHeader audit={audit} index={index} header={header} key={index} triedToSubmit={triedToSubmit}/>)}
                        <div className='my-2'>
                            <div className='text-xl font-bold my-3 sm:px-0 px-4'><FormattedMessage id='web_based_audit.summary_and_submit'/></div>
                            <SummaryAndSubmit notify={() => {
                                setTriedToSubmit(true);
                                if(hasMandatoryInput) {
                                    setShowRequireInputModal(true);
                                }
                            }} checklist={checklist} />
                        </div>
                    </div>
                    <div className='sm:w-1/3 w-full mb-6 mt-2'>
                        <WebAuditInfo audit={audit}/>
                        <AuditAttachments audit={audit}/>
                    </div>
                    <ToTop />
                </div>
    
                <OpenInAppButton openInApp={openInApp}/>
                
                <Modal
                    size="small"
                    closeOnEscape
                    closeIcon
                    onClose={() => setShowRequireInputModal(false)}
                    open={showRequireInputModal}
                >
                    <Modal.Header>
                        <div className='flex flex-row justify-start font-bold'><FormattedMessage id='web_based_audit.mandatory_data_missing.title'/></div>
                    </Modal.Header>
                    <Modal.Content>
                        <div className='flex flex-row justify-start'><FormattedMessage id='web_based_audit.mandatory_data_missing.description'/></div>
                    </Modal.Content>
                    <Modal.Actions className="flex justify-end">
                        <Button primary onClick={() => {
                            setShowRequireInputModal(false)
                            scrollTo({
                                left: 0,
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}>
                            <FormattedMessage id="web_based_audit.modal.confirm" />
                        </Button>
                    </Modal.Actions>
                </Modal>
                { showLockedAuditModal && <AuditLockedModal auditId={auditId} showContinueInBrowserGuide={showContinueInBrowserGuide}/> }
            </PageContainer>
        );
    }

    return <Dimmer inverted active>
            <Loader className='dimLoader'><FormattedMessage id='globals.initialising_app.please_wait' /></Loader>
        </Dimmer>
}

export default PerformAuditPage;

function AuditCheckpointHeader(props: {
    audit: Audit,
    index: number,
    header: AuditChecklistHeader,
    triedToSubmit: boolean
}) {
    const {audit, header, triedToSubmit, index} = props;
    const requiresInput = doesChecklistHeaderHaveAnyMandatoryCheckpoints(header);

    const [isOpen, setIsOpen] = React.useState(true);

    React.useEffect(() => {
        setIsOpen(triedToSubmit ? requiresInput : true)
    }, [triedToSubmit])

    return (
        <div className={`checkpoint-header mb-4 ${index == 0 ? '': 'pt-4'}`}>
            <div className='flex flex-row justify-between items-center mb-3 cursor-pointer mx-2 sm:mx-0' onClick={() => setIsOpen(!isOpen)}>
                <div className='flex flex-row justify-start items-center'>
                    {
                        triedToSubmit && requiresInput &&
                        <Icon name='error' className='text-2xl text-status-rejected mr-1' />
                    }
                    <MultiLingualTextComponent text={header.title} className={`text-xl font-bold sm:px-0 px-2 w-fit`} />
                </div>
                <div className='flex flex-row justify-end items-center' >
                    <CheckpointHeaderProgressPopup header={header}/>
                    <button type="button" title={isOpen ? "Collapse section" : "Expand section"} aria-label="Toggle section" aria-expaned={isOpen}>
                        {isOpen ? <Icon name='expand_less' className='text-2xl' /> : <Icon name='expand_more' className='text-2xl' />}
                    </button>
                </div>
            </div>
            {
                isOpen ? header.checkpoints.map((checkpoint, index) => <AuditCheckpointComponent audit={audit} checkpoint={checkpoint} key={index} triedToSubmit={triedToSubmit}/>) : null
            }
        </div>
    )
}

function AuditCheckpointComponent(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {audit, checkpoint, triedToSubmit} = props;
    const requiresInputOrMedia = doesCheckpointRequiresInputOrMedia(checkpoint);

    function getCheckpointComponent() {
        switch(checkpoint.checkpoint_type) {
            case AuditCheckpoint.checkpoint_type.TEXT_INPUTS:
                return <TextInputsCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            case AuditCheckpoint.checkpoint_type.TEXT_TABLE:
                return <TextTableCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            case AuditCheckpoint.checkpoint_type.MULTIPLE_CHOICE:
                return <MultipleChoiceCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            case AuditCheckpoint.checkpoint_type.PRODUCTION_UNIT_CUSTOM_FIELDS:
                return <ProductionUnitCustomFieldCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            case AuditCheckpoint.checkpoint_type.SUPPLIER_CUSTOM_FIELDS:
                return <SupplierCustomFieldCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            case AuditCheckpoint.checkpoint_type.MEASUREMENT_TABLES:
                return <MeasurementTableCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
            default:
                return <DefaultCheckpoint audit={audit} checkpoint={checkpoint} triedToSubmit={triedToSubmit}/>
        }
    }

    return <div className={`border bg-white py-4 px-4 rounded-md my-2 ${requiresInputOrMedia && triedToSubmit ? 'border-red-500' : ''}`}>
        {getCheckpointComponent()}
    </div>
}

type AppLinkConfig = {
    auditId: string,
    checkpointId?: string
}
export function getAppLink(config: AppLinkConfig): string {
    const {auditId, checkpointId} = config;
    const isTemporaryUser = !!sessionStorage.getItem('temporary_user_mode');
    const sessionId = sessionStorage.getItem('session_id');
    const params = isTemporaryUser && sessionId ? `session_id=${sessionId}&checkpoint_id=${checkpointId}` : `audit_id=${auditId}&checkpoint_id=${checkpointId}`;

    if (isAndroidOS()) {
        return `android-app://com.qarma.app/http/app.qarmainspect.com/q/app/applink/audits?${params}`;
    } else {
        return window.location.origin + `/q/app/applink/audits?${params}`;
    }
}

function AuditCorrectiveActions() {
    return (
        <div className='border bg-white flex-col my-4'>
            <div className='text-lg font-bold px-4 py-4 border-b'>
                Corrective Actions
            </div>
            <div className='text-sm text-gray-500 py-4 px-4'>
                No corrective actions on this audit yet.
            </div>
            <div className='flex flex-row items-center px-4'>
                <div className='flex flex-row items-center px-2 py-1 mb-4 border-0 bg-gray-100 rounded-md grow-0 cursor-pointer' onClick={null} >
                    <div className='text-sm'>Create corrective action</div>
                </div>
            </div>
        </div>
    )
}

function AuditAttachments(props: {
    audit: Audit
}) {
    const {audit} = props;

    if(!audit.attachments || audit.attachments.length == 0) {
        return null;
    }
    return (
        <div className='border bg-white flex-col my-4'>
            <div className='text-lg font-bold px-4 py-4 border-b'><FormattedMessage id='attachments.header'/></div>
            {
                audit.attachments.map((attachment, index) => {
                    return (
                    <div className={`flex flex-row items-center my-2 mx-4`} key={index}>
                        <Icon className='text-grey text-xl pr-1' name='attachment' />
                        <div className='text-sm text-green-600 cursor-pointer' onClick={() => window.open(getFileResourceUrl(attachment), '_blank')}>{attachment.name}</div>
                    </div>
                    )
                })
                
            }
            {/* <div className='text-sm text-gray-500 py-4 px-4'>
                No attachments on this audit yet.
            </div>
            <div className='flex flex-row items-center px-4'>
                <div className='flex flex-row items-center px-2 py-1 mb-4 border-0 bg-gray-100 rounded-md grow-0 cursor-pointer' onClick={null} >
                    <Icon className='text-grey text-xl pr-1' name='attach_file' />
                    <div className='text-sm'>Attach files</div>
                </div>
            </div> */}
        </div>
    )
}

function OpenInAppButton(props: {openInApp: () => void}): React.ReactElement {
    const {openInApp} = props;
    const VISIBLE_AT_POSITION = 750;
    const [scrollPosition, setScrollPosition] = React.useState({ x: 0, y: 0 });
    useScrollPosition(({ currPos }) => {
        setScrollPosition(currPos)
    }, []);

    return isMobileDevice() && (scrollPosition.y > -VISIBLE_AT_POSITION) && 
    <div className="fixed w-full flex-col justify-center xl:invisible px-8 bottom-0 bg-white py-8">
        <Button
            className="justify-center w-full text-base my-2"
            primary
            onClick={openInApp}
        >
            <FormattedMessage id="web_based_audit.open_in_app" />
            <Icon className="text-white pl-2" name="open_in_new" />
        </Button>
    </div>;
}

export function AuditCheckpointTitleAndActionMenu(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint
}) {
    const {checkpoint} = props;
    const {audit} = useSelector(getAuditContentByIdSelector);
    const intl = useIntl();
    const hasComment = !!checkpoint.comment;
    const shouldAskForComment = checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.PENDING || checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.CHECKED_NOT_OK;
    const showCommentFieldByDefault = hasComment || shouldAskForComment;

    const [showingNoteInput, setShowingNoteInput] = React.useState(showCommentFieldByDefault);
    const [showingAddMediaModal, setShowingAddMediaModal] = React.useState(false);
    const [commentAutoFocus, setCommentAutoFocus] = React.useState(false);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if(audit.locked) {
            setShowingAddMediaModal(false);
        }
    }, [audit])


    const [comment, setComment] = React.useState(checkpoint.comment);

    const addMediaContextMenuItem: ContextMenuActionItemType = {
        label: 'web_based_audit.buttons.add_media',
        icon: 'operation_take-photo',
        onPress: () => {
            setShowingAddMediaModal(true);
        }
    }
    const addNoteContextMenuItem: ContextMenuActionItemType = {
        label: 'web_based_audit.buttons.add_note',
        labelLeft: true,
        icon: 'object_comment',
        onPress: () => {
            setShowingNoteInput(true)
            setCommentAutoFocus(true);
        },
    }
    
    function doUpdateComment(comment: string) {
        const noUpdate = !comment && !checkpoint.comment;
        if(noUpdate) {
            return;
        } else {
            return dispatch(updateCheckpointComment({
                audit_id: audit.id,
                checkpoint_id: checkpoint.id,
                comment: comment
            }))
        }
    }

    return (
        <div>
            <div className='flex flex-row justify-between mb-4'>
                <div>
                    {checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.PRODUCTION_UNIT_CUSTOM_FIELDS && <div className='text-sm text-gray-500'><FormattedMessage id='web_based_audit.production_unit_data'/></div>}
                    {checkpoint.checkpoint_type == AuditCheckpoint.checkpoint_type.SUPPLIER_CUSTOM_FIELDS && <div className='text-sm text-gray-500'><FormattedMessage id='web_based_audit.supplier_data'/></div>}

                    <MultiLingualTextComponent text={checkpoint.description} className={`text-lg font-bold w-fit`} />
                    <MultiLingualTextComponent text={checkpoint.instruction_details} className="text-sm w-fit" />
                    {
                        checkpoint.instruction_file_resources?.map((file, index) => 
                            <div className={`flex flex-row items-center ${index == checkpoint.instruction_file_resources.length - 1 ? 'mb-2' : ''}`} key={index}>
                                <Icon className='text-grey text-xl pr-1' name='attachment' />
                                <div className='text-sm text-green-600 cursor-pointer' onClick={() => window.open(getFileResourceUrl(file), '_blank')}>{file.name}</div>
                            </div>
                        ) 
                    }
                </div>
                <ContextMenu
                    className='h-fit-content'
                    actions={
                        showingNoteInput ? 
                        [addMediaContextMenuItem] :
                        [addNoteContextMenuItem, addMediaContextMenuItem]
                    }
                />
                <AddMediaModal appLink={getAppLink({auditId: audit.id, checkpointId: checkpoint.id})} showing={showingAddMediaModal} setShowing={setShowingAddMediaModal} />
            </div>
            {
                showingNoteInput &&
                <TextArea 
                    height={TextAreaHeight.SINGLE_LINE_AUTO_EXPAND}
                    value={comment}
                    onChange={setComment}
                    onBlur={doUpdateComment}
                    className="w-full sm:w-2/3 mb-4"
                    label='Note'
                    labelIcon='sticky_note_2'
                    placeholder={intl.formatMessage({id: "web_based_audit.add_note.placeholder"})}
                    autoFocus={commentAutoFocus}
                />
            }
        </div>
    )
}

function getFileResourceUrl(file: FileResource) {
    switch(file.type) {
        case FileResource.type.FILE:
            return file.file.download_url;
        case FileResource.type.IMAGE:
            return file.image.image_url;
        case FileResource.type.VIDEO:
            return file.video.video_resources[0].url;
    }
}

export function MultiLingualTextComponent(props: {
    className: string
    text: MultiLingualText
}) {
    const intl = useIntl();
    const lang = getLocaleLanguageString(intl.locale);
    const {className, text} = props;
    const translatedText = getTextWithTranslation(text, lang);

    return translatedText ? <div className={className}>{translatedText}</div> : null
}

export function getTextWithTranslation(text: MultiLingualText, language: string): string {
    return text[language] || text.C;
}

export function isAuditImmutable(audit: Audit): boolean {
    return audit.locked || audit.status == AuditStatus.REPORT;
}