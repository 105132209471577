import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Input from '../../../../base/components/basic/Input';
import { Inspection } from '../../types';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import { QtysType } from './SplitShipments';
import SplitShipmentsMarker from './SplitShipmentsMarker';

type SplitItemProps = {
    className?: string;
    inspection: Inspection;
    isInspection?: boolean;
    qty1: number;
    qty2: number;
    updateQtys(qtys: QtysType): void;
};

const SplitItem = (props: SplitItemProps): React.ReactElement => {
    const { inspection, isInspection = true, updateQtys } = props;
    const [q1, setValue1] = useState(props.qty1);
    const [q2, setValue2] = useState(props.qty2);
    const noInSeq = inspection.sequence_identifier.filter((f) => isNaN(parseInt(f)));
    let firstLetterLabel: React.ReactElement, secondLetterLabel: React.ReactElement;
    if (noInSeq.length > 0) {
        const firstLetter = noInSeq[noInSeq.length - 1];
        const secondLetter = String.fromCharCode(firstLetter.charCodeAt(0) + 1);
        firstLetterLabel = <FormattedMessage id='split_shipments.qty_for_shipment_n' values={{ n: firstLetter }} />;
        secondLetterLabel = <FormattedMessage id='split_shipments.qty_for_shipment_n' values={{ n: secondLetter }} />;
    } else {
        const p = 1; //TODO: The item's position in the chain - should come from data
        firstLetterLabel = <FormattedMessage id='split_shipments.qty_for_ordinal_shipment' values={{ n: p }} />;
        secondLetterLabel = <FormattedMessage id='split_shipments.qty_for_ordinal_shipment' values={{ n: p + 1 }} />;
    }
    const hasChanged = (id: string, first: number, second: number) => {
        setValue1(first);
        setValue2(second);
        updateQtys({ first, second, id });
    }
    const qty = inspection.total_quantity;
    const id = isInspection ? inspection.inspection_id : inspection.id;
    const setValue = (value: number, isFirst: boolean): void => {
        if ((value >= 0 && value <= qty) || isNaN(value)) {
            if (isFirst) {
                isNaN(value) ? hasChanged(id, undefined, qty) : hasChanged(id, value, qty - value);
            } else {
                isNaN(value) ? hasChanged(id, qty, undefined) : hasChanged(id, qty - value, value);
            }
        }
    }
    return <div>
        <h4 className='flex py-2 space-x-1'><SplitShipmentsMarker inspection={inspection} /><InspectionTypeMarker inspections={[inspection]} /><div>{inspection.item_number}-{inspection.item_name}</div></h4>
        <div className='flex space-x-2 mb-4'><div className='w-1/4'><FormattedMessage id='split_shipments.current_qty' /></div><b>{inspection.total_quantity}</b></div>
        <div className='py-2'><FormattedMessage id='split_shipments.enter_new_qtys' /></div>
        <div className='flex flex-col'>
            <div className='py-2 flex items-center space-x-2'><div className='w-1/4'>{firstLetterLabel}</div><Input className='w-4/12' type='number' value={q1} onChange={(e) => setValue(parseInt(e.currentTarget.value || '', 10), true)} /></div>
            <div className='py-2 flex items-center space-x-2'><div className='w-1/4'>{secondLetterLabel}</div><Input className='w-4/12' type='number' value={q2} onChange={(e) => setValue(parseInt(e.currentTarget.value || '', 10), false)} /></div>
        </div>
    </div>
}
export default SplitItem;
