import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Image } from 'semantic-ui-react';

interface ImagesProps {
    image: string[];
    disabled?: boolean;
    removePhoto(inspectionId: string);
    compact?: boolean;
}

export const Images = (props: ImagesProps): React.ReactElement => {
    const size = props.compact ? 'tiny' : undefined;
    const images = (<Fragment>
        {(props.image || []).map((image, index: number) =>
            <div key={index} style={{ minWidth: '100px' }}>
                <Image src={image + '?w=500'} size={size} />
                {!props.disabled && <div className='link delete' onClick={(): void => props.removePhoto(image)}><FormattedMessage id='inspections.delete_photo' /></div>}
            </div>
        )}
    </Fragment>)
    return (
        <Card.Content>
            {props.image && props.image.length > 0 && images}
            {props.image && props.image.length === 0 && <div><FormattedMessage id='inspections.no_photo_uploaded' /></div>}
        </Card.Content>
    )
}