import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import Confirm from './Confirm';

interface DeleteOrderProps {
    onlyHasPlanned: boolean;
    showConfirm: boolean;
    handleConfirm();
    deleteOrder();
    handleClose();
}

const DeleteOrder = (props: DeleteOrderProps): React.ReactElement => {
    return (
        <Segment basic>
            <Header as='h3'><FormattedMessage id='inspections.delete_the_order' /></Header>
            <FormattedMessage id='inspections.this_deletes_the_entire_order' />
            <Divider />
            <Button disabled={!props.onlyHasPlanned} negative onClick={(): void => props.handleConfirm()}><FormattedMessage id='inspections.delete_order' /></Button>
            <Button onClick={(): void => props.handleClose()}><FormattedMessage id='inspections.cancel' /></Button>
            <Confirm
                show={props.showConfirm}
                handleCancel={props.handleClose}
                confirmAction={props.deleteOrder}>
                <FormattedMessage id='inspections.are_you_sure_this_action_cannot_be_undone' /></Confirm>
        </Segment>
    );
};

export default DeleteOrder;
