import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Divider, Dropdown } from 'semantic-ui-react';
import { DropdownFilter } from '.';
import { getLocaleSpecificString, getLocationEntry } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { DropdownOptionType } from '../../users/types';
import { getFilterConclusions, setFilter } from '../filtersActions';
import { getFilterConclusionsSelector } from '../selectors';
import Filter from './Filter';
type ConclusionFilterProps = {
    disabled?: boolean;
    search?: boolean;
};
const ConclusionFilter = (props: ConclusionFilterProps): React.ReactElement => {
    const { disabled = false, search = true } = props;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const intl = useIntl();
    const conclusion = getLocationEntry(location, 'custom_conclusion_ids');
    const conclusions = useSelector(getFilterConclusionsSelector);
    const [conclusionOptions, setConclusionOptions] = useState([])
    useEffect(() => {
        dispatch(getFilterConclusions());
    }, [])
    useEffect(() => {
        if (conclusions) {
            const options: DropdownOptionType[] = [];
            let deprecatedSection = false;
            conclusions.map((conclusion) => {
                const name = getLocaleSpecificString(conclusion.full_name);
                if (conclusion.deprecated && !deprecatedSection) {
                    deprecatedSection = true;
                    options.push(
                        {
                            key: 'devider',
                            text: '',
                            value: '',
                            content: (<Divider />),
                            disabled: true
                        }
                    );
                    options.push(
                        {
                            key: 'filters.deprecated-conclusions',
                            text: '',
                            value: '',
                            content: <Dropdown.Header content={intl.formatMessage({ id: 'filters.deprecated-conclusions' })} />,
                            disabled: true
                        }
                    );
                }
                options.push({ key: conclusion.id, value: conclusion.id, text: name })
            })
            setConclusionOptions(options);
        }
    }, [conclusions])

    return <Filter labelId='filters.conclusions.label_conclusion'>
        <DropdownFilter
            name='conclusions'
            options={conclusionOptions}
            multiple={true}
            placeholder={intl.formatMessage({ id: 'filters.conclusions.select_conclusion' })}
            selectedItems={conclusion || []}
            handleSave={(items): void => {
                dispatch(setFilter('custom_conclusion_ids', items));
            }}
            inline={false}
            disabled={disabled}
            search={search}

        />
    </Filter>;
};

export default ConclusionFilter;
