import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { DisplayCustomField, DisplayCustomFieldMultipleChoiceValue } from '../../../backend_api/models';
import { Checkbox, Radio } from '../../../base/components/basic/CheckboxRadio';
import { translateTextWithTranslation } from '../../../base/components/basic/TextWithTranslation';
import { OptionsType } from '../../../base/types';
import { addArrayItemOrRemoveIfExists, getLocaleLanguage, sortArrayByKey, twMerge } from '../../../base/utils';
import { searchOptionTextAndDescription } from '../../inspections/Utils';

type Props = {
    customField: DisplayCustomField;
    edit: boolean;
    className?: string;
    handleMCChange(ids: string[]);
};

const DisplayCustomFieldsMultipleChoice = (props: Props): React.ReactElement => {
    const intl = useIntl();
    const { className, customField, handleMCChange, edit } = props;
    const MANY_OPTIONS_CNT = 10;
    let options: OptionsType[];
    let valueOptions = [];
    const [value, setValue] = useState<DisplayCustomFieldMultipleChoiceValue>(customField.value as DisplayCustomFieldMultipleChoiceValue);
    const [selectedId, setSelectedId] = useState(() => value.options.filter((option) => option.selected).map((sel) => sel.option_id));
    const multipleSelectionAllowed = value.multiple_selection_allowed;
    const hasColors = value.options.filter((option) => option.hex_color !== null).length > 0;

    useEffect(() => {
        setSelectedId(value.options.filter((option) => option.selected).map((sel) => sel.option_id));        
    }, [value.options])
    useEffect(() => {
        if (!edit) {
            setValue(customField.value as DisplayCustomFieldMultipleChoiceValue);
        }
    }, [customField.value])

    const manyOptions = value.options.length > MANY_OPTIONS_CNT;
    if (manyOptions) {
        options = value.options.map((option) => ({
            key: option.option_id,
            sorttext: option.name.text,
            content: <div className='flex'>{option.hex_color && <div className='w-4 h-4 mr-1 rounded' style={{ backgroundColor: option.hex_color }}></div>}<div>{translateTextWithTranslation(option.name, getLocaleLanguage(intl.locale))}</div></div>,
            text: <div className='flex'>{option.hex_color && <div className='w-4 h-4 mr-1 rounded' style={{ backgroundColor: option.hex_color }}></div>}<div>{translateTextWithTranslation(option.name, getLocaleLanguage(intl.locale))}</div></div>,
            searchtext: option.name.text,
            id: option.option_id,
            value: option.option_id,
            color: option.hex_color,
        }))
        options.push({ id: null, value: null, text: intl.formatMessage({ id: 'users.unassigned' }) })
        sortArrayByKey(options, 'sorttext');
    }
    valueOptions = value.options.map((option) => ({
        sorttext: option.name.text,
        ...option,
    }));
    sortArrayByKey(valueOptions, 'sorttext');

    return <div className={twMerge('', className)}>
        {(manyOptions && edit) && <Dropdown
            className='w-72'
            selection
            options={options}
            value={multipleSelectionAllowed ? selectedId : selectedId[0]}
            multiple={multipleSelectionAllowed}
            search={searchOptionTextAndDescription}
            placeholder={selectedId.length === 0 && intl.formatMessage({ id: 'globals.select' })}
            renderLabel={(item) => {
                item.content
                return ({
                    style: { color: 'white' },
                    className: 'selectLabel justify-between flex-0',
                    content: <div className='flex text-white'>{item.content}</div>,
                });
            }}
            onChange={(e, option) => {
                let value;
                if (option.value !== null) {
                    value = multipleSelectionAllowed ? option.value : [option.value];
                } else {
                    value = [];
                }
                setSelectedId(value);
                handleMCChange(value);
            }}
        />}
        {(!manyOptions || (manyOptions && !edit)) && valueOptions.map((option) => {
            const colorBox = hasColors && <div className={twMerge('flex w-5 h-5 rounded border-default border', !edit && '')} style={{ backgroundColor: option.hex_color }}></div>;
            const nameAndColor = <div className={twMerge('items-start flex', hasColors && ' space-x-2')}><div className=''>{colorBox}</div><div className=''>{translateTextWithTranslation(option.name, getLocaleLanguage(intl.locale))}</div></div>;
            const checked = selectedId.includes(option.option_id);
            return <div className={twMerge(edit ? 'pb-2' : option.selected && 'pb-2')} key={'option_' + option.option_id}>
                <div className={twMerge('flex items-start', hasColors && 'space-x-4', edit && '')}>
                    {edit && <div>{colorBox}</div>}
                    <div className={twMerge('')}>{multipleSelectionAllowed ?
                        edit ? <Checkbox
                            key={'option_' + option.option_id}
                            label={translateTextWithTranslation(option.name, getLocaleLanguage(intl.locale))}
                            name={customField.id}
                            checked={checked}
                            onChange={() => {
                                const ids = addArrayItemOrRemoveIfExists([...selectedId], option.option_id);
                                setSelectedId(ids);
                                handleMCChange(ids);
                            }} /> : checked && nameAndColor
                        :
                        edit ? <Radio label={translateTextWithTranslation(option.name, getLocaleLanguage(intl.locale))}
                            checked={checked}
                            name={customField.id} onChange={() => {
                                setSelectedId([option.option_id]);
                                handleMCChange([option.option_id]);
                            }} />
                            : option.selected && nameAndColor
                    }
                    </div>
                </div>
            </div>
        })}
        {!multipleSelectionAllowed && !manyOptions && selectedId.length > 0 && edit && <div className='link text-sm' onClick={() => {
            setSelectedId([]);
            handleMCChange([]);
        }}><FormattedMessage id='production_units.detailed_page.multiple_choice.selected_option_remove' /></div>}

    </div>
}
export default DisplayCustomFieldsMultipleChoice;
