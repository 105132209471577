import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../../base/components/basic/Icon';
import { arrayOfStringsToNiceString, isUndefinedOrNull } from '../../../base/utils';

type Props = {
    previousValue: any,
    newValue: any,
    label: string
}

const Change = (props: Props) => {
    const intl = useIntl();
    const getLabel = (value) => {
        let ret = '';
        switch (typeof (value)) {
            case 'boolean':
                ret = value.toString();
                break;

            case 'object':
                if (Array.isArray(value)) {
                    if(value.length > 0) {
                    ret = arrayOfStringsToNiceString(value);
                    }else (
                        ret  = 'Empty'
                    )
                }
                break;
            case 'string':
                ret = value;
                break;
        }
        return ret;

    }

    const prevValueIsUndefined = Array.isArray(props.previousValue) ?  props.previousValue.length === 0 : isUndefinedOrNull(props.previousValue);

    return <Fragment>{
        <div className='flex flex-col mb-4'>
            <label className='mb-1'>{props.label}</label>
            <div className='flex items-center text-secondary'>
                {!prevValueIsUndefined && <div>{getLabel(props.previousValue) || <div className='w-4' />}</div>}
                {prevValueIsUndefined && <div className='italic'>{intl.formatMessage({ id: 'corrective_actions.history.edit_event.empty' }) || <div className='w-4' />}</div>}
                <div className='flex mx-1'><Icon className='' name='arrow_right_alt' /></div>
                <div>{getLabel(props.newValue) || <div className='w-4' />}</div>
            </div>
        </div>}
    </Fragment>
}

export default Change;