import qs from 'query-string';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { twMerge } from '../../../base/utils';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
type Props = {
    className?: string;
};

const DataExportFilesDownload = (props: Props): React.ReactElement => {
    const { className } = props;
    const location = useLocation();
    const intl = useIntl();
    let types = [];
    const locationParsed = qs.parse(location.search as string);
    const isProd = document.location.hostname.includes('app.qarmainspect.com');
    const base = locationParsed.base;
    const parsed = locationParsed.files;
    const host = isProd ? 'https://app.qarmainspect.com/' : 'https://testing.aws.qarma.one/';
    const linkPrefix = host + 'q/nocache/objects/files_cache/';
    const fileTypes = {
        inspections: 'inspections.csv.gz',
        checkpoints: 'checkpoints.csv.gz',
        suppliers: 'suppliers.csv.gz',
        production_units: 'production_units.csv.gz',
        audits: 'audits.csv.gz',
        defects: 'defects.csv.gz',
        corrective_actions: 'corrective_actions.csv.gz',
        excel: 'export.xlsx',
    }

    if (typeof parsed === 'string') {
        types = [parsed]
    } else {
        types = parsed;
    }

    return <div className={twMerge('', className)}>
        <DocumentTitle title={intl.formatMessage({ id: 'page_title.data_export.files' })} />
        <PageContainer
            header={<>{intl.formatMessage({ id: 'data_export.files.header' })}</>}>
            <ContentSection content={
                <div className='px-2'>
                    {types && <div className='break-word py-4'>{types.length > 0 && types.map((type) => {
                        const typeString = intl.formatMessage({ id: 'data_export.files.' + type });
                        const fileType = fileTypes[type];
                        const link = linkPrefix + base + '/' + fileType;
                        return <div className='py-3' key={'ft_' + type}>
                            <div className='font-bold'>{typeString}</div>
                            <div><a className='link hover:underline' href={link}>{link}</a></div>
                        </div>
                    })}</div>}
                    {!types && <div><FormattedMessage id='data_export.no_file_types_selected_for_download'/></div>}
                </div>}
            />
        </PageContainer>
    </div>
}
export default DataExportFilesDownload;
